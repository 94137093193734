{
  "metadata": {
    "version": 4.5,
    "type": "Object",
    "generator": "Object3D.toJSON"
  },
  "geometries": [
    {
      "uuid": "C41D692E-D3B0-4506-A01F-7CFEF0880A85",
      "type": "BufferGeometry",
      "data": {
        "attributes": {
          "position": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              -0.29335299134254456,
              0.5174450278282166,
              -0.019625499844551086,
              -0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              -0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              -0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              -0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              -0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              -0.2200150042772293,
              0.08088339865207672,
              0.06523320078849792,
              -0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              -0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              -0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              -0.29335400462150574,
              0.08170349895954132,
              0.06479089707136154,
              -0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              -0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              -0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              -0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              -0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              -0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              -0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              -0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              -0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              -0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              -0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              -0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              -0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              -0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              -0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              -0.2933630049228668,
              0.29742300510406494,
              0.015340199694037437,
              -0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              -0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              -0.2933630049228668,
              0.29742300510406494,
              0.015340199694037437,
              -0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              -0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              -0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              -0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              -0.2933630049228668,
              0.29742300510406494,
              0.015340199694037437,
              -0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              -0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              -0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              -0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              -0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              -0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              -0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              -0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              -0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              -0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              -0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              -0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              -0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              -0.22002199292182922,
              0.17710499465465546,
              0.0264274999499321,
              -0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              -0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              -0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              -0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              -0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.22002199292182922,
              0.4919399917125702,
              -0.021792300045490265,
              -0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              -0.22002199292182922,
              0.4919399917125702,
              -0.021792300045490265,
              -0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              -0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              -0.22002199292182922,
              0.10316000133752823,
              0.053778599947690964,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              -0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              -0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              -0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              -0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              -0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              -0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              -0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              -0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              -0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              -0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              -0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              -0.2933630049228668,
              0.14018499851226807,
              0.046517498791217804,
              -0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              -0.22002199292182922,
              0.4919399917125702,
              -0.021792300045490265,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              -0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.22002199292182922,
              0.10316000133752823,
              0.053778599947690964,
              -0.29335400462150574,
              0.08170349895954132,
              0.06479089707136154,
              -0.22002199292182922,
              0.10316000133752823,
              0.053778599947690964,
              -0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              -0.29335400462150574,
              0.08170349895954132,
              0.06479089707136154,
              -0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              -0.3176969885826111,
              0.2991639971733093,
              0.02280390076339245,
              -0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              -0.3176969885826111,
              0.2991639971733093,
              0.02280390076339245,
              -0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              -0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              -0.3176969885826111,
              0.2991639971733093,
              0.02280390076339245,
              -0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              -0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              -0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              -0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              -0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              -0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              -0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              -0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              -0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              -0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              -0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              -0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              -0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              -0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              -0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              -0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              -0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              -0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              -0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              -0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              -0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              -0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              -0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              -0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              -0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              -0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              -0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              -0.3165220022201538,
              0.18078899383544922,
              0.04538070037961006,
              -0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              -0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              -0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              -0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              -0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              -0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              -0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              -0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              -0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              -0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              -0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              -0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              -0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              -0.3176969885826111,
              0.14181099832057953,
              0.053390100598335266,
              -0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              -0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              -0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              -0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              -0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              -0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              -0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              -0.3165220022201538,
              0.4932210147380829,
              -0.01519909966737032,
              -0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              -0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              -0.3165220022201538,
              0.4932210147380829,
              -0.01519909966737032,
              -0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              -0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              -0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              -0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              -0.3165220022201538,
              0.10444100201129913,
              0.060371801257133484,
              -0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              0,
              0.5174450278282166,
              -0.019625499844551086,
              0,
              0.5159350037574768,
              -0.019741099327802658,
              -0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              0,
              0.5159350037574768,
              -0.019741099327802658,
              -0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              -0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              0,
              0.08167990297079086,
              0.06466930359601974,
              0,
              0.08088330179452896,
              0.06523320078849792,
              -0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              0,
              0.08088330179452896,
              0.06523320078849792,
              -0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              -0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              -0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              -0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              -0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              -0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              -0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              -0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              -0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              -0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              -0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              -0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              -0.2200150042772293,
              0.08088339865207672,
              0.06523320078849792,
              -0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              -0.29721999168395996,
              0.5159609913825989,
              -0.019613299518823624,
              -0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              -0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              -0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              -0.29335299134254456,
              0.5174450278282166,
              -0.019625499844551086,
              -0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              -0.29335400462150574,
              0.08170349895954132,
              0.06479089707136154,
              -0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              -0.29721999168395996,
              0.08170410245656967,
              0.06479740142822266,
              -0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              -0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              -0.29721999168395996,
              0.08170410245656967,
              0.06479740142822266,
              -0.30098798871040344,
              0.5155979990959167,
              -0.019537700340151787,
              -0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              -0.29721999168395996,
              0.5159609913825989,
              -0.019613299518823624,
              -0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              -0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              -0.29721999168395996,
              0.5159609913825989,
              -0.019613299518823624,
              -0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              -0.30098798871040344,
              0.0820688009262085,
              0.06473159790039062,
              -0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              -0.30098798871040344,
              0.0820688009262085,
              0.06473159790039062,
              -0.29721999168395996,
              0.08170410245656967,
              0.06479740142822266,
              -0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              -0.30461201071739197,
              0.514519989490509,
              -0.019325200468301773,
              -0.3051750063896179,
              0.5159289836883545,
              -0.01933090016245842,
              -0.30098798871040344,
              0.5155979990959167,
              -0.019537700340151787,
              -0.3051750063896179,
              0.5159289836883545,
              -0.01933090016245842,
              -0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              -0.30098798871040344,
              0.5155979990959167,
              -0.019537700340151787,
              -0.3051750063896179,
              0.08239900320768356,
              0.06493859738111496,
              -0.30461201071739197,
              0.08314819633960724,
              0.06452509760856628,
              -0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              -0.30461201071739197,
              0.08314819633960724,
              0.06452509760856628,
              -0.30098798871040344,
              0.0820688009262085,
              0.06473159790039062,
              -0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              -0.3079429864883423,
              0.5127729773521423,
              -0.018983300775289536,
              -0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              -0.30461201071739197,
              0.514519989490509,
              -0.019325200468301773,
              -0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              -0.3051750063896179,
              0.5159289836883545,
              -0.01933090016245842,
              -0.30461201071739197,
              0.514519989490509,
              -0.019325200468301773,
              -0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              -0.3079429864883423,
              0.0848957970738411,
              0.06418800354003906,
              -0.3051750063896179,
              0.08239900320768356,
              0.06493859738111496,
              -0.3079429864883423,
              0.0848957970738411,
              0.06418800354003906,
              -0.30461201071739197,
              0.08314819633960724,
              0.06452509760856628,
              -0.3051750063896179,
              0.08239900320768356,
              0.06493859738111496,
              -0.3108679950237274,
              0.510420024394989,
              -0.01851790025830269,
              -0.3117560148239136,
              0.5116130113601685,
              -0.018491899594664574,
              -0.3079429864883423,
              0.5127729773521423,
              -0.018983300775289536,
              -0.3117560148239136,
              0.5116130113601685,
              -0.018491899594664574,
              -0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              -0.3079429864883423,
              0.5127729773521423,
              -0.018983300775289536,
              -0.3117560148239136,
              0.0867151990532875,
              0.06409960240125656,
              -0.3108679950237274,
              0.08725090324878693,
              0.0637321025133133,
              -0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              -0.3108679950237274,
              0.08725090324878693,
              0.0637321025133133,
              -0.3079429864883423,
              0.0848957970738411,
              0.06418800354003906,
              -0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              -0.31327199935913086,
              0.5075439810752869,
              -0.017956199124455452,
              -0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              -0.3108679950237274,
              0.510420024394989,
              -0.01851790025830269,
              -0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              -0.3117560148239136,
              0.5116130113601685,
              -0.018491899594664574,
              -0.3108679950237274,
              0.510420024394989,
              -0.01851790025830269,
              -0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              -0.31327199935913086,
              0.09012719988822937,
              0.06317199766635895,
              -0.3117560148239136,
              0.0867151990532875,
              0.06409960240125656,
              -0.31327199935913086,
              0.09012719988822937,
              0.06317199766635895,
              -0.3108679950237274,
              0.08725090324878693,
              0.0637321025133133,
              -0.3117560148239136,
              0.0867151990532875,
              0.06409960240125656,
              -0.3150550127029419,
              0.504269003868103,
              -0.017321500927209854,
              -0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              -0.31327199935913086,
              0.5075439810752869,
              -0.017956199124455452,
              -0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              -0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              -0.31327199935913086,
              0.5075439810752869,
              -0.017956199124455452,
              -0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              -0.3150550127029419,
              0.09340210258960724,
              0.06253670156002045,
              -0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              -0.3150550127029419,
              0.09340210258960724,
              0.06253670156002045,
              -0.31327199935913086,
              0.09012719988822937,
              0.06317199766635895,
              -0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              -0.31615298986434937,
              0.5007110238075256,
              -0.01663759909570217,
              -0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              -0.3150550127029419,
              0.504269003868103,
              -0.017321500927209854,
              -0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              -0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              -0.3150550127029419,
              0.504269003868103,
              -0.017321500927209854,
              -0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              -0.31615298986434937,
              0.09695850312709808,
              0.06184320151805878,
              -0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              -0.31615298986434937,
              0.09695850312709808,
              0.06184320151805878,
              -0.3150550127029419,
              0.09340210258960724,
              0.06253670156002045,
              -0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              -0.3165220022201538,
              0.49702098965644836,
              -0.01592710055410862,
              -0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              -0.31615298986434937,
              0.5007110238075256,
              -0.01663759909570217,
              -0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              -0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              -0.31615298986434937,
              0.5007110238075256,
              -0.01663759909570217,
              -0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              -0.3165220022201538,
              0.1006459966301918,
              0.06112020090222359,
              -0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              -0.3165220022201538,
              0.1006459966301918,
              0.06112020090222359,
              -0.31615298986434937,
              0.09695850312709808,
              0.06184320151805878,
              -0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              -0.3165220022201538,
              0.4932210147380829,
              -0.01519909966737032,
              -0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              -0.3165220022201538,
              0.49702098965644836,
              -0.01592710055410862,
              -0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              -0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              -0.3165220022201538,
              0.49702098965644836,
              -0.01592710055410862,
              -0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              -0.3165220022201538,
              0.10444100201129913,
              0.060371801257133484,
              -0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              -0.3165220022201538,
              0.10444100201129913,
              0.060371801257133484,
              -0.3165220022201538,
              0.1006459966301918,
              0.06112020090222359,
              -0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              -0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              0,
              0.37218400835990906,
              -0.02009419910609722,
              -0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              0,
              0.37218400835990906,
              -0.02009419910609722,
              0,
              0.3324989974498749,
              -0.014235200360417366,
              -0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              0,
              0.25382199883461,
              0.0010578499641269445,
              0,
              0.21483099460601807,
              0.010491999797523022,
              -0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              0,
              0.21483099460601807,
              0.010491999797523022,
              -0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              -0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              -0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              0,
              0.3324989974498749,
              -0.014235200360417366,
              -0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              0,
              0.3324989974498749,
              -0.014235200360417366,
              0,
              0.2930609881877899,
              -0.0071014100685715675,
              -0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              0,
              0.2930609881877899,
              -0.0071014100685715675,
              0,
              0.25382199883461,
              0.0010578499641269445,
              -0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              0,
              0.25382199883461,
              0.0010578499641269445,
              -0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              -0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              -0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              -0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              -0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              -0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              -0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              -0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              -0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              -0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              -0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              -0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              -0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              -0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              -0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              -0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              -0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              -0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              -0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              -0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              -0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              -0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              -0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              -0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              -0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              -0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              -0.2933630049228668,
              0.29742300510406494,
              0.015340199694037437,
              -0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              -0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              -0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              -0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              -0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              -0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              -0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              -0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              -0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              -0.2933630049228668,
              0.29742300510406494,
              0.015340199694037437,
              -0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              -0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              -0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              -0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              -0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              -0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              -0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              -0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              -0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              -0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              -0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              -0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              -0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              -0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              0,
              0.4532409906387329,
              -0.023140599951148033,
              -0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              0,
              0.4532409906387329,
              -0.023140599951148033,
              0,
              0.41236600279808044,
              -0.023398999124765396,
              -0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              -0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              0,
              0.41236600279808044,
              -0.023398999124765396,
              -0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              0,
              0.41236600279808044,
              -0.023398999124765396,
              0,
              0.37218400835990906,
              -0.02009419910609722,
              -0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              0,
              0.21483099460601807,
              0.010491999797523022,
              0,
              0.17633700370788574,
              0.022480299696326256,
              -0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              0,
              0.17633700370788574,
              0.022480299696326256,
              -0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              -0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              -0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              -0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              -0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              -0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              -0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              -0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              -0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              -0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              -0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              -0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              -0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              -0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              -0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              -0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              -0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              -0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              -0.22002199292182922,
              0.17710499465465546,
              0.0264274999499321,
              -0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              -0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              -0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              -0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              -0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              -0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              -0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              -0.3165220022201538,
              0.18078899383544922,
              0.04538070037961006,
              -0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              -0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              -0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              -0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              -0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              -0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              -0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              -0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              -0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              -0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              -0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              -0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              0,
              0.49191299080848694,
              -0.021930299699306488,
              -0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              0,
              0.49191299080848694,
              -0.021930299699306488,
              0,
              0.4725799858570099,
              -0.022522199898958206,
              -0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              0,
              0.12083700299263,
              0.045849401503801346,
              0,
              0.10313300043344498,
              0.0536407008767128,
              -0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              0,
              0.10313300043344498,
              0.0536407008767128,
              -0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              -0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              -0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              0,
              0.4725799858570099,
              -0.022522199898958206,
              -0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              0,
              0.4725799858570099,
              -0.022522199898958206,
              0,
              0.4532409906387329,
              -0.023140599951148033,
              -0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              0,
              0.1385360062122345,
              0.03803170099854469,
              0,
              0.12083700299263,
              0.045849401503801346,
              -0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              0,
              0.12083700299263,
              0.045849401503801346,
              -0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              -0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              -0.22002199292182922,
              0.4919399917125702,
              -0.021792300045490265,
              -0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              -0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              -0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              -0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              -0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              -0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              -0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              -0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              -0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              -0.22002199292182922,
              0.10316000133752823,
              0.053778599947690964,
              -0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              -0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              -0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              -0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              -0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              -0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              -0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              -0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              -0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              -0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              -0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              -0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              -0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              -0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              -0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              -0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              -0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              -0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              -0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              -0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              -0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              -0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              -0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              -0.2933630049228668,
              0.14018499851226807,
              0.046517498791217804,
              -0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              -0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              -0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              -0.3165220022201538,
              0.4932210147380829,
              -0.01519909966737032,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.3165220022201538,
              0.10444100201129913,
              0.060371801257133484,
              -0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              -0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0,
              0.5159350037574768,
              -0.019741099327802658,
              0,
              0.49191299080848694,
              -0.021930299699306488,
              -0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              0,
              0.49191299080848694,
              -0.021930299699306488,
              -0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              -0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              -0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              0,
              0.10313300043344498,
              0.0536407008767128,
              -0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              0,
              0.10313300043344498,
              0.0536407008767128,
              0,
              0.08167990297079086,
              0.06466930359601974,
              -0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              -0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              -0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              -0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              -0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              -0.22002199292182922,
              0.4919399917125702,
              -0.021792300045490265,
              -0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              -0.22002199292182922,
              0.10316000133752823,
              0.053778599947690964,
              -0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              -0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              -0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              -0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              -0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.29721999168395996,
              0.5159609913825989,
              -0.019613299518823624,
              -0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              -0.29335400462150574,
              0.08170349895954132,
              0.06479089707136154,
              -0.29721999168395996,
              0.08170410245656967,
              0.06479740142822266,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.30098798871040344,
              0.5155979990959167,
              -0.019537700340151787,
              -0.29721999168395996,
              0.5159609913825989,
              -0.019613299518823624,
              -0.29721999168395996,
              0.08170410245656967,
              0.06479740142822266,
              -0.30098798871040344,
              0.0820688009262085,
              0.06473159790039062,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.30461201071739197,
              0.514519989490509,
              -0.019325200468301773,
              -0.30098798871040344,
              0.5155979990959167,
              -0.019537700340151787,
              -0.30098798871040344,
              0.0820688009262085,
              0.06473159790039062,
              -0.30461201071739197,
              0.08314819633960724,
              0.06452509760856628,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.3079429864883423,
              0.5127729773521423,
              -0.018983300775289536,
              -0.30461201071739197,
              0.514519989490509,
              -0.019325200468301773,
              -0.30461201071739197,
              0.08314819633960724,
              0.06452509760856628,
              -0.3079429864883423,
              0.0848957970738411,
              0.06418800354003906,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.3108679950237274,
              0.510420024394989,
              -0.01851790025830269,
              -0.3079429864883423,
              0.5127729773521423,
              -0.018983300775289536,
              -0.3079429864883423,
              0.0848957970738411,
              0.06418800354003906,
              -0.3108679950237274,
              0.08725090324878693,
              0.0637321025133133,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.31327199935913086,
              0.5075439810752869,
              -0.017956199124455452,
              -0.3108679950237274,
              0.510420024394989,
              -0.01851790025830269,
              -0.3108679950237274,
              0.08725090324878693,
              0.0637321025133133,
              -0.31327199935913086,
              0.09012719988822937,
              0.06317199766635895,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.3150550127029419,
              0.504269003868103,
              -0.017321500927209854,
              -0.31327199935913086,
              0.5075439810752869,
              -0.017956199124455452,
              -0.31327199935913086,
              0.09012719988822937,
              0.06317199766635895,
              -0.3150550127029419,
              0.09340210258960724,
              0.06253670156002045,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.31615298986434937,
              0.5007110238075256,
              -0.01663759909570217,
              -0.3150550127029419,
              0.504269003868103,
              -0.017321500927209854,
              -0.3150550127029419,
              0.09340210258960724,
              0.06253670156002045,
              -0.31615298986434937,
              0.09695850312709808,
              0.06184320151805878,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.3165220022201538,
              0.49702098965644836,
              -0.01592710055410862,
              -0.31615298986434937,
              0.5007110238075256,
              -0.01663759909570217,
              -0.31615298986434937,
              0.09695850312709808,
              0.06184320151805878,
              -0.3165220022201538,
              0.1006459966301918,
              0.06112020090222359,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              -0.3165220022201538,
              0.4932210147380829,
              -0.01519909966737032,
              -0.3165220022201538,
              0.49702098965644836,
              -0.01592710055410862,
              -0.3165220022201538,
              0.1006459966301918,
              0.06112020090222359,
              -0.3165220022201538,
              0.10444100201129913,
              0.060371801257133484,
              -0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              -0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              -0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              -0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              -0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              -0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              -0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              -0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              -0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              -0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              -0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              -0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              -0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              -0.3010759949684143,
              0.08690600097179413,
              0.09494239836931229,
              -0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              -0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              -0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              -0.30484798550605774,
              0.0881597027182579,
              0.09473749995231628,
              -0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              -0.30484798550605774,
              0.5211910009384155,
              0.010564999654889107,
              -0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              -0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              -0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              -0.3010759949684143,
              0.5224300026893616,
              0.010285300202667713,
              -0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              -0.31406301259994507,
              0.09542439877986908,
              0.09328660368919373,
              -0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              -0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              -0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              -0.3114379942417145,
              0.09248179942369461,
              0.09389740228652954,
              -0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              -0.3114379942417145,
              0.5168690085411072,
              0.011405100114643574,
              -0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              -0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              -0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              -0.31406301259994507,
              0.5139120221138,
              0.011941099539399147,
              -0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              -0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              -0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              -0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              -0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              -0.29300999641418457,
              0.08664199709892273,
              0.09503249824047089,
              -0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              -0.29300999641418457,
              0.5227090120315552,
              0.01027000043541193,
              -0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              -0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              -0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              -0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              -0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              -0.31406301259994507,
              0.09542439877986908,
              0.09328660368919373,
              -0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              -0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              -0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              -0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              -0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              -0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              -0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              -0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              -0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              -0.31406301259994507,
              0.5139120221138,
              0.011941099539399147,
              -0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              -0.3010759949684143,
              0.08690600097179413,
              0.09494239836931229,
              -0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              -0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              -0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              -0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              -0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              -0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              -0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              -0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              -0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              -0.3010759949684143,
              0.5224300026893616,
              0.010285300202667713,
              -0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              -0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              -0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              -0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              -0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              -0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              -0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              -0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              -0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              -0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              -0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              -0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              -0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              -0.30843299627304077,
              0.08989720046520233,
              0.09436099976301193,
              -0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              -0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              -0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              -0.3114379942417145,
              0.09248179942369461,
              0.09389740228652954,
              -0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              -0.3114379942417145,
              0.5168690085411072,
              0.011405100114643574,
              -0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              -0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              -0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              -0.30843299627304077,
              0.5194389820098877,
              0.010866699740290642,
              -0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              -0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              -0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              -0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              -0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              -0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              -0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              -0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              -0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              -0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              -0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              -0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              -0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              -0.30843299627304077,
              0.08989720046520233,
              0.09436099976301193,
              -0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              -0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              -0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              -0.30484798550605774,
              0.0881597027182579,
              0.09473749995231628,
              -0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              -0.30484798550605774,
              0.5211910009384155,
              0.010564999654889107,
              -0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              -0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              -0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              -0.30843299627304077,
              0.5194389820098877,
              0.010866699740290642,
              -0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              -0.29300999641418457,
              0.08664199709892273,
              0.09503249824047089,
              -0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              0,
              0.08664199709892273,
              0.09503249824047089,
              -0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              0,
              0.08652099967002869,
              0.0950172021985054,
              0,
              0.08664199709892273,
              0.09503249824047089,
              0,
              0.5228149890899658,
              0.010210500098764896,
              -0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              0,
              0.5227090120315552,
              0.01027000043541193,
              -0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              -0.29300999641418457,
              0.5227090120315552,
              0.01027000043541193,
              0,
              0.5227090120315552,
              0.01027000043541193,
              -0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              -0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              -0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              -0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              -0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              -0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              -0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              -0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              -0.3172299861907959,
              0.5027620196342468,
              0.014174699783325195,
              -0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              -0.3172299861907959,
              0.49879100918769836,
              0.01494670007377863,
              -0.3172299861907959,
              0.5027620196342468,
              0.014174699783325195,
              -0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              -0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              -0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              -0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              -0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              -0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              -0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              -0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              -0.31684398651123047,
              0.50661700963974,
              0.013425500132143497,
              -0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              -0.3172299861907959,
              0.5027620196342468,
              0.014174699783325195,
              -0.31684398651123047,
              0.50661700963974,
              0.013425500132143497,
              -0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              -0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              -0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              -0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              -0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              -0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              -0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              -0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              -0.31569600105285645,
              0.5103350281715393,
              0.01270269975066185,
              -0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              -0.31684398651123047,
              0.50661700963974,
              0.013425500132143497,
              -0.31569600105285645,
              0.5103350281715393,
              0.01270269975066185,
              -0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              -0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              -0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              -0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              -0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              -0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              -0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              -0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              -0.31383299827575684,
              0.5137580037117004,
              0.012037400156259537,
              -0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              -0.31569600105285645,
              0.5103350281715393,
              0.01270269975066185,
              -0.31383299827575684,
              0.5137580037117004,
              0.012037400156259537,
              -0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              -0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              -0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              -0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              -0.3114379942417145,
              0.09248179942369461,
              0.09389740228652954,
              -0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              -0.3114379942417145,
              0.5168690085411072,
              0.011405100114643574,
              -0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              -0.3113180100917816,
              0.5167660117149353,
              0.011452700011432171,
              -0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              -0.31383299827575684,
              0.5137580037117004,
              0.012037400156259537,
              -0.3113180100917816,
              0.5167660117149353,
              0.011452700011432171,
              -0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              -0.3114379942417145,
              0.09248179942369461,
              0.09389740228652954,
              -0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              -0.3114379942417145,
              0.09248179942369461,
              0.09389740228652954,
              -0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              -0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              -0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              -0.3114379942417145,
              0.5168690085411072,
              0.011405100114643574,
              -0.3082610070705414,
              0.5192270278930664,
              0.010974300093948841,
              -0.3114379942417145,
              0.5168690085411072,
              0.011405100114643574,
              -0.3113180100917816,
              0.5167660117149353,
              0.011452700011432171,
              -0.3082610070705414,
              0.5192270278930664,
              0.010974300093948841,
              -0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              -0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              -0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              -0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              -0.30484798550605774,
              0.0881597027182579,
              0.09473749995231628,
              -0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              -0.30484798550605774,
              0.5211910009384155,
              0.010564999654889107,
              -0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              -0.3047800064086914,
              0.5210530161857605,
              0.010619400069117546,
              -0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              -0.3082610070705414,
              0.5192270278930664,
              0.010974300093948841,
              -0.3047800064086914,
              0.5210530161857605,
              0.010619400069117546,
              -0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              -0.30484798550605774,
              0.0881597027182579,
              0.09473749995231628,
              -0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              -0.30484798550605774,
              0.0881597027182579,
              0.09473749995231628,
              -0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              -0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              -0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              -0.30484798550605774,
              0.5211910009384155,
              0.010564999654889107,
              -0.3009909987449646,
              0.5221800208091736,
              0.010400200262665749,
              -0.30484798550605774,
              0.5211910009384155,
              0.010564999654889107,
              -0.3047800064086914,
              0.5210530161857605,
              0.010619400069117546,
              -0.3009909987449646,
              0.5221800208091736,
              0.010400200262665749,
              -0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              -0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              -0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              -0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              -0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              -0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              -0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              -0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              -0.2970519959926605,
              0.5225610136985779,
              0.01032629981637001,
              -0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              -0.3009909987449646,
              0.5221800208091736,
              0.010400200262665749,
              -0.2970519959926605,
              0.5225610136985779,
              0.01032629981637001,
              -0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              -0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              -0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              -0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              -0.29300999641418457,
              0.08664199709892273,
              0.09503249824047089,
              -0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              -0.29300999641418457,
              0.5227090120315552,
              0.01027000043541193,
              -0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              -0.29300999641418457,
              0.5225600004196167,
              0.010326500050723553,
              -0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              -0.2970519959926605,
              0.5225610136985779,
              0.01032629981637001,
              -0.29300999641418457,
              0.5225600004196167,
              0.010326500050723553,
              -0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              -0.29300999641418457,
              0.08664199709892273,
              0.09503249824047089,
              0,
              0.08680140227079391,
              0.09502910077571869,
              -0.29300999641418457,
              0.08664199709892273,
              0.09503249824047089,
              0,
              0.08664199709892273,
              0.09503249824047089,
              0,
              0.08680140227079391,
              0.09502910077571869,
              0,
              0.5227090120315552,
              0.01027000043541193,
              -0.29300999641418457,
              0.5227090120315552,
              0.01027000043541193,
              0,
              0.5225600004196167,
              0.010326500050723553,
              -0.29300999641418457,
              0.5227090120315552,
              0.01027000043541193,
              -0.29300999641418457,
              0.5225600004196167,
              0.010326500050723553,
              0,
              0.5225600004196167,
              0.010326500050723553,
              -0.2974109947681427,
              0.11059299856424332,
              0.09052319824695587,
              -0.2974109947681427,
              0.10977199673652649,
              0.09068270027637482,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.2974109947681427,
              0.10977199673652649,
              0.09068270027637482,
              -0.29736098647117615,
              0.10915900021791458,
              0.09080199897289276,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.29736098647117615,
              0.10915900021791458,
              0.09080199897289276,
              -0.2971540093421936,
              0.10846500098705292,
              0.09093669801950455,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.2971540093421936,
              0.10846500098705292,
              0.09093669801950455,
              -0.29683101177215576,
              0.10785999894142151,
              0.09105449914932251,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.29683101177215576,
              0.10785999894142151,
              0.09105449914932251,
              -0.29633501172065735,
              0.10726600140333176,
              0.0911697968840599,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.29633501172065735,
              0.10726600140333176,
              0.0911697968840599,
              -0.2957879900932312,
              0.10683800280094147,
              0.09125299751758575,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.2957879900932312,
              0.10683800280094147,
              0.09125299751758575,
              -0.29521799087524414,
              0.10654699802398682,
              0.09130959957838058,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.29521799087524414,
              0.10654699802398682,
              0.09130959957838058,
              -0.2945069968700409,
              0.1063390001654625,
              0.09135019779205322,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.2945069968700409,
              0.1063390001654625,
              0.09135019779205322,
              -0.2937780022621155,
              0.10627400130033493,
              0.09136269986629486,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.2937780022621155,
              0.10627400130033493,
              0.09136269986629486,
              -0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              0,
              0.11059200018644333,
              0.09052109718322754,
              -0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              0,
              0.10628099739551544,
              0.09135960042476654,
              0,
              0.11059200018644333,
              0.09052109718322754,
              -0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              -0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              -0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              -0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              -0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              -0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              -0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              -0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              -0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              -0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              -0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              -0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              -0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              -0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              -0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              -0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              -0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              -0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              -0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              -0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              -0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              -0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              -0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              -0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              -0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              -0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              -0.3010759949684143,
              0.08690600097179413,
              0.09494239836931229,
              -0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              -0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              -0.3010759949684143,
              0.08690600097179413,
              0.09494239836931229,
              -0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              -0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              -0.3010759949684143,
              0.5224300026893616,
              0.010285300202667713,
              -0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              -0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              -0.3010759949684143,
              0.5224300026893616,
              0.010285300202667713,
              -0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              -0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              -0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              -0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              -0.3010759949684143,
              0.08624079823493958,
              0.09152019768953323,
              -0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              -0.3010759949684143,
              0.5217649936676025,
              0.006863050162792206,
              -0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              -0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              -0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              -0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              -0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              -0.31406301259994507,
              0.09542439877986908,
              0.09328660368919373,
              -0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              -0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              -0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              -0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              -0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              -0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              -0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              -0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              -0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              -0.31406301259994507,
              0.5139120221138,
              0.011941099539399147,
              -0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              -0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              -0.31406301259994507,
              0.09475920349359512,
              0.08986440300941467,
              -0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              -0.31406301259994507,
              0.09475920349359512,
              0.08986440300941467,
              -0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              -0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              -0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              -0.31406301259994507,
              0.513247013092041,
              0.008518850430846214,
              -0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              -0.31406301259994507,
              0.513247013092041,
              0.008518850430846214,
              -0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              -0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              -0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              -0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              -0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              -0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              -0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              -0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              -0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              -0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              -0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              -0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              -0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              -0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              -0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              -0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              -0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              -0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              -0.29300999641418457,
              0.08585579693317413,
              0.09159500151872635,
              -0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              -0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              -0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              -0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              -0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              -0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              -0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              -0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              -0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              -0.31406301259994507,
              0.09542439877986908,
              0.09328660368919373,
              -0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              -0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              -0.31406301259994507,
              0.09542439877986908,
              0.09328660368919373,
              -0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              -0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              -0.31406301259994507,
              0.5139120221138,
              0.011941099539399147,
              -0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              -0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              -0.31406301259994507,
              0.5139120221138,
              0.011941099539399147,
              -0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              -0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              -0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              -0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              -0.31406301259994507,
              0.09475920349359512,
              0.08986440300941467,
              -0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              -0.31406301259994507,
              0.513247013092041,
              0.008518850430846214,
              -0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              -0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              -0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              -0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              -0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              -0.3010759949684143,
              0.08690600097179413,
              0.09494239836931229,
              -0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              -0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              -0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              -0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              -0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              -0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              -0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              -0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              -0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              -0.3010759949684143,
              0.5224300026893616,
              0.010285300202667713,
              -0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              -0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              -0.3010759949684143,
              0.08624079823493958,
              0.09152019768953323,
              -0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              -0.3010759949684143,
              0.08624079823493958,
              0.09152019768953323,
              -0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              -0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              -0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              -0.3010759949684143,
              0.5217649936676025,
              0.006863050162792206,
              -0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              -0.3010759949684143,
              0.5217649936676025,
              0.006863050162792206,
              -0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              -0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              -0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              -0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              -0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              -0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              -0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              -0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              -0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              -0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              -0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              -0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              -0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              -0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              -0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              -0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              -0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              -0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              -0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              -0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              -0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              -0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              -0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              -0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              -0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              -0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              -0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              -0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              -0.30843299627304077,
              0.08989720046520233,
              0.09436099976301193,
              -0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              -0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              -0.30843299627304077,
              0.08989720046520233,
              0.09436099976301193,
              -0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              -0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              -0.30843299627304077,
              0.5194389820098877,
              0.010866699740290642,
              -0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              -0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              -0.30843299627304077,
              0.5194389820098877,
              0.010866699740290642,
              -0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              -0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              -0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              -0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              -0.30843299627304077,
              0.08923199772834778,
              0.09093870222568512,
              -0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              -0.30843299627304077,
              0.5187739729881287,
              0.007444479968398809,
              -0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              -0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              -0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              -0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              -0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              -0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              -0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              -0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              -0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              -0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              -0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              -0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              -0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              -0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              -0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              -0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              -0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              -0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              -0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              -0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              -0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              -0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              -0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              -0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              -0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              -0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              -0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              -0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              -0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              -0.30843299627304077,
              0.08989720046520233,
              0.09436099976301193,
              -0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              -0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              -0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              -0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              -0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              -0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              -0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              -0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              -0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              -0.30843299627304077,
              0.5194389820098877,
              0.010866699740290642,
              -0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              -0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              -0.30843299627304077,
              0.08923199772834778,
              0.09093870222568512,
              -0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              -0.30843299627304077,
              0.08923199772834778,
              0.09093870222568512,
              -0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              -0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              -0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              -0.30843299627304077,
              0.5187739729881287,
              0.007444479968398809,
              -0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              -0.30843299627304077,
              0.5187739729881287,
              0.007444479968398809,
              -0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              -0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              -0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              -0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              0,
              0.08652099967002869,
              0.0950172021985054,
              -0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              0,
              0.08647610247135162,
              0.09478580206632614,
              0,
              0.08652099967002869,
              0.0950172021985054,
              0,
              0.5227699875831604,
              0.009979059919714928,
              -0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              0,
              0.5228149890899658,
              0.010210500098764896,
              -0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              -0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              0,
              0.5228149890899658,
              0.010210500098764896,
              -0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              -0.29300999641418457,
              0.08585579693317413,
              0.09159500151872635,
              0,
              0.08647610247135162,
              0.09478580206632614,
              -0.29300999641418457,
              0.08585579693317413,
              0.09159500151872635,
              0,
              0.08585579693317413,
              0.09159500151872635,
              0,
              0.08647610247135162,
              0.09478580206632614,
              0,
              0.5221499800682068,
              0.006788210012018681,
              -0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              0,
              0.5227699875831604,
              0.009979059919714928,
              -0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              -0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              0,
              0.5227699875831604,
              0.009979059919714928,
              -0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              -0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              -0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              -0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              -0.3052479922771454,
              0.08288119733333588,
              0.06815049797296524,
              -0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              -0.3052479922771454,
              0.5164049863815308,
              -0.016117800027132034,
              -0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              -0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              -0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              -0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              -0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              -0.30877599120140076,
              0.08420459926128387,
              0.06507500261068344,
              -0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              -0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              -0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              -0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              -0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              -0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              -0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              -0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              -0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              -0.30877599120140076,
              0.5143060088157654,
              -0.018528100103139877,
              -0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              -0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              -0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              -0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              -0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              -0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              -0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              -0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              -0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              -0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              -0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              -0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              -0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              -0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              -0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              -0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              -0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              -0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              -0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              -0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              -0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              -0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              -0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              -0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              -0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              -0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              -0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              -0.31186801195144653,
              0.08722390234470367,
              0.06730639934539795,
              -0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              -0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              -0.31186801195144653,
              0.08722390234470367,
              0.06730639934539795,
              -0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              -0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              -0.31186801195144653,
              0.5120630264282227,
              -0.015273699536919594,
              -0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              -0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              -0.31186801195144653,
              0.5120630264282227,
              -0.015273699536919594,
              -0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              -0.30877599120140076,
              0.08420459926128387,
              0.06507500261068344,
              -0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              -0.30877599120140076,
              0.08420459926128387,
              0.06507500261068344,
              -0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              -0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              -0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              -0.30877599120140076,
              0.5143060088157654,
              -0.018528100103139877,
              -0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              -0.30877599120140076,
              0.5143060088157654,
              -0.018528100103139877,
              -0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              -0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              -0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              -0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              -0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              -0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              -0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              -0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              -0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              -0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              -0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              -0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              -0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              -0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              -0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              -0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              -0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              -0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              -0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              -0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              -0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              -0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              -0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              -0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              -0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              -0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              -0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              -0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              -0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              -0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              -0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              -0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              -0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              -0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              -0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              -0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              -0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              -0.3014189898967743,
              0.08121339976787567,
              0.0656564012169838,
              -0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              -0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              -0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              -0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              -0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              -0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              -0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              -0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              -0.3014189898967743,
              0.5172979831695557,
              -0.01910950057208538,
              -0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              -0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              -0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              -0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              -0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              -0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              -0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              -0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              -0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              -0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              -0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              -0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              -0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              -0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              -0.31440600752830505,
              0.08973179757595062,
              0.06400059908628464,
              -0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              -0.31440600752830505,
              0.08973179757595062,
              0.06400059908628464,
              -0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              -0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              -0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              -0.31440600752830505,
              0.508778989315033,
              -0.017453700304031372,
              -0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              -0.31440600752830505,
              0.508778989315033,
              -0.017453700304031372,
              -0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              -0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              -0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              -0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              -0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              -0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              -0.2931390106678009,
              0.08135630190372467,
              0.06844689697027206,
              -0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              -0.29335299134254456,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              -0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              -0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              -0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              -0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              -0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              -0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              -0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              -0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              -0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              -0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              -0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              -0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              -0.31186801195144653,
              0.08722390234470367,
              0.06730639934539795,
              -0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              -0.31186801195144653,
              0.5120630264282227,
              -0.015273699536919594,
              -0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              -0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              -0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              -0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              -0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              -0.31440600752830505,
              0.08973179757595062,
              0.06400059908628464,
              -0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              -0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              -0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              -0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              -0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              -0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              -0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              -0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              -0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              -0.31440600752830505,
              0.508778989315033,
              -0.017453700304031372,
              -0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              -0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              -0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              -0.3052479922771454,
              0.08288119733333588,
              0.06815049797296524,
              -0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              -0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              -0.3052479922771454,
              0.08288119733333588,
              0.06815049797296524,
              -0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              -0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              -0.3052479922771454,
              0.5164049863815308,
              -0.016117800027132034,
              -0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              -0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              -0.3052479922771454,
              0.5164049863815308,
              -0.016117800027132034,
              -0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              -0.3014189898967743,
              0.08121339976787567,
              0.0656564012169838,
              -0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              -0.3014189898967743,
              0.08121339976787567,
              0.0656564012169838,
              -0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              -0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              -0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              -0.3014189898967743,
              0.5172979831695557,
              -0.01910950057208538,
              -0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              -0.3014189898967743,
              0.5172979831695557,
              -0.01910950057208538,
              -0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              -0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              -0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              -0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              -0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              -0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              -0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              -0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              -0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              -0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              -0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              -0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              -0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              -0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              -0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              -0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              -0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              -0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              -0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              -0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              -0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              -0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              -0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              -0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              -0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              -0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              -0.30877599120140076,
              0.08420459926128387,
              0.06507500261068344,
              -0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              -0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              -0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              -0.3051750063896179,
              0.08239900320768356,
              0.06493859738111496,
              -0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              -0.3051750063896179,
              0.5159289836883545,
              -0.01933090016245842,
              -0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              -0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              -0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              -0.30877599120140076,
              0.5143060088157654,
              -0.018528100103139877,
              -0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              -0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              -0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              -0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              -0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              -0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              -0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              -0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              -0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              -0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              -0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              -0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              -0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              -0.30877599120140076,
              0.08420459926128387,
              0.06507500261068344,
              -0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              -0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              -0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              -0.3117560148239136,
              0.0867151990532875,
              0.06409960240125656,
              -0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              -0.3117560148239136,
              0.5116130113601685,
              -0.018491899594664574,
              -0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              -0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              -0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              -0.30877599120140076,
              0.5143060088157654,
              -0.018528100103139877,
              -0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              -0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              -0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              -0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              -0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              -0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              -0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              -0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              -0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              -0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              -0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              -0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              -0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              -0.3014189898967743,
              0.08121339976787567,
              0.0656564012169838,
              -0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              -0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              -0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              -0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              -0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              -0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              -0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              -0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              -0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              -0.3014189898967743,
              0.5172979831695557,
              -0.01910950057208538,
              -0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              -0.31440600752830505,
              0.08973179757595062,
              0.06400059908628464,
              -0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              -0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              -0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              -0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              -0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              -0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              -0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              -0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              -0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              -0.31440600752830505,
              0.508778989315033,
              -0.017453700304031372,
              -0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              -0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              -0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              -0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              -0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              -0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              -0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              -0.29335299134254456,
              0.5174450278282166,
              -0.019625499844551086,
              -0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              -0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              -0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              -0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              -0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              -0.31440600752830505,
              0.08973179757595062,
              0.06400059908628464,
              -0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              -0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              -0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              -0.3117560148239136,
              0.0867151990532875,
              0.06409960240125656,
              -0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              -0.3117560148239136,
              0.5116130113601685,
              -0.018491899594664574,
              -0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              -0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              -0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              -0.31440600752830505,
              0.508778989315033,
              -0.017453700304031372,
              -0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              -0.3014189898967743,
              0.08121339976787567,
              0.0656564012169838,
              -0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              -0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              -0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              -0.3051750063896179,
              0.08239900320768356,
              0.06493859738111496,
              -0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              -0.3051750063896179,
              0.5159289836883545,
              -0.01933090016245842,
              -0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              -0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              -0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              -0.3014189898967743,
              0.5172979831695557,
              -0.01910950057208538,
              -0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              -0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              -0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              -0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              -0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              -0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              -0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              -0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              -0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              -0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              -0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              -0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              -0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              -0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              -0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              -0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              -0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              -0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              -0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              -0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              -0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              -0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              -0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              -0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              -0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              -0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              -0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              -0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              -0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              -0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              -0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              -0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              -0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              -0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              -0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              -0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              -0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              -0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              -0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              -0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              -0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              -0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              -0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              -0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              -0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              -0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              -0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              -0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              -0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              -0.31406301259994507,
              0.09475920349359512,
              0.08986440300941467,
              -0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              -0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              -0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              -0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              -0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              -0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              -0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              -0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              -0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              -0.31406301259994507,
              0.513247013092041,
              0.008518850430846214,
              -0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              -0.31406301259994507,
              0.09475920349359512,
              0.08986440300941467,
              -0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              -0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              -0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              -0.31186801195144653,
              0.08722390234470367,
              0.06730639934539795,
              -0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              -0.31186801195144653,
              0.5120630264282227,
              -0.015273699536919594,
              -0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              -0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              -0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              -0.31406301259994507,
              0.513247013092041,
              0.008518850430846214,
              -0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              -0.30843299627304077,
              0.08923199772834778,
              0.09093870222568512,
              -0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              -0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              -0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              -0.31186801195144653,
              0.08722390234470367,
              0.06730639934539795,
              -0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              -0.31186801195144653,
              0.5120630264282227,
              -0.015273699536919594,
              -0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              -0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              -0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              -0.30843299627304077,
              0.5187739729881287,
              0.007444479968398809,
              -0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              -0.30843299627304077,
              0.08923199772834778,
              0.09093870222568512,
              -0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              -0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              -0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              -0.3052479922771454,
              0.08288119733333588,
              0.06815049797296524,
              -0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              -0.3052479922771454,
              0.5164049863815308,
              -0.016117800027132034,
              -0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              -0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              -0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              -0.30843299627304077,
              0.5187739729881287,
              0.007444479968398809,
              -0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              -0.3010759949684143,
              0.08624079823493958,
              0.09152019768953323,
              -0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              -0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              -0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              -0.3052479922771454,
              0.08288119733333588,
              0.06815049797296524,
              -0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              -0.3052479922771454,
              0.5164049863815308,
              -0.016117800027132034,
              -0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              -0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              -0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              -0.3010759949684143,
              0.5217649936676025,
              0.006863050162792206,
              -0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              -0.3010759949684143,
              0.08624079823493958,
              0.09152019768953323,
              -0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              -0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              -0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              -0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              -0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              -0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              -0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              -0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              -0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              -0.3010759949684143,
              0.5217649936676025,
              0.006863050162792206,
              -0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              -0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              -0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              -0.29300999641418457,
              0.08585579693317413,
              0.09159500151872635,
              -0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              -0.2931390106678009,
              0.08135630190372467,
              0.06844689697027206,
              -0.29300999641418457,
              0.08585579693317413,
              0.09159500151872635,
              -0.29335299134254456,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              -0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              -0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              -0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              -0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              -0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              -0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              -0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              -0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              -0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              -0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              -0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              -0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              -0.2937780022621155,
              0.10627400130033493,
              0.09136269986629486,
              -0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              -0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              -0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              -0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              -0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              -0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              -0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              -0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              -0.2937780022621155,
              0.10627400130033493,
              0.09136269986629486,
              -0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              -0.2937780022621155,
              0.10627400130033493,
              0.09136269986629486,
              -0.2945069968700409,
              0.1063390001654625,
              0.09135019779205322,
              -0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              -0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              -0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              -0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              -0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              -0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              -0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              -0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              -0.2945069968700409,
              0.1063390001654625,
              0.09135019779205322,
              -0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              -0.2945069968700409,
              0.1063390001654625,
              0.09135019779205322,
              -0.29521799087524414,
              0.10654699802398682,
              0.09130959957838058,
              -0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              -0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              -0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              -0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              -0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              -0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              -0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              -0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              -0.29521799087524414,
              0.10654699802398682,
              0.09130959957838058,
              -0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              -0.29521799087524414,
              0.10654699802398682,
              0.09130959957838058,
              -0.2957879900932312,
              0.10683800280094147,
              0.09125299751758575,
              -0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              -0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              -0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              -0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              -0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              -0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              -0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              -0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              -0.2957879900932312,
              0.10683800280094147,
              0.09125299751758575,
              -0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              -0.2957879900932312,
              0.10683800280094147,
              0.09125299751758575,
              -0.29633501172065735,
              0.10726600140333176,
              0.0911697968840599,
              -0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              -0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              -0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              -0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              -0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              -0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              -0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              -0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              -0.29633501172065735,
              0.10726600140333176,
              0.0911697968840599,
              -0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              -0.29633501172065735,
              0.10726600140333176,
              0.0911697968840599,
              -0.29683101177215576,
              0.10785999894142151,
              0.09105449914932251,
              -0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              -0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              -0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              -0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              -0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              -0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              -0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              -0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              -0.29683101177215576,
              0.10785999894142151,
              0.09105449914932251,
              -0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              -0.29683101177215576,
              0.10785999894142151,
              0.09105449914932251,
              -0.2971540093421936,
              0.10846500098705292,
              0.09093669801950455,
              -0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              -0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              -0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              -0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              -0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              -0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              -0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              -0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              -0.2971540093421936,
              0.10846500098705292,
              0.09093669801950455,
              -0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              -0.2971540093421936,
              0.10846500098705292,
              0.09093669801950455,
              -0.29736098647117615,
              0.10915900021791458,
              0.09080199897289276,
              -0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              -0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              -0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              -0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              -0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              -0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              -0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              -0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              -0.29736098647117615,
              0.10915900021791458,
              0.09080199897289276,
              -0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              -0.29736098647117615,
              0.10915900021791458,
              0.09080199897289276,
              -0.2974109947681427,
              0.10977199673652649,
              0.09068270027637482,
              -0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              -0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              -0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              -0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              -0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              -0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              -0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              -0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              -0.2974109947681427,
              0.10977199673652649,
              0.09068270027637482,
              -0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              -0.2974109947681427,
              0.10977199673652649,
              0.09068270027637482,
              -0.2974109947681427,
              0.11059299856424332,
              0.09052319824695587,
              -0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              -0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              -0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              0,
              0.10628099739551544,
              0.09135960042476654,
              -0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              0,
              0.08729659765958786,
              0.09493879973888397,
              0,
              0.10628099739551544,
              0.09135960042476654,
              -0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              -0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              0,
              0.08729659765958786,
              0.09493879973888397,
              -0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              0,
              0.08680140227079391,
              0.09502910077571869,
              0,
              0.08729659765958786,
              0.09493879973888397,
              -0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              -0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              -0.2200150042772293,
              0.08135630190372467,
              0.06844689697027206,
              -0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              -0.14667700231075287,
              0.08135630190372467,
              0.06844689697027206,
              -0.2200150042772293,
              0.08135630190372467,
              0.06844689697027206,
              -0.14667700231075287,
              0.5179299712181091,
              -0.016414200887084007,
              -0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              -0.2200150042772293,
              0.5179299712181091,
              -0.016414200887084007,
              -0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              -0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              -0.2200150042772293,
              0.5179299712181091,
              -0.016414200887084007,
              -0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              -0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              -0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              -0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              -0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              -0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              -0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              -0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              -0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              -0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              -0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              -0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              -0.2200150042772293,
              0.08088339865207672,
              0.06523320078849792,
              -0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              -0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              -0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              -0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              -0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              -0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              -0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              -0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              -0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              -0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              -0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              -0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              0,
              0.08098050206899643,
              0.06651370227336884,
              -0.14667700231075287,
              0.08135630190372467,
              0.06844689697027206,
              0,
              0.08098050206899643,
              0.06651370227336884,
              0,
              0.08135630190372467,
              0.06844689697027206,
              -0.14667700231075287,
              0.08135630190372467,
              0.06844689697027206,
              0,
              0.5179299712181091,
              -0.016414200887084007,
              0,
              0.5178350210189819,
              -0.018401900306344032,
              -0.14667700231075287,
              0.5179299712181091,
              -0.016414200887084007,
              0,
              0.5178350210189819,
              -0.018401900306344032,
              -0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              -0.14667700231075287,
              0.5179299712181091,
              -0.016414200887084007,
              -0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              0,
              0.08082839846611023,
              0.06573119759559631,
              -0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              0,
              0.08082839846611023,
              0.06573119759559631,
              0,
              0.08098050206899643,
              0.06651370227336884,
              -0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              0,
              0.5178350210189819,
              -0.018401900306344032,
              0,
              0.5176820158958435,
              -0.019184399396181107,
              -0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              0,
              0.5176820158958435,
              -0.019184399396181107,
              -0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              -0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              -0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              0,
              0.08088330179452896,
              0.06523320078849792,
              -0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              0,
              0.08088330179452896,
              0.06523320078849792,
              0,
              0.08082839846611023,
              0.06573119759559631,
              -0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              0,
              0.5176820158958435,
              -0.019184399396181107,
              0,
              0.5174450278282166,
              -0.019625499844551086,
              -0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              0,
              0.5174450278282166,
              -0.019625499844551086,
              -0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              -0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              -0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              -0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              -0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              -0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              -0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              -0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              -0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              -0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              -0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              -0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              -0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              -0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              -0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              -0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              -0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              -0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              -0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              -0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              -0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              -0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              -0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              -0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              -0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              -0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              -0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              -0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              -0.3172299861907959,
              0.14760799705982208,
              0.08320949971675873,
              -0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              -0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              -0.3172299861907959,
              0.14760799705982208,
              0.08320949971675873,
              -0.3172299861907959,
              0.48027199506759644,
              0.01854640059173107,
              -0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              -0.3172299861907959,
              0.4617530107498169,
              0.02214599959552288,
              -0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              -0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              -0.3172299861907959,
              0.4617530107498169,
              0.02214599959552288,
              -0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              -0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              -0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              -0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              -0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              -0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              -0.3172299861907959,
              0.49879100918769836,
              0.01494670007377863,
              -0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              -0.3172299861907959,
              0.48027199506759644,
              0.01854640059173107,
              -0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              -0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              -0.3172299861907959,
              0.48027199506759644,
              0.01854640059173107,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              -0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              -0.2974109947681427,
              0.11059299856424332,
              0.09052319824695587,
              -0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              -0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              -0.2974109947681427,
              0.11059299856424332,
              0.09052319824695587,
              -0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              -0.29302000999450684,
              0.1476300060749054,
              0.08332400023937225,
              -0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              -0.29302000999450684,
              0.1476300060749054,
              0.08332400023937225,
              -0.2974109947681427,
              0.1476300060749054,
              0.08332400023937225,
              -0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              0,
              0.11059200018644333,
              0.09052109718322754,
              0,
              0.12911100685596466,
              0.08692140132188797,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0,
              0.12911100685596466,
              0.08692140132188797,
              -0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              -0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0,
              0.12911100685596466,
              0.08692140132188797,
              0,
              0.1476300060749054,
              0.08332180231809616,
              -0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              0,
              0.1476300060749054,
              0.08332180231809616,
              -0.29302000999450684,
              0.1476300060749054,
              0.08332400023937225,
              -0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              -0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              -0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              -0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              -0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              -0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              -0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              -0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              -0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              -0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              -0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              -0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              -0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              -0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              -0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              -0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              -0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              -0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              -0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              -0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              -0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              -0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              -0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              -0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              -0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              -0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              -0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              -0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              -0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              -0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              -0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              -0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              -0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              -0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              -0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              -0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              -0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              -0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              -0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              -0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              -0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              -0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              -0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              -0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              -0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              -0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              -0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              -0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              -0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              -0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              -0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              -0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              -0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              -0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              -0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              -0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              -0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              -0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              -0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              -0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              -0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              -0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              -0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              -0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              -0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              -0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              -0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              -0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              -0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              -0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              -0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              -0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              -0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              -0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              -0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              -0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              -0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              -0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              -0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              -0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              -0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              -0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              -0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              -0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              -0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              -0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              -0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              -0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              -0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              -0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              -0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              -0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              -0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              -0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              -0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              -0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              -0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              -0.3176969885826111,
              0.14181099832057953,
              0.053390100598335266,
              -0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              -0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              -0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              -0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              -0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              -0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              -0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              -0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              -0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              -0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              -0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              -0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              -0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              -0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              -0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              -0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              -0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              -0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              -0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              -0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              -0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              -0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              -0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              -0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              -0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              -0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              -0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              -0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              -0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              -0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              -0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              -0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              -0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              -0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              -0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              -0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              -0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              -0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              -0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              -0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              -0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              -0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              -0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              -0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              -0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              -0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              -0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              -0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              -0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              -0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              -0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              -0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              -0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              -0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              -0.3167259991168976,
              0.14760899543762207,
              0.08321540057659149,
              -0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              -0.3167259991168976,
              0.14760899543762207,
              0.08321540057659149,
              -0.3172299861907959,
              0.14760799705982208,
              0.08320949971675873,
              -0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              -0.2974109947681427,
              0.11059299856424332,
              0.09052319824695587,
              -0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              -0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              -0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              -0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              -0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              -0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              -0.2974109947681427,
              0.1476300060749054,
              0.08332400023937225,
              -0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              -0.2974109947681427,
              0.1476300060749054,
              0.08332400023937225,
              -0.3167259991168976,
              0.14760899543762207,
              0.08321540057659149,
              -0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              -0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              -0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              -0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              -0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              -0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              -0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              -0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              -0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              -0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              -0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              -0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              -0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              -0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              -0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              -0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              -0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              -0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              -0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              -0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              -0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              -0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              -0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              -0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              -0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              -0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              -0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              -0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              -0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              -0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              -0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              -0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              -0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              -0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              -0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              -0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              -0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              -0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              -0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              -0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              -0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              -0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              -0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              -0.31784600019454956,
              0.181768998503685,
              0.04892880097031593,
              -0.31784600019454956,
              0.22082699835300446,
              0.041336700320243835,
              -0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              -0.31784600019454956,
              0.22082699835300446,
              0.041336700320243835,
              -0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              -0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              -0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              -0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              -0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              -0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              -0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              -0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              -0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              -0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              -0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              -0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              -0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              -0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              -0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              -0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              -0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              -0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              -0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              -0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              -0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              -0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              -0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              -0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              -0.3176969885826111,
              0.2991639971733093,
              0.02280390076339245,
              -0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              -0.3176969885826111,
              0.2991639971733093,
              0.02280390076339245,
              -0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              -0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              -0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              -0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              -0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              -0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              -0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              -0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              -0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              -0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              -0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              -0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              -0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              -0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              -0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              -0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              -0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              -0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              -0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              -0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              -0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              -0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              -0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              -0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              -0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              -0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              -0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              -0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              -0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              -0.31784600019454956,
              0.22082699835300446,
              0.041336700320243835,
              -0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              -0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              -0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              -0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              -0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              -0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              -0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              -0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              -0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              -0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              -0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              -0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              -0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              -0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              -0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              -0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              -0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              -0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              -0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              -0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              -0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              -0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              -0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              -0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              -0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              -0.2931390106678009,
              0.08135630190372467,
              0.06844689697027206,
              -0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              -0.2200150042772293,
              0.08135630190372467,
              0.06844689697027206,
              -0.2931390106678009,
              0.08135630190372467,
              0.06844689697027206,
              -0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              -0.2200150042772293,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29335299134254456,
              0.5179299712181091,
              -0.016414200887084007,
              -0.2200150042772293,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              -0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              -0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              -0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              -0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              -0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              -0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              -0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              -0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              -0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              -0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              -0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              -0.2200150042772293,
              0.08088339865207672,
              0.06523320078849792,
              -0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              -0.2200150042772293,
              0.08088339865207672,
              0.06523320078849792,
              -0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              -0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              -0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              -0.29335299134254456,
              0.5174450278282166,
              -0.019625499844551086,
              -0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              -0.29335299134254456,
              0.5174450278282166,
              -0.019625499844551086,
              -0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              -0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              -0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              -0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              -0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              -0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              -0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              -0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              -0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              -0.3172299861907959,
              0.18694600462913513,
              0.07556299865245819,
              -0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              -0.3172299861907959,
              0.18694600462913513,
              0.07556299865245819,
              -0.3172299861907959,
              0.22623899579048157,
              0.06768500059843063,
              -0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              -0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              -0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              -0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              -0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              -0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              -0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              -0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              -0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              -0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              -0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              -0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              -0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              -0.31784600019454956,
              0.181768998503685,
              0.04892880097031593,
              -0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              -0.31784600019454956,
              0.22078199684619904,
              0.04110519960522652,
              -0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              -0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              -0.31784600019454956,
              0.22078199684619904,
              0.04110519960522652,
              -0.31784600019454956,
              0.22078199684619904,
              0.04110519960522652,
              -0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              -0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              -0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              -0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              -0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              -0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              -0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              -0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              -0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              -0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              -0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              -0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              -0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              -0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              -0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              -0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              -0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              -0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              -0.3172299861907959,
              0.22623899579048157,
              0.06768500059843063,
              -0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              -0.3172299861907959,
              0.22623899579048157,
              0.06768500059843063,
              -0.3172299861907959,
              0.26562198996543884,
              0.06026989966630936,
              -0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              -0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              -0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              -0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              -0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              -0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              -0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              -0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              -0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              -0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              -0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              -0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              -0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              -0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              -0.3172299861907959,
              0.26562198996543884,
              0.06026989966630936,
              -0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              -0.3172299861907959,
              0.26562198996543884,
              0.06026989966630936,
              -0.3172299861907959,
              0.3049600124359131,
              0.05262339860200882,
              -0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              -0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              -0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              -0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              -0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              -0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              -0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              -0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              -0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              -0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              -0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              -0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              -0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              -0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              -0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              -0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              -0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              -0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              -0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              -0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              -0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              -0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              -0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              -0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              -0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              -0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              -0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              -0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              -0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              -0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              -0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              -0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              -0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              -0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              -0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              -0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              -0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              -0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              -0.3172299861907959,
              0.3049600124359131,
              0.05262339860200882,
              -0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              -0.3172299861907959,
              0.3049600124359131,
              0.05262339860200882,
              -0.3172299861907959,
              0.34429898858070374,
              0.04497680068016052,
              -0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              -0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              -0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              -0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              -0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              -0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              -0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              -0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              -0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              -0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              -0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              -0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              -0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              -0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              -0.3172299861907959,
              0.34429898858070374,
              0.04497680068016052,
              -0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              -0.3172299861907959,
              0.34429898858070374,
              0.04497680068016052,
              -0.3172299861907959,
              0.383637011051178,
              0.037330299615859985,
              -0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              -0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              -0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              -0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              -0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              -0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              -0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              -0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              -0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              -0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              -0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              -0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              -0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              -0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              -0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              -0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              -0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              -0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              -0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              -0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              -0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              -0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              -0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              -0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              -0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              -0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              -0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              -0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              -0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              -0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              -0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              -0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              -0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              -0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              -0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              -0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              -0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              -0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              -0.3172299861907959,
              0.383637011051178,
              0.037330299615859985,
              -0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              -0.3172299861907959,
              0.383637011051178,
              0.037330299615859985,
              -0.3172299861907959,
              0.42297500371932983,
              0.02968369983136654,
              -0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              -0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              -0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              -0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              -0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              -0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              -0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              -0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              -0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              -0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              -0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              -0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              -0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              -0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              -0.3172299861907959,
              0.42297500371932983,
              0.02968369983136654,
              -0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              -0.3172299861907959,
              0.42297500371932983,
              0.02968369983136654,
              -0.3172299861907959,
              0.4617530107498169,
              0.02214599959552288,
              -0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              -0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              -0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              -0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              -0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              -0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              -0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              -0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              -0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              -0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              -0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              -0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              -0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              -0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              -0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              -0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              -0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              -0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              -0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              -0.2933630049228668,
              0.14018499851226807,
              0.046517498791217804,
              -0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              -0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              -0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              -0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              -0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              -0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              -0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              -0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              -0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              -0.22002199292182922,
              0.17710499465465546,
              0.0264274999499321,
              -0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              -0.3165220022201538,
              0.18078899383544922,
              0.04538070037961006,
              -0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              -0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              -0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              -0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              -0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              -0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              -0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              -0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              -0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              -0.3176969885826111,
              0.14181099832057953,
              0.053390100598335266,
              -0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              0,
              0.17633700370788574,
              0.022480299696326256,
              0,
              0.15932700037956238,
              0.029478399083018303,
              -0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              0,
              0.15932700037956238,
              0.029478399083018303,
              -0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              -0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              0,
              0.15932700037956238,
              0.029478399083018303,
              0,
              0.1385360062122345,
              0.03803170099854469,
              -0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              0,
              0.1385360062122345,
              0.03803170099854469,
              -0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              -0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              -0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              -0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              -0.22002199292182922,
              0.17710499465465546,
              0.0264274999499321,
              -0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              -0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              -0.22002199292182922,
              0.17710499465465546,
              0.0264274999499321,
              -0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              -0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              -0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              -0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              -0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              -0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              -0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              -0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              -0.2933630049228668,
              0.14018499851226807,
              0.046517498791217804,
              -0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              -0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              -0.2933630049228668,
              0.14018499851226807,
              0.046517498791217804,
              -0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              -0.3165220022201538,
              0.18078899383544922,
              0.04538070037961006,
              -0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              -0.3165220022201538,
              0.18078899383544922,
              0.04538070037961006,
              -0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              -0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              -0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              -0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              -0.3176969885826111,
              0.14181099832057953,
              0.053390100598335266,
              -0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              -0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              -0.3176969885826111,
              0.14181099832057953,
              0.053390100598335266,
              -0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              -0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              -0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              -0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              -0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              -0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              -0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              -0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              -0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              -0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              -0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              -0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              -0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              -0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              -0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              -0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              -0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              -0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              -0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              -0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              -0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              -0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              -0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              -0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              -0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              -0.31784600019454956,
              0.181768998503685,
              0.04892880097031593,
              -0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              -0.31784600019454956,
              0.181768998503685,
              0.04892880097031593,
              -0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              -0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              -0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              -0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              -0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              -0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              -0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              -0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              -0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              -0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              -0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              -0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              -0.31784600019454956,
              0.181768998503685,
              0.04892880097031593,
              -0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              -0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              -0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              -0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              -0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              -0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              -0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              -0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              -0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              -0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              -0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              -0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              -0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              -0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              -0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              -0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              -0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              -0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              -0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              -0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              -0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              -0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              -0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              -0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              -0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              -0.3172299861907959,
              0.14760799705982208,
              0.08320949971675873,
              -0.3172299861907959,
              0.16924400627613068,
              0.07900390028953552,
              -0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              -0.3172299861907959,
              0.16924400627613068,
              0.07900390028953552,
              -0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              -0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              -0.3172299861907959,
              0.16924400627613068,
              0.07900390028953552,
              -0.3172299861907959,
              0.18694600462913513,
              0.07556299865245819,
              -0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              -0.3172299861907959,
              0.18694600462913513,
              0.07556299865245819,
              -0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              -0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              -0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              -0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              -0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              -0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              -0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              -0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              -0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              -0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              -0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              -0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              -0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              -0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              -0.29335299134254456,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              -0.2201859951019287,
              0.5179299712181091,
              -0.016414200887084007,
              -0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              -0.21984300017356873,
              0.5221499800682068,
              0.006788210012018681,
              -0.2201859951019287,
              0.5179299712181091,
              -0.016414200887084007,
              -0.2201859951019287,
              0.5179299712181091,
              -0.016414200887084007,
              -0.21984300017356873,
              0.5221499800682068,
              0.006788210012018681,
              -0.14684799313545227,
              0.5179299712181091,
              -0.016414200887084007,
              -0.21984300017356873,
              0.5221499800682068,
              0.006788210012018681,
              -0.14650499820709229,
              0.5221499800682068,
              0.006788210012018681,
              -0.14684799313545227,
              0.5179299712181091,
              -0.016414200887084007,
              -0.14684799313545227,
              0.5179299712181091,
              -0.016414200887084007,
              -0.14650499820709229,
              0.5221499800682068,
              0.006788210012018681,
              -0.00017157199908979237,
              0.5179299712181091,
              -0.016414200887084007,
              -0.14650499820709229,
              0.5221499800682068,
              0.006788210012018681,
              0,
              0.5221499800682068,
              0.006788210012018681,
              -0.00017157199908979237,
              0.5179299712181091,
              -0.016414200887084007,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              0.0853922963142395,
              0.0011095399968326092,
              -0.06915389746427536,
              0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              0.07833889871835709,
              0.04159890115261078,
              -0.09128779917955399,
              0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              0.0853922963142395,
              0.0011095399968326092,
              -0.06915389746427536,
              0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              0.08564220368862152,
              0.001047880039550364,
              -0.06609869748353958,
              0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              0.0853922963142395,
              0.0011095399968326092,
              -0.06915389746427536,
              0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              0.0853922963142395,
              0.0011095399968326092,
              -0.06915389746427536,
              0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              0.07833889871835709,
              0.04159890115261078,
              -0.09128779917955399,
              0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              0.0853922963142395,
              0.0011095399968326092,
              -0.06915389746427536,
              0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              0.08130879700183868,
              0.017782999202609062,
              -0.0965799018740654,
              0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              0.08130879700183868,
              0.017782999202609062,
              -0.0965799018740654,
              0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              0.07863560318946838,
              0.039639201015233994,
              -0.09233040362596512,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              0.07863560318946838,
              0.039639201015233994,
              -0.09233040362596512,
              0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              0.07863560318946838,
              0.039639201015233994,
              -0.09233040362596512,
              0.10563500225543976,
              0.0015097300056368113,
              0.07223069667816162,
              0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              0.08565250039100647,
              0.008104950189590454,
              -0.06776560097932816,
              0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              0.08130879700183868,
              0.017782999202609062,
              -0.0965799018740654,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              0.08130879700183868,
              0.017782999202609062,
              -0.0965799018740654,
              0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              0.07863560318946838,
              0.039639201015233994,
              -0.09233040362596512,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              0.08565250039100647,
              0.008104950189590454,
              -0.06776560097932816,
              0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              0.08565250039100647,
              0.008104950189590454,
              -0.06776560097932816,
              0.08455760031938553,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              0.08455760031938553,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08455760031938553,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              0.0781899020075798,
              0.03946889936923981,
              -0.09189900010824203,
              0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              0.08564220368862152,
              0.001047880039550364,
              -0.06609869748353958,
              0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              0.08564220368862152,
              0.001047880039550364,
              -0.06609869748353958,
              0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              0.07783559709787369,
              0.04243500158190727,
              -0.09941379725933075,
              0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              0.0781899020075798,
              0.03946889936923981,
              -0.09189900010824203,
              0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              0.0781899020075798,
              0.03946889936923981,
              -0.09189900010824203,
              0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              0.05047040060162544,
              0.27090299129486084,
              -0.026756800711154938,
              0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              0.1053910031914711,
              0.004109830129891634,
              0.07226540148258209,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              0.08517000079154968,
              0.008572700433433056,
              -0.06787610054016113,
              0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              0.08130879700183868,
              0.017782999202609062,
              -0.0965799018740654,
              0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.10563500225543976,
              0.0015097300056368113,
              0.07223069667816162,
              0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              0.08517000079154968,
              0.008572700433433056,
              -0.06787610054016113,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              0,
              0.001047880039550364,
              -0.06609869748353958,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              0.0781899020075798,
              0.03946889936923981,
              -0.09189900010824203,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              0.0781899020075798,
              0.03946889936923981,
              -0.09189900010824203,
              0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              0,
              0.04157029837369919,
              -0.0912322998046875,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              0.08455760031938553,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08455760031938553,
              0.0011880099773406982,
              -0.07216750085353851,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              0,
              0.003179189981892705,
              -0.080628901720047,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              0,
              0.003179189981892705,
              -0.080628901720047,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              0,
              0.003179189981892705,
              -0.080628901720047,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              0.07783559709787369,
              0.04243500158190727,
              -0.09941379725933075,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              0.07783559709787369,
              0.04243500158190727,
              -0.09941379725933075,
              0.05047040060162544,
              0.27090299129486084,
              -0.026756800711154938,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              0.08564220368862152,
              0.001047880039550364,
              -0.06609869748353958,
              0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              0.1053910031914711,
              0.004109830129891634,
              0.07226540148258209,
              0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              0.08565250039100647,
              0.008104950189590454,
              -0.06776560097932816,
              0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              0.08565250039100647,
              0.008104950189590454,
              -0.06776560097932816,
              0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              0.08564220368862152,
              0.001047880039550364,
              -0.06609869748353958,
              0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              0.10517700016498566,
              0.0010476399911567569,
              0.07229600101709366,
              0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              0.08517000079154968,
              0.008572700433433056,
              -0.06787610054016113,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              0.08517000079154968,
              0.008572700433433056,
              -0.06787610054016113,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              0.08517000079154968,
              0.008572700433433056,
              -0.06787610054016113,
              0.10517700016498566,
              0.0010476399911567569,
              0.07229600101709366,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              0,
              0.001047880039550364,
              -0.06609869748353958,
              0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              0.07783559709787369,
              0.04243500158190727,
              -0.09941379725933075,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              0.07863560318946838,
              0.039639201015233994,
              -0.09233040362596512,
              0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              0.07783559709787369,
              0.04243500158190727,
              -0.09941379725933075,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              0,
              0.04531560093164444,
              -0.09849770367145538,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              0.050682298839092255,
              0.27250099182128906,
              -0.0261841993778944,
              0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              0.050682298839092255,
              0.27250099182128906,
              -0.0261841993778944,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              0.048734501004219055,
              0.288765013217926,
              0.011296999640762806,
              0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              0.04877439886331558,
              0.2889449894428253,
              0.011264700442552567,
              0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              0.04877439886331558,
              0.2889449894428253,
              0.011264700442552567,
              0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              0.04780210182070732,
              0.2965500056743622,
              0.00985088013112545,
              0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              0.04761059954762459,
              0.2966090142726898,
              0.009838059544563293,
              0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              0.04761059954762459,
              0.2966090142726898,
              0.009838059544563293,
              0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              0.1053910031914711,
              0.004109830129891634,
              0.07226540148258209,
              0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              0.1053910031914711,
              0.004109830129891634,
              0.07226540148258209,
              0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              0,
              0.003052680054679513,
              0.10353100299835205,
              0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              0,
              0.003120010020211339,
              0.10334599763154984,
              0,
              0.003052680054679513,
              0.10353100299835205,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              0.050682298839092255,
              0.27250099182128906,
              -0.0261841993778944,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              0.048557400703430176,
              0.2887049913406372,
              0.011305900290608406,
              0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              0.048734501004219055,
              0.288765013217926,
              0.011296999640762806,
              0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              0.048734501004219055,
              0.288765013217926,
              0.011296999640762806,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              0.04788510128855705,
              0.2963699996471405,
              0.00988634955137968,
              0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              0.04780210182070732,
              0.2965500056743622,
              0.00985088013112545,
              0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              0.04780210182070732,
              0.2965500056743622,
              0.00985088013112545,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              0,
              0.003052680054679513,
              0.10353100299835205,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              0.04902850091457367,
              0.28682398796081543,
              -0.0009301499812863767,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              0.0492652989923954,
              0.28484800457954407,
              -0.01835639961063862,
              0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              0.0492652989923954,
              0.28484800457954407,
              -0.01835639961063862,
              0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              0.04813599959015846,
              0.29427599906921387,
              0.00009717050124891102,
              0.04902850091457367,
              0.28682398796081543,
              -0.0009301499812863767,
              0.04813599959015846,
              0.29427599906921387,
              0.00009717050124891102,
              0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              0.04902850091457367,
              0.28682398796081543,
              -0.0009301499812863767,
              0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              0.10563500225543976,
              0.0015097300056368113,
              0.07223069667816162,
              0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              0.04877439886331558,
              0.2889449894428253,
              0.011264700442552567,
              0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              0.04874910041689873,
              0.2891570031642914,
              0.0112252002581954,
              0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              0.04874910041689873,
              0.2891570031642914,
              0.0112252002581954,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.0492652989923954,
              0.28484800457954407,
              -0.01835639961063862,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              0.0492652989923954,
              0.28484800457954407,
              -0.01835639961063862,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              0.10563500225543976,
              0.0015097300056368113,
              0.07223069667816162,
              0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              0.10563500225543976,
              0.0015097300056368113,
              0.07223069667816162,
              0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              0.04761059954762459,
              0.2966090142726898,
              0.009838059544563293,
              0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              0.047394201159477234,
              0.2966090142726898,
              0.009837949648499489,
              0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              0.047394201159477234,
              0.2966090142726898,
              0.009837949648499489,
              0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              0.04862150177359581,
              0.28636300563812256,
              -0.0008914790232665837,
              0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              0.04862150177359581,
              0.28636300563812256,
              -0.0008914790232665837,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              0.08487509936094284,
              0.0010476099560037255,
              0.10277099907398224,
              0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              0.08487509936094284,
              0.0010476099560037255,
              0.10277099907398224,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              0.0959613025188446,
              0.0010476099560037255,
              0.0978911966085434,
              0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              0.0959613025188446,
              0.0010476099560037255,
              0.0978911966085434,
              0.0989162027835846,
              0.0010476399911567569,
              0.09520299732685089,
              0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.10141000151634216,
              0.0010476399911567569,
              0.09208790212869644,
              0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              0.10022400319576263,
              0.0010476099560037255,
              0.09369490295648575,
              0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              0.10022400319576263,
              0.0010476099560037255,
              0.09369490295648575,
              0.10558900237083435,
              0.0010476399911567569,
              0.08080659806728363,
              0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              0.10570000112056732,
              0.0010476700263097882,
              0.07660719752311707,
              0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              0.10570000112056732,
              0.0010476700263097882,
              0.07660719752311707,
              0.0827736034989357,
              0.0010476399911567569,
              0.10303899645805359,
              0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              0.08060730248689651,
              0.0010476099560037255,
              0.10312999784946442,
              0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              0.08060730248689651,
              0.0010476099560037255,
              0.10312999784946442,
              0.09260019659996033,
              0.0010476099560037255,
              0.10008999705314636,
              0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              0.0959613025188446,
              0.0010476099560037255,
              0.0978911966085434,
              0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              0.09432809799909592,
              0.001047580037266016,
              0.09905560314655304,
              0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              0.09432809799909592,
              0.001047580037266016,
              0.09905560314655304,
              0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              0.10141000151634216,
              0.0010476399911567569,
              0.09208790212869644,
              0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              0.10141000151634216,
              0.0010476399911567569,
              0.09208790212869644,
              0.1048009991645813,
              0.0010476399911567569,
              0.08482740074396133,
              0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              0.10417000204324722,
              0.0010476399911567569,
              0.0867517963051796,
              0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              0.10417000204324722,
              0.0010476399911567569,
              0.0867517963051796,
              0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              0.1048009991645813,
              0.0010476399911567569,
              0.08482740074396133,
              0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              0.1048009991645813,
              0.0010476399911567569,
              0.08482740074396133,
              0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              0.08888690173625946,
              0.003171290038153529,
              0.10173700004816055,
              0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              0.08888690173625946,
              0.003171290038153529,
              0.10173700004816055,
              0.08888690173625946,
              0.003171290038153529,
              0.10173700004816055,
              0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              0.09078410267829895,
              0.0031951400451362133,
              0.10098700225353241,
              0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              0.09078410267829895,
              0.0031951400451362133,
              0.10098700225353241,
              0.09596119821071625,
              0.0032937501091510057,
              0.09789139777421951,
              0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              0.09749279916286469,
              0.003334699897095561,
              0.0966046005487442,
              0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              0.09749279916286469,
              0.003334699897095561,
              0.0966046005487442,
              0.09891609847545624,
              0.00337934005074203,
              0.09520330280065536,
              0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              0.10141000151634216,
              0.003478559898212552,
              0.09208820015192032,
              0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              0.10246799886226654,
              0.003532649949193001,
              0.09039010107517242,
              0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              0.10246799886226654,
              0.003532649949193001,
              0.09039010107517242,
              0.10246799886226654,
              0.003532649949193001,
              0.09039010107517242,
              0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              0.10339000076055527,
              0.003589330008253455,
              0.08860869705677032,
              0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              0.10339000076055527,
              0.003589330008253455,
              0.08860869705677032,
              0.10339000076055527,
              0.003589330008253455,
              0.08860869705677032,
              0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              0.10480000078678131,
              0.0037097299937158823,
              0.0848274976015091,
              0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              0.10527600347995758,
              0.003772940021008253,
              0.08284319937229156,
              0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              0.10527600347995758,
              0.003772940021008253,
              0.08284319937229156,
              0.10558900237083435,
              0.0038378199096769094,
              0.08080659806728363,
              0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0.10548499971628189,
              0.004040270112454891,
              0.07444970309734344,
              0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              0.1053910031914711,
              0.004109830129891634,
              0.07226540148258209,
              0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              0.07841949909925461,
              0.003126889932900667,
              0.10312999784946442,
              0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              0.08060730248689651,
              0.0031269199680536985,
              0.10312999784946442,
              0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              0.08060730248689651,
              0.0031269199680536985,
              0.10312999784946442,
              0,
              0.003126859897747636,
              0.10312999784946442,
              0,
              0.003120010020211339,
              0.10334599763154984,
              0.07841949909925461,
              0.003126889932900667,
              0.10312999784946442,
              0,
              0.003120010020211339,
              0.10334599763154984,
              0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              0.07841949909925461,
              0.003126889932900667,
              0.10312999784946442,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              0.047655899077653885,
              0.29442399740219116,
              -0.0015682900557294488,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              0.04862150177359581,
              0.28636300563812256,
              -0.0008914790232665837,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0.08888699859380722,
              0.0010476399911567569,
              0.10173700004816055,
              0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              0.08888699859380722,
              0.0010476399911567569,
              0.10173700004816055,
              0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              0.08888699859380722,
              0.0010476399911567569,
              0.10173700004816055,
              0.09432809799909592,
              0.001047580037266016,
              0.09905560314655304,
              0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              0.09260019659996033,
              0.0010476099560037255,
              0.10008999705314636,
              0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              0.09260019659996033,
              0.0010476099560037255,
              0.10008999705314636,
              0.10570000112056732,
              0.0010476700263097882,
              0.07660719752311707,
              0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              0.10573200136423111,
              0.0010476399911567569,
              0.07872539758682251,
              0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              0.10573200136423111,
              0.0010476399911567569,
              0.07872539758682251,
              0.08487509936094284,
              0.0010476099560037255,
              0.10277099907398224,
              0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              0.0827736034989357,
              0.0010476399911567569,
              0.10303899645805359,
              0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              0.0827736034989357,
              0.0010476399911567569,
              0.10303899645805359,
              0.10022400319576263,
              0.0010476099560037255,
              0.09369490295648575,
              0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              0.0989162027835846,
              0.0010476399911567569,
              0.09520299732685089,
              0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              0.0989162027835846,
              0.0010476399911567569,
              0.09520299732685089,
              0.10339000076055527,
              0.0010476399911567569,
              0.0886085033416748,
              0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              0.10417000204324722,
              0.0010476399911567569,
              0.0867517963051796,
              0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              0.10339000076055527,
              0.0010476399911567569,
              0.0886085033416748,
              0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              0.10339000076055527,
              0.0010476399911567569,
              0.0886085033416748,
              0.10573200136423111,
              0.0010476399911567569,
              0.07872539758682251,
              0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              0.10558900237083435,
              0.0010476399911567569,
              0.08080659806728363,
              0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              0.10558900237083435,
              0.0010476399911567569,
              0.08080659806728363,
              0.10517700016498566,
              0.0010476399911567569,
              0.07229600101709366,
              0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0.08060730248689651,
              0.0010476099560037255,
              0.10312999784946442,
              0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              0.08060730248689651,
              0.0031269199680536985,
              0.10312999784946442,
              0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              0.08487509936094284,
              0.0031383601017296314,
              0.10277099907398224,
              0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              0.08487509936094284,
              0.0031383601017296314,
              0.10277099907398224,
              0.08487509936094284,
              0.0031383601017296314,
              0.10277099907398224,
              0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              0.09078410267829895,
              0.0031951400451362133,
              0.10098700225353241,
              0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              0.09260009974241257,
              0.0032236899714916945,
              0.10008999705314636,
              0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              0.09260009974241257,
              0.0032236899714916945,
              0.10008999705314636,
              0.09260009974241257,
              0.0032236899714916945,
              0.10008999705314636,
              0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              0.09596119821071625,
              0.0032937501091510057,
              0.09789139777421951,
              0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              0.09596119821071625,
              0.0032937501091510057,
              0.09789139777421951,
              0.09749279916286469,
              0.003334699897095561,
              0.0966046005487442,
              0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              0.09891609847545624,
              0.00337934005074203,
              0.09520330280065536,
              0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              0.09891609847545624,
              0.00337934005074203,
              0.09520330280065536,
              0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              0.10141000151634216,
              0.003478559898212552,
              0.09208820015192032,
              0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              0.10141000151634216,
              0.003478559898212552,
              0.09208820015192032,
              0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              0.10480000078678131,
              0.0037097299937158823,
              0.0848274976015091,
              0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              0.10480000078678131,
              0.0037097299937158823,
              0.0848274976015091,
              0.10527600347995758,
              0.003772940021008253,
              0.08284319937229156,
              0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              0.10558900237083435,
              0.0038378199096769094,
              0.08080659806728363,
              0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              0.10558900237083435,
              0.0038378199096769094,
              0.08080659806728363,
              0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              0.10570000112056732,
              0.003971550147980452,
              0.07660719752311707,
              0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              0.10570000112056732,
              0.003971550147980452,
              0.07660719752311707,
              0.10570000112056732,
              0.003971550147980452,
              0.07660719752311707,
              0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              0.10548499971628189,
              0.004040270112454891,
              0.07444970309734344,
              0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              0.10548499971628189,
              0.004040270112454891,
              0.07444970309734344,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              0.0492652989923954,
              0.28484800457954407,
              -0.01835639961063862,
              0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              0.04813599959015846,
              0.29427599906921387,
              0.00009717050124891102,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              0.04902850091457367,
              0.28682398796081543,
              -0.0009301499812863767,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              0.0827736034989357,
              0.0010476399911567569,
              0.10303899645805359,
              0.08060730248689651,
              0.0010476099560037255,
              0.10312999784946442,
              0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              0.08060730248689651,
              0.0010476099560037255,
              0.10312999784946442,
              0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              0.10517700016498566,
              0.0010476399911567569,
              0.07229600101709366,
              0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0.10570000112056732,
              0.0010476700263097882,
              0.07660719752311707,
              0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              0.10570000112056732,
              0.0010476700263097882,
              0.07660719752311707,
              0.10573200136423111,
              0.0010476399911567569,
              0.07872539758682251,
              0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              0.10573200136423111,
              0.0010476399911567569,
              0.07872539758682251,
              0.10558900237083435,
              0.0010476399911567569,
              0.08080659806728363,
              0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              0.1048009991645813,
              0.0010476399911567569,
              0.08482740074396133,
              0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              0.1048009991645813,
              0.0010476399911567569,
              0.08482740074396133,
              0.10417000204324722,
              0.0010476399911567569,
              0.0867517963051796,
              0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              0.10417000204324722,
              0.0010476399911567569,
              0.0867517963051796,
              0.10339000076055527,
              0.0010476399911567569,
              0.0886085033416748,
              0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              0.10141000151634216,
              0.0010476399911567569,
              0.09208790212869644,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.10141000151634216,
              0.0010476399911567569,
              0.09208790212869644,
              0.10022400319576263,
              0.0010476099560037255,
              0.09369490295648575,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.10022400319576263,
              0.0010476099560037255,
              0.09369490295648575,
              0.0989162027835846,
              0.0010476399911567569,
              0.09520299732685089,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.0959613025188446,
              0.0010476099560037255,
              0.0978911966085434,
              0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              0.0959613025188446,
              0.0010476099560037255,
              0.0978911966085434,
              0.09432809799909592,
              0.001047580037266016,
              0.09905560314655304,
              0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              0.09432809799909592,
              0.001047580037266016,
              0.09905560314655304,
              0.09260019659996033,
              0.0010476099560037255,
              0.10008999705314636,
              0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              0.08888699859380722,
              0.0010476399911567569,
              0.10173700004816055,
              0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              0.08487509936094284,
              0.0010476099560037255,
              0.10277099907398224,
              0.0827736034989357,
              0.0010476399911567569,
              0.10303899645805359,
              0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              0.09078410267829895,
              0.0031951400451362133,
              0.10098700225353241,
              0.09260009974241257,
              0.0032236899714916945,
              0.10008999705314636,
              0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              0.09260009974241257,
              0.0032236899714916945,
              0.10008999705314636,
              0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              0.09596119821071625,
              0.0032937501091510057,
              0.09789139777421951,
              0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              0.09596119821071625,
              0.0032937501091510057,
              0.09789139777421951,
              0.09749279916286469,
              0.003334699897095561,
              0.0966046005487442,
              0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              0.09749279916286469,
              0.003334699897095561,
              0.0966046005487442,
              0.09891609847545624,
              0.00337934005074203,
              0.09520330280065536,
              0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              0.10141000151634216,
              0.003478559898212552,
              0.09208820015192032,
              0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              0.10141000151634216,
              0.003478559898212552,
              0.09208820015192032,
              0.10246799886226654,
              0.003532649949193001,
              0.09039010107517242,
              0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              0.10246799886226654,
              0.003532649949193001,
              0.09039010107517242,
              0.10339000076055527,
              0.003589330008253455,
              0.08860869705677032,
              0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              0.10480000078678131,
              0.0037097299937158823,
              0.0848274976015091,
              0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0.10480000078678131,
              0.0037097299937158823,
              0.0848274976015091,
              0.10527600347995758,
              0.003772940021008253,
              0.08284319937229156,
              0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0.10527600347995758,
              0.003772940021008253,
              0.08284319937229156,
              0.10558900237083435,
              0.0038378199096769094,
              0.08080659806728363,
              0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0.10570000112056732,
              0.003971550147980452,
              0.07660719752311707,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0.10570000112056732,
              0.003971550147980452,
              0.07660719752311707,
              0.10548499971628189,
              0.004040270112454891,
              0.07444970309734344,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0.10548499971628189,
              0.004040270112454891,
              0.07444970309734344,
              0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0,
              0.003126859897747636,
              0.10312999784946442,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0,
              0.003126859897747636,
              0.10312999784946442,
              0.07841949909925461,
              0.003126889932900667,
              0.10312999784946442,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0.07841949909925461,
              0.003126889932900667,
              0.10312999784946442,
              0.08060730248689651,
              0.0031269199680536985,
              0.10312999784946442,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0.08487509936094284,
              0.0031383601017296314,
              0.10277099907398224,
              0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              0.08487509936094284,
              0.0031383601017296314,
              0.10277099907398224,
              0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              0.08888690173625946,
              0.003171290038153529,
              0.10173700004816055,
              0.09078410267829895,
              0.0031951400451362133,
              0.10098700225353241,
              0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              0.04862150177359581,
              0.28636300563812256,
              -0.0008914790232665837,
              0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              0,
              0.2865920066833496,
              0.000297877995762974,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              0,
              0.282056987285614,
              -0.010528000071644783,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.282056987285614,
              -0.010528000071644783,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              0,
              0.283378005027771,
              -0.00859792996197939,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              0,
              0.283378005027771,
              -0.00859792996197939,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              0.04862150177359581,
              0.28636300563812256,
              -0.0008914790232665837,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              0,
              0.271806001663208,
              -0.018157100304961205,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.271806001663208,
              -0.018157100304961205,
              0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              0,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              0,
              0.282056987285614,
              -0.010528000071644783,
              0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              0,
              0.283378005027771,
              -0.00859792996197939,
              0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.282710999250412,
              -0.02083520032465458,
              0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              0.047394201159477234,
              0.2966090142726898,
              0.009837949648499489,
              0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              0,
              0.2966090142726898,
              0.00983795989304781,
              0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              0,
              0.2947410047054291,
              0.00008496319787809625,
              0,
              0.2966090142726898,
              0.00983795989304781,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              0,
              0.29025998711586,
              -0.012332700192928314,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              0,
              0.294064998626709,
              -0.003213060088455677,
              0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              0,
              0.294064998626709,
              -0.003213060088455677,
              0.047655899077653885,
              0.29442399740219116,
              -0.0015682900557294488,
              0,
              0.294064998626709,
              -0.003213060088455677,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              0.047655899077653885,
              0.29442399740219116,
              -0.0015682900557294488,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.282710999250412,
              -0.02083520032465458,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              0,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.29025998711586,
              -0.012332700192928314,
              0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              0,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0.05047040060162544,
              0.27090299129486084,
              -0.026756800711154938,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0,
              0.200997993350029,
              -0.04898789897561073,
              0.05047040060162544,
              0.27090299129486084,
              -0.026756800711154938,
              0,
              0.200997993350029,
              -0.04898789897561073,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              0.05047040060162544,
              0.27090299129486084,
              -0.026756800711154938,
              0.050682298839092255,
              0.27250099182128906,
              -0.0261841993778944,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              0.050855301320552826,
              0.2695069909095764,
              -0.0189191997051239,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              0.050682298839092255,
              0.27250099182128906,
              -0.0261841993778944,
              0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              0.05094530060887337,
              0.2708210051059723,
              -0.02629769966006279,
              0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.05094530060887337,
              0.2708210051059723,
              -0.02629769966006279,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              0.05094530060887337,
              0.2708210051059723,
              -0.02629769966006279,
              0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              0.050855301320552826,
              0.2695069909095764,
              -0.0189191997051239,
              0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              0.050855301320552826,
              0.2695069909095764,
              -0.0189191997051239,
              0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              0.04813599959015846,
              0.29427599906921387,
              0.00009717050124891102,
              0.04791039973497391,
              0.2961589992046356,
              0.00992604997009039,
              0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              0.04791039973497391,
              0.2961589992046356,
              0.00992604997009039,
              0.04874910041689873,
              0.2891570031642914,
              0.0112252002581954,
              0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              0.04902850091457367,
              0.28682398796081543,
              -0.0009301499812863767,
              0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              0.047655899077653885,
              0.29442399740219116,
              -0.0015682900557294488,
              0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              0.048557400703430176,
              0.2887049913406372,
              0.011305900290608406,
              0.04834109917283058,
              0.2887040078639984,
              0.011304900050163269,
              0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              0.04834109917283058,
              0.2887040078639984,
              0.011304900050163269,
              0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              0.04788510128855705,
              0.2963699996471405,
              0.00988634955137968,
              0.04791039973497391,
              0.2961589992046356,
              0.00992604997009039,
              0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              0.04791039973497391,
              0.2961589992046356,
              0.00992604997009039,
              0.04813599959015846,
              0.29427599906921387,
              0.00009717050124891102,
              0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              0.04834109917283058,
              0.2887040078639984,
              0.011304900050163269,
              0,
              0.2887040078639984,
              0.011304699815809727,
              0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              0,
              0.2887040078639984,
              0.011304699815809727,
              0,
              0.2865920066833496,
              0.000297877995762974,
              0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              0.047655899077653885,
              0.29442399740219116,
              -0.0015682900557294488,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              0,
              0.2947410047054291,
              0.00008496319787809625,
              0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              0.059248898178339005,
              0.20097999274730682,
              -0.04892919957637787,
              0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              0.050855301320552826,
              0.2695069909095764,
              -0.0189191997051239,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              0.059531401842832565,
              0.19862000644207,
              -0.041472699493169785,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              0.059248898178339005,
              0.20097999274730682,
              -0.04892919957637787,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              0,
              0.19860200583934784,
              -0.04141410067677498,
              0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              0.059531401842832565,
              0.19862000644207,
              -0.041472699493169785,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              0.05094530060887337,
              0.2708210051059723,
              -0.02629769966006279,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              0.05094530060887337,
              0.2708210051059723,
              -0.02629769966006279,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              0.050855301320552826,
              0.2695069909095764,
              -0.0189191997051239,
              0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              -0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              -0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              -0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              -0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              -0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              -0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              -0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              -0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              -0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              -0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              -0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              -0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              -0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              -0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              -0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              -0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              -0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              -0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              -0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              -0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              -0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              -0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              -0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              -0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              -0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              -0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              -0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              -0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              -0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              -0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              -0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              -0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              -0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              -0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              -0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              -0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              -0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              -0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              -0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              -0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              -0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              -0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              -0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              -0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              -0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              -0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              -0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              -0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              -0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              -0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              -0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              -0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              -0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              -0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              -0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              -0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              -0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              -0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              -0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              -0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              -0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              -0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              -0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              -0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              -0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              -0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              -0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              -0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              -0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              -0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              -0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              -0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              -0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              -0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              -0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              -0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              -0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              -0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              -0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              -0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              -0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              -0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              -0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              -0.0853922963142395,
              0.0011095700319856405,
              -0.06915400177240372,
              -0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              -0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              -0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              -0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              -0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              -0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              -0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              -0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              -0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              -0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              -0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              -0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              -0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              -0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              -0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              -0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              -0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              -0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              -0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              -0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              -0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              -0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              -0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              -0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              -0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              -0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              -0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              -0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              -0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              -0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              -0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              -0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              -0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              -0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              -0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              -0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              -0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              -0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              -0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              -0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              -0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              -0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              -0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              -0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              -0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              -0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              -0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              -0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              -0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              -0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              -0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              -0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              -0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              -0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              -0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              -0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              -0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              -0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              -0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              -0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              -0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              -0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              -0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              -0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              -0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              -0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              -0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              -0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              -0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              -0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              -0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              -0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              -0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              -0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              -0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              -0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              -0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              -0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              -0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              -0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              -0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              -0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              -0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              -0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              -0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              -0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              -0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              -0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              -0.07833880186080933,
              0.04159890115261078,
              -0.09128790348768234,
              -0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              -0.0853922963142395,
              0.0011095700319856405,
              -0.06915400177240372,
              -0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              -0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08564220368862152,
              0.0010479099582880735,
              -0.06609880179166794,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              -0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              -0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              -0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              -0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              -0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              -0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              -0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              -0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              -0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              -0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              -0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              -0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              -0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              -0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              -0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              -0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              -0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              -0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              -0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              -0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              -0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              -0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              -0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              -0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              -0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              -0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              -0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              -0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              -0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              -0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              -0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              -0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              -0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              -0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              -0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              -0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              -0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              -0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              -0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              -0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              -0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              -0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              -0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              -0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              -0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              -0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              -0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              -0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              -0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              -0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              -0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              -0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              -0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              -0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              -0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              -0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              -0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              -0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              -0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              -0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              -0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              -0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              -0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              -0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              -0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              -0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              -0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              -0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              -0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              -0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              -0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              -0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              -0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              -0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              -0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              -0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              -0.0853922963142395,
              0.0011095700319856405,
              -0.06915400177240372,
              -0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              -0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              -0.0853922963142395,
              0.0011095700319856405,
              -0.06915400177240372,
              -0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              -0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              -0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              -0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              -0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              -0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              -0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              -0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              -0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              -0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              -0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              -0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              -0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              -0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              -0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              -0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              -0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              -0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              -0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              -0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              -0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              -0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              -0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              -0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              -0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              -0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              -0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              -0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              -0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              -0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              -0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              -0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              -0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              -0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              -0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              -0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              -0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              -0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              -0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              -0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              -0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              -0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              -0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              -0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              -0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              -0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              -0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              -0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              -0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              -0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              -0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              -0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              -0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              -0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              -0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              -0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              -0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              -0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              -0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              -0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              -0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              -0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              -0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              -0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              -0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              -0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              -0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              -0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              -0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              -0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              -0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              -0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              -0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              -0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              -0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              -0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              -0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              -0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              -0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              -0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              -0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              -0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              -0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              -0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              -0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              -0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              -0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              -0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              -0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              -0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              -0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              -0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              -0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              -0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              -0.07833880186080933,
              0.04159890115261078,
              -0.09128790348768234,
              -0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              -0.0853922963142395,
              0.0011095700319856405,
              -0.06915400177240372,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              -0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              -0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              -0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              -0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              -0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              -0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              -0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              -0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              -0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              -0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              -0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              -0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              -0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              -0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              -0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              -0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              -0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              -0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              -0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              -0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              -0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              -0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              -0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              -0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              -0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              -0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              -0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              -0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              -0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              -0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              -0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              -0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              -0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              -0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              -0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              -0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              -0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              -0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              -0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              -0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              -0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              -0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              -0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              -0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              -0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              -0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              -0.08130870014429092,
              0.017782999202609062,
              -0.09657999873161316,
              -0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              -0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              -0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              -0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              -0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              -0.08130870014429092,
              0.017782999202609062,
              -0.09657999873161316,
              -0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              -0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              -0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              -0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              -0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              -0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              -0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              -0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              -0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              -0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              -0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              -0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              -0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              -0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              -0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              -0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              -0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              -0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              -0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              -0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              -0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              -0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              -0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              -0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              -0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              -0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              -0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              -0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              -0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              -0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              -0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              -0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              -0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              -0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              -0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              -0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              -0.07863560318946838,
              0.03963930159807205,
              -0.09233059734106064,
              -0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              -0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              -0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              -0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              -0.07863560318946838,
              0.03963930159807205,
              -0.09233059734106064,
              -0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              -0.07863560318946838,
              0.03963930159807205,
              -0.09233059734106064,
              -0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              -0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              -0.10563500225543976,
              0.0015097899595275521,
              0.0722305029630661,
              -0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              -0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              -0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              -0.08565259724855423,
              0.00810500979423523,
              -0.06776569783687592,
              -0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              -0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              -0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              -0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              -0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              -0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              -0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              -0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              -0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              -0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              -0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              -0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              -0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              -0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              -0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              -0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              -0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              -0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              -0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              -0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              -0.08130870014429092,
              0.017782999202609062,
              -0.09657999873161316,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              -0.08130870014429092,
              0.017782999202609062,
              -0.09657999873161316,
              -0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              -0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              -0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              -0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              -0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              -0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              -0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              -0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              -0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              -0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              -0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              -0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              -0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              -0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              -0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              -0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              -0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              -0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              -0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              -0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              -0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              -0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              -0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              -0.07863560318946838,
              0.03963930159807205,
              -0.09233059734106064,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              -0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              -0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              -0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              -0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              -0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              -0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              -0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              -0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              -0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              -0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              -0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              -0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              -0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              -0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              -0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              -0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              -0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              -0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              -0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              -0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              -0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              -0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              -0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              -0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              -0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              -0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              -0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              -0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              -0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              -0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              -0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              -0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              -0.08565259724855423,
              0.00810500979423523,
              -0.06776569783687592,
              -0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              -0.08565259724855423,
              0.00810500979423523,
              -0.06776569783687592,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              -0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              -0.08455760031938553,
              0.0011880099773406982,
              -0.07216759771108627,
              -0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              -0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08455760031938553,
              0.0011880099773406982,
              -0.07216759771108627,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08455760031938553,
              0.0011880099773406982,
              -0.07216759771108627,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              -0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              -0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              -0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              -0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              -0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              -0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              -0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              -0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              -0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              -0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              -0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              -0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              -0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              -0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              -0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              -0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              -0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              -0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              -0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              -0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              -0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              -0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              -0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              -0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              -0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              -0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              -0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              -0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              -0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              -0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              -0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              -0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              -0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              -0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              -0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              -0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              -0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              -0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              -0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              -0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              -0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              -0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              -0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              -0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              -0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              -0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              -0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              -0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              -0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              -0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              -0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              -0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              -0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              -0.07818979769945145,
              0.039468999952077866,
              -0.0918990969657898,
              -0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              -0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              -0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              -0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              -0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              -0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08564220368862152,
              0.0010479099582880735,
              -0.06609880179166794,
              -0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              -0.08564220368862152,
              0.0010479099582880735,
              -0.06609880179166794,
              -0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              -0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              -0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              -0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              -0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              -0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              -0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              -0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              -0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              -0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              -0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              -0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              -0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              -0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              -0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              -0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              -0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              -0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              -0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              -0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              -0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              -0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              -0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              -0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              -0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              -0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07783559709787369,
              0.04243509843945503,
              -0.0994139015674591,
              -0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              -0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              -0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              -0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              -0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              -0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              -0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              -0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              -0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              -0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              -0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              -0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              -0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              -0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              -0.07818979769945145,
              0.039468999952077866,
              -0.0918990969657898,
              -0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              -0.07818979769945145,
              0.039468999952077866,
              -0.0918990969657898,
              -0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              -0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              -0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              -0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              -0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              -0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              -0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              -0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              -0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              -0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              -0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              -0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              -0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              -0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              -0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              -0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              -0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              -0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              -0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              -0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              -0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              -0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              -0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              -0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              -0.05047040060162544,
              0.27090299129486084,
              -0.026756899431347847,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              -0.10539200156927109,
              0.004109859932214022,
              0.07226529717445374,
              -0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              -0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              -0.08517000079154968,
              0.008572730235755444,
              -0.06787619739770889,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              -0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              -0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              -0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              -0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              -0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              -0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              -0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              -0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              -0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              -0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              -0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              -0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              -0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              -0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              -0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              -0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              -0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              -0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              -0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              -0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              -0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              -0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              -0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              -0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              -0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              -0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              -0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              -0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              -0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              -0.08130870014429092,
              0.017782999202609062,
              -0.09657999873161316,
              -0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              -0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              -0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              -0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              -0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              -0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              -0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              -0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              -0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              -0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              -0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              -0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              -0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              -0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              -0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              -0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              -0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              -0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              -0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              -0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              -0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              -0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              -0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              -0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              -0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              -0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              -0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              -0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              -0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              -0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              -0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              -0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              -0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              -0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              -0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              -0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              -0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              -0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              -0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              -0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              -0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              -0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              -0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              -0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              -0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              -0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              -0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              -0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              -0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              -0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              -0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              -0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              -0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              -0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              -0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              -0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              -0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              -0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              -0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              -0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              -0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              -0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              -0.10563500225543976,
              0.0015097899595275521,
              0.0722305029630661,
              -0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              -0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              -0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              -0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              -0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              -0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              -0.08517000079154968,
              0.008572730235755444,
              -0.06787619739770889,
              -0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              -0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              -0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              0,
              0.001047880039550364,
              -0.06609869748353958,
              -0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              -0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              -0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              -0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              -0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              -0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              -0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              -0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              -0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              -0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              -0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              -0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              -0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              -0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              -0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              -0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              -0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              -0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              -0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              -0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              -0.07818979769945145,
              0.039468999952077866,
              -0.0918990969657898,
              -0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              -0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              -0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              -0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              -0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              -0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              -0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              -0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              -0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              -0.07818979769945145,
              0.039468999952077866,
              -0.0918990969657898,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              0,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              -0.08455760031938553,
              0.0011880099773406982,
              -0.07216759771108627,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              -0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              -0.08455760031938553,
              0.0011880099773406982,
              -0.07216759771108627,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              0,
              0.003179189981892705,
              -0.080628901720047,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              0,
              0.003179189981892705,
              -0.080628901720047,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              0,
              0.003179189981892705,
              -0.080628901720047,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              -0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              -0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              -0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              -0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              -0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              -0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              -0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              -0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              -0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              -0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              -0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              -0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07783559709787369,
              0.04243509843945503,
              -0.0994139015674591,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07783559709787369,
              0.04243509843945503,
              -0.0994139015674591,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              -0.05047040060162544,
              0.27090299129486084,
              -0.026756899431347847,
              -0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              -0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              -0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              -0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              -0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              -0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              -0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              -0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              -0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              -0.08564220368862152,
              0.0010479099582880735,
              -0.06609880179166794,
              -0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              -0.10539200156927109,
              0.004109859932214022,
              0.07226529717445374,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              -0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              -0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              -0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              -0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              -0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              -0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              -0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              -0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              -0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              -0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              -0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              -0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              -0.08565259724855423,
              0.00810500979423523,
              -0.06776569783687592,
              -0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              -0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              -0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              -0.08565259724855423,
              0.00810500979423523,
              -0.06776569783687592,
              -0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              -0.08564220368862152,
              0.0010479099582880735,
              -0.06609880179166794,
              -0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              -0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              -0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              -0.10517799854278564,
              0.0010476999450474977,
              0.07229579985141754,
              -0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              -0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              -0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              -0.08517000079154968,
              0.008572730235755444,
              -0.06787619739770889,
              -0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              -0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              -0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              -0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              -0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              -0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              -0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              -0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              -0.08517000079154968,
              0.008572730235755444,
              -0.06787619739770889,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              -0.08517000079154968,
              0.008572730235755444,
              -0.06787619739770889,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              -0.10517799854278564,
              0.0010476999450474977,
              0.07229579985141754,
              -0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              -0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              0,
              0.001047880039550364,
              -0.06609869748353958,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              -0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              -0.07783559709787369,
              0.04243509843945503,
              -0.0994139015674591,
              -0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              -0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              -0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              -0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              -0.07863560318946838,
              0.03963930159807205,
              -0.09233059734106064,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              -0.07783559709787369,
              0.04243509843945503,
              -0.0994139015674591,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              0,
              0.04531560093164444,
              -0.09849770367145538,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              -0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              -0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              -0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              -0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              -0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              -0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              -0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              -0.050682298839092255,
              0.27250099182128906,
              -0.02618429996073246,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              -0.050682298839092255,
              0.27250099182128906,
              -0.02618429996073246,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              -0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              -0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              -0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              -0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              -0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              -0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              -0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              -0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              -0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              -0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              -0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              -0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              -0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              -0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              -0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              -0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              -0.048734601587057114,
              0.288765013217926,
              0.011296999640762806,
              -0.04877439886331558,
              0.2889449894428253,
              0.011264599859714508,
              -0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              -0.04877439886331558,
              0.2889449894428253,
              0.011264599859714508,
              -0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              -0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              -0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              -0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              -0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              -0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              -0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              -0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              -0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              -0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              -0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              -0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              -0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              -0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              -0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              -0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              -0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              -0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              -0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              -0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              -0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              -0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              -0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              -0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              -0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              -0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              -0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              -0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              -0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              -0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              -0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              -0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              -0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              -0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              -0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              -0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              -0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              -0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              -0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              -0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              -0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              -0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              -0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              -0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              -0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              -0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              -0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              -0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              -0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              -0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              -0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              -0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              -0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              -0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              -0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              -0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              -0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              -0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              -0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              -0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              -0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              -0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              -0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              -0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              -0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              -0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              -0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              -0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              -0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              -0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              -0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              -0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              -0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              -0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              -0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              -0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              -0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              -0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              -0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              -0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              -0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              -0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              -0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              -0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              -0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              -0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              -0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              -0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              -0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              -0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              -0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              -0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              -0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              -0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              -0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              -0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              -0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              -0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              -0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              -0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              -0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              -0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              -0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              -0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              -0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              -0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              -0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              -0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              -0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              -0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              -0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              -0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              -0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              -0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              -0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              -0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              -0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              -0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              -0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              -0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              -0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              -0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              -0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              -0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              -0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              -0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              -0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              -0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              -0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              -0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              -0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              -0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              -0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              -0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              -0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              -0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              -0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              -0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              -0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              -0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              -0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              -0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              -0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              -0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              -0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              -0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              -0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              -0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              -0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              -0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              -0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              -0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              -0.04780210182070732,
              0.2965500056743622,
              0.009850800037384033,
              -0.04761059954762459,
              0.2966090142726898,
              0.009837989695370197,
              -0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              -0.04761059954762459,
              0.2966090142726898,
              0.009837989695370197,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              -0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              -0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              -0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              -0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              -0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              -0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              -0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              -0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              -0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              -0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              -0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              -0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              -0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              -0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              -0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              -0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              -0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              -0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              -0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              -0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              -0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              -0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              -0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              -0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              -0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              -0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              -0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              -0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              -0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              -0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              -0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              -0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              -0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              -0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              -0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              -0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              -0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              -0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              -0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              -0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              -0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              -0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              -0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              -0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              -0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              -0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              -0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              -0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              -0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              -0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              -0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              -0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              -0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              -0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              -0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              -0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              -0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              -0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              -0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              -0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              -0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              -0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              -0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              -0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              -0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              -0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              -0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              -0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              -0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              -0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              -0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              -0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              -0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              -0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              -0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              -0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              -0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              -0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              -0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              -0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              -0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              -0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              -0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              -0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              -0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              -0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              -0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              -0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              -0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              -0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              -0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              -0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              -0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              -0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              -0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              -0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              -0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              -0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              -0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              -0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              -0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              -0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              -0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              -0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              -0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              -0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              -0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              -0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              -0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              -0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              -0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              -0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              -0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              -0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              -0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              -0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              -0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              -0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              -0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              -0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              -0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              -0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              -0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              -0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              -0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              -0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              -0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              -0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              -0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              -0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              -0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              -0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              -0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              -0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              -0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              -0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              -0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              -0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              -0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              -0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              -0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              -0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              -0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              -0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              -0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              -0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              -0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              -0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              -0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              -0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              -0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              -0.10539200156927109,
              0.004109859932214022,
              0.07226529717445374,
              -0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              -0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              -0.10539200156927109,
              0.004109859932214022,
              0.07226529717445374,
              -0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              -0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              -0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              -0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              -0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              -0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              -0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              0,
              0.003052680054679513,
              0.10353100299835205,
              -0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              0,
              0.003052680054679513,
              0.10353100299835205,
              0,
              0.003120010020211339,
              0.10334599763154984,
              -0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              -0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              -0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              -0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              -0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              -0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              -0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              -0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              -0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              -0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              -0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              -0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              -0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              -0.050682298839092255,
              0.27250099182128906,
              -0.02618429996073246,
              -0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              -0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              -0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              -0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              -0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              -0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              -0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              -0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              -0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              -0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              -0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              -0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              -0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              -0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              -0.048557400703430176,
              0.2887049913406372,
              0.011305799707770348,
              -0.048734601587057114,
              0.288765013217926,
              0.011296999640762806,
              -0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              -0.048734601587057114,
              0.288765013217926,
              0.011296999640762806,
              -0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              -0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              -0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              -0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              -0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              -0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              -0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              -0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              -0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              -0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              -0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              -0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              -0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              -0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              -0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              -0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              -0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              -0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              -0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              -0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              -0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              -0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              -0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              -0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              -0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              -0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              -0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              -0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              -0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              -0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              -0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              -0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              -0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              -0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              -0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              -0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              -0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              -0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              -0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              -0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              -0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              -0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              -0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              -0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              -0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              -0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              -0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              -0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              -0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              -0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              -0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              -0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              -0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              -0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              -0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              -0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              -0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              -0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              -0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              -0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              -0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              -0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              -0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              -0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              -0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              -0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              -0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              -0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              -0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              -0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              -0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              -0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              -0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              -0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              -0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              -0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              -0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              -0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              -0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              -0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              -0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              -0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              -0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              -0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              -0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              -0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              -0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              -0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              -0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              -0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              -0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              -0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              -0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              -0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              -0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              -0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              -0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              -0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              -0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              -0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              -0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              -0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              -0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              -0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              -0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              -0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              -0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              -0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              -0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              -0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              -0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              -0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              -0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              -0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              -0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              -0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              -0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              -0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              -0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              -0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              -0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              -0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              -0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              -0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              -0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              -0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              -0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              -0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              -0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              -0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              -0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              -0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              -0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              -0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              -0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              -0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              -0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              -0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              -0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              -0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              -0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              -0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              -0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              -0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              -0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              -0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              -0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              -0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              -0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              -0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              -0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              -0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              -0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              -0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              -0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              -0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              -0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              -0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              -0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              -0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              -0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              -0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              -0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              -0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              -0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.04788510128855705,
              0.2963699996471405,
              0.009886279702186584,
              -0.04780210182070732,
              0.2965500056743622,
              0.009850800037384033,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              -0.04780210182070732,
              0.2965500056743622,
              0.009850800037384033,
              -0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              -0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              -0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              -0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              -0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              -0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              -0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              -0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              -0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              -0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              -0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              -0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              -0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              -0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              -0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              -0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              -0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              -0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              -0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              -0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              -0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              -0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              -0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              -0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              -0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              -0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              -0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              -0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              -0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              -0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              -0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              -0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              -0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              -0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              -0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              -0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              -0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              -0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              -0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              -0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              -0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              -0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              -0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              -0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              -0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              -0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              -0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              -0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              -0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              -0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              -0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              -0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              -0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              -0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              -0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              -0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              -0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              -0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              -0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              -0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              -0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              -0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              -0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              -0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              -0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              -0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              -0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              -0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              -0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              -0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              -0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              -0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              -0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              -0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              -0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              -0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              -0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              -0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              -0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              -0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              -0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              -0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              -0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              -0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              -0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              -0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              -0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              -0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              -0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              -0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              -0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              -0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              -0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              -0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              -0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              -0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              -0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              -0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              -0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              -0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              -0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              -0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              -0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              -0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              -0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              -0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              -0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              -0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              -0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              -0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              -0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              -0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              -0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              -0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              -0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              -0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              -0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              -0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              -0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              -0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              -0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              -0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              -0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              -0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              -0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              -0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              -0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              -0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              -0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              -0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              -0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              -0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              -0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              -0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              -0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              -0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              -0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              -0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              -0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              -0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              -0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              -0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              -0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              -0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              -0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              -0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              -0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              -0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              -0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              -0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              -0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              -0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              -0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              -0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              -0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              -0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              -0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              -0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              -0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              -0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              -0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              -0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              -0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              -0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              -0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              -0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              0,
              0.003052680054679513,
              0.10353100299835205,
              -0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              -0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              -0.04902850091457367,
              0.28682398796081543,
              -0.0009302229736931622,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              -0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              -0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              -0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              -0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              -0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              -0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              -0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              -0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              -0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              -0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              -0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              -0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              -0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              -0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              -0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              -0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              -0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.04926519840955734,
              0.28484800457954407,
              -0.018356500193476677,
              -0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              -0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              -0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              -0.04926519840955734,
              0.28484800457954407,
              -0.018356500193476677,
              -0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              -0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              -0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              -0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              -0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              -0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              -0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              -0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              -0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              -0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              -0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              -0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              -0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              -0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              -0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              -0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              -0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              -0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              -0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              -0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              -0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              -0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              -0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              -0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              -0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              -0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              -0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              -0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              -0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              -0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              -0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              -0.04902850091457367,
              0.28682398796081543,
              -0.0009302229736931622,
              -0.04813599959015846,
              0.2942749857902527,
              0.0000971035988186486,
              -0.04902850091457367,
              0.28682398796081543,
              -0.0009302229736931622,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              -0.04813599959015846,
              0.2942749857902527,
              0.0000971035988186486,
              -0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              -0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              -0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              -0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              -0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              -0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              -0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              -0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              -0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              -0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              -0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              -0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              -0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              -0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              -0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              -0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              -0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              -0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              -0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              -0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              -0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              -0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              -0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              -0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              -0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              -0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              -0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              -0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              -0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              -0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              -0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              -0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              -0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              -0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              -0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              -0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              -0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              -0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              -0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              -0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              -0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              -0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              -0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              -0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              -0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              -0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              -0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              -0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              -0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              -0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              -0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              -0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              -0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              -0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              -0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              -0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              -0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              -0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              -0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              -0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              -0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              -0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              -0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              -0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              -0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              -0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              -0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              -0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              -0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              -0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              -0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              -0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              -0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              -0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              -0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              -0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              -0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              -0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              -0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              -0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              -0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              -0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              -0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              -0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              -0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              -0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              -0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              -0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              -0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              -0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              -0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              -0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              -0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              -0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              -0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              -0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              -0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              -0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              -0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              -0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              -0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              -0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              -0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              -0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              -0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              -0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              -0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              -0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              -0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              -0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              -0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              -0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              -0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              -0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              -0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              -0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              -0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              -0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              -0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              -0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              -0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              -0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              -0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              -0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              -0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              -0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              -0.10563500225543976,
              0.0015097899595275521,
              0.0722305029630661,
              -0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              -0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              -0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              -0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              -0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              -0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              -0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              -0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              -0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              -0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              -0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              -0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              -0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              -0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              -0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              -0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              -0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              -0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              -0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              -0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              -0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              -0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              -0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              -0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              -0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              -0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              -0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              -0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              -0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              -0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              -0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              -0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              -0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              -0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              -0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              -0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              -0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              -0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              -0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              -0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              -0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              -0.04877439886331558,
              0.2889449894428253,
              0.011264599859714508,
              -0.04874910041689873,
              0.2891570031642914,
              0.011225099675357342,
              -0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              -0.04874910041689873,
              0.2891570031642914,
              0.011225099675357342,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              -0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              -0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              -0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              -0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              -0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              -0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              -0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              -0.04926519840955734,
              0.28484800457954407,
              -0.018356500193476677,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.04926519840955734,
              0.28484800457954407,
              -0.018356500193476677,
              -0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              -0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              -0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              -0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              -0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              -0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              -0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              -0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              -0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              -0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              -0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              -0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              -0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              -0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              -0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              -0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              -0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              -0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              -0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              -0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              -0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              -0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              -0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              -0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              -0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              -0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              -0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              -0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              -0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              -0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              -0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              -0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              -0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              -0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              -0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              -0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              -0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              -0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              -0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              -0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              -0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              -0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              -0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              -0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              -0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              -0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              -0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              -0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              -0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              -0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              -0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              -0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              -0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              -0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              -0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              -0.10563500225543976,
              0.0015097899595275521,
              0.0722305029630661,
              -0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              -0.10563500225543976,
              0.0015097899595275521,
              0.0722305029630661,
              -0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              -0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              -0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              -0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              -0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              -0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              -0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              -0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              -0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              -0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              -0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              -0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              -0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              -0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              -0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              -0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              -0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              -0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              -0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              -0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              -0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              -0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              -0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              -0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              -0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              -0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              -0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              -0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              -0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              -0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              -0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              -0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              -0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              -0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              -0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              -0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              -0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              -0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              -0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              -0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              -0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              -0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              -0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              -0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              -0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              -0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              -0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              -0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              -0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              -0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              -0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              -0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              -0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              -0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              -0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              -0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              -0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              -0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              -0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              -0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              -0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              -0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              -0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              -0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              -0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              -0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              -0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              -0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              -0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              -0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              -0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              -0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              -0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              -0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              -0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              -0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              -0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              -0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              -0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              -0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              -0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              -0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              -0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              -0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              -0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              -0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              -0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              -0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              -0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              -0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              -0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              -0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              -0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04761059954762459,
              0.2966090142726898,
              0.009837989695370197,
              -0.04739430174231529,
              0.2966090142726898,
              0.009837879799306393,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              -0.04739430174231529,
              0.2966090142726898,
              0.009837879799306393,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              -0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              -0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              -0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              -0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              -0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              -0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              -0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              -0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              -0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              -0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              -0.04862150177359581,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              -0.04862150177359581,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              -0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              -0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              -0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              -0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              -0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              -0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              -0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              -0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              -0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              -0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              -0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              -0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              -0.08487539738416672,
              0.0010476399911567569,
              0.10277099907398224,
              -0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              -0.08487539738416672,
              0.0010476399911567569,
              0.10277099907398224,
              -0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              -0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              -0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              -0.09596150368452072,
              0.0010476399911567569,
              0.09789100289344788,
              -0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              -0.09596150368452072,
              0.0010476399911567569,
              0.09789100289344788,
              -0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              -0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              -0.09891639649868011,
              0.0010476700263097882,
              0.09520290046930313,
              -0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              -0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              -0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              -0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              -0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              -0.10141099989414215,
              0.0010476700263097882,
              0.09208779782056808,
              -0.10022500157356262,
              0.0010476700263097882,
              0.09369479864835739,
              -0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              -0.10022500157356262,
              0.0010476700263097882,
              0.09369479864835739,
              -0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              -0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              -0.10558900237083435,
              0.0010476999450474977,
              0.08080639690160751,
              -0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              -0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              -0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              -0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              -0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              -0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              -0.10570099949836731,
              0.0010476999450474977,
              0.0766071006655693,
              -0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              -0.10570099949836731,
              0.0010476999450474977,
              0.0766071006655693,
              -0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              -0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              -0.08277390152215958,
              0.0010476399911567569,
              0.10303899645805359,
              -0.08060760051012039,
              0.0010476399911567569,
              0.10312999784946442,
              -0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              -0.08060760051012039,
              0.0010476399911567569,
              0.10312999784946442,
              -0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              -0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              -0.09260039776563644,
              0.0010476399911567569,
              0.10008999705314636,
              -0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              -0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              -0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              -0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              -0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              -0.09596150368452072,
              0.0010476399911567569,
              0.09789100289344788,
              -0.09432829916477203,
              0.0010476399911567569,
              0.09905549883842468,
              -0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              -0.09432829916477203,
              0.0010476399911567569,
              0.09905549883842468,
              -0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              -0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              -0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              -0.10141099989414215,
              0.0010476700263097882,
              0.09208779782056808,
              -0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              -0.10141099989414215,
              0.0010476700263097882,
              0.09208779782056808,
              -0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              -0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              -0.1048009991645813,
              0.0010476700263097882,
              0.08482729643583298,
              -0.10417000204324722,
              0.0010476999450474977,
              0.08675160259008408,
              -0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              -0.10417000204324722,
              0.0010476999450474977,
              0.08675160259008408,
              -0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              -0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              -0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              -0.1048009991645813,
              0.0010476700263097882,
              0.08482729643583298,
              -0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              -0.1048009991645813,
              0.0010476700263097882,
              0.08482729643583298,
              -0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              -0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              -0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              -0.08888719975948334,
              0.003171290038153529,
              0.10173700004816055,
              -0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              -0.08888719975948334,
              0.003171290038153529,
              0.10173700004816055,
              -0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              -0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              -0.08888719975948334,
              0.003171290038153529,
              0.10173700004816055,
              -0.09078440070152283,
              0.0031951700802892447,
              0.10098599642515182,
              -0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              -0.09078440070152283,
              0.0031951700802892447,
              0.10098599642515182,
              -0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              -0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              -0.09596139937639236,
              0.0032937799114733934,
              0.09789130091667175,
              -0.0974930003285408,
              0.0033347599674016237,
              0.09660450369119644,
              -0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              -0.0974930003285408,
              0.0033347599674016237,
              0.09660450369119644,
              -0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              -0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              -0.09891629964113235,
              0.0033793700858950615,
              0.09520310163497925,
              -0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              -0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              -0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              -0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              -0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              -0.10141099989414215,
              0.0034785899333655834,
              0.0920879989862442,
              -0.10246799886226654,
              0.003532679984346032,
              0.09038989990949631,
              -0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              -0.10246799886226654,
              0.003532679984346032,
              0.09038989990949631,
              -0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              -0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              -0.10246799886226654,
              0.003532679984346032,
              0.09038989990949631,
              -0.10339000076055527,
              0.003589420113712549,
              0.08860860019922256,
              -0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              -0.10339000076055527,
              0.003589420113712549,
              0.08860860019922256,
              -0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              -0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              -0.10339000076055527,
              0.003589420113712549,
              0.08860860019922256,
              -0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              -0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              -0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              -0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              -0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              -0.1048009991645813,
              0.003709790064021945,
              0.08482740074396133,
              -0.10527600347995758,
              0.003773000091314316,
              0.08284299820661545,
              -0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              -0.10527600347995758,
              0.003773000091314316,
              0.08284299820661545,
              -0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              -0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              -0.10558900237083435,
              0.003837849944829941,
              0.08080650120973587,
              -0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              -0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              -0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              -0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              -0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              -0.10548499971628189,
              0.004040299914777279,
              0.07444950193166733,
              -0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              -0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              -0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              -0.10539200156927109,
              0.004109859932214022,
              0.07226529717445374,
              -0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              -0.07841979712247849,
              0.0031269199680536985,
              0.10312999784946442,
              -0.08060750365257263,
              0.0031269199680536985,
              0.10312999784946442,
              -0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              -0.08060750365257263,
              0.0031269199680536985,
              0.10312999784946442,
              -0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              -0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              0,
              0.003126859897747636,
              0.10312999784946442,
              -0.07841979712247849,
              0.0031269199680536985,
              0.10312999784946442,
              0,
              0.003120010020211339,
              0.10334599763154984,
              -0.07841979712247849,
              0.0031269199680536985,
              0.10312999784946442,
              -0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              0,
              0.003120010020211339,
              0.10334599763154984,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              -0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              -0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              -0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              -0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              -0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              -0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              -0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              -0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              -0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              -0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              -0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              -0.04765599966049194,
              0.29442399740219116,
              -0.0015683600213378668,
              -0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              -0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              -0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              -0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              -0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              -0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              -0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              -0.04862150177359581,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              -0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              -0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              -0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              -0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              -0.08888719975948334,
              0.0010476399911567569,
              0.10173700004816055,
              -0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              -0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              -0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              -0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              -0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              -0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              -0.08888719975948334,
              0.0010476399911567569,
              0.10173700004816055,
              -0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              -0.08888719975948334,
              0.0010476399911567569,
              0.10173700004816055,
              -0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              -0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              -0.09432829916477203,
              0.0010476399911567569,
              0.09905549883842468,
              -0.09260039776563644,
              0.0010476399911567569,
              0.10008999705314636,
              -0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              -0.09260039776563644,
              0.0010476399911567569,
              0.10008999705314636,
              -0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              -0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              -0.10570099949836731,
              0.0010476999450474977,
              0.0766071006655693,
              -0.1057329997420311,
              0.0010476700263097882,
              0.07872530072927475,
              -0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              -0.1057329997420311,
              0.0010476700263097882,
              0.07872530072927475,
              -0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              -0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              -0.08487539738416672,
              0.0010476399911567569,
              0.10277099907398224,
              -0.08277390152215958,
              0.0010476399911567569,
              0.10303899645805359,
              -0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              -0.08277390152215958,
              0.0010476399911567569,
              0.10303899645805359,
              -0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              -0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              -0.10022500157356262,
              0.0010476700263097882,
              0.09369479864835739,
              -0.09891639649868011,
              0.0010476700263097882,
              0.09520290046930313,
              -0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              -0.09891639649868011,
              0.0010476700263097882,
              0.09520290046930313,
              -0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              -0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              -0.10339000076055527,
              0.0010476700263097882,
              0.08860839903354645,
              -0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              -0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              -0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              -0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              -0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              -0.10417000204324722,
              0.0010476999450474977,
              0.08675160259008408,
              -0.10339000076055527,
              0.0010476700263097882,
              0.08860839903354645,
              -0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              -0.10339000076055527,
              0.0010476700263097882,
              0.08860839903354645,
              -0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              -0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              -0.1057329997420311,
              0.0010476700263097882,
              0.07872530072927475,
              -0.10558900237083435,
              0.0010476999450474977,
              0.08080639690160751,
              -0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              -0.10558900237083435,
              0.0010476999450474977,
              0.08080639690160751,
              -0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              -0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              -0.10517799854278564,
              0.0010476999450474977,
              0.07229579985141754,
              -0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              -0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              -0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              -0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              -0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              -0.08060760051012039,
              0.0010476399911567569,
              0.10312999784946442,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              -0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              -0.08060750365257263,
              0.0031269199680536985,
              0.10312999784946442,
              -0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              -0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              -0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              -0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              -0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              -0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              -0.08487530052661896,
              0.0031383300665766,
              0.10277099907398224,
              -0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              -0.08487530052661896,
              0.0031383300665766,
              0.10277099907398224,
              -0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              -0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              -0.08487530052661896,
              0.0031383300665766,
              0.10277099907398224,
              -0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              -0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              -0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              -0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              -0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              -0.09078440070152283,
              0.0031951700802892447,
              0.10098599642515182,
              -0.09260030090808868,
              0.003223720006644726,
              0.10008999705314636,
              -0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              -0.09260030090808868,
              0.003223720006644726,
              0.10008999705314636,
              -0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              -0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              -0.09260030090808868,
              0.003223720006644726,
              0.10008999705314636,
              -0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              -0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              -0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              -0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              -0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              -0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              -0.09596139937639236,
              0.0032937799114733934,
              0.09789130091667175,
              -0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              -0.09596139937639236,
              0.0032937799114733934,
              0.09789130091667175,
              -0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              -0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              -0.0974930003285408,
              0.0033347599674016237,
              0.09660450369119644,
              -0.09891629964113235,
              0.0033793700858950615,
              0.09520310163497925,
              -0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              -0.09891629964113235,
              0.0033793700858950615,
              0.09520310163497925,
              -0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              -0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              -0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              -0.10141099989414215,
              0.0034785899333655834,
              0.0920879989862442,
              -0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              -0.10141099989414215,
              0.0034785899333655834,
              0.0920879989862442,
              -0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              -0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              -0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              -0.1048009991645813,
              0.003709790064021945,
              0.08482740074396133,
              -0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              -0.1048009991645813,
              0.003709790064021945,
              0.08482740074396133,
              -0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              -0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              -0.10527600347995758,
              0.003773000091314316,
              0.08284299820661545,
              -0.10558900237083435,
              0.003837849944829941,
              0.08080650120973587,
              -0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              -0.10558900237083435,
              0.003837849944829941,
              0.08080650120973587,
              -0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              -0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              -0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              -0.10570099949836731,
              0.003971610218286514,
              0.07660699635744095,
              -0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              -0.10570099949836731,
              0.003971610218286514,
              0.07660699635744095,
              -0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              -0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              -0.10570099949836731,
              0.003971610218286514,
              0.07660699635744095,
              -0.10548499971628189,
              0.004040299914777279,
              0.07444950193166733,
              -0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              -0.10548499971628189,
              0.004040299914777279,
              0.07444950193166733,
              -0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              -0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              -0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              -0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              -0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              -0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              -0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              -0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              -0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              -0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              -0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              -0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              -0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              -0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              -0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              -0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              -0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              -0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              -0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              -0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              -0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              -0.04926519840955734,
              0.28484800457954407,
              -0.018356500193476677,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              -0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              -0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              -0.04813599959015846,
              0.2942749857902527,
              0.0000971035988186486,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              -0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              -0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              -0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              -0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              -0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              -0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              -0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              -0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              -0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              -0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              -0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              -0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              -0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              -0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              -0.04902850091457367,
              0.28682398796081543,
              -0.0009302229736931622,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              -0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              -0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              -0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              -0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              -0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              -0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              -0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              -0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              -0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              -0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              -0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              -0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              -0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              -0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              -0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              -0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              -0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              -0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              -0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              -0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              -0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              -0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              -0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              -0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              -0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              -0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              -0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              -0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              -0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              -0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              -0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              -0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              -0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              -0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              -0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              -0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              -0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              -0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              -0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              -0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              -0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              -0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              -0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              -0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              -0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              -0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              -0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              -0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              -0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              -0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              -0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              -0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              -0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              -0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              -0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              -0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              -0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              -0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              -0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              -0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              -0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              -0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              -0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              -0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              -0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              -0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              -0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              -0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              -0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              -0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              -0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              -0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              -0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              -0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              -0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              -0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              -0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              -0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              -0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              -0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              -0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              -0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              -0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              -0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              -0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              -0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              -0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              -0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              -0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              -0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              -0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              -0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              -0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              -0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              -0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              -0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              -0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              -0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              -0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              -0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              -0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              -0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              -0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              -0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              -0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              -0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              -0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              -0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              -0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              -0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              -0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              -0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              -0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              -0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              -0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              -0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              -0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              -0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              -0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              -0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              -0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              -0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              -0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              -0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              -0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              -0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              -0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              -0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              -0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              -0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              -0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              -0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              -0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              -0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              -0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              -0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              -0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              -0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              -0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              -0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              -0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              -0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              -0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              -0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              -0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              -0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              -0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              -0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              -0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              -0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              -0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              -0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              -0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              -0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              -0.08888719975948334,
              0.0010476399911567569,
              0.10173700004816055,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.08888719975948334,
              0.0010476399911567569,
              0.10173700004816055,
              -0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              -0.09260039776563644,
              0.0010476399911567569,
              0.10008999705314636,
              -0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              -0.09260039776563644,
              0.0010476399911567569,
              0.10008999705314636,
              -0.09432829916477203,
              0.0010476399911567569,
              0.09905549883842468,
              -0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              -0.09432829916477203,
              0.0010476399911567569,
              0.09905549883842468,
              -0.09596150368452072,
              0.0010476399911567569,
              0.09789100289344788,
              -0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              -0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              -0.09891639649868011,
              0.0010476700263097882,
              0.09520290046930313,
              -0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              -0.09891639649868011,
              0.0010476700263097882,
              0.09520290046930313,
              -0.10022500157356262,
              0.0010476700263097882,
              0.09369479864835739,
              -0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              -0.10022500157356262,
              0.0010476700263097882,
              0.09369479864835739,
              -0.10141099989414215,
              0.0010476700263097882,
              0.09208779782056808,
              -0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              -0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              -0.10339000076055527,
              0.0010476700263097882,
              0.08860839903354645,
              -0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              -0.10339000076055527,
              0.0010476700263097882,
              0.08860839903354645,
              -0.10417000204324722,
              0.0010476999450474977,
              0.08675160259008408,
              -0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              -0.10417000204324722,
              0.0010476999450474977,
              0.08675160259008408,
              -0.1048009991645813,
              0.0010476700263097882,
              0.08482729643583298,
              -0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              -0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              -0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              -0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              -0.10558900237083435,
              0.0010476999450474977,
              0.08080639690160751,
              -0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              -0.10558900237083435,
              0.0010476999450474977,
              0.08080639690160751,
              -0.1057329997420311,
              0.0010476700263097882,
              0.07872530072927475,
              -0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              -0.1057329997420311,
              0.0010476700263097882,
              0.07872530072927475,
              -0.10570099949836731,
              0.0010476999450474977,
              0.0766071006655693,
              -0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              -0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              -0.10517799854278564,
              0.0010476999450474977,
              0.07229579985141754,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.10517799854278564,
              0.0010476999450474977,
              0.07229579985141754,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              -0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              -0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              -0.08060760051012039,
              0.0010476399911567569,
              0.10312999784946442,
              -0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              -0.08060760051012039,
              0.0010476399911567569,
              0.10312999784946442,
              -0.08277390152215958,
              0.0010476399911567569,
              0.10303899645805359,
              -0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              -0.08487539738416672,
              0.0010476399911567569,
              0.10277099907398224,
              -0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              -0.08277390152215958,
              0.0010476399911567569,
              0.10303899645805359,
              -0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              -0.08487530052661896,
              0.0031383300665766,
              0.10277099907398224,
              -0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              -0.08487530052661896,
              0.0031383300665766,
              0.10277099907398224,
              -0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              -0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              -0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              -0.08060750365257263,
              0.0031269199680536985,
              0.10312999784946442,
              0,
              0.004108880180865526,
              0.0722958967089653,
              -0.08060750365257263,
              0.0031269199680536985,
              0.10312999784946442,
              -0.07841979712247849,
              0.0031269199680536985,
              0.10312999784946442,
              0,
              0.004108880180865526,
              0.0722958967089653,
              -0.07841979712247849,
              0.0031269199680536985,
              0.10312999784946442,
              0,
              0.003126859897747636,
              0.10312999784946442,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0,
              0.004108880180865526,
              0.0722958967089653,
              -0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              -0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              -0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              -0.10548499971628189,
              0.004040299914777279,
              0.07444950193166733,
              -0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              -0.10548499971628189,
              0.004040299914777279,
              0.07444950193166733,
              -0.10570099949836731,
              0.003971610218286514,
              0.07660699635744095,
              -0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              -0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              -0.10558900237083435,
              0.003837849944829941,
              0.08080650120973587,
              -0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              -0.10558900237083435,
              0.003837849944829941,
              0.08080650120973587,
              -0.10527600347995758,
              0.003773000091314316,
              0.08284299820661545,
              -0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              -0.10527600347995758,
              0.003773000091314316,
              0.08284299820661545,
              -0.1048009991645813,
              0.003709790064021945,
              0.08482740074396133,
              -0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              0,
              0.004108880180865526,
              0.0722958967089653,
              -0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              -0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              -0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              -0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              -0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              -0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              -0.10339000076055527,
              0.003589420113712549,
              0.08860860019922256,
              -0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              -0.10339000076055527,
              0.003589420113712549,
              0.08860860019922256,
              -0.10246799886226654,
              0.003532679984346032,
              0.09038989990949631,
              -0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              -0.10246799886226654,
              0.003532679984346032,
              0.09038989990949631,
              -0.10141099989414215,
              0.0034785899333655834,
              0.0920879989862442,
              -0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              -0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              -0.09891629964113235,
              0.0033793700858950615,
              0.09520310163497925,
              -0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              -0.09891629964113235,
              0.0033793700858950615,
              0.09520310163497925,
              -0.0974930003285408,
              0.0033347599674016237,
              0.09660450369119644,
              -0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              -0.0974930003285408,
              0.0033347599674016237,
              0.09660450369119644,
              -0.09596139937639236,
              0.0032937799114733934,
              0.09789130091667175,
              -0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              -0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              -0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              -0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              -0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              -0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              -0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              -0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              -0.09260030090808868,
              0.003223720006644726,
              0.10008999705314636,
              -0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              -0.09260030090808868,
              0.003223720006644726,
              0.10008999705314636,
              -0.09078440070152283,
              0.0031951700802892447,
              0.10098599642515182,
              -0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              -0.08888719975948334,
              0.003171290038153529,
              0.10173700004816055,
              -0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              -0.09078440070152283,
              0.0031951700802892447,
              0.10098599642515182,
              -0.04862150177359581,
              0.28636300563812256,
              -0.0008915470098145306,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              -0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              0,
              0.2865920066833496,
              0.000297877995762974,
              -0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              -0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              -0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              -0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              -0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.282056987285614,
              -0.010528000071644783,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.282056987285614,
              -0.010528000071644783,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              0,
              0.283378005027771,
              -0.00859792996197939,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              0,
              0.283378005027771,
              -0.00859792996197939,
              -0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              -0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              -0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              -0.04862150177359581,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              -0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              -0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              -0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              0,
              0.271806001663208,
              -0.018157100304961205,
              -0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              0,
              0.271806001663208,
              -0.018157100304961205,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              -0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              -0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              0,
              0.282056987285614,
              -0.010528000071644783,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              0,
              0.283378005027771,
              -0.00859792996197939,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              -0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              -0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              -0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              -0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              -0.04739430174231529,
              0.2966090142726898,
              0.009837879799306393,
              0,
              0.2966090142726898,
              0.00983795989304781,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              0,
              0.2966090142726898,
              0.00983795989304781,
              0,
              0.2947410047054291,
              0.00008496319787809625,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              -0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              -0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              -0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              -0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              0,
              0.294064998626709,
              -0.003213060088455677,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              -0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              -0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              -0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              -0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              -0.04765599966049194,
              0.29442399740219116,
              -0.0015683600213378668,
              0,
              0.294064998626709,
              -0.003213060088455677,
              -0.04765599966049194,
              0.29442399740219116,
              -0.0015683600213378668,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              0,
              0.294064998626709,
              -0.003213060088455677,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              -0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              0,
              0.282710999250412,
              -0.02083520032465458,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              0,
              0.282710999250412,
              -0.02083520032465458,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              0,
              0.29025998711586,
              -0.012332700192928314,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              0,
              0.29025998711586,
              -0.012332700192928314,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              -0.05047040060162544,
              0.27090299129486084,
              -0.026756899431347847,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              -0.05047040060162544,
              0.27090299129486084,
              -0.026756899431347847,
              0,
              0.200997993350029,
              -0.04898789897561073,
              -0.05047040060162544,
              0.27090299129486084,
              -0.026756899431347847,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              0,
              0.200997993350029,
              -0.04898789897561073,
              -0.050682298839092255,
              0.27250099182128906,
              -0.02618429996073246,
              -0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              -0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              -0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              -0.050855301320552826,
              0.2695069909095764,
              -0.01891930028796196,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              -0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              -0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              -0.050682298839092255,
              0.27250099182128906,
              -0.02618429996073246,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              -0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              -0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              -0.05094520002603531,
              0.2708210051059723,
              -0.02629780024290085,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              -0.05094520002603531,
              0.2708210051059723,
              -0.02629780024290085,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              -0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              -0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              -0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              -0.05094520002603531,
              0.2708210051059723,
              -0.02629780024290085,
              -0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              -0.050855301320552826,
              0.2695069909095764,
              -0.01891930028796196,
              -0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              -0.050855301320552826,
              0.2695069909095764,
              -0.01891930028796196,
              -0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              -0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              -0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              -0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              -0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              -0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              -0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              -0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              -0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              -0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              -0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              -0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              -0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              -0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              -0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              -0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              -0.04813599959015846,
              0.2942749857902527,
              0.0000971035988186486,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              -0.047910500317811966,
              0.2961589992046356,
              0.009925980120897293,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              -0.04874910041689873,
              0.2891570031642914,
              0.011225099675357342,
              -0.047910500317811966,
              0.2961589992046356,
              0.009925980120897293,
              -0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              -0.04902850091457367,
              0.28682398796081543,
              -0.0009302229736931622,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              -0.04765599966049194,
              0.29442399740219116,
              -0.0015683600213378668,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              -0.048557400703430176,
              0.2887049913406372,
              0.011305799707770348,
              -0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              -0.04834109917283058,
              0.2887040078639984,
              0.011304800398647785,
              -0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              -0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              -0.04834109917283058,
              0.2887040078639984,
              0.011304800398647785,
              -0.04788510128855705,
              0.2963699996471405,
              0.009886279702186584,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              -0.047910500317811966,
              0.2961589992046356,
              0.009925980120897293,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              -0.04813599959015846,
              0.2942749857902527,
              0.0000971035988186486,
              -0.047910500317811966,
              0.2961589992046356,
              0.009925980120897293,
              -0.04834109917283058,
              0.2887040078639984,
              0.011304800398647785,
              -0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              0,
              0.2887040078639984,
              0.011304699815809727,
              -0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              0,
              0.2865920066833496,
              0.000297877995762974,
              0,
              0.2887040078639984,
              0.011304699815809727,
              -0.04765599966049194,
              0.29442399740219116,
              -0.0015683600213378668,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              0,
              0.2947410047054291,
              0.00008496319787809625,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              -0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              -0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              -0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              -0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              -0.059248898178339005,
              0.20097999274730682,
              -0.04892930015921593,
              -0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              -0.050855301320552826,
              0.2695069909095764,
              -0.01891930028796196,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              -0.059531401842832565,
              0.19862000644207,
              -0.04147280007600784,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.059248898178339005,
              0.20097999274730682,
              -0.04892930015921593,
              -0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              0,
              0.19860200583934784,
              -0.04141410067677498,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              -0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              -0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              -0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              -0.059531401842832565,
              0.19862000644207,
              -0.04147280007600784,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              -0.05094520002603531,
              0.2708210051059723,
              -0.02629780024290085,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              -0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              -0.05094520002603531,
              0.2708210051059723,
              -0.02629780024290085,
              -0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              -0.050855301320552826,
              0.2695069909095764,
              -0.01891930028796196,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              -0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              -0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              -0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              0.07833889871835709,
              0.04159890115261078,
              -0.09128779917955399,
              0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              0.07833889871835709,
              0.04159890115261078,
              -0.09128779917955399,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              0.07833889871835709,
              0.04159890115261078,
              -0.09128779917955399,
              0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              0,
              0.04157029837369919,
              -0.0912322998046875,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              0,
              0.04157029837369919,
              -0.0912322998046875,
              0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              0,
              0.04531560093164444,
              -0.09849770367145538,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              -0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              -0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              -0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              -0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              -0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              -0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              -0.07833880186080933,
              0.04159890115261078,
              -0.09128790348768234,
              -0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              -0.07833880186080933,
              0.04159890115261078,
              -0.09128790348768234,
              -0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              -0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              -0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              -0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              -0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              -0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              -0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              -0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              -0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              -0.07833880186080933,
              0.04159890115261078,
              -0.09128790348768234,
              -0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              -0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              -0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              -0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              -0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              -0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              -0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              -0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              -0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              -0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              -0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              -0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              -0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              -0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              -0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              -0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              -0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              -0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              -0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              -0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              -0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              -0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              -0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              -0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              -0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              -0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              -0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              0,
              0.04157029837369919,
              -0.0912322998046875,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              -0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              -0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              -0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              -0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              -0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              -0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              -0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              -0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              -0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              -0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              -0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              -0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              -0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              -0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              -0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              -0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              -0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              -0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              -0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              -0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              -0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              -0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              -0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              -0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              -0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              -0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              -0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              -0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              -0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              -0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              -0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              -0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              -0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              -0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              -0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              -0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              -0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              -0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              -0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              -0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              0,
              0.04531560093164444,
              -0.09849770367145538,
              -0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              0,
              0.04531560093164444,
              -0.09849770367145538,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              -0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              -0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              -0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              -0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              -0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              -0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              -0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              -0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              -0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              -0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              -0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              -0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              -0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              -0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              -0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              -0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              -0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              -0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              -0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              -0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              -0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              -0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              -0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              -0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              -0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              -0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              -0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              -0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              -0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              -0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              -0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              -0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              -0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              -0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              -0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              -0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              -0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              -0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              -0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              -0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              -0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              -0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              -0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              -0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              -0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              -0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              -0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              -0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              -0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              -0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              -0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              -0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              -0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              -0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              -0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              -0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              -0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              -0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              -0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              -0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              -0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              -0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              -0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              -0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              -0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              -0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              -0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              -0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              -0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              -0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              -0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              -0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              -0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              -0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              -0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              -0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              -0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              -0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              -0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              -0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              -0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              -0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              -0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              -0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              -0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              -0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              -0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              -0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              -0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              -0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              -0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              -0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              -0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              -0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              -0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              0,
              0.19860200583934784,
              -0.04141410067677498,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              0,
              0.19860200583934784,
              -0.04141410067677498,
              -0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              0,
              0.200997993350029,
              -0.04898789897561073,
              0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              0,
              0.200997993350029,
              -0.04898789897561073,
              0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              0.059248898178339005,
              0.20097999274730682,
              -0.04892919957637787,
              0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              0.059531401842832565,
              0.19862000644207,
              -0.041472699493169785,
              0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              0.059531401842832565,
              0.19862000644207,
              -0.041472699493169785,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              0.059531401842832565,
              0.19862000644207,
              -0.041472699493169785,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              0.059248898178339005,
              0.20097999274730682,
              -0.04892919957637787,
              0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              0.059248898178339005,
              0.20097999274730682,
              -0.04892919957637787,
              0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              0,
              0.19860200583934784,
              -0.04141410067677498,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              -0.059248898178339005,
              0.20097999274730682,
              -0.04892930015921593,
              -0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              -0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              -0.059248898178339005,
              0.20097999274730682,
              -0.04892930015921593,
              -0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              -0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              -0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              -0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              -0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              -0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              -0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              -0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              -0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              -0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              -0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              -0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              -0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              -0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              -0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              -0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              -0.059531401842832565,
              0.19862000644207,
              -0.04147280007600784,
              -0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              -0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              -0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              -0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              -0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              -0.059531401842832565,
              0.19862000644207,
              -0.04147280007600784,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              -0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              -0.059531401842832565,
              0.19862000644207,
              -0.04147280007600784,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              -0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              -0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              -0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              -0.059248898178339005,
              0.20097999274730682,
              -0.04892930015921593,
              -0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              -0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              -0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              -0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              -0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              -0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              -0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              -0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              -0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              -0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              -0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              -0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              -0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              -0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              -0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              -0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              -0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              -0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              -0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              -0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              0,
              0.200997993350029,
              -0.04898789897561073,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              -0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              -0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              -0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              -0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              -0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              -0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              -0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              -0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              -0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              -0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              -0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              -0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              -0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              -0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              0.29335299134254456,
              0.5174450278282166,
              -0.019625499844551086,
              0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              0.2200150042772293,
              0.08088339865207672,
              0.06523320078849792,
              0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              0.29335400462150574,
              0.08170349895954132,
              0.06479089707136154,
              0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              0.2933630049228668,
              0.29742300510406494,
              0.015340199694037437,
              0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              0.2933630049228668,
              0.29742300510406494,
              0.015340199694037437,
              0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              0.2933630049228668,
              0.29742300510406494,
              0.015340199694037437,
              0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              0.22002199292182922,
              0.17710499465465546,
              0.0264274999499321,
              0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              0.22002199292182922,
              0.4919399917125702,
              -0.021792300045490265,
              0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              0.22002199292182922,
              0.4919399917125702,
              -0.021792300045490265,
              0.22002199292182922,
              0.10316000133752823,
              0.053778599947690964,
              0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              0.2933630049228668,
              0.14018499851226807,
              0.046517498791217804,
              0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              0.22002199292182922,
              0.4919399917125702,
              -0.021792300045490265,
              0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.29335400462150574,
              0.08170349895954132,
              0.06479089707136154,
              0.22002199292182922,
              0.10316000133752823,
              0.053778599947690964,
              0.29335400462150574,
              0.08170349895954132,
              0.06479089707136154,
              0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              0.22002199292182922,
              0.10316000133752823,
              0.053778599947690964,
              0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              0.3176969885826111,
              0.2991639971733093,
              0.02280390076339245,
              0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              0.3176969885826111,
              0.2991639971733093,
              0.02280390076339245,
              0.3176969885826111,
              0.2991639971733093,
              0.02280390076339245,
              0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              0.3165220022201538,
              0.18078899383544922,
              0.04538070037961006,
              0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              0.3176969885826111,
              0.14181099832057953,
              0.053390100598335266,
              0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              0.3165220022201538,
              0.4932210147380829,
              -0.01519909966737032,
              0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              0.3165220022201538,
              0.4932210147380829,
              -0.01519909966737032,
              0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              0.3165220022201538,
              0.10444100201129913,
              0.060371801257133484,
              0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              0,
              0.5174450278282166,
              -0.019625499844551086,
              0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              0,
              0.5159350037574768,
              -0.019741099327802658,
              0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              0,
              0.5159350037574768,
              -0.019741099327802658,
              0,
              0.08167990297079086,
              0.06466930359601974,
              0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              0,
              0.08088330179452896,
              0.06523320078849792,
              0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              0,
              0.08088330179452896,
              0.06523320078849792,
              0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              0.2200150042772293,
              0.08088339865207672,
              0.06523320078849792,
              0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              0.29721999168395996,
              0.5159609913825989,
              -0.019613299518823624,
              0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              0.29335299134254456,
              0.5174450278282166,
              -0.019625499844551086,
              0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              0.29335400462150574,
              0.08170349895954132,
              0.06479089707136154,
              0.29721999168395996,
              0.08170410245656967,
              0.06479740142822266,
              0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              0.29721999168395996,
              0.08170410245656967,
              0.06479740142822266,
              0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              0.30098798871040344,
              0.5155979990959167,
              -0.019537700340151787,
              0.29721999168395996,
              0.5159609913825989,
              -0.019613299518823624,
              0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              0.29721999168395996,
              0.5159609913825989,
              -0.019613299518823624,
              0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              0.30098798871040344,
              0.0820688009262085,
              0.06473159790039062,
              0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              0.29721999168395996,
              0.08170410245656967,
              0.06479740142822266,
              0.30098798871040344,
              0.0820688009262085,
              0.06473159790039062,
              0.30461201071739197,
              0.514519989490509,
              -0.019325200468301773,
              0.30098798871040344,
              0.5155979990959167,
              -0.019537700340151787,
              0.3051750063896179,
              0.5159289836883545,
              -0.01933090016245842,
              0.30098798871040344,
              0.5155979990959167,
              -0.019537700340151787,
              0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              0.3051750063896179,
              0.5159289836883545,
              -0.01933090016245842,
              0.3051750063896179,
              0.08239900320768356,
              0.06493859738111496,
              0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              0.30461201071739197,
              0.08314819633960724,
              0.06452509760856628,
              0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              0.30098798871040344,
              0.0820688009262085,
              0.06473159790039062,
              0.30461201071739197,
              0.08314819633960724,
              0.06452509760856628,
              0.3079429864883423,
              0.5127729773521423,
              -0.018983300775289536,
              0.30461201071739197,
              0.514519989490509,
              -0.019325200468301773,
              0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              0.30461201071739197,
              0.514519989490509,
              -0.019325200468301773,
              0.3051750063896179,
              0.5159289836883545,
              -0.01933090016245842,
              0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              0.3051750063896179,
              0.08239900320768356,
              0.06493859738111496,
              0.3079429864883423,
              0.0848957970738411,
              0.06418800354003906,
              0.3051750063896179,
              0.08239900320768356,
              0.06493859738111496,
              0.30461201071739197,
              0.08314819633960724,
              0.06452509760856628,
              0.3079429864883423,
              0.0848957970738411,
              0.06418800354003906,
              0.3108679950237274,
              0.510420024394989,
              -0.01851790025830269,
              0.3079429864883423,
              0.5127729773521423,
              -0.018983300775289536,
              0.3117560148239136,
              0.5116130113601685,
              -0.018491899594664574,
              0.3079429864883423,
              0.5127729773521423,
              -0.018983300775289536,
              0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              0.3117560148239136,
              0.5116130113601685,
              -0.018491899594664574,
              0.3117560148239136,
              0.0867151990532875,
              0.06409960240125656,
              0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              0.3108679950237274,
              0.08725090324878693,
              0.0637321025133133,
              0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              0.3079429864883423,
              0.0848957970738411,
              0.06418800354003906,
              0.3108679950237274,
              0.08725090324878693,
              0.0637321025133133,
              0.31327199935913086,
              0.5075439810752869,
              -0.017956199124455452,
              0.3108679950237274,
              0.510420024394989,
              -0.01851790025830269,
              0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              0.3108679950237274,
              0.510420024394989,
              -0.01851790025830269,
              0.3117560148239136,
              0.5116130113601685,
              -0.018491899594664574,
              0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              0.3117560148239136,
              0.0867151990532875,
              0.06409960240125656,
              0.31327199935913086,
              0.09012719988822937,
              0.06317199766635895,
              0.3117560148239136,
              0.0867151990532875,
              0.06409960240125656,
              0.3108679950237274,
              0.08725090324878693,
              0.0637321025133133,
              0.31327199935913086,
              0.09012719988822937,
              0.06317199766635895,
              0.3150550127029419,
              0.504269003868103,
              -0.017321500927209854,
              0.31327199935913086,
              0.5075439810752869,
              -0.017956199124455452,
              0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              0.31327199935913086,
              0.5075439810752869,
              -0.017956199124455452,
              0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              0.3150550127029419,
              0.09340210258960724,
              0.06253670156002045,
              0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              0.31327199935913086,
              0.09012719988822937,
              0.06317199766635895,
              0.3150550127029419,
              0.09340210258960724,
              0.06253670156002045,
              0.31615298986434937,
              0.5007110238075256,
              -0.01663759909570217,
              0.3150550127029419,
              0.504269003868103,
              -0.017321500927209854,
              0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              0.3150550127029419,
              0.504269003868103,
              -0.017321500927209854,
              0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              0.31615298986434937,
              0.09695850312709808,
              0.06184320151805878,
              0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              0.3150550127029419,
              0.09340210258960724,
              0.06253670156002045,
              0.31615298986434937,
              0.09695850312709808,
              0.06184320151805878,
              0.3165220022201538,
              0.49702098965644836,
              -0.01592710055410862,
              0.31615298986434937,
              0.5007110238075256,
              -0.01663759909570217,
              0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              0.31615298986434937,
              0.5007110238075256,
              -0.01663759909570217,
              0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              0.3165220022201538,
              0.1006459966301918,
              0.06112020090222359,
              0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              0.31615298986434937,
              0.09695850312709808,
              0.06184320151805878,
              0.3165220022201538,
              0.1006459966301918,
              0.06112020090222359,
              0.3165220022201538,
              0.4932210147380829,
              -0.01519909966737032,
              0.3165220022201538,
              0.49702098965644836,
              -0.01592710055410862,
              0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              0.3165220022201538,
              0.49702098965644836,
              -0.01592710055410862,
              0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              0.3165220022201538,
              0.10444100201129913,
              0.060371801257133484,
              0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              0.3165220022201538,
              0.1006459966301918,
              0.06112020090222359,
              0.3165220022201538,
              0.10444100201129913,
              0.060371801257133484,
              0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              0,
              0.37218400835990906,
              -0.02009419910609722,
              0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              0,
              0.3324989974498749,
              -0.014235200360417366,
              0,
              0.37218400835990906,
              -0.02009419910609722,
              0,
              0.25382199883461,
              0.0010578499641269445,
              0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              0,
              0.21483099460601807,
              0.010491999797523022,
              0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              0,
              0.21483099460601807,
              0.010491999797523022,
              0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              0,
              0.3324989974498749,
              -0.014235200360417366,
              0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              0,
              0.2930609881877899,
              -0.0071014100685715675,
              0,
              0.3324989974498749,
              -0.014235200360417366,
              0,
              0.2930609881877899,
              -0.0071014100685715675,
              0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              0,
              0.25382199883461,
              0.0010578499641269445,
              0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              0,
              0.25382199883461,
              0.0010578499641269445,
              0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              0.22002199292182922,
              0.33383700251579285,
              -0.007349540013819933,
              0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              0.1466819941997528,
              0.3326619863510132,
              -0.013394000008702278,
              0.1466819941997528,
              0.29322999715805054,
              -0.0062326998449862,
              0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              0.22002199292182922,
              0.2944589853286743,
              0.00009282270184485242,
              0.22002199292182922,
              0.25516098737716675,
              0.007943550124764442,
              0.1466819941997528,
              0.2539860010147095,
              0.0018991200486198068,
              0.2933630049228668,
              0.29742300510406494,
              0.015340199694037437,
              0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              0.2933630049228668,
              0.29742300510406494,
              0.015340199694037437,
              0.3165220022201538,
              0.29880398511886597,
              0.022443000227212906,
              0.2933630049228668,
              0.3367609977722168,
              0.00769134983420372,
              0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              0.3165220022201538,
              0.3381420075893402,
              0.014796299859881401,
              0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              0.2933630049228668,
              0.2580839991569519,
              0.022984400391578674,
              0.3165220022201538,
              0.2594650089740753,
              0.030089400708675385,
              0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              0,
              0.4532409906387329,
              -0.023140599951148033,
              0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              0,
              0.41236600279808044,
              -0.023398999124765396,
              0,
              0.4532409906387329,
              -0.023140599951148033,
              0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              0,
              0.41236600279808044,
              -0.023398999124765396,
              0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              0,
              0.37218400835990906,
              -0.02009419910609722,
              0,
              0.41236600279808044,
              -0.023398999124765396,
              0,
              0.21483099460601807,
              0.010491999797523022,
              0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              0,
              0.17633700370788574,
              0.022480299696326256,
              0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              0,
              0.17633700370788574,
              0.022480299696326256,
              0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              0.22002199292182922,
              0.41313400864601135,
              -0.019451800733804703,
              0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              0.22002199292182922,
              0.3733290135860443,
              -0.014206299558281898,
              0.1466819941997528,
              0.37233099341392517,
              -0.019336700439453125,
              0.1466819941997528,
              0.4124799966812134,
              -0.0228154007345438,
              0.1466819941997528,
              0.21497899293899536,
              0.011249500326812267,
              0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              0.22002199292182922,
              0.21597599983215332,
              0.01637990027666092,
              0.22002199292182922,
              0.17710499465465546,
              0.0264274999499321,
              0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              0.2933630049228668,
              0.3760969936847687,
              0.00003569589898688719,
              0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              0.3165220022201538,
              0.37748000025749207,
              0.007149280048906803,
              0.3165220022201538,
              0.18078899383544922,
              0.04538070037961006,
              0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              0.2933630049228668,
              0.21874399483203888,
              0.030621899291872978,
              0.3165220022201538,
              0.22012700140476227,
              0.03773539885878563,
              0.2933630049228668,
              0.4154300093650818,
              -0.00763530982658267,
              0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              0.3165220022201538,
              0.4168179929256439,
              -0.0004985950072295964,
              0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              0,
              0.49191299080848694,
              -0.021930299699306488,
              0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              0,
              0.4725799858570099,
              -0.022522199898958206,
              0,
              0.49191299080848694,
              -0.021930299699306488,
              0,
              0.12083700299263,
              0.045849401503801346,
              0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              0,
              0.10313300043344498,
              0.0536407008767128,
              0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              0,
              0.10313300043344498,
              0.0536407008767128,
              0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              0,
              0.4725799858570099,
              -0.022522199898958206,
              0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              0,
              0.4532409906387329,
              -0.023140599951148033,
              0,
              0.4725799858570099,
              -0.022522199898958206,
              0,
              0.1385360062122345,
              0.03803170099854469,
              0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              0,
              0.12083700299263,
              0.045849401503801346,
              0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              0,
              0.12083700299263,
              0.045849401503801346,
              0.22002199292182922,
              0.4919399917125702,
              -0.021792300045490265,
              0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              0.22002199292182922,
              0.10316000133752823,
              0.053778599947690964,
              0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              0.22002199292182922,
              0.47271299362182617,
              -0.0218362994492054,
              0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              0.22002199292182922,
              0.4534280002117157,
              -0.02218100056052208,
              0.1466819941997528,
              0.4532740116119385,
              -0.02297160029411316,
              0.1466819941997528,
              0.4726020097732544,
              -0.022404799237847328,
              0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              0.22002199292182922,
              0.12097000330686569,
              0.046535298228263855,
              0.1466819941997528,
              0.12086000293493271,
              0.045966800302267075,
              0.2933630049228668,
              0.45489001274108887,
              -0.014654899947345257,
              0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              0.3165220022201538,
              0.4561629891395569,
              -0.008110069669783115,
              0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              0.2933630049228668,
              0.14018499851226807,
              0.046517498791217804,
              0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              0.2933630049228668,
              0.47363901138305664,
              -0.017069900408387184,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.3165220022201538,
              0.4932210147380829,
              -0.01519909966737032,
              0.3165220022201538,
              0.4746930003166199,
              -0.011649100109934807,
              0.3165220022201538,
              0.10444100201129913,
              0.060371801257133484,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.2933630049228668,
              0.12189699709415436,
              0.05130179971456528,
              0.3165220022201538,
              0.1229500025510788,
              0.056722600013017654,
              0,
              0.5159350037574768,
              -0.019741099327802658,
              0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              0,
              0.49191299080848694,
              -0.021930299699306488,
              0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              0,
              0.49191299080848694,
              -0.021930299699306488,
              0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              0,
              0.10313300043344498,
              0.0536407008767128,
              0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              0,
              0.08167990297079086,
              0.06466930359601974,
              0,
              0.10313300043344498,
              0.0536407008767128,
              0.14667700231075287,
              0.515936017036438,
              -0.019735200330615044,
              0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              0.2200150042772293,
              0.5159369707107544,
              -0.019733000546693802,
              0.22002199292182922,
              0.4919399917125702,
              -0.021792300045490265,
              0.1466819941997528,
              0.49192100763320923,
              -0.02188660018146038,
              0.22002199292182922,
              0.10316000133752823,
              0.053778599947690964,
              0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              0.2200150042772293,
              0.0816814973950386,
              0.06467749923467636,
              0.14667700231075287,
              0.08168099820613861,
              0.06467530131340027,
              0.1466819941997528,
              0.10314200073480606,
              0.053684301674366,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.29335400462150574,
              0.5159590244293213,
              -0.019619600847363472,
              0.29721999168395996,
              0.5159609913825989,
              -0.019613299518823624,
              0.29335400462150574,
              0.08170349895954132,
              0.06479089707136154,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.29721999168395996,
              0.08170410245656967,
              0.06479740142822266,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.29721999168395996,
              0.5159609913825989,
              -0.019613299518823624,
              0.30098798871040344,
              0.5155979990959167,
              -0.019537700340151787,
              0.29721999168395996,
              0.08170410245656967,
              0.06479740142822266,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.30098798871040344,
              0.0820688009262085,
              0.06473159790039062,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.30098798871040344,
              0.5155979990959167,
              -0.019537700340151787,
              0.30461201071739197,
              0.514519989490509,
              -0.019325200468301773,
              0.30098798871040344,
              0.0820688009262085,
              0.06473159790039062,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.30461201071739197,
              0.08314819633960724,
              0.06452509760856628,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.30461201071739197,
              0.514519989490509,
              -0.019325200468301773,
              0.3079429864883423,
              0.5127729773521423,
              -0.018983300775289536,
              0.30461201071739197,
              0.08314819633960724,
              0.06452509760856628,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.3079429864883423,
              0.0848957970738411,
              0.06418800354003906,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.3079429864883423,
              0.5127729773521423,
              -0.018983300775289536,
              0.3108679950237274,
              0.510420024394989,
              -0.01851790025830269,
              0.3079429864883423,
              0.0848957970738411,
              0.06418800354003906,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.3108679950237274,
              0.08725090324878693,
              0.0637321025133133,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.3108679950237274,
              0.510420024394989,
              -0.01851790025830269,
              0.31327199935913086,
              0.5075439810752869,
              -0.017956199124455452,
              0.3108679950237274,
              0.08725090324878693,
              0.0637321025133133,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.31327199935913086,
              0.09012719988822937,
              0.06317199766635895,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.31327199935913086,
              0.5075439810752869,
              -0.017956199124455452,
              0.3150550127029419,
              0.504269003868103,
              -0.017321500927209854,
              0.31327199935913086,
              0.09012719988822937,
              0.06317199766635895,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.3150550127029419,
              0.09340210258960724,
              0.06253670156002045,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.3150550127029419,
              0.504269003868103,
              -0.017321500927209854,
              0.31615298986434937,
              0.5007110238075256,
              -0.01663759909570217,
              0.3150550127029419,
              0.09340210258960724,
              0.06253670156002045,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.31615298986434937,
              0.09695850312709808,
              0.06184320151805878,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.31615298986434937,
              0.5007110238075256,
              -0.01663759909570217,
              0.3165220022201538,
              0.49702098965644836,
              -0.01592710055410862,
              0.31615298986434937,
              0.09695850312709808,
              0.06184320151805878,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.3165220022201538,
              0.1006459966301918,
              0.06112020090222359,
              0.2933630049228668,
              0.4924820065498352,
              -0.019002199172973633,
              0.3165220022201538,
              0.49702098965644836,
              -0.01592710055410862,
              0.3165220022201538,
              0.4932210147380829,
              -0.01519909966737032,
              0.3165220022201538,
              0.1006459966301918,
              0.06112020090222359,
              0.2933630049228668,
              0.10370200127363205,
              0.056568700820207596,
              0.3165220022201538,
              0.10444100201129913,
              0.060371801257133484,
              0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              0.3010759949684143,
              0.08690600097179413,
              0.09494239836931229,
              0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              0.30484798550605774,
              0.0881597027182579,
              0.09473749995231628,
              0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              0.30484798550605774,
              0.5211910009384155,
              0.010564999654889107,
              0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              0.3010759949684143,
              0.5224300026893616,
              0.010285300202667713,
              0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              0.31406301259994507,
              0.09542439877986908,
              0.09328660368919373,
              0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              0.3114379942417145,
              0.09248179942369461,
              0.09389740228652954,
              0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              0.3114379942417145,
              0.5168690085411072,
              0.011405100114643574,
              0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              0.31406301259994507,
              0.5139120221138,
              0.011941099539399147,
              0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              0.29300999641418457,
              0.08664199709892273,
              0.09503249824047089,
              0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              0.29300999641418457,
              0.5227090120315552,
              0.01027000043541193,
              0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              0.31406301259994507,
              0.09542439877986908,
              0.09328660368919373,
              0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              0.31406301259994507,
              0.5139120221138,
              0.011941099539399147,
              0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              0.3010759949684143,
              0.08690600097179413,
              0.09494239836931229,
              0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              0.3010759949684143,
              0.5224300026893616,
              0.010285300202667713,
              0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              0.30843299627304077,
              0.08989720046520233,
              0.09436099976301193,
              0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              0.3114379942417145,
              0.09248179942369461,
              0.09389740228652954,
              0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              0.3114379942417145,
              0.5168690085411072,
              0.011405100114643574,
              0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              0.30843299627304077,
              0.5194389820098877,
              0.010866699740290642,
              0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              0.30843299627304077,
              0.08989720046520233,
              0.09436099976301193,
              0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              0.30484798550605774,
              0.0881597027182579,
              0.09473749995231628,
              0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              0.30484798550605774,
              0.5211910009384155,
              0.010564999654889107,
              0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              0.30843299627304077,
              0.5194389820098877,
              0.010866699740290642,
              0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              0.29300999641418457,
              0.08664199709892273,
              0.09503249824047089,
              0,
              0.08664199709892273,
              0.09503249824047089,
              0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              0,
              0.08664199709892273,
              0.09503249824047089,
              0,
              0.08652099967002869,
              0.0950172021985054,
              0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              0,
              0.5228149890899658,
              0.010210500098764896,
              0,
              0.5227090120315552,
              0.01027000043541193,
              0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              0,
              0.5227090120315552,
              0.01027000043541193,
              0.29300999641418457,
              0.5227090120315552,
              0.01027000043541193,
              0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              0.3172299861907959,
              0.5027620196342468,
              0.014174699783325195,
              0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              0.3172299861907959,
              0.5027620196342468,
              0.014174699783325195,
              0.3172299861907959,
              0.49879100918769836,
              0.01494670007377863,
              0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              0.31738701462745667,
              0.10657999664545059,
              0.09115699678659439,
              0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              0.31684398651123047,
              0.50661700963974,
              0.013425500132143497,
              0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              0.31684398651123047,
              0.50661700963974,
              0.013425500132143497,
              0.3172299861907959,
              0.5027620196342468,
              0.014174699783325195,
              0.31738701462745667,
              0.5027710199356079,
              0.01414559967815876,
              0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              0.3169960081577301,
              0.10268999636173248,
              0.09191299974918365,
              0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              0.31569600105285645,
              0.5103350281715393,
              0.01270269975066185,
              0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              0.31569600105285645,
              0.5103350281715393,
              0.01270269975066185,
              0.31684398651123047,
              0.50661700963974,
              0.013425500132143497,
              0.3169960081577301,
              0.506659984588623,
              0.013389499858021736,
              0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              0.3158409893512726,
              0.09895019978284836,
              0.09264010190963745,
              0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              0.31383299827575684,
              0.5137580037117004,
              0.012037400156259537,
              0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              0.31383299827575684,
              0.5137580037117004,
              0.012037400156259537,
              0.31569600105285645,
              0.5103350281715393,
              0.01270269975066185,
              0.3158409893512726,
              0.5104010105133057,
              0.012662399560213089,
              0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              0.3114379942417145,
              0.09248179942369461,
              0.09389740228652954,
              0.31396400928497314,
              0.09550320357084274,
              0.09331010282039642,
              0.3114379942417145,
              0.5168690085411072,
              0.011405100114643574,
              0.3113180100917816,
              0.5167660117149353,
              0.011452700011432171,
              0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              0.3113180100917816,
              0.5167660117149353,
              0.011452700011432171,
              0.31383299827575684,
              0.5137580037117004,
              0.012037400156259537,
              0.31396400928497314,
              0.5138480067253113,
              0.011992399580776691,
              0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              0.3114379942417145,
              0.09248179942369461,
              0.09389740228652954,
              0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              0.3114379942417145,
              0.09248179942369461,
              0.09389740228652954,
              0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              0.3082610070705414,
              0.5192270278930664,
              0.010974300093948841,
              0.3114379942417145,
              0.5168690085411072,
              0.011405100114643574,
              0.3082610070705414,
              0.5192270278930664,
              0.010974300093948841,
              0.3113180100917816,
              0.5167660117149353,
              0.011452700011432171,
              0.3114379942417145,
              0.5168690085411072,
              0.011405100114643574,
              0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              0.30484798550605774,
              0.0881597027182579,
              0.09473749995231628,
              0.3083600103855133,
              0.09000220149755478,
              0.09437940269708633,
              0.30484798550605774,
              0.5211910009384155,
              0.010564999654889107,
              0.3047800064086914,
              0.5210530161857605,
              0.010619400069117546,
              0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              0.3047800064086914,
              0.5210530161857605,
              0.010619400069117546,
              0.3082610070705414,
              0.5192270278930664,
              0.010974300093948841,
              0.3083600103855133,
              0.5193489789962769,
              0.01092309970408678,
              0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              0.30484798550605774,
              0.0881597027182579,
              0.09473749995231628,
              0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              0.30484798550605774,
              0.0881597027182579,
              0.09473749995231628,
              0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              0.3009909987449646,
              0.5221800208091736,
              0.010400200262665749,
              0.30484798550605774,
              0.5211910009384155,
              0.010564999654889107,
              0.3009909987449646,
              0.5221800208091736,
              0.010400200262665749,
              0.3047800064086914,
              0.5210530161857605,
              0.010619400069117546,
              0.30484798550605774,
              0.5211910009384155,
              0.010564999654889107,
              0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              0.30103799700737,
              0.08702520281076431,
              0.09495809674263,
              0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              0.2970519959926605,
              0.5225610136985779,
              0.01032629981637001,
              0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              0.2970519959926605,
              0.5225610136985779,
              0.01032629981637001,
              0.3009909987449646,
              0.5221800208091736,
              0.010400200262665749,
              0.30103799700737,
              0.5223259925842285,
              0.010344499722123146,
              0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              0.29300999641418457,
              0.08664199709892273,
              0.09503249824047089,
              0.2970750033855438,
              0.08664199709892273,
              0.09503260254859924,
              0.29300999641418457,
              0.5227090120315552,
              0.01027000043541193,
              0.29300999641418457,
              0.5225600004196167,
              0.010326500050723553,
              0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              0.29300999641418457,
              0.5225600004196167,
              0.010326500050723553,
              0.2970519959926605,
              0.5225610136985779,
              0.01032629981637001,
              0.2970750033855438,
              0.5227090120315552,
              0.01027000043541193,
              0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              0,
              0.08680140227079391,
              0.09502910077571869,
              0.29300999641418457,
              0.08664199709892273,
              0.09503249824047089,
              0,
              0.08680140227079391,
              0.09502910077571869,
              0,
              0.08664199709892273,
              0.09503249824047089,
              0.29300999641418457,
              0.08664199709892273,
              0.09503249824047089,
              0,
              0.5227090120315552,
              0.01027000043541193,
              0,
              0.5225600004196167,
              0.010326500050723553,
              0.29300999641418457,
              0.5227090120315552,
              0.01027000043541193,
              0,
              0.5225600004196167,
              0.010326500050723553,
              0.29300999641418457,
              0.5225600004196167,
              0.010326500050723553,
              0.29300999641418457,
              0.5227090120315552,
              0.01027000043541193,
              0.2974109947681427,
              0.11059299856424332,
              0.09052319824695587,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.2974109947681427,
              0.10977199673652649,
              0.09068270027637482,
              0.2974109947681427,
              0.10977199673652649,
              0.09068270027637482,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.29736098647117615,
              0.10915900021791458,
              0.09080199897289276,
              0.29736098647117615,
              0.10915900021791458,
              0.09080199897289276,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.2971540093421936,
              0.10846500098705292,
              0.09093669801950455,
              0.2971540093421936,
              0.10846500098705292,
              0.09093669801950455,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.29683101177215576,
              0.10785999894142151,
              0.09105449914932251,
              0.29683101177215576,
              0.10785999894142151,
              0.09105449914932251,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.29633501172065735,
              0.10726600140333176,
              0.0911697968840599,
              0.29633501172065735,
              0.10726600140333176,
              0.0911697968840599,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.2957879900932312,
              0.10683800280094147,
              0.09125299751758575,
              0.2957879900932312,
              0.10683800280094147,
              0.09125299751758575,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.29521799087524414,
              0.10654699802398682,
              0.09130959957838058,
              0.29521799087524414,
              0.10654699802398682,
              0.09130959957838058,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.2945069968700409,
              0.1063390001654625,
              0.09135019779205322,
              0.2945069968700409,
              0.1063390001654625,
              0.09135019779205322,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.2937780022621155,
              0.10627400130033493,
              0.09136269986629486,
              0.2937780022621155,
              0.10627400130033493,
              0.09136269986629486,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0,
              0.11059200018644333,
              0.09052109718322754,
              0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              0,
              0.11059200018644333,
              0.09052109718322754,
              0,
              0.10628099739551544,
              0.09135960042476654,
              0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              0.3010759949684143,
              0.08690600097179413,
              0.09494239836931229,
              0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              0.3010759949684143,
              0.08690600097179413,
              0.09494239836931229,
              0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              0.3010759949684143,
              0.5224300026893616,
              0.010285300202667713,
              0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              0.3010759949684143,
              0.5224300026893616,
              0.010285300202667713,
              0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              0.3010759949684143,
              0.08624079823493958,
              0.09152019768953323,
              0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              0.3010759949684143,
              0.5217649936676025,
              0.006863050162792206,
              0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              0.31406301259994507,
              0.09542439877986908,
              0.09328660368919373,
              0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              0.31406301259994507,
              0.5139120221138,
              0.011941099539399147,
              0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              0.31406301259994507,
              0.09475920349359512,
              0.08986440300941467,
              0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              0.31406301259994507,
              0.09475920349359512,
              0.08986440300941467,
              0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              0.31406301259994507,
              0.513247013092041,
              0.008518850430846214,
              0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              0.31406301259994507,
              0.513247013092041,
              0.008518850430846214,
              0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              0.29300999641418457,
              0.08585579693317413,
              0.09159500151872635,
              0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              0.31406301259994507,
              0.09542439877986908,
              0.09328660368919373,
              0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              0.31406301259994507,
              0.09542439877986908,
              0.09328660368919373,
              0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              0.31406301259994507,
              0.5139120221138,
              0.011941099539399147,
              0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              0.31406301259994507,
              0.5139120221138,
              0.011941099539399147,
              0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              0.31406301259994507,
              0.09537939727306366,
              0.09305520355701447,
              0.31406301259994507,
              0.09475920349359512,
              0.08986440300941467,
              0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              0.31406301259994507,
              0.513247013092041,
              0.008518850430846214,
              0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              0.31406301259994507,
              0.5138670206069946,
              0.011709700338542461,
              0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              0.3010759949684143,
              0.08690600097179413,
              0.09494239836931229,
              0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              0.29709500074386597,
              0.08652109652757645,
              0.0950172021985054,
              0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              0.29709500074386597,
              0.5228149890899658,
              0.010210500098764896,
              0.3010759949684143,
              0.5224300026893616,
              0.010285300202667713,
              0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              0.3010759949684143,
              0.08686099946498871,
              0.09471099823713303,
              0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              0.3010759949684143,
              0.08624079823493958,
              0.09152019768953323,
              0.29709500074386597,
              0.08647610247135162,
              0.09478580206632614,
              0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              0.3010759949684143,
              0.08624079823493958,
              0.09152019768953323,
              0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              0.3010759949684143,
              0.5217649936676025,
              0.006863050162792206,
              0.29709500074386597,
              0.5227699875831604,
              0.009979070164263248,
              0.3010759949684143,
              0.5223850011825562,
              0.010053900070488453,
              0.3010759949684143,
              0.5217649936676025,
              0.006863050162792206,
              0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              0.3159489929676056,
              0.09888769686222076,
              0.09261339902877808,
              0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              0.3159489929676056,
              0.5104489922523499,
              0.012614299543201923,
              0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              0.3159489929676056,
              0.09884270280599594,
              0.09238199889659882,
              0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              0.3159489929676056,
              0.5104039907455444,
              0.012382900342345238,
              0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              0.31152498722076416,
              0.09238860011100769,
              0.09387669712305069,
              0.30843299627304077,
              0.08989720046520233,
              0.09436099976301193,
              0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              0.30843299627304077,
              0.08989720046520233,
              0.09436099976301193,
              0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              0.30843299627304077,
              0.5194389820098877,
              0.010866699740290642,
              0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              0.30843299627304077,
              0.5194389820098877,
              0.010866699740290642,
              0.31152498722076416,
              0.5169479846954346,
              0.01135099958628416,
              0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              0.31152498722076416,
              0.09234359860420227,
              0.09364529699087143,
              0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              0.30843299627304077,
              0.08923199772834778,
              0.09093870222568512,
              0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              0.30843299627304077,
              0.5187739729881287,
              0.007444479968398809,
              0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              0.31152498722076416,
              0.5169029831886292,
              0.011119600385427475,
              0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              0.3175030052661896,
              0.10655400156974792,
              0.09112320095300674,
              0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              0.31711000204086304,
              0.10264600068330765,
              0.09188289940357208,
              0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              0.31711000204086304,
              0.5066909790039062,
              0.013344800099730492,
              0.3175030052661896,
              0.5027819871902466,
              0.014104500412940979,
              0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              0.3175030052661896,
              0.1065090000629425,
              0.09089180082082748,
              0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              0.31711000204086304,
              0.10260099917650223,
              0.09165149927139282,
              0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              0.31711000204086304,
              0.5066459774971008,
              0.013113399967551231,
              0.3175030052661896,
              0.5027369856834412,
              0.013873100280761719,
              0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              0.30843299627304077,
              0.08989720046520233,
              0.09436099976301193,
              0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              0.3049040138721466,
              0.08804599940776825,
              0.09472080320119858,
              0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              0.3049040138721466,
              0.5212900042533875,
              0.010506900027394295,
              0.30843299627304077,
              0.5194389820098877,
              0.010866699740290642,
              0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              0.30843299627304077,
              0.08985219895839691,
              0.09412959963083267,
              0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              0.30843299627304077,
              0.08923199772834778,
              0.09093870222568512,
              0.3049040138721466,
              0.08800099790096283,
              0.09448940306901932,
              0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              0.30843299627304077,
              0.08923199772834778,
              0.09093870222568512,
              0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              0.30843299627304077,
              0.5187739729881287,
              0.007444479968398809,
              0.3049040138721466,
              0.521245002746582,
              0.010275499895215034,
              0.30843299627304077,
              0.5193939805030823,
              0.010635299608111382,
              0.30843299627304077,
              0.5187739729881287,
              0.007444479968398809,
              0.29300999641418457,
              0.08652099967002869,
              0.0950172021985054,
              0,
              0.08652099967002869,
              0.0950172021985054,
              0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              0,
              0.08652099967002869,
              0.0950172021985054,
              0,
              0.08647610247135162,
              0.09478580206632614,
              0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              0,
              0.5227699875831604,
              0.009979059919714928,
              0,
              0.5228149890899658,
              0.010210500098764896,
              0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              0,
              0.5228149890899658,
              0.010210500098764896,
              0.29300999641418457,
              0.5228149890899658,
              0.010210500098764896,
              0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              0.29300999641418457,
              0.08647610247135162,
              0.09478580206632614,
              0,
              0.08647610247135162,
              0.09478580206632614,
              0.29300999641418457,
              0.08585579693317413,
              0.09159500151872635,
              0,
              0.08647610247135162,
              0.09478580206632614,
              0,
              0.08585579693317413,
              0.09159500151872635,
              0.29300999641418457,
              0.08585579693317413,
              0.09159500151872635,
              0,
              0.5221499800682068,
              0.006788210012018681,
              0,
              0.5227699875831604,
              0.009979059919714928,
              0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              0,
              0.5227699875831604,
              0.009979059919714928,
              0.29300999641418457,
              0.5227699875831604,
              0.009979059919714928,
              0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              0.3052479922771454,
              0.08288119733333588,
              0.06815049797296524,
              0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              0.3052479922771454,
              0.5164049863815308,
              -0.016117800027132034,
              0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              0.30877599120140076,
              0.08420459926128387,
              0.06507500261068344,
              0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              0.30877599120140076,
              0.5143060088157654,
              -0.018528100103139877,
              0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              0.31186801195144653,
              0.08722390234470367,
              0.06730639934539795,
              0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              0.31186801195144653,
              0.08722390234470367,
              0.06730639934539795,
              0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              0.31186801195144653,
              0.5120630264282227,
              -0.015273699536919594,
              0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              0.31186801195144653,
              0.5120630264282227,
              -0.015273699536919594,
              0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              0.30877599120140076,
              0.08420459926128387,
              0.06507500261068344,
              0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              0.30877599120140076,
              0.08435670286417007,
              0.06585749983787537,
              0.30877599120140076,
              0.08420459926128387,
              0.06507500261068344,
              0.30877599120140076,
              0.5144580006599426,
              -0.017745599150657654,
              0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              0.30877599120140076,
              0.5143060088157654,
              -0.018528100103139877,
              0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              0.30877599120140076,
              0.5143060088157654,
              -0.018528100103139877,
              0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              0.3174540102481842,
              0.09710530191659927,
              0.06337939947843552,
              0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              0.3174540102481842,
              0.5017099976539612,
              -0.015267499722540379,
              0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              0.3014189898967743,
              0.08121339976787567,
              0.0656564012169838,
              0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              0.3014189898967743,
              0.5172979831695557,
              -0.01910950057208538,
              0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              0.31440600752830505,
              0.08973179757595062,
              0.06400059908628464,
              0.3162919878959656,
              0.09334719926118851,
              0.06410989910364151,
              0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              0.31440600752830505,
              0.08973179757595062,
              0.06400059908628464,
              0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              0.31440600752830505,
              0.508778989315033,
              -0.017453700304031372,
              0.3162919878959656,
              0.5054680109024048,
              -0.015998000279068947,
              0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              0.31440600752830505,
              0.508778989315033,
              -0.017453700304031372,
              0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              0.2931390106678009,
              0.08135630190372467,
              0.06844689697027206,
              0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              0.29335299134254456,
              0.5179299712181091,
              -0.016414200887084007,
              0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              0.29743799567222595,
              0.08098059892654419,
              0.06651370227336884,
              0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              0.29743799567222595,
              0.5178350210189819,
              -0.018401900306344032,
              0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              0.31186801195144653,
              0.08722390234470367,
              0.06730639934539795,
              0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              0.31186801195144653,
              0.5120630264282227,
              -0.015273699536919594,
              0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              0.31440600752830505,
              0.08973179757595062,
              0.06400059908628464,
              0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              0.31440600752830505,
              0.08988379687070847,
              0.06478309631347656,
              0.31186801195144653,
              0.08684810250997543,
              0.06537319719791412,
              0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              0.31186801195144653,
              0.5119670033454895,
              -0.01726130023598671,
              0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              0.31440600752830505,
              0.5089309811592102,
              -0.01667119935154915,
              0.31440600752830505,
              0.508778989315033,
              -0.017453700304031372,
              0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              0.3052479922771454,
              0.08288119733333588,
              0.06815049797296524,
              0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              0.3052479922771454,
              0.08288119733333588,
              0.06815049797296524,
              0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              0.3052479922771454,
              0.5164049863815308,
              -0.016117800027132034,
              0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              0.3052479922771454,
              0.5164049863815308,
              -0.016117800027132034,
              0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              0.3014189898967743,
              0.08121339976787567,
              0.0656564012169838,
              0.3052479922771454,
              0.08250550180673599,
              0.06621730327606201,
              0.3014189898967743,
              0.08136550337076187,
              0.06643889844417572,
              0.3014189898967743,
              0.08121339976787567,
              0.0656564012169838,
              0.3014189898967743,
              0.5174499750137329,
              -0.018326999619603157,
              0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              0.3014189898967743,
              0.5172979831695557,
              -0.01910950057208538,
              0.3052479922771454,
              0.5163099765777588,
              -0.01810540072619915,
              0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              0.3014189898967743,
              0.5172979831695557,
              -0.01910950057208538,
              0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              0.31784600019454956,
              0.10101400315761566,
              0.06261970102787018,
              0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              0.31784600019454956,
              0.4978019893169403,
              -0.01450789999216795,
              0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              0.30877599120140076,
              0.08420459926128387,
              0.06507500261068344,
              0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              0.3051750063896179,
              0.08239900320768356,
              0.06493859738111496,
              0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              0.3051750063896179,
              0.5159289836883545,
              -0.01933090016245842,
              0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              0.30877599120140076,
              0.5143060088157654,
              -0.018528100103139877,
              0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              0.30877599120140076,
              0.08420459926128387,
              0.06507500261068344,
              0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              0.30868199467658997,
              0.08423899859189987,
              0.064580999314785,
              0.3117560148239136,
              0.0867151990532875,
              0.06409960240125656,
              0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              0.3117560148239136,
              0.5116130113601685,
              -0.018491899594664574,
              0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              0.30868199467658997,
              0.5140889883041382,
              -0.018973199650645256,
              0.30877599120140076,
              0.5143060088157654,
              -0.018528100103139877,
              0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              0.3173069953918457,
              0.09690999984741211,
              0.06211800128221512,
              0.3174540102481842,
              0.09695319831371307,
              0.06259690225124359,
              0.3173069953918457,
              0.5014179944992065,
              -0.01651019975543022,
              0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              0.3174540102481842,
              0.5015580058097839,
              -0.016049999743700027,
              0.3014189898967743,
              0.08121339976787567,
              0.0656564012169838,
              0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              0.3014189898967743,
              0.5172979831695557,
              -0.01910950057208538,
              0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              0.31440600752830505,
              0.08973179757595062,
              0.06400059908628464,
              0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              0.31615298986434937,
              0.09317480027675629,
              0.06284400075674057,
              0.3162919878959656,
              0.0931951031088829,
              0.06332740187644958,
              0.31615298986434937,
              0.5051530003547668,
              -0.01723629981279373,
              0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              0.31440600752830505,
              0.508778989315033,
              -0.017453700304031372,
              0.3162919878959656,
              0.5053160190582275,
              -0.01678049936890602,
              0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              0.2974129915237427,
              0.08088330179452896,
              0.06523320078849792,
              0.29743799567222595,
              0.08082850277423859,
              0.06573119759559631,
              0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              0.29335299134254456,
              0.5174450278282166,
              -0.019625499844551086,
              0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              0.29743799567222595,
              0.5176820158958435,
              -0.019184399396181107,
              0.2974129915237427,
              0.5174450278282166,
              -0.019625499844551086,
              0.31440600752830505,
              0.08973179757595062,
              0.06400059908628464,
              0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              0.31186801195144653,
              0.08669599890708923,
              0.0645906999707222,
              0.3117560148239136,
              0.0867151990532875,
              0.06409960240125656,
              0.3142780065536499,
              0.08973249793052673,
              0.06351310014724731,
              0.3117560148239136,
              0.5116130113601685,
              -0.018491899594664574,
              0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              0.31186801195144653,
              0.5118150115013123,
              -0.018043799325823784,
              0.31440600752830505,
              0.508778989315033,
              -0.017453700304031372,
              0.3142780065536499,
              0.5085960030555725,
              -0.017905399203300476,
              0.3014189898967743,
              0.08121339976787567,
              0.0656564012169838,
              0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              0.3013699948787689,
              0.08126600086688995,
              0.0651589035987854,
              0.3051750063896179,
              0.08239900320768356,
              0.06493859738111496,
              0.3052479922771454,
              0.08235339820384979,
              0.06543479859828949,
              0.3051750063896179,
              0.5159289836883545,
              -0.01933090016245842,
              0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              0.3013699948787689,
              0.5170620083808899,
              -0.019551100209355354,
              0.3014189898967743,
              0.5172979831695557,
              -0.01910950057208538,
              0.3052479922771454,
              0.5161579847335815,
              -0.018887899816036224,
              0.31784600019454956,
              0.10086099803447723,
              0.061837200075387955,
              0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              0.3176969885826111,
              0.10079500079154968,
              0.06136289983987808,
              0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              0.3176969885826111,
              0.49753400683403015,
              -0.015755200758576393,
              0.31784600019454956,
              0.49764901399612427,
              -0.015290300361812115,
              0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              0.31784600019454956,
              0.10138899832963943,
              0.064552903175354,
              0.3175030052661896,
              0.10588899999856949,
              0.08770100027322769,
              0.31784600019454956,
              0.4978969991207123,
              -0.01252019964158535,
              0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              0.3175030052661896,
              0.5021169781684875,
              0.010682200081646442,
              0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              0.3174540102481842,
              0.09748110175132751,
              0.06531260162591934,
              0.31711000204086304,
              0.10198099911212921,
              0.08846069872379303,
              0.3174540102481842,
              0.5018060207366943,
              -0.013279899954795837,
              0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              0.31711000204086304,
              0.5060250163078308,
              0.009922539815306664,
              0.31406301259994507,
              0.09475920349359512,
              0.08986440300941467,
              0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              0.3162919878959656,
              0.09372299909591675,
              0.06604310125112534,
              0.3159489929676056,
              0.09822250157594681,
              0.08919110149145126,
              0.3162919878959656,
              0.5055639743804932,
              -0.014010399580001831,
              0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              0.31406301259994507,
              0.513247013092041,
              0.008518850430846214,
              0.3159489929676056,
              0.5097830295562744,
              0.00919205043464899,
              0.31406301259994507,
              0.09475920349359512,
              0.08986440300941467,
              0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              0.31186801195144653,
              0.08722390234470367,
              0.06730639934539795,
              0.31440600752830505,
              0.0902595967054367,
              0.06671629846096039,
              0.31186801195144653,
              0.5120630264282227,
              -0.015273699536919594,
              0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              0.31406301259994507,
              0.513247013092041,
              0.008518850430846214,
              0.31440600752830505,
              0.5090270042419434,
              -0.014683599583804607,
              0.30843299627304077,
              0.08923199772834778,
              0.09093870222568512,
              0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              0.31186801195144653,
              0.08722390234470367,
              0.06730639934539795,
              0.31152498722076416,
              0.09172339737415314,
              0.09045439958572388,
              0.31186801195144653,
              0.5120630264282227,
              -0.015273699536919594,
              0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              0.30843299627304077,
              0.5187739729881287,
              0.007444479968398809,
              0.31152498722076416,
              0.5162820219993591,
              0.007928749546408653,
              0.30843299627304077,
              0.08923199772834778,
              0.09093870222568512,
              0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              0.3052479922771454,
              0.08288119733333588,
              0.06815049797296524,
              0.30877599120140076,
              0.08473250269889832,
              0.06779070198535919,
              0.3052479922771454,
              0.5164049863815308,
              -0.016117800027132034,
              0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              0.30843299627304077,
              0.5187739729881287,
              0.007444479968398809,
              0.30877599120140076,
              0.5145540237426758,
              -0.015758000314235687,
              0.3010759949684143,
              0.08624079823493958,
              0.09152019768953323,
              0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              0.3052479922771454,
              0.08288119733333588,
              0.06815049797296524,
              0.3049040138721466,
              0.0873807966709137,
              0.09129860252141953,
              0.3052479922771454,
              0.5164049863815308,
              -0.016117800027132034,
              0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              0.3010759949684143,
              0.5217649936676025,
              0.006863050162792206,
              0.3049040138721466,
              0.5206249952316284,
              0.007084629964083433,
              0.3010759949684143,
              0.08624079823493958,
              0.09152019768953323,
              0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              0.3014189898967743,
              0.08174130320549011,
              0.06837210059165955,
              0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              0.3010759949684143,
              0.5217649936676025,
              0.006863050162792206,
              0.3014189898967743,
              0.5175449848175049,
              -0.01633940078318119,
              0.29709500074386597,
              0.08585590124130249,
              0.09159500151872635,
              0.29300999641418457,
              0.08585579693317413,
              0.09159500151872635,
              0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              0.29300999641418457,
              0.08585579693317413,
              0.09159500151872635,
              0.2931390106678009,
              0.08135630190372467,
              0.06844689697027206,
              0.29743799567222595,
              0.08135630190372467,
              0.06844689697027206,
              0.29335299134254456,
              0.5179299712181091,
              -0.016414200887084007,
              0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              0.29709500074386597,
              0.5221499800682068,
              0.006788230035454035,
              0.29743799567222595,
              0.5179299712181091,
              -0.016414200887084007,
              0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              0.2937780022621155,
              0.10627400130033493,
              0.09136269986629486,
              0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              0.2970519959926605,
              0.08680059760808945,
              0.09502919763326645,
              0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              0.29697299003601074,
              0.08729469776153564,
              0.0949392020702362,
              0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              0.2937780022621155,
              0.10627400130033493,
              0.09136269986629486,
              0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              0.2945069968700409,
              0.1063390001654625,
              0.09135019779205322,
              0.2937780022621155,
              0.10627400130033493,
              0.09136269986629486,
              0.3009909987449646,
              0.08718069642782211,
              0.09495539963245392,
              0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              0.30083200335502625,
              0.08766619861125946,
              0.09486699849367142,
              0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              0.2945069968700409,
              0.1063390001654625,
              0.09135019779205322,
              0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              0.29521799087524414,
              0.10654699802398682,
              0.09130959957838058,
              0.2945069968700409,
              0.1063390001654625,
              0.09135019779205322,
              0.3047800064086914,
              0.08830849826335907,
              0.09473609924316406,
              0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              0.3045459985733032,
              0.08877120167016983,
              0.0946521982550621,
              0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              0.29521799087524414,
              0.10654699802398682,
              0.09130959957838058,
              0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              0.2957879900932312,
              0.10683800280094147,
              0.09125299751758575,
              0.29521799087524414,
              0.10654699802398682,
              0.09130959957838058,
              0.3082610070705414,
              0.09013420343399048,
              0.09438119828701019,
              0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              0.30794501304626465,
              0.09055239707231522,
              0.09430599957704544,
              0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              0.2957879900932312,
              0.10683800280094147,
              0.09125299751758575,
              0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              0.29633501172065735,
              0.10726600140333176,
              0.0911697968840599,
              0.2957879900932312,
              0.10683800280094147,
              0.09125299751758575,
              0.3113180100917816,
              0.09259550273418427,
              0.09390279650688171,
              0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              0.31093698740005493,
              0.09295950084924698,
              0.09383810311555862,
              0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              0.29633501172065735,
              0.10726600140333176,
              0.0911697968840599,
              0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              0.29683101177215576,
              0.10785999894142151,
              0.09105449914932251,
              0.29633501172065735,
              0.10726600140333176,
              0.0911697968840599,
              0.31383299827575684,
              0.09560330212116241,
              0.09331820160150528,
              0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              0.31340599060058594,
              0.09591250121593475,
              0.09326410293579102,
              0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              0.29683101177215576,
              0.10785999894142151,
              0.09105449914932251,
              0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              0.2971540093421936,
              0.10846500098705292,
              0.09093669801950455,
              0.29683101177215576,
              0.10785999894142151,
              0.09105449914932251,
              0.31569600105285645,
              0.09902620315551758,
              0.09265279769897461,
              0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              0.3152279853820801,
              0.09926190227270126,
              0.09261299669742584,
              0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              0.2971540093421936,
              0.10846500098705292,
              0.09093669801950455,
              0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              0.29736098647117615,
              0.10915900021791458,
              0.09080199897289276,
              0.2971540093421936,
              0.10846500098705292,
              0.09093669801950455,
              0.31684398651123047,
              0.10274499654769897,
              0.09193000197410583,
              0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              0.3163520097732544,
              0.1029060035943985,
              0.09190469980239868,
              0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              0.29736098647117615,
              0.10915900021791458,
              0.09080199897289276,
              0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              0.2974109947681427,
              0.10977199673652649,
              0.09068270027637482,
              0.29736098647117615,
              0.10915900021791458,
              0.09080199897289276,
              0.3172299861907959,
              0.10659900307655334,
              0.09118080139160156,
              0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              0.3167259991168976,
              0.10666599869728088,
              0.09117379784584045,
              0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              0.2974109947681427,
              0.10977199673652649,
              0.09068270027637482,
              0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              0.2974109947681427,
              0.11059299856424332,
              0.09052319824695587,
              0.2974109947681427,
              0.10977199673652649,
              0.09068270027637482,
              0.29302000999450684,
              0.10628099739551544,
              0.09136179834604263,
              0,
              0.10628099739551544,
              0.09135960042476654,
              0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              0,
              0.10628099739551544,
              0.09135960042476654,
              0,
              0.08729659765958786,
              0.09493879973888397,
              0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              0.29301100969314575,
              0.08729659765958786,
              0.09493900090456009,
              0,
              0.08729659765958786,
              0.09493879973888397,
              0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              0,
              0.08729659765958786,
              0.09493879973888397,
              0,
              0.08680140227079391,
              0.09502910077571869,
              0.29300999641418457,
              0.08680140227079391,
              0.09502910077571869,
              0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              0.2200150042772293,
              0.08135630190372467,
              0.06844689697027206,
              0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              0.2200150042772293,
              0.08135630190372467,
              0.06844689697027206,
              0.14667700231075287,
              0.08135630190372467,
              0.06844689697027206,
              0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              0.14667700231075287,
              0.5179299712181091,
              -0.016414200887084007,
              0.2200150042772293,
              0.5179299712181091,
              -0.016414200887084007,
              0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              0.2200150042772293,
              0.5179299712181091,
              -0.016414200887084007,
              0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              0.2200150042772293,
              0.08088339865207672,
              0.06523320078849792,
              0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              0.14667700231075287,
              0.08135630190372467,
              0.06844689697027206,
              0,
              0.08098050206899643,
              0.06651370227336884,
              0.14667700231075287,
              0.08135630190372467,
              0.06844689697027206,
              0,
              0.08135630190372467,
              0.06844689697027206,
              0,
              0.08098050206899643,
              0.06651370227336884,
              0,
              0.5179299712181091,
              -0.016414200887084007,
              0.14667700231075287,
              0.5179299712181091,
              -0.016414200887084007,
              0,
              0.5178350210189819,
              -0.018401900306344032,
              0.14667700231075287,
              0.5179299712181091,
              -0.016414200887084007,
              0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              0,
              0.5178350210189819,
              -0.018401900306344032,
              0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              0,
              0.08082839846611023,
              0.06573119759559631,
              0.14667700231075287,
              0.08098050206899643,
              0.06651370227336884,
              0,
              0.08098050206899643,
              0.06651370227336884,
              0,
              0.08082839846611023,
              0.06573119759559631,
              0,
              0.5178350210189819,
              -0.018401900306344032,
              0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              0,
              0.5176820158958435,
              -0.019184399396181107,
              0.14667700231075287,
              0.5178350210189819,
              -0.018401900306344032,
              0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              0,
              0.5176820158958435,
              -0.019184399396181107,
              0.14667700231075287,
              0.08088330179452896,
              0.06523320078849792,
              0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              0,
              0.08088330179452896,
              0.06523320078849792,
              0.14667700231075287,
              0.08082839846611023,
              0.06573119759559631,
              0,
              0.08082839846611023,
              0.06573119759559631,
              0,
              0.08088330179452896,
              0.06523320078849792,
              0,
              0.5176820158958435,
              -0.019184399396181107,
              0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              0,
              0.5174450278282166,
              -0.019625499844551086,
              0.14667700231075287,
              0.5176820158958435,
              -0.019184399396181107,
              0.14667700231075287,
              0.5174450278282166,
              -0.019625499844551086,
              0,
              0.5174450278282166,
              -0.019625499844551086,
              0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              0.3172299861907959,
              0.14760799705982208,
              0.08320949971675873,
              0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              0.3172299861907959,
              0.14760799705982208,
              0.08320949971675873,
              0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              0.3172299861907959,
              0.48027199506759644,
              0.01854640059173107,
              0.3172299861907959,
              0.4617530107498169,
              0.02214599959552288,
              0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              0.3172299861907959,
              0.4617530107498169,
              0.02214599959552288,
              0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              0.31738701462745667,
              0.1290840059518814,
              0.08678270131349564,
              0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              0.31738701462745667,
              0.11056499928236008,
              0.09038230031728745,
              0.3172299861907959,
              0.49879100918769836,
              0.01494670007377863,
              0.3172299861907959,
              0.48027199506759644,
              0.01854640059173107,
              0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              0.3172299861907959,
              0.48027199506759644,
              0.01854640059173107,
              0.31738701462745667,
              0.4802669882774353,
              0.018519900739192963,
              0.31738701462745667,
              0.49878498911857605,
              0.01492020022124052,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.2974109947681427,
              0.11059299856424332,
              0.09052319824695587,
              0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              0.2974109947681427,
              0.11059299856424332,
              0.09052319824695587,
              0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              0.29302000999450684,
              0.1476300060749054,
              0.08332400023937225,
              0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              0.2974109947681427,
              0.1476300060749054,
              0.08332400023937225,
              0.29302000999450684,
              0.1476300060749054,
              0.08332400023937225,
              0,
              0.11059200018644333,
              0.09052109718322754,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0,
              0.12911100685596466,
              0.08692140132188797,
              0.29302000999450684,
              0.11059299856424332,
              0.09052330255508423,
              0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              0,
              0.12911100685596466,
              0.08692140132188797,
              0,
              0.12911100685596466,
              0.08692140132188797,
              0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              0,
              0.1476300060749054,
              0.08332180231809616,
              0.29302000999450684,
              0.12911100685596466,
              0.08692359924316406,
              0.29302000999450684,
              0.1476300060749054,
              0.08332400023937225,
              0,
              0.1476300060749054,
              0.08332180231809616,
              0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              0.3175030052661896,
              0.1290770024061203,
              0.08674529939889908,
              0.3175030052661896,
              0.11055800318717957,
              0.09034489840269089,
              0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              0.3175030052661896,
              0.49877798557281494,
              0.014882800169289112,
              0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              0.3175030052661896,
              0.4802600145339966,
              0.018482400104403496,
              0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              0.3175030052661896,
              0.12903200089931488,
              0.08651389926671982,
              0.3175030052661896,
              0.11051300168037415,
              0.09011349827051163,
              0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              0.3175030052661896,
              0.4987330138683319,
              0.014651400037109852,
              0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              0.3175030052661896,
              0.48021501302719116,
              0.018250999972224236,
              0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              0.31784600019454956,
              0.12353599816560745,
              0.05824179947376251,
              0.31784600019454956,
              0.1050179973244667,
              0.06184139847755432,
              0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              0.31784600019454956,
              0.49379798769950867,
              -0.013729499652981758,
              0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              0.31784600019454956,
              0.4752790033817291,
              -0.010129899717867374,
              0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              0.3176969885826111,
              0.14181099832057953,
              0.053390100598335266,
              0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              0.3176969885826111,
              0.12329299747943878,
              0.056989699602127075,
              0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              0.31784600019454956,
              0.12338399887084961,
              0.05745929852128029,
              0.31784600019454956,
              0.10486499965190887,
              0.061058901250362396,
              0.3176969885826111,
              0.10477399826049805,
              0.060589298605918884,
              0.31784600019454956,
              0.4936450123786926,
              -0.014511999674141407,
              0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              0.31784600019454956,
              0.4751270115375519,
              -0.010912399739027023,
              0.3176969885826111,
              0.47503501176834106,
              -0.011381999589502811,
              0.3176969885826111,
              0.4935539960861206,
              -0.01498160045593977,
              0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              0.31784600019454956,
              0.12391199916601181,
              0.06017500162124634,
              0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              0.3175030052661896,
              0.12841199338436127,
              0.08332300186157227,
              0.3175030052661896,
              0.10989300161600113,
              0.08692269772291183,
              0.31784600019454956,
              0.10539299994707108,
              0.06377460062503815,
              0.3175030052661896,
              0.4981130063533783,
              0.011460499837994576,
              0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              0.3175030052661896,
              0.47959399223327637,
              0.015060200355947018,
              0.31784600019454956,
              0.4753749966621399,
              -0.008142270147800446,
              0.31784600019454956,
              0.49389299750328064,
              -0.011741899885237217,
              0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              0.3172299861907959,
              0.11056999862194061,
              0.0904088020324707,
              0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              0.3167259991168976,
              0.14760899543762207,
              0.08321540057659149,
              0.3172299861907959,
              0.12908899784088135,
              0.0868092030286789,
              0.3172299861907959,
              0.14760799705982208,
              0.08320949971675873,
              0.3167259991168976,
              0.14760899543762207,
              0.08321540057659149,
              0.2974109947681427,
              0.11059299856424332,
              0.09052319824695587,
              0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              0.3167259991168976,
              0.11057200282812119,
              0.09041459858417511,
              0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              0.2974109947681427,
              0.12911100685596466,
              0.08692359924316406,
              0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              0.2974109947681427,
              0.1476300060749054,
              0.08332400023937225,
              0.3167259991168976,
              0.12908999621868134,
              0.0868149995803833,
              0.3167259991168976,
              0.14760899543762207,
              0.08321540057659149,
              0.2974109947681427,
              0.1476300060749054,
              0.08332400023937225,
              0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              0.3176969885826111,
              0.4565170109272003,
              -0.007782309781759977,
              0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              0.3176969885826111,
              0.4171789884567261,
              -0.0001357589935651049,
              0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              0.31784600019454956,
              0.45660799741744995,
              -0.007312749978154898,
              0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              0.31784600019454956,
              0.4172700047492981,
              0.0003338009992148727,
              0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              0.31784600019454956,
              0.4567599892616272,
              -0.006530249956995249,
              0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              0.31784600019454956,
              0.181768998503685,
              0.04892880097031593,
              0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              0.31784600019454956,
              0.22082699835300446,
              0.041336700320243835,
              0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              0.31784600019454956,
              0.22082699835300446,
              0.041336700320243835,
              0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              0.31784600019454956,
              0.41742199659347534,
              0.0011163000017404556,
              0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              0.3176969885826111,
              0.22048799693584442,
              0.03809700161218643,
              0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              0.3176969885826111,
              0.3778409957885742,
              0.0075107901357114315,
              0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              0.3176969885826111,
              0.25982600450515747,
              0.030450399965047836,
              0.3176969885826111,
              0.2991639971733093,
              0.02280390076339245,
              0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              0.3176969885826111,
              0.2991639971733093,
              0.02280390076339245,
              0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              0.3176969885826111,
              0.3385019898414612,
              0.015157300047576427,
              0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              0.31784600019454956,
              0.22057899832725525,
              0.03856649994850159,
              0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              0.31784600019454956,
              0.37793201208114624,
              0.007980350404977798,
              0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              0.31784600019454956,
              0.2599169909954071,
              0.0309200007468462,
              0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              0.31784600019454956,
              0.29925501346588135,
              0.023273399099707603,
              0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              0.31784600019454956,
              0.338593989610672,
              0.015626899898052216,
              0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              0.31784600019454956,
              0.22082699835300446,
              0.041336700320243835,
              0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              0.31784600019454956,
              0.2207310050725937,
              0.03934900090098381,
              0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              0.31784600019454956,
              0.3780840039253235,
              0.008762850426137447,
              0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              0.31784600019454956,
              0.26006901264190674,
              0.03170249983668327,
              0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              0.31784600019454956,
              0.2994079887866974,
              0.024055900052189827,
              0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              0.31784600019454956,
              0.33874601125717163,
              0.01640940085053444,
              0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              0.2931390106678009,
              0.08135630190372467,
              0.06844689697027206,
              0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              0.2931390106678009,
              0.08135630190372467,
              0.06844689697027206,
              0.2200150042772293,
              0.08135630190372467,
              0.06844689697027206,
              0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              0.2200150042772293,
              0.5179299712181091,
              -0.016414200887084007,
              0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              0.2200150042772293,
              0.5179299712181091,
              -0.016414200887084007,
              0.29335299134254456,
              0.5179299712181091,
              -0.016414200887084007,
              0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              0.29335299134254456,
              0.08098050206899643,
              0.06651370227336884,
              0.2200150042772293,
              0.08098050206899643,
              0.06651370227336884,
              0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              0.2200150042772293,
              0.5178350210189819,
              -0.018401900306344032,
              0.29335299134254456,
              0.5178350210189819,
              -0.018401900306344032,
              0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              0.29335299134254456,
              0.08088330179452896,
              0.06523320078849792,
              0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              0.2200150042772293,
              0.08088339865207672,
              0.06523320078849792,
              0.29335299134254456,
              0.08082839846611023,
              0.06573119759559631,
              0.2200150042772293,
              0.08082839846611023,
              0.06573119759559631,
              0.2200150042772293,
              0.08088339865207672,
              0.06523320078849792,
              0.2200150042772293,
              0.5174450278282166,
              -0.019625499844551086,
              0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              0.29335299134254456,
              0.5174450278282166,
              -0.019625499844551086,
              0.2200150042772293,
              0.5176820158958435,
              -0.019184399396181107,
              0.29335299134254456,
              0.5176820158958435,
              -0.019184399396181107,
              0.29335299134254456,
              0.5174450278282166,
              -0.019625499844551086,
              0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              0.3172299861907959,
              0.18694600462913513,
              0.07556299865245819,
              0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              0.3172299861907959,
              0.22623899579048157,
              0.06768500059843063,
              0.3172299861907959,
              0.18694600462913513,
              0.07556299865245819,
              0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              0.31784600019454956,
              0.181768998503685,
              0.04892880097031593,
              0.31784600019454956,
              0.22078199684619904,
              0.04110519960522652,
              0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              0.31784600019454956,
              0.22078199684619904,
              0.04110519960522652,
              0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              0.31784600019454956,
              0.22078199684619904,
              0.04110519960522652,
              0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              0.3175030052661896,
              0.2255609929561615,
              0.06419889628887177,
              0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              0.3175030052661896,
              0.2261819988489151,
              0.06738969683647156,
              0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              0.3172299861907959,
              0.22623899579048157,
              0.06768500059843063,
              0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              0.3172299861907959,
              0.26562198996543884,
              0.06026989966630936,
              0.3172299861907959,
              0.22623899579048157,
              0.06768500059843063,
              0.3175030052661896,
              0.22622700035572052,
              0.06762109696865082,
              0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              0.31738701462745667,
              0.22623400390148163,
              0.06765849888324738,
              0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              0.31738701462745667,
              0.2656170129776001,
              0.0602434016764164,
              0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              0.3172299861907959,
              0.26562198996543884,
              0.06026989966630936,
              0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              0.3172299861907959,
              0.3049600124359131,
              0.05262339860200882,
              0.3172299861907959,
              0.26562198996543884,
              0.06026989966630936,
              0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              0.3175030052661896,
              0.265610009431839,
              0.06020599976181984,
              0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              0.3175030052661896,
              0.26556500792503357,
              0.05997459962964058,
              0.31784600019454956,
              0.2601650059223175,
              0.03369009867310524,
              0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              0.3175030052661896,
              0.26494500041007996,
              0.05678369849920273,
              0.31784600019454956,
              0.29950299859046936,
              0.026043599471449852,
              0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              0.3175030052661896,
              0.3042829930782318,
              0.04913720116019249,
              0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              0.3175030052661896,
              0.3049030005931854,
              0.05232809856534004,
              0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              0.3172299861907959,
              0.3049600124359131,
              0.05262339860200882,
              0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              0.3172299861907959,
              0.34429898858070374,
              0.04497680068016052,
              0.3172299861907959,
              0.3049600124359131,
              0.05262339860200882,
              0.3175030052661896,
              0.30494800209999084,
              0.0525594986975193,
              0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              0.31738701462745667,
              0.30495500564575195,
              0.05259690061211586,
              0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              0.31738701462745667,
              0.3442929983139038,
              0.044950298964977264,
              0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              0.3172299861907959,
              0.34429898858070374,
              0.04497680068016052,
              0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              0.3172299861907959,
              0.383637011051178,
              0.037330299615859985,
              0.3172299861907959,
              0.34429898858070374,
              0.04497680068016052,
              0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              0.3175030052661896,
              0.3442859947681427,
              0.044912900775671005,
              0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              0.3175030052661896,
              0.3442409932613373,
              0.044681500643491745,
              0.31784600019454956,
              0.3388420045375824,
              0.018396999686956406,
              0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              0.3175030052661896,
              0.34362098574638367,
              0.041490599513053894,
              0.31784600019454956,
              0.37817999720573425,
              0.010750499553978443,
              0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              0.3175030052661896,
              0.3829590082168579,
              0.03384409844875336,
              0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              0.3175030052661896,
              0.38357898592948914,
              0.03703499957919121,
              0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              0.3172299861907959,
              0.383637011051178,
              0.037330299615859985,
              0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              0.3172299861907959,
              0.42297500371932983,
              0.02968369983136654,
              0.3172299861907959,
              0.383637011051178,
              0.037330299615859985,
              0.3175030052661896,
              0.38362398743629456,
              0.03726639971137047,
              0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              0.31738701462745667,
              0.38363200426101685,
              0.037303801625967026,
              0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              0.31738701462745667,
              0.4229699969291687,
              0.02965719997882843,
              0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              0.3172299861907959,
              0.42297500371932983,
              0.02968369983136654,
              0.31738701462745667,
              0.46174800395965576,
              0.02211949974298477,
              0.3172299861907959,
              0.4617530107498169,
              0.02214599959552288,
              0.3172299861907959,
              0.42297500371932983,
              0.02968369983136654,
              0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              0.3175030052661896,
              0.46174100041389465,
              0.022082099691033363,
              0.3175030052661896,
              0.4229629933834076,
              0.029619799926877022,
              0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              0.3175030052661896,
              0.46169599890708923,
              0.021850699558854103,
              0.3175030052661896,
              0.4229179918766022,
              0.02938839979469776,
              0.31784600019454956,
              0.4175179898738861,
              0.003103930037468672,
              0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              0.31784600019454956,
              0.45685601234436035,
              -0.004542619921267033,
              0.3175030052661896,
              0.4610759913921356,
              0.0186598002910614,
              0.3175030052661896,
              0.42229700088500977,
              0.02619759924709797,
              0.2933630049228668,
              0.14018499851226807,
              0.046517498791217804,
              0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              0.22002199292182922,
              0.17710499465465546,
              0.0264274999499321,
              0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              0.3165220022201538,
              0.18078899383544922,
              0.04538070037961006,
              0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              0.3176969885826111,
              0.14181099832057953,
              0.053390100598335266,
              0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              0,
              0.17633700370788574,
              0.022480299696326256,
              0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              0,
              0.15932700037956238,
              0.029478399083018303,
              0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              0,
              0.15932700037956238,
              0.029478399083018303,
              0,
              0.15932700037956238,
              0.029478399083018303,
              0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              0,
              0.1385360062122345,
              0.03803170099854469,
              0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              0,
              0.1385360062122345,
              0.03803170099854469,
              0.1466819941997528,
              0.17645099759101868,
              0.023063899949193,
              0.22002199292182922,
              0.17710499465465546,
              0.0264274999499321,
              0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              0.22002199292182922,
              0.17710499465465546,
              0.0264274999499321,
              0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              0.1466819941997528,
              0.159403994679451,
              0.02987550012767315,
              0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              0.22002199292182922,
              0.15983200073242188,
              0.032081201672554016,
              0.22002199292182922,
              0.1387220025062561,
              0.038991399109363556,
              0.1466819941997528,
              0.1385679990053177,
              0.03820069879293442,
              0.3165220022201538,
              0.14145700633525848,
              0.05306230112910271,
              0.2933630049228668,
              0.14018499851226807,
              0.046517498791217804,
              0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              0.2933630049228668,
              0.14018499851226807,
              0.046517498791217804,
              0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              0.3165220022201538,
              0.16309000551700592,
              0.04883740097284317,
              0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              0.3165220022201538,
              0.18078899383544922,
              0.04538070037961006,
              0.2933630049228668,
              0.16175399720668793,
              0.04196710139513016,
              0.2933630049228668,
              0.1794009953737259,
              0.03824400156736374,
              0.3165220022201538,
              0.18078899383544922,
              0.04538070037961006,
              0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              0.3176969885826111,
              0.14181099832057953,
              0.053390100598335266,
              0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              0.3176969885826111,
              0.14181099832057953,
              0.053390100598335266,
              0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              0.3176969885826111,
              0.16344699263572693,
              0.04918450117111206,
              0.3176969885826111,
              0.18115000426769257,
              0.04574349895119667,
              0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              0.31784600019454956,
              0.14190299808979034,
              0.05385959893465042,
              0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              0.31784600019454956,
              0.16353900730609894,
              0.049653999507427216,
              0.31784600019454956,
              0.1812410056591034,
              0.04621310159564018,
              0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              0.31784600019454956,
              0.14205500483512878,
              0.054642099887132645,
              0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              0.31784600019454956,
              0.181768998503685,
              0.04892880097031593,
              0.31784600019454956,
              0.16369099915027618,
              0.05043650045990944,
              0.31784600019454956,
              0.18139299750328064,
              0.04699559882283211,
              0.31784600019454956,
              0.181768998503685,
              0.04892880097031593,
              0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              0.31784600019454956,
              0.14243100583553314,
              0.05657529830932617,
              0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              0.31784600019454956,
              0.16406700015068054,
              0.052369698882102966,
              0.31784600019454956,
              0.181768998503685,
              0.04892880097031593,
              0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              0.3175030052661896,
              0.14692999422550201,
              0.07972340285778046,
              0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              0.3175030052661896,
              0.1685660034418106,
              0.07551780343055725,
              0.3175030052661896,
              0.18626800179481506,
              0.07207679748535156,
              0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              0.3175030052661896,
              0.14755000174045563,
              0.08291420340538025,
              0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              0.3175030052661896,
              0.16918599605560303,
              0.07870859652757645,
              0.3175030052661896,
              0.18688899278640747,
              0.07526759803295135,
              0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              0.3172299861907959,
              0.14760799705982208,
              0.08320949971675873,
              0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              0.3172299861907959,
              0.16924400627613068,
              0.07900390028953552,
              0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              0.3172299861907959,
              0.16924400627613068,
              0.07900390028953552,
              0.3172299861907959,
              0.16924400627613068,
              0.07900390028953552,
              0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              0.3172299861907959,
              0.18694600462913513,
              0.07556299865245819,
              0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              0.3172299861907959,
              0.18694600462913513,
              0.07556299865245819,
              0.31738701462745667,
              0.14760300517082214,
              0.08318299800157547,
              0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              0.3175030052661896,
              0.14759500324726105,
              0.08314560353755951,
              0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              0.31738701462745667,
              0.16923899948596954,
              0.07897739857435226,
              0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              0.3175030052661896,
              0.16923099756240845,
              0.0789399966597557,
              0.3175030052661896,
              0.1869339942932129,
              0.07549910247325897,
              0.31738701462745667,
              0.186940997838974,
              0.07553649693727493,
              0.29335299134254456,
              0.5179299712181091,
              -0.016414200887084007,
              0.2201859951019287,
              0.5179299712181091,
              -0.016414200887084007,
              0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              0.2201859951019287,
              0.5179299712181091,
              -0.016414200887084007,
              0.21984300017356873,
              0.5221499800682068,
              0.006788210012018681,
              0.29300999641418457,
              0.5221499800682068,
              0.006788210012018681,
              0.2201859951019287,
              0.5179299712181091,
              -0.016414200887084007,
              0.14684799313545227,
              0.5179299712181091,
              -0.016414200887084007,
              0.21984300017356873,
              0.5221499800682068,
              0.006788210012018681,
              0.14684799313545227,
              0.5179299712181091,
              -0.016414200887084007,
              0.14650499820709229,
              0.5221499800682068,
              0.006788210012018681,
              0.21984300017356873,
              0.5221499800682068,
              0.006788210012018681,
              0.14684799313545227,
              0.5179299712181091,
              -0.016414200887084007,
              0.00017157199908979237,
              0.5179299712181091,
              -0.016414200887084007,
              0.14650499820709229,
              0.5221499800682068,
              0.006788210012018681,
              0.00017157199908979237,
              0.5179299712181091,
              -0.016414200887084007,
              0,
              0.5221499800682068,
              0.006788210012018681,
              0.14650499820709229,
              0.5221499800682068,
              0.006788210012018681,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              -0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              -0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              -0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              -0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              -0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              -0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              -0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              -0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              -0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              -0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              -0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              -0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              -0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              -0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              -0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              -0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              -0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              -0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              -0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              -0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              -0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              -0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              -0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              -0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              -0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              -0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              -0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              -0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              -0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              -0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              -0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              -0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              -0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              -0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              -0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              -0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              -0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              -0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              -0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              -0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              -0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              -0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              -0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              -0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              -0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              -0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              -0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              -0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              -0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              -0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              -0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              -0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              -0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              -0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              -0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              -0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              -0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              -0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              -0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              -0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              -0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              -0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              -0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              -0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              -0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              -0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              -0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              -0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              -0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              -0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              -0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              -0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              -0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              -0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              -0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              -0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              -0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              -0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              -0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              -0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              -0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              -0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              -0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              -0.0853922963142395,
              0.0011095399968326092,
              -0.06915389746427536,
              -0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              -0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              -0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              -0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              -0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              -0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              -0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              -0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              -0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              -0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              -0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              -0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              -0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              -0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              -0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              -0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              -0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              -0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              -0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              -0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              -0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              -0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              -0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              -0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              -0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              -0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              -0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              -0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              -0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              -0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              -0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              -0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              -0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              -0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              -0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              -0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              -0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              -0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              -0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              -0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              -0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              -0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              -0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              -0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              -0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              -0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              -0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              -0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              -0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              -0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              -0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              -0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              -0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              -0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              -0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              -0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              -0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              -0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              -0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              -0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              -0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              -0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              -0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              -0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              -0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              -0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              -0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              -0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              -0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              -0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              -0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              -0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              -0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              -0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              -0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              -0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              -0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              -0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              -0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              -0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              -0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              -0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              -0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              -0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              -0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              -0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              -0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07833889871835709,
              0.04159890115261078,
              -0.09128779917955399,
              -0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              -0.0853922963142395,
              0.0011095399968326092,
              -0.06915389746427536,
              -0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              -0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08564220368862152,
              0.001047880039550364,
              -0.06609869748353958,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              -0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              -0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              -0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              -0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              -0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              -0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              -0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              -0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              -0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              -0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08208219707012177,
              0.010943399742245674,
              -0.0920696035027504,
              -0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              -0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              -0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              -0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08177179843187332,
              0.01301840040832758,
              -0.09387829899787903,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              -0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              -0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              -0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              -0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              -0.08150479942560196,
              0.015239300206303596,
              -0.09550230205059052,
              -0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              -0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              -0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              -0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              -0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              -0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              -0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              -0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              -0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07894039899110794,
              0.036587901413440704,
              -0.1006309986114502,
              -0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              -0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07859139889478683,
              0.03949660062789917,
              -0.10013700276613235,
              -0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              -0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              -0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              -0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              -0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              -0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              -0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              -0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              -0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              -0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              -0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              -0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              -0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              -0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              -0.07891929894685745,
              0.03735119849443436,
              -0.09252999722957611,
              -0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              -0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              -0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              -0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              -0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              -0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              -0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              -0.0810030996799469,
              0.02493640035390854,
              -0.09189210087060928,
              -0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              -0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              -0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              -0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              -0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              -0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              -0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              -0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              -0.08326739817857742,
              0.013494400307536125,
              -0.08380120247602463,
              -0.08354020118713379,
              0.012313099578022957,
              -0.08213090151548386,
              -0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              -0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              -0.07824160158634186,
              0.042412400245666504,
              -0.09935639798641205,
              -0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              -0.08525759726762772,
              0.008581729605793953,
              -0.07009799778461456,
              -0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              -0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              -0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              -0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              -0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              -0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              -0.0853922963142395,
              0.0011095399968326092,
              -0.06915389746427536,
              -0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              -0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              -0.0853922963142395,
              0.0011095399968326092,
              -0.06915389746427536,
              -0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              -0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              -0.08496200293302536,
              0.0012498799478635192,
              -0.07216860353946686,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              -0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              -0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              -0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              -0.08454509824514389,
              0.0016607600264251232,
              -0.07508979737758636,
              -0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              -0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              -0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              -0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              -0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              -0.08414299786090851,
              0.0023281900212168694,
              -0.07790739834308624,
              -0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              -0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              -0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              -0.08375710248947144,
              0.003238230012357235,
              -0.0806111991405487,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              -0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              -0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              -0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              -0.0830397978425026,
              0.005730839911848307,
              -0.08563710004091263,
              -0.08338890224695206,
              0.004377100151032209,
              -0.08319120109081268,
              -0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              -0.08240389823913574,
              0.009027870371937752,
              -0.09008649736642838,
              -0.08271100372076035,
              0.007285770028829575,
              -0.08793889731168747,
              -0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              -0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              -0.08092500269412994,
              0.02006240002810955,
              -0.09815579652786255,
              -0.08122199773788452,
              0.017591800540685654,
              -0.09693150222301483,
              -0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              -0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              -0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              -0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              -0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              -0.08061560243368149,
              0.022637100890278816,
              -0.09916509687900543,
              -0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              -0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              -0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              -0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              -0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              -0.08029539883136749,
              0.02530210092663765,
              -0.09994920343160629,
              -0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              -0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              -0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              -0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              -0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              -0.07996609807014465,
              0.02804349921643734,
              -0.10049799829721451,
              -0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              -0.07962939888238907,
              0.030847499147057533,
              -0.1008009985089302,
              -0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              -0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              -0.08494210243225098,
              0.008754460141062737,
              -0.07230769842863083,
              -0.0846370980143547,
              0.00911995954811573,
              -0.07444500178098679,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              -0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              -0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              -0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              -0.08434340357780457,
              0.009668559767305851,
              -0.07650309801101685,
              -0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              -0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              -0.08406200259923935,
              0.010390199720859528,
              -0.07847479730844498,
              -0.08379390090703964,
              0.011275099590420723,
              -0.08035310357809067,
              -0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              -0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              -0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              -0.07925619930028915,
              0.03521449863910675,
              -0.09289760142564774,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              -0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              -0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              -0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              -0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              -0.07959990203380585,
              0.033091600984334946,
              -0.09306629747152328,
              -0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              -0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              -0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              -0.07994840294122696,
              0.030992399901151657,
              -0.09304309636354446,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              -0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              -0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              -0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              -0.0806519016623497,
              0.02690489962697029,
              -0.09244900196790695,
              -0.08029969781637192,
              0.028926799073815346,
              -0.09283500164747238,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              -0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              -0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              -0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              -0.08169420063495636,
              0.02120020054280758,
              -0.0902940034866333,
              -0.08135119825601578,
              0.023031599819660187,
              -0.0911715030670166,
              -0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              -0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              -0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              -0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              -0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              -0.0820302963256836,
              0.019452199339866638,
              -0.08926679939031601,
              -0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              -0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              -0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              -0.08235739916563034,
              0.01779779978096485,
              -0.08809679746627808,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              -0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              -0.08297819644212723,
              0.01480880007147789,
              -0.08535700291395187,
              -0.08267410099506378,
              0.016246600076556206,
              -0.08679120242595673,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              -0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              -0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              -0.07859130203723907,
              0.039491500705480576,
              -0.09195630252361298,
              -0.07833889871835709,
              0.04159890115261078,
              -0.09128779917955399,
              -0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              -0.0853922963142395,
              0.0011095399968326092,
              -0.06915389746427536,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              -0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              -0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              -0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              -0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              -0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              -0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              -0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              -0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              -0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              -0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              -0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              -0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              -0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              -0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              -0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              -0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              -0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              -0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              -0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              -0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              -0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              -0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              -0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              -0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              -0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              -0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              -0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              -0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              -0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              -0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              -0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              -0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              -0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              -0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              -0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              -0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              -0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              -0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              -0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              -0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              -0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              -0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              -0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              -0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              -0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              -0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              -0.08130879700183868,
              0.017782999202609062,
              -0.0965799018740654,
              -0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              -0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              -0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              -0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              -0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              -0.08130879700183868,
              0.017782999202609062,
              -0.0965799018740654,
              -0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              -0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              -0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              -0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              -0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              -0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              -0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              -0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              -0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              -0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              -0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              -0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              -0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              -0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              -0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              -0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              -0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              -0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              -0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              -0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              -0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              -0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              -0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              -0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              -0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              -0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              -0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              -0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              -0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              -0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              -0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              -0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              -0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              -0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              -0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              -0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              -0.07863560318946838,
              0.039639201015233994,
              -0.09233040362596512,
              -0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              -0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              -0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              -0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              -0.07863560318946838,
              0.039639201015233994,
              -0.09233040362596512,
              -0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              -0.07863560318946838,
              0.039639201015233994,
              -0.09233040362596512,
              -0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              -0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              -0.10563500225543976,
              0.0015097300056368113,
              0.07223069667816162,
              -0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              -0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              -0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              -0.08565250039100647,
              0.008104950189590454,
              -0.06776560097932816,
              -0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              -0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              -0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              -0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              -0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              -0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              -0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              -0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              -0.08217570185661316,
              0.01122680027037859,
              -0.09178759902715683,
              -0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              -0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              -0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              -0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              -0.08215860277414322,
              0.011073799803853035,
              -0.09193989634513855,
              -0.08186479657888412,
              0.013273200020194054,
              -0.09357020258903503,
              -0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              -0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              -0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              -0.08184800297021866,
              0.01313559990376234,
              -0.09373649954795837,
              -0.08159449696540833,
              0.015463200397789478,
              -0.09517080336809158,
              -0.08130879700183868,
              0.017782999202609062,
              -0.0965799018740654,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              -0.08130879700183868,
              0.017782999202609062,
              -0.0965799018740654,
              -0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              -0.08157940208911896,
              0.015342299826443195,
              -0.09534980356693268,
              -0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              -0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              -0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              -0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              -0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              -0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07901839911937714,
              0.03651769831776619,
              -0.1002350002527237,
              -0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              -0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              -0.07866989821195602,
              0.039387598633766174,
              -0.0997501015663147,
              -0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              -0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              -0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              -0.07866089791059494,
              0.0394463986158371,
              -0.09995879977941513,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              -0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              -0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              -0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              -0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              -0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              -0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              -0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              -0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              -0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              -0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              -0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              -0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              -0.07863560318946838,
              0.039639201015233994,
              -0.09233040362596512,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              -0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              -0.07896479964256287,
              0.037460099905729294,
              -0.09291639924049377,
              -0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              -0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              -0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              -0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              -0.07897379994392395,
              0.03740129992365837,
              -0.0927077978849411,
              -0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              -0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              -0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              -0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              -0.081045001745224,
              0.024815699085593224,
              -0.09227409958839417,
              -0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              -0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              -0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              -0.08105599880218506,
              0.024880899116396904,
              -0.0920678973197937,
              -0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              -0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              -0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              -0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              -0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              -0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              -0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              -0.08329950273036957,
              0.013160999864339828,
              -0.08402340114116669,
              -0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              -0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              -0.08357519656419754,
              0.011958999559283257,
              -0.08231949806213379,
              -0.08358970284461975,
              0.012150200083851814,
              -0.08221770077943802,
              -0.08331570029258728,
              0.01334100030362606,
              -0.08390340209007263,
              -0.07831159979104996,
              0.04234439879655838,
              -0.0991842970252037,
              -0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              -0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              -0.07832109928131104,
              0.042264699935913086,
              -0.0989822968840599,
              -0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              -0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              -0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              -0.08565250039100647,
              0.008104950189590454,
              -0.06776560097932816,
              -0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              -0.08565250039100647,
              0.008104950189590454,
              -0.06776560097932816,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              -0.08532299846410751,
              0.00839576032012701,
              -0.07007449865341187,
              -0.08455760031938553,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              -0.08455760031938553,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              -0.08455760031938553,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08477599918842316,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              -0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              -0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              -0.08435820043087006,
              0.0015992199769243598,
              -0.07509510219097137,
              -0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              -0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              -0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              -0.0839553028345108,
              0.0022676000371575356,
              -0.07791899889707565,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              -0.08356860280036926,
              0.003179189981892705,
              -0.08062879741191864,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08319970220327377,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              -0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              -0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              -0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              -0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              -0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              -0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              -0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              -0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              -0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              -0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              -0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              -0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              -0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              -0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              -0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              -0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              -0.08010700345039368,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              -0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              -0.07977789640426636,
              0.02803649939596653,
              -0.10055900365114212,
              -0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07944130152463913,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              -0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              -0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              -0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              -0.08387859910726547,
              0.010449400171637535,
              -0.07845710217952728,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              -0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              -0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07941550016403198,
              0.03308679908514023,
              -0.09300500154495239,
              -0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              -0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              -0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              -0.0797640010714531,
              0.030993500724434853,
              -0.09298159927129745,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              -0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              -0.08011530339717865,
              0.028933899477124214,
              -0.09277389943599701,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              -0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              -0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              -0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              -0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              -0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              -0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              -0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              -0.0821755975484848,
              0.01783679984509945,
              -0.08804959803819656,
              -0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              -0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              -0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              -0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              -0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              -0.08249250054359436,
              0.016290100291371346,
              -0.08674799650907516,
              -0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              -0.0781899020075798,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              -0.08520720154047012,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08564220368862152,
              0.001047880039550364,
              -0.06609869748353958,
              -0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              -0.08564220368862152,
              0.001047880039550364,
              -0.06609869748353958,
              -0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08285000175237656,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08252090215682983,
              0.0072346897795796394,
              -0.08797299861907959,
              -0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              -0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              -0.08221369981765747,
              0.008980420418083668,
              -0.09012529999017715,
              -0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08189179748296738,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              -0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              -0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              -0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08158169686794281,
              0.012979400344192982,
              -0.0939256027340889,
              -0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              -0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              -0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              -0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              -0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              -0.0813153013586998,
              0.015204899944365025,
              -0.09555310010910034,
              -0.08103279769420624,
              0.0175624992698431,
              -0.09698539972305298,
              -0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              -0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              -0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              -0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              -0.08073610067367554,
              0.02003839984536171,
              -0.09821230173110962,
              -0.0804269015789032,
              0.022618599236011505,
              -0.09922370314598083,
              -0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              -0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              -0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              -0.078752301633358,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07783559709787369,
              0.04243500158190727,
              -0.09941379725933075,
              -0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              -0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              -0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              -0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              -0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              -0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              -0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              -0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              -0.0847558006644249,
              0.008816570043563843,
              -0.07230880111455917,
              -0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              -0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              -0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              -0.0841590017080307,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08445180207490921,
              0.009181739762425423,
              -0.07443969696760178,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              -0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              -0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08361150324344635,
              0.011332100257277489,
              -0.08032959699630737,
              -0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              -0.0781899020075798,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              -0.0781899020075798,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07840809971094131,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              -0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              -0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              -0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              -0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              -0.07873540371656418,
              0.03733440116047859,
              -0.0924706980586052,
              -0.0790719985961914,
              0.03520369902253151,
              -0.09283699840307236,
              -0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              -0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              -0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              -0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              -0.08046770095825195,
              0.026917699724435806,
              -0.09238889813423157,
              -0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              -0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              -0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              -0.08081910014152527,
              0.0249549001455307,
              -0.09183350205421448,
              -0.08116760104894638,
              0.023055600002408028,
              -0.09111490100622177,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08151110261678696,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08184780180454254,
              0.019486600533127785,
              -0.08921589702367783,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              -0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              -0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              -0.0827966034412384,
              0.014856399968266487,
              -0.0853182002902031,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              -0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              -0.08308590203523636,
              0.013545500114560127,
              -0.08376710116863251,
              -0.08335839956998825,
              0.012367400340735912,
              -0.08210200071334839,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              -0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              -0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              -0.05047040060162544,
              0.27090299129486084,
              -0.026756800711154938,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              -0.1053910031914711,
              0.004109830129891634,
              0.07226540148258209,
              -0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              -0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              -0.08517000079154968,
              0.008572700433433056,
              -0.06787610054016113,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              -0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              -0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              -0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              -0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              -0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              -0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              -0.08502300083637238,
              0.0016533699817955494,
              -0.07217609882354736,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              -0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              -0.08502359688282013,
              0.0014355500461533666,
              -0.07217200100421906,
              -0.08461210131645203,
              0.0020622299052774906,
              -0.07505510002374649,
              -0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              -0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              -0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              -0.08460939675569534,
              0.0018454799428582191,
              -0.07507380098104477,
              -0.08421579748392105,
              0.0027233699802309275,
              -0.07783179730176926,
              -0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              -0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              -0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              -0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              -0.08420989662408829,
              0.0025100100319832563,
              -0.07787259668111801,
              -0.08383549749851227,
              0.003623219905421138,
              -0.08049619942903519,
              -0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              -0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              -0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              -0.08382660150527954,
              0.003415350103750825,
              -0.08055830001831055,
              -0.0834726020693779,
              0.004748110193759203,
              -0.08303850144147873,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              -0.08312860131263733,
              0.0060844398103654385,
              -0.08544880151748657,
              -0.08311410248279572,
              0.005893529858440161,
              -0.08555050194263458,
              -0.08346080034971237,
              0.004547799937427044,
              -0.08312089741230011,
              -0.08280269801616669,
              0.007618810050189495,
              -0.08771699666976929,
              -0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              -0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              -0.08249650150537491,
              0.009337480179965496,
              -0.08983319997787476,
              -0.08247990161180496,
              0.009170349687337875,
              -0.08996990323066711,
              -0.08278659731149673,
              0.007438989821821451,
              -0.08783680200576782,
              -0.08130879700183868,
              0.017782999202609062,
              -0.0965799018740654,
              -0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              -0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              -0.08100929856300354,
              0.020219000056385994,
              -0.0977872982621193,
              -0.08099710196256638,
              0.020134499296545982,
              -0.09798630326986313,
              -0.08129529654979706,
              0.01767979934811592,
              -0.09676969796419144,
              -0.08069779723882675,
              0.02275790087878704,
              -0.09878309816122055,
              -0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              -0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              -0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              -0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              -0.08068680018186569,
              0.02269270084798336,
              -0.09898930042982101,
              -0.08037590235471725,
              0.0253858994692564,
              -0.09955719858407974,
              -0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              -0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              -0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              -0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              -0.08036579936742783,
              0.025340599939227104,
              -0.09976880252361298,
              -0.08004540205001831,
              0.028089400380849838,
              -0.10010000318288803,
              -0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              -0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              -0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              -0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              -0.08003599941730499,
              0.028064699843525887,
              -0.10031499713659286,
              -0.0797078013420105,
              0.030855000019073486,
              -0.10040000081062317,
              -0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              -0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              -0.07936490327119827,
              0.03366899862885475,
              -0.10044900327920914,
              -0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              -0.07969889789819717,
              0.030851000919938087,
              -0.10061699897050858,
              -0.08469419926404953,
              0.008717269636690617,
              -0.07447980344295502,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              -0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              -0.08500529825687408,
              0.008349509909749031,
              -0.07230020314455032,
              -0.08500470221042633,
              0.008568139746785164,
              -0.07230419665575027,
              -0.08469689637422562,
              0.008934680372476578,
              -0.07446099817752838,
              -0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              -0.08439459651708603,
              0.009272339753806591,
              -0.07657890021800995,
              -0.08440040051937103,
              0.009486230090260506,
              -0.07653799653053284,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              -0.08383399993181229,
              0.010903400368988514,
              -0.08050599694252014,
              -0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              -0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              -0.08410750329494476,
              0.01000440027564764,
              -0.07858999818563461,
              -0.08411639928817749,
              0.010212699882686138,
              -0.07852780073881149,
              -0.08384580165147781,
              0.011103999800980091,
              -0.08042339980602264,
              -0.07930230349302292,
              0.035284701734781265,
              -0.09329280257225037,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              -0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              -0.07931099832057953,
              0.035246800631284714,
              -0.09307940304279327,
              -0.07964599877595901,
              0.03312290087342262,
              -0.09346629679203033,
              -0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              -0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              -0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              -0.07965470105409622,
              0.0331059992313385,
              -0.09325040131807327,
              -0.07999420166015625,
              0.030984900891780853,
              -0.0934441015124321,
              -0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              -0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              -0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              -0.08000309765338898,
              0.030988899990916252,
              -0.09322760254144669,
              -0.08034469932317734,
              0.028880899772047997,
              -0.09323319792747498,
              -0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              -0.08069559931755066,
              0.02682109922170639,
              -0.09284090250730515,
              -0.08070570230484009,
              0.026866300031542778,
              -0.09262929856777191,
              -0.0803539976477623,
              0.028905700892210007,
              -0.09301819652318954,
              -0.08139099925756454,
              0.022874899208545685,
              -0.0915398970246315,
              -0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              -0.08173149824142456,
              0.02100910060107708,
              -0.09064559638500214,
              -0.08174499869346619,
              0.021112199872732162,
              -0.0904558002948761,
              -0.08140309900045395,
              0.022959500551223755,
              -0.09134099632501602,
              -0.08206459879875183,
              0.01922830007970333,
              -0.08959820121526718,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              -0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              -0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              -0.0820797011256218,
              0.019349200651049614,
              -0.08941929787397385,
              -0.08238840103149414,
              0.017542999237775803,
              -0.08840499818325043,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              -0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              -0.08240529894828796,
              0.01768060028553009,
              -0.08823859691619873,
              -0.08270460367202759,
              0.015963200479745865,
              -0.08707340061664581,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              -0.0830094963312149,
              0.014499099925160408,
              -0.08561050146818161,
              -0.08302610367536545,
              0.014666300266981125,
              -0.08547359704971313,
              -0.08272170275449753,
              0.016116200014948845,
              -0.08692099899053574,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              -0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              -0.10563500225543976,
              0.0015097300056368113,
              0.07223069667816162,
              -0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              -0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              -0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              -0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              -0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              -0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              -0.08517000079154968,
              0.008572700433433056,
              -0.06787610054016113,
              -0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              -0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              -0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              0,
              0.001047880039550364,
              -0.06609869748353958,
              -0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              -0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              -0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              -0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              -0.08366040140390396,
              0.010449400171637535,
              -0.07845710217952728,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              -0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              -0.07919629663228989,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              -0.07954459637403488,
              0.030993500724434853,
              -0.09298159927129745,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              -0.07989589869976044,
              0.028933899477124214,
              -0.09277389943599701,
              -0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              -0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              -0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              -0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              -0.08195649832487106,
              0.01783679984509945,
              -0.08804959803819656,
              -0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              -0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              -0.0822736993432045,
              0.016290100291371346,
              -0.08674799650907516,
              -0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              -0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              -0.08453740179538727,
              0.008816570043563843,
              -0.07230880111455917,
              -0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              -0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              -0.08394079655408859,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08423340320587158,
              0.009181739762425423,
              -0.07443969696760178,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              -0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              -0.08339349925518036,
              0.011332100257277489,
              -0.08032959699630737,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              -0.0781899020075798,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07851669937372208,
              0.03733440116047859,
              -0.0924706980586052,
              -0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              -0.07885300368070602,
              0.03520369902253151,
              -0.09283699840307236,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              -0.08024830371141434,
              0.026917699724435806,
              -0.09238889813423157,
              -0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              -0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              -0.08059970289468765,
              0.0249549001455307,
              -0.09183350205421448,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              -0.08094819635152817,
              0.023055600002408028,
              -0.09111490100622177,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              -0.0812918022274971,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08162859827280045,
              0.019486600533127785,
              -0.08921589702367783,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              -0.08257800340652466,
              0.014856399968266487,
              -0.0853182002902031,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              -0.08286750316619873,
              0.013545500114560127,
              -0.08376710116863251,
              -0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              -0.08314040303230286,
              0.012367400340735912,
              -0.08210200071334839,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              -0.0781899020075798,
              0.03946889936923981,
              -0.09189900010824203,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              -0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              0,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              -0.08455760031938553,
              0.0011880099773406982,
              -0.07216750085353851,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              -0.08498869836330414,
              0.001047880039550364,
              -0.06914609670639038,
              -0.08455760031938553,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              -0.08413980156183243,
              0.0015992199769243598,
              -0.07509510219097137,
              -0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              -0.08373689651489258,
              0.0022676000371575356,
              -0.07791899889707565,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              0,
              0.003179189981892705,
              -0.080628901720047,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              -0.08335039764642715,
              0.003179189981892705,
              -0.08062879741191864,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              0,
              0.003179189981892705,
              -0.080628901720047,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              0,
              0.003179189981892705,
              -0.080628901720047,
              -0.08298160135746002,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              -0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              -0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              -0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              -0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              -0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              -0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              -0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              -0.07956010103225708,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              -0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              -0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              -0.08263210207223892,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08230320364236832,
              0.0072346897795796394,
              -0.08797299861907959,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              -0.08199600130319595,
              0.008980420418083668,
              -0.09012529999017715,
              -0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              -0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              -0.08167409896850586,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              -0.08136419951915741,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              -0.08109790086746216,
              0.015204899944365025,
              -0.09555310010910034,
              -0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08081530034542084,
              0.0175624992698431,
              -0.09698539972305298,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              -0.08051849901676178,
              0.02003839984536171,
              -0.09821230173110962,
              -0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              -0.08020920306444168,
              0.022618599236011505,
              -0.09922370314598083,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              -0.07888100296258926,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              -0.07853449881076813,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              -0.07818549871444702,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07783559709787369,
              0.04243500158190727,
              -0.09941379725933075,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              -0.07783559709787369,
              0.04243500158190727,
              -0.09941379725933075,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              -0.05047040060162544,
              0.27090299129486084,
              -0.026756800711154938,
              -0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              -0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              -0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              -0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              -0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              -0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              -0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              -0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              -0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              -0.08564220368862152,
              0.001047880039550364,
              -0.06609869748353958,
              -0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              -0.1053910031914711,
              0.004109830129891634,
              0.07226540148258209,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              -0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              -0.08557680249214172,
              0.0085105299949646,
              -0.0678614005446434,
              -0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              -0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              -0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              -0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              -0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              -0.08582630008459091,
              0.0011095399968326092,
              -0.0661131963133812,
              -0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              -0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              -0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              -0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              -0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              -0.08565250039100647,
              0.008104950189590454,
              -0.06776560097932816,
              -0.0853269025683403,
              0.008177580311894417,
              -0.07004690170288086,
              -0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              -0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              -0.08564519882202148,
              0.008323909714818,
              -0.06781730055809021,
              -0.08565250039100647,
              0.008104950189590454,
              -0.06776560097932816,
              -0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              -0.08564220368862152,
              0.001047880039550364,
              -0.06609869748353958,
              -0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              -0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              -0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              -0.10517700016498566,
              0.0010476399911567569,
              0.07229600101709366,
              -0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              -0.08507029712200165,
              0.008643720299005508,
              -0.07010579854249954,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              -0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              -0.08538849651813507,
              0.008572700433433056,
              -0.06787610054016113,
              -0.08517000079154968,
              0.008572700433433056,
              -0.06787610054016113,
              -0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              -0.08545109629631042,
              0.0012945199850946665,
              -0.0691772997379303,
              -0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              -0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              -0.08588209748268127,
              0.0012945199850946665,
              -0.06615690141916275,
              -0.08587480336427689,
              0.0015115400310605764,
              -0.06620819866657257,
              -0.08544710278511047,
              0.001511569949798286,
              -0.06920470297336578,
              -0.08485180139541626,
              0.008643720299005508,
              -0.07010579854249954,
              -0.08517000079154968,
              0.008572700433433056,
              -0.06787610054016113,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              -0.08517000079154968,
              0.008572700433433056,
              -0.06787610054016113,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              -0.10517700016498566,
              0.0010476399911567569,
              0.07229600101709366,
              -0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              -0.08542370051145554,
              0.001047880039550364,
              -0.06609869748353958,
              0,
              0.001047880039550364,
              -0.06609869748353958,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              -0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              -0.07783559709787369,
              0.04243500158190727,
              -0.09941379725933075,
              -0.0780533030629158,
              0.04243500158190727,
              -0.09941379725933075,
              -0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              -0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              -0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              -0.07863560318946838,
              0.039639201015233994,
              -0.09233040362596512,
              -0.07864510267972946,
              0.0395594984292984,
              -0.09212850034236908,
              -0.07783559709787369,
              0.04243500158190727,
              -0.09941379725933075,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              0,
              0.04531560093164444,
              -0.09849770367145538,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              -0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              -0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              -0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              -0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              -0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              -0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              -0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              -0.050682298839092255,
              0.27250099182128906,
              -0.0261841993778944,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              -0.050682298839092255,
              0.27250099182128906,
              -0.0261841993778944,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              -0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              -0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              -0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              -0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              -0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              -0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              -0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              -0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              -0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              -0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              -0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              -0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              -0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              -0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              -0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              -0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              -0.048734501004219055,
              0.288765013217926,
              0.011296999640762806,
              -0.04877439886331558,
              0.2889449894428253,
              0.011264700442552567,
              -0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              -0.04877439886331558,
              0.2889449894428253,
              0.011264700442552567,
              -0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              -0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              -0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              -0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              -0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              -0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              -0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              -0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              -0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              -0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              -0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              -0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              -0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              -0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              -0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              -0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              -0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              -0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              -0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              -0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              -0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              -0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              -0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              -0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              -0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              -0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              -0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              -0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              -0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              -0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              -0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              -0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              -0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              -0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              -0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              -0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              -0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              -0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              -0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              -0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              -0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              -0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              -0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              -0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              -0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              -0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              -0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              -0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              -0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              -0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              -0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              -0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              -0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              -0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              -0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              -0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              -0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              -0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              -0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              -0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              -0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              -0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              -0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              -0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              -0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              -0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              -0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              -0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              -0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              -0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              -0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              -0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              -0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              -0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              -0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              -0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              -0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              -0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              -0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              -0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              -0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              -0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              -0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              -0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              -0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              -0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              -0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              -0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              -0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              -0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              -0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              -0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              -0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              -0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              -0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              -0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              -0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              -0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              -0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              -0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              -0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              -0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              -0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              -0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              -0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              -0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              -0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              -0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              -0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              -0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              -0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              -0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              -0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              -0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              -0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              -0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              -0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              -0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              -0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              -0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              -0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              -0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              -0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              -0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              -0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              -0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              -0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              -0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              -0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              -0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              -0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              -0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              -0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              -0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              -0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              -0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              -0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              -0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              -0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              -0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              -0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              -0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              -0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              -0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              -0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              -0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              -0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              -0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              -0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              -0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              -0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              -0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              -0.04780210182070732,
              0.2965500056743622,
              0.00985088013112545,
              -0.04761059954762459,
              0.2966090142726898,
              0.009838059544563293,
              -0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              -0.04761059954762459,
              0.2966090142726898,
              0.009838059544563293,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              -0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              -0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              -0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              -0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              -0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              -0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              -0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              -0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              -0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              -0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              -0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              -0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              -0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              -0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              -0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              -0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              -0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              -0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              -0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              -0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              -0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              -0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              -0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              -0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              -0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              -0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              -0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              -0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              -0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              -0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              -0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              -0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              -0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              -0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              -0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              -0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              -0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              -0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              -0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              -0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              -0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              -0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              -0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              -0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              -0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              -0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              -0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              -0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              -0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              -0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              -0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              -0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              -0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              -0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              -0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              -0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              -0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              -0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              -0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              -0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              -0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              -0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              -0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              -0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              -0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              -0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              -0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              -0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              -0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              -0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              -0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              -0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              -0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              -0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              -0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              -0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              -0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              -0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              -0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              -0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              -0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              -0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              -0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              -0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              -0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              -0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              -0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              -0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              -0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              -0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              -0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              -0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              -0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              -0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              -0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              -0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              -0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              -0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              -0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              -0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              -0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              -0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              -0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              -0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              -0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              -0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              -0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              -0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              -0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              -0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              -0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              -0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              -0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              -0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              -0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              -0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              -0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              -0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              -0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              -0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              -0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              -0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              -0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              -0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              -0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              -0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              -0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              -0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              -0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              -0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              -0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              -0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              -0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              -0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              -0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              -0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              -0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              -0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              -0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              -0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              -0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              -0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              -0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              -0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              -0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              -0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              -0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              -0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              -0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              -0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              -0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              -0.1053910031914711,
              0.004109830129891634,
              0.07226540148258209,
              -0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              -0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              -0.1053910031914711,
              0.004109830129891634,
              0.07226540148258209,
              -0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              -0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              -0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              -0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              -0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              -0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              -0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              0,
              0.003052680054679513,
              0.10353100299835205,
              -0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              0,
              0.003052680054679513,
              0.10353100299835205,
              0,
              0.003120010020211339,
              0.10334599763154984,
              -0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              -0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              -0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              -0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              -0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              -0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              -0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              -0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              -0.04991459846496582,
              0.2789109945297241,
              -0.013981799595057964,
              -0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              -0.04981119930744171,
              0.279774010181427,
              -0.013188299722969532,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              -0.0491287000477314,
              0.2854740023612976,
              -0.004370360169559717,
              -0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              -0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              -0.050682298839092255,
              0.27250099182128906,
              -0.0261841993778944,
              -0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              -0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              -0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              -0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              -0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              -0.04822219908237457,
              0.29304200410842896,
              -0.006389429792761803,
              -0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              -0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              -0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              -0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              -0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              -0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.05076339840888977,
              0.2718240022659302,
              -0.018215900287032127,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              -0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.05062999948859215,
              0.27293699979782104,
              -0.017764700576663017,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              -0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              -0.048557400703430176,
              0.2887049913406372,
              0.011305900290608406,
              -0.048734501004219055,
              0.288765013217926,
              0.011296999640762806,
              -0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              -0.048734501004219055,
              0.288765013217926,
              0.011296999640762806,
              -0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              -0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              -0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04978359863162041,
              0.28000399470329285,
              -0.022679900750517845,
              -0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              -0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              -0.04931370168924332,
              0.2839280068874359,
              -0.019744299352169037,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              -0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              -0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              -0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              -0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              -0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              -0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              -0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              -0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              -0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              -0.08901900053024292,
              0.0011090299813076854,
              0.10211600363254547,
              -0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              -0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              -0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              -0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              -0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              -0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              -0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              -0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              -0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              -0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              -0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              -0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              -0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              -0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              -0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              -0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              -0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              -0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              -0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              -0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              -0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              -0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              -0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              -0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              -0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              -0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              -0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              -0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              -0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              -0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              -0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              -0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              -0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              -0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              -0.103753000497818,
              0.0011090600164607167,
              0.08877839893102646,
              -0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              -0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              -0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              -0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              -0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              -0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              -0.10613299906253815,
              0.001109090051613748,
              0.07873620092868805,
              -0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              -0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              -0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              -0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              -0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              -0.10557399690151215,
              0.001109090051613748,
              0.07223939895629883,
              -0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              -0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              -0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              -0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              -0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              -0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              -0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              -0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              -0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              -0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              -0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              -0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              -0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              -0.08280739933252335,
              0.003055660054087639,
              0.10343799740076065,
              -0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              -0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              -0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              -0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              -0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              -0.08494240045547485,
              0.0030642999336123466,
              0.10316599905490875,
              -0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              -0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              -0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              -0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              -0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              -0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              -0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              -0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              -0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              -0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              -0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              -0.09279199689626694,
              0.0031510600820183754,
              0.10044199973344803,
              -0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              -0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              -0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              -0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              -0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              -0.09454759955406189,
              0.0031845299527049065,
              0.09939110279083252,
              -0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              -0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              -0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              -0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              -0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              -0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              -0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              -0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              -0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              -0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              -0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              -0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              -0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              -0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              -0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              -0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              -0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              -0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              -0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              -0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              -0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              -0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              -0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              -0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              -0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              -0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              -0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              -0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              -0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              -0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              -0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              -0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              -0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              -0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              -0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              -0.10610099881887436,
              0.00391077995300293,
              0.07658419758081436,
              -0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              -0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              -0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              -0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              -0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              -0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              -0.050374701619148254,
              0.275068998336792,
              -0.016701599583029747,
              -0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              -0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              -0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              -0.050022900104522705,
              0.27800700068473816,
              -0.01473159994930029,
              -0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              -0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              -0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              -0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              -0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              -0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              -0.049449700862169266,
              0.2827939987182617,
              -0.00961271021515131,
              -0.04937319830060005,
              0.2834320068359375,
              -0.008627120405435562,
              -0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              -0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              -0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              -0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              -0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              -0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              -0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              -0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              -0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              -0.049463901668787,
              0.28267401456832886,
              -0.020786600187420845,
              -0.049620699137449265,
              0.2813650071620941,
              -0.021765900775790215,
              -0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              -0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              -0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              -0.04788510128855705,
              0.2963699996471405,
              0.00988634955137968,
              -0.04780210182070732,
              0.2965500056743622,
              0.00985088013112545,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              -0.04780210182070732,
              0.2965500056743622,
              0.00985088013112545,
              -0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              -0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              -0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              -0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              -0.05025310069322586,
              0.2760849893093109,
              -0.0160933006554842,
              -0.05013579875230789,
              0.2770650088787079,
              -0.015436000190675259,
              -0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              -0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              -0.04971279948949814,
              0.280595988035202,
              -0.01235289964824915,
              -0.04961970075964928,
              0.2813740074634552,
              -0.011477399617433548,
              -0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              -0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              -0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              -0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              -0.04930270090699196,
              0.2840209901332855,
              -0.007608340121805668,
              -0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              -0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              -0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              -0.04923830181360245,
              0.2845590114593506,
              -0.006558119785040617,
              -0.049180299043655396,
              0.28504401445388794,
              -0.005478209815919399,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              -0.04908379912376404,
              0.285849004983902,
              -0.0032363201025873423,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              -0.04904580116271973,
              0.2861660122871399,
              -0.00207782001234591,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              -0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              -0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              -0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              -0.050307098776102066,
              0.2756339907646179,
              -0.025005200877785683,
              -0.05049220100045204,
              0.2740879952907562,
              -0.025633500888943672,
              -0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.04995249956846237,
              0.2785939872264862,
              -0.02352599985897541,
              -0.05012710019946098,
              0.27713701128959656,
              -0.024302000179886818,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              -0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              -0.049033500254154205,
              0.2862679958343506,
              -0.017480500042438507,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              -0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              -0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              -0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              -0.04890409857034683,
              0.28734898567199707,
              -0.016263799741864204,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              -0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              -0.04846389964222908,
              0.29102399945259094,
              -0.010888000018894672,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              -0.04829379916191101,
              0.29244399070739746,
              -0.007928590290248394,
              -0.04837450012564659,
              0.2917709946632385,
              -0.009428929537534714,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              -0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              -0.048106998205184937,
              0.29400399327278137,
              -0.003204300068318844,
              -0.048159800469875336,
              0.29356300830841064,
              -0.004813860170543194,
              -0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              -0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              -0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08701519668102264,
              0.0011090600164607167,
              0.10272300243377686,
              -0.08494249731302261,
              0.0011090600164607167,
              0.10316599905490875,
              -0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              -0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              -0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              -0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              -0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              -0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              -0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              -0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              -0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              -0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              -0.09920849651098251,
              0.0011090600164607167,
              0.0954774022102356,
              -0.09776269644498825,
              0.0011090600164607167,
              0.09690090268850327,
              -0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              -0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              -0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              -0.1005370020866394,
              0.001109090051613748,
              0.09394550323486328,
              -0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              -0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              -0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              -0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              -0.10598699748516083,
              0.001109090051613748,
              0.08085089921951294,
              -0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              -0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              -0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              -0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              -0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              -0.10588300228118896,
              0.001109090051613748,
              0.07440149784088135,
              -0.10610099881887436,
              0.001109090051613748,
              0.07658419758081436,
              -0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              -0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              -0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              -0.08280739933252335,
              0.0011090600164607167,
              0.10343799740076065,
              -0.08061569929122925,
              0.0011090600164607167,
              0.10353100299835205,
              -0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              -0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              -0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              -0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              -0.09279199689626694,
              0.0011090600164607167,
              0.10044199973344803,
              -0.09094689786434174,
              0.0011090299813076854,
              0.1013529971241951,
              -0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              -0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              -0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              -0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              -0.09620679914951324,
              0.0011090600164607167,
              0.09820809960365295,
              -0.09454759955406189,
              0.0011090600164607167,
              0.09939110279083252,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              -0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              -0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              -0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              -0.10281600058078766,
              0.001109090051613748,
              0.09058810025453568,
              -0.10174199938774109,
              0.0011090600164607167,
              0.09231310337781906,
              -0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              -0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              -0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              -0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              -0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              -0.104544997215271,
              0.001109090051613748,
              0.08689200133085251,
              -0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              -0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              -0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              -0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              -0.10566899925470352,
              0.0011090600164607167,
              0.08292029798030853,
              -0.10518600046634674,
              0.001109090051613748,
              0.08493659645318985,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              -0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              -0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              -0.07841949909925461,
              0.0011090600164607167,
              0.10353100299835205,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              -0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              -0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              -0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              -0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              -0.08701519668102264,
              0.0030783999245613813,
              0.10272300243377686,
              -0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              -0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              -0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              -0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              -0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              -0.08901900053024292,
              0.00309774000197649,
              0.10211600363254547,
              -0.09094680100679398,
              0.0031220600940287113,
              0.1013529971241951,
              -0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              -0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              -0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              -0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              -0.09620679914951324,
              0.0032222000882029533,
              0.09820819646120071,
              -0.09776269644498825,
              0.0032637999393045902,
              0.09690099954605103,
              -0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              -0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              -0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              -0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              -0.09920849651098251,
              0.0033091299701482058,
              0.09547749906778336,
              -0.1005370020866394,
              0.0033579200971871614,
              0.09394560009241104,
              -0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              -0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              -0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              -0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              -0.10174199938774109,
              0.003409889992326498,
              0.09231320023536682,
              -0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              -0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              -0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              -0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              -0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              -0.10281600058078766,
              0.0034648498985916376,
              0.09058819711208344,
              -0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              -0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              -0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              -0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              -0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              -0.103753000497818,
              0.003522489918395877,
              0.08877850323915482,
              -0.104544997215271,
              0.0035825700033456087,
              0.08689200133085251,
              -0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              -0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              -0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              -0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              -0.10518600046634674,
              0.0036448200698941946,
              0.08493659645318985,
              -0.10566899925470352,
              0.0037090498954057693,
              0.08292029798030853,
              -0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              -0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              -0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              -0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              -0.10598699748516083,
              0.0037749100010842085,
              0.08085089921951294,
              -0.10613299906253815,
              0.0038422599900513887,
              0.07873620092868805,
              -0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              -0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              -0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              -0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              -0.10588300228118896,
              0.003980310168117285,
              0.07440149784088135,
              -0.10557399690151215,
              0.0040491800755262375,
              0.07223939895629883,
              -0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              -0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              -0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              -0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              -0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              -0.08061569929122925,
              0.0030527100898325443,
              0.10353100299835205,
              -0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              -0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              0,
              0.003052680054679513,
              0.10353100299835205,
              -0.07841949909925461,
              0.0030527100898325443,
              0.10353100299835205,
              -0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              -0.04902850091457367,
              0.28682398796081543,
              -0.0009301499812863767,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              -0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              -0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              -0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              -0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              -0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              -0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              -0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              -0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              -0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              -0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              -0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              -0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              -0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              -0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              -0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              -0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              -0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.0492652989923954,
              0.28484800457954407,
              -0.01835639961063862,
              -0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              -0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              -0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              -0.0492652989923954,
              0.28484800457954407,
              -0.01835639961063862,
              -0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              -0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              -0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              -0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              -0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              -0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              -0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              -0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              -0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              -0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              -0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              -0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              -0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              -0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              -0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              -0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              -0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              -0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              -0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              -0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              -0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              -0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              -0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              -0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              -0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              -0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              -0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              -0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              -0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              -0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              -0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              -0.04902850091457367,
              0.28682398796081543,
              -0.0009301499812863767,
              -0.04813599959015846,
              0.29427599906921387,
              0.00009717050124891102,
              -0.04902850091457367,
              0.28682398796081543,
              -0.0009301499812863767,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              -0.04813599959015846,
              0.29427599906921387,
              0.00009717050124891102,
              -0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              -0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              -0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              -0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              -0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              -0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              -0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              -0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              -0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              -0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              -0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              -0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              -0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              -0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              -0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              -0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              -0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              -0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              -0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              -0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              -0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              -0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              -0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              -0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              -0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              -0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              -0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              -0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              -0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              -0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              -0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              -0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              -0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              -0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              -0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              -0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              -0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              -0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              -0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              -0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              -0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              -0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              -0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              -0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              -0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              -0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              -0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              -0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              -0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              -0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              -0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              -0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              -0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              -0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              -0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              -0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              -0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              -0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              -0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              -0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              -0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              -0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              -0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              -0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              -0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              -0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              -0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              -0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              -0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              -0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              -0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              -0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              -0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              -0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              -0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              -0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              -0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              -0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              -0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              -0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              -0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              -0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              -0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              -0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              -0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              -0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              -0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              -0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              -0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              -0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              -0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              -0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              -0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              -0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              -0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              -0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              -0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              -0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              -0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              -0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              -0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              -0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              -0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              -0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              -0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              -0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              -0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              -0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              -0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              -0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              -0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              -0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              -0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              -0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              -0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              -0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              -0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              -0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              -0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              -0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              -0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              -0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              -0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              -0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              -0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              -0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              -0.10563500225543976,
              0.0015097300056368113,
              0.07223069667816162,
              -0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              -0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              -0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              -0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              -0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              -0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              -0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              -0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              -0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              -0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              -0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              -0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              -0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              -0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              -0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              -0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              -0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              -0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              -0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              -0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.04994489997625351,
              0.27917298674583435,
              -0.01428310014307499,
              -0.04996189847588539,
              0.27903199195861816,
              -0.014120399951934814,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              -0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              -0.04983939975500107,
              0.2800540030002594,
              -0.013473300263285637,
              -0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              -0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              -0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              -0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              -0.049143899232149124,
              0.28586000204086304,
              -0.00447337981313467,
              -0.0491688996553421,
              0.2856520116329193,
              -0.004417759831994772,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              -0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              -0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              -0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              -0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              -0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              -0.048329900950193405,
              0.2926560044288635,
              -0.006286419928073883,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              -0.048305001109838486,
              0.29286500811576843,
              -0.006342030130326748,
              -0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              -0.050811201333999634,
              0.27194100618362427,
              -0.018599700182676315,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              -0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              -0.05081870034337044,
              0.2718780040740967,
              -0.018392499536275864,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              -0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              -0.050675101578235626,
              0.27307701110839844,
              -0.0181403998285532,
              -0.05068409815430641,
              0.2730019986629486,
              -0.01793760061264038,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              -0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              -0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              -0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              -0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              -0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              -0.04877439886331558,
              0.2889449894428253,
              0.011264700442552567,
              -0.04874910041689873,
              0.2891570031642914,
              0.0112252002581954,
              -0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              -0.04874910041689873,
              0.2891570031642914,
              0.0112252002581954,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              -0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              -0.049869898706674576,
              0.2797999978065491,
              -0.022336000576615334,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              -0.049856700003147125,
              0.27990999817848206,
              -0.022521700710058212,
              -0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              -0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              -0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              -0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              -0.04940680041909218,
              0.2836660146713257,
              -0.019442999735474586,
              -0.0492652989923954,
              0.28484800457954407,
              -0.01835639961063862,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.0492652989923954,
              0.28484800457954407,
              -0.01835639961063862,
              -0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              -0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              -0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              -0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              -0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              -0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              -0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              -0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08903919905424118,
              0.00150969997048378,
              0.10217399895191193,
              -0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              -0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              -0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              -0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              -0.08903919905424118,
              0.001293360022827983,
              0.10217399895191193,
              -0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              -0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              -0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              -0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              -0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              -0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              -0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              -0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              -0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              -0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              -0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              -0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              -0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              -0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              -0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              -0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              -0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              -0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              -0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              -0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              -0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              -0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              -0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              -0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              -0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              -0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              -0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              -0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              -0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              -0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              -0.10380899906158447,
              0.00150969997048378,
              0.08880449831485748,
              -0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              -0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              -0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              -0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              -0.10380899906158447,
              0.0012934199767187238,
              0.08880449831485748,
              -0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              -0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              -0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              -0.10619500279426575,
              0.0015097300056368113,
              0.07873789966106415,
              -0.10619500279426575,
              0.0012934500118717551,
              0.07873789966106415,
              -0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              -0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              -0.10563500225543976,
              0.0015097300056368113,
              0.07223069667816162,
              -0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              -0.10563500225543976,
              0.0015097300056368113,
              0.07223069667816162,
              -0.10563500225543976,
              0.0012934500118717551,
              0.07223069667816162,
              -0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              -0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              -0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              -0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              -0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              -0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              -0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              -0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              -0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              -0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              -0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              -0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              -0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              -0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              -0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              -0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              -0.08281259983778,
              0.002653090050444007,
              0.10350000113248825,
              -0.08281259983778,
              0.0028693699277937412,
              0.10350000113248825,
              -0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              -0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              -0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              -0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              -0.08495280146598816,
              0.0026617299299687147,
              0.10322699695825577,
              -0.08495280146598816,
              0.002878040075302124,
              0.10322699695825577,
              -0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              -0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              -0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              -0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              -0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              -0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              -0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              -0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              -0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              -0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              -0.0928213968873024,
              0.0027486400213092566,
              0.10049600154161453,
              -0.0928213968873024,
              0.002964939922094345,
              0.10049600154161453,
              -0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              -0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              -0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              -0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              -0.09458129853010178,
              0.0027821999974548817,
              0.09944260120391846,
              -0.09458129853010178,
              0.00299849989823997,
              0.09944260120391846,
              -0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              -0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              -0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              -0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              -0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              -0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              -0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              -0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              -0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              -0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              -0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              -0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              -0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              -0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              -0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              -0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              -0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              -0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              -0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              -0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              -0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              -0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              -0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              -0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              -0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              -0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              -0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              -0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              -0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              -0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              -0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              -0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              -0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              -0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              -0.10616199672222137,
              0.0035100900568068027,
              0.0765807032585144,
              -0.10616199672222137,
              0.0037264800630509853,
              0.0765807032585144,
              -0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              -0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              -0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              -0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              -0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              -0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              -0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              -0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              -0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              -0.05019279941916466,
              0.27504101395606995,
              -0.0166471004486084,
              -0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              -0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              -0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04761059954762459,
              0.2966090142726898,
              0.009838059544563293,
              -0.047394201159477234,
              0.2966090142726898,
              0.009837949648499489,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              -0.047394201159477234,
              0.2966090142726898,
              0.009837949648499489,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              -0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              -0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              -0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              -0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04995470121502876,
              0.27702999114990234,
              -0.015385299921035767,
              -0.0500716008245945,
              0.2760530114173889,
              -0.01604050025343895,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              -0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              -0.049060601741075516,
              0.2845019996166229,
              -0.006535459775477648,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              -0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              -0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              -0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              -0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              -0.04862150177359581,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              -0.04862150177359581,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.04886870086193085,
              0.2861050069332123,
              -0.0020690900273621082,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              -0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              -0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              -0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              -0.04993870109319687,
              0.27716198563575745,
              -0.024358099326491356,
              -0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              -0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              -0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              -0.048183098435401917,
              0.2918280065059662,
              -0.009451580233871937,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              -0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              -0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              -0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              -0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              -0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              -0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              -0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              -0.08487509936094284,
              0.0010476099560037255,
              0.10277099907398224,
              -0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              -0.08487509936094284,
              0.0010476099560037255,
              0.10277099907398224,
              -0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              -0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.0959613025188446,
              0.0010476099560037255,
              0.0978911966085434,
              -0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              -0.0959613025188446,
              0.0010476099560037255,
              0.0978911966085434,
              -0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              -0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              -0.0989162027835846,
              0.0010476399911567569,
              0.09520299732685089,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.09763850271701813,
              0.0010476099560037255,
              0.09676449745893478,
              -0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              -0.10141000151634216,
              0.0010476399911567569,
              0.09208790212869644,
              -0.10022400319576263,
              0.0010476099560037255,
              0.09369490295648575,
              -0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              -0.10022400319576263,
              0.0010476099560037255,
              0.09369490295648575,
              -0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              -0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              -0.10558900237083435,
              0.0010476399911567569,
              0.08080659806728363,
              -0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              -0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              -0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              -0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              -0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              -0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              -0.10570000112056732,
              0.0010476700263097882,
              0.07660719752311707,
              -0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              -0.10570000112056732,
              0.0010476700263097882,
              0.07660719752311707,
              -0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              -0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              -0.0827736034989357,
              0.0010476399911567569,
              0.10303899645805359,
              -0.08060730248689651,
              0.0010476099560037255,
              0.10312999784946442,
              -0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              -0.08060730248689651,
              0.0010476099560037255,
              0.10312999784946442,
              -0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              -0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              -0.09260019659996033,
              0.0010476099560037255,
              0.10008999705314636,
              -0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              -0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              -0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              -0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              -0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              -0.0959613025188446,
              0.0010476099560037255,
              0.0978911966085434,
              -0.09432809799909592,
              0.001047580037266016,
              0.09905560314655304,
              -0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              -0.09432809799909592,
              0.001047580037266016,
              0.09905560314655304,
              -0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              -0.0960938036441803,
              0.0010476399911567569,
              0.09806229919195175,
              -0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              -0.10141000151634216,
              0.0010476399911567569,
              0.09208790212869644,
              -0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              -0.10141000151634216,
              0.0010476399911567569,
              0.09208790212869644,
              -0.10159000009298325,
              0.0010476399911567569,
              0.09220950305461884,
              -0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              -0.1048009991645813,
              0.0010476399911567569,
              0.08482740074396133,
              -0.10417000204324722,
              0.0010476399911567569,
              0.0867517963051796,
              -0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              -0.10417000204324722,
              0.0010476399911567569,
              0.0867517963051796,
              -0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              -0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              -0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              -0.1048009991645813,
              0.0010476399911567569,
              0.08482740074396133,
              -0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              -0.1048009991645813,
              0.0010476399911567569,
              0.08482740074396133,
              -0.10500899702310562,
              0.0010476399911567569,
              0.08488640189170837,
              -0.10548800230026245,
              0.0010476399911567569,
              0.08288480341434479,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              -0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              -0.08888690173625946,
              0.003171290038153529,
              0.10173700004816055,
              -0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              -0.08888690173625946,
              0.003171290038153529,
              0.10173700004816055,
              -0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              -0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              -0.08888690173625946,
              0.003171290038153529,
              0.10173700004816055,
              -0.09078410267829895,
              0.0031951400451362133,
              0.10098700225353241,
              -0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              -0.09078410267829895,
              0.0031951400451362133,
              0.10098700225353241,
              -0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              -0.08895819634199142,
              0.0031647998839616776,
              0.10194200277328491,
              -0.09596119821071625,
              0.0032937501091510057,
              0.09789139777421951,
              -0.09749279916286469,
              0.003334699897095561,
              0.0966046005487442,
              -0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              -0.09749279916286469,
              0.003334699897095561,
              0.0966046005487442,
              -0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              -0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              -0.09891609847545624,
              0.00337934005074203,
              0.09520330280065536,
              -0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              -0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              -0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              -0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              -0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              -0.10141000151634216,
              0.003478559898212552,
              0.09208820015192032,
              -0.10246799886226654,
              0.003532649949193001,
              0.09039010107517242,
              -0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              -0.10246799886226654,
              0.003532649949193001,
              0.09039010107517242,
              -0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              -0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              -0.10246799886226654,
              0.003532649949193001,
              0.09039010107517242,
              -0.10339000076055527,
              0.003589330008253455,
              0.08860869705677032,
              -0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              -0.10339000076055527,
              0.003589330008253455,
              0.08860869705677032,
              -0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              -0.10265599936246872,
              0.003529219888150692,
              0.09049700200557709,
              -0.10339000076055527,
              0.003589330008253455,
              0.08860869705677032,
              -0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              -0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              -0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              -0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              -0.10358600318431854,
              0.0035864401143044233,
              0.08870039880275726,
              -0.10480000078678131,
              0.0037097299937158823,
              0.0848274976015091,
              -0.10527600347995758,
              0.003772940021008253,
              0.08284319937229156,
              -0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              -0.10527600347995758,
              0.003772940021008253,
              0.08284319937229156,
              -0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              -0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              -0.10558900237083435,
              0.0038378199096769094,
              0.08080659806728363,
              -0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              -0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              -0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              -0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              -0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              -0.10548499971628189,
              0.004040270112454891,
              0.07444970309734344,
              -0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              -0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              -0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              -0.1053910031914711,
              0.004109830129891634,
              0.07226540148258209,
              -0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              -0.07841949909925461,
              0.003126889932900667,
              0.10312999784946442,
              -0.08060730248689651,
              0.0031269199680536985,
              0.10312999784946442,
              -0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              -0.08060730248689651,
              0.0031269199680536985,
              0.10312999784946442,
              -0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              -0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              0,
              0.003126859897747636,
              0.10312999784946442,
              -0.07841949909925461,
              0.003126889932900667,
              0.10312999784946442,
              0,
              0.003120010020211339,
              0.10334599763154984,
              -0.07841949909925461,
              0.003126889932900667,
              0.10312999784946442,
              -0.07841949909925461,
              0.003120010020211339,
              0.10334700345993042,
              0,
              0.003120010020211339,
              0.10334599763154984,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              -0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              -0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              -0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              -0.04973439872264862,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04963139817118645,
              0.27973198890686035,
              -0.013144600205123425,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              -0.04900269955396652,
              0.2849850058555603,
              -0.005458950065076351,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              -0.0489065982401371,
              0.28578901290893555,
              -0.003224019892513752,
              -0.04895130172371864,
              0.28541499376296997,
              -0.004354559816420078,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              -0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              -0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              -0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              -0.0503045991063118,
              0.2741059958934784,
              -0.025692300871014595,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04976379871368408,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              -0.048102300614118576,
              0.2925019860267639,
              -0.007947860285639763,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              -0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              -0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              -0.04803059995174408,
              0.2931019961833954,
              -0.006405230145901442,
              -0.04796820133924484,
              0.2936230003833771,
              -0.0048261601477861404,
              -0.047655899077653885,
              0.29442399740219116,
              -0.0015682900557294488,
              -0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              -0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              -0.047915298491716385,
              0.294064998626709,
              -0.0032130300533026457,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              -0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              -0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              -0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              -0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              -0.050317998975515366,
              0.2739950120449066,
              -0.017203200608491898,
              -0.05044719949364662,
              0.2729159891605377,
              -0.0177071001380682,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              -0.049271099269390106,
              0.2827419936656952,
              -0.009580370038747787,
              -0.04935299977660179,
              0.282056987285614,
              -0.010528000071644783,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              -0.04912469908595085,
              0.2839660048484802,
              -0.007582370191812515,
              -0.049194999039173126,
              0.283378005027771,
              -0.008597910404205322,
              -0.04862150177359581,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              -0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04959459975361824,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              -0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              -0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              -0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              -0.049123700708150864,
              0.2839690148830414,
              -0.01979050040245056,
              -0.048979900777339935,
              0.2851699888706207,
              -0.018685199320316315,
              -0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              -0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              -0.04859140142798424,
              0.2884159982204437,
              -0.015029099769890308,
              -0.04847700148820877,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              -0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              -0.048272598534822464,
              0.2910799980163574,
              -0.010913999751210213,
              -0.08888699859380722,
              0.0010476399911567569,
              0.10173700004816055,
              -0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              -0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              -0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              -0.08696909993886948,
              0.0010476099560037255,
              0.10254400223493576,
              -0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              -0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              -0.08888699859380722,
              0.0010476399911567569,
              0.10173700004816055,
              -0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              -0.08888699859380722,
              0.0010476399911567569,
              0.10173700004816055,
              -0.08895819634199142,
              0.0010476099560037255,
              0.10194200277328491,
              -0.09087199717760086,
              0.001047580037266016,
              0.10118400305509567,
              -0.09432809799909592,
              0.001047580037266016,
              0.09905560314655304,
              -0.09260019659996033,
              0.0010476099560037255,
              0.10008999705314636,
              -0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              -0.09260019659996033,
              0.0010476099560037255,
              0.10008999705314636,
              -0.09270379692316055,
              0.0010476099560037255,
              0.10028000175952911,
              -0.09444659948348999,
              0.0010476399911567569,
              0.09923669695854187,
              -0.10570000112056732,
              0.0010476700263097882,
              0.07660719752311707,
              -0.10573200136423111,
              0.0010476399911567569,
              0.07872539758682251,
              -0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              -0.10573200136423111,
              0.0010476399911567569,
              0.07872539758682251,
              -0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              -0.10591699928045273,
              0.0010476700263097882,
              0.07659479975700378,
              -0.08487509936094284,
              0.0010476099560037255,
              0.10277099907398224,
              -0.0827736034989357,
              0.0010476399911567569,
              0.10303899645805359,
              -0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              -0.0827736034989357,
              0.0010476399911567569,
              0.10303899645805359,
              -0.08279190212488174,
              0.0010476399911567569,
              0.10325399786233902,
              -0.08491150289773941,
              0.0010476099560037255,
              0.10298500210046768,
              -0.10022400319576263,
              0.0010476099560037255,
              0.09369490295648575,
              -0.0989162027835846,
              0.0010476399911567569,
              0.09520299732685089,
              -0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              -0.0989162027835846,
              0.0010476399911567569,
              0.09520299732685089,
              -0.09907399863004684,
              0.0010476399911567569,
              0.0953511968255043,
              -0.10039299726486206,
              0.0010476399911567569,
              0.09383019804954529,
              -0.10339000076055527,
              0.0010476399911567569,
              0.0886085033416748,
              -0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              -0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              -0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              -0.10265599936246872,
              0.0010476099560037255,
              0.09049689769744873,
              -0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              -0.10417000204324722,
              0.0010476399911567569,
              0.0867517963051796,
              -0.10339000076055527,
              0.0010476399911567569,
              0.0886085033416748,
              -0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              -0.10339000076055527,
              0.0010476399911567569,
              0.0886085033416748,
              -0.10358600318431854,
              0.0010476399911567569,
              0.0887003019452095,
              -0.10437200218439102,
              0.0010476099560037255,
              0.08682750165462494,
              -0.10573200136423111,
              0.0010476399911567569,
              0.07872539758682251,
              -0.10558900237083435,
              0.0010476399911567569,
              0.08080659806728363,
              -0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              -0.10558900237083435,
              0.0010476399911567569,
              0.08080659806728363,
              -0.10580399632453918,
              0.0010476399911567569,
              0.08083049952983856,
              -0.10594899952411652,
              0.0010476700263097882,
              0.07873129844665527,
              -0.10517700016498566,
              0.0010476399911567569,
              0.07229600101709366,
              -0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              -0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              -0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              -0.10570000112056732,
              0.0010476399911567569,
              0.07442370057106018,
              -0.1053910031914711,
              0.0010476700263097882,
              0.07226540148258209,
              -0.08060730248689651,
              0.0010476099560037255,
              0.10312999784946442,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              -0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10334599763154984,
              -0.08061189949512482,
              0.0010476099560037255,
              0.10334599763154984,
              -0.08060730248689651,
              0.0031269199680536985,
              0.10312999784946442,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              -0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              -0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              -0.08061180263757706,
              0.003120010020211339,
              0.10334700345993042,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              -0.08487509936094284,
              0.0031383601017296314,
              0.10277099907398224,
              -0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              -0.08487509936094284,
              0.0031383601017296314,
              0.10277099907398224,
              -0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              -0.08279190212488174,
              0.0031229599844664335,
              0.1032550036907196,
              -0.08487509936094284,
              0.0031383601017296314,
              0.10277099907398224,
              -0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              -0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              -0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              -0.08696909993886948,
              0.003145549912005663,
              0.10254400223493576,
              -0.08491150289773941,
              0.0031315400265157223,
              0.10298500210046768,
              -0.09078410267829895,
              0.0031951400451362133,
              0.10098700225353241,
              -0.09260009974241257,
              0.0032236899714916945,
              0.10008999705314636,
              -0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              -0.09260009974241257,
              0.0032236899714916945,
              0.10008999705314636,
              -0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              -0.09087199717760086,
              0.0031888498924672604,
              0.10118400305509567,
              -0.09260009974241257,
              0.0032236899714916945,
              0.10008999705314636,
              -0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              -0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              -0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              -0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              -0.09270370006561279,
              0.0032176401000469923,
              0.10028000175952911,
              -0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              -0.09596119821071625,
              0.0032937501091510057,
              0.09789139777421951,
              -0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              -0.09596119821071625,
              0.0032937501091510057,
              0.09789139777421951,
              -0.0960938036441803,
              0.0032882699742913246,
              0.09806240350008011,
              -0.09444650262594223,
              0.0032508699223399162,
              0.09923689812421799,
              -0.09749279916286469,
              0.003334699897095561,
              0.0966046005487442,
              -0.09891609847545624,
              0.00337934005074203,
              0.09520330280065536,
              -0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              -0.09891609847545624,
              0.00337934005074203,
              0.09520330280065536,
              -0.09907390177249908,
              0.0033746100962162018,
              0.09535130113363266,
              -0.09763850271701813,
              0.003329630009829998,
              0.09676460176706314,
              -0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              -0.10141000151634216,
              0.003478559898212552,
              0.09208820015192032,
              -0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              -0.10141000151634216,
              0.003478559898212552,
              0.09208820015192032,
              -0.10159000009298325,
              0.003474680008366704,
              0.0922095999121666,
              -0.10039299726486206,
              0.003423040034249425,
              0.0938303992152214,
              -0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              -0.10480000078678131,
              0.0037097299937158823,
              0.0848274976015091,
              -0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              -0.10480000078678131,
              0.0037097299937158823,
              0.0848274976015091,
              -0.10500899702310562,
              0.0037078598979860544,
              0.08488640189170837,
              -0.10437200218439102,
              0.0036460799165070057,
              0.0868275985121727,
              -0.10527600347995758,
              0.003772940021008253,
              0.08284319937229156,
              -0.10558900237083435,
              0.0038378199096769094,
              0.08080659806728363,
              -0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              -0.10558900237083435,
              0.0038378199096769094,
              0.08080659806728363,
              -0.10580399632453918,
              0.003837049938738346,
              0.08083049952983856,
              -0.10548800230026245,
              0.0037716301158070564,
              0.08288480341434479,
              -0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              -0.10570000112056732,
              0.003971550147980452,
              0.07660719752311707,
              -0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              -0.10570000112056732,
              0.003971550147980452,
              0.07660719752311707,
              -0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              -0.10594899952411652,
              0.0039039000403136015,
              0.07873129844665527,
              -0.10570000112056732,
              0.003971550147980452,
              0.07660719752311707,
              -0.10548499971628189,
              0.004040270112454891,
              0.07444970309734344,
              -0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              -0.10548499971628189,
              0.004040270112454891,
              0.07444970309734344,
              -0.10570000112056732,
              0.004041079897433519,
              0.07442360371351242,
              -0.10591699928045273,
              0.003971930127590895,
              0.07659479975700378,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              -0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              -0.05054280161857605,
              0.274181991815567,
              -0.017625700682401657,
              -0.050553299486637115,
              0.27409398555755615,
              -0.017427900806069374,
              -0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              -0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              -0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              -0.05041449889540672,
              0.2752529978752136,
              -0.017057299613952637,
              -0.05042640119791031,
              0.2751539945602417,
              -0.01686530001461506,
              -0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              -0.05005539953708649,
              0.2782509922981262,
              -0.015048200264573097,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              -0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              -0.049554500728845596,
              0.28243300318717957,
              -0.010793999768793583,
              -0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              -0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              -0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              -0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              -0.049575500190258026,
              0.28225699067115784,
              -0.010669499635696411,
              -0.0493927001953125,
              0.2837829887866974,
              -0.008817539550364017,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              -0.04947059974074364,
              0.28313300013542175,
              -0.009823589585721493,
              -0.04949260130524635,
              0.2829500138759613,
              -0.009709740057587624,
              -0.04941539838910103,
              0.283594012260437,
              -0.008714740164577961,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              -0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              -0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              -0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              -0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              -0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              -0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              -0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              -0.04955489933490753,
              0.2824299931526184,
              -0.02047000080347061,
              -0.049539100378751755,
              0.2825610041618347,
              -0.0206409003585577,
              -0.04969479888677597,
              0.28126099705696106,
              -0.021613700315356255,
              -0.0492652989923954,
              0.28484800457954407,
              -0.01835639961063862,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              -0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              -0.04924720153212547,
              0.2849990129470825,
              -0.018510300666093826,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              -0.04817330092191696,
              0.2939639985561371,
              -0.0015296200290322304,
              -0.04813599959015846,
              0.29427599906921387,
              0.00009717050124891102,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              -0.05017060041427612,
              0.2772890031337738,
              -0.015766799449920654,
              -0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              -0.0502903014421463,
              0.2762889862060547,
              -0.016437100246548653,
              -0.05030360072851181,
              0.2761789858341217,
              -0.016251500695943832,
              -0.05018509924411774,
              0.27716800570487976,
              -0.015588199719786644,
              -0.049644000828266144,
              0.281686007976532,
              -0.011726999655365944,
              -0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              -0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              -0.04973899945616722,
              0.280892014503479,
              -0.012620699591934681,
              -0.049758199602365494,
              0.28073200583457947,
              -0.012476099655032158,
              -0.049664098769426346,
              0.28151699900627136,
              -0.0115922000259161,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              -0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              -0.049320898950099945,
              0.28438299894332886,
              -0.007777680177241564,
              -0.04934430122375488,
              0.284186989068985,
              -0.0076862601563334465,
              -0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              -0.04919629916548729,
              0.28542301058769226,
              -0.005603789817541838,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              -0.04925540089607239,
              0.2849299907684326,
              -0.006705820094794035,
              -0.04927939921617508,
              0.28472900390625,
              -0.00662607979029417,
              -0.04922090098261833,
              0.2852180004119873,
              -0.005535989999771118,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              -0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              -0.04909839853644371,
              0.28624001145362854,
              -0.0033164098858833313,
              -0.049123700708150864,
              0.28602901101112366,
              -0.0032731699757277966,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              -0.04902850091457367,
              0.28682398796081543,
              -0.0009301499812863767,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              -0.049059901386499405,
              0.2865619957447052,
              -0.0021347200963646173,
              -0.049085501581430435,
              0.28634798526763916,
              -0.0021039999555796385,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              -0.050568100064992905,
              0.27397099137306213,
              -0.025249699130654335,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              -0.050385601818561554,
              0.2754940092563629,
              -0.02462949976325035,
              -0.05037660151720047,
              0.27556899189949036,
              -0.02483239956200123,
              -0.05056050047278404,
              0.2740339934825897,
              -0.025456899777054787,
              -0.05020830035209656,
              0.27697399258613586,
              -0.023935599252581596,
              -0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              -0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              -0.05003629997372627,
              0.2784099876880646,
              -0.02317029982805252,
              -0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              -0.050197798758745193,
              0.27706199884414673,
              -0.02413339912891388,
              -0.049130599945783615,
              0.285971999168396,
              -0.017212700098752975,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              -0.04911139979958534,
              0.28613200783729553,
              -0.017357299104332924,
              -0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              -0.048882801085710526,
              0.28804099559783936,
              -0.014763100072741508,
              -0.04886170104146004,
              0.2882170081138611,
              -0.01488760020583868,
              -0.0489828996360302,
              0.2872050106525421,
              -0.016148900613188744,
              -0.048770200461149216,
              0.28898099064826965,
              -0.013461999595165253,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              -0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              -0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              -0.04874829947948456,
              0.2891640067100525,
              -0.013575799763202667,
              -0.04856880009174347,
              0.2906619906425476,
              -0.010718699544668198,
              -0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              -0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              -0.04854540154337883,
              0.2908580005168915,
              -0.01081009954214096,
              -0.048480499535799026,
              0.2913999855518341,
              -0.00928121991455555,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              -0.04840080067515373,
              0.29206499457359314,
              -0.007803020067512989,
              -0.04837619885802269,
              0.2922700047492981,
              -0.007870820350944996,
              -0.048456501215696335,
              0.29159998893737793,
              -0.009360959753394127,
              -0.04826829954981804,
              0.2931709885597229,
              -0.004733750130981207,
              -0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              -0.048215899616479874,
              0.29360800981521606,
              -0.0031473899725824594,
              -0.04819029942154884,
              0.2938219904899597,
              -0.0031781198922544718,
              -0.048242900520563126,
              0.29338300228118896,
              -0.004777010064572096,
              -0.08495280146598816,
              0.00150969997048378,
              0.10322699695825577,
              -0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              -0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              -0.08703059703111649,
              0.00150969997048378,
              0.1027820035815239,
              -0.08703059703111649,
              0.0012934199767187238,
              0.1027820035815239,
              -0.08495280146598816,
              0.0012934199767187238,
              0.10322699695825577,
              -0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              -0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              -0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              -0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              -0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              -0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              -0.09780409932136536,
              0.00150969997048378,
              0.09694640338420868,
              -0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              -0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              -0.09925329685211182,
              0.00150969997048378,
              0.09551949799060822,
              -0.09925329685211182,
              0.0012934199767187238,
              0.09551949799060822,
              -0.09780409932136536,
              0.0012933900579810143,
              0.09694640338420868,
              -0.10058499872684479,
              0.0015096700517460704,
              0.09398400038480759,
              -0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              -0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              -0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              -0.10058499872684479,
              0.0012933900579810143,
              0.09398400038480759,
              -0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              -0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              -0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              -0.10604800283908844,
              0.0015097300056368113,
              0.08085770159959793,
              -0.10604800283908844,
              0.0012934199767187238,
              0.08085770159959793,
              -0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              -0.10616199672222137,
              0.00150969997048378,
              0.0765807032585144,
              -0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              -0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              -0.10594400018453598,
              0.0015097300056368113,
              0.0743940994143486,
              -0.10594400018453598,
              0.0012934199767187238,
              0.0743940994143486,
              -0.10616199672222137,
              0.0012934199767187238,
              0.0765807032585144,
              -0.08061700314283371,
              0.00150969997048378,
              0.10359200090169907,
              -0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              -0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              -0.08281259983778,
              0.00150969997048378,
              0.10350000113248825,
              -0.08281259983778,
              0.0012933900579810143,
              0.10350000113248825,
              -0.08061700314283371,
              0.0012933900579810143,
              0.10359200090169907,
              -0.09097179770469666,
              0.00150969997048378,
              0.10140900313854218,
              -0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              -0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              -0.0928213968873024,
              0.00150969997048378,
              0.10049600154161453,
              -0.0928213968873024,
              0.0012933900579810143,
              0.10049600154161453,
              -0.09097179770469666,
              0.0012934199767187238,
              0.10140900313854218,
              -0.09458129853010178,
              0.00150969997048378,
              0.09944260120391846,
              -0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              -0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              -0.09624440222978592,
              0.00150969997048378,
              0.09825679659843445,
              -0.09624440222978592,
              0.0012933900579810143,
              0.09825669974088669,
              -0.09458129853010178,
              0.0012933900579810143,
              0.09944260120391846,
              -0.10179299861192703,
              0.0015097300056368113,
              0.09234769642353058,
              -0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              -0.10287000238895416,
              0.00150969997048378,
              0.09061860293149948,
              -0.10287000238895416,
              0.0012934199767187238,
              0.09061849862337112,
              -0.10179299861192703,
              0.0012934199767187238,
              0.09234769642353058,
              -0.10460299998521805,
              0.00150969997048378,
              0.08691350370645523,
              -0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              -0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              -0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              -0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              -0.10460299998521805,
              0.0012934199767187238,
              0.08691350370645523,
              -0.10524500161409378,
              0.0015097300056368113,
              0.08495339751243591,
              -0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              -0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              -0.10572999715805054,
              0.0015097300056368113,
              0.08293209969997406,
              -0.10572999715805054,
              0.0012934199767187238,
              0.08293209969997406,
              -0.10524500161409378,
              0.0012934199767187238,
              0.08495339751243591,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              -0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              -0.07841949909925461,
              0.00150969997048378,
              0.10359200090169907,
              -0.07841949909925461,
              0.0012933900579810143,
              0.10359200090169907,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              -0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              -0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              -0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              -0.08703059703111649,
              0.002675889991223812,
              0.10278300195932388,
              -0.08703059703111649,
              0.00289217010140419,
              0.10278300195932388,
              -0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              -0.09097179770469666,
              0.002719609998166561,
              0.10140900313854218,
              -0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              -0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              -0.08903919905424118,
              0.002695260103791952,
              0.10217399895191193,
              -0.08903919905424118,
              0.002911570016294718,
              0.10217399895191193,
              -0.09097179770469666,
              0.002935890108346939,
              0.10140900313854218,
              -0.09780409932136536,
              0.00286164996214211,
              0.09694640338420868,
              -0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              -0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              -0.09624440222978592,
              0.0028199299704283476,
              0.09825679659843445,
              -0.09624440222978592,
              0.0030362599063664675,
              0.09825679659843445,
              -0.09780409932136536,
              0.0030779798980802298,
              0.09694640338420868,
              -0.10058499872684479,
              0.0029559400863945484,
              0.09398400038480759,
              -0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              -0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              -0.09925329685211182,
              0.0029070700984448195,
              0.09551949799060822,
              -0.09925329685211182,
              0.0031234300695359707,
              0.09551949799060822,
              -0.10058499872684479,
              0.0031723100692033768,
              0.09398400038480759,
              -0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              -0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              -0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              -0.10179299861192703,
              0.003008039901033044,
              0.09234769642353058,
              -0.10179299861192703,
              0.0032244001049548388,
              0.09234769642353058,
              -0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              -0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              -0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              -0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              -0.10287000238895416,
              0.0030631099361926317,
              0.09061849862337112,
              -0.10287000238895416,
              0.00327947991900146,
              0.09061860293149948,
              -0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              -0.10460299998521805,
              0.00318107008934021,
              0.08691350370645523,
              -0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              -0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              -0.10380899906158447,
              0.0031208700966089964,
              0.08880449831485748,
              -0.10380899906158447,
              0.0033372300677001476,
              0.08880449831485748,
              -0.10460299998521805,
              0.0033974600955843925,
              0.08691350370645523,
              -0.10572999715805054,
              0.003307820064947009,
              0.08293209969997406,
              -0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              -0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              -0.10524500161409378,
              0.0032434500753879547,
              0.08495339751243591,
              -0.10524500161409378,
              0.0034598400816321373,
              0.08495339751243591,
              -0.10572999715805054,
              0.0035242100711911917,
              0.08293209969997406,
              -0.10619500279426575,
              0.0034413901157677174,
              0.07873789966106415,
              -0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              -0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              -0.10604800283908844,
              0.0033738899510353804,
              0.08085770159959793,
              -0.10604800283908844,
              0.0035902599338442087,
              0.08085770159959793,
              -0.10619500279426575,
              0.0036577899008989334,
              0.07873789966106415,
              -0.10563500225543976,
              0.00364863988943398,
              0.07223069667816162,
              -0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              -0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              -0.10594400018453598,
              0.0035797099117189646,
              0.0743940994143486,
              -0.10594400018453598,
              0.0037961299531161785,
              0.0743940994143486,
              -0.10563500225543976,
              0.0038650298956781626,
              0.07223069667816162,
              -0.08061700314283371,
              0.0026501400861889124,
              0.10359200090169907,
              -0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              -0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              -0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              -0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              -0.08061700314283371,
              0.0028664199635386467,
              0.10359200090169907,
              -0.07841949909925461,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              -0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              -0.07841949909925461,
              0.0028663899283856153,
              0.10359200090169907,
              -0.0827736034989357,
              0.0010476399911567569,
              0.10303899645805359,
              -0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              -0.08060730248689651,
              0.0010476099560037255,
              0.10312999784946442,
              -0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              -0.08060730248689651,
              0.0010476099560037255,
              0.10312999784946442,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              -0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              -0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              -0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              -0.10517700016498566,
              0.0010476399911567569,
              0.07229600101709366,
              -0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              -0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              -0.10570000112056732,
              0.0010476700263097882,
              0.07660719752311707,
              -0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              -0.10573200136423111,
              0.0010476399911567569,
              0.07872539758682251,
              -0.10570000112056732,
              0.0010476700263097882,
              0.07660719752311707,
              -0.10573200136423111,
              0.0010476399911567569,
              0.07872539758682251,
              -0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              -0.10558900237083435,
              0.0010476399911567569,
              0.08080659806728363,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              -0.10548499971628189,
              0.0010476700263097882,
              0.07444970309734344,
              -0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              -0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              -0.1048009991645813,
              0.0010476399911567569,
              0.08482740074396133,
              -0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              -0.10417000204324722,
              0.0010476399911567569,
              0.0867517963051796,
              -0.1048009991645813,
              0.0010476399911567569,
              0.08482740074396133,
              -0.10417000204324722,
              0.0010476399911567569,
              0.0867517963051796,
              -0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              -0.10339000076055527,
              0.0010476399911567569,
              0.0886085033416748,
              -0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.10141000151634216,
              0.0010476399911567569,
              0.09208790212869644,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.10022400319576263,
              0.0010476099560037255,
              0.09369490295648575,
              -0.10141000151634216,
              0.0010476399911567569,
              0.09208790212869644,
              -0.10022400319576263,
              0.0010476099560037255,
              0.09369490295648575,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.0989162027835846,
              0.0010476399911567569,
              0.09520299732685089,
              -0.10527600347995758,
              0.0010476399911567569,
              0.0828431025147438,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.10246799886226654,
              0.0010476099560037255,
              0.09038980305194855,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              -0.0959613025188446,
              0.0010476099560037255,
              0.0978911966085434,
              -0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              -0.09432809799909592,
              0.001047580037266016,
              0.09905560314655304,
              -0.0959613025188446,
              0.0010476099560037255,
              0.0978911966085434,
              -0.09432809799909592,
              0.001047580037266016,
              0.09905560314655304,
              -0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              -0.09260019659996033,
              0.0010476099560037255,
              0.10008999705314636,
              -0.07841949909925461,
              0.0010476099560037255,
              0.10312999784946442,
              -0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              -0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              -0.09749290347099304,
              0.0010476099560037255,
              0.09660439938306808,
              -0.09078419953584671,
              0.0010476099560037255,
              0.10098599642515182,
              -0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              -0.08888699859380722,
              0.0010476399911567569,
              0.10173700004816055,
              -0.08487509936094284,
              0.0010476099560037255,
              0.10277099907398224,
              -0.08691509813070297,
              0.0010476099560037255,
              0.10233499854803085,
              -0.0827736034989357,
              0.0010476399911567569,
              0.10303899645805359,
              -0.09078410267829895,
              0.0031951400451362133,
              0.10098700225353241,
              -0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              -0.09260009974241257,
              0.0032236899714916945,
              0.10008999705314636,
              -0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              -0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              -0.09260009974241257,
              0.0032236899714916945,
              0.10008999705314636,
              -0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              -0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              -0.09596119821071625,
              0.0032937501091510057,
              0.09789139777421951,
              -0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              -0.09749279916286469,
              0.003334699897095561,
              0.0966046005487442,
              -0.09596119821071625,
              0.0032937501091510057,
              0.09789139777421951,
              -0.09749279916286469,
              0.003334699897095561,
              0.0966046005487442,
              -0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              -0.09891609847545624,
              0.00337934005074203,
              0.09520330280065536,
              -0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              -0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              -0.10141000151634216,
              0.003478559898212552,
              0.09208820015192032,
              -0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              -0.10246799886226654,
              0.003532649949193001,
              0.09039010107517242,
              -0.10141000151634216,
              0.003478559898212552,
              0.09208820015192032,
              -0.10246799886226654,
              0.003532649949193001,
              0.09039010107517242,
              -0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              -0.10339000076055527,
              0.003589330008253455,
              0.08860869705677032,
              -0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              -0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              -0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              -0.10022400319576263,
              0.003427359974011779,
              0.09369520097970963,
              -0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              -0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              -0.10480000078678131,
              0.0037097299937158823,
              0.0848274976015091,
              -0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              -0.10527600347995758,
              0.003772940021008253,
              0.08284319937229156,
              -0.10480000078678131,
              0.0037097299937158823,
              0.0848274976015091,
              -0.10527600347995758,
              0.003772940021008253,
              0.08284319937229156,
              -0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              -0.10558900237083435,
              0.0038378199096769094,
              0.08080659806728363,
              -0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0,
              0.004108880180865526,
              0.0722958967089653,
              -0.10570000112056732,
              0.003971550147980452,
              0.07660719752311707,
              0,
              0.004108880180865526,
              0.0722958967089653,
              -0.10548499971628189,
              0.004040270112454891,
              0.07444970309734344,
              -0.10570000112056732,
              0.003971550147980452,
              0.07660719752311707,
              -0.10548499971628189,
              0.004040270112454891,
              0.07444970309734344,
              0,
              0.004108880180865526,
              0.0722958967089653,
              -0.10517700016498566,
              0.0041088201105594635,
              0.0722958967089653,
              -0.10417000204324722,
              0.003648489946499467,
              0.08675190061330795,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              -0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0,
              0.004108880180865526,
              0.0722958967089653,
              -0.10573200136423111,
              0.0039041000418365,
              0.07872539758682251,
              0,
              0.004108880180865526,
              0.0722958967089653,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              0,
              0.003126859897747636,
              0.10312999784946442,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              -0.07841949909925461,
              0.003126889932900667,
              0.10312999784946442,
              0,
              0.003126859897747636,
              0.10312999784946442,
              -0.07841949909925461,
              0.003126889932900667,
              0.10312999784946442,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              -0.08060730248689651,
              0.0031269199680536985,
              0.10312999784946442,
              -0.0827736034989357,
              0.0031298398971557617,
              0.10303899645805359,
              -0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              -0.08487509936094284,
              0.0031383601017296314,
              0.10277099907398224,
              -0.09432800114154816,
              0.0032566499430686235,
              0.09905590116977692,
              -0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              -0.08487509936094284,
              0.0031383601017296314,
              0.10277099907398224,
              -0.08888690173625946,
              0.003171290038153529,
              0.10173700004816055,
              -0.08691500127315521,
              0.003152220044285059,
              0.10233499854803085,
              -0.09078410267829895,
              0.0031951400451362133,
              0.10098700225353241,
              -0.04862150177359581,
              0.28636300563812256,
              -0.0008914790232665837,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              -0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              0,
              0.2865920066833496,
              0.000297877995762974,
              -0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              -0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              -0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              -0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              -0.04997529834508896,
              0.27504101395606995,
              -0.0166471004486084,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              -0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.282056987285614,
              -0.010528000071644783,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.282056987285614,
              -0.010528000071644783,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              0,
              0.283378005027771,
              -0.00859792996197939,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              0,
              0.283378005027771,
              -0.00859792996197939,
              -0.04973730072379112,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04985420033335686,
              0.2760530114173889,
              -0.01604050025343895,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              -0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              -0.048843998461961746,
              0.2845019996166229,
              -0.006535459775477648,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              -0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              -0.04862150177359581,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              -0.048652298748493195,
              0.2861050069332123,
              -0.0020690900273621082,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              -0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              -0.0495171993970871,
              0.27887099981307983,
              -0.013935600407421589,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              -0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04941429942846298,
              0.27973198890686035,
              -0.013144600205123425,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              -0.04878620058298111,
              0.2849850058555603,
              -0.005458950065076351,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              -0.048690199851989746,
              0.28578901290893555,
              -0.003224019892513752,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              -0.04873479902744293,
              0.28541499376296997,
              -0.004354559816420078,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              -0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              -0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              0,
              0.271806001663208,
              -0.018157100304961205,
              -0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              0,
              0.271806001663208,
              -0.018157100304961205,
              -0.050100501626729965,
              0.2739950120449066,
              -0.017203200608491898,
              -0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              -0.05022960156202316,
              0.2729159891605377,
              -0.0177071001380682,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              -0.04905429854989052,
              0.2827419936656952,
              -0.009580370038747787,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              -0.0491361990571022,
              0.282056987285614,
              -0.010528000071644783,
              0,
              0.282056987285614,
              -0.010528000071644783,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              -0.04890809953212738,
              0.2839660048484802,
              -0.007582370191812515,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              -0.04897819831967354,
              0.283378005027771,
              -0.008597910404205322,
              0,
              0.283378005027771,
              -0.00859792996197939,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              -0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              -0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              -0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              -0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              -0.047394201159477234,
              0.2966090142726898,
              0.009837949648499489,
              0,
              0.2966090142726898,
              0.00983795989304781,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              0,
              0.2966090142726898,
              0.00983795989304781,
              0,
              0.2947410047054291,
              0.00008496319787809625,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              -0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              -0.04972120001912117,
              0.27716198563575745,
              -0.024358099326491356,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              -0.04862609878182411,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              -0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              -0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              -0.047966498881578445,
              0.2918280065059662,
              -0.009451580233871937,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              -0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              -0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              -0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              0,
              0.294064998626709,
              -0.003213060088455677,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              -0.05027740076184273,
              0.2725149989128113,
              -0.02624410018324852,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              -0.05008690059185028,
              0.2741059958934784,
              -0.025692300871014595,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              -0.04954630136489868,
              0.27862200140953064,
              -0.023580599576234818,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              -0.04788580164313316,
              0.2925019860267639,
              -0.007947860285639763,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              -0.04781410098075867,
              0.2931019961833954,
              -0.006405230145901442,
              -0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              -0.04775179922580719,
              0.2936230003833771,
              -0.0048261601477861404,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              -0.04769900068640709,
              0.294064998626709,
              -0.0032130300533026457,
              -0.047655899077653885,
              0.29442399740219116,
              -0.0015682900557294488,
              0,
              0.294064998626709,
              -0.003213060088455677,
              -0.047655899077653885,
              0.29442399740219116,
              -0.0015682900557294488,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              0,
              0.294064998626709,
              -0.003213060088455677,
              -0.0493771992623806,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              -0.04905709996819496,
              0.282710999250412,
              -0.02083520032465458,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              0,
              0.282710999250412,
              -0.02083520032465458,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              0,
              0.282710999250412,
              -0.02083520032465458,
              -0.0489065982401371,
              0.2839690148830414,
              -0.01979050040245056,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              -0.04876279830932617,
              0.2851699888706207,
              -0.018685199320316315,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              -0.04837450012564659,
              0.2884159982204437,
              -0.015029099769890308,
              -0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04826020076870918,
              0.2893719971179962,
              -0.013705199584364891,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              -0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              0,
              0.29025998711586,
              -0.012332700192928314,
              -0.04805599898099899,
              0.2910799980163574,
              -0.010913999751210213,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              0,
              0.29025998711586,
              -0.012332700192928314,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              -0.05047040060162544,
              0.27090299129486084,
              -0.026756800711154938,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              -0.05047040060162544,
              0.27090299129486084,
              -0.026756800711154938,
              0,
              0.200997993350029,
              -0.04898789897561073,
              -0.05047040060162544,
              0.27090299129486084,
              -0.026756800711154938,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              0,
              0.200997993350029,
              -0.04898789897561073,
              -0.050682298839092255,
              0.27250099182128906,
              -0.0261841993778944,
              -0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              -0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              -0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              -0.050855301320552826,
              0.2695069909095764,
              -0.0189191997051239,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              -0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              -0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              -0.050682298839092255,
              0.27250099182128906,
              -0.0261841993778944,
              -0.050494998693466187,
              0.2725149989128113,
              -0.02624410018324852,
              -0.05095690116286278,
              0.27072399854660034,
              -0.01879110001027584,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              -0.05090019851922989,
              0.2706809937953949,
              -0.018611300736665726,
              -0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              -0.05094530060887337,
              0.2708210051059723,
              -0.02629769966006279,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              -0.05094530060887337,
              0.2708210051059723,
              -0.02629769966006279,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.0509507991373539,
              0.2707749903202057,
              -0.019001999869942665,
              -0.05074929818511009,
              0.2724579870700836,
              -0.026004400104284286,
              -0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              -0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              -0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              -0.05094530060887337,
              0.2708210051059723,
              -0.02629769966006279,
              -0.05075540021061897,
              0.27240699529647827,
              -0.025793500244617462,
              -0.050716400146484375,
              0.2706669867038727,
              -0.018551399931311607,
              -0.050855301320552826,
              0.2695069909095764,
              -0.0189191997051239,
              -0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              -0.050855301320552826,
              0.2695069909095764,
              -0.0189191997051239,
              -0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              -0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              -0.05049879848957062,
              0.2706669867038727,
              -0.018551399931311607,
              -0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              -0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              -0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              -0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              -0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              -0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              -0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              -0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              -0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              -0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.04898739978671074,
              0.286653995513916,
              0.00029629399068653584,
              -0.049014899879693985,
              0.28642499446868896,
              -0.0008966209716163576,
              -0.048837799578905106,
              0.28636300563812256,
              -0.0008914790232665837,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              -0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              -0.048026200383901596,
              0.2946789860725403,
              0.000086618798377458,
              -0.04806400090456009,
              0.29436299204826355,
              -0.001563139958307147,
              -0.048147499561309814,
              0.2941789925098419,
              -0.0015477200504392385,
              -0.04813599959015846,
              0.29427599906921387,
              0.00009717050124891102,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              -0.04791039973497391,
              0.2961589992046356,
              0.00992604997009039,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              -0.04874910041689873,
              0.2891570031642914,
              0.0112252002581954,
              -0.04791039973497391,
              0.2961589992046356,
              0.00992604997009039,
              -0.04902670159935951,
              0.28683900833129883,
              0.00029144500149413943,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              -0.04900059849023819,
              0.2870570123195648,
              0.0002857480139937252,
              -0.04902850091457367,
              0.28682398796081543,
              -0.0009301499812863767,
              -0.04905429854989052,
              0.2866080105304718,
              -0.0009120460017584264,
              -0.047834400087594986,
              0.2947410047054291,
              0.00008499770046910271,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              -0.047655899077653885,
              0.29442399740219116,
              -0.0015682900557294488,
              -0.04787230119109154,
              0.29442399740219116,
              -0.0015682900557294488,
              -0.048557400703430176,
              0.2887049913406372,
              0.011305900290608406,
              -0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              -0.04834109917283058,
              0.2887040078639984,
              0.011304900050163269,
              -0.04881050065159798,
              0.2865920066833496,
              0.00029791099950671196,
              -0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              -0.04834109917283058,
              0.2887040078639984,
              0.011304900050163269,
              -0.04788510128855705,
              0.2963699996471405,
              0.00988634955137968,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              -0.04791039973497391,
              0.2961589992046356,
              0.00992604997009039,
              -0.04810990020632744,
              0.2944929897785187,
              0.00009147210221271962,
              -0.04813599959015846,
              0.29427599906921387,
              0.00009717050124891102,
              -0.04791039973497391,
              0.2961589992046356,
              0.00992604997009039,
              -0.04834109917283058,
              0.2887040078639984,
              0.011304900050163269,
              -0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              0,
              0.2887040078639984,
              0.011304699815809727,
              -0.048594098538160324,
              0.2865920066833496,
              0.00029791099950671196,
              0,
              0.2865920066833496,
              0.000297877995762974,
              0,
              0.2887040078639984,
              0.011304699815809727,
              -0.047655899077653885,
              0.29442399740219116,
              -0.0015682900557294488,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              -0.04761800169944763,
              0.2947410047054291,
              0.00008499980322085321,
              0,
              0.2947410047054291,
              0.00008496319787809625,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              -0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              -0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              -0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              -0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              -0.059248898178339005,
              0.20097999274730682,
              -0.04892919957637787,
              -0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              -0.050855301320552826,
              0.2695069909095764,
              -0.0189191997051239,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              -0.059531401842832565,
              0.19862000644207,
              -0.041472699493169785,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              -0.05068809911608696,
              0.27090299129486084,
              -0.026756800711154938,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.059248898178339005,
              0.20097999274730682,
              -0.04892919957637787,
              -0.05087500065565109,
              0.2708919942378998,
              -0.026695700362324715,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              0,
              0.19860200583934784,
              -0.04141410067677498,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              -0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              -0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              -0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              -0.05109750106930733,
              0.26955100893974304,
              -0.019163399934768677,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              -0.059531401842832565,
              0.19862000644207,
              -0.041472699493169785,
              -0.051039498299360275,
              0.2695179879665375,
              -0.018980300053954124,
              -0.05094530060887337,
              0.2708210051059723,
              -0.02629769966006279,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              -0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              -0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              -0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              -0.05094530060887337,
              0.2708210051059723,
              -0.02629769966006279,
              -0.05094069987535477,
              0.2708599865436554,
              -0.026512600481510162,
              -0.050855301320552826,
              0.2695069909095764,
              -0.0189191997051239,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              -0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              -0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              -0.05063759908080101,
              0.2695069909095764,
              -0.0189191997051239,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              0.0853922963142395,
              0.0011095700319856405,
              -0.06915400177240372,
              0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              0.07833880186080933,
              0.04159890115261078,
              -0.09128790348768234,
              0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              0.0853922963142395,
              0.0011095700319856405,
              -0.06915400177240372,
              0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              0.08564220368862152,
              0.0010479099582880735,
              -0.06609880179166794,
              0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              0.082082100212574,
              0.010943399742245674,
              -0.09206970036029816,
              0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              0.08177179843187332,
              0.013018500059843063,
              -0.09387840330600739,
              0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              0.08150479942560196,
              0.015239300206303596,
              -0.09550239890813828,
              0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              0.07894030213356018,
              0.036587998270988464,
              -0.1006309986114502,
              0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              0.07859130203723907,
              0.03949660062789917,
              -0.10013700276613235,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              0.07891929894685745,
              0.03735119849443436,
              -0.09253010153770447,
              0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              0.08100300282239914,
              0.024936499074101448,
              -0.0918923020362854,
              0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              0.08354009687900543,
              0.012313200160861015,
              -0.08213099837303162,
              0.08326739817857742,
              0.013494400307536125,
              -0.08380140364170074,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              0.07824160158634186,
              0.042412400245666504,
              -0.0993565022945404,
              0.08525759726762772,
              0.008581729605793953,
              -0.07009810209274292,
              0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              0.0853922963142395,
              0.0011095700319856405,
              -0.06915400177240372,
              0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              0.0853922963142395,
              0.0011095700319856405,
              -0.06915400177240372,
              0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              0.08496200293302536,
              0.0012499400181695819,
              -0.07216870039701462,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              0.08454509824514389,
              0.0016607600264251232,
              -0.07508990168571472,
              0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              0.08414290100336075,
              0.0023282200563699007,
              -0.0779075026512146,
              0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              0.08375699818134308,
              0.003238230012357235,
              -0.08061130344867706,
              0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              0.0833887979388237,
              0.004377100151032209,
              -0.08319129794836044,
              0.0830397978425026,
              0.005730839911848307,
              -0.08563719689846039,
              0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              0.08271100372076035,
              0.007285770028829575,
              -0.08793900161981583,
              0.08240389823913574,
              0.00902790017426014,
              -0.09008660167455673,
              0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              0.08122190088033676,
              0.017591800540685654,
              -0.09693159908056259,
              0.08092489838600159,
              0.02006240002810955,
              -0.09815599769353867,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              0.08061549812555313,
              0.022637100890278816,
              -0.09916520118713379,
              0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              0.08029530197381973,
              0.02530210092663765,
              -0.09994930028915405,
              0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              0.07996609807014465,
              0.028043599799275398,
              -0.10049799829721451,
              0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.07962939888238907,
              0.030847599729895592,
              -0.1008009985089302,
              0.07928690314292908,
              0.03370029851794243,
              -0.10084900259971619,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              0.0846370980143547,
              0.009119990281760693,
              -0.0744452029466629,
              0.08494210243225098,
              0.008754489943385124,
              -0.07230780273675919,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              0.08434340357780457,
              0.009668559767305851,
              -0.0765032023191452,
              0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              0.08379390090703964,
              0.011275099590420723,
              -0.08035320043563843,
              0.08406200259923935,
              0.010390300303697586,
              -0.07847490161657333,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              0.0792561024427414,
              0.03521449863910675,
              -0.0928976982831955,
              0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              0.07959979772567749,
              0.033091600984334946,
              -0.09306640177965164,
              0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              0.0799482986330986,
              0.030992399901151657,
              -0.09304320067167282,
              0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              0.08029960095882416,
              0.028926799073815346,
              -0.09283509850502014,
              0.0806519016623497,
              0.02690489962697029,
              -0.09244909882545471,
              0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              0.08135110139846802,
              0.023031599819660187,
              -0.09117159992456436,
              0.08169420063495636,
              0.02120020054280758,
              -0.09029410034418106,
              0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              0.0820302963256836,
              0.019452299922704697,
              -0.08926690369844437,
              0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              0.08235739916563034,
              0.01779779978096485,
              -0.0880969986319542,
              0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              0.08267410099506378,
              0.016246700659394264,
              -0.08679140359163284,
              0.08297809958457947,
              0.014808899722993374,
              -0.0853571966290474,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              0.07833880186080933,
              0.04159890115261078,
              -0.09128790348768234,
              0.07859119772911072,
              0.039491601288318634,
              -0.09195650368928909,
              0.0853922963142395,
              0.0011095700319856405,
              -0.06915400177240372,
              0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              0.08130870014429092,
              0.017782999202609062,
              -0.09657999873161316,
              0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              0.08130870014429092,
              0.017782999202609062,
              -0.09657999873161316,
              0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              0.07863560318946838,
              0.03963930159807205,
              -0.09233059734106064,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              0.07863560318946838,
              0.03963930159807205,
              -0.09233059734106064,
              0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              0.07863560318946838,
              0.03963930159807205,
              -0.09233059734106064,
              0.10563500225543976,
              0.0015097899595275521,
              0.0722305029630661,
              0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              0.08565259724855423,
              0.00810500979423523,
              -0.06776569783687592,
              0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              0.0821755975484848,
              0.01122680027037859,
              -0.09178770333528519,
              0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              0.08215860277414322,
              0.011073900386691093,
              -0.0919400006532669,
              0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              0.08186479657888412,
              0.013273200020194054,
              -0.0935702994465828,
              0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              0.0818478986620903,
              0.013135699555277824,
              -0.09373670071363449,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              0.08159449696540833,
              0.015463200397789478,
              -0.0951709970831871,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              0.08130870014429092,
              0.017782999202609062,
              -0.09657999873161316,
              0.08157940208911896,
              0.015342299826443195,
              -0.09534990042448044,
              0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              0.08130870014429092,
              0.017782999202609062,
              -0.09657999873161316,
              0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              0.07901839911937714,
              0.03651779890060425,
              -0.10023599863052368,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              0.07900969684123993,
              0.03655559942126274,
              -0.10044900327920914,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              0.07866989821195602,
              0.039387598633766174,
              -0.09975030273199081,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              0.07866089791059494,
              0.0394463986158371,
              -0.09995889663696289,
              0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              0.07863560318946838,
              0.03963930159807205,
              -0.09233059734106064,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              0.07896479964256287,
              0.037460099905729294,
              -0.09291650354862213,
              0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              0.07897379994392395,
              0.03740129992365837,
              -0.09270790219306946,
              0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              0.081045001745224,
              0.024815699085593224,
              -0.09227419644594193,
              0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              0.08105599880218506,
              0.024880899116396904,
              -0.09206800162792206,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              0.08329950273036957,
              0.013160999864339828,
              -0.08402349799871445,
              0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              0.08331560343503952,
              0.01334100030362606,
              -0.08390360325574875,
              0.08358970284461975,
              0.012150200083851814,
              -0.08221779763698578,
              0.08357519656419754,
              0.011959100142121315,
              -0.08231960237026215,
              0.07831159979104996,
              0.04234449937939644,
              -0.09918440133333206,
              0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              0.07832109928131104,
              0.042264699935913086,
              -0.09898240119218826,
              0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              0.08565259724855423,
              0.00810500979423523,
              -0.06776569783687592,
              0.08532299846410751,
              0.008395790122449398,
              -0.07007460296154022,
              0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              0.08565259724855423,
              0.00810500979423523,
              -0.06776569783687592,
              0.08455760031938553,
              0.0011880099773406982,
              -0.07216759771108627,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08455760031938553,
              0.0011880099773406982,
              -0.07216759771108627,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08477599918842316,
              0.0011880099773406982,
              -0.07216759771108627,
              0.08455760031938553,
              0.0011880099773406982,
              -0.07216759771108627,
              0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              0.08435820043087006,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              0.08395519852638245,
              0.002267630072310567,
              -0.07791910320520401,
              0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              0.08356860280036926,
              0.003179189981892705,
              -0.08062899857759476,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              0.08319970220327377,
              0.004320169799029827,
              -0.08321470022201538,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              0.08010689914226532,
              0.025289300829172134,
              -0.1000090017914772,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              0.0797777995467186,
              0.02803649939596653,
              -0.10055900365114212,
              0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.07944119721651077,
              0.030846400186419487,
              -0.1008630022406578,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              0.08387859910726547,
              0.010449400171637535,
              -0.07845719903707504,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              0.07941550016403198,
              0.03308679908514023,
              -0.09300509840250015,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              0.07976389676332474,
              0.030993599444627762,
              -0.09298170357942581,
              0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              0.08011530339717865,
              0.028933899477124214,
              -0.09277399629354477,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              0.08217550069093704,
              0.017836900427937508,
              -0.08804970234632492,
              0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              0.0824924036860466,
              0.016290200874209404,
              -0.08674810081720352,
              0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              0.07818979769945145,
              0.039468999952077866,
              -0.0918990969657898,
              0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              0.08520720154047012,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08564220368862152,
              0.0010479099582880735,
              -0.06609880179166794,
              0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08564220368862152,
              0.0010479099582880735,
              -0.06609880179166794,
              0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              0.08252079784870148,
              0.0072346897795796394,
              -0.08797310292720795,
              0.0828498974442482,
              0.0056766001507639885,
              -0.08566609770059586,
              0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              0.08221360296010971,
              0.008980420418083668,
              -0.09012550115585327,
              0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              0.08189170062541962,
              0.010900000110268593,
              -0.09211300313472748,
              0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              0.08158160001039505,
              0.012979400344192982,
              -0.09392569959163666,
              0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              0.08103270083665848,
              0.0175624992698431,
              -0.09698549658060074,
              0.08131519705057144,
              0.015204899944365025,
              -0.0955531969666481,
              0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              0.0804269015789032,
              0.022618599236011505,
              -0.0992238000035286,
              0.08073599636554718,
              0.02003839984536171,
              -0.09821250289678574,
              0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              0.07909879833459854,
              0.033705100417137146,
              -0.1009100005030632,
              0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.07875219732522964,
              0.036598701030015945,
              -0.10069099813699722,
              0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              0.07783559709787369,
              0.04243509843945503,
              -0.0994139015674591,
              0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              0.07840319722890854,
              0.039513301104307175,
              -0.1001959964632988,
              0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              0.0847558006644249,
              0.00881659984588623,
              -0.07230889797210693,
              0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              0.08445180207490921,
              0.009181739762425423,
              -0.07443980127573013,
              0.0841590017080307,
              0.00972933042794466,
              -0.07649160176515579,
              0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              0.08361150324344635,
              0.011332100257277489,
              -0.08032970130443573,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              0.07818979769945145,
              0.039468999952077866,
              -0.0918990969657898,
              0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              0.07840800285339355,
              0.039468999952077866,
              -0.0918990969657898,
              0.07818979769945145,
              0.039468999952077866,
              -0.0918990969657898,
              0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              0.0790719985961914,
              0.03520379960536957,
              -0.09283710271120071,
              0.07873540371656418,
              0.03733440116047859,
              -0.09247080236673355,
              0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              0.08046770095825195,
              0.026917699724435806,
              -0.09238900244235992,
              0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              0.08116760104894638,
              0.023055600002408028,
              -0.09111510217189789,
              0.08081910014152527,
              0.02495500072836876,
              -0.0918337032198906,
              0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              0.08184769749641418,
              0.019486600533127785,
              -0.08921609818935394,
              0.08151110261678696,
              0.021229499951004982,
              -0.09024020284414291,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              0.0827966034412384,
              0.014856399968266487,
              -0.08531829714775085,
              0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              0.08335839956998825,
              0.012367400340735912,
              -0.08210209757089615,
              0.08308590203523636,
              0.013545500114560127,
              -0.08376730233430862,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              0.05047040060162544,
              0.27090299129486084,
              -0.026756899431347847,
              0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              0.10539200156927109,
              0.004109859932214022,
              0.07226529717445374,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              0.08517000079154968,
              0.008572730235755444,
              -0.06787619739770889,
              0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              0.08502300083637238,
              0.0016534000169485807,
              -0.07217620313167572,
              0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              0.08502359688282013,
              0.001435579964891076,
              -0.07217220216989517,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              0.08461210131645203,
              0.0020622299052774906,
              -0.07505519688129425,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              0.08460939675569534,
              0.0018454799428582191,
              -0.07507389783859253,
              0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              0.08421570062637329,
              0.00272343005053699,
              -0.07783190160989761,
              0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              0.08420989662408829,
              0.0025100400671362877,
              -0.07787270098924637,
              0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              0.08383540064096451,
              0.003623219905421138,
              -0.08049629628658295,
              0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              0.08382660150527954,
              0.003415350103750825,
              -0.0805583968758583,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              0.0834726020693779,
              0.004748169798403978,
              -0.08303859829902649,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              0.08346080034971237,
              0.004547799937427044,
              -0.08312100172042847,
              0.08311399817466736,
              0.005893560126423836,
              -0.08555059880018234,
              0.08312860131263733,
              0.0060844398103654385,
              -0.08544889837503433,
              0.08280269801616669,
              0.007618810050189495,
              -0.08771710097789764,
              0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              0.08278650045394897,
              0.007439020089805126,
              -0.08783689886331558,
              0.08247990161180496,
              0.009170349687337875,
              -0.08997009694576263,
              0.08249650150537491,
              0.009337509982287884,
              -0.08983329683542252,
              0.08130870014429092,
              0.017782999202609062,
              -0.09657999873161316,
              0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              0.0812951996922493,
              0.01767979934811592,
              -0.0967698022723198,
              0.08099710196256638,
              0.020134499296545982,
              -0.09798640012741089,
              0.08100920170545578,
              0.020219100639224052,
              -0.09778740257024765,
              0.08069770038127899,
              0.02275790087878704,
              -0.09878320246934891,
              0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              0.08068670332431793,
              0.02269270084798336,
              -0.09898939728736877,
              0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              0.08037590235471725,
              0.0253858994692564,
              -0.0995573028922081,
              0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              0.08036579936742783,
              0.025340700522065163,
              -0.09976889938116074,
              0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              0.08004529774188995,
              0.028089499101042747,
              -0.10010000318288803,
              0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              0.08003590255975723,
              0.028064699843525887,
              -0.10031499713659286,
              0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              0.0797078013420105,
              0.030855100601911545,
              -0.10040000081062317,
              0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              0.07969880104064941,
              0.030851000919938087,
              -0.10061699897050858,
              0.07935620099306107,
              0.03368590027093887,
              -0.10066500306129456,
              0.07936490327119827,
              0.03366909921169281,
              -0.10044900327920914,
              0.08469419926404953,
              0.008717330172657967,
              -0.07447999715805054,
              0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              0.08469689637422562,
              0.008934680372476578,
              -0.0744611993432045,
              0.08500470221042633,
              0.008568139746785164,
              -0.07230430096387863,
              0.08500529825687408,
              0.008349509909749031,
              -0.07230030000209808,
              0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              0.08440040051937103,
              0.009486259892582893,
              -0.0765381008386612,
              0.08439459651708603,
              0.009272339753806591,
              -0.07657899707555771,
              0.08383399993181229,
              0.010903400368988514,
              -0.0805061012506485,
              0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              0.08384580165147781,
              0.01110410038381815,
              -0.08042360097169876,
              0.08411639928817749,
              0.010212699882686138,
              -0.07852789759635925,
              0.08410750329494476,
              0.010004499927163124,
              -0.07859010249376297,
              0.07930219918489456,
              0.035284701734781265,
              -0.09329289942979813,
              0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              0.07931090146303177,
              0.035246800631284714,
              -0.0930795967578888,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              0.07964599877595901,
              0.03312290087342262,
              -0.09346640110015869,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              0.07965470105409622,
              0.0331059992313385,
              -0.09325049817562103,
              0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              0.0799940973520279,
              0.030984900891780853,
              -0.09344419836997986,
              0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              0.08000300079584122,
              0.03098900057375431,
              -0.09322769939899445,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              0.08034460246562958,
              0.028881000354886055,
              -0.09323339909315109,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              0.0803539976477623,
              0.028905700892210007,
              -0.09301839768886566,
              0.08070559799671173,
              0.026866400614380836,
              -0.09262940287590027,
              0.08069559931755066,
              0.02682109922170639,
              -0.0928409993648529,
              0.08139090240001678,
              0.022874999791383743,
              -0.09154000133275986,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              0.08140309900045395,
              0.022959500551223755,
              -0.09134110063314438,
              0.08174490183591843,
              0.021112199872732162,
              -0.09045589715242386,
              0.0817314013838768,
              0.02100900001823902,
              -0.0906457006931305,
              0.08206459879875183,
              0.01922830007970333,
              -0.0895984023809433,
              0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              0.0820797011256218,
              0.019349200651049614,
              -0.0894194021821022,
              0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              0.08238840103149414,
              0.017542999237775803,
              -0.08840510249137878,
              0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              0.0824052020907402,
              0.01768060028553009,
              -0.08823879808187485,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              0.08270460367202759,
              0.015963200479745865,
              -0.08707349747419357,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              0.08272170275449753,
              0.016116200014948845,
              -0.08692120015621185,
              0.08302610367536545,
              0.014666300266981125,
              -0.08547379821538925,
              0.0830094963312149,
              0.014499099925160408,
              -0.08561059832572937,
              0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.10563500225543976,
              0.0015097899595275521,
              0.0722305029630661,
              0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              0.08517000079154968,
              0.008572730235755444,
              -0.06787619739770889,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              0,
              0.001047880039550364,
              -0.06609869748353958,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              0,
              0.010449400171637535,
              -0.07845719903707504,
              0.08366040140390396,
              0.010449400171637535,
              -0.07845719903707504,
              0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              0.07919629663228989,
              0.03308679908514023,
              -0.09300509840250015,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              0,
              0.03308679908514023,
              -0.09300500154495239,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              0.07954459637403488,
              0.030993599444627762,
              -0.09298170357942581,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              0,
              0.030993500724434853,
              -0.09298159927129745,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              0.07989589869976044,
              0.028933899477124214,
              -0.09277399629354477,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              0,
              0.028933899477124214,
              -0.09277389943599701,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              0.08195649832487106,
              0.017836900427937508,
              -0.08804970234632492,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              0,
              0.01783679984509945,
              -0.08804959803819656,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              0.08227360248565674,
              0.016290200874209404,
              -0.08674810081720352,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              0,
              0.016290100291371346,
              -0.08674799650907516,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              0.08453740179538727,
              0.00881659984588623,
              -0.07230889797210693,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              0,
              0.008816570043563843,
              -0.07230889797210693,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              0.08394069969654083,
              0.00972933042794466,
              -0.07649160176515579,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              0,
              0.00972933042794466,
              -0.07649149745702744,
              0,
              0.009181709960103035,
              -0.07443969696760178,
              0.08423340320587158,
              0.009181739762425423,
              -0.07443980127573013,
              0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              0,
              0.01133199967443943,
              -0.08032959699630737,
              0.08339349925518036,
              0.011332100257277489,
              -0.08032970130443573,
              0.07818979769945145,
              0.039468999952077866,
              -0.0918990969657898,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              0.07851660251617432,
              0.03733440116047859,
              -0.09247080236673355,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              0,
              0.03733440116047859,
              -0.0924706980586052,
              0,
              0.03520369902253151,
              -0.09283710271120071,
              0.07885300368070602,
              0.03520379960536957,
              -0.09283710271120071,
              0.08024819940328598,
              0.026917699724435806,
              -0.09238900244235992,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              0,
              0.026917699724435806,
              -0.09238889813423157,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              0.0805995985865593,
              0.02495500072836876,
              -0.0918337032198906,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              0,
              0.0249549001455307,
              -0.09183359891176224,
              0,
              0.023055600002408028,
              -0.09111499786376953,
              0.08094819635152817,
              0.023055600002408028,
              -0.09111510217189789,
              0.0812918022274971,
              0.021229499951004982,
              -0.09024020284414291,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              0,
              0.021229499951004982,
              -0.09024009853601456,
              0,
              0.019486600533127785,
              -0.08921600133180618,
              0.08162859827280045,
              0.019486600533127785,
              -0.08921609818935394,
              0.08257800340652466,
              0.014856399968266487,
              -0.08531829714775085,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              0,
              0.014856300316751003,
              -0.0853182002902031,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              0.08286750316619873,
              0.013545500114560127,
              -0.08376730233430862,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              0,
              0.013545500114560127,
              -0.08376719802618027,
              0,
              0.012367400340735912,
              -0.08210200071334839,
              0.0831402987241745,
              0.012367400340735912,
              -0.08210209757089615,
              0.07818979769945145,
              0.039468999952077866,
              -0.0918990969657898,
              0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              0,
              0.04157029837369919,
              -0.0912322998046875,
              0,
              0.03946889936923981,
              -0.09189900010824203,
              0.08455760031938553,
              0.0011880099773406982,
              -0.07216759771108627,
              0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08498869836330414,
              0.0010479099582880735,
              -0.0691462978720665,
              0,
              0.0010478500043973327,
              -0.06914620101451874,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08455760031938553,
              0.0011880099773406982,
              -0.07216759771108627,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              0,
              0.0011880099773406982,
              -0.07216750085353851,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08413980156183243,
              0.0015992199769243598,
              -0.07509519904851913,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              0,
              0.0015992199769243598,
              -0.07509519904851913,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              0.08373689651489258,
              0.002267630072310567,
              -0.07791910320520401,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              0,
              0.002267570002004504,
              -0.07791899889707565,
              0,
              0.003179189981892705,
              -0.080628901720047,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              0.08335039764642715,
              0.003179189981892705,
              -0.08062899857759476,
              0,
              0.003179189981892705,
              -0.080628901720047,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              0,
              0.003179189981892705,
              -0.080628901720047,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              0.08298160135746002,
              0.004320169799029827,
              -0.08321470022201538,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              0,
              0.004320169799029827,
              -0.08321460336446762,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0.0798892006278038,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.025289300829172134,
              -0.1000090017914772,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              0.07955999672412872,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.02803649939596653,
              -0.10055900365114212,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0.0792234018445015,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.030846400186419487,
              -0.1008630022406578,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              0.08263210207223892,
              0.0056766001507639885,
              -0.08566609770059586,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              0,
              0.0056766001507639885,
              -0.0856660008430481,
              0,
              0.007234630174934864,
              -0.08797299861907959,
              0.08230309933423996,
              0.0072346897795796394,
              -0.08797310292720795,
              0.0819958969950676,
              0.008980420418083668,
              -0.09012550115585327,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              0,
              0.008980389684438705,
              -0.09012539684772491,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              0.0816740021109581,
              0.010900000110268593,
              -0.09211300313472748,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              0,
              0.010900000110268593,
              -0.09211289882659912,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              0.08136419951915741,
              0.012979400344192982,
              -0.09392569959163666,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              0,
              0.012979400344192982,
              -0.0939256027340889,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              0.08109790086746216,
              0.015204899944365025,
              -0.0955531969666481,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              0,
              0.015204899944365025,
              -0.0955531969666481,
              0,
              0.0175624992698431,
              -0.09698549658060074,
              0.08081520348787308,
              0.0175624992698431,
              -0.09698549658060074,
              0.08051840215921402,
              0.02003839984536171,
              -0.09821250289678574,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              0,
              0.02003829926252365,
              -0.09821239858865738,
              0,
              0.022618599236011505,
              -0.09922370314598083,
              0.08020920306444168,
              0.022618599236011505,
              -0.0992238000035286,
              0.07888089865446091,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.033705100417137146,
              -0.1009100005030632,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              0.07853440195322037,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.036598701030015945,
              -0.10069099813699722,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              0.07818540185689926,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              0.07783559709787369,
              0.04243509843945503,
              -0.0994139015674591,
              0,
              0.039513301104307175,
              -0.1001959964632988,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              0.07783559709787369,
              0.04243509843945503,
              -0.0994139015674591,
              0.05047040060162544,
              0.27090299129486084,
              -0.026756899431347847,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              0.08564220368862152,
              0.0010479099582880735,
              -0.06609880179166794,
              0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              0.10539200156927109,
              0.004109859932214022,
              0.07226529717445374,
              0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              0.08557680249214172,
              0.0085105299949646,
              -0.06786149740219116,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              0.08582630008459091,
              0.0011095399968326092,
              -0.06611339747905731,
              0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              0.0853269025683403,
              0.008177610114216805,
              -0.07004710286855698,
              0.08565259724855423,
              0.00810500979423523,
              -0.06776569783687592,
              0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              0.08565259724855423,
              0.00810500979423523,
              -0.06776569783687592,
              0.08564519882202148,
              0.008323909714818,
              -0.06781739741563797,
              0.08564220368862152,
              0.0010479099582880735,
              -0.06609880179166794,
              0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              0.10517799854278564,
              0.0010476999450474977,
              0.07229579985141754,
              0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              0.08507029712200165,
              0.008643750101327896,
              -0.07010599970817566,
              0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              0.08517000079154968,
              0.008572730235755444,
              -0.06787619739770889,
              0.08538849651813507,
              0.008572700433433056,
              -0.06787619739770889,
              0.08545109629631042,
              0.0012945500202476978,
              -0.06917739659547806,
              0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              0.08544710278511047,
              0.0015115999849513173,
              -0.06920479983091354,
              0.08587480336427689,
              0.001511569949798286,
              -0.06620830297470093,
              0.08588220179080963,
              0.0012945199850946665,
              -0.06615699827671051,
              0.08485180139541626,
              0.008643750101327896,
              -0.07010599970817566,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              0.08517000079154968,
              0.008572730235755444,
              -0.06787619739770889,
              0,
              0.008643720299005508,
              -0.0701059028506279,
              0,
              0.008572700433433056,
              -0.06787619739770889,
              0.08517000079154968,
              0.008572730235755444,
              -0.06787619739770889,
              0.10517799854278564,
              0.0010476999450474977,
              0.07229579985141754,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              0,
              0.001047880039550364,
              -0.06609869748353958,
              0.08542370051145554,
              0.0010479099582880735,
              -0.06609880179166794,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              0.0780533030629158,
              0.04243509843945503,
              -0.0994139015674591,
              0.07783559709787369,
              0.04243509843945503,
              -0.0994139015674591,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              0.07864510267972946,
              0.0395594984292984,
              -0.09212859719991684,
              0.07863560318946838,
              0.03963930159807205,
              -0.09233059734106064,
              0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              0.07783559709787369,
              0.04243509843945503,
              -0.0994139015674591,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              0,
              0.04243500158190727,
              -0.09941379725933075,
              0,
              0.04531560093164444,
              -0.09849770367145538,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              0.050682298839092255,
              0.27250099182128906,
              -0.02618429996073246,
              0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              0.050682298839092255,
              0.27250099182128906,
              -0.02618429996073246,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              0.048734601587057114,
              0.288765013217926,
              0.011296999640762806,
              0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              0.04877439886331558,
              0.2889449894428253,
              0.011264599859714508,
              0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              0.04877439886331558,
              0.2889449894428253,
              0.011264599859714508,
              0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              0.04780210182070732,
              0.2965500056743622,
              0.009850800037384033,
              0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              0.04761059954762459,
              0.2966090142726898,
              0.009837989695370197,
              0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              0.04761059954762459,
              0.2966090142726898,
              0.009837989695370197,
              0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              0.10539200156927109,
              0.004109859932214022,
              0.07226529717445374,
              0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              0.10539200156927109,
              0.004109859932214022,
              0.07226529717445374,
              0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              0,
              0.003052680054679513,
              0.10353100299835205,
              0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              0,
              0.003120010020211339,
              0.10334599763154984,
              0,
              0.003052680054679513,
              0.10353100299835205,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              0.04991459846496582,
              0.2789109945297241,
              -0.013981900177896023,
              0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.04981119930744171,
              0.279774010181427,
              -0.01318840030580759,
              0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              0.0491287000477314,
              0.2854740023612976,
              -0.004370430018752813,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              0.050682298839092255,
              0.27250099182128906,
              -0.02618429996073246,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              0.04822219908237457,
              0.29304200410842896,
              -0.006389500107616186,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              0.05076330155134201,
              0.2718240022659302,
              -0.018216000869870186,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              0.05062999948859215,
              0.27293699979782104,
              -0.017764799296855927,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              0.048557400703430176,
              0.2887049913406372,
              0.011305799707770348,
              0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              0.048734601587057114,
              0.288765013217926,
              0.011296999640762806,
              0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              0.048734601587057114,
              0.288765013217926,
              0.011296999640762806,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              0.04978359863162041,
              0.28000399470329285,
              -0.022679999470710754,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.04931360110640526,
              0.2839280068874359,
              -0.019744399935007095,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              0.08901920169591904,
              0.001109090051613748,
              0.10211600363254547,
              0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              0.103753000497818,
              0.0011091199703514576,
              0.0887783020734787,
              0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              0.10613299906253815,
              0.0011091199703514576,
              0.07873609662055969,
              0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              0.10557399690151215,
              0.001109150005504489,
              0.07223919779062271,
              0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              0.08280769735574722,
              0.00305569008924067,
              0.10343799740076065,
              0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              0.08494269847869873,
              0.0030642999336123466,
              0.10316599905490875,
              0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              0.09279230237007141,
              0.003151089884340763,
              0.10044199973344803,
              0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              0.094547800719738,
              0.003184559987857938,
              0.09939099848270416,
              0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              0.10610099881887436,
              0.003910840023308992,
              0.0765841007232666,
              0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              0.050500400364398956,
              0.2740190029144287,
              -0.017259400337934494,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              0.050374701619148254,
              0.275068998336792,
              -0.016701700165867805,
              0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              0.050022900104522705,
              0.27800700068473816,
              -0.014731699600815773,
              0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              0.04953189939260483,
              0.28210699558258057,
              -0.010563399642705917,
              0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              0.04937319830060005,
              0.2834320068359375,
              -0.008627190254628658,
              0.049449700862169266,
              0.2827939987182617,
              -0.009612780064344406,
              0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              0.049620598554611206,
              0.2813650071620941,
              -0.021765999495983124,
              0.049463901668787,
              0.28267401456832886,
              -0.020786700770258904,
              0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              0.04917009919881821,
              0.28512701392173767,
              -0.018641499802470207,
              0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              0.04788510128855705,
              0.2963699996471405,
              0.009886279702186584,
              0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              0.04780210182070732,
              0.2965500056743622,
              0.009850800037384033,
              0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              0.04780210182070732,
              0.2965500056743622,
              0.009850800037384033,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              0.05013569816946983,
              0.2770650088787079,
              -0.015436099842190742,
              0.05025310069322586,
              0.2760849893093109,
              -0.01609339937567711,
              0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              0.04961960017681122,
              0.2813740074634552,
              -0.011477399617433548,
              0.04971279948949814,
              0.280595988035202,
              -0.012353000231087208,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              0.04930270090699196,
              0.2840209901332855,
              -0.007608409970998764,
              0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              0.049180299043655396,
              0.28504401445388794,
              -0.005478280130773783,
              0.04923830181360245,
              0.2845590114593506,
              -0.0065581900998950005,
              0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              0.04908379912376404,
              0.285849004983902,
              -0.0032363899517804384,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              0.04904580116271973,
              0.2861660122871399,
              -0.00207789009436965,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              0.05049220100045204,
              0.2740879952907562,
              -0.02563359960913658,
              0.05030699819326401,
              0.2756339907646179,
              -0.025005299597978592,
              0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.050126999616622925,
              0.27713701128959656,
              -0.024302000179886818,
              0.04995249956846237,
              0.2785939872264862,
              -0.02352610044181347,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              0.04903339967131615,
              0.2862679958343506,
              -0.017480600625276566,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              0.04890409857034683,
              0.28734898567199707,
              -0.016263900324702263,
              0.04878219962120056,
              0.2883659899234772,
              -0.014993799850344658,
              0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.04866800084710121,
              0.2893199920654297,
              -0.013672900386154652,
              0.04856180027127266,
              0.2902059853076935,
              -0.012303500436246395,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              0.04846389964222908,
              0.29102399945259094,
              -0.010888099670410156,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              0.04837450012564659,
              0.2917709946632385,
              -0.009428990073502064,
              0.04829379916191101,
              0.29244399070739746,
              -0.00792866013944149,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              0.048159800469875336,
              0.29356300830841064,
              -0.0048139397986233234,
              0.048106998205184937,
              0.29400399327278137,
              -0.00320436991751194,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              0.08494269847869873,
              0.001109090051613748,
              0.10316599905490875,
              0.08701550215482712,
              0.0011090600164607167,
              0.10272300243377686,
              0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              0.09776289761066437,
              0.001109090051613748,
              0.09690079838037491,
              0.09920869767665863,
              0.0011091199703514576,
              0.09547729790210724,
              0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              0.1005370020866394,
              0.0011091199703514576,
              0.09394539892673492,
              0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              0.10598699748516083,
              0.001109150005504489,
              0.08085069805383682,
              0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              0.10610099881887436,
              0.0011091199703514576,
              0.0765841007232666,
              0.10588300228118896,
              0.001109150005504489,
              0.07440129667520523,
              0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              0.08061599731445312,
              0.0011090600164607167,
              0.10353100299835205,
              0.08280769735574722,
              0.0011090600164607167,
              0.10343799740076065,
              0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              0.09094709903001785,
              0.001109090051613748,
              0.1013529971241951,
              0.09279230237007141,
              0.001109090051613748,
              0.10044199973344803,
              0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              0.094547800719738,
              0.001109090051613748,
              0.09939099848270416,
              0.09620700031518936,
              0.0011091199703514576,
              0.0982080027461052,
              0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              0.10174199938774109,
              0.0011091199703514576,
              0.0923129990696907,
              0.10281699895858765,
              0.0011091199703514576,
              0.09058800339698792,
              0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              0.10454600304365158,
              0.0011091199703514576,
              0.08689180016517639,
              0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              0.10518699884414673,
              0.0011091199703514576,
              0.08493649959564209,
              0.10566899925470352,
              0.001109150005504489,
              0.08292009681463242,
              0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              0,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841979712247849,
              0.0011090600164607167,
              0.10353100299835205,
              0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              0.08701550215482712,
              0.003078459994867444,
              0.10272300243377686,
              0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              0.09094709903001785,
              0.003122089896351099,
              0.1013529971241951,
              0.08901920169591904,
              0.0030977700371295214,
              0.10211600363254547,
              0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              0.09776289761066437,
              0.0032638299744576216,
              0.09690079838037491,
              0.09620700031518936,
              0.0032222000882029533,
              0.0982080027461052,
              0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              0.1005370020866394,
              0.003357949899509549,
              0.09394539892673492,
              0.09920869767665863,
              0.003309160005301237,
              0.0954774022102356,
              0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              0.10174199938774109,
              0.0034099500626325607,
              0.0923129990696907,
              0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              0.10281699895858765,
              0.0034649099688977003,
              0.09058800339698792,
              0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              0.10454600304365158,
              0.00358260003849864,
              0.08689189702272415,
              0.103753000497818,
              0.0035225499887019396,
              0.0887783020734787,
              0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              0.10566899925470352,
              0.0037090799305588007,
              0.08292020112276077,
              0.10518699884414673,
              0.003644850105047226,
              0.08493649959564209,
              0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              0.10613299906253815,
              0.00384229002520442,
              0.07873609662055969,
              0.10598699748516083,
              0.00377494003623724,
              0.08085069805383682,
              0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              0.10557399690151215,
              0.004049209877848625,
              0.07223919779062271,
              0.10588300228118896,
              0.0039803399704396725,
              0.07440129667520523,
              0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              0.08061599731445312,
              0.0030527100898325443,
              0.10353100299835205,
              0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              0.07841979712247849,
              0.0030527100898325443,
              0.10353100299835205,
              0,
              0.003052680054679513,
              0.10353100299835205,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              0.04902850091457367,
              0.28682398796081543,
              -0.0009302229736931622,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              0.04926519840955734,
              0.28484800457954407,
              -0.018356500193476677,
              0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              0.04926519840955734,
              0.28484800457954407,
              -0.018356500193476677,
              0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              0.04813599959015846,
              0.2942749857902527,
              0.0000971035988186486,
              0.04902850091457367,
              0.28682398796081543,
              -0.0009302229736931622,
              0.04813599959015846,
              0.2942749857902527,
              0.0000971035988186486,
              0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              0.04902850091457367,
              0.28682398796081543,
              -0.0009302229736931622,
              0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              0.10563500225543976,
              0.0015097899595275521,
              0.0722305029630661,
              0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.04996189847588539,
              0.27903199195861816,
              -0.014120499603450298,
              0.04994489997625351,
              0.27917298674583435,
              -0.014283199794590473,
              0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              0.049857500940561295,
              0.27990299463272095,
              -0.013319499790668488,
              0.04983939975500107,
              0.2800540030002594,
              -0.01347339991480112,
              0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              0.0491688996553421,
              0.2856520116329193,
              -0.004417839925736189,
              0.049143899232149124,
              0.28586000204086304,
              -0.004473450127989054,
              0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              0.048329900950193405,
              0.2926560044288635,
              -0.006286489777266979,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              0.048305001109838486,
              0.29286500811576843,
              -0.006342099979519844,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              0.050811100751161575,
              0.27194100618362427,
              -0.018599700182676315,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              0.05081870034337044,
              0.2718780040740967,
              -0.018392600119113922,
              0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              0.05068409815430641,
              0.2730019986629486,
              -0.01793769933283329,
              0.050675101578235626,
              0.27307701110839844,
              -0.018140500411391258,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              0.04877439886331558,
              0.2889449894428253,
              0.011264599859714508,
              0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              0.04874910041689873,
              0.2891570031642914,
              0.011225099675357342,
              0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              0.04874910041689873,
              0.2891570031642914,
              0.011225099675357342,
              0.049869898706674576,
              0.2797999978065491,
              -0.022336099296808243,
              0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.04985659942030907,
              0.27990999817848206,
              -0.022521700710058212,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              0.04940669983625412,
              0.2836660146713257,
              -0.019443100318312645,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.04926519840955734,
              0.28484800457954407,
              -0.018356500193476677,
              0.04938979819417,
              0.28380799293518066,
              -0.01960569992661476,
              0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              0.04926519840955734,
              0.28484800457954407,
              -0.018356500193476677,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              0.08903949707746506,
              0.00150969997048378,
              0.10217399895191193,
              0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              0.08903949707746506,
              0.0012934500118717551,
              0.10217399895191193,
              0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              0.10380899906158447,
              0.0015097600407898426,
              0.08880440145730972,
              0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              0.10380899906158447,
              0.0012934500118717551,
              0.08880440145730972,
              0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              0.10619500279426575,
              0.0012934800470247865,
              0.07873769849538803,
              0.10619500279426575,
              0.0015097600407898426,
              0.07873769849538803,
              0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              0.10563500225543976,
              0.0015097899595275521,
              0.0722305029630661,
              0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              0.10563500225543976,
              0.0012934800470247865,
              0.0722305029630661,
              0.10563500225543976,
              0.0015097899595275521,
              0.0722305029630661,
              0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              0.08281289786100388,
              0.0028693699277937412,
              0.10349900275468826,
              0.08281289786100388,
              0.002653090050444007,
              0.10349900275468826,
              0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              0.08495300263166428,
              0.0028780701104551554,
              0.10322699695825577,
              0.08495300263166428,
              0.0026617900002747774,
              0.10322699695825577,
              0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              0.09282170236110687,
              0.002964999992400408,
              0.10049600154161453,
              0.09282170236110687,
              0.002748670056462288,
              0.10049600154161453,
              0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              0.0945814996957779,
              0.0029985299333930016,
              0.09944240003824234,
              0.0945814996957779,
              0.002782230032607913,
              0.09944240003824234,
              0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              0.10616199672222137,
              0.0037265399005264044,
              0.07658059895038605,
              0.10616199672222137,
              0.003510120091959834,
              0.07658059895038605,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              0.0501926988363266,
              0.27504101395606995,
              -0.016647199168801308,
              0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              0.04761059954762459,
              0.2966090142726898,
              0.009837989695370197,
              0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              0.04739430174231529,
              0.2966090142726898,
              0.009837879799306393,
              0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              0.04739430174231529,
              0.2966090142726898,
              0.009837879799306393,
              0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              0.05007150024175644,
              0.2760530114173889,
              -0.016040600836277008,
              0.049954600632190704,
              0.27702999114990234,
              -0.01538539957255125,
              0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.049440499395132065,
              0.2813259959220886,
              -0.011439099907875061,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              0.049060601741075516,
              0.2845019996166229,
              -0.006535539869219065,
              0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              0.04862150177359581,
              0.28636300563812256,
              -0.0008915470098145306,
              0.04886870086193085,
              0.2861050069332123,
              -0.002069160109385848,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              0.04862150177359581,
              0.28636300563812256,
              -0.0008915470098145306,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              0.05011900141835213,
              0.27565500140190125,
              -0.02506290003657341,
              0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              0.04993870109319687,
              0.27716198563575745,
              -0.024358199909329414,
              0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04884310066699982,
              0.2863140106201172,
              -0.017521599307656288,
              0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              0.048713501542806625,
              0.28739601373672485,
              -0.01630209945142269,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              0.048183098435401917,
              0.2918280065059662,
              -0.009451660327613354,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              0.08487539738416672,
              0.0010476399911567569,
              0.10277099907398224,
              0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              0.08487539738416672,
              0.0010476399911567569,
              0.10277099907398224,
              0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              0.09596150368452072,
              0.0010476399911567569,
              0.09789100289344788,
              0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              0.09596150368452072,
              0.0010476399911567569,
              0.09789100289344788,
              0.09891639649868011,
              0.0010476700263097882,
              0.09520290046930313,
              0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              0.097638800740242,
              0.0010476399911567569,
              0.09676429629325867,
              0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              0.10141099989414215,
              0.0010476700263097882,
              0.09208779782056808,
              0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              0.10022500157356262,
              0.0010476700263097882,
              0.09369479864835739,
              0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              0.10022500157356262,
              0.0010476700263097882,
              0.09369479864835739,
              0.10558900237083435,
              0.0010476999450474977,
              0.08080639690160751,
              0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              0.10570099949836731,
              0.0010476999450474977,
              0.0766071006655693,
              0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              0.10570099949836731,
              0.0010476999450474977,
              0.0766071006655693,
              0.08277390152215958,
              0.0010476399911567569,
              0.10303899645805359,
              0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              0.08060760051012039,
              0.0010476399911567569,
              0.10312999784946442,
              0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              0.08060760051012039,
              0.0010476399911567569,
              0.10312999784946442,
              0.09260039776563644,
              0.0010476399911567569,
              0.10008999705314636,
              0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              0.09596150368452072,
              0.0010476399911567569,
              0.09789100289344788,
              0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              0.09432829916477203,
              0.0010476399911567569,
              0.09905549883842468,
              0.09609410166740417,
              0.0010476700263097882,
              0.09806220233440399,
              0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              0.09432829916477203,
              0.0010476399911567569,
              0.09905549883842468,
              0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              0.10141099989414215,
              0.0010476700263097882,
              0.09208779782056808,
              0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              0.10159000009298325,
              0.0010476700263097882,
              0.09220939874649048,
              0.10141099989414215,
              0.0010476700263097882,
              0.09208779782056808,
              0.1048009991645813,
              0.0010476700263097882,
              0.08482729643583298,
              0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              0.10417000204324722,
              0.0010476999450474977,
              0.08675160259008408,
              0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              0.10417000204324722,
              0.0010476999450474977,
              0.08675160259008408,
              0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              0.1048009991645813,
              0.0010476700263097882,
              0.08482729643583298,
              0.10548800230026245,
              0.0010476700263097882,
              0.08288460224866867,
              0.10500899702310562,
              0.0010476700263097882,
              0.08488620072603226,
              0.1048009991645813,
              0.0010476700263097882,
              0.08482729643583298,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              0,
              0.0010476099560037255,
              0.10334599763154984,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              0.08888719975948334,
              0.003171290038153529,
              0.10173700004816055,
              0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              0.08888719975948334,
              0.003171290038153529,
              0.10173700004816055,
              0.08888719975948334,
              0.003171290038153529,
              0.10173700004816055,
              0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              0.09078440070152283,
              0.0031951700802892447,
              0.10098599642515182,
              0.0889585018157959,
              0.0031647998839616776,
              0.10194200277328491,
              0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              0.09078440070152283,
              0.0031951700802892447,
              0.10098599642515182,
              0.09596139937639236,
              0.0032937799114733934,
              0.09789130091667175,
              0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              0.0974930003285408,
              0.0033347599674016237,
              0.09660450369119644,
              0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              0.0974930003285408,
              0.0033347599674016237,
              0.09660450369119644,
              0.09891629964113235,
              0.0033793700858950615,
              0.09520310163497925,
              0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              0.10141099989414215,
              0.0034785899333655834,
              0.0920879989862442,
              0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              0.10246799886226654,
              0.003532679984346032,
              0.09038989990949631,
              0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              0.10246799886226654,
              0.003532679984346032,
              0.09038989990949631,
              0.10246799886226654,
              0.003532679984346032,
              0.09038989990949631,
              0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              0.10339000076055527,
              0.003589420113712549,
              0.08860860019922256,
              0.10265599936246872,
              0.0035292799584567547,
              0.09049689769744873,
              0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              0.10339000076055527,
              0.003589420113712549,
              0.08860860019922256,
              0.10339000076055527,
              0.003589420113712549,
              0.08860860019922256,
              0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              0.10358600318431854,
              0.003586469916626811,
              0.08870019763708115,
              0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              0.1048009991645813,
              0.003709790064021945,
              0.08482740074396133,
              0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              0.10527600347995758,
              0.003773000091314316,
              0.08284299820661545,
              0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              0.10527600347995758,
              0.003773000091314316,
              0.08284299820661545,
              0.10558900237083435,
              0.003837849944829941,
              0.08080650120973587,
              0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              0.10548499971628189,
              0.004040299914777279,
              0.07444950193166733,
              0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              0.10539200156927109,
              0.004109859932214022,
              0.07226529717445374,
              0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              0.07841979712247849,
              0.0031269199680536985,
              0.10312999784946442,
              0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              0.08060750365257263,
              0.0031269199680536985,
              0.10312999784946442,
              0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              0.08060750365257263,
              0.0031269199680536985,
              0.10312999784946442,
              0,
              0.003126859897747636,
              0.10312999784946442,
              0,
              0.003120010020211339,
              0.10334599763154984,
              0.07841979712247849,
              0.0031269199680536985,
              0.10312999784946442,
              0,
              0.003120010020211339,
              0.10334599763154984,
              0.07841979712247849,
              0.003120010020211339,
              0.10334599763154984,
              0.07841979712247849,
              0.0031269199680536985,
              0.10312999784946442,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              0.04984220117330551,
              0.27796998620033264,
              -0.014683100394904613,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              0.04973439872264862,
              0.27887099981307983,
              -0.013935700058937073,
              0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0.04963130131363869,
              0.27973198890686035,
              -0.013144699856638908,
              0.04953330010175705,
              0.2805509865283966,
              -0.012311900034546852,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              0.04900269955396652,
              0.2849850058555603,
              -0.005459019914269447,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              0.04895130172371864,
              0.28541499376296997,
              -0.004354630131274462,
              0.0489065982401371,
              0.28578901290893555,
              -0.003224099986255169,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              0.05030449852347374,
              0.2741059958934784,
              -0.025692399591207504,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              0.04976370185613632,
              0.27862200140953064,
              -0.023580700159072876,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              0.048102300614118576,
              0.2925019860267639,
              -0.007947919890284538,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              0.04796820133924484,
              0.2936230003833771,
              -0.004826220218092203,
              0.04803059995174408,
              0.2931019961833954,
              -0.006405309773981571,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              0.04765599966049194,
              0.29442399740219116,
              -0.0015683600213378668,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              0.04791539907455444,
              0.294064998626709,
              -0.003213099902495742,
              0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              0.05057999864220619,
              0.271806001663208,
              -0.018157100304961205,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              0.05044709891080856,
              0.2729159891605377,
              -0.017707200720906258,
              0.050317998975515366,
              0.2739950120449066,
              -0.017203299328684807,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              0.04935299977660179,
              0.282056987285614,
              -0.010528099723160267,
              0.049271099269390106,
              0.2827419936656952,
              -0.009580439887940884,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              0.049194999039173126,
              0.283378005027771,
              -0.008597980253398418,
              0.04912469908595085,
              0.2839660048484802,
              -0.007582440041005611,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              0.04862150177359581,
              0.28636300563812256,
              -0.0008915470098145306,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              0.04943130165338516,
              0.2813990116119385,
              -0.02181670069694519,
              0.04959459975361824,
              0.28003600239753723,
              -0.022732699289917946,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              0.049274299293756485,
              0.282710999250412,
              -0.02083520032465458,
              0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              0.048979900777339935,
              0.2851699888706207,
              -0.018685299903154373,
              0.049123700708150864,
              0.2839690148830414,
              -0.01979059912264347,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              0.04847700148820877,
              0.2893719971179962,
              -0.01370530016720295,
              0.04859140142798424,
              0.2884159982204437,
              -0.015029200352728367,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0.048272598534822464,
              0.2910799980163574,
              -0.010914100334048271,
              0.04837070032954216,
              0.29025998711586,
              -0.012332700192928314,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0.08888719975948334,
              0.0010476399911567569,
              0.10173700004816055,
              0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              0.08696939796209335,
              0.0010476399911567569,
              0.10254400223493576,
              0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              0.08888719975948334,
              0.0010476399911567569,
              0.10173700004816055,
              0.09087230265140533,
              0.0010476399911567569,
              0.10118400305509567,
              0.0889585018157959,
              0.0010476399911567569,
              0.10194099694490433,
              0.08888719975948334,
              0.0010476399911567569,
              0.10173700004816055,
              0.09432829916477203,
              0.0010476399911567569,
              0.09905549883842468,
              0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              0.09260039776563644,
              0.0010476399911567569,
              0.10008999705314636,
              0.09444680064916611,
              0.0010476399911567569,
              0.09923660010099411,
              0.09270399808883667,
              0.0010476399911567569,
              0.10028000175952911,
              0.09260039776563644,
              0.0010476399911567569,
              0.10008999705314636,
              0.10570099949836731,
              0.0010476999450474977,
              0.0766071006655693,
              0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              0.1057329997420311,
              0.0010476700263097882,
              0.07872530072927475,
              0.10591699928045273,
              0.0010476999450474977,
              0.07659470289945602,
              0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              0.1057329997420311,
              0.0010476700263097882,
              0.07872530072927475,
              0.08487539738416672,
              0.0010476399911567569,
              0.10277099907398224,
              0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              0.08277390152215958,
              0.0010476399911567569,
              0.10303899645805359,
              0.08491169661283493,
              0.0010476399911567569,
              0.1029840037226677,
              0.08279210329055786,
              0.0010476399911567569,
              0.10325399786233902,
              0.08277390152215958,
              0.0010476399911567569,
              0.10303899645805359,
              0.10022500157356262,
              0.0010476700263097882,
              0.09369479864835739,
              0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              0.09891639649868011,
              0.0010476700263097882,
              0.09520290046930313,
              0.10039299726486206,
              0.0010476700263097882,
              0.09383010119199753,
              0.09907419979572296,
              0.0010476700263097882,
              0.09535100311040878,
              0.09891639649868011,
              0.0010476700263097882,
              0.09520290046930313,
              0.10339000076055527,
              0.0010476700263097882,
              0.08860839903354645,
              0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              0.10265599936246872,
              0.0010476700263097882,
              0.09049669653177261,
              0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              0.10417000204324722,
              0.0010476999450474977,
              0.08675160259008408,
              0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              0.10339000076055527,
              0.0010476700263097882,
              0.08860839903354645,
              0.10437300056219101,
              0.0010476999450474977,
              0.08682730048894882,
              0.10358600318431854,
              0.0010476700263097882,
              0.08870010077953339,
              0.10339000076055527,
              0.0010476700263097882,
              0.08860839903354645,
              0.1057329997420311,
              0.0010476700263097882,
              0.07872530072927475,
              0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              0.10558900237083435,
              0.0010476999450474977,
              0.08080639690160751,
              0.10594899952411652,
              0.0010476999450474977,
              0.07873109728097916,
              0.10580399632453918,
              0.0010476700263097882,
              0.08083029836416245,
              0.10558900237083435,
              0.0010476999450474977,
              0.08080639690160751,
              0.10517799854278564,
              0.0010476999450474977,
              0.07229579985141754,
              0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              0.10539200156927109,
              0.0010476999450474977,
              0.07226529717445374,
              0.10570000112056732,
              0.0010476999450474977,
              0.07442349940538406,
              0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              0.08060760051012039,
              0.0010476399911567569,
              0.10312999784946442,
              0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.08061210066080093,
              0.0010476399911567569,
              0.10334599763154984,
              0.07841979712247849,
              0.0010476399911567569,
              0.10334599763154984,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.08060750365257263,
              0.0031269199680536985,
              0.10312999784946442,
              0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              0.08061210066080093,
              0.0031200300436466932,
              0.10334599763154984,
              0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              0.08487530052661896,
              0.0031383300665766,
              0.10277099907398224,
              0.08279210329055786,
              0.0031229599844664335,
              0.10325399786233902,
              0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              0.08487530052661896,
              0.0031383300665766,
              0.10277099907398224,
              0.08487530052661896,
              0.0031383300665766,
              0.10277099907398224,
              0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              0.08491169661283493,
              0.0031315700616687536,
              0.10298500210046768,
              0.08696939796209335,
              0.003145549912005663,
              0.10254400223493576,
              0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              0.09078440070152283,
              0.0031951700802892447,
              0.10098599642515182,
              0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              0.09260030090808868,
              0.003223720006644726,
              0.10008999705314636,
              0.09087219834327698,
              0.0031888799276202917,
              0.10118400305509567,
              0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              0.09260030090808868,
              0.003223720006644726,
              0.10008999705314636,
              0.09260030090808868,
              0.003223720006644726,
              0.10008999705314636,
              0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              0.09270399808883667,
              0.0032176999375224113,
              0.10028000175952911,
              0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              0.09596139937639236,
              0.0032937799114733934,
              0.09789130091667175,
              0.09444680064916611,
              0.003250929992645979,
              0.09923669695854187,
              0.09609399735927582,
              0.0032883300445973873,
              0.09806229919195175,
              0.09596139937639236,
              0.0032937799114733934,
              0.09789130091667175,
              0.0974930003285408,
              0.0033347599674016237,
              0.09660450369119644,
              0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              0.09891629964113235,
              0.0033793700858950615,
              0.09520310163497925,
              0.09763869643211365,
              0.0033296600449830294,
              0.09676449745893478,
              0.09907419979572296,
              0.003374669933691621,
              0.0953511968255043,
              0.09891629964113235,
              0.0033793700858950615,
              0.09520310163497925,
              0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              0.10141099989414215,
              0.0034785899333655834,
              0.0920879989862442,
              0.10039299726486206,
              0.003423119895160198,
              0.09383019804954529,
              0.10159000009298325,
              0.0034747100435197353,
              0.09220950305461884,
              0.10141099989414215,
              0.0034785899333655834,
              0.0920879989862442,
              0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              0.1048009991645813,
              0.003709790064021945,
              0.08482740074396133,
              0.10437300056219101,
              0.0036461399868130684,
              0.08682739734649658,
              0.10500899702310562,
              0.003707919968292117,
              0.08488629758358002,
              0.1048009991645813,
              0.003709790064021945,
              0.08482740074396133,
              0.10527600347995758,
              0.003773000091314316,
              0.08284299820661545,
              0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              0.10558900237083435,
              0.003837849944829941,
              0.08080650120973587,
              0.10548800230026245,
              0.003771659918129444,
              0.08288469910621643,
              0.10580399632453918,
              0.0038370799738913774,
              0.0808304026722908,
              0.10558900237083435,
              0.003837849944829941,
              0.08080650120973587,
              0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              0.10570099949836731,
              0.003971610218286514,
              0.07660699635744095,
              0.10594899952411652,
              0.003903960110619664,
              0.07873109728097916,
              0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              0.10570099949836731,
              0.003971610218286514,
              0.07660699635744095,
              0.10570099949836731,
              0.003971610218286514,
              0.07660699635744095,
              0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              0.10548499971628189,
              0.004040299914777279,
              0.07444950193166733,
              0.10591699928045273,
              0.003971990197896957,
              0.07659470289945602,
              0.10570000112056732,
              0.004041110165417194,
              0.07442349940538406,
              0.10548499971628189,
              0.004040299914777279,
              0.07444950193166733,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              0.050553299486637115,
              0.27409398555755615,
              -0.017427999526262283,
              0.05054280161857605,
              0.274181991815567,
              -0.017625799402594566,
              0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              0.05042630061507225,
              0.2751539945602417,
              -0.016865400597453117,
              0.05041449889540672,
              0.2752529978752136,
              -0.017057400196790695,
              0.05005539953708649,
              0.2782509922981262,
              -0.015048299916088581,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              0.050071101635694504,
              0.2781189978122711,
              -0.014877299778163433,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              0.049554500728845596,
              0.28243300318717957,
              -0.010794100351631641,
              0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              0.049575500190258026,
              0.28225699067115784,
              -0.01066960021853447,
              0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              0.0493927001953125,
              0.2837829887866974,
              -0.008817619644105434,
              0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              0.04941539838910103,
              0.283594012260437,
              -0.008714810013771057,
              0.04949260130524635,
              0.2829500138759613,
              -0.00970982015132904,
              0.04947059974074364,
              0.28313300013542175,
              -0.009823660366237164,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              0.04970930144190788,
              0.281139999628067,
              -0.021435199305415154,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              0.04969479888677597,
              0.28126099705696106,
              -0.021613800898194313,
              0.049539100378751755,
              0.2825610041618347,
              -0.02064099907875061,
              0.04955479875206947,
              0.2824299931526184,
              -0.02047009952366352,
              0.04926519840955734,
              0.28484800457954407,
              -0.018356500193476677,
              0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              0.049247100949287415,
              0.2849990129470825,
              -0.018510399386286736,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              0.04813599959015846,
              0.2942749857902527,
              0.0000971035988186486,
              0.04817330092191696,
              0.2939639985561371,
              -0.0015296899946406484,
              0.05017060041427612,
              0.2772890031337738,
              -0.015766900032758713,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              0.05018509924411774,
              0.27716800570487976,
              -0.015588300302624702,
              0.05030350014567375,
              0.2761789858341217,
              -0.01625159941613674,
              0.0502903014421463,
              0.2762889862060547,
              -0.01643720082938671,
              0.049644000828266144,
              0.281686007976532,
              -0.011727100238204002,
              0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              0.049664098769426346,
              0.28151699900627136,
              -0.011592299677431583,
              0.049758199602365494,
              0.28073200583457947,
              -0.012476200237870216,
              0.04973899945616722,
              0.280892014503479,
              -0.01262080017477274,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              0.04934430122375488,
              0.284186989068985,
              -0.007686330005526543,
              0.049320898950099945,
              0.28438299894332886,
              -0.007777759805321693,
              0.04919629916548729,
              0.28542301058769226,
              -0.005603860132396221,
              0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              0.04922090098261833,
              0.2852180004119873,
              -0.005536059848964214,
              0.04927939921617508,
              0.28472900390625,
              -0.006626150105148554,
              0.04925540089607239,
              0.2849299907684326,
              -0.006705889943987131,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              0.049123700708150864,
              0.28602901101112366,
              -0.0032732500694692135,
              0.04909839853644371,
              0.28624099493026733,
              -0.0033164999913424253,
              0.04902850091457367,
              0.28682398796081543,
              -0.0009302229736931622,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              0.049085501581430435,
              0.28634798526763916,
              -0.0021040700376033783,
              0.049059901386499405,
              0.2865619957447052,
              -0.0021347899455577135,
              0.050567999482154846,
              0.27397099137306213,
              -0.025249799713492393,
              0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              0.05056050047278404,
              0.2740339934825897,
              -0.025457000359892845,
              0.05037650093436241,
              0.27556899189949036,
              -0.024832500144839287,
              0.050385601818561554,
              0.2754940092563629,
              -0.02462960034608841,
              0.05020830035209656,
              0.27697500586509705,
              -0.023935699835419655,
              0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              0.050197798758745193,
              0.27706199884414673,
              -0.024133499711751938,
              0.050024401396512985,
              0.2785100042819977,
              -0.023362400010228157,
              0.050036199390888214,
              0.27841100096702576,
              -0.02317040041089058,
              0.049130599945783615,
              0.285971999168396,
              -0.017212800681591034,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.04911139979958534,
              0.28613200783729553,
              -0.017357399687170982,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.0490030013024807,
              0.2870369851589203,
              -0.01601419970393181,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              0.0489828996360302,
              0.2872050106525421,
              -0.016148999333381653,
              0.04886170104146004,
              0.2882170081138611,
              -0.014887699857354164,
              0.048882801085710526,
              0.28804099559783936,
              -0.014763199724256992,
              0.048770200461149216,
              0.28898099064826965,
              -0.013462100178003311,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.04874829947948456,
              0.2891640067100525,
              -0.013575900346040726,
              0.04864269867539406,
              0.29004499316215515,
              -0.012215900234878063,
              0.048665400594472885,
              0.2898559868335724,
              -0.012113099917769432,
              0.04856880009174347,
              0.2906630039215088,
              -0.010718800127506256,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              0.04854540154337883,
              0.2908580005168915,
              -0.01081020012497902,
              0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              0.048480499535799026,
              0.2913999855518341,
              -0.009281289763748646,
              0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              0.048456501215696335,
              0.29159998893737793,
              -0.009361039847135544,
              0.04837619885802269,
              0.2922700047492981,
              -0.007870890200138092,
              0.04840080067515373,
              0.29206499457359314,
              -0.007803089916706085,
              0.04826829954981804,
              0.2931709885597229,
              -0.004733830224722624,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              0.048242900520563126,
              0.29338300228118896,
              -0.004777079913765192,
              0.04819029942154884,
              0.2938219904899597,
              -0.0031781899742782116,
              0.048215899616479874,
              0.29360800981521606,
              -0.003147450042888522,
              0.08495300263166428,
              0.0015097300056368113,
              0.10322699695825577,
              0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              0.08495300263166428,
              0.0012934199767187238,
              0.10322699695825577,
              0.08703090250492096,
              0.0012934199767187238,
              0.1027820035815239,
              0.08703090250492096,
              0.00150969997048378,
              0.1027820035815239,
              0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              0.09780430048704147,
              0.0015097300056368113,
              0.09694629907608032,
              0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              0.09780430048704147,
              0.0012934500118717551,
              0.09694629907608032,
              0.09925349801778793,
              0.0012934500118717551,
              0.09551940113306046,
              0.09925349801778793,
              0.0015097300056368113,
              0.09551940113306046,
              0.10058499872684479,
              0.0015097600407898426,
              0.09398379921913147,
              0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              0.10058499872684479,
              0.0012934500118717551,
              0.09398379921913147,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              0.10604800283908844,
              0.0012934500118717551,
              0.08085750043392181,
              0.10604800283908844,
              0.0015097899595275521,
              0.08085750043392181,
              0.10616199672222137,
              0.0015097899595275521,
              0.07658059895038605,
              0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              0.10616199672222137,
              0.0012934800470247865,
              0.07658059895038605,
              0.10594400018453598,
              0.001293509965762496,
              0.07439389824867249,
              0.10594400018453598,
              0.0015097899595275521,
              0.07439389824867249,
              0.08061730116605759,
              0.0015097300056368113,
              0.10359200090169907,
              0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              0.08061730116605759,
              0.0012934199767187238,
              0.10359200090169907,
              0.08281289786100388,
              0.0012934199767187238,
              0.10349900275468826,
              0.08281289786100388,
              0.0015097300056368113,
              0.10349900275468826,
              0.09097210317850113,
              0.0015097300056368113,
              0.10140900313854218,
              0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              0.09097210317850113,
              0.0012934199767187238,
              0.10140900313854218,
              0.09282170236110687,
              0.0012934500118717551,
              0.10049600154161453,
              0.09282170236110687,
              0.0015097300056368113,
              0.10049600154161453,
              0.0945814996957779,
              0.0015097300056368113,
              0.09944240003824234,
              0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              0.0945814996957779,
              0.0012934199767187238,
              0.09944240003824234,
              0.0962447002530098,
              0.0012934199767187238,
              0.09825660288333893,
              0.0962447002530098,
              0.0015097600407898426,
              0.09825660288333893,
              0.10179299861192703,
              0.0015097600407898426,
              0.09234750270843506,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              0.10179299861192703,
              0.0012934199767187238,
              0.09234750270843506,
              0.10287000238895416,
              0.0012934500118717551,
              0.09061840176582336,
              0.10287000238895416,
              0.0015097600407898426,
              0.09061840176582336,
              0.10460299998521805,
              0.0015097600407898426,
              0.08691330254077911,
              0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              0.10460299998521805,
              0.0012934500118717551,
              0.08691330254077911,
              0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              0.10524599999189377,
              0.0015097600407898426,
              0.0849531963467598,
              0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              0.10524599999189377,
              0.0012934500118717551,
              0.0849531963467598,
              0.10572999715805054,
              0.0012934500118717551,
              0.0829320028424263,
              0.10572999715805054,
              0.0015097600407898426,
              0.0829320028424263,
              0,
              0.0015096700517460704,
              0.10359200090169907,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              0,
              0.0012933900579810143,
              0.10359200090169907,
              0.07841979712247849,
              0.0012934199767187238,
              0.10359200090169907,
              0.07841979712247849,
              0.0015097300056368113,
              0.10359200090169907,
              0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              0.08703090250492096,
              0.0028921999037265778,
              0.1027820035815239,
              0.08703090250492096,
              0.002675889991223812,
              0.1027820035815239,
              0.09097210317850113,
              0.002719609998166561,
              0.10140900313854218,
              0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              0.09097210317850113,
              0.002935949945822358,
              0.10140900313854218,
              0.08903949707746506,
              0.002911600051447749,
              0.10217399895191193,
              0.08903949707746506,
              0.00269528990611434,
              0.10217399895191193,
              0.09780430048704147,
              0.0028616799972951412,
              0.09694629907608032,
              0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              0.09780430048704147,
              0.003078009933233261,
              0.09694629907608032,
              0.0962447002530098,
              0.003036289941519499,
              0.09825660288333893,
              0.0962447002530098,
              0.0028199900407344103,
              0.09825660288333893,
              0.10058499872684479,
              0.0029559999238699675,
              0.09398379921913147,
              0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              0.10058499872684479,
              0.003172369906678796,
              0.09398379921913147,
              0.09925349801778793,
              0.003123460104689002,
              0.09551940113306046,
              0.09925349801778793,
              0.0029071299359202385,
              0.09551940113306046,
              0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              0.10179299861192703,
              0.0032244299072772264,
              0.09234750270843506,
              0.10179299861192703,
              0.003008069936186075,
              0.09234750270843506,
              0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              0.10287000238895416,
              0.0032795099541544914,
              0.09061840176582336,
              0.10287000238895416,
              0.003063139971345663,
              0.09061840176582336,
              0.10460299998521805,
              0.0031810998916625977,
              0.08691330254077911,
              0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              0.10460299998521805,
              0.0033974898979067802,
              0.08691330254077911,
              0.10380899906158447,
              0.003337260102853179,
              0.08880440145730972,
              0.10380899906158447,
              0.003120899898931384,
              0.08880440145730972,
              0.10572999715805054,
              0.0033078501001000404,
              0.0829320028424263,
              0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              0.10572999715805054,
              0.003524240106344223,
              0.0829320028424263,
              0.10524599999189377,
              0.003459869883954525,
              0.08495330065488815,
              0.10524599999189377,
              0.0032435099128633738,
              0.0849531963467598,
              0.10619500279426575,
              0.0034414499532431364,
              0.07873769849538803,
              0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              0.10619500279426575,
              0.003657849971204996,
              0.07873769849538803,
              0.10604800283908844,
              0.0035903200041502714,
              0.08085750043392181,
              0.10604800283908844,
              0.0033739199861884117,
              0.08085750043392181,
              0.10563500225543976,
              0.0036486699245870113,
              0.0722305029630661,
              0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              0.10563500225543976,
              0.0038650899659842253,
              0.0722305029630661,
              0.10594400018453598,
              0.00379615998826921,
              0.07439389824867249,
              0.10594400018453598,
              0.0035798000171780586,
              0.07439389824867249,
              0.08061730116605759,
              0.002650110051035881,
              0.10359200090169907,
              0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              0.08061730116605759,
              0.0028664199635386467,
              0.10359200090169907,
              0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              0.07841979712247849,
              0.002650110051035881,
              0.10359200090169907,
              0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              0.07841979712247849,
              0.0028664199635386467,
              0.10359200090169907,
              0,
              0.0028663899283856153,
              0.10359200090169907,
              0,
              0.002650110051035881,
              0.10359200090169907,
              0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.08888719975948334,
              0.0010476399911567569,
              0.10173700004816055,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              0.08888719975948334,
              0.0010476399911567569,
              0.10173700004816055,
              0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              0.09260039776563644,
              0.0010476399911567569,
              0.10008999705314636,
              0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              0.09432829916477203,
              0.0010476399911567569,
              0.09905549883842468,
              0.09260039776563644,
              0.0010476399911567569,
              0.10008999705314636,
              0.09432829916477203,
              0.0010476399911567569,
              0.09905549883842468,
              0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              0.09596150368452072,
              0.0010476399911567569,
              0.09789100289344788,
              0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              0.09891639649868011,
              0.0010476700263097882,
              0.09520290046930313,
              0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              0.10022500157356262,
              0.0010476700263097882,
              0.09369479864835739,
              0.09891639649868011,
              0.0010476700263097882,
              0.09520290046930313,
              0.10022500157356262,
              0.0010476700263097882,
              0.09369479864835739,
              0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              0.10141099989414215,
              0.0010476700263097882,
              0.09208779782056808,
              0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              0.10339000076055527,
              0.0010476700263097882,
              0.08860839903354645,
              0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              0.10417000204324722,
              0.0010476999450474977,
              0.08675160259008408,
              0.10339000076055527,
              0.0010476700263097882,
              0.08860839903354645,
              0.10417000204324722,
              0.0010476999450474977,
              0.08675160259008408,
              0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              0.1048009991645813,
              0.0010476700263097882,
              0.08482729643583298,
              0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              0.10246799886226654,
              0.0010476700263097882,
              0.09038969874382019,
              0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              0.10558900237083435,
              0.0010476999450474977,
              0.08080639690160751,
              0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              0.1057329997420311,
              0.0010476700263097882,
              0.07872530072927475,
              0.10558900237083435,
              0.0010476999450474977,
              0.08080639690160751,
              0.1057329997420311,
              0.0010476700263097882,
              0.07872530072927475,
              0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              0.10570099949836731,
              0.0010476999450474977,
              0.0766071006655693,
              0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.10517799854278564,
              0.0010476999450474977,
              0.07229579985141754,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              0.10517799854278564,
              0.0010476999450474977,
              0.07229579985141754,
              0,
              0.0010476999450474977,
              0.0722958967089653,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0,
              0.0010476099560037255,
              0.10312999784946442,
              0.10527600347995758,
              0.0010476700263097882,
              0.08284290134906769,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.10548499971628189,
              0.0010476999450474977,
              0.07444959878921509,
              0.09078449755907059,
              0.0010476399911567569,
              0.10098599642515182,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.09749309718608856,
              0.0010476700263097882,
              0.09660419821739197,
              0.07841979712247849,
              0.0010476399911567569,
              0.10312999784946442,
              0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              0.08060760051012039,
              0.0010476399911567569,
              0.10312999784946442,
              0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              0.08277390152215958,
              0.0010476399911567569,
              0.10303899645805359,
              0.08060760051012039,
              0.0010476399911567569,
              0.10312999784946442,
              0.08487539738416672,
              0.0010476399911567569,
              0.10277099907398224,
              0.08277390152215958,
              0.0010476399911567569,
              0.10303899645805359,
              0.08691529929637909,
              0.0010476399911567569,
              0.10233499854803085,
              0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              0.08487530052661896,
              0.0031383300665766,
              0.10277099907398224,
              0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              0.08487530052661896,
              0.0031383300665766,
              0.10277099907398224,
              0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0.08060750365257263,
              0.0031269199680536985,
              0.10312999784946442,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0.07841979712247849,
              0.0031269199680536985,
              0.10312999784946442,
              0.08060750365257263,
              0.0031269199680536985,
              0.10312999784946442,
              0.07841979712247849,
              0.0031269199680536985,
              0.10312999784946442,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0,
              0.003126859897747636,
              0.10312999784946442,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              0.10548499971628189,
              0.004040299914777279,
              0.07444950193166733,
              0.10517799854278564,
              0.004108909983187914,
              0.07229579985141754,
              0.10548499971628189,
              0.004040299914777279,
              0.07444950193166733,
              0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              0.10570099949836731,
              0.003971610218286514,
              0.07660699635744095,
              0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              0.10558900237083435,
              0.003837849944829941,
              0.08080650120973587,
              0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              0.10527600347995758,
              0.003773000091314316,
              0.08284299820661545,
              0.10558900237083435,
              0.003837849944829941,
              0.08080650120973587,
              0.10527600347995758,
              0.003773000091314316,
              0.08284299820661545,
              0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              0.1048009991645813,
              0.003709790064021945,
              0.08482740074396133,
              0,
              0.004108880180865526,
              0.0722958967089653,
              0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              0.1057329997420311,
              0.003904160112142563,
              0.07872530072927475,
              0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              0.10339000076055527,
              0.003589420113712549,
              0.08860860019922256,
              0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              0.10246799886226654,
              0.003532679984346032,
              0.09038989990949631,
              0.10339000076055527,
              0.003589420113712549,
              0.08860860019922256,
              0.10246799886226654,
              0.003532679984346032,
              0.09038989990949631,
              0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              0.10141099989414215,
              0.0034785899333655834,
              0.0920879989862442,
              0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              0.09891629964113235,
              0.0033793700858950615,
              0.09520310163497925,
              0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              0.0974930003285408,
              0.0033347599674016237,
              0.09660450369119644,
              0.09891629964113235,
              0.0033793700858950615,
              0.09520310163497925,
              0.0974930003285408,
              0.0033347599674016237,
              0.09660450369119644,
              0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              0.09596139937639236,
              0.0032937799114733934,
              0.09789130091667175,
              0.10417000204324722,
              0.0036485199816524982,
              0.0867517963051796,
              0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              0.08277390152215958,
              0.0031298398971557617,
              0.10303899645805359,
              0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              0.10022400319576263,
              0.0034274200443178415,
              0.09369499981403351,
              0.09432820230722427,
              0.003256679978221655,
              0.0990557000041008,
              0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              0.09260030090808868,
              0.003223720006644726,
              0.10008999705314636,
              0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              0.09078440070152283,
              0.0031951700802892447,
              0.10098599642515182,
              0.09260030090808868,
              0.003223720006644726,
              0.10008999705314636,
              0.08888719975948334,
              0.003171290038153529,
              0.10173700004816055,
              0.09078440070152283,
              0.0031951700802892447,
              0.10098599642515182,
              0.08691529929637909,
              0.0031522500794380903,
              0.10233499854803085,
              0.04862150177359581,
              0.28636300563812256,
              -0.0008915470098145306,
              0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              0,
              0.2865920066833496,
              0.000297877995762974,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              0,
              0.27504101395606995,
              -0.0166471004486084,
              0.04997529834508896,
              0.27504101395606995,
              -0.016647199168801308,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              0,
              0.282056987285614,
              -0.010528000071644783,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.282056987285614,
              -0.010528000071644783,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              0,
              0.283378005027771,
              -0.00859792996197939,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              0,
              0.283378005027771,
              -0.00859792996197939,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              0.04973730072379112,
              0.27702999114990234,
              -0.01538539957255125,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              0,
              0.27702999114990234,
              -0.015385299921035767,
              0,
              0.2760530114173889,
              -0.016040600836277008,
              0.04985420033335686,
              0.2760530114173889,
              -0.016040600836277008,
              0.04922360181808472,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.2813259959220886,
              -0.011439099907875061,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              0,
              0.2845019996166229,
              -0.006535490043461323,
              0.048843998461961746,
              0.2845019996166229,
              -0.006535539869219065,
              0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              0.04862150177359581,
              0.28636300563812256,
              -0.0008915470098145306,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              0,
              0.28636300563812256,
              -0.0008915070211514831,
              0,
              0.2861050069332123,
              -0.0020691100507974625,
              0.048652298748493195,
              0.2861050069332123,
              -0.002069160109385848,
              0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              0,
              0.271806001663208,
              -0.018157100304961205,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              0,
              0.27796998620033264,
              -0.014683100394904613,
              0.04962490126490593,
              0.27796998620033264,
              -0.014683100394904613,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              0,
              0.27887099981307983,
              -0.013935600407421589,
              0.0495171993970871,
              0.27887099981307983,
              -0.013935700058937073,
              0.049316298216581345,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              0,
              0.2805509865283966,
              -0.012311900034546852,
              0,
              0.27973198890686035,
              -0.013144600205123425,
              0.04941429942846298,
              0.27973198890686035,
              -0.013144699856638908,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              0,
              0.2849850058555603,
              -0.005458979867398739,
              0.04878620058298111,
              0.2849850058555603,
              -0.005459019914269447,
              0.048690199851989746,
              0.28578901290893555,
              -0.003224099986255169,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              0,
              0.28578901290893555,
              -0.0032240499276667833,
              0,
              0.28541499376296997,
              -0.004354590084403753,
              0.04873479902744293,
              0.28541499376296997,
              -0.004354630131274462,
              0.05036240071058273,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.271806001663208,
              -0.018157100304961205,
              0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              0,
              0.271806001663208,
              -0.018157100304961205,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              0.050100501626729965,
              0.2739950120449066,
              -0.017203299328684807,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              0,
              0.2739950120449066,
              -0.017203200608491898,
              0,
              0.2729159891605377,
              -0.0177071001380682,
              0.05022960156202316,
              0.2729159891605377,
              -0.017707200720906258,
              0.04905429854989052,
              0.2827419936656952,
              -0.009580439887940884,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              0,
              0.2827419936656952,
              -0.009580399841070175,
              0,
              0.282056987285614,
              -0.010528000071644783,
              0.0491361990571022,
              0.282056987285614,
              -0.010528099723160267,
              0.04890809953212738,
              0.2839660048484802,
              -0.007582440041005611,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              0,
              0.2839660048484802,
              -0.0075823902152478695,
              0,
              0.283378005027771,
              -0.00859792996197939,
              0.04897819831967354,
              0.283378005027771,
              -0.008597980253398418,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.282710999250412,
              -0.02083520032465458,
              0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              0.04739430174231529,
              0.2966090142726898,
              0.009837879799306393,
              0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              0,
              0.2966090142726898,
              0.00983795989304781,
              0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              0,
              0.2947410047054291,
              0.00008496319787809625,
              0,
              0.2966090142726898,
              0.00983795989304781,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              0,
              0.27565500140190125,
              -0.02506290003657341,
              0.04990139976143837,
              0.27565500140190125,
              -0.02506290003657341,
              0.04972109943628311,
              0.27716198563575745,
              -0.024358199909329414,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              0,
              0.27716198563575745,
              -0.024358199909329414,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              0.04862599819898605,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.2863140106201172,
              -0.017521599307656288,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0.04849649965763092,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              0,
              0.28739601373672485,
              -0.01630209945142269,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              0,
              0.29025998711586,
              -0.012332700192928314,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              0.047966498881578445,
              0.2918280065059662,
              -0.009451660327613354,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              0,
              0.2918280065059662,
              -0.009451620280742645,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              0,
              0.294064998626709,
              -0.003213060088455677,
              0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              0.05027730017900467,
              0.2725149989128113,
              -0.02624420076608658,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              0,
              0.2725149989128113,
              -0.02624420076608658,
              0,
              0.2741059958934784,
              -0.025692399591207504,
              0.05008690059185028,
              0.2741059958934784,
              -0.025692399591207504,
              0.04954630136489868,
              0.27862200140953064,
              -0.023580700159072876,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              0,
              0.27862200140953064,
              -0.023580599576234818,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              0.04788580164313316,
              0.2925019860267639,
              -0.007947919890284538,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              0,
              0.2925019860267639,
              -0.00794787984341383,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              0.04781410098075867,
              0.2931019961833954,
              -0.006405309773981571,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              0,
              0.2931019961833954,
              -0.00640527019277215,
              0,
              0.2936230003833771,
              -0.004826180171221495,
              0.04775179922580719,
              0.2936230003833771,
              -0.004826220218092203,
              0.04769900068640709,
              0.294064998626709,
              -0.003213099902495742,
              0,
              0.294064998626709,
              -0.003213060088455677,
              0.04765599966049194,
              0.29442399740219116,
              -0.0015683600213378668,
              0,
              0.294064998626709,
              -0.003213060088455677,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              0.04765599966049194,
              0.29442399740219116,
              -0.0015683600213378668,
              0.0493771992623806,
              0.28003600239753723,
              -0.022732699289917946,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0,
              0.28003600239753723,
              -0.022732600569725037,
              0,
              0.2813990116119385,
              -0.02181670069694519,
              0.04921400174498558,
              0.2813990116119385,
              -0.02181670069694519,
              0.0490569993853569,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.282710999250412,
              -0.02083520032465458,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              0,
              0.282710999250412,
              -0.02083520032465458,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              0.0489065982401371,
              0.2839690148830414,
              -0.01979059912264347,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              0,
              0.2839690148830414,
              -0.01979059912264347,
              0,
              0.2851699888706207,
              -0.018685199320316315,
              0.04876279830932617,
              0.2851699888706207,
              -0.018685299903154373,
              0.04837450012564659,
              0.2884159982204437,
              -0.015029200352728367,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              0,
              0.2884159982204437,
              -0.015029200352728367,
              0,
              0.2893719971179962,
              -0.013705199584364891,
              0.04826020076870918,
              0.2893719971179962,
              -0.01370530016720295,
              0.04815400019288063,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.29025998711586,
              -0.012332700192928314,
              0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              0,
              0.29025998711586,
              -0.012332700192928314,
              0,
              0.2910799980163574,
              -0.010913999751210213,
              0.04805599898099899,
              0.2910799980163574,
              -0.010914100334048271,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0.05047040060162544,
              0.27090299129486084,
              -0.026756899431347847,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0,
              0.200997993350029,
              -0.04898789897561073,
              0.05047040060162544,
              0.27090299129486084,
              -0.026756899431347847,
              0,
              0.200997993350029,
              -0.04898789897561073,
              0,
              0.27090299129486084,
              -0.026756800711154938,
              0.05047040060162544,
              0.27090299129486084,
              -0.026756899431347847,
              0.050682298839092255,
              0.27250099182128906,
              -0.02618429996073246,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              0.050855301320552826,
              0.2695069909095764,
              -0.01891930028796196,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              0.050494998693466187,
              0.2725149989128113,
              -0.02624420076608658,
              0.050682298839092255,
              0.27250099182128906,
              -0.02618429996073246,
              0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              0.05095690116286278,
              0.27072399854660034,
              -0.0187912005931139,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              0.05090019851922989,
              0.2706809937953949,
              -0.018611399456858635,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              0.05094520002603531,
              0.2708210051059723,
              -0.02629780024290085,
              0.0509507991373539,
              0.2707749903202057,
              -0.019002100452780724,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.05094520002603531,
              0.2708210051059723,
              -0.02629780024290085,
              0.05074929818511009,
              0.2724579870700836,
              -0.026004500687122345,
              0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              0.050755299627780914,
              0.27240699529647827,
              -0.02579360082745552,
              0.05094520002603531,
              0.2708210051059723,
              -0.02629780024290085,
              0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              0.050716400146484375,
              0.2706669867038727,
              -0.018551500514149666,
              0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              0.050855301320552826,
              0.2695069909095764,
              -0.01891930028796196,
              0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              0.050855301320552826,
              0.2695069909095764,
              -0.01891930028796196,
              0.05049870163202286,
              0.2706669867038727,
              -0.018551500514149666,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              0,
              0.2706669867038727,
              -0.018551399931311607,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              0.048837799578905106,
              0.28636300563812256,
              -0.0008915470098145306,
              0.049014899879693985,
              0.28642499446868896,
              -0.0008966909954324365,
              0.0489875003695488,
              0.286653995513916,
              0.00029622300644405186,
              0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              0.048147499561309814,
              0.2941789925098419,
              -0.0015477900160476565,
              0.04806400090456009,
              0.29436299204826355,
              -0.0015632100403308868,
              0.048026200383901596,
              0.2946789860725403,
              0.00008654080011183396,
              0.04813599959015846,
              0.2942749857902527,
              0.0000971035988186486,
              0.047910500317811966,
              0.2961589992046356,
              0.009925980120897293,
              0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              0.047910500317811966,
              0.2961589992046356,
              0.009925980120897293,
              0.04874910041689873,
              0.2891570031642914,
              0.011225099675357342,
              0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              0.04902670159935951,
              0.28683900833129883,
              0.000291375006781891,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              0.04905429854989052,
              0.2866080105304718,
              -0.0009121199836954474,
              0.04902850091457367,
              0.28682398796081543,
              -0.0009302229736931622,
              0.04900059849023819,
              0.2870570123195648,
              0.0002856779901776463,
              0.047834400087594986,
              0.2947410047054291,
              0.00008492809865856543,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              0.04787230119109154,
              0.29442399740219116,
              -0.0015683600213378668,
              0.04765599966049194,
              0.29442399740219116,
              -0.0015683600213378668,
              0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              0.048557400703430176,
              0.2887049913406372,
              0.011305799707770348,
              0.04834109917283058,
              0.2887040078639984,
              0.011304800398647785,
              0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              0.04834109917283058,
              0.2887040078639984,
              0.011304800398647785,
              0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              0.04881050065159798,
              0.2865920066833496,
              0.0002978449920192361,
              0.04788510128855705,
              0.2963699996471405,
              0.009886279702186584,
              0.047910500317811966,
              0.2961589992046356,
              0.009925980120897293,
              0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              0.047910500317811966,
              0.2961589992046356,
              0.009925980120897293,
              0.04813599959015846,
              0.2942749857902527,
              0.0000971035988186486,
              0.04810990020632744,
              0.2944929897785187,
              0.00009140159818343818,
              0.04834109917283058,
              0.2887040078639984,
              0.011304800398647785,
              0,
              0.2887040078639984,
              0.011304699815809727,
              0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              0,
              0.2887040078639984,
              0.011304699815809727,
              0,
              0.2865920066833496,
              0.000297877995762974,
              0.048594098538160324,
              0.2865920066833496,
              0.0002978449920192361,
              0.04765599966049194,
              0.29442399740219116,
              -0.0015683600213378668,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              0,
              0.29442399740219116,
              -0.0015683099627494812,
              0,
              0.2947410047054291,
              0.00008496319787809625,
              0.04761800169944763,
              0.2947410047054291,
              0.00008493290079059079,
              0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              0.059248898178339005,
              0.20097999274730682,
              -0.04892930015921593,
              0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              0.050855301320552826,
              0.2695069909095764,
              -0.01891930028796196,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              0.059531401842832565,
              0.19862000644207,
              -0.04147280007600784,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              0.05068809911608696,
              0.27090299129486084,
              -0.026756899431347847,
              0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.050874900072813034,
              0.2708919942378998,
              -0.026695799082517624,
              0.059248898178339005,
              0.20097999274730682,
              -0.04892930015921593,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              0,
              0.19860200583934784,
              -0.04141410067677498,
              0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              0,
              0.2695069909095764,
              -0.01891930028796196,
              0.05109750106930733,
              0.26955100893974304,
              -0.019163500517606735,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              0.051039498299360275,
              0.2695179879665375,
              -0.018980400636792183,
              0.059531401842832565,
              0.19862000644207,
              -0.04147280007600784,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              0.05094520002603531,
              0.2708210051059723,
              -0.02629780024290085,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              0.05109290033578873,
              0.26958900690078735,
              -0.01937830075621605,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              0.05094069987535477,
              0.2708599865436554,
              -0.02651269920170307,
              0.05094520002603531,
              0.2708210051059723,
              -0.02629780024290085,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              0.050855301320552826,
              0.2695069909095764,
              -0.01891930028796196,
              0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              0.05063759908080101,
              0.2695069909095764,
              -0.01891930028796196,
              0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              -0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              -0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              -0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              -0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              -0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              -0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              -0.07833889871835709,
              0.04159890115261078,
              -0.09128779917955399,
              -0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              -0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              -0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              -0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              -0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              -0.07605569809675217,
              0.06066109985113144,
              -0.08524029701948166,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              -0.07833889871835709,
              0.04159890115261078,
              -0.09128779917955399,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              -0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              -0.07833889871835709,
              0.04159890115261078,
              -0.09128779917955399,
              -0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              -0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              -0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              -0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              -0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              -0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              -0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              -0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              -0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              -0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              -0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              -0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              -0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              -0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              -0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              -0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              -0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              -0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              -0.07577420026063919,
              0.06352879852056503,
              -0.0922200009226799,
              -0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              -0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              -0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              -0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              -0.07798150181770325,
              0.04510049894452095,
              -0.09808050096035004,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              -0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              0,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              0,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07837849855422974,
              0.04178540036082268,
              -0.0916495993733406,
              -0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              -0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              -0.07839059829711914,
              0.041684698313474655,
              -0.09145429730415344,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              -0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              -0.07609929889440536,
              0.06081410124897957,
              -0.0856126993894577,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              -0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              -0.07610920071601868,
              0.06073150038719177,
              -0.08541160076856613,
              -0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              -0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              -0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              -0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              -0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              -0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              -0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              -0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              -0.07576429843902588,
              0.06361129879951477,
              -0.09242100268602371,
              -0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              -0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              -0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              -0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              -0.07796940207481384,
              0.04520120099186897,
              -0.0982758030295372,
              -0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              -0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              -0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              -0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              -0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              -0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              -0.0756940022110939,
              0.0636816993355751,
              -0.09259240329265594,
              -0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              -0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              -0.07789730280637741,
              0.04528699815273285,
              -0.0984421968460083,
              -0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              -0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              -0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07565440237522125,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07587260007858276,
              0.0606377013027668,
              -0.08518320322036743,
              -0.07815639674663544,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07793819904327393,
              0.04157029837369919,
              -0.0912322998046875,
              -0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              -0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              0,
              0.04531560093164444,
              -0.09849770367145538,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              -0.07749059796333313,
              0.04531560093164444,
              -0.09849770367145538,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              -0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07770829647779465,
              0.04531560093164444,
              -0.09849770367145538,
              -0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07524190098047256,
              0.06370510160923004,
              -0.09264949709177017,
              -0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              -0.0754856988787651,
              0.06370510160923004,
              -0.09264949709177017,
              -0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              -0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              0.07833880186080933,
              0.04159890115261078,
              -0.09128790348768234,
              0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              0.07833880186080933,
              0.04159890115261078,
              -0.09128790348768234,
              0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              0.0760556012392044,
              0.0606612004339695,
              -0.08524040132761002,
              0.07833880186080933,
              0.04159890115261078,
              -0.09128790348768234,
              0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              0.07578609883785248,
              0.0635278970003128,
              -0.0922205001115799,
              0.07798139750957489,
              0.04510059952735901,
              -0.0980805978178978,
              0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              0,
              0.0606377013027668,
              -0.08518320322036743,
              0,
              0.04157029837369919,
              -0.0912322998046875,
              0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              0.07839050143957138,
              0.041684698313474655,
              -0.0914544016122818,
              0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              0.07837849855422974,
              0.04178540036082268,
              -0.09164970368146896,
              0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              0.07610920071601868,
              0.06073150038719177,
              -0.08541169762611389,
              0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              0.07609929889440536,
              0.06081410124897957,
              -0.08561280369758606,
              0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              0.07577519863843918,
              0.06361079961061478,
              -0.09242139756679535,
              0.07796940207481384,
              0.04520120099186897,
              -0.09827589988708496,
              0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              0.07569660246372223,
              0.06368160247802734,
              -0.0925925001502037,
              0.07789730280637741,
              0.04528699815273285,
              -0.09844230115413666,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              0.075872503221035,
              0.0606377013027668,
              -0.08518330007791519,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              0.07565440237522125,
              0.0606377013027668,
              -0.08518330007791519,
              0.07793810218572617,
              0.04157029837369919,
              -0.09123239666223526,
              0.07815629988908768,
              0.04157029837369919,
              -0.09123239666223526,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              0,
              0.06370510160923004,
              -0.09264949709177017,
              0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              0,
              0.04531560093164444,
              -0.09849770367145538,
              0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              0,
              0.04531560093164444,
              -0.09849770367145538,
              0.07770829647779465,
              0.04531560093164444,
              -0.09849780052900314,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              0.07749059796333313,
              0.04531560093164444,
              -0.09849780052900314,
              0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              0.07550559937953949,
              0.0637051984667778,
              -0.09264960139989853,
              0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              0.07528790086507797,
              0.0637051984667778,
              -0.09264960139989853,
              0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              -0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              -0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              -0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              -0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              -0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              -0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              -0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              -0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              -0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              -0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              -0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              -0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              -0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              -0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              -0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              -0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              -0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              -0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              -0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              -0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              -0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              -0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              -0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              -0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              -0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              -0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              -0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              -0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              -0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              -0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              -0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              -0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              -0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              -0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              -0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              -0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              -0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              -0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              -0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              -0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              -0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              -0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              -0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              -0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              -0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              -0.07021220028400421,
              0.10944800078868866,
              -0.0697626993060112,
              -0.07377249747514725,
              0.07972340285778046,
              -0.07919280230998993,
              -0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              -0.07026749849319458,
              0.10950300097465515,
              -0.06993889808654785,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              -0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              -0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              -0.07382780313491821,
              0.07977829873561859,
              -0.07936900109052658,
              -0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              -0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              -0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              -0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              -0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              -0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              -0.06999880075454712,
              0.11174699664115906,
              -0.07711309939622879,
              -0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              -0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              -0.069930300116539,
              0.11180199682712555,
              -0.07728920131921768,
              -0.07349079847335815,
              0.08207640051841736,
              -0.08674260228872299,
              -0.07355920225381851,
              0.08202140033245087,
              -0.08656629920005798,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              -0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              -0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              -0.07025980204343796,
              0.10956700146198273,
              -0.07014559954404831,
              -0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              -0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              -0.07000649720430374,
              0.1116819977760315,
              -0.07690639793872833,
              -0.07356689870357513,
              0.08195699751377106,
              -0.08635950088500977,
              -0.073820099234581,
              0.07984279841184616,
              -0.0795757994055748,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              -0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              -0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              -0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              -0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              -0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              -0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              -0.07002860307693481,
              0.1094290018081665,
              -0.06970400363206863,
              -0.07358880341053009,
              0.07970509678125381,
              -0.079134002327919,
              -0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              -0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              0,
              0.1094290018081665,
              -0.0697041004896164,
              -0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              -0.06981059908866882,
              0.1094290018081665,
              -0.06970400363206863,
              -0.07337070256471634,
              0.07970509678125381,
              -0.079134002327919,
              0,
              0.07970509678125381,
              -0.07913409918546677,
              0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              0.06974250078201294,
              0.1118210032582283,
              -0.0773480013012886,
              0.07330299913883209,
              0.0820946991443634,
              -0.08680140227079391,
              0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              0.07026749849319458,
              0.10950300097465515,
              -0.06993900239467621,
              0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              0.07021220028400421,
              0.10944800078868866,
              -0.06976290047168732,
              0.07377240061759949,
              0.07972340285778046,
              -0.07919289916753769,
              0.07382780313491821,
              0.07977840304374695,
              -0.07936909794807434,
              0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              0.06993419677019119,
              0.11180199682712555,
              -0.0772894024848938,
              0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              0.07349599897861481,
              0.08207610249519348,
              -0.08674269914627075,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              0.07002849876880646,
              0.1094290018081665,
              -0.0697041004896164,
              0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              0.06981050223112106,
              0.1094290018081665,
              -0.0697041004896164,
              0.07337070256471634,
              0.07970509678125381,
              -0.07913409918546677,
              0.07358869910240173,
              0.07970509678125381,
              -0.07913409918546677,
              0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              0.07025980204343796,
              0.10956700146198273,
              -0.07014569640159607,
              0.073820099234581,
              0.07984279841184616,
              -0.07957590371370316,
              0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              0,
              0.1118210032582283,
              -0.0773480013012886,
              0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              0.06952480226755142,
              0.1118210032582283,
              -0.0773480013012886,
              0.07308530062437057,
              0.0820946991443634,
              -0.08680140227079391,
              0,
              0.0820946991443634,
              -0.08680129796266556,
              0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              0.07001510262489319,
              0.11174599826335907,
              -0.07711350172758102,
              0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              0.07002429664134979,
              0.1116809993982315,
              -0.07690709829330444,
              0.0735907033085823,
              0.0819552019238472,
              -0.08636049926280975,
              0.07358100265264511,
              0.08202049881219864,
              -0.08656690269708633,
              -0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              -0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              -0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              -0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              -0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              -0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              -0.06971269845962524,
              0.1118210032582283,
              -0.07734789699316025,
              -0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              -0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              -0.06945569813251495,
              0.1118210032582283,
              -0.07734789699316025,
              -0.07299309968948364,
              0.0820946991443634,
              -0.08680129796266556,
              -0.07326319813728333,
              0.0820946991443634,
              -0.08680129796266556,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              0,
              0.19860200583934784,
              -0.04141410067677498,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              0,
              0.19860200583934784,
              -0.04141410067677498,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0,
              0.200997993350029,
              -0.04898789897561073,
              -0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              0,
              0.200997993350029,
              -0.04898789897561073,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              -0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              -0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              -0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              -0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              -0.059248898178339005,
              0.20097999274730682,
              -0.04892919957637787,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              -0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              -0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              -0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              -0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              -0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              -0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              -0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              -0.059531401842832565,
              0.19862000644207,
              -0.041472699493169785,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              -0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              -0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              -0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              -0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              -0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              -0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              -0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              -0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              -0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              -0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              -0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              -0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              -0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              -0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              -0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              -0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              -0.059531401842832565,
              0.19862000644207,
              -0.041472699493169785,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              -0.059531401842832565,
              0.19862000644207,
              -0.041472699493169785,
              -0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              -0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              -0.0630917027592659,
              0.16889600455760956,
              -0.05090269818902016,
              -0.06665190309286118,
              0.1391720026731491,
              -0.06033270061016083,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              -0.059586599469184875,
              0.1986760050058365,
              -0.04164860025048256,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              -0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              -0.06314689666032791,
              0.16895100474357605,
              -0.05107869952917099,
              -0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              -0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.06670720130205154,
              0.1392270028591156,
              -0.06050879880785942,
              -0.05931739881634712,
              0.20092399418354034,
              -0.048753298819065094,
              -0.059248898178339005,
              0.20097999274730682,
              -0.04892919957637787,
              -0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              -0.059248898178339005,
              0.20097999274730682,
              -0.04892919957637787,
              -0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              -0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              -0.06287779659032822,
              0.17119799554347992,
              -0.058206599205732346,
              -0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              -0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              -0.06280940026044846,
              0.1712539941072464,
              -0.05838260054588318,
              -0.06636980175971985,
              0.14152799546718597,
              -0.06783589720726013,
              -0.06643830239772797,
              0.14147299528121948,
              -0.06765980273485184,
              -0.059578798711299896,
              0.19874100387096405,
              -0.04185489937663078,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              -0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              -0.0593251995742321,
              0.20085899531841278,
              -0.04854699969291687,
              -0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              -0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              -0.06313920021057129,
              0.1690160036087036,
              -0.05128519982099533,
              -0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.0628855973482132,
              0.17113299667835236,
              -0.058000098913908005,
              -0.06644610315561295,
              0.14140799641609192,
              -0.0674533024430275,
              -0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              -0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              -0.05934799835085869,
              0.19860200583934784,
              -0.04141410067677498,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              -0.06290820240974426,
              0.1688770055770874,
              -0.05084399878978729,
              -0.06646840274333954,
              0.13915300369262695,
              -0.06027400121092796,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              0,
              0.19860200583934784,
              -0.04141410067677498,
              -0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              -0.05913019925355911,
              0.19860200583934784,
              -0.04141410067677498,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              0,
              0.1688770055770874,
              -0.05084409937262535,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              -0.06269030272960663,
              0.1688770055770874,
              -0.05084409937262535,
              -0.06625039875507355,
              0.13915300369262695,
              -0.06027410179376602,
              0,
              0.13915300369262695,
              -0.06027410179376602,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.059248898178339005,
              0.20097999274730682,
              -0.04892930015921593,
              0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              0.059248898178339005,
              0.20097999274730682,
              -0.04892930015921593,
              0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              0.0626216009259224,
              0.17127199470996857,
              -0.05844129994511604,
              0.06618200242519379,
              0.14154599606990814,
              -0.06789469718933105,
              0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.059531401842832565,
              0.19862000644207,
              -0.04147280007600784,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              0.059586599469184875,
              0.1986760050058365,
              -0.04164870083332062,
              0.059531401842832565,
              0.19862000644207,
              -0.04147280007600784,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              0.059531401842832565,
              0.19862000644207,
              -0.04147280007600784,
              0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              0.06314689666032791,
              0.16895100474357605,
              -0.05107880011200905,
              0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              0.0630917027592659,
              0.16889600455760956,
              -0.050902798771858215,
              0.06665190309286118,
              0.1391720026731491,
              -0.060332801192998886,
              0.06670720130205154,
              0.1392270028591156,
              -0.06050889939069748,
              0.059248898178339005,
              0.20097999274730682,
              -0.04892930015921593,
              0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              0.06281069666147232,
              0.1712539941072464,
              -0.05838270112872124,
              0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              0.06637240201234818,
              0.14152799546718597,
              -0.06783600151538849,
              0.05934799835085869,
              0.19860200583934784,
              -0.04141420125961304,
              0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              0.059130098670721054,
              0.19860200583934784,
              -0.04141420125961304,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              0.06290820240974426,
              0.1688770055770874,
              -0.05084409937262535,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              0.06269030272960663,
              0.1688770055770874,
              -0.05084419995546341,
              0.06625039875507355,
              0.13915300369262695,
              -0.06027419865131378,
              0.06646840274333954,
              0.13915300369262695,
              -0.06027410179376602,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              0.059578798711299896,
              0.19874100387096405,
              -0.04185499995946884,
              0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              0.06313910335302353,
              0.1690170019865036,
              -0.05128519982099533,
              0.06669949740171432,
              0.13929200172424316,
              -0.06071539968252182,
              0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              0,
              0.200997993350029,
              -0.04898789897561073,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              0,
              0.17127199470996857,
              -0.058441199362277985,
              0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              0.06240389868617058,
              0.17127199470996857,
              -0.05844129994511604,
              0.06596429646015167,
              0.14154599606990814,
              -0.06789469718933105,
              0,
              0.14154599606990814,
              -0.0678946003317833,
              0.05931739881634712,
              0.20092399418354034,
              -0.04875339940190315,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              0.0593251995742321,
              0.20085899531841278,
              -0.04854710027575493,
              0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              0.06288330256938934,
              0.17119799554347992,
              -0.05820680037140846,
              0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              0.06289160251617432,
              0.17113299667835236,
              -0.05800040066242218,
              0.06645789742469788,
              0.14140699803829193,
              -0.06745380163192749,
              0.06644920259714127,
              0.1414719969034195,
              -0.06766010075807571,
              -0.05906109884381294,
              0.200997993350029,
              -0.04898789897561073,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              -0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              -0.058843400329351425,
              0.200997993350029,
              -0.04898789897561073,
              -0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              -0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              -0.06261160224676132,
              0.17127199470996857,
              -0.058441199362277985,
              -0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              -0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833,
              -0.062380898743867874,
              0.17127199470996857,
              -0.058441199362277985,
              -0.06591829657554626,
              0.14154599606990814,
              -0.0678946003317833,
              -0.0661621019244194,
              0.14154599606990814,
              -0.0678946003317833
            ],
            "normalized": false
          },
          "normal": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              -0.00042725898674689233,
              0.5188149809837341,
              -0.8548539876937866,
              -0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              -0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              -0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              -0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              -0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              -0.00018311099847778678,
              -0.8526570200920105,
              -0.5224159955978394,
              -0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              -0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              -0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              -0.005706959869712591,
              -0.43852001428604126,
              -0.8986790180206299,
              -0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              -0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              -0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              -0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              -0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              -0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              -0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              -0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              -0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              -0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              -0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              -0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              -0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              -0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              -0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              -0.25232699513435364,
              -0.18460600078105927,
              -0.9498580098152161,
              -0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              -0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              -0.25232699513435364,
              -0.18460600078105927,
              -0.9498580098152161,
              -0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              -0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              -0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              -0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              -0.25232699513435364,
              -0.18460600078105927,
              -0.9498580098152161,
              -0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              -0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              -0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              -0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              -0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              -0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              -0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              -0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              -0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              -0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              -0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              -0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              -0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              -0.106113001704216,
              -0.2722249925136566,
              -0.9563590288162231,
              -0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              -0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              -0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              -0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              -0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.01922670006752014,
              0.020630499348044395,
              -0.9995729923248291,
              -0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              -0.01922670006752014,
              0.020630499348044395,
              -0.9995729923248291,
              -0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              -0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              -0.01922670006752014,
              -0.3935669958591461,
              -0.919064998626709,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              -0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              -0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              -0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              -0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              -0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              -0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              -0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              -0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              -0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              -0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              -0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              -0.18601000308990479,
              -0.24811500310897827,
              -0.9506819844245911,
              -0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              -0.01922670006752014,
              0.020630499348044395,
              -0.9995729923248291,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              -0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.01922670006752014,
              -0.3935669958591461,
              -0.919064998626709,
              -0.005706959869712591,
              -0.43852001428604126,
              -0.8986790180206299,
              -0.01922670006752014,
              -0.3935669958591461,
              -0.919064998626709,
              -0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              -0.005706959869712591,
              -0.43852001428604126,
              -0.8986790180206299,
              -0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              -0.7224649786949158,
              -0.13190099596977234,
              -0.6786710023880005,
              -0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              -0.7224649786949158,
              -0.13190099596977234,
              -0.6786710023880005,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              -0.7224649786949158,
              -0.13190099596977234,
              -0.6786710023880005,
              -0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              -0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              -0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              -0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              -0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              -0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              -0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              -0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              -0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              -0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              -0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              -0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              -0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              -0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              -0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              -0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              -0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              -0.3168739974498749,
              -0.18295800685882568,
              -0.9306309819221497,
              -0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              -0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              -0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              -0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              -0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              -0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              -0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              -0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              -0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              -0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              -0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              -0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              -0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              -0.7115089893341064,
              -0.13455599546432495,
              -0.6896269917488098,
              -0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              -0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              -0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              -0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              -0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              -0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              -0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              -0.21042500436306,
              -0.17407800257205963,
              -0.961974024772644,
              -0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              -0.6839810013771057,
              -0.13846200704574585,
              -0.7162079811096191,
              -0.21042500436306,
              -0.17407800257205963,
              -0.961974024772644,
              -0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              -0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              -0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              -0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              -0.20895999670028687,
              -0.19898100197315216,
              -0.9574570059776306,
              -0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              0,
              0.5444499850273132,
              -0.8387709856033325,
              0,
              0.08221690356731415,
              -0.996612012386322,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              0,
              0.08221690356731415,
              -0.996612012386322,
              -0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              0,
              -0.5172280073165894,
              -0.8558310270309448,
              0,
              -0.8609269857406616,
              -0.5086820125579834,
              -0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              0,
              -0.8609269857406616,
              -0.5086820125579834,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              -0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              -0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              -0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              -0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              -0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              -0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              -0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              -0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              -0.00018311099847778678,
              -0.8526570200920105,
              -0.5224159955978394,
              -0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              -0.009521770291030407,
              -0.018067000433802605,
              -0.9997860193252563,
              -0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              -0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              -0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              -0.00042725898674689233,
              0.5188149809837341,
              -0.8548539876937866,
              -0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              -0.005706959869712591,
              -0.43852001428604126,
              -0.8986790180206299,
              -0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              -0.011719100177288055,
              -0.41059601306915283,
              -0.9117100238800049,
              -0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              -0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              -0.011719100177288055,
              -0.41059601306915283,
              -0.9117100238800049,
              -0.03375349938869476,
              -0.02539139986038208,
              -0.9990839958190918,
              -0.13104599714279175,
              0.510574996471405,
              -0.8497570157051086,
              -0.009521770291030407,
              -0.018067000433802605,
              -0.9997860193252563,
              -0.13104599714279175,
              0.510574996471405,
              -0.8497570157051086,
              -0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              -0.009521770291030407,
              -0.018067000433802605,
              -0.9997860193252563,
              -0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              -0.04257329925894737,
              -0.40073901414871216,
              -0.9151890277862549,
              -0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              -0.04257329925894737,
              -0.40073901414871216,
              -0.9151890277862549,
              -0.011719100177288055,
              -0.41059601306915283,
              -0.9117100238800049,
              -0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              -0.06430249661207199,
              -0.0378430001437664,
              -0.9971920251846313,
              -0.25666099786758423,
              0.46937501430511475,
              -0.8448439836502075,
              -0.03375349938869476,
              -0.02539139986038208,
              -0.9990839958190918,
              -0.25666099786758423,
              0.46937501430511475,
              -0.8448439836502075,
              -0.13104599714279175,
              0.510574996471405,
              -0.8497570157051086,
              -0.03375349938869476,
              -0.02539139986038208,
              -0.9990839958190918,
              -0.2700279951095581,
              -0.7733700275421143,
              -0.5735340118408203,
              -0.08078250288963318,
              -0.3808099925518036,
              -0.9210789799690247,
              -0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              -0.08078250288963318,
              -0.3808099925518036,
              -0.9210789799690247,
              -0.04257329925894737,
              -0.40073901414871216,
              -0.9151890277862549,
              -0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              -0.09265419840812683,
              -0.054628100246191025,
              -0.9941710233688354,
              -0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              -0.06430249661207199,
              -0.0378430001437664,
              -0.9971920251846313,
              -0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              -0.25666099786758423,
              0.46937501430511475,
              -0.8448439836502075,
              -0.06430249661207199,
              -0.0378430001437664,
              -0.9971920251846313,
              -0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              -0.11359000205993652,
              -0.35593700408935547,
              -0.9275490045547485,
              -0.2700279951095581,
              -0.7733700275421143,
              -0.5735340118408203,
              -0.11359000205993652,
              -0.35593700408935547,
              -0.9275490045547485,
              -0.08078250288963318,
              -0.3808099925518036,
              -0.9210789799690247,
              -0.2700279951095581,
              -0.7733700275421143,
              -0.5735340118408203,
              -0.11758799850940704,
              -0.07510609924793243,
              -0.9902039766311646,
              -0.4738300144672394,
              0.32419800758361816,
              -0.8187199831008911,
              -0.09265419840812683,
              -0.054628100246191025,
              -0.9941710233688354,
              -0.4738300144672394,
              0.32419800758361816,
              -0.8187199831008911,
              -0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              -0.09265419840812683,
              -0.054628100246191025,
              -0.9941710233688354,
              -0.4917449951171875,
              -0.6181219816207886,
              -0.6132389903068542,
              -0.14072099328041077,
              -0.3272800147533417,
              -0.9343550205230713,
              -0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              -0.14072099328041077,
              -0.3272800147533417,
              -0.9343550205230713,
              -0.11359000205993652,
              -0.35593700408935547,
              -0.9275490045547485,
              -0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              -0.13965299725532532,
              -0.09790340065956116,
              -0.9853209853172302,
              -0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              -0.11758799850940704,
              -0.07510609924793243,
              -0.9902039766311646,
              -0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              -0.4738300144672394,
              0.32419800758361816,
              -0.8187199831008911,
              -0.11758799850940704,
              -0.07510609924793243,
              -0.9902039766311646,
              -0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              -0.16245000064373016,
              -0.29545000195503235,
              -0.9414349794387817,
              -0.4917449951171875,
              -0.6181219816207886,
              -0.6132389903068542,
              -0.16245000064373016,
              -0.29545000195503235,
              -0.9414349794387817,
              -0.14072099328041077,
              -0.3272800147533417,
              -0.9343550205230713,
              -0.4917449951171875,
              -0.6181219816207886,
              -0.6132389903068542,
              -0.16049699485301971,
              -0.12329500168561935,
              -0.979278028011322,
              -0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              -0.13965299725532532,
              -0.09790340065956116,
              -0.9853209853172302,
              -0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              -0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              -0.13965299725532532,
              -0.09790340065956116,
              -0.9853209853172302,
              -0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              -0.17841100692749023,
              -0.2613610029220581,
              -0.9485759735107422,
              -0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              -0.17841100692749023,
              -0.2613610029220581,
              -0.9485759735107422,
              -0.16245000064373016,
              -0.29545000195503235,
              -0.9414349794387817,
              -0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              -0.179967999458313,
              -0.1530809998512268,
              -0.9716789722442627,
              -0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              -0.16049699485301971,
              -0.12329500168561935,
              -0.979278028011322,
              -0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              -0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              -0.16049699485301971,
              -0.12329500168561935,
              -0.979278028011322,
              -0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              -0.18903200328350067,
              -0.22617299854755402,
              -0.9555349946022034,
              -0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              -0.18903200328350067,
              -0.22617299854755402,
              -0.9555349946022034,
              -0.17841100692749023,
              -0.2613610029220581,
              -0.9485759735107422,
              -0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              -0.19238899648189545,
              -0.177374005317688,
              -0.9651479721069336,
              -0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              -0.179967999458313,
              -0.1530809998512268,
              -0.9716789722442627,
              -0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              -0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              -0.179967999458313,
              -0.1530809998512268,
              -0.9716789722442627,
              -0.6863309741020203,
              -0.17108699679374695,
              -0.7068390250205994,
              -0.1943420022726059,
              -0.1986750066280365,
              -0.9605699777603149,
              -0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              -0.1943420022726059,
              -0.1986750066280365,
              -0.9605699777603149,
              -0.18903200328350067,
              -0.22617299854755402,
              -0.9555349946022034,
              -0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              -0.21042500436306,
              -0.17407800257205963,
              -0.961974024772644,
              -0.6839810013771057,
              -0.13846200704574585,
              -0.7162079811096191,
              -0.19238899648189545,
              -0.177374005317688,
              -0.9651479721069336,
              -0.6839810013771057,
              -0.13846200704574585,
              -0.7162079811096191,
              -0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              -0.19238899648189545,
              -0.177374005317688,
              -0.9651479721069336,
              -0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              -0.20895999670028687,
              -0.19898100197315216,
              -0.9574570059776306,
              -0.6863309741020203,
              -0.17108699679374695,
              -0.7068390250205994,
              -0.20895999670028687,
              -0.19898100197315216,
              -0.9574570059776306,
              -0.1943420022726059,
              -0.1986750066280365,
              -0.9605699777603149,
              -0.6863309741020203,
              -0.17108699679374695,
              -0.7068390250205994,
              -0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              0,
              -0.115665003657341,
              -0.9932550191879272,
              -0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              0,
              -0.115665003657341,
              -0.9932550191879272,
              0,
              -0.16272500157356262,
              -0.9866629838943481,
              -0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              0,
              -0.2186959981918335,
              -0.9757680296897888,
              0,
              -0.2648090124130249,
              -0.9642930030822754,
              -0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              0,
              -0.2648090124130249,
              -0.9642930030822754,
              -0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              -0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              -0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              0,
              -0.16272500157356262,
              -0.9866629838943481,
              -0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              0,
              -0.16272500157356262,
              -0.9866629838943481,
              0,
              -0.19080199301242828,
              -0.9815970063209534,
              -0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              0,
              -0.19080199301242828,
              -0.9815970063209534,
              0,
              -0.2186959981918335,
              -0.9757680296897888,
              -0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              0,
              -0.2186959981918335,
              -0.9757680296897888,
              -0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              -0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              -0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              -0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              -0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              -0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              -0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              -0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              -0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              -0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              -0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              -0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              -0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              -0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              -0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              -0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              -0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              -0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              -0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              -0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              -0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              -0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              -0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              -0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              -0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              -0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              -0.25232699513435364,
              -0.18460600078105927,
              -0.9498580098152161,
              -0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              -0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              -0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              -0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              -0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              -0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              -0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              -0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              -0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              -0.25232699513435364,
              -0.18460600078105927,
              -0.9498580098152161,
              -0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              -0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              -0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              -0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              -0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              -0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              -0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              -0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              -0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              -0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              -0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              -0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              -0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              -0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              0,
              0.015930699184536934,
              -0.9998469948768616,
              -0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              0,
              0.015930699184536934,
              -0.9998469948768616,
              0,
              -0.04147469997406006,
              -0.999114990234375,
              -0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              -0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              0,
              -0.04147469997406006,
              -0.999114990234375,
              -0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              0,
              -0.04147469997406006,
              -0.999114990234375,
              0,
              -0.115665003657341,
              -0.9932550191879272,
              -0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              0,
              -0.2648090124130249,
              -0.9642930030822754,
              0,
              -0.33579498529434204,
              -0.9419230222702026,
              -0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              0,
              -0.33579498529434204,
              -0.9419230222702026,
              -0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              -0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              -0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              -0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              -0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              -0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              -0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              -0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              -0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              -0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              -0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              -0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              -0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              -0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              -0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              -0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              -0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              -0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              -0.106113001704216,
              -0.2722249925136566,
              -0.9563590288162231,
              -0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              -0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              -0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              -0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              -0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              -0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              -0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              -0.3168739974498749,
              -0.18295800685882568,
              -0.9306309819221497,
              -0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              -0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              -0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              -0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              -0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              -0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              -0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              -0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              -0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              -0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              -0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              -0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              0,
              0.05935849994421005,
              -0.99822998046875,
              -0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              0,
              0.05935849994421005,
              -0.99822998046875,
              0,
              0.029664000496268272,
              -0.9995419979095459,
              -0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              0,
              -0.40192899107933044,
              -0.9156469702720642,
              0,
              -0.42896801233291626,
              -0.9032869935035706,
              -0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              0,
              -0.42896801233291626,
              -0.9032869935035706,
              -0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              -0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              -0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              0,
              0.029664000496268272,
              -0.9995419979095459,
              -0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              0,
              0.029664000496268272,
              -0.9995419979095459,
              0,
              0.015930699184536934,
              -0.9998469948768616,
              -0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              0,
              -0.38932499289512634,
              -0.9210789799690247,
              0,
              -0.40192899107933044,
              -0.9156469702720642,
              -0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              0,
              -0.40192899107933044,
              -0.9156469702720642,
              -0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              -0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              -0.01922670006752014,
              0.020630499348044395,
              -0.9995729923248291,
              -0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              -0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              -0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              -0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              -0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              -0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              -0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              -0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              -0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              -0.01922670006752014,
              -0.3935669958591461,
              -0.919064998626709,
              -0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              -0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              -0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              -0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              -0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              -0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              -0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              -0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              -0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              -0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              -0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              -0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              -0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              -0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              -0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              -0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              -0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              -0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              -0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              -0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              -0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              -0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              -0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              -0.18601000308990479,
              -0.24811500310897827,
              -0.9506819844245911,
              -0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              -0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              -0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              -0.21042500436306,
              -0.17407800257205963,
              -0.961974024772644,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.20895999670028687,
              -0.19898100197315216,
              -0.9574570059776306,
              -0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              -0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0,
              0.08221690356731415,
              -0.996612012386322,
              0,
              0.05935849994421005,
              -0.99822998046875,
              -0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              0,
              0.05935849994421005,
              -0.99822998046875,
              -0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              -0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              -0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              0,
              -0.42896801233291626,
              -0.9032869935035706,
              -0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              0,
              -0.42896801233291626,
              -0.9032869935035706,
              0,
              -0.5172280073165894,
              -0.8558310270309448,
              -0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              -0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              -0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              -0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              -0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              -0.01922670006752014,
              0.020630499348044395,
              -0.9995729923248291,
              -0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              -0.01922670006752014,
              -0.3935669958591461,
              -0.919064998626709,
              -0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              -0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              -0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              -0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              -0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.009521770291030407,
              -0.018067000433802605,
              -0.9997860193252563,
              -0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              -0.005706959869712591,
              -0.43852001428604126,
              -0.8986790180206299,
              -0.011719100177288055,
              -0.41059601306915283,
              -0.9117100238800049,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.03375349938869476,
              -0.02539139986038208,
              -0.9990839958190918,
              -0.009521770291030407,
              -0.018067000433802605,
              -0.9997860193252563,
              -0.011719100177288055,
              -0.41059601306915283,
              -0.9117100238800049,
              -0.04257329925894737,
              -0.40073901414871216,
              -0.9151890277862549,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.06430249661207199,
              -0.0378430001437664,
              -0.9971920251846313,
              -0.03375349938869476,
              -0.02539139986038208,
              -0.9990839958190918,
              -0.04257329925894737,
              -0.40073901414871216,
              -0.9151890277862549,
              -0.08078250288963318,
              -0.3808099925518036,
              -0.9210789799690247,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.09265419840812683,
              -0.054628100246191025,
              -0.9941710233688354,
              -0.06430249661207199,
              -0.0378430001437664,
              -0.9971920251846313,
              -0.08078250288963318,
              -0.3808099925518036,
              -0.9210789799690247,
              -0.11359000205993652,
              -0.35593700408935547,
              -0.9275490045547485,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.11758799850940704,
              -0.07510609924793243,
              -0.9902039766311646,
              -0.09265419840812683,
              -0.054628100246191025,
              -0.9941710233688354,
              -0.11359000205993652,
              -0.35593700408935547,
              -0.9275490045547485,
              -0.14072099328041077,
              -0.3272800147533417,
              -0.9343550205230713,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.13965299725532532,
              -0.09790340065956116,
              -0.9853209853172302,
              -0.11758799850940704,
              -0.07510609924793243,
              -0.9902039766311646,
              -0.14072099328041077,
              -0.3272800147533417,
              -0.9343550205230713,
              -0.16245000064373016,
              -0.29545000195503235,
              -0.9414349794387817,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.16049699485301971,
              -0.12329500168561935,
              -0.979278028011322,
              -0.13965299725532532,
              -0.09790340065956116,
              -0.9853209853172302,
              -0.16245000064373016,
              -0.29545000195503235,
              -0.9414349794387817,
              -0.17841100692749023,
              -0.2613610029220581,
              -0.9485759735107422,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.179967999458313,
              -0.1530809998512268,
              -0.9716789722442627,
              -0.16049699485301971,
              -0.12329500168561935,
              -0.979278028011322,
              -0.17841100692749023,
              -0.2613610029220581,
              -0.9485759735107422,
              -0.18903200328350067,
              -0.22617299854755402,
              -0.9555349946022034,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.19238899648189545,
              -0.177374005317688,
              -0.9651479721069336,
              -0.179967999458313,
              -0.1530809998512268,
              -0.9716789722442627,
              -0.18903200328350067,
              -0.22617299854755402,
              -0.9555349946022034,
              -0.1943420022726059,
              -0.1986750066280365,
              -0.9605699777603149,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              -0.21042500436306,
              -0.17407800257205963,
              -0.961974024772644,
              -0.19238899648189545,
              -0.177374005317688,
              -0.9651479721069336,
              -0.1943420022726059,
              -0.1986750066280365,
              -0.9605699777603149,
              -0.20895999670028687,
              -0.19898100197315216,
              -0.9574570059776306,
              -0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              -0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              -0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.24240900576114655,
              0.19632600247859955,
              0.9500719904899597,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.24240900576114655,
              0.19632600247859955,
              0.9500719904899597,
              -0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.16113799810409546,
              -0.6795250177383423,
              0.715690016746521,
              -0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              -0.3137910068035126,
              -0.6317939758300781,
              0.708761990070343,
              -0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              -0.08990749716758728,
              -0.029419800266623497,
              0.9955139756202698,
              -0.3137910068035126,
              -0.6317939758300781,
              0.708761990070343,
              -0.08990749716758728,
              0.40022000670433044,
              0.9119849801063538,
              -0.04605240002274513,
              0.4169740080833435,
              0.9077119827270508,
              -0.3137910068035126,
              0.8512830138206482,
              0.4205150008201599,
              -0.04605240002274513,
              0.4169740080833435,
              0.9077119827270508,
              -0.16113799810409546,
              0.8981599807739258,
              0.40904000401496887,
              -0.3137910068035126,
              0.8512830138206482,
              0.4205150008201599,
              -0.6790980100631714,
              -0.33591699600219727,
              0.6526380181312561,
              -0.5775319933891296,
              -0.45680099725723267,
              0.6765649914741516,
              -0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              -0.5775319933891296,
              -0.45680099725723267,
              0.6765649914741516,
              -0.16510500013828278,
              0.023682400584220886,
              0.9859610199928284,
              -0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              -0.16504399478435516,
              0.34730100631713867,
              0.9230930209159851,
              -0.5774710178375244,
              0.6769620180130005,
              0.45625200867652893,
              -0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              -0.5774710178375244,
              0.6769620180130005,
              0.45625200867652893,
              -0.6790980100631714,
              0.5559250116348267,
              0.47929298877716064,
              -0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              -0.04065069928765297,
              -0.6891999840736389,
              0.7234110236167908,
              0,
              -0.683309018611908,
              0.7300940155982971,
              -0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.05215609818696976,
              0.9986270070075989,
              -0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              0,
              0.422529011964798,
              0.9063389897346497,
              0,
              0.9070709943771362,
              0.4209420084953308,
              -0.011566500179469585,
              0.4231700003147125,
              0.9059720039367676,
              0,
              0.9070709943771362,
              0.4209420084953308,
              -0.0406200997531414,
              0.9100009799003601,
              0.4125800132751465,
              -0.011566500179469585,
              0.4231700003147125,
              0.9059720039367676,
              -0.6790980100631714,
              -0.33591699600219727,
              0.6526380181312561,
              -0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              -0.7553939819335938,
              -0.1982789933681488,
              0.6244999766349792,
              -0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              -0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              -0.7553939819335938,
              -0.1982789933681488,
              0.6244999766349792,
              -0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              -0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              -0.755424976348877,
              0.4177980124950409,
              0.5047460198402405,
              -0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              -0.6790980100631714,
              0.5559250116348267,
              0.47929298877716064,
              -0.755424976348877,
              0.4177980124950409,
              0.5047460198402405,
              -0.16113799810409546,
              -0.6795250177383423,
              0.715690016746521,
              -0.04065069928765297,
              -0.6891999840736389,
              0.7234110236167908,
              -0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              -0.04065069928765297,
              -0.6891999840736389,
              0.7234110236167908,
              -0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              -0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              -0.011566500179469585,
              0.4231700003147125,
              0.9059720039367676,
              -0.0406200997531414,
              0.9100009799003601,
              0.4125800132751465,
              -0.04605240002274513,
              0.4169740080833435,
              0.9077119827270508,
              -0.0406200997531414,
              0.9100009799003601,
              0.4125800132751465,
              -0.16113799810409546,
              0.8981599807739258,
              0.40904000401496887,
              -0.04605240002274513,
              0.4169740080833435,
              0.9077119827270508,
              -0.7553939819335938,
              -0.1982789933681488,
              0.6244999766349792,
              -0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              -0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              -0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              -0.23615199327468872,
              0.13986599445343018,
              0.9615769982337952,
              -0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              -0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              -0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              -0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              -0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              -0.755424976348877,
              0.4177980124950409,
              0.5047460198402405,
              -0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              -0.4542680084705353,
              -0.5566269755363464,
              0.6955469846725464,
              -0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              -0.5775319933891296,
              -0.45680099725723267,
              0.6765649914741516,
              -0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              -0.16510500013828278,
              0.023682400584220886,
              0.9859610199928284,
              -0.5775319933891296,
              -0.45680099725723267,
              0.6765649914741516,
              -0.16504399478435516,
              0.34730100631713867,
              0.9230930209159851,
              -0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              -0.5774710178375244,
              0.6769620180130005,
              0.45625200867652893,
              -0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              -0.4542370140552521,
              0.7766349911689758,
              0.4364449977874756,
              -0.5774710178375244,
              0.6769620180130005,
              0.45625200867652893,
              -0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              -0.23615199327468872,
              0.13986599445343018,
              0.9615769982337952,
              -0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              -0.23615199327468872,
              0.13986599445343018,
              0.9615769982337952,
              -0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              -0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              -0.24240900576114655,
              0.19632600247859955,
              0.9500719904899597,
              -0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              -0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              -0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              -0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              -0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              -0.4542680084705353,
              -0.5566269755363464,
              0.6955469846725464,
              -0.3137910068035126,
              -0.6317939758300781,
              0.708761990070343,
              -0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              -0.3137910068035126,
              -0.6317939758300781,
              0.708761990070343,
              -0.08990749716758728,
              -0.029419800266623497,
              0.9955139756202698,
              -0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              -0.08990749716758728,
              0.40022000670433044,
              0.9119849801063538,
              -0.3137910068035126,
              0.8512830138206482,
              0.4205150008201599,
              -0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              -0.3137910068035126,
              0.8512830138206482,
              0.4205150008201599,
              -0.4542370140552521,
              0.7766349911689758,
              0.4364449977874756,
              -0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              0,
              -0.05215609818696976,
              0.9986270070075989,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.05203409865498543,
              0.9986270070075989,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.05203409865498543,
              0.9986270070075989,
              0,
              0.9070709943771362,
              0.4209110140800476,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0,
              0.42240700125694275,
              0.9064000248908997,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0,
              0.422529011964798,
              0.9063389897346497,
              0,
              0.42240700125694275,
              0.9064000248908997,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              -0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              -0.24240900576114655,
              0.19632600247859955,
              0.9500719904899597,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.16895000636577606,
              0.19598999619483948,
              0.9659109711647034,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              -0.16895000636577606,
              0.19598999619483948,
              0.9659109711647034,
              -0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              -0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              -0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              -0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              -0.23615199327468872,
              0.13986599445343018,
              0.9615769982337952,
              -0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              -0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              -0.24240900576114655,
              0.19632600247859955,
              0.9500719904899597,
              -0.164342001080513,
              0.21884800493717194,
              0.9617909789085388,
              -0.24240900576114655,
              0.19632600247859955,
              0.9500719904899597,
              -0.16895000636577606,
              0.19598999619483948,
              0.9659109711647034,
              -0.164342001080513,
              0.21884800493717194,
              0.9617909789085388,
              -0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              -0.23615199327468872,
              0.13986599445343018,
              0.9615769982337952,
              -0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              -0.23615199327468872,
              0.13986599445343018,
              0.9615769982337952,
              -0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              -0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              -0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              -0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              -0.15286700427532196,
              0.24948899447917938,
              0.9562060236930847,
              -0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              -0.164342001080513,
              0.21884800493717194,
              0.9617909789085388,
              -0.15286700427532196,
              0.24948899447917938,
              0.9562060236930847,
              -0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              -0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              -0.07708980143070221,
              0.13974399864673615,
              0.9871519804000854,
              -0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              -0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              -0.07708980143070221,
              0.13974399864673615,
              0.9871519804000854,
              -0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              -0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              -0.1365090012550354,
              0.27713900804519653,
              0.9510480165481567,
              -0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              -0.15286700427532196,
              0.24948899447917938,
              0.9562060236930847,
              -0.1365090012550354,
              0.27713900804519653,
              0.9510480165481567,
              -0.07708980143070221,
              0.13974399864673615,
              0.9871519804000854,
              -0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              -0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              -0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              -0.16510500013828278,
              0.023682400584220886,
              0.9859610199928284,
              -0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              -0.16504399478435516,
              0.34730100631713867,
              0.9230930209159851,
              -0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              -0.11532899737358093,
              0.30198100209236145,
              0.9462869763374329,
              -0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              -0.1365090012550354,
              0.27713900804519653,
              0.9510480165481567,
              -0.11532899737358093,
              0.30198100209236145,
              0.9462869763374329,
              -0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              -0.16510500013828278,
              0.023682400584220886,
              0.9859610199928284,
              -0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              -0.16510500013828278,
              0.023682400584220886,
              0.9859610199928284,
              -0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              -0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              -0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              -0.16504399478435516,
              0.34730100631713867,
              0.9230930209159851,
              -0.09070099890232086,
              0.3226720094680786,
              0.9421370029449463,
              -0.16504399478435516,
              0.34730100631713867,
              0.9230930209159851,
              -0.11532899737358093,
              0.30198100209236145,
              0.9462869763374329,
              -0.09070099890232086,
              0.3226720094680786,
              0.9421370029449463,
              -0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              -0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              -0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              -0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              -0.08990749716758728,
              -0.029419800266623497,
              0.9955139756202698,
              -0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              -0.08990749716758728,
              0.40022000670433044,
              0.9119849801063538,
              -0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              -0.06274610012769699,
              0.33817601203918457,
              0.938962996006012,
              -0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              -0.09070099890232086,
              0.3226720094680786,
              0.9421370029449463,
              -0.06274610012769699,
              0.33817601203918457,
              0.938962996006012,
              -0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              -0.08990749716758728,
              -0.029419800266623497,
              0.9955139756202698,
              -0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              -0.08990749716758728,
              -0.029419800266623497,
              0.9955139756202698,
              -0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              -0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              -0.04605240002274513,
              0.4169740080833435,
              0.9077119827270508,
              -0.08990749716758728,
              0.40022000670433044,
              0.9119849801063538,
              -0.031495101749897,
              0.3491320013999939,
              0.9365209937095642,
              -0.08990749716758728,
              0.40022000670433044,
              0.9119849801063538,
              -0.06274610012769699,
              0.33817601203918457,
              0.938962996006012,
              -0.031495101749897,
              0.3491320013999939,
              0.9365209937095642,
              -0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              -0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              -0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              -0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              -0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              -0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              -0.011566500179469585,
              0.4231700003147125,
              0.9059720039367676,
              -0.04605240002274513,
              0.4169740080833435,
              0.9077119827270508,
              -0.007782219909131527,
              0.35410600900650024,
              0.9351480007171631,
              -0.04605240002274513,
              0.4169740080833435,
              0.9077119827270508,
              -0.031495101749897,
              0.3491320013999939,
              0.9365209937095642,
              -0.007782219909131527,
              0.35410600900650024,
              0.9351480007171631,
              -0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              -0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              0,
              0.10062000155448914,
              0.994903028011322,
              -0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              0,
              -0.05215609818696976,
              0.9986270070075989,
              0,
              0.10062000155448914,
              0.994903028011322,
              0,
              0.422529011964798,
              0.9063389897346497,
              -0.011566500179469585,
              0.4231700003147125,
              0.9059720039367676,
              0,
              0.3546859920024872,
              0.9349650144577026,
              -0.011566500179469585,
              0.4231700003147125,
              0.9059720039367676,
              -0.007782219909131527,
              0.35410600900650024,
              0.9351480007171631,
              0,
              0.3546859920024872,
              0.9349650144577026,
              0,
              0.10062000155448914,
              0.994903028011322,
              0,
              -0.05215609818696976,
              0.9986270070075989,
              0,
              0.10074199736118317,
              0.994903028011322,
              0,
              -0.05215609818696976,
              0.9986270070075989,
              0,
              -0.05203409865498543,
              0.9986270070075989,
              0,
              0.10074199736118317,
              0.994903028011322,
              0,
              0.42240700125694275,
              0.9064000248908997,
              0,
              0.422529011964798,
              0.9063389897346497,
              0,
              0.35453400015830994,
              0.9350259900093079,
              0,
              0.422529011964798,
              0.9063389897346497,
              0,
              0.3546859920024872,
              0.9349650144577026,
              0,
              0.35453400015830994,
              0.9350259900093079,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              -0.002899260027334094,
              0.19067999720573425,
              0.9816280007362366,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.002899260027334094,
              0.19067999720573425,
              0.9816280007362366,
              -0.002899260027334094,
              0.19025200605392456,
              0.9817190170288086,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.002899260027334094,
              0.19025200605392456,
              0.9817190170288086,
              -0.0026856299955397844,
              0.18973399698734283,
              0.981810986995697,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.0026856299955397844,
              0.18973399698734283,
              0.981810986995697,
              -0.0023804400116205215,
              0.18924500048160553,
              0.9819030165672302,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.0023804400116205215,
              0.18924500048160553,
              0.9819030165672302,
              -0.002014219993725419,
              0.18878799676895142,
              0.9819939732551575,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.002014219993725419,
              0.18878799676895142,
              0.9819939732551575,
              -0.0015564400237053633,
              0.18845200538635254,
              0.9820550084114075,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.0015564400237053633,
              0.18845200538635254,
              0.9820550084114075,
              -0.0010681500425562263,
              0.18814699351787567,
              0.9821159839630127,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.0010681500425562263,
              0.18814699351787567,
              0.9821159839630127,
              -0.0005493329954333603,
              0.18793299794197083,
              0.9821770191192627,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.0005493329954333603,
              0.18793299794197083,
              0.9821770191192627,
              -0.00027466699248179793,
              0.18793299794197083,
              0.9821770191192627,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.00027466699248179793,
              0.18793299794197083,
              0.9821770191192627,
              -0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              0,
              0.18805499374866486,
              0.9821469783782959,
              0,
              0.1908320039510727,
              0.9815970063209534,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -1,
              0,
              0.000030518498533638194,
              -0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              -1,
              0,
              0.000030518498533638194,
              -0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              -0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              -0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              -1,
              0,
              0.000030518498533638194,
              -0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              -1,
              0,
              0.000030518498533638194,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              -1,
              0,
              0.000030518498533638194,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              -0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              -0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0.000030518498533638194,
              -0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              -0.3137910068035126,
              -0.6317939758300781,
              0.708761990070343,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.16113799810409546,
              -0.6795250177383423,
              0.715690016746521,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              -0.16113799810409546,
              -0.6795250177383423,
              0.715690016746521,
              -0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              -0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              -0.16113799810409546,
              0.8981599807739258,
              0.40904000401496887,
              -0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              -0.3137910068035126,
              0.8512830138206482,
              0.4205150008201599,
              -0.16113799810409546,
              0.8981599807739258,
              0.40904000401496887,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              -0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              -0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              -0.19464699923992157,
              -0.9625539779663086,
              0.18854300677776337,
              -0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              -0.19470800459384918,
              0.9642019867897034,
              -0.17993700504302979,
              -0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              -0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              -0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              -0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              -0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              -0.6790980100631714,
              -0.33591699600219727,
              0.6526380181312561,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              -0.5775319933891296,
              -0.45680099725723267,
              0.6765649914741516,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              -0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              -0.5775319933891296,
              -0.45680099725723267,
              0.6765649914741516,
              -0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              -0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              -0.5774710178375244,
              0.6769620180130005,
              0.45625200867652893,
              -0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              -0.6790980100631714,
              0.5559250116348267,
              0.47929298877716064,
              -0.5774710178375244,
              0.6769620180130005,
              0.45625200867652893,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              -0.8312940001487732,
              -0.544389009475708,
              0.11197199672460556,
              -0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              -0.8312940001487732,
              -0.544389009475708,
              0.11197199672460556,
              -0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              -0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              -0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              -0.8314459919929504,
              0.5470749735832214,
              -0.09674370288848877,
              -0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              -0.8314459919929504,
              0.5470749735832214,
              -0.09674370288848877,
              -0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              -0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.683309018611908,
              0.7300940155982971,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              0,
              -0.683309018611908,
              0.7300940155982971,
              -0.04065069928765297,
              -0.6891999840736389,
              0.7234110236167908,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              -0.0406200997531414,
              0.9100009799003601,
              0.4125800132751465,
              0,
              0.9070709943771362,
              0.4209420084953308,
              -0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              -0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              -0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              -0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              0.9827569723129272,
              -0.1848510056734085,
              -0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              -0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              -0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              -0.7553939819335938,
              -0.1982789933681488,
              0.6244999766349792,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              -0.6790980100631714,
              -0.33591699600219727,
              0.6526380181312561,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              -0.6790980100631714,
              -0.33591699600219727,
              0.6526380181312561,
              -0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              -0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              -0.6790980100631714,
              0.5559250116348267,
              0.47929298877716064,
              -0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              -0.755424976348877,
              0.4177980124950409,
              0.5047460198402405,
              -0.6790980100631714,
              0.5559250116348267,
              0.47929298877716064,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              -0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              -0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              -0.8312940001487732,
              -0.544389009475708,
              0.11197199672460556,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              -0.8314459919929504,
              0.5470749735832214,
              -0.09674370288848877,
              -0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              -0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              -0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              -0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              -0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              -0.16113799810409546,
              -0.6795250177383423,
              0.715690016746521,
              -0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              -0.04065069928765297,
              -0.6891999840736389,
              0.7234110236167908,
              -0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              -0.04065069928765297,
              -0.6891999840736389,
              0.7234110236167908,
              -0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              -0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              -0.0406200997531414,
              0.9100009799003601,
              0.4125800132751465,
              -0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              -0.16113799810409546,
              0.8981599807739258,
              0.40904000401496887,
              -0.0406200997531414,
              0.9100009799003601,
              0.4125800132751465,
              -0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              -0.19464699923992157,
              -0.9625539779663086,
              0.18854300677776337,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              -0.19464699923992157,
              -0.9625539779663086,
              0.18854300677776337,
              -0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              -0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              -0.19470800459384918,
              0.9642019867897034,
              -0.17993700504302979,
              -0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              -0.19470800459384918,
              0.9642019867897034,
              -0.17993700504302979,
              -0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              -0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              -0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              -0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              -0.7553939819335938,
              -0.1982789933681488,
              0.6244999766349792,
              -0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              -0.7553939819335938,
              -0.1982789933681488,
              0.6244999766349792,
              -0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              -0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              -0.755424976348877,
              0.4177980124950409,
              0.5047460198402405,
              -0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              -0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              -0.755424976348877,
              0.4177980124950409,
              0.5047460198402405,
              -0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              -0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              -0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              -0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              -0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              -0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              -0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              -0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              -0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              -0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              -0.5775319933891296,
              -0.45680099725723267,
              0.6765649914741516,
              -0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              -0.4542680084705353,
              -0.5566269755363464,
              0.6955469846725464,
              -0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              -0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              -0.4542680084705353,
              -0.5566269755363464,
              0.6955469846725464,
              -0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              -0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              -0.4542370140552521,
              0.7766349911689758,
              0.4364449977874756,
              -0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              -0.5774710178375244,
              0.6769620180130005,
              0.45625200867652893,
              -0.4542370140552521,
              0.7766349911689758,
              0.4364449977874756,
              -0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              -0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              -0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              -0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              -0.5552229881286621,
              -0.8156070113182068,
              0.1626330018043518,
              -0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              -0.5553759932518005,
              0.8179569840431213,
              -0.14975400269031525,
              -0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              -0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              -0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              -0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              -0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              -0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              -0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              -0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              -0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              -0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              -0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              -0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              -0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              -0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              -0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              -0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              -0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              -0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              -0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              -0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              -0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              -0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              -0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              -0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              -0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              -0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              -0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              -0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              -0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              -0.4542680084705353,
              -0.5566269755363464,
              0.6955469846725464,
              -0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              -0.3137910068035126,
              -0.6317939758300781,
              0.708761990070343,
              -0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.3137910068035126,
              -0.6317939758300781,
              0.708761990070343,
              -0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              -0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              -0.3137910068035126,
              0.8512830138206482,
              0.4205150008201599,
              -0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              -0.4542370140552521,
              0.7766349911689758,
              0.4364449977874756,
              -0.3137910068035126,
              0.8512830138206482,
              0.4205150008201599,
              -0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              -0.5552229881286621,
              -0.8156070113182068,
              0.1626330018043518,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.5552229881286621,
              -0.8156070113182068,
              0.1626330018043518,
              -0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              -0.5553759932518005,
              0.8179569840431213,
              -0.14975400269031525,
              -0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              -0.5553759932518005,
              0.8179569840431213,
              -0.14975400269031525,
              -0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              -0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              0.9815970063209534,
              -0.1908320039510727,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              0,
              0.9070709943771362,
              0.4209110140800476,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0,
              0.9070709943771362,
              0.4209110140800476,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              0.9827569723129272,
              -0.18488100171089172,
              0,
              0.9827569723129272,
              -0.1848510056734085,
              0,
              0.9815970063209534,
              -0.1908320039510727,
              0,
              0.9827569723129272,
              -0.1848510056734085,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              0,
              0.9815970063209534,
              -0.1908320039510727,
              -0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.3830380141735077,
              -0.9061859846115112,
              0.17899100482463837,
              -0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              -0.3834350109100342,
              0.9179049730300903,
              -0.10202299803495407,
              -0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              -0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              -0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              -0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              -0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              -0.5489060282707214,
              -0.8358410000801086,
              0.0017090400215238333,
              -0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              -0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              -0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              -0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              -0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              -0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              -0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              -0.5489360094070435,
              0.7756279706954956,
              -0.31150200963020325,
              -0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              -0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              -0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              -0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              -0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              -0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              -0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              -0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              -0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              -0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              -0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              -0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              -0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              -0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              -0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              -0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              -0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              -0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              -0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              -0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              -0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              -0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              -0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              -0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              -0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              -0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              -0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              -0.7072970271110535,
              -0.6928920149803162,
              0.1399269998073578,
              -0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              -0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              -0.7072970271110535,
              -0.6928920149803162,
              0.1399269998073578,
              -0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              -0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              -0.7080900073051453,
              0.7020779848098755,
              -0.07510609924793243,
              -0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              -0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              -0.7080900073051453,
              0.7020779848098755,
              -0.07510609924793243,
              -0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              -0.5489060282707214,
              -0.8358410000801086,
              0.0017090400215238333,
              -0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              -0.5489060282707214,
              -0.8358410000801086,
              0.0017090400215238333,
              -0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              -0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              -0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              -0.5489360094070435,
              0.7756279706954956,
              -0.31150200963020325,
              -0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              -0.5489360094070435,
              0.7756279706954956,
              -0.31150200963020325,
              -0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              -0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              -0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              -0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              -0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              -0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              -0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              -0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              -0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              -0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              -0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              -0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              -0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              -0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              -0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              -0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              -0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              -0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              -0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              -0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              -0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              -0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              -0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              -0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              -0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              -0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              -0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              -0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              -0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              -0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              -0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              -0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              -0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              -0.1938840001821518,
              -0.9804069995880127,
              0.034669000655412674,
              -0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              -0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              -0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              -0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              -0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              -0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              -0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              -0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              -0.1938840001821518,
              0.9219949841499329,
              -0.3351239860057831,
              -0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              -0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              -0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              -0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              -0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              -0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              -0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              -0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              -0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              -0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              -0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              -0.82082599401474,
              -0.5689259767532349,
              -0.05014190077781677,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              -0.82082599401474,
              -0.5689259767532349,
              -0.05014190077781677,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              -0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              -0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              -0.82082599401474,
              0.5087130069732666,
              -0.2596209943294525,
              -0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              -0.82082599401474,
              0.5087130069732666,
              -0.2596209943294525,
              -0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              -0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              -0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              -0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              0,
              0.9934999942779541,
              -0.11377300322055817,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              -0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              -0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              -0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              -0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              -0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              -0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              -0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              -0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              -0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              -0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              -0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              -0.7072970271110535,
              -0.6928920149803162,
              0.1399269998073578,
              -0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              -0.7080900073051453,
              0.7020779848098755,
              -0.07510609924793243,
              -0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              -0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              -0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              -0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              -0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              -0.82082599401474,
              -0.5689259767532349,
              -0.05014190077781677,
              -0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              -0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              -0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              -0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              -0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              -0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              -0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              -0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              -0.82082599401474,
              0.5087130069732666,
              -0.2596209943294525,
              -0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              -0.3830380141735077,
              -0.9061859846115112,
              0.17899100482463837,
              -0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              -0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              -0.3830380141735077,
              -0.9061859846115112,
              0.17899100482463837,
              -0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              -0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              -0.3834350109100342,
              0.9179049730300903,
              -0.10202299803495407,
              -0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              -0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              -0.3834350109100342,
              0.9179049730300903,
              -0.10202299803495407,
              -0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              -0.1938840001821518,
              -0.9804069995880127,
              0.034669000655412674,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.1938840001821518,
              -0.9804069995880127,
              0.034669000655412674,
              -0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              -0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              -0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              -0.1938840001821518,
              0.9219949841499329,
              -0.3351239860057831,
              -0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              -0.1938840001821518,
              0.9219949841499329,
              -0.3351239860057831,
              -0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              -0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              -1,
              0,
              0,
              -0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              -0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              -0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              -1,
              0,
              0,
              -0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              -0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              -1,
              0,
              0,
              -0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              -0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              -1,
              0,
              0,
              -0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.5489060282707214,
              -0.8358410000801086,
              0.0017090400215238333,
              -0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              -0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              -0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              -0.2700279951095581,
              -0.7733700275421143,
              -0.5735340118408203,
              -0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              -0.25666099786758423,
              0.46937501430511475,
              -0.8448439836502075,
              -0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              -0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              -0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              -0.5489360094070435,
              0.7756279706954956,
              -0.31150200963020325,
              -0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              -0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              -0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              -0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              -0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              -0.6863309741020203,
              -0.17108699679374695,
              -0.7068390250205994,
              -0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              -0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              -0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              -0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              -0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              -0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              -0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              -0.5489060282707214,
              -0.8358410000801086,
              0.0017090400215238333,
              -0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              -0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              -0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              -0.4917449951171875,
              -0.6181219816207886,
              -0.6132389903068542,
              -0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              -0.4738300144672394,
              0.32419800758361816,
              -0.8187199831008911,
              -0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              -0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              -0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              -0.5489360094070435,
              0.7756279706954956,
              -0.31150200963020325,
              -0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              -0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              -0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              -0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              -0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              -0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              -0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              -0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              -0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              -0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              -0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              -0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              -0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              -0.1938840001821518,
              -0.9804069995880127,
              0.034669000655412674,
              -0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              -0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              -0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              -0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              -0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              -0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              -0.13104599714279175,
              0.510574996471405,
              -0.8497570157051086,
              -0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              -0.13104599714279175,
              0.510574996471405,
              -0.8497570157051086,
              -0.1938840001821518,
              0.9219949841499329,
              -0.3351239860057831,
              -0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              -0.82082599401474,
              -0.5689259767532349,
              -0.05014190077781677,
              -0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              -0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              -0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              -0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              -0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              -0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              -0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              -0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              -0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              -0.82082599401474,
              0.5087130069732666,
              -0.2596209943294525,
              -0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              -0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              -0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              -0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              -0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              -0.00042725898674689233,
              0.5188149809837341,
              -0.8548539876937866,
              -0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              -0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              -0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              -0.82082599401474,
              -0.5689259767532349,
              -0.05014190077781677,
              -0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              -0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              -0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              -0.4917449951171875,
              -0.6181219816207886,
              -0.6132389903068542,
              -0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              -0.4738300144672394,
              0.32419800758361816,
              -0.8187199831008911,
              -0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              -0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              -0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              -0.82082599401474,
              0.5087130069732666,
              -0.2596209943294525,
              -0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              -0.1938840001821518,
              -0.9804069995880127,
              0.034669000655412674,
              -0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              -0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              -0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              -0.2700279951095581,
              -0.7733700275421143,
              -0.5735340118408203,
              -0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              -0.25666099786758423,
              0.46937501430511475,
              -0.8448439836502075,
              -0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              -0.13104599714279175,
              0.510574996471405,
              -0.8497570157051086,
              -0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              -0.1938840001821518,
              0.9219949841499329,
              -0.3351239860057831,
              -0.13104599714279175,
              0.510574996471405,
              -0.8497570157051086,
              -0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.6863309741020203,
              -0.17108699679374695,
              -0.7068390250205994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              -0.6863309741020203,
              -0.17108699679374695,
              -0.7068390250205994,
              -0.6839810013771057,
              -0.13846200704574585,
              -0.7162079811096191,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              -0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              -0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              -0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              -0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              -0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              -0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              -0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              -0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              -0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              -0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              -0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              -0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              -0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              -0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              -0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              -0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              -0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              -0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              -0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              -0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              -0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              -0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              -0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              -0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              -0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              -0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              -0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              -0.8312940001487732,
              -0.544389009475708,
              0.11197199672460556,
              -0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              -0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              -0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              -0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              -0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              -0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              -0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              -0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              -0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              -0.8314459919929504,
              0.5470749735832214,
              -0.09674370288848877,
              -0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              -0.8312940001487732,
              -0.544389009475708,
              0.11197199672460556,
              -0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              -0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              -0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              -0.7072970271110535,
              -0.6928920149803162,
              0.1399269998073578,
              -0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              -0.7080900073051453,
              0.7020779848098755,
              -0.07510609924793243,
              -0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              -0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              -0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              -0.8314459919929504,
              0.5470749735832214,
              -0.09674370288848877,
              -0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              -0.5552229881286621,
              -0.8156070113182068,
              0.1626330018043518,
              -0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              -0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              -0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              -0.7072970271110535,
              -0.6928920149803162,
              0.1399269998073578,
              -0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              -0.7080900073051453,
              0.7020779848098755,
              -0.07510609924793243,
              -0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              -0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              -0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              -0.5553759932518005,
              0.8179569840431213,
              -0.14975400269031525,
              -0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              -0.5552229881286621,
              -0.8156070113182068,
              0.1626330018043518,
              -0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              -0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              -0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              -0.3830380141735077,
              -0.9061859846115112,
              0.17899100482463837,
              -0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              -0.3834350109100342,
              0.9179049730300903,
              -0.10202299803495407,
              -0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              -0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              -0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              -0.5553759932518005,
              0.8179569840431213,
              -0.14975400269031525,
              -0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              -0.19464699923992157,
              -0.9625539779663086,
              0.18854300677776337,
              -0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              -0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              -0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              -0.3830380141735077,
              -0.9061859846115112,
              0.17899100482463837,
              -0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              -0.3834350109100342,
              0.9179049730300903,
              -0.10202299803495407,
              -0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              -0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              -0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              -0.19470800459384918,
              0.9642019867897034,
              -0.17993700504302979,
              -0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              -0.19464699923992157,
              -0.9625539779663086,
              0.18854300677776337,
              -0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              -0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              -0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              -0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              -0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              -0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              -0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              -0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              -0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              -0.19470800459384918,
              0.9642019867897034,
              -0.17993700504302979,
              -0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              -0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              -0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              -0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              0.9934999942779541,
              -0.11377300322055817,
              -0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              0,
              0.9827569723129272,
              -0.1848510056734085,
              -0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              -0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              0,
              0.9827569723129272,
              -0.1848510056734085,
              0,
              0.10062000155448914,
              0.994903028011322,
              -0.000030518498533638194,
              0.1821649968624115,
              0.9832450151443481,
              -0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              -0.000030518498533638194,
              0.1821649968624115,
              0.9832450151443481,
              -0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              -0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              -0.000030518498533638194,
              0.1821649968624115,
              0.9832450151443481,
              -0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              -0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              -0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              -0.00027466699248179793,
              0.18793299794197083,
              0.9821770191192627,
              -0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              -0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              -0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              -0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              -0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              -0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              -0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              -0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              -0.00027466699248179793,
              0.18793299794197083,
              0.9821770191192627,
              -0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              -0.00027466699248179793,
              0.18793299794197083,
              0.9821770191192627,
              -0.0005493329954333603,
              0.18793299794197083,
              0.9821770191192627,
              -0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              -0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              -0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              -0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              -0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              -0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              -0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              -0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              -0.0005493329954333603,
              0.18793299794197083,
              0.9821770191192627,
              -0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              -0.0005493329954333603,
              0.18793299794197083,
              0.9821770191192627,
              -0.0010681500425562263,
              0.18814699351787567,
              0.9821159839630127,
              -0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              -0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              -0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              -0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              -0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              -0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              -0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              -0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              -0.0010681500425562263,
              0.18814699351787567,
              0.9821159839630127,
              -0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              -0.0010681500425562263,
              0.18814699351787567,
              0.9821159839630127,
              -0.0015564400237053633,
              0.18845200538635254,
              0.9820550084114075,
              -0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              -0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              -0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              -0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              -0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              -0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              -0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              -0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              -0.0015564400237053633,
              0.18845200538635254,
              0.9820550084114075,
              -0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              -0.0015564400237053633,
              0.18845200538635254,
              0.9820550084114075,
              -0.002014219993725419,
              0.18878799676895142,
              0.9819939732551575,
              -0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              -0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              -0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              -0.07708980143070221,
              0.13974399864673615,
              0.9871519804000854,
              -0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              -0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              -0.07708980143070221,
              0.13974399864673615,
              0.9871519804000854,
              -0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              -0.002014219993725419,
              0.18878799676895142,
              0.9819939732551575,
              -0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              -0.002014219993725419,
              0.18878799676895142,
              0.9819939732551575,
              -0.0023804400116205215,
              0.18924500048160553,
              0.9819030165672302,
              -0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              -0.07708980143070221,
              0.13974399864673615,
              0.9871519804000854,
              -0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              -0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              -0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              -0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              -0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              -0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              -0.0023804400116205215,
              0.18924500048160553,
              0.9819030165672302,
              -0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              -0.0023804400116205215,
              0.18924500048160553,
              0.9819030165672302,
              -0.0026856299955397844,
              0.18973399698734283,
              0.981810986995697,
              -0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              -0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              -0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              -0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              -0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              -0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              -0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              -0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              -0.0026856299955397844,
              0.18973399698734283,
              0.981810986995697,
              -0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              -0.0026856299955397844,
              0.18973399698734283,
              0.981810986995697,
              -0.002899260027334094,
              0.19025200605392456,
              0.9817190170288086,
              -0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              -0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              -0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              -0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              -0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              -0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              -0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              -0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              -0.002899260027334094,
              0.19025200605392456,
              0.9817190170288086,
              -0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              -0.002899260027334094,
              0.19025200605392456,
              0.9817190170288086,
              -0.002899260027334094,
              0.19067999720573425,
              0.9816280007362366,
              -0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              -0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              -0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              -0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              -0.002899260027334094,
              0.19067999720573425,
              0.9816280007362366,
              -0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              -0.002899260027334094,
              0.19067999720573425,
              0.9816280007362366,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              -0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              -0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              -0.000030518498533638194,
              0.1821649968624115,
              0.9832450151443481,
              0,
              0.18805499374866486,
              0.9821469783782959,
              -0.000030518498533638194,
              0.1821649968624115,
              0.9832450151443481,
              0,
              0.18219499289989471,
              0.9832450151443481,
              0,
              0.18805499374866486,
              0.9821469783782959,
              -0.000030518498533638194,
              0.1821649968624115,
              0.9832450151443481,
              0,
              0.10062000155448914,
              0.994903028011322,
              0,
              0.18219499289989471,
              0.9832450151443481,
              0,
              0.10062000155448914,
              0.994903028011322,
              0,
              0.10074199736118317,
              0.994903028011322,
              0,
              0.18219499289989471,
              0.9832450151443481,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              -0.00018311099847778678,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              -0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              0,
              -0.8609269857406616,
              -0.5086820125579834,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.8609269857406616,
              -0.5086820125579834,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.5444499850273132,
              -0.8387709856033325,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.5444499850273132,
              -0.8387709856033325,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.11710000038146973,
              0.18948900699615479,
              0.974852979183197,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.11710000038146973,
              0.18948900699615479,
              0.974852979183197,
              -0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              -0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.24152299761772156,
              0.18515600264072418,
              0.9525439739227295,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              -0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              -1,
              0,
              0.000030518498533638194,
              -1,
              0,
              0.000030518498533638194,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -1,
              0,
              0.000030518498533638194,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -1,
              0,
              0.000030518498533638194,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -1,
              0,
              0.000030518498533638194,
              -1,
              0,
              0.000030518498533638194,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -1,
              0,
              0.000030518498533638194,
              -1,
              0,
              0.000030518498533638194,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -1,
              0,
              0.000030518498533638194,
              -0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -1,
              0,
              0.000030518498533638194,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -1,
              0,
              0.000030518498533638194,
              -1,
              0,
              0.000030518498533638194,
              -0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0.000030518498533638194,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0.000030518498533638194,
              -1,
              0,
              0.000030518498533638194,
              -1,
              0,
              0.000030518498533638194,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0.000030518498533638194,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0.000030518498533638194,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0.000030518498533638194,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0.000030518498533638194,
              -1,
              0,
              0.000030518498533638194,
              -1,
              0,
              0.000030518498533638194,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0.000030518498533638194,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0.000030518498533638194,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.7115089893341064,
              -0.13455599546432495,
              -0.6896269917488098,
              -0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              -0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              -0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.6839810013771057,
              -0.13846200704574585,
              -0.7162079811096191,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.6839810013771057,
              -0.13846200704574585,
              -0.7162079811096191,
              -0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              -0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              -0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              -0.11710000038146973,
              0.18948900699615479,
              0.974852979183197,
              -0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              -0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              -0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              -0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              -0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              -0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              -0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              -0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              -0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              -0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              -0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.0014038500376045704,
              0.007141329813748598,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7224649786949158,
              -0.13190099596977234,
              -0.6786710023880005,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.7224649786949158,
              -0.13190099596977234,
              -0.6786710023880005,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.0013428099919110537,
              0.007141329813748598,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.0013428099919110537,
              0.007141329813748598,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.0013428099919110537,
              0.007141329813748598,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9934999942779541,
              -0.11377300322055817,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              -0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              -0.00018311099847778678,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              -0.00018311099847778678,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              -0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              -0.00042725898674689233,
              0.5188149809837341,
              -0.8548539876937866,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              -0.00042725898674689233,
              0.5188149809837341,
              -0.8548539876937866,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              -0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              -0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              -0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              -0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              -0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              -0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              -0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              -0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              -0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              -0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              -0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              -0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              -1,
              0.000024851800844771788,
              0.00012395500380080193,
              -1,
              0.000024851800844771788,
              0.00012395500380080193,
              -1,
              0.000024851800844771788,
              0.00012395500380080193,
              -1,
              0.000024851800844771788,
              0.00012395500380080193,
              -1,
              0.000024851800844771788,
              0.00012395500380080193,
              -1,
              0.000024851800844771788,
              0.00012395500380080193,
              -1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              -1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              -1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              -1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              -1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              -1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              -0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              -0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              -0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              -0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              -0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              -0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              -0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              -0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              -0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              -0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              -0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              -0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              -1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              -1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              -1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              -1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              -1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              -1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              -1,
              0.000023391599825117737,
              0.00012423799489624798,
              -1,
              0.000023391599825117737,
              0.00012423799489624798,
              -1,
              0.000023391599825117737,
              0.00012423799489624798,
              -1,
              0.000023391599825117737,
              0.00012423799489624798,
              -1,
              0.000023391599825117737,
              0.00012423799489624798,
              -1,
              0.000023391599825117737,
              0.00012423799489624798,
              -0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              -0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              -0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              -0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              -0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              -0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              -0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              -0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              -0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              -0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              -0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              -0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              -0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              -0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              -0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              -0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              -0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              -1,
              0.000024121300157275982,
              0.0001240940036950633,
              -1,
              0.000024121300157275982,
              0.0001240940036950633,
              -1,
              0.000024121300157275982,
              0.0001240940036950633,
              -1,
              0.000024121300157275982,
              0.0001240940036950633,
              -1,
              0.000024121300157275982,
              0.0001240940036950633,
              -1,
              0.000024121300157275982,
              0.0001240940036950633,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              -0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              -0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              -0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              -0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              -0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              -0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              -0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              -0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              -0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              -0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              -0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              -0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              -0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              -0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              -0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              -0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              -0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              -0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              -0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              -0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              -0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              -0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              -0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              -1,
              0.000024121600290527567,
              0.00012409499322529882,
              -1,
              0.000024121600290527567,
              0.00012409499322529882,
              -1,
              0.000024121600290527567,
              0.00012409499322529882,
              -1,
              0.000024121600290527567,
              0.00012409499322529882,
              -1,
              0.000024121600290527567,
              0.00012409499322529882,
              -1,
              0.000024121600290527567,
              0.00012409499322529882,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -1,
              0.000024121700334944762,
              0.00012409499322529882,
              -0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              -0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              -0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              -0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              -0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              -0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              -0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              -0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              -0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              -0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              -0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              -0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              -1,
              0.000024121800379361957,
              0.00012409599730744958,
              -1,
              0.000024121800379361957,
              0.00012409599730744958,
              -1,
              0.000024121800379361957,
              0.00012409599730744958,
              -1,
              0.000024121800379361957,
              0.00012409599730744958,
              -1,
              0.000024121800379361957,
              0.00012409599730744958,
              -1,
              0.000024121800379361957,
              0.00012409599730744958,
              -1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              -1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              -1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              -1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              -1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              -1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              -0.18601000308990479,
              -0.24811500310897827,
              -0.9506819844245911,
              -0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              -0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              -0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              -0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              -0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              -0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              -0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              -0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              -0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              -0.106113001704216,
              -0.2722249925136566,
              -0.9563590288162231,
              -0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              -0.3168739974498749,
              -0.18295800685882568,
              -0.9306309819221497,
              -0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              -0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              -0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              -0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              -0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              -0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              -0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              -0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              -0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              -0.7115089893341064,
              -0.13455599546432495,
              -0.6896269917488098,
              -0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              0,
              -0.33579498529434204,
              -0.9419230222702026,
              0,
              -0.3757439851760864,
              -0.9266949892044067,
              -0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              0,
              -0.3757439851760864,
              -0.9266949892044067,
              -0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              -0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              0,
              -0.3757439851760864,
              -0.9266949892044067,
              0,
              -0.38932499289512634,
              -0.9210789799690247,
              -0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              0,
              -0.38932499289512634,
              -0.9210789799690247,
              -0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              -0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              -0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              -0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              -0.106113001704216,
              -0.2722249925136566,
              -0.9563590288162231,
              -0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              -0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              -0.106113001704216,
              -0.2722249925136566,
              -0.9563590288162231,
              -0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              -0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              -0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              -0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              -0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              -0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              -0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              -0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              -0.18601000308990479,
              -0.24811500310897827,
              -0.9506819844245911,
              -0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              -0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              -0.18601000308990479,
              -0.24811500310897827,
              -0.9506819844245911,
              -0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              -0.3168739974498749,
              -0.18295800685882568,
              -0.9306309819221497,
              -0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              -0.3168739974498749,
              -0.18295800685882568,
              -0.9306309819221497,
              -0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              -0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7115089893341064,
              -0.13455599546432495,
              -0.6896269917488098,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              -0.7115089893341064,
              -0.13455599546432495,
              -0.6896269917488098,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              -0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -1,
              0,
              0,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              -0.9999690055847168,
              0.0014038500376045704,
              0.007141329813748598,
              -1,
              0,
              0,
              -0.9999690055847168,
              0.0014038500376045704,
              0.007141329813748598,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              -0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              -0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              -0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              -0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              -0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              -0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              -0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              -0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              -0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              -0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              -0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              -1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              -1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              -1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              -1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              -1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              -1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              -1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              -1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              -1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              -1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              -1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              -1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              -1,
              0.000025448700398555957,
              0.00012383599823806435,
              -1,
              0.000025448700398555957,
              0.00012383599823806435,
              -1,
              0.000025448700398555957,
              0.00012383599823806435,
              -1,
              0.000025448700398555957,
              0.00012383599823806435,
              -1,
              0.000025448700398555957,
              0.00012383599823806435,
              -1,
              0.000025448700398555957,
              0.00012383599823806435,
              -1,
              0.000022499199985759333,
              0.00012440899445209652,
              -1,
              0.000022499199985759333,
              0.00012440899445209652,
              -1,
              0.000022499199985759333,
              0.00012440899445209652,
              -1,
              0.000022499199985759333,
              0.00012440899445209652,
              -1,
              0.000022499199985759333,
              0.00012440899445209652,
              -1,
              0.000022499199985759333,
              0.00012440899445209652,
              -0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              -0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              -0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              -0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              -0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              -0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              -0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              -0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              -0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              -0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              -0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              -0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              -0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              -0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              -0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              -0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              -0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              -0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              -0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              -0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              -0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              -0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              -0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              -0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.9656059741973877,
              -0.1361130028963089,
              -0.22147299349308014,
              0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              0.6760770082473755,
              -0.2922149896621704,
              -0.6763820052146912,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.6783040165901184,
              -0.22898000478744507,
              -0.6981719732284546,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              0.6783040165901184,
              -0.22898000478744507,
              -0.6981719732284546,
              0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.9734789729118347,
              0.1225619986653328,
              -0.1930299997329712,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              0.7304610013961792,
              0.6423540115356445,
              0.23194099962711334,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              0.7224040031433105,
              0.3816950023174286,
              0.5765249729156494,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0.15530900657176971,
              -0.9135109782218933,
              -0.37589600682258606,
              0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              0.15530900657176971,
              -0.9135109782218933,
              -0.37589600682258606,
              0.6729329824447632,
              -0.6207159757614136,
              -0.4022339880466461,
              0.15530900657176971,
              -0.9135109782218933,
              -0.37589600682258606,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0.6729329824447632,
              -0.6207159757614136,
              -0.4022339880466461,
              0.6729329824447632,
              -0.6207159757614136,
              -0.4022339880466461,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              0.6760770082473755,
              -0.2922149896621704,
              -0.6763820052146912,
              0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              0.6783040165901184,
              -0.22898000478744507,
              -0.6981719732284546,
              0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              0.6783040165901184,
              -0.22898000478744507,
              -0.6981719732284546,
              0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              0.16080200672149658,
              0.9686570167541504,
              0.18921500444412231,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.16080200672149658,
              0.9686570167541504,
              0.18921500444412231,
              0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              0.7304610013961792,
              0.6423540115356445,
              0.23194099962711334,
              0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              0.16022199392318726,
              0.08224739879369736,
              0.9836419820785522,
              0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              0.16022199392318726,
              0.08224739879369736,
              0.9836419820785522,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.16022199392318726,
              0.08224739879369736,
              0.9836419820785522,
              0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              0.7224040031433105,
              0.3816950023174286,
              0.5765249729156494,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              0.7343969941139221,
              0.5360270142555237,
              0.41624200344085693,
              0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0.7343969941139221,
              0.5360270142555237,
              0.41624200344085693,
              0.7343969941139221,
              0.5360270142555237,
              0.41624200344085693,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              0.7150179743766785,
              -0.13211500644683838,
              0.6864529848098755,
              0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.15894000232219696,
              -0.986998975276947,
              -0.022675300016999245,
              0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              0.6760770082473755,
              -0.2922149896621704,
              -0.6763820052146912,
              0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              0.6760770082473755,
              -0.2922149896621704,
              -0.6763820052146912,
              0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              0.6783040165901184,
              -0.22898000478744507,
              -0.6981719732284546,
              0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              0.16080200672149658,
              0.9686570167541504,
              0.18921500444412231,
              0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              0.7304610013961792,
              0.6423540115356445,
              0.23194099962711334,
              0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              0.7304610013961792,
              0.6423540115356445,
              0.23194099962711334,
              0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0.7224040031433105,
              0.3816950023174286,
              0.5765249729156494,
              0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              0.7224040031433105,
              0.3816950023174286,
              0.5765249729156494,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              0.6729329824447632,
              -0.6207159757614136,
              -0.4022339880466461,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.6729329824447632,
              -0.6207159757614136,
              -0.4022339880466461,
              0.9656059741973877,
              -0.1361130028963089,
              -0.22147299349308014,
              0.6729329824447632,
              -0.6207159757614136,
              -0.4022339880466461,
              0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              0.9656059741973877,
              -0.1361130028963089,
              -0.22147299349308014,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.6760770082473755,
              -0.2922149896621704,
              -0.6763820052146912,
              0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              0.6760770082473755,
              -0.2922149896621704,
              -0.6763820052146912,
              0.6783040165901184,
              -0.22898000478744507,
              -0.6981719732284546,
              0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.9734789729118347,
              0.1225619986653328,
              -0.1930299997329712,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              0.9734789729118347,
              0.1225619986653328,
              -0.1930299997329712,
              0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.7304610013961792,
              0.6423540115356445,
              0.23194099962711334,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.7304610013961792,
              0.6423540115356445,
              0.23194099962711334,
              0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.7224040031433105,
              0.3816950023174286,
              0.5765249729156494,
              0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              0.7224040031433105,
              0.3816950023174286,
              0.5765249729156494,
              0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              0.7343969941139221,
              0.5360270142555237,
              0.41624200344085693,
              0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              0.7343969941139221,
              0.5360270142555237,
              0.41624200344085693,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.7343969941139221,
              0.5360270142555237,
              0.41624200344085693,
              0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.7150179743766785,
              -0.13211500644683838,
              0.6864529848098755,
              0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9911500215530396,
              0.1291240006685257,
              -0.030030200257897377,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              0.9911500215530396,
              0.1291240006685257,
              -0.030030200257897377,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9911500215530396,
              0.1291240006685257,
              -0.030030200257897377,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9656059741973877,
              -0.1361130028963089,
              -0.22147299349308014,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9656059741973877,
              -0.1361130028963089,
              -0.22147299349308014,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              0.9911500215530396,
              0.1291240006685257,
              -0.030030200257897377,
              0.9734789729118347,
              0.1225619986653328,
              -0.1930299997329712,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9734789729118347,
              0.1225619986653328,
              -0.1930299997329712,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0.15530900657176971,
              -0.9135109782218933,
              -0.37589600682258606,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0.15530900657176971,
              -0.9135109782218933,
              -0.37589600682258606,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0.15530900657176971,
              -0.9135109782218933,
              -0.37589600682258606,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              0.16080200672149658,
              0.9686570167541504,
              0.18921500444412231,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              0,
              0.9226660132408142,
              0.3855710029602051,
              0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              0,
              0.9226660132408142,
              0.3855710029602051,
              0,
              0.9226660132408142,
              0.3855710029602051,
              0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0.16022199392318726,
              0.08224739879369736,
              0.9836419820785522,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0.16022199392318726,
              0.08224739879369736,
              0.9836419820785522,
              0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.14407800137996674,
              0.98956298828125,
              0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              0.16022199392318726,
              0.08224739879369736,
              0.9836419820785522,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.23011000454425812,
              0.973143994808197,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0,
              0.14407800137996674,
              0.98956298828125,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.46974098682403564,
              0.882777988910675,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.735588014125824,
              0.6773890256881714,
              0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              0.15894000232219696,
              -0.986998975276947,
              -0.022675300016999245,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0.15894000232219696,
              -0.986998975276947,
              -0.022675300016999245,
              0,
              -1,
              0,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0.16080200672149658,
              0.9686570167541504,
              0.18921500444412231,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0.16080200672149658,
              0.9686570167541504,
              0.18921500444412231,
              0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              0,
              0.23011000454425812,
              0.973143994808197,
              0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              0,
              0.46974098682403564,
              0.882777988910675,
              0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0,
              0.735588014125824,
              0.6773890256881714,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0.15887899696826935,
              0.9872429966926575,
              0.008758810348808765,
              0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.9656059741973877,
              -0.1361130028963089,
              -0.22147299349308014,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.9911500215530396,
              0.1291240006685257,
              -0.030030200257897377,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.9734789729118347,
              0.1225619986653328,
              -0.1930299997329712,
              0.9911500215530396,
              0.1291240006685257,
              -0.030030200257897377,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1412699967622757,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -1,
              0,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9226660132408142,
              0.3855710029602051,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.9226660132408142,
              0.3855710029602051,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9226660132408142,
              0.3855710029602051,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0.7017430067062378,
              0.7081519961357117,
              -0.07760860025882721,
              0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              0.15894000232219696,
              -0.986998975276947,
              -0.022675300016999245,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.15887899696826935,
              0.9872429966926575,
              0.008758810348808765,
              0.7017430067062378,
              0.7081519961357117,
              -0.07760860025882721,
              0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              0.7017430067062378,
              0.7081519961357117,
              -0.07760860025882721,
              0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              0.15894000232219696,
              -0.986998975276947,
              -0.022675300016999245,
              0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -1,
              0,
              0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              0.9931640028953552,
              0.006530960090458393,
              0.11633700132369995,
              0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              0.9931640028953552,
              0.006530960090458393,
              0.11633700132369995,
              0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              0.9931640028953552,
              0.006530960090458393,
              0.11633700132369995,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              0.6876429915428162,
              0.30149200558662415,
              -0.6604509949684143,
              0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.6876429915428162,
              0.30149200558662415,
              -0.6604509949684143,
              0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              0.6628010272979736,
              0.7365339994430542,
              -0.13489200174808502,
              0.9628890156745911,
              0.2679530084133148,
              -0.031495101749897,
              0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              0.9628890156745911,
              0.2679530084133148,
              -0.031495101749897,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              0.7413859963417053,
              -0.49925199151039124,
              0.4483779966831207,
              0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.7472760081291199,
              -0.6491590142250061,
              0.141757994890213,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.9985960125923157,
              -0.04272589832544327,
              0.03112890012562275,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.9985960125923157,
              -0.04272589832544327,
              0.03112890012562275,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              0,
              -0.707082986831665,
              0.707082986831665,
              0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.707082986831665,
              0.707082986831665,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              0.7682729959487915,
              0.15854400396347046,
              0.6201670169830322,
              0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              0.7682729959487915,
              0.15854400396347046,
              0.6201670169830322,
              0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              0.6640830039978027,
              0.6973170042037964,
              0.2696619927883148,
              0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              0.9858090281486511,
              0.15964199602603912,
              -0.05157630145549774,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.9858090281486511,
              0.15964199602603912,
              -0.05157630145549774,
              0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              0.9858090281486511,
              0.15964199602603912,
              -0.05157630145549774,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              0.7291479706764221,
              -0.30149200558662415,
              0.6143379807472229,
              0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              0.7413859963417053,
              -0.49925199151039124,
              0.4483779966831207,
              0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              0.6626179814338684,
              0.738211989402771,
              -0.12619400024414062,
              0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              0.15201300382614136,
              0.9713429808616638,
              -0.182561993598938,
              0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              0.15201300382614136,
              0.9713429808616638,
              -0.182561993598938,
              0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              0.7291479706764221,
              -0.30149200558662415,
              0.6143379807472229,
              0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              0.7291479706764221,
              -0.30149200558662415,
              0.6143379807472229,
              0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              0.1539350003004074,
              0.7930539846420288,
              -0.5893120169639587,
              0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              0.15360000729560852,
              0.8478350043296814,
              -0.5074620246887207,
              0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              0.43629300594329834,
              -0.7016819715499878,
              0.5632190108299255,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              0.43629300594329834,
              -0.7016819715499878,
              0.5632190108299255,
              0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0.43629300594329834,
              -0.7016819715499878,
              0.5632190108299255,
              0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.987060010433197,
              0.16016100347042084,
              0,
              -0.707082986831665,
              0.707082986831665,
              0,
              -0.987060010433197,
              0.16016100347042084,
              0,
              -0.987060010433197,
              0.16019199788570404,
              0,
              -0.707082986831665,
              0.707082986831665,
              0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              0.1095919981598854,
              0.9823300242424011,
              0.1516769975423813,
              0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              0.1095919981598854,
              0.9823300242424011,
              0.1516769975423813,
              0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.6640830039978027,
              0.6973170042037964,
              0.2696619927883148,
              0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.7017430067062378,
              0.7081519961357117,
              -0.07760860025882721,
              0.15887899696826935,
              0.9872429966926575,
              0.008758810348808765,
              0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              0.15887899696826935,
              0.9872429966926575,
              0.008758810348808765,
              0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.6876429915428162,
              0.30149200558662415,
              -0.6604509949684143,
              0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              0.6628010272979736,
              0.7365339994430542,
              -0.13489200174808502,
              0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              0.6628010272979736,
              0.7365339994430542,
              -0.13489200174808502,
              0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              0.7413859963417053,
              -0.49925199151039124,
              0.4483779966831207,
              0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              0.7413859963417053,
              -0.49925199151039124,
              0.4483779966831207,
              0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0.16385400295257568,
              -0.9680780172348022,
              0.1895810067653656,
              0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              0.7472760081291199,
              -0.6491590142250061,
              0.141757994890213,
              0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.7472760081291199,
              -0.6491590142250061,
              0.141757994890213,
              0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              0.1539350003004074,
              0.7930539846420288,
              -0.5893120169639587,
              0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              0.1539350003004074,
              0.7930539846420288,
              -0.5893120169639587,
              0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.15360000729560852,
              0.8478350043296814,
              -0.5074620246887207,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              0.15360000729560852,
              0.8478350043296814,
              -0.5074620246887207,
              0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              0,
              -0.987060010433197,
              0.16019199788570404,
              0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              0,
              -0.707082986831665,
              0.707082986831665,
              0,
              -0.987060010433197,
              0.16019199788570404,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              0.1095919981598854,
              0.9823300242424011,
              0.1516769975423813,
              0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              0.6640830039978027,
              0.6973170042037964,
              0.2696619927883148,
              0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              0.6640830039978027,
              0.6973170042037964,
              0.2696619927883148,
              0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.7291479706764221,
              -0.30149200558662415,
              0.6143379807472229,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.7291479706764221,
              -0.30149200558662415,
              0.6143379807472229,
              0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              0.7413859963417053,
              -0.49925199151039124,
              0.4483779966831207,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.7413859963417053,
              -0.49925199151039124,
              0.4483779966831207,
              0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.9630730152130127,
              0.26761698722839355,
              -0.029145199805498123,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.6626179814338684,
              0.738211989402771,
              -0.12619400024414062,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              0.6626179814338684,
              0.738211989402771,
              -0.12619400024414062,
              0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              0.7291479706764221,
              -0.30149200558662415,
              0.6143379807472229,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              0.43629300594329834,
              -0.7016819715499878,
              0.5632190108299255,
              0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              0.43629300594329834,
              -0.7016819715499878,
              0.5632190108299255,
              0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              0.43629300594329834,
              -0.7016819715499878,
              0.5632190108299255,
              0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.707082986831665,
              0.707082986831665,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              0.7682729959487915,
              0.15854400396347046,
              0.6201670169830322,
              0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              0.6640830039978027,
              0.6973170042037964,
              0.2696619927883148,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.6640830039978027,
              0.6973170042037964,
              0.2696619927883148,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              0.7017430067062378,
              0.7081519961357117,
              -0.07760860025882721,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.7017430067062378,
              0.7081519961357117,
              -0.07760860025882721,
              0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9931640028953552,
              0.006530960090458393,
              0.11633700132369995,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9931640028953552,
              0.006530960090458393,
              0.11633700132369995,
              0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.9931640028953552,
              0.006530960090458393,
              0.11633700132369995,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.9628890156745911,
              0.2679530084133148,
              -0.031495101749897,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985960125923157,
              -0.04272589832544327,
              0.03112890012562275,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0,
              0,
              1,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              0.7682729959487915,
              0.15854400396347046,
              0.6201670169830322,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9858090281486511,
              0.15964199602603912,
              -0.05157630145549774,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9858090281486511,
              0.15964199602603912,
              -0.05157630145549774,
              0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.9858090281486511,
              0.15964199602603912,
              -0.05157630145549774,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0.15201300382614136,
              0.9713429808616638,
              -0.182561993598938,
              0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0.1539350003004074,
              0.7930539846420288,
              -0.5893120169639587,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0.1539350003004074,
              0.7930539846420288,
              -0.5893120169639587,
              0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0.15360000729560852,
              0.8478350043296814,
              -0.5074620246887207,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0.15360000729560852,
              0.8478350043296814,
              -0.5074620246887207,
              0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              -0.9999690055847168,
              0,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0,
              -1,
              -0.00006103699706727639,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0,
              -1,
              -0.00006103699706727639,
              0,
              -1,
              0,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0,
              -1,
              0.000030518498533638194,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0,
              -1,
              0.000030518498533638194,
              0.00012207399413455278,
              -1,
              0.00012207399413455278,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0,
              -1,
              0,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0,
              -1,
              0,
              0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              0,
              -1,
              0,
              0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              0,
              -1,
              0,
              0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              0,
              -1,
              0,
              0,
              -1,
              0.000030518498533638194,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0,
              -1,
              0.000030518498533638194,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              0,
              -1,
              0.000030518498533638194,
              0.000030518498533638194,
              -0.9999690055847168,
              0.00009155549923889339,
              0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              0,
              -1,
              -0.000030518498533638194,
              0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              0,
              -1,
              -0.000030518498533638194,
              0,
              -1,
              0.000030518498533638194,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0,
              -1,
              0,
              0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              0,
              -0.9999690055847168,
              0,
              0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              0,
              -1,
              0,
              0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              0,
              -1,
              0,
              0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.987060010433197,
              0.16019199788570404,
              0,
              -1,
              0,
              0,
              -0.987060010433197,
              0.16019199788570404,
              0,
              -0.987060010433197,
              0.16016100347042084,
              0,
              -1,
              0,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              -0.00009155549923889339,
              0.9994810223579407,
              0.031495101749897,
              0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              -0.00009155549923889339,
              0.9994810223579407,
              0.031495101749897,
              -0.00009155549923889339,
              0.9994810223579407,
              0.031495101749897,
              0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              0.1095919981598854,
              0.9823300242424011,
              0.1516769975423813,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              0.00015259299834724516,
              0.9994810223579407,
              0.031891800463199615,
              0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              0.00015259299834724516,
              0.9994810223579407,
              0.031891800463199615,
              0.00015259299834724516,
              0.9994810223579407,
              0.031891800463199615,
              0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              0.15887899696826935,
              0.9872429966926575,
              0.008758810348808765,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              0.1539350003004074,
              0.7930539846420288,
              -0.5893120169639587,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              0.15360000729560852,
              0.8478350043296814,
              -0.5074620246887207,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0.000030518498533638194,
              -1,
              0.00009155549923889339,
              0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              0,
              -0.9999690055847168,
              0,
              0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              -0.000030518498533638194,
              0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              0.000030518498533638194,
              -1,
              0.00009155549923889339,
              0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              0.000030518498533638194,
              -1,
              0.00009155549923889339,
              0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              0.000030518498533638194,
              -0.9999690055847168,
              0.00009155549923889339,
              0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              0.000030518498533638194,
              -0.9999690055847168,
              0.00009155549923889339,
              0,
              -1,
              0,
              0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              0,
              -1,
              0,
              0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0,
              -1,
              0,
              0,
              -1,
              -0.00006103699706727639,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0,
              -1,
              0.000030518498533638194,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0,
              -1,
              0.000030518498533638194,
              0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0.00012207399413455278,
              -1,
              0.00012207399413455278,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0.00012207399413455278,
              -1,
              0.00012207399413455278,
              0.00006103699706727639,
              -1,
              0,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0,
              -1,
              0,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              0.00006103699706727639,
              -1,
              0,
              0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0.00006103699706727639,
              -1,
              0,
              0,
              -1,
              0,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0,
              -1,
              0,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -1,
              0,
              0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              0,
              -1,
              0,
              0,
              -1,
              0.000030518498533638194,
              0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              0,
              -1,
              0,
              0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              0,
              -0.987060010433197,
              0.16019199788570404,
              0,
              -1,
              0,
              0,
              0.9994810223579407,
              0.031769800931215286,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.1095919981598854,
              0.9823300242424011,
              0.1516769975423813,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0.1095919981598854,
              0.9823300242424011,
              0.1516769975423813,
              0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9628890156745911,
              0.2679530084133148,
              -0.031495101749897,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0,
              0,
              1,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              0,
              1,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              0,
              1,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.4056209921836853,
              0,
              0.9139990210533142,
              0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7682729959487915,
              0.15854400396347046,
              0.6201670169830322,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7682729959487915,
              0.15854400396347046,
              0.6201670169830322,
              0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0,
              0,
              1,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0,
              1,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0,
              1,
              0,
              -1,
              0.000030518498533638194,
              0,
              -1,
              0.000030518498533638194,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0.000030518498533638194,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.00006103699706727639,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0,
              -1,
              0,
              0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0.00012207399413455278,
              -1,
              0.00012207399413455278,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.000030518498533638194,
              0,
              -1,
              -0.000030518498533638194,
              0,
              -1,
              0.000030518498533638194,
              0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0,
              -1,
              -0.000030518498533638194,
              0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0.000030518498533638194,
              -0.9999690055847168,
              0.00009155549923889339,
              0,
              -1,
              -0.000030518498533638194,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.000030518498533638194,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              -0.000030518498533638194,
              0.000030518498533638194,
              -1,
              0.00009155549923889339,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              -0.00006103699706727639,
              0,
              -1,
              0.000030518498533638194,
              0,
              -0.9999690055847168,
              0,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.00015259299834724516,
              0.9994810223579407,
              0.031891800463199615,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.00009155549923889339,
              0.9994810223579407,
              0.031495101749897,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18814699351787567,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.6876429915428162,
              0.30149200558662415,
              -0.6604509949684143,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.9747310280799866,
              0.1647080034017563,
              -0.15079200267791748,
              0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0.16101600229740143,
              -0.2808620035648346,
              0.9461349844932556,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              0.6876429915428162,
              0.30149200558662415,
              -0.6604509949684143,
              0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747310280799866,
              0.1647080034017563,
              -0.15079200267791748,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747310280799866,
              0.1647080034017563,
              -0.15079200267791748,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0.16101600229740143,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16101600229740143,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              0.6628010272979736,
              0.7365339994430542,
              -0.13489200174808502,
              0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              0.6628010272979736,
              0.7365339994430542,
              -0.13489200174808502,
              0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.9628890156745911,
              0.2679530084133148,
              -0.031495101749897,
              0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              0.9628890156745911,
              0.2679530084133148,
              -0.031495101749897,
              0.6628010272979736,
              0.7365339994430542,
              -0.13489200174808502,
              0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0.16385400295257568,
              -0.9680780172348022,
              0.1895810067653656,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              0.9630730152130127,
              0.26761698722839355,
              -0.029145199805498123,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9821159839630127,
              -0.18814699351787567,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0.9747310280799866,
              0.1647080034017563,
              -0.15079200267791748,
              0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              0.6885889768600464,
              0.29334399104118347,
              -0.6631370186805725,
              0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              0.16101600229740143,
              -0.2808620035648346,
              0.9461349844932556,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0.7152619957923889,
              -0.13202300667762756,
              0.6862390041351318,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              0.6885889768600464,
              0.29334399104118347,
              -0.6631370186805725,
              0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.7152619957923889,
              -0.13202300667762756,
              0.6862390041351318,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              0.9747310280799866,
              0.1647080034017563,
              -0.15079200267791748,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747310280799866,
              0.1647080034017563,
              -0.15079200267791748,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.16101600229740143,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              -0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              -0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              -0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              -0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              -0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              -0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              -0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              -0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              -0.9736620187759399,
              0.13895100355148315,
              -0.18063899874687195,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.9736620187759399,
              0.13895100355148315,
              -0.18063899874687195,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              -0.9736620187759399,
              0.13895100355148315,
              -0.18063899874687195,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              -0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              -0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              -0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              -0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              -0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              -0.9841300249099731,
              0.15051700174808502,
              -0.0937528982758522,
              -0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              -0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              -0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.7167879939079285,
              -0.039796099066734314,
              0.6961269974708557,
              -0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              -0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              -0.7167879939079285,
              -0.039796099066734314,
              0.6961269974708557,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.7167879939079285,
              -0.039796099066734314,
              0.6961269974708557,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              -0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              -0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              -0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              -0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              -0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              -0.983489990234375,
              0.1804559975862503,
              -0.012787300162017345,
              -0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              -0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              -0.983489990234375,
              0.1804559975862503,
              -0.012787300162017345,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              -0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              -0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              -0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              -0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              -0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              -0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              -0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              -0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              -0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              -0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              -0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              -0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              -0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              -0.157383993268013,
              -0.981016993522644,
              -0.11316300183534622,
              -0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              -0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              -0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.157383993268013,
              -0.981016993522644,
              -0.11316300183534622,
              -0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              -0.157383993268013,
              -0.981016993522644,
              -0.11316300183534622,
              -0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              -0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              -0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              -0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              -0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              -0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              -0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              -0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              -0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              -0.15424099564552307,
              -0.8327590227127075,
              -0.5316929817199707,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              -0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              -0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              -0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              -0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              -0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              -0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              -0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              -0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              -0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              -0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              -0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              -0.15622399747371674,
              -0.3031100034713745,
              -0.9400309920310974,
              -0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              -0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              -0.15622399747371674,
              -0.3031100034713745,
              -0.9400309920310974,
              -0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              -0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              -0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              -0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              -0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              -0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              -0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              -0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              -0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              -0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              -0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              -0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              -0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              -0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              -0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              -0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              -0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              -0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              -0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              -0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              -0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              -0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              -0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              -0.716513991355896,
              0.22110700607299805,
              0.6615800261497498,
              -0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              -0.716513991355896,
              0.22110700607299805,
              0.6615800261497498,
              -0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              -0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              -0.716513991355896,
              0.22110700607299805,
              0.6615800261497498,
              -0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              -0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              -0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              -0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              -0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              -0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              -0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              -0.1621749997138977,
              0.5575429797172546,
              0.8141419887542725,
              -0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              -0.16269400715827942,
              0.6233710050582886,
              0.7647939920425415,
              -0.1621749997138977,
              0.5575429797172546,
              0.8141419887542725,
              -0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.16269400715827942,
              0.6233710050582886,
              0.7647939920425415,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              -0.16269400715827942,
              0.6233710050582886,
              0.7647939920425415,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              -0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.7150179743766785,
              -0.1321450024843216,
              0.6864830255508423,
              -0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              -0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              -0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              -0.15891000628471375,
              -0.986998975276947,
              -0.022675300016999245,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              -0.15424099564552307,
              -0.8327590227127075,
              -0.5316929817199707,
              -0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              -0.15424099564552307,
              -0.8327590227127075,
              -0.5316929817199707,
              -0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              -0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              -0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              -0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              -0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              -0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              -0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              -0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              -0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              -0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              -0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              -0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              -0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              -0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              -0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              -0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              -0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              -0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              -0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              -0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              -0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              -0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              -0.15622399747371674,
              -0.3031100034713745,
              -0.9400309920310974,
              -0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              -0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              -0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              -0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778439998626709,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778439998626709,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778439998626709,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7098910212516785,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              -0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              -0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              -0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              -0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              -0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              -0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              -0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              -0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              -0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              -0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              -0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              -0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              -0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              -0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              -0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              -0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              -0.7167879939079285,
              -0.039796099066734314,
              0.6961269974708557,
              -0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              -0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              -0.7167879939079285,
              -0.039796099066734314,
              0.6961269974708557,
              -0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              -0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              -0.7167879939079285,
              -0.039796099066734314,
              0.6961269974708557,
              -0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              -0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              -0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              -0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              -0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              -0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              -0.1621749997138977,
              0.5575429797172546,
              0.8141419887542725,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              -0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              -0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              -0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              -0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              -0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              -0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              -0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              -0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              -0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              -0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              -0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              -0.9743949770927429,
              -0.16315199434757233,
              -0.15463699400424957,
              -0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              -0.9743949770927429,
              -0.16315199434757233,
              -0.15463699400424957,
              -0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              -0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              -0.9743949770927429,
              -0.16315199434757233,
              -0.15463699400424957,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              -0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              -0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              -0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              -0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              -0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              -0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              -0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              -0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              -0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              -0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              -0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              -0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.9841300249099731,
              0.15051700174808502,
              -0.0937528982758522,
              -0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              -0.9841300249099731,
              0.15051700174808502,
              -0.0937528982758522,
              -0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              -0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              -0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.9794300198554993,
              0.1543630063533783,
              0.12970399856567383,
              -0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              -0.9794300198554993,
              0.1543630063533783,
              0.12970399856567383,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              -0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              -0.9794300198554993,
              0.1543630063533783,
              0.12970399856567383,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.716513991355896,
              0.22110700607299805,
              0.6615800261497498,
              -0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              -0.716513991355896,
              0.22110700607299805,
              0.6615800261497498,
              -0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              -0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              -0.716513991355896,
              0.22110700607299805,
              0.6615800261497498,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              -0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              -0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              -0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              -0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              -0.983489990234375,
              0.1804559975862503,
              -0.012787300162017345,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              -0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              -0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              -0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              -0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              -0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              -0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              -0.7150179743766785,
              -0.1321450024843216,
              0.6864830255508423,
              -0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              -0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              -0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              -0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              -0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              -0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              -0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              -0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              -0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              -0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              -0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9911190271377563,
              0.07351910322904587,
              -0.11072099953889847,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              -0.9911190271377563,
              0.07351910322904587,
              -0.11072099953889847,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              -0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              -0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              -0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              -0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              -0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              -0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              -0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.9911190271377563,
              0.07351910322904587,
              -0.11072099953889847,
              -0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              -0.9911190271377563,
              0.07351910322904587,
              -0.11072099953889847,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              -0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              -0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9736620187759399,
              0.13895100355148315,
              -0.18063899874687195,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9736620187759399,
              0.13895100355148315,
              -0.18063899874687195,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.9736620187759399,
              0.13895100355148315,
              -0.18063899874687195,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              -0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9841300249099731,
              0.15051700174808502,
              -0.0937528982758522,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              -0.9841300249099731,
              0.15051700174808502,
              -0.0937528982758522,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              -0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              -0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              -0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              -0.983489990234375,
              0.1804559975862503,
              -0.012787300162017345,
              -0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              -0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              -0.157383993268013,
              -0.981016993522644,
              -0.11316300183534622,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              -0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              -0.157383993268013,
              -0.981016993522644,
              -0.11316300183534622,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              -0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              -0.157383993268013,
              -0.981016993522644,
              -0.11316300183534622,
              -0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              -0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              -0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              -0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              -0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              -0.15424099564552307,
              -0.8327590227127075,
              -0.5316929817199707,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              -0.15424099564552307,
              -0.8327590227127075,
              -0.5316929817199707,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              -0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              -0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              -0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              -0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              -0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              -0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              -0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              -0.15622399747371674,
              -0.3031100034713745,
              -0.9400309920310974,
              -0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              -0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              -0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              -0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              -0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9768970012664795,
              0.21356900036334991,
              -0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              0,
              0.9768970012664795,
              0.21356900036334991,
              -0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              -0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9226660132408142,
              0.3855400085449219,
              -0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              0,
              0.9226660132408142,
              0.3855400085449219,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              -0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.8848540186882019,
              0.46583500504493713,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              0,
              0.8848540186882019,
              0.46583500504493713,
              -0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.12439300119876862,
              0.992218017578125,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              0,
              -0.12439300119876862,
              0.992218017578125,
              -0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              -0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              -0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.05578780174255371,
              0.9984130263328552,
              -0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              0,
              0.05578780174255371,
              0.9984130263328552,
              -0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              -0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.14407800137996674,
              0.98956298828125,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0,
              0.14407800137996674,
              0.98956298828125,
              -0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.39329200983047485,
              0.9194009900093079,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0,
              0.39329200983047485,
              0.9194009900093079,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.5424050092697144,
              0.8400830030441284,
              -0.16269400715827942,
              0.6233710050582886,
              0.7647939920425415,
              0,
              0.5424050092697144,
              0.8400830030441284,
              -0.1621749997138977,
              0.5575429797172546,
              0.8141419887542725,
              -0.16269400715827942,
              0.6233710050582886,
              0.7647939920425415,
              0,
              0.6755880117416382,
              0.7372660040855408,
              0,
              0.6111029982566833,
              0.7915279865264893,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0,
              0.6111029982566833,
              0.7915279865264893,
              -0.16269400715827942,
              0.6233710050582886,
              0.7647939920425415,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.6755880117416382,
              0.7372660040855408,
              -0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              0,
              0.6755880117416382,
              0.7372660040855408,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              -0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              -0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              -0.15891000628471375,
              -0.986998975276947,
              -0.022675300016999245,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -1,
              0,
              -0.15891000628471375,
              -0.986998975276947,
              -0.022675300016999245,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              -0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              -0.15424099564552307,
              -0.8327590227127075,
              -0.5316929817199707,
              -0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              -0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              -0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              -0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              -0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              -0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              -0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              -0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              -0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              -0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              -0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              -0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              -0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              -0.15622399747371674,
              -0.3031100034713745,
              -0.9400309920310974,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              -0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              -0.15622399747371674,
              -0.3031100034713745,
              -0.9400309920310974,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778439998626709,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778439998626709,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778439998626709,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9923089742660522,
              0.12366099655628204,
              -0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              0,
              0.9923089742660522,
              0.12366099655628204,
              -0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              -0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              0,
              0.9768970012664795,
              0.21356900036334991,
              0,
              0.9535509943962097,
              0.3012180030345917,
              -0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              0,
              0.9535509943962097,
              0.3012180030345917,
              -0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              -0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8406929969787598,
              0.5414590239524841,
              -0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              0,
              0.8406929969787598,
              0.5414590239524841,
              -0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              -0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              -0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              -0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              -0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.23011000454425812,
              0.973143994808197,
              -0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              0,
              0.23011000454425812,
              0.973143994808197,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              -0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.31333398818969727,
              0.9496139883995056,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0,
              0.31333398818969727,
              0.9496139883995056,
              -0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.46974098682403564,
              0.882777988910675,
              -0.1621749997138977,
              0.5575429797172546,
              0.8141419887542725,
              0,
              0.46974098682403564,
              0.882777988910675,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              -0.1621749997138977,
              0.5575429797172546,
              0.8141419887542725,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.735588014125824,
              0.6773890256881714,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0,
              0.735588014125824,
              0.6773890256881714,
              -0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.7907649874687195,
              0.6120790243148804,
              -0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              0,
              0.7907649874687195,
              0.6120790243148804,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              -0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              -0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              0,
              0.31540900468826294,
              -0.948943018913269,
              -0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              -0.15897099673748016,
              0.9872130155563354,
              0.008728289976716042,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9743949770927429,
              -0.16315199434757233,
              -0.15463699400424957,
              -0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9743949770927429,
              -0.16315199434757233,
              -0.15463699400424957,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.9743949770927429,
              -0.16315199434757233,
              -0.15463699400424957,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.9911190271377563,
              0.07351910322904587,
              -0.11072099953889847,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              -0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              -0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              -0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              -0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              -0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9841300249099731,
              0.15051700174808502,
              -0.0937528982758522,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9794300198554993,
              0.1543630063533783,
              0.12970399856567383,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9794300198554993,
              0.1543630063533783,
              0.12970399856567383,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.9794300198554993,
              0.1543630063533783,
              0.12970399856567383,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              -0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              -0.983489990234375,
              0.1804559975862503,
              -0.012787300162017345,
              -0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.983489990234375,
              0.1804559975862503,
              -0.012787300162017345,
              -0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -1,
              0,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              0.9768970012664795,
              0.21356900036334991,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6755880117416382,
              0.7372660040855408,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6755880117416382,
              0.7372660040855408,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6755880117416382,
              0.7372660040855408,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9768970012664795,
              0.21356900036334991,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9768970012664795,
              0.21356900036334991,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              -0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              -0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              -0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              -0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              -0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              -0.700186014175415,
              -0.7069000005722046,
              -0.09991759806871414,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.15891000628471375,
              -0.986998975276947,
              -0.022675300016999245,
              -0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              -0.15897099673748016,
              0.9872130155563354,
              0.008728289976716042,
              -0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              -0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              -0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              -0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              -0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              -0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              -0.700186014175415,
              -0.7069000005722046,
              -0.09991759806871414,
              -0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.700186014175415,
              -0.7069000005722046,
              -0.09991759806871414,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              -0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.15891000628471375,
              -0.986998975276947,
              -0.022675300016999245,
              0,
              -1,
              0,
              -0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              -0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              -0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              0,
              0.9984740018844604,
              0.055024899542331696,
              -0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9984740018844604,
              0.055024899542331696,
              -0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              -0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              0,
              0.9984740018844604,
              0.055024899542331696,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9984740018844604,
              0.055024899542331696,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.280892014503479,
              -0.9597160220146179,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              -0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              -0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              -0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              -0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              -0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              -0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.280892014503479,
              -0.9597160220146179,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              -0.9867849946022034,
              0.06234930083155632,
              0.14948000013828278,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.9867849946022034,
              0.06234930083155632,
              0.14948000013828278,
              -0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              -0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              -0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              -0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              -0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              -0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              -0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              -0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              -0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.6876130104064941,
              0.30149200558662415,
              -0.6604819893836975,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              -0.6876130104064941,
              0.30149200558662415,
              -0.6604819893836975,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              -0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              -0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              -0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              -0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              -0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              -0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              -0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              -0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              -0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.9867849946022034,
              0.06234930083155632,
              0.14948000013828278,
              -0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              -0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              -0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              -0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              -0.7473369836807251,
              -0.6491289734840393,
              0.141757994890213,
              -0.9985960125923157,
              -0.04269539937376976,
              0.03112890012562275,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.9985960125923157,
              -0.04269539937376976,
              0.03112890012562275,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              -0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              -0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              -0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              -0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              -0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              -0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              -0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              -0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              -0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              -0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              -0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              -0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              -0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              -0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              -0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              -0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              -0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              -0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              -0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              -0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              -0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              -0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              -0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              -0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              -0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              -0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.700186014175415,
              -0.7069000005722046,
              -0.09991759806871414,
              -0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              -0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              -0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              0,
              -0.7071139812469482,
              0.707082986831665,
              -0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              -0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              -0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              -0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              -0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              -0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              -0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              -0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              -0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              -0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              -0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              -0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              -0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              -0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              -0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              -0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              -0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              -0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              -0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              -0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              -0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              -0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              -0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              -0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              -0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              -0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              -0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              -0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              -0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              -0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              -0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              -0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              -0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              -0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              -0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              -0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              -0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              -0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              -0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              -0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              -0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              -0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              -0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              -0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              -0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              -0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              -0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              -0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              -0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              -0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              -0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              -0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              -0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              -0.7398599982261658,
              -0.4748370051383972,
              0.4765470027923584,
              -0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              -0.7398599982261658,
              -0.4748370051383972,
              0.4765470027923584,
              -0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              -0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              -0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              -0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              -0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              -0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              -0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              -0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              -0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              -0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              -0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              -0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              -0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              -0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              -0.6625869870185852,
              0.7382429838180542,
              -0.12619400024414062,
              -0.15195199847221375,
              0.9713429808616638,
              -0.182561993598938,
              -0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              -0.15195199847221375,
              0.9713429808616638,
              -0.182561993598938,
              -0.15375199913978577,
              0.9710990190505981,
              -0.18250100314617157,
              -0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              -0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              -0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              -0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              -0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              -0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              -0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              -0.7398599982261658,
              -0.4748370051383972,
              0.4765470027923584,
              -0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              -0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              -0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              -0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48020899295806885,
              -0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              -0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              -0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              -0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              -0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              -0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              -0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              -0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              -0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              -0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              -0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              -0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              -0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              -0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              -0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              -0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              -0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              -0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              -0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              -0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              -0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              -0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              -0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              -0.15408800542354584,
              0.7627490162849426,
              -0.6280400156974792,
              -0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              -0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              -0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              -0.15408800542354584,
              0.7627490162849426,
              -0.6280400156974792,
              -0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              -0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              -0.15408800542354584,
              0.7627490162849426,
              -0.6280400156974792,
              -0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              -0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              -0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              -0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              -0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              -0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              -0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              -0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              -0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              -0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              -0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              -0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              -0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              -0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              -0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              -0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              -0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              -0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              -0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              -0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              -0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              -0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              -0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              -0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              -0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              -0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              -0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              -0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              -0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              -0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              -0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              -0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              -0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              -0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              -0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              -0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              -0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              -0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              -0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              -0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              -0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              -0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              -0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              -0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              -0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              -0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              -0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              -0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              -0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              -0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              -0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              -0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              -0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              -0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              -0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              -0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              -0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              -0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              -0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.987060010433197,
              0.16016100347042084,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              0,
              -0.987060010433197,
              0.16016100347042084,
              -0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              -0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              -0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              -0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              -0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              -0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              -0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              -0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              -0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              -0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              -0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              -0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              -0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              -0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              -0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              -0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              -0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              -0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              -0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              -0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              -0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              -0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              -0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              -0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              -0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              -0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              -0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              -0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              -0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              -0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              -0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              -0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              -0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              -0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              -0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              -0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              -0.15897099673748016,
              0.9872130155563354,
              0.008728289976716042,
              -0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              -0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              -0.15897099673748016,
              0.9872130155563354,
              0.008728289976716042,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0,
              0.6957610249519348,
              0.7182530164718628,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.9817799925804138,
              0.18988600373268127,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.9817799925804138,
              0.18988600373268127,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9817799925804138,
              0.18988600373268127,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              -0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              -0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              -0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              -0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              -0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              -0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              -0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              -0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              -0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              -0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              -0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              -0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              -0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              -0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              -0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              -0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              -0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              -0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              -0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              -0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              -0.6876130104064941,
              0.30149200558662415,
              -0.6604819893836975,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              -0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              -0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              -0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              -0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              -0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              -0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              -0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              -0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              -0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              -0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              -0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              -0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              -0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              -0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              -0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              -0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              -0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              -0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              -0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              -0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              -0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              -0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              -0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              -0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              -0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              -0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              -0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              -0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              -0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              -0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              -0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48020899295806885,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48020899295806885,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.1638839989900589,
              -0.9680780172348022,
              0.1895499974489212,
              -0.7473369836807251,
              -0.6491289734840393,
              0.141757994890213,
              -0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              -0.7473369836807251,
              -0.6491289734840393,
              0.141757994890213,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              -0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              -0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              -0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              -0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              -0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              -0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              -0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              -0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              -0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              -0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              -0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              -0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              -0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              -0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              -0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              -0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              -0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              -0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              -0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              -0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              -0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              -0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              -0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              -0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              -0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              -0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              -0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              -0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              -0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              -0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              -0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              -0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              -0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              -0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              -0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              -0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              -0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              -0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              -0.700186014175415,
              -0.7069000005722046,
              -0.09991759806871414,
              -0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              -0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.700186014175415,
              -0.7069000005722046,
              -0.09991759806871414,
              -0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              -0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              0,
              -0.7071139812469482,
              0.707082986831665,
              -0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              -0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              -0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              -0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              -0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              -0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              -0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              -0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              -0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              -0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              -0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              -0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              -0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              -0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              -0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              -0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              -0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              -0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              -0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              -0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              -0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              -0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              -0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              -0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              -0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              -0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              -0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              -0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              -0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              -0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              -0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              -0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              -0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              -0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              -0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              -0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              -0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              -0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              -0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              -0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              -0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              -0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              -0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              -0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              -0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              -0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              -0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              -0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              -0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              -0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              -0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              -0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              -0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              -0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              -0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              -0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.7398599982261658,
              -0.4748370051383972,
              0.4765470027923584,
              -0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              -0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              -0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              -0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              -0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              -0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              -0.9630730152130127,
              0.26758599281311035,
              -0.029145199805498123,
              -0.6625869870185852,
              0.7382429838180542,
              -0.12619400024414062,
              -0.9632250070571899,
              0.26706698536872864,
              -0.029053600504994392,
              -0.6625869870185852,
              0.7382429838180542,
              -0.12619400024414062,
              -0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              -0.9632250070571899,
              0.26706698536872864,
              -0.029053600504994392,
              -0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              -0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.7398599982261658,
              -0.4748370051383972,
              0.4765470027923584,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              -0.7398599982261658,
              -0.4748370051383972,
              0.4765470027923584,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              -0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              -0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              -0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              -0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              -0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              -0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              -0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              -0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              -0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.9662160277366638,
              0.2365490049123764,
              -0.1021760031580925,
              -0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              -0.9662160277366638,
              0.2365490049123764,
              -0.1021760031580925,
              -0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              -0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              -0.9662160277366638,
              0.2365490049123764,
              -0.1021760031580925,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              -0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              -0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              -0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              -0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              -0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              -0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              -0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              -0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              -0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              -0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              -0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              -0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              -0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              -0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              -0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              -0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              -0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              -0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              -0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              -0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              -0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              -0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              -0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              -0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              -0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              -0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              -0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              -0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              -0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              -0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              -0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              -0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              -0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              -0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              -0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              -0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              -0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              -0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              -0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              -0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              -0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              -0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              -0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              -0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              -0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              -0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              -0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              -0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              -0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              -0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              -0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              -0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              -0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              -0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              -0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.7071139812469482,
              0.707082986831665,
              -0.3235569894313812,
              0.15793299674987793,
              0.932889997959137,
              -0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              -0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              -0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              -0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              -0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              -0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              -0.3235569894313812,
              0.15793299674987793,
              0.932889997959137,
              -0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              -0.3235569894313812,
              0.15793299674987793,
              0.932889997959137,
              -0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              -0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              -0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              -0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              -0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              -0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              -0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              -0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              -0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              -0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              -0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              -0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              -0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              -0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              -0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              -0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              -0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              -0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              -0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              -0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              -0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0,
              0.15854400396347046,
              0.9873350262641907,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0,
              0.15854400396347046,
              0.9873350262641907,
              0,
              0.6957610249519348,
              0.7182530164718628,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0,
              0.15854400396347046,
              0.9873350262641907,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.6957610249519348,
              0.7182530164718628,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9867849946022034,
              0.06234930083155632,
              0.14948000013828278,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9867849946022034,
              0.06234930083155632,
              0.14948000013828278,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              -0.9867849946022034,
              0.06234930083155632,
              0.14948000013828278,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.9985960125923157,
              -0.04269539937376976,
              0.03112890012562275,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              -0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              -0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0,
              0,
              1,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              -0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              -0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              -0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              -0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              -0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.4917750060558319,
              0.870693027973175,
              -0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              0,
              -0.4917750060558319,
              0.870693027973175,
              -0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              -0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              -0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              -0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              -0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              -0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              -0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              -0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              -0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              -0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              -0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              0,
              -0.825190007686615,
              0.5648369789123535,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              -0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              -0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              0,
              0.619189977645874,
              -0.7852110266685486,
              0,
              0.5785390138626099,
              -0.81563800573349,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0,
              0.5785390138626099,
              -0.81563800573349,
              -0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              -0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              -0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              -0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              -0.15195199847221375,
              0.9713429808616638,
              -0.182561993598938,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              -0.15375199913978577,
              0.9710990190505981,
              -0.18250100314617157,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              -0.15375199913978577,
              0.9710990190505981,
              -0.18250100314617157,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              -0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              -0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              -0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              -0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              0,
              -0.9014250040054321,
              0.432904988527298,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48020899295806885,
              0,
              -0.9014250040054321,
              0.432904988527298,
              -0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48020899295806885,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              -0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              -0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              -0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              -0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              -0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              -0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              -0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              -0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              -0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              -0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.446943998336792,
              -0.8945279717445374,
              -0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              0,
              0.446943998336792,
              -0.8945279717445374,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              -0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              -0.15408800542354584,
              0.7627490162849426,
              -0.6280400156974792,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              -0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              -0.15408800542354584,
              0.7627490162849426,
              -0.6280400156974792,
              0,
              0.7958620190620422,
              -0.6054570078849792,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              -0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              -0.15408800542354584,
              0.7627490162849426,
              -0.6280400156974792,
              -0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              -0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              -0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              -0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              0,
              0.9015780091285706,
              -0.4325389862060547,
              0,
              0.878383994102478,
              -0.4779199957847595,
              -0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              0,
              0.878383994102478,
              -0.4779199957847595,
              -0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              -0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9015780091285706,
              -0.4325389862060547,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0,
              0.9015780091285706,
              -0.4325389862060547,
              -0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              -0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              -0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              -0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0,
              -1,
              0,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0,
              -1,
              0,
              0.000030518498533638194,
              -1,
              -0.00006103699706727639,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0.000030518498533638194,
              -1,
              -0.00006103699706727639,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0,
              -1,
              0,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0,
              -0.9999690055847168,
              0,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              0,
              -1,
              0,
              -0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              -0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              0,
              -0.9999690055847168,
              0,
              -0.00009155549923889339,
              -0.9999690055847168,
              0,
              -0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              -0.00009155549923889339,
              -0.9999690055847168,
              0,
              -0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              -0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0,
              -1,
              0,
              -0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              0,
              -1,
              0,
              -0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              0.000030518498533638194,
              -1,
              -0.00006103699706727639,
              0,
              -1,
              0,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0,
              -1,
              0,
              -0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              0,
              -1,
              0,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              -0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              0.00006103699706727639,
              -1,
              0,
              0,
              -1,
              0,
              -0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              0,
              -1,
              0,
              -0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              -0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              0,
              -1,
              0,
              0.00006103699706727639,
              -1,
              0,
              -0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              0.00006103699706727639,
              -1,
              0,
              -0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              -0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              0,
              -1,
              0,
              0,
              -0.987060010433197,
              0.16016100347042084,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031678199768066406,
              -0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              0.000030518498533638194,
              0.9994810223579407,
              0.031678199768066406,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              -0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              0.000030518498533638194,
              0.9994810223579407,
              0.031678199768066406,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              -0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              -0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              -0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              -0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              -0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              -0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              -0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              -0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              -0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.15897099673748016,
              0.9872130155563354,
              0.008728289976716042,
              -0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9817799925804138,
              0.18988600373268127,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9817799925804138,
              0.18988600373268127,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9817799925804138,
              0.18988600373268127,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              -0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              -0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              -0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              -0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              -0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              -0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              -0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              -0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              -0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              -0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              -0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              -0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              -0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              -0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.31540900468826294,
              -0.948943018913269,
              -0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              0,
              0.31540900468826294,
              -0.948943018913269,
              -0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              -0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              -0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.922544002532959,
              -0.38584598898887634,
              -0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              0,
              0.922544002532959,
              -0.38584598898887634,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              -0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              -0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              -0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              -0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              -0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              -0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              -0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              -0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              -0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              -0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.825190007686615,
              0.5648369789123535,
              -0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              0,
              -0.825190007686615,
              0.5648369789123535,
              -0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              -0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48020899295806885,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              -0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              -0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              -0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              -0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              -0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.619189977645874,
              -0.7852110266685486,
              -0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              0,
              0.619189977645874,
              -0.7852110266685486,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              -0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              0,
              0.6581929922103882,
              -0.752830982208252,
              -0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              0,
              0.6581929922103882,
              -0.752830982208252,
              -0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              -0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              0,
              0.7958620190620422,
              -0.6054570078849792,
              -0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              0,
              0.7958620190620422,
              -0.6054570078849792,
              -0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              -0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.8529919981956482,
              -0.52189701795578,
              -0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              0,
              0.8529919981956482,
              -0.52189701795578,
              -0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              -0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              -0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              0,
              -1,
              0,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              -0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              -0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              0,
              -0.9999690055847168,
              0,
              -0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              -0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              0,
              -1,
              0,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              -0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              -0.00009155549923889339,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              -0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              0,
              -1,
              0,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              -0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0,
              -0.9999690055847168,
              0,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0,
              -1,
              0,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0,
              -1,
              0,
              -0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              -0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              0,
              -0.9999690055847168,
              0,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              -0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0,
              -1,
              0,
              -0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              -0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -0.9999690055847168,
              0,
              -0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              -0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              -0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              -0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              -0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              -0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              -0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              -0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              -0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              -0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              -0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              -0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              -0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              -0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              -0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              -0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              -0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              -0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              -0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              -0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              -0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              -0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9632250070571899,
              0.26706698536872864,
              -0.029053600504994392,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9632250070571899,
              0.26706698536872864,
              -0.029053600504994392,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9662160277366638,
              0.2365490049123764,
              -0.1021760031580925,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9662160277366638,
              0.2365490049123764,
              -0.1021760031580925,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.9662160277366638,
              0.2365490049123764,
              -0.1021760031580925,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              -0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              -0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              -0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              -0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              -0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              -0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              -0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              -0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              0,
              1,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              0,
              -0.16016100347042084,
              0.987060010433197,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.3235569894313812,
              0.15793299674987793,
              0.932889997959137,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              -0.3235569894313812,
              0.15793299674987793,
              0.932889997959137,
              -0.4056209921836853,
              0,
              0.9140290021896362,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.3235569894313812,
              0.15793299674987793,
              0.932889997959137,
              -0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              -0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              -0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.9866629838943481,
              0.1595200002193451,
              0.03161719813942909,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              -0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0,
              0,
              1,
              0,
              0.15854400396347046,
              0.9873350262641907,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.15854400396347046,
              0.9873350262641907,
              0,
              0,
              1,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.15854400396347046,
              0.9873350262641907,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.000030518498533638194,
              -1,
              -0.00006103699706727639,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.00006103699706727639,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              -0.00009155549923889339,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031678199768066406,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.825190007686615,
              0.5648369789123535,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.825190007686615,
              0.5648369789123535,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.825190007686615,
              0.5648369789123535,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.619189977645874,
              -0.7852110266685486,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.619189977645874,
              -0.7852110266685486,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18814699351787567,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7958620190620422,
              -0.6054570078849792,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.7958620190620422,
              -0.6054570078849792,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.9015780091285706,
              -0.4325389862060547,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.9015780091285706,
              -0.4325389862060547,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.9015780091285706,
              -0.4325389862060547,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.619189977645874,
              -0.7852110266685486,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.7958620190620422,
              -0.6054570078849792,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.8529919981956482,
              -0.52189701795578,
              -0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              -0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.6876130104064941,
              0.30149200558662415,
              -0.6604819893836975,
              -0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.16098499298095703,
              -0.2808620035648346,
              0.9461349844932556,
              -0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              -0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              -0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              -0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              -0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              -0.6876130104064941,
              0.30149200558662415,
              -0.6604819893836975,
              -0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              -0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              -0.16098499298095703,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.314767986536026,
              0.9491559863090515,
              -0.16098499298095703,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.314767986536026,
              0.9491559863090515,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              -0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              -0.15375199913978577,
              0.9710990190505981,
              -0.18250100314617157,
              -0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              -0.15375199913978577,
              0.9710990190505981,
              -0.18250100314617157,
              -0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              -0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              -0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              -0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              -0.9632250070571899,
              0.26706698536872864,
              -0.029053600504994392,
              -0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              -0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.15375199913978577,
              0.9710990190505981,
              -0.18250100314617157,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              -0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9795830249786377,
              -0.200873002409935,
              -0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              -0.1638839989900589,
              -0.9680780172348022,
              0.1895499974489212,
              -0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              -0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              -0.9630730152130127,
              0.26758599281311035,
              -0.029145199805498123,
              -0.9632250070571899,
              0.26706698536872864,
              -0.029053600504994392,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9632250070571899,
              0.26706698536872864,
              -0.029053600504994392,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18814699351787567,
              0,
              0.9795830249786377,
              -0.200873002409935,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              -0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              -0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              -0.6881309747695923,
              0.2934660017490387,
              -0.6635640263557434,
              -0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              -0.16098499298095703,
              -0.2808620035648346,
              0.9461349844932556,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.7152320146560669,
              -0.13202300667762756,
              0.686269998550415,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              -0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              -0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              -0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              -0.6881309747695923,
              0.2934660017490387,
              -0.6635640263557434,
              -0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.7152320146560669,
              -0.13202300667762756,
              0.686269998550415,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              -0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              -0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.16098499298095703,
              -0.2808620035648346,
              0.9461349844932556,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              0.7150179743766785,
              -0.13211500644683838,
              0.6864529848098755,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.7150179743766785,
              -0.13211500644683838,
              0.6864529848098755,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.7150179743766785,
              -0.13211500644683838,
              0.6864529848098755,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.6742150187492371,
              0.29593798518180847,
              -0.6766260266304016,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              0.6742150187492371,
              0.29593798518180847,
              -0.6766260266304016,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              0.6742150187492371,
              0.29593798518180847,
              -0.6766260266304016,
              0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.7150179743766785,
              -0.1321450024843216,
              0.6864830255508423,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.7150179743766785,
              -0.1321450024843216,
              0.6864830255508423,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              -0.7150179743766785,
              -0.1321450024843216,
              0.6864830255508423,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              -0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              -0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              -0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              -0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              -0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              -0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              -0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              -0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              -0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              -0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              -0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              -0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              -0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              -0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              -0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              -0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              -0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              -0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              -0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              -0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              -0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              -0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              -0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              -0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              -0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              -0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              -0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              -0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              -0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              -0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              -0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              -0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              -0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              -0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              -0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              -0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              -0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              -0.15427100658416748,
              0.3161410093307495,
              -0.9360640048980713,
              -0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              -0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              -0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              -0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              -0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              -0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              -0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              -0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              -0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              -0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              -0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              -0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              -0.15427100658416748,
              0.3161410093307495,
              -0.9360640048980713,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.15427100658416748,
              0.3161410093307495,
              -0.9360640048980713,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              0.6742150187492371,
              0.29593798518180847,
              -0.6766260266304016,
              0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              0.6742150187492371,
              0.29593798518180847,
              -0.6766260266304016,
              0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              0.6742150187492371,
              0.29593798518180847,
              -0.6766260266304016,
              0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              -0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              -0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              -0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              -0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              -0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              -0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              -0.15427100658416748,
              0.3161410093307495,
              -0.9360640048980713,
              -0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              -0.15427100658416748,
              0.3161410093307495,
              -0.9360640048980713,
              -0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              -0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              -0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.15427100658416748,
              0.3161410093307495,
              -0.9360640048980713,
              -0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              -0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              -0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              -0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              -0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              -0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              -0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              -0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              -0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              -0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              -0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              -0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              -0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              -0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              -0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              -0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              -0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              -0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              -0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              -0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              -0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              -0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              -0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              -0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              -0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              -0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              -0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              -0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              -0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              -0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              -0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              -0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              -0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              -0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              -0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              -0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              -0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.6885889768600464,
              0.29334399104118347,
              -0.6631370186805725,
              0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              0.7152619957923889,
              -0.13202300667762756,
              0.6862390041351318,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747310280799866,
              0.16467800736427307,
              -0.15070000290870667,
              0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747310280799866,
              0.16467800736427307,
              -0.15070000290870667,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              0.9747310280799866,
              0.16467800736427307,
              -0.15070000290870667,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0.1609240025281906,
              -0.280892014503479,
              0.9461349844932556,
              0.7152619957923889,
              -0.13202300667762756,
              0.6862390041351318,
              0.1609240025281906,
              -0.280892014503479,
              0.9461349844932556,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.7152619957923889,
              -0.13202300667762756,
              0.6862390041351318,
              0.1609240025281906,
              -0.280892014503479,
              0.9461349844932556,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              0.9747310280799866,
              0.16467800736427307,
              -0.15070000290870667,
              0.6885889768600464,
              0.29334399104118347,
              -0.6631370186805725,
              0.9747310280799866,
              0.16467800736427307,
              -0.15070000290870667,
              0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              0.6885889768600464,
              0.29334399104118347,
              -0.6631370186805725,
              0.9747310280799866,
              0.16467800736427307,
              -0.15070000290870667,
              0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.1609240025281906,
              -0.280892014503479,
              0.9461349844932556,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.1609240025281906,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0.1609240025281906,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              -0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              -0.6881309747695923,
              0.2934660017490387,
              -0.6635640263557434,
              -0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              -0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              -0.6881309747695923,
              0.2934660017490387,
              -0.6635640263557434,
              -0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              -0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              -0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              -0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              -0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              -0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              -0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              -0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              -0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              -0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              -0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              -0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              -0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              -0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              -0.7152320146560669,
              -0.13202300667762756,
              0.686269998550415,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              -0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              -0.7152320146560669,
              -0.13202300667762756,
              0.686269998550415,
              -0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.7152320146560669,
              -0.13202300667762756,
              0.686269998550415,
              -0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.6881309747695923,
              0.2934660017490387,
              -0.6635640263557434,
              -0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              -0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              -0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              -0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              -0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              -0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              -0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              -0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              -0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              -0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              -0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              -0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              -0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              -0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              -0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              -0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              -0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              -0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              -0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              -0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              -0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              -0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              -0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              -0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              -0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              -0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              -0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              -0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              -0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              -0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              -0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              -0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              -0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.00042725898674689233,
              0.5188149809837341,
              -0.8548539876937866,
              0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              0.00018311099847778678,
              -0.8526570200920105,
              -0.5224159955978394,
              0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              0.005706959869712591,
              -0.43852001428604126,
              -0.8986790180206299,
              0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              0.25232699513435364,
              -0.18460600078105927,
              -0.9498580098152161,
              0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              0.25232699513435364,
              -0.18460600078105927,
              -0.9498580098152161,
              0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              0.25232699513435364,
              -0.18460600078105927,
              -0.9498580098152161,
              0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              0.106113001704216,
              -0.2722249925136566,
              -0.9563590288162231,
              0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              0.01922670006752014,
              0.020630499348044395,
              -0.9995729923248291,
              0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              0.01922670006752014,
              0.020630499348044395,
              -0.9995729923248291,
              0.01922670006752014,
              -0.3935669958591461,
              -0.919064998626709,
              0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              0.18601000308990479,
              -0.24811500310897827,
              -0.9506819844245911,
              0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              0.01922670006752014,
              0.020630499348044395,
              -0.9995729923248291,
              0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.005706959869712591,
              -0.43852001428604126,
              -0.8986790180206299,
              0.01922670006752014,
              -0.3935669958591461,
              -0.919064998626709,
              0.005706959869712591,
              -0.43852001428604126,
              -0.8986790180206299,
              0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              0.01922670006752014,
              -0.3935669958591461,
              -0.919064998626709,
              0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              0.7224649786949158,
              -0.13190099596977234,
              -0.6786710023880005,
              0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.7224649786949158,
              -0.13190099596977234,
              -0.6786710023880005,
              0.7224649786949158,
              -0.13190099596977234,
              -0.6786710023880005,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              0.3168739974498749,
              -0.18295800685882568,
              -0.9306309819221497,
              0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              0.7115089893341064,
              -0.13455599546432495,
              -0.6896269917488098,
              0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              0.21042500436306,
              -0.17407800257205963,
              -0.961974024772644,
              0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              0.21042500436306,
              -0.17407800257205963,
              -0.961974024772644,
              0.6839810013771057,
              -0.13843199610710144,
              -0.7162079811096191,
              0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              0.20895999670028687,
              -0.19898100197315216,
              -0.9574570059776306,
              0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              0,
              0.5444499850273132,
              -0.8387709856033325,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              0,
              0.08221690356731415,
              -0.996612012386322,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              0,
              0.08221690356731415,
              -0.996612012386322,
              0,
              -0.5172280073165894,
              -0.8558310270309448,
              0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              0,
              -0.8609269857406616,
              -0.5086820125579834,
              0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              0,
              -0.8609269857406616,
              -0.5086820125579834,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              0.00018311099847778678,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              0.009521770291030407,
              -0.018067000433802605,
              -0.9997860193252563,
              0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              0.00042725898674689233,
              0.5188149809837341,
              -0.8548539876937866,
              0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              0.005706959869712591,
              -0.43852001428604126,
              -0.8986790180206299,
              0.011719100177288055,
              -0.41059601306915283,
              -0.9117100238800049,
              0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              0.011719100177288055,
              -0.41059601306915283,
              -0.9117100238800049,
              0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              0.03375349938869476,
              -0.02539139986038208,
              -0.9990839958190918,
              0.009521770291030407,
              -0.018067000433802605,
              -0.9997860193252563,
              0.13101600110530853,
              0.5105440020561218,
              -0.8497880101203918,
              0.009521770291030407,
              -0.018067000433802605,
              -0.9997860193252563,
              0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              0.13101600110530853,
              0.5105440020561218,
              -0.8497880101203918,
              0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              0.04257329925894737,
              -0.40073901414871216,
              -0.9151890277862549,
              0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              0.011719100177288055,
              -0.41059601306915283,
              -0.9117100238800049,
              0.04257329925894737,
              -0.40073901414871216,
              -0.9151890277862549,
              0.06430249661207199,
              -0.0378430001437664,
              -0.9971920251846313,
              0.03375349938869476,
              -0.02539139986038208,
              -0.9990839958190918,
              0.25666099786758423,
              0.46937501430511475,
              -0.8448439836502075,
              0.03375349938869476,
              -0.02539139986038208,
              -0.9990839958190918,
              0.13101600110530853,
              0.5105440020561218,
              -0.8497880101203918,
              0.25666099786758423,
              0.46937501430511475,
              -0.8448439836502075,
              0.2700279951095581,
              -0.7733700275421143,
              -0.5735340118408203,
              0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              0.08078250288963318,
              -0.3808099925518036,
              -0.9210789799690247,
              0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              0.04257329925894737,
              -0.40073901414871216,
              -0.9151890277862549,
              0.08078250288963318,
              -0.3808099925518036,
              -0.9210789799690247,
              0.09265419840812683,
              -0.054628100246191025,
              -0.9941710233688354,
              0.06430249661207199,
              -0.0378430001437664,
              -0.9971920251846313,
              0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              0.06430249661207199,
              -0.0378430001437664,
              -0.9971920251846313,
              0.25666099786758423,
              0.46937501430511475,
              -0.8448439836502075,
              0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              0.2700279951095581,
              -0.7733700275421143,
              -0.5735340118408203,
              0.11359000205993652,
              -0.35593700408935547,
              -0.9275490045547485,
              0.2700279951095581,
              -0.7733700275421143,
              -0.5735340118408203,
              0.08078250288963318,
              -0.3808099925518036,
              -0.9210789799690247,
              0.11359000205993652,
              -0.35593700408935547,
              -0.9275490045547485,
              0.11758799850940704,
              -0.07510609924793243,
              -0.9902039766311646,
              0.09265419840812683,
              -0.054628100246191025,
              -0.9941710233688354,
              0.4738300144672394,
              0.32419800758361816,
              -0.8187199831008911,
              0.09265419840812683,
              -0.054628100246191025,
              -0.9941710233688354,
              0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              0.4738300144672394,
              0.32419800758361816,
              -0.8187199831008911,
              0.4917449951171875,
              -0.6181219816207886,
              -0.6132389903068542,
              0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              0.14072099328041077,
              -0.3272800147533417,
              -0.9343550205230713,
              0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              0.11359000205993652,
              -0.35593700408935547,
              -0.9275490045547485,
              0.14072099328041077,
              -0.3272800147533417,
              -0.9343550205230713,
              0.13965299725532532,
              -0.09790340065956116,
              -0.9853209853172302,
              0.11758799850940704,
              -0.07510609924793243,
              -0.9902039766311646,
              0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              0.11758799850940704,
              -0.07510609924793243,
              -0.9902039766311646,
              0.4738300144672394,
              0.32419800758361816,
              -0.8187199831008911,
              0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              0.4917449951171875,
              -0.6181219816207886,
              -0.6132389903068542,
              0.16245000064373016,
              -0.29545000195503235,
              -0.9414349794387817,
              0.4917449951171875,
              -0.6181219816207886,
              -0.6132389903068542,
              0.14072099328041077,
              -0.3272800147533417,
              -0.9343550205230713,
              0.16245000064373016,
              -0.29545000195503235,
              -0.9414349794387817,
              0.16049699485301971,
              -0.12329500168561935,
              -0.979278028011322,
              0.13965299725532532,
              -0.09790340065956116,
              -0.9853209853172302,
              0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              0.13965299725532532,
              -0.09790340065956116,
              -0.9853209853172302,
              0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              0.17841100692749023,
              -0.2613610029220581,
              -0.9485759735107422,
              0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              0.16245000064373016,
              -0.29545000195503235,
              -0.9414349794387817,
              0.17841100692749023,
              -0.2613610029220581,
              -0.9485759735107422,
              0.1799979954957962,
              -0.1530809998512268,
              -0.9716789722442627,
              0.16049699485301971,
              -0.12329500168561935,
              -0.979278028011322,
              0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              0.16049699485301971,
              -0.12329500168561935,
              -0.979278028011322,
              0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              0.18903200328350067,
              -0.22617299854755402,
              -0.9555349946022034,
              0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              0.17841100692749023,
              -0.2613610029220581,
              -0.9485759735107422,
              0.18903200328350067,
              -0.22617299854755402,
              -0.9555349946022034,
              0.19238899648189545,
              -0.177374005317688,
              -0.9651479721069336,
              0.1799979954957962,
              -0.1530809998512268,
              -0.9716789722442627,
              0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              0.1799979954957962,
              -0.1530809998512268,
              -0.9716789722442627,
              0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              0.6863309741020203,
              -0.17111699283123016,
              -0.7068390250205994,
              0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              0.1943420022726059,
              -0.1986750066280365,
              -0.9605699777603149,
              0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              0.18903200328350067,
              -0.22617299854755402,
              -0.9555349946022034,
              0.1943420022726059,
              -0.1986750066280365,
              -0.9605699777603149,
              0.21042500436306,
              -0.17407800257205963,
              -0.961974024772644,
              0.19238899648189545,
              -0.177374005317688,
              -0.9651479721069336,
              0.6839810013771057,
              -0.13843199610710144,
              -0.7162079811096191,
              0.19238899648189545,
              -0.177374005317688,
              -0.9651479721069336,
              0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              0.6839810013771057,
              -0.13843199610710144,
              -0.7162079811096191,
              0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              0.6863309741020203,
              -0.17111699283123016,
              -0.7068390250205994,
              0.20895999670028687,
              -0.19898100197315216,
              -0.9574570059776306,
              0.6863309741020203,
              -0.17111699283123016,
              -0.7068390250205994,
              0.1943420022726059,
              -0.1986750066280365,
              -0.9605699777603149,
              0.20895999670028687,
              -0.19898100197315216,
              -0.9574570059776306,
              0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              0,
              -0.115665003657341,
              -0.9932550191879272,
              0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              0,
              -0.16272500157356262,
              -0.9866629838943481,
              0,
              -0.115665003657341,
              -0.9932550191879272,
              0,
              -0.2186959981918335,
              -0.9757680296897888,
              0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              0,
              -0.2648090124130249,
              -0.9642930030822754,
              0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              0,
              -0.2648090124130249,
              -0.9642930030822754,
              0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              0,
              -0.16272500157356262,
              -0.9866629838943481,
              0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              0,
              -0.19080199301242828,
              -0.9815970063209534,
              0,
              -0.16272500157356262,
              -0.9866629838943481,
              0,
              -0.19080199301242828,
              -0.9815970063209534,
              0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              0,
              -0.2186959981918335,
              -0.9757680296897888,
              0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              0,
              -0.2186959981918335,
              -0.9757680296897888,
              0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              0.1422470062971115,
              -0.17590899765491486,
              -0.974058985710144,
              0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              0.04361090064048767,
              -0.16666199266910553,
              -0.9850460290908813,
              0.04577780142426491,
              -0.19058799743652344,
              -0.9805899858474731,
              0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              0.14594000577926636,
              -0.1887570023536682,
              -0.9710990190505981,
              0.1422470062971115,
              -0.2017579972743988,
              -0.9690240025520325,
              0.04361090064048767,
              -0.21445399522781372,
              -0.975737988948822,
              0.25232699513435364,
              -0.18460600078105927,
              -0.9498580098152161,
              0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              0.25232699513435364,
              -0.18460600078105927,
              -0.9498580098152161,
              0.3186739981174469,
              -0.1808529943227768,
              -0.9304180145263672,
              0.2507709860801697,
              -0.1817380040884018,
              -0.9508039951324463,
              0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              0.3187659978866577,
              -0.1808830052614212,
              -0.930387020111084,
              0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              0.2507709860801697,
              -0.18765799701213837,
              -0.9496750235557556,
              0.3187960088253021,
              -0.18079200387001038,
              -0.930387020111084,
              0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              0,
              0.015930699184536934,
              -0.9998469948768616,
              0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              0,
              -0.04147469997406006,
              -0.999114990234375,
              0,
              0.015930699184536934,
              -0.9998469948768616,
              0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              0,
              -0.04147469997406006,
              -0.999114990234375,
              0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              0,
              -0.115665003657341,
              -0.9932550191879272,
              0,
              -0.04147469997406006,
              -0.999114990234375,
              0,
              -0.2648090124130249,
              -0.9642930030822754,
              0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              0,
              -0.33579498529434204,
              -0.9419230222702026,
              0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              0,
              -0.33579498529434204,
              -0.9419230222702026,
              0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              0.09973450005054474,
              -0.10589899867773056,
              -0.9893490076065063,
              0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              0.12851299345493317,
              -0.15112799406051636,
              -0.980102002620697,
              0.03656119853258133,
              -0.1248209998011589,
              -0.9914849996566772,
              0.023621300235390663,
              -0.0567949004471302,
              -0.9980769753456116,
              0.03656119853258133,
              -0.2556839883327484,
              -0.9660630226135254,
              0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              0.12851299345493317,
              -0.2269970029592514,
              -0.9653609991073608,
              0.106113001704216,
              -0.2722249925136566,
              -0.9563590288162231,
              0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              0.24411800503730774,
              -0.17542000114917755,
              -0.9537339806556702,
              0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              0.3191629946231842,
              -0.1809139996767044,
              -0.9302350282669067,
              0.3168739974498749,
              -0.18295800685882568,
              -0.9306309819221497,
              0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              0.24411800503730774,
              -0.19458599388599396,
              -0.9500110149383545,
              0.3192239999771118,
              -0.18070000410079956,
              -0.9302650094032288,
              0.22507399320602417,
              -0.15942899882793427,
              -0.9611799716949463,
              0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              0.31412699818611145,
              -0.1791439950466156,
              -0.9323099851608276,
              0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              0,
              0.05935849994421005,
              -0.99822998046875,
              0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              0,
              0.029664000496268272,
              -0.9995419979095459,
              0,
              0.05935849994421005,
              -0.99822998046875,
              0,
              -0.40192899107933044,
              -0.9156469702720642,
              0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              0,
              -0.42896801233291626,
              -0.9032869935035706,
              0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              0,
              -0.42896801233291626,
              -0.9032869935035706,
              0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              0,
              0.029664000496268272,
              -0.9995419979095459,
              0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              0,
              0.015930699184536934,
              -0.9998469948768616,
              0,
              0.029664000496268272,
              -0.9995419979095459,
              0,
              -0.38932499289512634,
              -0.9210789799690247,
              0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              0,
              -0.40192899107933044,
              -0.9156469702720642,
              0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              0,
              -0.40192899107933044,
              -0.9156469702720642,
              0.01922670006752014,
              0.020630499348044395,
              -0.9995729923248291,
              0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              0.01922670006752014,
              -0.3935669958591461,
              -0.919064998626709,
              0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              0.03756830096244812,
              -0.01638839952647686,
              -0.9991459846496582,
              0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              0.06390579789876938,
              -0.0467849001288414,
              -0.9968569874763489,
              0.010315299965441227,
              0.00494399992749095,
              -0.9999079704284668,
              0.003784300060942769,
              0.02438429929316044,
              -0.9996950030326843,
              0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              0.03756830096244812,
              -0.3590810000896454,
              -0.9325240254402161,
              0.003784300060942769,
              -0.3970760107040405,
              -0.9177529811859131,
              0.19058799743652344,
              -0.12591899931430817,
              -0.9735400080680847,
              0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              0.2917869985103607,
              -0.17206299304962158,
              -0.9408550262451172,
              0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              0.18601000308990479,
              -0.24811500310897827,
              -0.9506819844245911,
              0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              0.14801500737667084,
              -0.100040003657341,
              -0.9838860034942627,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.21042500436306,
              -0.17407800257205963,
              -0.961974024772644,
              0.2501299977302551,
              -0.16482999920845032,
              -0.954069972038269,
              0.20895999670028687,
              -0.19898100197315216,
              -0.9574570059776306,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.14801500737667084,
              -0.27579599618911743,
              -0.9497359991073608,
              0.24729099869728088,
              -0.20465700328350067,
              -0.9470499753952026,
              0,
              0.08221690356731415,
              -0.996612012386322,
              0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              0,
              0.05935849994421005,
              -0.99822998046875,
              0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              0,
              0.05935849994421005,
              -0.99822998046875,
              0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              0,
              -0.42896801233291626,
              -0.9032869935035706,
              0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              0,
              -0.5172280073165894,
              -0.8558310270309448,
              0,
              -0.42896801233291626,
              -0.9032869935035706,
              0.00018311099847778678,
              0.08090460300445557,
              -0.9967039823532104,
              0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              0.005249179899692535,
              0.055848900228738785,
              -0.9984130263328552,
              0.01922670006752014,
              0.020630499348044395,
              -0.9995729923248291,
              0.0014648899668827653,
              0.05517749860882759,
              -0.9984740018844604,
              0.01922670006752014,
              -0.3935669958591461,
              -0.919064998626709,
              0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              0.005279699806123972,
              -0.48982200026512146,
              -0.8717920184135437,
              0.00018311099847778678,
              -0.515824019908905,
              -0.8566550016403198,
              0.0014648899668827653,
              -0.4251840114593506,
              -0.9050869941711426,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.005706959869712591,
              0.007934809662401676,
              -0.9999390244483948,
              0.009521770291030407,
              -0.018067000433802605,
              -0.9997860193252563,
              0.005706959869712591,
              -0.43852001428604126,
              -0.8986790180206299,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.011719100177288055,
              -0.41059601306915283,
              -0.9117100238800049,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.009521770291030407,
              -0.018067000433802605,
              -0.9997860193252563,
              0.03375349938869476,
              -0.02539139986038208,
              -0.9990839958190918,
              0.011719100177288055,
              -0.41059601306915283,
              -0.9117100238800049,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.04257329925894737,
              -0.40073901414871216,
              -0.9151890277862549,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.03375349938869476,
              -0.02539139986038208,
              -0.9990839958190918,
              0.06430249661207199,
              -0.0378430001437664,
              -0.9971920251846313,
              0.04257329925894737,
              -0.40073901414871216,
              -0.9151890277862549,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.08078250288963318,
              -0.3808099925518036,
              -0.9210789799690247,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.06430249661207199,
              -0.0378430001437664,
              -0.9971920251846313,
              0.09265419840812683,
              -0.054628100246191025,
              -0.9941710233688354,
              0.08078250288963318,
              -0.3808099925518036,
              -0.9210789799690247,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.11359000205993652,
              -0.35593700408935547,
              -0.9275490045547485,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.09265419840812683,
              -0.054628100246191025,
              -0.9941710233688354,
              0.11758799850940704,
              -0.07510609924793243,
              -0.9902039766311646,
              0.11359000205993652,
              -0.35593700408935547,
              -0.9275490045547485,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.14072099328041077,
              -0.3272800147533417,
              -0.9343550205230713,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.11758799850940704,
              -0.07510609924793243,
              -0.9902039766311646,
              0.13965299725532532,
              -0.09790340065956116,
              -0.9853209853172302,
              0.14072099328041077,
              -0.3272800147533417,
              -0.9343550205230713,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.16245000064373016,
              -0.29545000195503235,
              -0.9414349794387817,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.13965299725532532,
              -0.09790340065956116,
              -0.9853209853172302,
              0.16049699485301971,
              -0.12329500168561935,
              -0.979278028011322,
              0.16245000064373016,
              -0.29545000195503235,
              -0.9414349794387817,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.17841100692749023,
              -0.2613610029220581,
              -0.9485759735107422,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.16049699485301971,
              -0.12329500168561935,
              -0.979278028011322,
              0.1799979954957962,
              -0.1530809998512268,
              -0.9716789722442627,
              0.17841100692749023,
              -0.2613610029220581,
              -0.9485759735107422,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.18903200328350067,
              -0.22617299854755402,
              -0.9555349946022034,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.1799979954957962,
              -0.1530809998512268,
              -0.9716789722442627,
              0.19238899648189545,
              -0.177374005317688,
              -0.9651479721069336,
              0.18903200328350067,
              -0.22617299854755402,
              -0.9555349946022034,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.1943420022726059,
              -0.1986750066280365,
              -0.9605699777603149,
              0.09265419840812683,
              -0.06378369778394699,
              -0.9936220049858093,
              0.19238899648189545,
              -0.177374005317688,
              -0.9651479721069336,
              0.21042500436306,
              -0.17407800257205963,
              -0.961974024772644,
              0.1943420022726059,
              -0.1986750066280365,
              -0.9605699777603149,
              0.09262370318174362,
              -0.3130280077457428,
              -0.9451889991760254,
              0.20895999670028687,
              -0.19898100197315216,
              -0.9574570059776306,
              0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.24240900576114655,
              0.19629499316215515,
              0.9501019716262817,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              0.24240900576114655,
              0.19629499316215515,
              0.9501019716262817,
              0.16113799810409546,
              -0.6795250177383423,
              0.715690016746521,
              0.3138220012187958,
              -0.6317939758300781,
              0.708761990070343,
              0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              0.3138220012187958,
              -0.6317939758300781,
              0.708761990070343,
              0.08990749716758728,
              -0.029419800266623497,
              0.9955139756202698,
              0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              0.08990749716758728,
              0.40022000670433044,
              0.9119849801063538,
              0.3137910068035126,
              0.8512529730796814,
              0.4205150008201599,
              0.04605240002274513,
              0.4169439971446991,
              0.907742977142334,
              0.3137910068035126,
              0.8512529730796814,
              0.4205150008201599,
              0.16113799810409546,
              0.8981289863586426,
              0.4091010093688965,
              0.04605240002274513,
              0.4169439971446991,
              0.907742977142334,
              0.6790980100631714,
              -0.33591699600219727,
              0.6526380181312561,
              0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              0.5775319933891296,
              -0.45680099725723267,
              0.6765949726104736,
              0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              0.16510500013828278,
              0.023682400584220886,
              0.9859610199928284,
              0.5775319933891296,
              -0.45680099725723267,
              0.6765949726104736,
              0.16504399478435516,
              0.34730100631713867,
              0.9230930209159851,
              0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              0.5775020122528076,
              0.6769620180130005,
              0.45625200867652893,
              0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              0.6790980100631714,
              0.5559250116348267,
              0.47929298877716064,
              0.5775020122528076,
              0.6769620180130005,
              0.45625200867652893,
              0.0406200997531414,
              -0.6891999840736389,
              0.7234110236167908,
              0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              0,
              -0.05215609818696976,
              0.9986270070075989,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              0.422529011964798,
              0.9063389897346497,
              0.011536000296473503,
              0.4231390058994293,
              0.9059720039367676,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0.011536000296473503,
              0.4231390058994293,
              0.9059720039367676,
              0.0406200997531414,
              0.9099699854850769,
              0.4126099944114685,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0.6790980100631714,
              -0.33591699600219727,
              0.6526380181312561,
              0.755424976348877,
              -0.1982789933681488,
              0.6244999766349792,
              0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              0.755424976348877,
              -0.1982789933681488,
              0.6244999766349792,
              0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              0.755424976348877,
              0.4177980124950409,
              0.5047150254249573,
              0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              0.755424976348877,
              0.4177980124950409,
              0.5047150254249573,
              0.6790980100631714,
              0.5559250116348267,
              0.47929298877716064,
              0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              0.16113799810409546,
              -0.6795250177383423,
              0.715690016746521,
              0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              0.0406200997531414,
              -0.6891999840736389,
              0.7234110236167908,
              0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              0.0406200997531414,
              -0.6891999840736389,
              0.7234110236167908,
              0.011536000296473503,
              0.4231390058994293,
              0.9059720039367676,
              0.04605240002274513,
              0.4169439971446991,
              0.907742977142334,
              0.0406200997531414,
              0.9099699854850769,
              0.4126099944114685,
              0.04605240002274513,
              0.4169439971446991,
              0.907742977142334,
              0.16113799810409546,
              0.8981289863586426,
              0.4091010093688965,
              0.0406200997531414,
              0.9099699854850769,
              0.4126099944114685,
              0.755424976348877,
              -0.1982789933681488,
              0.6244999766349792,
              0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              0.23618300259113312,
              0.13986599445343018,
              0.9615769982337952,
              0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              0.755424976348877,
              0.4177980124950409,
              0.5047150254249573,
              0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              0.4542680084705353,
              -0.5565969944000244,
              0.6955469846725464,
              0.5775319933891296,
              -0.45680099725723267,
              0.6765949726104736,
              0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              0.5775319933891296,
              -0.45680099725723267,
              0.6765949726104736,
              0.16510500013828278,
              0.023682400584220886,
              0.9859610199928284,
              0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              0.16504399478435516,
              0.34730100631713867,
              0.9230930209159851,
              0.5775020122528076,
              0.6769620180130005,
              0.45625200867652893,
              0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              0.5775020122528076,
              0.6769620180130005,
              0.45625200867652893,
              0.4542370140552521,
              0.7766349911689758,
              0.4364149868488312,
              0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              0.23618300259113312,
              0.13986599445343018,
              0.9615769982337952,
              0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              0.23618300259113312,
              0.13986599445343018,
              0.9615769982337952,
              0.24240900576114655,
              0.19629499316215515,
              0.9501019716262817,
              0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              0.4542680084705353,
              -0.5565969944000244,
              0.6955469846725464,
              0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              0.3138220012187958,
              -0.6317939758300781,
              0.708761990070343,
              0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              0.08990749716758728,
              -0.029419800266623497,
              0.9955139756202698,
              0.3138220012187958,
              -0.6317939758300781,
              0.708761990070343,
              0.08990749716758728,
              0.40022000670433044,
              0.9119849801063538,
              0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              0.3137910068035126,
              0.8512529730796814,
              0.4205150008201599,
              0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              0.4542370140552521,
              0.7766349911689758,
              0.4364149868488312,
              0.3137910068035126,
              0.8512529730796814,
              0.4205150008201599,
              0,
              -0.05215609818696976,
              0.9986270070075989,
              0,
              -0.05203409865498543,
              0.9986270070075989,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.05203409865498543,
              0.9986270070075989,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              0.9070709943771362,
              0.4209110140800476,
              0,
              0.42240700125694275,
              0.9064000248908997,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0,
              0.42240700125694275,
              0.9064000248908997,
              0,
              0.422529011964798,
              0.9063389897346497,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0.09082309901714325,
              0.1900079995393753,
              0.9775689840316772,
              0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.24240900576114655,
              0.19629499316215515,
              0.9501019716262817,
              0.16895000636577606,
              0.19598999619483948,
              0.9659109711647034,
              0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              0.16895000636577606,
              0.19598999619483948,
              0.9659109711647034,
              0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              0.23618300259113312,
              0.13986599445343018,
              0.9615769982337952,
              0.24237799644470215,
              0.173894003033638,
              0.9544360041618347,
              0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              0.164342001080513,
              0.21884800493717194,
              0.9617909789085388,
              0.24240900576114655,
              0.19629499316215515,
              0.9501019716262817,
              0.164342001080513,
              0.21884800493717194,
              0.9617909789085388,
              0.16895000636577606,
              0.19598999619483948,
              0.9659109711647034,
              0.24240900576114655,
              0.19629499316215515,
              0.9501019716262817,
              0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              0.23618300259113312,
              0.13986599445343018,
              0.9615769982337952,
              0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              0.23618300259113312,
              0.13986599445343018,
              0.9615769982337952,
              0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              0.15286700427532196,
              0.24948899447917938,
              0.9562060236930847,
              0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              0.15286700427532196,
              0.24948899447917938,
              0.9562060236930847,
              0.164342001080513,
              0.21884800493717194,
              0.9617909789085388,
              0.23618300259113312,
              0.23053699731826782,
              0.9439370036125183,
              0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              0.07705920189619064,
              0.13974399864673615,
              0.9871519804000854,
              0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              0.07705920189619064,
              0.13974399864673615,
              0.9871519804000854,
              0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              0.2187570035457611,
              0.097018301486969,
              0.9709159731864929,
              0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              0.1365090012550354,
              0.27713900804519653,
              0.9510480165481567,
              0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              0.1365090012550354,
              0.27713900804519653,
              0.9510480165481567,
              0.15286700427532196,
              0.24948899447917938,
              0.9562060236930847,
              0.2187570035457611,
              0.27372100949287415,
              0.9365829825401306,
              0.07705920189619064,
              0.13974399864673615,
              0.9871519804000854,
              0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              0.16510500013828278,
              0.023682400584220886,
              0.9859610199928284,
              0.19473899900913239,
              0.05801570042967796,
              0.9791250228881836,
              0.16504399478435516,
              0.34730100631713867,
              0.9230930209159851,
              0.11532899737358093,
              0.30201101303100586,
              0.9462869763374329,
              0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              0.11532899737358093,
              0.30201101303100586,
              0.9462869763374329,
              0.1365090012550354,
              0.27713900804519653,
              0.9510480165481567,
              0.19470800459384918,
              0.3129369914531708,
              0.9295629858970642,
              0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              0.16510500013828278,
              0.023682400584220886,
              0.9859610199928284,
              0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              0.16510500013828278,
              0.023682400584220886,
              0.9859610199928284,
              0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              0.09070099890232086,
              0.3226720094680786,
              0.9421370029449463,
              0.16504399478435516,
              0.34730100631713867,
              0.9230930209159851,
              0.09070099890232086,
              0.3226720094680786,
              0.9421370029449463,
              0.11532899737358093,
              0.30201101303100586,
              0.9462869763374329,
              0.16504399478435516,
              0.34730100631713867,
              0.9230930209159851,
              0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              0.08990749716758728,
              -0.029419800266623497,
              0.9955139756202698,
              0.1300390064716339,
              -0.005798520054668188,
              0.9914849996566772,
              0.08990749716758728,
              0.40022000670433044,
              0.9119849801063538,
              0.0627766028046608,
              0.33817601203918457,
              0.938962996006012,
              0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              0.0627766028046608,
              0.33817601203918457,
              0.938962996006012,
              0.09070099890232086,
              0.3226720094680786,
              0.9421370029449463,
              0.1300089955329895,
              0.37678200006484985,
              0.9171119928359985,
              0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              0.08990749716758728,
              -0.029419800266623497,
              0.9955139756202698,
              0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              0.08990749716758728,
              -0.029419800266623497,
              0.9955139756202698,
              0.04605240002274513,
              0.4169439971446991,
              0.907742977142334,
              0.031495101749897,
              0.3491320013999939,
              0.9365209937095642,
              0.08990749716758728,
              0.40022000670433044,
              0.9119849801063538,
              0.031495101749897,
              0.3491320013999939,
              0.9365209937095642,
              0.0627766028046608,
              0.33817601203918457,
              0.938962996006012,
              0.08990749716758728,
              0.40022000670433044,
              0.9119849801063538,
              0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              0.04605240002274513,
              -0.04657119885087013,
              0.9978330135345459,
              0.011536000296473503,
              0.4231390058994293,
              0.9059720039367676,
              0.007782219909131527,
              0.35407599806785583,
              0.9351480007171631,
              0.04605240002274513,
              0.4169439971446991,
              0.907742977142334,
              0.007782219909131527,
              0.35407599806785583,
              0.9351480007171631,
              0.031495101749897,
              0.3491320013999939,
              0.9365209937095642,
              0.04605240002274513,
              0.4169439971446991,
              0.907742977142334,
              0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              0,
              0.10062000155448914,
              0.994903028011322,
              0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              0,
              0.10062000155448914,
              0.994903028011322,
              0,
              -0.05215609818696976,
              0.9986270070075989,
              0.011566500179469585,
              -0.05291910097002983,
              0.9985049962997437,
              0,
              0.422529011964798,
              0.9063389897346497,
              0,
              0.3546859920024872,
              0.9349650144577026,
              0.011536000296473503,
              0.4231390058994293,
              0.9059720039367676,
              0,
              0.3546859920024872,
              0.9349650144577026,
              0.007782219909131527,
              0.35407599806785583,
              0.9351480007171631,
              0.011536000296473503,
              0.4231390058994293,
              0.9059720039367676,
              0,
              0.10062000155448914,
              0.994903028011322,
              0,
              0.10074199736118317,
              0.994903028011322,
              0,
              -0.05215609818696976,
              0.9986270070075989,
              0,
              0.10074199736118317,
              0.994903028011322,
              0,
              -0.05203409865498543,
              0.9986270070075989,
              0,
              -0.05215609818696976,
              0.9986270070075989,
              0,
              0.42240700125694275,
              0.9064000248908997,
              0,
              0.35453400015830994,
              0.9350259900093079,
              0,
              0.422529011964798,
              0.9063389897346497,
              0,
              0.35453400015830994,
              0.9350259900093079,
              0,
              0.3546859920024872,
              0.9349650144577026,
              0,
              0.422529011964798,
              0.9063389897346497,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.002899260027334094,
              0.19067999720573425,
              0.9816280007362366,
              0.002899260027334094,
              0.19067999720573425,
              0.9816280007362366,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.002899260027334094,
              0.19025200605392456,
              0.9817190170288086,
              0.002899260027334094,
              0.19025200605392456,
              0.9817190170288086,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.0026856299955397844,
              0.18973399698734283,
              0.981810986995697,
              0.0026856299955397844,
              0.18973399698734283,
              0.981810986995697,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.0023804400116205215,
              0.18924500048160553,
              0.9819030165672302,
              0.0023804400116205215,
              0.18924500048160553,
              0.9819030165672302,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.002014219993725419,
              0.18878799676895142,
              0.9819939732551575,
              0.002014219993725419,
              0.18878799676895142,
              0.9819939732551575,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.0015564400237053633,
              0.18845200538635254,
              0.9820550084114075,
              0.0015564400237053633,
              0.18845200538635254,
              0.9820550084114075,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.0010681500425562263,
              0.18814699351787567,
              0.9821159839630127,
              0.0010681500425562263,
              0.18814699351787567,
              0.9821159839630127,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.0005493329954333603,
              0.18793299794197083,
              0.9821770191192627,
              0.0005493329954333603,
              0.18793299794197083,
              0.9821770191192627,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.00027466699248179793,
              0.18793299794197083,
              0.9821770191192627,
              0.00027466699248179793,
              0.18793299794197083,
              0.9821770191192627,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0,
              0.18805499374866486,
              0.9821469783782959,
              0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              1,
              0,
              0.000030518498533638194,
              0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              1,
              0,
              0.000030518498533638194,
              0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              1,
              0,
              0.000030518498533638194,
              0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              1,
              0,
              0.000030518498533638194,
              1,
              0,
              0.000030518498533638194,
              0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              1,
              0,
              0.000030518498533638194,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.3138220012187958,
              -0.6317939758300781,
              0.708761990070343,
              0.16113799810409546,
              -0.6795250177383423,
              0.715690016746521,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.16113799810409546,
              -0.6795250177383423,
              0.715690016746521,
              0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              0.16113799810409546,
              0.8981289863586426,
              0.4091010093688965,
              0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              0.16113799810409546,
              0.8981289863586426,
              0.4091010093688965,
              0.3137910068035126,
              0.8512529730796814,
              0.4205150008201599,
              0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              0.19464699923992157,
              -0.9625539779663086,
              0.18854300677776337,
              0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              0.19470800459384918,
              0.9642019867897034,
              -0.17993700504302979,
              0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              0.6790980100631714,
              -0.33591699600219727,
              0.6526380181312561,
              0.5775319933891296,
              -0.45680099725723267,
              0.6765949726104736,
              0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              0.5775319933891296,
              -0.45680099725723267,
              0.6765949726104736,
              0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              0.5775020122528076,
              0.6769620180130005,
              0.45625200867652893,
              0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              0.5775020122528076,
              0.6769620180130005,
              0.45625200867652893,
              0.6790980100631714,
              0.5559250116348267,
              0.47929298877716064,
              0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              0.8312940001487732,
              -0.544389009475708,
              0.11197199672460556,
              0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              0.8312940001487732,
              -0.544389009475708,
              0.11197199672460556,
              0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              0.8314459919929504,
              0.5470749735832214,
              -0.09674370288848877,
              0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              0.8314459919929504,
              0.5470749735832214,
              -0.09674370288848877,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              0.0406200997531414,
              -0.6891999840736389,
              0.7234110236167908,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0.0406200997531414,
              0.9099699854850769,
              0.4126099944114685,
              0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              0,
              0.9827569723129272,
              -0.1848510056734085,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              0.755424976348877,
              -0.1982789933681488,
              0.6244999766349792,
              0.6790980100631714,
              -0.33591699600219727,
              0.6526380181312561,
              0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              0.6790980100631714,
              -0.33591699600219727,
              0.6526380181312561,
              0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              0.6790980100631714,
              0.5559250116348267,
              0.47929298877716064,
              0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              0.6790980100631714,
              0.5559250116348267,
              0.47929298877716064,
              0.755424976348877,
              0.4177980124950409,
              0.5047150254249573,
              0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              0.8314459919929504,
              -0.5453349947929382,
              0.10602100193500519,
              0.8312940001487732,
              -0.544389009475708,
              0.11197199672460556,
              0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              0.8314459919929504,
              0.5470749735832214,
              -0.09674370288848877,
              0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              0.8314459919929504,
              0.5453659892082214,
              -0.10595999658107758,
              0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              0.16113799810409546,
              -0.6795250177383423,
              0.715690016746521,
              0.0406200997531414,
              -0.6891999840736389,
              0.7234110236167908,
              0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              0.0406200997531414,
              -0.6891999840736389,
              0.7234110236167908,
              0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              0.0406200997531414,
              0.9099699854850769,
              0.4126099944114685,
              0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              0.0406200997531414,
              0.9099699854850769,
              0.4126099944114685,
              0.16113799810409546,
              0.8981289863586426,
              0.4091010093688965,
              0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              0.19507400691509247,
              -0.9627370238304138,
              0.18713900446891785,
              0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              0.19464699923992157,
              -0.9625539779663086,
              0.18854300677776337,
              0.0490432009100914,
              -0.9804379940032959,
              0.19058799743652344,
              0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              0.19464699923992157,
              -0.9625539779663086,
              0.18854300677776337,
              0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              0.19470800459384918,
              0.9642019867897034,
              -0.17993700504302979,
              0.0490432009100914,
              0.9804379940032959,
              -0.1904969960451126,
              0.19507400691509247,
              0.9627670049667358,
              -0.18707799911499023,
              0.19470800459384918,
              0.9642019867897034,
              -0.17993700504302979,
              0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              0.755424976348877,
              -0.1982789933681488,
              0.6244999766349792,
              0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              0.755424976348877,
              -0.1982789933681488,
              0.6244999766349792,
              0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              0.755424976348877,
              0.4177980124950409,
              0.5047150254249573,
              0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              0.755424976348877,
              0.4177980124950409,
              0.5047150254249573,
              0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              0.9238560199737549,
              -0.3756519854068756,
              0.07303079962730408,
              0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              0.9238560199737549,
              0.3756519854068756,
              -0.07296980172395706,
              0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              0.5775319933891296,
              -0.45680099725723267,
              0.6765949726104736,
              0.4542680084705353,
              -0.5565969944000244,
              0.6955469846725464,
              0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              0.4542680084705353,
              -0.5565969944000244,
              0.6955469846725464,
              0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              0.4542370140552521,
              0.7766349911689758,
              0.4364149868488312,
              0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              0.4542370140552521,
              0.7766349911689758,
              0.4364149868488312,
              0.5775020122528076,
              0.6769620180130005,
              0.45625200867652893,
              0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              0.707082986831665,
              -0.6940820217132568,
              0.13495300710201263,
              0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              0.5552229881286621,
              -0.8156070113182068,
              0.1626330018043518,
              0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              0.5553759932518005,
              0.8179569840431213,
              -0.14975400269031525,
              0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              0.707082986831665,
              0.69411301612854,
              -0.1348309963941574,
              0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              0.8145390152931213,
              0.07049780339002609,
              0.5757619738578796,
              0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              0.803613007068634,
              -0.04882960021495819,
              0.5931270122528076,
              0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              0.803613007068634,
              0.2674950063228607,
              0.5316320061683655,
              0.8145390152931213,
              0.15030400454998016,
              0.5602589845657349,
              0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              0.9987789988517761,
              -0.048158198595047,
              0.009399699978530407,
              0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              0.9807729721069336,
              -0.19150400161743164,
              0.03723260015249252,
              0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              0.9807729721069336,
              0.19150400161743164,
              -0.037202101200819016,
              0.9987789988517761,
              0.048158198595047,
              -0.00930815003812313,
              0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              0.4542680084705353,
              -0.5565969944000244,
              0.6955469846725464,
              0.3138220012187958,
              -0.6317939758300781,
              0.708761990070343,
              0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              0.3138220012187958,
              -0.6317939758300781,
              0.708761990070343,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              0.3137910068035126,
              0.8512529730796814,
              0.4205150008201599,
              0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              0.3137910068035126,
              0.8512529730796814,
              0.4205150008201599,
              0.4542370140552521,
              0.7766349911689758,
              0.4364149868488312,
              0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              0.555558979511261,
              -0.8161569833755493,
              0.1586959958076477,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.5552229881286621,
              -0.8156070113182068,
              0.1626330018043518,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              0.5552229881286621,
              -0.8156070113182068,
              0.1626330018043518,
              0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              0.5553759932518005,
              0.8179569840431213,
              -0.14975400269031525,
              0.3826720118522644,
              0.906919002532959,
              -0.17621399462223053,
              0.555558979511261,
              0.8161870241165161,
              -0.15854400396347046,
              0.5553759932518005,
              0.8179569840431213,
              -0.14975400269031525,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.683309018611908,
              0.7300940155982971,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              0.9815970063209534,
              -0.1908320039510727,
              0,
              0.9070709943771362,
              0.4209110140800476,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              0,
              0.9070709943771362,
              0.4209110140800476,
              0,
              0.9070709943771362,
              0.4209420084953308,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              0.9827569723129272,
              -0.18488100171089172,
              0,
              0.9815970063209534,
              -0.1908320039510727,
              0,
              0.9827569723129272,
              -0.1848510056734085,
              0,
              0.9815970063209534,
              -0.1908320039510727,
              0,
              0.9816280007362366,
              -0.19074100255966187,
              0,
              0.9827569723129272,
              -0.1848510056734085,
              0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              0.3830380141735077,
              -0.9061859846115112,
              0.17899100482463837,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.3834350109100342,
              0.9179049730300903,
              -0.10202299803495407,
              0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              0.5489060282707214,
              -0.8358410000801086,
              0.0017090400215238333,
              0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              0.5489360094070435,
              0.7756279706954956,
              -0.31150200963020325,
              0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              0.7072970271110535,
              -0.6928920149803162,
              0.1399269998073578,
              0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              0.7072970271110535,
              -0.6928920149803162,
              0.1399269998073578,
              0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              0.7080900073051453,
              0.7020779848098755,
              -0.07510609924793243,
              0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              0.7080900073051453,
              0.7020779848098755,
              -0.07510609924793243,
              0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              0.5489060282707214,
              -0.8358410000801086,
              0.0017090400215238333,
              0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              0.555558979511261,
              -0.8161870241165161,
              0.1586350053548813,
              0.5489060282707214,
              -0.8358410000801086,
              0.0017090400215238333,
              0.5525379776954651,
              0.8274480104446411,
              -0.09988710284233093,
              0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              0.5489360094070435,
              0.7756279706954956,
              -0.31150200963020325,
              0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              0.5489360094070435,
              0.7756279706954956,
              -0.31150200963020325,
              0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              0.9807729721069336,
              -0.19150400161743164,
              0.037202101200819016,
              0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              0.9797970056533813,
              0.19849200546741486,
              -0.0235603004693985,
              0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              0.1938840001821518,
              -0.9804069995880127,
              0.034669000655412674,
              0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              0.1938840001821518,
              0.9219639897346497,
              -0.3351539969444275,
              0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              0.82082599401474,
              -0.5689259767532349,
              -0.05014190077781677,
              0.9238560199737549,
              -0.3756519854068756,
              0.07300029695034027,
              0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              0.82082599401474,
              -0.5689259767532349,
              -0.05014190077781677,
              0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              0.82082599401474,
              0.5087130069732666,
              -0.2596209943294525,
              0.9219030141830444,
              0.38459399342536926,
              -0.04605240002274513,
              0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              0.82082599401474,
              0.5087130069732666,
              -0.2596209943294525,
              0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              0.9934999942779541,
              -0.11377300322055817,
              0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              0.0490432009100914,
              -0.9804379940032959,
              0.19055800139904022,
              0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0.04882960021495819,
              0.9915770292282104,
              -0.11981599777936935,
              0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              0.7072970271110535,
              -0.6928920149803162,
              0.1399269998073578,
              0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              0.7080900073051453,
              0.7020779848098755,
              -0.07510609924793243,
              0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              0.82082599401474,
              -0.5689259767532349,
              -0.05014190077781677,
              0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              0.8314459919929504,
              -0.5453349947929382,
              0.10599099844694138,
              0.707082986831665,
              -0.69411301612854,
              0.13489200174808502,
              0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              0.7039700150489807,
              0.7050999999046326,
              -0.08496350049972534,
              0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              0.8287299871444702,
              0.555620014667511,
              -0.06680499762296677,
              0.82082599401474,
              0.5087130069732666,
              -0.2596209943294525,
              0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.3830380141735077,
              -0.9061859846115112,
              0.17899100482463837,
              0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              0.3830380141735077,
              -0.9061859846115112,
              0.17899100482463837,
              0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              0.3834350109100342,
              0.9179049730300903,
              -0.10202299803495407,
              0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              0.3834350109100342,
              0.9179049730300903,
              -0.10202299803495407,
              0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.1938840001821518,
              -0.9804069995880127,
              0.034669000655412674,
              0.3826720118522644,
              -0.9068880081176758,
              0.17627499997615814,
              0.19507400691509247,
              -0.9627670049667358,
              0.18710899353027344,
              0.1938840001821518,
              -0.9804069995880127,
              0.034669000655412674,
              0.19376200437545776,
              0.9739369750022888,
              -0.1178010031580925,
              0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              0.1938840001821518,
              0.9219639897346497,
              -0.3351539969444275,
              0.3802909851074219,
              0.918179988861084,
              -0.11096499860286713,
              0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              0.1938840001821518,
              0.9219639897346497,
              -0.3351539969444275,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              1,
              0,
              0,
              0.9987789988517761,
              -0.048158198595047,
              0.009338660165667534,
              0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              0.9986569881439209,
              0.05108800157904625,
              -0.005981630180031061,
              1,
              0,
              0,
              0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              0.5489060282707214,
              -0.8358410000801086,
              0.0017090400215238333,
              0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              0.2700279951095581,
              -0.7733700275421143,
              -0.5735340118408203,
              0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              0.25666099786758423,
              0.46937501430511475,
              -0.8448439836502075,
              0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              0.5489360094070435,
              0.7756279706954956,
              -0.31150200963020325,
              0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              0.6863309741020203,
              -0.17111699283123016,
              -0.7068390250205994,
              0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              0.5489060282707214,
              -0.8358410000801086,
              0.0017090400215238333,
              0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              0.3887749910354614,
              -0.7056789994239807,
              -0.5923029780387878,
              0.4917449951171875,
              -0.6181219816207886,
              -0.6132389903068542,
              0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              0.4738300144672394,
              0.32419800758361816,
              -0.8187199831008911,
              0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              0.3722650110721588,
              0.4065369963645935,
              -0.8343150019645691,
              0.5489360094070435,
              0.7756279706954956,
              -0.31150200963020325,
              0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              0.6790369749069214,
              -0.2683489918708801,
              -0.6832789778709412,
              0.9689930081367493,
              -0.21952000260353088,
              -0.11322399973869324,
              0.6709190011024475,
              -0.007141329813748598,
              -0.7414469718933105,
              0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              0.9689930081367493,
              0.16110700368881226,
              -0.18720099329948425,
              0.1938840001821518,
              -0.9804069995880127,
              0.034669000655412674,
              0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              0.13101600110530853,
              0.5105440020561218,
              -0.8497880101203918,
              0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              0.1938840001821518,
              0.9219639897346497,
              -0.3351539969444275,
              0.13101600110530853,
              0.5105440020561218,
              -0.8497880101203918,
              0.82082599401474,
              -0.5689259767532349,
              -0.05014190077781677,
              0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              0.6389970183372498,
              -0.395702987909317,
              -0.6595969796180725,
              0.9122589826583862,
              -0.40150201320648193,
              -0.0808435007929802,
              0.6250500082969666,
              0.11450500041246414,
              -0.7721179723739624,
              0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              0.82082599401474,
              0.5087130069732666,
              -0.2596209943294525,
              0.9122589826583862,
              0.34196001291275024,
              -0.22534899413585663,
              0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0.03530989959836006,
              -0.8309879899024963,
              -0.5551620125770569,
              0.049012698233127594,
              -0.9979550242424011,
              0.04055910184979439,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0.00042725898674689233,
              0.5188149809837341,
              -0.8548539876937866,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0.049012698233127594,
              0.9404579997062683,
              -0.33628299832344055,
              0.032929498702287674,
              0.5174720287322998,
              -0.8550369739532471,
              0.82082599401474,
              -0.5689259767532349,
              -0.05014190077781677,
              0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              0.6981719732284546,
              -0.7155669927597046,
              -0.022278500720858574,
              0.4917449951171875,
              -0.6181219816207886,
              -0.6132389903068542,
              0.5760369896888733,
              -0.5136269927024841,
              -0.6358529925346375,
              0.4738300144672394,
              0.32419800758361816,
              -0.8187199831008911,
              0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              0.6981719732284546,
              0.6551100015640259,
              -0.2887049913406372,
              0.82082599401474,
              0.5087130069732666,
              -0.2596209943294525,
              0.5586410164833069,
              0.22586700320243835,
              -0.7980290055274963,
              0.1938840001821518,
              -0.9804069995880127,
              0.034669000655412674,
              0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              0.139164000749588,
              -0.8182929754257202,
              -0.5576649904251099,
              0.2700279951095581,
              -0.7733700275421143,
              -0.5735340118408203,
              0.3787040114402771,
              -0.9252600073814392,
              0.020935699343681335,
              0.25666099786758423,
              0.46937501430511475,
              -0.8448439836502075,
              0.13101600110530853,
              0.5105440020561218,
              -0.8497880101203918,
              0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              0.13101600110530853,
              0.5105440020561218,
              -0.8497880101203918,
              0.1938840001821518,
              0.9219639897346497,
              -0.3351539969444275,
              0.3787040114402771,
              0.8657190203666687,
              -0.32718899846076965,
              0.9873650074005127,
              -0.07684560120105743,
              -0.13852399587631226,
              0.6863309741020203,
              -0.17111699283123016,
              -0.7068390250205994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.6863309741020203,
              -0.17111699283123016,
              -0.7068390250205994,
              0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.6839810013771057,
              -0.13843199610710144,
              -0.7162079811096191,
              0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.6837059855461121,
              -0.10507500171661377,
              -0.7221289873123169,
              0.9873650074005127,
              0.01934869959950447,
              -0.15723100304603577,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              0.9987490177154541,
              -0.04675440117716789,
              0.01648000068962574,
              0.9987490177154541,
              -0.0467849001288414,
              0.01648000068962574,
              0.9988099932670593,
              0.0481887012720108,
              0.0016174799529835582,
              0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              0.9987490177154541,
              0.04940950125455856,
              -0.0018921500304713845,
              0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              0.9807729721069336,
              -0.1900389939546585,
              0.04419080168008804,
              0.9807119965553284,
              -0.19022199511528015,
              0.04422130063176155,
              0.9813230037689209,
              0.19162599742412567,
              -0.015015100128948689,
              0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              0.9808040261268616,
              0.19281600415706635,
              -0.028992600739002228,
              0.8312940001487732,
              -0.544389009475708,
              0.11197199672460556,
              0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              0.9239169955253601,
              -0.37418699264526367,
              0.07956179976463318,
              0.9237650036811829,
              -0.3744930028915405,
              0.0796227976679802,
              0.9247720241546631,
              0.3786740005016327,
              -0.03674430027604103,
              0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              0.8314459919929504,
              0.5470749735832214,
              -0.09674370288848877,
              0.9238870143890381,
              0.37717801332473755,
              -0.06408890336751938,
              0.8312940001487732,
              -0.544389009475708,
              0.11197199672460556,
              0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              0.7072970271110535,
              -0.6928920149803162,
              0.1399269998073578,
              0.8315680027008057,
              -0.5439620018005371,
              0.11188100278377533,
              0.7080900073051453,
              0.7020779848098755,
              -0.07510609924793243,
              0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              0.8314459919929504,
              0.5470749735832214,
              -0.09674370288848877,
              0.8324840068817139,
              0.5510420203208923,
              -0.05700860172510147,
              0.5552229881286621,
              -0.8156070113182068,
              0.1626330018043518,
              0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              0.7072970271110535,
              -0.6928920149803162,
              0.1399269998073578,
              0.7068700194358826,
              -0.6933190226554871,
              0.1399880051612854,
              0.7080900073051453,
              0.7020779848098755,
              -0.07510609924793243,
              0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              0.5553759932518005,
              0.8179569840431213,
              -0.14975400269031525,
              0.7070220112800598,
              0.6959139704704285,
              -0.1256749927997589,
              0.5552229881286621,
              -0.8156070113182068,
              0.1626330018043518,
              0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              0.3830380141735077,
              -0.9061859846115112,
              0.17899100482463837,
              0.5558639764785767,
              -0.8151800036430359,
              0.1625719964504242,
              0.3834350109100342,
              0.9179049730300903,
              -0.10202299803495407,
              0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              0.5553759932518005,
              0.8179569840431213,
              -0.14975400269031525,
              0.556443989276886,
              0.8259220123291016,
              -0.09030430018901825,
              0.19464699923992157,
              -0.9625539779663086,
              0.18854300677776337,
              0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              0.3830380141735077,
              -0.9061859846115112,
              0.17899100482463837,
              0.3822749853134155,
              -0.9065219759941101,
              0.17902199923992157,
              0.3834350109100342,
              0.9179049730300903,
              -0.10202299803495407,
              0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              0.19470800459384918,
              0.9642019867897034,
              -0.17993700504302979,
              0.38239699602127075,
              0.9085670113563538,
              -0.16806499660015106,
              0.19464699923992157,
              -0.9625539779663086,
              0.18854300677776337,
              0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              0.19550199806690216,
              -0.9624009728431702,
              0.18851299583911896,
              0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              0.19470800459384918,
              0.9642019867897034,
              -0.17993700504302979,
              0.19571499526500702,
              0.9744870066642761,
              -0.10983599722385406,
              0.04882960021495819,
              -0.9803770184516907,
              0.1909240037202835,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0.049287401139736176,
              -0.9803460240364075,
              0.1909240037202835,
              0,
              0.9934999942779541,
              -0.11377300322055817,
              0,
              0.9827569723129272,
              -0.1848510056734085,
              0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              0,
              0.9827569723129272,
              -0.1848510056734085,
              0.04882960021495819,
              0.9816280007362366,
              -0.1843010038137436,
              0.04922639951109886,
              0.9923400282859802,
              -0.11313199996948242,
              0,
              0.10062000155448914,
              0.994903028011322,
              0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              0,
              0.1821649968624115,
              0.9832450151443481,
              0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              0,
              0.1821649968624115,
              0.9832450151443481,
              0,
              0.1821649968624115,
              0.9832450151443481,
              0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              0.00027466699248179793,
              0.18793299794197083,
              0.9821770191192627,
              0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              0.004303109832108021,
              0.09851379692554474,
              0.9951170086860657,
              0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              0.00042725898674689233,
              0.1821340024471283,
              0.9832450151443481,
              0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              0.00027466699248179793,
              0.18793299794197083,
              0.9821770191192627,
              0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              0.0005493329954333603,
              0.18793299794197083,
              0.9821770191192627,
              0.00027466699248179793,
              0.18793299794197083,
              0.9821770191192627,
              0.017853299155831337,
              0.09900200366973877,
              0.994903028011322,
              0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              0.0016479999758303165,
              0.18237900733947754,
              0.9832149744033813,
              0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              0.0005493329954333603,
              0.18793299794197083,
              0.9821770191192627,
              0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              0.0010681500425562263,
              0.18814699351787567,
              0.9821159839630127,
              0.0005493329954333603,
              0.18793299794197083,
              0.9821770191192627,
              0.03546249866485596,
              0.10525800287723541,
              0.9938049912452698,
              0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              0.0032349599059671164,
              0.1830199956893921,
              0.9830930233001709,
              0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              0.0010681500425562263,
              0.18814699351787567,
              0.9821159839630127,
              0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              0.0015564400237053633,
              0.18845200538635254,
              0.9820550084114075,
              0.0010681500425562263,
              0.18814699351787567,
              0.9821159839630127,
              0.051240600645542145,
              0.11404799669981003,
              0.9921259880065918,
              0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              0.004669330082833767,
              0.1838430017232895,
              0.9829099774360657,
              0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              0.0015564400237053633,
              0.18845200538635254,
              0.9820550084114075,
              0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              0.002014219993725419,
              0.18878799676895142,
              0.9819939732551575,
              0.0015564400237053633,
              0.18845200538635254,
              0.9820550084114075,
              0.06512650102376938,
              0.12573599815368652,
              0.9898980259895325,
              0.07705920189619064,
              0.13974399864673615,
              0.9871519804000854,
              0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              0.07705920189619064,
              0.13974399864673615,
              0.9871519804000854,
              0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              0.005951109807938337,
              0.18491199612617493,
              0.9827269911766052,
              0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              0.002014219993725419,
              0.18878799676895142,
              0.9819939732551575,
              0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              0.0023804400116205215,
              0.18924500048160553,
              0.9819030165672302,
              0.002014219993725419,
              0.18878799676895142,
              0.9819939732551575,
              0.07705920189619064,
              0.13974399864673615,
              0.9871519804000854,
              0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              0.007019259966909885,
              0.18619300425052643,
              0.9824820160865784,
              0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              0.0023804400116205215,
              0.18924500048160553,
              0.9819030165672302,
              0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              0.0026856299955397844,
              0.18973399698734283,
              0.981810986995697,
              0.0023804400116205215,
              0.18924500048160553,
              0.9819030165672302,
              0.08630629628896713,
              0.15527799725532532,
              0.9840689897537231,
              0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              0.007904290221631527,
              0.18759700655937195,
              0.9822080135345459,
              0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              0.0026856299955397844,
              0.18973399698734283,
              0.981810986995697,
              0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              0.002899260027334094,
              0.19025200605392456,
              0.9817190170288086,
              0.0026856299955397844,
              0.18973399698734283,
              0.981810986995697,
              0.09280680119991302,
              0.17249099910259247,
              0.9806209802627563,
              0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              0.008545179851353168,
              0.1891539990901947,
              0.9819030165672302,
              0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              0.002899260027334094,
              0.19025200605392456,
              0.9817190170288086,
              0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              0.002899260027334094,
              0.19067999720573425,
              0.9816280007362366,
              0.002899260027334094,
              0.19025200605392456,
              0.9817190170288086,
              0.09302040189504623,
              0.1855829954147339,
              0.9782099723815918,
              0.09082309901714325,
              0.1900079995393753,
              0.9775689840316772,
              0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              0.09082309901714325,
              0.1900079995393753,
              0.9775689840316772,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              0.008758810348808765,
              0.1903740018606186,
              0.9816579818725586,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.002899260027334094,
              0.19067999720573425,
              0.9816280007362366,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0.002899260027334094,
              0.19067999720573425,
              0.9816280007362366,
              0.00015259299834724516,
              0.18805499374866486,
              0.9821469783782959,
              0,
              0.18805499374866486,
              0.9821469783782959,
              0,
              0.1821649968624115,
              0.9832450151443481,
              0,
              0.18805499374866486,
              0.9821469783782959,
              0,
              0.18219499289989471,
              0.9832450151443481,
              0,
              0.1821649968624115,
              0.9832450151443481,
              0,
              0.1821649968624115,
              0.9832450151443481,
              0,
              0.18219499289989471,
              0.9832450151443481,
              0,
              0.10062000155448914,
              0.994903028011322,
              0,
              0.18219499289989471,
              0.9832450151443481,
              0,
              0.10074199736118317,
              0.994903028011322,
              0,
              0.10062000155448914,
              0.994903028011322,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0.00018311099847778678,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              -0.8604080080986023,
              -0.5095369815826416,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.8609269857406616,
              -0.5086820125579834,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.8609269857406616,
              -0.5086820125579834,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.5444499850273132,
              -0.8387709856033325,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.5439010262489319,
              -0.8391370177268982,
              0,
              0.5444499850273132,
              -0.8387709856033325,
              0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.11710000038146973,
              0.18948900699615479,
              0.974852979183197,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.11710000038146973,
              0.18948900699615479,
              0.974852979183197,
              0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              0.16931700706481934,
              0.18799400329589844,
              0.9674370288848877,
              0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              0.16931700706481934,
              0.18799400329589844,
              0.9674370288848877,
              0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              0.09082309901714325,
              0.1900079995393753,
              0.9775689840316772,
              0.24152299761772156,
              0.18512499332427979,
              0.9525439739227295,
              0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              0.16931700706481934,
              0.18802499771118164,
              0.9674370288848877,
              0.24155400693416595,
              0.18515600264072418,
              0.9525439739227295,
              0.24155400693416595,
              0.18512499332427979,
              0.9525439739227295,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.1908320039510727,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              0,
              0.19080199301242828,
              0.9815970063209534,
              1,
              0,
              0.000030518498533638194,
              0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              1,
              0,
              0.000030518498533638194,
              0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              1,
              0,
              0.000030518498533638194,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              1,
              0,
              0.000030518498533638194,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              1,
              0,
              0.000030518498533638194,
              1,
              0,
              0.000030518498533638194,
              1,
              0,
              0.000030518498533638194,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              1,
              0,
              0.000030518498533638194,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.8100219964981079,
              0.11185000091791153,
              0.5755789875984192,
              1,
              0,
              0.000030518498533638194,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              1,
              0,
              0.000030518498533638194,
              0.8100219964981079,
              0.11188100278377533,
              0.5755789875984192,
              1,
              0,
              0.000030518498533638194,
              1,
              0,
              0.000030518498533638194,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0.000030518498533638194,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0.000030518498533638194,
              1,
              0,
              0.000030518498533638194,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0.000030518498533638194,
              1,
              0,
              0.000030518498533638194,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0.000030518498533638194,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0.000030518498533638194,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0.000030518498533638194,
              1,
              0,
              0.000030518498533638194,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0.000030518498533638194,
              1,
              0,
              0.000030518498533638194,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0.000030518498533638194,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7115089893341064,
              -0.13455599546432495,
              -0.6896269917488098,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              0.6985080242156982,
              -0.1373330056667328,
              -0.7022609710693359,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.6855369806289673,
              -0.13962200284004211,
              -0.7144989967346191,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.6839810013771057,
              -0.13843199610710144,
              -0.7162079811096191,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.6959750056266785,
              -0.13620400428771973,
              -0.7050079703330994,
              0.6839810013771057,
              -0.13843199610710144,
              -0.7162079811096191,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.09082309901714325,
              0.1900079995393753,
              0.9775689840316772,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.09082309901714325,
              0.1900079995393753,
              0.9775689840316772,
              0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              0.09079259634017944,
              0.1900079995393753,
              0.9775689840316772,
              0.11710000038146973,
              0.18948900699615479,
              0.974852979183197,
              0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0.008728289976716042,
              0.19080199301242828,
              0.9815670251846313,
              0.008728289976716042,
              0.19077099859714508,
              0.9815670251846313,
              0.002868739888072014,
              0.19080199301242828,
              0.9815970063209534,
              0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7107149958610535,
              -0.13360999524593353,
              -0.6906639933586121,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7201759815216064,
              -0.1322370022535324,
              -0.681020975112915,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.0014038500376045704,
              0.007141329813748598,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.722648024559021,
              -0.13187000155448914,
              -0.6784870028495789,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.722678005695343,
              -0.13187000155448914,
              -0.6784570217132568,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.7224649786949158,
              -0.13190099596977234,
              -0.6786710023880005,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7224649786949158,
              -0.13190099596977234,
              -0.6786710023880005,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7224950194358826,
              -0.13190099596977234,
              -0.6786400079727173,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0.0013428099919110537,
              0.007141329813748598,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0.0013428099919110537,
              0.007141329813748598,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.0013428099919110537,
              0.007141329813748598,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9815970063209534,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9988099932670593,
              -0.048158198595047,
              0,
              0.9934999942779541,
              -0.11377300322055817,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9816280007362366,
              0.19080199301242828,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9927979707717896,
              -0.11978500336408615,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0.00039674100116826594,
              -0.8369089961051941,
              -0.5473189949989319,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0.00018311099847778678,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0,
              -0.9991459846496582,
              0.04107790067791939,
              0.00018311099847778678,
              -0.8526570200920105,
              -0.5224159955978394,
              0.00021363000269047916,
              0.5353860259056091,
              -0.8445690274238586,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0.00042725898674689233,
              0.5188149809837341,
              -0.8548539876937866,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0,
              0.9417709708213806,
              -0.3361920118331909,
              0.00042725898674689233,
              0.5188149809837341,
              -0.8548539876937866,
              0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              0.31247299909591675,
              0.18673600256443024,
              0.9313920140266418,
              0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              0.16930200159549713,
              0.19374099373817444,
              0.9663339853286743,
              1,
              0.000024851800844771788,
              0.00012395500380080193,
              1,
              0.000024851800844771788,
              0.00012395500380080193,
              1,
              0.000024851800844771788,
              0.00012395500380080193,
              1,
              0.000024851800844771788,
              0.00012395500380080193,
              1,
              0.000024851800844771788,
              0.00012395500380080193,
              1,
              0.000024851800844771788,
              0.00012395500380080193,
              1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              1,
              -0.0000018023299617198063,
              -0.000008989590241981205,
              0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              0.9998940229415894,
              0.002860219916328788,
              0.014264499768614769,
              0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              0.9998940229415894,
              0.002692189998924732,
              0.014298800379037857,
              1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              1,
              -0.0000016964499991445336,
              -0.000009010180292534642,
              1,
              0.000023391599825117737,
              0.00012423799489624798,
              1,
              0.000023391599825117737,
              0.00012423799489624798,
              1,
              0.000023391599825117737,
              0.00012423799489624798,
              1,
              0.000023391599825117737,
              0.00012423799489624798,
              1,
              0.000023391599825117737,
              0.00012423799489624798,
              1,
              0.000023391599825117737,
              0.00012423799489624798,
              0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              0.169282004237175,
              0.1823599934577942,
              0.9685500264167786,
              0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              0.3124859929084778,
              0.17576399445533752,
              0.9335200190544128,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              0.16928499937057495,
              0.18805499374866486,
              0.9674599766731262,
              1,
              0.000024121300157275982,
              0.0001240940036950633,
              1,
              0.000024121300157275982,
              0.0001240940036950633,
              1,
              0.000024121300157275982,
              0.0001240940036950633,
              1,
              0.000024121300157275982,
              0.0001240940036950633,
              1,
              0.000024121300157275982,
              0.0001240940036950633,
              1,
              0.000024121300157275982,
              0.0001240940036950633,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              0.16929100453853607,
              0.18805499374866486,
              0.9674590229988098,
              0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              0.31248798966407776,
              0.18125300109386444,
              0.9324690103530884,
              0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              0.31248998641967773,
              0.18125300109386444,
              0.9324679970741272,
              0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              0.16930200159549713,
              0.18805399537086487,
              0.9674569964408875,
              1,
              0.000024121600290527567,
              0.00012409499322529882,
              1,
              0.000024121600290527567,
              0.00012409499322529882,
              1,
              0.000024121600290527567,
              0.00012409499322529882,
              1,
              0.000024121600290527567,
              0.00012409499322529882,
              1,
              0.000024121600290527567,
              0.00012409499322529882,
              1,
              0.000024121600290527567,
              0.00012409499322529882,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              -0.0000017493999848738895,
              -0.000008999900273920503,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              1,
              0.000024121700334944762,
              0.00012409499322529882,
              0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              0.16933099925518036,
              0.18805299699306488,
              0.9674519896507263,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.3124749958515167,
              0.18125399947166443,
              0.9324730038642883,
              0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              0.16933199763298035,
              0.18805299699306488,
              0.9674519896507263,
              1,
              0.000024121800379361957,
              0.00012409599730744958,
              1,
              0.000024121800379361957,
              0.00012409599730744958,
              1,
              0.000024121800379361957,
              0.00012409599730744958,
              1,
              0.000024121800379361957,
              0.00012409599730744958,
              1,
              0.000024121800379361957,
              0.00012409599730744958,
              1,
              0.000024121800379361957,
              0.00012409599730744958,
              1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              1,
              -0.0000017493999848738895,
              -0.000008999890269478783,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.9998940229415894,
              0.002776019973680377,
              0.014281399548053741,
              0.18601000308990479,
              -0.24811500310897827,
              -0.9506819844245911,
              0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              0.106113001704216,
              -0.2722249925136566,
              -0.9563590288162231,
              0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              0.3168739974498749,
              -0.18295800685882568,
              -0.9306309819221497,
              0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              0.7115089893341064,
              -0.13455599546432495,
              -0.6896269917488098,
              0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              0,
              -0.33579498529434204,
              -0.9419230222702026,
              0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              0,
              -0.3757439851760864,
              -0.9266949892044067,
              0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              0,
              -0.3757439851760864,
              -0.9266949892044067,
              0,
              -0.3757439851760864,
              -0.9266949892044067,
              0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              0,
              -0.38932499289512634,
              -0.9210789799690247,
              0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              0,
              -0.38932499289512634,
              -0.9210789799690247,
              0.026245899498462677,
              -0.32117700576782227,
              -0.9466230273246765,
              0.106113001704216,
              -0.2722249925136566,
              -0.9563590288162231,
              0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              0.106113001704216,
              -0.2722249925136566,
              -0.9563590288162231,
              0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              0.015991700813174248,
              -0.3585920035839081,
              -0.9333480000495911,
              0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              0.08203379809856415,
              -0.29978299140930176,
              -0.9504380226135254,
              0.05859550088644028,
              -0.3300879895687103,
              -0.9421060085296631,
              0.008148440159857273,
              -0.3791620135307312,
              -0.9252600073814392,
              0.286873996257782,
              -0.19324299693107605,
              -0.9382609724998474,
              0.18601000308990479,
              -0.24811500310897827,
              -0.9506819844245911,
              0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              0.18601000308990479,
              -0.24811500310897827,
              -0.9506819844245911,
              0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              0.30808401107788086,
              -0.1856749951839447,
              -0.9330419898033142,
              0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              0.3168739974498749,
              -0.18295800685882568,
              -0.9306309819221497,
              0.212226003408432,
              -0.22458599507808685,
              -0.9510480165481567,
              0.23056699335575104,
              -0.21192100644111633,
              -0.9496750235557556,
              0.3168739974498749,
              -0.18295800685882568,
              -0.9306309819221497,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7115089893341064,
              -0.13455599546432495,
              -0.6896269917488098,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7115089893341064,
              -0.13455599546432495,
              -0.6896269917488098,
              0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.7188630104064941,
              -0.13284699618816376,
              -0.6823019981384277,
              0.7219759821891785,
              -0.13211500644683838,
              -0.6791589856147766,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              0.9886170029640198,
              -0.028687400743365288,
              -0.14758799970149994,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              0.9999690055847168,
              0.001373330014757812,
              0.0071108099073171616,
              1,
              0,
              0,
              0.9999690055847168,
              0.0014038500376045704,
              0.007141329813748598,
              1,
              0,
              0,
              1,
              0,
              0,
              0.9999690055847168,
              0.0014038500376045704,
              0.007141329813748598,
              0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              0.9998940229415894,
              0.0027768199797719717,
              0.01428189966827631,
              0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              0.9998940229415894,
              0.0027753401082009077,
              0.014282199554145336,
              1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              1,
              -4.221149936256552e-7,
              -0.000009257900273951236,
              1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              1,
              -0.0000033716501093294937,
              -0.000008684560270921793,
              1,
              0.000025448700398555957,
              0.00012383599823806435,
              1,
              0.000025448700398555957,
              0.00012383599823806435,
              1,
              0.000025448700398555957,
              0.00012383599823806435,
              1,
              0.000025448700398555957,
              0.00012383599823806435,
              1,
              0.000025448700398555957,
              0.00012383599823806435,
              1,
              0.000025448700398555957,
              0.00012383599823806435,
              1,
              0.000022499199985759333,
              0.00012440899445209652,
              1,
              0.000022499199985759333,
              0.00012440899445209652,
              1,
              0.000022499199985759333,
              0.00012440899445209652,
              1,
              0.000022499199985759333,
              0.00012440899445209652,
              1,
              0.000022499199985759333,
              0.00012440899445209652,
              1,
              0.000022499199985759333,
              0.00012440899445209652,
              0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              0.16927599906921387,
              0.18805600702762604,
              0.9674609899520874,
              0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              0.16929000616073608,
              0.18805499374866486,
              0.9674590229988098,
              0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              0.3125399947166443,
              0.18125100433826447,
              0.9324520230293274,
              0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              0.31251299381256104,
              0.18125200271606445,
              0.9324610233306885,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              0,
              0.9838619828224182,
              -0.1789270043373108,
              -0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              -0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              -0.9656059741973877,
              -0.1361130028963089,
              -0.22150300443172455,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              -0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              -0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              -0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              -0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6763820052146912,
              -0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6981719732284546,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6981719732284546,
              -0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7099220156669617,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              -0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7099220156669617,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7099220156669617,
              -0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              -0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              -0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              -0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              -0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              -0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              -0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              -0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              -0.730430006980896,
              0.6423540115356445,
              0.23194099962711334,
              -0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              -0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              -0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              -0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              -0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              -0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              -0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              -0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              -0.7224339842796326,
              0.3816950023174286,
              0.5765249729156494,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              -0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              -0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              -0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              -0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              -0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              -0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              -0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              -0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              -0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              -0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              -0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              -0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              -0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              -0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              -0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              -0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              -0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              -0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              -0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              -0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              -0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              -0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              -0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              -0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              -0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              -0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              -0.15530900657176971,
              -0.9135410189628601,
              -0.37589600682258606,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              -0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.15530900657176971,
              -0.9135410189628601,
              -0.37589600682258606,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              -0.15530900657176971,
              -0.9135410189628601,
              -0.37589600682258606,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              -0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              -0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              -0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              -0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              -0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              -0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              -0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              -0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6763820052146912,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6981719732284546,
              -0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6981719732284546,
              -0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              -0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              -0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              -0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              -0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              -0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              -0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              -0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              -0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298499941825867,
              -0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298499941825867,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              -0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298499941825867,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              -0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              -0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              -0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.16080200672149658,
              0.9686570167541504,
              0.18918399512767792,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              -0.16080200672149658,
              0.9686570167541504,
              0.18918399512767792,
              -0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              -0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              -0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              -0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              -0.730430006980896,
              0.6423540115356445,
              0.23194099962711334,
              -0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              -0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              -0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              -0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              -0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              -0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              -0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              -0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              -0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              -0.16022199392318726,
              0.08221690356731415,
              0.9836419820785522,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              -0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              -0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              -0.16022199392318726,
              0.08221690356731415,
              0.9836419820785522,
              -0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              -0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              -0.16022199392318726,
              0.08221690356731415,
              0.9836419820785522,
              -0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              -0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              -0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              -0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              -0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              -0.7224339842796326,
              0.3816950023174286,
              0.5765249729156494,
              -0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              -0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              -0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              -0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              -0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              -0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              -0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              -0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              -0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              -0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              -0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              -0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              -0.7150179743766785,
              -0.13211500644683838,
              0.6864830255508423,
              -0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              -0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              -0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              -0.15894000232219696,
              -0.986998975276947,
              -0.022675300016999245,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              -0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              -0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              -0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              -0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              -0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              -0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              -0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              -0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              -0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              -0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              -0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              -0.15384399890899658,
              -0.6043890118598938,
              -0.7816709876060486,
              -0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              -0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              -0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              -0.6678370237350464,
              -0.45539700984954834,
              -0.5887020230293274,
              -0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              -0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              -0.15384399890899658,
              -0.6043890118598938,
              -0.7816709876060486,
              -0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.15384399890899658,
              -0.6043890118598938,
              -0.7816709876060486,
              -0.6687830090522766,
              -0.40815499424934387,
              -0.6213570237159729,
              -0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              -0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6763820052146912,
              -0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              -0.6734520196914673,
              -0.35370999574661255,
              -0.6490979790687561,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6763820052146912,
              -0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              -0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              -0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              -0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6981719732284546,
              -0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7099220156669617,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7099220156669617,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              -0.6840720176696777,
              0.1674250066280365,
              -0.7099220156669617,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              -0.6839500069618225,
              0.23142200708389282,
              -0.6918240189552307,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              -0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              -0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              -0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              -0.16080200672149658,
              0.9686570167541504,
              0.18918399512767792,
              -0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              -0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              -0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              -0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              -0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              -0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              -0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              -0.730430006980896,
              0.6423540115356445,
              0.23194099962711334,
              -0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              -0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              -0.730430006980896,
              0.6423540115356445,
              0.23194099962711334,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              -0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              -0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              -0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              -0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              -0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              -0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              -0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              -0.7167580127716064,
              -0.039796099066734314,
              0.6961579918861389,
              -0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              -0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              -0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              -0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              -0.7224339842796326,
              0.3816950023174286,
              0.5765249729156494,
              -0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              -0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              -0.7224339842796326,
              0.3816950023174286,
              0.5765249729156494,
              -0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              -0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              -0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              -0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              -0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              -0.7345190048217773,
              0.6167790293693542,
              0.28287601470947266,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              -0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              -0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              -0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              -0.7065340280532837,
              0.7048249840736389,
              -0.06302069872617722,
              -0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              -0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              -0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              -0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              -0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              -0.9763180017471313,
              -0.16193099319934845,
              -0.14337599277496338,
              -0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              -0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              -0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              -0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              -0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              -0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.6893829703330994,
              -0.7059239745140076,
              -0.16238899528980255,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              -0.6848049759864807,
              -0.6926479935646057,
              -0.22626399993896484,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              -0.6805930137634277,
              -0.6736660003662109,
              -0.28794199228286743,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.6766260266304016,
              -0.649495005607605,
              -0.34678199887275696,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.9656059741973877,
              -0.1361130028963089,
              -0.22150300443172455,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.9656059741973877,
              -0.1361130028963089,
              -0.22150300443172455,
              -0.6698809862136841,
              -0.5874810218811035,
              -0.453931987285614,
              -0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.668569028377533,
              -0.5042570233345032,
              -0.5464950203895569,
              -0.6682329773902893,
              -0.5496990084648132,
              -0.5012360215187073,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6763820052146912,
              -0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              -0.6782739758491516,
              -0.22898000478744507,
              -0.6981719732284546,
              -0.6760460138320923,
              -0.2922149896621704,
              -0.6763820052146912,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              -0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              -0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              -0.6801660060882568,
              -0.16422000527381897,
              -0.7143769860267639,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              -0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298499941825867,
              -0.6816920042037964,
              -0.09836120158433914,
              -0.7249670028686523,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298499941825867,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.6828209757804871,
              -0.03173920139670372,
              -0.7298499941825867,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.6839810013771057,
              0.10177899897098541,
              -0.7223430275917053,
              -0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              -0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              -0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              -0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              -0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              -0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              -0.7172769904136658,
              0.6951509714126587,
              0.04757839813828468,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              -0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              -0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              -0.721792995929718,
              0.6831570267677307,
              0.11075200140476227,
              -0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.730430006980896,
              0.6423540115356445,
              0.23194099962711334,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.7262489795684814,
              0.6654260158538818,
              0.17239899933338165,
              -0.730430006980896,
              0.6423540115356445,
              0.23194099962711334,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              -0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              -0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              -0.7158120274543762,
              0.028382200747728348,
              0.6976839900016785,
              -0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              -0.7154759764671326,
              0.09521769732236862,
              0.6920989751815796,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              -0.715690016746521,
              0.15970300137996674,
              0.6798909902572632,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              -0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              -0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              -0.7178869843482971,
              0.27881699800491333,
              0.6378669738769531,
              -0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              -0.7224339842796326,
              0.3816950023174286,
              0.5765249729156494,
              -0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              -0.7254859805107117,
              0.4265269935131073,
              0.5401170253753662,
              -0.7224339842796326,
              0.3816950023174286,
              0.5765249729156494,
              -0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              -0.7290570139884949,
              0.46681100130081177,
              0.5005339980125427,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              -0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              -0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.7323529720306396,
              0.5030670166015625,
              0.45884600281715393,
              -0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.7354050278663635,
              0.5657830238342285,
              0.37287500500679016,
              -0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              -0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              -0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.7142249941825867,
              -0.10367099940776825,
              0.6921600103378296,
              -0.7150179743766785,
              -0.13211500644683838,
              0.6864830255508423,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.9763180017471313,
              -0.16193099319934845,
              -0.14337599277496338,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              -0.9763180017471313,
              -0.16193099319934845,
              -0.14337599277496338,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              -0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              -0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              -0.9902039766311646,
              0.041810400784015656,
              -0.13300000131130219,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              -0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              -0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              -0.9902039766311646,
              0.041810400784015656,
              -0.13300000131130219,
              -0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.9902039766311646,
              0.041810400784015656,
              -0.13300000131130219,
              -0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              -0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9911500215530396,
              0.1291539967060089,
              -0.030030200257897377,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9911500215530396,
              0.1291539967060089,
              -0.030030200257897377,
              -0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9911500215530396,
              0.1291539967060089,
              -0.030030200257897377,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9656059741973877,
              -0.1361130028963089,
              -0.22150300443172455,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.9656059741973877,
              -0.1361130028963089,
              -0.22150300443172455,
              -0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.9891960024833679,
              0.03265479952096939,
              -0.14276599884033203,
              -0.9902039766311646,
              0.041810400784015656,
              -0.13300000131130219,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.9902039766311646,
              0.041810400784015656,
              -0.13300000131130219,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              -0.9902039766311646,
              0.041810400784015656,
              -0.13300000131130219,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              -0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              -0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              -0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              -0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              -0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.9684739708900452,
              -0.04614400118589401,
              -0.2447279989719391,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              -0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              -0.9911500215530396,
              0.1291539967060089,
              -0.030030200257897377,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              -0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              -0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              -0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              -0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              -0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              -0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              -0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.9800099730491638,
              0.19180899858474731,
              0.05252239853143692,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              -0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              -0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              -0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              -0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              -0.9883109927177429,
              0.13934800028800964,
              -0.06134219840168953,
              -0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              -0.9742730259895325,
              0.16238899528980255,
              -0.1561329960823059,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              -0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              -0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              -0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              -0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              -0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              -0.15729199349880219,
              -0.981016993522644,
              -0.11316300183534622,
              -0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              -0.1565289944410324,
              -0.9664599895477295,
              -0.2034969925880432,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              -0.15530900657176971,
              -0.9135410189628601,
              -0.37589600682258606,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              -0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              -0.15530900657176971,
              -0.9135410189628601,
              -0.37589600682258606,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              -0.15530900657176971,
              -0.9135410189628601,
              -0.37589600682258606,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              -0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              -0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              -0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              -0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              -0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              -0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              -0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              -0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              -0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              -0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              -0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              -0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              -0.156468003988266,
              -0.21878699958324432,
              -0.9631339907646179,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              -0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              -0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              -0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              -0.15671299397945404,
              -0.04260379821062088,
              -0.9866939783096313,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9768970012664795,
              0.21359899640083313,
              -0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              0,
              0.9768970012664795,
              0.21359899640083313,
              -0.16080200672149658,
              0.9686570167541504,
              0.18918399512767792,
              -0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9226660132408142,
              0.3855710029602051,
              -0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              0,
              0.9226660132408142,
              0.3855710029602051,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              -0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              0,
              0.9226660132408142,
              0.3855710029602051,
              0,
              0.8848540186882019,
              0.46583500504493713,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              0,
              0.8848540186882019,
              0.46583500504493713,
              -0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              -0.16190099716186523,
              0.9183930158615112,
              0.3609730005264282,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.12439300119876862,
              0.992218017578125,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              0,
              -0.12439300119876862,
              0.992218017578125,
              -0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              -0.16022199392318726,
              0.08221690356731415,
              0.9836419820785522,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              -0.16019199788570404,
              -0.006958220154047012,
              0.987030029296875,
              -0.16022199392318726,
              0.08221690356731415,
              0.9836419820785522,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.05578780174255371,
              0.9984130263328552,
              -0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              0,
              0.05578780174255371,
              0.9984130263328552,
              -0.16022199392318726,
              0.08221690356731415,
              0.9836419820785522,
              -0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.14407800137996674,
              0.98956298828125,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0,
              0.14407800137996674,
              0.98956298828125,
              -0.16034400463104248,
              0.1695300042629242,
              0.9723809957504272,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.39329200983047485,
              0.9194009900093079,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0,
              0.39329200983047485,
              0.9194009900093079,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.5424050092697144,
              0.8400830030441284,
              -0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              0,
              0.5424050092697144,
              0.8400830030441284,
              -0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              -0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.6111029982566833,
              0.7915279865264893,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0,
              0.6111029982566833,
              0.7915279865264893,
              -0.16272500157356262,
              0.6233410239219666,
              0.7647939920425415,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.6755880117416382,
              0.7372360229492188,
              -0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              0,
              0.6755880117416382,
              0.7372360229492188,
              -0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              -0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              -0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              -0.15836100280284882,
              -0.9863280057907104,
              -0.045014798641204834,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              -0.15894000232219696,
              -0.986998975276947,
              -0.022675300016999245,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -1,
              0,
              -0.15894000232219696,
              -0.986998975276947,
              -0.022675300016999245,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              -0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              -0.15427100658416748,
              -0.8327280282974243,
              -0.5316929817199707,
              -0.1539659947156906,
              -0.7834410071372986,
              -0.6020389795303345,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              -0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              -0.15448500216007233,
              -0.7281110286712646,
              -0.6678060293197632,
              -0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              -0.15384399890899658,
              -0.6043890118598938,
              -0.7816709876060486,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              -0.15402700006961823,
              -0.6678979992866516,
              -0.7280799746513367,
              -0.15384399890899658,
              -0.6043890118598938,
              -0.7816709876060486,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              -0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              -0.15384399890899658,
              -0.6043890118598938,
              -0.7816709876060486,
              -0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              -0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              -0.15536999702453613,
              -0.535660982131958,
              -0.8299810290336609,
              -0.15573599934577942,
              -0.46186700463294983,
              -0.873134970664978,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              -0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              -0.1560409963130951,
              -0.3842589855194092,
              -0.9099090099334717,
              -0.15628500282764435,
              -0.3031100034713745,
              -0.9400309920310974,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              -0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              -0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              -0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9923089742660522,
              0.12366099655628204,
              -0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              0,
              0.9923089742660522,
              0.12366099655628204,
              -0.16031399369239807,
              0.9819639921188354,
              0.09991759806871414,
              -0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9535509943962097,
              0.3012180030345917,
              -0.16080200672149658,
              0.9686570167541504,
              0.18918399512767792,
              0,
              0.9535509943962097,
              0.3012180030345917,
              -0.16135099530220032,
              0.9473249912261963,
              0.2765589952468872,
              -0.16080200672149658,
              0.9686570167541504,
              0.18918399512767792,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8406929969787598,
              0.5414590239524841,
              -0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              0,
              0.8406929969787598,
              0.5414590239524841,
              -0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              -0.16238899528980255,
              0.8823509812355042,
              0.44160300493240356,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              -0.15924599766731262,
              -0.2568129897117615,
              0.9532150030136108,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              -0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              -0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              -0.16025300323963165,
              -0.09717089682817459,
              0.9822689890861511,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.23011000454425812,
              0.973143994808197,
              -0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              0,
              0.23011000454425812,
              0.973143994808197,
              -0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              -0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.31333398818969727,
              0.9496139883995056,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0,
              0.31333398818969727,
              0.9496139883995056,
              -0.16083300113677979,
              0.3355819880962372,
              0.9281589984893799,
              -0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.46974098682403564,
              0.882777988910675,
              -0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              0,
              0.46974098682403564,
              0.882777988910675,
              -0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              -0.1622059941291809,
              0.5575119853019714,
              0.8141419887542725,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.735588014125824,
              0.6773890256881714,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0,
              0.735588014125824,
              0.6773890256881714,
              -0.16336600482463837,
              0.741690993309021,
              0.6505020260810852,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.7907649874687195,
              0.6120790243148804,
              -0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              0,
              0.7907649874687195,
              0.6120790243148804,
              -0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              -0.1634880006313324,
              0.8405410051345825,
              0.5164650082588196,
              -0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              0,
              0.31540900468826294,
              -0.948943018913269,
              -0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              -0.15887899696826935,
              0.9872429966926575,
              0.008758810348808765,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9763180017471313,
              -0.16193099319934845,
              -0.14337599277496338,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              -0.9763180017471313,
              -0.16193099319934845,
              -0.14337599277496338,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              -0.9656059741973877,
              -0.1361130028963089,
              -0.22150300443172455,
              -0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              -0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              -0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.9894710183143616,
              0.01904349960386753,
              -0.14328399300575256,
              -0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              -0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              -0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.9910579919815063,
              0.08951079845428467,
              -0.09872739762067795,
              -0.9707630276679993,
              0.011017199605703354,
              -0.23975299298763275,
              -0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              -0.9909970164299011,
              0.10284700244665146,
              -0.08554340153932571,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              -0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              -0.9909359812736511,
              0.1134369969367981,
              -0.07159639894962311,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              -0.9722890257835388,
              0.06149480119347572,
              -0.22547100484371185,
              -0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              -0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              -0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.9728080034255981,
              0.08386489748954773,
              -0.21576599776744843,
              -0.9909970164299011,
              0.12646900117397308,
              -0.043366800993680954,
              -0.9911500215530396,
              0.1291539967060089,
              -0.030030200257897377,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.9911500215530396,
              0.1291539967060089,
              -0.030030200257897377,
              -0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              -0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9803770184516907,
              0.1551869958639145,
              -0.12152499705553055,
              -0.9822689890861511,
              0.15356899797916412,
              -0.10748600214719772,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.9899590015411377,
              0,
              -0.1412699967622757,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              -0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              -0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              -0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              -0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.9794300198554993,
              0.1543319970369339,
              0.12973399460315704,
              -0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              -0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              -0.9793689846992493,
              0.18790200352668762,
              0.07416000217199326,
              -0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              -0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              -0.9821159839630127,
              0.18802499771118164,
              0.008423109538853168,
              -0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              -0.9874259829521179,
              0.07901240140199661,
              -0.13687600195407867,
              -0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              -0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              -0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.983489990234375,
              0.1804559975862503,
              -0.012756699696183205,
              -0.987030029296875,
              0.06100650131702423,
              -0.1482589989900589,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              -0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              -0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              -0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              -0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              -0.9858090281486511,
              0.16153399646282196,
              -0.045014798641204834,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              -0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              -0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -1,
              0,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9226660132408142,
              0.3855710029602051,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.9226660132408142,
              0.3855710029602051,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.9226660132408142,
              0.3855710029602051,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.7017729878425598,
              0.7081519961357117,
              -0.07760860025882721,
              -0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              -0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              -0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              -0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              -0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              -0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.15894000232219696,
              -0.986998975276947,
              -0.022675300016999245,
              -0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              -0.15887899696826935,
              0.9872429966926575,
              0.008758810348808765,
              -0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              -0.7017729878425598,
              0.7081519961357117,
              -0.07760860025882721,
              -0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              -0.7021999955177307,
              0.7076939940452576,
              -0.07766959816217422,
              -0.7017729878425598,
              0.7081519961357117,
              -0.07760860025882721,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              -0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              -0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              -0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              -0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              -0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              -0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9779049754142761,
              0.15988600254058838,
              -0.13446499407291412,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.15894000232219696,
              -0.986998975276947,
              -0.022675300016999245,
              0,
              -1,
              0,
              -0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              -0.15970300137996674,
              0.9866330027580261,
              0.03186130151152611,
              -0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              0,
              0.9984740018844604,
              0.055024899542331696,
              -0.15921500325202942,
              0.9871820211410522,
              0.008697779849171638,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9984740018844604,
              0.055024899542331696,
              -0.9763180017471313,
              -0.16193099319934845,
              -0.14337599277496338,
              -0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9772329926490784,
              -0.15979500114917755,
              -0.13946999609470367,
              -0.9899590015411377,
              -0.000030518498533638194,
              -0.1413010060787201,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.280892014503479,
              -0.9597160220146179,
              -0.15707899630069733,
              0.2947779893875122,
              -0.9425340294837952,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              -0.9837639927864075,
              0.08819849789142609,
              0.15616300702095032,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.280892014503479,
              -0.9597160220146179,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              -0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              -0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              -0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              -0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              -0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              -0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              -0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              -0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              -0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              -0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.6876429915428162,
              0.30149200558662415,
              -0.6604509949684143,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              -0.6876429915428162,
              0.30149200558662415,
              -0.6604509949684143,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              -0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              -0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              -0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              -0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              -0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              -0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              -0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              -0.6628010272979736,
              0.736503005027771,
              -0.13489200174808502,
              -0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              -0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              -0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.7413859963417053,
              -0.49925199151039124,
              0.44840800762176514,
              -0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              -0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              -0.7472760081291199,
              -0.6491590142250061,
              0.141757994890213,
              -0.9985960125923157,
              -0.04269539937376976,
              0.03112890012562275,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.9985960125923157,
              -0.04269539937376976,
              0.03112890012562275,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              -0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              -0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              -0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              -0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              -0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              -0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              -0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              -0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              -0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              -0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              -0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              -0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              -0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              -0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              -0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              -0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              -0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              -0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              -0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              -0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              -0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              -0.7705619931221008,
              -0.15967300534248352,
              0.6169930100440979,
              -0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              -0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              -0.7705619931221008,
              -0.15967300534248352,
              0.6169930100440979,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              -0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              -0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              -0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              -0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              0,
              -0.707082986831665,
              0.707082986831665,
              -0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              0,
              -0.707082986831665,
              0.707082986831665,
              0,
              -0.16016100347042084,
              0.987060010433197,
              -0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              -0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              -0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              -0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              -0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              -0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              -0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              -0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              -0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              -0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              -0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              -0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              -0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              -0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              -0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              -0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              -0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              -0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              -0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              -0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              -0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              -0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              -0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              -0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              -0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              -0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              -0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              -0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              -0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              -0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.6640830039978027,
              0.6973170042037964,
              0.2696309983730316,
              -0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              -0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              -0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              -0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.9866629838943481,
              0.15949000418186188,
              0.03161719813942909,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.9866629838943481,
              0.15949000418186188,
              0.03161719813942909,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              -0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              -0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              -0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              -0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              -0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              -0.7291479706764221,
              -0.30146199464797974,
              0.6143379807472229,
              -0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              -0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              -0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              -0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              -0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              -0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              -0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              -0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              -0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              -0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              -0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              -0.7413859963417053,
              -0.49925199151039124,
              0.44840800762176514,
              -0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              -0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              -0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              -0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              -0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              -0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              -0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              -0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              -0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              -0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              -0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              -0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              -0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              -0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              -0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              -0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              -0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              -0.6626179814338684,
              0.738211989402771,
              -0.12619400024414062,
              -0.15204299986362457,
              0.9713429808616638,
              -0.182561993598938,
              -0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              -0.15204299986362457,
              0.9713429808616638,
              -0.182561993598938,
              -0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              -0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              -0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              -0.7291479706764221,
              -0.30146199464797974,
              0.6143379807472229,
              -0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              -0.7291479706764221,
              -0.30146199464797974,
              0.6143379807472229,
              -0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              -0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              -0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              -0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              -0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              -0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              -0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              -0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              -0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              -0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              -0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              -0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              -0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              -0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              -0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              -0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              -0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              -0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              -0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              -0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              -0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              -0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              -0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              -0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              -0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              -0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              -0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              -0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              -0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              -0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              -0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              -0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              -0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              -0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              -0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              -0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              -0.1539350003004074,
              0.793084979057312,
              -0.5893120169639587,
              -0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              -0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              -0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              -0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              -0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              -0.15360000729560852,
              0.8478350043296814,
              -0.5074920058250427,
              -0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              -0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              -0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              -0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              -0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              -0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              -0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              -0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              -0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              -0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              -0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              -0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              -0.43629300594329834,
              -0.7016819715499878,
              0.5632500052452087,
              -0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              -0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              -0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              -0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              -0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              -0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              -0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              -0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              -0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              -0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              -0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              -0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              -0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              -0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              -0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              -0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              -0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              -0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              -0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              -0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              -0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              -0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              -0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              -0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              -0.43629300594329834,
              -0.7016819715499878,
              0.5632500052452087,
              -0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              -0.43629300594329834,
              -0.7016819715499878,
              0.5632500052452087,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              -0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              -0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              -0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              -0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              -0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              -0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              -0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              -0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              -0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              -0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              -0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              -0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              -0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              -0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.707082986831665,
              0.707082986831665,
              0,
              -0.987060010433197,
              0.16016100347042084,
              0,
              -0.707082986831665,
              0.707082986831665,
              0,
              -0.987060010433197,
              0.16019199788570404,
              0,
              -0.987060010433197,
              0.16016100347042084,
              -0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              -0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              -0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              -0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              -0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              -0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              -0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              -0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              -0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              -0.1095609962940216,
              0.9823300242424011,
              0.1516769975423813,
              -0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              -0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              -0.1095609962940216,
              0.9823300242424011,
              0.1516769975423813,
              -0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              -0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              -0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              -0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              -0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              -0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              -0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              -0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              -0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              -0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              -0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              -0.6640830039978027,
              0.6973170042037964,
              0.2696309983730316,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              -0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              -0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              -0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              -0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              -0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              -0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              -0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              -0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              -0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              -0.7017729878425598,
              0.7081519961357117,
              -0.07760860025882721,
              -0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              -0.15887899696826935,
              0.9872429966926575,
              0.008758810348808765,
              -0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              -0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              -0.15887899696826935,
              0.9872429966926575,
              0.008758810348808765,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0,
              0.6957610249519348,
              0.7182530164718628,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.9817799925804138,
              0.18985599279403687,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9817799925804138,
              0.18985599279403687,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              -0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              -0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              -0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              -0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              -0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              -0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              -0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              -0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              -0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              -0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              -0.7347639799118042,
              -0.3935360014438629,
              0.5524460077285767,
              -0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              -0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              -0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              -0.7365339994430542,
              -0.42191800475120544,
              0.5286110043525696,
              -0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              -0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              -0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              -0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              -0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              -0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              -0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              -0.6876429915428162,
              0.30149200558662415,
              -0.6604509949684143,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              -0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              -0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              -0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              -0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              -0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              -0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              -0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              -0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              -0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              -0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              -0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              -0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              -0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              -0.6621599793434143,
              0.7137359976768494,
              -0.2282170057296753,
              -0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              -0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              -0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              -0.6628010272979736,
              0.736503005027771,
              -0.13489200174808502,
              -0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              -0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              -0.6628010272979736,
              0.736503005027771,
              -0.13489200174808502,
              -0.16119900345802307,
              -0.29322201013565063,
              0.9423199892044067,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              -0.7209389805793762,
              -0.16534900665283203,
              0.6729639768600464,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              -0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              -0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              -0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              -0.7230139970779419,
              -0.20056800544261932,
              0.6610609889030457,
              -0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              -0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              -0.7413859963417053,
              -0.49925199151039124,
              0.44840800762176514,
              -0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              -0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              -0.7413859963417053,
              -0.49925199151039124,
              0.44840800762176514,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.16385400295257568,
              -0.9680780172348022,
              0.1895810067653656,
              -0.7472760081291199,
              -0.6491590142250061,
              0.141757994890213,
              -0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              -0.7472760081291199,
              -0.6491590142250061,
              0.141757994890213,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              -0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              -0.6771450042724609,
              0.4500870108604431,
              -0.5821099877357483,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              -0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              -0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              -0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              -0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              -0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              -0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              -0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              -0.6725670099258423,
              0.5306559801101685,
              -0.5157930254936218,
              -0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              -0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              -0.1539350003004074,
              0.793084979057312,
              -0.5893120169639587,
              -0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              -0.1539350003004074,
              0.793084979057312,
              -0.5893120169639587,
              -0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              -0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              -0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              -0.15360000729560852,
              0.8478350043296814,
              -0.5074920058250427,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.15360000729560852,
              0.8478350043296814,
              -0.5074920058250427,
              -0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              -0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              -0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              -0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              -0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              -0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              -0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              -0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              -0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              -0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              -0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              -0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              -0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              -0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              -0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              -0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              -0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              -0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              -0.6453750133514404,
              -0.7016209959983826,
              0.30195000767707825,
              -0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              -0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              -0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              -0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              -0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              -0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              -0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              -0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              -0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              -0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              0,
              -0.987060010433197,
              0.16019199788570404,
              -0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              0,
              -0.987060010433197,
              0.16019199788570404,
              0,
              -0.707082986831665,
              0.707082986831665,
              -0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              -0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              -0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              -0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              -0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              -0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              -0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              -0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              -0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              -0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              -0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              -0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              -0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              -0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              -0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              -0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              -0.3364970088005066,
              0.691519021987915,
              0.639149010181427,
              -0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              -0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              -0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              -0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              -0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              -0.3852350115776062,
              0.6920379996299744,
              0.6104620099067688,
              -0.1095609962940216,
              0.9823300242424011,
              0.1516769975423813,
              -0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              -0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              -0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              -0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              -0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              -0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              -0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              -0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              -0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              -0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              -0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              -0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              -0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              -0.6640830039978027,
              0.6973170042037964,
              0.2696309983730316,
              -0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              -0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              -0.6640830039978027,
              0.6973170042037964,
              0.2696309983730316,
              -0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              -0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              -0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              -0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              -0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              -0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              -0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              -0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              -0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              -0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              -0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              -0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.7250890135765076,
              -0.23505400121212006,
              0.6472370028495789,
              -0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.7291479706764221,
              -0.30146199464797974,
              0.6143379807472229,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.7271339893341064,
              -0.2687149941921234,
              0.6316720247268677,
              -0.7291479706764221,
              -0.30146199464797974,
              0.6143379807472229,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              -0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              -0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              -0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              -0.7329630255699158,
              -0.3639639914035797,
              0.5746639966964722,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.7413859963417053,
              -0.49925199151039124,
              0.44840800762176514,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              -0.7413859963417053,
              -0.49925199151039124,
              0.44840800762176514,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              -0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              -0.7428510189056396,
              -0.5221719741821289,
              0.41883599758148193,
              -0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              -0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              -0.6740319728851318,
              0.505020022392273,
              -0.5390790104866028,
              -0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              -0.671163022518158,
              0.5550100207328796,
              -0.4913789927959442,
              -0.9630730152130127,
              0.26761698722839355,
              -0.029145199805498123,
              -0.6626179814338684,
              0.738211989402771,
              -0.12619400024414062,
              -0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              -0.6626179814338684,
              0.738211989402771,
              -0.12619400024414062,
              -0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              -0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              -0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.7291479706764221,
              -0.30146199464797974,
              0.6143379807472229,
              -0.7311319708824158,
              -0.33323198556900024,
              0.5952939987182617,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.738273024559021,
              -0.44901901483535767,
              0.5032809972763062,
              -0.739890992641449,
              -0.4748069941997528,
              0.4765160083770752,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703452005982399,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703452005982399,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703452005982399,
              -0.7466350197792053,
              -0.5817130208015442,
              0.322611004114151,
              -0.747734010219574,
              -0.5981630086898804,
              0.2881860136985779,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              -0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              -0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              -0.749504029750824,
              -0.6256290078163147,
              0.21628500521183014,
              -0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.7502670288085938,
              -0.6364020109176636,
              0.17905199527740479,
              -0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              -0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              -0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              -0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              -0.6787930130958557,
              0.42085000872612,
              -0.6017029881477356,
              -0.6805319786071777,
              0.3904539942741394,
              -0.6199529767036438,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              -0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              -0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              -0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              -0.6698510050773621,
              0.578112006187439,
              -0.46586498618125916,
              -0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.6674399971961975,
              0.6203799843788147,
              -0.4118170142173767,
              -0.668599009513855,
              0.5999019742012024,
              -0.43934398889541626,
              -0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.6653040051460266,
              0.65727698802948,
              -0.353983998298645,
              -0.6663410067558289,
              0.6395149827003479,
              -0.38337400555610657,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.6628310084342957,
              0.701865017414093,
              -0.2608110010623932,
              -0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              -0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.6611220240592957,
              0.7327489852905273,
              -0.16113799810409546,
              -0.6615800261497498,
              0.7240520119667053,
              -0.19498300552368164,
              -0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              -0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              -0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              -0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              -0.17804500460624695,
              -0.7013760209083557,
              0.6901760101318359,
              -0.11966300010681152,
              -0.7013459801673889,
              0.702688992023468,
              -0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              -0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              -0.43629300594329834,
              -0.7016819715499878,
              0.5632500052452087,
              -0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              -0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              -0.43629300594329834,
              -0.7016819715499878,
              0.5632500052452087,
              -0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              -0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              -0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              -0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              -0.5193949937820435,
              -0.7017430067062378,
              0.48759400844573975,
              -0.47941499948501587,
              -0.7017120122909546,
              0.5269629955291748,
              -0.7705619931221008,
              -0.15967300534248352,
              0.6169930100440979,
              -0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              -0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              -0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              -0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              -0.5561079978942871,
              -0.7017120122909546,
              0.4452959895133972,
              -0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              -0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              -0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              -0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              -0.7083650231361389,
              -0.7014070153236389,
              0.07873780280351639,
              -0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              -0.7048249840736389,
              -0.7041540145874023,
              -0.08548229932785034,
              -0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              -0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              -0.014954100362956524,
              -0.7041540145874023,
              0.7098609805107117,
              -0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              -0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              -0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              -0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              -0.34098300337791443,
              -0.7015290260314941,
              0.6257209777832031,
              -0.2891019880771637,
              -0.7014679908752441,
              0.6513869762420654,
              -0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              -0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              -0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              -0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              -0.43629300594329834,
              -0.7016819715499878,
              0.5632500052452087,
              -0.3900879919528961,
              -0.7016209959983826,
              0.5962100028991699,
              -0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.6192820072174072,
              -0.7016509771347046,
              0.35233598947525024,
              -0.5894349813461304,
              -0.7016819715499878,
              0.40015900135040283,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              -0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              -0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              -0.667622983455658,
              -0.7015290260314941,
              0.2491530030965805,
              -0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              -0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              -0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              -0.6993619799613953,
              -0.7014679908752441,
              0.13721099495887756,
              -0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.707082986831665,
              0.707082986831665,
              0,
              -0.7071139812469482,
              0.707082986831665,
              -0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              -0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              -0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              -0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              -0.17548100650310516,
              0.6903290152549744,
              0.701865017414093,
              -0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              -0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              -0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              -0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              -0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              -0.23139099776744843,
              0.6906030178070068,
              0.6851710081100464,
              -0.285165011882782,
              0.6910309791564941,
              0.6641740202903748,
              -0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              -0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              -0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              -0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              -0.43116599321365356,
              0.6925569772720337,
              0.5782949924468994,
              -0.47413599491119385,
              0.6931059956550598,
              0.5428630113601685,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              -0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              -0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              -0.514083981513977,
              0.6937469840049744,
              0.5043489933013916,
              -0.5509200096130371,
              0.6944180130958557,
              0.46287399530410767,
              -0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              -0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              -0.5844900012016296,
              0.6950590014457703,
              0.41856101155281067,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              -0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.6640830039978027,
              0.6973170042037964,
              0.2696309983730316,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              -0.6640830039978027,
              0.6973170042037964,
              0.2696309983730316,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.6828209757804871,
              0.6981409788131714,
              0.2151859998703003,
              -0.6973170042037964,
              0.6989650130271912,
              0.1586659997701645,
              -0.9866629838943481,
              0.15949000418186188,
              0.03161719813942909,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              -0.712240993976593,
              0.7007049918174744,
              0.041108399629592896,
              -0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              -0.7017729878425598,
              0.7081519961357117,
              -0.07760860025882721,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              -0.706167995929718,
              0.7051609754562378,
              -0.06335639953613281,
              -0.7017729878425598,
              0.7081519961357117,
              -0.07760860025882721,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0,
              0.15851299464702606,
              0.9873350262641907,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.6957610249519348,
              0.7182530164718628,
              -0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.6957610249519348,
              0.7182530164718628,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9924619793891907,
              0.012634700164198875,
              0.121738001704216,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              -0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              -0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              -0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.9985960125923157,
              -0.04269539937376976,
              0.03112890012562275,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              -0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              -0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              -0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              -0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7705619931221008,
              -0.15967300534248352,
              0.6169930100440979,
              -0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              -0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9771720170974731,
              -0.1600389927625656,
              -0.13946999609470367,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0,
              0,
              1,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              -0.16016100347042084,
              0.987060010433197,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              0,
              -0.16016100347042084,
              0.987060010433197,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.16498300433158875,
              0.15790300071239471,
              0.9735400080680847,
              -0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              -0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.4703820049762726,
              0.15805499255657196,
              0.8681600093841553,
              -0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.538316011428833,
              0.15814700722694397,
              0.827754020690918,
              -0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              -0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9866629838943481,
              0.15949000418186188,
              0.03161719813942909,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.4917750060558319,
              0.870693027973175,
              -0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              0,
              -0.4917750060558319,
              0.870693027973175,
              -0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              -0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              -0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              -0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              -0.16223600506782532,
              -0.4704729914665222,
              0.8673670291900635,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              -0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              -0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              -0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              -0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              -0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              -0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              -0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              -0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.5785390138626099,
              -0.81563800573349,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0,
              0.5785390138626099,
              -0.81563800573349,
              -0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              -0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              -0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              -0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              -0.15204299986362457,
              0.9713429808616638,
              -0.182561993598938,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              -0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              -0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.578050971031189,
              0.8159729838371277,
              -0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              0,
              -0.578050971031189,
              0.8159729838371277,
              -0.16278600692749023,
              -0.5572680234909058,
              0.8141729831695557,
              -0.16251100599765778,
              -0.5146340131759644,
              0.8418530225753784,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              -0.1639149934053421,
              -0.7453230023384094,
              0.6461989879608154,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.9014250040054321,
              0.432904988527298,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0,
              -0.9014250040054321,
              0.432904988527298,
              -0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              -0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              -0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              -0.1647389978170395,
              -0.8852810263633728,
              0.4348280131816864,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              -0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              -0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              -0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              -0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              -0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              -0.1652269959449768,
              -0.9563890099525452,
              0.24082200229167938,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              -0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              -0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.446943998336792,
              -0.8945279717445374,
              -0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              0,
              0.446943998336792,
              -0.8945279717445374,
              -0.15598000586032867,
              0.4561910033226013,
              -0.8760949969291687,
              -0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              -0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              -0.1543319970369339,
              0.7305219769477844,
              -0.6651809811592102,
              -0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              -0.1539350003004074,
              0.793084979057312,
              -0.5893120169639587,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              -0.15414899587631226,
              0.7627490162849426,
              -0.6280400156974792,
              -0.1539350003004074,
              0.793084979057312,
              -0.5893120169639587,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              -0.15360000729560852,
              0.8478350043296814,
              -0.5074920058250427,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              -0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              -0.15360000729560852,
              0.8478350043296814,
              -0.5074920058250427,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.878383994102478,
              -0.4779199957847595,
              -0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              0,
              0.878383994102478,
              -0.4779199957847595,
              -0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              -0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              0,
              0.922544002532959,
              -0.38581499457359314,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              -0.15338599681854248,
              0.8943449854850769,
              -0.42017900943756104,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              -0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              -0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              -0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              -0.00006103699706727639,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0,
              -1,
              -0.00006103699706727639,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0,
              -1,
              0,
              0,
              -1,
              0.000030518498533638194,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0,
              -1,
              0.000030518498533638194,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              -0.00012207399413455278,
              -1,
              0.00012207399413455278,
              0,
              -1,
              0,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0,
              -1,
              0,
              -0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0,
              -1,
              0,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              0,
              -1,
              0,
              -0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              -0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              0,
              -1,
              0,
              -0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              -0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              0,
              -1,
              0.000030518498533638194,
              0,
              -1,
              0.000030518498533638194,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0,
              -1,
              0.000030518498533638194,
              -0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.00009155549923889339,
              0,
              -1,
              -0.000030518498533638194,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              0,
              -1,
              -0.000030518498533638194,
              -0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              0,
              -1,
              0.000030518498533638194,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              -0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              -0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              0,
              -1,
              0,
              -0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              -0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              0,
              -1,
              0,
              -0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              -0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              0,
              -1,
              0,
              -0.15790300071239471,
              -0.9864190220832825,
              0.044679101556539536,
              -0.16104599833488464,
              -0.9864190220832825,
              0.031586699187755585,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.987060010433197,
              0.16019199788570404,
              0,
              -1,
              0,
              0,
              -0.987060010433197,
              0.16016100347042084,
              0,
              -0.987060010433197,
              0.16019199788570404,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.00009155549923889339,
              0.9994810223579407,
              0.031495101749897,
              -0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              0.00009155549923889339,
              0.9994810223579407,
              0.031495101749897,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              -0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              0.00009155549923889339,
              0.9994810223579407,
              0.031495101749897,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              -0.05355999991297722,
              0.9812920093536377,
              0.1848510056734085,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.1095609962940216,
              0.9823300242424011,
              0.1516769975423813,
              -0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              -0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              -0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.00015259299834724516,
              0.9994810223579407,
              0.031891800463199615,
              -0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              -0.00015259299834724516,
              0.9994810223579407,
              0.031891800463199615,
              -0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              -0.14188100397586823,
              0.983519971370697,
              0.11200299859046936,
              -0.00015259299834724516,
              0.9994810223579407,
              0.031891800463199615,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              -0.14792299270629883,
              0.9838560223579407,
              0.10049699991941452,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              -0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              -0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.15887899696826935,
              0.9872429966926575,
              0.008758810348808765,
              -0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9994810223579407,
              0.031769800931215286,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              -0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              -0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              -0.1629990041255951,
              -0.5983150005340576,
              0.7844780087471008,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              -0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              -0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              -0.16321299970149994,
              -0.6377450227737427,
              0.7527390122413635,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              -0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              -0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              -0.16342699527740479,
              -0.6754050254821777,
              0.7190470099449158,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              -0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              -0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              -0.16489200294017792,
              -0.9067050218582153,
              0.3881340026855469,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              -0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              -0.16510500013828278,
              -0.9423509836196899,
              0.29099398851394653,
              -0.16498300433158875,
              -0.9257789850234985,
              0.34012898802757263,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.31540900468826294,
              -0.948943018913269,
              -0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              0,
              0.31540900468826294,
              -0.948943018913269,
              -0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              -0.1565600037574768,
              0.3639639914035797,
              -0.9181489944458008,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              -0.15570500493049622,
              0.500229001045227,
              -0.8517720103263855,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.922544002532959,
              -0.38581499457359314,
              -0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              0,
              0.922544002532959,
              -0.38581499457359314,
              -0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              -0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              -0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              -0.15323300659656525,
              0.9321569800376892,
              -0.32798200845718384,
              -0.15317200124263763,
              0.9476000070571899,
              -0.28031298518180847,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              -0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              -0.1530809998512268,
              0.9606310129165649,
              -0.2316969931125641,
              -0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              -0.16119900345802307,
              -0.29322201013565063,
              0.9423199892044067,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              -0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              -0.16119900345802307,
              -0.29322201013565063,
              0.9423199892044067,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              -0.16199199855327606,
              -0.4248790144920349,
              0.8906220197677612,
              -0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              -0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              -0.16425099968910217,
              -0.8075810074806213,
              0.5663930177688599,
              -0.16406799852848053,
              -0.7774289846420288,
              0.607134997844696,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8781700134277344,
              0.478316992521286,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0,
              -0.8781700134277344,
              0.478316992521286,
              -0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              -0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              -0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              -0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              -0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              -0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              -0.15546099841594696,
              0.5427410006523132,
              -0.8253729939460754,
              -0.1552170068025589,
              0.5836970210075378,
              -0.7969599962234497,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              -0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              -0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              -0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.6581929922103882,
              -0.752830982208252,
              -0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              0,
              0.6581929922103882,
              -0.752830982208252,
              -0.1547590047121048,
              0.6606040000915527,
              -0.7345809936523438,
              -0.15454599261283875,
              0.6964629888534546,
              -0.7007049918174744,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.7958620190620422,
              -0.605426013469696,
              -0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              0,
              0.7958620190620422,
              -0.605426013469696,
              -0.1539350003004074,
              0.793084979057312,
              -0.5893120169639587,
              -0.15378299355506897,
              0.8214359879493713,
              -0.5491200089454651,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.8529919981956482,
              -0.52189701795578,
              -0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              0,
              0.8529919981956482,
              -0.52189701795578,
              -0.15360000729560852,
              0.8478350043296814,
              -0.5074920058250427,
              -0.1535079926252365,
              0.8721579909324646,
              -0.46449199318885803,
              -0.000030518498533638194,
              -1,
              0.00009155549923889339,
              0,
              -0.9999690055847168,
              0,
              -0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              0,
              -0.9999690055847168,
              0,
              -0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              -0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              0,
              -1,
              -0.000030518498533638194,
              -0.000030518498533638194,
              -1,
              0.00009155549923889339,
              -0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              -0.000030518498533638194,
              -1,
              0.00009155549923889339,
              -0.0540178008377552,
              -0.9864190220832825,
              0.15491199493408203,
              -0.06653030216693878,
              -0.9864500164985657,
              0.1499370038509369,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.00009155549923889339,
              -0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.00009155549923889339,
              -0.07849360257387161,
              -0.9864190220832825,
              0.14404700696468353,
              -0.08981599658727646,
              -0.9864190220832825,
              0.13727200031280518,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              0,
              -1,
              0,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              -0.1639149934053421,
              -0.9864190220832825,
              -0.009399699978530407,
              0,
              -1,
              -0.00006103699706727639,
              0,
              -1,
              0.000030518498533638194,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0,
              -1,
              0.000030518498533638194,
              -0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              -0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              -0.00012207399413455278,
              -1,
              0.00012207399413455278,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              -0.00012207399413455278,
              -1,
              0.00012207399413455278,
              -0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              -0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              -0.00006103699706727639,
              -1,
              0,
              0,
              -1,
              0,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0,
              -1,
              0,
              -0.14255200326442719,
              -0.9864500164985657,
              0.08108770102262497,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0,
              -1,
              0,
              -0.00006103699706727639,
              -1,
              0,
              -0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              -0.00006103699706727639,
              -1,
              0,
              -0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              -0.15369099378585815,
              -0.9864190220832825,
              0.05734429880976677,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0,
              -1,
              0,
              -0.16315199434757233,
              -0.9864190220832825,
              0.01812800019979477,
              -0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -1,
              0,
              -0.16101600229740143,
              -0.9867550134658813,
              -0.019501300528645515,
              -0.1586659997701645,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -1,
              0.000030518498533638194,
              0,
              -1,
              0,
              -0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              0,
              -1,
              0,
              0,
              -0.987060010433197,
              0.16019199788570404,
              -0.0034180700313299894,
              -0.9867240190505981,
              0.1621749997138977,
              0,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              -0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              -0.013702799566090107,
              0.981016993522644,
              0.19333499670028687,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.04065069928765297,
              0.9811699986457825,
              0.18878799676895142,
              -0.02731410041451454,
              0.9810789823532104,
              0.19162599742412567,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              -0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              -0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.09967339783906937,
              0.9820550084114075,
              0.15991699695587158,
              -0.08905299752950668,
              0.9818419814109802,
              0.1673640012741089,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              -0.1095609962940216,
              0.9823300242424011,
              0.1516769975423813,
              0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              -0.11877799779176712,
              0.9825739860534668,
              0.14273500442504883,
              -0.1095609962940216,
              0.9823300242424011,
              0.1516769975423813,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.13498300313949585,
              0.9831839799880981,
              0.12289799749851227,
              -0.12723200023174286,
              0.9828789830207825,
              0.13315199315547943,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.1574140042066574,
              0.9845880270004272,
              0.07586900144815445,
              -0.15314200520515442,
              0.9842219948768616,
              0.0884426012635231,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.1630299985408783,
              0.985351026058197,
              0.04944000020623207,
              -0.16071000695228577,
              0.9849849939346313,
              0.06283760070800781,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              -0.16415899991989136,
              0.9857779741287231,
              0.035767700523138046,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.1612289994955063,
              0.9868159890174866,
              0.011841200292110443,
              -0.16406799852848053,
              0.986175000667572,
              0.02191229909658432,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              -0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.9950860142707825,
              -0.010071099735796452,
              0.09833060204982758,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9956660270690918,
              -0.014984600245952606,
              0.09173859655857086,
              -0.9961850047111511,
              -0.019531799480319023,
              0.08487199991941452,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9686880111694336,
              0.21448400616645813,
              -0.12494300305843353,
              -0.9693899750709534,
              0.2082580029964447,
              -0.1299780011177063,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.9673759937286377,
              0.2261119931936264,
              -0.11407800018787384,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              -0.9909660220146179,
              0.025666100904345512,
              0.13150399923324585,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              -0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703452005982399,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703452005982399,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9971309900283813,
              -0.02761930041015148,
              0.0703452005982399,
              -0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9981989860534668,
              -0.03686640039086342,
              0.046937499195337296,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              -0.9733269810676575,
              0.17322300374507904,
              -0.15027299523353577,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9708849787712097,
              0.19495199620723724,
              -0.13907299935817719,
              -0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              -0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              -0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              -0.9651780128479004,
              0.24585700035095215,
              -0.08929719775915146,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              -0.9642630219459534,
              0.2538830041885376,
              -0.07556380331516266,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              -0.9638659954071045,
              0.2573930025100708,
              -0.06836149841547012,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              -0.9629200100898743,
              0.26584699749946594,
              -0.04574719816446304,
              -0.16791300475597382,
              0,
              0.9857779741287231,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.2465900033712387,
              -0.1595810055732727,
              0.9558699727058411,
              -0.16574600338935852,
              -0.1596119999885559,
              0.973143994808197,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              -0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7196869850158691,
              -0.15967300534248352,
              0.6756489872932434,
              -0.6642959713935852,
              -0.15967300534248352,
              0.7301859855651855,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.7705619931221008,
              -0.15967300534248352,
              0.6169930100440979,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              -0.7705619931221008,
              -0.15967300534248352,
              0.6169930100440979,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9811090230941772,
              -0.1596119999885559,
              0.10907299816608429,
              -0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              -0.9983519911766052,
              0,
              -0.05731379985809326,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              -0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              -0.08429209887981415,
              0,
              0.9964290261268616,
              -0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              -0.02078310027718544,
              -0.15988600254058838,
              0.9869080185890198,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              -0.47850000858306885,
              0,
              0.8780480027198792,
              -0.47236499190330505,
              -0.1596119999885559,
              0.8668169975280762,
              -0.4004330039024353,
              -0.1596119999885559,
              0.9022799730300903,
              -0.5475019812583923,
              0,
              0.8367869853973389,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.604511022567749,
              -0.15967300534248352,
              0.7803890109062195,
              -0.5404829978942871,
              -0.15964199602603912,
              0.8260440230369568,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              -0.8167359828948975,
              -0.15964199602603912,
              0.5544599890708923,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              -0.9248629808425903,
              -0.15964199602603912,
              0.3451339900493622,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9686880111694336,
              -0.15964199602603912,
              0.1900389939546585,
              -0.949828028678894,
              -0.15964199602603912,
              0.26892900466918945,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              0,
              1,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.16016100347042084,
              0.987060010433197,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              -0.24979400634765625,
              0,
              0.9682909846305847,
              -0.24546000361442566,
              0.15793299674987793,
              0.9564200043678284,
              -0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              -0.4056209921836853,
              0,
              0.9139990210533142,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              -0.32926398515701294,
              0,
              0.944212019443512,
              -0.3235569894313812,
              0.15796400606632233,
              0.932889997959137,
              -0.3986940085887909,
              0.15799400210380554,
              0.9033480286598206,
              -0.6729329824447632,
              0,
              0.7396770119667053,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              -0.6123539805412292,
              0,
              0.7905510067939758,
              -0.6022220253944397,
              0.1582379937171936,
              0.7824640274047852,
              -0.6619459986686707,
              0.15836100280284882,
              0.7325969934463501,
              -0.7805719971656799,
              0,
              0.6250190138816833,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.7290570139884949,
              0,
              0.6844390034675598,
              -0.7173380255699158,
              0.15845200419425964,
              0.6784260272979736,
              -0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              -0.8273259997367859,
              0,
              0.5616629719734192,
              -0.8145390152931213,
              0.1586350053548813,
              0.5579699873924255,
              -0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.8691369891166687,
              0,
              0.49452200531959534,
              -0.8559529781341553,
              0.1587270051240921,
              0.49201899766921997,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.9368569850921631,
              0,
              0.3496200144290924,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              -0.9057589769363403,
              0,
              0.42375001311302185,
              -0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              -0.9233070015907288,
              0.15897099673748016,
              0.349590003490448,
              -0.9812620282173157,
              0,
              0.19251100718975067,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.9621570110321045,
              0,
              0.27240800857543945,
              -0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              -0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              -0.9996340274810791,
              0,
              0.02691729925572872,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9866629838943481,
              0.15949000418186188,
              0.03161719813942909,
              -0.9938660264015198,
              0,
              0.11047700047492981,
              -0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              -0.9866629838943481,
              0.15949000418186188,
              0.03161719813942909,
              -0.9899590015411377,
              0,
              -0.1413010060787201,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              -0.9927060008049011,
              0,
              -0.12039600312709808,
              -0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              -0.9778739809989929,
              0.16019199788570404,
              -0.13446499407291412,
              -0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0,
              0,
              1,
              0,
              0.15851299464702606,
              0.9873350262641907,
              -0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0,
              1,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              -1,
              0.000030518498533638194,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0.000030518498533638194,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0.000030518498533638194,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.00006103699706727639,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0,
              -1,
              0,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0,
              -1,
              0,
              -0.00012207399413455278,
              -1,
              0.00012207399413455278,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              -0.000030518498533638194,
              0,
              -1,
              0.000030518498533638194,
              0,
              -1,
              -0.000030518498533638194,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0,
              -1,
              0.000030518498533638194,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.000030518498533638194,
              0,
              -1,
              -0.000030518498533638194,
              -0.000030518498533638194,
              -0.9999690055847168,
              0.00009155549923889339,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              -0.000030518498533638194,
              0,
              -1,
              0,
              0,
              -1,
              -0.000030518498533638194,
              0,
              -0.9999690055847168,
              0,
              -0.000030518498533638194,
              -1,
              0.00009155549923889339,
              0,
              -1,
              -0.00006103699706727639,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0.000030518498533638194,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.00015259299834724516,
              0.9994810223579407,
              0.031891800463199615,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.00009155549923889339,
              0.9994810223579407,
              0.031495101749897,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6187319755554199,
              0.7855769991874695,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9223610162734985,
              0.38624200224876404,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.35154300928115845,
              0.9361550211906433,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18814699351787567,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.922544002532959,
              -0.38581499457359314,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.922544002532959,
              -0.38581499457359314,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.922544002532959,
              -0.38581499457359314,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.8529919981956482,
              -0.52189701795578,
              -0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              -0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.6876429915428162,
              0.30149200558662415,
              -0.6604509949684143,
              -0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.16119900345802307,
              -0.29322201013565063,
              0.9423199892044067,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.16101600229740143,
              -0.2808620035648346,
              0.9461349844932556,
              -0.16119900345802307,
              -0.29322201013565063,
              0.9423199892044067,
              -0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              -0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              -0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              -0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              -0.6876429915428162,
              0.30149200558662415,
              -0.6604509949684143,
              -0.15710900723934174,
              0.3282569944858551,
              -0.9313939809799194,
              -0.9859610199928284,
              0.06765949726104736,
              0.15253199636936188,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.16119900345802307,
              -0.29322201013565063,
              0.9423199892044067,
              -0.16101600229740143,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.314767986536026,
              0.9491559863090515,
              -0.16101600229740143,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.314767986536026,
              0.9491559863090515,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              -0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              -0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              -0.6628010272979736,
              0.736503005027771,
              -0.13489200174808502,
              -0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              -0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              -0.6628010272979736,
              0.736503005027771,
              -0.13489200174808502,
              -0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              -0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              -0.7489550113677979,
              -0.645160973072052,
              0.15100599825382233,
              -0.1652580052614212,
              -0.9652090072631836,
              0.20252099633216858,
              -0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              -0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.6646630167961121,
              0.7364420294761658,
              -0.12579700350761414,
              -0.6628010272979736,
              0.736503005027771,
              -0.13489200174808502,
              -0.9628890156745911,
              0.2679530084133148,
              -0.0314645990729332,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11893100291490555,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9985349774360657,
              -0.04342779889702797,
              0.031281501054763794,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              -0.15384399890899658,
              0.9710680246353149,
              -0.18246999382972717,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              -0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9795830249786377,
              -0.200873002409935,
              -0.1534779965877533,
              0.9687489867210388,
              -0.1947689950466156,
              -0.16385400295257568,
              -0.9680780172348022,
              0.1895810067653656,
              -0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              -0.1652269959449768,
              -0.9678339958190918,
              0.1895499974489212,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              -0.9630730152130127,
              0.26761698722839355,
              -0.029145199805498123,
              -0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18814699351787567,
              0,
              0.9795830249786377,
              -0.200873002409935,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              -0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              -0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              -0.6885889768600464,
              0.29334399104118347,
              -0.6631370186805725,
              -0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              -0.16101600229740143,
              -0.2808620035648346,
              0.9461349844932556,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.7152619957923889,
              -0.13202300667762756,
              0.6862390041351318,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.15747599303722382,
              0.31635499000549316,
              -0.9354529976844788,
              -0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              -0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              -0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              -0.6885889768600464,
              0.29334399104118347,
              -0.6631370186805725,
              -0.6900230050086975,
              0.2930999994277954,
              -0.6617329716682434,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.7152619957923889,
              -0.13202300667762756,
              0.6862390041351318,
              -0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              -0.16101600229740143,
              -0.2808620035648346,
              0.9461349844932556,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              0.6840720176696777,
              0.16745500266551971,
              -0.7098910212516785,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              0.6839200258255005,
              0.23142200708389282,
              -0.6918240189552307,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.6840720176696777,
              0.16745500266551971,
              -0.7098910212516785,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.6840720176696777,
              0.16745500266551971,
              -0.7098910212516785,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              0.6839200258255005,
              0.23142200708389282,
              -0.6918240189552307,
              0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.6839200258255005,
              0.23142200708389282,
              -0.6918240189552307,
              0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.9883109927177429,
              0.13931700587272644,
              -0.06134219840168953,
              0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961269974708557,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961269974708557,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961269974708557,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.7290570139884949,
              0.46678099036216736,
              0.5005339980125427,
              0.983489990234375,
              0.1804250031709671,
              -0.012787300162017345,
              0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              0.983489990234375,
              0.1804250031709671,
              -0.012787300162017345,
              0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.7353739738464355,
              0.5657830238342285,
              0.37287500500679016,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.7353739738464355,
              0.5657830238342285,
              0.37287500500679016,
              0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              0.9883109927177429,
              0.13931700587272644,
              -0.06134219840168953,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.9883109927177429,
              0.13931700587272644,
              -0.06134219840168953,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              0.1573529988527298,
              -0.981016993522644,
              -0.11316300183534622,
              0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              0.1573529988527298,
              -0.981016993522644,
              -0.11316300183534622,
              0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              0.1573529988527298,
              -0.981016993522644,
              -0.11316300183534622,
              0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0.15427100658416748,
              -0.8327590227127075,
              -0.5316929817199707,
              0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              0.15622399747371674,
              -0.3031400144100189,
              -0.9400309920310974,
              0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              0.15622399747371674,
              -0.3031400144100189,
              -0.9400309920310974,
              0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              0.16187000274658203,
              0.9183930158615112,
              0.3609730005264282,
              0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              0.16187000274658203,
              0.9183930158615112,
              0.3609730005264282,
              0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              0.16187000274658203,
              0.9183930158615112,
              0.3609730005264282,
              0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              0.16022199392318726,
              -0.006958220154047012,
              0.987030029296875,
              0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              0.16022199392318726,
              -0.006958220154047012,
              0.987030029296875,
              0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              0.16022199392318726,
              -0.006958220154047012,
              0.987030029296875,
              0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              0.7290570139884949,
              0.46678099036216736,
              0.5005339980125427,
              0.1622059941291809,
              0.5575429797172546,
              0.8141419887542725,
              0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              0.1622059941291809,
              0.5575429797172546,
              0.8141419887542725,
              0.16272500157356262,
              0.6233710050582886,
              0.7647939920425415,
              0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              0.16272500157356262,
              0.6233710050582886,
              0.7647939920425415,
              0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              0.16272500157356262,
              0.6233710050582886,
              0.7647939920425415,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0.7353739738464355,
              0.5657830238342285,
              0.37287500500679016,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              0.7353739738464355,
              0.5657830238342285,
              0.37287500500679016,
              0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              0.7150179743766785,
              -0.1321450024843216,
              0.6864830255508423,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.15891000628471375,
              -0.986998975276947,
              -0.022675300016999245,
              0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              0.15427100658416748,
              -0.8327590227127075,
              -0.5316929817199707,
              0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              0.15427100658416748,
              -0.8327590227127075,
              -0.5316929817199707,
              0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              0.6678670048713684,
              -0.45536699891090393,
              -0.5886719822883606,
              0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              0.6687830090522766,
              -0.40812399983406067,
              -0.6213870048522949,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              0.6734210252761841,
              -0.35370999574661255,
              -0.6490979790687561,
              0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              0.15622399747371674,
              -0.3031400144100189,
              -0.9400309920310974,
              0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0.6840720176696777,
              0.16745500266551971,
              -0.7098910212516785,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0.6840720176696777,
              0.16745500266551971,
              -0.7098910212516785,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0.6839200258255005,
              0.23142200708389282,
              -0.6918240189552307,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0.6839200258255005,
              0.23142200708389282,
              -0.6918240189552307,
              0.6840720176696777,
              0.16745500266551971,
              -0.7098910212516785,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425640106201172,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.6839200258255005,
              0.23142200708389282,
              -0.6918240189552307,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961269974708557,
              0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961269974708557,
              0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              0.7167580127716064,
              -0.039796099066734314,
              0.6961269974708557,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              0.7198709845542908,
              0.33240801095962524,
              0.6093019843101501,
              0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              0.1622059941291809,
              0.5575429797172546,
              0.8141419887542725,
              0.7290570139884949,
              0.46678099036216736,
              0.5005339980125427,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0.7290570139884949,
              0.46678099036216736,
              0.5005339980125427,
              0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.7353739738464355,
              0.5657830238342285,
              0.37287500500679016,
              0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0.7344890236854553,
              0.6168100237846375,
              0.28290700912475586,
              0.735617995262146,
              0.5924860239028931,
              0.3282879889011383,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425640106201172,
              0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              0.6869410276412964,
              0.27805399894714355,
              -0.6713770031929016,
              0.7065650224685669,
              0.7047939896583557,
              -0.06302069872617722,
              0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.6894440054893494,
              -0.7058929800987244,
              -0.16238899528980255,
              0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.6848350167274475,
              -0.6926479935646057,
              -0.22626399993896484,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.6805930137634277,
              -0.6736660003662109,
              -0.28797298669815063,
              0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.6766260266304016,
              -0.649524986743927,
              -0.34678199887275696,
              0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              0.6729329824447632,
              -0.6207460165023804,
              -0.4022339880466461,
              0.6699119806289673,
              -0.5874810218811035,
              -0.453931987285614,
              0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.6682639718055725,
              -0.5496990084648132,
              -0.5012059807777405,
              0.668599009513855,
              -0.5042269825935364,
              -0.5464950203895569,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              0.6760460138320923,
              -0.2922149896621704,
              -0.6764119863510132,
              0.6782739758491516,
              -0.22898000478744507,
              -0.6982020139694214,
              0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              0.6801350116729736,
              -0.16425099968910217,
              -0.7144079804420471,
              0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              0.6816610097885132,
              -0.09836120158433914,
              -0.7249979972839355,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.6828209757804871,
              -0.03173920139670372,
              -0.7298809885978699,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              0.6835839748382568,
              0.035157300531864166,
              -0.7289959788322449,
              0.6839810013771057,
              0.10177899897098541,
              -0.7223119735717773,
              0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              0.7172459959983826,
              0.6951509714126587,
              0.04757839813828468,
              0.7128509879112244,
              0.7011020183563232,
              -0.016327399760484695,
              0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              0.721763014793396,
              0.6831570267677307,
              0.11078199744224548,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.7303990125656128,
              0.6423839926719666,
              0.23194099962711334,
              0.7261880040168762,
              0.6654869914054871,
              0.17242999374866486,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              0.9794300198554993,
              0.1543319970369339,
              0.12970399856567383,
              0.7158120274543762,
              0.02835169993340969,
              0.6976839900016785,
              0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              0.9794300198554993,
              0.1543319970369339,
              0.12970399856567383,
              0.9794300198554993,
              0.1543319970369339,
              0.12970399856567383,
              0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.7154759764671326,
              0.09521769732236862,
              0.6920679807662964,
              0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.715719997882843,
              0.15970300137996674,
              0.6798610091209412,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              0.7164829969406128,
              0.22110700607299805,
              0.6615800261497498,
              0.7178869843482971,
              0.2787869870662689,
              0.6378669738769531,
              0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              0.7224339842796326,
              0.3816950023174286,
              0.5764949917793274,
              0.7254859805107117,
              0.4265269935131073,
              0.5400859713554382,
              0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              0.983489990234375,
              0.1804250031709671,
              -0.012787300162017345,
              0.7290570139884949,
              0.46678099036216736,
              0.5005339980125427,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.7290570139884949,
              0.46678099036216736,
              0.5005339980125427,
              0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              0.7323830127716064,
              0.5030670166015625,
              0.4588150084018707,
              0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.7344279885292053,
              0.5360270142555237,
              0.41624200344085693,
              0.7353739738464355,
              0.5657830238342285,
              0.37287500500679016,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              0.7150179743766785,
              -0.1321450024843216,
              0.6864830255508423,
              0.7141939997673035,
              -0.10367099940776825,
              0.6921899914741516,
              0.6962190270423889,
              -0.7085480093955994,
              -0.11490199714899063,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              0.9891960024833679,
              0.03265479952096939,
              -0.14273500442504883,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.964568018913269,
              -0.08410900086164474,
              -0.24994699656963348,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.9902039766311646,
              0.04184089973568916,
              -0.13300000131130219,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.966247022151947,
              -0.06784260272979736,
              -0.24848200380802155,
              0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.9911190271377563,
              0.055024899542331696,
              -0.12091399729251862,
              0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              0.9684739708900452,
              -0.046113498508930206,
              -0.2447279989719391,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9913939833641052,
              0.12952099740505219,
              -0.017792299389839172,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9736620187759399,
              0.13891999423503876,
              -0.18063899874687195,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9917290210723877,
              0.12796400487422943,
              -0.006988740060478449,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9737849831581116,
              0.15335600078105927,
              -0.16794300079345703,
              0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9883109927177429,
              0.13931700587272644,
              -0.06134219840168953,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9883109927177429,
              0.13931700587272644,
              -0.06134219840168953,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9897149801254272,
              0.14291800558567047,
              -0.002716149901971221,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9811699986457825,
              0.1174049973487854,
              0.15323300659656525,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9874259829521179,
              0.12756699323654175,
              -0.09320349991321564,
              0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.9800099730491638,
              0.19180899858474731,
              0.052552901208400726,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              0.983489990234375,
              0.1804250031709671,
              -0.012787300162017345,
              0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9883720278739929,
              0.013794399797916412,
              -0.1512800008058548,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9877009987831116,
              0.1444139927625656,
              -0.05948060005903244,
              0.9883109927177429,
              0.13931700587272644,
              -0.06134219840168953,
              0.9893490076065063,
              0.003753779921680689,
              -0.14532899856567383,
              0.9742730259895325,
              0.16235800087451935,
              -0.1561329960823059,
              0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              0.9924309849739075,
              0.12271499633789062,
              -0.0010376300197094679,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9786679744720459,
              0.15762799978256226,
              -0.13153499364852905,
              0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0.1573529988527298,
              -0.981016993522644,
              -0.11316300183534622,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0.1573529988527298,
              -0.981016993522644,
              -0.11316300183534622,
              0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              0.1573529988527298,
              -0.981016993522644,
              -0.11316300183534622,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0.1565600037574768,
              -0.9664599895477295,
              -0.2034969925880432,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              0.15588900446891785,
              -0.9437850117683411,
              -0.29142099618911743,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0.15527799725532532,
              -0.9135410189628601,
              -0.37589600682258606,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0.15427100658416748,
              -0.8327590227127075,
              -0.5316929817199707,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0.15427100658416748,
              -0.8327590227127075,
              -0.5316929817199707,
              0.1547289937734604,
              -0.8763089776039124,
              -0.4561910033226013,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              0.15622399747371674,
              -0.3031400144100189,
              -0.9400309920310974,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0.156468003988266,
              -0.21881799399852753,
              -0.9631339907646179,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              0.15662099421024323,
              -0.1317790001630783,
              -0.9788200259208679,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0.15668199956417084,
              -0.04260379821062088,
              -0.9867240190505981,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              0,
              0.9768970012664795,
              0.21356900036334991,
              0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              0,
              0.9768970012664795,
              0.21356900036334991,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              0.16187000274658203,
              0.9183930158615112,
              0.3609730005264282,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0.16187000274658203,
              0.9183930158615112,
              0.3609730005264282,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0.16187000274658203,
              0.9183930158615112,
              0.3609730005264282,
              0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0.16022199392318726,
              -0.006958220154047012,
              0.987030029296875,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0.16022199392318726,
              -0.006958220154047012,
              0.987030029296875,
              0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              0.16022199392318726,
              -0.006958220154047012,
              0.987030029296875,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.14407800137996674,
              0.98956298828125,
              0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              0.16025300323963165,
              0.08221690356731415,
              0.9836120009422302,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.23011000454425812,
              0.973143994808197,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0,
              0.14407800137996674,
              0.98956298828125,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0.16037499904632568,
              0.16949999332427979,
              0.9723809957504272,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.46974098682403564,
              0.882777988910675,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0.16272500157356262,
              0.6233710050582886,
              0.7647939920425415,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0.16272500157356262,
              0.6233710050582886,
              0.7647939920425415,
              0.1622059941291809,
              0.5575429797172546,
              0.8141419887542725,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6755880117416382,
              0.7372660040855408,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0.16272500157356262,
              0.6233710050582886,
              0.7647939920425415,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.735588014125824,
              0.6773890256881714,
              0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              0,
              0.6755880117416382,
              0.7372660040855408,
              0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              0.1630910038948059,
              0.6848049759864807,
              0.7101960182189941,
              0,
              0.6755880117416382,
              0.7372660040855408,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.15839099884033203,
              -0.9863280057907104,
              -0.045014798641204834,
              0.15891000628471375,
              -0.986998975276947,
              -0.022675300016999245,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0.15891000628471375,
              -0.986998975276947,
              -0.022675300016999245,
              0,
              -1,
              0,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0.15399600565433502,
              -0.7834410071372986,
              -0.6020389795303345,
              0.15427100658416748,
              -0.8327590227127075,
              -0.5316929817199707,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              0.15454599261283875,
              -0.7280799746513367,
              -0.6678060293197632,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0.15408800542354584,
              -0.6678979992866516,
              -0.7281110286712646,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              0.15381300449371338,
              -0.6043890118598938,
              -0.7816709876060486,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0.1556749939918518,
              -0.46186700463294983,
              -0.8731650114059448,
              0.15533900260925293,
              -0.535660982131958,
              -0.8299810290336609,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0.15622399747371674,
              -0.3031400144100189,
              -0.9400309920310974,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0.15622399747371674,
              -0.3031400144100189,
              -0.9400309920310974,
              0.15598000586032867,
              -0.3842589855194092,
              -0.9099400043487549,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0.15671299397945404,
              0.0478225015103817,
              -0.9864799976348877,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0.15668199956417084,
              0.13858500123023987,
              -0.9778739809989929,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425640106201172,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425640106201172,
              0.15665200352668762,
              0.22855299711227417,
              -0.9608139991760254,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              0.16028299927711487,
              0.9819939732551575,
              0.09991759806871414,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9768970012664795,
              0.21356900036334991,
              0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0.16074100136756897,
              0.9686570167541504,
              0.18918399512767792,
              0.1613209992647171,
              0.9473249912261963,
              0.2765589952468872,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0.16235800087451935,
              0.8823509812355042,
              0.4416329860687256,
              0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0.15927599370479584,
              -0.2568129897117615,
              0.9532150030136108,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0.16028299927711487,
              -0.09717089682817459,
              0.9822689890861511,
              0.16049699485301971,
              -0.18726199865341187,
              0.9690849781036377,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              0,
              0.23011000454425812,
              0.973143994808197,
              0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              0.16055800020694733,
              0.25415799021720886,
              0.9537339806556702,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0.1612289994955063,
              0.4134649932384491,
              0.8961150050163269,
              0.160862997174263,
              0.3355819880962372,
              0.9281589984893799,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0.1622059941291809,
              0.5575429797172546,
              0.8141419887542725,
              0,
              0.46974098682403564,
              0.882777988910675,
              0.1622059941291809,
              0.5575429797172546,
              0.8141419887542725,
              0.1616870015859604,
              0.4875029921531677,
              0.8579970002174377,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0,
              0.735588014125824,
              0.6773890256881714,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0.16333499550819397,
              0.741690993309021,
              0.6505330204963684,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0.1634570062160492,
              0.8405410051345825,
              0.5164650082588196,
              0.1634880006313324,
              0.7937250137329102,
              0.5858640074729919,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0.15897099673748016,
              0.9872130155563354,
              0.008728289976716042,
              0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9743949770927429,
              -0.16312099993228912,
              -0.15463699400424957,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9724109768867493,
              -0.1604049950838089,
              -0.16925600171089172,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9705190062522888,
              -0.15628500282764435,
              -0.183447003364563,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9686880111694336,
              -0.15094499289989471,
              -0.1969970017671585,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9669790267944336,
              -0.14450499415397644,
              -0.20984500646591187,
              0.9656059741973877,
              -0.1360819935798645,
              -0.22147299349308014,
              0.9898070096969604,
              0.0018616300076246262,
              -0.1421860009431839,
              0.9897760152816772,
              0.007507550064474344,
              -0.14233799278736115,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              0.9650560021400452,
              -0.1243629977107048,
              -0.23053699731826782,
              0.9647819995880127,
              -0.10562500357627869,
              -0.2408829927444458,
              0.9894710183143616,
              0.019074099138379097,
              -0.14328399300575256,
              0.9911190271377563,
              0.07351910322904587,
              -0.11069100350141525,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              0.969726026058197,
              -0.016754699870944023,
              -0.24356800317764282,
              0.9707630276679993,
              0.01104770042002201,
              -0.23972299695014954,
              0.9910579919815063,
              0.08951079845428467,
              -0.09875790029764175,
              0.9909970164299011,
              0.10284700244665146,
              -0.08557389676570892,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              0.9716179966926575,
              0.03717150166630745,
              -0.23358899354934692,
              0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              0.9909359812736511,
              0.1134369969367981,
              -0.07162690162658691,
              0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9722890257835388,
              0.06149480119347572,
              -0.22550100088119507,
              0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9909359812736511,
              0.12128099799156189,
              -0.057374801486730576,
              0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              0.9728080034255981,
              0.08386489748954773,
              -0.21579599380493164,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              0.9909970164299011,
              0.12646900117397308,
              -0.04339729994535446,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              0.9732049703598022,
              0.10422100126743317,
              -0.20484000444412231,
              0.9734789729118347,
              0.1225930005311966,
              -0.1930299997329712,
              0.9911500215530396,
              0.1291240006685257,
              -0.030060699209570885,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9822689890861511,
              0.1535390019416809,
              -0.10751699656248093,
              0.9803770184516907,
              0.1551560014486313,
              -0.12152499705553055,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.9841300249099731,
              0.15054799616336823,
              -0.0937528982758522,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9877009987831116,
              0.14093400537967682,
              -0.06747639924287796,
              0.9859610199928284,
              0.14630599319934845,
              -0.0803551971912384,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9890130162239075,
              0.14688600599765778,
              -0.014648900367319584,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9801629781723022,
              0.1369670033454895,
              0.14319300651550293,
              0.9794300198554993,
              0.1543319970369339,
              0.12970399856567383,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9884639978408813,
              0.1486860066652298,
              -0.02844329923391342,
              0.9794300198554993,
              0.1543319970369339,
              0.12970399856567383,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9794300198554993,
              0.1543319970369339,
              0.12970399856567383,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9880059957504272,
              0.1478929966688156,
              -0.043671999126672745,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9790639877319336,
              0.16888900101184845,
              0.11337599903345108,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9877009987831116,
              0.14422999322414398,
              -0.05990780144929886,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9790340065956116,
              0.18018099665641785,
              0.09466840326786041,
              0.9793689846992493,
              0.18790200352668762,
              0.07419049739837646,
              0.9875180125236511,
              0.13748599588871002,
              -0.07660149782896042,
              0.9873960018157959,
              0.11444400250911713,
              -0.1091649979352951,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.980925977230072,
              0.19183899462223053,
              0.03039640001952648,
              0.9821159839630127,
              0.18802499771118164,
              0.00839259009808302,
              0.9873960018157959,
              0.09820859879255295,
              -0.1239050030708313,
              0.9874259829521179,
              0.07904289662837982,
              -0.13684499263763428,
              0.983489990234375,
              0.1804250031709671,
              -0.012787300162017345,
              0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              0.983489990234375,
              0.1804250031709671,
              -0.012787300162017345,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              0.987060010433197,
              0.06100650131702423,
              -0.1482589989900589,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9847710132598877,
              0.1708430051803589,
              -0.03143410012125969,
              0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9868469834327698,
              0.045289501547813416,
              -0.15503400564193726,
              0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9858090281486511,
              0.16150400042533875,
              -0.04504530131816864,
              0.9868159890174866,
              0.1525620073080063,
              -0.05386520177125931,
              0.9875180125236511,
              0.028626399114727974,
              -0.1547289937734604,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9771720170974731,
              -0.16007000207901,
              -0.13946999609470367,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9771720170974731,
              -0.16007000207901,
              -0.13946999609470367,
              0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -1,
              0,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              0.9768970012664795,
              0.21356900036334991,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              0.9226660132408142,
              0.3855400085449219,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              -0.03399759903550148,
              0.9994199872016907,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.05578780174255371,
              0.9984130263328552,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.14407800137996674,
              0.98956298828125,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6755880117416382,
              0.7372660040855408,
              0,
              0.6111029982566833,
              0.7915279865264893,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.6755880117416382,
              0.7372660040855408,
              0,
              0.6755880117416382,
              0.7372660040855408,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.6755880117416382,
              0.7372360229492188,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9923089742660522,
              0.12366099655628204,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9768970012664795,
              0.21356900036334991,
              0,
              0.9535509943962097,
              0.3012180030345917,
              0,
              0.9768970012664795,
              0.21359899640083313,
              0,
              0.9768970012664795,
              0.21356900036334991,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              0.8848540186882019,
              0.46583500504493713,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.2143010050058365,
              0.9767450094223022,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              -0.12439300119876862,
              0.992218017578125,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.23011000454425812,
              0.973143994808197,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.31333398818969727,
              0.9496139883995056,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.39329200983047485,
              0.9194009900093079,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.46974098682403564,
              0.882777988910675,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.5424050092697144,
              0.8400830030441284,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.735588014125824,
              0.6773890256881714,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              0.7907649874687195,
              0.6120790243148804,
              0,
              0.8406929969787598,
              0.5414900183677673,
              0,
              0.8406929969787598,
              0.5414590239524841,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.28061801195144653,
              0.9598069787025452,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9997249841690063,
              -0.02316349931061268,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              0,
              -0.9956660270690918,
              -0.09280680119991302,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              0,
              -0.9827269911766052,
              -0.18491199612617493,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9827269911766052,
              -0.18488100171089172,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9614549875259399,
              -0.2748500108718872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.9323099851608276,
              -0.3616439998149872,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8958399891853333,
              -0.4443489909172058,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.23941799998283386,
              -0.9708849787712097,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.15198199450969696,
              -0.9883720278739929,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              -0.06207460165023804,
              -0.9980469942092896,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8526570200920105,
              -0.5224159955978394,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.8034300208091736,
              -0.5953549742698669,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.7486500144004822,
              -0.6629229784011841,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6888329982757568,
              -0.7249060273170471,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.6242560148239136,
              -0.7811819911003113,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.5552539825439453,
              -0.8316599726676941,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4820399880409241,
              -0.8761249780654907,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.4048280119895935,
              -0.9143649935722351,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              -0.3238619863986969,
              -0.9460740089416504,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.029419800266623497,
              -0.9995419979095459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.12161599844694138,
              -0.9925529956817627,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.21329399943351746,
              -0.9769589900970459,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              0.15891000628471375,
              -0.986998975276947,
              -0.022675300016999245,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.15897099673748016,
              0.9872130155563354,
              0.008728289976716042,
              0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              0.7022309899330139,
              0.7076629996299744,
              -0.07766959816217422,
              0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              0.9771720170974731,
              -0.16007000207901,
              -0.13946999609470367,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.7005220055580139,
              -0.7065650224685669,
              -0.09997860342264175,
              0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9779049754142761,
              0.15985600650310516,
              -0.13449500501155853,
              0.15891000628471375,
              -0.986998975276947,
              -0.022675300016999245,
              0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -1,
              0,
              0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.15976400673389435,
              0.9866330027580261,
              0.03186130151152611,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.15930700302124023,
              0.9871820211410522,
              0.008697779849171638,
              0.9763180017471313,
              -0.16190099716186523,
              -0.14337599277496338,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9772329926490784,
              -0.15976400673389435,
              -0.13946999609470367,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9984740018844604,
              0.055024899542331696,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425640106201172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.15707899630069733,
              0.2947779893875122,
              -0.9425640106201172,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              0.9837639927864075,
              0.08822900056838989,
              0.15619400143623352,
              0.9915459752082825,
              0.12952099740505219,
              0.001281779957935214,
              0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.280892014503479,
              -0.9597160220146179,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.720907986164093,
              -0.16534900665283203,
              0.6729639768600464,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              0.747734010219574,
              -0.5981630086898804,
              0.28815600275993347,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              0.6876130104064941,
              0.30149200558662415,
              -0.6604819893836975,
              0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.6876130104064941,
              0.30149200558662415,
              -0.6604819893836975,
              0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              0.9628890156745911,
              0.26798298954963684,
              -0.0314645990729332,
              0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              0.9628890156745911,
              0.26798298954963684,
              -0.0314645990729332,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              0.720907986164093,
              -0.16534900665283203,
              0.6729639768600464,
              0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.720907986164093,
              -0.16534900665283203,
              0.6729639768600464,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              0.9950860142707825,
              -0.010071099735796452,
              0.09830009937286377,
              0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              0.9950860142707825,
              -0.010071099735796452,
              0.09830009937286377,
              0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.7473369836807251,
              -0.6491289734840393,
              0.141757994890213,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.9985960125923157,
              -0.04269539937376976,
              0.03112890012562275,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.9985960125923157,
              -0.04269539937376976,
              0.03112890012562275,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              0.6454049944877625,
              -0.7015900015830994,
              0.30195000767707825,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              0.9248329997062683,
              -0.15964199602603912,
              0.3451339900493622,
              0.6454049944877625,
              -0.7015900015830994,
              0.30195000767707825,
              0.9248329997062683,
              -0.15964199602603912,
              0.3451339900493622,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.6454049944877625,
              -0.7015900015830994,
              0.30195000767707825,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.9771720170974731,
              -0.16007000207901,
              -0.13946999609470367,
              0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              0.9771720170974731,
              -0.16007000207901,
              -0.13946999609470367,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              0.9866629838943481,
              0.1595509946346283,
              0.03161719813942909,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.9866629838943481,
              0.1595509946346283,
              0.03161719813942909,
              0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              0.7398599982261658,
              -0.4748069941997528,
              0.4765470027923584,
              0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              0.7398599982261658,
              -0.4748069941997528,
              0.4765470027923584,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0.15598000586032867,
              0.4561600089073181,
              -0.8760949969291687,
              0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              0.6625869870185852,
              0.7382429838180542,
              -0.12619400024414062,
              0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              0.15195199847221375,
              0.9713429808616638,
              -0.182561993598938,
              0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              0.15378299355506897,
              0.9710990190505981,
              -0.18250100314617157,
              0.15195199847221375,
              0.9713429808616638,
              -0.182561993598938,
              0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              0.7398599982261658,
              -0.4748069941997528,
              0.4765470027923584,
              0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              0.7466350197792053,
              -0.5817440152168274,
              0.322611004114151,
              0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.747734010219574,
              -0.5981630086898804,
              0.28815600275993347,
              0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              0.7466350197792053,
              -0.5817440152168274,
              0.322611004114151,
              0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              0.7466350197792053,
              -0.5817440152168274,
              0.322611004114151,
              0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              0.15598000586032867,
              0.4561600089073181,
              -0.8760949969291687,
              0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              0.15598000586032867,
              0.4561600089073181,
              -0.8760949969291687,
              0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              0.15411800146102905,
              0.7627490162849426,
              -0.6280400156974792,
              0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              0.15411800146102905,
              0.7627490162849426,
              -0.6280400156974792,
              0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              0.15411800146102905,
              0.7627490162849426,
              -0.6280400156974792,
              0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.987060010433197,
              0.16016100347042084,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.987060010433197,
              0.16016100347042084,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              0.15897099673748016,
              0.9872130155563354,
              0.008728289976716042,
              0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              0.15897099673748016,
              0.9872130155563354,
              0.008728289976716042,
              0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9817799925804138,
              0.18988600373268127,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.9817799925804138,
              0.18988600373268127,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.9817799925804138,
              0.18988600373268127,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0.720907986164093,
              -0.16534900665283203,
              0.6729639768600464,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0.720907986164093,
              -0.16534900665283203,
              0.6729639768600464,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              0.7348250150680542,
              -0.3935059905052185,
              0.5523849725723267,
              0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0.7365949749946594,
              -0.42185699939727783,
              0.5285810232162476,
              0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              0.747734010219574,
              -0.5981630086898804,
              0.28815600275993347,
              0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              0.747734010219574,
              -0.5981630086898804,
              0.28815600275993347,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              0.7486500144004822,
              -0.6128420233726501,
              0.2527239918708801,
              0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.6876130104064941,
              0.30149200558662415,
              -0.6604819893836975,
              0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              0.6621299982070923,
              0.7137669920921326,
              -0.2282170057296753,
              0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.720907986164093,
              -0.16534900665283203,
              0.6729639768600464,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              0.7230139970779419,
              -0.20056800544261932,
              0.6610310077667236,
              0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0.1638839989900589,
              -0.9680780172348022,
              0.1895499974489212,
              0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              0.7473369836807251,
              -0.6491289734840393,
              0.141757994890213,
              0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.7473369836807251,
              -0.6491289734840393,
              0.141757994890213,
              0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.6771450042724609,
              0.4500559866428375,
              -0.5821099877357483,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              0.6725670099258423,
              0.5306249856948853,
              -0.515762984752655,
              0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              0.23465700447559357,
              -0.7014369964599609,
              0.6729639768600464,
              0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0.6454049944877625,
              -0.7015900015830994,
              0.30195000767707825,
              0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              0.6454049944877625,
              -0.7015900015830994,
              0.30195000767707825,
              0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              0.6454049944877625,
              -0.7015900015830994,
              0.30195000767707825,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              0.7125160098075867,
              -0.7013760209083557,
              0.019196100533008575,
              0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              0.7001559734344482,
              -0.7069309949874878,
              -0.09991759806871414,
              0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              0.05920590087771416,
              0.6899930238723755,
              0.7213659882545471,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              0.11792399734258652,
              0.6901149749755859,
              0.7139809727668762,
              0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              0.3365280032157898,
              0.691519021987915,
              0.639149010181427,
              0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              0.3852660059928894,
              0.6919770240783691,
              0.6104919910430908,
              0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              0.7122719883918762,
              0.7016209959983826,
              -0.01895200088620186,
              0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              0.7251200079917908,
              -0.23502300679683685,
              0.6472370028495789,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              0.7271950244903564,
              -0.2686850130558014,
              0.6316419839859009,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              0.732932984828949,
              -0.3639639914035797,
              0.5746939778327942,
              0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              0.9950860142707825,
              -0.010071099735796452,
              0.09830009937286377,
              0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.7413560152053833,
              -0.49925199151039124,
              0.44840800762176514,
              0.7398599982261658,
              -0.4748069941997528,
              0.4765470027923584,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              0.7442240118980408,
              -0.5436260104179382,
              0.38801199197769165,
              0.7428510189056396,
              -0.5222020149230957,
              0.41883599758148193,
              0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              0.6755269765853882,
              0.4781639873981476,
              -0.5612350106239319,
              0.6740009784698486,
              0.505020022392273,
              -0.5390790104866028,
              0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.6711940169334412,
              0.5550100207328796,
              -0.491347998380661,
              0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.9630730152130127,
              0.26758599281311035,
              -0.029145199805498123,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.6625869870185852,
              0.7382429838180542,
              -0.12619400024414062,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              0.6625869870185852,
              0.7382429838180542,
              -0.12619400024414062,
              0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.7310709953308105,
              -0.33326199650764465,
              0.5953249931335449,
              0.7291790246963501,
              -0.30146199464797974,
              0.6143069863319397,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.7398599982261658,
              -0.4748069941997528,
              0.4765470027923584,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.7398599982261658,
              -0.4748069941997528,
              0.4765470027923584,
              0.738273024559021,
              -0.4489879906177521,
              0.5032809972763062,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.7466350197792053,
              -0.5817440152168274,
              0.322611004114151,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.7466350197792053,
              -0.5817440152168274,
              0.322611004114151,
              0.7454760074615479,
              -0.5634940266609192,
              0.35590699315071106,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.747734010219574,
              -0.5981630086898804,
              0.28815600275993347,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.747734010219574,
              -0.5981630086898804,
              0.28815600275993347,
              0.7466350197792053,
              -0.5817440152168274,
              0.322611004114151,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              0.7494739890098572,
              -0.6256600022315979,
              0.21628500521183014,
              0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              0.750236988067627,
              -0.6364330053329468,
              0.17905199527740479,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.6842859983444214,
              0.3264870047569275,
              -0.6519970297813416,
              0.682362973690033,
              0.35898900032043457,
              -0.6367689967155457,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              0.6805629730224609,
              0.3904539942741394,
              -0.6199529767036438,
              0.6788229942321777,
              0.4208199977874756,
              -0.6017029881477356,
              0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.6698200106620789,
              0.578112006187439,
              -0.46589601039886475,
              0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.668599009513855,
              0.5999330282211304,
              -0.43934398889541626,
              0.6674699783325195,
              0.6203799843788147,
              -0.4118170142173767,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.6664019823074341,
              0.6394850015640259,
              -0.38334301114082336,
              0.6653649806976318,
              0.6572160124778748,
              -0.3539539873600006,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              0.6644179821014404,
              0.6735739707946777,
              -0.32374000549316406,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.6635940074920654,
              0.6884369850158691,
              -0.2926419973373413,
              0.6628310084342957,
              0.701865017414093,
              -0.2607809901237488,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.6615189909934998,
              0.7241129875183105,
              -0.19501300156116486,
              0.6610919833183289,
              0.7327799797058105,
              -0.16113799810409546,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              0.11966300010681152,
              -0.7013459801673889,
              0.7026579976081848,
              0.17804500460624695,
              -0.7013760209083557,
              0.6901460289955139,
              0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              0.4794459939002991,
              -0.7016819715499878,
              0.526993989944458,
              0.5194249749183655,
              -0.7016819715499878,
              0.48762500286102295,
              0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              0.5561389923095703,
              -0.7016819715499878,
              0.4452959895133972,
              0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              0.7083960175514221,
              -0.7013760209083557,
              0.07873780280351639,
              0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.7116310000419617,
              -0.7013459801673889,
              -0.04086429998278618,
              0.7048559784889221,
              -0.7041540145874023,
              -0.08548229932785034,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.014954100362956524,
              -0.7041839957237244,
              0.7098299860954285,
              0.06009089946746826,
              -0.7013459801673889,
              0.7102569937705994,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              0.2890709936618805,
              -0.7014979720115662,
              0.6513869762420654,
              0.34098300337791443,
              -0.7015590071678162,
              0.6256899833679199,
              0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              0.3901180028915405,
              -0.7016209959983826,
              0.5962399840354919,
              0.43632298707962036,
              -0.7016509771347046,
              0.5632799863815308,
              0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.5894650220870972,
              -0.7016819715499878,
              0.40015900135040283,
              0.619251012802124,
              -0.7016509771347046,
              0.35233598947525024,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.9248329997062683,
              -0.15964199602603912,
              0.3451339900493622,
              0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              0.6675919890403748,
              -0.7015900015830994,
              0.2491230070590973,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              0.6856899857521057,
              -0.7014979720115662,
              0.19412800669670105,
              0.6993929743766785,
              -0.7014070153236389,
              0.13721099495887756,
              0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.7071139812469482,
              0.707082986831665,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              0.3235569894313812,
              0.15793299674987793,
              0.932919979095459,
              0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              0.17548100650310516,
              0.6903589963912964,
              0.7018340229988098,
              0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              0.3235569894313812,
              0.15793299674987793,
              0.932919979095459,
              0.2851339876651764,
              0.6910610198974609,
              0.6641129851341248,
              0.2313610017299652,
              0.6906639933586121,
              0.6851410269737244,
              0.3235569894313812,
              0.15793299674987793,
              0.932919979095459,
              0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              0.47416600584983826,
              0.6930750012397766,
              0.5428940057754517,
              0.431196004152298,
              0.6924960017204285,
              0.5783259868621826,
              0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              0.5509510040283203,
              0.6943569779396057,
              0.46290498971939087,
              0.514145016670227,
              0.6936860084533691,
              0.5043789744377136,
              0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.5844900012016296,
              0.6950590014457703,
              0.41859200596809387,
              0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.6146730184555054,
              0.6957610249519348,
              0.37153199315071106,
              0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.6640830039978027,
              0.6972870230674744,
              0.2696619927883148,
              0.6412850022315979,
              0.6965240240097046,
              0.3218179941177368,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.6973170042037964,
              0.6989349722862244,
              0.1586659997701645,
              0.682852029800415,
              0.6981109976768494,
              0.2151859998703003,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.9866629838943481,
              0.1595509946346283,
              0.03161719813942909,
              0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.7122719883918762,
              0.7006739974021912,
              0.041108399629592896,
              0.7072049975395203,
              0.6997889876365662,
              0.10049699991941452,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.7017729878425598,
              0.7081210017204285,
              -0.07760860025882721,
              0.706167995929718,
              0.7051910161972046,
              -0.06338690221309662,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0,
              0.15854400396347046,
              0.9873350262641907,
              0.014709900133311749,
              0.6928009986877441,
              0.7209389805793762,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.15854400396347046,
              0.9873350262641907,
              0,
              0.15854400396347046,
              0.9873350262641907,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.6957610249519348,
              0.7182530164718628,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              0.9924619793891907,
              0.012604099698364735,
              0.12170799821615219,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.9931640028953552,
              0.0065004401840269566,
              0.11633700132369995,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              0.9978939890861511,
              -0.03418070077896118,
              0.054933298379182816,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9631950259208679,
              0.26337501406669617,
              -0.05346839874982834,
              0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.9628890156745911,
              0.26798298954963684,
              -0.0314645990729332,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9867849946022034,
              0.06234930083155632,
              0.149509996175766,
              0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.9876400232315063,
              0.054628100246191025,
              0.14670200645923615,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              0.9950860142707825,
              -0.010071099735796452,
              0.09830009937286377,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9985960125923157,
              -0.04269539937376976,
              0.03112890012562275,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9701219797134399,
              0.2017270028591156,
              -0.13467800617218018,
              0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9680169820785522,
              0.2204349935054779,
              -0.11966300010681152,
              0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.32502201199531555,
              -0.1596119999885559,
              0.9321269989013672,
              0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.8941310048103333,
              -0.15964199602603912,
              0.4183169901371002,
              0.9248329997062683,
              -0.15964199602603912,
              0.3451339900493622,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              0.9868159890174866,
              -0.1596119999885559,
              0.026581600308418274,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.9771720170974731,
              -0.16007000207901,
              -0.13946999609470367,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0,
              0,
              1,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              0.08282720297574997,
              0.1578720062971115,
              0.9839469790458679,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              0.16498300433158875,
              0.15790300071239471,
              0.9735710024833679,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              0.4703820049762726,
              0.15811599791049957,
              0.8681600093841553,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              0.538316011428833,
              0.15817700326442719,
              0.8277230262756348,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              0.9866629838943481,
              0.1595509946346283,
              0.03161719813942909,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.9858090281486511,
              0.15967300534248352,
              -0.05157630145549774,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0.16229699552059174,
              -0.4704430103302002,
              0.8673359751701355,
              0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.825190007686615,
              0.5648369789123535,
              0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0.15598000586032867,
              0.4561600089073181,
              -0.8760949969291687,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0.15598000586032867,
              0.4561600089073181,
              -0.8760949969291687,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.619189977645874,
              -0.7852110266685486,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              0.15195199847221375,
              0.9713429808616638,
              -0.182561993598938,
              0.15378299355506897,
              0.9710990190505981,
              -0.18250100314617157,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0.15378299355506897,
              0.9710990190505981,
              -0.18250100314617157,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              0.16254200041294098,
              -0.5146030187606812,
              0.8418530225753784,
              0.16272500157356262,
              -0.5572680234909058,
              0.8142030239105225,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0.1638839989900589,
              -0.7453230023384094,
              0.6461989879608154,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              0.1647080034017563,
              -0.8853110074996948,
              0.4347969889640808,
              0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              0,
              -0.9795219898223877,
              0.2012699991464615,
              0.1651969999074936,
              -0.9563890099525452,
              0.24082200229167938,
              0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              0,
              -0.9795219898223877,
              0.2012699991464615,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0.15625500679016113,
              0.41071799397468567,
              -0.8982509970664978,
              0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              0.15598000586032867,
              0.4561600089073181,
              -0.8760949969291687,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0.15411800146102905,
              0.7627490162849426,
              -0.6280400156974792,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0.15411800146102905,
              0.7627490162849426,
              -0.6280400156974792,
              0.15430200099945068,
              0.7305219769477844,
              -0.6651809811592102,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              0.15411800146102905,
              0.7627490162849426,
              -0.6280400156974792,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0.1534470021724701,
              0.8943449854850769,
              -0.42017900943756104,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              -1,
              0,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0,
              -1,
              0,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              -0.000030518498533638194,
              -1,
              -0.00006103699706727639,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              -0.000030518498533638194,
              -1,
              -0.00006103699706727639,
              0,
              -1,
              0,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0,
              -1,
              0,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0.11032400280237198,
              -0.9864500164985657,
              0.12128099799156189,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0,
              -0.9999690055847168,
              0,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              0,
              -1,
              0,
              0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              0.00009155549923889339,
              -0.9999690055847168,
              0,
              0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              0.00009155549923889339,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0,
              -1,
              0,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              0,
              -1,
              0,
              0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              0,
              -1,
              0,
              -0.000030518498533638194,
              -1,
              -0.00006103699706727639,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0,
              -1,
              0,
              0.1004360020160675,
              -0.9864500164985657,
              0.12967300415039062,
              0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              0,
              -1,
              0,
              0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              0.1356550008058548,
              -0.9864500164985657,
              0.09210489690303802,
              0,
              -1,
              0,
              -0.00006103699706727639,
              -1,
              0,
              0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              0,
              -1,
              0,
              0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              -0.00006103699706727639,
              -1,
              0,
              0.16113799810409546,
              -0.9864190220832825,
              0.031586699187755585,
              0.15790300071239471,
              -0.9864190220832825,
              0.04470960050821304,
              -0.00006103699706727639,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              0,
              -1,
              0,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              0,
              -0.987060010433197,
              0.16016100347042084,
              0,
              -1,
              0,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031678199768066406,
              0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031678199768066406,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031678199768066406,
              0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.05355999991297722,
              0.9812920093536377,
              0.1848199963569641,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0,
              0.9994810223579407,
              0.0318307988345623,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.14184999465942383,
              0.983519971370697,
              0.11197199672460556,
              0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.14795400202274323,
              0.9838560223579407,
              0.10049699991941452,
              0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              0.15897099673748016,
              0.9872130155563354,
              0.008728289976716042,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9817799925804138,
              0.18988600373268127,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9817799925804138,
              0.18988600373268127,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9817799925804138,
              0.18985599279403687,
              0,
              0.9817799925804138,
              0.18988600373268127,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              0.16296899318695068,
              -0.5983459949493408,
              0.7844780087471008,
              0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              0.16327400505542755,
              -0.6377149820327759,
              0.7527390122413635,
              0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0.1635179966688156,
              -0.6753749847412109,
              0.7190769910812378,
              0.16370099782943726,
              -0.7112950086593628,
              0.6835229992866516,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0.16489200294017792,
              -0.9067050218582153,
              0.38810399174690247,
              0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0.16498300433158875,
              -0.9257479906082153,
              0.34015899896621704,
              0.16507500410079956,
              -0.9423509836196899,
              0.29099398851394653,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0.15658999979496002,
              0.3639639914035797,
              -0.9181190133094788,
              0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              0.15573599934577942,
              0.500229001045227,
              -0.8517410159111023,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              0.15329399704933167,
              0.9143959879875183,
              -0.3746449947357178,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0.1530809998512268,
              0.9476000070571899,
              -0.28031298518180847,
              0.15320299565792084,
              0.9321569800376892,
              -0.32801300287246704,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              0.1530500054359436,
              0.9606620073318481,
              -0.2316969931125641,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0.16150400042533875,
              -0.3299660086631775,
              0.9300519824028015,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0.161748006939888,
              -0.37800198793411255,
              0.9115269780158997,
              0.16205300390720367,
              -0.4248790144920349,
              0.890591025352478,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              0,
              -0.825190007686615,
              0.5648369789123535,
              0.16403700411319733,
              -0.7774289846420288,
              0.6071659922599792,
              0.16422000527381897,
              -0.8075810074806213,
              0.5663930177688599,
              0,
              -0.825190007686615,
              0.5648369789123535,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              0.16443400084972382,
              -0.835627019405365,
              0.5240640044212341,
              0.16458599269390106,
              -0.8615679740905762,
              0.48017799854278564,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              0,
              -0.9795219898223877,
              0.2012699991464615,
              0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0.1551869958639145,
              0.5836970210075378,
              -0.7969599962234497,
              0.15546099841594696,
              0.5427410006523132,
              -0.8253430128097534,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              0,
              0.619189977645874,
              -0.7852110266685486,
              0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              0.15500399470329285,
              0.6230049729347229,
              -0.7666860222816467,
              0,
              0.619189977645874,
              -0.7852110266685486,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0.15457600355148315,
              0.6964629888534546,
              -0.7007049918174744,
              0.15481999516487122,
              0.6606040000915527,
              -0.7345499992370605,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0.15384399890899658,
              0.8214359879493713,
              -0.5490890145301819,
              0.1539659947156906,
              0.7930539846420288,
              -0.5893120169639587,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0.1535390019416809,
              0.8721579909324646,
              -0.46449199318885803,
              0.15366099774837494,
              0.8478350043296814,
              -0.5074620246887207,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              -0.9999690055847168,
              0,
              0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              0,
              -1,
              0,
              0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              0.040986400097608566,
              -0.9864190220832825,
              0.15894000232219696,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              0,
              -0.9999690055847168,
              0,
              0.06656090170145035,
              -0.9864190220832825,
              0.1499979943037033,
              0.05404829978942871,
              -0.9864190220832825,
              0.15497300028800964,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              0,
              -1,
              0,
              0.08981599658727646,
              -0.9864500164985657,
              0.13727200031280518,
              0.07849360257387161,
              -0.9864190220832825,
              0.14407800137996674,
              0,
              -1,
              0,
              0.00009155549923889339,
              -0.9999690055847168,
              0,
              0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              0,
              -1,
              0,
              0.1639450043439865,
              -0.9864190220832825,
              -0.009399699978530407,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0,
              -0.9999690055847168,
              0,
              0.02755820006132126,
              -0.9864190220832825,
              0.16187000274658203,
              0.013824899680912495,
              -0.9864190220832825,
              0.16360999643802643,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0,
              -1,
              0,
              0.12799499928951263,
              -0.9864500164985657,
              0.10248100012540817,
              0.1195409968495369,
              -0.9864500164985657,
              0.1122170016169548,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0,
              -1,
              0,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0.1425209939479828,
              -0.9864500164985657,
              0.08108770102262497,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              0,
              -0.9999690055847168,
              0,
              0.15369099378585815,
              -0.9864500164985657,
              0.05734429880976677,
              0.14856399595737457,
              -0.9864500164985657,
              0.06949059665203094,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0,
              -1,
              0,
              0.16412900388240814,
              -0.9864190220832825,
              0.004394670017063618,
              0.16321299970149994,
              -0.9864190220832825,
              0.01812800019979477,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              0,
              -0.9999690055847168,
              0,
              0.1586959958076477,
              -0.987060010433197,
              -0.02264470048248768,
              0.16104599833488464,
              -0.9867240190505981,
              -0.019531799480319023,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              0,
              -0.9999690055847168,
              0,
              0.0033875498920679092,
              -0.9867550134658813,
              0.1621139943599701,
              0,
              -0.9870910048484802,
              0.16013100743293762,
              0,
              -0.9999690055847168,
              0,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.00335703999735415,
              0.9813839793205261,
              0.19187000393867493,
              0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.013702799566090107,
              0.981016993522644,
              0.19330400228500366,
              0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.02731410041451454,
              0.9810789823532104,
              0.19156500697135925,
              0.0406200997531414,
              0.9811699986457825,
              0.18872599303722382,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.06598100066184998,
              0.9814450144767761,
              0.17987599968910217,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.07782220095396042,
              0.9816280007362366,
              0.17401699721813202,
              0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0.08908350020647049,
              0.9818419814109802,
              0.1673939973115921,
              0.09970399737358093,
              0.9820550084114075,
              0.15994800627231598,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.10962200164794922,
              0.9822989702224731,
              0.1517380028963089,
              0.11880899965763092,
              0.9825739860534668,
              0.14279599487781525,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.12726199626922607,
              0.9828789830207825,
              0.13315199315547943,
              0.13495300710201263,
              0.9831839799880981,
              0.12286800146102905,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0.15317200124263763,
              0.9842219948768616,
              0.0884426012635231,
              0.15747599303722382,
              0.9845880270004272,
              0.07586900144815445,
              0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0.16077199578285217,
              0.9849539995193481,
              0.06286810338497162,
              0.1630599945783615,
              0.985351026058197,
              0.04944000020623207,
              0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.16418999433517456,
              0.9857479929924011,
              0.035767700523138046,
              0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.16409799456596375,
              0.986175000667572,
              0.02191229909658432,
              0.1612900048494339,
              0.9868159890174866,
              0.01181070040911436,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.9885249733924866,
              0.04709009826183319,
              0.1434680074453354,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.9893490076065063,
              0.0397351011633873,
              0.13983599841594696,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9917290210723877,
              0.019013000652194023,
              0.12677399814128876,
              0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9950860142707825,
              -0.010071099735796452,
              0.09830009937286377,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9950860142707825,
              -0.010071099735796452,
              0.09830009937286377,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9961850047111511,
              -0.019531799480319023,
              0.08484149724245071,
              0.9956660270690918,
              -0.014954100362956524,
              0.09170810133218765,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9693899750709534,
              0.2082280069589615,
              -0.12994800508022308,
              0.9686880111694336,
              0.21445399522781372,
              -0.12494300305843353,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9673759937286377,
              0.2261119931936264,
              -0.11410900205373764,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9628890156745911,
              0.26798298954963684,
              -0.0314645990729332,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9909660220146179,
              0.025666100904345512,
              0.13147400319576263,
              0.9901729822158813,
              0.032593801617622375,
              0.13583800196647644,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9944760203361511,
              -0.00485244020819664,
              0.10461699962615967,
              0.9938349723815918,
              0.0006714069750159979,
              0.11062999814748764,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.9966729879379272,
              -0.02377389930188656,
              0.07773060351610184,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9975280165672302,
              -0.031098399311304092,
              0.06274610012769699,
              0.9971309900283813,
              -0.02761930041015148,
              0.0703757032752037,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.9981989860534668,
              -0.036896899342536926,
              0.046937499195337296,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.9984740018844604,
              -0.03912470117211342,
              0.038758501410484314,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9733269810676575,
              0.17322300374507904,
              -0.15030400454998016,
              0.9724720120429993,
              0.18070000410079956,
              -0.146916002035141,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9716789722442627,
              0.18796400725841522,
              -0.14316199719905853,
              0.9708849787712097,
              0.19498300552368164,
              -0.13907299935817719,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9667649865150452,
              0.23148299753665924,
              -0.1082490012049675,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9662160277366638,
              0.23657900094985962,
              -0.1021760031580925,
              0.9656670093536377,
              0.2413710057735443,
              -0.0958586037158966,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9651479721069336,
              0.24585700035095215,
              -0.08932770043611526,
              0.9646900296211243,
              0.2500379979610443,
              -0.08255259692668915,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9642630219459534,
              0.2538830041885376,
              -0.07559440284967422,
              0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9638659954071045,
              0.2573930025100708,
              -0.06839200109243393,
              0.9635000228881836,
              0.2605670094490051,
              -0.06100650131702423,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9629200100898743,
              0.26581600308418274,
              -0.04574719816446304,
              0.9626759886741638,
              0.267890989780426,
              -0.03787349909543991,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.16791300475597382,
              0,
              0.9857779741287231,
              0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.16574600338935852,
              -0.15964199602603912,
              0.973143994808197,
              0.2465900033712387,
              -0.15964199602603912,
              0.9558699727058411,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.6642959713935852,
              -0.15964199602603912,
              0.7301859855651855,
              0.7196869850158691,
              -0.15964199602603912,
              0.6756489872932434,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.7705619931221008,
              -0.15964199602603912,
              0.6169930100440979,
              0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              0.9811090230941772,
              -0.15964199602603912,
              0.10907299816608429,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9983519911766052,
              0,
              -0.05731379985809326,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9855340123176575,
              -0.1596119999885559,
              -0.056581299751996994,
              0.9799489974975586,
              -0.15985600650310516,
              -0.11883900314569473,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.02078310027718544,
              -0.15985600650310516,
              0.9869080185890198,
              0.08322399854660034,
              -0.15964199602603912,
              0.9836419820785522,
              0.08429209887981415,
              0,
              0.9964290261268616,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.4004330039024353,
              -0.15964199602603912,
              0.9022799730300903,
              0.47236499190330505,
              -0.15967300534248352,
              0.8667870163917542,
              0.47850000858306885,
              0,
              0.8780480027198792,
              0.5475019812583923,
              0,
              0.8367869853973389,
              0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.5404829978942871,
              -0.15967300534248352,
              0.8260440230369568,
              0.604511022567749,
              -0.15964199602603912,
              0.7804189920425415,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8167359828948975,
              -0.15967300534248352,
              0.5544599890708923,
              0.8579970002174377,
              -0.15964199602603912,
              0.4881739914417267,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9248329997062683,
              -0.15964199602603912,
              0.3451339900493622,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9248329997062683,
              -0.15964199602603912,
              0.3451339900493622,
              0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.949828028678894,
              -0.15967300534248352,
              0.26892900466918945,
              0.9686880111694336,
              -0.15967300534248352,
              0.1900389939546585,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0,
              0,
              1,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              0,
              1,
              0,
              -0.16016100347042084,
              0.987060010433197,
              0,
              -0.16013100743293762,
              0.9870910048484802,
              0,
              0,
              1,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.3235569894313812,
              0.15793299674987793,
              0.932919979095459,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.3235569894313812,
              0.15793299674987793,
              0.932919979095459,
              0.24546000361442566,
              0.15790300071239471,
              0.9564499855041504,
              0.24979400634765625,
              0,
              0.9682909846305847,
              0.4056209921836853,
              0,
              0.9140290021896362,
              0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.3986940085887909,
              0.15802499651908875,
              0.9033480286598206,
              0.3235569894313812,
              0.15793299674987793,
              0.932919979095459,
              0.32926398515701294,
              0,
              0.944212019443512,
              0.6729329824447632,
              0,
              0.7396770119667053,
              0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.6619459986686707,
              0.15832999348640442,
              0.7325969934463501,
              0.6022220253944397,
              0.158269003033638,
              0.7824640274047852,
              0.6123539805412292,
              0,
              0.7905510067939758,
              0.7805719971656799,
              0,
              0.6250190138816833,
              0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.7682729959487915,
              0.15851299464702606,
              0.6201670169830322,
              0.7173380255699158,
              0.15842199325561523,
              0.6784260272979736,
              0.7290570139884949,
              0,
              0.6844390034675598,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              0.8145390152931213,
              0.15860499441623688,
              0.5579699873924255,
              0.8273259997367859,
              0,
              0.5616629719734192,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.8559830188751221,
              0.1586959958076477,
              0.49201899766921997,
              0.8691369891166687,
              0,
              0.49452200531959534,
              0.9368569850921631,
              0,
              0.3496200144290924,
              0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9233070015907288,
              0.15894000232219696,
              0.3495590090751648,
              0.8923310041427612,
              0.15881800651550293,
              0.42246800661087036,
              0.9057589769363403,
              0,
              0.42375001311302185,
              0.9812620282173157,
              0,
              0.19251100718975067,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9678030014038086,
              0.15921500325202942,
              0.19489100575447083,
              0.9485759735107422,
              0.1590930074453354,
              0.27359798550605774,
              0.9621570110321045,
              0,
              0.27240800857543945,
              0.9996340274810791,
              0,
              0.02691729925572872,
              0.9866629838943481,
              0.1595509946346283,
              0.03161719813942909,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9866629838943481,
              0.1595509946346283,
              0.03161719813942909,
              0.9805899858474731,
              0.15936799347400665,
              0.11401700228452682,
              0.9938660264015198,
              0,
              0.11047700047492981,
              0.9899590015411377,
              0,
              -0.1413010060787201,
              0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.9778739809989929,
              0.16016100347042084,
              -0.13446499407291412,
              0.9804990291595459,
              0.1600089967250824,
              -0.11383400112390518,
              0.9927060008049011,
              0,
              -0.12039600312709808,
              0.021057799458503723,
              0,
              0.9997559785842896,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0,
              0,
              1,
              0.02069149911403656,
              0.15814700722694397,
              0.9871820211410522,
              0,
              0.15854400396347046,
              0.9873350262641907,
              0,
              0,
              1,
              0,
              0,
              1,
              0,
              0.15854400396347046,
              0.9873350262641907,
              0,
              0,
              1,
              0,
              0.15854400396347046,
              0.9873350262641907,
              0,
              0.15851299464702606,
              0.9873350262641907,
              0,
              0,
              1,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.000030518498533638194,
              -1,
              -0.00006103699706727639,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              -0.00006103699706727639,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0.00009155549923889339,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              -1,
              0,
              0,
              -0.9999690055847168,
              0,
              0,
              -1,
              0,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.03186130151152611,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.00006103699706727639,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0.000030518498533638194,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031769800931215286,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.031800299882888794,
              -0.000030518498533638194,
              0.9994810223579407,
              0.031678199768066406,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              0.9994810223579407,
              0.0318307988345623,
              0,
              -0.9795219898223877,
              0.2012699991464615,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.4917750060558319,
              0.870693027973175,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.825190007686615,
              0.5648369789123535,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.825190007686615,
              0.5648369789123535,
              0,
              -0.5780209898948669,
              0.8159729838371277,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.578050971031189,
              0.8159729838371277,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.5356910228729248,
              0.8443859815597534,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7639390230178833,
              0.6452530026435852,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9014250040054321,
              0.432904988527298,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9795219898223877,
              0.2012699991464615,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9795219898223877,
              0.20123900473117828,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.9709159731864929,
              0.23929600417613983,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6187630295753479,
              0.7855769991874695,
              0,
              -0.6187630295753479,
              0.7855460047721863,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6577349901199341,
              0.7531970143318176,
              0,
              -0.6577649712562561,
              0.7531970143318176,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              0,
              -0.7303990125656128,
              0.6829739809036255,
              0,
              -0.6949679851531982,
              0.7189859747886658,
              0,
              -0.6949679851531982,
              0.7190160155296326,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9223909974098206,
              0.38624200224876404,
              0,
              -0.9223909974098206,
              0.38621199131011963,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9572129845619202,
              0.28928500413894653,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.9410079717636108,
              0.3383280038833618,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.35151201486587524,
              0.9361550211906433,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.44636398553848267,
              0.8948330283164978,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.3995789885520935,
              0.9166839718818665,
              0,
              -0.825190007686615,
              0.5648369789123535,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8251590132713318,
              0.5648369789123535,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.7955560088157654,
              0.6058530211448669,
              0,
              -0.8781399726867676,
              0.4783470034599304,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8781700134277344,
              0.478316992521286,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              -0.8527179956436157,
              0.5223240256309509,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.619189977645874,
              -0.7852110266685486,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.619189977645874,
              -0.7852110266685486,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9821159839630127,
              -0.18814699351787567,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.40018901228904724,
              -0.9164100289344788,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.446943998336792,
              -0.8945279717445374,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7307659983634949,
              -0.6826069951057434,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7642750144004822,
              -0.6448559761047363,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7642750144004822,
              -0.6448259949684143,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9016079902648926,
              -0.4325389862060547,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.31540900468826294,
              -0.948943018913269,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.3521530032157898,
              -0.9359109997749329,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.4923250079154968,
              -0.8703879714012146,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.922544002532959,
              -0.38584598898887634,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9411600232124329,
              -0.3379310071468353,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9573349952697754,
              -0.28891900181770325,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9710069894790649,
              -0.23892900347709656,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5362100005149841,
              -0.8440499901771545,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.5785390138626099,
              -0.81563800573349,
              0,
              0.619189977645874,
              -0.7852110266685486,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6192209720611572,
              -0.7852110266685486,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              0,
              0.6581929922103882,
              -0.752830982208252,
              0,
              0.6953949928283691,
              -0.7186189889907837,
              0,
              0.6953949928283691,
              -0.7185890078544617,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              0,
              0.7958620190620422,
              -0.605426013469696,
              0,
              0.8254340291023254,
              -0.5644400119781494,
              0,
              0.8254650235176086,
              -0.5644400119781494,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.8529919981956482,
              -0.52189701795578,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0,
              0.878383994102478,
              -0.4779199957847595,
              0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.6876130104064941,
              0.30149200558662415,
              -0.6604819893836975,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0.16098499298095703,
              -0.2808620035648346,
              0.9461349844932556,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              0.15707899630069733,
              0.3282569944858551,
              -0.9314249753952026,
              0.6876130104064941,
              0.30149200558662415,
              -0.6604819893836975,
              0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              0.9859610199928284,
              0.06769009679555893,
              0.15253199636936188,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0.7175819873809814,
              -0.13980500400066376,
              0.6822720170021057,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9742419719696045,
              0.16705800592899323,
              -0.1513410061597824,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              0.9928889870643616,
              0.11893100291490555,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              0.16119900345802307,
              -0.29325199127197266,
              0.9423199892044067,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0.16098499298095703,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16098499298095703,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.314767986536026,
              0.9491559863090515,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              0.15378299355506897,
              0.9710990190505981,
              -0.18250100314617157,
              0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              0.15378299355506897,
              0.9710990190505981,
              -0.18250100314617157,
              0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.1652269959449768,
              -0.9652090072631836,
              0.20252099633216858,
              0.7489240169525146,
              -0.64519202709198,
              0.15100599825382233,
              0.7473070025444031,
              -0.6491289734840393,
              0.141757994890213,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.9628890156745911,
              0.26798298954963684,
              -0.0314645990729332,
              0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              0.9628890156745911,
              0.26798298954963684,
              -0.0314645990729332,
              0.6627699732780457,
              0.7365339994430542,
              -0.13489200174808502,
              0.6646320223808289,
              0.736473023891449,
              -0.12582799792289734,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985349774360657,
              -0.043458398431539536,
              0.031281501054763794,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9985659718513489,
              -0.041810400784015656,
              0.03296000137925148,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.15378299355506897,
              0.9710990190505981,
              -0.18250100314617157,
              0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0.15341700613498688,
              0.9687489867210388,
              -0.19480000436306,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0.1638839989900589,
              -0.9680780172348022,
              0.1895499974489212,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0.1652269959449768,
              -0.9678639769554138,
              0.1895499974489212,
              0.9630730152130127,
              0.26758599281311035,
              -0.029145199805498123,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9632250070571899,
              0.26709800958633423,
              -0.029053600504994392,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              -0.9820550084114075,
              0.18848200142383575,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0,
              0.9795830249786377,
              -0.200873002409935,
              0,
              0.9821159839630127,
              -0.18814699351787567,
              0,
              0.9821159839630127,
              -0.18811599910259247,
              0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              0.6881309747695923,
              0.2934660017490387,
              -0.6635640263557434,
              0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              0.16098499298095703,
              -0.2808620035648346,
              0.9461349844932556,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0.7152320146560669,
              -0.13202300667762756,
              0.686269998550415,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.1574140042066574,
              0.31632399559020996,
              -0.935483992099762,
              0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              0.6899930238723755,
              0.2930999994277954,
              -0.6617940068244934,
              0.6881309747695923,
              0.2934660017490387,
              -0.6635640263557434,
              0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.9855949878692627,
              0.06909389793872833,
              0.15424099564552307,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.7152929902076721,
              -0.13202300667762756,
              0.6862090229988098,
              0.7152320146560669,
              -0.13202300667762756,
              0.686269998550415,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              0.9747310280799866,
              0.1647080034017563,
              -0.15076099336147308,
              0.9928889870643616,
              0.11890000104904175,
              0,
              0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              0.16098499298095703,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.7150179743766785,
              -0.13211500644683838,
              0.6864830255508423,
              -0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.7150179743766785,
              -0.13211500644683838,
              0.6864830255508423,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.7150179743766785,
              -0.13211500644683838,
              0.6864830255508423,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855650067329407,
              0.06903290003538132,
              0.1543930023908615,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.6742150187492371,
              0.29593798518180847,
              -0.6765949726104736,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.6742150187492371,
              0.29593798518180847,
              -0.6765949726104736,
              -0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              -0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.6742150187492371,
              0.29593798518180847,
              -0.6765949726104736,
              -0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              -0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              -0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              -0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              -0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              -0.6804410219192505,
              0.29490000009536743,
              -0.6708269715309143,
              -0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              -0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              -0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              -0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              -0.6877650022506714,
              0.2935580015182495,
              -0.6639000177383423,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.16080200672149658,
              -0.280892014503479,
              0.9461650252342224,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.1535390019416809,
              0.3161410093307495,
              -0.9361860156059265,
              -0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.14313200116157532,
              0.3155309855937958,
              -0.9380469918251038,
              -0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.7150179743766785,
              -0.1321450024843216,
              0.6864830255508423,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0.7150179743766785,
              -0.1321450024843216,
              0.6864830255508423,
              0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              0.7150490283966064,
              -0.13211500644683838,
              0.6864529848098755,
              0.7150179743766785,
              -0.1321450024843216,
              0.6864830255508423,
              0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              0.9928889870643616,
              0.11890000104904175,
              -0.0013428099919110537,
              0.9928889870643616,
              0.11880899965763092,
              -0.0003051850071642548,
              0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.9855650067329407,
              0.06903290003538132,
              0.15445399284362793,
              0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.9928889870643616,
              0.11880899965763092,
              0.00015259299834724516,
              0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.9855650067329407,
              0.06903290003538132,
              0.15442399680614471,
              0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              0.9929199814796448,
              0.11853399872779846,
              0.0007019260083325207,
              0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              0.9706109762191772,
              0.16992700099945068,
              -0.17026300728321075,
              0.9738460183143616,
              0.16577699780464172,
              -0.15530900657176971,
              0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              0.15424099564552307,
              0.3161410093307495,
              -0.9360640048980713,
              0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              0.6743670105934143,
              0.2957240045070648,
              -0.6765649914741516,
              0.6859340071678162,
              0.29374098777770996,
              -0.6657000184059143,
              0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.1569869965314865,
              0.31632399559020996,
              -0.935545027256012,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              0.15543100237846375,
              0.3162020146846771,
              -0.9358500242233276,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.15424099564552307,
              0.3161410093307495,
              -0.9360640048980713,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.15424099564552307,
              0.3161410093307495,
              -0.9360640048980713,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              -0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              -0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              -0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              -0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              -0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              -0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              -0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              -0.6742150187492371,
              0.29593798518180847,
              -0.6765949726104736,
              -0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              -0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              -0.6742150187492371,
              0.29593798518180847,
              -0.6765949726104736,
              -0.7151709794998169,
              -0.13205400109291077,
              0.6863309741020203,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              -0.7151709794998169,
              -0.13205400109291077,
              0.6863309741020203,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.7151709794998169,
              -0.13205400109291077,
              0.6863309741020203,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              -0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              -0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              -0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15067000687122345,
              -0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.6751000285148621,
              0.295754998922348,
              -0.6758319735527039,
              -0.6742150187492371,
              0.29593798518180847,
              -0.6765949726104736,
              -0.9747610092163086,
              0.16464699804782867,
              -0.15063899755477905,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              -0.16083300113677979,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              0.15424099564552307,
              0.3161410093307495,
              -0.9360640048980713,
              0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              0.15424099564552307,
              0.3161410093307495,
              -0.9360640048980713,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.15454599261283875,
              0.3161720037460327,
              -0.9360030293464661,
              0.15424099564552307,
              0.3161410093307495,
              -0.9360640048980713,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.9855650067329407,
              0.06906340271234512,
              0.1543630063533783,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              0.7151100039482117,
              -0.13208399713039398,
              0.6863920092582703,
              0.7150790095329285,
              -0.13211500644683838,
              0.6864219903945923,
              0.9855650067329407,
              0.06906340271234512,
              0.1543930023908615,
              0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              0.6671649813652039,
              0.29718899726867676,
              -0.6830350160598755,
              0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              0.6652119755744934,
              0.2974030077457428,
              -0.6848350167274475,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.160862997174263,
              -0.280892014503479,
              0.9461349844932556,
              0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              0.9929199814796448,
              0.11859499663114548,
              0.0011902200058102608,
              0.9929500222206116,
              0.11847300082445145,
              0.0012512600515037775,
              0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              0.9680780172348022,
              0.17355899512767792,
              -0.18073099851608276,
              0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              0.9927979707717896,
              0.11963299661874771,
              -0.0018616300076246262,
              0.9928280115127563,
              0.11941900104284286,
              -0.0021057799458503723,
              0.9675589799880981,
              0.1739249974489212,
              -0.1830500066280365,
              -0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              -0.13745500147342682,
              0.31507301330566406,
              -0.9390550255775452,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.13556300103664398,
              0.31498199701309204,
              -0.9393290281295776,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.6885889768600464,
              0.29334399104118347,
              -0.6631370186805725,
              -0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              -0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              -0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              -0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              -0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              -0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              -0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              -0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              -0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              -0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              -0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              -0.7152619957923889,
              -0.13202300667762756,
              0.6862390041351318,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              -0.7151709794998169,
              -0.13205400109291077,
              0.6863309741020203,
              -0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.7151709794998169,
              -0.13205400109291077,
              0.6863309741020203,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.7152619957923889,
              -0.13202300667762756,
              0.6862390041351318,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              -0.7152619957923889,
              -0.13202300667762756,
              0.6862390041351318,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              -0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              -0.7151709794998169,
              -0.13205400109291077,
              0.6863309741020203,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              -0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              -0.9855949878692627,
              0.06909389793872833,
              0.15430200099945068,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              -0.9747310280799866,
              0.16467800736427307,
              -0.15073099732398987,
              -0.6885889768600464,
              0.29334399104118347,
              -0.6631370186805725,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.6885889768600464,
              0.29334399104118347,
              -0.6631370186805725,
              -0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.6845909953117371,
              0.294046014547348,
              -0.6669510006904602,
              -0.6796470284461975,
              0.29496100544929504,
              -0.6715899705886841,
              -0.9747610092163086,
              0.16467800736427307,
              -0.15070000290870667,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              -0.9928889870643616,
              0.11890000104904175,
              0,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              -0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              -0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              0.6881309747695923,
              0.2934660017490387,
              -0.6635640263557434,
              0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              0.6881309747695923,
              0.2934660017490387,
              -0.6635640263557434,
              0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.15720100700855255,
              0.31632399559020996,
              -0.935513973236084,
              0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.1564989984035492,
              0.31629401445388794,
              -0.9356359839439392,
              0.15552200376987457,
              0.3162330090999603,
              -0.9358199834823608,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              0.7152320146560669,
              -0.13202300667762756,
              0.686269998550415,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.9855949878692627,
              0.06909389793872833,
              0.15427100658416748,
              0.7152320146560669,
              -0.13202300667762756,
              0.686269998550415,
              0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              0.7152320146560669,
              -0.13202300667762756,
              0.686269998550415,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              0.9855949878692627,
              0.06906340271234512,
              0.15430200099945068,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.7152010202407837,
              -0.13205400109291077,
              0.6862999796867371,
              0.7151709794998169,
              -0.13208399713039398,
              0.6863309741020203,
              0.9855949878692627,
              0.06906340271234512,
              0.1543319970369339,
              0.6881309747695923,
              0.2934660017490387,
              -0.6635640263557434,
              0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              0.682424008846283,
              0.29453399777412415,
              -0.6689350008964539,
              0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              0.6747639775276184,
              0.29587700963020325,
              -0.6760770082473755,
              0.16095499694347382,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              0.1609240025281906,
              -0.2808620035648346,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0,
              -0.30237698554992676,
              0.9531540274620056,
              0.1608940064907074,
              -0.280892014503479,
              0.9461349844932556,
              0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              0.9928889870643616,
              0.11890000104904175,
              0.00009155549923889339,
              0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              0.9928889870643616,
              0.11883900314569473,
              0.00042725898674689233,
              0.9929199814796448,
              0.1187169998884201,
              0.0007934810128062963,
              0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0.9741809964179993,
              0.16550199687480927,
              -0.15332500636577606,
              0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              0.9928889870643616,
              0.11902199685573578,
              -0.00012207399413455278,
              0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              0.9725639820098877,
              0.1677909940481186,
              -0.1609240025281906,
              0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              0.9928590059280396,
              0.11923599988222122,
              -0.0005798520287498832,
              0.9928280115127563,
              0.11944899708032608,
              -0.0012207400286570191,
              0.9703670144081116,
              0.17072099447250366,
              -0.1709039956331253,
              -0.15573599934577942,
              0.3162330090999603,
              -0.9357889890670776,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              -0.1504260003566742,
              0.31589698791503906,
              -0.9367660284042358,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              -0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985,
              0,
              0.3030489981174469,
              -0.9529399871826172,
              0,
              0.3030489981174469,
              -0.9529709815979004,
              -0.14365099370479584,
              0.31547001004219055,
              -0.9379860162734985
            ],
            "normalized": false
          },
          "color": {
            "itemSize": 3,
            "type": "Float32Array",
            "array": [
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1
            ],
            "normalized": false
          },
          "uv": {
            "itemSize": 2,
            "type": "Float32Array",
            "array": [
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0,
              1,
              0,
              1,
              1,
              0,
              0.045916300266981125,
              0.00036484398879110813,
              0.045959699898958206,
              0,
              0.05509360134601593,
              0.00036484398879110813,
              0.045959699898958206,
              0.00036484398879110813,
              0.05509360134601593,
              0,
              0.05509360134601593,
              0.00036484398879110813,
              0.9449059963226318,
              0.00036484398879110813,
              0.954039990901947,
              0,
              0.9449059963226318,
              0.00036484398879110813,
              0.954039990901947,
              0,
              0.9540839791297913,
              0,
              0.9449059963226318,
              0.05173660069704056,
              0.0008823429816402495,
              0.05185690149664879,
              0.001138749998062849,
              0.0396789014339447,
              0.0034951800480484962,
              0.05185690149664879,
              0.001138749998062849,
              0.03985600173473358,
              0.0037392498925328255,
              0.0396789014339447,
              0.0034951800480484962,
              0.03985600173473358,
              0.9962610006332397,
              0.05185690149664879,
              0.9988610148429871,
              0.0396789014339447,
              0.9965050220489502,
              0.05185690149664879,
              0.9988610148429871,
              0.05173660069704056,
              0.9991179704666138,
              0.0396789014339447,
              0.9965050220489502,
              0.010832499712705612,
              0.020406700670719147,
              0.01882619969546795,
              0.013448599725961685,
              0.011146100237965584,
              0.020570699125528336,
              0.01882619969546795,
              0.013448599725961685,
              0.019101999700069427,
              0.01364549994468689,
              0.011146100237965584,
              0.020570699125528336,
              0.019101999700069427,
              0.9863539934158325,
              0.01882619969546795,
              0.9865509867668152,
              0.011146100237965584,
              0.9794290065765381,
              0.01882619969546795,
              0.9865509867668152,
              0.010832499712705612,
              0.9795929789543152,
              0.011146100237965584,
              0.9794290065765381,
              0.06427659839391708,
              9.074810236597841e-8,
              0.07714170217514038,
              0,
              0.06433740258216858,
              0.00026062800316140056,
              0.07714170217514038,
              0,
              0.07714170217514038,
              0.00026058300863951445,
              0.06433740258216858,
              0.00026062800316140056,
              0.07714170217514038,
              0.9997389912605286,
              0.07714170217514038,
              1,
              0.06433740258216858,
              0.9997389912605286,
              0.07714170217514038,
              1,
              0.06427659839391708,
              1,
              0.06433740258216858,
              0.9997389912605286,
              0.010832499712705612,
              0.020406700670719147,
              0.011146100237965584,
              0.020570699125528336,
              0.004892770200967789,
              0.02834489941596985,
              0.011146100237965584,
              0.020570699125528336,
              0.005234649870544672,
              0.02847139909863472,
              0.004892770200967789,
              0.02834489941596985,
              0.005234649870544672,
              0.9715290069580078,
              0.011146100237965584,
              0.9794290065765381,
              0.004892770200967789,
              0.971655011177063,
              0.011146100237965584,
              0.9794290065765381,
              0.010832499712705612,
              0.9795929789543152,
              0.004892770200967789,
              0.971655011177063,
              0.05173660069704056,
              0.0008823429816402495,
              0.06427659839391708,
              9.074810236597841e-8,
              0.05185690149664879,
              0.001138749998062849,
              0.06427659839391708,
              9.074810236597841e-8,
              0.06433740258216858,
              0.00026062800316140056,
              0.05185690149664879,
              0.001138749998062849,
              0.06433740258216858,
              0.9997389912605286,
              0.06427659839391708,
              1,
              0.05185690149664879,
              0.9988610148429871,
              0.06427659839391708,
              1,
              0.05173660069704056,
              0.9991179704666138,
              0.05185690149664879,
              0.9988610148429871,
              0.004892770200967789,
              0.02834489941596985,
              0.005234649870544672,
              0.02847139909863472,
              0.0012350799515843391,
              0.036958400160074234,
              0.005234649870544672,
              0.02847139909863472,
              0.0015941399615257978,
              0.03704420104622841,
              0.0012350799515843391,
              0.036958400160074234,
              0.0015941399615257978,
              0.9629560112953186,
              0.005234649870544672,
              0.9715290069580078,
              0.0012350799515843391,
              0.9630420207977295,
              0.005234649870544672,
              0.9715290069580078,
              0.004892770200967789,
              0.971655011177063,
              0.0012350799515843391,
              0.9630420207977295,
              0.028566399589180946,
              0.007738309912383556,
              0.02879609912633896,
              0.007962300442159176,
              0.01882619969546795,
              0.013448599725961685,
              0.02879609912633896,
              0.007962300442159176,
              0.019101999700069427,
              0.01364549994468689,
              0.01882619969546795,
              0.013448599725961685,
              0.019101999700069427,
              0.9863539934158325,
              0.02879609912633896,
              0.9920380115509033,
              0.01882619969546795,
              0.9865509867668152,
              0.02879609912633896,
              0.9920380115509033,
              0.028566399589180946,
              0.9922620058059692,
              0.01882619969546795,
              0.9865509867668152,
              0.0012350799515843391,
              0.036958400160074234,
              0.0015941399615257978,
              0.03704420104622841,
              0,
              0.045916300266981125,
              0.0015941399615257978,
              0.03704420104622841,
              0.00036484398879110813,
              0.045959699898958206,
              0,
              0.045916300266981125,
              0.00036484398879110813,
              0.954039990901947,
              0.0015941399615257978,
              0.9629560112953186,
              0,
              0.9540839791297913,
              0.0015941399615257978,
              0.9629560112953186,
              0.0012350799515843391,
              0.9630420207977295,
              0,
              0.9540839791297913,
              0.028566399589180946,
              0.007738309912383556,
              0.0396789014339447,
              0.0034951800480484962,
              0.02879609912633896,
              0.007962300442159176,
              0.0396789014339447,
              0.0034951800480484962,
              0.03985600173473358,
              0.0037392498925328255,
              0.02879609912633896,
              0.007962300442159176,
              0.03985600173473358,
              0.9962610006332397,
              0.0396789014339447,
              0.9965050220489502,
              0.02879609912633896,
              0.9920380115509033,
              0.0396789014339447,
              0.9965050220489502,
              0.028566399589180946,
              0.9922620058059692,
              0.02879609912633896,
              0.9920380115509033,
              0.07714170217514038,
              0.00026058300863951445,
              0.07714170217514038,
              0,
              1,
              0.00026058300863951445,
              0.07714170217514038,
              0,
              1,
              0,
              1,
              0.00026058300863951445,
              1,
              1,
              0.07714170217514038,
              1,
              1,
              0.9997389912605286,
              0.07714170217514038,
              1,
              0.07714170217514038,
              0.9997389912605286,
              1,
              0.9997389912605286,
              0.0008599749999120831,
              0.05509360134601593,
              0.00036484398879110813,
              0.05509360134601593,
              0.0008599749999120831,
              0.04599040001630783,
              0.00036484398879110813,
              0.05509360134601593,
              0.00036484398879110813,
              0.045959699898958206,
              0.0008599749999120831,
              0.04599040001630783,
              0.00036484398879110813,
              0.954039990901947,
              0.00036484398879110813,
              0.9449059963226318,
              0.0008599749999120831,
              0.954010009765625,
              0.00036484398879110813,
              0.9449059963226318,
              0.0008599749999120831,
              0.9449059963226318,
              0.0008599749999120831,
              0.954010009765625,
              0.0008599749999120831,
              0.04599040001630783,
              0.00036484398879110813,
              0.045959699898958206,
              0.002073720097541809,
              0.0371566005051136,
              0.00036484398879110813,
              0.045959699898958206,
              0.0015941399615257978,
              0.03704420104622841,
              0.002073720097541809,
              0.0371566005051136,
              0.0015941399615257978,
              0.9629560112953186,
              0.00036484398879110813,
              0.954039990901947,
              0.002073720097541809,
              0.9628430008888245,
              0.00036484398879110813,
              0.954039990901947,
              0.0008599749999120831,
              0.954010009765625,
              0.002073720097541809,
              0.9628430008888245,
              0.002073720097541809,
              0.0371566005051136,
              0.0015941399615257978,
              0.03704420104622841,
              0.005690149962902069,
              0.02863370068371296,
              0.0015941399615257978,
              0.03704420104622841,
              0.005234649870544672,
              0.02847139909863472,
              0.005690149962902069,
              0.02863370068371296,
              0.005234649870544672,
              0.9715290069580078,
              0.0015941399615257978,
              0.9629560112953186,
              0.005690149962902069,
              0.9713659882545471,
              0.0015941399615257978,
              0.9629560112953186,
              0.002073720097541809,
              0.9628430008888245,
              0.005690149962902069,
              0.9713659882545471,
              0.005690149962902069,
              0.02863370068371296,
              0.005234649870544672,
              0.02847139909863472,
              0.011557700112462044,
              0.020788200199604034,
              0.005234649870544672,
              0.02847139909863472,
              0.011146100237965584,
              0.020570699125528336,
              0.011557700112462044,
              0.020788200199604034,
              0.011146100237965584,
              0.9794290065765381,
              0.005234649870544672,
              0.9715290069580078,
              0.011557700112462044,
              0.9792119860649109,
              0.005234649870544672,
              0.9715290069580078,
              0.005690149962902069,
              0.9713659882545471,
              0.011557700112462044,
              0.9792119860649109,
              0.011557700112462044,
              0.020788200199604034,
              0.011146100237965584,
              0.020570699125528336,
              0.019478000700473785,
              0.013894299976527691,
              0.011146100237965584,
              0.020570699125528336,
              0.019101999700069427,
              0.01364549994468689,
              0.019478000700473785,
              0.013894299976527691,
              0.019101999700069427,
              0.9863539934158325,
              0.011146100237965584,
              0.9794290065765381,
              0.019478000700473785,
              0.9861059784889221,
              0.011146100237965584,
              0.9794290065765381,
              0.011557700112462044,
              0.9792119860649109,
              0.019478000700473785,
              0.9861059784889221,
              0.019478000700473785,
              0.013894299976527691,
              0.019101999700069427,
              0.01364549994468689,
              0.02910660021007061,
              0.008252969942986965,
              0.019101999700069427,
              0.01364549994468689,
              0.02879609912633896,
              0.007962300442159176,
              0.02910660021007061,
              0.008252969942986965,
              0.02879609912633896,
              0.9920380115509033,
              0.019101999700069427,
              0.9863539934158325,
              0.02910660021007061,
              0.9917470216751099,
              0.019101999700069427,
              0.9863539934158325,
              0.019478000700473785,
              0.9861059784889221,
              0.02910660021007061,
              0.9917470216751099,
              0.02910660021007061,
              0.008252969942986965,
              0.02879609912633896,
              0.007962300442159176,
              0.04007149860262871,
              0.004068439826369286,
              0.02879609912633896,
              0.007962300442159176,
              0.03985600173473358,
              0.0037392498925328255,
              0.04007149860262871,
              0.004068439826369286,
              0.03985600173473358,
              0.9962610006332397,
              0.02879609912633896,
              0.9920380115509033,
              0.04007149860262871,
              0.9959319829940796,
              0.02879609912633896,
              0.9920380115509033,
              0.02910660021007061,
              0.9917470216751099,
              0.04007149860262871,
              0.9959319829940796,
              0.04007149860262871,
              0.004068439826369286,
              0.03985600173473358,
              0.0037392498925328255,
              0.05200380086898804,
              0.0014835300389677286,
              0.03985600173473358,
              0.0037392498925328255,
              0.05185690149664879,
              0.001138749998062849,
              0.05200380086898804,
              0.0014835300389677286,
              0.05185690149664879,
              0.9988610148429871,
              0.03985600173473358,
              0.9962610006332397,
              0.05200380086898804,
              0.9985160231590271,
              0.03985600173473358,
              0.9962610006332397,
              0.04007149860262871,
              0.9959319829940796,
              0.05200380086898804,
              0.9985160231590271,
              0.05200380086898804,
              0.0014835300389677286,
              0.05185690149664879,
              0.001138749998062849,
              0.06440950185060501,
              0.0006123000057414174,
              0.05185690149664879,
              0.001138749998062849,
              0.06433740258216858,
              0.00026062800316140056,
              0.06440950185060501,
              0.0006123000057414174,
              0.06433740258216858,
              0.9997389912605286,
              0.05185690149664879,
              0.9988610148429871,
              0.06440950185060501,
              0.9993879795074463,
              0.05185690149664879,
              0.9988610148429871,
              0.05200380086898804,
              0.9985160231590271,
              0.06440950185060501,
              0.9993879795074463,
              0.06440950185060501,
              0.0006123000057414174,
              0.06433740258216858,
              0.00026062800316140056,
              0.07714080065488815,
              0.0006141599733382463,
              0.06433740258216858,
              0.00026062800316140056,
              0.07714170217514038,
              0.00026058300863951445,
              0.07714080065488815,
              0.0006141599733382463,
              0.07714170217514038,
              0.9997389912605286,
              0.06433740258216858,
              0.9997389912605286,
              0.07714080065488815,
              0.9993860125541687,
              0.06433740258216858,
              0.9997389912605286,
              0.06440950185060501,
              0.9993879795074463,
              0.07714080065488815,
              0.9993860125541687,
              0.07714080065488815,
              0.0006141599733382463,
              0.07714170217514038,
              0.00026058300863951445,
              1,
              0.0006141599733382463,
              0.07714170217514038,
              0.00026058300863951445,
              1,
              0.00026058300863951445,
              1,
              0.0006141599733382463,
              1,
              0.9997389912605286,
              0.07714170217514038,
              0.9997389912605286,
              1,
              0.9993860125541687,
              0.07714170217514038,
              0.9997389912605286,
              0.07714080065488815,
              0.9993860125541687,
              1,
              0.9993860125541687,
              0.06327860057353973,
              0.05509360134601593,
              0.06327860057353973,
              0.053213298320770264,
              0.07711009681224823,
              0.05509369820356369,
              0.06327860057353973,
              0.053213298320770264,
              0.0634377971291542,
              0.051806699484586716,
              0.07711009681224823,
              0.05509369820356369,
              0.0634377971291542,
              0.051806699484586716,
              0.06408960372209549,
              0.050217900425195694,
              0.07711009681224823,
              0.05509369820356369,
              0.06408960372209549,
              0.050217900425195694,
              0.06510569900274277,
              0.04882900044322014,
              0.07711009681224823,
              0.05509369820356369,
              0.06510569900274277,
              0.04882900044322014,
              0.06666990369558334,
              0.04746929928660393,
              0.07711009681224823,
              0.05509369820356369,
              0.06666990369558334,
              0.04746929928660393,
              0.06839320063591003,
              0.046488601714372635,
              0.07711009681224823,
              0.05509369820356369,
              0.06839320063591003,
              0.046488601714372635,
              0.07018859684467316,
              0.04582170024514198,
              0.07711009681224823,
              0.05509369820356369,
              0.07018859684467316,
              0.04582170024514198,
              0.07242579758167267,
              0.04534289985895157,
              0.07711009681224823,
              0.05509369820356369,
              0.07242579758167267,
              0.04534289985895157,
              0.07472269982099533,
              0.045194998383522034,
              0.07711009681224823,
              0.05509369820356369,
              0.07472269982099533,
              0.045194998383522034,
              0.07711009681224823,
              0.04521099850535393,
              0.07711009681224823,
              0.05509369820356369,
              0.07711009681224823,
              0.05509369820356369,
              0.07711009681224823,
              0.04521099850535393,
              1,
              0.05509369820356369,
              0.07711009681224823,
              0.04521099850535393,
              1,
              0.04521099850535393,
              1,
              0.05509369820356369,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0.07714080065488815,
              0.0006141599733382463,
              0.07713919878005981,
              0.0017464900156483054,
              0.06440950185060501,
              0.0006123000057414174,
              0.07713919878005981,
              0.0017464900156483054,
              0.06465990096330643,
              0.0017420899821445346,
              0.06440950185060501,
              0.0006123000057414174,
              0.07713919878005981,
              0.0017464900156483054,
              0.07711009681224823,
              0.04521099850535393,
              0.06465990096330643,
              0.0017420899821445346,
              0.07711009681224823,
              0.04521099850535393,
              0.07472269982099533,
              0.045194998383522034,
              0.06465990096330643,
              0.0017420899821445346,
              0.06440950185060501,
              0.0006123000057414174,
              0.06465990096330643,
              0.0017420899821445346,
              0.05200380086898804,
              0.0014835300389677286,
              0.06465990096330643,
              0.0017420899821445346,
              0.05250440165400505,
              0.0025935599114745855,
              0.05200380086898804,
              0.0014835300389677286,
              0.06465990096330643,
              0.0017420899821445346,
              0.07472269982099533,
              0.045194998383522034,
              0.05250440165400505,
              0.0025935599114745855,
              0.07472269982099533,
              0.045194998383522034,
              0.07242579758167267,
              0.04534289985895157,
              0.05250440165400505,
              0.0025935599114745855,
              0.05200380086898804,
              0.0014835300389677286,
              0.05250440165400505,
              0.0025935599114745855,
              0.04007149860262871,
              0.004068439826369286,
              0.05250440165400505,
              0.0025935599114745855,
              0.04080849885940552,
              0.005126330070197582,
              0.04007149860262871,
              0.004068439826369286,
              0.05250440165400505,
              0.0025935599114745855,
              0.07242579758167267,
              0.04534289985895157,
              0.04080849885940552,
              0.005126330070197582,
              0.07242579758167267,
              0.04534289985895157,
              0.07018859684467316,
              0.04582170024514198,
              0.04080849885940552,
              0.005126330070197582,
              0.04007149860262871,
              0.004068439826369286,
              0.04080849885940552,
              0.005126330070197582,
              0.02910660021007061,
              0.008252969942986965,
              0.04080849885940552,
              0.005126330070197582,
              0.03010289929807186,
              0.009208889678120613,
              0.02910660021007061,
              0.008252969942986965,
              0.04080849885940552,
              0.005126330070197582,
              0.07018859684467316,
              0.04582170024514198,
              0.03010289929807186,
              0.009208889678120613,
              0.07018859684467316,
              0.04582170024514198,
              0.06839320063591003,
              0.046488601714372635,
              0.03010289929807186,
              0.009208889678120613,
              0.02910660021007061,
              0.008252969942986965,
              0.03010289929807186,
              0.009208889678120613,
              0.019478000700473785,
              0.013894299976527691,
              0.03010289929807186,
              0.009208889678120613,
              0.020678099244832993,
              0.01472609955817461,
              0.019478000700473785,
              0.013894299976527691,
              0.03010289929807186,
              0.009208889678120613,
              0.06839320063591003,
              0.046488601714372635,
              0.020678099244832993,
              0.01472609955817461,
              0.06839320063591003,
              0.046488601714372635,
              0.06666990369558334,
              0.04746929928660393,
              0.020678099244832993,
              0.01472609955817461,
              0.019478000700473785,
              0.013894299976527691,
              0.020678099244832993,
              0.01472609955817461,
              0.011557700112462044,
              0.020788200199604034,
              0.020678099244832993,
              0.01472609955817461,
              0.012901700101792812,
              0.02149450033903122,
              0.011557700112462044,
              0.020788200199604034,
              0.020678099244832993,
              0.01472609955817461,
              0.06666990369558334,
              0.04746929928660393,
              0.012901700101792812,
              0.02149450033903122,
              0.06666990369558334,
              0.04746929928660393,
              0.06510569900274277,
              0.04882900044322014,
              0.012901700101792812,
              0.02149450033903122,
              0.011557700112462044,
              0.020788200199604034,
              0.012901700101792812,
              0.02149450033903122,
              0.005690149962902069,
              0.02863370068371296,
              0.012901700101792812,
              0.02149450033903122,
              0.007163949776440859,
              0.029171299189329147,
              0.005690149962902069,
              0.02863370068371296,
              0.012901700101792812,
              0.02149450033903122,
              0.06510569900274277,
              0.04882900044322014,
              0.007163949776440859,
              0.029171299189329147,
              0.06510569900274277,
              0.04882900044322014,
              0.06408960372209549,
              0.050217900425195694,
              0.007163949776440859,
              0.029171299189329147,
              0.005690149962902069,
              0.02863370068371296,
              0.007163949776440859,
              0.029171299189329147,
              0.002073720097541809,
              0.0371566005051136,
              0.007163949776440859,
              0.029171299189329147,
              0.003623540047556162,
              0.03752399981021881,
              0.002073720097541809,
              0.0371566005051136,
              0.007163949776440859,
              0.029171299189329147,
              0.06408960372209549,
              0.050217900425195694,
              0.003623540047556162,
              0.03752399981021881,
              0.06408960372209549,
              0.050217900425195694,
              0.0634377971291542,
              0.051806699484586716,
              0.003623540047556162,
              0.03752399981021881,
              0.002073720097541809,
              0.0371566005051136,
              0.003623540047556162,
              0.03752399981021881,
              0.0008599749999120831,
              0.04599040001630783,
              0.003623540047556162,
              0.03752399981021881,
              0.0024448600597679615,
              0.046142399311065674,
              0.0008599749999120831,
              0.04599040001630783,
              0.003623540047556162,
              0.03752399981021881,
              0.0634377971291542,
              0.051806699484586716,
              0.0024448600597679615,
              0.046142399311065674,
              0.0634377971291542,
              0.051806699484586716,
              0.06327860057353973,
              0.053213298320770264,
              0.0024448600597679615,
              0.046142399311065674,
              0.0008599749999120831,
              0.04599040001630783,
              0.0024448600597679615,
              0.046142399311065674,
              0.0008599749999120831,
              0.05509360134601593,
              0.0024448600597679615,
              0.046142399311065674,
              0.0024448600597679615,
              0.05509360134601593,
              0.0008599749999120831,
              0.05509360134601593,
              0.0024448600597679615,
              0.046142399311065674,
              0.06327860057353973,
              0.053213298320770264,
              0.0024448600597679615,
              0.05509360134601593,
              0.06327860057353973,
              0.053213298320770264,
              0.06327860057353973,
              0.05509360134601593,
              0.0024448600597679615,
              0.05509360134601593,
              0.07711009681224823,
              0.04521099850535393,
              0.07713919878005981,
              0.0017464900156483054,
              1,
              0.04521099850535393,
              0.07713919878005981,
              0.0017464900156483054,
              1,
              0.0017464900156483054,
              1,
              0.04521099850535393,
              0.07713919878005981,
              0.0017464900156483054,
              0.07714080065488815,
              0.0006141599733382463,
              1,
              0.0017464900156483054,
              0.07714080065488815,
              0.0006141599733382463,
              1,
              0.0006141599733382463,
              1,
              0.0017464900156483054,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0.13998399674892426,
              0,
              0.09753890335559845,
              0.00036484398879110813,
              0.13998399674892426,
              0,
              0.09753890335559845,
              0.00036484398879110813,
              0.09753890335559845,
              0.00036484398879110813,
              0.13998399674892426,
              0.00036484398879110813,
              0.9024609923362732,
              0,
              0.9024609923362732,
              0.00036484398879110813,
              0.8600159883499146,
              0,
              0.9024609923362732,
              0,
              0.8600159883499146,
              0.00036484398879110813,
              0.8600159883499146,
              0,
              0.09753890335559845,
              0,
              0.05509360134601593,
              0.00036484398879110813,
              0.09753890335559845,
              0,
              0.05509360134601593,
              0.00036484398879110813,
              0.05509360134601593,
              0.00036484398879110813,
              0.09753890335559845,
              0.00036484398879110813,
              0.9449059963226318,
              0,
              0.9449059963226318,
              0.00036484398879110813,
              0.9024609923362732,
              0,
              0.9449059963226318,
              0,
              0.9024609923362732,
              0.00036484398879110813,
              0.9024609923362732,
              0.00036484398879110813,
              0.13998399674892426,
              0.00036484398879110813,
              0.09753890335559845,
              0.0008599749999120831,
              0.13998399674892426,
              0.00036484398879110813,
              0.09753890335559845,
              0.0008599749999120831,
              0.09753890335559845,
              0.0008599749999120831,
              0.13998399674892426,
              0.0008599749999120831,
              0.9024609923362732,
              0.00036484398879110813,
              0.9024609923362732,
              0.0008599749999120831,
              0.8600159883499146,
              0.00036484398879110813,
              0.9024609923362732,
              0.00036484398879110813,
              0.8600159883499146,
              0.0008599749999120831,
              0.8600159883499146,
              0.00036484398879110813,
              0.09753890335559845,
              0.00036484398879110813,
              0.05509360134601593,
              0.0008599749999120831,
              0.09753890335559845,
              0.00036484398879110813,
              0.05509360134601593,
              0.0008599749999120831,
              0.05509360134601593,
              0.0008599749999120831,
              0.09753890335559845,
              0.0008599749999120831,
              0.9449059963226318,
              0.00036484398879110813,
              0.9449059963226318,
              0.0008599749999120831,
              0.9024609923362732,
              0.00036484398879110813,
              0.9449059963226318,
              0.00036484398879110813,
              0.9024609923362732,
              0.0008599749999120831,
              0.9024609923362732,
              0.07711009681224823,
              0.05509369820356369,
              0.07711009681224823,
              0.09753890335559845,
              0.06327860057353973,
              0.05509360134601593,
              0.07711009681224823,
              0.09753890335559845,
              0.06327860057353973,
              0.09753890335559845,
              0.06327860057353973,
              0.05509360134601593,
              0.07711009681224823,
              0.09753890335559845,
              0.07711009681224823,
              0.13998399674892426,
              0.06327860057353973,
              0.09753890335559845,
              0.07711009681224823,
              0.13998399674892426,
              0.06327860057353973,
              0.13998399674892426,
              0.06327860057353973,
              0.09753890335559845,
              1,
              0.05509369820356369,
              1,
              0.09753890335559845,
              0.07711009681224823,
              0.05509369820356369,
              1,
              0.09753890335559845,
              0.07711009681224823,
              0.09753890335559845,
              0.07711009681224823,
              0.05509369820356369,
              1,
              0.09753890335559845,
              1,
              0.13998399674892426,
              0.07711009681224823,
              0.09753890335559845,
              1,
              0.13998399674892426,
              0.07711009681224823,
              0.13998399674892426,
              0.07711009681224823,
              0.09753890335559845,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0.0024448600597679615,
              0.05509360134601593,
              0.0024448600597679615,
              0.09753890335559845,
              0.0008599749999120831,
              0.05509360134601593,
              0.0024448600597679615,
              0.09753890335559845,
              0.0008599749999120831,
              0.09753890335559845,
              0.0008599749999120831,
              0.05509360134601593,
              0.0024448600597679615,
              0.09753890335559845,
              0.0024448600597679615,
              0.13998399674892426,
              0.0008599749999120831,
              0.09753890335559845,
              0.0024448600597679615,
              0.13998399674892426,
              0.0008599749999120831,
              0.13998399674892426,
              0.0008599749999120831,
              0.09753890335559845,
              0.06327860057353973,
              0.05509360134601593,
              0.06327860057353973,
              0.09753890335559845,
              0.0024448600597679615,
              0.05509360134601593,
              0.06327860057353973,
              0.09753890335559845,
              0.0024448600597679615,
              0.09753890335559845,
              0.0024448600597679615,
              0.05509360134601593,
              0.06327860057353973,
              0.09753890335559845,
              0.06327860057353973,
              0.13998399674892426,
              0.0024448600597679615,
              0.09753890335559845,
              0.06327860057353973,
              0.13998399674892426,
              0.0024448600597679615,
              0.13998399674892426,
              0.0024448600597679615,
              0.09753890335559845,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0.23014900088310242,
              0.00036484398879110813,
              0.23014900088310242,
              0,
              0.3203130066394806,
              0.00036484398879110813,
              0.23014900088310242,
              0.00036484398879110813,
              0.3203130066394806,
              0,
              0.3203130066394806,
              0.00036484398879110813,
              0.23014900088310242,
              0.0008599749999120831,
              0.23014900088310242,
              0.00036484398879110813,
              0.3203130066394806,
              0.0008599749999120831,
              0.23014900088310242,
              0.0008599749999120831,
              0.3203130066394806,
              0.00036484398879110813,
              0.3203130066394806,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0.00036484398879110813,
              0.3203130066394806,
              0.0008599749999120831,
              0.3203130066394806,
              0.00036484398879110813,
              0.41047701239585876,
              0.0008599749999120831,
              0.3203130066394806,
              0.0008599749999120831,
              0.41047701239585876,
              0.00036484398879110813,
              0.41047701239585876,
              0,
              0.3203130066394806,
              0.00036484398879110813,
              0.3203130066394806,
              0,
              0.41047701239585876,
              0.00036484398879110813,
              0.3203130066394806,
              0.00036484398879110813,
              0.41047701239585876,
              0,
              0.41047701239585876,
              0,
              0.41047701239585876,
              0.00036484398879110813,
              0.41047701239585876,
              0,
              0.5006420016288757,
              0.00036484398879110813,
              0.41047701239585876,
              0.00036484398879110813,
              0.5006420016288757,
              0,
              0.5006420016288757,
              0.00036484398879110813,
              0.41047701239585876,
              0.0008599749999120831,
              0.41047701239585876,
              0.00036484398879110813,
              0.5006420016288757,
              0.0008599749999120831,
              0.41047701239585876,
              0.0008599749999120831,
              0.5006420016288757,
              0.00036484398879110813,
              0.5006420016288757,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0.00036484398879110813,
              0.5006420016288757,
              0.0008599749999120831,
              0.5006420016288757,
              0.00036484398879110813,
              0.5908060073852539,
              0.0008599749999120831,
              0.5006420016288757,
              0.0008599749999120831,
              0.5908060073852539,
              0.00036484398879110813,
              0.5908060073852539,
              0,
              0.5006420016288757,
              0.00036484398879110813,
              0.5006420016288757,
              0,
              0.5908060073852539,
              0.00036484398879110813,
              0.5006420016288757,
              0.00036484398879110813,
              0.5908060073852539,
              0,
              0.5908060073852539,
              0,
              0.5908060073852539,
              0.00036484398879110813,
              0.5908060073852539,
              0,
              0.6809700131416321,
              0.00036484398879110813,
              0.5908060073852539,
              0.00036484398879110813,
              0.6809700131416321,
              0,
              0.6809700131416321,
              0.00036484398879110813,
              0.5908060073852539,
              0.0008599749999120831,
              0.5908060073852539,
              0.00036484398879110813,
              0.6809700131416321,
              0.0008599749999120831,
              0.5908060073852539,
              0.0008599749999120831,
              0.6809700131416321,
              0.00036484398879110813,
              0.6809700131416321,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0.00036484398879110813,
              0.6809700131416321,
              0.0008599749999120831,
              0.6809700131416321,
              0.00036484398879110813,
              0.7711340188980103,
              0.0008599749999120831,
              0.6809700131416321,
              0.0008599749999120831,
              0.7711340188980103,
              0.00036484398879110813,
              0.7711340188980103,
              0,
              0.6809700131416321,
              0.00036484398879110813,
              0.6809700131416321,
              0,
              0.7711340188980103,
              0.00036484398879110813,
              0.6809700131416321,
              0.00036484398879110813,
              0.7711340188980103,
              0,
              0.7711340188980103,
              0,
              0.7711340188980103,
              0.00036484398879110813,
              0.7711340188980103,
              0,
              0.8600159883499146,
              0.00036484398879110813,
              0.7711340188980103,
              0.00036484398879110813,
              0.8600159883499146,
              0,
              0.8600159883499146,
              0.00036484398879110813,
              0.7711340188980103,
              0.0008599749999120831,
              0.7711340188980103,
              0.00036484398879110813,
              0.8600159883499146,
              0.0008599749999120831,
              0.7711340188980103,
              0.0008599749999120831,
              0.8600159883499146,
              0.00036484398879110813,
              0.8600159883499146,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0.0008599749999120831,
              0.13998399674892426,
              0.0008600769797340035,
              0.18957500159740448,
              0.00036484398879110813,
              0.13998399674892426,
              0.0008600769797340035,
              0.18957500159740448,
              0.00036484398879110813,
              0.18957500159740448,
              0.00036484398879110813,
              0.13998399674892426,
              0.0008600769797340035,
              0.18957500159740448,
              0.0008599749999120831,
              0.23014900088310242,
              0.00036484398879110813,
              0.18957500159740448,
              0.0008599749999120831,
              0.23014900088310242,
              0.00036484398879110813,
              0.23014900088310242,
              0.00036484398879110813,
              0.18957500159740448,
              0.00036484398879110813,
              0.13998399674892426,
              0.00036484398879110813,
              0.18957500159740448,
              0,
              0.13998399674892426,
              0.00036484398879110813,
              0.18957500159740448,
              1.0162799668478328e-7,
              0.18957500159740448,
              0,
              0.13998399674892426,
              0.00036484398879110813,
              0.18957500159740448,
              0.00036484398879110813,
              0.23014900088310242,
              1.0162799668478328e-7,
              0.18957500159740448,
              0.00036484398879110813,
              0.23014900088310242,
              0,
              0.23014900088310242,
              1.0162799668478328e-7,
              0.18957500159740448,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0,
              0,
              1,
              0,
              0,
              1,
              1,
              0,
              1,
              1,
              0,
              1,
              0.7251999974250793,
              0.1938759982585907,
              0.7251859903335571,
              0.19402800500392914,
              0.7251350283622742,
              0.1923390030860901,
              0.7251859903335571,
              0.19402800500392914,
              0.725121021270752,
              0.19249999523162842,
              0.7251350283622742,
              0.1923390030860901,
              0.7253249883651733,
              0.19749200344085693,
              0.7253100275993347,
              0.19762100279331207,
              0.7252609729766846,
              0.19559800624847412,
              0.7253100275993347,
              0.19762100279331207,
              0.725246012210846,
              0.1957390010356903,
              0.7252609729766846,
              0.19559800624847412,
              0.7253860235214233,
              0.1995439976453781,
              0.7253710031509399,
              0.19965900480747223,
              0.7253249883651733,
              0.19749200344085693,
              0.7253710031509399,
              0.19965900480747223,
              0.7253100275993347,
              0.19762100279331207,
              0.7253249883651733,
              0.19749200344085693,
              0.7254390120506287,
              0.20173899829387665,
              0.7254239916801453,
              0.20184099674224854,
              0.7253860235214233,
              0.1995439976453781,
              0.7254239916801453,
              0.20184099674224854,
              0.7253710031509399,
              0.19965900480747223,
              0.7253860235214233,
              0.1995439976453781,
              0.7254949808120728,
              0.2040649950504303,
              0.7254800200462341,
              0.20415200293064117,
              0.7254390120506287,
              0.20173899829387665,
              0.7254800200462341,
              0.20415200293064117,
              0.7254239916801453,
              0.20184099674224854,
              0.7254390120506287,
              0.20173899829387665,
              0.7256150245666504,
              0.20905299484729767,
              0.7256010174751282,
              0.20910799503326416,
              0.7255539894104004,
              0.20650799572467804,
              0.7256010174751282,
              0.20910799503326416,
              0.7255390286445618,
              0.20657899975776672,
              0.7255539894104004,
              0.20650799572467804,
              0.7259460091590881,
              0.22284600138664246,
              0.7259320020675659,
              0.22281399369239807,
              0.7258780002593994,
              0.21999099850654602,
              0.7259320020675659,
              0.22281399369239807,
              0.7258639931678772,
              0.219977006316185,
              0.7258780002593994,
              0.21999099850654602,
              0.7260149717330933,
              0.22572100162506104,
              0.7260010242462158,
              0.22567200660705566,
              0.7259460091590881,
              0.22284600138664246,
              0.7260010242462158,
              0.22567200660705566,
              0.7259320020675659,
              0.22281399369239807,
              0.7259460091590881,
              0.22284600138664246,
              0.7260839939117432,
              0.2286040037870407,
              0.726069986820221,
              0.22853699326515198,
              0.7260149717330933,
              0.22572100162506104,
              0.726069986820221,
              0.22853699326515198,
              0.7260010242462158,
              0.22567200660705566,
              0.7260149717330933,
              0.22572100162506104,
              0.7246969938278198,
              0.1951570063829422,
              0.7246840000152588,
              0.19497300684452057,
              0.7247589826583862,
              0.1953279972076416,
              0.7246840000152588,
              0.19497300684452057,
              0.7247470021247864,
              0.19514399766921997,
              0.7247589826583862,
              0.1953279972076416,
              0.7248200178146362,
              0.19568899273872375,
              0.7248079776763916,
              0.1955060064792633,
              0.7248780131340027,
              0.19623200595378876,
              0.7248079776763916,
              0.1955060064792633,
              0.7248659729957581,
              0.19605199992656708,
              0.7248780131340027,
              0.19623200595378876,
              0.7249860167503357,
              0.19781999289989471,
              0.7249760031700134,
              0.1976509988307953,
              0.7250369787216187,
              0.1988459974527359,
              0.7249760031700134,
              0.1976509988307953,
              0.7250270247459412,
              0.19868500530719757,
              0.7250369787216187,
              0.1988459974527359,
              0.7259500026702881,
              0.22360000014305115,
              0.7259389758110046,
              0.2236499935388565,
              0.7260149717330933,
              0.2257159948348999,
              0.7259389758110046,
              0.2236499935388565,
              0.7260040044784546,
              0.22578300535678864,
              0.7260149717330933,
              0.2257159948348999,
              0.7258840203285217,
              0.22148799896240234,
              0.7258729934692383,
              0.22152000665664673,
              0.7259500026702881,
              0.22360000014305115,
              0.7258729934692383,
              0.22152000665664673,
              0.7259389758110046,
              0.2236499935388565,
              0.7259500026702881,
              0.22360000014305115,
              0.7255380153656006,
              0.211326003074646,
              0.7255280017852783,
              0.2112710028886795,
              0.7256079912185669,
              0.21327200531959534,
              0.7255280017852783,
              0.2112710028886795,
              0.7255970239639282,
              0.21323400735855103,
              0.7256079912185669,
              0.21327200531959534,
              0.7254689931869507,
              0.20944300293922424,
              0.7254589796066284,
              0.20937199890613556,
              0.7255380153656006,
              0.211326003074646,
              0.7254589796066284,
              0.20937199890613556,
              0.7255280017852783,
              0.2112710028886795,
              0.7255380153656006,
              0.211326003074646,
              0.7253350019454956,
              0.2059040069580078,
              0.7253249883651733,
              0.2058030068874359,
              0.7254019975662231,
              0.20763200521469116,
              0.7253249883651733,
              0.2058030068874359,
              0.7253909707069397,
              0.20754599571228027,
              0.7254019975662231,
              0.20763200521469116,
              0.7250900268554688,
              0.20001399517059326,
              0.7250810265541077,
              0.199863001704216,
              0.7251480221748352,
              0.20131400227546692,
              0.7250810265541077,
              0.199863001704216,
              0.7251380085945129,
              0.20117299258708954,
              0.7251480221748352,
              0.20131400227546692,
              0.7250369787216187,
              0.1988459974527359,
              0.7250270247459412,
              0.19868500530719757,
              0.7250900268554688,
              0.20001399517059326,
              0.7250270247459412,
              0.19868500530719757,
              0.7250810265541077,
              0.199863001704216,
              0.7250900268554688,
              0.20001399517059326,
              0.7260839939117432,
              0.2286040037870407,
              0.7261520028114319,
              0.23144599795341492,
              0.726069986820221,
              0.22853699326515198,
              0.7261520028114319,
              0.23144599795341492,
              0.7261379957199097,
              0.2313610017299652,
              0.726069986820221,
              0.22853699326515198,
              0.7246840000152588,
              0.19497300684452057,
              0.7246969938278198,
              0.1951570063829422,
              0.72461998462677,
              0.19490200281143188,
              0.7246969938278198,
              0.1951570063829422,
              0.7246339917182922,
              0.1950870007276535,
              0.72461998462677,
              0.19490200281143188,
              0.7246699929237366,
              0.18776999413967133,
              0.7247070074081421,
              0.1877090036869049,
              0.7247549891471863,
              0.18790900707244873,
              0.7247070074081421,
              0.1877090036869049,
              0.7247920036315918,
              0.18784800171852112,
              0.7247549891471863,
              0.18790900707244873,
              0.7247549891471863,
              0.18790900707244873,
              0.7247920036315918,
              0.18784800171852112,
              0.7248380184173584,
              0.1883150041103363,
              0.7247920036315918,
              0.18784800171852112,
              0.7248749732971191,
              0.1882539987564087,
              0.7248380184173584,
              0.1883150041103363,
              0.7248380184173584,
              0.1883150041103363,
              0.7248749732971191,
              0.1882539987564087,
              0.7249169945716858,
              0.1889750063419342,
              0.7248749732971191,
              0.1882539987564087,
              0.7249540090560913,
              0.18891499936580658,
              0.7249169945716858,
              0.1889750063419342,
              0.7249169945716858,
              0.1889750063419342,
              0.7249540090560913,
              0.18891499936580658,
              0.7249940037727356,
              0.18987399339675903,
              0.7249540090560913,
              0.18891499936580658,
              0.7250310182571411,
              0.18981599807739258,
              0.7249940037727356,
              0.18987399339675903,
              0.7249940037727356,
              0.18987399339675903,
              0.7250310182571411,
              0.18981599807739258,
              0.7250660061836243,
              0.19099999964237213,
              0.7250310182571411,
              0.18981599807739258,
              0.7251039743423462,
              0.19094400107860565,
              0.7250660061836243,
              0.19099999964237213,
              0.7250660061836243,
              0.19099999964237213,
              0.7251039743423462,
              0.19094400107860565,
              0.7251350283622742,
              0.1923390030860901,
              0.7251039743423462,
              0.19094400107860565,
              0.7251729965209961,
              0.19228500127792358,
              0.7251350283622742,
              0.1923390030860901,
              0.7251999974250793,
              0.1938759982585907,
              0.725238025188446,
              0.19382600486278534,
              0.7252609729766846,
              0.19559800624847412,
              0.725238025188446,
              0.19382600486278534,
              0.7252990007400513,
              0.19555099308490753,
              0.7252609729766846,
              0.19559800624847412,
              0.7254949808120728,
              0.2040649950504303,
              0.7255319952964783,
              0.20403599739074707,
              0.7255539894104004,
              0.20650799572467804,
              0.7255319952964783,
              0.20403599739074707,
              0.7255910038948059,
              0.20648400485515594,
              0.7255539894104004,
              0.20650799572467804,
              0.7256150245666504,
              0.20905299484729767,
              0.7256519794464111,
              0.2090349942445755,
              0.725678026676178,
              0.21168799698352814,
              0.7256519794464111,
              0.2090349942445755,
              0.7257149815559387,
              0.2116750031709671,
              0.725678026676178,
              0.21168799698352814,
              0.725678026676178,
              0.21168799698352814,
              0.7257149815559387,
              0.2116750031709671,
              0.7257429957389832,
              0.21439799666404724,
              0.7257149815559387,
              0.2116750031709671,
              0.7257800102233887,
              0.21439099311828613,
              0.7257429957389832,
              0.21439799666404724,
              0.7257429957389832,
              0.21439799666404724,
              0.7257800102233887,
              0.21439099311828613,
              0.7258099913597107,
              0.21717000007629395,
              0.7257800102233887,
              0.21439099311828613,
              0.7258470058441162,
              0.21716900169849396,
              0.7258099913597107,
              0.21717000007629395,
              0.7258099913597107,
              0.21717000007629395,
              0.7258470058441162,
              0.21716900169849396,
              0.7258780002593994,
              0.21999099850654602,
              0.7258470058441162,
              0.21716900169849396,
              0.7259150147438049,
              0.21999500691890717,
              0.7258780002593994,
              0.21999099850654602,
              0.7248200178146362,
              0.19568899273872375,
              0.7248560190200806,
              0.19575099647045135,
              0.7247589826583862,
              0.1953279972076416,
              0.7248560190200806,
              0.19575099647045135,
              0.7247959971427917,
              0.1953900009393692,
              0.7247589826583862,
              0.1953279972076416,
              0.7249330282211304,
              0.19694499671459198,
              0.7249699831008911,
              0.19700400531291962,
              0.7248780131340027,
              0.19623200595378876,
              0.7249699831008911,
              0.19700400531291962,
              0.724914014339447,
              0.1962919980287552,
              0.7248780131340027,
              0.19623200595378876,
              0.7249860167503357,
              0.19781999289989471,
              0.72502201795578,
              0.1978760063648224,
              0.7249330282211304,
              0.19694499671459198,
              0.72502201795578,
              0.1978760063648224,
              0.7249699831008911,
              0.19700400531291962,
              0.7249330282211304,
              0.19694499671459198,
              0.7258840203285217,
              0.22148799896240234,
              0.7259200215339661,
              0.22147700190544128,
              0.725816011428833,
              0.21938900649547577,
              0.7259200215339661,
              0.22147700190544128,
              0.7258520126342773,
              0.21938399970531464,
              0.725816011428833,
              0.21938900649547577,
              0.725816011428833,
              0.21938900649547577,
              0.7258520126342773,
              0.21938399970531464,
              0.7257469892501831,
              0.2173140048980713,
              0.7258520126342773,
              0.21938399970531464,
              0.7257829904556274,
              0.21731500327587128,
              0.7257469892501831,
              0.2173140048980713,
              0.7257469892501831,
              0.2173140048980713,
              0.7257829904556274,
              0.21731500327587128,
              0.7256770133972168,
              0.21527099609375,
              0.7257829904556274,
              0.21731500327587128,
              0.7257140278816223,
              0.2152779996395111,
              0.7256770133972168,
              0.21527099609375,
              0.7256770133972168,
              0.21527099609375,
              0.7257140278816223,
              0.2152779996395111,
              0.7256079912185669,
              0.21327200531959534,
              0.7257140278816223,
              0.2152779996395111,
              0.7256439924240112,
              0.21328499913215637,
              0.7256079912185669,
              0.21327200531959534,
              0.7254689931869507,
              0.20944300293922424,
              0.7255060076713562,
              0.20946699380874634,
              0.7254019975662231,
              0.20763200521469116,
              0.7255060076713562,
              0.20946699380874634,
              0.7254379987716675,
              0.2076610028743744,
              0.7254019975662231,
              0.20763200521469116,
              0.7253350019454956,
              0.2059040069580078,
              0.7253710031509399,
              0.20593799650669098,
              0.7252699732780457,
              0.20426900684833527,
              0.7253710031509399,
              0.20593799650669098,
              0.72530597448349,
              0.20430700480937958,
              0.7252699732780457,
              0.20426900684833527,
              0.7252699732780457,
              0.20426900684833527,
              0.72530597448349,
              0.20430700480937958,
              0.7252079844474792,
              0.20273500680923462,
              0.72530597448349,
              0.20430700480937958,
              0.7252439856529236,
              0.20277799665927887,
              0.7252079844474792,
              0.20273500680923462,
              0.7252079844474792,
              0.20273500680923462,
              0.7252439856529236,
              0.20277799665927887,
              0.7251480221748352,
              0.20131400227546692,
              0.7252439856529236,
              0.20277799665927887,
              0.7251830101013184,
              0.20136100053787231,
              0.7251480221748352,
              0.20131400227546692,
              0.7260509729385376,
              0.22569400072097778,
              0.7260149717330933,
              0.2257159948348999,
              0.7261009812355042,
              0.22777099907398224,
              0.7260149717330933,
              0.2257159948348999,
              0.7260649800300598,
              0.22779999673366547,
              0.7261009812355042,
              0.22777099907398224,
              0.7246699929237366,
              0.18776999413967133,
              0.7245839834213257,
              0.18776999413967133,
              0.7247070074081421,
              0.1877090036869049,
              0.7245839834213257,
              0.18776999413967133,
              0.7246209979057312,
              0.1877090036869049,
              0.7247070074081421,
              0.1877090036869049,
              0.725238025188446,
              0.19382600486278534,
              0.7251999974250793,
              0.1938759982585907,
              0.7251729965209961,
              0.19228500127792358,
              0.7251999974250793,
              0.1938759982585907,
              0.7251350283622742,
              0.1923390030860901,
              0.7251729965209961,
              0.19228500127792358,
              0.7253620028495789,
              0.1974489986896515,
              0.7253249883651733,
              0.19749200344085693,
              0.7252990007400513,
              0.19555099308490753,
              0.7253249883651733,
              0.19749200344085693,
              0.7252609729766846,
              0.19559800624847412,
              0.7252990007400513,
              0.19555099308490753,
              0.7254239916801453,
              0.1995050013065338,
              0.7253860235214233,
              0.1995439976453781,
              0.7253620028495789,
              0.1974489986896515,
              0.7253860235214233,
              0.1995439976453781,
              0.7253249883651733,
              0.19749200344085693,
              0.7253620028495789,
              0.1974489986896515,
              0.7254760265350342,
              0.2017049938440323,
              0.7254390120506287,
              0.20173899829387665,
              0.7254239916801453,
              0.1995050013065338,
              0.7254390120506287,
              0.20173899829387665,
              0.7253860235214233,
              0.1995439976453781,
              0.7254239916801453,
              0.1995050013065338,
              0.7255319952964783,
              0.20403599739074707,
              0.7254949808120728,
              0.2040649950504303,
              0.7254760265350342,
              0.2017049938440323,
              0.7254949808120728,
              0.2040649950504303,
              0.7254390120506287,
              0.20173899829387665,
              0.7254760265350342,
              0.2017049938440323,
              0.7256519794464111,
              0.2090349942445755,
              0.7256150245666504,
              0.20905299484729767,
              0.7255910038948059,
              0.20648400485515594,
              0.7256150245666504,
              0.20905299484729767,
              0.7255539894104004,
              0.20650799572467804,
              0.7255910038948059,
              0.20648400485515594,
              0.7259830236434937,
              0.22285600006580353,
              0.7259460091590881,
              0.22284600138664246,
              0.7259150147438049,
              0.21999500691890717,
              0.7259460091590881,
              0.22284600138664246,
              0.7258780002593994,
              0.21999099850654602,
              0.7259150147438049,
              0.21999500691890717,
              0.7260519862174988,
              0.22573800384998322,
              0.7260149717330933,
              0.22572100162506104,
              0.7259830236434937,
              0.22285600006580353,
              0.7260149717330933,
              0.22572100162506104,
              0.7259460091590881,
              0.22284600138664246,
              0.7259830236434937,
              0.22285600006580353,
              0.7261210083961487,
              0.22862599790096283,
              0.7260839939117432,
              0.2286040037870407,
              0.7260519862174988,
              0.22573800384998322,
              0.7260839939117432,
              0.2286040037870407,
              0.7260149717330933,
              0.22572100162506104,
              0.7260519862174988,
              0.22573800384998322,
              0.7247340083122253,
              0.1952189952135086,
              0.7246969938278198,
              0.1951570063829422,
              0.7247959971427917,
              0.1953900009393692,
              0.7246969938278198,
              0.1951570063829422,
              0.7247589826583862,
              0.1953279972076416,
              0.7247959971427917,
              0.1953900009393692,
              0.7248560190200806,
              0.19575099647045135,
              0.7248200178146362,
              0.19568899273872375,
              0.724914014339447,
              0.1962919980287552,
              0.7248200178146362,
              0.19568899273872375,
              0.7248780131340027,
              0.19623200595378876,
              0.724914014339447,
              0.1962919980287552,
              0.72502201795578,
              0.1978760063648224,
              0.7249860167503357,
              0.19781999289989471,
              0.7250720262527466,
              0.1988999992609024,
              0.7249860167503357,
              0.19781999289989471,
              0.7250369787216187,
              0.1988459974527359,
              0.7250720262527466,
              0.1988999992609024,
              0.7259870171546936,
              0.22358399629592896,
              0.7259500026702881,
              0.22360000014305115,
              0.7260509729385376,
              0.22569400072097778,
              0.7259500026702881,
              0.22360000014305115,
              0.7260149717330933,
              0.2257159948348999,
              0.7260509729385376,
              0.22569400072097778,
              0.7259200215339661,
              0.22147700190544128,
              0.7258840203285217,
              0.22148799896240234,
              0.7259870171546936,
              0.22358399629592896,
              0.7258840203285217,
              0.22148799896240234,
              0.7259500026702881,
              0.22360000014305115,
              0.7259870171546936,
              0.22358399629592896,
              0.7255740165710449,
              0.21134500205516815,
              0.7255380153656006,
              0.211326003074646,
              0.7256439924240112,
              0.21328499913215637,
              0.7255380153656006,
              0.211326003074646,
              0.7256079912185669,
              0.21327200531959534,
              0.7256439924240112,
              0.21328499913215637,
              0.7255060076713562,
              0.20946699380874634,
              0.7254689931869507,
              0.20944300293922424,
              0.7255740165710449,
              0.21134500205516815,
              0.7254689931869507,
              0.20944300293922424,
              0.7255380153656006,
              0.211326003074646,
              0.7255740165710449,
              0.21134500205516815,
              0.7253710031509399,
              0.20593799650669098,
              0.7253350019454956,
              0.2059040069580078,
              0.7254379987716675,
              0.2076610028743744,
              0.7253350019454956,
              0.2059040069580078,
              0.7254019975662231,
              0.20763200521469116,
              0.7254379987716675,
              0.2076610028743744,
              0.7251260280609131,
              0.2000650018453598,
              0.7250900268554688,
              0.20001399517059326,
              0.7251830101013184,
              0.20136100053787231,
              0.7250900268554688,
              0.20001399517059326,
              0.7251480221748352,
              0.20131400227546692,
              0.7251830101013184,
              0.20136100053787231,
              0.7250720262527466,
              0.1988999992609024,
              0.7250369787216187,
              0.1988459974527359,
              0.7251260280609131,
              0.2000650018453598,
              0.7250369787216187,
              0.1988459974527359,
              0.7250900268554688,
              0.20001399517059326,
              0.7251260280609131,
              0.2000650018453598,
              0.7261210083961487,
              0.22862599790096283,
              0.7261899709701538,
              0.23147399723529816,
              0.7260839939117432,
              0.2286040037870407,
              0.7261899709701538,
              0.23147399723529816,
              0.7261520028114319,
              0.23144599795341492,
              0.7260839939117432,
              0.2286040037870407,
              0.7246969938278198,
              0.1951570063829422,
              0.7247340083122253,
              0.1952189952135086,
              0.7246339917182922,
              0.1950870007276535,
              0.7247340083122253,
              0.1952189952135086,
              0.7246710062026978,
              0.19514800608158112,
              0.7246339917182922,
              0.1950870007276535,
              0.7246590256690979,
              0.18795299530029297,
              0.7246699929237366,
              0.18776999413967133,
              0.7247430086135864,
              0.18809199333190918,
              0.7246699929237366,
              0.18776999413967133,
              0.7247549891471863,
              0.18790900707244873,
              0.7247430086135864,
              0.18809199333190918,
              0.7247430086135864,
              0.18809199333190918,
              0.7247549891471863,
              0.18790900707244873,
              0.7248250246047974,
              0.18849700689315796,
              0.7247549891471863,
              0.18790900707244873,
              0.7248380184173584,
              0.1883150041103363,
              0.7248250246047974,
              0.18849700689315796,
              0.7248250246047974,
              0.18849700689315796,
              0.7248380184173584,
              0.1883150041103363,
              0.7249040007591248,
              0.1891549974679947,
              0.7248380184173584,
              0.1883150041103363,
              0.7249169945716858,
              0.1889750063419342,
              0.7249040007591248,
              0.1891549974679947,
              0.7249040007591248,
              0.1891549974679947,
              0.7249169945716858,
              0.1889750063419342,
              0.7249799966812134,
              0.19005000591278076,
              0.7249169945716858,
              0.1889750063419342,
              0.7249940037727356,
              0.18987399339675903,
              0.7249799966812134,
              0.19005000591278076,
              0.7249799966812134,
              0.19005000591278076,
              0.7249940037727356,
              0.18987399339675903,
              0.725051999092102,
              0.19116899371147156,
              0.7249940037727356,
              0.18987399339675903,
              0.7250660061836243,
              0.19099999964237213,
              0.725051999092102,
              0.19116899371147156,
              0.725051999092102,
              0.19116899371147156,
              0.7250660061836243,
              0.19099999964237213,
              0.725121021270752,
              0.19249999523162842,
              0.7250660061836243,
              0.19099999964237213,
              0.7251350283622742,
              0.1923390030860901,
              0.725121021270752,
              0.19249999523162842,
              0.7251859903335571,
              0.19402800500392914,
              0.7251999974250793,
              0.1938759982585907,
              0.725246012210846,
              0.1957390010356903,
              0.7251999974250793,
              0.1938759982585907,
              0.7252609729766846,
              0.19559800624847412,
              0.725246012210846,
              0.1957390010356903,
              0.7254800200462341,
              0.20415200293064117,
              0.7254949808120728,
              0.2040649950504303,
              0.7255390286445618,
              0.20657899975776672,
              0.7254949808120728,
              0.2040649950504303,
              0.7255539894104004,
              0.20650799572467804,
              0.7255390286445618,
              0.20657899975776672,
              0.7256010174751282,
              0.20910799503326416,
              0.7256150245666504,
              0.20905299484729767,
              0.7256640195846558,
              0.21172599494457245,
              0.7256150245666504,
              0.20905299484729767,
              0.725678026676178,
              0.21168799698352814,
              0.7256640195846558,
              0.21172599494457245,
              0.7256640195846558,
              0.21172599494457245,
              0.725678026676178,
              0.21168799698352814,
              0.7257289886474609,
              0.21441900730133057,
              0.725678026676178,
              0.21168799698352814,
              0.7257429957389832,
              0.21439799666404724,
              0.7257289886474609,
              0.21441900730133057,
              0.7257289886474609,
              0.21441900730133057,
              0.7257429957389832,
              0.21439799666404724,
              0.7257959842681885,
              0.2171739935874939,
              0.7257429957389832,
              0.21439799666404724,
              0.7258099913597107,
              0.21717000007629395,
              0.7257959842681885,
              0.2171739935874939,
              0.7257959842681885,
              0.2171739935874939,
              0.7258099913597107,
              0.21717000007629395,
              0.7258639931678772,
              0.219977006316185,
              0.7258099913597107,
              0.21717000007629395,
              0.7258780002593994,
              0.21999099850654602,
              0.7258639931678772,
              0.219977006316185,
              0.7248079776763916,
              0.1955060064792633,
              0.7248200178146362,
              0.19568899273872375,
              0.7247470021247864,
              0.19514399766921997,
              0.7248200178146362,
              0.19568899273872375,
              0.7247589826583862,
              0.1953279972076416,
              0.7247470021247864,
              0.19514399766921997,
              0.7249230146408081,
              0.19676999747753143,
              0.7249330282211304,
              0.19694499671459198,
              0.7248659729957581,
              0.19605199992656708,
              0.7249330282211304,
              0.19694499671459198,
              0.7248780131340027,
              0.19623200595378876,
              0.7248659729957581,
              0.19605199992656708,
              0.7249760031700134,
              0.1976509988307953,
              0.7249860167503357,
              0.19781999289989471,
              0.7249230146408081,
              0.19676999747753143,
              0.7249860167503357,
              0.19781999289989471,
              0.7249330282211304,
              0.19694499671459198,
              0.7249230146408081,
              0.19676999747753143,
              0.7258729934692383,
              0.22152000665664673,
              0.7258840203285217,
              0.22148799896240234,
              0.7258049845695496,
              0.2194029986858368,
              0.7258840203285217,
              0.22148799896240234,
              0.725816011428833,
              0.21938900649547577,
              0.7258049845695496,
              0.2194029986858368,
              0.7258049845695496,
              0.2194029986858368,
              0.725816011428833,
              0.21938900649547577,
              0.7257360219955444,
              0.21730999648571014,
              0.725816011428833,
              0.21938900649547577,
              0.7257469892501831,
              0.2173140048980713,
              0.7257360219955444,
              0.21730999648571014,
              0.7257360219955444,
              0.21730999648571014,
              0.7257469892501831,
              0.2173140048980713,
              0.7256669998168945,
              0.21525099873542786,
              0.7257469892501831,
              0.2173140048980713,
              0.7256770133972168,
              0.21527099609375,
              0.7256669998168945,
              0.21525099873542786,
              0.7256669998168945,
              0.21525099873542786,
              0.7256770133972168,
              0.21527099609375,
              0.7255970239639282,
              0.21323400735855103,
              0.7256770133972168,
              0.21527099609375,
              0.7256079912185669,
              0.21327200531959534,
              0.7255970239639282,
              0.21323400735855103,
              0.7254589796066284,
              0.20937199890613556,
              0.7254689931869507,
              0.20944300293922424,
              0.7253909707069397,
              0.20754599571228027,
              0.7254689931869507,
              0.20944300293922424,
              0.7254019975662231,
              0.20763200521469116,
              0.7253909707069397,
              0.20754599571228027,
              0.7253249883651733,
              0.2058030068874359,
              0.7253350019454956,
              0.2059040069580078,
              0.7252609729766846,
              0.20415300130844116,
              0.7253350019454956,
              0.2059040069580078,
              0.7252699732780457,
              0.20426900684833527,
              0.7252609729766846,
              0.20415300130844116,
              0.7252609729766846,
              0.20415300130844116,
              0.7252699732780457,
              0.20426900684833527,
              0.725197970867157,
              0.2026059925556183,
              0.7252699732780457,
              0.20426900684833527,
              0.7252079844474792,
              0.20273500680923462,
              0.725197970867157,
              0.2026059925556183,
              0.725197970867157,
              0.2026059925556183,
              0.7252079844474792,
              0.20273500680923462,
              0.7251380085945129,
              0.20117299258708954,
              0.7252079844474792,
              0.20273500680923462,
              0.7251480221748352,
              0.20131400227546692,
              0.7251380085945129,
              0.20117299258708954,
              0.7260040044784546,
              0.22578300535678864,
              0.7260550260543823,
              0.22788499295711517,
              0.7260149717330933,
              0.2257159948348999,
              0.7260550260543823,
              0.22788499295711517,
              0.7260649800300598,
              0.22779999673366547,
              0.7260149717330933,
              0.2257159948348999,
              0.7246699929237366,
              0.18776999413967133,
              0.7246590256690979,
              0.18795299530029297,
              0.7245839834213257,
              0.18776999413967133,
              0.7246590256690979,
              0.18795299530029297,
              0.724573016166687,
              0.18795299530029297,
              0.7245839834213257,
              0.18776999413967133,
              0.7246829867362976,
              0.19475799798965454,
              0.7246590256690979,
              0.188167005777359,
              0.7247470021247864,
              0.19492800533771515,
              0.7246590256690979,
              0.188167005777359,
              0.7247430086135864,
              0.1883080005645752,
              0.7247470021247864,
              0.19492800533771515,
              0.7247470021247864,
              0.19492800533771515,
              0.7247430086135864,
              0.1883080005645752,
              0.7248079776763916,
              0.19529099762439728,
              0.7247430086135864,
              0.1883080005645752,
              0.7248250246047974,
              0.1887120008468628,
              0.7248079776763916,
              0.19529099762439728,
              0.7248079776763916,
              0.19529099762439728,
              0.7248250246047974,
              0.1887120008468628,
              0.7248679995536804,
              0.1958400011062622,
              0.7248250246047974,
              0.1887120008468628,
              0.7249029874801636,
              0.18936499953269958,
              0.7248679995536804,
              0.1958400011062622,
              0.7248679995536804,
              0.1958400011062622,
              0.7249029874801636,
              0.18936499953269958,
              0.7249240279197693,
              0.19656400382518768,
              0.7249029874801636,
              0.18936499953269958,
              0.7249780297279358,
              0.19025500118732452,
              0.7249240279197693,
              0.19656400382518768,
              0.7249240279197693,
              0.19656400382518768,
              0.7249780297279358,
              0.19025500118732452,
              0.7249780297279358,
              0.19745300710201263,
              0.7249780297279358,
              0.19025500118732452,
              0.7250499725341797,
              0.1913670003414154,
              0.7249780297279358,
              0.19745300710201263,
              0.7249780297279358,
              0.19745300710201263,
              0.7250499725341797,
              0.1913670003414154,
              0.7250300049781799,
              0.1984959989786148,
              0.7250499725341797,
              0.1913670003414154,
              0.7251179814338684,
              0.1926880031824112,
              0.7250300049781799,
              0.1984959989786148,
              0.7250300049781799,
              0.1984959989786148,
              0.7251179814338684,
              0.1926880031824112,
              0.7250840067863464,
              0.1996850073337555,
              0.7251179814338684,
              0.1926880031824112,
              0.7251819968223572,
              0.19420500099658966,
              0.7250840067863464,
              0.1996850073337555,
              0.7250840067863464,
              0.1996850073337555,
              0.7251819968223572,
              0.19420500099658966,
              0.7251409888267517,
              0.20100699365139008,
              0.7251819968223572,
              0.19420500099658966,
              0.7252429723739624,
              0.19590400159358978,
              0.7251409888267517,
              0.20100699365139008,
              0.7251409888267517,
              0.20100699365139008,
              0.7252429723739624,
              0.19590400159358978,
              0.7252020239830017,
              0.20245499908924103,
              0.7252429723739624,
              0.19590400159358978,
              0.72530597448349,
              0.19777199625968933,
              0.7252020239830017,
              0.20245499908924103,
              0.7252020239830017,
              0.20245499908924103,
              0.72530597448349,
              0.19777199625968933,
              0.7252640128135681,
              0.20401699841022491,
              0.72530597448349,
              0.19777199625968933,
              0.7253680229187012,
              0.19979499280452728,
              0.7252640128135681,
              0.20401699841022491,
              0.7252640128135681,
              0.20401699841022491,
              0.7253680229187012,
              0.19979499280452728,
              0.7253280282020569,
              0.20568299293518066,
              0.7253680229187012,
              0.19979499280452728,
              0.7254210114479065,
              0.2019609957933426,
              0.7253280282020569,
              0.20568299293518066,
              0.7253280282020569,
              0.20568299293518066,
              0.7254210114479065,
              0.2019609957933426,
              0.7253940105438232,
              0.2074439972639084,
              0.7254210114479065,
              0.2019609957933426,
              0.7254779934883118,
              0.20425400137901306,
              0.7253940105438232,
              0.2074439972639084,
              0.7254610061645508,
              0.20928800106048584,
              0.7253940105438232,
              0.2074439972639084,
              0.7255370020866394,
              0.20666299760341644,
              0.7253940105438232,
              0.2074439972639084,
              0.7254779934883118,
              0.20425400137901306,
              0.7255370020866394,
              0.20666299760341644,
              0.7255300283432007,
              0.21120700240135193,
              0.7254610061645508,
              0.20928800106048584,
              0.7255989909172058,
              0.20917299389839172,
              0.7254610061645508,
              0.20928800106048584,
              0.7255370020866394,
              0.20666299760341644,
              0.7255989909172058,
              0.20917299389839172,
              0.7255989909172058,
              0.2131900042295456,
              0.7255300283432007,
              0.21120700240135193,
              0.7256619930267334,
              0.21177099645137787,
              0.7255300283432007,
              0.21120700240135193,
              0.7255989909172058,
              0.20917299389839172,
              0.7256619930267334,
              0.21177099645137787,
              0.7256680130958557,
              0.21522599458694458,
              0.7255989909172058,
              0.2131900042295456,
              0.7257279753684998,
              0.21444399654865265,
              0.7255989909172058,
              0.2131900042295456,
              0.7256619930267334,
              0.21177099645137787,
              0.7257279753684998,
              0.21444399654865265,
              0.725737988948822,
              0.2173060029745102,
              0.7256680130958557,
              0.21522599458694458,
              0.7257940173149109,
              0.21717800199985504,
              0.7256680130958557,
              0.21522599458694458,
              0.7257279753684998,
              0.21444399654865265,
              0.7257940173149109,
              0.21717800199985504,
              0.7258059978485107,
              0.21942000091075897,
              0.725737988948822,
              0.2173060029745102,
              0.7258620262145996,
              0.21996000409126282,
              0.725737988948822,
              0.2173060029745102,
              0.7257940173149109,
              0.21717800199985504,
              0.7258620262145996,
              0.21996000409126282,
              0.7258740067481995,
              0.22155700623989105,
              0.7258059978485107,
              0.21942000091075897,
              0.7259309887886047,
              0.22277599573135376,
              0.7258059978485107,
              0.21942000091075897,
              0.7258620262145996,
              0.21996000409126282,
              0.7259309887886047,
              0.22277599573135376,
              0.725941002368927,
              0.22370800375938416,
              0.7258740067481995,
              0.22155700623989105,
              0.7259989976882935,
              0.22561399638652802,
              0.7258740067481995,
              0.22155700623989105,
              0.7259309887886047,
              0.22277599573135376,
              0.7259989976882935,
              0.22561399638652802,
              0.7260059714317322,
              0.22586199641227722,
              0.725941002368927,
              0.22370800375938416,
              0.7260680198669434,
              0.2284580022096634,
              0.725941002368927,
              0.22370800375938416,
              0.7259989976882935,
              0.22561399638652802,
              0.7260680198669434,
              0.2284580022096634,
              0.7260680198669434,
              0.2284580022096634,
              0.7261360287666321,
              0.2312619984149933,
              0.7260059714317322,
              0.22586199641227722,
              0.7261360287666321,
              0.2312619984149933,
              0.7260569930076599,
              0.2279839962720871,
              0.7260059714317322,
              0.22586199641227722,
              0.7206680178642273,
              0.18816600739955902,
              0.7245749831199646,
              0.188167005777359,
              0.7206680178642273,
              0.1902800053358078,
              0.7245749831199646,
              0.188167005777359,
              0.7246189713478088,
              0.19468599557876587,
              0.7206680178642273,
              0.1902800053358078,
              0.7251179814338684,
              0.1926880031824112,
              0.725121021270752,
              0.19249999523162842,
              0.7251819968223572,
              0.19420500099658966,
              0.725121021270752,
              0.19249999523162842,
              0.7251859903335571,
              0.19402800500392914,
              0.7251819968223572,
              0.19420500099658966,
              0.7252429723739624,
              0.19590400159358978,
              0.725246012210846,
              0.1957390010356903,
              0.72530597448349,
              0.19777199625968933,
              0.725246012210846,
              0.1957390010356903,
              0.7253100275993347,
              0.19762100279331207,
              0.72530597448349,
              0.19777199625968933,
              0.72530597448349,
              0.19777199625968933,
              0.7253100275993347,
              0.19762100279331207,
              0.7253680229187012,
              0.19979499280452728,
              0.7253100275993347,
              0.19762100279331207,
              0.7253710031509399,
              0.19965900480747223,
              0.7253680229187012,
              0.19979499280452728,
              0.7253680229187012,
              0.19979499280452728,
              0.7253710031509399,
              0.19965900480747223,
              0.7254210114479065,
              0.2019609957933426,
              0.7253710031509399,
              0.19965900480747223,
              0.7254239916801453,
              0.20184099674224854,
              0.7254210114479065,
              0.2019609957933426,
              0.7254210114479065,
              0.2019609957933426,
              0.7254239916801453,
              0.20184099674224854,
              0.7254779934883118,
              0.20425400137901306,
              0.7254239916801453,
              0.20184099674224854,
              0.7254800200462341,
              0.20415200293064117,
              0.7254779934883118,
              0.20425400137901306,
              0.7255370020866394,
              0.20666299760341644,
              0.7255390286445618,
              0.20657899975776672,
              0.7255989909172058,
              0.20917299389839172,
              0.7255390286445618,
              0.20657899975776672,
              0.7256010174751282,
              0.20910799503326416,
              0.7255989909172058,
              0.20917299389839172,
              0.7258620262145996,
              0.21996000409126282,
              0.7258639931678772,
              0.219977006316185,
              0.7259309887886047,
              0.22277599573135376,
              0.7258639931678772,
              0.219977006316185,
              0.7259320020675659,
              0.22281399369239807,
              0.7259309887886047,
              0.22277599573135376,
              0.7259309887886047,
              0.22277599573135376,
              0.7259320020675659,
              0.22281399369239807,
              0.7259989976882935,
              0.22561399638652802,
              0.7259320020675659,
              0.22281399369239807,
              0.7260010242462158,
              0.22567200660705566,
              0.7259989976882935,
              0.22561399638652802,
              0.7259989976882935,
              0.22561399638652802,
              0.7260010242462158,
              0.22567200660705566,
              0.7260680198669434,
              0.2284580022096634,
              0.7260010242462158,
              0.22567200660705566,
              0.726069986820221,
              0.22853699326515198,
              0.7260680198669434,
              0.2284580022096634,
              0.7247470021247864,
              0.19492800533771515,
              0.7247470021247864,
              0.19514399766921997,
              0.7246829867362976,
              0.19475799798965454,
              0.7247470021247864,
              0.19514399766921997,
              0.7246840000152588,
              0.19497300684452057,
              0.7246829867362976,
              0.19475799798965454,
              0.7248679995536804,
              0.1958400011062622,
              0.7248659729957581,
              0.19605199992656708,
              0.7248079776763916,
              0.19529099762439728,
              0.7248659729957581,
              0.19605199992656708,
              0.7248079776763916,
              0.1955060064792633,
              0.7248079776763916,
              0.19529099762439728,
              0.7250300049781799,
              0.1984959989786148,
              0.7250270247459412,
              0.19868500530719757,
              0.7249780297279358,
              0.19745300710201263,
              0.7250270247459412,
              0.19868500530719757,
              0.7249760031700134,
              0.1976509988307953,
              0.7249780297279358,
              0.19745300710201263,
              0.7260059714317322,
              0.22586199641227722,
              0.7260040044784546,
              0.22578300535678864,
              0.725941002368927,
              0.22370800375938416,
              0.7260040044784546,
              0.22578300535678864,
              0.7259389758110046,
              0.2236499935388565,
              0.725941002368927,
              0.22370800375938416,
              0.725941002368927,
              0.22370800375938416,
              0.7259389758110046,
              0.2236499935388565,
              0.7258740067481995,
              0.22155700623989105,
              0.7259389758110046,
              0.2236499935388565,
              0.7258729934692383,
              0.22152000665664673,
              0.7258740067481995,
              0.22155700623989105,
              0.7255989909172058,
              0.2131900042295456,
              0.7255970239639282,
              0.21323400735855103,
              0.7255300283432007,
              0.21120700240135193,
              0.7255970239639282,
              0.21323400735855103,
              0.7255280017852783,
              0.2112710028886795,
              0.7255300283432007,
              0.21120700240135193,
              0.7255300283432007,
              0.21120700240135193,
              0.7255280017852783,
              0.2112710028886795,
              0.7254610061645508,
              0.20928800106048584,
              0.7255280017852783,
              0.2112710028886795,
              0.7254589796066284,
              0.20937199890613556,
              0.7254610061645508,
              0.20928800106048584,
              0.7253940105438232,
              0.2074439972639084,
              0.7253909707069397,
              0.20754599571228027,
              0.7253280282020569,
              0.20568299293518066,
              0.7253909707069397,
              0.20754599571228027,
              0.7253249883651733,
              0.2058030068874359,
              0.7253280282020569,
              0.20568299293518066,
              0.7251409888267517,
              0.20100699365139008,
              0.7251380085945129,
              0.20117299258708954,
              0.7250840067863464,
              0.1996850073337555,
              0.7251380085945129,
              0.20117299258708954,
              0.7250810265541077,
              0.199863001704216,
              0.7250840067863464,
              0.1996850073337555,
              0.7250840067863464,
              0.1996850073337555,
              0.7250810265541077,
              0.199863001704216,
              0.7250300049781799,
              0.1984959989786148,
              0.7250810265541077,
              0.199863001704216,
              0.7250270247459412,
              0.19868500530719757,
              0.7250300049781799,
              0.1984959989786148,
              0.726069986820221,
              0.22853699326515198,
              0.7261379957199097,
              0.2313610017299652,
              0.7260680198669434,
              0.2284580022096634,
              0.7261379957199097,
              0.2313610017299652,
              0.7261360287666321,
              0.2312619984149933,
              0.7260680198669434,
              0.2284580022096634,
              0.7246829867362976,
              0.19475799798965454,
              0.7246840000152588,
              0.19497300684452057,
              0.7246189713478088,
              0.19468599557876587,
              0.7246840000152588,
              0.19497300684452057,
              0.72461998462677,
              0.19490200281143188,
              0.7246189713478088,
              0.19468599557876587,
              0.7248349785804749,
              0.18784800171852112,
              0.7247920036315918,
              0.18784800171852112,
              0.7247499823570251,
              0.1877090036869049,
              0.7247920036315918,
              0.18784800171852112,
              0.7247070074081421,
              0.1877090036869049,
              0.7247499823570251,
              0.1877090036869049,
              0.724918007850647,
              0.1882539987564087,
              0.7248749732971191,
              0.1882539987564087,
              0.7248349785804749,
              0.18784800171852112,
              0.7248749732971191,
              0.1882539987564087,
              0.7247920036315918,
              0.18784800171852112,
              0.7248349785804749,
              0.18784800171852112,
              0.7249979972839355,
              0.18891499936580658,
              0.7249540090560913,
              0.18891499936580658,
              0.724918007850647,
              0.1882539987564087,
              0.7249540090560913,
              0.18891499936580658,
              0.7248749732971191,
              0.1882539987564087,
              0.724918007850647,
              0.1882539987564087,
              0.7250739932060242,
              0.18981599807739258,
              0.7250310182571411,
              0.18981599807739258,
              0.7249979972839355,
              0.18891499936580658,
              0.7250310182571411,
              0.18981599807739258,
              0.7249540090560913,
              0.18891499936580658,
              0.7249979972839355,
              0.18891499936580658,
              0.725147008895874,
              0.19094400107860565,
              0.7251039743423462,
              0.19094400107860565,
              0.7250739932060242,
              0.18981599807739258,
              0.7251039743423462,
              0.19094400107860565,
              0.7250310182571411,
              0.18981599807739258,
              0.7250739932060242,
              0.18981599807739258,
              0.7252159714698792,
              0.19228500127792358,
              0.7251729965209961,
              0.19228500127792358,
              0.725147008895874,
              0.19094400107860565,
              0.7251729965209961,
              0.19228500127792358,
              0.7251039743423462,
              0.19094400107860565,
              0.725147008895874,
              0.19094400107860565,
              0.7253419756889343,
              0.19555099308490753,
              0.7252990007400513,
              0.19555099308490753,
              0.7252810001373291,
              0.19382600486278534,
              0.7252990007400513,
              0.19555099308490753,
              0.725238025188446,
              0.19382600486278534,
              0.7252810001373291,
              0.19382600486278534,
              0.725633978843689,
              0.20648400485515594,
              0.7255910038948059,
              0.20648400485515594,
              0.7255749702453613,
              0.20403599739074707,
              0.7255910038948059,
              0.20648400485515594,
              0.7255319952964783,
              0.20403599739074707,
              0.7255749702453613,
              0.20403599739074707,
              0.7257580161094666,
              0.2116750031709671,
              0.7257149815559387,
              0.2116750031709671,
              0.725695013999939,
              0.2090349942445755,
              0.7257149815559387,
              0.2116750031709671,
              0.7256519794464111,
              0.2090349942445755,
              0.725695013999939,
              0.2090349942445755,
              0.7258239984512329,
              0.21439099311828613,
              0.7257800102233887,
              0.21439099311828613,
              0.7257580161094666,
              0.2116750031709671,
              0.7257800102233887,
              0.21439099311828613,
              0.7257149815559387,
              0.2116750031709671,
              0.7257580161094666,
              0.2116750031709671,
              0.7258899807929993,
              0.21716900169849396,
              0.7258470058441162,
              0.21716900169849396,
              0.7258239984512329,
              0.21439099311828613,
              0.7258470058441162,
              0.21716900169849396,
              0.7257800102233887,
              0.21439099311828613,
              0.7258239984512329,
              0.21439099311828613,
              0.725957989692688,
              0.21999500691890717,
              0.7259150147438049,
              0.21999500691890717,
              0.7258899807929993,
              0.21716900169849396,
              0.7259150147438049,
              0.21999500691890717,
              0.7258470058441162,
              0.21716900169849396,
              0.7258899807929993,
              0.21716900169849396,
              0.7248389720916748,
              0.1953900009393692,
              0.7247959971427917,
              0.1953900009393692,
              0.7248989939689636,
              0.19575099647045135,
              0.7247959971427917,
              0.1953900009393692,
              0.7248560190200806,
              0.19575099647045135,
              0.7248989939689636,
              0.19575099647045135,
              0.7249569892883301,
              0.1962919980287552,
              0.724914014339447,
              0.1962919980287552,
              0.725013017654419,
              0.19700400531291962,
              0.724914014339447,
              0.1962919980287552,
              0.7249699831008911,
              0.19700400531291962,
              0.725013017654419,
              0.19700400531291962,
              0.725013017654419,
              0.19700400531291962,
              0.7249699831008911,
              0.19700400531291962,
              0.7250649929046631,
              0.1978760063648224,
              0.7249699831008911,
              0.19700400531291962,
              0.72502201795578,
              0.1978760063648224,
              0.7250649929046631,
              0.1978760063648224,
              0.7258949875831604,
              0.21938399970531464,
              0.7258520126342773,
              0.21938399970531464,
              0.7259629964828491,
              0.22147700190544128,
              0.7258520126342773,
              0.21938399970531464,
              0.7259200215339661,
              0.22147700190544128,
              0.7259629964828491,
              0.22147700190544128,
              0.7258269786834717,
              0.21731500327587128,
              0.7257829904556274,
              0.21731500327587128,
              0.7258949875831604,
              0.21938399970531464,
              0.7257829904556274,
              0.21731500327587128,
              0.7258520126342773,
              0.21938399970531464,
              0.7258949875831604,
              0.21938399970531464,
              0.7257570028305054,
              0.2152779996395111,
              0.7257140278816223,
              0.2152779996395111,
              0.7258269786834717,
              0.21731500327587128,
              0.7257140278816223,
              0.2152779996395111,
              0.7257829904556274,
              0.21731500327587128,
              0.7258269786834717,
              0.21731500327587128,
              0.7256870269775391,
              0.21328499913215637,
              0.7256439924240112,
              0.21328499913215637,
              0.7257570028305054,
              0.2152779996395111,
              0.7256439924240112,
              0.21328499913215637,
              0.7257140278816223,
              0.2152779996395111,
              0.7257570028305054,
              0.2152779996395111,
              0.7254809737205505,
              0.2076610028743744,
              0.7254379987716675,
              0.2076610028743744,
              0.7255489826202393,
              0.20946699380874634,
              0.7254379987716675,
              0.2076610028743744,
              0.7255060076713562,
              0.20946699380874634,
              0.7255489826202393,
              0.20946699380874634,
              0.725350022315979,
              0.20430700480937958,
              0.72530597448349,
              0.20430700480937958,
              0.7254149913787842,
              0.20593799650669098,
              0.72530597448349,
              0.20430700480937958,
              0.7253710031509399,
              0.20593799650669098,
              0.7254149913787842,
              0.20593799650669098,
              0.7252870202064514,
              0.20277799665927887,
              0.7252439856529236,
              0.20277799665927887,
              0.725350022315979,
              0.20430700480937958,
              0.7252439856529236,
              0.20277799665927887,
              0.72530597448349,
              0.20430700480937958,
              0.725350022315979,
              0.20430700480937958,
              0.7252269983291626,
              0.20136100053787231,
              0.7251830101013184,
              0.20136100053787231,
              0.7252870202064514,
              0.20277799665927887,
              0.7251830101013184,
              0.20136100053787231,
              0.7252439856529236,
              0.20277799665927887,
              0.7252870202064514,
              0.20277799665927887,
              0.7260940074920654,
              0.22569400072097778,
              0.7260509729385376,
              0.22569400072097778,
              0.726144015789032,
              0.22777099907398224,
              0.7260509729385376,
              0.22569400072097778,
              0.7261009812355042,
              0.22777099907398224,
              0.726144015789032,
              0.22777099907398224,
              0.7247070074081421,
              0.1877090036869049,
              0.7246209979057312,
              0.1877090036869049,
              0.7247499823570251,
              0.1877090036869049,
              0.7246209979057312,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7247499823570251,
              0.1877090036869049,
              0.7252810001373291,
              0.19382600486278534,
              0.725238025188446,
              0.19382600486278534,
              0.7252159714698792,
              0.19228500127792358,
              0.725238025188446,
              0.19382600486278534,
              0.7251729965209961,
              0.19228500127792358,
              0.7252159714698792,
              0.19228500127792358,
              0.7254049777984619,
              0.1974489986896515,
              0.7253620028495789,
              0.1974489986896515,
              0.7253419756889343,
              0.19555099308490753,
              0.7253620028495789,
              0.1974489986896515,
              0.7252990007400513,
              0.19555099308490753,
              0.7253419756889343,
              0.19555099308490753,
              0.7254670262336731,
              0.1995050013065338,
              0.7254239916801453,
              0.1995050013065338,
              0.7254049777984619,
              0.1974489986896515,
              0.7254239916801453,
              0.1995050013065338,
              0.7253620028495789,
              0.1974489986896515,
              0.7254049777984619,
              0.1974489986896515,
              0.7255190014839172,
              0.2017049938440323,
              0.7254760265350342,
              0.2017049938440323,
              0.7254670262336731,
              0.1995050013065338,
              0.7254760265350342,
              0.2017049938440323,
              0.7254239916801453,
              0.1995050013065338,
              0.7254670262336731,
              0.1995050013065338,
              0.7255749702453613,
              0.20403599739074707,
              0.7255319952964783,
              0.20403599739074707,
              0.7255190014839172,
              0.2017049938440323,
              0.7255319952964783,
              0.20403599739074707,
              0.7254760265350342,
              0.2017049938440323,
              0.7255190014839172,
              0.2017049938440323,
              0.725695013999939,
              0.2090349942445755,
              0.7256519794464111,
              0.2090349942445755,
              0.725633978843689,
              0.20648400485515594,
              0.7256519794464111,
              0.2090349942445755,
              0.7255910038948059,
              0.20648400485515594,
              0.725633978843689,
              0.20648400485515594,
              0.7260259985923767,
              0.22285600006580353,
              0.7259830236434937,
              0.22285600006580353,
              0.725957989692688,
              0.21999500691890717,
              0.7259830236434937,
              0.22285600006580353,
              0.7259150147438049,
              0.21999500691890717,
              0.725957989692688,
              0.21999500691890717,
              0.7260950207710266,
              0.22573800384998322,
              0.7260519862174988,
              0.22573800384998322,
              0.7260259985923767,
              0.22285600006580353,
              0.7260519862174988,
              0.22573800384998322,
              0.7259830236434937,
              0.22285600006580353,
              0.7260259985923767,
              0.22285600006580353,
              0.7261639833450317,
              0.22862599790096283,
              0.7261210083961487,
              0.22862599790096283,
              0.7260950207710266,
              0.22573800384998322,
              0.7261210083961487,
              0.22862599790096283,
              0.7260519862174988,
              0.22573800384998322,
              0.7260950207710266,
              0.22573800384998322,
              0.7247769832611084,
              0.1952189952135086,
              0.7247340083122253,
              0.1952189952135086,
              0.7248389720916748,
              0.1953900009393692,
              0.7247340083122253,
              0.1952189952135086,
              0.7247959971427917,
              0.1953900009393692,
              0.7248389720916748,
              0.1953900009393692,
              0.7248989939689636,
              0.19575099647045135,
              0.7248560190200806,
              0.19575099647045135,
              0.7249569892883301,
              0.1962919980287552,
              0.7248560190200806,
              0.19575099647045135,
              0.724914014339447,
              0.1962919980287552,
              0.7249569892883301,
              0.1962919980287552,
              0.7250649929046631,
              0.1978760063648224,
              0.72502201795578,
              0.1978760063648224,
              0.7251160144805908,
              0.1988999992609024,
              0.72502201795578,
              0.1978760063648224,
              0.7250720262527466,
              0.1988999992609024,
              0.7251160144805908,
              0.1988999992609024,
              0.7260299921035767,
              0.22358399629592896,
              0.7259870171546936,
              0.22358399629592896,
              0.7260940074920654,
              0.22569400072097778,
              0.7259870171546936,
              0.22358399629592896,
              0.7260509729385376,
              0.22569400072097778,
              0.7260940074920654,
              0.22569400072097778,
              0.7259629964828491,
              0.22147700190544128,
              0.7259200215339661,
              0.22147700190544128,
              0.7260299921035767,
              0.22358399629592896,
              0.7259200215339661,
              0.22147700190544128,
              0.7259870171546936,
              0.22358399629592896,
              0.7260299921035767,
              0.22358399629592896,
              0.7256180047988892,
              0.21134500205516815,
              0.7255740165710449,
              0.21134500205516815,
              0.7256870269775391,
              0.21328499913215637,
              0.7255740165710449,
              0.21134500205516815,
              0.7256439924240112,
              0.21328499913215637,
              0.7256870269775391,
              0.21328499913215637,
              0.7255489826202393,
              0.20946699380874634,
              0.7255060076713562,
              0.20946699380874634,
              0.7256180047988892,
              0.21134500205516815,
              0.7255060076713562,
              0.20946699380874634,
              0.7255740165710449,
              0.21134500205516815,
              0.7256180047988892,
              0.21134500205516815,
              0.7254149913787842,
              0.20593799650669098,
              0.7253710031509399,
              0.20593799650669098,
              0.7254809737205505,
              0.2076610028743744,
              0.7253710031509399,
              0.20593799650669098,
              0.7254379987716675,
              0.2076610028743744,
              0.7254809737205505,
              0.2076610028743744,
              0.7251700162887573,
              0.2000650018453598,
              0.7251260280609131,
              0.2000650018453598,
              0.7252269983291626,
              0.20136100053787231,
              0.7251260280609131,
              0.2000650018453598,
              0.7251830101013184,
              0.20136100053787231,
              0.7252269983291626,
              0.20136100053787231,
              0.7251160144805908,
              0.1988999992609024,
              0.7250720262527466,
              0.1988999992609024,
              0.7251700162887573,
              0.2000650018453598,
              0.7250720262527466,
              0.1988999992609024,
              0.7251260280609131,
              0.2000650018453598,
              0.7251700162887573,
              0.2000650018453598,
              0.7315700054168701,
              0.45609501004219055,
              0.7315319776535034,
              0.4545019865036011,
              0.7316139936447144,
              0.45609501004219055,
              0.7315319776535034,
              0.4545019865036011,
              0.7315750122070312,
              0.4545019865036011,
              0.7316139936447144,
              0.45609501004219055,
              0.7207159996032715,
              0.19073599576950073,
              0.7246710062026978,
              0.19514800608158112,
              0.7207580208778381,
              0.19073499739170074,
              0.7246710062026978,
              0.19514800608158112,
              0.7247139811515808,
              0.19514800608158112,
              0.7207580208778381,
              0.19073499739170074,
              0.7246590256690979,
              0.188167005777359,
              0.7246590256690979,
              0.18795299530029297,
              0.7247430086135864,
              0.1883080005645752,
              0.7246590256690979,
              0.18795299530029297,
              0.7247430086135864,
              0.18809199333190918,
              0.7247430086135864,
              0.1883080005645752,
              0.7247430086135864,
              0.1883080005645752,
              0.7247430086135864,
              0.18809199333190918,
              0.7248250246047974,
              0.1887120008468628,
              0.7247430086135864,
              0.18809199333190918,
              0.7248250246047974,
              0.18849700689315796,
              0.7248250246047974,
              0.1887120008468628,
              0.7248250246047974,
              0.1887120008468628,
              0.7248250246047974,
              0.18849700689315796,
              0.7249029874801636,
              0.18936499953269958,
              0.7248250246047974,
              0.18849700689315796,
              0.7249040007591248,
              0.1891549974679947,
              0.7249029874801636,
              0.18936499953269958,
              0.7249029874801636,
              0.18936499953269958,
              0.7249040007591248,
              0.1891549974679947,
              0.7249780297279358,
              0.19025500118732452,
              0.7249040007591248,
              0.1891549974679947,
              0.7249799966812134,
              0.19005000591278076,
              0.7249780297279358,
              0.19025500118732452,
              0.7249780297279358,
              0.19025500118732452,
              0.7249799966812134,
              0.19005000591278076,
              0.7250499725341797,
              0.1913670003414154,
              0.7249799966812134,
              0.19005000591278076,
              0.725051999092102,
              0.19116899371147156,
              0.7250499725341797,
              0.1913670003414154,
              0.7250499725341797,
              0.1913670003414154,
              0.725051999092102,
              0.19116899371147156,
              0.7251179814338684,
              0.1926880031824112,
              0.725051999092102,
              0.19116899371147156,
              0.725121021270752,
              0.19249999523162842,
              0.7251179814338684,
              0.1926880031824112,
              0.7251819968223572,
              0.19420500099658966,
              0.7251859903335571,
              0.19402800500392914,
              0.7252429723739624,
              0.19590400159358978,
              0.7251859903335571,
              0.19402800500392914,
              0.725246012210846,
              0.1957390010356903,
              0.7252429723739624,
              0.19590400159358978,
              0.7254779934883118,
              0.20425400137901306,
              0.7254800200462341,
              0.20415200293064117,
              0.7255370020866394,
              0.20666299760341644,
              0.7254800200462341,
              0.20415200293064117,
              0.7255390286445618,
              0.20657899975776672,
              0.7255370020866394,
              0.20666299760341644,
              0.7255989909172058,
              0.20917299389839172,
              0.7256010174751282,
              0.20910799503326416,
              0.7256619930267334,
              0.21177099645137787,
              0.7256010174751282,
              0.20910799503326416,
              0.7256640195846558,
              0.21172599494457245,
              0.7256619930267334,
              0.21177099645137787,
              0.7256619930267334,
              0.21177099645137787,
              0.7256640195846558,
              0.21172599494457245,
              0.7257279753684998,
              0.21444399654865265,
              0.7256640195846558,
              0.21172599494457245,
              0.7257289886474609,
              0.21441900730133057,
              0.7257279753684998,
              0.21444399654865265,
              0.7257279753684998,
              0.21444399654865265,
              0.7257289886474609,
              0.21441900730133057,
              0.7257940173149109,
              0.21717800199985504,
              0.7257289886474609,
              0.21441900730133057,
              0.7257959842681885,
              0.2171739935874939,
              0.7257940173149109,
              0.21717800199985504,
              0.7257940173149109,
              0.21717800199985504,
              0.7257959842681885,
              0.2171739935874939,
              0.7258620262145996,
              0.21996000409126282,
              0.7257959842681885,
              0.2171739935874939,
              0.7258639931678772,
              0.219977006316185,
              0.7258620262145996,
              0.21996000409126282,
              0.7248079776763916,
              0.19529099762439728,
              0.7248079776763916,
              0.1955060064792633,
              0.7247470021247864,
              0.19492800533771515,
              0.7248079776763916,
              0.1955060064792633,
              0.7247470021247864,
              0.19514399766921997,
              0.7247470021247864,
              0.19492800533771515,
              0.7249240279197693,
              0.19656400382518768,
              0.7249230146408081,
              0.19676999747753143,
              0.7248679995536804,
              0.1958400011062622,
              0.7249230146408081,
              0.19676999747753143,
              0.7248659729957581,
              0.19605199992656708,
              0.7248679995536804,
              0.1958400011062622,
              0.7249780297279358,
              0.19745300710201263,
              0.7249760031700134,
              0.1976509988307953,
              0.7249240279197693,
              0.19656400382518768,
              0.7249760031700134,
              0.1976509988307953,
              0.7249230146408081,
              0.19676999747753143,
              0.7249240279197693,
              0.19656400382518768,
              0.7258740067481995,
              0.22155700623989105,
              0.7258729934692383,
              0.22152000665664673,
              0.7258059978485107,
              0.21942000091075897,
              0.7258729934692383,
              0.22152000665664673,
              0.7258049845695496,
              0.2194029986858368,
              0.7258059978485107,
              0.21942000091075897,
              0.7258059978485107,
              0.21942000091075897,
              0.7258049845695496,
              0.2194029986858368,
              0.725737988948822,
              0.2173060029745102,
              0.7258049845695496,
              0.2194029986858368,
              0.7257360219955444,
              0.21730999648571014,
              0.725737988948822,
              0.2173060029745102,
              0.725737988948822,
              0.2173060029745102,
              0.7257360219955444,
              0.21730999648571014,
              0.7256680130958557,
              0.21522599458694458,
              0.7257360219955444,
              0.21730999648571014,
              0.7256669998168945,
              0.21525099873542786,
              0.7256680130958557,
              0.21522599458694458,
              0.7256680130958557,
              0.21522599458694458,
              0.7256669998168945,
              0.21525099873542786,
              0.7255989909172058,
              0.2131900042295456,
              0.7256669998168945,
              0.21525099873542786,
              0.7255970239639282,
              0.21323400735855103,
              0.7255989909172058,
              0.2131900042295456,
              0.7254610061645508,
              0.20928800106048584,
              0.7254589796066284,
              0.20937199890613556,
              0.7253940105438232,
              0.2074439972639084,
              0.7254589796066284,
              0.20937199890613556,
              0.7253909707069397,
              0.20754599571228027,
              0.7253940105438232,
              0.2074439972639084,
              0.7253280282020569,
              0.20568299293518066,
              0.7253249883651733,
              0.2058030068874359,
              0.7252640128135681,
              0.20401699841022491,
              0.7253249883651733,
              0.2058030068874359,
              0.7252609729766846,
              0.20415300130844116,
              0.7252640128135681,
              0.20401699841022491,
              0.7252640128135681,
              0.20401699841022491,
              0.7252609729766846,
              0.20415300130844116,
              0.7252020239830017,
              0.20245499908924103,
              0.7252609729766846,
              0.20415300130844116,
              0.725197970867157,
              0.2026059925556183,
              0.7252020239830017,
              0.20245499908924103,
              0.7252020239830017,
              0.20245499908924103,
              0.725197970867157,
              0.2026059925556183,
              0.7251409888267517,
              0.20100699365139008,
              0.725197970867157,
              0.2026059925556183,
              0.7251380085945129,
              0.20117299258708954,
              0.7251409888267517,
              0.20100699365139008,
              0.7314800024032593,
              0.4543749988079071,
              0.7314789891242981,
              0.45432499051094055,
              0.7314519882202148,
              0.4532020092010498,
              0.7314789891242981,
              0.45432499051094055,
              0.7314509749412537,
              0.4531640112400055,
              0.7314519882202148,
              0.4532020092010498,
              0.7206680178642273,
              0.18816600739955902,
              0.7206680178642273,
              0.18795199692249298,
              0.7245749831199646,
              0.188167005777359,
              0.7206680178642273,
              0.18795199692249298,
              0.724573016166687,
              0.18795299530029297,
              0.7245749831199646,
              0.188167005777359,
              0.7415549755096436,
              0.19073499739170074,
              0.7207580208778381,
              0.19073499739170074,
              0.7415549755096436,
              0.19514800608158112,
              0.7207580208778381,
              0.19073499739170074,
              0.7247139811515808,
              0.19514800608158112,
              0.7415549755096436,
              0.19514800608158112,
              0.7247499823570251,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7248989939689636,
              0.19575099647045135,
              0.7415549755096436,
              0.19574999809265137,
              0.7248389720916748,
              0.1953900009393692,
              0.7415549755096436,
              0.19574999809265137,
              0.7415549755096436,
              0.1953890025615692,
              0.7248389720916748,
              0.1953900009393692,
              0.725013017654419,
              0.19700400531291962,
              0.7415549755096436,
              0.19700400531291962,
              0.7249569892883301,
              0.1962919980287552,
              0.7415549755096436,
              0.19700400531291962,
              0.7415549755096436,
              0.1962919980287552,
              0.7249569892883301,
              0.1962919980287552,
              0.7250649929046631,
              0.1978760063648224,
              0.7415549755096436,
              0.1978760063648224,
              0.725013017654419,
              0.19700400531291962,
              0.7415549755096436,
              0.1978760063648224,
              0.7415549755096436,
              0.19700400531291962,
              0.725013017654419,
              0.19700400531291962,
              0.7259629964828491,
              0.22147700190544128,
              0.7415549755096436,
              0.22147700190544128,
              0.7258949875831604,
              0.21938399970531464,
              0.7415549755096436,
              0.22147700190544128,
              0.7415549755096436,
              0.21938399970531464,
              0.7258949875831604,
              0.21938399970531464,
              0.7258949875831604,
              0.21938399970531464,
              0.7415549755096436,
              0.21938399970531464,
              0.7258269786834717,
              0.21731500327587128,
              0.7415549755096436,
              0.21938399970531464,
              0.7415549755096436,
              0.21731500327587128,
              0.7258269786834717,
              0.21731500327587128,
              0.7258269786834717,
              0.21731500327587128,
              0.7415549755096436,
              0.21731500327587128,
              0.7257570028305054,
              0.2152779996395111,
              0.7415549755096436,
              0.21731500327587128,
              0.7415549755096436,
              0.2152779996395111,
              0.7257570028305054,
              0.2152779996395111,
              0.7257570028305054,
              0.2152779996395111,
              0.7415549755096436,
              0.2152779996395111,
              0.7256870269775391,
              0.21328499913215637,
              0.7415549755096436,
              0.2152779996395111,
              0.7415549755096436,
              0.21328499913215637,
              0.7256870269775391,
              0.21328499913215637,
              0.7255489826202393,
              0.20946699380874634,
              0.7415549755096436,
              0.20946699380874634,
              0.7254809737205505,
              0.2076610028743744,
              0.7415549755096436,
              0.20946699380874634,
              0.7415549755096436,
              0.2076610028743744,
              0.7254809737205505,
              0.2076610028743744,
              0.7254149913787842,
              0.20593799650669098,
              0.7415549755096436,
              0.20593799650669098,
              0.725350022315979,
              0.20430700480937958,
              0.7415549755096436,
              0.20593799650669098,
              0.7415549755096436,
              0.20430700480937958,
              0.725350022315979,
              0.20430700480937958,
              0.725350022315979,
              0.20430700480937958,
              0.7415549755096436,
              0.20430700480937958,
              0.7252870202064514,
              0.20277799665927887,
              0.7415549755096436,
              0.20430700480937958,
              0.7415549755096436,
              0.20277799665927887,
              0.7252870202064514,
              0.20277799665927887,
              0.7252870202064514,
              0.20277799665927887,
              0.7415549755096436,
              0.20277799665927887,
              0.7252269983291626,
              0.20136100053787231,
              0.7415549755096436,
              0.20277799665927887,
              0.7415549755096436,
              0.20136100053787231,
              0.7252269983291626,
              0.20136100053787231,
              0.7248389720916748,
              0.1953900009393692,
              0.7415549755096436,
              0.1953890025615692,
              0.7247769832611084,
              0.1952189952135086,
              0.7415549755096436,
              0.1953890025615692,
              0.7415549755096436,
              0.1952189952135086,
              0.7247769832611084,
              0.1952189952135086,
              0.7249569892883301,
              0.1962919980287552,
              0.7415549755096436,
              0.1962919980287552,
              0.7248989939689636,
              0.19575099647045135,
              0.7415549755096436,
              0.1962919980287552,
              0.7415549755096436,
              0.19574999809265137,
              0.7248989939689636,
              0.19575099647045135,
              0.7251160144805908,
              0.1988999992609024,
              0.7415549755096436,
              0.1988999992609024,
              0.7250649929046631,
              0.1978760063648224,
              0.7415549755096436,
              0.1988999992609024,
              0.7415549755096436,
              0.1978760063648224,
              0.7250649929046631,
              0.1978760063648224,
              0.7260940074920654,
              0.22569400072097778,
              0.7415549755096436,
              0.22569400072097778,
              0.7260299921035767,
              0.22358399629592896,
              0.7415549755096436,
              0.22569400072097778,
              0.7415549755096436,
              0.22358399629592896,
              0.7260299921035767,
              0.22358399629592896,
              0.7260299921035767,
              0.22358399629592896,
              0.7415549755096436,
              0.22358399629592896,
              0.7259629964828491,
              0.22147700190544128,
              0.7415549755096436,
              0.22358399629592896,
              0.7415549755096436,
              0.22147700190544128,
              0.7259629964828491,
              0.22147700190544128,
              0.7256870269775391,
              0.21328499913215637,
              0.7415549755096436,
              0.21328499913215637,
              0.7256180047988892,
              0.21134500205516815,
              0.7415549755096436,
              0.21328499913215637,
              0.7415549755096436,
              0.21134500205516815,
              0.7256180047988892,
              0.21134500205516815,
              0.7256180047988892,
              0.21134500205516815,
              0.7415549755096436,
              0.21134500205516815,
              0.7255489826202393,
              0.20946699380874634,
              0.7415549755096436,
              0.21134500205516815,
              0.7415549755096436,
              0.20946699380874634,
              0.7255489826202393,
              0.20946699380874634,
              0.7254809737205505,
              0.2076610028743744,
              0.7415549755096436,
              0.2076610028743744,
              0.7254149913787842,
              0.20593799650669098,
              0.7415549755096436,
              0.2076610028743744,
              0.7415549755096436,
              0.20593799650669098,
              0.7254149913787842,
              0.20593799650669098,
              0.7252269983291626,
              0.20136100053787231,
              0.7415549755096436,
              0.20136100053787231,
              0.7251700162887573,
              0.2000650018453598,
              0.7415549755096436,
              0.20136100053787231,
              0.7415549755096436,
              0.2000650018453598,
              0.7251700162887573,
              0.2000650018453598,
              0.7251700162887573,
              0.2000650018453598,
              0.7415549755096436,
              0.2000650018453598,
              0.7251160144805908,
              0.1988999992609024,
              0.7415549755096436,
              0.2000650018453598,
              0.7415549755096436,
              0.1988999992609024,
              0.7251160144805908,
              0.1988999992609024,
              0.7260940074920654,
              0.22569400072097778,
              0.726144015789032,
              0.22777099907398224,
              0.7415549755096436,
              0.22569400072097778,
              0.726144015789032,
              0.22777099907398224,
              0.7415549755096436,
              0.22777099907398224,
              0.7415549755096436,
              0.22569400072097778,
              0.7248349785804749,
              0.18784800171852112,
              0.7247499823570251,
              0.1877090036869049,
              0.7415549755096436,
              0.18784800171852112,
              0.7247499823570251,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.18784800171852112,
              0.7248349785804749,
              0.18784800171852112,
              0.7415549755096436,
              0.18784800171852112,
              0.724918007850647,
              0.1882539987564087,
              0.7415549755096436,
              0.18784800171852112,
              0.7415549755096436,
              0.1882539987564087,
              0.724918007850647,
              0.1882539987564087,
              0.724918007850647,
              0.1882539987564087,
              0.7415549755096436,
              0.1882539987564087,
              0.7249979972839355,
              0.18891499936580658,
              0.7415549755096436,
              0.1882539987564087,
              0.7415549755096436,
              0.18891499936580658,
              0.7249979972839355,
              0.18891499936580658,
              0.7249979972839355,
              0.18891499936580658,
              0.7415549755096436,
              0.18891499936580658,
              0.7250739932060242,
              0.18981599807739258,
              0.7415549755096436,
              0.18891499936580658,
              0.7415549755096436,
              0.18981599807739258,
              0.7250739932060242,
              0.18981599807739258,
              0.7250739932060242,
              0.18981599807739258,
              0.7415549755096436,
              0.18981599807739258,
              0.725147008895874,
              0.19094400107860565,
              0.7415549755096436,
              0.18981599807739258,
              0.7415549755096436,
              0.19094400107860565,
              0.725147008895874,
              0.19094400107860565,
              0.725147008895874,
              0.19094400107860565,
              0.7415549755096436,
              0.19094400107860565,
              0.7252159714698792,
              0.19228500127792358,
              0.7415549755096436,
              0.19094400107860565,
              0.7415549755096436,
              0.19228500127792358,
              0.7252159714698792,
              0.19228500127792358,
              0.7252810001373291,
              0.19382600486278534,
              0.7415549755096436,
              0.19382600486278534,
              0.7253419756889343,
              0.19555099308490753,
              0.7415549755096436,
              0.19382600486278534,
              0.7415549755096436,
              0.19555099308490753,
              0.7253419756889343,
              0.19555099308490753,
              0.7255749702453613,
              0.20403599739074707,
              0.7415549755096436,
              0.20403599739074707,
              0.725633978843689,
              0.20648400485515594,
              0.7415549755096436,
              0.20403599739074707,
              0.7415549755096436,
              0.20648400485515594,
              0.725633978843689,
              0.20648400485515594,
              0.725695013999939,
              0.2090349942445755,
              0.7415549755096436,
              0.2090349942445755,
              0.7257580161094666,
              0.2116750031709671,
              0.7415549755096436,
              0.2090349942445755,
              0.7415549755096436,
              0.2116750031709671,
              0.7257580161094666,
              0.2116750031709671,
              0.7257580161094666,
              0.2116750031709671,
              0.7415549755096436,
              0.2116750031709671,
              0.7258239984512329,
              0.21439099311828613,
              0.7415549755096436,
              0.2116750031709671,
              0.7415549755096436,
              0.21439099311828613,
              0.7258239984512329,
              0.21439099311828613,
              0.7258239984512329,
              0.21439099311828613,
              0.7415549755096436,
              0.21439099311828613,
              0.7258899807929993,
              0.21716900169849396,
              0.7415549755096436,
              0.21439099311828613,
              0.7415549755096436,
              0.21716900169849396,
              0.7258899807929993,
              0.21716900169849396,
              0.7258899807929993,
              0.21716900169849396,
              0.7415549755096436,
              0.21716900169849396,
              0.725957989692688,
              0.21999500691890717,
              0.7415549755096436,
              0.21716900169849396,
              0.7415549755096436,
              0.21999500691890717,
              0.725957989692688,
              0.21999500691890717,
              0.7252159714698792,
              0.19228500127792358,
              0.7415549755096436,
              0.19228500127792358,
              0.7252810001373291,
              0.19382600486278534,
              0.7415549755096436,
              0.19228500127792358,
              0.7415549755096436,
              0.19382600486278534,
              0.7252810001373291,
              0.19382600486278534,
              0.7253419756889343,
              0.19555099308490753,
              0.7415549755096436,
              0.19555099308490753,
              0.7254049777984619,
              0.1974489986896515,
              0.7415549755096436,
              0.19555099308490753,
              0.7415549755096436,
              0.1974489986896515,
              0.7254049777984619,
              0.1974489986896515,
              0.7254049777984619,
              0.1974489986896515,
              0.7415549755096436,
              0.1974489986896515,
              0.7254670262336731,
              0.1995050013065338,
              0.7415549755096436,
              0.1974489986896515,
              0.7415549755096436,
              0.1995050013065338,
              0.7254670262336731,
              0.1995050013065338,
              0.7254670262336731,
              0.1995050013065338,
              0.7415549755096436,
              0.1995050013065338,
              0.7255190014839172,
              0.2017049938440323,
              0.7415549755096436,
              0.1995050013065338,
              0.7415549755096436,
              0.2017049938440323,
              0.7255190014839172,
              0.2017049938440323,
              0.7255190014839172,
              0.2017049938440323,
              0.7415549755096436,
              0.2017049938440323,
              0.7255749702453613,
              0.20403599739074707,
              0.7415549755096436,
              0.2017049938440323,
              0.7415549755096436,
              0.20403599739074707,
              0.7255749702453613,
              0.20403599739074707,
              0.725633978843689,
              0.20648400485515594,
              0.7415549755096436,
              0.20648400485515594,
              0.725695013999939,
              0.2090349942445755,
              0.7415549755096436,
              0.20648400485515594,
              0.7415549755096436,
              0.2090349942445755,
              0.725695013999939,
              0.2090349942445755,
              0.725957989692688,
              0.21999500691890717,
              0.7415549755096436,
              0.21999500691890717,
              0.7260259985923767,
              0.22285600006580353,
              0.7415549755096436,
              0.21999500691890717,
              0.7415549755096436,
              0.22285600006580353,
              0.7260259985923767,
              0.22285600006580353,
              0.7260259985923767,
              0.22285600006580353,
              0.7415549755096436,
              0.22285600006580353,
              0.7260950207710266,
              0.22573800384998322,
              0.7415549755096436,
              0.22285600006580353,
              0.7415549755096436,
              0.22573800384998322,
              0.7260950207710266,
              0.22573800384998322,
              0.7260950207710266,
              0.22573800384998322,
              0.7415549755096436,
              0.22573800384998322,
              0.7261639833450317,
              0.22862599790096283,
              0.7415549755096436,
              0.22573800384998322,
              0.7415549755096436,
              0.22862599790096283,
              0.7261639833450317,
              0.22862599790096283,
              0.7315750122070312,
              0.4545019865036011,
              0.7415549755096436,
              0.4545019865036011,
              0.7316139936447144,
              0.45609501004219055,
              0.7415549755096436,
              0.4545019865036011,
              0.7415549755096436,
              0.45609501004219055,
              0.7316139936447144,
              0.45609501004219055,
              0.7206799983978271,
              0.1906760036945343,
              0.7206680178642273,
              0.19049400091171265,
              0.7246339917182922,
              0.1950870007276535,
              0.7206680178642273,
              0.19049400091171265,
              0.72461998462677,
              0.19490200281143188,
              0.7246339917182922,
              0.1950870007276535,
              0.7206799983978271,
              0.18776999413967133,
              0.7207159996032715,
              0.1877090036869049,
              0.7245839834213257,
              0.18776999413967133,
              0.7207159996032715,
              0.1877090036869049,
              0.7246209979057312,
              0.1877090036869049,
              0.7245839834213257,
              0.18776999413967133,
              0.7207159996032715,
              0.19073599576950073,
              0.7206799983978271,
              0.1906760036945343,
              0.7246710062026978,
              0.19514800608158112,
              0.7206799983978271,
              0.1906760036945343,
              0.7246339917182922,
              0.1950870007276535,
              0.7246710062026978,
              0.19514800608158112,
              0.7206680178642273,
              0.18795199692249298,
              0.7206799983978271,
              0.18776999413967133,
              0.724573016166687,
              0.18795299530029297,
              0.7206799983978271,
              0.18776999413967133,
              0.7245839834213257,
              0.18776999413967133,
              0.724573016166687,
              0.18795299530029297,
              0.7246590256690979,
              0.188167005777359,
              0.7246829867362976,
              0.19475799798965454,
              0.7245749831199646,
              0.188167005777359,
              0.7246829867362976,
              0.19475799798965454,
              0.7246189713478088,
              0.19468599557876587,
              0.7245749831199646,
              0.188167005777359,
              0.7206680178642273,
              0.19049400091171265,
              0.7206680178642273,
              0.1902800053358078,
              0.72461998462677,
              0.19490200281143188,
              0.7206680178642273,
              0.1902800053358078,
              0.7246189713478088,
              0.19468599557876587,
              0.72461998462677,
              0.19490200281143188,
              0.7246209979057312,
              0.1877090036869049,
              0.7207159996032715,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7207159996032715,
              0.1877090036869049,
              0.7207580208778381,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7247340083122253,
              0.1952189952135086,
              0.7247769832611084,
              0.1952189952135086,
              0.7246710062026978,
              0.19514800608158112,
              0.7247769832611084,
              0.1952189952135086,
              0.7247139811515808,
              0.19514800608158112,
              0.7246710062026978,
              0.19514800608158112,
              0.7246590256690979,
              0.18795299530029297,
              0.7246590256690979,
              0.188167005777359,
              0.724573016166687,
              0.18795299530029297,
              0.7246590256690979,
              0.188167005777359,
              0.7245749831199646,
              0.188167005777359,
              0.724573016166687,
              0.18795299530029297,
              0.7247769832611084,
              0.1952189952135086,
              0.7415549755096436,
              0.1952189952135086,
              0.7247139811515808,
              0.19514800608158112,
              0.7415549755096436,
              0.1952189952135086,
              0.7415549755096436,
              0.19514800608158112,
              0.7247139811515808,
              0.19514800608158112,
              0.7207580208778381,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7261899709701538,
              0.23147399723529816,
              0.7261210083961487,
              0.22862599790096283,
              0.7262330055236816,
              0.23147399723529816,
              0.7261210083961487,
              0.22862599790096283,
              0.7261639833450317,
              0.22862599790096283,
              0.7262330055236816,
              0.23147399723529816,
              0.7260550260543823,
              0.22788499295711517,
              0.7260040044784546,
              0.22578300535678864,
              0.7260569930076599,
              0.2279839962720871,
              0.7260040044784546,
              0.22578300535678864,
              0.7260059714317322,
              0.22586199641227722,
              0.7260569930076599,
              0.2279839962720871,
              0.7261639833450317,
              0.22862599790096283,
              0.7415549755096436,
              0.22862599790096283,
              0.7262330055236816,
              0.23147399723529816,
              0.7415549755096436,
              0.22862599790096283,
              0.7415549755096436,
              0.23147399723529816,
              0.7262330055236816,
              0.23147399723529816,
              0.7315170168876648,
              0.45541200041770935,
              0.7315059900283813,
              0.4554649889469147,
              0.731544017791748,
              0.4565120041370392,
              0.7315059900283813,
              0.4554649889469147,
              0.7315329909324646,
              0.45657598972320557,
              0.731544017791748,
              0.4565120041370392,
              0.7316640019416809,
              0.46152499318122864,
              0.7316539883613586,
              0.4616360068321228,
              0.7316849827766418,
              0.46241798996925354,
              0.7316539883613586,
              0.4616360068321228,
              0.7316759824752808,
              0.4625380039215088,
              0.7316849827766418,
              0.46241798996925354,
              0.7316849827766418,
              0.46241798996925354,
              0.7316759824752808,
              0.4625380039215088,
              0.7317060232162476,
              0.46327200531959534,
              0.7316759824752808,
              0.4625380039215088,
              0.7316970229148865,
              0.4633989930152893,
              0.7317060232162476,
              0.46327200531959534,
              0.7317060232162476,
              0.46327200531959534,
              0.7316970229148865,
              0.4633989930152893,
              0.7317249774932861,
              0.4640839993953705,
              0.7316970229148865,
              0.4633989930152893,
              0.731715977191925,
              0.46421900391578674,
              0.7317249774932861,
              0.4640839993953705,
              0.7318300008773804,
              0.46848100423812866,
              0.7318220138549805,
              0.46865400671958923,
              0.7318410277366638,
              0.4689069986343384,
              0.7318220138549805,
              0.46865400671958923,
              0.7318329811096191,
              0.4690830111503601,
              0.7318410277366638,
              0.4689069986343384,
              0.7318410277366638,
              0.4689069986343384,
              0.7318329811096191,
              0.4690830111503601,
              0.7318500280380249,
              0.4692780077457428,
              0.7318329811096191,
              0.4690830111503601,
              0.7318419814109802,
              0.4694559872150421,
              0.7318500280380249,
              0.4692780077457428,
              0.7315710186958313,
              0.45765000581741333,
              0.7315580248832703,
              0.4575969874858856,
              0.7315340042114258,
              0.45608100295066833,
              0.7315580248832703,
              0.4575969874858856,
              0.7315199971199036,
              0.4560379981994629,
              0.7315340042114258,
              0.45608100295066833,
              0.7317109704017639,
              0.4634990096092224,
              0.7316970229148865,
              0.4634059965610504,
              0.7316780090332031,
              0.46210500597953796,
              0.7316970229148865,
              0.4634059965610504,
              0.7316640019416809,
              0.46202200651168823,
              0.7316780090332031,
              0.46210500597953796,
              0.7320200204849243,
              0.47639000415802,
              0.7320039868354797,
              0.4762139916419983,
              0.7320060133934021,
              0.47579801082611084,
              0.7320039868354797,
              0.4762139916419983,
              0.7319899797439575,
              0.47562599182128906,
              0.7320060133934021,
              0.47579801082611084,
              0.7320320010185242,
              0.4769040048122406,
              0.7320160269737244,
              0.4767259955406189,
              0.7320200204849243,
              0.47639000415802,
              0.7320160269737244,
              0.4767259955406189,
              0.7320039868354797,
              0.4762139916419983,
              0.7320200204849243,
              0.47639000415802,
              0.7320510149002075,
              0.4776949882507324,
              0.7320349812507629,
              0.47751399874687195,
              0.7320430278778076,
              0.4773400127887726,
              0.7320349812507629,
              0.47751399874687195,
              0.7320259809494019,
              0.4771600067615509,
              0.7320430278778076,
              0.4773400127887726,
              0.7314900159835815,
              0.4542819857597351,
              0.7314789891242981,
              0.45432499051094055,
              0.7315170168876648,
              0.45541200041770935,
              0.7314789891242981,
              0.45432499051094055,
              0.7315059900283813,
              0.4554649889469147,
              0.7315170168876648,
              0.45541200041770935,
              0.731544017791748,
              0.4565120041370392,
              0.7315329909324646,
              0.45657598972320557,
              0.7315689921379089,
              0.4575830101966858,
              0.7315329909324646,
              0.45657598972320557,
              0.7315589785575867,
              0.45765599608421326,
              0.7315689921379089,
              0.4575830101966858,
              0.7317609786987305,
              0.46557798981666565,
              0.7317519783973694,
              0.4657270014286041,
              0.731777012348175,
              0.4662570059299469,
              0.7317519783973694,
              0.4657270014286041,
              0.7317690253257751,
              0.4664109945297241,
              0.731777012348175,
              0.4662570059299469,
              0.7317919731140137,
              0.4668880105018616,
              0.7317839860916138,
              0.4670479893684387,
              0.7318059802055359,
              0.46747100353240967,
              0.7317839860916138,
              0.4670479893684387,
              0.731797993183136,
              0.46763500571250916,
              0.7318059802055359,
              0.46747100353240967,
              0.73191899061203,
              0.4721609950065613,
              0.7318689823150635,
              0.47007301449775696,
              0.7319110035896301,
              0.472339004278183,
              0.7318689823150635,
              0.47007301449775696,
              0.7318609952926636,
              0.4702570140361786,
              0.7319110035896301,
              0.472339004278183,
              0.7317429780960083,
              0.4648439884185791,
              0.7317289710044861,
              0.4647420048713684,
              0.7317109704017639,
              0.4634990096092224,
              0.7317289710044861,
              0.4647420048713684,
              0.7316970229148865,
              0.4634059965610504,
              0.7317109704017639,
              0.4634990096092224,
              0.7318040132522583,
              0.46737900376319885,
              0.7317889928817749,
              0.4672600030899048,
              0.7317739725112915,
              0.46613800525665283,
              0.7317889928817749,
              0.4672600030899048,
              0.7317600250244141,
              0.46602699160575867,
              0.7317739725112915,
              0.46613800525665283,
              0.7318329811096191,
              0.4685640037059784,
              0.7318170070648193,
              0.46843698620796204,
              0.7318040132522583,
              0.46737900376319885,
              0.7318170070648193,
              0.46843698620796204,
              0.7317889928817749,
              0.4672600030899048,
              0.7318040132522583,
              0.46737900376319885,
              0.7319319844245911,
              0.4727090001106262,
              0.7319160103797913,
              0.472555011510849,
              0.7319089770317078,
              0.47176700830459595,
              0.7319160103797913,
              0.472555011510849,
              0.731893002986908,
              0.47161799669265747,
              0.7319089770317078,
              0.47176700830459595,
              0.7319719791412354,
              0.4743939936161041,
              0.7319560050964355,
              0.47422999143600464,
              0.7319530248641968,
              0.47358599305152893,
              0.7319560050964355,
              0.47422999143600464,
              0.7319369912147522,
              0.4734260141849518,
              0.7319530248641968,
              0.47358599305152893,
              0.7239530086517334,
              0.18776999413967133,
              0.7239490151405334,
              0.18795199692249298,
              0.7243490219116211,
              0.18776999413967133,
              0.7239490151405334,
              0.18795199692249298,
              0.7243459820747375,
              0.18795199692249298,
              0.7243490219116211,
              0.18776999413967133,
              0.7235720157623291,
              0.18776999413967133,
              0.723567008972168,
              0.18795199692249298,
              0.7239530086517334,
              0.18776999413967133,
              0.723567008972168,
              0.18795199692249298,
              0.7239490151405334,
              0.18795199692249298,
              0.7239530086517334,
              0.18776999413967133,
              0.7228599786758423,
              0.18776999413967133,
              0.7228530049324036,
              0.18795199692249298,
              0.7232069969177246,
              0.18776999413967133,
              0.7228530049324036,
              0.18795199692249298,
              0.7232009768486023,
              0.18795199692249298,
              0.7232069969177246,
              0.18776999413967133,
              0.7205759882926941,
              0.18776999413967133,
              0.7205629944801331,
              0.18795199692249298,
              0.7205690145492554,
              0.18776999413967133,
              0.7205629944801331,
              0.18795199692249298,
              0.7205569744110107,
              0.18795199692249298,
              0.7205690145492554,
              0.18776999413967133,
              0.7247589826583862,
              0.18776999413967133,
              0.7247570157051086,
              0.18795199692249298,
              0.725180983543396,
              0.18776999413967133,
              0.7247570157051086,
              0.18795199692249298,
              0.7251799702644348,
              0.18795199692249298,
              0.725180983543396,
              0.18776999413967133,
              0.7216759920120239,
              0.18776999413967133,
              0.7216659784317017,
              0.18795199692249298,
              0.7219380140304565,
              0.18776999413967133,
              0.7216659784317017,
              0.18795199692249298,
              0.7219300270080566,
              0.18795199692249298,
              0.7219380140304565,
              0.18776999413967133,
              0.7210400104522705,
              0.18776999413967133,
              0.7210289835929871,
              0.18795199692249298,
              0.7212250232696533,
              0.18776999413967133,
              0.7210289835929871,
              0.18795199692249298,
              0.7212139964103699,
              0.18795199692249298,
              0.7212250232696533,
              0.18776999413967133,
              0.7208830118179321,
              0.18776999413967133,
              0.7208719849586487,
              0.18795199692249298,
              0.7210400104522705,
              0.18776999413967133,
              0.7208719849586487,
              0.18795199692249298,
              0.7210289835929871,
              0.18795199692249298,
              0.7210400104522705,
              0.18776999413967133,
              0.7205690145492554,
              0.18776999413967133,
              0.7205569744110107,
              0.18795199692249298,
              0.7205979824066162,
              0.18776999413967133,
              0.7205569744110107,
              0.18795199692249298,
              0.7205860018730164,
              0.18795199692249298,
              0.7205979824066162,
              0.18776999413967133,
              0.7206799983978271,
              0.18776999413967133,
              0.7206680178642273,
              0.18795199692249298,
              0.7206190228462219,
              0.18776999413967133,
              0.7206680178642273,
              0.18795199692249298,
              0.7206069827079773,
              0.18795199692249298,
              0.7206190228462219,
              0.18776999413967133,
              0.7256150245666504,
              0.18776999413967133,
              0.7256140112876892,
              0.18795199692249298,
              0.72604900598526,
              0.18776999413967133,
              0.7256140112876892,
              0.18795199692249298,
              0.72604900598526,
              0.18795199692249298,
              0.72604900598526,
              0.18776999413967133,
              0.7256150245666504,
              0.18969100713729858,
              0.7256140112876892,
              0.18950699269771576,
              0.725180983543396,
              0.18969400227069855,
              0.7256140112876892,
              0.18950699269771576,
              0.7251799702644348,
              0.18951000273227692,
              0.725180983543396,
              0.18969400227069855,
              0.725180983543396,
              0.18969400227069855,
              0.7251799702644348,
              0.18951000273227692,
              0.7247589826583862,
              0.18970300257205963,
              0.7251799702644348,
              0.18951000273227692,
              0.7247570157051086,
              0.189519003033638,
              0.7247589826583862,
              0.18970300257205963,
              0.7247589826583862,
              0.18970300257205963,
              0.7247570157051086,
              0.189519003033638,
              0.7243490219116211,
              0.18971699476242065,
              0.7247570157051086,
              0.189519003033638,
              0.7243459820747375,
              0.18953299522399902,
              0.7243490219116211,
              0.18971699476242065,
              0.7235720157623291,
              0.1897599995136261,
              0.723567008972168,
              0.18957599997520447,
              0.7232069969177246,
              0.18978899717330933,
              0.723567008972168,
              0.18957599997520447,
              0.7232009768486023,
              0.1896049976348877,
              0.7232069969177246,
              0.18978899717330933,
              0.7232069969177246,
              0.18978899717330933,
              0.7232009768486023,
              0.1896049976348877,
              0.7228599786758423,
              0.1898220032453537,
              0.7232009768486023,
              0.1896049976348877,
              0.7228530049324036,
              0.18963800370693207,
              0.7228599786758423,
              0.1898220032453537,
              0.7228599786758423,
              0.1898220032453537,
              0.7228530049324036,
              0.18963800370693207,
              0.7225319743156433,
              0.18985900282859802,
              0.7228530049324036,
              0.18963800370693207,
              0.7225239872932434,
              0.1896750032901764,
              0.7225319743156433,
              0.18985900282859802,
              0.7222239971160889,
              0.1898999959230423,
              0.722216010093689,
              0.18971599638462067,
              0.7219380140304565,
              0.18994499742984772,
              0.722216010093689,
              0.18971599638462067,
              0.7219300270080566,
              0.1897609978914261,
              0.7219380140304565,
              0.18994499742984772,
              0.7216759920120239,
              0.1899929940700531,
              0.7216659784317017,
              0.18980999290943146,
              0.7214369773864746,
              0.19004400074481964,
              0.7216659784317017,
              0.18980999290943146,
              0.7214270234107971,
              0.189860999584198,
              0.7214369773864746,
              0.19004400074481964,
              0.7208830118179321,
              0.19021500647068024,
              0.7208719849586487,
              0.1900320053100586,
              0.7207559943199158,
              0.19027699530124664,
              0.7208719849586487,
              0.1900320053100586,
              0.7207450270652771,
              0.190093994140625,
              0.7207559943199158,
              0.19027699530124664,
              0.7206609845161438,
              0.19033999741077423,
              0.720649003982544,
              0.1901569962501526,
              0.7205979824066162,
              0.1904049962759018,
              0.720649003982544,
              0.1901569962501526,
              0.7205860018730164,
              0.19022299349308014,
              0.7205979824066162,
              0.1904049962759018,
              0.7205690145492554,
              0.19047200679779053,
              0.7205569744110107,
              0.19029000401496887,
              0.7205759882926941,
              0.19054000079631805,
              0.7205569744110107,
              0.19029000401496887,
              0.7205629944801331,
              0.1903569996356964,
              0.7205759882926941,
              0.19054000079631805,
              0.7205759882926941,
              0.19054000079631805,
              0.7205629944801331,
              0.1903569996356964,
              0.7206190228462219,
              0.19060799479484558,
              0.7205629944801331,
              0.1903569996356964,
              0.7206069827079773,
              0.19042600691318512,
              0.7206190228462219,
              0.19060799479484558,
              0.7315940260887146,
              0.45862001180648804,
              0.7316300272941589,
              0.4585919976234436,
              0.7315689921379089,
              0.4575830101966858,
              0.7316300272941589,
              0.4585919976234436,
              0.7316060066223145,
              0.4575580060482025,
              0.7315689921379089,
              0.4575830101966858,
              0.7316179871559143,
              0.4596239924430847,
              0.7316539883613586,
              0.4595929980278015,
              0.7315940260887146,
              0.45862001180648804,
              0.7316539883613586,
              0.4595929980278015,
              0.7316300272941589,
              0.4585919976234436,
              0.7315940260887146,
              0.45862001180648804,
              0.7316640019416809,
              0.46152499318122864,
              0.7317000031471252,
              0.4614880084991455,
              0.7316420078277588,
              0.46059298515319824,
              0.7317000031471252,
              0.4614880084991455,
              0.7316769957542419,
              0.46055901050567627,
              0.7316420078277588,
              0.46059298515319824,
              0.7317609786987305,
              0.46557798981666565,
              0.7317960262298584,
              0.4655289947986603,
              0.7317439913749695,
              0.46485400199890137,
              0.7317960262298584,
              0.4655289947986603,
              0.7317789793014526,
              0.4648059904575348,
              0.7317439913749695,
              0.46485400199890137,
              0.7317919731140137,
              0.4668880105018616,
              0.731827974319458,
              0.46683499217033386,
              0.731777012348175,
              0.4662570059299469,
              0.731827974319458,
              0.46683499217033386,
              0.7318120002746582,
              0.46620601415634155,
              0.731777012348175,
              0.4662570059299469,
              0.731607973575592,
              0.45917800068855286,
              0.7316449880599976,
              0.459199994802475,
              0.73164302110672,
              0.4606640040874481,
              0.7316449880599976,
              0.459199994802475,
              0.7316809892654419,
              0.4606890082359314,
              0.73164302110672,
              0.4606640040874481,
              0.7317429780960083,
              0.4648439884185791,
              0.731781005859375,
              0.46487900614738464,
              0.7317739725112915,
              0.46613800525665283,
              0.731781005859375,
              0.46487900614738464,
              0.7318120002746582,
              0.46617498993873596,
              0.7317739725112915,
              0.46613800525665283,
              0.7318329811096191,
              0.4685640037059784,
              0.7318699955940247,
              0.46860700845718384,
              0.7318599820137024,
              0.4696919918060303,
              0.7318699955940247,
              0.46860700845718384,
              0.7318969964981079,
              0.4697369933128357,
              0.7318599820137024,
              0.4696919918060303,
              0.7321029901504517,
              0.4798569977283478,
              0.7320590019226074,
              0.4780080020427704,
              0.7321410179138184,
              0.4799160063266754,
              0.7320590019226074,
              0.4780080020427704,
              0.7320970296859741,
              0.478069007396698,
              0.7321410179138184,
              0.4799160063266754,
              0.7316420078277588,
              0.46059298515319824,
              0.7316769957542419,
              0.46055901050567627,
              0.7316179871559143,
              0.4596239924430847,
              0.7316769957542419,
              0.46055901050567627,
              0.7316539883613586,
              0.4595929980278015,
              0.7316179871559143,
              0.4596239924430847,
              0.7317439913749695,
              0.46485400199890137,
              0.7317789793014526,
              0.4648059904575348,
              0.7317249774932861,
              0.4640839993953705,
              0.7317789793014526,
              0.4648059904575348,
              0.7317609786987305,
              0.46404001116752625,
              0.7317249774932861,
              0.4640839993953705,
              0.7318189740180969,
              0.46800199151039124,
              0.7318540215492249,
              0.46794599294662476,
              0.7318059802055359,
              0.46747100353240967,
              0.7318540215492249,
              0.46794599294662476,
              0.7318410277366638,
              0.467415988445282,
              0.7318059802055359,
              0.46747100353240967,
              0.7318300008773804,
              0.46848100423812866,
              0.7318660020828247,
              0.468423992395401,
              0.7318189740180969,
              0.46800199151039124,
              0.7318660020828247,
              0.468423992395401,
              0.7318540215492249,
              0.46794599294662476,
              0.7318189740180969,
              0.46800199151039124,
              0.7318570017814636,
              0.4695909917354584,
              0.7318919897079468,
              0.46953099966049194,
              0.7318500280380249,
              0.4692780077457428,
              0.7318919897079468,
              0.46953099966049194,
              0.7318850159645081,
              0.469217985868454,
              0.7318500280380249,
              0.4692780077457428,
              0.7318630218505859,
              0.4698469936847687,
              0.7318980097770691,
              0.46978598833084106,
              0.7318570017814636,
              0.4695909917354584,
              0.7318980097770691,
              0.46978598833084106,
              0.7318919897079468,
              0.46953099966049194,
              0.7318570017814636,
              0.4695909917354584,
              0.7315710186958313,
              0.45765000581741333,
              0.731607973575592,
              0.4576680064201355,
              0.731607973575592,
              0.45917800068855286,
              0.731607973575592,
              0.4576680064201355,
              0.7316449880599976,
              0.459199994802475,
              0.731607973575592,
              0.45917800068855286,
              0.73164302110672,
              0.4606640040874481,
              0.7316809892654419,
              0.4606890082359314,
              0.7316780090332031,
              0.46210500597953796,
              0.7316809892654419,
              0.4606890082359314,
              0.7317150235176086,
              0.46213299036026,
              0.7316780090332031,
              0.46210500597953796,
              0.7318599820137024,
              0.4696919918060303,
              0.7318969964981079,
              0.4697369933128357,
              0.7318850159645081,
              0.4707599878311157,
              0.7318969964981079,
              0.4697369933128357,
              0.73192298412323,
              0.4708069860935211,
              0.7318850159645081,
              0.4707599878311157,
              0.7318850159645081,
              0.4707599878311157,
              0.73192298412323,
              0.4708069860935211,
              0.7319089770317078,
              0.47176700830459595,
              0.73192298412323,
              0.4708069860935211,
              0.7319470047950745,
              0.4718160033226013,
              0.7319089770317078,
              0.47176700830459595,
              0.7319319844245911,
              0.4727090001106262,
              0.7319700121879578,
              0.47276100516319275,
              0.7319530248641968,
              0.47358599305152893,
              0.7319700121879578,
              0.47276100516319275,
              0.7319909930229187,
              0.47363901138305664,
              0.7319530248641968,
              0.47358599305152893,
              0.7319719791412354,
              0.4743939936161041,
              0.732010006904602,
              0.4744490087032318,
              0.7319899797439575,
              0.4751319885253906,
              0.732010006904602,
              0.4744490087032318,
              0.7320280075073242,
              0.4751890003681183,
              0.7319899797439575,
              0.4751319885253906,
              0.7319899797439575,
              0.4751319885253906,
              0.7320280075073242,
              0.4751890003681183,
              0.7320060133934021,
              0.47579801082611084,
              0.7320280075073242,
              0.4751890003681183,
              0.732043981552124,
              0.4758560061454773,
              0.7320060133934021,
              0.47579801082611084,
              0.7320320010185242,
              0.4769040048122406,
              0.7320700287818909,
              0.47696399688720703,
              0.7320430278778076,
              0.4773400127887726,
              0.7320700287818909,
              0.47696399688720703,
              0.7320809960365295,
              0.477400004863739,
              0.7320430278778076,
              0.4773400127887726,
              0.7247589826583862,
              0.18776999413967133,
              0.7247650027275085,
              0.1877090036869049,
              0.7243490219116211,
              0.18776999413967133,
              0.7247650027275085,
              0.1877090036869049,
              0.7243580222129822,
              0.1877090036869049,
              0.7243490219116211,
              0.18776999413967133,
              0.7225319743156433,
              0.18776999413967133,
              0.7225540280342102,
              0.1877090036869049,
              0.7222239971160889,
              0.18776999413967133,
              0.7225540280342102,
              0.1877090036869049,
              0.7222489714622498,
              0.1877090036869049,
              0.7222239971160889,
              0.18776999413967133,
              0.7222239971160889,
              0.18776999413967133,
              0.7222489714622498,
              0.1877090036869049,
              0.7219380140304565,
              0.18776999413967133,
              0.7222489714622498,
              0.1877090036869049,
              0.7219650149345398,
              0.1877090036869049,
              0.7219380140304565,
              0.18776999413967133,
              0.7216759920120239,
              0.18776999413967133,
              0.7217040061950684,
              0.1877090036869049,
              0.7214369773864746,
              0.18776999413967133,
              0.7217040061950684,
              0.1877090036869049,
              0.7214679718017578,
              0.1877090036869049,
              0.7214369773864746,
              0.18776999413967133,
              0.7206609845161438,
              0.18776999413967133,
              0.7206969857215881,
              0.1877090036869049,
              0.7205979824066162,
              0.18776999413967133,
              0.7206969857215881,
              0.1877090036869049,
              0.7206339836120605,
              0.1877090036869049,
              0.7205979824066162,
              0.18776999413967133,
              0.7205759882926941,
              0.18776999413967133,
              0.7206119894981384,
              0.1877090036869049,
              0.7206190228462219,
              0.18776999413967133,
              0.7206119894981384,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206190228462219,
              0.18776999413967133,
              0.7256150245666504,
              0.18776999413967133,
              0.7256159782409668,
              0.1877090036869049,
              0.725180983543396,
              0.18776999413967133,
              0.7256159782409668,
              0.1877090036869049,
              0.7251840233802795,
              0.1877090036869049,
              0.725180983543396,
              0.18776999413967133,
              0.7235720157623291,
              0.18776999413967133,
              0.7235869765281677,
              0.1877090036869049,
              0.7232069969177246,
              0.18776999413967133,
              0.7235869765281677,
              0.1877090036869049,
              0.7232249975204468,
              0.1877090036869049,
              0.7232069969177246,
              0.18776999413967133,
              0.7228599786758423,
              0.18776999413967133,
              0.7228800058364868,
              0.1877090036869049,
              0.7225319743156433,
              0.18776999413967133,
              0.7228800058364868,
              0.1877090036869049,
              0.7225540280342102,
              0.1877090036869049,
              0.7225319743156433,
              0.18776999413967133,
              0.7214369773864746,
              0.18776999413967133,
              0.7214679718017578,
              0.1877090036869049,
              0.7212250232696533,
              0.18776999413967133,
              0.7214679718017578,
              0.1877090036869049,
              0.7212569713592529,
              0.1877090036869049,
              0.7212250232696533,
              0.18776999413967133,
              0.7208830118179321,
              0.18776999413967133,
              0.7209169864654541,
              0.1877090036869049,
              0.7207559943199158,
              0.18776999413967133,
              0.7209169864654541,
              0.1877090036869049,
              0.7207909822463989,
              0.1877090036869049,
              0.7207559943199158,
              0.18776999413967133,
              0.7207559943199158,
              0.18776999413967133,
              0.7207909822463989,
              0.1877090036869049,
              0.7206609845161438,
              0.18776999413967133,
              0.7207909822463989,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7206609845161438,
              0.18776999413967133,
              0.7415549755096436,
              0.18776999413967133,
              0.7415549755096436,
              0.1877090036869049,
              0.72604900598526,
              0.18776999413967133,
              0.7415549755096436,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.72604900598526,
              0.18776999413967133,
              0.7239530086517334,
              0.1897359937429428,
              0.7239649891853333,
              0.18980200588703156,
              0.7243490219116211,
              0.18971699476242065,
              0.7239649891853333,
              0.18980200588703156,
              0.7243580222129822,
              0.1897830069065094,
              0.7243490219116211,
              0.18971699476242065,
              0.7235720157623291,
              0.1897599995136261,
              0.7235869765281677,
              0.18982599675655365,
              0.7239530086517334,
              0.1897359937429428,
              0.7235869765281677,
              0.18982599675655365,
              0.7239649891853333,
              0.18980200588703156,
              0.7239530086517334,
              0.1897359937429428,
              0.7222239971160889,
              0.1898999959230423,
              0.7222489714622498,
              0.18996499478816986,
              0.7225319743156433,
              0.18985900282859802,
              0.7222489714622498,
              0.18996499478816986,
              0.7225540280342102,
              0.18992400169372559,
              0.7225319743156433,
              0.18985900282859802,
              0.7216759920120239,
              0.1899929940700531,
              0.7217040061950684,
              0.19005699455738068,
              0.7219380140304565,
              0.18994499742984772,
              0.7217040061950684,
              0.19005699455738068,
              0.7219650149345398,
              0.19000999629497528,
              0.7219380140304565,
              0.18994499742984772,
              0.7212250232696533,
              0.19009900093078613,
              0.7212569713592529,
              0.19016200304031372,
              0.7214369773864746,
              0.19004400074481964,
              0.7212569713592529,
              0.19016200304031372,
              0.7214679718017578,
              0.19010800123214722,
              0.7214369773864746,
              0.19004400074481964,
              0.7210400104522705,
              0.1901559978723526,
              0.7210729718208313,
              0.1902189999818802,
              0.7212250232696533,
              0.19009900093078613,
              0.7210729718208313,
              0.1902189999818802,
              0.7212569713592529,
              0.19016200304031372,
              0.7212250232696533,
              0.19009900093078613,
              0.7208830118179321,
              0.19021500647068024,
              0.7209169864654541,
              0.19027799367904663,
              0.7210400104522705,
              0.1901559978723526,
              0.7209169864654541,
              0.19027799367904663,
              0.7210729718208313,
              0.1902189999818802,
              0.7210400104522705,
              0.1901559978723526,
              0.7206609845161438,
              0.19033999741077423,
              0.7206969857215881,
              0.19040200114250183,
              0.7207559943199158,
              0.19027699530124664,
              0.7206969857215881,
              0.19040200114250183,
              0.7207909822463989,
              0.19033899903297424,
              0.7207559943199158,
              0.19027699530124664,
              0.7205690145492554,
              0.19047200679779053,
              0.7206060290336609,
              0.19053299725055695,
              0.7205979824066162,
              0.1904049962759018,
              0.7206060290336609,
              0.19053299725055695,
              0.7206339836120605,
              0.1904670000076294,
              0.7205979824066162,
              0.1904049962759018,
              0.7206799983978271,
              0.1906760036945343,
              0.7207159996032715,
              0.19073599576950073,
              0.7206190228462219,
              0.19060799479484558,
              0.7207159996032715,
              0.19073599576950073,
              0.7206550240516663,
              0.1906680017709732,
              0.7206190228462219,
              0.19060799479484558,
              0.7256150245666504,
              0.18969100713729858,
              0.7256159782409668,
              0.18975800275802612,
              0.72604900598526,
              0.18969100713729858,
              0.7256159782409668,
              0.18975800275802612,
              0.72604900598526,
              0.18975800275802612,
              0.72604900598526,
              0.18969100713729858,
              0.72604900598526,
              0.18969100713729858,
              0.72604900598526,
              0.18975800275802612,
              0.7415549755096436,
              0.18969100713729858,
              0.72604900598526,
              0.18975800275802612,
              0.7415549755096436,
              0.18975800275802612,
              0.7415549755096436,
              0.18969100713729858,
              0.7315539717674255,
              0.4553939998149872,
              0.7315170168876648,
              0.45541200041770935,
              0.7315800189971924,
              0.45649099349975586,
              0.7315170168876648,
              0.45541200041770935,
              0.731544017791748,
              0.4565120041370392,
              0.7315800189971924,
              0.45649099349975586,
              0.7317000031471252,
              0.4614880084991455,
              0.7316640019416809,
              0.46152499318122864,
              0.7317209839820862,
              0.46237900853157043,
              0.7316640019416809,
              0.46152499318122864,
              0.7316849827766418,
              0.46241798996925354,
              0.7317209839820862,
              0.46237900853157043,
              0.7317209839820862,
              0.46237900853157043,
              0.7316849827766418,
              0.46241798996925354,
              0.7317410111427307,
              0.4632300138473511,
              0.7316849827766418,
              0.46241798996925354,
              0.7317060232162476,
              0.46327200531959534,
              0.7317410111427307,
              0.4632300138473511,
              0.7317410111427307,
              0.4632300138473511,
              0.7317060232162476,
              0.46327200531959534,
              0.7317609786987305,
              0.46404001116752625,
              0.7317060232162476,
              0.46327200531959534,
              0.7317249774932861,
              0.4640839993953705,
              0.7317609786987305,
              0.46404001116752625,
              0.7318660020828247,
              0.468423992395401,
              0.7318300008773804,
              0.46848100423812866,
              0.731876015663147,
              0.46884799003601074,
              0.7318300008773804,
              0.46848100423812866,
              0.7318410277366638,
              0.4689069986343384,
              0.731876015663147,
              0.46884799003601074,
              0.731876015663147,
              0.46884799003601074,
              0.7318410277366638,
              0.4689069986343384,
              0.7318850159645081,
              0.469217985868454,
              0.7318410277366638,
              0.4689069986343384,
              0.7318500280380249,
              0.4692780077457428,
              0.7318850159645081,
              0.469217985868454,
              0.731607973575592,
              0.4576680064201355,
              0.7315710186958313,
              0.45765000581741333,
              0.7315700054168701,
              0.45609501004219055,
              0.7315710186958313,
              0.45765000581741333,
              0.7315340042114258,
              0.45608100295066833,
              0.7315700054168701,
              0.45609501004219055,
              0.7317489981651306,
              0.4635300040245056,
              0.7317109704017639,
              0.4634990096092224,
              0.7317150235176086,
              0.46213299036026,
              0.7317109704017639,
              0.4634990096092224,
              0.7316780090332031,
              0.46210500597953796,
              0.7317150235176086,
              0.46213299036026,
              0.7320579886436462,
              0.4764479994773865,
              0.7320200204849243,
              0.47639000415802,
              0.732043981552124,
              0.4758560061454773,
              0.7320200204849243,
              0.47639000415802,
              0.7320060133934021,
              0.47579801082611084,
              0.732043981552124,
              0.4758560061454773,
              0.7320700287818909,
              0.47696399688720703,
              0.7320320010185242,
              0.4769040048122406,
              0.7320579886436462,
              0.4764479994773865,
              0.7320320010185242,
              0.4769040048122406,
              0.7320200204849243,
              0.47639000415802,
              0.7320579886436462,
              0.4764479994773865,
              0.7320889830589294,
              0.47775599360466003,
              0.7320510149002075,
              0.4776949882507324,
              0.7320809960365295,
              0.477400004863739,
              0.7320510149002075,
              0.4776949882507324,
              0.7320430278778076,
              0.4773400127887726,
              0.7320809960365295,
              0.477400004863739,
              0.7315269708633423,
              0.4542680084705353,
              0.7314900159835815,
              0.4542819857597351,
              0.7315539717674255,
              0.4553939998149872,
              0.7314900159835815,
              0.4542819857597351,
              0.7315170168876648,
              0.45541200041770935,
              0.7315539717674255,
              0.4553939998149872,
              0.7315800189971924,
              0.45649099349975586,
              0.731544017791748,
              0.4565120041370392,
              0.7316060066223145,
              0.4575580060482025,
              0.731544017791748,
              0.4565120041370392,
              0.7315689921379089,
              0.4575830101966858,
              0.7316060066223145,
              0.4575580060482025,
              0.7317960262298584,
              0.4655289947986603,
              0.7317609786987305,
              0.46557798981666565,
              0.7318120002746582,
              0.46620601415634155,
              0.7317609786987305,
              0.46557798981666565,
              0.731777012348175,
              0.4662570059299469,
              0.7318120002746582,
              0.46620601415634155,
              0.731827974319458,
              0.46683499217033386,
              0.7317919731140137,
              0.4668880105018616,
              0.7318410277366638,
              0.467415988445282,
              0.7317919731140137,
              0.4668880105018616,
              0.7318059802055359,
              0.46747100353240967,
              0.7318410277366638,
              0.467415988445282,
              0.7319539785385132,
              0.47210100293159485,
              0.7319039702415466,
              0.47001200914382935,
              0.73191899061203,
              0.4721609950065613,
              0.7319039702415466,
              0.47001200914382935,
              0.7318689823150635,
              0.47007301449775696,
              0.73191899061203,
              0.4721609950065613,
              0.731781005859375,
              0.46487900614738464,
              0.7317429780960083,
              0.4648439884185791,
              0.7317489981651306,
              0.4635300040245056,
              0.7317429780960083,
              0.4648439884185791,
              0.7317109704017639,
              0.4634990096092224,
              0.7317489981651306,
              0.4635300040245056,
              0.7318419814109802,
              0.46741899847984314,
              0.7318040132522583,
              0.46737900376319885,
              0.7318120002746582,
              0.46617498993873596,
              0.7318040132522583,
              0.46737900376319885,
              0.7317739725112915,
              0.46613800525665283,
              0.7318120002746582,
              0.46617498993873596,
              0.7318699955940247,
              0.46860700845718384,
              0.7318329811096191,
              0.4685640037059784,
              0.7318419814109802,
              0.46741899847984314,
              0.7318329811096191,
              0.4685640037059784,
              0.7318040132522583,
              0.46737900376319885,
              0.7318419814109802,
              0.46741899847984314,
              0.7319700121879578,
              0.47276100516319275,
              0.7319319844245911,
              0.4727090001106262,
              0.7319470047950745,
              0.4718160033226013,
              0.7319319844245911,
              0.4727090001106262,
              0.7319089770317078,
              0.47176700830459595,
              0.7319470047950745,
              0.4718160033226013,
              0.732010006904602,
              0.4744490087032318,
              0.7319719791412354,
              0.4743939936161041,
              0.7319909930229187,
              0.47363901138305664,
              0.7319719791412354,
              0.4743939936161041,
              0.7319530248641968,
              0.47358599305152893,
              0.7319909930229187,
              0.47363901138305664,
              0.7239649891853333,
              0.1877090036869049,
              0.7239530086517334,
              0.18776999413967133,
              0.7243580222129822,
              0.1877090036869049,
              0.7239530086517334,
              0.18776999413967133,
              0.7243490219116211,
              0.18776999413967133,
              0.7243580222129822,
              0.1877090036869049,
              0.7235869765281677,
              0.1877090036869049,
              0.7235720157623291,
              0.18776999413967133,
              0.7239649891853333,
              0.1877090036869049,
              0.7235720157623291,
              0.18776999413967133,
              0.7239530086517334,
              0.18776999413967133,
              0.7239649891853333,
              0.1877090036869049,
              0.7228800058364868,
              0.1877090036869049,
              0.7228599786758423,
              0.18776999413967133,
              0.7232249975204468,
              0.1877090036869049,
              0.7228599786758423,
              0.18776999413967133,
              0.7232069969177246,
              0.18776999413967133,
              0.7232249975204468,
              0.1877090036869049,
              0.7206119894981384,
              0.1877090036869049,
              0.7205759882926941,
              0.18776999413967133,
              0.7206060290336609,
              0.1877090036869049,
              0.7205759882926941,
              0.18776999413967133,
              0.7205690145492554,
              0.18776999413967133,
              0.7206060290336609,
              0.1877090036869049,
              0.7247650027275085,
              0.1877090036869049,
              0.7247589826583862,
              0.18776999413967133,
              0.7251840233802795,
              0.1877090036869049,
              0.7247589826583862,
              0.18776999413967133,
              0.725180983543396,
              0.18776999413967133,
              0.7251840233802795,
              0.1877090036869049,
              0.7217040061950684,
              0.1877090036869049,
              0.7216759920120239,
              0.18776999413967133,
              0.7219650149345398,
              0.1877090036869049,
              0.7216759920120239,
              0.18776999413967133,
              0.7219380140304565,
              0.18776999413967133,
              0.7219650149345398,
              0.1877090036869049,
              0.7210729718208313,
              0.1877090036869049,
              0.7210400104522705,
              0.18776999413967133,
              0.7212569713592529,
              0.1877090036869049,
              0.7210400104522705,
              0.18776999413967133,
              0.7212250232696533,
              0.18776999413967133,
              0.7212569713592529,
              0.1877090036869049,
              0.7209169864654541,
              0.1877090036869049,
              0.7208830118179321,
              0.18776999413967133,
              0.7210729718208313,
              0.1877090036869049,
              0.7208830118179321,
              0.18776999413967133,
              0.7210400104522705,
              0.18776999413967133,
              0.7210729718208313,
              0.1877090036869049,
              0.7206060290336609,
              0.1877090036869049,
              0.7205690145492554,
              0.18776999413967133,
              0.7206339836120605,
              0.1877090036869049,
              0.7205690145492554,
              0.18776999413967133,
              0.7205979824066162,
              0.18776999413967133,
              0.7206339836120605,
              0.1877090036869049,
              0.7207159996032715,
              0.1877090036869049,
              0.7206799983978271,
              0.18776999413967133,
              0.7206550240516663,
              0.1877090036869049,
              0.7206799983978271,
              0.18776999413967133,
              0.7206190228462219,
              0.18776999413967133,
              0.7206550240516663,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7256150245666504,
              0.18776999413967133,
              0.72604900598526,
              0.1877090036869049,
              0.7256150245666504,
              0.18776999413967133,
              0.72604900598526,
              0.18776999413967133,
              0.72604900598526,
              0.1877090036869049,
              0.7256159782409668,
              0.18975800275802612,
              0.7256150245666504,
              0.18969100713729858,
              0.7251840233802795,
              0.1897609978914261,
              0.7256150245666504,
              0.18969100713729858,
              0.725180983543396,
              0.18969400227069855,
              0.7251840233802795,
              0.1897609978914261,
              0.7251840233802795,
              0.1897609978914261,
              0.725180983543396,
              0.18969400227069855,
              0.7247650027275085,
              0.18976899981498718,
              0.725180983543396,
              0.18969400227069855,
              0.7247589826583862,
              0.18970300257205963,
              0.7247650027275085,
              0.18976899981498718,
              0.7247650027275085,
              0.18976899981498718,
              0.7247589826583862,
              0.18970300257205963,
              0.7243580222129822,
              0.1897830069065094,
              0.7247589826583862,
              0.18970300257205963,
              0.7243490219116211,
              0.18971699476242065,
              0.7243580222129822,
              0.1897830069065094,
              0.7235869765281677,
              0.18982599675655365,
              0.7235720157623291,
              0.1897599995136261,
              0.7232249975204468,
              0.1898539960384369,
              0.7235720157623291,
              0.1897599995136261,
              0.7232069969177246,
              0.18978899717330933,
              0.7232249975204468,
              0.1898539960384369,
              0.7232249975204468,
              0.1898539960384369,
              0.7232069969177246,
              0.18978899717330933,
              0.7228800058364868,
              0.18988700211048126,
              0.7232069969177246,
              0.18978899717330933,
              0.7228599786758423,
              0.1898220032453537,
              0.7228800058364868,
              0.18988700211048126,
              0.7228800058364868,
              0.18988700211048126,
              0.7228599786758423,
              0.1898220032453537,
              0.7225540280342102,
              0.18992400169372559,
              0.7228599786758423,
              0.1898220032453537,
              0.7225319743156433,
              0.18985900282859802,
              0.7225540280342102,
              0.18992400169372559,
              0.7222489714622498,
              0.18996499478816986,
              0.7222239971160889,
              0.1898999959230423,
              0.7219650149345398,
              0.19000999629497528,
              0.7222239971160889,
              0.1898999959230423,
              0.7219380140304565,
              0.18994499742984772,
              0.7219650149345398,
              0.19000999629497528,
              0.7217040061950684,
              0.19005699455738068,
              0.7216759920120239,
              0.1899929940700531,
              0.7214679718017578,
              0.19010800123214722,
              0.7216759920120239,
              0.1899929940700531,
              0.7214369773864746,
              0.19004400074481964,
              0.7214679718017578,
              0.19010800123214722,
              0.7209169864654541,
              0.19027799367904663,
              0.7208830118179321,
              0.19021500647068024,
              0.7207909822463989,
              0.19033899903297424,
              0.7208830118179321,
              0.19021500647068024,
              0.7207559943199158,
              0.19027699530124664,
              0.7207909822463989,
              0.19033899903297424,
              0.7206969857215881,
              0.19040200114250183,
              0.7206609845161438,
              0.19033999741077423,
              0.7206339836120605,
              0.1904670000076294,
              0.7206609845161438,
              0.19033999741077423,
              0.7205979824066162,
              0.1904049962759018,
              0.7206339836120605,
              0.1904670000076294,
              0.7206060290336609,
              0.19053299725055695,
              0.7205690145492554,
              0.19047200679779053,
              0.7206119894981384,
              0.19059999287128448,
              0.7205690145492554,
              0.19047200679779053,
              0.7205759882926941,
              0.19054000079631805,
              0.7206119894981384,
              0.19059999287128448,
              0.7206119894981384,
              0.19059999287128448,
              0.7205759882926941,
              0.19054000079631805,
              0.7206550240516663,
              0.1906680017709732,
              0.7205759882926941,
              0.19054000079631805,
              0.7206190228462219,
              0.19060799479484558,
              0.7206550240516663,
              0.1906680017709732,
              0.7315840125083923,
              0.45870399475097656,
              0.7315940260887146,
              0.45862001180648804,
              0.7315589785575867,
              0.45765599608421326,
              0.7315940260887146,
              0.45862001180648804,
              0.7315689921379089,
              0.4575830101966858,
              0.7315589785575867,
              0.45765599608421326,
              0.731607973575592,
              0.4597170054912567,
              0.7316179871559143,
              0.4596239924430847,
              0.7315840125083923,
              0.45870399475097656,
              0.7316179871559143,
              0.4596239924430847,
              0.7315940260887146,
              0.45862001180648804,
              0.7315840125083923,
              0.45870399475097656,
              0.7316539883613586,
              0.4616360068321228,
              0.7316640019416809,
              0.46152499318122864,
              0.7316319942474365,
              0.4606949985027313,
              0.7316640019416809,
              0.46152499318122864,
              0.7316420078277588,
              0.46059298515319824,
              0.7316319942474365,
              0.4606949985027313,
              0.7317519783973694,
              0.4657270014286041,
              0.7317609786987305,
              0.46557798981666565,
              0.7317349910736084,
              0.46499499678611755,
              0.7317609786987305,
              0.46557798981666565,
              0.7317439913749695,
              0.46485400199890137,
              0.7317349910736084,
              0.46499499678611755,
              0.7317839860916138,
              0.4670479893684387,
              0.7317919731140137,
              0.4668880105018616,
              0.7317690253257751,
              0.4664109945297241,
              0.7317919731140137,
              0.4668880105018616,
              0.731777012348175,
              0.4662570059299469,
              0.7317690253257751,
              0.4664109945297241,
              0.7315940260887146,
              0.45911499857902527,
              0.731607973575592,
              0.45917800068855286,
              0.7316290140151978,
              0.46059098839759827,
              0.731607973575592,
              0.45917800068855286,
              0.73164302110672,
              0.4606640040874481,
              0.7316290140151978,
              0.46059098839759827,
              0.7317289710044861,
              0.4647420048713684,
              0.7317429780960083,
              0.4648439884185791,
              0.7317600250244141,
              0.46602699160575867,
              0.7317429780960083,
              0.4648439884185791,
              0.7317739725112915,
              0.46613800525665283,
              0.7317600250244141,
              0.46602699160575867,
              0.7318170070648193,
              0.46843698620796204,
              0.7318329811096191,
              0.4685640037059784,
              0.7318440079689026,
              0.469556987285614,
              0.7318329811096191,
              0.4685640037059784,
              0.7318599820137024,
              0.4696919918060303,
              0.7318440079689026,
              0.469556987285614,
              0.7320870161056519,
              0.4796789884567261,
              0.7320420145988464,
              0.47782400250434875,
              0.7321029901504517,
              0.4798569977283478,
              0.7320420145988464,
              0.47782400250434875,
              0.7320590019226074,
              0.4780080020427704,
              0.7321029901504517,
              0.4798569977283478,
              0.7316319942474365,
              0.4606949985027313,
              0.7316420078277588,
              0.46059298515319824,
              0.731607973575592,
              0.4597170054912567,
              0.7316420078277588,
              0.46059298515319824,
              0.7316179871559143,
              0.4596239924430847,
              0.731607973575592,
              0.4597170054912567,
              0.7317349910736084,
              0.46499499678611755,
              0.7317439913749695,
              0.46485400199890137,
              0.731715977191925,
              0.46421900391578674,
              0.7317439913749695,
              0.46485400199890137,
              0.7317249774932861,
              0.4640839993953705,
              0.731715977191925,
              0.46421900391578674,
              0.731810986995697,
              0.46817100048065186,
              0.7318189740180969,
              0.46800199151039124,
              0.731797993183136,
              0.46763500571250916,
              0.7318189740180969,
              0.46800199151039124,
              0.7318059802055359,
              0.46747100353240967,
              0.731797993183136,
              0.46763500571250916,
              0.7318220138549805,
              0.46865400671958923,
              0.7318300008773804,
              0.46848100423812866,
              0.731810986995697,
              0.46817100048065186,
              0.7318300008773804,
              0.46848100423812866,
              0.7318189740180969,
              0.46800199151039124,
              0.731810986995697,
              0.46817100048065186,
              0.7318490147590637,
              0.46977099776268005,
              0.7318570017814636,
              0.4695909917354584,
              0.7318419814109802,
              0.4694559872150421,
              0.7318570017814636,
              0.4695909917354584,
              0.7318500280380249,
              0.4692780077457428,
              0.7318419814109802,
              0.4694559872150421,
              0.7318549752235413,
              0.47002899646759033,
              0.7318630218505859,
              0.4698469936847687,
              0.7318490147590637,
              0.46977099776268005,
              0.7318630218505859,
              0.4698469936847687,
              0.7318570017814636,
              0.4695909917354584,
              0.7318490147590637,
              0.46977099776268005,
              0.7315580248832703,
              0.4575969874858856,
              0.7315710186958313,
              0.45765000581741333,
              0.7315940260887146,
              0.45911499857902527,
              0.7315710186958313,
              0.45765000581741333,
              0.731607973575592,
              0.45917800068855286,
              0.7315940260887146,
              0.45911499857902527,
              0.7316290140151978,
              0.46059098839759827,
              0.73164302110672,
              0.4606640040874481,
              0.7316640019416809,
              0.46202200651168823,
              0.73164302110672,
              0.4606640040874481,
              0.7316780090332031,
              0.46210500597953796,
              0.7316640019416809,
              0.46202200651168823,
              0.7318440079689026,
              0.469556987285614,
              0.7318599820137024,
              0.4696919918060303,
              0.7318689823150635,
              0.47061899304389954,
              0.7318599820137024,
              0.4696919918060303,
              0.7318850159645081,
              0.4707599878311157,
              0.7318689823150635,
              0.47061899304389954,
              0.7318689823150635,
              0.47061899304389954,
              0.7318850159645081,
              0.4707599878311157,
              0.731893002986908,
              0.47161799669265747,
              0.7318850159645081,
              0.4707599878311157,
              0.7319089770317078,
              0.47176700830459595,
              0.731893002986908,
              0.47161799669265747,
              0.7319160103797913,
              0.472555011510849,
              0.7319319844245911,
              0.4727090001106262,
              0.7319369912147522,
              0.4734260141849518,
              0.7319319844245911,
              0.4727090001106262,
              0.7319530248641968,
              0.47358599305152893,
              0.7319369912147522,
              0.4734260141849518,
              0.7319560050964355,
              0.47422999143600464,
              0.7319719791412354,
              0.4743939936161041,
              0.7319740056991577,
              0.4749639928340912,
              0.7319719791412354,
              0.4743939936161041,
              0.7319899797439575,
              0.4751319885253906,
              0.7319740056991577,
              0.4749639928340912,
              0.7319740056991577,
              0.4749639928340912,
              0.7319899797439575,
              0.4751319885253906,
              0.7319899797439575,
              0.47562599182128906,
              0.7319899797439575,
              0.4751319885253906,
              0.7320060133934021,
              0.47579801082611084,
              0.7319899797439575,
              0.47562599182128906,
              0.7320160269737244,
              0.4767259955406189,
              0.7320320010185242,
              0.4769040048122406,
              0.7320259809494019,
              0.4771600067615509,
              0.7320320010185242,
              0.4769040048122406,
              0.7320430278778076,
              0.4773400127887726,
              0.7320259809494019,
              0.4771600067615509,
              0.7247570157051086,
              0.18795199692249298,
              0.7247589826583862,
              0.18776999413967133,
              0.7243459820747375,
              0.18795199692249298,
              0.7247589826583862,
              0.18776999413967133,
              0.7243490219116211,
              0.18776999413967133,
              0.7243459820747375,
              0.18795199692249298,
              0.7225239872932434,
              0.18795199692249298,
              0.7225319743156433,
              0.18776999413967133,
              0.722216010093689,
              0.18795199692249298,
              0.7225319743156433,
              0.18776999413967133,
              0.7222239971160889,
              0.18776999413967133,
              0.722216010093689,
              0.18795199692249298,
              0.722216010093689,
              0.18795199692249298,
              0.7222239971160889,
              0.18776999413967133,
              0.7219300270080566,
              0.18795199692249298,
              0.7222239971160889,
              0.18776999413967133,
              0.7219380140304565,
              0.18776999413967133,
              0.7219300270080566,
              0.18795199692249298,
              0.7216659784317017,
              0.18795199692249298,
              0.7216759920120239,
              0.18776999413967133,
              0.7214270234107971,
              0.18795199692249298,
              0.7216759920120239,
              0.18776999413967133,
              0.7214369773864746,
              0.18776999413967133,
              0.7214270234107971,
              0.18795199692249298,
              0.720649003982544,
              0.18795199692249298,
              0.7206609845161438,
              0.18776999413967133,
              0.7205860018730164,
              0.18795199692249298,
              0.7206609845161438,
              0.18776999413967133,
              0.7205979824066162,
              0.18776999413967133,
              0.7205860018730164,
              0.18795199692249298,
              0.7205629944801331,
              0.18795199692249298,
              0.7205759882926941,
              0.18776999413967133,
              0.7206069827079773,
              0.18795199692249298,
              0.7205759882926941,
              0.18776999413967133,
              0.7206190228462219,
              0.18776999413967133,
              0.7206069827079773,
              0.18795199692249298,
              0.7256140112876892,
              0.18795199692249298,
              0.7256150245666504,
              0.18776999413967133,
              0.7251799702644348,
              0.18795199692249298,
              0.7256150245666504,
              0.18776999413967133,
              0.725180983543396,
              0.18776999413967133,
              0.7251799702644348,
              0.18795199692249298,
              0.723567008972168,
              0.18795199692249298,
              0.7235720157623291,
              0.18776999413967133,
              0.7232009768486023,
              0.18795199692249298,
              0.7235720157623291,
              0.18776999413967133,
              0.7232069969177246,
              0.18776999413967133,
              0.7232009768486023,
              0.18795199692249298,
              0.7228530049324036,
              0.18795199692249298,
              0.7228599786758423,
              0.18776999413967133,
              0.7225239872932434,
              0.18795199692249298,
              0.7228599786758423,
              0.18776999413967133,
              0.7225319743156433,
              0.18776999413967133,
              0.7225239872932434,
              0.18795199692249298,
              0.7214270234107971,
              0.18795199692249298,
              0.7214369773864746,
              0.18776999413967133,
              0.7212139964103699,
              0.18795199692249298,
              0.7214369773864746,
              0.18776999413967133,
              0.7212250232696533,
              0.18776999413967133,
              0.7212139964103699,
              0.18795199692249298,
              0.7208719849586487,
              0.18795199692249298,
              0.7208830118179321,
              0.18776999413967133,
              0.7207450270652771,
              0.18795199692249298,
              0.7208830118179321,
              0.18776999413967133,
              0.7207559943199158,
              0.18776999413967133,
              0.7207450270652771,
              0.18795199692249298,
              0.7207450270652771,
              0.18795199692249298,
              0.7207559943199158,
              0.18776999413967133,
              0.720649003982544,
              0.18795199692249298,
              0.7207559943199158,
              0.18776999413967133,
              0.7206609845161438,
              0.18776999413967133,
              0.720649003982544,
              0.18795199692249298,
              0.7415549755096436,
              0.18795199692249298,
              0.7415549755096436,
              0.18776999413967133,
              0.72604900598526,
              0.18795199692249298,
              0.7415549755096436,
              0.18776999413967133,
              0.72604900598526,
              0.18776999413967133,
              0.72604900598526,
              0.18795199692249298,
              0.7239490151405334,
              0.18955199420452118,
              0.7239530086517334,
              0.1897359937429428,
              0.7243459820747375,
              0.18953299522399902,
              0.7239530086517334,
              0.1897359937429428,
              0.7243490219116211,
              0.18971699476242065,
              0.7243459820747375,
              0.18953299522399902,
              0.723567008972168,
              0.18957599997520447,
              0.7235720157623291,
              0.1897599995136261,
              0.7239490151405334,
              0.18955199420452118,
              0.7235720157623291,
              0.1897599995136261,
              0.7239530086517334,
              0.1897359937429428,
              0.7239490151405334,
              0.18955199420452118,
              0.722216010093689,
              0.18971599638462067,
              0.7222239971160889,
              0.1898999959230423,
              0.7225239872932434,
              0.1896750032901764,
              0.7222239971160889,
              0.1898999959230423,
              0.7225319743156433,
              0.18985900282859802,
              0.7225239872932434,
              0.1896750032901764,
              0.7216659784317017,
              0.18980999290943146,
              0.7216759920120239,
              0.1899929940700531,
              0.7219300270080566,
              0.1897609978914261,
              0.7216759920120239,
              0.1899929940700531,
              0.7219380140304565,
              0.18994499742984772,
              0.7219300270080566,
              0.1897609978914261,
              0.7212139964103699,
              0.1899159997701645,
              0.7212250232696533,
              0.19009900093078613,
              0.7214270234107971,
              0.189860999584198,
              0.7212250232696533,
              0.19009900093078613,
              0.7214369773864746,
              0.19004400074481964,
              0.7214270234107971,
              0.189860999584198,
              0.7210289835929871,
              0.18997299671173096,
              0.7210400104522705,
              0.1901559978723526,
              0.7212139964103699,
              0.1899159997701645,
              0.7210400104522705,
              0.1901559978723526,
              0.7212250232696533,
              0.19009900093078613,
              0.7212139964103699,
              0.1899159997701645,
              0.7208719849586487,
              0.1900320053100586,
              0.7208830118179321,
              0.19021500647068024,
              0.7210289835929871,
              0.18997299671173096,
              0.7208830118179321,
              0.19021500647068024,
              0.7210400104522705,
              0.1901559978723526,
              0.7210289835929871,
              0.18997299671173096,
              0.720649003982544,
              0.1901569962501526,
              0.7206609845161438,
              0.19033999741077423,
              0.7207450270652771,
              0.190093994140625,
              0.7206609845161438,
              0.19033999741077423,
              0.7207559943199158,
              0.19027699530124664,
              0.7207450270652771,
              0.190093994140625,
              0.7205569744110107,
              0.19029000401496887,
              0.7205690145492554,
              0.19047200679779053,
              0.7205860018730164,
              0.19022299349308014,
              0.7205690145492554,
              0.19047200679779053,
              0.7205979824066162,
              0.1904049962759018,
              0.7205860018730164,
              0.19022299349308014,
              0.7206680178642273,
              0.19049400091171265,
              0.7206799983978271,
              0.1906760036945343,
              0.7206069827079773,
              0.19042600691318512,
              0.7206799983978271,
              0.1906760036945343,
              0.7206190228462219,
              0.19060799479484558,
              0.7206069827079773,
              0.19042600691318512,
              0.7256140112876892,
              0.18950699269771576,
              0.7256150245666504,
              0.18969100713729858,
              0.72604900598526,
              0.18950699269771576,
              0.7256150245666504,
              0.18969100713729858,
              0.72604900598526,
              0.18969100713729858,
              0.72604900598526,
              0.18950699269771576,
              0.72604900598526,
              0.18950699269771576,
              0.72604900598526,
              0.18969100713729858,
              0.7415549755096436,
              0.18950699269771576,
              0.72604900598526,
              0.18969100713729858,
              0.7415549755096436,
              0.18969100713729858,
              0.7415549755096436,
              0.18950699269771576,
              0.7320209741592407,
              0.476949006319046,
              0.7320299744606018,
              0.4773010015487671,
              0.7318540215492249,
              0.4699830114841461,
              0.7320299744606018,
              0.4773010015487671,
              0.7318609952926636,
              0.4702419936656952,
              0.7318540215492249,
              0.4699830114841461,
              0.7320110201835632,
              0.476516991853714,
              0.7320209741592407,
              0.476949006319046,
              0.7318469882011414,
              0.469664990901947,
              0.7320209741592407,
              0.476949006319046,
              0.7318540215492249,
              0.4699830114841461,
              0.7318469882011414,
              0.469664990901947,
              0.7319989800453186,
              0.47600799798965454,
              0.7320110201835632,
              0.476516991853714,
              0.7318379878997803,
              0.46928900480270386,
              0.7320110201835632,
              0.476516991853714,
              0.7318469882011414,
              0.469664990901947,
              0.7318379878997803,
              0.46928900480270386,
              0.7319849729537964,
              0.47542300820350647,
              0.7319989800453186,
              0.47600799798965454,
              0.7318270206451416,
              0.46885600686073303,
              0.7319989800453186,
              0.47600799798965454,
              0.7318379878997803,
              0.46928900480270386,
              0.7318270206451416,
              0.46885600686073303,
              0.7319689989089966,
              0.47476598620414734,
              0.7319849729537964,
              0.47542300820350647,
              0.7318159937858582,
              0.4683690071105957,
              0.7319849729537964,
              0.47542300820350647,
              0.7318270206451416,
              0.46885600686073303,
              0.7318159937858582,
              0.4683690071105957,
              0.7319509983062744,
              0.4740369915962219,
              0.7319689989089966,
              0.47476598620414734,
              0.7318029999732971,
              0.46782800555229187,
              0.7319689989089966,
              0.47476598620414734,
              0.7318159937858582,
              0.4683690071105957,
              0.7318029999732971,
              0.46782800555229187,
              0.7319319844245911,
              0.473239004611969,
              0.7319509983062744,
              0.4740369915962219,
              0.7317889928817749,
              0.4672349989414215,
              0.7319509983062744,
              0.4740369915962219,
              0.7318029999732971,
              0.46782800555229187,
              0.7317889928817749,
              0.4672349989414215,
              0.7319120168685913,
              0.47237399220466614,
              0.7319319844245911,
              0.473239004611969,
              0.7317730188369751,
              0.466592013835907,
              0.7319319844245911,
              0.473239004611969,
              0.7317889928817749,
              0.4672349989414215,
              0.7317730188369751,
              0.466592013835907,
              0.731889009475708,
              0.4714449942111969,
              0.7319120168685913,
              0.47237399220466614,
              0.7317559719085693,
              0.4659009873867035,
              0.7319120168685913,
              0.47237399220466614,
              0.7317730188369751,
              0.466592013835907,
              0.7317559719085693,
              0.4659009873867035,
              0.7318660020828247,
              0.4704520106315613,
              0.731889009475708,
              0.4714449942111969,
              0.7317389845848083,
              0.4651620090007782,
              0.731889009475708,
              0.4714449942111969,
              0.7317559719085693,
              0.4659009873867035,
              0.7317389845848083,
              0.4651620090007782,
              0.7318400144577026,
              0.46939900517463684,
              0.7318660020828247,
              0.4704520106315613,
              0.731719970703125,
              0.4643769860267639,
              0.7318660020828247,
              0.4704520106315613,
              0.7317389845848083,
              0.4651620090007782,
              0.731719970703125,
              0.4643769860267639,
              0.7318140268325806,
              0.46828800439834595,
              0.7318400144577026,
              0.46939900517463684,
              0.7317000031471252,
              0.4635489881038666,
              0.7318400144577026,
              0.46939900517463684,
              0.731719970703125,
              0.4643769860267639,
              0.7317000031471252,
              0.4635489881038666,
              0.7318140268325806,
              0.46828800439834595,
              0.7317000031471252,
              0.4635489881038666,
              0.7317860126495361,
              0.4671199917793274,
              0.7317000031471252,
              0.4635489881038666,
              0.7316790223121643,
              0.4626779854297638,
              0.7317860126495361,
              0.4671199917793274,
              0.7317860126495361,
              0.4671199917793274,
              0.7316790223121643,
              0.4626779854297638,
              0.7317559719085693,
              0.46589699387550354,
              0.7316790223121643,
              0.4626779854297638,
              0.7316570281982422,
              0.46176600456237793,
              0.7317559719085693,
              0.46589699387550354,
              0.7317559719085693,
              0.46589699387550354,
              0.7316570281982422,
              0.46176600456237793,
              0.7317259907722473,
              0.46462199091911316,
              0.7316570281982422,
              0.46176600456237793,
              0.7316349744796753,
              0.4608150124549866,
              0.7317259907722473,
              0.46462199091911316,
              0.7317259907722473,
              0.46462199091911316,
              0.7316349744796753,
              0.4608150124549866,
              0.7316939830780029,
              0.4632970094680786,
              0.7316349744796753,
              0.4608150124549866,
              0.7316110134124756,
              0.4598270058631897,
              0.7316939830780029,
              0.4632970094680786,
              0.7316939830780029,
              0.4632970094680786,
              0.7316110134124756,
              0.4598270058631897,
              0.7316610217094421,
              0.4619239866733551,
              0.7316110134124756,
              0.4598270058631897,
              0.7315859794616699,
              0.4588020145893097,
              0.7316610217094421,
              0.4619239866733551,
              0.7316610217094421,
              0.4619239866733551,
              0.7315859794616699,
              0.4588020145893097,
              0.7316269874572754,
              0.4605039954185486,
              0.7315859794616699,
              0.4588020145893097,
              0.731561005115509,
              0.45774298906326294,
              0.7316269874572754,
              0.4605039954185486,
              0.7316269874572754,
              0.4605039954185486,
              0.731561005115509,
              0.45774298906326294,
              0.7315919995307922,
              0.45903998613357544,
              0.731561005115509,
              0.45774298906326294,
              0.731535017490387,
              0.4566510021686554,
              0.7315919995307922,
              0.45903998613357544,
              0.7315919995307922,
              0.45903998613357544,
              0.731535017490387,
              0.4566510021686554,
              0.7315559983253479,
              0.45753398537635803,
              0.731535017490387,
              0.4566510021686554,
              0.7315080165863037,
              0.45552799105644226,
              0.7315559983253479,
              0.45753398537635803,
              0.7315559983253479,
              0.45753398537635803,
              0.7315080165863037,
              0.45552799105644226,
              0.7315189838409424,
              0.45598798990249634,
              0.7315080165863037,
              0.45552799105644226,
              0.7314800024032593,
              0.4543749988079071,
              0.7315189838409424,
              0.45598798990249634,
              0.7320299744606018,
              0.4773010015487671,
              0.7320370078086853,
              0.4776090085506439,
              0.7318609952926636,
              0.4702419936656952,
              0.7320370078086853,
              0.4776090085506439,
              0.7318660020828247,
              0.4704720079898834,
              0.7318609952926636,
              0.4702419936656952,
              0.7251799702644348,
              0.18816600739955902,
              0.7251799702644348,
              0.18929600715637207,
              0.7256140112876892,
              0.18816600739955902,
              0.7251799702644348,
              0.18929600715637207,
              0.7256140112876892,
              0.1892929971218109,
              0.7256140112876892,
              0.18816600739955902,
              0.7247570157051086,
              0.18816600739955902,
              0.7247570157051086,
              0.18930499255657196,
              0.7251799702644348,
              0.18816600739955902,
              0.7247570157051086,
              0.18930499255657196,
              0.7251799702644348,
              0.18929600715637207,
              0.7251799702644348,
              0.18816600739955902,
              0.7243459820747375,
              0.18816600739955902,
              0.7243459820747375,
              0.18931899964809418,
              0.7247570157051086,
              0.18816600739955902,
              0.7243459820747375,
              0.18931899964809418,
              0.7247570157051086,
              0.18930499255657196,
              0.7247570157051086,
              0.18816600739955902,
              0.7239490151405334,
              0.18816600739955902,
              0.7239490151405334,
              0.18933799862861633,
              0.7243459820747375,
              0.18816600739955902,
              0.7239490151405334,
              0.18933799862861633,
              0.7243459820747375,
              0.18931899964809418,
              0.7243459820747375,
              0.18816600739955902,
              0.723567008972168,
              0.18816600739955902,
              0.723567008972168,
              0.18936200439929962,
              0.7239490151405334,
              0.18816600739955902,
              0.723567008972168,
              0.18936200439929962,
              0.7239490151405334,
              0.18933799862861633,
              0.7239490151405334,
              0.18816600739955902,
              0.7232009768486023,
              0.18816600739955902,
              0.7232009768486023,
              0.18939100205898285,
              0.723567008972168,
              0.18816600739955902,
              0.7232009768486023,
              0.18939100205898285,
              0.723567008972168,
              0.18936200439929962,
              0.723567008972168,
              0.18816600739955902,
              0.7228530049324036,
              0.18816600739955902,
              0.7228530049324036,
              0.18942399322986603,
              0.7232009768486023,
              0.18816600739955902,
              0.7228530049324036,
              0.18942399322986603,
              0.7232009768486023,
              0.18939100205898285,
              0.7232009768486023,
              0.18816600739955902,
              0.7225239872932434,
              0.18816600739955902,
              0.7225239872932434,
              0.18946099281311035,
              0.7228530049324036,
              0.18816600739955902,
              0.7225239872932434,
              0.18946099281311035,
              0.7228530049324036,
              0.18942399322986603,
              0.7228530049324036,
              0.18816600739955902,
              0.722216010093689,
              0.18816600739955902,
              0.722216010093689,
              0.18950200080871582,
              0.7225239872932434,
              0.18816600739955902,
              0.722216010093689,
              0.18950200080871582,
              0.7225239872932434,
              0.18946099281311035,
              0.7225239872932434,
              0.18816600739955902,
              0.7219300270080566,
              0.18816600739955902,
              0.7219300270080566,
              0.18954700231552124,
              0.722216010093689,
              0.18816600739955902,
              0.7219300270080566,
              0.18954700231552124,
              0.722216010093689,
              0.18950200080871582,
              0.722216010093689,
              0.18816600739955902,
              0.7216659784317017,
              0.18816600739955902,
              0.7216659784317017,
              0.1895959973335266,
              0.7219300270080566,
              0.18816600739955902,
              0.7216659784317017,
              0.1895959973335266,
              0.7219300270080566,
              0.18954700231552124,
              0.7219300270080566,
              0.18816600739955902,
              0.7214270234107971,
              0.18816600739955902,
              0.7214270234107971,
              0.18964700400829315,
              0.7216659784317017,
              0.18816600739955902,
              0.7214270234107971,
              0.18964700400829315,
              0.7216659784317017,
              0.1895959973335266,
              0.7216659784317017,
              0.18816600739955902,
              0.7212139964103699,
              0.18816600739955902,
              0.7212139964103699,
              0.18970200419425964,
              0.7214270234107971,
              0.18816600739955902,
              0.7212139964103699,
              0.18970200419425964,
              0.7214270234107971,
              0.18964700400829315,
              0.7214270234107971,
              0.18816600739955902,
              0.7210289835929871,
              0.18816600739955902,
              0.7210289835929871,
              0.1897590011358261,
              0.7212139964103699,
              0.18816600739955902,
              0.7210289835929871,
              0.1897590011358261,
              0.7212139964103699,
              0.18970200419425964,
              0.7212139964103699,
              0.18816600739955902,
              0.7208719849586487,
              0.18816600739955902,
              0.7208719849586487,
              0.18981799483299255,
              0.7210289835929871,
              0.18816600739955902,
              0.7208719849586487,
              0.18981799483299255,
              0.7210289835929871,
              0.1897590011358261,
              0.7210289835929871,
              0.18816600739955902,
              0.7207450270652771,
              0.18816600739955902,
              0.7207450270652771,
              0.18987999856472015,
              0.7208719849586487,
              0.18816600739955902,
              0.7207450270652771,
              0.18987999856472015,
              0.7208719849586487,
              0.18981799483299255,
              0.7208719849586487,
              0.18816600739955902,
              0.720649003982544,
              0.18816600739955902,
              0.720649003982544,
              0.18994399905204773,
              0.7207450270652771,
              0.18816600739955902,
              0.720649003982544,
              0.18994399905204773,
              0.7207450270652771,
              0.18987999856472015,
              0.7207450270652771,
              0.18816600739955902,
              0.7205860018730164,
              0.18816600739955902,
              0.7205860018730164,
              0.1900089979171753,
              0.720649003982544,
              0.18816600739955902,
              0.7205860018730164,
              0.1900089979171753,
              0.720649003982544,
              0.18994399905204773,
              0.720649003982544,
              0.18816600739955902,
              0.7205569744110107,
              0.18816600739955902,
              0.7205569744110107,
              0.19007599353790283,
              0.7205860018730164,
              0.18816600739955902,
              0.7205569744110107,
              0.19007599353790283,
              0.7205860018730164,
              0.1900089979171753,
              0.7205860018730164,
              0.18816600739955902,
              0.7205629944801331,
              0.18816600739955902,
              0.7205629944801331,
              0.19014300405979156,
              0.7205569744110107,
              0.18816600739955902,
              0.7205629944801331,
              0.19014300405979156,
              0.7205569744110107,
              0.19007599353790283,
              0.7205569744110107,
              0.18816600739955902,
              0.7206069827079773,
              0.18816600739955902,
              0.7206069827079773,
              0.19021199643611908,
              0.7205629944801331,
              0.18816600739955902,
              0.7206069827079773,
              0.19021199643611908,
              0.7205629944801331,
              0.19014300405979156,
              0.7205629944801331,
              0.18816600739955902,
              0.7206680178642273,
              0.18816600739955902,
              0.7206680178642273,
              0.1902800053358078,
              0.7206069827079773,
              0.18816600739955902,
              0.7206680178642273,
              0.1902800053358078,
              0.7206069827079773,
              0.19021199643611908,
              0.7206069827079773,
              0.18816600739955902,
              0.7256140112876892,
              0.18816600739955902,
              0.7256140112876892,
              0.1892929971218109,
              0.72604900598526,
              0.18816600739955902,
              0.7256140112876892,
              0.1892929971218109,
              0.72604900598526,
              0.1892929971218109,
              0.72604900598526,
              0.18816600739955902,
              0.72604900598526,
              0.18816600739955902,
              0.72604900598526,
              0.1892929971218109,
              0.7415549755096436,
              0.18816600739955902,
              0.72604900598526,
              0.1892929971218109,
              0.7415549755096436,
              0.1892929971218109,
              0.7415549755096436,
              0.18816600739955902,
              0.731535017490387,
              0.4566510021686554,
              0.7315329909324646,
              0.45657598972320557,
              0.7315080165863037,
              0.45552799105644226,
              0.7315329909324646,
              0.45657598972320557,
              0.7315059900283813,
              0.4554649889469147,
              0.7315080165863037,
              0.45552799105644226,
              0.7316790223121643,
              0.4626779854297638,
              0.7316759824752808,
              0.4625380039215088,
              0.7316570281982422,
              0.46176600456237793,
              0.7316759824752808,
              0.4625380039215088,
              0.7316539883613586,
              0.4616360068321228,
              0.7316570281982422,
              0.46176600456237793,
              0.7317000031471252,
              0.4635489881038666,
              0.7316970229148865,
              0.4633989930152893,
              0.7316790223121643,
              0.4626779854297638,
              0.7316970229148865,
              0.4633989930152893,
              0.7316759824752808,
              0.4625380039215088,
              0.7316790223121643,
              0.4626779854297638,
              0.731719970703125,
              0.4643769860267639,
              0.731715977191925,
              0.46421900391578674,
              0.7317000031471252,
              0.4635489881038666,
              0.731715977191925,
              0.46421900391578674,
              0.7316970229148865,
              0.4633989930152893,
              0.7317000031471252,
              0.4635489881038666,
              0.7318379878997803,
              0.46928900480270386,
              0.7318329811096191,
              0.4690830111503601,
              0.7318270206451416,
              0.46885600686073303,
              0.7318329811096191,
              0.4690830111503601,
              0.7318220138549805,
              0.46865400671958923,
              0.7318270206451416,
              0.46885600686073303,
              0.7318469882011414,
              0.469664990901947,
              0.7318419814109802,
              0.4694559872150421,
              0.7318379878997803,
              0.46928900480270386,
              0.7318419814109802,
              0.4694559872150421,
              0.7318329811096191,
              0.4690830111503601,
              0.7318379878997803,
              0.46928900480270386,
              0.7315189838409424,
              0.45598798990249634,
              0.7315199971199036,
              0.4560379981994629,
              0.7315559983253479,
              0.45753398537635803,
              0.7315199971199036,
              0.4560379981994629,
              0.7315580248832703,
              0.4575969874858856,
              0.7315559983253479,
              0.45753398537635803,
              0.7316610217094421,
              0.4619239866733551,
              0.7316640019416809,
              0.46202200651168823,
              0.7316939830780029,
              0.4632970094680786,
              0.7316640019416809,
              0.46202200651168823,
              0.7316970229148865,
              0.4634059965610504,
              0.7316939830780029,
              0.4632970094680786,
              0.7319849729537964,
              0.47542300820350647,
              0.7319899797439575,
              0.47562599182128906,
              0.7319989800453186,
              0.47600799798965454,
              0.7319899797439575,
              0.47562599182128906,
              0.7320039868354797,
              0.4762139916419983,
              0.7319989800453186,
              0.47600799798965454,
              0.7319989800453186,
              0.47600799798965454,
              0.7320039868354797,
              0.4762139916419983,
              0.7320110201835632,
              0.476516991853714,
              0.7320039868354797,
              0.4762139916419983,
              0.7320160269737244,
              0.4767259955406189,
              0.7320110201835632,
              0.476516991853714,
              0.7320209741592407,
              0.476949006319046,
              0.7320259809494019,
              0.4771600067615509,
              0.7320299744606018,
              0.4773010015487671,
              0.7320259809494019,
              0.4771600067615509,
              0.7320349812507629,
              0.47751399874687195,
              0.7320299744606018,
              0.4773010015487671,
              0.7315080165863037,
              0.45552799105644226,
              0.7315059900283813,
              0.4554649889469147,
              0.7314800024032593,
              0.4543749988079071,
              0.7315059900283813,
              0.4554649889469147,
              0.7314789891242981,
              0.45432499051094055,
              0.7314800024032593,
              0.4543749988079071,
              0.731561005115509,
              0.45774298906326294,
              0.7315589785575867,
              0.45765599608421326,
              0.731535017490387,
              0.4566510021686554,
              0.7315589785575867,
              0.45765599608421326,
              0.7315329909324646,
              0.45657598972320557,
              0.731535017490387,
              0.4566510021686554,
              0.7317730188369751,
              0.466592013835907,
              0.7317690253257751,
              0.4664109945297241,
              0.7317559719085693,
              0.4659009873867035,
              0.7317690253257751,
              0.4664109945297241,
              0.7317519783973694,
              0.4657270014286041,
              0.7317559719085693,
              0.4659009873867035,
              0.7318029999732971,
              0.46782800555229187,
              0.731797993183136,
              0.46763500571250916,
              0.7317889928817749,
              0.4672349989414215,
              0.731797993183136,
              0.46763500571250916,
              0.7317839860916138,
              0.4670479893684387,
              0.7317889928817749,
              0.4672349989414215,
              0.7319110035896301,
              0.472339004278183,
              0.7318609952926636,
              0.4702570140361786,
              0.7319160103797913,
              0.4725480079650879,
              0.7318609952926636,
              0.4702570140361786,
              0.7318660020828247,
              0.4704720079898834,
              0.7319160103797913,
              0.4725480079650879,
              0.7316939830780029,
              0.4632970094680786,
              0.7316970229148865,
              0.4634059965610504,
              0.7317259907722473,
              0.46462199091911316,
              0.7316970229148865,
              0.4634059965610504,
              0.7317289710044861,
              0.4647420048713684,
              0.7317259907722473,
              0.46462199091911316,
              0.7317559719085693,
              0.46589699387550354,
              0.7317600250244141,
              0.46602699160575867,
              0.7317860126495361,
              0.4671199917793274,
              0.7317600250244141,
              0.46602699160575867,
              0.7317889928817749,
              0.4672600030899048,
              0.7317860126495361,
              0.4671199917793274,
              0.7317860126495361,
              0.4671199917793274,
              0.7317889928817749,
              0.4672600030899048,
              0.7318140268325806,
              0.46828800439834595,
              0.7317889928817749,
              0.4672600030899048,
              0.7318170070648193,
              0.46843698620796204,
              0.7318140268325806,
              0.46828800439834595,
              0.731889009475708,
              0.4714449942111969,
              0.731893002986908,
              0.47161799669265747,
              0.7319120168685913,
              0.47237399220466614,
              0.731893002986908,
              0.47161799669265747,
              0.7319160103797913,
              0.472555011510849,
              0.7319120168685913,
              0.47237399220466614,
              0.7319319844245911,
              0.473239004611969,
              0.7319369912147522,
              0.4734260141849518,
              0.7319509983062744,
              0.4740369915962219,
              0.7319369912147522,
              0.4734260141849518,
              0.7319560050964355,
              0.47422999143600464,
              0.7319509983062744,
              0.4740369915962219,
              0.7243459820747375,
              0.18816600739955902,
              0.7243459820747375,
              0.18795199692249298,
              0.7239490151405334,
              0.18816600739955902,
              0.7243459820747375,
              0.18795199692249298,
              0.7239490151405334,
              0.18795199692249298,
              0.7239490151405334,
              0.18816600739955902,
              0.7239490151405334,
              0.18816600739955902,
              0.7239490151405334,
              0.18795199692249298,
              0.723567008972168,
              0.18816600739955902,
              0.7239490151405334,
              0.18795199692249298,
              0.723567008972168,
              0.18795199692249298,
              0.723567008972168,
              0.18816600739955902,
              0.7232009768486023,
              0.18816600739955902,
              0.7232009768486023,
              0.18795199692249298,
              0.7228530049324036,
              0.18816600739955902,
              0.7232009768486023,
              0.18795199692249298,
              0.7228530049324036,
              0.18795199692249298,
              0.7228530049324036,
              0.18816600739955902,
              0.7205569744110107,
              0.18816600739955902,
              0.7205569744110107,
              0.18795199692249298,
              0.7205629944801331,
              0.18816600739955902,
              0.7205569744110107,
              0.18795199692249298,
              0.7205629944801331,
              0.18795199692249298,
              0.7205629944801331,
              0.18816600739955902,
              0.7251799702644348,
              0.18816600739955902,
              0.7251799702644348,
              0.18795199692249298,
              0.7247570157051086,
              0.18816600739955902,
              0.7251799702644348,
              0.18795199692249298,
              0.7247570157051086,
              0.18795199692249298,
              0.7247570157051086,
              0.18816600739955902,
              0.7219300270080566,
              0.18816600739955902,
              0.7219300270080566,
              0.18795199692249298,
              0.7216659784317017,
              0.18816600739955902,
              0.7219300270080566,
              0.18795199692249298,
              0.7216659784317017,
              0.18795199692249298,
              0.7216659784317017,
              0.18816600739955902,
              0.7212139964103699,
              0.18816600739955902,
              0.7212139964103699,
              0.18795199692249298,
              0.7210289835929871,
              0.18816600739955902,
              0.7212139964103699,
              0.18795199692249298,
              0.7210289835929871,
              0.18795199692249298,
              0.7210289835929871,
              0.18816600739955902,
              0.7210289835929871,
              0.18816600739955902,
              0.7210289835929871,
              0.18795199692249298,
              0.7208719849586487,
              0.18816600739955902,
              0.7210289835929871,
              0.18795199692249298,
              0.7208719849586487,
              0.18795199692249298,
              0.7208719849586487,
              0.18816600739955902,
              0.7205860018730164,
              0.18816600739955902,
              0.7205860018730164,
              0.18795199692249298,
              0.7205569744110107,
              0.18816600739955902,
              0.7205860018730164,
              0.18795199692249298,
              0.7205569744110107,
              0.18795199692249298,
              0.7205569744110107,
              0.18816600739955902,
              0.7206069827079773,
              0.18816600739955902,
              0.7206069827079773,
              0.18795199692249298,
              0.7206680178642273,
              0.18816600739955902,
              0.7206069827079773,
              0.18795199692249298,
              0.7206680178642273,
              0.18795199692249298,
              0.7206680178642273,
              0.18816600739955902,
              0.72604900598526,
              0.18816600739955902,
              0.72604900598526,
              0.18795199692249298,
              0.7256140112876892,
              0.18816600739955902,
              0.72604900598526,
              0.18795199692249298,
              0.7256140112876892,
              0.18795199692249298,
              0.7256140112876892,
              0.18816600739955902,
              0.7251799702644348,
              0.18929600715637207,
              0.7251799702644348,
              0.18951000273227692,
              0.7256140112876892,
              0.1892929971218109,
              0.7251799702644348,
              0.18951000273227692,
              0.7256140112876892,
              0.18950699269771576,
              0.7256140112876892,
              0.1892929971218109,
              0.7247570157051086,
              0.18930499255657196,
              0.7247570157051086,
              0.189519003033638,
              0.7251799702644348,
              0.18929600715637207,
              0.7247570157051086,
              0.189519003033638,
              0.7251799702644348,
              0.18951000273227692,
              0.7251799702644348,
              0.18929600715637207,
              0.7243459820747375,
              0.18931899964809418,
              0.7243459820747375,
              0.18953299522399902,
              0.7247570157051086,
              0.18930499255657196,
              0.7243459820747375,
              0.18953299522399902,
              0.7247570157051086,
              0.189519003033638,
              0.7247570157051086,
              0.18930499255657196,
              0.7232009768486023,
              0.18939100205898285,
              0.7232009768486023,
              0.1896049976348877,
              0.723567008972168,
              0.18936200439929962,
              0.7232009768486023,
              0.1896049976348877,
              0.723567008972168,
              0.18957599997520447,
              0.723567008972168,
              0.18936200439929962,
              0.7228530049324036,
              0.18942399322986603,
              0.7228530049324036,
              0.18963800370693207,
              0.7232009768486023,
              0.18939100205898285,
              0.7228530049324036,
              0.18963800370693207,
              0.7232009768486023,
              0.1896049976348877,
              0.7232009768486023,
              0.18939100205898285,
              0.7225239872932434,
              0.18946099281311035,
              0.7225239872932434,
              0.1896750032901764,
              0.7228530049324036,
              0.18942399322986603,
              0.7225239872932434,
              0.1896750032901764,
              0.7228530049324036,
              0.18963800370693207,
              0.7228530049324036,
              0.18942399322986603,
              0.7219300270080566,
              0.18954700231552124,
              0.7219300270080566,
              0.1897609978914261,
              0.722216010093689,
              0.18950200080871582,
              0.7219300270080566,
              0.1897609978914261,
              0.722216010093689,
              0.18971599638462067,
              0.722216010093689,
              0.18950200080871582,
              0.7214270234107971,
              0.18964700400829315,
              0.7214270234107971,
              0.189860999584198,
              0.7216659784317017,
              0.1895959973335266,
              0.7214270234107971,
              0.189860999584198,
              0.7216659784317017,
              0.18980999290943146,
              0.7216659784317017,
              0.1895959973335266,
              0.7207450270652771,
              0.18987999856472015,
              0.7207450270652771,
              0.190093994140625,
              0.7208719849586487,
              0.18981799483299255,
              0.7207450270652771,
              0.190093994140625,
              0.7208719849586487,
              0.1900320053100586,
              0.7208719849586487,
              0.18981799483299255,
              0.7205860018730164,
              0.1900089979171753,
              0.7205860018730164,
              0.19022299349308014,
              0.720649003982544,
              0.18994399905204773,
              0.7205860018730164,
              0.19022299349308014,
              0.720649003982544,
              0.1901569962501526,
              0.720649003982544,
              0.18994399905204773,
              0.7205629944801331,
              0.19014300405979156,
              0.7205629944801331,
              0.1903569996356964,
              0.7205569744110107,
              0.19007599353790283,
              0.7205629944801331,
              0.1903569996356964,
              0.7205569744110107,
              0.19029000401496887,
              0.7205569744110107,
              0.19007599353790283,
              0.7206069827079773,
              0.19021199643611908,
              0.7206069827079773,
              0.19042600691318512,
              0.7205629944801331,
              0.19014300405979156,
              0.7206069827079773,
              0.19042600691318512,
              0.7205629944801331,
              0.1903569996356964,
              0.7205629944801331,
              0.19014300405979156,
              0.7316489815711975,
              0.4575580060482025,
              0.7316060066223145,
              0.4575580060482025,
              0.731673002243042,
              0.4585919976234436,
              0.7316060066223145,
              0.4575580060482025,
              0.7316300272941589,
              0.4585919976234436,
              0.731673002243042,
              0.4585919976234436,
              0.731673002243042,
              0.4585919976234436,
              0.7316300272941589,
              0.4585919976234436,
              0.7316970229148865,
              0.4595929980278015,
              0.7316300272941589,
              0.4585919976234436,
              0.7316539883613586,
              0.4595929980278015,
              0.7316970229148865,
              0.4595929980278015,
              0.731719970703125,
              0.46055901050567627,
              0.7316769957542419,
              0.46055901050567627,
              0.7317429780960083,
              0.4614880084991455,
              0.7316769957542419,
              0.46055901050567627,
              0.7317000031471252,
              0.4614880084991455,
              0.7317429780960083,
              0.4614880084991455,
              0.7318220138549805,
              0.4648059904575348,
              0.7317789793014526,
              0.4648059904575348,
              0.7318390011787415,
              0.4655289947986603,
              0.7317789793014526,
              0.4648059904575348,
              0.7317960262298584,
              0.4655289947986603,
              0.7318390011787415,
              0.4655289947986603,
              0.7318549752235413,
              0.46620601415634155,
              0.7318120002746582,
              0.46620601415634155,
              0.7318699955940247,
              0.46683499217033386,
              0.7318120002746582,
              0.46620601415634155,
              0.731827974319458,
              0.46683499217033386,
              0.7318699955940247,
              0.46683499217033386,
              0.7317240238189697,
              0.4606890082359314,
              0.7316809892654419,
              0.4606890082359314,
              0.7316880226135254,
              0.459199994802475,
              0.7316809892654419,
              0.4606890082359314,
              0.7316449880599976,
              0.459199994802475,
              0.7316880226135254,
              0.459199994802475,
              0.7318549752235413,
              0.46617498993873596,
              0.7318120002746582,
              0.46617498993873596,
              0.7318239808082581,
              0.46487900614738464,
              0.7318120002746582,
              0.46617498993873596,
              0.731781005859375,
              0.46487900614738464,
              0.7318239808082581,
              0.46487900614738464,
              0.731939971446991,
              0.4697369933128357,
              0.7318969964981079,
              0.4697369933128357,
              0.7319129705429077,
              0.46860700845718384,
              0.7318969964981079,
              0.4697369933128357,
              0.7318699955940247,
              0.46860700845718384,
              0.7319129705429077,
              0.46860700845718384,
              0.7321410179138184,
              0.4799160063266754,
              0.7320970296859741,
              0.478069007396698,
              0.7321839928627014,
              0.4799160063266754,
              0.7320970296859741,
              0.478069007396698,
              0.732138991355896,
              0.478069007396698,
              0.7321839928627014,
              0.4799160063266754,
              0.7316970229148865,
              0.4595929980278015,
              0.7316539883613586,
              0.4595929980278015,
              0.731719970703125,
              0.46055901050567627,
              0.7316539883613586,
              0.4595929980278015,
              0.7316769957542419,
              0.46055901050567627,
              0.731719970703125,
              0.46055901050567627,
              0.7318040132522583,
              0.46404001116752625,
              0.7317609786987305,
              0.46404001116752625,
              0.7318220138549805,
              0.4648059904575348,
              0.7317609786987305,
              0.46404001116752625,
              0.7317789793014526,
              0.4648059904575348,
              0.7318220138549805,
              0.4648059904575348,
              0.7318840026855469,
              0.467415988445282,
              0.7318410277366638,
              0.467415988445282,
              0.7318969964981079,
              0.46794599294662476,
              0.7318410277366638,
              0.467415988445282,
              0.7318540215492249,
              0.46794599294662476,
              0.7318969964981079,
              0.46794599294662476,
              0.7318969964981079,
              0.46794599294662476,
              0.7318540215492249,
              0.46794599294662476,
              0.7319080233573914,
              0.468423992395401,
              0.7318540215492249,
              0.46794599294662476,
              0.7318660020828247,
              0.468423992395401,
              0.7319080233573914,
              0.468423992395401,
              0.7319269776344299,
              0.469217985868454,
              0.7318850159645081,
              0.469217985868454,
              0.7319350242614746,
              0.46953099966049194,
              0.7318850159645081,
              0.469217985868454,
              0.7318919897079468,
              0.46953099966049194,
              0.7319350242614746,
              0.46953099966049194,
              0.7319350242614746,
              0.46953099966049194,
              0.7318919897079468,
              0.46953099966049194,
              0.7319409847259521,
              0.46978598833084106,
              0.7318919897079468,
              0.46953099966049194,
              0.7318980097770691,
              0.46978598833084106,
              0.7319409847259521,
              0.46978598833084106,
              0.7316880226135254,
              0.459199994802475,
              0.7316449880599976,
              0.459199994802475,
              0.7316510081291199,
              0.4576680064201355,
              0.7316449880599976,
              0.459199994802475,
              0.731607973575592,
              0.4576680064201355,
              0.7316510081291199,
              0.4576680064201355,
              0.7317579984664917,
              0.46213299036026,
              0.7317150235176086,
              0.46213299036026,
              0.7317240238189697,
              0.4606890082359314,
              0.7317150235176086,
              0.46213299036026,
              0.7316809892654419,
              0.4606890082359314,
              0.7317240238189697,
              0.4606890082359314,
              0.7319660186767578,
              0.4708069860935211,
              0.73192298412323,
              0.4708069860935211,
              0.731939971446991,
              0.4697369933128357,
              0.73192298412323,
              0.4708069860935211,
              0.7318969964981079,
              0.4697369933128357,
              0.731939971446991,
              0.4697369933128357,
              0.7319899797439575,
              0.4718160033226013,
              0.7319470047950745,
              0.4718160033226013,
              0.7319660186767578,
              0.4708069860935211,
              0.7319470047950745,
              0.4718160033226013,
              0.73192298412323,
              0.4708069860935211,
              0.7319660186767578,
              0.4708069860935211,
              0.7320330142974854,
              0.47363901138305664,
              0.7319909930229187,
              0.47363901138305664,
              0.7320119738578796,
              0.47276100516319275,
              0.7319909930229187,
              0.47363901138305664,
              0.7319700121879578,
              0.47276100516319275,
              0.7320119738578796,
              0.47276100516319275,
              0.7320700287818909,
              0.4751890003681183,
              0.7320280075073242,
              0.4751890003681183,
              0.7320529818534851,
              0.4744490087032318,
              0.7320280075073242,
              0.4751890003681183,
              0.732010006904602,
              0.4744490087032318,
              0.7320529818534851,
              0.4744490087032318,
              0.7320860028266907,
              0.4758560061454773,
              0.732043981552124,
              0.4758560061454773,
              0.7320700287818909,
              0.4751890003681183,
              0.732043981552124,
              0.4758560061454773,
              0.7320280075073242,
              0.4751890003681183,
              0.7320700287818909,
              0.4751890003681183,
              0.7321230173110962,
              0.477400004863739,
              0.7320809960365295,
              0.477400004863739,
              0.7321130037307739,
              0.47696399688720703,
              0.7320809960365295,
              0.477400004863739,
              0.7320700287818909,
              0.47696399688720703,
              0.7321130037307739,
              0.47696399688720703,
              0.7243689894676208,
              0.1877090036869049,
              0.7243580222129822,
              0.1877090036869049,
              0.7247729897499084,
              0.1877090036869049,
              0.7243580222129822,
              0.1877090036869049,
              0.7247650027275085,
              0.1877090036869049,
              0.7247729897499084,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7222489714622498,
              0.1877090036869049,
              0.7225800156593323,
              0.1877090036869049,
              0.7222489714622498,
              0.1877090036869049,
              0.7225540280342102,
              0.1877090036869049,
              0.7225800156593323,
              0.1877090036869049,
              0.721996009349823,
              0.1877090036869049,
              0.7219650149345398,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7219650149345398,
              0.1877090036869049,
              0.7222489714622498,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7215030193328857,
              0.1877090036869049,
              0.7214679718017578,
              0.1877090036869049,
              0.7217379808425903,
              0.1877090036869049,
              0.7214679718017578,
              0.1877090036869049,
              0.7217040061950684,
              0.1877090036869049,
              0.7217379808425903,
              0.1877090036869049,
              0.7206770181655884,
              0.1877090036869049,
              0.7206339836120605,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206339836120605,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206119894981384,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7251880168914795,
              0.1877090036869049,
              0.7251840233802795,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7251840233802795,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7232450246810913,
              0.1877090036869049,
              0.7232249975204468,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7232249975204468,
              0.1877090036869049,
              0.7235869765281677,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7225800156593323,
              0.1877090036869049,
              0.7225540280342102,
              0.1877090036869049,
              0.7229030132293701,
              0.1877090036869049,
              0.7225540280342102,
              0.1877090036869049,
              0.7228800058364868,
              0.1877090036869049,
              0.7229030132293701,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7212569713592529,
              0.1877090036869049,
              0.7215030193328857,
              0.1877090036869049,
              0.7212569713592529,
              0.1877090036869049,
              0.7214679718017578,
              0.1877090036869049,
              0.7215030193328857,
              0.1877090036869049,
              0.7208330035209656,
              0.1877090036869049,
              0.7207909822463989,
              0.1877090036869049,
              0.7209569811820984,
              0.1877090036869049,
              0.7207909822463989,
              0.1877090036869049,
              0.7209169864654541,
              0.1877090036869049,
              0.7209569811820984,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7208330035209656,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7207909822463989,
              0.1877090036869049,
              0.7208330035209656,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7243689894676208,
              0.18978999555110931,
              0.7243580222129822,
              0.1897830069065094,
              0.7239789962768555,
              0.18980899453163147,
              0.7243580222129822,
              0.1897830069065094,
              0.7239649891853333,
              0.18980200588703156,
              0.7239789962768555,
              0.18980899453163147,
              0.7239789962768555,
              0.18980899453163147,
              0.7239649891853333,
              0.18980200588703156,
              0.7236040234565735,
              0.18983200192451477,
              0.7239649891853333,
              0.18980200588703156,
              0.7235869765281677,
              0.18982599675655365,
              0.7236040234565735,
              0.18983200192451477,
              0.7225800156593323,
              0.1899300068616867,
              0.7225540280342102,
              0.18992400169372559,
              0.7222779989242554,
              0.189970001578331,
              0.7225540280342102,
              0.18992400169372559,
              0.7222489714622498,
              0.18996499478816986,
              0.7222779989242554,
              0.189970001578331,
              0.721996009349823,
              0.19001400470733643,
              0.7219650149345398,
              0.19000999629497528,
              0.7217379808425903,
              0.1900620013475418,
              0.7219650149345398,
              0.19000999629497528,
              0.7217040061950684,
              0.19005699455738068,
              0.7217379808425903,
              0.1900620013475418,
              0.7215030193328857,
              0.19011199474334717,
              0.7214679718017578,
              0.19010800123214722,
              0.7212939858436584,
              0.19016599655151367,
              0.7214679718017578,
              0.19010800123214722,
              0.7212569713592529,
              0.19016200304031372,
              0.7212939858436584,
              0.19016599655151367,
              0.7212939858436584,
              0.19016599655151367,
              0.7212569713592529,
              0.19016200304031372,
              0.7211120128631592,
              0.19022199511528015,
              0.7212569713592529,
              0.19016200304031372,
              0.7210729718208313,
              0.1902189999818802,
              0.7211120128631592,
              0.19022199511528015,
              0.7211120128631592,
              0.19022199511528015,
              0.7210729718208313,
              0.1902189999818802,
              0.7209569811820984,
              0.1902800053358078,
              0.7210729718208313,
              0.1902189999818802,
              0.7209169864654541,
              0.19027799367904663,
              0.7209569811820984,
              0.1902800053358078,
              0.7208330035209656,
              0.19034099578857422,
              0.7207909822463989,
              0.19033899903297424,
              0.7207390069961548,
              0.19040299952030182,
              0.7207909822463989,
              0.19033899903297424,
              0.7206969857215881,
              0.19040200114250183,
              0.7207390069961548,
              0.19040299952030182,
              0.7206770181655884,
              0.1904670000076294,
              0.7206339836120605,
              0.1904670000076294,
              0.7206479907035828,
              0.19053299725055695,
              0.7206339836120605,
              0.1904670000076294,
              0.7206060290336609,
              0.19053299725055695,
              0.7206479907035828,
              0.19053299725055695,
              0.7206969857215881,
              0.1906680017709732,
              0.7206550240516663,
              0.1906680017709732,
              0.7207580208778381,
              0.19073499739170074,
              0.7206550240516663,
              0.1906680017709732,
              0.7207159996032715,
              0.19073599576950073,
              0.7207580208778381,
              0.19073499739170074,
              0.72604900598526,
              0.18976500630378723,
              0.72604900598526,
              0.18975800275802612,
              0.7256159782409668,
              0.18976500630378723,
              0.72604900598526,
              0.18975800275802612,
              0.7256159782409668,
              0.18975800275802612,
              0.7256159782409668,
              0.18976500630378723,
              0.7415549755096436,
              0.18976500630378723,
              0.7415549755096436,
              0.18975800275802612,
              0.72604900598526,
              0.18976500630378723,
              0.7415549755096436,
              0.18975800275802612,
              0.72604900598526,
              0.18975800275802612,
              0.72604900598526,
              0.18976500630378723,
              0.7315970063209534,
              0.4553939998149872,
              0.7315539717674255,
              0.4553939998149872,
              0.7316229939460754,
              0.45649099349975586,
              0.7315539717674255,
              0.4553939998149872,
              0.7315800189971924,
              0.45649099349975586,
              0.7316229939460754,
              0.45649099349975586,
              0.7317429780960083,
              0.4614880084991455,
              0.7317000031471252,
              0.4614880084991455,
              0.731764018535614,
              0.46237900853157043,
              0.7317000031471252,
              0.4614880084991455,
              0.7317209839820862,
              0.46237900853157043,
              0.731764018535614,
              0.46237900853157043,
              0.731764018535614,
              0.46237900853157043,
              0.7317209839820862,
              0.46237900853157043,
              0.7317839860916138,
              0.4632300138473511,
              0.7317209839820862,
              0.46237900853157043,
              0.7317410111427307,
              0.4632300138473511,
              0.7317839860916138,
              0.4632300138473511,
              0.7317839860916138,
              0.4632300138473511,
              0.7317410111427307,
              0.4632300138473511,
              0.7318040132522583,
              0.46404001116752625,
              0.7317410111427307,
              0.4632300138473511,
              0.7317609786987305,
              0.46404001116752625,
              0.7318040132522583,
              0.46404001116752625,
              0.7319080233573914,
              0.468423992395401,
              0.7318660020828247,
              0.468423992395401,
              0.73191899061203,
              0.46884799003601074,
              0.7318660020828247,
              0.468423992395401,
              0.731876015663147,
              0.46884799003601074,
              0.73191899061203,
              0.46884799003601074,
              0.73191899061203,
              0.46884799003601074,
              0.731876015663147,
              0.46884799003601074,
              0.7319269776344299,
              0.469217985868454,
              0.731876015663147,
              0.46884799003601074,
              0.7318850159645081,
              0.469217985868454,
              0.7319269776344299,
              0.469217985868454,
              0.7316510081291199,
              0.4576680064201355,
              0.731607973575592,
              0.4576680064201355,
              0.7316139936447144,
              0.45609501004219055,
              0.731607973575592,
              0.4576680064201355,
              0.7315700054168701,
              0.45609501004219055,
              0.7316139936447144,
              0.45609501004219055,
              0.7317910194396973,
              0.4635300040245056,
              0.7317489981651306,
              0.4635300040245056,
              0.7317579984664917,
              0.46213299036026,
              0.7317489981651306,
              0.4635300040245056,
              0.7317150235176086,
              0.46213299036026,
              0.7317579984664917,
              0.46213299036026,
              0.7321010231971741,
              0.4764479994773865,
              0.7320579886436462,
              0.4764479994773865,
              0.7320860028266907,
              0.4758560061454773,
              0.7320579886436462,
              0.4764479994773865,
              0.732043981552124,
              0.4758560061454773,
              0.7320860028266907,
              0.4758560061454773,
              0.7321130037307739,
              0.47696399688720703,
              0.7320700287818909,
              0.47696399688720703,
              0.7321010231971741,
              0.4764479994773865,
              0.7320700287818909,
              0.47696399688720703,
              0.7320579886436462,
              0.4764479994773865,
              0.7321010231971741,
              0.4764479994773865,
              0.7321320176124573,
              0.47775599360466003,
              0.7320889830589294,
              0.47775599360466003,
              0.7321230173110962,
              0.477400004863739,
              0.7320889830589294,
              0.47775599360466003,
              0.7320809960365295,
              0.477400004863739,
              0.7321230173110962,
              0.477400004863739,
              0.7315700054168701,
              0.4542680084705353,
              0.7315269708633423,
              0.4542680084705353,
              0.7315970063209534,
              0.4553939998149872,
              0.7315269708633423,
              0.4542680084705353,
              0.7315539717674255,
              0.4553939998149872,
              0.7315970063209534,
              0.4553939998149872,
              0.7316229939460754,
              0.45649099349975586,
              0.7315800189971924,
              0.45649099349975586,
              0.7316489815711975,
              0.4575580060482025,
              0.7315800189971924,
              0.45649099349975586,
              0.7316060066223145,
              0.4575580060482025,
              0.7316489815711975,
              0.4575580060482025,
              0.7318390011787415,
              0.4655289947986603,
              0.7317960262298584,
              0.4655289947986603,
              0.7318549752235413,
              0.46620601415634155,
              0.7317960262298584,
              0.4655289947986603,
              0.7318120002746582,
              0.46620601415634155,
              0.7318549752235413,
              0.46620601415634155,
              0.7318699955940247,
              0.46683499217033386,
              0.731827974319458,
              0.46683499217033386,
              0.7318840026855469,
              0.467415988445282,
              0.731827974319458,
              0.46683499217033386,
              0.7318410277366638,
              0.467415988445282,
              0.7318840026855469,
              0.467415988445282,
              0.7319409847259521,
              0.46978598833084106,
              0.7318980097770691,
              0.46978598833084106,
              0.7319459915161133,
              0.47001200914382935,
              0.7318980097770691,
              0.46978598833084106,
              0.7319039702415466,
              0.47001200914382935,
              0.7319459915161133,
              0.47001200914382935,
              0.7318239808082581,
              0.46487900614738464,
              0.731781005859375,
              0.46487900614738464,
              0.7317910194396973,
              0.4635300040245056,
              0.731781005859375,
              0.46487900614738464,
              0.7317489981651306,
              0.4635300040245056,
              0.7317910194396973,
              0.4635300040245056,
              0.7318850159645081,
              0.46741899847984314,
              0.7318419814109802,
              0.46741899847984314,
              0.7318549752235413,
              0.46617498993873596,
              0.7318419814109802,
              0.46741899847984314,
              0.7318120002746582,
              0.46617498993873596,
              0.7318549752235413,
              0.46617498993873596,
              0.7319129705429077,
              0.46860700845718384,
              0.7318699955940247,
              0.46860700845718384,
              0.7318850159645081,
              0.46741899847984314,
              0.7318699955940247,
              0.46860700845718384,
              0.7318419814109802,
              0.46741899847984314,
              0.7318850159645081,
              0.46741899847984314,
              0.7320119738578796,
              0.47276100516319275,
              0.7319700121879578,
              0.47276100516319275,
              0.7319899797439575,
              0.4718160033226013,
              0.7319700121879578,
              0.47276100516319275,
              0.7319470047950745,
              0.4718160033226013,
              0.7319899797439575,
              0.4718160033226013,
              0.7320529818534851,
              0.4744490087032318,
              0.732010006904602,
              0.4744490087032318,
              0.7320330142974854,
              0.47363901138305664,
              0.732010006904602,
              0.4744490087032318,
              0.7319909930229187,
              0.47363901138305664,
              0.7320330142974854,
              0.47363901138305664,
              0.7239789962768555,
              0.1877090036869049,
              0.7239649891853333,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7239649891853333,
              0.1877090036869049,
              0.7243580222129822,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7235869765281677,
              0.1877090036869049,
              0.7239789962768555,
              0.1877090036869049,
              0.7235869765281677,
              0.1877090036869049,
              0.7239649891853333,
              0.1877090036869049,
              0.7239789962768555,
              0.1877090036869049,
              0.7229030132293701,
              0.1877090036869049,
              0.7228800058364868,
              0.1877090036869049,
              0.7232450246810913,
              0.1877090036869049,
              0.7228800058364868,
              0.1877090036869049,
              0.7232249975204468,
              0.1877090036869049,
              0.7232450246810913,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206119894981384,
              0.1877090036869049,
              0.7206479907035828,
              0.1877090036869049,
              0.7206119894981384,
              0.1877090036869049,
              0.7206060290336609,
              0.1877090036869049,
              0.7206479907035828,
              0.1877090036869049,
              0.7247729897499084,
              0.1877090036869049,
              0.7247650027275085,
              0.1877090036869049,
              0.7251880168914795,
              0.1877090036869049,
              0.7247650027275085,
              0.1877090036869049,
              0.7251840233802795,
              0.1877090036869049,
              0.7251880168914795,
              0.1877090036869049,
              0.7217379808425903,
              0.1877090036869049,
              0.7217040061950684,
              0.1877090036869049,
              0.721996009349823,
              0.1877090036869049,
              0.7217040061950684,
              0.1877090036869049,
              0.7219650149345398,
              0.1877090036869049,
              0.721996009349823,
              0.1877090036869049,
              0.7211120128631592,
              0.1877090036869049,
              0.7210729718208313,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7210729718208313,
              0.1877090036869049,
              0.7212569713592529,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7209569811820984,
              0.1877090036869049,
              0.7209169864654541,
              0.1877090036869049,
              0.7211120128631592,
              0.1877090036869049,
              0.7209169864654541,
              0.1877090036869049,
              0.7210729718208313,
              0.1877090036869049,
              0.7211120128631592,
              0.1877090036869049,
              0.7206479907035828,
              0.1877090036869049,
              0.7206060290336609,
              0.1877090036869049,
              0.7206770181655884,
              0.1877090036869049,
              0.7206060290336609,
              0.1877090036869049,
              0.7206339836120605,
              0.1877090036869049,
              0.7206770181655884,
              0.1877090036869049,
              0.7207580208778381,
              0.1877090036869049,
              0.7207159996032715,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7207159996032715,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7256159782409668,
              0.18976500630378723,
              0.7256159782409668,
              0.18975800275802612,
              0.7251880168914795,
              0.1897680014371872,
              0.7256159782409668,
              0.18975800275802612,
              0.7251840233802795,
              0.1897609978914261,
              0.7251880168914795,
              0.1897680014371872,
              0.7251880168914795,
              0.1897680014371872,
              0.7251840233802795,
              0.1897609978914261,
              0.7247729897499084,
              0.1897760033607483,
              0.7251840233802795,
              0.1897609978914261,
              0.7247650027275085,
              0.18976899981498718,
              0.7247729897499084,
              0.1897760033607483,
              0.7247729897499084,
              0.1897760033607483,
              0.7247650027275085,
              0.18976899981498718,
              0.7243689894676208,
              0.18978999555110931,
              0.7247650027275085,
              0.18976899981498718,
              0.7243580222129822,
              0.1897830069065094,
              0.7243689894676208,
              0.18978999555110931,
              0.7236040234565735,
              0.18983200192451477,
              0.7235869765281677,
              0.18982599675655365,
              0.7232450246810913,
              0.189860001206398,
              0.7235869765281677,
              0.18982599675655365,
              0.7232249975204468,
              0.1898539960384369,
              0.7232450246810913,
              0.189860001206398,
              0.7232450246810913,
              0.189860001206398,
              0.7232249975204468,
              0.1898539960384369,
              0.7229030132293701,
              0.18989300727844238,
              0.7232249975204468,
              0.1898539960384369,
              0.7228800058364868,
              0.18988700211048126,
              0.7229030132293701,
              0.18989300727844238,
              0.7229030132293701,
              0.18989300727844238,
              0.7228800058364868,
              0.18988700211048126,
              0.7225800156593323,
              0.1899300068616867,
              0.7228800058364868,
              0.18988700211048126,
              0.7225540280342102,
              0.18992400169372559,
              0.7225800156593323,
              0.1899300068616867,
              0.7222779989242554,
              0.189970001578331,
              0.7222489714622498,
              0.18996499478816986,
              0.721996009349823,
              0.19001400470733643,
              0.7222489714622498,
              0.18996499478816986,
              0.7219650149345398,
              0.19000999629497528,
              0.721996009349823,
              0.19001400470733643,
              0.7217379808425903,
              0.1900620013475418,
              0.7217040061950684,
              0.19005699455738068,
              0.7215030193328857,
              0.19011199474334717,
              0.7217040061950684,
              0.19005699455738068,
              0.7214679718017578,
              0.19010800123214722,
              0.7215030193328857,
              0.19011199474334717,
              0.7209569811820984,
              0.1902800053358078,
              0.7209169864654541,
              0.19027799367904663,
              0.7208330035209656,
              0.19034099578857422,
              0.7209169864654541,
              0.19027799367904663,
              0.7207909822463989,
              0.19033899903297424,
              0.7208330035209656,
              0.19034099578857422,
              0.7207390069961548,
              0.19040299952030182,
              0.7206969857215881,
              0.19040200114250183,
              0.7206770181655884,
              0.1904670000076294,
              0.7206969857215881,
              0.19040200114250183,
              0.7206339836120605,
              0.1904670000076294,
              0.7206770181655884,
              0.1904670000076294,
              0.7206479907035828,
              0.19053299725055695,
              0.7206060290336609,
              0.19053299725055695,
              0.7206550240516663,
              0.19059999287128448,
              0.7206060290336609,
              0.19053299725055695,
              0.7206119894981384,
              0.19059999287128448,
              0.7206550240516663,
              0.19059999287128448,
              0.7206550240516663,
              0.19059999287128448,
              0.7206119894981384,
              0.19059999287128448,
              0.7206969857215881,
              0.1906680017709732,
              0.7206119894981384,
              0.19059999287128448,
              0.7206550240516663,
              0.1906680017709732,
              0.7206969857215881,
              0.1906680017709732,
              0.7315859794616699,
              0.4588020145893097,
              0.7315840125083923,
              0.45870399475097656,
              0.731561005115509,
              0.45774298906326294,
              0.7315840125083923,
              0.45870399475097656,
              0.7315589785575867,
              0.45765599608421326,
              0.731561005115509,
              0.45774298906326294,
              0.7316110134124756,
              0.4598270058631897,
              0.731607973575592,
              0.4597170054912567,
              0.7315859794616699,
              0.4588020145893097,
              0.731607973575592,
              0.4597170054912567,
              0.7315840125083923,
              0.45870399475097656,
              0.7315859794616699,
              0.4588020145893097,
              0.7316570281982422,
              0.46176600456237793,
              0.7316539883613586,
              0.4616360068321228,
              0.7316349744796753,
              0.4608150124549866,
              0.7316539883613586,
              0.4616360068321228,
              0.7316319942474365,
              0.4606949985027313,
              0.7316349744796753,
              0.4608150124549866,
              0.7317559719085693,
              0.4659009873867035,
              0.7317519783973694,
              0.4657270014286041,
              0.7317389845848083,
              0.4651620090007782,
              0.7317519783973694,
              0.4657270014286041,
              0.7317349910736084,
              0.46499499678611755,
              0.7317389845848083,
              0.4651620090007782,
              0.7317889928817749,
              0.4672349989414215,
              0.7317839860916138,
              0.4670479893684387,
              0.7317730188369751,
              0.466592013835907,
              0.7317839860916138,
              0.4670479893684387,
              0.7317690253257751,
              0.4664109945297241,
              0.7317730188369751,
              0.466592013835907,
              0.7315919995307922,
              0.45903998613357544,
              0.7315940260887146,
              0.45911499857902527,
              0.7316269874572754,
              0.4605039954185486,
              0.7315940260887146,
              0.45911499857902527,
              0.7316290140151978,
              0.46059098839759827,
              0.7316269874572754,
              0.4605039954185486,
              0.7317259907722473,
              0.46462199091911316,
              0.7317289710044861,
              0.4647420048713684,
              0.7317559719085693,
              0.46589699387550354,
              0.7317289710044861,
              0.4647420048713684,
              0.7317600250244141,
              0.46602699160575867,
              0.7317559719085693,
              0.46589699387550354,
              0.7318140268325806,
              0.46828800439834595,
              0.7318170070648193,
              0.46843698620796204,
              0.7318400144577026,
              0.46939900517463684,
              0.7318170070648193,
              0.46843698620796204,
              0.7318440079689026,
              0.469556987285614,
              0.7318400144577026,
              0.46939900517463684,
              0.7320349812507629,
              0.47751399874687195,
              0.7320420145988464,
              0.47782400250434875,
              0.7320299744606018,
              0.4773010015487671,
              0.7320420145988464,
              0.47782400250434875,
              0.7320370078086853,
              0.4776090085506439,
              0.7320299744606018,
              0.4773010015487671,
              0.7316349744796753,
              0.4608150124549866,
              0.7316319942474365,
              0.4606949985027313,
              0.7316110134124756,
              0.4598270058631897,
              0.7316319942474365,
              0.4606949985027313,
              0.731607973575592,
              0.4597170054912567,
              0.7316110134124756,
              0.4598270058631897,
              0.7317389845848083,
              0.4651620090007782,
              0.7317349910736084,
              0.46499499678611755,
              0.731719970703125,
              0.4643769860267639,
              0.7317349910736084,
              0.46499499678611755,
              0.731715977191925,
              0.46421900391578674,
              0.731719970703125,
              0.4643769860267639,
              0.7318159937858582,
              0.4683690071105957,
              0.731810986995697,
              0.46817100048065186,
              0.7318029999732971,
              0.46782800555229187,
              0.731810986995697,
              0.46817100048065186,
              0.731797993183136,
              0.46763500571250916,
              0.7318029999732971,
              0.46782800555229187,
              0.7318270206451416,
              0.46885600686073303,
              0.7318220138549805,
              0.46865400671958923,
              0.7318159937858582,
              0.4683690071105957,
              0.7318220138549805,
              0.46865400671958923,
              0.731810986995697,
              0.46817100048065186,
              0.7318159937858582,
              0.4683690071105957,
              0.7318540215492249,
              0.4699830114841461,
              0.7318490147590637,
              0.46977099776268005,
              0.7318469882011414,
              0.469664990901947,
              0.7318490147590637,
              0.46977099776268005,
              0.7318419814109802,
              0.4694559872150421,
              0.7318469882011414,
              0.469664990901947,
              0.7318609952926636,
              0.4702419936656952,
              0.7318549752235413,
              0.47002899646759033,
              0.7318540215492249,
              0.4699830114841461,
              0.7318549752235413,
              0.47002899646759033,
              0.7318490147590637,
              0.46977099776268005,
              0.7318540215492249,
              0.4699830114841461,
              0.7315559983253479,
              0.45753398537635803,
              0.7315580248832703,
              0.4575969874858856,
              0.7315919995307922,
              0.45903998613357544,
              0.7315580248832703,
              0.4575969874858856,
              0.7315940260887146,
              0.45911499857902527,
              0.7315919995307922,
              0.45903998613357544,
              0.7316269874572754,
              0.4605039954185486,
              0.7316290140151978,
              0.46059098839759827,
              0.7316610217094421,
              0.4619239866733551,
              0.7316290140151978,
              0.46059098839759827,
              0.7316640019416809,
              0.46202200651168823,
              0.7316610217094421,
              0.4619239866733551,
              0.7318400144577026,
              0.46939900517463684,
              0.7318440079689026,
              0.469556987285614,
              0.7318660020828247,
              0.4704520106315613,
              0.7318440079689026,
              0.469556987285614,
              0.7318689823150635,
              0.47061899304389954,
              0.7318660020828247,
              0.4704520106315613,
              0.7318660020828247,
              0.4704520106315613,
              0.7318689823150635,
              0.47061899304389954,
              0.731889009475708,
              0.4714449942111969,
              0.7318689823150635,
              0.47061899304389954,
              0.731893002986908,
              0.47161799669265747,
              0.731889009475708,
              0.4714449942111969,
              0.7319120168685913,
              0.47237399220466614,
              0.7319160103797913,
              0.472555011510849,
              0.7319319844245911,
              0.473239004611969,
              0.7319160103797913,
              0.472555011510849,
              0.7319369912147522,
              0.4734260141849518,
              0.7319319844245911,
              0.473239004611969,
              0.7319509983062744,
              0.4740369915962219,
              0.7319560050964355,
              0.47422999143600464,
              0.7319689989089966,
              0.47476598620414734,
              0.7319560050964355,
              0.47422999143600464,
              0.7319740056991577,
              0.4749639928340912,
              0.7319689989089966,
              0.47476598620414734,
              0.7319689989089966,
              0.47476598620414734,
              0.7319740056991577,
              0.4749639928340912,
              0.7319849729537964,
              0.47542300820350647,
              0.7319740056991577,
              0.4749639928340912,
              0.7319899797439575,
              0.47562599182128906,
              0.7319849729537964,
              0.47542300820350647,
              0.7320110201835632,
              0.476516991853714,
              0.7320160269737244,
              0.4767259955406189,
              0.7320209741592407,
              0.476949006319046,
              0.7320160269737244,
              0.4767259955406189,
              0.7320259809494019,
              0.4771600067615509,
              0.7320209741592407,
              0.476949006319046,
              0.7247570157051086,
              0.18816600739955902,
              0.7247570157051086,
              0.18795199692249298,
              0.7243459820747375,
              0.18816600739955902,
              0.7247570157051086,
              0.18795199692249298,
              0.7243459820747375,
              0.18795199692249298,
              0.7243459820747375,
              0.18816600739955902,
              0.7225239872932434,
              0.18816600739955902,
              0.7225239872932434,
              0.18795199692249298,
              0.722216010093689,
              0.18816600739955902,
              0.7225239872932434,
              0.18795199692249298,
              0.722216010093689,
              0.18795199692249298,
              0.722216010093689,
              0.18816600739955902,
              0.722216010093689,
              0.18816600739955902,
              0.722216010093689,
              0.18795199692249298,
              0.7219300270080566,
              0.18816600739955902,
              0.722216010093689,
              0.18795199692249298,
              0.7219300270080566,
              0.18795199692249298,
              0.7219300270080566,
              0.18816600739955902,
              0.7216659784317017,
              0.18816600739955902,
              0.7216659784317017,
              0.18795199692249298,
              0.7214270234107971,
              0.18816600739955902,
              0.7216659784317017,
              0.18795199692249298,
              0.7214270234107971,
              0.18795199692249298,
              0.7214270234107971,
              0.18816600739955902,
              0.720649003982544,
              0.18816600739955902,
              0.720649003982544,
              0.18795199692249298,
              0.7205860018730164,
              0.18816600739955902,
              0.720649003982544,
              0.18795199692249298,
              0.7205860018730164,
              0.18795199692249298,
              0.7205860018730164,
              0.18816600739955902,
              0.7205629944801331,
              0.18816600739955902,
              0.7205629944801331,
              0.18795199692249298,
              0.7206069827079773,
              0.18816600739955902,
              0.7205629944801331,
              0.18795199692249298,
              0.7206069827079773,
              0.18795199692249298,
              0.7206069827079773,
              0.18816600739955902,
              0.7256140112876892,
              0.18816600739955902,
              0.7256140112876892,
              0.18795199692249298,
              0.7251799702644348,
              0.18816600739955902,
              0.7256140112876892,
              0.18795199692249298,
              0.7251799702644348,
              0.18795199692249298,
              0.7251799702644348,
              0.18816600739955902,
              0.723567008972168,
              0.18816600739955902,
              0.723567008972168,
              0.18795199692249298,
              0.7232009768486023,
              0.18816600739955902,
              0.723567008972168,
              0.18795199692249298,
              0.7232009768486023,
              0.18795199692249298,
              0.7232009768486023,
              0.18816600739955902,
              0.7228530049324036,
              0.18816600739955902,
              0.7228530049324036,
              0.18795199692249298,
              0.7225239872932434,
              0.18816600739955902,
              0.7228530049324036,
              0.18795199692249298,
              0.7225239872932434,
              0.18795199692249298,
              0.7225239872932434,
              0.18816600739955902,
              0.7214270234107971,
              0.18816600739955902,
              0.7214270234107971,
              0.18795199692249298,
              0.7212139964103699,
              0.18816600739955902,
              0.7214270234107971,
              0.18795199692249298,
              0.7212139964103699,
              0.18795199692249298,
              0.7212139964103699,
              0.18816600739955902,
              0.7208719849586487,
              0.18816600739955902,
              0.7208719849586487,
              0.18795199692249298,
              0.7207450270652771,
              0.18816600739955902,
              0.7208719849586487,
              0.18795199692249298,
              0.7207450270652771,
              0.18795199692249298,
              0.7207450270652771,
              0.18816600739955902,
              0.7207450270652771,
              0.18816600739955902,
              0.7207450270652771,
              0.18795199692249298,
              0.720649003982544,
              0.18816600739955902,
              0.7207450270652771,
              0.18795199692249298,
              0.720649003982544,
              0.18795199692249298,
              0.720649003982544,
              0.18816600739955902,
              0.7415549755096436,
              0.18816600739955902,
              0.7415549755096436,
              0.18795199692249298,
              0.72604900598526,
              0.18816600739955902,
              0.7415549755096436,
              0.18795199692249298,
              0.72604900598526,
              0.18795199692249298,
              0.72604900598526,
              0.18816600739955902,
              0.7239490151405334,
              0.18933799862861633,
              0.7239490151405334,
              0.18955199420452118,
              0.7243459820747375,
              0.18931899964809418,
              0.7239490151405334,
              0.18955199420452118,
              0.7243459820747375,
              0.18953299522399902,
              0.7243459820747375,
              0.18931899964809418,
              0.723567008972168,
              0.18936200439929962,
              0.723567008972168,
              0.18957599997520447,
              0.7239490151405334,
              0.18933799862861633,
              0.723567008972168,
              0.18957599997520447,
              0.7239490151405334,
              0.18955199420452118,
              0.7239490151405334,
              0.18933799862861633,
              0.722216010093689,
              0.18950200080871582,
              0.722216010093689,
              0.18971599638462067,
              0.7225239872932434,
              0.18946099281311035,
              0.722216010093689,
              0.18971599638462067,
              0.7225239872932434,
              0.1896750032901764,
              0.7225239872932434,
              0.18946099281311035,
              0.7216659784317017,
              0.1895959973335266,
              0.7216659784317017,
              0.18980999290943146,
              0.7219300270080566,
              0.18954700231552124,
              0.7216659784317017,
              0.18980999290943146,
              0.7219300270080566,
              0.1897609978914261,
              0.7219300270080566,
              0.18954700231552124,
              0.7212139964103699,
              0.18970200419425964,
              0.7212139964103699,
              0.1899159997701645,
              0.7214270234107971,
              0.18964700400829315,
              0.7212139964103699,
              0.1899159997701645,
              0.7214270234107971,
              0.189860999584198,
              0.7214270234107971,
              0.18964700400829315,
              0.7210289835929871,
              0.1897590011358261,
              0.7210289835929871,
              0.18997299671173096,
              0.7212139964103699,
              0.18970200419425964,
              0.7210289835929871,
              0.18997299671173096,
              0.7212139964103699,
              0.1899159997701645,
              0.7212139964103699,
              0.18970200419425964,
              0.7208719849586487,
              0.18981799483299255,
              0.7208719849586487,
              0.1900320053100586,
              0.7210289835929871,
              0.1897590011358261,
              0.7208719849586487,
              0.1900320053100586,
              0.7210289835929871,
              0.18997299671173096,
              0.7210289835929871,
              0.1897590011358261,
              0.720649003982544,
              0.18994399905204773,
              0.720649003982544,
              0.1901569962501526,
              0.7207450270652771,
              0.18987999856472015,
              0.720649003982544,
              0.1901569962501526,
              0.7207450270652771,
              0.190093994140625,
              0.7207450270652771,
              0.18987999856472015,
              0.7205569744110107,
              0.19007599353790283,
              0.7205569744110107,
              0.19029000401496887,
              0.7205860018730164,
              0.1900089979171753,
              0.7205569744110107,
              0.19029000401496887,
              0.7205860018730164,
              0.19022299349308014,
              0.7205860018730164,
              0.1900089979171753,
              0.7206680178642273,
              0.1902800053358078,
              0.7206680178642273,
              0.19049400091171265,
              0.7206069827079773,
              0.19021199643611908,
              0.7206680178642273,
              0.19049400091171265,
              0.7206069827079773,
              0.19042600691318512,
              0.7206069827079773,
              0.19021199643611908,
              0.7256140112876892,
              0.1892929971218109,
              0.7256140112876892,
              0.18950699269771576,
              0.72604900598526,
              0.1892929971218109,
              0.7256140112876892,
              0.18950699269771576,
              0.72604900598526,
              0.18950699269771576,
              0.72604900598526,
              0.1892929971218109,
              0.72604900598526,
              0.1892929971218109,
              0.72604900598526,
              0.18950699269771576,
              0.7415549755096436,
              0.1892929971218109,
              0.72604900598526,
              0.18950699269771576,
              0.7415549755096436,
              0.18950699269771576,
              0.7415549755096436,
              0.1892929971218109,
              0.7251880168914795,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7207580208778381,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206479907035828,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206479907035828,
              0.1877090036869049,
              0.7206770181655884,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7208330035209656,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7208330035209656,
              0.1877090036869049,
              0.7209569811820984,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7209569811820984,
              0.1877090036869049,
              0.7211120128631592,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7215030193328857,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7215030193328857,
              0.1877090036869049,
              0.7217379808425903,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7217379808425903,
              0.1877090036869049,
              0.721996009349823,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7225800156593323,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7225800156593323,
              0.1877090036869049,
              0.7229030132293701,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7229030132293701,
              0.1877090036869049,
              0.7232450246810913,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7239789962768555,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7247729897499084,
              0.1877090036869049,
              0.7251880168914795,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7236040234565735,
              0.18983200192451477,
              0.7232450246810913,
              0.189860001206398,
              0.7243689894676208,
              0.18978999555110931,
              0.7232450246810913,
              0.189860001206398,
              0.7229030132293701,
              0.18989300727844238,
              0.7243689894676208,
              0.18978999555110931,
              0.7229030132293701,
              0.18989300727844238,
              0.7225800156593323,
              0.1899300068616867,
              0.7217379808425903,
              0.1900620013475418,
              0.7225800156593323,
              0.1899300068616867,
              0.7222779989242554,
              0.189970001578331,
              0.7217379808425903,
              0.1900620013475418,
              0.7222779989242554,
              0.189970001578331,
              0.721996009349823,
              0.19001400470733643,
              0.7217379808425903,
              0.1900620013475418,
              0.7217379808425903,
              0.1900620013475418,
              0.7215030193328857,
              0.19011199474334717,
              0.7209569811820984,
              0.1902800053358078,
              0.7215030193328857,
              0.19011199474334717,
              0.7212939858436584,
              0.19016599655151367,
              0.7209569811820984,
              0.1902800053358078,
              0.7212939858436584,
              0.19016599655151367,
              0.7211120128631592,
              0.19022199511528015,
              0.7209569811820984,
              0.1902800053358078,
              0.7229030132293701,
              0.18989300727844238,
              0.7217379808425903,
              0.1900620013475418,
              0.7251880168914795,
              0.1897680014371872,
              0.7217379808425903,
              0.1900620013475418,
              0.7209569811820984,
              0.1902800053358078,
              0.7251880168914795,
              0.1897680014371872,
              0.7209569811820984,
              0.1902800053358078,
              0.7208330035209656,
              0.19034099578857422,
              0.7206479907035828,
              0.19053299725055695,
              0.7208330035209656,
              0.19034099578857422,
              0.7207390069961548,
              0.19040299952030182,
              0.7206479907035828,
              0.19053299725055695,
              0.7207390069961548,
              0.19040299952030182,
              0.7206770181655884,
              0.1904670000076294,
              0.7206479907035828,
              0.19053299725055695,
              0.7206479907035828,
              0.19053299725055695,
              0.7206550240516663,
              0.19059999287128448,
              0.7415549755096436,
              0.19073499739170074,
              0.7206550240516663,
              0.19059999287128448,
              0.7206969857215881,
              0.1906680017709732,
              0.7415549755096436,
              0.19073499739170074,
              0.7206969857215881,
              0.1906680017709732,
              0.7207580208778381,
              0.19073499739170074,
              0.7415549755096436,
              0.19073499739170074,
              0.7209569811820984,
              0.1902800053358078,
              0.7206479907035828,
              0.19053299725055695,
              0.7251880168914795,
              0.1897680014371872,
              0.7206479907035828,
              0.19053299725055695,
              0.7415549755096436,
              0.19073499739170074,
              0.7251880168914795,
              0.1897680014371872,
              0.7415549755096436,
              0.19073499739170074,
              0.7415549755096436,
              0.18976500630378723,
              0.7251880168914795,
              0.1897680014371872,
              0.7415549755096436,
              0.18976500630378723,
              0.72604900598526,
              0.18976500630378723,
              0.7251880168914795,
              0.1897680014371872,
              0.72604900598526,
              0.18976500630378723,
              0.7256159782409668,
              0.18976500630378723,
              0.7251880168914795,
              0.1897680014371872,
              0.7251880168914795,
              0.1897680014371872,
              0.7247729897499084,
              0.1897760033607483,
              0.7229030132293701,
              0.18989300727844238,
              0.7247729897499084,
              0.1897760033607483,
              0.7243689894676208,
              0.18978999555110931,
              0.7229030132293701,
              0.18989300727844238,
              0.7239789962768555,
              0.18980899453163147,
              0.7236040234565735,
              0.18983200192451477,
              0.7243689894676208,
              0.18978999555110931,
              0.7319409847259521,
              0.46978598833084106,
              0.7319459915161133,
              0.47001200914382935,
              0.7415549755096436,
              0.46978598833084106,
              0.7319459915161133,
              0.47001200914382935,
              0.7415549755096436,
              0.47001200914382935,
              0.7415549755096436,
              0.46978598833084106,
              0.731673002243042,
              0.4585919976234436,
              0.7415549755096436,
              0.4585919976234436,
              0.7316489815711975,
              0.4575580060482025,
              0.7415549755096436,
              0.4585919976234436,
              0.7415549755096436,
              0.4575580060482025,
              0.7316489815711975,
              0.4575580060482025,
              0.7316970229148865,
              0.4595929980278015,
              0.7415549755096436,
              0.4595929980278015,
              0.731673002243042,
              0.4585919976234436,
              0.7415549755096436,
              0.4595929980278015,
              0.7415549755096436,
              0.4585919976234436,
              0.731673002243042,
              0.4585919976234436,
              0.7317429780960083,
              0.4614880084991455,
              0.7415549755096436,
              0.4614880084991455,
              0.731719970703125,
              0.46055901050567627,
              0.7415549755096436,
              0.4614880084991455,
              0.7415549755096436,
              0.46055901050567627,
              0.731719970703125,
              0.46055901050567627,
              0.7318390011787415,
              0.4655289947986603,
              0.7415549755096436,
              0.4655289947986603,
              0.7318220138549805,
              0.4648059904575348,
              0.7415549755096436,
              0.4655289947986603,
              0.7415549755096436,
              0.4648059904575348,
              0.7318220138549805,
              0.4648059904575348,
              0.7318699955940247,
              0.46683499217033386,
              0.7415549755096436,
              0.46683499217033386,
              0.7318549752235413,
              0.46620601415634155,
              0.7415549755096436,
              0.46683499217033386,
              0.7415549755096436,
              0.46620601415634155,
              0.7318549752235413,
              0.46620601415634155,
              0.731719970703125,
              0.46055901050567627,
              0.7415549755096436,
              0.46055901050567627,
              0.7316970229148865,
              0.4595929980278015,
              0.7415549755096436,
              0.46055901050567627,
              0.7415549755096436,
              0.4595929980278015,
              0.7316970229148865,
              0.4595929980278015,
              0.7318220138549805,
              0.4648059904575348,
              0.7415549755096436,
              0.4648059904575348,
              0.7318040132522583,
              0.46404001116752625,
              0.7415549755096436,
              0.4648059904575348,
              0.7415549755096436,
              0.46404001116752625,
              0.7318040132522583,
              0.46404001116752625,
              0.7318969964981079,
              0.46794599294662476,
              0.7415549755096436,
              0.46794599294662476,
              0.7318840026855469,
              0.467415988445282,
              0.7415549755096436,
              0.46794599294662476,
              0.7415549755096436,
              0.467415988445282,
              0.7318840026855469,
              0.467415988445282,
              0.7319080233573914,
              0.468423992395401,
              0.7415549755096436,
              0.468423992395401,
              0.7318969964981079,
              0.46794599294662476,
              0.7415549755096436,
              0.468423992395401,
              0.7415549755096436,
              0.46794599294662476,
              0.7318969964981079,
              0.46794599294662476,
              0.7319350242614746,
              0.46953099966049194,
              0.7415549755096436,
              0.46953099966049194,
              0.7319269776344299,
              0.469217985868454,
              0.7415549755096436,
              0.46953099966049194,
              0.7415549755096436,
              0.469217985868454,
              0.7319269776344299,
              0.469217985868454,
              0.7319409847259521,
              0.46978598833084106,
              0.7415549755096436,
              0.46978598833084106,
              0.7319350242614746,
              0.46953099966049194,
              0.7415549755096436,
              0.46978598833084106,
              0.7415549755096436,
              0.46953099966049194,
              0.7319350242614746,
              0.46953099966049194,
              0.7316229939460754,
              0.45649099349975586,
              0.7415549755096436,
              0.45649099349975586,
              0.7315970063209534,
              0.4553939998149872,
              0.7415549755096436,
              0.45649099349975586,
              0.7415549755096436,
              0.4553939998149872,
              0.7315970063209534,
              0.4553939998149872,
              0.731764018535614,
              0.46237900853157043,
              0.7415549755096436,
              0.46237900853157043,
              0.7317429780960083,
              0.4614880084991455,
              0.7415549755096436,
              0.46237900853157043,
              0.7415549755096436,
              0.4614880084991455,
              0.7317429780960083,
              0.4614880084991455,
              0.7317839860916138,
              0.4632300138473511,
              0.7415549755096436,
              0.4632300138473511,
              0.731764018535614,
              0.46237900853157043,
              0.7415549755096436,
              0.4632300138473511,
              0.7415549755096436,
              0.46237900853157043,
              0.731764018535614,
              0.46237900853157043,
              0.7318040132522583,
              0.46404001116752625,
              0.7415549755096436,
              0.46404001116752625,
              0.7317839860916138,
              0.4632300138473511,
              0.7415549755096436,
              0.46404001116752625,
              0.7415549755096436,
              0.4632300138473511,
              0.7317839860916138,
              0.4632300138473511,
              0.73191899061203,
              0.46884799003601074,
              0.7415549755096436,
              0.46884799003601074,
              0.7319080233573914,
              0.468423992395401,
              0.7415549755096436,
              0.46884799003601074,
              0.7415549755096436,
              0.468423992395401,
              0.7319080233573914,
              0.468423992395401,
              0.7319269776344299,
              0.469217985868454,
              0.7415549755096436,
              0.469217985868454,
              0.73191899061203,
              0.46884799003601074,
              0.7415549755096436,
              0.469217985868454,
              0.7415549755096436,
              0.46884799003601074,
              0.73191899061203,
              0.46884799003601074,
              0.7315970063209534,
              0.4553939998149872,
              0.7415549755096436,
              0.4553939998149872,
              0.7315700054168701,
              0.4542680084705353,
              0.7415549755096436,
              0.4553939998149872,
              0.7415549755096436,
              0.4542680084705353,
              0.7315700054168701,
              0.4542680084705353,
              0.7316489815711975,
              0.4575580060482025,
              0.7415549755096436,
              0.4575580060482025,
              0.7316229939460754,
              0.45649099349975586,
              0.7415549755096436,
              0.4575580060482025,
              0.7415549755096436,
              0.45649099349975586,
              0.7316229939460754,
              0.45649099349975586,
              0.7318549752235413,
              0.46620601415634155,
              0.7415549755096436,
              0.46620601415634155,
              0.7318390011787415,
              0.4655289947986603,
              0.7415549755096436,
              0.46620601415634155,
              0.7415549755096436,
              0.4655289947986603,
              0.7318390011787415,
              0.4655289947986603,
              0.7318840026855469,
              0.467415988445282,
              0.7415549755096436,
              0.467415988445282,
              0.7318699955940247,
              0.46683499217033386,
              0.7415549755096436,
              0.467415988445282,
              0.7415549755096436,
              0.46683499217033386,
              0.7318699955940247,
              0.46683499217033386,
              0.7316880226135254,
              0.459199994802475,
              0.7415549755096436,
              0.459199994802475,
              0.7317240238189697,
              0.4606890082359314,
              0.7415549755096436,
              0.459199994802475,
              0.7415549755096436,
              0.4606890082359314,
              0.7317240238189697,
              0.4606890082359314,
              0.7318239808082581,
              0.46487900614738464,
              0.7415549755096436,
              0.46487799286842346,
              0.7318549752235413,
              0.46617498993873596,
              0.7415549755096436,
              0.46487799286842346,
              0.7415549755096436,
              0.46617498993873596,
              0.7318549752235413,
              0.46617498993873596,
              0.7319129705429077,
              0.46860700845718384,
              0.7415549755096436,
              0.46860700845718384,
              0.731939971446991,
              0.4697369933128357,
              0.7415549755096436,
              0.46860700845718384,
              0.7415549755096436,
              0.4697369933128357,
              0.731939971446991,
              0.4697369933128357,
              0.7321839928627014,
              0.4799160063266754,
              0.732138991355896,
              0.478069007396698,
              0.7415549755096436,
              0.4799160063266754,
              0.732138991355896,
              0.478069007396698,
              0.7415549755096436,
              0.478069007396698,
              0.7415549755096436,
              0.4799160063266754,
              0.7316510081291199,
              0.4576680064201355,
              0.7415549755096436,
              0.4576680064201355,
              0.7316880226135254,
              0.459199994802475,
              0.7415549755096436,
              0.4576680064201355,
              0.7415549755096436,
              0.459199994802475,
              0.7316880226135254,
              0.459199994802475,
              0.7317240238189697,
              0.4606890082359314,
              0.7415549755096436,
              0.4606890082359314,
              0.7317579984664917,
              0.46213299036026,
              0.7415549755096436,
              0.4606890082359314,
              0.7415549755096436,
              0.46213299036026,
              0.7317579984664917,
              0.46213299036026,
              0.731939971446991,
              0.4697369933128357,
              0.7415549755096436,
              0.4697369933128357,
              0.7319660186767578,
              0.4708069860935211,
              0.7415549755096436,
              0.4697369933128357,
              0.7415549755096436,
              0.4708069860935211,
              0.7319660186767578,
              0.4708069860935211,
              0.7319660186767578,
              0.4708069860935211,
              0.7415549755096436,
              0.4708069860935211,
              0.7319899797439575,
              0.4718160033226013,
              0.7415549755096436,
              0.4708069860935211,
              0.7415549755096436,
              0.4718160033226013,
              0.7319899797439575,
              0.4718160033226013,
              0.7320119738578796,
              0.47276100516319275,
              0.7415549755096436,
              0.47275999188423157,
              0.7320330142974854,
              0.47363901138305664,
              0.7415549755096436,
              0.47275999188423157,
              0.7415549755096436,
              0.47363901138305664,
              0.7320330142974854,
              0.47363901138305664,
              0.7320529818534851,
              0.4744490087032318,
              0.7415549755096436,
              0.4744490087032318,
              0.7320700287818909,
              0.4751890003681183,
              0.7415549755096436,
              0.4744490087032318,
              0.7415549755096436,
              0.4751890003681183,
              0.7320700287818909,
              0.4751890003681183,
              0.7320700287818909,
              0.4751890003681183,
              0.7415549755096436,
              0.4751890003681183,
              0.7320860028266907,
              0.4758560061454773,
              0.7415549755096436,
              0.4751890003681183,
              0.7415549755096436,
              0.4758560061454773,
              0.7320860028266907,
              0.4758560061454773,
              0.7321130037307739,
              0.47696399688720703,
              0.7415549755096436,
              0.47696399688720703,
              0.7321230173110962,
              0.477400004863739,
              0.7415549755096436,
              0.47696399688720703,
              0.7415549755096436,
              0.477400004863739,
              0.7321230173110962,
              0.477400004863739,
              0.7316139936447144,
              0.45609501004219055,
              0.7415549755096436,
              0.45609501004219055,
              0.7316510081291199,
              0.4576680064201355,
              0.7415549755096436,
              0.45609501004219055,
              0.7415549755096436,
              0.4576680064201355,
              0.7316510081291199,
              0.4576680064201355,
              0.7317579984664917,
              0.46213299036026,
              0.7415549755096436,
              0.46213299036026,
              0.7317910194396973,
              0.4635300040245056,
              0.7415549755096436,
              0.46213299036026,
              0.7415549755096436,
              0.4635300040245056,
              0.7317910194396973,
              0.4635300040245056,
              0.7320860028266907,
              0.4758560061454773,
              0.7415549755096436,
              0.4758560061454773,
              0.7321010231971741,
              0.4764479994773865,
              0.7415549755096436,
              0.4758560061454773,
              0.7415549755096436,
              0.4764479994773865,
              0.7321010231971741,
              0.4764479994773865,
              0.7321010231971741,
              0.4764479994773865,
              0.7415549755096436,
              0.4764479994773865,
              0.7321130037307739,
              0.47696399688720703,
              0.7415549755096436,
              0.4764479994773865,
              0.7415549755096436,
              0.47696399688720703,
              0.7321130037307739,
              0.47696399688720703,
              0.7321230173110962,
              0.477400004863739,
              0.7415549755096436,
              0.477400004863739,
              0.7321320176124573,
              0.47775599360466003,
              0.7415549755096436,
              0.477400004863739,
              0.7415549755096436,
              0.47775599360466003,
              0.7321320176124573,
              0.47775599360466003,
              0.7317910194396973,
              0.4635300040245056,
              0.7415549755096436,
              0.4635300040245056,
              0.7318239808082581,
              0.46487900614738464,
              0.7415549755096436,
              0.4635300040245056,
              0.7415549755096436,
              0.46487799286842346,
              0.7318239808082581,
              0.46487900614738464,
              0.7318549752235413,
              0.46617498993873596,
              0.7415549755096436,
              0.46617498993873596,
              0.7318850159645081,
              0.46741899847984314,
              0.7415549755096436,
              0.46617498993873596,
              0.7415549755096436,
              0.46741899847984314,
              0.7318850159645081,
              0.46741899847984314,
              0.7318850159645081,
              0.46741899847984314,
              0.7415549755096436,
              0.46741899847984314,
              0.7319129705429077,
              0.46860700845718384,
              0.7415549755096436,
              0.46741899847984314,
              0.7415549755096436,
              0.46860700845718384,
              0.7319129705429077,
              0.46860700845718384,
              0.7319899797439575,
              0.4718160033226013,
              0.7415549755096436,
              0.4718160033226013,
              0.7320119738578796,
              0.47276100516319275,
              0.7415549755096436,
              0.4718160033226013,
              0.7415549755096436,
              0.47275999188423157,
              0.7320119738578796,
              0.47276100516319275,
              0.7320330142974854,
              0.47363901138305664,
              0.7415549755096436,
              0.47363901138305664,
              0.7320529818534851,
              0.4744490087032318,
              0.7415549755096436,
              0.47363901138305664,
              0.7415549755096436,
              0.4744490087032318,
              0.7320529818534851,
              0.4744490087032318,
              0.7298769950866699,
              0.3853900134563446,
              0.7299200296401978,
              0.3853900134563446,
              0.7315319776535034,
              0.4545019865036011,
              0.7299200296401978,
              0.3853900134563446,
              0.7315750122070312,
              0.4545019865036011,
              0.7315319776535034,
              0.4545019865036011,
              0.7299200296401978,
              0.3853900134563446,
              0.7415549755096436,
              0.3853900134563446,
              0.7315750122070312,
              0.4545019865036011,
              0.7415549755096436,
              0.3853900134563446,
              0.7415549755096436,
              0.4545019865036011,
              0.7315750122070312,
              0.4545019865036011,
              0.7315340042114258,
              0.45608100295066833,
              0.7315199971199036,
              0.4560379981994629,
              0.7314950227737427,
              0.45449098944664,
              0.7315199971199036,
              0.4560379981994629,
              0.7314820289611816,
              0.454459011554718,
              0.7314950227737427,
              0.45449098944664,
              0.7314900159835815,
              0.4542819857597351,
              0.7315269708633423,
              0.4542680084705353,
              0.7314630150794983,
              0.4531320035457611,
              0.7315269708633423,
              0.4542680084705353,
              0.7314990162849426,
              0.45312100648880005,
              0.7314630150794983,
              0.4531320035457611,
              0.7315319776535034,
              0.4545019865036011,
              0.7315700054168701,
              0.45609501004219055,
              0.7314950227737427,
              0.45449098944664,
              0.7315700054168701,
              0.45609501004219055,
              0.7315340042114258,
              0.45608100295066833,
              0.7314950227737427,
              0.45449098944664,
              0.7314789891242981,
              0.45432499051094055,
              0.7314900159835815,
              0.4542819857597351,
              0.7314509749412537,
              0.4531640112400055,
              0.7314900159835815,
              0.4542819857597351,
              0.7314630150794983,
              0.4531320035457611,
              0.7314509749412537,
              0.4531640112400055,
              0.7315189838409424,
              0.45598798990249634,
              0.7314800024032593,
              0.4543749988079071,
              0.7314810156822205,
              0.4544210135936737,
              0.7314800024032593,
              0.4543749988079071,
              0.7314519882202148,
              0.4532020092010498,
              0.7314810156822205,
              0.4544210135936737,
              0.7315199971199036,
              0.4560379981994629,
              0.7315189838409424,
              0.45598798990249634,
              0.7314820289611816,
              0.454459011554718,
              0.7315189838409424,
              0.45598798990249634,
              0.7314810156822205,
              0.4544210135936737,
              0.7314820289611816,
              0.454459011554718,
              0.7315269708633423,
              0.4542680084705353,
              0.7315700054168701,
              0.4542680084705353,
              0.7314990162849426,
              0.45312100648880005,
              0.7315700054168701,
              0.4542680084705353,
              0.7315419912338257,
              0.45312100648880005,
              0.7314990162849426,
              0.45312100648880005,
              0.7315700054168701,
              0.4542680084705353,
              0.7415549755096436,
              0.4542680084705353,
              0.7315419912338257,
              0.45312100648880005,
              0.7415549755096436,
              0.4542680084705353,
              0.7415549755096436,
              0.45312100648880005,
              0.7315419912338257,
              0.45312100648880005,
              0.7318689823150635,
              0.47007301449775696,
              0.7318630218505859,
              0.4698469936847687,
              0.7318609952926636,
              0.4702570140361786,
              0.7318630218505859,
              0.4698469936847687,
              0.7318549752235413,
              0.47002899646759033,
              0.7318609952926636,
              0.4702570140361786,
              0.7320590019226074,
              0.4780080020427704,
              0.7320510149002075,
              0.4776949882507324,
              0.7320970296859741,
              0.478069007396698,
              0.7320510149002075,
              0.4776949882507324,
              0.7320889830589294,
              0.47775599360466003,
              0.7320970296859741,
              0.478069007396698,
              0.7319039702415466,
              0.47001200914382935,
              0.7318980097770691,
              0.46978598833084106,
              0.7318689823150635,
              0.47007301449775696,
              0.7318980097770691,
              0.46978598833084106,
              0.7318630218505859,
              0.4698469936847687,
              0.7318689823150635,
              0.47007301449775696,
              0.7320420145988464,
              0.47782400250434875,
              0.7320349812507629,
              0.47751399874687195,
              0.7320590019226074,
              0.4780080020427704,
              0.7320349812507629,
              0.47751399874687195,
              0.7320510149002075,
              0.4776949882507324,
              0.7320590019226074,
              0.4780080020427704,
              0.7320370078086853,
              0.4776090085506439,
              0.7320820093154907,
              0.47947001457214355,
              0.7318660020828247,
              0.4704720079898834,
              0.7320820093154907,
              0.47947001457214355,
              0.7319160103797913,
              0.4725480079650879,
              0.7318660020828247,
              0.4704720079898834,
              0.7318609952926636,
              0.4702570140361786,
              0.7318549752235413,
              0.47002899646759033,
              0.7318660020828247,
              0.4704720079898834,
              0.7318549752235413,
              0.47002899646759033,
              0.7318609952926636,
              0.4702419936656952,
              0.7318660020828247,
              0.4704720079898834,
              0.7320970296859741,
              0.478069007396698,
              0.7320889830589294,
              0.47775599360466003,
              0.732138991355896,
              0.478069007396698,
              0.7320889830589294,
              0.47775599360466003,
              0.7321320176124573,
              0.47775599360466003,
              0.732138991355896,
              0.478069007396698,
              0.7319539785385132,
              0.47210100293159485,
              0.7319959998130798,
              0.47210100293159485,
              0.7319039702415466,
              0.47001200914382935,
              0.7319959998130798,
              0.47210100293159485,
              0.7319459915161133,
              0.47001200914382935,
              0.7319039702415466,
              0.47001200914382935,
              0.7320870161056519,
              0.4796789884567261,
              0.7320820093154907,
              0.47947001457214355,
              0.7320420145988464,
              0.47782400250434875,
              0.7320820093154907,
              0.47947001457214355,
              0.7320370078086853,
              0.4776090085506439,
              0.7320420145988464,
              0.47782400250434875,
              0.7319959998130798,
              0.47210100293159485,
              0.7415549755096436,
              0.47210100293159485,
              0.7319459915161133,
              0.47001200914382935,
              0.7415549755096436,
              0.47210100293159485,
              0.7415549755096436,
              0.47001200914382935,
              0.7319459915161133,
              0.47001200914382935,
              0.7321320176124573,
              0.47775599360466003,
              0.7415549755096436,
              0.47775599360466003,
              0.732138991355896,
              0.478069007396698,
              0.7415549755096436,
              0.47775599360466003,
              0.7415549755096436,
              0.478069007396698,
              0.732138991355896,
              0.478069007396698,
              0.7314820289611816,
              0.454459011554718,
              0.7298259735107422,
              0.38531601428985596,
              0.7314950227737427,
              0.45449098944664,
              0.7298259735107422,
              0.38531601428985596,
              0.7298399806022644,
              0.38537099957466125,
              0.7314950227737427,
              0.45449098944664,
              0.7314990162849426,
              0.45312100648880005,
              0.7298200130462646,
              0.3830209970474243,
              0.7314630150794983,
              0.4531320035457611,
              0.7298200130462646,
              0.3830209970474243,
              0.7297840118408203,
              0.3830389976501465,
              0.7314630150794983,
              0.4531320035457611,
              0.7315319776535034,
              0.4545019865036011,
              0.7314950227737427,
              0.45449098944664,
              0.7298769950866699,
              0.3853900134563446,
              0.7314950227737427,
              0.45449098944664,
              0.7298399806022644,
              0.38537099957466125,
              0.7298769950866699,
              0.3853900134563446,
              0.7297729849815369,
              0.38309401273727417,
              0.729773998260498,
              0.38315799832344055,
              0.7314509749412537,
              0.4531640112400055,
              0.729773998260498,
              0.38315799832344055,
              0.7314519882202148,
              0.4532020092010498,
              0.7314509749412537,
              0.4531640112400055,
              0.7415549755096436,
              0.3830209970474243,
              0.7298629879951477,
              0.3830209970474243,
              0.7415549755096436,
              0.45312100648880005,
              0.7298629879951477,
              0.3830209970474243,
              0.7315419912338257,
              0.45312100648880005,
              0.7415549755096436,
              0.45312100648880005,
              0.7314509749412537,
              0.4531640112400055,
              0.7314630150794983,
              0.4531320035457611,
              0.7297729849815369,
              0.38309401273727417,
              0.7314630150794983,
              0.4531320035457611,
              0.7297840118408203,
              0.3830389976501465,
              0.7297729849815369,
              0.38309401273727417,
              0.7314810156822205,
              0.4544210135936737,
              0.7314519882202148,
              0.4532020092010498,
              0.7298250198364258,
              0.3852519989013672,
              0.7314519882202148,
              0.4532020092010498,
              0.729773998260498,
              0.38315799832344055,
              0.7298250198364258,
              0.3852519989013672,
              0.7298259735107422,
              0.38531601428985596,
              0.7314820289611816,
              0.454459011554718,
              0.7298250198364258,
              0.3852519989013672,
              0.7314820289611816,
              0.454459011554718,
              0.7314810156822205,
              0.4544210135936737,
              0.7298250198364258,
              0.3852519989013672,
              0.7314990162849426,
              0.45312100648880005,
              0.7315419912338257,
              0.45312100648880005,
              0.7298200130462646,
              0.3830209970474243,
              0.7315419912338257,
              0.45312100648880005,
              0.7298629879951477,
              0.3830209970474243,
              0.7298200130462646,
              0.3830209970474243,
              0.7579089999198914,
              0.1938759982585907,
              0.7579740285873413,
              0.1923390030860901,
              0.7579240202903748,
              0.19402800500392914,
              0.7579740285873413,
              0.1923390030860901,
              0.7579889893531799,
              0.19249999523162842,
              0.7579240202903748,
              0.19402800500392914,
              0.7577850222587585,
              0.19749200344085693,
              0.7578489780426025,
              0.19559800624847412,
              0.7577999830245972,
              0.19762100279331207,
              0.7578489780426025,
              0.19559800624847412,
              0.7578639984130859,
              0.1957390010356903,
              0.7577999830245972,
              0.19762100279331207,
              0.7577239871025085,
              0.1995439976453781,
              0.7577850222587585,
              0.19749200344085693,
              0.7577390074729919,
              0.19965900480747223,
              0.7577850222587585,
              0.19749200344085693,
              0.7577999830245972,
              0.19762100279331207,
              0.7577390074729919,
              0.19965900480747223,
              0.7576709985733032,
              0.20173899829387665,
              0.7577239871025085,
              0.1995439976453781,
              0.7576860189437866,
              0.20184099674224854,
              0.7577239871025085,
              0.1995439976453781,
              0.7577390074729919,
              0.19965900480747223,
              0.7576860189437866,
              0.20184099674224854,
              0.7576149702072144,
              0.2040649950504303,
              0.7576709985733032,
              0.20173899829387665,
              0.7576289772987366,
              0.20415200293064117,
              0.7576709985733032,
              0.20173899829387665,
              0.7576860189437866,
              0.20184099674224854,
              0.7576289772987366,
              0.20415200293064117,
              0.7574949860572815,
              0.20905299484729767,
              0.7575560212135315,
              0.20650799572467804,
              0.7575089931488037,
              0.20910799503326416,
              0.7575560212135315,
              0.20650799572467804,
              0.7575709819793701,
              0.20657899975776672,
              0.7575089931488037,
              0.20910799503326416,
              0.7571640014648438,
              0.22284600138664246,
              0.7572320103645325,
              0.21999099850654602,
              0.7571769952774048,
              0.22281399369239807,
              0.7572320103645325,
              0.21999099850654602,
              0.7572460174560547,
              0.219977006316185,
              0.7571769952774048,
              0.22281399369239807,
              0.7570949792861938,
              0.22572100162506104,
              0.7571640014648438,
              0.22284600138664246,
              0.7571079730987549,
              0.22567200660705566,
              0.7571640014648438,
              0.22284600138664246,
              0.7571769952774048,
              0.22281399369239807,
              0.7571079730987549,
              0.22567200660705566,
              0.7570260167121887,
              0.2286040037870407,
              0.7570949792861938,
              0.22572100162506104,
              0.7570390105247498,
              0.22853699326515198,
              0.7570949792861938,
              0.22572100162506104,
              0.7571079730987549,
              0.22567200660705566,
              0.7570390105247498,
              0.22853699326515198,
              0.7584130167961121,
              0.1951570063829422,
              0.7583500146865845,
              0.1953279972076416,
              0.7584260106086731,
              0.19497300684452057,
              0.7583500146865845,
              0.1953279972076416,
              0.7583630084991455,
              0.19514399766921997,
              0.7584260106086731,
              0.19497300684452057,
              0.7582899928092957,
              0.19568899273872375,
              0.7582319974899292,
              0.19623200595378876,
              0.7583019733428955,
              0.1955060064792633,
              0.7582319974899292,
              0.19623200595378876,
              0.7582430243492126,
              0.19605199992656708,
              0.7583019733428955,
              0.1955060064792633,
              0.7581239938735962,
              0.19781999289989471,
              0.7580729722976685,
              0.1988459974527359,
              0.7581340074539185,
              0.1976509988307953,
              0.7580729722976685,
              0.1988459974527359,
              0.7580829858779907,
              0.19868500530719757,
              0.7581340074539185,
              0.1976509988307953,
              0.7571600079536438,
              0.22360000014305115,
              0.7570949792861938,
              0.2257159948348999,
              0.7571700215339661,
              0.2236499935388565,
              0.7570949792861938,
              0.2257159948348999,
              0.7571049928665161,
              0.22578300535678864,
              0.7571700215339661,
              0.2236499935388565,
              0.7572259902954102,
              0.22148799896240234,
              0.7571600079536438,
              0.22360000014305115,
              0.7572370171546936,
              0.22152000665664673,
              0.7571600079536438,
              0.22360000014305115,
              0.7571700215339661,
              0.2236499935388565,
              0.7572370171546936,
              0.22152000665664673,
              0.7575719952583313,
              0.211326003074646,
              0.757502019405365,
              0.21327200531959534,
              0.7575820088386536,
              0.2112710028886795,
              0.757502019405365,
              0.21327200531959534,
              0.7575129866600037,
              0.21323400735855103,
              0.7575820088386536,
              0.2112710028886795,
              0.75764000415802,
              0.20944300293922424,
              0.7575719952583313,
              0.211326003074646,
              0.7576509714126587,
              0.20937199890613556,
              0.7575719952583313,
              0.211326003074646,
              0.7575820088386536,
              0.2112710028886795,
              0.7576509714126587,
              0.20937199890613556,
              0.7577750086784363,
              0.2059040069580078,
              0.7577080130577087,
              0.20763200521469116,
              0.7577840089797974,
              0.2058030068874359,
              0.7577080130577087,
              0.20763200521469116,
              0.757718026638031,
              0.20754599571228027,
              0.7577840089797974,
              0.2058030068874359,
              0.758018970489502,
              0.20001399517059326,
              0.7579619884490967,
              0.20131400227546692,
              0.7580289840698242,
              0.199863001704216,
              0.7579619884490967,
              0.20131400227546692,
              0.757972002029419,
              0.20117299258708954,
              0.7580289840698242,
              0.199863001704216,
              0.7580729722976685,
              0.1988459974527359,
              0.758018970489502,
              0.20001399517059326,
              0.7580829858779907,
              0.19868500530719757,
              0.758018970489502,
              0.20001399517059326,
              0.7580289840698242,
              0.199863001704216,
              0.7580829858779907,
              0.19868500530719757,
              0.7570260167121887,
              0.2286040037870407,
              0.7570390105247498,
              0.22853699326515198,
              0.7569580078125,
              0.23144599795341492,
              0.7570390105247498,
              0.22853699326515198,
              0.7569720149040222,
              0.2313610017299652,
              0.7569580078125,
              0.23144599795341492,
              0.7584260106086731,
              0.19497300684452057,
              0.7584890127182007,
              0.19490200281143188,
              0.7584130167961121,
              0.1951570063829422,
              0.7584890127182007,
              0.19490200281143188,
              0.7584760189056396,
              0.1950870007276535,
              0.7584130167961121,
              0.1951570063829422,
              0.7584400177001953,
              0.18776999413967133,
              0.7583540081977844,
              0.18790900707244873,
              0.7584030032157898,
              0.1877090036869049,
              0.7583540081977844,
              0.18790900707244873,
              0.7583180069923401,
              0.18784800171852112,
              0.7584030032157898,
              0.1877090036869049,
              0.7583540081977844,
              0.18790900707244873,
              0.7582719922065735,
              0.1883150041103363,
              0.7583180069923401,
              0.18784800171852112,
              0.7582719922065735,
              0.1883150041103363,
              0.758234977722168,
              0.1882539987564087,
              0.7583180069923401,
              0.18784800171852112,
              0.7582719922065735,
              0.1883150041103363,
              0.7581930160522461,
              0.1889750063419342,
              0.758234977722168,
              0.1882539987564087,
              0.7581930160522461,
              0.1889750063419342,
              0.7581549882888794,
              0.18891499936580658,
              0.758234977722168,
              0.1882539987564087,
              0.7581930160522461,
              0.1889750063419342,
              0.7581160068511963,
              0.18987399339675903,
              0.7581549882888794,
              0.18891499936580658,
              0.7581160068511963,
              0.18987399339675903,
              0.7580789923667908,
              0.18981599807739258,
              0.7581549882888794,
              0.18891499936580658,
              0.7581160068511963,
              0.18987399339675903,
              0.7580429911613464,
              0.19099999964237213,
              0.7580789923667908,
              0.18981599807739258,
              0.7580429911613464,
              0.19099999964237213,
              0.7580059766769409,
              0.19094400107860565,
              0.7580789923667908,
              0.18981599807739258,
              0.7580429911613464,
              0.19099999964237213,
              0.7579740285873413,
              0.1923390030860901,
              0.7580059766769409,
              0.19094400107860565,
              0.7579740285873413,
              0.1923390030860901,
              0.7579370141029358,
              0.19228500127792358,
              0.7580059766769409,
              0.19094400107860565,
              0.7579089999198914,
              0.1938759982585907,
              0.7578489780426025,
              0.19559800624847412,
              0.7578719854354858,
              0.19382600486278534,
              0.7578489780426025,
              0.19559800624847412,
              0.7578110098838806,
              0.19555099308490753,
              0.7578719854354858,
              0.19382600486278534,
              0.7576149702072144,
              0.2040649950504303,
              0.7575560212135315,
              0.20650799572467804,
              0.7575780153274536,
              0.20403599739074707,
              0.7575560212135315,
              0.20650799572467804,
              0.757519006729126,
              0.20648400485515594,
              0.7575780153274536,
              0.20403599739074707,
              0.7574949860572815,
              0.20905299484729767,
              0.7574319839477539,
              0.21168799698352814,
              0.757457971572876,
              0.2090349942445755,
              0.7574319839477539,
              0.21168799698352814,
              0.757394015789032,
              0.2116750031709671,
              0.757457971572876,
              0.2090349942445755,
              0.7574319839477539,
              0.21168799698352814,
              0.7573670148849487,
              0.21439799666404724,
              0.757394015789032,
              0.2116750031709671,
              0.7573670148849487,
              0.21439799666404724,
              0.757328987121582,
              0.21439099311828613,
              0.757394015789032,
              0.2116750031709671,
              0.7573670148849487,
              0.21439799666404724,
              0.7573000192642212,
              0.21717000007629395,
              0.757328987121582,
              0.21439099311828613,
              0.7573000192642212,
              0.21717000007629395,
              0.7572630047798157,
              0.21716900169849396,
              0.757328987121582,
              0.21439099311828613,
              0.7573000192642212,
              0.21717000007629395,
              0.7572320103645325,
              0.21999099850654602,
              0.7572630047798157,
              0.21716900169849396,
              0.7572320103645325,
              0.21999099850654602,
              0.757194995880127,
              0.21999500691890717,
              0.7572630047798157,
              0.21716900169849396,
              0.7582899928092957,
              0.19568899273872375,
              0.7583500146865845,
              0.1953279972076416,
              0.7582539916038513,
              0.19575099647045135,
              0.7583500146865845,
              0.1953279972076416,
              0.7583140134811401,
              0.1953900009393692,
              0.7582539916038513,
              0.19575099647045135,
              0.7581760287284851,
              0.19694499671459198,
              0.7582319974899292,
              0.19623200595378876,
              0.7581400275230408,
              0.19700400531291962,
              0.7582319974899292,
              0.19623200595378876,
              0.7581959962844849,
              0.1962919980287552,
              0.7581400275230408,
              0.19700400531291962,
              0.7581239938735962,
              0.19781999289989471,
              0.7581760287284851,
              0.19694499671459198,
              0.7580869793891907,
              0.1978760063648224,
              0.7581760287284851,
              0.19694499671459198,
              0.7581400275230408,
              0.19700400531291962,
              0.7580869793891907,
              0.1978760063648224,
              0.7572259902954102,
              0.22148799896240234,
              0.7572939991950989,
              0.21938900649547577,
              0.7571899890899658,
              0.22147700190544128,
              0.7572939991950989,
              0.21938900649547577,
              0.7572579979896545,
              0.21938399970531464,
              0.7571899890899658,
              0.22147700190544128,
              0.7572939991950989,
              0.21938900649547577,
              0.7573630213737488,
              0.2173140048980713,
              0.7572579979896545,
              0.21938399970531464,
              0.7573630213737488,
              0.2173140048980713,
              0.7573270201683044,
              0.21731500327587128,
              0.7572579979896545,
              0.21938399970531464,
              0.7573630213737488,
              0.2173140048980713,
              0.7574329972267151,
              0.21527099609375,
              0.7573270201683044,
              0.21731500327587128,
              0.7574329972267151,
              0.21527099609375,
              0.7573959827423096,
              0.2152779996395111,
              0.7573270201683044,
              0.21731500327587128,
              0.7574329972267151,
              0.21527099609375,
              0.757502019405365,
              0.21327200531959534,
              0.7573959827423096,
              0.2152779996395111,
              0.757502019405365,
              0.21327200531959534,
              0.7574660181999207,
              0.21328499913215637,
              0.7573959827423096,
              0.2152779996395111,
              0.75764000415802,
              0.20944300293922424,
              0.7577080130577087,
              0.20763200521469116,
              0.7576040029525757,
              0.20946699380874634,
              0.7577080130577087,
              0.20763200521469116,
              0.7576720118522644,
              0.2076610028743744,
              0.7576040029525757,
              0.20946699380874634,
              0.7577750086784363,
              0.2059040069580078,
              0.757839024066925,
              0.20426900684833527,
              0.7577390074729919,
              0.20593799650669098,
              0.757839024066925,
              0.20426900684833527,
              0.7578030228614807,
              0.20430700480937958,
              0.7577390074729919,
              0.20593799650669098,
              0.757839024066925,
              0.20426900684833527,
              0.7579020261764526,
              0.20273500680923462,
              0.7578030228614807,
              0.20430700480937958,
              0.7579020261764526,
              0.20273500680923462,
              0.7578660249710083,
              0.20277799665927887,
              0.7578030228614807,
              0.20430700480937958,
              0.7579020261764526,
              0.20273500680923462,
              0.7579619884490967,
              0.20131400227546692,
              0.7578660249710083,
              0.20277799665927887,
              0.7579619884490967,
              0.20131400227546692,
              0.7579259872436523,
              0.20136100053787231,
              0.7578660249710083,
              0.20277799665927887,
              0.7570589780807495,
              0.22569400072097778,
              0.7570090293884277,
              0.22777099907398224,
              0.7570949792861938,
              0.2257159948348999,
              0.7570090293884277,
              0.22777099907398224,
              0.7570449709892273,
              0.22779999673366547,
              0.7570949792861938,
              0.2257159948348999,
              0.7584400177001953,
              0.18776999413967133,
              0.7584030032157898,
              0.1877090036869049,
              0.758525013923645,
              0.18776999413967133,
              0.7584030032157898,
              0.1877090036869049,
              0.7584890127182007,
              0.1877090036869049,
              0.758525013923645,
              0.18776999413967133,
              0.7578719854354858,
              0.19382600486278534,
              0.7579370141029358,
              0.19228500127792358,
              0.7579089999198914,
              0.1938759982585907,
              0.7579370141029358,
              0.19228500127792358,
              0.7579740285873413,
              0.1923390030860901,
              0.7579089999198914,
              0.1938759982585907,
              0.7577469944953918,
              0.1974489986896515,
              0.7578110098838806,
              0.19555099308490753,
              0.7577850222587585,
              0.19749200344085693,
              0.7578110098838806,
              0.19555099308490753,
              0.7578489780426025,
              0.19559800624847412,
              0.7577850222587585,
              0.19749200344085693,
              0.7576860189437866,
              0.1995050013065338,
              0.7577469944953918,
              0.1974489986896515,
              0.7577239871025085,
              0.1995439976453781,
              0.7577469944953918,
              0.1974489986896515,
              0.7577850222587585,
              0.19749200344085693,
              0.7577239871025085,
              0.1995439976453781,
              0.7576329708099365,
              0.2017049938440323,
              0.7576860189437866,
              0.1995050013065338,
              0.7576709985733032,
              0.20173899829387665,
              0.7576860189437866,
              0.1995050013065338,
              0.7577239871025085,
              0.1995439976453781,
              0.7576709985733032,
              0.20173899829387665,
              0.7575780153274536,
              0.20403599739074707,
              0.7576329708099365,
              0.2017049938440323,
              0.7576149702072144,
              0.2040649950504303,
              0.7576329708099365,
              0.2017049938440323,
              0.7576709985733032,
              0.20173899829387665,
              0.7576149702072144,
              0.2040649950504303,
              0.757457971572876,
              0.2090349942445755,
              0.757519006729126,
              0.20648400485515594,
              0.7574949860572815,
              0.20905299484729767,
              0.757519006729126,
              0.20648400485515594,
              0.7575560212135315,
              0.20650799572467804,
              0.7574949860572815,
              0.20905299484729767,
              0.7571269869804382,
              0.22285600006580353,
              0.757194995880127,
              0.21999500691890717,
              0.7571640014648438,
              0.22284600138664246,
              0.757194995880127,
              0.21999500691890717,
              0.7572320103645325,
              0.21999099850654602,
              0.7571640014648438,
              0.22284600138664246,
              0.7570580244064331,
              0.22573800384998322,
              0.7571269869804382,
              0.22285600006580353,
              0.7570949792861938,
              0.22572100162506104,
              0.7571269869804382,
              0.22285600006580353,
              0.7571640014648438,
              0.22284600138664246,
              0.7570949792861938,
              0.22572100162506104,
              0.756987988948822,
              0.22862599790096283,
              0.7570580244064331,
              0.22573800384998322,
              0.7570260167121887,
              0.2286040037870407,
              0.7570580244064331,
              0.22573800384998322,
              0.7570949792861938,
              0.22572100162506104,
              0.7570260167121887,
              0.2286040037870407,
              0.7583760023117065,
              0.1952189952135086,
              0.7583140134811401,
              0.1953900009393692,
              0.7584130167961121,
              0.1951570063829422,
              0.7583140134811401,
              0.1953900009393692,
              0.7583500146865845,
              0.1953279972076416,
              0.7584130167961121,
              0.1951570063829422,
              0.7582539916038513,
              0.19575099647045135,
              0.7581959962844849,
              0.1962919980287552,
              0.7582899928092957,
              0.19568899273872375,
              0.7581959962844849,
              0.1962919980287552,
              0.7582319974899292,
              0.19623200595378876,
              0.7582899928092957,
              0.19568899273872375,
              0.7580869793891907,
              0.1978760063648224,
              0.7580369710922241,
              0.1988999992609024,
              0.7581239938735962,
              0.19781999289989471,
              0.7580369710922241,
              0.1988999992609024,
              0.7580729722976685,
              0.1988459974527359,
              0.7581239938735962,
              0.19781999289989471,
              0.7571229934692383,
              0.22358399629592896,
              0.7570589780807495,
              0.22569400072097778,
              0.7571600079536438,
              0.22360000014305115,
              0.7570589780807495,
              0.22569400072097778,
              0.7570949792861938,
              0.2257159948348999,
              0.7571600079536438,
              0.22360000014305115,
              0.7571899890899658,
              0.22147700190544128,
              0.7571229934692383,
              0.22358399629592896,
              0.7572259902954102,
              0.22148799896240234,
              0.7571229934692383,
              0.22358399629592896,
              0.7571600079536438,
              0.22360000014305115,
              0.7572259902954102,
              0.22148799896240234,
              0.7575349807739258,
              0.21134500205516815,
              0.7574660181999207,
              0.21328499913215637,
              0.7575719952583313,
              0.211326003074646,
              0.7574660181999207,
              0.21328499913215637,
              0.757502019405365,
              0.21327200531959534,
              0.7575719952583313,
              0.211326003074646,
              0.7576040029525757,
              0.20946699380874634,
              0.7575349807739258,
              0.21134500205516815,
              0.75764000415802,
              0.20944300293922424,
              0.7575349807739258,
              0.21134500205516815,
              0.7575719952583313,
              0.211326003074646,
              0.75764000415802,
              0.20944300293922424,
              0.7577390074729919,
              0.20593799650669098,
              0.7576720118522644,
              0.2076610028743744,
              0.7577750086784363,
              0.2059040069580078,
              0.7576720118522644,
              0.2076610028743744,
              0.7577080130577087,
              0.20763200521469116,
              0.7577750086784363,
              0.2059040069580078,
              0.7579830288887024,
              0.2000650018453598,
              0.7579259872436523,
              0.20136100053787231,
              0.758018970489502,
              0.20001399517059326,
              0.7579259872436523,
              0.20136100053787231,
              0.7579619884490967,
              0.20131400227546692,
              0.758018970489502,
              0.20001399517059326,
              0.7580369710922241,
              0.1988999992609024,
              0.7579830288887024,
              0.2000650018453598,
              0.7580729722976685,
              0.1988459974527359,
              0.7579830288887024,
              0.2000650018453598,
              0.758018970489502,
              0.20001399517059326,
              0.7580729722976685,
              0.1988459974527359,
              0.756987988948822,
              0.22862599790096283,
              0.7570260167121887,
              0.2286040037870407,
              0.7569199800491333,
              0.23147399723529816,
              0.7570260167121887,
              0.2286040037870407,
              0.7569580078125,
              0.23144599795341492,
              0.7569199800491333,
              0.23147399723529816,
              0.7584130167961121,
              0.1951570063829422,
              0.7584760189056396,
              0.1950870007276535,
              0.7583760023117065,
              0.1952189952135086,
              0.7584760189056396,
              0.1950870007276535,
              0.7584390044212341,
              0.19514800608158112,
              0.7583760023117065,
              0.1952189952135086,
              0.758450984954834,
              0.18795299530029297,
              0.7583670020103455,
              0.18809199333190918,
              0.7584400177001953,
              0.18776999413967133,
              0.7583670020103455,
              0.18809199333190918,
              0.7583540081977844,
              0.18790900707244873,
              0.7584400177001953,
              0.18776999413967133,
              0.7583670020103455,
              0.18809199333190918,
              0.7582849860191345,
              0.18849800527095795,
              0.7583540081977844,
              0.18790900707244873,
              0.7582849860191345,
              0.18849800527095795,
              0.7582719922065735,
              0.1883150041103363,
              0.7583540081977844,
              0.18790900707244873,
              0.7582849860191345,
              0.18849800527095795,
              0.7582060098648071,
              0.1891549974679947,
              0.7582719922065735,
              0.1883150041103363,
              0.7582060098648071,
              0.1891549974679947,
              0.7581930160522461,
              0.1889750063419342,
              0.7582719922065735,
              0.1883150041103363,
              0.7582060098648071,
              0.1891549974679947,
              0.7581300139427185,
              0.19005000591278076,
              0.7581930160522461,
              0.1889750063419342,
              0.7581300139427185,
              0.19005000591278076,
              0.7581160068511963,
              0.18987399339675903,
              0.7581930160522461,
              0.1889750063419342,
              0.7581300139427185,
              0.19005000591278076,
              0.7580580115318298,
              0.19116899371147156,
              0.7581160068511963,
              0.18987399339675903,
              0.7580580115318298,
              0.19116899371147156,
              0.7580429911613464,
              0.19099999964237213,
              0.7581160068511963,
              0.18987399339675903,
              0.7580580115318298,
              0.19116899371147156,
              0.7579889893531799,
              0.19249999523162842,
              0.7580429911613464,
              0.19099999964237213,
              0.7579889893531799,
              0.19249999523162842,
              0.7579740285873413,
              0.1923390030860901,
              0.7580429911613464,
              0.19099999964237213,
              0.7579240202903748,
              0.19402800500392914,
              0.7578639984130859,
              0.1957390010356903,
              0.7579089999198914,
              0.1938759982585907,
              0.7578639984130859,
              0.1957390010356903,
              0.7578489780426025,
              0.19559800624847412,
              0.7579089999198914,
              0.1938759982585907,
              0.7576289772987366,
              0.20415200293064117,
              0.7575709819793701,
              0.20657899975776672,
              0.7576149702072144,
              0.2040649950504303,
              0.7575709819793701,
              0.20657899975776672,
              0.7575560212135315,
              0.20650799572467804,
              0.7576149702072144,
              0.2040649950504303,
              0.7575089931488037,
              0.20910799503326416,
              0.7574459910392761,
              0.21172599494457245,
              0.7574949860572815,
              0.20905299484729767,
              0.7574459910392761,
              0.21172599494457245,
              0.7574319839477539,
              0.21168799698352814,
              0.7574949860572815,
              0.20905299484729767,
              0.7574459910392761,
              0.21172599494457245,
              0.7573800086975098,
              0.21441900730133057,
              0.7574319839477539,
              0.21168799698352814,
              0.7573800086975098,
              0.21441900730133057,
              0.7573670148849487,
              0.21439799666404724,
              0.7574319839477539,
              0.21168799698352814,
              0.7573800086975098,
              0.21441900730133057,
              0.7573140263557434,
              0.2171739935874939,
              0.7573670148849487,
              0.21439799666404724,
              0.7573140263557434,
              0.2171739935874939,
              0.7573000192642212,
              0.21717000007629395,
              0.7573670148849487,
              0.21439799666404724,
              0.7573140263557434,
              0.2171739935874939,
              0.7572460174560547,
              0.219977006316185,
              0.7573000192642212,
              0.21717000007629395,
              0.7572460174560547,
              0.219977006316185,
              0.7572320103645325,
              0.21999099850654602,
              0.7573000192642212,
              0.21717000007629395,
              0.7583019733428955,
              0.1955060064792633,
              0.7583630084991455,
              0.19514399766921997,
              0.7582899928092957,
              0.19568899273872375,
              0.7583630084991455,
              0.19514399766921997,
              0.7583500146865845,
              0.1953279972076416,
              0.7582899928092957,
              0.19568899273872375,
              0.7581869959831238,
              0.19676999747753143,
              0.7582430243492126,
              0.19605199992656708,
              0.7581760287284851,
              0.19694499671459198,
              0.7582430243492126,
              0.19605199992656708,
              0.7582319974899292,
              0.19623200595378876,
              0.7581760287284851,
              0.19694499671459198,
              0.7581340074539185,
              0.1976509988307953,
              0.7581869959831238,
              0.19676999747753143,
              0.7581239938735962,
              0.19781999289989471,
              0.7581869959831238,
              0.19676999747753143,
              0.7581760287284851,
              0.19694499671459198,
              0.7581239938735962,
              0.19781999289989471,
              0.7572370171546936,
              0.22152000665664673,
              0.7573050260543823,
              0.2194029986858368,
              0.7572259902954102,
              0.22148799896240234,
              0.7573050260543823,
              0.2194029986858368,
              0.7572939991950989,
              0.21938900649547577,
              0.7572259902954102,
              0.22148799896240234,
              0.7573050260543823,
              0.2194029986858368,
              0.7573739886283875,
              0.21730999648571014,
              0.7572939991950989,
              0.21938900649547577,
              0.7573739886283875,
              0.21730999648571014,
              0.7573630213737488,
              0.2173140048980713,
              0.7572939991950989,
              0.21938900649547577,
              0.7573739886283875,
              0.21730999648571014,
              0.7574430108070374,
              0.21525099873542786,
              0.7573630213737488,
              0.2173140048980713,
              0.7574430108070374,
              0.21525099873542786,
              0.7574329972267151,
              0.21527099609375,
              0.7573630213737488,
              0.2173140048980713,
              0.7574430108070374,
              0.21525099873542786,
              0.7575129866600037,
              0.21323400735855103,
              0.7574329972267151,
              0.21527099609375,
              0.7575129866600037,
              0.21323400735855103,
              0.757502019405365,
              0.21327200531959534,
              0.7574329972267151,
              0.21527099609375,
              0.7576509714126587,
              0.20937199890613556,
              0.757718026638031,
              0.20754599571228027,
              0.75764000415802,
              0.20944300293922424,
              0.757718026638031,
              0.20754599571228027,
              0.7577080130577087,
              0.20763200521469116,
              0.75764000415802,
              0.20944300293922424,
              0.7577840089797974,
              0.2058030068874359,
              0.7578489780426025,
              0.20415300130844116,
              0.7577750086784363,
              0.2059040069580078,
              0.7578489780426025,
              0.20415300130844116,
              0.757839024066925,
              0.20426900684833527,
              0.7577750086784363,
              0.2059040069580078,
              0.7578489780426025,
              0.20415300130844116,
              0.7579110264778137,
              0.2026059925556183,
              0.757839024066925,
              0.20426900684833527,
              0.7579110264778137,
              0.2026059925556183,
              0.7579020261764526,
              0.20273500680923462,
              0.757839024066925,
              0.20426900684833527,
              0.7579110264778137,
              0.2026059925556183,
              0.757972002029419,
              0.20117299258708954,
              0.7579020261764526,
              0.20273500680923462,
              0.757972002029419,
              0.20117299258708954,
              0.7579619884490967,
              0.20131400227546692,
              0.7579020261764526,
              0.20273500680923462,
              0.7571049928665161,
              0.22578300535678864,
              0.7570949792861938,
              0.2257159948348999,
              0.7570549845695496,
              0.22788499295711517,
              0.7570949792861938,
              0.2257159948348999,
              0.7570449709892273,
              0.22779999673366547,
              0.7570549845695496,
              0.22788499295711517,
              0.7584400177001953,
              0.18776999413967133,
              0.758525013923645,
              0.18776999413967133,
              0.758450984954834,
              0.18795299530029297,
              0.758525013923645,
              0.18776999413967133,
              0.7585359811782837,
              0.18795299530029297,
              0.758450984954834,
              0.18795299530029297,
              0.7584270238876343,
              0.19475799798965454,
              0.7583630084991455,
              0.19492800533771515,
              0.7584499716758728,
              0.188167005777359,
              0.7583630084991455,
              0.19492800533771515,
              0.7583659887313843,
              0.1883080005645752,
              0.7584499716758728,
              0.188167005777359,
              0.7583630084991455,
              0.19492800533771515,
              0.7583010196685791,
              0.19529099762439728,
              0.7583659887313843,
              0.1883080005645752,
              0.7583010196685791,
              0.19529099762439728,
              0.7582849860191345,
              0.1887120008468628,
              0.7583659887313843,
              0.1883080005645752,
              0.7583010196685791,
              0.19529099762439728,
              0.7582420110702515,
              0.1958400011062622,
              0.7582849860191345,
              0.1887120008468628,
              0.7582420110702515,
              0.1958400011062622,
              0.7582070231437683,
              0.18936499953269958,
              0.7582849860191345,
              0.1887120008468628,
              0.7582420110702515,
              0.1958400011062622,
              0.7581859827041626,
              0.19656400382518768,
              0.7582070231437683,
              0.18936499953269958,
              0.7581859827041626,
              0.19656400382518768,
              0.7581319808959961,
              0.19025500118732452,
              0.7582070231437683,
              0.18936499953269958,
              0.7581859827041626,
              0.19656400382518768,
              0.7581310272216797,
              0.19745300710201263,
              0.7581319808959961,
              0.19025500118732452,
              0.7581310272216797,
              0.19745300710201263,
              0.7580599784851074,
              0.1913670003414154,
              0.7581319808959961,
              0.19025500118732452,
              0.7581310272216797,
              0.19745300710201263,
              0.758080005645752,
              0.1984959989786148,
              0.7580599784851074,
              0.1913670003414154,
              0.758080005645752,
              0.1984959989786148,
              0.7579920291900635,
              0.1926880031824112,
              0.7580599784851074,
              0.1913670003414154,
              0.758080005645752,
              0.1984959989786148,
              0.7580260038375854,
              0.1996850073337555,
              0.7579920291900635,
              0.1926880031824112,
              0.7580260038375854,
              0.1996850073337555,
              0.7579270005226135,
              0.19420500099658966,
              0.7579920291900635,
              0.1926880031824112,
              0.7580260038375854,
              0.1996850073337555,
              0.757968008518219,
              0.20100800693035126,
              0.7579270005226135,
              0.19420500099658966,
              0.757968008518219,
              0.20100800693035126,
              0.7578669786453247,
              0.19590400159358978,
              0.7579270005226135,
              0.19420500099658966,
              0.757968008518219,
              0.20100800693035126,
              0.7579079866409302,
              0.20245499908924103,
              0.7578669786453247,
              0.19590400159358978,
              0.7579079866409302,
              0.20245499908924103,
              0.7578030228614807,
              0.19777199625968933,
              0.7578669786453247,
              0.19590400159358978,
              0.7579079866409302,
              0.20245499908924103,
              0.7578459978103638,
              0.20401699841022491,
              0.7578030228614807,
              0.19777199625968933,
              0.7578459978103638,
              0.20401699841022491,
              0.7577419877052307,
              0.19979600608348846,
              0.7578030228614807,
              0.19777199625968933,
              0.7578459978103638,
              0.20401699841022491,
              0.757781982421875,
              0.20568299293518066,
              0.7577419877052307,
              0.19979600608348846,
              0.757781982421875,
              0.20568299293518066,
              0.7576889991760254,
              0.2019609957933426,
              0.7577419877052307,
              0.19979600608348846,
              0.757781982421875,
              0.20568299293518066,
              0.7577160000801086,
              0.2074439972639084,
              0.7576889991760254,
              0.2019609957933426,
              0.7577160000801086,
              0.2074439972639084,
              0.7576320171356201,
              0.20425400137901306,
              0.7576889991760254,
              0.2019609957933426,
              0.7576479911804199,
              0.20928800106048584,
              0.7575730085372925,
              0.20666299760341644,
              0.7577160000801086,
              0.2074439972639084,
              0.7575730085372925,
              0.20666299760341644,
              0.7576320171356201,
              0.20425400137901306,
              0.7577160000801086,
              0.2074439972639084,
              0.7575799822807312,
              0.21120700240135193,
              0.7575110197067261,
              0.20917299389839172,
              0.7576479911804199,
              0.20928800106048584,
              0.7575110197067261,
              0.20917299389839172,
              0.7575730085372925,
              0.20666299760341644,
              0.7576479911804199,
              0.20928800106048584,
              0.7575110197067261,
              0.2131900042295456,
              0.7574480175971985,
              0.21177099645137787,
              0.7575799822807312,
              0.21120700240135193,
              0.7574480175971985,
              0.21177099645137787,
              0.7575110197067261,
              0.20917299389839172,
              0.7575799822807312,
              0.21120700240135193,
              0.757440984249115,
              0.21522599458694458,
              0.7573819756507874,
              0.21444399654865265,
              0.7575110197067261,
              0.2131900042295456,
              0.7573819756507874,
              0.21444399654865265,
              0.7574480175971985,
              0.21177099645137787,
              0.7575110197067261,
              0.2131900042295456,
              0.7573720216751099,
              0.2173060029745102,
              0.757315993309021,
              0.21717800199985504,
              0.757440984249115,
              0.21522599458694458,
              0.757315993309021,
              0.21717800199985504,
              0.7573819756507874,
              0.21444399654865265,
              0.757440984249115,
              0.21522599458694458,
              0.75730299949646,
              0.21942000091075897,
              0.7572479844093323,
              0.21996000409126282,
              0.7573720216751099,
              0.2173060029745102,
              0.7572479844093323,
              0.21996000409126282,
              0.757315993309021,
              0.21717800199985504,
              0.7573720216751099,
              0.2173060029745102,
              0.7572349905967712,
              0.22155700623989105,
              0.7571790218353271,
              0.22277599573135376,
              0.75730299949646,
              0.21942000091075897,
              0.7571790218353271,
              0.22277599573135376,
              0.7572479844093323,
              0.21996000409126282,
              0.75730299949646,
              0.21942000091075897,
              0.7571690082550049,
              0.22370800375938416,
              0.7571099996566772,
              0.22561399638652802,
              0.7572349905967712,
              0.22155700623989105,
              0.7571099996566772,
              0.22561399638652802,
              0.7571790218353271,
              0.22277599573135376,
              0.7572349905967712,
              0.22155700623989105,
              0.7571030259132385,
              0.22586199641227722,
              0.7570409774780273,
              0.2284580022096634,
              0.7571690082550049,
              0.22370800375938416,
              0.7570409774780273,
              0.2284580022096634,
              0.7571099996566772,
              0.22561399638652802,
              0.7571690082550049,
              0.22370800375938416,
              0.7570409774780273,
              0.2284580022096634,
              0.7571030259132385,
              0.22586199641227722,
              0.7569739818572998,
              0.2312619984149933,
              0.7571030259132385,
              0.22586199641227722,
              0.757053017616272,
              0.2279839962720871,
              0.7569739818572998,
              0.2312619984149933,
              0.7624419927597046,
              0.18816600739955902,
              0.7624419927597046,
              0.1902800053358078,
              0.7585350275039673,
              0.188167005777359,
              0.7624419927597046,
              0.1902800053358078,
              0.7584909796714783,
              0.19468599557876587,
              0.7585350275039673,
              0.188167005777359,
              0.7579920291900635,
              0.1926880031824112,
              0.7579270005226135,
              0.19420500099658966,
              0.7579889893531799,
              0.19249999523162842,
              0.7579270005226135,
              0.19420500099658966,
              0.7579240202903748,
              0.19402800500392914,
              0.7579889893531799,
              0.19249999523162842,
              0.7578669786453247,
              0.19590400159358978,
              0.7578030228614807,
              0.19777199625968933,
              0.7578639984130859,
              0.1957390010356903,
              0.7578030228614807,
              0.19777199625968933,
              0.7577999830245972,
              0.19762100279331207,
              0.7578639984130859,
              0.1957390010356903,
              0.7578030228614807,
              0.19777199625968933,
              0.7577419877052307,
              0.19979600608348846,
              0.7577999830245972,
              0.19762100279331207,
              0.7577419877052307,
              0.19979600608348846,
              0.7577390074729919,
              0.19965900480747223,
              0.7577999830245972,
              0.19762100279331207,
              0.7577419877052307,
              0.19979600608348846,
              0.7576889991760254,
              0.2019609957933426,
              0.7577390074729919,
              0.19965900480747223,
              0.7576889991760254,
              0.2019609957933426,
              0.7576860189437866,
              0.20184099674224854,
              0.7577390074729919,
              0.19965900480747223,
              0.7576889991760254,
              0.2019609957933426,
              0.7576320171356201,
              0.20425400137901306,
              0.7576860189437866,
              0.20184099674224854,
              0.7576320171356201,
              0.20425400137901306,
              0.7576289772987366,
              0.20415200293064117,
              0.7576860189437866,
              0.20184099674224854,
              0.7575730085372925,
              0.20666299760341644,
              0.7575110197067261,
              0.20917299389839172,
              0.7575709819793701,
              0.20657899975776672,
              0.7575110197067261,
              0.20917299389839172,
              0.7575089931488037,
              0.20910799503326416,
              0.7575709819793701,
              0.20657899975776672,
              0.7572479844093323,
              0.21996000409126282,
              0.7571790218353271,
              0.22277599573135376,
              0.7572460174560547,
              0.219977006316185,
              0.7571790218353271,
              0.22277599573135376,
              0.7571769952774048,
              0.22281399369239807,
              0.7572460174560547,
              0.219977006316185,
              0.7571790218353271,
              0.22277599573135376,
              0.7571099996566772,
              0.22561399638652802,
              0.7571769952774048,
              0.22281399369239807,
              0.7571099996566772,
              0.22561399638652802,
              0.7571079730987549,
              0.22567200660705566,
              0.7571769952774048,
              0.22281399369239807,
              0.7571099996566772,
              0.22561399638652802,
              0.7570409774780273,
              0.2284580022096634,
              0.7571079730987549,
              0.22567200660705566,
              0.7570409774780273,
              0.2284580022096634,
              0.7570390105247498,
              0.22853699326515198,
              0.7571079730987549,
              0.22567200660705566,
              0.7583630084991455,
              0.19492800533771515,
              0.7584270238876343,
              0.19475799798965454,
              0.7583630084991455,
              0.19514399766921997,
              0.7584270238876343,
              0.19475799798965454,
              0.7584260106086731,
              0.19497300684452057,
              0.7583630084991455,
              0.19514399766921997,
              0.7582420110702515,
              0.1958400011062622,
              0.7583010196685791,
              0.19529099762439728,
              0.7582430243492126,
              0.19605199992656708,
              0.7583010196685791,
              0.19529099762439728,
              0.7583019733428955,
              0.1955060064792633,
              0.7582430243492126,
              0.19605199992656708,
              0.758080005645752,
              0.1984959989786148,
              0.7581310272216797,
              0.19745300710201263,
              0.7580829858779907,
              0.19868500530719757,
              0.7581310272216797,
              0.19745300710201263,
              0.7581340074539185,
              0.1976509988307953,
              0.7580829858779907,
              0.19868500530719757,
              0.7571030259132385,
              0.22586199641227722,
              0.7571690082550049,
              0.22370800375938416,
              0.7571049928665161,
              0.22578300535678864,
              0.7571690082550049,
              0.22370800375938416,
              0.7571700215339661,
              0.2236499935388565,
              0.7571049928665161,
              0.22578300535678864,
              0.7571690082550049,
              0.22370800375938416,
              0.7572349905967712,
              0.22155700623989105,
              0.7571700215339661,
              0.2236499935388565,
              0.7572349905967712,
              0.22155700623989105,
              0.7572370171546936,
              0.22152000665664673,
              0.7571700215339661,
              0.2236499935388565,
              0.7575110197067261,
              0.2131900042295456,
              0.7575799822807312,
              0.21120700240135193,
              0.7575129866600037,
              0.21323400735855103,
              0.7575799822807312,
              0.21120700240135193,
              0.7575820088386536,
              0.2112710028886795,
              0.7575129866600037,
              0.21323400735855103,
              0.7575799822807312,
              0.21120700240135193,
              0.7576479911804199,
              0.20928800106048584,
              0.7575820088386536,
              0.2112710028886795,
              0.7576479911804199,
              0.20928800106048584,
              0.7576509714126587,
              0.20937199890613556,
              0.7575820088386536,
              0.2112710028886795,
              0.7577160000801086,
              0.2074439972639084,
              0.757781982421875,
              0.20568299293518066,
              0.757718026638031,
              0.20754599571228027,
              0.757781982421875,
              0.20568299293518066,
              0.7577840089797974,
              0.2058030068874359,
              0.757718026638031,
              0.20754599571228027,
              0.757968008518219,
              0.20100800693035126,
              0.7580260038375854,
              0.1996850073337555,
              0.757972002029419,
              0.20117299258708954,
              0.7580260038375854,
              0.1996850073337555,
              0.7580289840698242,
              0.199863001704216,
              0.757972002029419,
              0.20117299258708954,
              0.7580260038375854,
              0.1996850073337555,
              0.758080005645752,
              0.1984959989786148,
              0.7580289840698242,
              0.199863001704216,
              0.758080005645752,
              0.1984959989786148,
              0.7580829858779907,
              0.19868500530719757,
              0.7580289840698242,
              0.199863001704216,
              0.7570390105247498,
              0.22853699326515198,
              0.7570409774780273,
              0.2284580022096634,
              0.7569720149040222,
              0.2313610017299652,
              0.7570409774780273,
              0.2284580022096634,
              0.7569739818572998,
              0.2312619984149933,
              0.7569720149040222,
              0.2313610017299652,
              0.7584270238876343,
              0.19475799798965454,
              0.7584909796714783,
              0.19468599557876587,
              0.7584260106086731,
              0.19497300684452057,
              0.7584909796714783,
              0.19468599557876587,
              0.7584890127182007,
              0.19490200281143188,
              0.7584260106086731,
              0.19497300684452057,
              0.7582740187644958,
              0.18784800171852112,
              0.7583600282669067,
              0.1877090036869049,
              0.7583180069923401,
              0.18784800171852112,
              0.7583600282669067,
              0.1877090036869049,
              0.7584030032157898,
              0.1877090036869049,
              0.7583180069923401,
              0.18784800171852112,
              0.7581920027732849,
              0.1882539987564087,
              0.7582740187644958,
              0.18784800171852112,
              0.758234977722168,
              0.1882539987564087,
              0.7582740187644958,
              0.18784800171852112,
              0.7583180069923401,
              0.18784800171852112,
              0.758234977722168,
              0.1882539987564087,
              0.7581120133399963,
              0.18891499936580658,
              0.7581920027732849,
              0.1882539987564087,
              0.7581549882888794,
              0.18891499936580658,
              0.7581920027732849,
              0.1882539987564087,
              0.758234977722168,
              0.1882539987564087,
              0.7581549882888794,
              0.18891499936580658,
              0.7580360174179077,
              0.18981599807739258,
              0.7581120133399963,
              0.18891499936580658,
              0.7580789923667908,
              0.18981599807739258,
              0.7581120133399963,
              0.18891499936580658,
              0.7581549882888794,
              0.18891499936580658,
              0.7580789923667908,
              0.18981599807739258,
              0.7579630017280579,
              0.19094400107860565,
              0.7580360174179077,
              0.18981599807739258,
              0.7580059766769409,
              0.19094400107860565,
              0.7580360174179077,
              0.18981599807739258,
              0.7580789923667908,
              0.18981599807739258,
              0.7580059766769409,
              0.19094400107860565,
              0.757893979549408,
              0.19228500127792358,
              0.7579630017280579,
              0.19094400107860565,
              0.7579370141029358,
              0.19228500127792358,
              0.7579630017280579,
              0.19094400107860565,
              0.7580059766769409,
              0.19094400107860565,
              0.7579370141029358,
              0.19228500127792358,
              0.7577679753303528,
              0.19555099308490753,
              0.7578290104866028,
              0.19382600486278534,
              0.7578110098838806,
              0.19555099308490753,
              0.7578290104866028,
              0.19382600486278534,
              0.7578719854354858,
              0.19382600486278534,
              0.7578110098838806,
              0.19555099308490753,
              0.7574759721755981,
              0.20648400485515594,
              0.7575349807739258,
              0.20403599739074707,
              0.757519006729126,
              0.20648400485515594,
              0.7575349807739258,
              0.20403599739074707,
              0.7575780153274536,
              0.20403599739074707,
              0.757519006729126,
              0.20648400485515594,
              0.7573509812355042,
              0.2116750031709671,
              0.7574149966239929,
              0.2090349942445755,
              0.757394015789032,
              0.2116750031709671,
              0.7574149966239929,
              0.2090349942445755,
              0.757457971572876,
              0.2090349942445755,
              0.757394015789032,
              0.2116750031709671,
              0.757286012172699,
              0.21439099311828613,
              0.7573509812355042,
              0.2116750031709671,
              0.757328987121582,
              0.21439099311828613,
              0.7573509812355042,
              0.2116750031709671,
              0.757394015789032,
              0.2116750031709671,
              0.757328987121582,
              0.21439099311828613,
              0.7572199702262878,
              0.21716900169849396,
              0.757286012172699,
              0.21439099311828613,
              0.7572630047798157,
              0.21716900169849396,
              0.757286012172699,
              0.21439099311828613,
              0.757328987121582,
              0.21439099311828613,
              0.7572630047798157,
              0.21716900169849396,
              0.7571520209312439,
              0.21999500691890717,
              0.7572199702262878,
              0.21716900169849396,
              0.757194995880127,
              0.21999500691890717,
              0.7572199702262878,
              0.21716900169849396,
              0.7572630047798157,
              0.21716900169849396,
              0.757194995880127,
              0.21999500691890717,
              0.7582709789276123,
              0.1953900009393692,
              0.7582100033760071,
              0.19575099647045135,
              0.7583140134811401,
              0.1953900009393692,
              0.7582100033760071,
              0.19575099647045135,
              0.7582539916038513,
              0.19575099647045135,
              0.7583140134811401,
              0.1953900009393692,
              0.7581520080566406,
              0.1962919980287552,
              0.7580969929695129,
              0.19700400531291962,
              0.7581959962844849,
              0.1962919980287552,
              0.7580969929695129,
              0.19700400531291962,
              0.7581400275230408,
              0.19700400531291962,
              0.7581959962844849,
              0.1962919980287552,
              0.7580969929695129,
              0.19700400531291962,
              0.7580440044403076,
              0.1978760063648224,
              0.7581400275230408,
              0.19700400531291962,
              0.7580440044403076,
              0.1978760063648224,
              0.7580869793891907,
              0.1978760063648224,
              0.7581400275230408,
              0.19700400531291962,
              0.7572140097618103,
              0.21938399970531464,
              0.7571460008621216,
              0.22147700190544128,
              0.7572579979896545,
              0.21938399970531464,
              0.7571460008621216,
              0.22147700190544128,
              0.7571899890899658,
              0.22147700190544128,
              0.7572579979896545,
              0.21938399970531464,
              0.7572829723358154,
              0.21731500327587128,
              0.7572140097618103,
              0.21938399970531464,
              0.7573270201683044,
              0.21731500327587128,
              0.7572140097618103,
              0.21938399970531464,
              0.7572579979896545,
              0.21938399970531464,
              0.7573270201683044,
              0.21731500327587128,
              0.7573530077934265,
              0.2152779996395111,
              0.7572829723358154,
              0.21731500327587128,
              0.7573959827423096,
              0.2152779996395111,
              0.7572829723358154,
              0.21731500327587128,
              0.7573270201683044,
              0.21731500327587128,
              0.7573959827423096,
              0.2152779996395111,
              0.7574219703674316,
              0.21328499913215637,
              0.7573530077934265,
              0.2152779996395111,
              0.7574660181999207,
              0.21328499913215637,
              0.7573530077934265,
              0.2152779996395111,
              0.7573959827423096,
              0.2152779996395111,
              0.7574660181999207,
              0.21328499913215637,
              0.7576289772987366,
              0.2076610028743744,
              0.7575610280036926,
              0.20946699380874634,
              0.7576720118522644,
              0.2076610028743744,
              0.7575610280036926,
              0.20946699380874634,
              0.7576040029525757,
              0.20946699380874634,
              0.7576720118522644,
              0.2076610028743744,
              0.7577599883079529,
              0.20430700480937958,
              0.7576950192451477,
              0.20593799650669098,
              0.7578030228614807,
              0.20430700480937958,
              0.7576950192451477,
              0.20593799650669098,
              0.7577390074729919,
              0.20593799650669098,
              0.7578030228614807,
              0.20430700480937958,
              0.7578229904174805,
              0.20277799665927887,
              0.7577599883079529,
              0.20430700480937958,
              0.7578660249710083,
              0.20277799665927887,
              0.7577599883079529,
              0.20430700480937958,
              0.7578030228614807,
              0.20430700480937958,
              0.7578660249710083,
              0.20277799665927887,
              0.7578830122947693,
              0.20136100053787231,
              0.7578229904174805,
              0.20277799665927887,
              0.7579259872436523,
              0.20136100053787231,
              0.7578229904174805,
              0.20277799665927887,
              0.7578660249710083,
              0.20277799665927887,
              0.7579259872436523,
              0.20136100053787231,
              0.7570149898529053,
              0.22569400072097778,
              0.7569659948348999,
              0.22777099907398224,
              0.7570589780807495,
              0.22569400072097778,
              0.7569659948348999,
              0.22777099907398224,
              0.7570090293884277,
              0.22777099907398224,
              0.7570589780807495,
              0.22569400072097778,
              0.7584030032157898,
              0.1877090036869049,
              0.7583600282669067,
              0.1877090036869049,
              0.7584890127182007,
              0.1877090036869049,
              0.7583600282669067,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7584890127182007,
              0.1877090036869049,
              0.7578290104866028,
              0.19382600486278534,
              0.757893979549408,
              0.19228500127792358,
              0.7578719854354858,
              0.19382600486278534,
              0.757893979549408,
              0.19228500127792358,
              0.7579370141029358,
              0.19228500127792358,
              0.7578719854354858,
              0.19382600486278534,
              0.7577040195465088,
              0.1974489986896515,
              0.7577679753303528,
              0.19555099308490753,
              0.7577469944953918,
              0.1974489986896515,
              0.7577679753303528,
              0.19555099308490753,
              0.7578110098838806,
              0.19555099308490753,
              0.7577469944953918,
              0.1974489986896515,
              0.7576429843902588,
              0.1995050013065338,
              0.7577040195465088,
              0.1974489986896515,
              0.7576860189437866,
              0.1995050013065338,
              0.7577040195465088,
              0.1974489986896515,
              0.7577469944953918,
              0.1974489986896515,
              0.7576860189437866,
              0.1995050013065338,
              0.7575899958610535,
              0.2017049938440323,
              0.7576429843902588,
              0.1995050013065338,
              0.7576329708099365,
              0.2017049938440323,
              0.7576429843902588,
              0.1995050013065338,
              0.7576860189437866,
              0.1995050013065338,
              0.7576329708099365,
              0.2017049938440323,
              0.7575349807739258,
              0.20403599739074707,
              0.7575899958610535,
              0.2017049938440323,
              0.7575780153274536,
              0.20403599739074707,
              0.7575899958610535,
              0.2017049938440323,
              0.7576329708099365,
              0.2017049938440323,
              0.7575780153274536,
              0.20403599739074707,
              0.7574149966239929,
              0.2090349942445755,
              0.7574759721755981,
              0.20648400485515594,
              0.757457971572876,
              0.2090349942445755,
              0.7574759721755981,
              0.20648400485515594,
              0.757519006729126,
              0.20648400485515594,
              0.757457971572876,
              0.2090349942445755,
              0.7570840120315552,
              0.22285600006580353,
              0.7571520209312439,
              0.21999500691890717,
              0.7571269869804382,
              0.22285600006580353,
              0.7571520209312439,
              0.21999500691890717,
              0.757194995880127,
              0.21999500691890717,
              0.7571269869804382,
              0.22285600006580353,
              0.7570149898529053,
              0.22573800384998322,
              0.7570840120315552,
              0.22285600006580353,
              0.7570580244064331,
              0.22573800384998322,
              0.7570840120315552,
              0.22285600006580353,
              0.7571269869804382,
              0.22285600006580353,
              0.7570580244064331,
              0.22573800384998322,
              0.756945013999939,
              0.22862599790096283,
              0.7570149898529053,
              0.22573800384998322,
              0.756987988948822,
              0.22862599790096283,
              0.7570149898529053,
              0.22573800384998322,
              0.7570580244064331,
              0.22573800384998322,
              0.756987988948822,
              0.22862599790096283,
              0.7583330273628235,
              0.1952189952135086,
              0.7582709789276123,
              0.1953900009393692,
              0.7583760023117065,
              0.1952189952135086,
              0.7582709789276123,
              0.1953900009393692,
              0.7583140134811401,
              0.1953900009393692,
              0.7583760023117065,
              0.1952189952135086,
              0.7582100033760071,
              0.19575099647045135,
              0.7581520080566406,
              0.1962919980287552,
              0.7582539916038513,
              0.19575099647045135,
              0.7581520080566406,
              0.1962919980287552,
              0.7581959962844849,
              0.1962919980287552,
              0.7582539916038513,
              0.19575099647045135,
              0.7580440044403076,
              0.1978760063648224,
              0.7579939961433411,
              0.1988999992609024,
              0.7580869793891907,
              0.1978760063648224,
              0.7579939961433411,
              0.1988999992609024,
              0.7580369710922241,
              0.1988999992609024,
              0.7580869793891907,
              0.1978760063648224,
              0.7570800185203552,
              0.22358399629592896,
              0.7570149898529053,
              0.22569400072097778,
              0.7571229934692383,
              0.22358399629592896,
              0.7570149898529053,
              0.22569400072097778,
              0.7570589780807495,
              0.22569400072097778,
              0.7571229934692383,
              0.22358399629592896,
              0.7571460008621216,
              0.22147700190544128,
              0.7570800185203552,
              0.22358399629592896,
              0.7571899890899658,
              0.22147700190544128,
              0.7570800185203552,
              0.22358399629592896,
              0.7571229934692383,
              0.22358399629592896,
              0.7571899890899658,
              0.22147700190544128,
              0.7574920058250427,
              0.21134500205516815,
              0.7574219703674316,
              0.21328499913215637,
              0.7575349807739258,
              0.21134500205516815,
              0.7574219703674316,
              0.21328499913215637,
              0.7574660181999207,
              0.21328499913215637,
              0.7575349807739258,
              0.21134500205516815,
              0.7575610280036926,
              0.20946699380874634,
              0.7574920058250427,
              0.21134500205516815,
              0.7576040029525757,
              0.20946699380874634,
              0.7574920058250427,
              0.21134500205516815,
              0.7575349807739258,
              0.21134500205516815,
              0.7576040029525757,
              0.20946699380874634,
              0.7576950192451477,
              0.20593799650669098,
              0.7576289772987366,
              0.2076610028743744,
              0.7577390074729919,
              0.20593799650669098,
              0.7576289772987366,
              0.2076610028743744,
              0.7576720118522644,
              0.2076610028743744,
              0.7577390074729919,
              0.20593799650669098,
              0.7579399943351746,
              0.2000650018453598,
              0.7578830122947693,
              0.20136100053787231,
              0.7579830288887024,
              0.2000650018453598,
              0.7578830122947693,
              0.20136100053787231,
              0.7579259872436523,
              0.20136100053787231,
              0.7579830288887024,
              0.2000650018453598,
              0.7579939961433411,
              0.1988999992609024,
              0.7579399943351746,
              0.2000650018453598,
              0.7580369710922241,
              0.1988999992609024,
              0.7579399943351746,
              0.2000650018453598,
              0.7579830288887024,
              0.2000650018453598,
              0.7580369710922241,
              0.1988999992609024,
              0.7515389919281006,
              0.45609501004219055,
              0.7514960169792175,
              0.45609501004219055,
              0.7515770196914673,
              0.4545019865036011,
              0.7514960169792175,
              0.45609501004219055,
              0.7515339851379395,
              0.4545019865036011,
              0.7515770196914673,
              0.4545019865036011,
              0.7623940110206604,
              0.19073599576950073,
              0.7623519897460938,
              0.19073499739170074,
              0.7584390044212341,
              0.19514800608158112,
              0.7623519897460938,
              0.19073499739170074,
              0.7583960294723511,
              0.19514800608158112,
              0.7584390044212341,
              0.19514800608158112,
              0.7584499716758728,
              0.188167005777359,
              0.7583659887313843,
              0.1883080005645752,
              0.758450984954834,
              0.18795299530029297,
              0.7583659887313843,
              0.1883080005645752,
              0.7583670020103455,
              0.18809199333190918,
              0.758450984954834,
              0.18795299530029297,
              0.7583659887313843,
              0.1883080005645752,
              0.7582849860191345,
              0.1887120008468628,
              0.7583670020103455,
              0.18809199333190918,
              0.7582849860191345,
              0.1887120008468628,
              0.7582849860191345,
              0.18849800527095795,
              0.7583670020103455,
              0.18809199333190918,
              0.7582849860191345,
              0.1887120008468628,
              0.7582070231437683,
              0.18936499953269958,
              0.7582849860191345,
              0.18849800527095795,
              0.7582070231437683,
              0.18936499953269958,
              0.7582060098648071,
              0.1891549974679947,
              0.7582849860191345,
              0.18849800527095795,
              0.7582070231437683,
              0.18936499953269958,
              0.7581319808959961,
              0.19025500118732452,
              0.7582060098648071,
              0.1891549974679947,
              0.7581319808959961,
              0.19025500118732452,
              0.7581300139427185,
              0.19005000591278076,
              0.7582060098648071,
              0.1891549974679947,
              0.7581319808959961,
              0.19025500118732452,
              0.7580599784851074,
              0.1913670003414154,
              0.7581300139427185,
              0.19005000591278076,
              0.7580599784851074,
              0.1913670003414154,
              0.7580580115318298,
              0.19116899371147156,
              0.7581300139427185,
              0.19005000591278076,
              0.7580599784851074,
              0.1913670003414154,
              0.7579920291900635,
              0.1926880031824112,
              0.7580580115318298,
              0.19116899371147156,
              0.7579920291900635,
              0.1926880031824112,
              0.7579889893531799,
              0.19249999523162842,
              0.7580580115318298,
              0.19116899371147156,
              0.7579270005226135,
              0.19420500099658966,
              0.7578669786453247,
              0.19590400159358978,
              0.7579240202903748,
              0.19402800500392914,
              0.7578669786453247,
              0.19590400159358978,
              0.7578639984130859,
              0.1957390010356903,
              0.7579240202903748,
              0.19402800500392914,
              0.7576320171356201,
              0.20425400137901306,
              0.7575730085372925,
              0.20666299760341644,
              0.7576289772987366,
              0.20415200293064117,
              0.7575730085372925,
              0.20666299760341644,
              0.7575709819793701,
              0.20657899975776672,
              0.7576289772987366,
              0.20415200293064117,
              0.7575110197067261,
              0.20917299389839172,
              0.7574480175971985,
              0.21177099645137787,
              0.7575089931488037,
              0.20910799503326416,
              0.7574480175971985,
              0.21177099645137787,
              0.7574459910392761,
              0.21172599494457245,
              0.7575089931488037,
              0.20910799503326416,
              0.7574480175971985,
              0.21177099645137787,
              0.7573819756507874,
              0.21444399654865265,
              0.7574459910392761,
              0.21172599494457245,
              0.7573819756507874,
              0.21444399654865265,
              0.7573800086975098,
              0.21441900730133057,
              0.7574459910392761,
              0.21172599494457245,
              0.7573819756507874,
              0.21444399654865265,
              0.757315993309021,
              0.21717800199985504,
              0.7573800086975098,
              0.21441900730133057,
              0.757315993309021,
              0.21717800199985504,
              0.7573140263557434,
              0.2171739935874939,
              0.7573800086975098,
              0.21441900730133057,
              0.757315993309021,
              0.21717800199985504,
              0.7572479844093323,
              0.21996000409126282,
              0.7573140263557434,
              0.2171739935874939,
              0.7572479844093323,
              0.21996000409126282,
              0.7572460174560547,
              0.219977006316185,
              0.7573140263557434,
              0.2171739935874939,
              0.7583010196685791,
              0.19529099762439728,
              0.7583630084991455,
              0.19492800533771515,
              0.7583019733428955,
              0.1955060064792633,
              0.7583630084991455,
              0.19492800533771515,
              0.7583630084991455,
              0.19514399766921997,
              0.7583019733428955,
              0.1955060064792633,
              0.7581859827041626,
              0.19656400382518768,
              0.7582420110702515,
              0.1958400011062622,
              0.7581869959831238,
              0.19676999747753143,
              0.7582420110702515,
              0.1958400011062622,
              0.7582430243492126,
              0.19605199992656708,
              0.7581869959831238,
              0.19676999747753143,
              0.7581310272216797,
              0.19745300710201263,
              0.7581859827041626,
              0.19656400382518768,
              0.7581340074539185,
              0.1976509988307953,
              0.7581859827041626,
              0.19656400382518768,
              0.7581869959831238,
              0.19676999747753143,
              0.7581340074539185,
              0.1976509988307953,
              0.7572349905967712,
              0.22155700623989105,
              0.75730299949646,
              0.21942000091075897,
              0.7572370171546936,
              0.22152000665664673,
              0.75730299949646,
              0.21942000091075897,
              0.7573050260543823,
              0.2194029986858368,
              0.7572370171546936,
              0.22152000665664673,
              0.75730299949646,
              0.21942000091075897,
              0.7573720216751099,
              0.2173060029745102,
              0.7573050260543823,
              0.2194029986858368,
              0.7573720216751099,
              0.2173060029745102,
              0.7573739886283875,
              0.21730999648571014,
              0.7573050260543823,
              0.2194029986858368,
              0.7573720216751099,
              0.2173060029745102,
              0.757440984249115,
              0.21522599458694458,
              0.7573739886283875,
              0.21730999648571014,
              0.757440984249115,
              0.21522599458694458,
              0.7574430108070374,
              0.21525099873542786,
              0.7573739886283875,
              0.21730999648571014,
              0.757440984249115,
              0.21522599458694458,
              0.7575110197067261,
              0.2131900042295456,
              0.7574430108070374,
              0.21525099873542786,
              0.7575110197067261,
              0.2131900042295456,
              0.7575129866600037,
              0.21323400735855103,
              0.7574430108070374,
              0.21525099873542786,
              0.7576479911804199,
              0.20928800106048584,
              0.7577160000801086,
              0.2074439972639084,
              0.7576509714126587,
              0.20937199890613556,
              0.7577160000801086,
              0.2074439972639084,
              0.757718026638031,
              0.20754599571228027,
              0.7576509714126587,
              0.20937199890613556,
              0.757781982421875,
              0.20568299293518066,
              0.7578459978103638,
              0.20401699841022491,
              0.7577840089797974,
              0.2058030068874359,
              0.7578459978103638,
              0.20401699841022491,
              0.7578489780426025,
              0.20415300130844116,
              0.7577840089797974,
              0.2058030068874359,
              0.7578459978103638,
              0.20401699841022491,
              0.7579079866409302,
              0.20245499908924103,
              0.7578489780426025,
              0.20415300130844116,
              0.7579079866409302,
              0.20245499908924103,
              0.7579110264778137,
              0.2026059925556183,
              0.7578489780426025,
              0.20415300130844116,
              0.7579079866409302,
              0.20245499908924103,
              0.757968008518219,
              0.20100800693035126,
              0.7579110264778137,
              0.2026059925556183,
              0.757968008518219,
              0.20100800693035126,
              0.757972002029419,
              0.20117299258708954,
              0.7579110264778137,
              0.2026059925556183,
              0.7516289949417114,
              0.4543749988079071,
              0.7516570091247559,
              0.4532020092010498,
              0.7516310214996338,
              0.45432499051094055,
              0.7516570091247559,
              0.4532020092010498,
              0.751658022403717,
              0.4531640112400055,
              0.7516310214996338,
              0.45432499051094055,
              0.7624419927597046,
              0.18816600739955902,
              0.7585350275039673,
              0.188167005777359,
              0.7624419927597046,
              0.18795199692249298,
              0.7585350275039673,
              0.188167005777359,
              0.7585359811782837,
              0.18795299530029297,
              0.7624419927597046,
              0.18795199692249298,
              0.7415549755096436,
              0.19073499739170074,
              0.7415549755096436,
              0.19514800608158112,
              0.7623519897460938,
              0.19073499739170074,
              0.7415549755096436,
              0.19514800608158112,
              0.7583960294723511,
              0.19514800608158112,
              0.7623519897460938,
              0.19073499739170074,
              0.7583600282669067,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7582100033760071,
              0.19575099647045135,
              0.7582709789276123,
              0.1953900009393692,
              0.7415549755096436,
              0.19574999809265137,
              0.7582709789276123,
              0.1953900009393692,
              0.7415549755096436,
              0.1953890025615692,
              0.7415549755096436,
              0.19574999809265137,
              0.7580969929695129,
              0.19700400531291962,
              0.7581520080566406,
              0.1962919980287552,
              0.7415549755096436,
              0.19700400531291962,
              0.7581520080566406,
              0.1962919980287552,
              0.7415549755096436,
              0.1962919980287552,
              0.7415549755096436,
              0.19700400531291962,
              0.7580440044403076,
              0.1978760063648224,
              0.7580969929695129,
              0.19700400531291962,
              0.7415549755096436,
              0.1978760063648224,
              0.7580969929695129,
              0.19700400531291962,
              0.7415549755096436,
              0.19700400531291962,
              0.7415549755096436,
              0.1978760063648224,
              0.7571460008621216,
              0.22147700190544128,
              0.7572140097618103,
              0.21938399970531464,
              0.7415549755096436,
              0.22147700190544128,
              0.7572140097618103,
              0.21938399970531464,
              0.7415549755096436,
              0.21938399970531464,
              0.7415549755096436,
              0.22147700190544128,
              0.7572140097618103,
              0.21938399970531464,
              0.7572829723358154,
              0.21731500327587128,
              0.7415549755096436,
              0.21938399970531464,
              0.7572829723358154,
              0.21731500327587128,
              0.7415549755096436,
              0.21731500327587128,
              0.7415549755096436,
              0.21938399970531464,
              0.7572829723358154,
              0.21731500327587128,
              0.7573530077934265,
              0.2152779996395111,
              0.7415549755096436,
              0.21731500327587128,
              0.7573530077934265,
              0.2152779996395111,
              0.7415549755096436,
              0.2152779996395111,
              0.7415549755096436,
              0.21731500327587128,
              0.7573530077934265,
              0.2152779996395111,
              0.7574219703674316,
              0.21328499913215637,
              0.7415549755096436,
              0.2152779996395111,
              0.7574219703674316,
              0.21328499913215637,
              0.7415549755096436,
              0.21328499913215637,
              0.7415549755096436,
              0.2152779996395111,
              0.7575610280036926,
              0.20946699380874634,
              0.7576289772987366,
              0.2076610028743744,
              0.7415549755096436,
              0.20946699380874634,
              0.7576289772987366,
              0.2076610028743744,
              0.7415549755096436,
              0.2076610028743744,
              0.7415549755096436,
              0.20946699380874634,
              0.7576950192451477,
              0.20593799650669098,
              0.7577599883079529,
              0.20430700480937958,
              0.7415549755096436,
              0.20593799650669098,
              0.7577599883079529,
              0.20430700480937958,
              0.7415549755096436,
              0.20430700480937958,
              0.7415549755096436,
              0.20593799650669098,
              0.7577599883079529,
              0.20430700480937958,
              0.7578229904174805,
              0.20277799665927887,
              0.7415549755096436,
              0.20430700480937958,
              0.7578229904174805,
              0.20277799665927887,
              0.7415549755096436,
              0.20277799665927887,
              0.7415549755096436,
              0.20430700480937958,
              0.7578229904174805,
              0.20277799665927887,
              0.7578830122947693,
              0.20136100053787231,
              0.7415549755096436,
              0.20277799665927887,
              0.7578830122947693,
              0.20136100053787231,
              0.7415549755096436,
              0.20136100053787231,
              0.7415549755096436,
              0.20277799665927887,
              0.7582709789276123,
              0.1953900009393692,
              0.7583330273628235,
              0.1952189952135086,
              0.7415549755096436,
              0.1953890025615692,
              0.7583330273628235,
              0.1952189952135086,
              0.7415549755096436,
              0.1952189952135086,
              0.7415549755096436,
              0.1953890025615692,
              0.7581520080566406,
              0.1962919980287552,
              0.7582100033760071,
              0.19575099647045135,
              0.7415549755096436,
              0.1962919980287552,
              0.7582100033760071,
              0.19575099647045135,
              0.7415549755096436,
              0.19574999809265137,
              0.7415549755096436,
              0.1962919980287552,
              0.7579939961433411,
              0.1988999992609024,
              0.7580440044403076,
              0.1978760063648224,
              0.7415549755096436,
              0.1988999992609024,
              0.7580440044403076,
              0.1978760063648224,
              0.7415549755096436,
              0.1978760063648224,
              0.7415549755096436,
              0.1988999992609024,
              0.7570149898529053,
              0.22569400072097778,
              0.7570800185203552,
              0.22358399629592896,
              0.7415549755096436,
              0.22569400072097778,
              0.7570800185203552,
              0.22358399629592896,
              0.7415549755096436,
              0.22358399629592896,
              0.7415549755096436,
              0.22569400072097778,
              0.7570800185203552,
              0.22358399629592896,
              0.7571460008621216,
              0.22147700190544128,
              0.7415549755096436,
              0.22358399629592896,
              0.7571460008621216,
              0.22147700190544128,
              0.7415549755096436,
              0.22147700190544128,
              0.7415549755096436,
              0.22358399629592896,
              0.7574219703674316,
              0.21328499913215637,
              0.7574920058250427,
              0.21134500205516815,
              0.7415549755096436,
              0.21328499913215637,
              0.7574920058250427,
              0.21134500205516815,
              0.7415549755096436,
              0.21134500205516815,
              0.7415549755096436,
              0.21328499913215637,
              0.7574920058250427,
              0.21134500205516815,
              0.7575610280036926,
              0.20946699380874634,
              0.7415549755096436,
              0.21134500205516815,
              0.7575610280036926,
              0.20946699380874634,
              0.7415549755096436,
              0.20946699380874634,
              0.7415549755096436,
              0.21134500205516815,
              0.7576289772987366,
              0.2076610028743744,
              0.7576950192451477,
              0.20593799650669098,
              0.7415549755096436,
              0.2076610028743744,
              0.7576950192451477,
              0.20593799650669098,
              0.7415549755096436,
              0.20593799650669098,
              0.7415549755096436,
              0.2076610028743744,
              0.7578830122947693,
              0.20136100053787231,
              0.7579399943351746,
              0.2000650018453598,
              0.7415549755096436,
              0.20136100053787231,
              0.7579399943351746,
              0.2000650018453598,
              0.7415549755096436,
              0.2000650018453598,
              0.7415549755096436,
              0.20136100053787231,
              0.7579399943351746,
              0.2000650018453598,
              0.7579939961433411,
              0.1988999992609024,
              0.7415549755096436,
              0.2000650018453598,
              0.7579939961433411,
              0.1988999992609024,
              0.7415549755096436,
              0.1988999992609024,
              0.7415549755096436,
              0.2000650018453598,
              0.7570149898529053,
              0.22569400072097778,
              0.7415549755096436,
              0.22569400072097778,
              0.7569659948348999,
              0.22777099907398224,
              0.7415549755096436,
              0.22569400072097778,
              0.7415549755096436,
              0.22777099907398224,
              0.7569659948348999,
              0.22777099907398224,
              0.7582740187644958,
              0.18784800171852112,
              0.7415549755096436,
              0.18784800171852112,
              0.7583600282669067,
              0.1877090036869049,
              0.7415549755096436,
              0.18784800171852112,
              0.7415549755096436,
              0.1877090036869049,
              0.7583600282669067,
              0.1877090036869049,
              0.7582740187644958,
              0.18784800171852112,
              0.7581920027732849,
              0.1882539987564087,
              0.7415549755096436,
              0.18784800171852112,
              0.7581920027732849,
              0.1882539987564087,
              0.7415549755096436,
              0.1882539987564087,
              0.7415549755096436,
              0.18784800171852112,
              0.7581920027732849,
              0.1882539987564087,
              0.7581120133399963,
              0.18891499936580658,
              0.7415549755096436,
              0.1882539987564087,
              0.7581120133399963,
              0.18891499936580658,
              0.7415549755096436,
              0.18891499936580658,
              0.7415549755096436,
              0.1882539987564087,
              0.7581120133399963,
              0.18891499936580658,
              0.7580360174179077,
              0.18981599807739258,
              0.7415549755096436,
              0.18891499936580658,
              0.7580360174179077,
              0.18981599807739258,
              0.7415549755096436,
              0.18981599807739258,
              0.7415549755096436,
              0.18891499936580658,
              0.7580360174179077,
              0.18981599807739258,
              0.7579630017280579,
              0.19094400107860565,
              0.7415549755096436,
              0.18981599807739258,
              0.7579630017280579,
              0.19094400107860565,
              0.7415549755096436,
              0.19094400107860565,
              0.7415549755096436,
              0.18981599807739258,
              0.7579630017280579,
              0.19094400107860565,
              0.757893979549408,
              0.19228500127792358,
              0.7415549755096436,
              0.19094400107860565,
              0.757893979549408,
              0.19228500127792358,
              0.7415549755096436,
              0.19228500127792358,
              0.7415549755096436,
              0.19094400107860565,
              0.7578290104866028,
              0.19382600486278534,
              0.7577679753303528,
              0.19555099308490753,
              0.7415549755096436,
              0.19382600486278534,
              0.7577679753303528,
              0.19555099308490753,
              0.7415549755096436,
              0.19555099308490753,
              0.7415549755096436,
              0.19382600486278534,
              0.7575349807739258,
              0.20403599739074707,
              0.7574759721755981,
              0.20648400485515594,
              0.7415549755096436,
              0.20403599739074707,
              0.7574759721755981,
              0.20648400485515594,
              0.7415549755096436,
              0.20648400485515594,
              0.7415549755096436,
              0.20403599739074707,
              0.7574149966239929,
              0.2090349942445755,
              0.7573509812355042,
              0.2116750031709671,
              0.7415549755096436,
              0.2090349942445755,
              0.7573509812355042,
              0.2116750031709671,
              0.7415549755096436,
              0.2116750031709671,
              0.7415549755096436,
              0.2090349942445755,
              0.7573509812355042,
              0.2116750031709671,
              0.757286012172699,
              0.21439099311828613,
              0.7415549755096436,
              0.2116750031709671,
              0.757286012172699,
              0.21439099311828613,
              0.7415549755096436,
              0.21439099311828613,
              0.7415549755096436,
              0.2116750031709671,
              0.757286012172699,
              0.21439099311828613,
              0.7572199702262878,
              0.21716900169849396,
              0.7415549755096436,
              0.21439099311828613,
              0.7572199702262878,
              0.21716900169849396,
              0.7415549755096436,
              0.21716900169849396,
              0.7415549755096436,
              0.21439099311828613,
              0.7572199702262878,
              0.21716900169849396,
              0.7571520209312439,
              0.21999500691890717,
              0.7415549755096436,
              0.21716900169849396,
              0.7571520209312439,
              0.21999500691890717,
              0.7415549755096436,
              0.21999500691890717,
              0.7415549755096436,
              0.21716900169849396,
              0.757893979549408,
              0.19228500127792358,
              0.7578290104866028,
              0.19382600486278534,
              0.7415549755096436,
              0.19228500127792358,
              0.7578290104866028,
              0.19382600486278534,
              0.7415549755096436,
              0.19382600486278534,
              0.7415549755096436,
              0.19228500127792358,
              0.7577679753303528,
              0.19555099308490753,
              0.7577040195465088,
              0.1974489986896515,
              0.7415549755096436,
              0.19555099308490753,
              0.7577040195465088,
              0.1974489986896515,
              0.7415549755096436,
              0.1974489986896515,
              0.7415549755096436,
              0.19555099308490753,
              0.7577040195465088,
              0.1974489986896515,
              0.7576429843902588,
              0.1995050013065338,
              0.7415549755096436,
              0.1974489986896515,
              0.7576429843902588,
              0.1995050013065338,
              0.7415549755096436,
              0.1995050013065338,
              0.7415549755096436,
              0.1974489986896515,
              0.7576429843902588,
              0.1995050013065338,
              0.7575899958610535,
              0.2017049938440323,
              0.7415549755096436,
              0.1995050013065338,
              0.7575899958610535,
              0.2017049938440323,
              0.7415549755096436,
              0.2017049938440323,
              0.7415549755096436,
              0.1995050013065338,
              0.7575899958610535,
              0.2017049938440323,
              0.7575349807739258,
              0.20403599739074707,
              0.7415549755096436,
              0.2017049938440323,
              0.7575349807739258,
              0.20403599739074707,
              0.7415549755096436,
              0.20403599739074707,
              0.7415549755096436,
              0.2017049938440323,
              0.7574759721755981,
              0.20648400485515594,
              0.7574149966239929,
              0.2090349942445755,
              0.7415549755096436,
              0.20648400485515594,
              0.7574149966239929,
              0.2090349942445755,
              0.7415549755096436,
              0.2090349942445755,
              0.7415549755096436,
              0.20648400485515594,
              0.7571520209312439,
              0.21999500691890717,
              0.7570840120315552,
              0.22285600006580353,
              0.7415549755096436,
              0.21999500691890717,
              0.7570840120315552,
              0.22285600006580353,
              0.7415549755096436,
              0.22285600006580353,
              0.7415549755096436,
              0.21999500691890717,
              0.7570840120315552,
              0.22285600006580353,
              0.7570149898529053,
              0.22573800384998322,
              0.7415549755096436,
              0.22285600006580353,
              0.7570149898529053,
              0.22573800384998322,
              0.7415549755096436,
              0.22573800384998322,
              0.7415549755096436,
              0.22285600006580353,
              0.7570149898529053,
              0.22573800384998322,
              0.756945013999939,
              0.22862599790096283,
              0.7415549755096436,
              0.22573800384998322,
              0.756945013999939,
              0.22862599790096283,
              0.7415549755096436,
              0.22862599790096283,
              0.7415549755096436,
              0.22573800384998322,
              0.7515339851379395,
              0.4545019865036011,
              0.7514960169792175,
              0.45609501004219055,
              0.7415549755096436,
              0.4545019865036011,
              0.7514960169792175,
              0.45609501004219055,
              0.7415549755096436,
              0.45609501004219055,
              0.7415549755096436,
              0.4545019865036011,
              0.7624300122261047,
              0.1906760036945343,
              0.7584760189056396,
              0.1950870007276535,
              0.7624419927597046,
              0.19049400091171265,
              0.7584760189056396,
              0.1950870007276535,
              0.7584890127182007,
              0.19490200281143188,
              0.7624419927597046,
              0.19049400091171265,
              0.7624300122261047,
              0.18776999413967133,
              0.758525013923645,
              0.18776999413967133,
              0.7623940110206604,
              0.1877090036869049,
              0.758525013923645,
              0.18776999413967133,
              0.7584890127182007,
              0.1877090036869049,
              0.7623940110206604,
              0.1877090036869049,
              0.7623940110206604,
              0.19073599576950073,
              0.7584390044212341,
              0.19514800608158112,
              0.7624300122261047,
              0.1906760036945343,
              0.7584390044212341,
              0.19514800608158112,
              0.7584760189056396,
              0.1950870007276535,
              0.7624300122261047,
              0.1906760036945343,
              0.7624419927597046,
              0.18795199692249298,
              0.7585359811782837,
              0.18795299530029297,
              0.7624300122261047,
              0.18776999413967133,
              0.7585359811782837,
              0.18795299530029297,
              0.758525013923645,
              0.18776999413967133,
              0.7624300122261047,
              0.18776999413967133,
              0.7584499716758728,
              0.188167005777359,
              0.7585350275039673,
              0.188167005777359,
              0.7584270238876343,
              0.19475799798965454,
              0.7585350275039673,
              0.188167005777359,
              0.7584909796714783,
              0.19468599557876587,
              0.7584270238876343,
              0.19475799798965454,
              0.7624419927597046,
              0.19049400091171265,
              0.7584890127182007,
              0.19490200281143188,
              0.7624419927597046,
              0.1902800053358078,
              0.7584890127182007,
              0.19490200281143188,
              0.7584909796714783,
              0.19468599557876587,
              0.7624419927597046,
              0.1902800053358078,
              0.7584890127182007,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7623940110206604,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7623519897460938,
              0.1877090036869049,
              0.7623940110206604,
              0.1877090036869049,
              0.7583760023117065,
              0.1952189952135086,
              0.7584390044212341,
              0.19514800608158112,
              0.7583330273628235,
              0.1952189952135086,
              0.7584390044212341,
              0.19514800608158112,
              0.7583960294723511,
              0.19514800608158112,
              0.7583330273628235,
              0.1952189952135086,
              0.758450984954834,
              0.18795299530029297,
              0.7585359811782837,
              0.18795299530029297,
              0.7584499716758728,
              0.188167005777359,
              0.7585359811782837,
              0.18795299530029297,
              0.7585350275039673,
              0.188167005777359,
              0.7584499716758728,
              0.188167005777359,
              0.7583330273628235,
              0.1952189952135086,
              0.7583960294723511,
              0.19514800608158112,
              0.7415549755096436,
              0.1952189952135086,
              0.7583960294723511,
              0.19514800608158112,
              0.7415549755096436,
              0.19514800608158112,
              0.7415549755096436,
              0.1952189952135086,
              0.7623519897460938,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7569199800491333,
              0.23147399723529816,
              0.7568770051002502,
              0.23147399723529816,
              0.756987988948822,
              0.22862599790096283,
              0.7568770051002502,
              0.23147399723529816,
              0.756945013999939,
              0.22862599790096283,
              0.756987988948822,
              0.22862599790096283,
              0.7570549845695496,
              0.22788499295711517,
              0.757053017616272,
              0.2279839962720871,
              0.7571049928665161,
              0.22578300535678864,
              0.757053017616272,
              0.2279839962720871,
              0.7571030259132385,
              0.22586199641227722,
              0.7571049928665161,
              0.22578300535678864,
              0.756945013999939,
              0.22862599790096283,
              0.7568770051002502,
              0.23147399723529816,
              0.7415549755096436,
              0.22862599790096283,
              0.7568770051002502,
              0.23147399723529816,
              0.7415549755096436,
              0.23147399723529816,
              0.7415549755096436,
              0.22862599790096283,
              0.7515919804573059,
              0.45541200041770935,
              0.7515659928321838,
              0.4565120041370392,
              0.7516030073165894,
              0.4554649889469147,
              0.7515659928321838,
              0.4565120041370392,
              0.7515770196914673,
              0.45657598972320557,
              0.7516030073165894,
              0.4554649889469147,
              0.751446008682251,
              0.46152499318122864,
              0.75142502784729,
              0.46241798996925354,
              0.7514550089836121,
              0.4616360068321228,
              0.75142502784729,
              0.46241798996925354,
              0.7514340281486511,
              0.4625380039215088,
              0.7514550089836121,
              0.4616360068321228,
              0.75142502784729,
              0.46241798996925354,
              0.7514039874076843,
              0.46327200531959534,
              0.7514340281486511,
              0.4625380039215088,
              0.7514039874076843,
              0.46327200531959534,
              0.7514129877090454,
              0.4633989930152893,
              0.7514340281486511,
              0.4625380039215088,
              0.7514039874076843,
              0.46327200531959534,
              0.751384973526001,
              0.4640839993953705,
              0.7514129877090454,
              0.4633989930152893,
              0.751384973526001,
              0.4640839993953705,
              0.7513939738273621,
              0.46421900391578674,
              0.7514129877090454,
              0.4633989930152893,
              0.7512789964675903,
              0.46848100423812866,
              0.7512689828872681,
              0.4689069986343384,
              0.7512869834899902,
              0.46865400671958923,
              0.7512689828872681,
              0.4689069986343384,
              0.7512770295143127,
              0.4690830111503601,
              0.7512869834899902,
              0.46865400671958923,
              0.7512689828872681,
              0.4689069986343384,
              0.751259982585907,
              0.4692780077457428,
              0.7512770295143127,
              0.4690830111503601,
              0.751259982585907,
              0.4692780077457428,
              0.7512680292129517,
              0.4694559872150421,
              0.7512770295143127,
              0.4690830111503601,
              0.7515389919281006,
              0.45765000581741333,
              0.7515760064125061,
              0.45608100295066833,
              0.7515519857406616,
              0.4575969874858856,
              0.7515760064125061,
              0.45608100295066833,
              0.7515900135040283,
              0.4560379981994629,
              0.7515519857406616,
              0.4575969874858856,
              0.7513989806175232,
              0.4634990096092224,
              0.7514320015907288,
              0.46210500597953796,
              0.7514129877090454,
              0.4634059965610504,
              0.7514320015907288,
              0.46210500597953796,
              0.751446008682251,
              0.46202200651168823,
              0.7514129877090454,
              0.4634059965610504,
              0.7510899901390076,
              0.47639000415802,
              0.7511039972305298,
              0.47579801082611084,
              0.7511060237884521,
              0.4762139916419983,
              0.7511039972305298,
              0.47579801082611084,
              0.7511199712753296,
              0.47562599182128906,
              0.7511060237884521,
              0.4762139916419983,
              0.7510780096054077,
              0.4769040048122406,
              0.7510899901390076,
              0.47639000415802,
              0.7510939836502075,
              0.4767259955406189,
              0.7510899901390076,
              0.47639000415802,
              0.7511060237884521,
              0.4762139916419983,
              0.7510939836502075,
              0.4767259955406189,
              0.7510589957237244,
              0.4776949882507324,
              0.7510669827461243,
              0.4773400127887726,
              0.751075029373169,
              0.47751399874687195,
              0.7510669827461243,
              0.4773400127887726,
              0.75108402967453,
              0.4771600067615509,
              0.751075029373169,
              0.47751399874687195,
              0.7516189813613892,
              0.4542819857597351,
              0.7515919804573059,
              0.45541200041770935,
              0.7516310214996338,
              0.45432499051094055,
              0.7515919804573059,
              0.45541200041770935,
              0.7516030073165894,
              0.4554649889469147,
              0.7516310214996338,
              0.45432499051094055,
              0.7515659928321838,
              0.4565120041370392,
              0.7515400052070618,
              0.4575830101966858,
              0.7515770196914673,
              0.45657598972320557,
              0.7515400052070618,
              0.4575830101966858,
              0.7515509724617004,
              0.45765599608421326,
              0.7515770196914673,
              0.45657598972320557,
              0.7513489723205566,
              0.46557798981666565,
              0.7513329982757568,
              0.4662570059299469,
              0.7513570189476013,
              0.4657270014286041,
              0.7513329982757568,
              0.4662570059299469,
              0.7513409852981567,
              0.4664109945297241,
              0.7513570189476013,
              0.4657270014286041,
              0.751317024230957,
              0.4668880105018616,
              0.7513039708137512,
              0.46747100353240967,
              0.7513260245323181,
              0.4670479893684387,
              0.7513039708137512,
              0.46747100353240967,
              0.7513120174407959,
              0.46763500571250916,
              0.7513260245323181,
              0.4670479893684387,
              0.7511910200119019,
              0.4721609950065613,
              0.7511990070343018,
              0.472339004278183,
              0.7512410283088684,
              0.47007301449775696,
              0.7511990070343018,
              0.472339004278183,
              0.7512490153312683,
              0.4702570140361786,
              0.7512410283088684,
              0.47007301449775696,
              0.7513660192489624,
              0.4648439884185791,
              0.7513989806175232,
              0.4634990096092224,
              0.751380980014801,
              0.4647420048713684,
              0.7513989806175232,
              0.4634990096092224,
              0.7514129877090454,
              0.4634059965610504,
              0.751380980014801,
              0.4647420048713684,
              0.7513059973716736,
              0.46737900376319885,
              0.7513350248336792,
              0.46613800525665283,
              0.751321017742157,
              0.4672600030899048,
              0.7513350248336792,
              0.46613800525665283,
              0.7513499855995178,
              0.46602699160575867,
              0.751321017742157,
              0.4672600030899048,
              0.7512770295143127,
              0.4685640037059784,
              0.7513059973716736,
              0.46737900376319885,
              0.7512919902801514,
              0.46843698620796204,
              0.7513059973716736,
              0.46737900376319885,
              0.751321017742157,
              0.4672600030899048,
              0.7512919902801514,
              0.46843698620796204,
              0.7511780261993408,
              0.4727090001106262,
              0.7512009739875793,
              0.47176700830459595,
              0.7511940002441406,
              0.472555011510849,
              0.7512009739875793,
              0.47176700830459595,
              0.7512159943580627,
              0.47161799669265747,
              0.7511940002441406,
              0.472555011510849,
              0.7511379718780518,
              0.4743939936161041,
              0.7511569857597351,
              0.47358599305152893,
              0.7511540055274963,
              0.47422999143600464,
              0.7511569857597351,
              0.47358599305152893,
              0.7511730194091797,
              0.4734260141849518,
              0.7511540055274963,
              0.47422999143600464,
              0.7591570019721985,
              0.18776999413967133,
              0.7587599754333496,
              0.18776999413967133,
              0.7591609954833984,
              0.18795199692249298,
              0.7587599754333496,
              0.18776999413967133,
              0.7587640285491943,
              0.18795199692249298,
              0.7591609954833984,
              0.18795199692249298,
              0.7595379948616028,
              0.18776999413967133,
              0.7591570019721985,
              0.18776999413967133,
              0.7595430016517639,
              0.18795199692249298,
              0.7591570019721985,
              0.18776999413967133,
              0.7591609954833984,
              0.18795199692249298,
              0.7595430016517639,
              0.18795199692249298,
              0.7602499723434448,
              0.18776999413967133,
              0.7599030137062073,
              0.18776999413967133,
              0.7602570056915283,
              0.18795199692249298,
              0.7599030137062073,
              0.18776999413967133,
              0.7599089741706848,
              0.18795199692249298,
              0.7602570056915283,
              0.18795199692249298,
              0.7625340223312378,
              0.18776999413967133,
              0.7625409960746765,
              0.18776999413967133,
              0.7625460028648376,
              0.18795199692249298,
              0.7625409960746765,
              0.18776999413967133,
              0.7625529766082764,
              0.18795199692249298,
              0.7625460028648376,
              0.18795199692249298,
              0.7583510279655457,
              0.18776999413967133,
              0.7579280138015747,
              0.18776999413967133,
              0.7583529949188232,
              0.18795199692249298,
              0.7579280138015747,
              0.18776999413967133,
              0.7579290270805359,
              0.18795199692249298,
              0.7583529949188232,
              0.18795199692249298,
              0.761434018611908,
              0.18776999413967133,
              0.7611709833145142,
              0.18776999413967133,
              0.7614439725875854,
              0.18795199692249298,
              0.7611709833145142,
              0.18776999413967133,
              0.7611799836158752,
              0.18795199692249298,
              0.7614439725875854,
              0.18795199692249298,
              0.7620700001716614,
              0.18776999413967133,
              0.7618849873542786,
              0.18776999413967133,
              0.7620810270309448,
              0.18795199692249298,
              0.7618849873542786,
              0.18776999413967133,
              0.7618950009346008,
              0.18795199692249298,
              0.7620810270309448,
              0.18795199692249298,
              0.7622269988059998,
              0.18776999413967133,
              0.7620700001716614,
              0.18776999413967133,
              0.7622380256652832,
              0.18795199692249298,
              0.7620700001716614,
              0.18776999413967133,
              0.7620810270309448,
              0.18795199692249298,
              0.7622380256652832,
              0.18795199692249298,
              0.7625409960746765,
              0.18776999413967133,
              0.7625120282173157,
              0.18776999413967133,
              0.7625529766082764,
              0.18795199692249298,
              0.7625120282173157,
              0.18776999413967133,
              0.7625240087509155,
              0.18795199692249298,
              0.7625529766082764,
              0.18795199692249298,
              0.7624300122261047,
              0.18776999413967133,
              0.76249098777771,
              0.18776999413967133,
              0.7624419927597046,
              0.18795199692249298,
              0.76249098777771,
              0.18776999413967133,
              0.7625030279159546,
              0.18795199692249298,
              0.7624419927597046,
              0.18795199692249298,
              0.7574949860572815,
              0.18776999413967133,
              0.7570610046386719,
              0.18776999413967133,
              0.7574949860572815,
              0.18795199692249298,
              0.7570610046386719,
              0.18776999413967133,
              0.7570610046386719,
              0.18795199692249298,
              0.7574949860572815,
              0.18795199692249298,
              0.7574949860572815,
              0.18969100713729858,
              0.7579280138015747,
              0.18969400227069855,
              0.7574949860572815,
              0.18950699269771576,
              0.7579280138015747,
              0.18969400227069855,
              0.7579290270805359,
              0.18951000273227692,
              0.7574949860572815,
              0.18950699269771576,
              0.7579280138015747,
              0.18969400227069855,
              0.7583510279655457,
              0.18970300257205963,
              0.7579290270805359,
              0.18951000273227692,
              0.7583510279655457,
              0.18970300257205963,
              0.7583529949188232,
              0.189519003033638,
              0.7579290270805359,
              0.18951000273227692,
              0.7583510279655457,
              0.18970300257205963,
              0.7587599754333496,
              0.18971699476242065,
              0.7583529949188232,
              0.189519003033638,
              0.7587599754333496,
              0.18971699476242065,
              0.7587640285491943,
              0.18953299522399902,
              0.7583529949188232,
              0.189519003033638,
              0.7595379948616028,
              0.1897599995136261,
              0.7599030137062073,
              0.18978899717330933,
              0.7595430016517639,
              0.18957599997520447,
              0.7599030137062073,
              0.18978899717330933,
              0.7599089741706848,
              0.1896049976348877,
              0.7595430016517639,
              0.18957599997520447,
              0.7599030137062073,
              0.18978899717330933,
              0.7602499723434448,
              0.1898220032453537,
              0.7599089741706848,
              0.1896049976348877,
              0.7602499723434448,
              0.1898220032453537,
              0.7602570056915283,
              0.18963800370693207,
              0.7599089741706848,
              0.1896049976348877,
              0.7602499723434448,
              0.1898220032453537,
              0.7605779767036438,
              0.18985900282859802,
              0.7602570056915283,
              0.18963800370693207,
              0.7605779767036438,
              0.18985900282859802,
              0.7605850100517273,
              0.1896750032901764,
              0.7602570056915283,
              0.18963800370693207,
              0.760886013507843,
              0.1898999959230423,
              0.7611709833145142,
              0.18994499742984772,
              0.7608940005302429,
              0.18971599638462067,
              0.7611709833145142,
              0.18994499742984772,
              0.7611799836158752,
              0.1897609978914261,
              0.7608940005302429,
              0.18971599638462067,
              0.761434018611908,
              0.1899929940700531,
              0.7616720199584961,
              0.19004400074481964,
              0.7614439725875854,
              0.18980999290943146,
              0.7616720199584961,
              0.19004400074481964,
              0.7616829872131348,
              0.189860999584198,
              0.7614439725875854,
              0.18980999290943146,
              0.7622269988059998,
              0.19021500647068024,
              0.7623530030250549,
              0.19027699530124664,
              0.7622380256652832,
              0.1900320053100586,
              0.7623530030250549,
              0.19027699530124664,
              0.7623649835586548,
              0.190093994140625,
              0.7622380256652832,
              0.1900320053100586,
              0.7624490261077881,
              0.19033999741077423,
              0.7625120282173157,
              0.1904049962759018,
              0.7624610066413879,
              0.1901569962501526,
              0.7625120282173157,
              0.1904049962759018,
              0.7625240087509155,
              0.19022299349308014,
              0.7624610066413879,
              0.1901569962501526,
              0.7625409960746765,
              0.19047200679779053,
              0.7625340223312378,
              0.19054000079631805,
              0.7625529766082764,
              0.19029000401496887,
              0.7625340223312378,
              0.19054000079631805,
              0.7625460028648376,
              0.1903569996356964,
              0.7625529766082764,
              0.19029000401496887,
              0.7625340223312378,
              0.19054000079631805,
              0.76249098777771,
              0.19060799479484558,
              0.7625460028648376,
              0.1903569996356964,
              0.76249098777771,
              0.19060799479484558,
              0.7625030279159546,
              0.19042600691318512,
              0.7625460028648376,
              0.1903569996356964,
              0.7515159845352173,
              0.45862001180648804,
              0.7515400052070618,
              0.4575830101966858,
              0.751479983329773,
              0.4585919976234436,
              0.7515400052070618,
              0.4575830101966858,
              0.7515040040016174,
              0.4575580060482025,
              0.751479983329773,
              0.4585919976234436,
              0.7514910101890564,
              0.4596239924430847,
              0.7515159845352173,
              0.45862001180648804,
              0.7514560222625732,
              0.4595929980278015,
              0.7515159845352173,
              0.45862001180648804,
              0.751479983329773,
              0.4585919976234436,
              0.7514560222625732,
              0.4595929980278015,
              0.751446008682251,
              0.46152499318122864,
              0.7514680027961731,
              0.46059298515319824,
              0.7514100074768066,
              0.4614880084991455,
              0.7514680027961731,
              0.46059298515319824,
              0.7514320015907288,
              0.46055901050567627,
              0.7514100074768066,
              0.4614880084991455,
              0.7513489723205566,
              0.46557798981666565,
              0.7513660192489624,
              0.46485400199890137,
              0.7513129711151123,
              0.4655289947986603,
              0.7513660192489624,
              0.46485400199890137,
              0.7513309717178345,
              0.4648059904575348,
              0.7513129711151123,
              0.4655289947986603,
              0.751317024230957,
              0.4668880105018616,
              0.7513329982757568,
              0.4662570059299469,
              0.7512819766998291,
              0.46683499217033386,
              0.7513329982757568,
              0.4662570059299469,
              0.7512969970703125,
              0.46620601415634155,
              0.7512819766998291,
              0.46683499217033386,
              0.7515019774436951,
              0.45917800068855286,
              0.7514669895172119,
              0.4606640040874481,
              0.7514650225639343,
              0.459199994802475,
              0.7514669895172119,
              0.4606640040874481,
              0.75142902135849,
              0.4606890082359314,
              0.7514650225639343,
              0.459199994802475,
              0.7513660192489624,
              0.4648439884185791,
              0.7513350248336792,
              0.46613800525665283,
              0.7513290047645569,
              0.46487900614738464,
              0.7513350248336792,
              0.46613800525665283,
              0.7512980103492737,
              0.46617498993873596,
              0.7513290047645569,
              0.46487900614738464,
              0.7512770295143127,
              0.4685640037059784,
              0.7512500286102295,
              0.4696919918060303,
              0.7512400150299072,
              0.46860700845718384,
              0.7512500286102295,
              0.4696919918060303,
              0.751213014125824,
              0.4697369933128357,
              0.7512400150299072,
              0.46860700845718384,
              0.7510070204734802,
              0.4798569977283478,
              0.7509689927101135,
              0.4799160063266754,
              0.7510510087013245,
              0.4780080020427704,
              0.7509689927101135,
              0.4799160063266754,
              0.7510129809379578,
              0.478069007396698,
              0.7510510087013245,
              0.4780080020427704,
              0.7514680027961731,
              0.46059298515319824,
              0.7514910101890564,
              0.4596239924430847,
              0.7514320015907288,
              0.46055901050567627,
              0.7514910101890564,
              0.4596239924430847,
              0.7514560222625732,
              0.4595929980278015,
              0.7514320015907288,
              0.46055901050567627,
              0.7513660192489624,
              0.46485400199890137,
              0.751384973526001,
              0.4640839993953705,
              0.7513309717178345,
              0.4648059904575348,
              0.751384973526001,
              0.4640839993953705,
              0.7513489723205566,
              0.46404001116752625,
              0.7513309717178345,
              0.4648059904575348,
              0.7512909770011902,
              0.46800199151039124,
              0.7513039708137512,
              0.46747100353240967,
              0.751255989074707,
              0.46794599294662476,
              0.7513039708137512,
              0.46747100353240967,
              0.7512680292129517,
              0.467415988445282,
              0.751255989074707,
              0.46794599294662476,
              0.7512789964675903,
              0.46848100423812866,
              0.7512909770011902,
              0.46800199151039124,
              0.7512440085411072,
              0.468423992395401,
              0.7512909770011902,
              0.46800199151039124,
              0.751255989074707,
              0.46794599294662476,
              0.7512440085411072,
              0.468423992395401,
              0.7512530088424683,
              0.4695909917354584,
              0.751259982585907,
              0.4692780077457428,
              0.7512180209159851,
              0.46953099966049194,
              0.751259982585907,
              0.4692780077457428,
              0.7512249946594238,
              0.469217985868454,
              0.7512180209159851,
              0.46953099966049194,
              0.751246988773346,
              0.4698469936847687,
              0.7512530088424683,
              0.4695909917354584,
              0.7512120008468628,
              0.46978598833084106,
              0.7512530088424683,
              0.4695909917354584,
              0.7512180209159851,
              0.46953099966049194,
              0.7512120008468628,
              0.46978598833084106,
              0.7515389919281006,
              0.45765000581741333,
              0.7515019774436951,
              0.45917800068855286,
              0.7515019774436951,
              0.4576680064201355,
              0.7515019774436951,
              0.45917800068855286,
              0.7514650225639343,
              0.459199994802475,
              0.7515019774436951,
              0.4576680064201355,
              0.7514669895172119,
              0.4606640040874481,
              0.7514320015907288,
              0.46210500597953796,
              0.75142902135849,
              0.4606890082359314,
              0.7514320015907288,
              0.46210500597953796,
              0.7513949871063232,
              0.46213299036026,
              0.75142902135849,
              0.4606890082359314,
              0.7512500286102295,
              0.4696919918060303,
              0.7512249946594238,
              0.4707599878311157,
              0.751213014125824,
              0.4697369933128357,
              0.7512249946594238,
              0.4707599878311157,
              0.7511870265007019,
              0.4708069860935211,
              0.751213014125824,
              0.4697369933128357,
              0.7512249946594238,
              0.4707599878311157,
              0.7512009739875793,
              0.47176700830459595,
              0.7511870265007019,
              0.4708069860935211,
              0.7512009739875793,
              0.47176700830459595,
              0.7511630058288574,
              0.4718160033226013,
              0.7511870265007019,
              0.4708069860935211,
              0.7511780261993408,
              0.4727090001106262,
              0.7511569857597351,
              0.47358599305152893,
              0.7511399984359741,
              0.47276100516319275,
              0.7511569857597351,
              0.47358599305152893,
              0.7511190176010132,
              0.47363901138305664,
              0.7511399984359741,
              0.47276100516319275,
              0.7511379718780518,
              0.4743939936161041,
              0.7511199712753296,
              0.4751319885253906,
              0.7511000037193298,
              0.4744490087032318,
              0.7511199712753296,
              0.4751319885253906,
              0.7510820031166077,
              0.4751890003681183,
              0.7511000037193298,
              0.4744490087032318,
              0.7511199712753296,
              0.4751319885253906,
              0.7511039972305298,
              0.47579801082611084,
              0.7510820031166077,
              0.4751890003681183,
              0.7511039972305298,
              0.47579801082611084,
              0.7510660290718079,
              0.4758560061454773,
              0.7510820031166077,
              0.4751890003681183,
              0.7510780096054077,
              0.4769040048122406,
              0.7510669827461243,
              0.4773400127887726,
              0.751039981842041,
              0.47696399688720703,
              0.7510669827461243,
              0.4773400127887726,
              0.7510290145874023,
              0.477400004863739,
              0.751039981842041,
              0.47696399688720703,
              0.7583510279655457,
              0.18776999413967133,
              0.7587599754333496,
              0.18776999413967133,
              0.7583439946174622,
              0.1877090036869049,
              0.7587599754333496,
              0.18776999413967133,
              0.7587509751319885,
              0.1877090036869049,
              0.7583439946174622,
              0.1877090036869049,
              0.7605779767036438,
              0.18776999413967133,
              0.760886013507843,
              0.18776999413967133,
              0.7605559825897217,
              0.1877090036869049,
              0.760886013507843,
              0.18776999413967133,
              0.7608609795570374,
              0.1877090036869049,
              0.7605559825897217,
              0.1877090036869049,
              0.760886013507843,
              0.18776999413967133,
              0.7611709833145142,
              0.18776999413967133,
              0.7608609795570374,
              0.1877090036869049,
              0.7611709833145142,
              0.18776999413967133,
              0.7611449956893921,
              0.1877090036869049,
              0.7608609795570374,
              0.1877090036869049,
              0.761434018611908,
              0.18776999413967133,
              0.7616720199584961,
              0.18776999413967133,
              0.7614060044288635,
              0.1877090036869049,
              0.7616720199584961,
              0.18776999413967133,
              0.7616419792175293,
              0.1877090036869049,
              0.7614060044288635,
              0.1877090036869049,
              0.7624490261077881,
              0.18776999413967133,
              0.7625120282173157,
              0.18776999413967133,
              0.7624130249023438,
              0.1877090036869049,
              0.7625120282173157,
              0.18776999413967133,
              0.7624750137329102,
              0.1877090036869049,
              0.7624130249023438,
              0.1877090036869049,
              0.7625340223312378,
              0.18776999413967133,
              0.76249098777771,
              0.18776999413967133,
              0.7624980211257935,
              0.1877090036869049,
              0.76249098777771,
              0.18776999413967133,
              0.7624549865722656,
              0.1877090036869049,
              0.7624980211257935,
              0.1877090036869049,
              0.7574949860572815,
              0.18776999413967133,
              0.7579280138015747,
              0.18776999413967133,
              0.7574939727783203,
              0.1877090036869049,
              0.7579280138015747,
              0.18776999413967133,
              0.7579249739646912,
              0.1877090036869049,
              0.7574939727783203,
              0.1877090036869049,
              0.7595379948616028,
              0.18776999413967133,
              0.7599030137062073,
              0.18776999413967133,
              0.7595229744911194,
              0.1877090036869049,
              0.7599030137062073,
              0.18776999413967133,
              0.7598850131034851,
              0.1877090036869049,
              0.7595229744911194,
              0.1877090036869049,
              0.7602499723434448,
              0.18776999413967133,
              0.7605779767036438,
              0.18776999413967133,
              0.7602300047874451,
              0.1877090036869049,
              0.7605779767036438,
              0.18776999413967133,
              0.7605559825897217,
              0.1877090036869049,
              0.7602300047874451,
              0.1877090036869049,
              0.7616720199584961,
              0.18776999413967133,
              0.7618849873542786,
              0.18776999413967133,
              0.7616419792175293,
              0.1877090036869049,
              0.7618849873542786,
              0.18776999413967133,
              0.7618529796600342,
              0.1877090036869049,
              0.7616419792175293,
              0.1877090036869049,
              0.7622269988059998,
              0.18776999413967133,
              0.7623530030250549,
              0.18776999413967133,
              0.7621920108795166,
              0.1877090036869049,
              0.7623530030250549,
              0.18776999413967133,
              0.7623180150985718,
              0.1877090036869049,
              0.7621920108795166,
              0.1877090036869049,
              0.7623530030250549,
              0.18776999413967133,
              0.7624490261077881,
              0.18776999413967133,
              0.7623180150985718,
              0.1877090036869049,
              0.7624490261077881,
              0.18776999413967133,
              0.7624130249023438,
              0.1877090036869049,
              0.7623180150985718,
              0.1877090036869049,
              0.7415549755096436,
              0.18776999413967133,
              0.7570610046386719,
              0.18776999413967133,
              0.7415549755096436,
              0.1877090036869049,
              0.7570610046386719,
              0.18776999413967133,
              0.7570610046386719,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7591570019721985,
              0.1897359937429428,
              0.7587599754333496,
              0.18971699476242065,
              0.7591450214385986,
              0.18980200588703156,
              0.7587599754333496,
              0.18971699476242065,
              0.7587509751319885,
              0.1897830069065094,
              0.7591450214385986,
              0.18980200588703156,
              0.7595379948616028,
              0.1897599995136261,
              0.7591570019721985,
              0.1897359937429428,
              0.7595229744911194,
              0.18982599675655365,
              0.7591570019721985,
              0.1897359937429428,
              0.7591450214385986,
              0.18980200588703156,
              0.7595229744911194,
              0.18982599675655365,
              0.760886013507843,
              0.1898999959230423,
              0.7605779767036438,
              0.18985900282859802,
              0.7608609795570374,
              0.18996499478816986,
              0.7605779767036438,
              0.18985900282859802,
              0.7605559825897217,
              0.18992400169372559,
              0.7608609795570374,
              0.18996499478816986,
              0.761434018611908,
              0.1899929940700531,
              0.7611709833145142,
              0.18994499742984772,
              0.7614060044288635,
              0.19005699455738068,
              0.7611709833145142,
              0.18994499742984772,
              0.7611449956893921,
              0.19000999629497528,
              0.7614060044288635,
              0.19005699455738068,
              0.7618849873542786,
              0.19009900093078613,
              0.7616720199584961,
              0.19004400074481964,
              0.7618529796600342,
              0.19016200304031372,
              0.7616720199584961,
              0.19004400074481964,
              0.7616419792175293,
              0.1901089996099472,
              0.7618529796600342,
              0.19016200304031372,
              0.7620700001716614,
              0.1901559978723526,
              0.7618849873542786,
              0.19009900093078613,
              0.7620369791984558,
              0.1902189999818802,
              0.7618849873542786,
              0.19009900093078613,
              0.7618529796600342,
              0.19016200304031372,
              0.7620369791984558,
              0.1902189999818802,
              0.7622269988059998,
              0.19021500647068024,
              0.7620700001716614,
              0.1901559978723526,
              0.7621920108795166,
              0.19027799367904663,
              0.7620700001716614,
              0.1901559978723526,
              0.7620369791984558,
              0.1902189999818802,
              0.7621920108795166,
              0.19027799367904663,
              0.7624490261077881,
              0.19033999741077423,
              0.7623530030250549,
              0.19027699530124664,
              0.7624130249023438,
              0.19040200114250183,
              0.7623530030250549,
              0.19027699530124664,
              0.7623180150985718,
              0.19033899903297424,
              0.7624130249023438,
              0.19040200114250183,
              0.7625409960746765,
              0.19047200679779053,
              0.7625120282173157,
              0.1904049962759018,
              0.762503981590271,
              0.19053299725055695,
              0.7625120282173157,
              0.1904049962759018,
              0.7624750137329102,
              0.1904670000076294,
              0.762503981590271,
              0.19053299725055695,
              0.7624300122261047,
              0.1906760036945343,
              0.76249098777771,
              0.19060799479484558,
              0.7623940110206604,
              0.19073599576950073,
              0.76249098777771,
              0.19060799479484558,
              0.7624549865722656,
              0.1906680017709732,
              0.7623940110206604,
              0.19073599576950073,
              0.7574949860572815,
              0.18969100713729858,
              0.7570610046386719,
              0.18969100713729858,
              0.7574939727783203,
              0.18975800275802612,
              0.7570610046386719,
              0.18969100713729858,
              0.7570610046386719,
              0.18975800275802612,
              0.7574939727783203,
              0.18975800275802612,
              0.7570610046386719,
              0.18969100713729858,
              0.7415549755096436,
              0.18969100713729858,
              0.7570610046386719,
              0.18975800275802612,
              0.7415549755096436,
              0.18969100713729858,
              0.7415549755096436,
              0.18975800275802612,
              0.7570610046386719,
              0.18975800275802612,
              0.7515559792518616,
              0.4553939998149872,
              0.7515299916267395,
              0.45649099349975586,
              0.7515919804573059,
              0.45541200041770935,
              0.7515299916267395,
              0.45649099349975586,
              0.7515659928321838,
              0.4565120041370392,
              0.7515919804573059,
              0.45541200041770935,
              0.7514100074768066,
              0.4614880084991455,
              0.7513890266418457,
              0.46237900853157043,
              0.751446008682251,
              0.46152499318122864,
              0.7513890266418457,
              0.46237900853157043,
              0.75142502784729,
              0.46241798996925354,
              0.751446008682251,
              0.46152499318122864,
              0.7513890266418457,
              0.46237900853157043,
              0.7513689994812012,
              0.4632300138473511,
              0.75142502784729,
              0.46241798996925354,
              0.7513689994812012,
              0.4632300138473511,
              0.7514039874076843,
              0.46327200531959534,
              0.75142502784729,
              0.46241798996925354,
              0.7513689994812012,
              0.4632300138473511,
              0.7513489723205566,
              0.46404001116752625,
              0.7514039874076843,
              0.46327200531959534,
              0.7513489723205566,
              0.46404001116752625,
              0.751384973526001,
              0.4640839993953705,
              0.7514039874076843,
              0.46327200531959534,
              0.7512440085411072,
              0.468423992395401,
              0.7512339949607849,
              0.46884799003601074,
              0.7512789964675903,
              0.46848100423812866,
              0.7512339949607849,
              0.46884799003601074,
              0.7512689828872681,
              0.4689069986343384,
              0.7512789964675903,
              0.46848100423812866,
              0.7512339949607849,
              0.46884799003601074,
              0.7512249946594238,
              0.469217985868454,
              0.7512689828872681,
              0.4689069986343384,
              0.7512249946594238,
              0.469217985868454,
              0.751259982585907,
              0.4692780077457428,
              0.7512689828872681,
              0.4689069986343384,
              0.7515019774436951,
              0.4576680064201355,
              0.7515389919281006,
              0.45609501004219055,
              0.7515389919281006,
              0.45765000581741333,
              0.7515389919281006,
              0.45609501004219055,
              0.7515760064125061,
              0.45608100295066833,
              0.7515389919281006,
              0.45765000581741333,
              0.7513610124588013,
              0.4635300040245056,
              0.7513949871063232,
              0.46213299036026,
              0.7513989806175232,
              0.4634990096092224,
              0.7513949871063232,
              0.46213299036026,
              0.7514320015907288,
              0.46210500597953796,
              0.7513989806175232,
              0.4634990096092224,
              0.7510520219802856,
              0.4764479994773865,
              0.7510660290718079,
              0.4758560061454773,
              0.7510899901390076,
              0.47639000415802,
              0.7510660290718079,
              0.4758560061454773,
              0.7511039972305298,
              0.47579801082611084,
              0.7510899901390076,
              0.47639000415802,
              0.751039981842041,
              0.47696399688720703,
              0.7510520219802856,
              0.4764479994773865,
              0.7510780096054077,
              0.4769040048122406,
              0.7510520219802856,
              0.4764479994773865,
              0.7510899901390076,
              0.47639000415802,
              0.7510780096054077,
              0.4769040048122406,
              0.7510210275650024,
              0.47775599360466003,
              0.7510290145874023,
              0.477400004863739,
              0.7510589957237244,
              0.4776949882507324,
              0.7510290145874023,
              0.477400004863739,
              0.7510669827461243,
              0.4773400127887726,
              0.7510589957237244,
              0.4776949882507324,
              0.7515829801559448,
              0.4542680084705353,
              0.7515559792518616,
              0.4553939998149872,
              0.7516189813613892,
              0.4542819857597351,
              0.7515559792518616,
              0.4553939998149872,
              0.7515919804573059,
              0.45541200041770935,
              0.7516189813613892,
              0.4542819857597351,
              0.7515299916267395,
              0.45649099349975586,
              0.7515040040016174,
              0.4575580060482025,
              0.7515659928321838,
              0.4565120041370392,
              0.7515040040016174,
              0.4575580060482025,
              0.7515400052070618,
              0.4575830101966858,
              0.7515659928321838,
              0.4565120041370392,
              0.7513129711151123,
              0.4655289947986603,
              0.7512969970703125,
              0.46620601415634155,
              0.7513489723205566,
              0.46557798981666565,
              0.7512969970703125,
              0.46620601415634155,
              0.7513329982757568,
              0.4662570059299469,
              0.7513489723205566,
              0.46557798981666565,
              0.7512819766998291,
              0.46683499217033386,
              0.7512680292129517,
              0.467415988445282,
              0.751317024230957,
              0.4668880105018616,
              0.7512680292129517,
              0.467415988445282,
              0.7513039708137512,
              0.46747100353240967,
              0.751317024230957,
              0.4668880105018616,
              0.7511559724807739,
              0.47210100293159485,
              0.7511910200119019,
              0.4721609950065613,
              0.7512059807777405,
              0.47001200914382935,
              0.7511910200119019,
              0.4721609950065613,
              0.7512410283088684,
              0.47007301449775696,
              0.7512059807777405,
              0.47001200914382935,
              0.7513290047645569,
              0.46487900614738464,
              0.7513610124588013,
              0.4635300040245056,
              0.7513660192489624,
              0.4648439884185791,
              0.7513610124588013,
              0.4635300040245056,
              0.7513989806175232,
              0.4634990096092224,
              0.7513660192489624,
              0.4648439884185791,
              0.7512680292129517,
              0.46741899847984314,
              0.7512980103492737,
              0.46617498993873596,
              0.7513059973716736,
              0.46737900376319885,
              0.7512980103492737,
              0.46617498993873596,
              0.7513350248336792,
              0.46613800525665283,
              0.7513059973716736,
              0.46737900376319885,
              0.7512400150299072,
              0.46860700845718384,
              0.7512680292129517,
              0.46741899847984314,
              0.7512770295143127,
              0.4685640037059784,
              0.7512680292129517,
              0.46741899847984314,
              0.7513059973716736,
              0.46737900376319885,
              0.7512770295143127,
              0.4685640037059784,
              0.7511399984359741,
              0.47276100516319275,
              0.7511630058288574,
              0.4718160033226013,
              0.7511780261993408,
              0.4727090001106262,
              0.7511630058288574,
              0.4718160033226013,
              0.7512009739875793,
              0.47176700830459595,
              0.7511780261993408,
              0.4727090001106262,
              0.7511000037193298,
              0.4744490087032318,
              0.7511190176010132,
              0.47363901138305664,
              0.7511379718780518,
              0.4743939936161041,
              0.7511190176010132,
              0.47363901138305664,
              0.7511569857597351,
              0.47358599305152893,
              0.7511379718780518,
              0.4743939936161041,
              0.7591450214385986,
              0.1877090036869049,
              0.7587509751319885,
              0.1877090036869049,
              0.7591570019721985,
              0.18776999413967133,
              0.7587509751319885,
              0.1877090036869049,
              0.7587599754333496,
              0.18776999413967133,
              0.7591570019721985,
              0.18776999413967133,
              0.7595229744911194,
              0.1877090036869049,
              0.7591450214385986,
              0.1877090036869049,
              0.7595379948616028,
              0.18776999413967133,
              0.7591450214385986,
              0.1877090036869049,
              0.7591570019721985,
              0.18776999413967133,
              0.7595379948616028,
              0.18776999413967133,
              0.7602300047874451,
              0.1877090036869049,
              0.7598850131034851,
              0.1877090036869049,
              0.7602499723434448,
              0.18776999413967133,
              0.7598850131034851,
              0.1877090036869049,
              0.7599030137062073,
              0.18776999413967133,
              0.7602499723434448,
              0.18776999413967133,
              0.7624980211257935,
              0.1877090036869049,
              0.762503981590271,
              0.1877090036869049,
              0.7625340223312378,
              0.18776999413967133,
              0.762503981590271,
              0.1877090036869049,
              0.7625409960746765,
              0.18776999413967133,
              0.7625340223312378,
              0.18776999413967133,
              0.7583439946174622,
              0.1877090036869049,
              0.7579249739646912,
              0.1877090036869049,
              0.7583510279655457,
              0.18776999413967133,
              0.7579249739646912,
              0.1877090036869049,
              0.7579280138015747,
              0.18776999413967133,
              0.7583510279655457,
              0.18776999413967133,
              0.7614060044288635,
              0.1877090036869049,
              0.7611449956893921,
              0.1877090036869049,
              0.761434018611908,
              0.18776999413967133,
              0.7611449956893921,
              0.1877090036869049,
              0.7611709833145142,
              0.18776999413967133,
              0.761434018611908,
              0.18776999413967133,
              0.7620369791984558,
              0.1877090036869049,
              0.7618529796600342,
              0.1877090036869049,
              0.7620700001716614,
              0.18776999413967133,
              0.7618529796600342,
              0.1877090036869049,
              0.7618849873542786,
              0.18776999413967133,
              0.7620700001716614,
              0.18776999413967133,
              0.7621920108795166,
              0.1877090036869049,
              0.7620369791984558,
              0.1877090036869049,
              0.7622269988059998,
              0.18776999413967133,
              0.7620369791984558,
              0.1877090036869049,
              0.7620700001716614,
              0.18776999413967133,
              0.7622269988059998,
              0.18776999413967133,
              0.762503981590271,
              0.1877090036869049,
              0.7624750137329102,
              0.1877090036869049,
              0.7625409960746765,
              0.18776999413967133,
              0.7624750137329102,
              0.1877090036869049,
              0.7625120282173157,
              0.18776999413967133,
              0.7625409960746765,
              0.18776999413967133,
              0.7623940110206604,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624300122261047,
              0.18776999413967133,
              0.7624549865722656,
              0.1877090036869049,
              0.76249098777771,
              0.18776999413967133,
              0.7624300122261047,
              0.18776999413967133,
              0.7574939727783203,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7574949860572815,
              0.18776999413967133,
              0.7570610046386719,
              0.1877090036869049,
              0.7570610046386719,
              0.18776999413967133,
              0.7574949860572815,
              0.18776999413967133,
              0.7574939727783203,
              0.18975800275802612,
              0.7579249739646912,
              0.1897609978914261,
              0.7574949860572815,
              0.18969100713729858,
              0.7579249739646912,
              0.1897609978914261,
              0.7579280138015747,
              0.18969400227069855,
              0.7574949860572815,
              0.18969100713729858,
              0.7579249739646912,
              0.1897609978914261,
              0.7583439946174622,
              0.18976899981498718,
              0.7579280138015747,
              0.18969400227069855,
              0.7583439946174622,
              0.18976899981498718,
              0.7583510279655457,
              0.18970300257205963,
              0.7579280138015747,
              0.18969400227069855,
              0.7583439946174622,
              0.18976899981498718,
              0.7587509751319885,
              0.1897830069065094,
              0.7583510279655457,
              0.18970300257205963,
              0.7587509751319885,
              0.1897830069065094,
              0.7587599754333496,
              0.18971699476242065,
              0.7583510279655457,
              0.18970300257205963,
              0.7595229744911194,
              0.18982599675655365,
              0.7598850131034851,
              0.1898539960384369,
              0.7595379948616028,
              0.1897599995136261,
              0.7598850131034851,
              0.1898539960384369,
              0.7599030137062073,
              0.18978899717330933,
              0.7595379948616028,
              0.1897599995136261,
              0.7598850131034851,
              0.1898539960384369,
              0.7602300047874451,
              0.18988700211048126,
              0.7599030137062073,
              0.18978899717330933,
              0.7602300047874451,
              0.18988700211048126,
              0.7602499723434448,
              0.1898220032453537,
              0.7599030137062073,
              0.18978899717330933,
              0.7602300047874451,
              0.18988700211048126,
              0.7605559825897217,
              0.18992400169372559,
              0.7602499723434448,
              0.1898220032453537,
              0.7605559825897217,
              0.18992400169372559,
              0.7605779767036438,
              0.18985900282859802,
              0.7602499723434448,
              0.1898220032453537,
              0.7608609795570374,
              0.18996499478816986,
              0.7611449956893921,
              0.19000999629497528,
              0.760886013507843,
              0.1898999959230423,
              0.7611449956893921,
              0.19000999629497528,
              0.7611709833145142,
              0.18994499742984772,
              0.760886013507843,
              0.1898999959230423,
              0.7614060044288635,
              0.19005699455738068,
              0.7616419792175293,
              0.1901089996099472,
              0.761434018611908,
              0.1899929940700531,
              0.7616419792175293,
              0.1901089996099472,
              0.7616720199584961,
              0.19004400074481964,
              0.761434018611908,
              0.1899929940700531,
              0.7621920108795166,
              0.19027799367904663,
              0.7623180150985718,
              0.19033899903297424,
              0.7622269988059998,
              0.19021500647068024,
              0.7623180150985718,
              0.19033899903297424,
              0.7623530030250549,
              0.19027699530124664,
              0.7622269988059998,
              0.19021500647068024,
              0.7624130249023438,
              0.19040200114250183,
              0.7624750137329102,
              0.1904670000076294,
              0.7624490261077881,
              0.19033999741077423,
              0.7624750137329102,
              0.1904670000076294,
              0.7625120282173157,
              0.1904049962759018,
              0.7624490261077881,
              0.19033999741077423,
              0.762503981590271,
              0.19053299725055695,
              0.7624980211257935,
              0.19059999287128448,
              0.7625409960746765,
              0.19047200679779053,
              0.7624980211257935,
              0.19059999287128448,
              0.7625340223312378,
              0.19054000079631805,
              0.7625409960746765,
              0.19047200679779053,
              0.7624980211257935,
              0.19059999287128448,
              0.7624549865722656,
              0.1906680017709732,
              0.7625340223312378,
              0.19054000079631805,
              0.7624549865722656,
              0.1906680017709732,
              0.76249098777771,
              0.19060799479484558,
              0.7625340223312378,
              0.19054000079631805,
              0.7515259981155396,
              0.45870399475097656,
              0.7515509724617004,
              0.45765599608421326,
              0.7515159845352173,
              0.45862001180648804,
              0.7515509724617004,
              0.45765599608421326,
              0.7515400052070618,
              0.4575830101966858,
              0.7515159845352173,
              0.45862001180648804,
              0.7515010237693787,
              0.4597170054912567,
              0.7515259981155396,
              0.45870399475097656,
              0.7514910101890564,
              0.4596239924430847,
              0.7515259981155396,
              0.45870399475097656,
              0.7515159845352173,
              0.45862001180648804,
              0.7514910101890564,
              0.4596239924430847,
              0.7514550089836121,
              0.4616360068321228,
              0.7514780163764954,
              0.4606949985027313,
              0.751446008682251,
              0.46152499318122864,
              0.7514780163764954,
              0.4606949985027313,
              0.7514680027961731,
              0.46059298515319824,
              0.751446008682251,
              0.46152499318122864,
              0.7513570189476013,
              0.4657270014286041,
              0.7513750195503235,
              0.46499499678611755,
              0.7513489723205566,
              0.46557798981666565,
              0.7513750195503235,
              0.46499499678611755,
              0.7513660192489624,
              0.46485400199890137,
              0.7513489723205566,
              0.46557798981666565,
              0.7513260245323181,
              0.4670479893684387,
              0.7513409852981567,
              0.4664109945297241,
              0.751317024230957,
              0.4668880105018616,
              0.7513409852981567,
              0.4664109945297241,
              0.7513329982757568,
              0.4662570059299469,
              0.751317024230957,
              0.4668880105018616,
              0.7515159845352173,
              0.45911499857902527,
              0.751479983329773,
              0.46059098839759827,
              0.7515019774436951,
              0.45917800068855286,
              0.751479983329773,
              0.46059098839759827,
              0.7514669895172119,
              0.4606640040874481,
              0.7515019774436951,
              0.45917800068855286,
              0.751380980014801,
              0.4647420048713684,
              0.7513499855995178,
              0.46602699160575867,
              0.7513660192489624,
              0.4648439884185791,
              0.7513499855995178,
              0.46602699160575867,
              0.7513350248336792,
              0.46613800525665283,
              0.7513660192489624,
              0.4648439884185791,
              0.7512919902801514,
              0.46843698620796204,
              0.7512660026550293,
              0.469556987285614,
              0.7512770295143127,
              0.4685640037059784,
              0.7512660026550293,
              0.469556987285614,
              0.7512500286102295,
              0.4696919918060303,
              0.7512770295143127,
              0.4685640037059784,
              0.75102299451828,
              0.4796789884567261,
              0.7510070204734802,
              0.4798569977283478,
              0.7510679960250854,
              0.47782400250434875,
              0.7510070204734802,
              0.4798569977283478,
              0.7510510087013245,
              0.4780080020427704,
              0.7510679960250854,
              0.47782400250434875,
              0.7514780163764954,
              0.4606949985027313,
              0.7515010237693787,
              0.4597170054912567,
              0.7514680027961731,
              0.46059298515319824,
              0.7515010237693787,
              0.4597170054912567,
              0.7514910101890564,
              0.4596239924430847,
              0.7514680027961731,
              0.46059298515319824,
              0.7513750195503235,
              0.46499499678611755,
              0.7513939738273621,
              0.46421900391578674,
              0.7513660192489624,
              0.46485400199890137,
              0.7513939738273621,
              0.46421900391578674,
              0.751384973526001,
              0.4640839993953705,
              0.7513660192489624,
              0.46485400199890137,
              0.7512990236282349,
              0.46817100048065186,
              0.7513120174407959,
              0.46763500571250916,
              0.7512909770011902,
              0.46800199151039124,
              0.7513120174407959,
              0.46763500571250916,
              0.7513039708137512,
              0.46747100353240967,
              0.7512909770011902,
              0.46800199151039124,
              0.7512869834899902,
              0.46865400671958923,
              0.7512990236282349,
              0.46817100048065186,
              0.7512789964675903,
              0.46848100423812866,
              0.7512990236282349,
              0.46817100048065186,
              0.7512909770011902,
              0.46800199151039124,
              0.7512789964675903,
              0.46848100423812866,
              0.7512609958648682,
              0.46977099776268005,
              0.7512680292129517,
              0.4694559872150421,
              0.7512530088424683,
              0.4695909917354584,
              0.7512680292129517,
              0.4694559872150421,
              0.751259982585907,
              0.4692780077457428,
              0.7512530088424683,
              0.4695909917354584,
              0.7512540221214294,
              0.47002899646759033,
              0.7512609958648682,
              0.46977099776268005,
              0.751246988773346,
              0.4698469936847687,
              0.7512609958648682,
              0.46977099776268005,
              0.7512530088424683,
              0.4695909917354584,
              0.751246988773346,
              0.4698469936847687,
              0.7515519857406616,
              0.4575969874858856,
              0.7515159845352173,
              0.45911499857902527,
              0.7515389919281006,
              0.45765000581741333,
              0.7515159845352173,
              0.45911499857902527,
              0.7515019774436951,
              0.45917800068855286,
              0.7515389919281006,
              0.45765000581741333,
              0.751479983329773,
              0.46059098839759827,
              0.751446008682251,
              0.46202200651168823,
              0.7514669895172119,
              0.4606640040874481,
              0.751446008682251,
              0.46202200651168823,
              0.7514320015907288,
              0.46210500597953796,
              0.7514669895172119,
              0.4606640040874481,
              0.7512660026550293,
              0.469556987285614,
              0.7512400150299072,
              0.47061899304389954,
              0.7512500286102295,
              0.4696919918060303,
              0.7512400150299072,
              0.47061899304389954,
              0.7512249946594238,
              0.4707599878311157,
              0.7512500286102295,
              0.4696919918060303,
              0.7512400150299072,
              0.47061899304389954,
              0.7512159943580627,
              0.47161799669265747,
              0.7512249946594238,
              0.4707599878311157,
              0.7512159943580627,
              0.47161799669265747,
              0.7512009739875793,
              0.47176700830459595,
              0.7512249946594238,
              0.4707599878311157,
              0.7511940002441406,
              0.472555011510849,
              0.7511730194091797,
              0.4734260141849518,
              0.7511780261993408,
              0.4727090001106262,
              0.7511730194091797,
              0.4734260141849518,
              0.7511569857597351,
              0.47358599305152893,
              0.7511780261993408,
              0.4727090001106262,
              0.7511540055274963,
              0.47422999143600464,
              0.7511360049247742,
              0.4749639928340912,
              0.7511379718780518,
              0.4743939936161041,
              0.7511360049247742,
              0.4749639928340912,
              0.7511199712753296,
              0.4751319885253906,
              0.7511379718780518,
              0.4743939936161041,
              0.7511360049247742,
              0.4749639928340912,
              0.7511199712753296,
              0.47562599182128906,
              0.7511199712753296,
              0.4751319885253906,
              0.7511199712753296,
              0.47562599182128906,
              0.7511039972305298,
              0.47579801082611084,
              0.7511199712753296,
              0.4751319885253906,
              0.7510939836502075,
              0.4767259955406189,
              0.75108402967453,
              0.4771600067615509,
              0.7510780096054077,
              0.4769040048122406,
              0.75108402967453,
              0.4771600067615509,
              0.7510669827461243,
              0.4773400127887726,
              0.7510780096054077,
              0.4769040048122406,
              0.7583529949188232,
              0.18795199692249298,
              0.7587640285491943,
              0.18795199692249298,
              0.7583510279655457,
              0.18776999413967133,
              0.7587640285491943,
              0.18795199692249298,
              0.7587599754333496,
              0.18776999413967133,
              0.7583510279655457,
              0.18776999413967133,
              0.7605850100517273,
              0.18795199692249298,
              0.7608940005302429,
              0.18795199692249298,
              0.7605779767036438,
              0.18776999413967133,
              0.7608940005302429,
              0.18795199692249298,
              0.760886013507843,
              0.18776999413967133,
              0.7605779767036438,
              0.18776999413967133,
              0.7608940005302429,
              0.18795199692249298,
              0.7611799836158752,
              0.18795199692249298,
              0.760886013507843,
              0.18776999413967133,
              0.7611799836158752,
              0.18795199692249298,
              0.7611709833145142,
              0.18776999413967133,
              0.760886013507843,
              0.18776999413967133,
              0.7614439725875854,
              0.18795199692249298,
              0.7616829872131348,
              0.18795199692249298,
              0.761434018611908,
              0.18776999413967133,
              0.7616829872131348,
              0.18795199692249298,
              0.7616720199584961,
              0.18776999413967133,
              0.761434018611908,
              0.18776999413967133,
              0.7624610066413879,
              0.18795199692249298,
              0.7625240087509155,
              0.18795199692249298,
              0.7624490261077881,
              0.18776999413967133,
              0.7625240087509155,
              0.18795199692249298,
              0.7625120282173157,
              0.18776999413967133,
              0.7624490261077881,
              0.18776999413967133,
              0.7625460028648376,
              0.18795199692249298,
              0.7625030279159546,
              0.18795199692249298,
              0.7625340223312378,
              0.18776999413967133,
              0.7625030279159546,
              0.18795199692249298,
              0.76249098777771,
              0.18776999413967133,
              0.7625340223312378,
              0.18776999413967133,
              0.7574949860572815,
              0.18795199692249298,
              0.7579290270805359,
              0.18795199692249298,
              0.7574949860572815,
              0.18776999413967133,
              0.7579290270805359,
              0.18795199692249298,
              0.7579280138015747,
              0.18776999413967133,
              0.7574949860572815,
              0.18776999413967133,
              0.7595430016517639,
              0.18795199692249298,
              0.7599089741706848,
              0.18795199692249298,
              0.7595379948616028,
              0.18776999413967133,
              0.7599089741706848,
              0.18795199692249298,
              0.7599030137062073,
              0.18776999413967133,
              0.7595379948616028,
              0.18776999413967133,
              0.7602570056915283,
              0.18795199692249298,
              0.7605850100517273,
              0.18795199692249298,
              0.7602499723434448,
              0.18776999413967133,
              0.7605850100517273,
              0.18795199692249298,
              0.7605779767036438,
              0.18776999413967133,
              0.7602499723434448,
              0.18776999413967133,
              0.7616829872131348,
              0.18795199692249298,
              0.7618950009346008,
              0.18795199692249298,
              0.7616720199584961,
              0.18776999413967133,
              0.7618950009346008,
              0.18795199692249298,
              0.7618849873542786,
              0.18776999413967133,
              0.7616720199584961,
              0.18776999413967133,
              0.7622380256652832,
              0.18795199692249298,
              0.7623649835586548,
              0.18795199692249298,
              0.7622269988059998,
              0.18776999413967133,
              0.7623649835586548,
              0.18795199692249298,
              0.7623530030250549,
              0.18776999413967133,
              0.7622269988059998,
              0.18776999413967133,
              0.7623649835586548,
              0.18795199692249298,
              0.7624610066413879,
              0.18795199692249298,
              0.7623530030250549,
              0.18776999413967133,
              0.7624610066413879,
              0.18795199692249298,
              0.7624490261077881,
              0.18776999413967133,
              0.7623530030250549,
              0.18776999413967133,
              0.7415549755096436,
              0.18795199692249298,
              0.7570610046386719,
              0.18795199692249298,
              0.7415549755096436,
              0.18776999413967133,
              0.7570610046386719,
              0.18795199692249298,
              0.7570610046386719,
              0.18776999413967133,
              0.7415549755096436,
              0.18776999413967133,
              0.7591609954833984,
              0.18955199420452118,
              0.7587640285491943,
              0.18953299522399902,
              0.7591570019721985,
              0.1897359937429428,
              0.7587640285491943,
              0.18953299522399902,
              0.7587599754333496,
              0.18971699476242065,
              0.7591570019721985,
              0.1897359937429428,
              0.7595430016517639,
              0.18957599997520447,
              0.7591609954833984,
              0.18955199420452118,
              0.7595379948616028,
              0.1897599995136261,
              0.7591609954833984,
              0.18955199420452118,
              0.7591570019721985,
              0.1897359937429428,
              0.7595379948616028,
              0.1897599995136261,
              0.7608940005302429,
              0.18971599638462067,
              0.7605850100517273,
              0.1896750032901764,
              0.760886013507843,
              0.1898999959230423,
              0.7605850100517273,
              0.1896750032901764,
              0.7605779767036438,
              0.18985900282859802,
              0.760886013507843,
              0.1898999959230423,
              0.7614439725875854,
              0.18980999290943146,
              0.7611799836158752,
              0.1897609978914261,
              0.761434018611908,
              0.1899929940700531,
              0.7611799836158752,
              0.1897609978914261,
              0.7611709833145142,
              0.18994499742984772,
              0.761434018611908,
              0.1899929940700531,
              0.7618950009346008,
              0.1899159997701645,
              0.7616829872131348,
              0.189860999584198,
              0.7618849873542786,
              0.19009900093078613,
              0.7616829872131348,
              0.189860999584198,
              0.7616720199584961,
              0.19004400074481964,
              0.7618849873542786,
              0.19009900093078613,
              0.7620810270309448,
              0.18997299671173096,
              0.7618950009346008,
              0.1899159997701645,
              0.7620700001716614,
              0.1901559978723526,
              0.7618950009346008,
              0.1899159997701645,
              0.7618849873542786,
              0.19009900093078613,
              0.7620700001716614,
              0.1901559978723526,
              0.7622380256652832,
              0.1900320053100586,
              0.7620810270309448,
              0.18997299671173096,
              0.7622269988059998,
              0.19021500647068024,
              0.7620810270309448,
              0.18997299671173096,
              0.7620700001716614,
              0.1901559978723526,
              0.7622269988059998,
              0.19021500647068024,
              0.7624610066413879,
              0.1901569962501526,
              0.7623649835586548,
              0.190093994140625,
              0.7624490261077881,
              0.19033999741077423,
              0.7623649835586548,
              0.190093994140625,
              0.7623530030250549,
              0.19027699530124664,
              0.7624490261077881,
              0.19033999741077423,
              0.7625529766082764,
              0.19029000401496887,
              0.7625240087509155,
              0.19022299349308014,
              0.7625409960746765,
              0.19047200679779053,
              0.7625240087509155,
              0.19022299349308014,
              0.7625120282173157,
              0.1904049962759018,
              0.7625409960746765,
              0.19047200679779053,
              0.7624419927597046,
              0.19049400091171265,
              0.7625030279159546,
              0.19042600691318512,
              0.7624300122261047,
              0.1906760036945343,
              0.7625030279159546,
              0.19042600691318512,
              0.76249098777771,
              0.19060799479484558,
              0.7624300122261047,
              0.1906760036945343,
              0.7574949860572815,
              0.18950699269771576,
              0.7570610046386719,
              0.18950699269771576,
              0.7574949860572815,
              0.18969100713729858,
              0.7570610046386719,
              0.18950699269771576,
              0.7570610046386719,
              0.18969100713729858,
              0.7574949860572815,
              0.18969100713729858,
              0.7570610046386719,
              0.18950699269771576,
              0.7415549755096436,
              0.18950699269771576,
              0.7570610046386719,
              0.18969100713729858,
              0.7415549755096436,
              0.18950699269771576,
              0.7415549755096436,
              0.18969100713729858,
              0.7570610046386719,
              0.18969100713729858,
              0.7510889768600464,
              0.476949006319046,
              0.751255989074707,
              0.4699830114841461,
              0.7510799765586853,
              0.4773010015487671,
              0.751255989074707,
              0.4699830114841461,
              0.7512490153312683,
              0.4702419936656952,
              0.7510799765586853,
              0.4773010015487671,
              0.7510989904403687,
              0.476516991853714,
              0.7512630224227905,
              0.469664990901947,
              0.7510889768600464,
              0.476949006319046,
              0.7512630224227905,
              0.469664990901947,
              0.751255989074707,
              0.4699830114841461,
              0.7510889768600464,
              0.476949006319046,
              0.7511109709739685,
              0.47600799798965454,
              0.7512720227241516,
              0.46928900480270386,
              0.7510989904403687,
              0.476516991853714,
              0.7512720227241516,
              0.46928900480270386,
              0.7512630224227905,
              0.469664990901947,
              0.7510989904403687,
              0.476516991853714,
              0.7511249780654907,
              0.47542300820350647,
              0.7512819766998291,
              0.46885600686073303,
              0.7511109709739685,
              0.47600799798965454,
              0.7512819766998291,
              0.46885600686073303,
              0.7512720227241516,
              0.46928900480270386,
              0.7511109709739685,
              0.47600799798965454,
              0.7511410117149353,
              0.47476598620414734,
              0.7512940168380737,
              0.4683690071105957,
              0.7511249780654907,
              0.47542300820350647,
              0.7512940168380737,
              0.4683690071105957,
              0.7512819766998291,
              0.46885600686073303,
              0.7511249780654907,
              0.47542300820350647,
              0.7511579990386963,
              0.4740369915962219,
              0.7513070106506348,
              0.46782800555229187,
              0.7511410117149353,
              0.47476598620414734,
              0.7513070106506348,
              0.46782800555229187,
              0.7512940168380737,
              0.4683690071105957,
              0.7511410117149353,
              0.47476598620414734,
              0.7511780261993408,
              0.473239004611969,
              0.751321017742157,
              0.4672349989414215,
              0.7511579990386963,
              0.4740369915962219,
              0.751321017742157,
              0.4672349989414215,
              0.7513070106506348,
              0.46782800555229187,
              0.7511579990386963,
              0.4740369915962219,
              0.7511979937553406,
              0.47237399220466614,
              0.7513369917869568,
              0.466592013835907,
              0.7511780261993408,
              0.473239004611969,
              0.7513369917869568,
              0.466592013835907,
              0.751321017742157,
              0.4672349989414215,
              0.7511780261993408,
              0.473239004611969,
              0.7512199878692627,
              0.4714449942111969,
              0.7513530254364014,
              0.4659009873867035,
              0.7511979937553406,
              0.47237399220466614,
              0.7513530254364014,
              0.4659009873867035,
              0.7513369917869568,
              0.466592013835907,
              0.7511979937553406,
              0.47237399220466614,
              0.7512440085411072,
              0.4704520106315613,
              0.7513710260391235,
              0.4651620090007782,
              0.7512199878692627,
              0.4714449942111969,
              0.7513710260391235,
              0.4651620090007782,
              0.7513530254364014,
              0.4659009873867035,
              0.7512199878692627,
              0.4714449942111969,
              0.7512689828872681,
              0.46939900517463684,
              0.7513899803161621,
              0.4643769860267639,
              0.7512440085411072,
              0.4704520106315613,
              0.7513899803161621,
              0.4643769860267639,
              0.7513710260391235,
              0.4651620090007782,
              0.7512440085411072,
              0.4704520106315613,
              0.7512959837913513,
              0.46828800439834595,
              0.7514100074768066,
              0.4635489881038666,
              0.7512689828872681,
              0.46939900517463684,
              0.7514100074768066,
              0.4635489881038666,
              0.7513899803161621,
              0.4643769860267639,
              0.7512689828872681,
              0.46939900517463684,
              0.7512959837913513,
              0.46828800439834595,
              0.7513239979743958,
              0.4671199917793274,
              0.7514100074768066,
              0.4635489881038666,
              0.7513239979743958,
              0.4671199917793274,
              0.7514309883117676,
              0.4626779854297638,
              0.7514100074768066,
              0.4635489881038666,
              0.7513239979743958,
              0.4671199917793274,
              0.7513530254364014,
              0.46589699387550354,
              0.7514309883117676,
              0.4626779854297638,
              0.7513530254364014,
              0.46589699387550354,
              0.7514520287513733,
              0.46176600456237793,
              0.7514309883117676,
              0.4626779854297638,
              0.7513530254364014,
              0.46589699387550354,
              0.7513840198516846,
              0.46462199091911316,
              0.7514520287513733,
              0.46176600456237793,
              0.7513840198516846,
              0.46462199091911316,
              0.7514749765396118,
              0.4608150124549866,
              0.7514520287513733,
              0.46176600456237793,
              0.7513840198516846,
              0.46462199091911316,
              0.751416027545929,
              0.4632970094680786,
              0.7514749765396118,
              0.4608150124549866,
              0.751416027545929,
              0.4632970094680786,
              0.7514989972114563,
              0.4598270058631897,
              0.7514749765396118,
              0.4608150124549866,
              0.751416027545929,
              0.4632970094680786,
              0.7514489889144897,
              0.4619239866733551,
              0.7514989972114563,
              0.4598270058631897,
              0.7514489889144897,
              0.4619239866733551,
              0.7515230178833008,
              0.4588020145893097,
              0.7514989972114563,
              0.4598270058631897,
              0.7514489889144897,
              0.4619239866733551,
              0.7514830231666565,
              0.4605039954185486,
              0.7515230178833008,
              0.4588020145893097,
              0.7514830231666565,
              0.4605039954185486,
              0.7515490055084229,
              0.45774298906326294,
              0.7515230178833008,
              0.4588020145893097,
              0.7514830231666565,
              0.4605039954185486,
              0.7515180110931396,
              0.45903998613357544,
              0.7515490055084229,
              0.45774298906326294,
              0.7515180110931396,
              0.45903998613357544,
              0.7515749931335449,
              0.4566510021686554,
              0.7515490055084229,
              0.45774298906326294,
              0.7515180110931396,
              0.45903998613357544,
              0.751554012298584,
              0.45753398537635803,
              0.7515749931335449,
              0.4566510021686554,
              0.751554012298584,
              0.45753398537635803,
              0.7516019940376282,
              0.45552799105644226,
              0.7515749931335449,
              0.4566510021686554,
              0.751554012298584,
              0.45753398537635803,
              0.7515910267829895,
              0.45598798990249634,
              0.7516019940376282,
              0.45552799105644226,
              0.7515910267829895,
              0.45598798990249634,
              0.7516289949417114,
              0.4543749988079071,
              0.7516019940376282,
              0.45552799105644226,
              0.7510799765586853,
              0.4773010015487671,
              0.7512490153312683,
              0.4702419936656952,
              0.7510730028152466,
              0.4776090085506439,
              0.7512490153312683,
              0.4702419936656952,
              0.7512440085411072,
              0.4704720079898834,
              0.7510730028152466,
              0.4776090085506439,
              0.7579290270805359,
              0.18816600739955902,
              0.7574949860572815,
              0.18816600739955902,
              0.7579290270805359,
              0.18929600715637207,
              0.7574949860572815,
              0.18816600739955902,
              0.7574949860572815,
              0.1892929971218109,
              0.7579290270805359,
              0.18929600715637207,
              0.7583529949188232,
              0.18816600739955902,
              0.7579290270805359,
              0.18816600739955902,
              0.7583529949188232,
              0.18930499255657196,
              0.7579290270805359,
              0.18816600739955902,
              0.7579290270805359,
              0.18929600715637207,
              0.7583529949188232,
              0.18930499255657196,
              0.7587640285491943,
              0.18816600739955902,
              0.7583529949188232,
              0.18816600739955902,
              0.7587640285491943,
              0.18931899964809418,
              0.7583529949188232,
              0.18816600739955902,
              0.7583529949188232,
              0.18930499255657196,
              0.7587640285491943,
              0.18931899964809418,
              0.7591609954833984,
              0.18816600739955902,
              0.7587640285491943,
              0.18816600739955902,
              0.7591609954833984,
              0.18933799862861633,
              0.7587640285491943,
              0.18816600739955902,
              0.7587640285491943,
              0.18931899964809418,
              0.7591609954833984,
              0.18933799862861633,
              0.7595430016517639,
              0.18816600739955902,
              0.7591609954833984,
              0.18816600739955902,
              0.7595430016517639,
              0.18936200439929962,
              0.7591609954833984,
              0.18816600739955902,
              0.7591609954833984,
              0.18933799862861633,
              0.7595430016517639,
              0.18936200439929962,
              0.7599089741706848,
              0.18816600739955902,
              0.7595430016517639,
              0.18816600739955902,
              0.7599089741706848,
              0.18939100205898285,
              0.7595430016517639,
              0.18816600739955902,
              0.7595430016517639,
              0.18936200439929962,
              0.7599089741706848,
              0.18939100205898285,
              0.7602570056915283,
              0.18816600739955902,
              0.7599089741706848,
              0.18816600739955902,
              0.7602570056915283,
              0.18942399322986603,
              0.7599089741706848,
              0.18816600739955902,
              0.7599089741706848,
              0.18939100205898285,
              0.7602570056915283,
              0.18942399322986603,
              0.7605850100517273,
              0.18816600739955902,
              0.7602570056915283,
              0.18816600739955902,
              0.7605850100517273,
              0.18946099281311035,
              0.7602570056915283,
              0.18816600739955902,
              0.7602570056915283,
              0.18942399322986603,
              0.7605850100517273,
              0.18946099281311035,
              0.7608940005302429,
              0.18816600739955902,
              0.7605850100517273,
              0.18816600739955902,
              0.7608940005302429,
              0.18950200080871582,
              0.7605850100517273,
              0.18816600739955902,
              0.7605850100517273,
              0.18946099281311035,
              0.7608940005302429,
              0.18950200080871582,
              0.7611799836158752,
              0.18816600739955902,
              0.7608940005302429,
              0.18816600739955902,
              0.7611799836158752,
              0.18954700231552124,
              0.7608940005302429,
              0.18816600739955902,
              0.7608940005302429,
              0.18950200080871582,
              0.7611799836158752,
              0.18954700231552124,
              0.7614439725875854,
              0.18816600739955902,
              0.7611799836158752,
              0.18816600739955902,
              0.7614439725875854,
              0.1895959973335266,
              0.7611799836158752,
              0.18816600739955902,
              0.7611799836158752,
              0.18954700231552124,
              0.7614439725875854,
              0.1895959973335266,
              0.7616829872131348,
              0.18816600739955902,
              0.7614439725875854,
              0.18816600739955902,
              0.7616829872131348,
              0.18964700400829315,
              0.7614439725875854,
              0.18816600739955902,
              0.7614439725875854,
              0.1895959973335266,
              0.7616829872131348,
              0.18964700400829315,
              0.7618950009346008,
              0.18816600739955902,
              0.7616829872131348,
              0.18816600739955902,
              0.7618950009346008,
              0.18970200419425964,
              0.7616829872131348,
              0.18816600739955902,
              0.7616829872131348,
              0.18964700400829315,
              0.7618950009346008,
              0.18970200419425964,
              0.7620810270309448,
              0.18816600739955902,
              0.7618950009346008,
              0.18816600739955902,
              0.7620810270309448,
              0.1897590011358261,
              0.7618950009346008,
              0.18816600739955902,
              0.7618950009346008,
              0.18970200419425964,
              0.7620810270309448,
              0.1897590011358261,
              0.7622380256652832,
              0.18816600739955902,
              0.7620810270309448,
              0.18816600739955902,
              0.7622380256652832,
              0.18981799483299255,
              0.7620810270309448,
              0.18816600739955902,
              0.7620810270309448,
              0.1897590011358261,
              0.7622380256652832,
              0.18981799483299255,
              0.7623649835586548,
              0.18816600739955902,
              0.7622380256652832,
              0.18816600739955902,
              0.7623649835586548,
              0.18987999856472015,
              0.7622380256652832,
              0.18816600739955902,
              0.7622380256652832,
              0.18981799483299255,
              0.7623649835586548,
              0.18987999856472015,
              0.7624610066413879,
              0.18816600739955902,
              0.7623649835586548,
              0.18816600739955902,
              0.7624610066413879,
              0.18994399905204773,
              0.7623649835586548,
              0.18816600739955902,
              0.7623649835586548,
              0.18987999856472015,
              0.7624610066413879,
              0.18994399905204773,
              0.7625240087509155,
              0.18816600739955902,
              0.7624610066413879,
              0.18816600739955902,
              0.7625240087509155,
              0.1900089979171753,
              0.7624610066413879,
              0.18816600739955902,
              0.7624610066413879,
              0.18994399905204773,
              0.7625240087509155,
              0.1900089979171753,
              0.7625529766082764,
              0.18816600739955902,
              0.7625240087509155,
              0.18816600739955902,
              0.7625529766082764,
              0.19007599353790283,
              0.7625240087509155,
              0.18816600739955902,
              0.7625240087509155,
              0.1900089979171753,
              0.7625529766082764,
              0.19007599353790283,
              0.7625460028648376,
              0.18816600739955902,
              0.7625529766082764,
              0.18816600739955902,
              0.7625460028648376,
              0.19014300405979156,
              0.7625529766082764,
              0.18816600739955902,
              0.7625529766082764,
              0.19007599353790283,
              0.7625460028648376,
              0.19014300405979156,
              0.7625030279159546,
              0.18816600739955902,
              0.7625460028648376,
              0.18816600739955902,
              0.7625030279159546,
              0.19021199643611908,
              0.7625460028648376,
              0.18816600739955902,
              0.7625460028648376,
              0.19014300405979156,
              0.7625030279159546,
              0.19021199643611908,
              0.7624419927597046,
              0.18816600739955902,
              0.7625030279159546,
              0.18816600739955902,
              0.7624419927597046,
              0.1902800053358078,
              0.7625030279159546,
              0.18816600739955902,
              0.7625030279159546,
              0.19021199643611908,
              0.7624419927597046,
              0.1902800053358078,
              0.7574949860572815,
              0.18816600739955902,
              0.7570610046386719,
              0.18816600739955902,
              0.7574949860572815,
              0.1892929971218109,
              0.7570610046386719,
              0.18816600739955902,
              0.7570610046386719,
              0.1892929971218109,
              0.7574949860572815,
              0.1892929971218109,
              0.7570610046386719,
              0.18816600739955902,
              0.7415549755096436,
              0.18816600739955902,
              0.7570610046386719,
              0.1892929971218109,
              0.7415549755096436,
              0.18816600739955902,
              0.7415549755096436,
              0.1892929971218109,
              0.7570610046386719,
              0.1892929971218109,
              0.7515749931335449,
              0.4566510021686554,
              0.7516019940376282,
              0.45552799105644226,
              0.7515770196914673,
              0.45657598972320557,
              0.7516019940376282,
              0.45552799105644226,
              0.7516030073165894,
              0.4554649889469147,
              0.7515770196914673,
              0.45657598972320557,
              0.7514309883117676,
              0.4626779854297638,
              0.7514520287513733,
              0.46176600456237793,
              0.7514340281486511,
              0.4625380039215088,
              0.7514520287513733,
              0.46176600456237793,
              0.7514550089836121,
              0.4616360068321228,
              0.7514340281486511,
              0.4625380039215088,
              0.7514100074768066,
              0.4635489881038666,
              0.7514309883117676,
              0.4626779854297638,
              0.7514129877090454,
              0.4633989930152893,
              0.7514309883117676,
              0.4626779854297638,
              0.7514340281486511,
              0.4625380039215088,
              0.7514129877090454,
              0.4633989930152893,
              0.7513899803161621,
              0.4643769860267639,
              0.7514100074768066,
              0.4635489881038666,
              0.7513939738273621,
              0.46421900391578674,
              0.7514100074768066,
              0.4635489881038666,
              0.7514129877090454,
              0.4633989930152893,
              0.7513939738273621,
              0.46421900391578674,
              0.7512720227241516,
              0.46928900480270386,
              0.7512819766998291,
              0.46885600686073303,
              0.7512770295143127,
              0.4690830111503601,
              0.7512819766998291,
              0.46885600686073303,
              0.7512869834899902,
              0.46865400671958923,
              0.7512770295143127,
              0.4690830111503601,
              0.7512630224227905,
              0.469664990901947,
              0.7512720227241516,
              0.46928900480270386,
              0.7512680292129517,
              0.4694559872150421,
              0.7512720227241516,
              0.46928900480270386,
              0.7512770295143127,
              0.4690830111503601,
              0.7512680292129517,
              0.4694559872150421,
              0.7515910267829895,
              0.45598798990249634,
              0.751554012298584,
              0.45753398537635803,
              0.7515900135040283,
              0.4560379981994629,
              0.751554012298584,
              0.45753398537635803,
              0.7515519857406616,
              0.4575969874858856,
              0.7515900135040283,
              0.4560379981994629,
              0.7514489889144897,
              0.4619239866733551,
              0.751416027545929,
              0.4632970094680786,
              0.751446008682251,
              0.46202200651168823,
              0.751416027545929,
              0.4632970094680786,
              0.7514129877090454,
              0.4634059965610504,
              0.751446008682251,
              0.46202200651168823,
              0.7511249780654907,
              0.47542300820350647,
              0.7511109709739685,
              0.47600799798965454,
              0.7511199712753296,
              0.47562599182128906,
              0.7511109709739685,
              0.47600799798965454,
              0.7511060237884521,
              0.4762139916419983,
              0.7511199712753296,
              0.47562599182128906,
              0.7511109709739685,
              0.47600799798965454,
              0.7510989904403687,
              0.476516991853714,
              0.7511060237884521,
              0.4762139916419983,
              0.7510989904403687,
              0.476516991853714,
              0.7510939836502075,
              0.4767259955406189,
              0.7511060237884521,
              0.4762139916419983,
              0.7510889768600464,
              0.476949006319046,
              0.7510799765586853,
              0.4773010015487671,
              0.75108402967453,
              0.4771600067615509,
              0.7510799765586853,
              0.4773010015487671,
              0.751075029373169,
              0.47751399874687195,
              0.75108402967453,
              0.4771600067615509,
              0.7516019940376282,
              0.45552799105644226,
              0.7516289949417114,
              0.4543749988079071,
              0.7516030073165894,
              0.4554649889469147,
              0.7516289949417114,
              0.4543749988079071,
              0.7516310214996338,
              0.45432499051094055,
              0.7516030073165894,
              0.4554649889469147,
              0.7515490055084229,
              0.45774298906326294,
              0.7515749931335449,
              0.4566510021686554,
              0.7515509724617004,
              0.45765599608421326,
              0.7515749931335449,
              0.4566510021686554,
              0.7515770196914673,
              0.45657598972320557,
              0.7515509724617004,
              0.45765599608421326,
              0.7513369917869568,
              0.466592013835907,
              0.7513530254364014,
              0.4659009873867035,
              0.7513409852981567,
              0.4664109945297241,
              0.7513530254364014,
              0.4659009873867035,
              0.7513570189476013,
              0.4657270014286041,
              0.7513409852981567,
              0.4664109945297241,
              0.7513070106506348,
              0.46782800555229187,
              0.751321017742157,
              0.4672349989414215,
              0.7513120174407959,
              0.46763500571250916,
              0.751321017742157,
              0.4672349989414215,
              0.7513260245323181,
              0.4670479893684387,
              0.7513120174407959,
              0.46763500571250916,
              0.7511990070343018,
              0.472339004278183,
              0.7511940002441406,
              0.4725480079650879,
              0.7512490153312683,
              0.4702570140361786,
              0.7511940002441406,
              0.4725480079650879,
              0.7512440085411072,
              0.4704720079898834,
              0.7512490153312683,
              0.4702570140361786,
              0.751416027545929,
              0.4632970094680786,
              0.7513840198516846,
              0.46462199091911316,
              0.7514129877090454,
              0.4634059965610504,
              0.7513840198516846,
              0.46462199091911316,
              0.751380980014801,
              0.4647420048713684,
              0.7514129877090454,
              0.4634059965610504,
              0.7513530254364014,
              0.46589699387550354,
              0.7513239979743958,
              0.4671199917793274,
              0.7513499855995178,
              0.46602699160575867,
              0.7513239979743958,
              0.4671199917793274,
              0.751321017742157,
              0.4672600030899048,
              0.7513499855995178,
              0.46602699160575867,
              0.7513239979743958,
              0.4671199917793274,
              0.7512959837913513,
              0.46828800439834595,
              0.751321017742157,
              0.4672600030899048,
              0.7512959837913513,
              0.46828800439834595,
              0.7512919902801514,
              0.46843698620796204,
              0.751321017742157,
              0.4672600030899048,
              0.7512199878692627,
              0.4714449942111969,
              0.7511979937553406,
              0.47237399220466614,
              0.7512159943580627,
              0.47161799669265747,
              0.7511979937553406,
              0.47237399220466614,
              0.7511940002441406,
              0.472555011510849,
              0.7512159943580627,
              0.47161799669265747,
              0.7511780261993408,
              0.473239004611969,
              0.7511579990386963,
              0.4740369915962219,
              0.7511730194091797,
              0.4734260141849518,
              0.7511579990386963,
              0.4740369915962219,
              0.7511540055274963,
              0.47422999143600464,
              0.7511730194091797,
              0.4734260141849518,
              0.7587640285491943,
              0.18816600739955902,
              0.7591609954833984,
              0.18816600739955902,
              0.7587640285491943,
              0.18795199692249298,
              0.7591609954833984,
              0.18816600739955902,
              0.7591609954833984,
              0.18795199692249298,
              0.7587640285491943,
              0.18795199692249298,
              0.7591609954833984,
              0.18816600739955902,
              0.7595430016517639,
              0.18816600739955902,
              0.7591609954833984,
              0.18795199692249298,
              0.7595430016517639,
              0.18816600739955902,
              0.7595430016517639,
              0.18795199692249298,
              0.7591609954833984,
              0.18795199692249298,
              0.7599089741706848,
              0.18816600739955902,
              0.7602570056915283,
              0.18816600739955902,
              0.7599089741706848,
              0.18795199692249298,
              0.7602570056915283,
              0.18816600739955902,
              0.7602570056915283,
              0.18795199692249298,
              0.7599089741706848,
              0.18795199692249298,
              0.7625529766082764,
              0.18816600739955902,
              0.7625460028648376,
              0.18816600739955902,
              0.7625529766082764,
              0.18795199692249298,
              0.7625460028648376,
              0.18816600739955902,
              0.7625460028648376,
              0.18795199692249298,
              0.7625529766082764,
              0.18795199692249298,
              0.7579290270805359,
              0.18816600739955902,
              0.7583529949188232,
              0.18816600739955902,
              0.7579290270805359,
              0.18795199692249298,
              0.7583529949188232,
              0.18816600739955902,
              0.7583529949188232,
              0.18795199692249298,
              0.7579290270805359,
              0.18795199692249298,
              0.7611799836158752,
              0.18816600739955902,
              0.7614439725875854,
              0.18816600739955902,
              0.7611799836158752,
              0.18795199692249298,
              0.7614439725875854,
              0.18816600739955902,
              0.7614439725875854,
              0.18795199692249298,
              0.7611799836158752,
              0.18795199692249298,
              0.7618950009346008,
              0.18816600739955902,
              0.7620810270309448,
              0.18816600739955902,
              0.7618950009346008,
              0.18795199692249298,
              0.7620810270309448,
              0.18816600739955902,
              0.7620810270309448,
              0.18795199692249298,
              0.7618950009346008,
              0.18795199692249298,
              0.7620810270309448,
              0.18816600739955902,
              0.7622380256652832,
              0.18816600739955902,
              0.7620810270309448,
              0.18795199692249298,
              0.7622380256652832,
              0.18816600739955902,
              0.7622380256652832,
              0.18795199692249298,
              0.7620810270309448,
              0.18795199692249298,
              0.7625240087509155,
              0.18816600739955902,
              0.7625529766082764,
              0.18816600739955902,
              0.7625240087509155,
              0.18795199692249298,
              0.7625529766082764,
              0.18816600739955902,
              0.7625529766082764,
              0.18795199692249298,
              0.7625240087509155,
              0.18795199692249298,
              0.7625030279159546,
              0.18816600739955902,
              0.7624419927597046,
              0.18816600739955902,
              0.7625030279159546,
              0.18795199692249298,
              0.7624419927597046,
              0.18816600739955902,
              0.7624419927597046,
              0.18795199692249298,
              0.7625030279159546,
              0.18795199692249298,
              0.7570610046386719,
              0.18816600739955902,
              0.7574949860572815,
              0.18816600739955902,
              0.7570610046386719,
              0.18795199692249298,
              0.7574949860572815,
              0.18816600739955902,
              0.7574949860572815,
              0.18795199692249298,
              0.7570610046386719,
              0.18795199692249298,
              0.7579290270805359,
              0.18929600715637207,
              0.7574949860572815,
              0.1892929971218109,
              0.7579290270805359,
              0.18951000273227692,
              0.7574949860572815,
              0.1892929971218109,
              0.7574949860572815,
              0.18950699269771576,
              0.7579290270805359,
              0.18951000273227692,
              0.7583529949188232,
              0.18930499255657196,
              0.7579290270805359,
              0.18929600715637207,
              0.7583529949188232,
              0.189519003033638,
              0.7579290270805359,
              0.18929600715637207,
              0.7579290270805359,
              0.18951000273227692,
              0.7583529949188232,
              0.189519003033638,
              0.7587640285491943,
              0.18931899964809418,
              0.7583529949188232,
              0.18930499255657196,
              0.7587640285491943,
              0.18953299522399902,
              0.7583529949188232,
              0.18930499255657196,
              0.7583529949188232,
              0.189519003033638,
              0.7587640285491943,
              0.18953299522399902,
              0.7599089741706848,
              0.18939100205898285,
              0.7595430016517639,
              0.18936200439929962,
              0.7599089741706848,
              0.1896049976348877,
              0.7595430016517639,
              0.18936200439929962,
              0.7595430016517639,
              0.18957599997520447,
              0.7599089741706848,
              0.1896049976348877,
              0.7602570056915283,
              0.18942399322986603,
              0.7599089741706848,
              0.18939100205898285,
              0.7602570056915283,
              0.18963800370693207,
              0.7599089741706848,
              0.18939100205898285,
              0.7599089741706848,
              0.1896049976348877,
              0.7602570056915283,
              0.18963800370693207,
              0.7605850100517273,
              0.18946099281311035,
              0.7602570056915283,
              0.18942399322986603,
              0.7605850100517273,
              0.1896750032901764,
              0.7602570056915283,
              0.18942399322986603,
              0.7602570056915283,
              0.18963800370693207,
              0.7605850100517273,
              0.1896750032901764,
              0.7611799836158752,
              0.18954700231552124,
              0.7608940005302429,
              0.18950200080871582,
              0.7611799836158752,
              0.1897609978914261,
              0.7608940005302429,
              0.18950200080871582,
              0.7608940005302429,
              0.18971599638462067,
              0.7611799836158752,
              0.1897609978914261,
              0.7616829872131348,
              0.18964700400829315,
              0.7614439725875854,
              0.1895959973335266,
              0.7616829872131348,
              0.189860999584198,
              0.7614439725875854,
              0.1895959973335266,
              0.7614439725875854,
              0.18980999290943146,
              0.7616829872131348,
              0.189860999584198,
              0.7623649835586548,
              0.18987999856472015,
              0.7622380256652832,
              0.18981799483299255,
              0.7623649835586548,
              0.190093994140625,
              0.7622380256652832,
              0.18981799483299255,
              0.7622380256652832,
              0.1900320053100586,
              0.7623649835586548,
              0.190093994140625,
              0.7625240087509155,
              0.1900089979171753,
              0.7624610066413879,
              0.18994399905204773,
              0.7625240087509155,
              0.19022299349308014,
              0.7624610066413879,
              0.18994399905204773,
              0.7624610066413879,
              0.1901569962501526,
              0.7625240087509155,
              0.19022299349308014,
              0.7625460028648376,
              0.19014300405979156,
              0.7625529766082764,
              0.19007599353790283,
              0.7625460028648376,
              0.1903569996356964,
              0.7625529766082764,
              0.19007599353790283,
              0.7625529766082764,
              0.19029000401496887,
              0.7625460028648376,
              0.1903569996356964,
              0.7625030279159546,
              0.19021199643611908,
              0.7625460028648376,
              0.19014300405979156,
              0.7625030279159546,
              0.19042600691318512,
              0.7625460028648376,
              0.19014300405979156,
              0.7625460028648376,
              0.1903569996356964,
              0.7625030279159546,
              0.19042600691318512,
              0.7514610290527344,
              0.4575580060482025,
              0.7514359951019287,
              0.4585919976234436,
              0.7515040040016174,
              0.4575580060482025,
              0.7514359951019287,
              0.4585919976234436,
              0.751479983329773,
              0.4585919976234436,
              0.7515040040016174,
              0.4575580060482025,
              0.7514359951019287,
              0.4585919976234436,
              0.7514129877090454,
              0.4595929980278015,
              0.751479983329773,
              0.4585919976234436,
              0.7514129877090454,
              0.4595929980278015,
              0.7514560222625732,
              0.4595929980278015,
              0.751479983329773,
              0.4585919976234436,
              0.7513899803161621,
              0.46055901050567627,
              0.7513669729232788,
              0.4614880084991455,
              0.7514320015907288,
              0.46055901050567627,
              0.7513669729232788,
              0.4614880084991455,
              0.7514100074768066,
              0.4614880084991455,
              0.7514320015907288,
              0.46055901050567627,
              0.7512879967689514,
              0.4648059904575348,
              0.7512710094451904,
              0.4655289947986603,
              0.7513309717178345,
              0.4648059904575348,
              0.7512710094451904,
              0.4655289947986603,
              0.7513129711151123,
              0.4655289947986603,
              0.7513309717178345,
              0.4648059904575348,
              0.7512540221214294,
              0.46620601415634155,
              0.751239001750946,
              0.46683499217033386,
              0.7512969970703125,
              0.46620601415634155,
              0.751239001750946,
              0.46683499217033386,
              0.7512819766998291,
              0.46683499217033386,
              0.7512969970703125,
              0.46620601415634155,
              0.7513859868049622,
              0.4606890082359314,
              0.7514219880104065,
              0.459199994802475,
              0.75142902135849,
              0.4606890082359314,
              0.7514219880104065,
              0.459199994802475,
              0.7514650225639343,
              0.459199994802475,
              0.75142902135849,
              0.4606890082359314,
              0.7512549757957458,
              0.46617498993873596,
              0.751285970211029,
              0.46487900614738464,
              0.7512980103492737,
              0.46617498993873596,
              0.751285970211029,
              0.46487900614738464,
              0.7513290047645569,
              0.46487900614738464,
              0.7512980103492737,
              0.46617498993873596,
              0.7511699795722961,
              0.4697369933128357,
              0.7511969804763794,
              0.46860700845718384,
              0.751213014125824,
              0.4697369933128357,
              0.7511969804763794,
              0.46860700845718384,
              0.7512400150299072,
              0.46860700845718384,
              0.751213014125824,
              0.4697369933128357,
              0.7509689927101135,
              0.4799160063266754,
              0.7509260177612305,
              0.4799160063266754,
              0.7510129809379578,
              0.478069007396698,
              0.7509260177612305,
              0.4799160063266754,
              0.7509700059890747,
              0.478069007396698,
              0.7510129809379578,
              0.478069007396698,
              0.7514129877090454,
              0.4595929980278015,
              0.7513899803161621,
              0.46055901050567627,
              0.7514560222625732,
              0.4595929980278015,
              0.7513899803161621,
              0.46055901050567627,
              0.7514320015907288,
              0.46055901050567627,
              0.7514560222625732,
              0.4595929980278015,
              0.7513059973716736,
              0.46404001116752625,
              0.7512879967689514,
              0.4648059904575348,
              0.7513489723205566,
              0.46404001116752625,
              0.7512879967689514,
              0.4648059904575348,
              0.7513309717178345,
              0.4648059904575348,
              0.7513489723205566,
              0.46404001116752625,
              0.7512249946594238,
              0.467415988445282,
              0.751213014125824,
              0.46794599294662476,
              0.7512680292129517,
              0.467415988445282,
              0.751213014125824,
              0.46794599294662476,
              0.751255989074707,
              0.46794599294662476,
              0.7512680292129517,
              0.467415988445282,
              0.751213014125824,
              0.46794599294662476,
              0.7512009739875793,
              0.468423992395401,
              0.751255989074707,
              0.46794599294662476,
              0.7512009739875793,
              0.468423992395401,
              0.7512440085411072,
              0.468423992395401,
              0.751255989074707,
              0.46794599294662476,
              0.7511820197105408,
              0.469217985868454,
              0.7511749863624573,
              0.46953099966049194,
              0.7512249946594238,
              0.469217985868454,
              0.7511749863624573,
              0.46953099966049194,
              0.7512180209159851,
              0.46953099966049194,
              0.7512249946594238,
              0.469217985868454,
              0.7511749863624573,
              0.46953099966049194,
              0.7511690258979797,
              0.46978598833084106,
              0.7512180209159851,
              0.46953099966049194,
              0.7511690258979797,
              0.46978598833084106,
              0.7512120008468628,
              0.46978598833084106,
              0.7512180209159851,
              0.46953099966049194,
              0.7514219880104065,
              0.459199994802475,
              0.751459002494812,
              0.4576680064201355,
              0.7514650225639343,
              0.459199994802475,
              0.751459002494812,
              0.4576680064201355,
              0.7515019774436951,
              0.4576680064201355,
              0.7514650225639343,
              0.459199994802475,
              0.7513520121574402,
              0.46213299036026,
              0.7513859868049622,
              0.4606890082359314,
              0.7513949871063232,
              0.46213299036026,
              0.7513859868049622,
              0.4606890082359314,
              0.75142902135849,
              0.4606890082359314,
              0.7513949871063232,
              0.46213299036026,
              0.7511439919471741,
              0.4708069860935211,
              0.7511699795722961,
              0.4697369933128357,
              0.7511870265007019,
              0.4708069860935211,
              0.7511699795722961,
              0.4697369933128357,
              0.751213014125824,
              0.4697369933128357,
              0.7511870265007019,
              0.4708069860935211,
              0.7511199712753296,
              0.4718160033226013,
              0.7511439919471741,
              0.4708069860935211,
              0.7511630058288574,
              0.4718160033226013,
              0.7511439919471741,
              0.4708069860935211,
              0.7511870265007019,
              0.4708069860935211,
              0.7511630058288574,
              0.4718160033226013,
              0.7510759830474854,
              0.47363901138305664,
              0.7510970234870911,
              0.47276100516319275,
              0.7511190176010132,
              0.47363901138305664,
              0.7510970234870911,
              0.47276100516319275,
              0.7511399984359741,
              0.47276100516319275,
              0.7511190176010132,
              0.47363901138305664,
              0.7510390281677246,
              0.4751890003681183,
              0.7510570287704468,
              0.4744490087032318,
              0.7510820031166077,
              0.4751890003681183,
              0.7510570287704468,
              0.4744490087032318,
              0.7511000037193298,
              0.4744490087032318,
              0.7510820031166077,
              0.4751890003681183,
              0.75102299451828,
              0.4758560061454773,
              0.7510390281677246,
              0.4751890003681183,
              0.7510660290718079,
              0.4758560061454773,
              0.7510390281677246,
              0.4751890003681183,
              0.7510820031166077,
              0.4751890003681183,
              0.7510660290718079,
              0.4758560061454773,
              0.7509859800338745,
              0.477400004863739,
              0.750997006893158,
              0.47696399688720703,
              0.7510290145874023,
              0.477400004863739,
              0.750997006893158,
              0.47696399688720703,
              0.751039981842041,
              0.47696399688720703,
              0.7510290145874023,
              0.477400004863739,
              0.758741021156311,
              0.1877090036869049,
              0.7583370208740234,
              0.1877090036869049,
              0.7587509751319885,
              0.1877090036869049,
              0.7583370208740234,
              0.1877090036869049,
              0.7583439946174622,
              0.1877090036869049,
              0.7587509751319885,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7605289816856384,
              0.1877090036869049,
              0.7608609795570374,
              0.1877090036869049,
              0.7605289816856384,
              0.1877090036869049,
              0.7605559825897217,
              0.1877090036869049,
              0.7608609795570374,
              0.1877090036869049,
              0.7611140012741089,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7611449956893921,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7608609795570374,
              0.1877090036869049,
              0.7611449956893921,
              0.1877090036869049,
              0.7616069912910461,
              0.1877090036869049,
              0.7613720297813416,
              0.1877090036869049,
              0.7616419792175293,
              0.1877090036869049,
              0.7613720297813416,
              0.1877090036869049,
              0.7614060044288635,
              0.1877090036869049,
              0.7616419792175293,
              0.1877090036869049,
              0.7624329924583435,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7624750137329102,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7624130249023438,
              0.1877090036869049,
              0.7624750137329102,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624980211257935,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7579219937324524,
              0.1877090036869049,
              0.7574930191040039,
              0.1877090036869049,
              0.7579249739646912,
              0.1877090036869049,
              0.7574930191040039,
              0.1877090036869049,
              0.7574939727783203,
              0.1877090036869049,
              0.7579249739646912,
              0.1877090036869049,
              0.7598649859428406,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7598850131034851,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7595229744911194,
              0.1877090036869049,
              0.7598850131034851,
              0.1877090036869049,
              0.7605289816856384,
              0.1877090036869049,
              0.7602059841156006,
              0.1877090036869049,
              0.7605559825897217,
              0.1877090036869049,
              0.7602059841156006,
              0.1877090036869049,
              0.7602300047874451,
              0.1877090036869049,
              0.7605559825897217,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7616069912910461,
              0.1877090036869049,
              0.7618529796600342,
              0.1877090036869049,
              0.7616069912910461,
              0.1877090036869049,
              0.7616419792175293,
              0.1877090036869049,
              0.7618529796600342,
              0.1877090036869049,
              0.7622770071029663,
              0.1877090036869049,
              0.7621520161628723,
              0.1877090036869049,
              0.7623180150985718,
              0.1877090036869049,
              0.7621520161628723,
              0.1877090036869049,
              0.7621920108795166,
              0.1877090036869049,
              0.7623180150985718,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7622770071029663,
              0.1877090036869049,
              0.7624130249023438,
              0.1877090036869049,
              0.7622770071029663,
              0.1877090036869049,
              0.7623180150985718,
              0.1877090036869049,
              0.7624130249023438,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.758741021156311,
              0.18978999555110931,
              0.7591300010681152,
              0.18980899453163147,
              0.7587509751319885,
              0.1897830069065094,
              0.7591300010681152,
              0.18980899453163147,
              0.7591450214385986,
              0.18980200588703156,
              0.7587509751319885,
              0.1897830069065094,
              0.7591300010681152,
              0.18980899453163147,
              0.7595059871673584,
              0.18983200192451477,
              0.7591450214385986,
              0.18980200588703156,
              0.7595059871673584,
              0.18983200192451477,
              0.7595229744911194,
              0.18982599675655365,
              0.7591450214385986,
              0.18980200588703156,
              0.7605289816856384,
              0.1899300068616867,
              0.7608320116996765,
              0.189970001578331,
              0.7605559825897217,
              0.18992400169372559,
              0.7608320116996765,
              0.189970001578331,
              0.7608609795570374,
              0.18996499478816986,
              0.7605559825897217,
              0.18992400169372559,
              0.7611140012741089,
              0.19001400470733643,
              0.7613720297813416,
              0.1900620013475418,
              0.7611449956893921,
              0.19000999629497528,
              0.7613720297813416,
              0.1900620013475418,
              0.7614060044288635,
              0.19005699455738068,
              0.7611449956893921,
              0.19000999629497528,
              0.7616069912910461,
              0.19011199474334717,
              0.7618160247802734,
              0.19016599655151367,
              0.7616419792175293,
              0.1901089996099472,
              0.7618160247802734,
              0.19016599655151367,
              0.7618529796600342,
              0.19016200304031372,
              0.7616419792175293,
              0.1901089996099472,
              0.7618160247802734,
              0.19016599655151367,
              0.7619979977607727,
              0.19022199511528015,
              0.7618529796600342,
              0.19016200304031372,
              0.7619979977607727,
              0.19022199511528015,
              0.7620369791984558,
              0.1902189999818802,
              0.7618529796600342,
              0.19016200304031372,
              0.7619979977607727,
              0.19022199511528015,
              0.7621520161628723,
              0.1902800053358078,
              0.7620369791984558,
              0.1902189999818802,
              0.7621520161628723,
              0.1902800053358078,
              0.7621920108795166,
              0.19027799367904663,
              0.7620369791984558,
              0.1902189999818802,
              0.7622770071029663,
              0.19034099578857422,
              0.7623710036277771,
              0.19040299952030182,
              0.7623180150985718,
              0.19033899903297424,
              0.7623710036277771,
              0.19040299952030182,
              0.7624130249023438,
              0.19040200114250183,
              0.7623180150985718,
              0.19033899903297424,
              0.7624329924583435,
              0.19046799838542938,
              0.7624610066413879,
              0.19053299725055695,
              0.7624750137329102,
              0.1904670000076294,
              0.7624610066413879,
              0.19053299725055695,
              0.762503981590271,
              0.19053299725055695,
              0.7624750137329102,
              0.1904670000076294,
              0.7624120116233826,
              0.1906680017709732,
              0.7623519897460938,
              0.19073499739170074,
              0.7624549865722656,
              0.1906680017709732,
              0.7623519897460938,
              0.19073499739170074,
              0.7623940110206604,
              0.19073599576950073,
              0.7624549865722656,
              0.1906680017709732,
              0.7570610046386719,
              0.18976500630378723,
              0.7574930191040039,
              0.18976500630378723,
              0.7570610046386719,
              0.18975800275802612,
              0.7574930191040039,
              0.18976500630378723,
              0.7574939727783203,
              0.18975800275802612,
              0.7570610046386719,
              0.18975800275802612,
              0.7415549755096436,
              0.18976500630378723,
              0.7570610046386719,
              0.18976500630378723,
              0.7415549755096436,
              0.18975800275802612,
              0.7570610046386719,
              0.18976500630378723,
              0.7570610046386719,
              0.18975800275802612,
              0.7415549755096436,
              0.18975800275802612,
              0.7515130043029785,
              0.4553939998149872,
              0.7514870166778564,
              0.45649099349975586,
              0.7515559792518616,
              0.4553939998149872,
              0.7514870166778564,
              0.45649099349975586,
              0.7515299916267395,
              0.45649099349975586,
              0.7515559792518616,
              0.4553939998149872,
              0.7513669729232788,
              0.4614880084991455,
              0.7513459920883179,
              0.46237900853157043,
              0.7514100074768066,
              0.4614880084991455,
              0.7513459920883179,
              0.46237900853157043,
              0.7513890266418457,
              0.46237900853157043,
              0.7514100074768066,
              0.4614880084991455,
              0.7513459920883179,
              0.46237900853157043,
              0.7513260245323181,
              0.4632300138473511,
              0.7513890266418457,
              0.46237900853157043,
              0.7513260245323181,
              0.4632300138473511,
              0.7513689994812012,
              0.4632300138473511,
              0.7513890266418457,
              0.46237900853157043,
              0.7513260245323181,
              0.4632300138473511,
              0.7513059973716736,
              0.46404001116752625,
              0.7513689994812012,
              0.4632300138473511,
              0.7513059973716736,
              0.46404001116752625,
              0.7513489723205566,
              0.46404001116752625,
              0.7513689994812012,
              0.4632300138473511,
              0.7512009739875793,
              0.468423992395401,
              0.7511910200119019,
              0.46884799003601074,
              0.7512440085411072,
              0.468423992395401,
              0.7511910200119019,
              0.46884799003601074,
              0.7512339949607849,
              0.46884799003601074,
              0.7512440085411072,
              0.468423992395401,
              0.7511910200119019,
              0.46884799003601074,
              0.7511820197105408,
              0.469217985868454,
              0.7512339949607849,
              0.46884799003601074,
              0.7511820197105408,
              0.469217985868454,
              0.7512249946594238,
              0.469217985868454,
              0.7512339949607849,
              0.46884799003601074,
              0.751459002494812,
              0.4576680064201355,
              0.7514960169792175,
              0.45609501004219055,
              0.7515019774436951,
              0.4576680064201355,
              0.7514960169792175,
              0.45609501004219055,
              0.7515389919281006,
              0.45609501004219055,
              0.7515019774436951,
              0.4576680064201355,
              0.7513179779052734,
              0.4635300040245056,
              0.7513520121574402,
              0.46213299036026,
              0.7513610124588013,
              0.4635300040245056,
              0.7513520121574402,
              0.46213299036026,
              0.7513949871063232,
              0.46213299036026,
              0.7513610124588013,
              0.4635300040245056,
              0.7510089874267578,
              0.4764479994773865,
              0.75102299451828,
              0.4758560061454773,
              0.7510520219802856,
              0.4764479994773865,
              0.75102299451828,
              0.4758560061454773,
              0.7510660290718079,
              0.4758560061454773,
              0.7510520219802856,
              0.4764479994773865,
              0.750997006893158,
              0.47696399688720703,
              0.7510089874267578,
              0.4764479994773865,
              0.751039981842041,
              0.47696399688720703,
              0.7510089874267578,
              0.4764479994773865,
              0.7510520219802856,
              0.4764479994773865,
              0.751039981842041,
              0.47696399688720703,
              0.7509779930114746,
              0.47775599360466003,
              0.7509859800338745,
              0.477400004863739,
              0.7510210275650024,
              0.47775599360466003,
              0.7509859800338745,
              0.477400004863739,
              0.7510290145874023,
              0.477400004863739,
              0.7510210275650024,
              0.47775599360466003,
              0.7515400052070618,
              0.4542680084705353,
              0.7515130043029785,
              0.4553939998149872,
              0.7515829801559448,
              0.4542680084705353,
              0.7515130043029785,
              0.4553939998149872,
              0.7515559792518616,
              0.4553939998149872,
              0.7515829801559448,
              0.4542680084705353,
              0.7514870166778564,
              0.45649099349975586,
              0.7514610290527344,
              0.4575580060482025,
              0.7515299916267395,
              0.45649099349975586,
              0.7514610290527344,
              0.4575580060482025,
              0.7515040040016174,
              0.4575580060482025,
              0.7515299916267395,
              0.45649099349975586,
              0.7512710094451904,
              0.4655289947986603,
              0.7512540221214294,
              0.46620601415634155,
              0.7513129711151123,
              0.4655289947986603,
              0.7512540221214294,
              0.46620601415634155,
              0.7512969970703125,
              0.46620601415634155,
              0.7513129711151123,
              0.4655289947986603,
              0.751239001750946,
              0.46683499217033386,
              0.7512249946594238,
              0.467415988445282,
              0.7512819766998291,
              0.46683499217033386,
              0.7512249946594238,
              0.467415988445282,
              0.7512680292129517,
              0.467415988445282,
              0.7512819766998291,
              0.46683499217033386,
              0.7511690258979797,
              0.46978598833084106,
              0.7511630058288574,
              0.47001200914382935,
              0.7512120008468628,
              0.46978598833084106,
              0.7511630058288574,
              0.47001200914382935,
              0.7512059807777405,
              0.47001200914382935,
              0.7512120008468628,
              0.46978598833084106,
              0.751285970211029,
              0.46487900614738464,
              0.7513179779052734,
              0.4635300040245056,
              0.7513290047645569,
              0.46487900614738464,
              0.7513179779052734,
              0.4635300040245056,
              0.7513610124588013,
              0.4635300040245056,
              0.7513290047645569,
              0.46487900614738464,
              0.7512249946594238,
              0.46741899847984314,
              0.7512549757957458,
              0.46617498993873596,
              0.7512680292129517,
              0.46741899847984314,
              0.7512549757957458,
              0.46617498993873596,
              0.7512980103492737,
              0.46617498993873596,
              0.7512680292129517,
              0.46741899847984314,
              0.7511969804763794,
              0.46860700845718384,
              0.7512249946594238,
              0.46741899847984314,
              0.7512400150299072,
              0.46860700845718384,
              0.7512249946594238,
              0.46741899847984314,
              0.7512680292129517,
              0.46741899847984314,
              0.7512400150299072,
              0.46860700845718384,
              0.7510970234870911,
              0.47276100516319275,
              0.7511199712753296,
              0.4718160033226013,
              0.7511399984359741,
              0.47276100516319275,
              0.7511199712753296,
              0.4718160033226013,
              0.7511630058288574,
              0.4718160033226013,
              0.7511399984359741,
              0.47276100516319275,
              0.7510570287704468,
              0.4744490087032318,
              0.7510759830474854,
              0.47363901138305664,
              0.7511000037193298,
              0.4744490087032318,
              0.7510759830474854,
              0.47363901138305664,
              0.7511190176010132,
              0.47363901138305664,
              0.7511000037193298,
              0.4744490087032318,
              0.7591300010681152,
              0.1877090036869049,
              0.758741021156311,
              0.1877090036869049,
              0.7591450214385986,
              0.1877090036869049,
              0.758741021156311,
              0.1877090036869049,
              0.7587509751319885,
              0.1877090036869049,
              0.7591450214385986,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7591300010681152,
              0.1877090036869049,
              0.7595229744911194,
              0.1877090036869049,
              0.7591300010681152,
              0.1877090036869049,
              0.7591450214385986,
              0.1877090036869049,
              0.7595229744911194,
              0.1877090036869049,
              0.7602059841156006,
              0.1877090036869049,
              0.7598649859428406,
              0.1877090036869049,
              0.7602300047874451,
              0.1877090036869049,
              0.7598649859428406,
              0.1877090036869049,
              0.7598850131034851,
              0.1877090036869049,
              0.7602300047874451,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624610066413879,
              0.1877090036869049,
              0.7624980211257935,
              0.1877090036869049,
              0.7624610066413879,
              0.1877090036869049,
              0.762503981590271,
              0.1877090036869049,
              0.7624980211257935,
              0.1877090036869049,
              0.7583370208740234,
              0.1877090036869049,
              0.7579219937324524,
              0.1877090036869049,
              0.7583439946174622,
              0.1877090036869049,
              0.7579219937324524,
              0.1877090036869049,
              0.7579249739646912,
              0.1877090036869049,
              0.7583439946174622,
              0.1877090036869049,
              0.7613720297813416,
              0.1877090036869049,
              0.7611140012741089,
              0.1877090036869049,
              0.7614060044288635,
              0.1877090036869049,
              0.7611140012741089,
              0.1877090036869049,
              0.7611449956893921,
              0.1877090036869049,
              0.7614060044288635,
              0.1877090036869049,
              0.7619979977607727,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7620369791984558,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7618529796600342,
              0.1877090036869049,
              0.7620369791984558,
              0.1877090036869049,
              0.7621520161628723,
              0.1877090036869049,
              0.7619979977607727,
              0.1877090036869049,
              0.7621920108795166,
              0.1877090036869049,
              0.7619979977607727,
              0.1877090036869049,
              0.7620369791984558,
              0.1877090036869049,
              0.7621920108795166,
              0.1877090036869049,
              0.7624610066413879,
              0.1877090036869049,
              0.7624329924583435,
              0.1877090036869049,
              0.762503981590271,
              0.1877090036869049,
              0.7624329924583435,
              0.1877090036869049,
              0.7624750137329102,
              0.1877090036869049,
              0.762503981590271,
              0.1877090036869049,
              0.7623519897460938,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7623940110206604,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7623940110206604,
              0.1877090036869049,
              0.7574930191040039,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7574939727783203,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7574939727783203,
              0.1877090036869049,
              0.7574930191040039,
              0.18976500630378723,
              0.7579219937324524,
              0.1897680014371872,
              0.7574939727783203,
              0.18975800275802612,
              0.7579219937324524,
              0.1897680014371872,
              0.7579249739646912,
              0.1897609978914261,
              0.7574939727783203,
              0.18975800275802612,
              0.7579219937324524,
              0.1897680014371872,
              0.7583370208740234,
              0.1897760033607483,
              0.7579249739646912,
              0.1897609978914261,
              0.7583370208740234,
              0.1897760033607483,
              0.7583439946174622,
              0.18976899981498718,
              0.7579249739646912,
              0.1897609978914261,
              0.7583370208740234,
              0.1897760033607483,
              0.758741021156311,
              0.18978999555110931,
              0.7583439946174622,
              0.18976899981498718,
              0.758741021156311,
              0.18978999555110931,
              0.7587509751319885,
              0.1897830069065094,
              0.7583439946174622,
              0.18976899981498718,
              0.7595059871673584,
              0.18983200192451477,
              0.7598649859428406,
              0.189860001206398,
              0.7595229744911194,
              0.18982599675655365,
              0.7598649859428406,
              0.189860001206398,
              0.7598850131034851,
              0.1898539960384369,
              0.7595229744911194,
              0.18982599675655365,
              0.7598649859428406,
              0.189860001206398,
              0.7602059841156006,
              0.18989300727844238,
              0.7598850131034851,
              0.1898539960384369,
              0.7602059841156006,
              0.18989300727844238,
              0.7602300047874451,
              0.18988700211048126,
              0.7598850131034851,
              0.1898539960384369,
              0.7602059841156006,
              0.18989300727844238,
              0.7605289816856384,
              0.1899300068616867,
              0.7602300047874451,
              0.18988700211048126,
              0.7605289816856384,
              0.1899300068616867,
              0.7605559825897217,
              0.18992400169372559,
              0.7602300047874451,
              0.18988700211048126,
              0.7608320116996765,
              0.189970001578331,
              0.7611140012741089,
              0.19001400470733643,
              0.7608609795570374,
              0.18996499478816986,
              0.7611140012741089,
              0.19001400470733643,
              0.7611449956893921,
              0.19000999629497528,
              0.7608609795570374,
              0.18996499478816986,
              0.7613720297813416,
              0.1900620013475418,
              0.7616069912910461,
              0.19011199474334717,
              0.7614060044288635,
              0.19005699455738068,
              0.7616069912910461,
              0.19011199474334717,
              0.7616419792175293,
              0.1901089996099472,
              0.7614060044288635,
              0.19005699455738068,
              0.7621520161628723,
              0.1902800053358078,
              0.7622770071029663,
              0.19034099578857422,
              0.7621920108795166,
              0.19027799367904663,
              0.7622770071029663,
              0.19034099578857422,
              0.7623180150985718,
              0.19033899903297424,
              0.7621920108795166,
              0.19027799367904663,
              0.7623710036277771,
              0.19040299952030182,
              0.7624329924583435,
              0.19046799838542938,
              0.7624130249023438,
              0.19040200114250183,
              0.7624329924583435,
              0.19046799838542938,
              0.7624750137329102,
              0.1904670000076294,
              0.7624130249023438,
              0.19040200114250183,
              0.7624610066413879,
              0.19053299725055695,
              0.7624549865722656,
              0.19059999287128448,
              0.762503981590271,
              0.19053299725055695,
              0.7624549865722656,
              0.19059999287128448,
              0.7624980211257935,
              0.19059999287128448,
              0.762503981590271,
              0.19053299725055695,
              0.7624549865722656,
              0.19059999287128448,
              0.7624120116233826,
              0.1906680017709732,
              0.7624980211257935,
              0.19059999287128448,
              0.7624120116233826,
              0.1906680017709732,
              0.7624549865722656,
              0.1906680017709732,
              0.7624980211257935,
              0.19059999287128448,
              0.7515230178833008,
              0.4588020145893097,
              0.7515490055084229,
              0.45774298906326294,
              0.7515259981155396,
              0.45870399475097656,
              0.7515490055084229,
              0.45774298906326294,
              0.7515509724617004,
              0.45765599608421326,
              0.7515259981155396,
              0.45870399475097656,
              0.7514989972114563,
              0.4598270058631897,
              0.7515230178833008,
              0.4588020145893097,
              0.7515010237693787,
              0.4597170054912567,
              0.7515230178833008,
              0.4588020145893097,
              0.7515259981155396,
              0.45870399475097656,
              0.7515010237693787,
              0.4597170054912567,
              0.7514520287513733,
              0.46176600456237793,
              0.7514749765396118,
              0.4608150124549866,
              0.7514550089836121,
              0.4616360068321228,
              0.7514749765396118,
              0.4608150124549866,
              0.7514780163764954,
              0.4606949985027313,
              0.7514550089836121,
              0.4616360068321228,
              0.7513530254364014,
              0.4659009873867035,
              0.7513710260391235,
              0.4651620090007782,
              0.7513570189476013,
              0.4657270014286041,
              0.7513710260391235,
              0.4651620090007782,
              0.7513750195503235,
              0.46499499678611755,
              0.7513570189476013,
              0.4657270014286041,
              0.751321017742157,
              0.4672349989414215,
              0.7513369917869568,
              0.466592013835907,
              0.7513260245323181,
              0.4670479893684387,
              0.7513369917869568,
              0.466592013835907,
              0.7513409852981567,
              0.4664109945297241,
              0.7513260245323181,
              0.4670479893684387,
              0.7515180110931396,
              0.45903998613357544,
              0.7514830231666565,
              0.4605039954185486,
              0.7515159845352173,
              0.45911499857902527,
              0.7514830231666565,
              0.4605039954185486,
              0.751479983329773,
              0.46059098839759827,
              0.7515159845352173,
              0.45911499857902527,
              0.7513840198516846,
              0.46462199091911316,
              0.7513530254364014,
              0.46589699387550354,
              0.751380980014801,
              0.4647420048713684,
              0.7513530254364014,
              0.46589699387550354,
              0.7513499855995178,
              0.46602699160575867,
              0.751380980014801,
              0.4647420048713684,
              0.7512959837913513,
              0.46828800439834595,
              0.7512689828872681,
              0.46939900517463684,
              0.7512919902801514,
              0.46843698620796204,
              0.7512689828872681,
              0.46939900517463684,
              0.7512660026550293,
              0.469556987285614,
              0.7512919902801514,
              0.46843698620796204,
              0.751075029373169,
              0.47751399874687195,
              0.7510799765586853,
              0.4773010015487671,
              0.7510679960250854,
              0.47782400250434875,
              0.7510799765586853,
              0.4773010015487671,
              0.7510730028152466,
              0.4776090085506439,
              0.7510679960250854,
              0.47782400250434875,
              0.7514749765396118,
              0.4608150124549866,
              0.7514989972114563,
              0.4598270058631897,
              0.7514780163764954,
              0.4606949985027313,
              0.7514989972114563,
              0.4598270058631897,
              0.7515010237693787,
              0.4597170054912567,
              0.7514780163764954,
              0.4606949985027313,
              0.7513710260391235,
              0.4651620090007782,
              0.7513899803161621,
              0.4643769860267639,
              0.7513750195503235,
              0.46499499678611755,
              0.7513899803161621,
              0.4643769860267639,
              0.7513939738273621,
              0.46421900391578674,
              0.7513750195503235,
              0.46499499678611755,
              0.7512940168380737,
              0.4683690071105957,
              0.7513070106506348,
              0.46782800555229187,
              0.7512990236282349,
              0.46817100048065186,
              0.7513070106506348,
              0.46782800555229187,
              0.7513120174407959,
              0.46763500571250916,
              0.7512990236282349,
              0.46817100048065186,
              0.7512819766998291,
              0.46885600686073303,
              0.7512940168380737,
              0.4683690071105957,
              0.7512869834899902,
              0.46865400671958923,
              0.7512940168380737,
              0.4683690071105957,
              0.7512990236282349,
              0.46817100048065186,
              0.7512869834899902,
              0.46865400671958923,
              0.751255989074707,
              0.4699830114841461,
              0.7512630224227905,
              0.469664990901947,
              0.7512609958648682,
              0.46977099776268005,
              0.7512630224227905,
              0.469664990901947,
              0.7512680292129517,
              0.4694559872150421,
              0.7512609958648682,
              0.46977099776268005,
              0.7512490153312683,
              0.4702419936656952,
              0.751255989074707,
              0.4699830114841461,
              0.7512540221214294,
              0.47002899646759033,
              0.751255989074707,
              0.4699830114841461,
              0.7512609958648682,
              0.46977099776268005,
              0.7512540221214294,
              0.47002899646759033,
              0.751554012298584,
              0.45753398537635803,
              0.7515180110931396,
              0.45903998613357544,
              0.7515519857406616,
              0.4575969874858856,
              0.7515180110931396,
              0.45903998613357544,
              0.7515159845352173,
              0.45911499857902527,
              0.7515519857406616,
              0.4575969874858856,
              0.7514830231666565,
              0.4605039954185486,
              0.7514489889144897,
              0.4619239866733551,
              0.751479983329773,
              0.46059098839759827,
              0.7514489889144897,
              0.4619239866733551,
              0.751446008682251,
              0.46202200651168823,
              0.751479983329773,
              0.46059098839759827,
              0.7512689828872681,
              0.46939900517463684,
              0.7512440085411072,
              0.4704520106315613,
              0.7512660026550293,
              0.469556987285614,
              0.7512440085411072,
              0.4704520106315613,
              0.7512400150299072,
              0.47061899304389954,
              0.7512660026550293,
              0.469556987285614,
              0.7512440085411072,
              0.4704520106315613,
              0.7512199878692627,
              0.4714449942111969,
              0.7512400150299072,
              0.47061899304389954,
              0.7512199878692627,
              0.4714449942111969,
              0.7512159943580627,
              0.47161799669265747,
              0.7512400150299072,
              0.47061899304389954,
              0.7511979937553406,
              0.47237399220466614,
              0.7511780261993408,
              0.473239004611969,
              0.7511940002441406,
              0.472555011510849,
              0.7511780261993408,
              0.473239004611969,
              0.7511730194091797,
              0.4734260141849518,
              0.7511940002441406,
              0.472555011510849,
              0.7511579990386963,
              0.4740369915962219,
              0.7511410117149353,
              0.47476598620414734,
              0.7511540055274963,
              0.47422999143600464,
              0.7511410117149353,
              0.47476598620414734,
              0.7511360049247742,
              0.4749639928340912,
              0.7511540055274963,
              0.47422999143600464,
              0.7511410117149353,
              0.47476598620414734,
              0.7511249780654907,
              0.47542300820350647,
              0.7511360049247742,
              0.4749639928340912,
              0.7511249780654907,
              0.47542300820350647,
              0.7511199712753296,
              0.47562599182128906,
              0.7511360049247742,
              0.4749639928340912,
              0.7510989904403687,
              0.476516991853714,
              0.7510889768600464,
              0.476949006319046,
              0.7510939836502075,
              0.4767259955406189,
              0.7510889768600464,
              0.476949006319046,
              0.75108402967453,
              0.4771600067615509,
              0.7510939836502075,
              0.4767259955406189,
              0.7583529949188232,
              0.18816600739955902,
              0.7587640285491943,
              0.18816600739955902,
              0.7583529949188232,
              0.18795199692249298,
              0.7587640285491943,
              0.18816600739955902,
              0.7587640285491943,
              0.18795199692249298,
              0.7583529949188232,
              0.18795199692249298,
              0.7605850100517273,
              0.18816600739955902,
              0.7608940005302429,
              0.18816600739955902,
              0.7605850100517273,
              0.18795199692249298,
              0.7608940005302429,
              0.18816600739955902,
              0.7608940005302429,
              0.18795199692249298,
              0.7605850100517273,
              0.18795199692249298,
              0.7608940005302429,
              0.18816600739955902,
              0.7611799836158752,
              0.18816600739955902,
              0.7608940005302429,
              0.18795199692249298,
              0.7611799836158752,
              0.18816600739955902,
              0.7611799836158752,
              0.18795199692249298,
              0.7608940005302429,
              0.18795199692249298,
              0.7614439725875854,
              0.18816600739955902,
              0.7616829872131348,
              0.18816600739955902,
              0.7614439725875854,
              0.18795199692249298,
              0.7616829872131348,
              0.18816600739955902,
              0.7616829872131348,
              0.18795199692249298,
              0.7614439725875854,
              0.18795199692249298,
              0.7624610066413879,
              0.18816600739955902,
              0.7625240087509155,
              0.18816600739955902,
              0.7624610066413879,
              0.18795199692249298,
              0.7625240087509155,
              0.18816600739955902,
              0.7625240087509155,
              0.18795199692249298,
              0.7624610066413879,
              0.18795199692249298,
              0.7625460028648376,
              0.18816600739955902,
              0.7625030279159546,
              0.18816600739955902,
              0.7625460028648376,
              0.18795199692249298,
              0.7625030279159546,
              0.18816600739955902,
              0.7625030279159546,
              0.18795199692249298,
              0.7625460028648376,
              0.18795199692249298,
              0.7574949860572815,
              0.18816600739955902,
              0.7579290270805359,
              0.18816600739955902,
              0.7574949860572815,
              0.18795199692249298,
              0.7579290270805359,
              0.18816600739955902,
              0.7579290270805359,
              0.18795199692249298,
              0.7574949860572815,
              0.18795199692249298,
              0.7595430016517639,
              0.18816600739955902,
              0.7599089741706848,
              0.18816600739955902,
              0.7595430016517639,
              0.18795199692249298,
              0.7599089741706848,
              0.18816600739955902,
              0.7599089741706848,
              0.18795199692249298,
              0.7595430016517639,
              0.18795199692249298,
              0.7602570056915283,
              0.18816600739955902,
              0.7605850100517273,
              0.18816600739955902,
              0.7602570056915283,
              0.18795199692249298,
              0.7605850100517273,
              0.18816600739955902,
              0.7605850100517273,
              0.18795199692249298,
              0.7602570056915283,
              0.18795199692249298,
              0.7616829872131348,
              0.18816600739955902,
              0.7618950009346008,
              0.18816600739955902,
              0.7616829872131348,
              0.18795199692249298,
              0.7618950009346008,
              0.18816600739955902,
              0.7618950009346008,
              0.18795199692249298,
              0.7616829872131348,
              0.18795199692249298,
              0.7622380256652832,
              0.18816600739955902,
              0.7623649835586548,
              0.18816600739955902,
              0.7622380256652832,
              0.18795199692249298,
              0.7623649835586548,
              0.18816600739955902,
              0.7623649835586548,
              0.18795199692249298,
              0.7622380256652832,
              0.18795199692249298,
              0.7623649835586548,
              0.18816600739955902,
              0.7624610066413879,
              0.18816600739955902,
              0.7623649835586548,
              0.18795199692249298,
              0.7624610066413879,
              0.18816600739955902,
              0.7624610066413879,
              0.18795199692249298,
              0.7623649835586548,
              0.18795199692249298,
              0.7415549755096436,
              0.18816600739955902,
              0.7570610046386719,
              0.18816600739955902,
              0.7415549755096436,
              0.18795199692249298,
              0.7570610046386719,
              0.18816600739955902,
              0.7570610046386719,
              0.18795199692249298,
              0.7415549755096436,
              0.18795199692249298,
              0.7591609954833984,
              0.18933799862861633,
              0.7587640285491943,
              0.18931899964809418,
              0.7591609954833984,
              0.18955199420452118,
              0.7587640285491943,
              0.18931899964809418,
              0.7587640285491943,
              0.18953299522399902,
              0.7591609954833984,
              0.18955199420452118,
              0.7595430016517639,
              0.18936200439929962,
              0.7591609954833984,
              0.18933799862861633,
              0.7595430016517639,
              0.18957599997520447,
              0.7591609954833984,
              0.18933799862861633,
              0.7591609954833984,
              0.18955199420452118,
              0.7595430016517639,
              0.18957599997520447,
              0.7608940005302429,
              0.18950200080871582,
              0.7605850100517273,
              0.18946099281311035,
              0.7608940005302429,
              0.18971599638462067,
              0.7605850100517273,
              0.18946099281311035,
              0.7605850100517273,
              0.1896750032901764,
              0.7608940005302429,
              0.18971599638462067,
              0.7614439725875854,
              0.1895959973335266,
              0.7611799836158752,
              0.18954700231552124,
              0.7614439725875854,
              0.18980999290943146,
              0.7611799836158752,
              0.18954700231552124,
              0.7611799836158752,
              0.1897609978914261,
              0.7614439725875854,
              0.18980999290943146,
              0.7618950009346008,
              0.18970200419425964,
              0.7616829872131348,
              0.18964700400829315,
              0.7618950009346008,
              0.1899159997701645,
              0.7616829872131348,
              0.18964700400829315,
              0.7616829872131348,
              0.189860999584198,
              0.7618950009346008,
              0.1899159997701645,
              0.7620810270309448,
              0.1897590011358261,
              0.7618950009346008,
              0.18970200419425964,
              0.7620810270309448,
              0.18997299671173096,
              0.7618950009346008,
              0.18970200419425964,
              0.7618950009346008,
              0.1899159997701645,
              0.7620810270309448,
              0.18997299671173096,
              0.7622380256652832,
              0.18981799483299255,
              0.7620810270309448,
              0.1897590011358261,
              0.7622380256652832,
              0.1900320053100586,
              0.7620810270309448,
              0.1897590011358261,
              0.7620810270309448,
              0.18997299671173096,
              0.7622380256652832,
              0.1900320053100586,
              0.7624610066413879,
              0.18994399905204773,
              0.7623649835586548,
              0.18987999856472015,
              0.7624610066413879,
              0.1901569962501526,
              0.7623649835586548,
              0.18987999856472015,
              0.7623649835586548,
              0.190093994140625,
              0.7624610066413879,
              0.1901569962501526,
              0.7625529766082764,
              0.19007599353790283,
              0.7625240087509155,
              0.1900089979171753,
              0.7625529766082764,
              0.19029000401496887,
              0.7625240087509155,
              0.1900089979171753,
              0.7625240087509155,
              0.19022299349308014,
              0.7625529766082764,
              0.19029000401496887,
              0.7624419927597046,
              0.1902800053358078,
              0.7625030279159546,
              0.19021199643611908,
              0.7624419927597046,
              0.19049400091171265,
              0.7625030279159546,
              0.19021199643611908,
              0.7625030279159546,
              0.19042600691318512,
              0.7624419927597046,
              0.19049400091171265,
              0.7574949860572815,
              0.1892929971218109,
              0.7570610046386719,
              0.1892929971218109,
              0.7574949860572815,
              0.18950699269771576,
              0.7570610046386719,
              0.1892929971218109,
              0.7570610046386719,
              0.18950699269771576,
              0.7574949860572815,
              0.18950699269771576,
              0.7570610046386719,
              0.1892929971218109,
              0.7415549755096436,
              0.1892929971218109,
              0.7570610046386719,
              0.18950699269771576,
              0.7415549755096436,
              0.1892929971218109,
              0.7415549755096436,
              0.18950699269771576,
              0.7570610046386719,
              0.18950699269771576,
              0.758741021156311,
              0.1877090036869049,
              0.7591300010681152,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7591300010681152,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7598649859428406,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7598649859428406,
              0.1877090036869049,
              0.7602059841156006,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7602059841156006,
              0.1877090036869049,
              0.7605289816856384,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7611140012741089,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7611140012741089,
              0.1877090036869049,
              0.7613720297813416,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7613720297813416,
              0.1877090036869049,
              0.7616069912910461,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7619979977607727,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7619979977607727,
              0.1877090036869049,
              0.7621520161628723,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7621520161628723,
              0.1877090036869049,
              0.7622770071029663,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7624329924583435,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7624329924583435,
              0.1877090036869049,
              0.7624610066413879,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7624610066413879,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7623519897460938,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7623519897460938,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7574930191040039,
              0.1877090036869049,
              0.758741021156311,
              0.1877090036869049,
              0.7574930191040039,
              0.1877090036869049,
              0.7579219937324524,
              0.1877090036869049,
              0.758741021156311,
              0.1877090036869049,
              0.7583370208740234,
              0.1877090036869049,
              0.758741021156311,
              0.1877090036869049,
              0.7579219937324524,
              0.1877090036869049,
              0.758741021156311,
              0.18978999555110931,
              0.7583370208740234,
              0.1897760033607483,
              0.7602059841156006,
              0.18989300727844238,
              0.7583370208740234,
              0.1897760033607483,
              0.7579219937324524,
              0.1897680014371872,
              0.7602059841156006,
              0.18989300727844238,
              0.7579219937324524,
              0.1897680014371872,
              0.7574930191040039,
              0.18976500630378723,
              0.7415549755096436,
              0.19073499739170074,
              0.7574930191040039,
              0.18976500630378723,
              0.7570610046386719,
              0.18976500630378723,
              0.7415549755096436,
              0.19073499739170074,
              0.7570610046386719,
              0.18976500630378723,
              0.7415549755096436,
              0.18976500630378723,
              0.7415549755096436,
              0.19073499739170074,
              0.7415549755096436,
              0.19073499739170074,
              0.7623519897460938,
              0.19073499739170074,
              0.7624610066413879,
              0.19053299725055695,
              0.7623519897460938,
              0.19073499739170074,
              0.7624120116233826,
              0.1906680017709732,
              0.7624610066413879,
              0.19053299725055695,
              0.7624120116233826,
              0.1906680017709732,
              0.7624549865722656,
              0.19059999287128448,
              0.7624610066413879,
              0.19053299725055695,
              0.7624610066413879,
              0.19053299725055695,
              0.7624329924583435,
              0.19046799838542938,
              0.7621520161628723,
              0.1902800053358078,
              0.7624329924583435,
              0.19046799838542938,
              0.7623710036277771,
              0.19040299952030182,
              0.7621520161628723,
              0.1902800053358078,
              0.7623710036277771,
              0.19040299952030182,
              0.7622770071029663,
              0.19034099578857422,
              0.7621520161628723,
              0.1902800053358078,
              0.7415549755096436,
              0.19073499739170074,
              0.7624610066413879,
              0.19053299725055695,
              0.7579219937324524,
              0.1897680014371872,
              0.7624610066413879,
              0.19053299725055695,
              0.7621520161628723,
              0.1902800053358078,
              0.7579219937324524,
              0.1897680014371872,
              0.7621520161628723,
              0.1902800053358078,
              0.7619979977607727,
              0.19022199511528015,
              0.7613720297813416,
              0.1900620013475418,
              0.7619979977607727,
              0.19022199511528015,
              0.7618160247802734,
              0.19016599655151367,
              0.7613720297813416,
              0.1900620013475418,
              0.7618160247802734,
              0.19016599655151367,
              0.7616069912910461,
              0.19011199474334717,
              0.7613720297813416,
              0.1900620013475418,
              0.7613720297813416,
              0.1900620013475418,
              0.7611140012741089,
              0.19001400470733643,
              0.7602059841156006,
              0.18989300727844238,
              0.7611140012741089,
              0.19001400470733643,
              0.7608320116996765,
              0.189970001578331,
              0.7602059841156006,
              0.18989300727844238,
              0.7608320116996765,
              0.189970001578331,
              0.7605289816856384,
              0.1899300068616867,
              0.7602059841156006,
              0.18989300727844238,
              0.7621520161628723,
              0.1902800053358078,
              0.7613720297813416,
              0.1900620013475418,
              0.7579219937324524,
              0.1897680014371872,
              0.7613720297813416,
              0.1900620013475418,
              0.7602059841156006,
              0.18989300727844238,
              0.7579219937324524,
              0.1897680014371872,
              0.7602059841156006,
              0.18989300727844238,
              0.7598649859428406,
              0.189860001206398,
              0.758741021156311,
              0.18978999555110931,
              0.7598649859428406,
              0.189860001206398,
              0.7595059871673584,
              0.18983200192451477,
              0.758741021156311,
              0.18978999555110931,
              0.7591300010681152,
              0.18980899453163147,
              0.758741021156311,
              0.18978999555110931,
              0.7595059871673584,
              0.18983200192451477,
              0.7511690258979797,
              0.46978598833084106,
              0.7415549755096436,
              0.46978598833084106,
              0.7511630058288574,
              0.47001200914382935,
              0.7415549755096436,
              0.46978598833084106,
              0.7415549755096436,
              0.47001200914382935,
              0.7511630058288574,
              0.47001200914382935,
              0.7514359951019287,
              0.4585919976234436,
              0.7514610290527344,
              0.4575580060482025,
              0.7415549755096436,
              0.4585919976234436,
              0.7514610290527344,
              0.4575580060482025,
              0.7415549755096436,
              0.4575580060482025,
              0.7415549755096436,
              0.4585919976234436,
              0.7514129877090454,
              0.4595929980278015,
              0.7514359951019287,
              0.4585919976234436,
              0.7415549755096436,
              0.4595929980278015,
              0.7514359951019287,
              0.4585919976234436,
              0.7415549755096436,
              0.4585919976234436,
              0.7415549755096436,
              0.4595929980278015,
              0.7513669729232788,
              0.4614880084991455,
              0.7513899803161621,
              0.46055901050567627,
              0.7415549755096436,
              0.4614880084991455,
              0.7513899803161621,
              0.46055901050567627,
              0.7415549755096436,
              0.46055901050567627,
              0.7415549755096436,
              0.4614880084991455,
              0.7512710094451904,
              0.4655289947986603,
              0.7512879967689514,
              0.4648059904575348,
              0.7415549755096436,
              0.4655289947986603,
              0.7512879967689514,
              0.4648059904575348,
              0.7415549755096436,
              0.4648059904575348,
              0.7415549755096436,
              0.4655289947986603,
              0.751239001750946,
              0.46683499217033386,
              0.7512540221214294,
              0.46620601415634155,
              0.7415549755096436,
              0.46683499217033386,
              0.7512540221214294,
              0.46620601415634155,
              0.7415549755096436,
              0.46620601415634155,
              0.7415549755096436,
              0.46683499217033386,
              0.7513899803161621,
              0.46055901050567627,
              0.7514129877090454,
              0.4595929980278015,
              0.7415549755096436,
              0.46055901050567627,
              0.7514129877090454,
              0.4595929980278015,
              0.7415549755096436,
              0.4595929980278015,
              0.7415549755096436,
              0.46055901050567627,
              0.7512879967689514,
              0.4648059904575348,
              0.7513059973716736,
              0.46404001116752625,
              0.7415549755096436,
              0.4648059904575348,
              0.7513059973716736,
              0.46404001116752625,
              0.7415549755096436,
              0.46404001116752625,
              0.7415549755096436,
              0.4648059904575348,
              0.751213014125824,
              0.46794599294662476,
              0.7512249946594238,
              0.467415988445282,
              0.7415549755096436,
              0.46794599294662476,
              0.7512249946594238,
              0.467415988445282,
              0.7415549755096436,
              0.467415988445282,
              0.7415549755096436,
              0.46794599294662476,
              0.7512009739875793,
              0.468423992395401,
              0.751213014125824,
              0.46794599294662476,
              0.7415549755096436,
              0.468423992395401,
              0.751213014125824,
              0.46794599294662476,
              0.7415549755096436,
              0.46794599294662476,
              0.7415549755096436,
              0.468423992395401,
              0.7511749863624573,
              0.46953099966049194,
              0.7511820197105408,
              0.469217985868454,
              0.7415549755096436,
              0.46953099966049194,
              0.7511820197105408,
              0.469217985868454,
              0.7415549755096436,
              0.469217985868454,
              0.7415549755096436,
              0.46953099966049194,
              0.7511690258979797,
              0.46978598833084106,
              0.7511749863624573,
              0.46953099966049194,
              0.7415549755096436,
              0.46978598833084106,
              0.7511749863624573,
              0.46953099966049194,
              0.7415549755096436,
              0.46953099966049194,
              0.7415549755096436,
              0.46978598833084106,
              0.7514870166778564,
              0.45649099349975586,
              0.7515130043029785,
              0.4553939998149872,
              0.7415549755096436,
              0.45649099349975586,
              0.7515130043029785,
              0.4553939998149872,
              0.7415549755096436,
              0.4553939998149872,
              0.7415549755096436,
              0.45649099349975586,
              0.7513459920883179,
              0.46237900853157043,
              0.7513669729232788,
              0.4614880084991455,
              0.7415549755096436,
              0.46237900853157043,
              0.7513669729232788,
              0.4614880084991455,
              0.7415549755096436,
              0.4614880084991455,
              0.7415549755096436,
              0.46237900853157043,
              0.7513260245323181,
              0.4632300138473511,
              0.7513459920883179,
              0.46237900853157043,
              0.7415549755096436,
              0.4632300138473511,
              0.7513459920883179,
              0.46237900853157043,
              0.7415549755096436,
              0.46237900853157043,
              0.7415549755096436,
              0.4632300138473511,
              0.7513059973716736,
              0.46404001116752625,
              0.7513260245323181,
              0.4632300138473511,
              0.7415549755096436,
              0.46404001116752625,
              0.7513260245323181,
              0.4632300138473511,
              0.7415549755096436,
              0.4632300138473511,
              0.7415549755096436,
              0.46404001116752625,
              0.7511910200119019,
              0.46884799003601074,
              0.7512009739875793,
              0.468423992395401,
              0.7415549755096436,
              0.46884799003601074,
              0.7512009739875793,
              0.468423992395401,
              0.7415549755096436,
              0.468423992395401,
              0.7415549755096436,
              0.46884799003601074,
              0.7511820197105408,
              0.469217985868454,
              0.7511910200119019,
              0.46884799003601074,
              0.7415549755096436,
              0.469217985868454,
              0.7511910200119019,
              0.46884799003601074,
              0.7415549755096436,
              0.46884799003601074,
              0.7415549755096436,
              0.469217985868454,
              0.7515130043029785,
              0.4553939998149872,
              0.7515400052070618,
              0.4542680084705353,
              0.7415549755096436,
              0.4553939998149872,
              0.7515400052070618,
              0.4542680084705353,
              0.7415549755096436,
              0.4542680084705353,
              0.7415549755096436,
              0.4553939998149872,
              0.7514610290527344,
              0.4575580060482025,
              0.7514870166778564,
              0.45649099349975586,
              0.7415549755096436,
              0.4575580060482025,
              0.7514870166778564,
              0.45649099349975586,
              0.7415549755096436,
              0.45649099349975586,
              0.7415549755096436,
              0.4575580060482025,
              0.7512540221214294,
              0.46620601415634155,
              0.7512710094451904,
              0.4655289947986603,
              0.7415549755096436,
              0.46620601415634155,
              0.7512710094451904,
              0.4655289947986603,
              0.7415549755096436,
              0.4655289947986603,
              0.7415549755096436,
              0.46620601415634155,
              0.7512249946594238,
              0.467415988445282,
              0.751239001750946,
              0.46683499217033386,
              0.7415549755096436,
              0.467415988445282,
              0.751239001750946,
              0.46683499217033386,
              0.7415549755096436,
              0.46683499217033386,
              0.7415549755096436,
              0.467415988445282,
              0.7514219880104065,
              0.459199994802475,
              0.7513859868049622,
              0.4606890082359314,
              0.7415549755096436,
              0.459199994802475,
              0.7513859868049622,
              0.4606890082359314,
              0.7415549755096436,
              0.4606890082359314,
              0.7415549755096436,
              0.459199994802475,
              0.751285970211029,
              0.46487900614738464,
              0.7512549757957458,
              0.46617498993873596,
              0.7415549755096436,
              0.46487799286842346,
              0.7512549757957458,
              0.46617498993873596,
              0.7415549755096436,
              0.46617498993873596,
              0.7415549755096436,
              0.46487799286842346,
              0.7511969804763794,
              0.46860700845718384,
              0.7511699795722961,
              0.4697369933128357,
              0.7415549755096436,
              0.46860700845718384,
              0.7511699795722961,
              0.4697369933128357,
              0.7415549755096436,
              0.4697369933128357,
              0.7415549755096436,
              0.46860700845718384,
              0.7509260177612305,
              0.4799160063266754,
              0.7415549755096436,
              0.4799160063266754,
              0.7509700059890747,
              0.478069007396698,
              0.7415549755096436,
              0.4799160063266754,
              0.7415549755096436,
              0.478069007396698,
              0.7509700059890747,
              0.478069007396698,
              0.751459002494812,
              0.4576680064201355,
              0.7514219880104065,
              0.459199994802475,
              0.7415549755096436,
              0.4576680064201355,
              0.7514219880104065,
              0.459199994802475,
              0.7415549755096436,
              0.459199994802475,
              0.7415549755096436,
              0.4576680064201355,
              0.7513859868049622,
              0.4606890082359314,
              0.7513520121574402,
              0.46213299036026,
              0.7415549755096436,
              0.4606890082359314,
              0.7513520121574402,
              0.46213299036026,
              0.7415549755096436,
              0.46213299036026,
              0.7415549755096436,
              0.4606890082359314,
              0.7511699795722961,
              0.4697369933128357,
              0.7511439919471741,
              0.4708069860935211,
              0.7415549755096436,
              0.4697369933128357,
              0.7511439919471741,
              0.4708069860935211,
              0.7415549755096436,
              0.4708069860935211,
              0.7415549755096436,
              0.4697369933128357,
              0.7511439919471741,
              0.4708069860935211,
              0.7511199712753296,
              0.4718160033226013,
              0.7415549755096436,
              0.4708069860935211,
              0.7511199712753296,
              0.4718160033226013,
              0.7415549755096436,
              0.4718160033226013,
              0.7415549755096436,
              0.4708069860935211,
              0.7510970234870911,
              0.47276100516319275,
              0.7510759830474854,
              0.47363901138305664,
              0.7415549755096436,
              0.47275999188423157,
              0.7510759830474854,
              0.47363901138305664,
              0.7415549755096436,
              0.47363901138305664,
              0.7415549755096436,
              0.47275999188423157,
              0.7510570287704468,
              0.4744490087032318,
              0.7510390281677246,
              0.4751890003681183,
              0.7415549755096436,
              0.4744490087032318,
              0.7510390281677246,
              0.4751890003681183,
              0.7415549755096436,
              0.4751890003681183,
              0.7415549755096436,
              0.4744490087032318,
              0.7510390281677246,
              0.4751890003681183,
              0.75102299451828,
              0.4758560061454773,
              0.7415549755096436,
              0.4751890003681183,
              0.75102299451828,
              0.4758560061454773,
              0.7415549755096436,
              0.4758560061454773,
              0.7415549755096436,
              0.4751890003681183,
              0.750997006893158,
              0.47696399688720703,
              0.7509859800338745,
              0.477400004863739,
              0.7415549755096436,
              0.47696399688720703,
              0.7509859800338745,
              0.477400004863739,
              0.7415549755096436,
              0.477400004863739,
              0.7415549755096436,
              0.47696399688720703,
              0.7514960169792175,
              0.45609501004219055,
              0.751459002494812,
              0.4576680064201355,
              0.7415549755096436,
              0.45609501004219055,
              0.751459002494812,
              0.4576680064201355,
              0.7415549755096436,
              0.4576680064201355,
              0.7415549755096436,
              0.45609501004219055,
              0.7513520121574402,
              0.46213299036026,
              0.7513179779052734,
              0.4635300040245056,
              0.7415549755096436,
              0.46213299036026,
              0.7513179779052734,
              0.4635300040245056,
              0.7415549755096436,
              0.4635300040245056,
              0.7415549755096436,
              0.46213299036026,
              0.75102299451828,
              0.4758560061454773,
              0.7510089874267578,
              0.4764479994773865,
              0.7415549755096436,
              0.4758560061454773,
              0.7510089874267578,
              0.4764479994773865,
              0.7415549755096436,
              0.4764479994773865,
              0.7415549755096436,
              0.4758560061454773,
              0.7510089874267578,
              0.4764479994773865,
              0.750997006893158,
              0.47696399688720703,
              0.7415549755096436,
              0.4764479994773865,
              0.750997006893158,
              0.47696399688720703,
              0.7415549755096436,
              0.47696399688720703,
              0.7415549755096436,
              0.4764479994773865,
              0.7509859800338745,
              0.477400004863739,
              0.7509779930114746,
              0.47775599360466003,
              0.7415549755096436,
              0.477400004863739,
              0.7509779930114746,
              0.47775599360466003,
              0.7415549755096436,
              0.47775599360466003,
              0.7415549755096436,
              0.477400004863739,
              0.7513179779052734,
              0.4635300040245056,
              0.751285970211029,
              0.46487900614738464,
              0.7415549755096436,
              0.4635300040245056,
              0.751285970211029,
              0.46487900614738464,
              0.7415549755096436,
              0.46487799286842346,
              0.7415549755096436,
              0.4635300040245056,
              0.7512549757957458,
              0.46617498993873596,
              0.7512249946594238,
              0.46741899847984314,
              0.7415549755096436,
              0.46617498993873596,
              0.7512249946594238,
              0.46741899847984314,
              0.7415549755096436,
              0.46741899847984314,
              0.7415549755096436,
              0.46617498993873596,
              0.7512249946594238,
              0.46741899847984314,
              0.7511969804763794,
              0.46860700845718384,
              0.7415549755096436,
              0.46741899847984314,
              0.7511969804763794,
              0.46860700845718384,
              0.7415549755096436,
              0.46860700845718384,
              0.7415549755096436,
              0.46741899847984314,
              0.7511199712753296,
              0.4718160033226013,
              0.7510970234870911,
              0.47276100516319275,
              0.7415549755096436,
              0.4718160033226013,
              0.7510970234870911,
              0.47276100516319275,
              0.7415549755096436,
              0.47275999188423157,
              0.7415549755096436,
              0.4718160033226013,
              0.7510759830474854,
              0.47363901138305664,
              0.7510570287704468,
              0.4744490087032318,
              0.7415549755096436,
              0.47363901138305664,
              0.7510570287704468,
              0.4744490087032318,
              0.7415549755096436,
              0.4744490087032318,
              0.7415549755096436,
              0.47363901138305664,
              0.753233015537262,
              0.3853900134563446,
              0.7515770196914673,
              0.4545019865036011,
              0.7531899809837341,
              0.3853900134563446,
              0.7515770196914673,
              0.4545019865036011,
              0.7515339851379395,
              0.4545019865036011,
              0.7531899809837341,
              0.3853900134563446,
              0.7531899809837341,
              0.3853900134563446,
              0.7515339851379395,
              0.4545019865036011,
              0.7415549755096436,
              0.3853900134563446,
              0.7515339851379395,
              0.4545019865036011,
              0.7415549755096436,
              0.4545019865036011,
              0.7415549755096436,
              0.3853900134563446,
              0.7515760064125061,
              0.45608100295066833,
              0.751613974571228,
              0.45449098944664,
              0.7515900135040283,
              0.4560379981994629,
              0.751613974571228,
              0.45449098944664,
              0.7516270279884338,
              0.454459011554718,
              0.7515900135040283,
              0.4560379981994629,
              0.7516189813613892,
              0.4542819857597351,
              0.7516469955444336,
              0.4531320035457611,
              0.7515829801559448,
              0.4542680084705353,
              0.7516469955444336,
              0.4531320035457611,
              0.7516109943389893,
              0.45312100648880005,
              0.7515829801559448,
              0.4542680084705353,
              0.7515770196914673,
              0.4545019865036011,
              0.751613974571228,
              0.45449098944664,
              0.7515389919281006,
              0.45609501004219055,
              0.751613974571228,
              0.45449098944664,
              0.7515760064125061,
              0.45608100295066833,
              0.7515389919281006,
              0.45609501004219055,
              0.7516310214996338,
              0.45432499051094055,
              0.751658022403717,
              0.4531640112400055,
              0.7516189813613892,
              0.4542819857597351,
              0.751658022403717,
              0.4531640112400055,
              0.7516469955444336,
              0.4531320035457611,
              0.7516189813613892,
              0.4542819857597351,
              0.7515910267829895,
              0.45598798990249634,
              0.7516279816627502,
              0.4544210135936737,
              0.7516289949417114,
              0.4543749988079071,
              0.7516279816627502,
              0.4544210135936737,
              0.7516570091247559,
              0.4532020092010498,
              0.7516289949417114,
              0.4543749988079071,
              0.7515900135040283,
              0.4560379981994629,
              0.7516270279884338,
              0.454459011554718,
              0.7515910267829895,
              0.45598798990249634,
              0.7516270279884338,
              0.454459011554718,
              0.7516279816627502,
              0.4544210135936737,
              0.7515910267829895,
              0.45598798990249634,
              0.7515829801559448,
              0.4542680084705353,
              0.7516109943389893,
              0.45312100648880005,
              0.7515400052070618,
              0.4542680084705353,
              0.7516109943389893,
              0.45312100648880005,
              0.751567006111145,
              0.45312100648880005,
              0.7515400052070618,
              0.4542680084705353,
              0.7515400052070618,
              0.4542680084705353,
              0.751567006111145,
              0.45312100648880005,
              0.7415549755096436,
              0.4542680084705353,
              0.751567006111145,
              0.45312100648880005,
              0.7415549755096436,
              0.45312100648880005,
              0.7415549755096436,
              0.4542680084705353,
              0.7512410283088684,
              0.47007301449775696,
              0.7512490153312683,
              0.4702570140361786,
              0.751246988773346,
              0.4698469936847687,
              0.7512490153312683,
              0.4702570140361786,
              0.7512540221214294,
              0.47002899646759033,
              0.751246988773346,
              0.4698469936847687,
              0.7510510087013245,
              0.4780080020427704,
              0.7510129809379578,
              0.478069007396698,
              0.7510589957237244,
              0.4776949882507324,
              0.7510129809379578,
              0.478069007396698,
              0.7510210275650024,
              0.47775599360466003,
              0.7510589957237244,
              0.4776949882507324,
              0.7512059807777405,
              0.47001200914382935,
              0.7512410283088684,
              0.47007301449775696,
              0.7512120008468628,
              0.46978598833084106,
              0.7512410283088684,
              0.47007301449775696,
              0.751246988773346,
              0.4698469936847687,
              0.7512120008468628,
              0.46978598833084106,
              0.7510679960250854,
              0.47782400250434875,
              0.7510510087013245,
              0.4780080020427704,
              0.751075029373169,
              0.47751399874687195,
              0.7510510087013245,
              0.4780080020427704,
              0.7510589957237244,
              0.4776949882507324,
              0.751075029373169,
              0.47751399874687195,
              0.7510730028152466,
              0.4776090085506439,
              0.7512440085411072,
              0.4704720079898834,
              0.7510280013084412,
              0.47947001457214355,
              0.7512440085411072,
              0.4704720079898834,
              0.7511940002441406,
              0.4725480079650879,
              0.7510280013084412,
              0.47947001457214355,
              0.7512490153312683,
              0.4702570140361786,
              0.7512440085411072,
              0.4704720079898834,
              0.7512540221214294,
              0.47002899646759033,
              0.7512440085411072,
              0.4704720079898834,
              0.7512490153312683,
              0.4702419936656952,
              0.7512540221214294,
              0.47002899646759033,
              0.7510129809379578,
              0.478069007396698,
              0.7509700059890747,
              0.478069007396698,
              0.7510210275650024,
              0.47775599360466003,
              0.7509700059890747,
              0.478069007396698,
              0.7509779930114746,
              0.47775599360466003,
              0.7510210275650024,
              0.47775599360466003,
              0.7511559724807739,
              0.47210100293159485,
              0.7512059807777405,
              0.47001200914382935,
              0.7511129975318909,
              0.47210100293159485,
              0.7512059807777405,
              0.47001200914382935,
              0.7511630058288574,
              0.47001200914382935,
              0.7511129975318909,
              0.47210100293159485,
              0.75102299451828,
              0.4796789884567261,
              0.7510679960250854,
              0.47782400250434875,
              0.7510280013084412,
              0.47947001457214355,
              0.7510679960250854,
              0.47782400250434875,
              0.7510730028152466,
              0.4776090085506439,
              0.7510280013084412,
              0.47947001457214355,
              0.7511129975318909,
              0.47210100293159485,
              0.7511630058288574,
              0.47001200914382935,
              0.7415549755096436,
              0.47210100293159485,
              0.7511630058288574,
              0.47001200914382935,
              0.7415549755096436,
              0.47001200914382935,
              0.7415549755096436,
              0.47210100293159485,
              0.7509779930114746,
              0.47775599360466003,
              0.7509700059890747,
              0.478069007396698,
              0.7415549755096436,
              0.47775599360466003,
              0.7509700059890747,
              0.478069007396698,
              0.7415549755096436,
              0.478069007396698,
              0.7415549755096436,
              0.47775599360466003,
              0.7516270279884338,
              0.454459011554718,
              0.751613974571228,
              0.45449098944664,
              0.7532839775085449,
              0.38531699776649475,
              0.751613974571228,
              0.45449098944664,
              0.7532699704170227,
              0.38537099957466125,
              0.7532839775085449,
              0.38531699776649475,
              0.7516109943389893,
              0.45312100648880005,
              0.7516469955444336,
              0.4531320035457611,
              0.7532899975776672,
              0.3830209970474243,
              0.7516469955444336,
              0.4531320035457611,
              0.7533259987831116,
              0.3830389976501465,
              0.7532899975776672,
              0.3830209970474243,
              0.7515770196914673,
              0.4545019865036011,
              0.753233015537262,
              0.3853900134563446,
              0.751613974571228,
              0.45449098944664,
              0.753233015537262,
              0.3853900134563446,
              0.7532699704170227,
              0.38537099957466125,
              0.751613974571228,
              0.45449098944664,
              0.753337025642395,
              0.38309401273727417,
              0.751658022403717,
              0.4531640112400055,
              0.7533349990844727,
              0.38315799832344055,
              0.751658022403717,
              0.4531640112400055,
              0.7516570091247559,
              0.4532020092010498,
              0.7533349990844727,
              0.38315799832344055,
              0.7415549755096436,
              0.3830209970474243,
              0.7415549755096436,
              0.45312100648880005,
              0.7532470226287842,
              0.3830209970474243,
              0.7415549755096436,
              0.45312100648880005,
              0.751567006111145,
              0.45312100648880005,
              0.7532470226287842,
              0.3830209970474243,
              0.751658022403717,
              0.4531640112400055,
              0.753337025642395,
              0.38309401273727417,
              0.7516469955444336,
              0.4531320035457611,
              0.753337025642395,
              0.38309401273727417,
              0.7533259987831116,
              0.3830389976501465,
              0.7516469955444336,
              0.4531320035457611,
              0.7516279816627502,
              0.4544210135936737,
              0.7532849907875061,
              0.3852519989013672,
              0.7516570091247559,
              0.4532020092010498,
              0.7532849907875061,
              0.3852519989013672,
              0.7533349990844727,
              0.38315799832344055,
              0.7516570091247559,
              0.4532020092010498,
              0.7532839775085449,
              0.38531699776649475,
              0.7532849907875061,
              0.3852519989013672,
              0.7516270279884338,
              0.454459011554718,
              0.7532849907875061,
              0.3852519989013672,
              0.7516279816627502,
              0.4544210135936737,
              0.7516270279884338,
              0.454459011554718,
              0.7516109943389893,
              0.45312100648880005,
              0.7532899975776672,
              0.3830209970474243,
              0.751567006111145,
              0.45312100648880005,
              0.7532899975776672,
              0.3830209970474243,
              0.7532470226287842,
              0.3830209970474243,
              0.751567006111145,
              0.45312100648880005,
              0.7270039916038513,
              0.26547300815582275,
              0.7265530228614807,
              0.2466219961643219,
              0.726967990398407,
              0.2654919922351837,
              0.7265530228614807,
              0.2466219961643219,
              0.7265160083770752,
              0.2466460019350052,
              0.726967990398407,
              0.2654919922351837,
              0.7265530228614807,
              0.2466219961643219,
              0.7261009812355042,
              0.22777099907398224,
              0.7265160083770752,
              0.2466460019350052,
              0.7261009812355042,
              0.22777099907398224,
              0.7260649800300598,
              0.22779999673366547,
              0.7265160083770752,
              0.2466460019350052,
              0.726967990398407,
              0.2654919922351837,
              0.7265160083770752,
              0.2466460019350052,
              0.7269570231437683,
              0.2655459940433502,
              0.7265160083770752,
              0.2466460019350052,
              0.7265059947967529,
              0.2467159926891327,
              0.7269570231437683,
              0.2655459940433502,
              0.7265160083770752,
              0.2466460019350052,
              0.7260649800300598,
              0.22779999673366547,
              0.7265059947967529,
              0.2467159926891327,
              0.7260649800300598,
              0.22779999673366547,
              0.7260550260543823,
              0.22788499295711517,
              0.7265059947967529,
              0.2467159926891327,
              0.7261360287666321,
              0.2312619984149933,
              0.7265719771385193,
              0.2494809925556183,
              0.7260569930076599,
              0.2279839962720871,
              0.7265719771385193,
              0.2494809925556183,
              0.7265080213546753,
              0.24679699540138245,
              0.7260569930076599,
              0.2279839962720871,
              0.7265719771385193,
              0.2494809925556183,
              0.7270079851150513,
              0.2676999866962433,
              0.7265080213546753,
              0.24679699540138245,
              0.7270079851150513,
              0.2676999866962433,
              0.7269579768180847,
              0.265610009431839,
              0.7265080213546753,
              0.24679699540138245,
              0.7270079851150513,
              0.2676999866962433,
              0.7265719771385193,
              0.2494809925556183,
              0.7270100116729736,
              0.2677629888057709,
              0.7265719771385193,
              0.2494809925556183,
              0.7265740036964417,
              0.24956199526786804,
              0.7270100116729736,
              0.2677629888057709,
              0.7265719771385193,
              0.2494809925556183,
              0.7261360287666321,
              0.2312619984149933,
              0.7265740036964417,
              0.24956199526786804,
              0.7261360287666321,
              0.2312619984149933,
              0.7261379957199097,
              0.2313610017299652,
              0.7265740036964417,
              0.24956199526786804,
              0.7415549755096436,
              0.26547300815582275,
              0.7415549755096436,
              0.2466219961643219,
              0.7270470261573792,
              0.26547300815582275,
              0.7415549755096436,
              0.2466219961643219,
              0.7265959978103638,
              0.2466219961643219,
              0.7270470261573792,
              0.26547300815582275,
              0.7415549755096436,
              0.2466219961643219,
              0.7415549755096436,
              0.22777099907398224,
              0.7265959978103638,
              0.2466219961643219,
              0.7415549755096436,
              0.22777099907398224,
              0.726144015789032,
              0.22777099907398224,
              0.7265959978103638,
              0.2466219961643219,
              0.7260569930076599,
              0.2279839962720871,
              0.7265070080757141,
              0.24679699540138245,
              0.7260550260543823,
              0.22788499295711517,
              0.7265070080757141,
              0.24679699540138245,
              0.7265059947967529,
              0.2467159926891327,
              0.7260550260543823,
              0.22788499295711517,
              0.7265070080757141,
              0.24679699540138245,
              0.7269579768180847,
              0.265610009431839,
              0.7265059947967529,
              0.2467159926891327,
              0.7269579768180847,
              0.265610009431839,
              0.7269570231437683,
              0.2655459940433502,
              0.7265059947967529,
              0.2467159926891327,
              0.7270100116729736,
              0.2677629888057709,
              0.7265740036964417,
              0.24956199526786804,
              0.7270240187644958,
              0.26781800389289856,
              0.7265740036964417,
              0.24956199526786804,
              0.7265880107879639,
              0.24963200092315674,
              0.7270240187644958,
              0.26781800389289856,
              0.7265740036964417,
              0.24956199526786804,
              0.7261379957199097,
              0.2313610017299652,
              0.7265880107879639,
              0.24963200092315674,
              0.7261379957199097,
              0.2313610017299652,
              0.7261520028114319,
              0.23144599795341492,
              0.7265880107879639,
              0.24963200092315674,
              0.7270240187644958,
              0.26781800389289856,
              0.7265880107879639,
              0.24963200092315674,
              0.7270609736442566,
              0.2678360044956207,
              0.7265880107879639,
              0.24963200092315674,
              0.7266250252723694,
              0.24965499341487885,
              0.7270609736442566,
              0.2678360044956207,
              0.7265880107879639,
              0.24963200092315674,
              0.7261520028114319,
              0.23144599795341492,
              0.7266250252723694,
              0.24965499341487885,
              0.7261520028114319,
              0.23144599795341492,
              0.7261899709701538,
              0.23147399723529816,
              0.7266250252723694,
              0.24965499341487885,
              0.7270470261573792,
              0.26547300815582275,
              0.7265959978103638,
              0.2466219961643219,
              0.7270039916038513,
              0.26547300815582275,
              0.7265959978103638,
              0.2466219961643219,
              0.7265520095825195,
              0.2466219961643219,
              0.7270039916038513,
              0.26547300815582275,
              0.7265959978103638,
              0.2466219961643219,
              0.726144015789032,
              0.22777099907398224,
              0.7265520095825195,
              0.2466219961643219,
              0.726144015789032,
              0.22777099907398224,
              0.7261009812355042,
              0.22777099907398224,
              0.7265520095825195,
              0.2466219961643219,
              0.7271040081977844,
              0.2678360044956207,
              0.7266680002212524,
              0.24965499341487885,
              0.7415549755096436,
              0.2678360044956207,
              0.7266680002212524,
              0.24965499341487885,
              0.7415549755096436,
              0.24965499341487885,
              0.7415549755096436,
              0.2678360044956207,
              0.7266680002212524,
              0.24965499341487885,
              0.7262330055236816,
              0.23147399723529816,
              0.7415549755096436,
              0.24965499341487885,
              0.7262330055236816,
              0.23147399723529816,
              0.7415549755096436,
              0.23147399723529816,
              0.7415549755096436,
              0.24965499341487885,
              0.7262330055236816,
              0.23147399723529816,
              0.7266690135002136,
              0.24965499341487885,
              0.7261899709701538,
              0.23147399723529816,
              0.7266690135002136,
              0.24965499341487885,
              0.7266250252723694,
              0.24965499341487885,
              0.7261899709701538,
              0.23147399723529816,
              0.7266690135002136,
              0.24965499341487885,
              0.7271040081977844,
              0.2678360044956207,
              0.7266250252723694,
              0.24965499341487885,
              0.7271040081977844,
              0.2678360044956207,
              0.7270609736442566,
              0.2678360044956207,
              0.7266250252723694,
              0.24965499341487885,
              0.7561420202255249,
              0.2654919922351837,
              0.7565940022468567,
              0.2466460019350052,
              0.7561060190200806,
              0.26547300815582275,
              0.7565940022468567,
              0.2466460019350052,
              0.7565580010414124,
              0.2466219961643219,
              0.7561060190200806,
              0.26547300815582275,
              0.7565940022468567,
              0.2466460019350052,
              0.7570449709892273,
              0.22779999673366547,
              0.7565580010414124,
              0.2466219961643219,
              0.7570449709892273,
              0.22779999673366547,
              0.7570090293884277,
              0.22777099907398224,
              0.7565580010414124,
              0.2466219961643219,
              0.7561529874801636,
              0.2655459940433502,
              0.756604015827179,
              0.2467159926891327,
              0.7561420202255249,
              0.2654919922351837,
              0.756604015827179,
              0.2467159926891327,
              0.7565929889678955,
              0.2466460019350052,
              0.7561420202255249,
              0.2654919922351837,
              0.756604015827179,
              0.2467159926891327,
              0.7570549845695496,
              0.22788499295711517,
              0.7565929889678955,
              0.2466460019350052,
              0.7570549845695496,
              0.22788499295711517,
              0.7570449709892273,
              0.22779999673366547,
              0.7565929889678955,
              0.2466460019350052,
              0.757053017616272,
              0.2279839962720871,
              0.7566019892692566,
              0.24679699540138245,
              0.7569739818572998,
              0.2312619984149933,
              0.7566019892692566,
              0.24679699540138245,
              0.7565370202064514,
              0.2494809925556183,
              0.7569739818572998,
              0.2312619984149933,
              0.7566019892692566,
              0.24679699540138245,
              0.756151020526886,
              0.265610009431839,
              0.7565370202064514,
              0.2494809925556183,
              0.756151020526886,
              0.265610009431839,
              0.7561010122299194,
              0.2676999866962433,
              0.7565370202064514,
              0.2494809925556183,
              0.7560999989509583,
              0.26776400208473206,
              0.7565360069274902,
              0.24956199526786804,
              0.7561010122299194,
              0.2676999866962433,
              0.7565360069274902,
              0.24956199526786804,
              0.7565370202064514,
              0.2494809925556183,
              0.7561010122299194,
              0.2676999866962433,
              0.7565360069274902,
              0.24956199526786804,
              0.7569720149040222,
              0.2313610017299652,
              0.7565370202064514,
              0.2494809925556183,
              0.7569720149040222,
              0.2313610017299652,
              0.7569739818572998,
              0.2312619984149933,
              0.7565370202064514,
              0.2494809925556183,
              0.7560629844665527,
              0.26547300815582275,
              0.7565140128135681,
              0.2466219961643219,
              0.7415549755096436,
              0.26547300815582275,
              0.7565140128135681,
              0.2466219961643219,
              0.7415549755096436,
              0.2466219961643219,
              0.7415549755096436,
              0.26547300815582275,
              0.7565140128135681,
              0.2466219961643219,
              0.7569659948348999,
              0.22777099907398224,
              0.7415549755096436,
              0.2466219961643219,
              0.7569659948348999,
              0.22777099907398224,
              0.7415549755096436,
              0.22777099907398224,
              0.7415549755096436,
              0.2466219961643219,
              0.7570549845695496,
              0.22788499295711517,
              0.756604015827179,
              0.2467149943113327,
              0.757053017616272,
              0.2279839962720871,
              0.756604015827179,
              0.2467149943113327,
              0.7566019892692566,
              0.24679699540138245,
              0.757053017616272,
              0.2279839962720871,
              0.756604015827179,
              0.2467149943113327,
              0.7561529874801636,
              0.2655459940433502,
              0.7566019892692566,
              0.24679699540138245,
              0.7561529874801636,
              0.2655459940433502,
              0.756151020526886,
              0.265610009431839,
              0.7566019892692566,
              0.24679699540138245,
              0.756085991859436,
              0.26781800389289856,
              0.756521999835968,
              0.24963200092315674,
              0.7560999989509583,
              0.26776400208473206,
              0.756521999835968,
              0.24963200092315674,
              0.7565360069274902,
              0.24956299364566803,
              0.7560999989509583,
              0.26776400208473206,
              0.756521999835968,
              0.24963200092315674,
              0.7569580078125,
              0.23144599795341492,
              0.7565360069274902,
              0.24956299364566803,
              0.7569580078125,
              0.23144599795341492,
              0.7569720149040222,
              0.2313610017299652,
              0.7565360069274902,
              0.24956299364566803,
              0.7560489773750305,
              0.2678360044956207,
              0.7564849853515625,
              0.24965499341487885,
              0.756085991859436,
              0.26781800389289856,
              0.7564849853515625,
              0.24965499341487885,
              0.756521999835968,
              0.24963200092315674,
              0.756085991859436,
              0.26781800389289856,
              0.7564849853515625,
              0.24965499341487885,
              0.7569199800491333,
              0.23147399723529816,
              0.756521999835968,
              0.24963200092315674,
              0.7569199800491333,
              0.23147399723529816,
              0.7569580078125,
              0.23144599795341492,
              0.756521999835968,
              0.24963200092315674,
              0.7561060190200806,
              0.26547300815582275,
              0.7565580010414124,
              0.2466219961643219,
              0.7560629844665527,
              0.26547300815582275,
              0.7565580010414124,
              0.2466219961643219,
              0.7565140128135681,
              0.2466219961643219,
              0.7560629844665527,
              0.26547300815582275,
              0.7565580010414124,
              0.2466219961643219,
              0.7570090293884277,
              0.22777099907398224,
              0.7565140128135681,
              0.2466219961643219,
              0.7570090293884277,
              0.22777099907398224,
              0.7569659948348999,
              0.22777099907398224,
              0.7565140128135681,
              0.2466219961643219,
              0.7415549755096436,
              0.2678360044956207,
              0.7415549755096436,
              0.24965499341487885,
              0.7560060024261475,
              0.2678360044956207,
              0.7415549755096436,
              0.24965499341487885,
              0.7564409971237183,
              0.24965499341487885,
              0.7560060024261475,
              0.2678360044956207,
              0.7415549755096436,
              0.24965499341487885,
              0.7415549755096436,
              0.23147399723529816,
              0.7564409971237183,
              0.24965499341487885,
              0.7415549755096436,
              0.23147399723529816,
              0.7568770051002502,
              0.23147399723529816,
              0.7564409971237183,
              0.24965499341487885,
              0.7569199800491333,
              0.23147399723529816,
              0.7564849853515625,
              0.24965499341487885,
              0.7568770051002502,
              0.23147399723529816,
              0.7564849853515625,
              0.24965499341487885,
              0.7564420104026794,
              0.24965499341487885,
              0.7568770051002502,
              0.23147399723529816,
              0.7564849853515625,
              0.24965499341487885,
              0.7560489773750305,
              0.2678360044956207,
              0.7564420104026794,
              0.24965499341487885,
              0.7560489773750305,
              0.2678360044956207,
              0.7560060024261475,
              0.2678360044956207,
              0.7564420104026794,
              0.24965499341487885,
              0.7474009990692139,
              0.3830209970474243,
              0.7444779872894287,
              0.3830209970474243,
              0.7465609908103943,
              0.45312100648880005,
              0.7444779872894287,
              0.3830209970474243,
              0.7440580129623413,
              0.45312100648880005,
              0.7465609908103943,
              0.45312100648880005,
              0.7444779872894287,
              0.3830209970474243,
              0.7415549755096436,
              0.3830209970474243,
              0.7440580129623413,
              0.45312100648880005,
              0.7415549755096436,
              0.3830209970474243,
              0.7415549755096436,
              0.45312100648880005,
              0.7440580129623413,
              0.45312100648880005,
              0.7415549755096436,
              0.41994601488113403,
              0.7415549755096436,
              0.43722400069236755,
              0.7307469844818115,
              0.41994601488113403,
              0.7415549755096436,
              0.43722400069236755,
              0.7311609983444214,
              0.43722400069236755,
              0.7307469844818115,
              0.41994601488113403,
              0.7415549755096436,
              0.43722400069236755,
              0.7415549755096436,
              0.4545019865036011,
              0.7311609983444214,
              0.43722400069236755,
              0.7415549755096436,
              0.4545019865036011,
              0.7315750122070312,
              0.4545019865036011,
              0.7311609983444214,
              0.43722400069236755,
              0.7306680083274841,
              0.41993099451065063,
              0.7302539944648743,
              0.40265101194381714,
              0.7307050228118896,
              0.41994601488113403,
              0.7302539944648743,
              0.40265101194381714,
              0.7302910089492798,
              0.4026679992675781,
              0.7307050228118896,
              0.41994601488113403,
              0.7302539944648743,
              0.40265101194381714,
              0.7298399806022644,
              0.38537099957466125,
              0.7302910089492798,
              0.4026679992675781,
              0.7298399806022644,
              0.38537099957466125,
              0.7298769950866699,
              0.3853900134563446,
              0.7302910089492798,
              0.4026679992675781,
              0.7306230068206787,
              0.4180859923362732,
              0.7302039861679077,
              0.40056198835372925,
              0.7306119799613953,
              0.41812899708747864,
              0.7302039861679077,
              0.40056198835372925,
              0.7301920056343079,
              0.400611013174057,
              0.7306119799613953,
              0.41812899708747864,
              0.7302039861679077,
              0.40056198835372925,
              0.7297840118408203,
              0.3830389976501465,
              0.7301920056343079,
              0.400611013174057,
              0.7297840118408203,
              0.3830389976501465,
              0.7297729849815369,
              0.38309401273727417,
              0.7301920056343079,
              0.400611013174057,
              0.7314820289611816,
              0.45443999767303467,
              0.7314810156822205,
              0.4544290006160736,
              0.7298250198364258,
              0.3852840065956116,
              0.7314810156822205,
              0.4544290006160736,
              0.7298250198364258,
              0.3852680027484894,
              0.7298250198364258,
              0.3852840065956116,
              0.7314810156822205,
              0.4544290006160736,
              0.7314810156822205,
              0.4544210135936737,
              0.7298250198364258,
              0.3852680027484894,
              0.7314810156822205,
              0.4544210135936737,
              0.7298250198364258,
              0.3852519989013672,
              0.7298250198364258,
              0.3852680027484894,
              0.7298020124435425,
              0.3830310106277466,
              0.7297930121421814,
              0.38303300738334656,
              0.7314810156822205,
              0.4531269967556,
              0.7297930121421814,
              0.38303300738334656,
              0.7314720153808594,
              0.45312801003456116,
              0.7314810156822205,
              0.4531269967556,
              0.7297930121421814,
              0.38303300738334656,
              0.7297840118408203,
              0.3830389976501465,
              0.7314720153808594,
              0.45312801003456116,
              0.7297840118408203,
              0.3830389976501465,
              0.7314630150794983,
              0.4531320035457611,
              0.7314720153808594,
              0.45312801003456116,
              0.7306129932403564,
              0.418179988861084,
              0.7310330271720886,
              0.4356909990310669,
              0.7306119799613953,
              0.41812899708747864,
              0.7310330271720886,
              0.4356909990310669,
              0.7310310006141663,
              0.4356459975242615,
              0.7306119799613953,
              0.41812899708747864,
              0.7310330271720886,
              0.4356909990310669,
              0.7314519882202148,
              0.4532020092010498,
              0.7310310006141663,
              0.4356459975242615,
              0.7314519882202148,
              0.4532020092010498,
              0.7314509749412537,
              0.4531640112400055,
              0.7310310006141663,
              0.4356459975242615,
              0.7298330068588257,
              0.3853429853916168,
              0.7298359870910645,
              0.38535699248313904,
              0.7314879894256592,
              0.4544749855995178,
              0.7298359870910645,
              0.38535699248313904,
              0.7314919829368591,
              0.4544839859008789,
              0.7314879894256592,
              0.4544749855995178,
              0.7298359870910645,
              0.38535699248313904,
              0.7298399806022644,
              0.38537099957466125,
              0.7314919829368591,
              0.4544839859008789,
              0.7298399806022644,
              0.38537099957466125,
              0.7314950227737427,
              0.45449098944664,
              0.7314919829368591,
              0.4544839859008789,
              0.7306129932403564,
              0.418179988861084,
              0.730193018913269,
              0.40066900849342346,
              0.7306529879570007,
              0.41983699798583984,
              0.730193018913269,
              0.40066900849342346,
              0.7302389740943909,
              0.4025439918041229,
              0.7306529879570007,
              0.41983699798583984,
              0.730193018913269,
              0.40066900849342346,
              0.729773998260498,
              0.38315799832344055,
              0.7302389740943909,
              0.4025439918041229,
              0.729773998260498,
              0.38315799832344055,
              0.7298250198364258,
              0.3852519989013672,
              0.7302389740943909,
              0.4025439918041229,
              0.7307019829750061,
              0.4180710017681122,
              0.7302830219268799,
              0.40054601430892944,
              0.730659008026123,
              0.4180710017681122,
              0.7302830219268799,
              0.40054601430892944,
              0.730239987373352,
              0.40054601430892944,
              0.730659008026123,
              0.4180710017681122,
              0.7302830219268799,
              0.40054601430892944,
              0.7298629879951477,
              0.3830209970474243,
              0.730239987373352,
              0.40054601430892944,
              0.7298629879951477,
              0.3830209970474243,
              0.7298200130462646,
              0.3830209970474243,
              0.730239987373352,
              0.40054601430892944,
              0.7415549755096436,
              0.4180710017681122,
              0.7415549755096436,
              0.40054601430892944,
              0.7307029962539673,
              0.4180710017681122,
              0.7415549755096436,
              0.40054601430892944,
              0.7302830219268799,
              0.40054601430892944,
              0.7307029962539673,
              0.4180710017681122,
              0.7415549755096436,
              0.40054601430892944,
              0.7415549755096436,
              0.3830209970474243,
              0.7302830219268799,
              0.40054601430892944,
              0.7415549755096436,
              0.3830209970474243,
              0.7298629879951477,
              0.3830209970474243,
              0.7302830219268799,
              0.40054601430892944,
              0.7532510161399841,
              0.3853810131549835,
              0.7532610297203064,
              0.3853760063648224,
              0.7515950202941895,
              0.45449700951576233,
              0.7532610297203064,
              0.3853760063648224,
              0.7516049742698669,
              0.45449399948120117,
              0.7515950202941895,
              0.45449700951576233,
              0.7532610297203064,
              0.3853760063648224,
              0.7532699704170227,
              0.38537099957466125,
              0.7516049742698669,
              0.45449399948120117,
              0.7532699704170227,
              0.38537099957466125,
              0.751613974571228,
              0.45449098944664,
              0.7516049742698669,
              0.45449399948120117,
              0.7515559792518616,
              0.4545010030269623,
              0.7515450119972229,
              0.45450299978256226,
              0.7532119750976562,
              0.3853900134563446,
              0.7515450119972229,
              0.45450299978256226,
              0.7532010078430176,
              0.3853900134563446,
              0.7532119750976562,
              0.3853900134563446,
              0.7515450119972229,
              0.45450299978256226,
              0.7515339851379395,
              0.4545019865036011,
              0.7532010078430176,
              0.3853900134563446,
              0.7515339851379395,
              0.4545019865036011,
              0.7531899809837341,
              0.3853900134563446,
              0.7532010078430176,
              0.3853900134563446,
              0.751658022403717,
              0.45318299531936646,
              0.7516570091247559,
              0.4531930088996887,
              0.7533360123634338,
              0.3831239938735962,
              0.7516570091247559,
              0.4531930088996887,
              0.7533360123634338,
              0.38314101099967957,
              0.7533360123634338,
              0.3831239938735962,
              0.7516570091247559,
              0.4531930088996887,
              0.7516570091247559,
              0.4532020092010498,
              0.7533360123634338,
              0.38314101099967957,
              0.7516570091247559,
              0.4532020092010498,
              0.7533349990844727,
              0.38315799832344055,
              0.7533360123634338,
              0.38314101099967957,
              0.752485990524292,
              0.4180859923362732,
              0.7529060244560242,
              0.40056198835372925,
              0.7524499893188477,
              0.4180710017681122,
              0.7529060244560242,
              0.40056198835372925,
              0.7528700232505798,
              0.40054601430892944,
              0.7524499893188477,
              0.4180710017681122,
              0.7529060244560242,
              0.40056198835372925,
              0.7533259987831116,
              0.3830389976501465,
              0.7528700232505798,
              0.40054601430892944,
              0.7533259987831116,
              0.3830389976501465,
              0.7532899975776672,
              0.3830209970474243,
              0.7528700232505798,
              0.40054601430892944,
              0.7533320188522339,
              0.3830670118331909,
              0.7533289790153503,
              0.3830539882183075,
              0.7516530156135559,
              0.4531469941139221,
              0.7533289790153503,
              0.3830539882183075,
              0.7516499757766724,
              0.45313799381256104,
              0.7516530156135559,
              0.4531469941139221,
              0.7533289790153503,
              0.3830539882183075,
              0.7533259987831116,
              0.3830389976501465,
              0.7516499757766724,
              0.45313799381256104,
              0.7533259987831116,
              0.3830389976501465,
              0.7516469955444336,
              0.4531320035457611,
              0.7516499757766724,
              0.45313799381256104,
              0.7524420022964478,
              0.41993099451065063,
              0.7528560161590576,
              0.40265101194381714,
              0.7524549961090088,
              0.4198879897594452,
              0.7528560161590576,
              0.40265101194381714,
              0.7528700232505798,
              0.40260300040245056,
              0.7524549961090088,
              0.4198879897594452,
              0.7528560161590576,
              0.40265101194381714,
              0.7532699704170227,
              0.38537099957466125,
              0.7528700232505798,
              0.40260300040245056,
              0.7532699704170227,
              0.38537099957466125,
              0.7532839775085449,
              0.38531699776649475,
              0.7528700232505798,
              0.40260300040245056,
              0.7532680034637451,
              0.3830200135707855,
              0.7532579898834229,
              0.3830209970474243,
              0.7515890002250671,
              0.45312100648880005,
              0.7532579898834229,
              0.3830209970474243,
              0.7515779733657837,
              0.45312198996543884,
              0.7515890002250671,
              0.45312100648880005,
              0.7532579898834229,
              0.3830209970474243,
              0.7532470226287842,
              0.3830209970474243,
              0.7515779733657837,
              0.45312198996543884,
              0.7532470226287842,
              0.3830209970474243,
              0.751567006111145,
              0.45312100648880005,
              0.7515779733657837,
              0.45312198996543884,
              0.7533100247383118,
              0.38420501351356506,
              0.7533230185508728,
              0.3836809992790222,
              0.7516419887542725,
              0.45381098985671997,
              0.7533230185508728,
              0.3836809992790222,
              0.7516499757766724,
              0.4535070061683655,
              0.7516419887542725,
              0.45381098985671997,
              0.7533230185508728,
              0.3836809992790222,
              0.7533349990844727,
              0.38315799832344055,
              0.7516499757766724,
              0.4535070061683655,
              0.7533349990844727,
              0.38315799832344055,
              0.7516570091247559,
              0.4532020092010498,
              0.7516499757766724,
              0.4535070061683655,
              0.7465440034866333,
              0.4545019865036011,
              0.7440500259399414,
              0.4545019865036011,
              0.7473729848861694,
              0.3853900134563446,
              0.7440500259399414,
              0.4545019865036011,
              0.7444639801979065,
              0.3853900134563446,
              0.7473729848861694,
              0.3853900134563446,
              0.7440500259399414,
              0.4545019865036011,
              0.7415549755096436,
              0.4545019865036011,
              0.7444639801979065,
              0.3853900134563446,
              0.7415549755096436,
              0.4545019865036011,
              0.7415549755096436,
              0.3853900134563446,
              0.7444639801979065,
              0.3853900134563446,
              0.75245600938797,
              0.41983699798583984,
              0.7520419955253601,
              0.4371289908885956,
              0.75245600938797,
              0.4198879897594452,
              0.7520419955253601,
              0.4371289908885956,
              0.7520409822463989,
              0.4371730089187622,
              0.75245600938797,
              0.4198879897594452,
              0.7520419955253601,
              0.4371289908885956,
              0.7516279816627502,
              0.4544210135936737,
              0.7520409822463989,
              0.4371730089187622,
              0.7516279816627502,
              0.4544210135936737,
              0.7516270279884338,
              0.454459011554718,
              0.7520409822463989,
              0.4371730089187622,
              0.7307479977607727,
              0.41994601488113403,
              0.7311609983444214,
              0.43722400069236755,
              0.7307040095329285,
              0.41994601488113403,
              0.7311609983444214,
              0.43722400069236755,
              0.7311180233955383,
              0.43722400069236755,
              0.7307040095329285,
              0.41994601488113403,
              0.7311609983444214,
              0.43722400069236755,
              0.7315750122070312,
              0.4545019865036011,
              0.7311180233955383,
              0.43722400069236755,
              0.7315750122070312,
              0.4545019865036011,
              0.7315319776535034,
              0.4545019865036011,
              0.7311180233955383,
              0.43722400069236755,
              0.749064028263092,
              0.45312100648880005,
              0.751567006111145,
              0.45312100648880005,
              0.750324010848999,
              0.3830209970474243,
              0.751567006111145,
              0.45312100648880005,
              0.7532470226287842,
              0.3830209970474243,
              0.750324010848999,
              0.3830209970474243,
              0.750324010848999,
              0.3830209970474243,
              0.7474009990692139,
              0.3830209970474243,
              0.749064028263092,
              0.45312100648880005,
              0.7474009990692139,
              0.3830209970474243,
              0.7465609908103943,
              0.45312100648880005,
              0.749064028263092,
              0.45312100648880005,
              0.7415549755096436,
              0.3853900134563446,
              0.7415549755096436,
              0.4026679992675781,
              0.7299200296401978,
              0.3853900134563446,
              0.7415549755096436,
              0.4026679992675781,
              0.7303339838981628,
              0.4026679992675781,
              0.7299200296401978,
              0.3853900134563446,
              0.7415549755096436,
              0.4026679992675781,
              0.7415549755096436,
              0.41994601488113403,
              0.7303339838981628,
              0.4026679992675781,
              0.7415549755096436,
              0.41994601488113403,
              0.7307469844818115,
              0.41994601488113403,
              0.7303339838981628,
              0.4026679992675781,
              0.7314950227737427,
              0.45449098944664,
              0.7310810089111328,
              0.4372110068798065,
              0.7315319776535034,
              0.4545019865036011,
              0.7310810089111328,
              0.4372110068798065,
              0.7311180233955383,
              0.43722400069236755,
              0.7315319776535034,
              0.4545019865036011,
              0.7310810089111328,
              0.4372110068798065,
              0.7306680083274841,
              0.41993099451065063,
              0.7311180233955383,
              0.43722400069236755,
              0.7306680083274841,
              0.41993099451065063,
              0.7307050228118896,
              0.41994601488113403,
              0.7311180233955383,
              0.43722400069236755,
              0.7314630150794983,
              0.4531320035457611,
              0.7310429811477661,
              0.43560901284217834,
              0.7314509749412537,
              0.4531640112400055,
              0.7310429811477661,
              0.43560901284217834,
              0.7310310006141663,
              0.4356459975242615,
              0.7314509749412537,
              0.4531640112400055,
              0.7310429811477661,
              0.43560901284217834,
              0.7306230068206787,
              0.4180859923362732,
              0.7310310006141663,
              0.4356459975242615,
              0.7306230068206787,
              0.4180859923362732,
              0.7306119799613953,
              0.41812899708747864,
              0.7310310006141663,
              0.4356459975242615,
              0.7314820289611816,
              0.454459011554718,
              0.7314820289611816,
              0.45444899797439575,
              0.7298259735107422,
              0.38531601428985596,
              0.7314820289611816,
              0.45444899797439575,
              0.7298259735107422,
              0.3852989971637726,
              0.7298259735107422,
              0.38531601428985596,
              0.7314820289611816,
              0.45444899797439575,
              0.7314820289611816,
              0.45443999767303467,
              0.7298259735107422,
              0.3852989971637726,
              0.7314820289611816,
              0.45443999767303467,
              0.7298250198364258,
              0.3852840065956116,
              0.7298259735107422,
              0.3852989971637726,
              0.7298200130462646,
              0.3830209970474243,
              0.7298110127449036,
              0.38302499055862427,
              0.7314990162849426,
              0.45312100648880005,
              0.7298110127449036,
              0.38302499055862427,
              0.7314900159835815,
              0.453125,
              0.7314990162849426,
              0.45312100648880005,
              0.7298110127449036,
              0.38302499055862427,
              0.7298020124435425,
              0.3830310106277466,
              0.7314900159835815,
              0.453125,
              0.7298020124435425,
              0.3830310106277466,
              0.7314810156822205,
              0.4531269967556,
              0.7314900159835815,
              0.453125,
              0.729773998260498,
              0.38315799832344055,
              0.7301939725875854,
              0.40066900849342346,
              0.7297729849815369,
              0.38309401273727417,
              0.7301939725875854,
              0.40066900849342346,
              0.7301920056343079,
              0.400611013174057,
              0.7297729849815369,
              0.38309401273727417,
              0.7301939725875854,
              0.40066900849342346,
              0.7306129932403564,
              0.418179988861084,
              0.7301920056343079,
              0.400611013174057,
              0.7306129932403564,
              0.418179988861084,
              0.7306119799613953,
              0.41812899708747864,
              0.7301920056343079,
              0.400611013174057,
              0.7298259735107422,
              0.38531601428985596,
              0.7298290133476257,
              0.3853299915790558,
              0.7314820289611816,
              0.454459011554718,
              0.7298290133476257,
              0.3853299915790558,
              0.7314850091934204,
              0.4544669985771179,
              0.7314820289611816,
              0.454459011554718,
              0.7298290133476257,
              0.3853299915790558,
              0.7298330068588257,
              0.3853429853916168,
              0.7314850091934204,
              0.4544669985771179,
              0.7298330068588257,
              0.3853429853916168,
              0.7314879894256592,
              0.4544749855995178,
              0.7314850091934204,
              0.4544669985771179,
              0.7314519882202148,
              0.4532020092010498,
              0.7310320138931274,
              0.4356909990310669,
              0.7314810156822205,
              0.4544210135936737,
              0.7310320138931274,
              0.4356909990310669,
              0.7310670018196106,
              0.4371289908885956,
              0.7314810156822205,
              0.4544210135936737,
              0.7310320138931274,
              0.4356909990310669,
              0.7306129932403564,
              0.418179988861084,
              0.7310670018196106,
              0.4371289908885956,
              0.7306129932403564,
              0.418179988861084,
              0.7306529879570007,
              0.41983699798583984,
              0.7310670018196106,
              0.4371289908885956,
              0.7315419912338257,
              0.45312100648880005,
              0.7311220169067383,
              0.4355959892272949,
              0.7314990162849426,
              0.45312100648880005,
              0.7311220169067383,
              0.4355959892272949,
              0.7310789823532104,
              0.4355959892272949,
              0.7314990162849426,
              0.45312100648880005,
              0.7311220169067383,
              0.4355959892272949,
              0.7307019829750061,
              0.4180710017681122,
              0.7310789823532104,
              0.4355959892272949,
              0.7307019829750061,
              0.4180710017681122,
              0.730659008026123,
              0.4180710017681122,
              0.7310789823532104,
              0.4355959892272949,
              0.7415549755096436,
              0.45312100648880005,
              0.7415549755096436,
              0.4355959892272949,
              0.7315419912338257,
              0.45312100648880005,
              0.7415549755096436,
              0.4355959892272949,
              0.7311220169067383,
              0.4355959892272949,
              0.7315419912338257,
              0.45312100648880005,
              0.7415549755096436,
              0.4355959892272949,
              0.7415549755096436,
              0.4180710017681122,
              0.7311220169067383,
              0.4355959892272949,
              0.7415549755096436,
              0.4180710017681122,
              0.7307029962539673,
              0.4180710017681122,
              0.7311220169067383,
              0.4355959892272949,
              0.753233015537262,
              0.3853900134563446,
              0.753242015838623,
              0.38538500666618347,
              0.7515770196914673,
              0.4545019865036011,
              0.753242015838623,
              0.38538500666618347,
              0.7515860199928284,
              0.4545010030269623,
              0.7515770196914673,
              0.4545019865036011,
              0.753242015838623,
              0.38538500666618347,
              0.7532510161399841,
              0.3853810131549835,
              0.7515860199928284,
              0.4545010030269623,
              0.7532510161399841,
              0.3853810131549835,
              0.7515950202941895,
              0.45449700951576233,
              0.7515860199928284,
              0.4545010030269623,
              0.7515770196914673,
              0.4545019865036011,
              0.7515659928321838,
              0.4545019865036011,
              0.753233015537262,
              0.3853900134563446,
              0.7515659928321838,
              0.4545019865036011,
              0.7532219886779785,
              0.3853900134563446,
              0.753233015537262,
              0.3853900134563446,
              0.7515659928321838,
              0.4545019865036011,
              0.7515559792518616,
              0.4545010030269623,
              0.7532219886779785,
              0.3853900134563446,
              0.7515559792518616,
              0.4545010030269623,
              0.7532119750976562,
              0.3853900134563446,
              0.7532219886779785,
              0.3853900134563446,
              0.751658022403717,
              0.4531640112400055,
              0.751658022403717,
              0.4531719982624054,
              0.753337025642395,
              0.38309401273727417,
              0.751658022403717,
              0.4531719982624054,
              0.753337025642395,
              0.3831090033054352,
              0.753337025642395,
              0.38309401273727417,
              0.751658022403717,
              0.4531719982624054,
              0.751658022403717,
              0.45318299531936646,
              0.753337025642395,
              0.3831090033054352,
              0.751658022403717,
              0.45318299531936646,
              0.7533360123634338,
              0.3831239938735962,
              0.753337025642395,
              0.3831090033054352,
              0.7516469955444336,
              0.4531320035457611,
              0.7520670294761658,
              0.43560901284217834,
              0.7516109943389893,
              0.45312100648880005,
              0.7520670294761658,
              0.43560901284217834,
              0.7520310282707214,
              0.4355959892272949,
              0.7516109943389893,
              0.45312100648880005,
              0.7520670294761658,
              0.43560901284217834,
              0.752485990524292,
              0.4180859923362732,
              0.7520310282707214,
              0.4355959892272949,
              0.752485990524292,
              0.4180859923362732,
              0.7524499893188477,
              0.4180710017681122,
              0.7520310282707214,
              0.4355959892272949,
              0.753337025642395,
              0.38309401273727417,
              0.7533339858055115,
              0.38308000564575195,
              0.751658022403717,
              0.4531640112400055,
              0.7533339858055115,
              0.38308000564575195,
              0.7516549825668335,
              0.4531559944152832,
              0.751658022403717,
              0.4531640112400055,
              0.7533339858055115,
              0.38308000564575195,
              0.7533320188522339,
              0.3830670118331909,
              0.7516549825668335,
              0.4531559944152832,
              0.7533320188522339,
              0.3830670118331909,
              0.7516530156135559,
              0.4531469941139221,
              0.7516549825668335,
              0.4531559944152832,
              0.751613974571228,
              0.45449098944664,
              0.7520279884338379,
              0.4372110068798065,
              0.7516270279884338,
              0.454459011554718,
              0.7520279884338379,
              0.4372110068798065,
              0.7520409822463989,
              0.437173992395401,
              0.7516270279884338,
              0.454459011554718,
              0.7520279884338379,
              0.4372110068798065,
              0.7524420022964478,
              0.41993099451065063,
              0.7520409822463989,
              0.437173992395401,
              0.7524420022964478,
              0.41993099451065063,
              0.7524549961090088,
              0.4198879897594452,
              0.7520409822463989,
              0.437173992395401,
              0.7532899975776672,
              0.3830209970474243,
              0.7532789707183838,
              0.3830200135707855,
              0.7516109943389893,
              0.45312100648880005,
              0.7532789707183838,
              0.3830200135707855,
              0.7516000270843506,
              0.4531190097332001,
              0.7516109943389893,
              0.45312100648880005,
              0.7532789707183838,
              0.3830200135707855,
              0.7532680034637451,
              0.3830200135707855,
              0.7516000270843506,
              0.4531190097332001,
              0.7532680034637451,
              0.3830200135707855,
              0.7515890002250671,
              0.45312100648880005,
              0.7516000270843506,
              0.4531190097332001,
              0.7532849907875061,
              0.3852519989013672,
              0.7532979846000671,
              0.38472801446914673,
              0.7516279816627502,
              0.4544210135936737,
              0.7532979846000671,
              0.38472801446914673,
              0.7516350150108337,
              0.45411598682403564,
              0.7516279816627502,
              0.4544210135936737,
              0.7532979846000671,
              0.38472801446914673,
              0.7533100247383118,
              0.38420501351356506,
              0.7516350150108337,
              0.45411598682403564,
              0.7533100247383118,
              0.38420501351356506,
              0.7516419887542725,
              0.45381098985671997,
              0.7516350150108337,
              0.45411598682403564,
              0.7515339851379395,
              0.4545019865036011,
              0.7490389943122864,
              0.4545019865036011,
              0.7531899809837341,
              0.3853900134563446,
              0.7490389943122864,
              0.4545019865036011,
              0.7502809762954712,
              0.3853900134563446,
              0.7531899809837341,
              0.3853900134563446,
              0.7490389943122864,
              0.4545019865036011,
              0.7465440034866333,
              0.4545019865036011,
              0.7502809762954712,
              0.3853900134563446,
              0.7465440034866333,
              0.4545019865036011,
              0.7473729848861694,
              0.3853900134563446,
              0.7502809762954712,
              0.3853900134563446,
              0.7532849907875061,
              0.3852519989013672,
              0.7528709769248962,
              0.4025439918041229,
              0.7532839775085449,
              0.38531699776649475,
              0.7528709769248962,
              0.4025439918041229,
              0.7528700232505798,
              0.4026019871234894,
              0.7532839775085449,
              0.38531699776649475,
              0.7528709769248962,
              0.4025439918041229,
              0.75245600938797,
              0.41983699798583984,
              0.7528700232505798,
              0.4026019871234894,
              0.75245600938797,
              0.41983699798583984,
              0.75245600938797,
              0.4198879897594452,
              0.7528700232505798,
              0.4026019871234894,
              0.7299200296401978,
              0.3853900134563446,
              0.7303339838981628,
              0.4026679992675781,
              0.7298769950866699,
              0.3853900134563446,
              0.7303339838981628,
              0.4026679992675781,
              0.7302910089492798,
              0.4026679992675781,
              0.7298769950866699,
              0.3853900134563446,
              0.7303339838981628,
              0.4026679992675781,
              0.7307479977607727,
              0.41994601488113403,
              0.7302910089492798,
              0.4026679992675781,
              0.7307479977607727,
              0.41994601488113403,
              0.7307040095329285,
              0.41994601488113403,
              0.7302910089492798,
              0.4026679992675781,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0,
              1,
              1,
              1,
              0,
              0,
              0.045916300266981125,
              0,
              0.05509360134601593,
              0.00036484398879110813,
              0.045959699898958206,
              0,
              0.05509360134601593,
              0.00036484398879110813,
              0.05509360134601593,
              0.00036484398879110813,
              0.045959699898958206,
              0.00036484398879110813,
              0.9449059963226318,
              0,
              0.9449059963226318,
              0.00036484398879110813,
              0.954039990901947,
              0,
              0.9449059963226318,
              0,
              0.9540839791297913,
              0.00036484398879110813,
              0.954039990901947,
              0.05173660069704056,
              0.0008823429816402495,
              0.0396789014339447,
              0.0034951800480484962,
              0.05185690149664879,
              0.001138749998062849,
              0.0396789014339447,
              0.0034951800480484962,
              0.03985600173473358,
              0.0037392498925328255,
              0.05185690149664879,
              0.001138749998062849,
              0.03985600173473358,
              0.9962610006332397,
              0.0396789014339447,
              0.9965050220489502,
              0.05185690149664879,
              0.9988610148429871,
              0.0396789014339447,
              0.9965050220489502,
              0.05173660069704056,
              0.9991179704666138,
              0.05185690149664879,
              0.9988610148429871,
              0.010832499712705612,
              0.020406700670719147,
              0.011146100237965584,
              0.020570699125528336,
              0.01882619969546795,
              0.013448599725961685,
              0.011146100237965584,
              0.020570699125528336,
              0.019101999700069427,
              0.01364549994468689,
              0.01882619969546795,
              0.013448599725961685,
              0.019101999700069427,
              0.9863539934158325,
              0.011146100237965584,
              0.9794290065765381,
              0.01882619969546795,
              0.9865509867668152,
              0.011146100237965584,
              0.9794290065765381,
              0.010832499712705612,
              0.9795929789543152,
              0.01882619969546795,
              0.9865509867668152,
              0.06427659839391708,
              9.074810236597841e-8,
              0.06433740258216858,
              0.00026062800316140056,
              0.07714170217514038,
              0,
              0.06433740258216858,
              0.00026062800316140056,
              0.07714170217514038,
              0.00026058300863951445,
              0.07714170217514038,
              0,
              0.07714170217514038,
              0.9997389912605286,
              0.06433740258216858,
              0.9997389912605286,
              0.07714170217514038,
              1,
              0.06433740258216858,
              0.9997389912605286,
              0.06427659839391708,
              1,
              0.07714170217514038,
              1,
              0.010832499712705612,
              0.020406700670719147,
              0.004892770200967789,
              0.02834489941596985,
              0.011146100237965584,
              0.020570699125528336,
              0.004892770200967789,
              0.02834489941596985,
              0.005234649870544672,
              0.02847139909863472,
              0.011146100237965584,
              0.020570699125528336,
              0.005234649870544672,
              0.9715290069580078,
              0.004892770200967789,
              0.971655011177063,
              0.011146100237965584,
              0.9794290065765381,
              0.004892770200967789,
              0.971655011177063,
              0.010832499712705612,
              0.9795929789543152,
              0.011146100237965584,
              0.9794290065765381,
              0.05173660069704056,
              0.0008823429816402495,
              0.05185690149664879,
              0.001138749998062849,
              0.06427659839391708,
              9.074810236597841e-8,
              0.05185690149664879,
              0.001138749998062849,
              0.06433740258216858,
              0.00026062800316140056,
              0.06427659839391708,
              9.074810236597841e-8,
              0.06433740258216858,
              0.9997389912605286,
              0.05185690149664879,
              0.9988610148429871,
              0.06427659839391708,
              1,
              0.05185690149664879,
              0.9988610148429871,
              0.05173660069704056,
              0.9991179704666138,
              0.06427659839391708,
              1,
              0.004892770200967789,
              0.02834489941596985,
              0.0012350799515843391,
              0.036958400160074234,
              0.005234649870544672,
              0.02847139909863472,
              0.0012350799515843391,
              0.036958400160074234,
              0.0015941399615257978,
              0.03704420104622841,
              0.005234649870544672,
              0.02847139909863472,
              0.0015941399615257978,
              0.9629560112953186,
              0.0012350799515843391,
              0.9630420207977295,
              0.005234649870544672,
              0.9715290069580078,
              0.0012350799515843391,
              0.9630420207977295,
              0.004892770200967789,
              0.971655011177063,
              0.005234649870544672,
              0.9715290069580078,
              0.028566399589180946,
              0.007738309912383556,
              0.01882619969546795,
              0.013448599725961685,
              0.02879609912633896,
              0.007962300442159176,
              0.01882619969546795,
              0.013448599725961685,
              0.019101999700069427,
              0.01364549994468689,
              0.02879609912633896,
              0.007962300442159176,
              0.019101999700069427,
              0.9863539934158325,
              0.01882619969546795,
              0.9865509867668152,
              0.02879609912633896,
              0.9920380115509033,
              0.01882619969546795,
              0.9865509867668152,
              0.028566399589180946,
              0.9922620058059692,
              0.02879609912633896,
              0.9920380115509033,
              0.0012350799515843391,
              0.036958400160074234,
              0,
              0.045916300266981125,
              0.0015941399615257978,
              0.03704420104622841,
              0,
              0.045916300266981125,
              0.00036484398879110813,
              0.045959699898958206,
              0.0015941399615257978,
              0.03704420104622841,
              0.00036484398879110813,
              0.954039990901947,
              0,
              0.9540839791297913,
              0.0015941399615257978,
              0.9629560112953186,
              0,
              0.9540839791297913,
              0.0012350799515843391,
              0.9630420207977295,
              0.0015941399615257978,
              0.9629560112953186,
              0.028566399589180946,
              0.007738309912383556,
              0.02879609912633896,
              0.007962300442159176,
              0.0396789014339447,
              0.0034951800480484962,
              0.02879609912633896,
              0.007962300442159176,
              0.03985600173473358,
              0.0037392498925328255,
              0.0396789014339447,
              0.0034951800480484962,
              0.03985600173473358,
              0.9962610006332397,
              0.02879609912633896,
              0.9920380115509033,
              0.0396789014339447,
              0.9965050220489502,
              0.02879609912633896,
              0.9920380115509033,
              0.028566399589180946,
              0.9922620058059692,
              0.0396789014339447,
              0.9965050220489502,
              0.07714170217514038,
              0.00026058300863951445,
              1,
              0.00026058300863951445,
              0.07714170217514038,
              0,
              1,
              0.00026058300863951445,
              1,
              0,
              0.07714170217514038,
              0,
              1,
              1,
              1,
              0.9997389912605286,
              0.07714170217514038,
              1,
              1,
              0.9997389912605286,
              0.07714170217514038,
              0.9997389912605286,
              0.07714170217514038,
              1,
              0.0008599749999120831,
              0.05509360134601593,
              0.0008599749999120831,
              0.04599040001630783,
              0.00036484398879110813,
              0.05509360134601593,
              0.0008599749999120831,
              0.04599040001630783,
              0.00036484398879110813,
              0.045959699898958206,
              0.00036484398879110813,
              0.05509360134601593,
              0.00036484398879110813,
              0.954039990901947,
              0.0008599749999120831,
              0.954010009765625,
              0.00036484398879110813,
              0.9449059963226318,
              0.0008599749999120831,
              0.954010009765625,
              0.0008599749999120831,
              0.9449059963226318,
              0.00036484398879110813,
              0.9449059963226318,
              0.0008599749999120831,
              0.04599040001630783,
              0.002073720097541809,
              0.0371566005051136,
              0.00036484398879110813,
              0.045959699898958206,
              0.002073720097541809,
              0.0371566005051136,
              0.0015941399615257978,
              0.03704420104622841,
              0.00036484398879110813,
              0.045959699898958206,
              0.0015941399615257978,
              0.9629560112953186,
              0.002073720097541809,
              0.9628430008888245,
              0.00036484398879110813,
              0.954039990901947,
              0.002073720097541809,
              0.9628430008888245,
              0.0008599749999120831,
              0.954010009765625,
              0.00036484398879110813,
              0.954039990901947,
              0.002073720097541809,
              0.0371566005051136,
              0.005690149962902069,
              0.02863370068371296,
              0.0015941399615257978,
              0.03704420104622841,
              0.005690149962902069,
              0.02863370068371296,
              0.005234649870544672,
              0.02847139909863472,
              0.0015941399615257978,
              0.03704420104622841,
              0.005234649870544672,
              0.9715290069580078,
              0.005690149962902069,
              0.9713659882545471,
              0.0015941399615257978,
              0.9629560112953186,
              0.005690149962902069,
              0.9713659882545471,
              0.002073720097541809,
              0.9628430008888245,
              0.0015941399615257978,
              0.9629560112953186,
              0.005690149962902069,
              0.02863370068371296,
              0.011557700112462044,
              0.020788200199604034,
              0.005234649870544672,
              0.02847139909863472,
              0.011557700112462044,
              0.020788200199604034,
              0.011146100237965584,
              0.020570699125528336,
              0.005234649870544672,
              0.02847139909863472,
              0.011146100237965584,
              0.9794290065765381,
              0.011557700112462044,
              0.9792119860649109,
              0.005234649870544672,
              0.9715290069580078,
              0.011557700112462044,
              0.9792119860649109,
              0.005690149962902069,
              0.9713659882545471,
              0.005234649870544672,
              0.9715290069580078,
              0.011557700112462044,
              0.020788200199604034,
              0.019478000700473785,
              0.013894299976527691,
              0.011146100237965584,
              0.020570699125528336,
              0.019478000700473785,
              0.013894299976527691,
              0.019101999700069427,
              0.01364549994468689,
              0.011146100237965584,
              0.020570699125528336,
              0.019101999700069427,
              0.9863539934158325,
              0.019478000700473785,
              0.9861059784889221,
              0.011146100237965584,
              0.9794290065765381,
              0.019478000700473785,
              0.9861059784889221,
              0.011557700112462044,
              0.9792119860649109,
              0.011146100237965584,
              0.9794290065765381,
              0.019478000700473785,
              0.013894299976527691,
              0.02910660021007061,
              0.008252969942986965,
              0.019101999700069427,
              0.01364549994468689,
              0.02910660021007061,
              0.008252969942986965,
              0.02879609912633896,
              0.007962300442159176,
              0.019101999700069427,
              0.01364549994468689,
              0.02879609912633896,
              0.9920380115509033,
              0.02910660021007061,
              0.9917470216751099,
              0.019101999700069427,
              0.9863539934158325,
              0.02910660021007061,
              0.9917470216751099,
              0.019478000700473785,
              0.9861059784889221,
              0.019101999700069427,
              0.9863539934158325,
              0.02910660021007061,
              0.008252969942986965,
              0.04007149860262871,
              0.004068439826369286,
              0.02879609912633896,
              0.007962300442159176,
              0.04007149860262871,
              0.004068439826369286,
              0.03985600173473358,
              0.0037392498925328255,
              0.02879609912633896,
              0.007962300442159176,
              0.03985600173473358,
              0.9962610006332397,
              0.04007149860262871,
              0.9959319829940796,
              0.02879609912633896,
              0.9920380115509033,
              0.04007149860262871,
              0.9959319829940796,
              0.02910660021007061,
              0.9917470216751099,
              0.02879609912633896,
              0.9920380115509033,
              0.04007149860262871,
              0.004068439826369286,
              0.05200380086898804,
              0.0014835300389677286,
              0.03985600173473358,
              0.0037392498925328255,
              0.05200380086898804,
              0.0014835300389677286,
              0.05185690149664879,
              0.001138749998062849,
              0.03985600173473358,
              0.0037392498925328255,
              0.05185690149664879,
              0.9988610148429871,
              0.05200380086898804,
              0.9985160231590271,
              0.03985600173473358,
              0.9962610006332397,
              0.05200380086898804,
              0.9985160231590271,
              0.04007149860262871,
              0.9959319829940796,
              0.03985600173473358,
              0.9962610006332397,
              0.05200380086898804,
              0.0014835300389677286,
              0.06440950185060501,
              0.0006123000057414174,
              0.05185690149664879,
              0.001138749998062849,
              0.06440950185060501,
              0.0006123000057414174,
              0.06433740258216858,
              0.00026062800316140056,
              0.05185690149664879,
              0.001138749998062849,
              0.06433740258216858,
              0.9997389912605286,
              0.06440950185060501,
              0.9993879795074463,
              0.05185690149664879,
              0.9988610148429871,
              0.06440950185060501,
              0.9993879795074463,
              0.05200380086898804,
              0.9985160231590271,
              0.05185690149664879,
              0.9988610148429871,
              0.06440950185060501,
              0.0006123000057414174,
              0.07714080065488815,
              0.0006141599733382463,
              0.06433740258216858,
              0.00026062800316140056,
              0.07714080065488815,
              0.0006141599733382463,
              0.07714170217514038,
              0.00026058300863951445,
              0.06433740258216858,
              0.00026062800316140056,
              0.07714170217514038,
              0.9997389912605286,
              0.07714080065488815,
              0.9993860125541687,
              0.06433740258216858,
              0.9997389912605286,
              0.07714080065488815,
              0.9993860125541687,
              0.06440950185060501,
              0.9993879795074463,
              0.06433740258216858,
              0.9997389912605286,
              0.07714080065488815,
              0.0006141599733382463,
              1,
              0.0006141599733382463,
              0.07714170217514038,
              0.00026058300863951445,
              1,
              0.0006141599733382463,
              1,
              0.00026058300863951445,
              0.07714170217514038,
              0.00026058300863951445,
              1,
              0.9997389912605286,
              1,
              0.9993860125541687,
              0.07714170217514038,
              0.9997389912605286,
              1,
              0.9993860125541687,
              0.07714080065488815,
              0.9993860125541687,
              0.07714170217514038,
              0.9997389912605286,
              0.06327860057353973,
              0.05509360134601593,
              0.07711009681224823,
              0.05509369820356369,
              0.06327860057353973,
              0.053213298320770264,
              0.06327860057353973,
              0.053213298320770264,
              0.07711009681224823,
              0.05509369820356369,
              0.0634377971291542,
              0.051806699484586716,
              0.0634377971291542,
              0.051806699484586716,
              0.07711009681224823,
              0.05509369820356369,
              0.06408960372209549,
              0.050217900425195694,
              0.06408960372209549,
              0.050217900425195694,
              0.07711009681224823,
              0.05509369820356369,
              0.06510569900274277,
              0.04882900044322014,
              0.06510569900274277,
              0.04882900044322014,
              0.07711009681224823,
              0.05509369820356369,
              0.06666990369558334,
              0.04746929928660393,
              0.06666990369558334,
              0.04746929928660393,
              0.07711009681224823,
              0.05509369820356369,
              0.06839320063591003,
              0.046488601714372635,
              0.06839320063591003,
              0.046488601714372635,
              0.07711009681224823,
              0.05509369820356369,
              0.07018859684467316,
              0.04582170024514198,
              0.07018859684467316,
              0.04582170024514198,
              0.07711009681224823,
              0.05509369820356369,
              0.07242579758167267,
              0.04534289985895157,
              0.07242579758167267,
              0.04534289985895157,
              0.07711009681224823,
              0.05509369820356369,
              0.07472269982099533,
              0.045194998383522034,
              0.07472269982099533,
              0.045194998383522034,
              0.07711009681224823,
              0.05509369820356369,
              0.07711009681224823,
              0.04521099850535393,
              0.07711009681224823,
              0.05509369820356369,
              1,
              0.05509369820356369,
              0.07711009681224823,
              0.04521099850535393,
              1,
              0.05509369820356369,
              1,
              0.04521099850535393,
              0.07711009681224823,
              0.04521099850535393,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0.07714080065488815,
              0.0006141599733382463,
              0.06440950185060501,
              0.0006123000057414174,
              0.07713919878005981,
              0.0017464900156483054,
              0.06440950185060501,
              0.0006123000057414174,
              0.06465990096330643,
              0.0017420899821445346,
              0.07713919878005981,
              0.0017464900156483054,
              0.07713919878005981,
              0.0017464900156483054,
              0.06465990096330643,
              0.0017420899821445346,
              0.07711009681224823,
              0.04521099850535393,
              0.06465990096330643,
              0.0017420899821445346,
              0.07472269982099533,
              0.045194998383522034,
              0.07711009681224823,
              0.04521099850535393,
              0.06440950185060501,
              0.0006123000057414174,
              0.05200380086898804,
              0.0014835300389677286,
              0.06465990096330643,
              0.0017420899821445346,
              0.05200380086898804,
              0.0014835300389677286,
              0.05250440165400505,
              0.0025935599114745855,
              0.06465990096330643,
              0.0017420899821445346,
              0.06465990096330643,
              0.0017420899821445346,
              0.05250440165400505,
              0.0025935599114745855,
              0.07472269982099533,
              0.045194998383522034,
              0.05250440165400505,
              0.0025935599114745855,
              0.07242579758167267,
              0.04534289985895157,
              0.07472269982099533,
              0.045194998383522034,
              0.05200380086898804,
              0.0014835300389677286,
              0.04007149860262871,
              0.004068439826369286,
              0.05250440165400505,
              0.0025935599114745855,
              0.04007149860262871,
              0.004068439826369286,
              0.04080849885940552,
              0.005126330070197582,
              0.05250440165400505,
              0.0025935599114745855,
              0.05250440165400505,
              0.0025935599114745855,
              0.04080849885940552,
              0.005126330070197582,
              0.07242579758167267,
              0.04534289985895157,
              0.04080849885940552,
              0.005126330070197582,
              0.07018859684467316,
              0.04582170024514198,
              0.07242579758167267,
              0.04534289985895157,
              0.04007149860262871,
              0.004068439826369286,
              0.02910660021007061,
              0.008252969942986965,
              0.04080849885940552,
              0.005126330070197582,
              0.02910660021007061,
              0.008252969942986965,
              0.03010289929807186,
              0.009208889678120613,
              0.04080849885940552,
              0.005126330070197582,
              0.04080849885940552,
              0.005126330070197582,
              0.03010289929807186,
              0.009208889678120613,
              0.07018859684467316,
              0.04582170024514198,
              0.03010289929807186,
              0.009208889678120613,
              0.06839320063591003,
              0.046488601714372635,
              0.07018859684467316,
              0.04582170024514198,
              0.02910660021007061,
              0.008252969942986965,
              0.019478000700473785,
              0.013894299976527691,
              0.03010289929807186,
              0.009208889678120613,
              0.019478000700473785,
              0.013894299976527691,
              0.020678099244832993,
              0.01472609955817461,
              0.03010289929807186,
              0.009208889678120613,
              0.03010289929807186,
              0.009208889678120613,
              0.020678099244832993,
              0.01472609955817461,
              0.06839320063591003,
              0.046488601714372635,
              0.020678099244832993,
              0.01472609955817461,
              0.06666990369558334,
              0.04746929928660393,
              0.06839320063591003,
              0.046488601714372635,
              0.019478000700473785,
              0.013894299976527691,
              0.011557700112462044,
              0.020788200199604034,
              0.020678099244832993,
              0.01472609955817461,
              0.011557700112462044,
              0.020788200199604034,
              0.012901700101792812,
              0.02149450033903122,
              0.020678099244832993,
              0.01472609955817461,
              0.020678099244832993,
              0.01472609955817461,
              0.012901700101792812,
              0.02149450033903122,
              0.06666990369558334,
              0.04746929928660393,
              0.012901700101792812,
              0.02149450033903122,
              0.06510569900274277,
              0.04882900044322014,
              0.06666990369558334,
              0.04746929928660393,
              0.011557700112462044,
              0.020788200199604034,
              0.005690149962902069,
              0.02863370068371296,
              0.012901700101792812,
              0.02149450033903122,
              0.005690149962902069,
              0.02863370068371296,
              0.007163949776440859,
              0.029171299189329147,
              0.012901700101792812,
              0.02149450033903122,
              0.012901700101792812,
              0.02149450033903122,
              0.007163949776440859,
              0.029171299189329147,
              0.06510569900274277,
              0.04882900044322014,
              0.007163949776440859,
              0.029171299189329147,
              0.06408960372209549,
              0.050217900425195694,
              0.06510569900274277,
              0.04882900044322014,
              0.005690149962902069,
              0.02863370068371296,
              0.002073720097541809,
              0.0371566005051136,
              0.007163949776440859,
              0.029171299189329147,
              0.002073720097541809,
              0.0371566005051136,
              0.003623540047556162,
              0.03752399981021881,
              0.007163949776440859,
              0.029171299189329147,
              0.007163949776440859,
              0.029171299189329147,
              0.003623540047556162,
              0.03752399981021881,
              0.06408960372209549,
              0.050217900425195694,
              0.003623540047556162,
              0.03752399981021881,
              0.0634377971291542,
              0.051806699484586716,
              0.06408960372209549,
              0.050217900425195694,
              0.002073720097541809,
              0.0371566005051136,
              0.0008599749999120831,
              0.04599040001630783,
              0.003623540047556162,
              0.03752399981021881,
              0.0008599749999120831,
              0.04599040001630783,
              0.0024448600597679615,
              0.046142399311065674,
              0.003623540047556162,
              0.03752399981021881,
              0.003623540047556162,
              0.03752399981021881,
              0.0024448600597679615,
              0.046142399311065674,
              0.0634377971291542,
              0.051806699484586716,
              0.0024448600597679615,
              0.046142399311065674,
              0.06327860057353973,
              0.053213298320770264,
              0.0634377971291542,
              0.051806699484586716,
              0.0008599749999120831,
              0.04599040001630783,
              0.0008599749999120831,
              0.05509360134601593,
              0.0024448600597679615,
              0.046142399311065674,
              0.0008599749999120831,
              0.05509360134601593,
              0.0024448600597679615,
              0.05509360134601593,
              0.0024448600597679615,
              0.046142399311065674,
              0.0024448600597679615,
              0.046142399311065674,
              0.0024448600597679615,
              0.05509360134601593,
              0.06327860057353973,
              0.053213298320770264,
              0.0024448600597679615,
              0.05509360134601593,
              0.06327860057353973,
              0.05509360134601593,
              0.06327860057353973,
              0.053213298320770264,
              0.07711009681224823,
              0.04521099850535393,
              1,
              0.04521099850535393,
              0.07713919878005981,
              0.0017464900156483054,
              1,
              0.04521099850535393,
              1,
              0.0017464900156483054,
              0.07713919878005981,
              0.0017464900156483054,
              0.07713919878005981,
              0.0017464900156483054,
              1,
              0.0017464900156483054,
              0.07714080065488815,
              0.0006141599733382463,
              1,
              0.0017464900156483054,
              1,
              0.0006141599733382463,
              0.07714080065488815,
              0.0006141599733382463,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0.13998399674892426,
              0.00036484398879110813,
              0.13998399674892426,
              0,
              0.09753890335559845,
              0.00036484398879110813,
              0.13998399674892426,
              0.00036484398879110813,
              0.09753890335559845,
              0,
              0.09753890335559845,
              0.00036484398879110813,
              0.9024609923362732,
              0.00036484398879110813,
              0.8600159883499146,
              0,
              0.9024609923362732,
              0.00036484398879110813,
              0.8600159883499146,
              0,
              0.8600159883499146,
              0,
              0.9024609923362732,
              0,
              0.09753890335559845,
              0.00036484398879110813,
              0.09753890335559845,
              0,
              0.05509360134601593,
              0.00036484398879110813,
              0.09753890335559845,
              0.00036484398879110813,
              0.05509360134601593,
              0,
              0.05509360134601593,
              0.00036484398879110813,
              0.9449059963226318,
              0.00036484398879110813,
              0.9024609923362732,
              0,
              0.9449059963226318,
              0.00036484398879110813,
              0.9024609923362732,
              0,
              0.9024609923362732,
              0,
              0.9449059963226318,
              0.00036484398879110813,
              0.13998399674892426,
              0.0008599749999120831,
              0.13998399674892426,
              0.00036484398879110813,
              0.09753890335559845,
              0.0008599749999120831,
              0.13998399674892426,
              0.0008599749999120831,
              0.09753890335559845,
              0.00036484398879110813,
              0.09753890335559845,
              0.0008599749999120831,
              0.9024609923362732,
              0.0008599749999120831,
              0.8600159883499146,
              0.00036484398879110813,
              0.9024609923362732,
              0.0008599749999120831,
              0.8600159883499146,
              0.00036484398879110813,
              0.8600159883499146,
              0.00036484398879110813,
              0.9024609923362732,
              0.00036484398879110813,
              0.09753890335559845,
              0.0008599749999120831,
              0.09753890335559845,
              0.00036484398879110813,
              0.05509360134601593,
              0.0008599749999120831,
              0.09753890335559845,
              0.0008599749999120831,
              0.05509360134601593,
              0.00036484398879110813,
              0.05509360134601593,
              0.0008599749999120831,
              0.9449059963226318,
              0.0008599749999120831,
              0.9024609923362732,
              0.00036484398879110813,
              0.9449059963226318,
              0.0008599749999120831,
              0.9024609923362732,
              0.00036484398879110813,
              0.9024609923362732,
              0.00036484398879110813,
              0.9449059963226318,
              0.07711009681224823,
              0.05509369820356369,
              0.06327860057353973,
              0.05509360134601593,
              0.07711009681224823,
              0.09753890335559845,
              0.06327860057353973,
              0.05509360134601593,
              0.06327860057353973,
              0.09753890335559845,
              0.07711009681224823,
              0.09753890335559845,
              0.07711009681224823,
              0.09753890335559845,
              0.06327860057353973,
              0.09753890335559845,
              0.07711009681224823,
              0.13998399674892426,
              0.06327860057353973,
              0.09753890335559845,
              0.06327860057353973,
              0.13998399674892426,
              0.07711009681224823,
              0.13998399674892426,
              1,
              0.05509369820356369,
              0.07711009681224823,
              0.05509369820356369,
              1,
              0.09753890335559845,
              0.07711009681224823,
              0.05509369820356369,
              0.07711009681224823,
              0.09753890335559845,
              1,
              0.09753890335559845,
              1,
              0.09753890335559845,
              0.07711009681224823,
              0.09753890335559845,
              1,
              0.13998399674892426,
              0.07711009681224823,
              0.09753890335559845,
              0.07711009681224823,
              0.13998399674892426,
              1,
              0.13998399674892426,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0.0024448600597679615,
              0.05509360134601593,
              0.0008599749999120831,
              0.05509360134601593,
              0.0024448600597679615,
              0.09753890335559845,
              0.0008599749999120831,
              0.05509360134601593,
              0.0008599749999120831,
              0.09753890335559845,
              0.0024448600597679615,
              0.09753890335559845,
              0.0024448600597679615,
              0.09753890335559845,
              0.0008599749999120831,
              0.09753890335559845,
              0.0024448600597679615,
              0.13998399674892426,
              0.0008599749999120831,
              0.09753890335559845,
              0.0008599749999120831,
              0.13998399674892426,
              0.0024448600597679615,
              0.13998399674892426,
              0.06327860057353973,
              0.05509360134601593,
              0.0024448600597679615,
              0.05509360134601593,
              0.06327860057353973,
              0.09753890335559845,
              0.0024448600597679615,
              0.05509360134601593,
              0.0024448600597679615,
              0.09753890335559845,
              0.06327860057353973,
              0.09753890335559845,
              0.06327860057353973,
              0.09753890335559845,
              0.0024448600597679615,
              0.09753890335559845,
              0.06327860057353973,
              0.13998399674892426,
              0.0024448600597679615,
              0.09753890335559845,
              0.0024448600597679615,
              0.13998399674892426,
              0.06327860057353973,
              0.13998399674892426,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0.23014900088310242,
              0,
              0.3203130066394806,
              0.00036484398879110813,
              0.23014900088310242,
              0,
              0.3203130066394806,
              0.00036484398879110813,
              0.3203130066394806,
              0.00036484398879110813,
              0.23014900088310242,
              0.00036484398879110813,
              0.23014900088310242,
              0.00036484398879110813,
              0.3203130066394806,
              0.0008599749999120831,
              0.23014900088310242,
              0.00036484398879110813,
              0.3203130066394806,
              0.0008599749999120831,
              0.3203130066394806,
              0.0008599749999120831,
              0.23014900088310242,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0.00036484398879110813,
              0.3203130066394806,
              0.00036484398879110813,
              0.41047701239585876,
              0.0008599749999120831,
              0.3203130066394806,
              0.00036484398879110813,
              0.41047701239585876,
              0.0008599749999120831,
              0.41047701239585876,
              0.0008599749999120831,
              0.3203130066394806,
              0,
              0.3203130066394806,
              0,
              0.41047701239585876,
              0.00036484398879110813,
              0.3203130066394806,
              0,
              0.41047701239585876,
              0.00036484398879110813,
              0.41047701239585876,
              0.00036484398879110813,
              0.3203130066394806,
              0,
              0.41047701239585876,
              0,
              0.5006420016288757,
              0.00036484398879110813,
              0.41047701239585876,
              0,
              0.5006420016288757,
              0.00036484398879110813,
              0.5006420016288757,
              0.00036484398879110813,
              0.41047701239585876,
              0.00036484398879110813,
              0.41047701239585876,
              0.00036484398879110813,
              0.5006420016288757,
              0.0008599749999120831,
              0.41047701239585876,
              0.00036484398879110813,
              0.5006420016288757,
              0.0008599749999120831,
              0.5006420016288757,
              0.0008599749999120831,
              0.41047701239585876,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0.00036484398879110813,
              0.5006420016288757,
              0.00036484398879110813,
              0.5908060073852539,
              0.0008599749999120831,
              0.5006420016288757,
              0.00036484398879110813,
              0.5908060073852539,
              0.0008599749999120831,
              0.5908060073852539,
              0.0008599749999120831,
              0.5006420016288757,
              0,
              0.5006420016288757,
              0,
              0.5908060073852539,
              0.00036484398879110813,
              0.5006420016288757,
              0,
              0.5908060073852539,
              0.00036484398879110813,
              0.5908060073852539,
              0.00036484398879110813,
              0.5006420016288757,
              0,
              0.5908060073852539,
              0,
              0.6809700131416321,
              0.00036484398879110813,
              0.5908060073852539,
              0,
              0.6809700131416321,
              0.00036484398879110813,
              0.6809700131416321,
              0.00036484398879110813,
              0.5908060073852539,
              0.00036484398879110813,
              0.5908060073852539,
              0.00036484398879110813,
              0.6809700131416321,
              0.0008599749999120831,
              0.5908060073852539,
              0.00036484398879110813,
              0.6809700131416321,
              0.0008599749999120831,
              0.6809700131416321,
              0.0008599749999120831,
              0.5908060073852539,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0.00036484398879110813,
              0.6809700131416321,
              0.00036484398879110813,
              0.7711340188980103,
              0.0008599749999120831,
              0.6809700131416321,
              0.00036484398879110813,
              0.7711340188980103,
              0.0008599749999120831,
              0.7711340188980103,
              0.0008599749999120831,
              0.6809700131416321,
              0,
              0.6809700131416321,
              0,
              0.7711340188980103,
              0.00036484398879110813,
              0.6809700131416321,
              0,
              0.7711340188980103,
              0.00036484398879110813,
              0.7711340188980103,
              0.00036484398879110813,
              0.6809700131416321,
              0,
              0.7711340188980103,
              0,
              0.8600159883499146,
              0.00036484398879110813,
              0.7711340188980103,
              0,
              0.8600159883499146,
              0.00036484398879110813,
              0.8600159883499146,
              0.00036484398879110813,
              0.7711340188980103,
              0.00036484398879110813,
              0.7711340188980103,
              0.00036484398879110813,
              0.8600159883499146,
              0.0008599749999120831,
              0.7711340188980103,
              0.00036484398879110813,
              0.8600159883499146,
              0.0008599749999120831,
              0.8600159883499146,
              0.0008599749999120831,
              0.7711340188980103,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0.0008599749999120831,
              0.13998399674892426,
              0.00036484398879110813,
              0.13998399674892426,
              0.0008600769797340035,
              0.18957500159740448,
              0.00036484398879110813,
              0.13998399674892426,
              0.00036484398879110813,
              0.18957500159740448,
              0.0008600769797340035,
              0.18957500159740448,
              0.0008600769797340035,
              0.18957500159740448,
              0.00036484398879110813,
              0.18957500159740448,
              0.0008599749999120831,
              0.23014900088310242,
              0.00036484398879110813,
              0.18957500159740448,
              0.00036484398879110813,
              0.23014900088310242,
              0.0008599749999120831,
              0.23014900088310242,
              0.00036484398879110813,
              0.13998399674892426,
              0,
              0.13998399674892426,
              0.00036484398879110813,
              0.18957500159740448,
              0,
              0.13998399674892426,
              1.0162799668478328e-7,
              0.18957500159740448,
              0.00036484398879110813,
              0.18957500159740448,
              0.00036484398879110813,
              0.18957500159740448,
              1.0162799668478328e-7,
              0.18957500159740448,
              0.00036484398879110813,
              0.23014900088310242,
              1.0162799668478328e-7,
              0.18957500159740448,
              0,
              0.23014900088310242,
              0.00036484398879110813,
              0.23014900088310242,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0,
              0,
              0,
              1,
              1,
              0,
              0,
              1,
              1,
              1,
              1,
              0,
              0.7251999974250793,
              0.1938759982585907,
              0.7251350283622742,
              0.1923390030860901,
              0.7251859903335571,
              0.19402800500392914,
              0.7251350283622742,
              0.1923390030860901,
              0.725121021270752,
              0.19249999523162842,
              0.7251859903335571,
              0.19402800500392914,
              0.7253249883651733,
              0.19749200344085693,
              0.7252609729766846,
              0.19559800624847412,
              0.7253100275993347,
              0.19762100279331207,
              0.7252609729766846,
              0.19559800624847412,
              0.725246012210846,
              0.1957390010356903,
              0.7253100275993347,
              0.19762100279331207,
              0.7253860235214233,
              0.1995439976453781,
              0.7253249883651733,
              0.19749200344085693,
              0.7253710031509399,
              0.19965900480747223,
              0.7253249883651733,
              0.19749200344085693,
              0.7253100275993347,
              0.19762100279331207,
              0.7253710031509399,
              0.19965900480747223,
              0.7254390120506287,
              0.20173899829387665,
              0.7253860235214233,
              0.1995439976453781,
              0.7254239916801453,
              0.20184099674224854,
              0.7253860235214233,
              0.1995439976453781,
              0.7253710031509399,
              0.19965900480747223,
              0.7254239916801453,
              0.20184099674224854,
              0.7254949808120728,
              0.2040649950504303,
              0.7254390120506287,
              0.20173899829387665,
              0.7254800200462341,
              0.20415200293064117,
              0.7254390120506287,
              0.20173899829387665,
              0.7254239916801453,
              0.20184099674224854,
              0.7254800200462341,
              0.20415200293064117,
              0.7256150245666504,
              0.20905299484729767,
              0.7255539894104004,
              0.20650799572467804,
              0.7256010174751282,
              0.20910799503326416,
              0.7255539894104004,
              0.20650799572467804,
              0.7255390286445618,
              0.20657899975776672,
              0.7256010174751282,
              0.20910799503326416,
              0.7259460091590881,
              0.22284600138664246,
              0.7258780002593994,
              0.21999099850654602,
              0.7259320020675659,
              0.22281399369239807,
              0.7258780002593994,
              0.21999099850654602,
              0.7258639931678772,
              0.219977006316185,
              0.7259320020675659,
              0.22281399369239807,
              0.7260149717330933,
              0.22572100162506104,
              0.7259460091590881,
              0.22284600138664246,
              0.7260010242462158,
              0.22567200660705566,
              0.7259460091590881,
              0.22284600138664246,
              0.7259320020675659,
              0.22281399369239807,
              0.7260010242462158,
              0.22567200660705566,
              0.7260839939117432,
              0.2286040037870407,
              0.7260149717330933,
              0.22572100162506104,
              0.726069986820221,
              0.22853699326515198,
              0.7260149717330933,
              0.22572100162506104,
              0.7260010242462158,
              0.22567200660705566,
              0.726069986820221,
              0.22853699326515198,
              0.7246969938278198,
              0.1951570063829422,
              0.7247589826583862,
              0.1953279972076416,
              0.7246840000152588,
              0.19497300684452057,
              0.7247589826583862,
              0.1953279972076416,
              0.7247470021247864,
              0.19514399766921997,
              0.7246840000152588,
              0.19497300684452057,
              0.7248200178146362,
              0.19568899273872375,
              0.7248780131340027,
              0.19623200595378876,
              0.7248079776763916,
              0.1955060064792633,
              0.7248780131340027,
              0.19623200595378876,
              0.7248659729957581,
              0.19605199992656708,
              0.7248079776763916,
              0.1955060064792633,
              0.7249860167503357,
              0.19781999289989471,
              0.7250369787216187,
              0.1988459974527359,
              0.7249760031700134,
              0.1976509988307953,
              0.7250369787216187,
              0.1988459974527359,
              0.7250270247459412,
              0.19868500530719757,
              0.7249760031700134,
              0.1976509988307953,
              0.7259500026702881,
              0.22360000014305115,
              0.7260149717330933,
              0.2257159948348999,
              0.7259389758110046,
              0.2236499935388565,
              0.7260149717330933,
              0.2257159948348999,
              0.7260040044784546,
              0.22578300535678864,
              0.7259389758110046,
              0.2236499935388565,
              0.7258840203285217,
              0.22148799896240234,
              0.7259500026702881,
              0.22360000014305115,
              0.7258729934692383,
              0.22152000665664673,
              0.7259500026702881,
              0.22360000014305115,
              0.7259389758110046,
              0.2236499935388565,
              0.7258729934692383,
              0.22152000665664673,
              0.7255380153656006,
              0.211326003074646,
              0.7256079912185669,
              0.21327200531959534,
              0.7255280017852783,
              0.2112710028886795,
              0.7256079912185669,
              0.21327200531959534,
              0.7255970239639282,
              0.21323400735855103,
              0.7255280017852783,
              0.2112710028886795,
              0.7254689931869507,
              0.20944300293922424,
              0.7255380153656006,
              0.211326003074646,
              0.7254589796066284,
              0.20937199890613556,
              0.7255380153656006,
              0.211326003074646,
              0.7255280017852783,
              0.2112710028886795,
              0.7254589796066284,
              0.20937199890613556,
              0.7253350019454956,
              0.2059040069580078,
              0.7254019975662231,
              0.20763200521469116,
              0.7253249883651733,
              0.2058030068874359,
              0.7254019975662231,
              0.20763200521469116,
              0.7253909707069397,
              0.20754599571228027,
              0.7253249883651733,
              0.2058030068874359,
              0.7250900268554688,
              0.20001399517059326,
              0.7251480221748352,
              0.20131400227546692,
              0.7250810265541077,
              0.199863001704216,
              0.7251480221748352,
              0.20131400227546692,
              0.7251380085945129,
              0.20117299258708954,
              0.7250810265541077,
              0.199863001704216,
              0.7250369787216187,
              0.1988459974527359,
              0.7250900268554688,
              0.20001399517059326,
              0.7250270247459412,
              0.19868500530719757,
              0.7250900268554688,
              0.20001399517059326,
              0.7250810265541077,
              0.199863001704216,
              0.7250270247459412,
              0.19868500530719757,
              0.7260839939117432,
              0.2286040037870407,
              0.726069986820221,
              0.22853699326515198,
              0.7261520028114319,
              0.23144599795341492,
              0.726069986820221,
              0.22853699326515198,
              0.7261379957199097,
              0.2313610017299652,
              0.7261520028114319,
              0.23144599795341492,
              0.7246840000152588,
              0.19497300684452057,
              0.72461998462677,
              0.19490200281143188,
              0.7246969938278198,
              0.1951570063829422,
              0.72461998462677,
              0.19490200281143188,
              0.7246339917182922,
              0.1950870007276535,
              0.7246969938278198,
              0.1951570063829422,
              0.7246699929237366,
              0.18776999413967133,
              0.7247549891471863,
              0.18790900707244873,
              0.7247070074081421,
              0.1877090036869049,
              0.7247549891471863,
              0.18790900707244873,
              0.7247920036315918,
              0.18784800171852112,
              0.7247070074081421,
              0.1877090036869049,
              0.7247549891471863,
              0.18790900707244873,
              0.7248380184173584,
              0.1883150041103363,
              0.7247920036315918,
              0.18784800171852112,
              0.7248380184173584,
              0.1883150041103363,
              0.7248749732971191,
              0.1882539987564087,
              0.7247920036315918,
              0.18784800171852112,
              0.7248380184173584,
              0.1883150041103363,
              0.7249169945716858,
              0.1889750063419342,
              0.7248749732971191,
              0.1882539987564087,
              0.7249169945716858,
              0.1889750063419342,
              0.7249540090560913,
              0.18891499936580658,
              0.7248749732971191,
              0.1882539987564087,
              0.7249169945716858,
              0.1889750063419342,
              0.7249940037727356,
              0.18987399339675903,
              0.7249540090560913,
              0.18891499936580658,
              0.7249940037727356,
              0.18987399339675903,
              0.7250310182571411,
              0.18981599807739258,
              0.7249540090560913,
              0.18891499936580658,
              0.7249940037727356,
              0.18987399339675903,
              0.7250660061836243,
              0.19099999964237213,
              0.7250310182571411,
              0.18981599807739258,
              0.7250660061836243,
              0.19099999964237213,
              0.7251039743423462,
              0.19094400107860565,
              0.7250310182571411,
              0.18981599807739258,
              0.7250660061836243,
              0.19099999964237213,
              0.7251350283622742,
              0.1923390030860901,
              0.7251039743423462,
              0.19094400107860565,
              0.7251350283622742,
              0.1923390030860901,
              0.7251729965209961,
              0.19228500127792358,
              0.7251039743423462,
              0.19094400107860565,
              0.7251999974250793,
              0.1938759982585907,
              0.7252609729766846,
              0.19559800624847412,
              0.725238025188446,
              0.19382600486278534,
              0.7252609729766846,
              0.19559800624847412,
              0.7252990007400513,
              0.19555099308490753,
              0.725238025188446,
              0.19382600486278534,
              0.7254949808120728,
              0.2040649950504303,
              0.7255539894104004,
              0.20650799572467804,
              0.7255319952964783,
              0.20403599739074707,
              0.7255539894104004,
              0.20650799572467804,
              0.7255910038948059,
              0.20648400485515594,
              0.7255319952964783,
              0.20403599739074707,
              0.7256150245666504,
              0.20905299484729767,
              0.725678026676178,
              0.21168799698352814,
              0.7256519794464111,
              0.2090349942445755,
              0.725678026676178,
              0.21168799698352814,
              0.7257149815559387,
              0.2116750031709671,
              0.7256519794464111,
              0.2090349942445755,
              0.725678026676178,
              0.21168799698352814,
              0.7257429957389832,
              0.21439799666404724,
              0.7257149815559387,
              0.2116750031709671,
              0.7257429957389832,
              0.21439799666404724,
              0.7257800102233887,
              0.21439099311828613,
              0.7257149815559387,
              0.2116750031709671,
              0.7257429957389832,
              0.21439799666404724,
              0.7258099913597107,
              0.21717000007629395,
              0.7257800102233887,
              0.21439099311828613,
              0.7258099913597107,
              0.21717000007629395,
              0.7258470058441162,
              0.21716900169849396,
              0.7257800102233887,
              0.21439099311828613,
              0.7258099913597107,
              0.21717000007629395,
              0.7258780002593994,
              0.21999099850654602,
              0.7258470058441162,
              0.21716900169849396,
              0.7258780002593994,
              0.21999099850654602,
              0.7259150147438049,
              0.21999500691890717,
              0.7258470058441162,
              0.21716900169849396,
              0.7248200178146362,
              0.19568899273872375,
              0.7247589826583862,
              0.1953279972076416,
              0.7248560190200806,
              0.19575099647045135,
              0.7247589826583862,
              0.1953279972076416,
              0.7247959971427917,
              0.1953900009393692,
              0.7248560190200806,
              0.19575099647045135,
              0.7249330282211304,
              0.19694499671459198,
              0.7248780131340027,
              0.19623200595378876,
              0.7249699831008911,
              0.19700400531291962,
              0.7248780131340027,
              0.19623200595378876,
              0.724914014339447,
              0.1962919980287552,
              0.7249699831008911,
              0.19700400531291962,
              0.7249860167503357,
              0.19781999289989471,
              0.7249330282211304,
              0.19694499671459198,
              0.72502201795578,
              0.1978760063648224,
              0.7249330282211304,
              0.19694499671459198,
              0.7249699831008911,
              0.19700400531291962,
              0.72502201795578,
              0.1978760063648224,
              0.7258840203285217,
              0.22148799896240234,
              0.725816011428833,
              0.21938900649547577,
              0.7259200215339661,
              0.22147700190544128,
              0.725816011428833,
              0.21938900649547577,
              0.7258520126342773,
              0.21938399970531464,
              0.7259200215339661,
              0.22147700190544128,
              0.725816011428833,
              0.21938900649547577,
              0.7257469892501831,
              0.2173140048980713,
              0.7258520126342773,
              0.21938399970531464,
              0.7257469892501831,
              0.2173140048980713,
              0.7257829904556274,
              0.21731500327587128,
              0.7258520126342773,
              0.21938399970531464,
              0.7257469892501831,
              0.2173140048980713,
              0.7256770133972168,
              0.21527099609375,
              0.7257829904556274,
              0.21731500327587128,
              0.7256770133972168,
              0.21527099609375,
              0.7257140278816223,
              0.2152779996395111,
              0.7257829904556274,
              0.21731500327587128,
              0.7256770133972168,
              0.21527099609375,
              0.7256079912185669,
              0.21327200531959534,
              0.7257140278816223,
              0.2152779996395111,
              0.7256079912185669,
              0.21327200531959534,
              0.7256439924240112,
              0.21328499913215637,
              0.7257140278816223,
              0.2152779996395111,
              0.7254689931869507,
              0.20944300293922424,
              0.7254019975662231,
              0.20763200521469116,
              0.7255060076713562,
              0.20946699380874634,
              0.7254019975662231,
              0.20763200521469116,
              0.7254379987716675,
              0.2076610028743744,
              0.7255060076713562,
              0.20946699380874634,
              0.7253350019454956,
              0.2059040069580078,
              0.7252699732780457,
              0.20426900684833527,
              0.7253710031509399,
              0.20593799650669098,
              0.7252699732780457,
              0.20426900684833527,
              0.72530597448349,
              0.20430700480937958,
              0.7253710031509399,
              0.20593799650669098,
              0.7252699732780457,
              0.20426900684833527,
              0.7252079844474792,
              0.20273500680923462,
              0.72530597448349,
              0.20430700480937958,
              0.7252079844474792,
              0.20273500680923462,
              0.7252439856529236,
              0.20277799665927887,
              0.72530597448349,
              0.20430700480937958,
              0.7252079844474792,
              0.20273500680923462,
              0.7251480221748352,
              0.20131400227546692,
              0.7252439856529236,
              0.20277799665927887,
              0.7251480221748352,
              0.20131400227546692,
              0.7251830101013184,
              0.20136100053787231,
              0.7252439856529236,
              0.20277799665927887,
              0.7260509729385376,
              0.22569400072097778,
              0.7261009812355042,
              0.22777099907398224,
              0.7260149717330933,
              0.2257159948348999,
              0.7261009812355042,
              0.22777099907398224,
              0.7260649800300598,
              0.22779999673366547,
              0.7260149717330933,
              0.2257159948348999,
              0.7246699929237366,
              0.18776999413967133,
              0.7247070074081421,
              0.1877090036869049,
              0.7245839834213257,
              0.18776999413967133,
              0.7247070074081421,
              0.1877090036869049,
              0.7246209979057312,
              0.1877090036869049,
              0.7245839834213257,
              0.18776999413967133,
              0.725238025188446,
              0.19382600486278534,
              0.7251729965209961,
              0.19228500127792358,
              0.7251999974250793,
              0.1938759982585907,
              0.7251729965209961,
              0.19228500127792358,
              0.7251350283622742,
              0.1923390030860901,
              0.7251999974250793,
              0.1938759982585907,
              0.7253620028495789,
              0.1974489986896515,
              0.7252990007400513,
              0.19555099308490753,
              0.7253249883651733,
              0.19749200344085693,
              0.7252990007400513,
              0.19555099308490753,
              0.7252609729766846,
              0.19559800624847412,
              0.7253249883651733,
              0.19749200344085693,
              0.7254239916801453,
              0.1995050013065338,
              0.7253620028495789,
              0.1974489986896515,
              0.7253860235214233,
              0.1995439976453781,
              0.7253620028495789,
              0.1974489986896515,
              0.7253249883651733,
              0.19749200344085693,
              0.7253860235214233,
              0.1995439976453781,
              0.7254760265350342,
              0.2017049938440323,
              0.7254239916801453,
              0.1995050013065338,
              0.7254390120506287,
              0.20173899829387665,
              0.7254239916801453,
              0.1995050013065338,
              0.7253860235214233,
              0.1995439976453781,
              0.7254390120506287,
              0.20173899829387665,
              0.7255319952964783,
              0.20403599739074707,
              0.7254760265350342,
              0.2017049938440323,
              0.7254949808120728,
              0.2040649950504303,
              0.7254760265350342,
              0.2017049938440323,
              0.7254390120506287,
              0.20173899829387665,
              0.7254949808120728,
              0.2040649950504303,
              0.7256519794464111,
              0.2090349942445755,
              0.7255910038948059,
              0.20648400485515594,
              0.7256150245666504,
              0.20905299484729767,
              0.7255910038948059,
              0.20648400485515594,
              0.7255539894104004,
              0.20650799572467804,
              0.7256150245666504,
              0.20905299484729767,
              0.7259830236434937,
              0.22285600006580353,
              0.7259150147438049,
              0.21999500691890717,
              0.7259460091590881,
              0.22284600138664246,
              0.7259150147438049,
              0.21999500691890717,
              0.7258780002593994,
              0.21999099850654602,
              0.7259460091590881,
              0.22284600138664246,
              0.7260519862174988,
              0.22573800384998322,
              0.7259830236434937,
              0.22285600006580353,
              0.7260149717330933,
              0.22572100162506104,
              0.7259830236434937,
              0.22285600006580353,
              0.7259460091590881,
              0.22284600138664246,
              0.7260149717330933,
              0.22572100162506104,
              0.7261210083961487,
              0.22862599790096283,
              0.7260519862174988,
              0.22573800384998322,
              0.7260839939117432,
              0.2286040037870407,
              0.7260519862174988,
              0.22573800384998322,
              0.7260149717330933,
              0.22572100162506104,
              0.7260839939117432,
              0.2286040037870407,
              0.7247340083122253,
              0.1952189952135086,
              0.7247959971427917,
              0.1953900009393692,
              0.7246969938278198,
              0.1951570063829422,
              0.7247959971427917,
              0.1953900009393692,
              0.7247589826583862,
              0.1953279972076416,
              0.7246969938278198,
              0.1951570063829422,
              0.7248560190200806,
              0.19575099647045135,
              0.724914014339447,
              0.1962919980287552,
              0.7248200178146362,
              0.19568899273872375,
              0.724914014339447,
              0.1962919980287552,
              0.7248780131340027,
              0.19623200595378876,
              0.7248200178146362,
              0.19568899273872375,
              0.72502201795578,
              0.1978760063648224,
              0.7250720262527466,
              0.1988999992609024,
              0.7249860167503357,
              0.19781999289989471,
              0.7250720262527466,
              0.1988999992609024,
              0.7250369787216187,
              0.1988459974527359,
              0.7249860167503357,
              0.19781999289989471,
              0.7259870171546936,
              0.22358399629592896,
              0.7260509729385376,
              0.22569400072097778,
              0.7259500026702881,
              0.22360000014305115,
              0.7260509729385376,
              0.22569400072097778,
              0.7260149717330933,
              0.2257159948348999,
              0.7259500026702881,
              0.22360000014305115,
              0.7259200215339661,
              0.22147700190544128,
              0.7259870171546936,
              0.22358399629592896,
              0.7258840203285217,
              0.22148799896240234,
              0.7259870171546936,
              0.22358399629592896,
              0.7259500026702881,
              0.22360000014305115,
              0.7258840203285217,
              0.22148799896240234,
              0.7255740165710449,
              0.21134500205516815,
              0.7256439924240112,
              0.21328499913215637,
              0.7255380153656006,
              0.211326003074646,
              0.7256439924240112,
              0.21328499913215637,
              0.7256079912185669,
              0.21327200531959534,
              0.7255380153656006,
              0.211326003074646,
              0.7255060076713562,
              0.20946699380874634,
              0.7255740165710449,
              0.21134500205516815,
              0.7254689931869507,
              0.20944300293922424,
              0.7255740165710449,
              0.21134500205516815,
              0.7255380153656006,
              0.211326003074646,
              0.7254689931869507,
              0.20944300293922424,
              0.7253710031509399,
              0.20593799650669098,
              0.7254379987716675,
              0.2076610028743744,
              0.7253350019454956,
              0.2059040069580078,
              0.7254379987716675,
              0.2076610028743744,
              0.7254019975662231,
              0.20763200521469116,
              0.7253350019454956,
              0.2059040069580078,
              0.7251260280609131,
              0.2000650018453598,
              0.7251830101013184,
              0.20136100053787231,
              0.7250900268554688,
              0.20001399517059326,
              0.7251830101013184,
              0.20136100053787231,
              0.7251480221748352,
              0.20131400227546692,
              0.7250900268554688,
              0.20001399517059326,
              0.7250720262527466,
              0.1988999992609024,
              0.7251260280609131,
              0.2000650018453598,
              0.7250369787216187,
              0.1988459974527359,
              0.7251260280609131,
              0.2000650018453598,
              0.7250900268554688,
              0.20001399517059326,
              0.7250369787216187,
              0.1988459974527359,
              0.7261210083961487,
              0.22862599790096283,
              0.7260839939117432,
              0.2286040037870407,
              0.7261899709701538,
              0.23147399723529816,
              0.7260839939117432,
              0.2286040037870407,
              0.7261520028114319,
              0.23144599795341492,
              0.7261899709701538,
              0.23147399723529816,
              0.7246969938278198,
              0.1951570063829422,
              0.7246339917182922,
              0.1950870007276535,
              0.7247340083122253,
              0.1952189952135086,
              0.7246339917182922,
              0.1950870007276535,
              0.7246710062026978,
              0.19514800608158112,
              0.7247340083122253,
              0.1952189952135086,
              0.7246590256690979,
              0.18795299530029297,
              0.7247430086135864,
              0.18809199333190918,
              0.7246699929237366,
              0.18776999413967133,
              0.7247430086135864,
              0.18809199333190918,
              0.7247549891471863,
              0.18790900707244873,
              0.7246699929237366,
              0.18776999413967133,
              0.7247430086135864,
              0.18809199333190918,
              0.7248250246047974,
              0.18849700689315796,
              0.7247549891471863,
              0.18790900707244873,
              0.7248250246047974,
              0.18849700689315796,
              0.7248380184173584,
              0.1883150041103363,
              0.7247549891471863,
              0.18790900707244873,
              0.7248250246047974,
              0.18849700689315796,
              0.7249040007591248,
              0.1891549974679947,
              0.7248380184173584,
              0.1883150041103363,
              0.7249040007591248,
              0.1891549974679947,
              0.7249169945716858,
              0.1889750063419342,
              0.7248380184173584,
              0.1883150041103363,
              0.7249040007591248,
              0.1891549974679947,
              0.7249799966812134,
              0.19005000591278076,
              0.7249169945716858,
              0.1889750063419342,
              0.7249799966812134,
              0.19005000591278076,
              0.7249940037727356,
              0.18987399339675903,
              0.7249169945716858,
              0.1889750063419342,
              0.7249799966812134,
              0.19005000591278076,
              0.725051999092102,
              0.19116899371147156,
              0.7249940037727356,
              0.18987399339675903,
              0.725051999092102,
              0.19116899371147156,
              0.7250660061836243,
              0.19099999964237213,
              0.7249940037727356,
              0.18987399339675903,
              0.725051999092102,
              0.19116899371147156,
              0.725121021270752,
              0.19249999523162842,
              0.7250660061836243,
              0.19099999964237213,
              0.725121021270752,
              0.19249999523162842,
              0.7251350283622742,
              0.1923390030860901,
              0.7250660061836243,
              0.19099999964237213,
              0.7251859903335571,
              0.19402800500392914,
              0.725246012210846,
              0.1957390010356903,
              0.7251999974250793,
              0.1938759982585907,
              0.725246012210846,
              0.1957390010356903,
              0.7252609729766846,
              0.19559800624847412,
              0.7251999974250793,
              0.1938759982585907,
              0.7254800200462341,
              0.20415200293064117,
              0.7255390286445618,
              0.20657899975776672,
              0.7254949808120728,
              0.2040649950504303,
              0.7255390286445618,
              0.20657899975776672,
              0.7255539894104004,
              0.20650799572467804,
              0.7254949808120728,
              0.2040649950504303,
              0.7256010174751282,
              0.20910799503326416,
              0.7256640195846558,
              0.21172599494457245,
              0.7256150245666504,
              0.20905299484729767,
              0.7256640195846558,
              0.21172599494457245,
              0.725678026676178,
              0.21168799698352814,
              0.7256150245666504,
              0.20905299484729767,
              0.7256640195846558,
              0.21172599494457245,
              0.7257289886474609,
              0.21441900730133057,
              0.725678026676178,
              0.21168799698352814,
              0.7257289886474609,
              0.21441900730133057,
              0.7257429957389832,
              0.21439799666404724,
              0.725678026676178,
              0.21168799698352814,
              0.7257289886474609,
              0.21441900730133057,
              0.7257959842681885,
              0.2171739935874939,
              0.7257429957389832,
              0.21439799666404724,
              0.7257959842681885,
              0.2171739935874939,
              0.7258099913597107,
              0.21717000007629395,
              0.7257429957389832,
              0.21439799666404724,
              0.7257959842681885,
              0.2171739935874939,
              0.7258639931678772,
              0.219977006316185,
              0.7258099913597107,
              0.21717000007629395,
              0.7258639931678772,
              0.219977006316185,
              0.7258780002593994,
              0.21999099850654602,
              0.7258099913597107,
              0.21717000007629395,
              0.7248079776763916,
              0.1955060064792633,
              0.7247470021247864,
              0.19514399766921997,
              0.7248200178146362,
              0.19568899273872375,
              0.7247470021247864,
              0.19514399766921997,
              0.7247589826583862,
              0.1953279972076416,
              0.7248200178146362,
              0.19568899273872375,
              0.7249230146408081,
              0.19676999747753143,
              0.7248659729957581,
              0.19605199992656708,
              0.7249330282211304,
              0.19694499671459198,
              0.7248659729957581,
              0.19605199992656708,
              0.7248780131340027,
              0.19623200595378876,
              0.7249330282211304,
              0.19694499671459198,
              0.7249760031700134,
              0.1976509988307953,
              0.7249230146408081,
              0.19676999747753143,
              0.7249860167503357,
              0.19781999289989471,
              0.7249230146408081,
              0.19676999747753143,
              0.7249330282211304,
              0.19694499671459198,
              0.7249860167503357,
              0.19781999289989471,
              0.7258729934692383,
              0.22152000665664673,
              0.7258049845695496,
              0.2194029986858368,
              0.7258840203285217,
              0.22148799896240234,
              0.7258049845695496,
              0.2194029986858368,
              0.725816011428833,
              0.21938900649547577,
              0.7258840203285217,
              0.22148799896240234,
              0.7258049845695496,
              0.2194029986858368,
              0.7257360219955444,
              0.21730999648571014,
              0.725816011428833,
              0.21938900649547577,
              0.7257360219955444,
              0.21730999648571014,
              0.7257469892501831,
              0.2173140048980713,
              0.725816011428833,
              0.21938900649547577,
              0.7257360219955444,
              0.21730999648571014,
              0.7256669998168945,
              0.21525099873542786,
              0.7257469892501831,
              0.2173140048980713,
              0.7256669998168945,
              0.21525099873542786,
              0.7256770133972168,
              0.21527099609375,
              0.7257469892501831,
              0.2173140048980713,
              0.7256669998168945,
              0.21525099873542786,
              0.7255970239639282,
              0.21323400735855103,
              0.7256770133972168,
              0.21527099609375,
              0.7255970239639282,
              0.21323400735855103,
              0.7256079912185669,
              0.21327200531959534,
              0.7256770133972168,
              0.21527099609375,
              0.7254589796066284,
              0.20937199890613556,
              0.7253909707069397,
              0.20754599571228027,
              0.7254689931869507,
              0.20944300293922424,
              0.7253909707069397,
              0.20754599571228027,
              0.7254019975662231,
              0.20763200521469116,
              0.7254689931869507,
              0.20944300293922424,
              0.7253249883651733,
              0.2058030068874359,
              0.7252609729766846,
              0.20415300130844116,
              0.7253350019454956,
              0.2059040069580078,
              0.7252609729766846,
              0.20415300130844116,
              0.7252699732780457,
              0.20426900684833527,
              0.7253350019454956,
              0.2059040069580078,
              0.7252609729766846,
              0.20415300130844116,
              0.725197970867157,
              0.2026059925556183,
              0.7252699732780457,
              0.20426900684833527,
              0.725197970867157,
              0.2026059925556183,
              0.7252079844474792,
              0.20273500680923462,
              0.7252699732780457,
              0.20426900684833527,
              0.725197970867157,
              0.2026059925556183,
              0.7251380085945129,
              0.20117299258708954,
              0.7252079844474792,
              0.20273500680923462,
              0.7251380085945129,
              0.20117299258708954,
              0.7251480221748352,
              0.20131400227546692,
              0.7252079844474792,
              0.20273500680923462,
              0.7260040044784546,
              0.22578300535678864,
              0.7260149717330933,
              0.2257159948348999,
              0.7260550260543823,
              0.22788499295711517,
              0.7260149717330933,
              0.2257159948348999,
              0.7260649800300598,
              0.22779999673366547,
              0.7260550260543823,
              0.22788499295711517,
              0.7246699929237366,
              0.18776999413967133,
              0.7245839834213257,
              0.18776999413967133,
              0.7246590256690979,
              0.18795299530029297,
              0.7245839834213257,
              0.18776999413967133,
              0.724573016166687,
              0.18795299530029297,
              0.7246590256690979,
              0.18795299530029297,
              0.7246829867362976,
              0.19475799798965454,
              0.7247470021247864,
              0.19492800533771515,
              0.7246590256690979,
              0.188167005777359,
              0.7247470021247864,
              0.19492800533771515,
              0.7247430086135864,
              0.1883080005645752,
              0.7246590256690979,
              0.188167005777359,
              0.7247470021247864,
              0.19492800533771515,
              0.7248079776763916,
              0.19529099762439728,
              0.7247430086135864,
              0.1883080005645752,
              0.7248079776763916,
              0.19529099762439728,
              0.7248250246047974,
              0.1887120008468628,
              0.7247430086135864,
              0.1883080005645752,
              0.7248079776763916,
              0.19529099762439728,
              0.7248679995536804,
              0.1958400011062622,
              0.7248250246047974,
              0.1887120008468628,
              0.7248679995536804,
              0.1958400011062622,
              0.7249029874801636,
              0.18936499953269958,
              0.7248250246047974,
              0.1887120008468628,
              0.7248679995536804,
              0.1958400011062622,
              0.7249240279197693,
              0.19656400382518768,
              0.7249029874801636,
              0.18936499953269958,
              0.7249240279197693,
              0.19656400382518768,
              0.7249780297279358,
              0.19025500118732452,
              0.7249029874801636,
              0.18936499953269958,
              0.7249240279197693,
              0.19656400382518768,
              0.7249780297279358,
              0.19745300710201263,
              0.7249780297279358,
              0.19025500118732452,
              0.7249780297279358,
              0.19745300710201263,
              0.7250499725341797,
              0.1913670003414154,
              0.7249780297279358,
              0.19025500118732452,
              0.7249780297279358,
              0.19745300710201263,
              0.7250300049781799,
              0.1984959989786148,
              0.7250499725341797,
              0.1913670003414154,
              0.7250300049781799,
              0.1984959989786148,
              0.7251179814338684,
              0.1926880031824112,
              0.7250499725341797,
              0.1913670003414154,
              0.7250300049781799,
              0.1984959989786148,
              0.7250840067863464,
              0.1996850073337555,
              0.7251179814338684,
              0.1926880031824112,
              0.7250840067863464,
              0.1996850073337555,
              0.7251819968223572,
              0.19420500099658966,
              0.7251179814338684,
              0.1926880031824112,
              0.7250840067863464,
              0.1996850073337555,
              0.7251409888267517,
              0.20100699365139008,
              0.7251819968223572,
              0.19420500099658966,
              0.7251409888267517,
              0.20100699365139008,
              0.7252429723739624,
              0.19590400159358978,
              0.7251819968223572,
              0.19420500099658966,
              0.7251409888267517,
              0.20100699365139008,
              0.7252020239830017,
              0.20245499908924103,
              0.7252429723739624,
              0.19590400159358978,
              0.7252020239830017,
              0.20245499908924103,
              0.72530597448349,
              0.19777199625968933,
              0.7252429723739624,
              0.19590400159358978,
              0.7252020239830017,
              0.20245499908924103,
              0.7252640128135681,
              0.20401699841022491,
              0.72530597448349,
              0.19777199625968933,
              0.7252640128135681,
              0.20401699841022491,
              0.7253680229187012,
              0.19979499280452728,
              0.72530597448349,
              0.19777199625968933,
              0.7252640128135681,
              0.20401699841022491,
              0.7253280282020569,
              0.20568299293518066,
              0.7253680229187012,
              0.19979499280452728,
              0.7253280282020569,
              0.20568299293518066,
              0.7254210114479065,
              0.2019609957933426,
              0.7253680229187012,
              0.19979499280452728,
              0.7253280282020569,
              0.20568299293518066,
              0.7253940105438232,
              0.2074439972639084,
              0.7254210114479065,
              0.2019609957933426,
              0.7253940105438232,
              0.2074439972639084,
              0.7254779934883118,
              0.20425400137901306,
              0.7254210114479065,
              0.2019609957933426,
              0.7254610061645508,
              0.20928800106048584,
              0.7255370020866394,
              0.20666299760341644,
              0.7253940105438232,
              0.2074439972639084,
              0.7255370020866394,
              0.20666299760341644,
              0.7254779934883118,
              0.20425400137901306,
              0.7253940105438232,
              0.2074439972639084,
              0.7255300283432007,
              0.21120700240135193,
              0.7255989909172058,
              0.20917299389839172,
              0.7254610061645508,
              0.20928800106048584,
              0.7255989909172058,
              0.20917299389839172,
              0.7255370020866394,
              0.20666299760341644,
              0.7254610061645508,
              0.20928800106048584,
              0.7255989909172058,
              0.2131900042295456,
              0.7256619930267334,
              0.21177099645137787,
              0.7255300283432007,
              0.21120700240135193,
              0.7256619930267334,
              0.21177099645137787,
              0.7255989909172058,
              0.20917299389839172,
              0.7255300283432007,
              0.21120700240135193,
              0.7256680130958557,
              0.21522599458694458,
              0.7257279753684998,
              0.21444399654865265,
              0.7255989909172058,
              0.2131900042295456,
              0.7257279753684998,
              0.21444399654865265,
              0.7256619930267334,
              0.21177099645137787,
              0.7255989909172058,
              0.2131900042295456,
              0.725737988948822,
              0.2173060029745102,
              0.7257940173149109,
              0.21717800199985504,
              0.7256680130958557,
              0.21522599458694458,
              0.7257940173149109,
              0.21717800199985504,
              0.7257279753684998,
              0.21444399654865265,
              0.7256680130958557,
              0.21522599458694458,
              0.7258059978485107,
              0.21942000091075897,
              0.7258620262145996,
              0.21996000409126282,
              0.725737988948822,
              0.2173060029745102,
              0.7258620262145996,
              0.21996000409126282,
              0.7257940173149109,
              0.21717800199985504,
              0.725737988948822,
              0.2173060029745102,
              0.7258740067481995,
              0.22155700623989105,
              0.7259309887886047,
              0.22277599573135376,
              0.7258059978485107,
              0.21942000091075897,
              0.7259309887886047,
              0.22277599573135376,
              0.7258620262145996,
              0.21996000409126282,
              0.7258059978485107,
              0.21942000091075897,
              0.725941002368927,
              0.22370800375938416,
              0.7259989976882935,
              0.22561399638652802,
              0.7258740067481995,
              0.22155700623989105,
              0.7259989976882935,
              0.22561399638652802,
              0.7259309887886047,
              0.22277599573135376,
              0.7258740067481995,
              0.22155700623989105,
              0.7260059714317322,
              0.22586199641227722,
              0.7260680198669434,
              0.2284580022096634,
              0.725941002368927,
              0.22370800375938416,
              0.7260680198669434,
              0.2284580022096634,
              0.7259989976882935,
              0.22561399638652802,
              0.725941002368927,
              0.22370800375938416,
              0.7260680198669434,
              0.2284580022096634,
              0.7260059714317322,
              0.22586199641227722,
              0.7261360287666321,
              0.2312619984149933,
              0.7260059714317322,
              0.22586199641227722,
              0.7260569930076599,
              0.2279839962720871,
              0.7261360287666321,
              0.2312619984149933,
              0.7206680178642273,
              0.18816600739955902,
              0.7206680178642273,
              0.1902800053358078,
              0.7245749831199646,
              0.188167005777359,
              0.7206680178642273,
              0.1902800053358078,
              0.7246189713478088,
              0.19468599557876587,
              0.7245749831199646,
              0.188167005777359,
              0.7251179814338684,
              0.1926880031824112,
              0.7251819968223572,
              0.19420500099658966,
              0.725121021270752,
              0.19249999523162842,
              0.7251819968223572,
              0.19420500099658966,
              0.7251859903335571,
              0.19402800500392914,
              0.725121021270752,
              0.19249999523162842,
              0.7252429723739624,
              0.19590400159358978,
              0.72530597448349,
              0.19777199625968933,
              0.725246012210846,
              0.1957390010356903,
              0.72530597448349,
              0.19777199625968933,
              0.7253100275993347,
              0.19762100279331207,
              0.725246012210846,
              0.1957390010356903,
              0.72530597448349,
              0.19777199625968933,
              0.7253680229187012,
              0.19979499280452728,
              0.7253100275993347,
              0.19762100279331207,
              0.7253680229187012,
              0.19979499280452728,
              0.7253710031509399,
              0.19965900480747223,
              0.7253100275993347,
              0.19762100279331207,
              0.7253680229187012,
              0.19979499280452728,
              0.7254210114479065,
              0.2019609957933426,
              0.7253710031509399,
              0.19965900480747223,
              0.7254210114479065,
              0.2019609957933426,
              0.7254239916801453,
              0.20184099674224854,
              0.7253710031509399,
              0.19965900480747223,
              0.7254210114479065,
              0.2019609957933426,
              0.7254779934883118,
              0.20425400137901306,
              0.7254239916801453,
              0.20184099674224854,
              0.7254779934883118,
              0.20425400137901306,
              0.7254800200462341,
              0.20415200293064117,
              0.7254239916801453,
              0.20184099674224854,
              0.7255370020866394,
              0.20666299760341644,
              0.7255989909172058,
              0.20917299389839172,
              0.7255390286445618,
              0.20657899975776672,
              0.7255989909172058,
              0.20917299389839172,
              0.7256010174751282,
              0.20910799503326416,
              0.7255390286445618,
              0.20657899975776672,
              0.7258620262145996,
              0.21996000409126282,
              0.7259309887886047,
              0.22277599573135376,
              0.7258639931678772,
              0.219977006316185,
              0.7259309887886047,
              0.22277599573135376,
              0.7259320020675659,
              0.22281399369239807,
              0.7258639931678772,
              0.219977006316185,
              0.7259309887886047,
              0.22277599573135376,
              0.7259989976882935,
              0.22561399638652802,
              0.7259320020675659,
              0.22281399369239807,
              0.7259989976882935,
              0.22561399638652802,
              0.7260010242462158,
              0.22567200660705566,
              0.7259320020675659,
              0.22281399369239807,
              0.7259989976882935,
              0.22561399638652802,
              0.7260680198669434,
              0.2284580022096634,
              0.7260010242462158,
              0.22567200660705566,
              0.7260680198669434,
              0.2284580022096634,
              0.726069986820221,
              0.22853699326515198,
              0.7260010242462158,
              0.22567200660705566,
              0.7247470021247864,
              0.19492800533771515,
              0.7246829867362976,
              0.19475799798965454,
              0.7247470021247864,
              0.19514399766921997,
              0.7246829867362976,
              0.19475799798965454,
              0.7246840000152588,
              0.19497300684452057,
              0.7247470021247864,
              0.19514399766921997,
              0.7248679995536804,
              0.1958400011062622,
              0.7248079776763916,
              0.19529099762439728,
              0.7248659729957581,
              0.19605199992656708,
              0.7248079776763916,
              0.19529099762439728,
              0.7248079776763916,
              0.1955060064792633,
              0.7248659729957581,
              0.19605199992656708,
              0.7250300049781799,
              0.1984959989786148,
              0.7249780297279358,
              0.19745300710201263,
              0.7250270247459412,
              0.19868500530719757,
              0.7249780297279358,
              0.19745300710201263,
              0.7249760031700134,
              0.1976509988307953,
              0.7250270247459412,
              0.19868500530719757,
              0.7260059714317322,
              0.22586199641227722,
              0.725941002368927,
              0.22370800375938416,
              0.7260040044784546,
              0.22578300535678864,
              0.725941002368927,
              0.22370800375938416,
              0.7259389758110046,
              0.2236499935388565,
              0.7260040044784546,
              0.22578300535678864,
              0.725941002368927,
              0.22370800375938416,
              0.7258740067481995,
              0.22155700623989105,
              0.7259389758110046,
              0.2236499935388565,
              0.7258740067481995,
              0.22155700623989105,
              0.7258729934692383,
              0.22152000665664673,
              0.7259389758110046,
              0.2236499935388565,
              0.7255989909172058,
              0.2131900042295456,
              0.7255300283432007,
              0.21120700240135193,
              0.7255970239639282,
              0.21323400735855103,
              0.7255300283432007,
              0.21120700240135193,
              0.7255280017852783,
              0.2112710028886795,
              0.7255970239639282,
              0.21323400735855103,
              0.7255300283432007,
              0.21120700240135193,
              0.7254610061645508,
              0.20928800106048584,
              0.7255280017852783,
              0.2112710028886795,
              0.7254610061645508,
              0.20928800106048584,
              0.7254589796066284,
              0.20937199890613556,
              0.7255280017852783,
              0.2112710028886795,
              0.7253940105438232,
              0.2074439972639084,
              0.7253280282020569,
              0.20568299293518066,
              0.7253909707069397,
              0.20754599571228027,
              0.7253280282020569,
              0.20568299293518066,
              0.7253249883651733,
              0.2058030068874359,
              0.7253909707069397,
              0.20754599571228027,
              0.7251409888267517,
              0.20100699365139008,
              0.7250840067863464,
              0.1996850073337555,
              0.7251380085945129,
              0.20117299258708954,
              0.7250840067863464,
              0.1996850073337555,
              0.7250810265541077,
              0.199863001704216,
              0.7251380085945129,
              0.20117299258708954,
              0.7250840067863464,
              0.1996850073337555,
              0.7250300049781799,
              0.1984959989786148,
              0.7250810265541077,
              0.199863001704216,
              0.7250300049781799,
              0.1984959989786148,
              0.7250270247459412,
              0.19868500530719757,
              0.7250810265541077,
              0.199863001704216,
              0.726069986820221,
              0.22853699326515198,
              0.7260680198669434,
              0.2284580022096634,
              0.7261379957199097,
              0.2313610017299652,
              0.7260680198669434,
              0.2284580022096634,
              0.7261360287666321,
              0.2312619984149933,
              0.7261379957199097,
              0.2313610017299652,
              0.7246829867362976,
              0.19475799798965454,
              0.7246189713478088,
              0.19468599557876587,
              0.7246840000152588,
              0.19497300684452057,
              0.7246189713478088,
              0.19468599557876587,
              0.72461998462677,
              0.19490200281143188,
              0.7246840000152588,
              0.19497300684452057,
              0.7248349785804749,
              0.18784800171852112,
              0.7247499823570251,
              0.1877090036869049,
              0.7247920036315918,
              0.18784800171852112,
              0.7247499823570251,
              0.1877090036869049,
              0.7247070074081421,
              0.1877090036869049,
              0.7247920036315918,
              0.18784800171852112,
              0.724918007850647,
              0.1882539987564087,
              0.7248349785804749,
              0.18784800171852112,
              0.7248749732971191,
              0.1882539987564087,
              0.7248349785804749,
              0.18784800171852112,
              0.7247920036315918,
              0.18784800171852112,
              0.7248749732971191,
              0.1882539987564087,
              0.7249979972839355,
              0.18891499936580658,
              0.724918007850647,
              0.1882539987564087,
              0.7249540090560913,
              0.18891499936580658,
              0.724918007850647,
              0.1882539987564087,
              0.7248749732971191,
              0.1882539987564087,
              0.7249540090560913,
              0.18891499936580658,
              0.7250739932060242,
              0.18981599807739258,
              0.7249979972839355,
              0.18891499936580658,
              0.7250310182571411,
              0.18981599807739258,
              0.7249979972839355,
              0.18891499936580658,
              0.7249540090560913,
              0.18891499936580658,
              0.7250310182571411,
              0.18981599807739258,
              0.725147008895874,
              0.19094400107860565,
              0.7250739932060242,
              0.18981599807739258,
              0.7251039743423462,
              0.19094400107860565,
              0.7250739932060242,
              0.18981599807739258,
              0.7250310182571411,
              0.18981599807739258,
              0.7251039743423462,
              0.19094400107860565,
              0.7252159714698792,
              0.19228500127792358,
              0.725147008895874,
              0.19094400107860565,
              0.7251729965209961,
              0.19228500127792358,
              0.725147008895874,
              0.19094400107860565,
              0.7251039743423462,
              0.19094400107860565,
              0.7251729965209961,
              0.19228500127792358,
              0.7253419756889343,
              0.19555099308490753,
              0.7252810001373291,
              0.19382600486278534,
              0.7252990007400513,
              0.19555099308490753,
              0.7252810001373291,
              0.19382600486278534,
              0.725238025188446,
              0.19382600486278534,
              0.7252990007400513,
              0.19555099308490753,
              0.725633978843689,
              0.20648400485515594,
              0.7255749702453613,
              0.20403599739074707,
              0.7255910038948059,
              0.20648400485515594,
              0.7255749702453613,
              0.20403599739074707,
              0.7255319952964783,
              0.20403599739074707,
              0.7255910038948059,
              0.20648400485515594,
              0.7257580161094666,
              0.2116750031709671,
              0.725695013999939,
              0.2090349942445755,
              0.7257149815559387,
              0.2116750031709671,
              0.725695013999939,
              0.2090349942445755,
              0.7256519794464111,
              0.2090349942445755,
              0.7257149815559387,
              0.2116750031709671,
              0.7258239984512329,
              0.21439099311828613,
              0.7257580161094666,
              0.2116750031709671,
              0.7257800102233887,
              0.21439099311828613,
              0.7257580161094666,
              0.2116750031709671,
              0.7257149815559387,
              0.2116750031709671,
              0.7257800102233887,
              0.21439099311828613,
              0.7258899807929993,
              0.21716900169849396,
              0.7258239984512329,
              0.21439099311828613,
              0.7258470058441162,
              0.21716900169849396,
              0.7258239984512329,
              0.21439099311828613,
              0.7257800102233887,
              0.21439099311828613,
              0.7258470058441162,
              0.21716900169849396,
              0.725957989692688,
              0.21999500691890717,
              0.7258899807929993,
              0.21716900169849396,
              0.7259150147438049,
              0.21999500691890717,
              0.7258899807929993,
              0.21716900169849396,
              0.7258470058441162,
              0.21716900169849396,
              0.7259150147438049,
              0.21999500691890717,
              0.7248389720916748,
              0.1953900009393692,
              0.7248989939689636,
              0.19575099647045135,
              0.7247959971427917,
              0.1953900009393692,
              0.7248989939689636,
              0.19575099647045135,
              0.7248560190200806,
              0.19575099647045135,
              0.7247959971427917,
              0.1953900009393692,
              0.7249569892883301,
              0.1962919980287552,
              0.725013017654419,
              0.19700400531291962,
              0.724914014339447,
              0.1962919980287552,
              0.725013017654419,
              0.19700400531291962,
              0.7249699831008911,
              0.19700400531291962,
              0.724914014339447,
              0.1962919980287552,
              0.725013017654419,
              0.19700400531291962,
              0.7250649929046631,
              0.1978760063648224,
              0.7249699831008911,
              0.19700400531291962,
              0.7250649929046631,
              0.1978760063648224,
              0.72502201795578,
              0.1978760063648224,
              0.7249699831008911,
              0.19700400531291962,
              0.7258949875831604,
              0.21938399970531464,
              0.7259629964828491,
              0.22147700190544128,
              0.7258520126342773,
              0.21938399970531464,
              0.7259629964828491,
              0.22147700190544128,
              0.7259200215339661,
              0.22147700190544128,
              0.7258520126342773,
              0.21938399970531464,
              0.7258269786834717,
              0.21731500327587128,
              0.7258949875831604,
              0.21938399970531464,
              0.7257829904556274,
              0.21731500327587128,
              0.7258949875831604,
              0.21938399970531464,
              0.7258520126342773,
              0.21938399970531464,
              0.7257829904556274,
              0.21731500327587128,
              0.7257570028305054,
              0.2152779996395111,
              0.7258269786834717,
              0.21731500327587128,
              0.7257140278816223,
              0.2152779996395111,
              0.7258269786834717,
              0.21731500327587128,
              0.7257829904556274,
              0.21731500327587128,
              0.7257140278816223,
              0.2152779996395111,
              0.7256870269775391,
              0.21328499913215637,
              0.7257570028305054,
              0.2152779996395111,
              0.7256439924240112,
              0.21328499913215637,
              0.7257570028305054,
              0.2152779996395111,
              0.7257140278816223,
              0.2152779996395111,
              0.7256439924240112,
              0.21328499913215637,
              0.7254809737205505,
              0.2076610028743744,
              0.7255489826202393,
              0.20946699380874634,
              0.7254379987716675,
              0.2076610028743744,
              0.7255489826202393,
              0.20946699380874634,
              0.7255060076713562,
              0.20946699380874634,
              0.7254379987716675,
              0.2076610028743744,
              0.725350022315979,
              0.20430700480937958,
              0.7254149913787842,
              0.20593799650669098,
              0.72530597448349,
              0.20430700480937958,
              0.7254149913787842,
              0.20593799650669098,
              0.7253710031509399,
              0.20593799650669098,
              0.72530597448349,
              0.20430700480937958,
              0.7252870202064514,
              0.20277799665927887,
              0.725350022315979,
              0.20430700480937958,
              0.7252439856529236,
              0.20277799665927887,
              0.725350022315979,
              0.20430700480937958,
              0.72530597448349,
              0.20430700480937958,
              0.7252439856529236,
              0.20277799665927887,
              0.7252269983291626,
              0.20136100053787231,
              0.7252870202064514,
              0.20277799665927887,
              0.7251830101013184,
              0.20136100053787231,
              0.7252870202064514,
              0.20277799665927887,
              0.7252439856529236,
              0.20277799665927887,
              0.7251830101013184,
              0.20136100053787231,
              0.7260940074920654,
              0.22569400072097778,
              0.726144015789032,
              0.22777099907398224,
              0.7260509729385376,
              0.22569400072097778,
              0.726144015789032,
              0.22777099907398224,
              0.7261009812355042,
              0.22777099907398224,
              0.7260509729385376,
              0.22569400072097778,
              0.7247070074081421,
              0.1877090036869049,
              0.7247499823570251,
              0.1877090036869049,
              0.7246209979057312,
              0.1877090036869049,
              0.7247499823570251,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7246209979057312,
              0.1877090036869049,
              0.7252810001373291,
              0.19382600486278534,
              0.7252159714698792,
              0.19228500127792358,
              0.725238025188446,
              0.19382600486278534,
              0.7252159714698792,
              0.19228500127792358,
              0.7251729965209961,
              0.19228500127792358,
              0.725238025188446,
              0.19382600486278534,
              0.7254049777984619,
              0.1974489986896515,
              0.7253419756889343,
              0.19555099308490753,
              0.7253620028495789,
              0.1974489986896515,
              0.7253419756889343,
              0.19555099308490753,
              0.7252990007400513,
              0.19555099308490753,
              0.7253620028495789,
              0.1974489986896515,
              0.7254670262336731,
              0.1995050013065338,
              0.7254049777984619,
              0.1974489986896515,
              0.7254239916801453,
              0.1995050013065338,
              0.7254049777984619,
              0.1974489986896515,
              0.7253620028495789,
              0.1974489986896515,
              0.7254239916801453,
              0.1995050013065338,
              0.7255190014839172,
              0.2017049938440323,
              0.7254670262336731,
              0.1995050013065338,
              0.7254760265350342,
              0.2017049938440323,
              0.7254670262336731,
              0.1995050013065338,
              0.7254239916801453,
              0.1995050013065338,
              0.7254760265350342,
              0.2017049938440323,
              0.7255749702453613,
              0.20403599739074707,
              0.7255190014839172,
              0.2017049938440323,
              0.7255319952964783,
              0.20403599739074707,
              0.7255190014839172,
              0.2017049938440323,
              0.7254760265350342,
              0.2017049938440323,
              0.7255319952964783,
              0.20403599739074707,
              0.725695013999939,
              0.2090349942445755,
              0.725633978843689,
              0.20648400485515594,
              0.7256519794464111,
              0.2090349942445755,
              0.725633978843689,
              0.20648400485515594,
              0.7255910038948059,
              0.20648400485515594,
              0.7256519794464111,
              0.2090349942445755,
              0.7260259985923767,
              0.22285600006580353,
              0.725957989692688,
              0.21999500691890717,
              0.7259830236434937,
              0.22285600006580353,
              0.725957989692688,
              0.21999500691890717,
              0.7259150147438049,
              0.21999500691890717,
              0.7259830236434937,
              0.22285600006580353,
              0.7260950207710266,
              0.22573800384998322,
              0.7260259985923767,
              0.22285600006580353,
              0.7260519862174988,
              0.22573800384998322,
              0.7260259985923767,
              0.22285600006580353,
              0.7259830236434937,
              0.22285600006580353,
              0.7260519862174988,
              0.22573800384998322,
              0.7261639833450317,
              0.22862599790096283,
              0.7260950207710266,
              0.22573800384998322,
              0.7261210083961487,
              0.22862599790096283,
              0.7260950207710266,
              0.22573800384998322,
              0.7260519862174988,
              0.22573800384998322,
              0.7261210083961487,
              0.22862599790096283,
              0.7247769832611084,
              0.1952189952135086,
              0.7248389720916748,
              0.1953900009393692,
              0.7247340083122253,
              0.1952189952135086,
              0.7248389720916748,
              0.1953900009393692,
              0.7247959971427917,
              0.1953900009393692,
              0.7247340083122253,
              0.1952189952135086,
              0.7248989939689636,
              0.19575099647045135,
              0.7249569892883301,
              0.1962919980287552,
              0.7248560190200806,
              0.19575099647045135,
              0.7249569892883301,
              0.1962919980287552,
              0.724914014339447,
              0.1962919980287552,
              0.7248560190200806,
              0.19575099647045135,
              0.7250649929046631,
              0.1978760063648224,
              0.7251160144805908,
              0.1988999992609024,
              0.72502201795578,
              0.1978760063648224,
              0.7251160144805908,
              0.1988999992609024,
              0.7250720262527466,
              0.1988999992609024,
              0.72502201795578,
              0.1978760063648224,
              0.7260299921035767,
              0.22358399629592896,
              0.7260940074920654,
              0.22569400072097778,
              0.7259870171546936,
              0.22358399629592896,
              0.7260940074920654,
              0.22569400072097778,
              0.7260509729385376,
              0.22569400072097778,
              0.7259870171546936,
              0.22358399629592896,
              0.7259629964828491,
              0.22147700190544128,
              0.7260299921035767,
              0.22358399629592896,
              0.7259200215339661,
              0.22147700190544128,
              0.7260299921035767,
              0.22358399629592896,
              0.7259870171546936,
              0.22358399629592896,
              0.7259200215339661,
              0.22147700190544128,
              0.7256180047988892,
              0.21134500205516815,
              0.7256870269775391,
              0.21328499913215637,
              0.7255740165710449,
              0.21134500205516815,
              0.7256870269775391,
              0.21328499913215637,
              0.7256439924240112,
              0.21328499913215637,
              0.7255740165710449,
              0.21134500205516815,
              0.7255489826202393,
              0.20946699380874634,
              0.7256180047988892,
              0.21134500205516815,
              0.7255060076713562,
              0.20946699380874634,
              0.7256180047988892,
              0.21134500205516815,
              0.7255740165710449,
              0.21134500205516815,
              0.7255060076713562,
              0.20946699380874634,
              0.7254149913787842,
              0.20593799650669098,
              0.7254809737205505,
              0.2076610028743744,
              0.7253710031509399,
              0.20593799650669098,
              0.7254809737205505,
              0.2076610028743744,
              0.7254379987716675,
              0.2076610028743744,
              0.7253710031509399,
              0.20593799650669098,
              0.7251700162887573,
              0.2000650018453598,
              0.7252269983291626,
              0.20136100053787231,
              0.7251260280609131,
              0.2000650018453598,
              0.7252269983291626,
              0.20136100053787231,
              0.7251830101013184,
              0.20136100053787231,
              0.7251260280609131,
              0.2000650018453598,
              0.7251160144805908,
              0.1988999992609024,
              0.7251700162887573,
              0.2000650018453598,
              0.7250720262527466,
              0.1988999992609024,
              0.7251700162887573,
              0.2000650018453598,
              0.7251260280609131,
              0.2000650018453598,
              0.7250720262527466,
              0.1988999992609024,
              0.7315700054168701,
              0.45609501004219055,
              0.7316139936447144,
              0.45609501004219055,
              0.7315319776535034,
              0.4545019865036011,
              0.7316139936447144,
              0.45609501004219055,
              0.7315750122070312,
              0.4545019865036011,
              0.7315319776535034,
              0.4545019865036011,
              0.7207159996032715,
              0.19073599576950073,
              0.7207580208778381,
              0.19073499739170074,
              0.7246710062026978,
              0.19514800608158112,
              0.7207580208778381,
              0.19073499739170074,
              0.7247139811515808,
              0.19514800608158112,
              0.7246710062026978,
              0.19514800608158112,
              0.7246590256690979,
              0.188167005777359,
              0.7247430086135864,
              0.1883080005645752,
              0.7246590256690979,
              0.18795299530029297,
              0.7247430086135864,
              0.1883080005645752,
              0.7247430086135864,
              0.18809199333190918,
              0.7246590256690979,
              0.18795299530029297,
              0.7247430086135864,
              0.1883080005645752,
              0.7248250246047974,
              0.1887120008468628,
              0.7247430086135864,
              0.18809199333190918,
              0.7248250246047974,
              0.1887120008468628,
              0.7248250246047974,
              0.18849700689315796,
              0.7247430086135864,
              0.18809199333190918,
              0.7248250246047974,
              0.1887120008468628,
              0.7249029874801636,
              0.18936499953269958,
              0.7248250246047974,
              0.18849700689315796,
              0.7249029874801636,
              0.18936499953269958,
              0.7249040007591248,
              0.1891549974679947,
              0.7248250246047974,
              0.18849700689315796,
              0.7249029874801636,
              0.18936499953269958,
              0.7249780297279358,
              0.19025500118732452,
              0.7249040007591248,
              0.1891549974679947,
              0.7249780297279358,
              0.19025500118732452,
              0.7249799966812134,
              0.19005000591278076,
              0.7249040007591248,
              0.1891549974679947,
              0.7249780297279358,
              0.19025500118732452,
              0.7250499725341797,
              0.1913670003414154,
              0.7249799966812134,
              0.19005000591278076,
              0.7250499725341797,
              0.1913670003414154,
              0.725051999092102,
              0.19116899371147156,
              0.7249799966812134,
              0.19005000591278076,
              0.7250499725341797,
              0.1913670003414154,
              0.7251179814338684,
              0.1926880031824112,
              0.725051999092102,
              0.19116899371147156,
              0.7251179814338684,
              0.1926880031824112,
              0.725121021270752,
              0.19249999523162842,
              0.725051999092102,
              0.19116899371147156,
              0.7251819968223572,
              0.19420500099658966,
              0.7252429723739624,
              0.19590400159358978,
              0.7251859903335571,
              0.19402800500392914,
              0.7252429723739624,
              0.19590400159358978,
              0.725246012210846,
              0.1957390010356903,
              0.7251859903335571,
              0.19402800500392914,
              0.7254779934883118,
              0.20425400137901306,
              0.7255370020866394,
              0.20666299760341644,
              0.7254800200462341,
              0.20415200293064117,
              0.7255370020866394,
              0.20666299760341644,
              0.7255390286445618,
              0.20657899975776672,
              0.7254800200462341,
              0.20415200293064117,
              0.7255989909172058,
              0.20917299389839172,
              0.7256619930267334,
              0.21177099645137787,
              0.7256010174751282,
              0.20910799503326416,
              0.7256619930267334,
              0.21177099645137787,
              0.7256640195846558,
              0.21172599494457245,
              0.7256010174751282,
              0.20910799503326416,
              0.7256619930267334,
              0.21177099645137787,
              0.7257279753684998,
              0.21444399654865265,
              0.7256640195846558,
              0.21172599494457245,
              0.7257279753684998,
              0.21444399654865265,
              0.7257289886474609,
              0.21441900730133057,
              0.7256640195846558,
              0.21172599494457245,
              0.7257279753684998,
              0.21444399654865265,
              0.7257940173149109,
              0.21717800199985504,
              0.7257289886474609,
              0.21441900730133057,
              0.7257940173149109,
              0.21717800199985504,
              0.7257959842681885,
              0.2171739935874939,
              0.7257289886474609,
              0.21441900730133057,
              0.7257940173149109,
              0.21717800199985504,
              0.7258620262145996,
              0.21996000409126282,
              0.7257959842681885,
              0.2171739935874939,
              0.7258620262145996,
              0.21996000409126282,
              0.7258639931678772,
              0.219977006316185,
              0.7257959842681885,
              0.2171739935874939,
              0.7248079776763916,
              0.19529099762439728,
              0.7247470021247864,
              0.19492800533771515,
              0.7248079776763916,
              0.1955060064792633,
              0.7247470021247864,
              0.19492800533771515,
              0.7247470021247864,
              0.19514399766921997,
              0.7248079776763916,
              0.1955060064792633,
              0.7249240279197693,
              0.19656400382518768,
              0.7248679995536804,
              0.1958400011062622,
              0.7249230146408081,
              0.19676999747753143,
              0.7248679995536804,
              0.1958400011062622,
              0.7248659729957581,
              0.19605199992656708,
              0.7249230146408081,
              0.19676999747753143,
              0.7249780297279358,
              0.19745300710201263,
              0.7249240279197693,
              0.19656400382518768,
              0.7249760031700134,
              0.1976509988307953,
              0.7249240279197693,
              0.19656400382518768,
              0.7249230146408081,
              0.19676999747753143,
              0.7249760031700134,
              0.1976509988307953,
              0.7258740067481995,
              0.22155700623989105,
              0.7258059978485107,
              0.21942000091075897,
              0.7258729934692383,
              0.22152000665664673,
              0.7258059978485107,
              0.21942000091075897,
              0.7258049845695496,
              0.2194029986858368,
              0.7258729934692383,
              0.22152000665664673,
              0.7258059978485107,
              0.21942000091075897,
              0.725737988948822,
              0.2173060029745102,
              0.7258049845695496,
              0.2194029986858368,
              0.725737988948822,
              0.2173060029745102,
              0.7257360219955444,
              0.21730999648571014,
              0.7258049845695496,
              0.2194029986858368,
              0.725737988948822,
              0.2173060029745102,
              0.7256680130958557,
              0.21522599458694458,
              0.7257360219955444,
              0.21730999648571014,
              0.7256680130958557,
              0.21522599458694458,
              0.7256669998168945,
              0.21525099873542786,
              0.7257360219955444,
              0.21730999648571014,
              0.7256680130958557,
              0.21522599458694458,
              0.7255989909172058,
              0.2131900042295456,
              0.7256669998168945,
              0.21525099873542786,
              0.7255989909172058,
              0.2131900042295456,
              0.7255970239639282,
              0.21323400735855103,
              0.7256669998168945,
              0.21525099873542786,
              0.7254610061645508,
              0.20928800106048584,
              0.7253940105438232,
              0.2074439972639084,
              0.7254589796066284,
              0.20937199890613556,
              0.7253940105438232,
              0.2074439972639084,
              0.7253909707069397,
              0.20754599571228027,
              0.7254589796066284,
              0.20937199890613556,
              0.7253280282020569,
              0.20568299293518066,
              0.7252640128135681,
              0.20401699841022491,
              0.7253249883651733,
              0.2058030068874359,
              0.7252640128135681,
              0.20401699841022491,
              0.7252609729766846,
              0.20415300130844116,
              0.7253249883651733,
              0.2058030068874359,
              0.7252640128135681,
              0.20401699841022491,
              0.7252020239830017,
              0.20245499908924103,
              0.7252609729766846,
              0.20415300130844116,
              0.7252020239830017,
              0.20245499908924103,
              0.725197970867157,
              0.2026059925556183,
              0.7252609729766846,
              0.20415300130844116,
              0.7252020239830017,
              0.20245499908924103,
              0.7251409888267517,
              0.20100699365139008,
              0.725197970867157,
              0.2026059925556183,
              0.7251409888267517,
              0.20100699365139008,
              0.7251380085945129,
              0.20117299258708954,
              0.725197970867157,
              0.2026059925556183,
              0.7314800024032593,
              0.4543749988079071,
              0.7314519882202148,
              0.4532020092010498,
              0.7314789891242981,
              0.45432499051094055,
              0.7314519882202148,
              0.4532020092010498,
              0.7314509749412537,
              0.4531640112400055,
              0.7314789891242981,
              0.45432499051094055,
              0.7206680178642273,
              0.18816600739955902,
              0.7245749831199646,
              0.188167005777359,
              0.7206680178642273,
              0.18795199692249298,
              0.7245749831199646,
              0.188167005777359,
              0.724573016166687,
              0.18795299530029297,
              0.7206680178642273,
              0.18795199692249298,
              0.7415549755096436,
              0.19073499739170074,
              0.7415549755096436,
              0.19514800608158112,
              0.7207580208778381,
              0.19073499739170074,
              0.7415549755096436,
              0.19514800608158112,
              0.7247139811515808,
              0.19514800608158112,
              0.7207580208778381,
              0.19073499739170074,
              0.7247499823570251,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7248989939689636,
              0.19575099647045135,
              0.7248389720916748,
              0.1953900009393692,
              0.7415549755096436,
              0.19574999809265137,
              0.7248389720916748,
              0.1953900009393692,
              0.7415549755096436,
              0.1953890025615692,
              0.7415549755096436,
              0.19574999809265137,
              0.725013017654419,
              0.19700400531291962,
              0.7249569892883301,
              0.1962919980287552,
              0.7415549755096436,
              0.19700400531291962,
              0.7249569892883301,
              0.1962919980287552,
              0.7415549755096436,
              0.1962919980287552,
              0.7415549755096436,
              0.19700400531291962,
              0.7250649929046631,
              0.1978760063648224,
              0.725013017654419,
              0.19700400531291962,
              0.7415549755096436,
              0.1978760063648224,
              0.725013017654419,
              0.19700400531291962,
              0.7415549755096436,
              0.19700400531291962,
              0.7415549755096436,
              0.1978760063648224,
              0.7259629964828491,
              0.22147700190544128,
              0.7258949875831604,
              0.21938399970531464,
              0.7415549755096436,
              0.22147700190544128,
              0.7258949875831604,
              0.21938399970531464,
              0.7415549755096436,
              0.21938399970531464,
              0.7415549755096436,
              0.22147700190544128,
              0.7258949875831604,
              0.21938399970531464,
              0.7258269786834717,
              0.21731500327587128,
              0.7415549755096436,
              0.21938399970531464,
              0.7258269786834717,
              0.21731500327587128,
              0.7415549755096436,
              0.21731500327587128,
              0.7415549755096436,
              0.21938399970531464,
              0.7258269786834717,
              0.21731500327587128,
              0.7257570028305054,
              0.2152779996395111,
              0.7415549755096436,
              0.21731500327587128,
              0.7257570028305054,
              0.2152779996395111,
              0.7415549755096436,
              0.2152779996395111,
              0.7415549755096436,
              0.21731500327587128,
              0.7257570028305054,
              0.2152779996395111,
              0.7256870269775391,
              0.21328499913215637,
              0.7415549755096436,
              0.2152779996395111,
              0.7256870269775391,
              0.21328499913215637,
              0.7415549755096436,
              0.21328499913215637,
              0.7415549755096436,
              0.2152779996395111,
              0.7255489826202393,
              0.20946699380874634,
              0.7254809737205505,
              0.2076610028743744,
              0.7415549755096436,
              0.20946699380874634,
              0.7254809737205505,
              0.2076610028743744,
              0.7415549755096436,
              0.2076610028743744,
              0.7415549755096436,
              0.20946699380874634,
              0.7254149913787842,
              0.20593799650669098,
              0.725350022315979,
              0.20430700480937958,
              0.7415549755096436,
              0.20593799650669098,
              0.725350022315979,
              0.20430700480937958,
              0.7415549755096436,
              0.20430700480937958,
              0.7415549755096436,
              0.20593799650669098,
              0.725350022315979,
              0.20430700480937958,
              0.7252870202064514,
              0.20277799665927887,
              0.7415549755096436,
              0.20430700480937958,
              0.7252870202064514,
              0.20277799665927887,
              0.7415549755096436,
              0.20277799665927887,
              0.7415549755096436,
              0.20430700480937958,
              0.7252870202064514,
              0.20277799665927887,
              0.7252269983291626,
              0.20136100053787231,
              0.7415549755096436,
              0.20277799665927887,
              0.7252269983291626,
              0.20136100053787231,
              0.7415549755096436,
              0.20136100053787231,
              0.7415549755096436,
              0.20277799665927887,
              0.7248389720916748,
              0.1953900009393692,
              0.7247769832611084,
              0.1952189952135086,
              0.7415549755096436,
              0.1953890025615692,
              0.7247769832611084,
              0.1952189952135086,
              0.7415549755096436,
              0.1952189952135086,
              0.7415549755096436,
              0.1953890025615692,
              0.7249569892883301,
              0.1962919980287552,
              0.7248989939689636,
              0.19575099647045135,
              0.7415549755096436,
              0.1962919980287552,
              0.7248989939689636,
              0.19575099647045135,
              0.7415549755096436,
              0.19574999809265137,
              0.7415549755096436,
              0.1962919980287552,
              0.7251160144805908,
              0.1988999992609024,
              0.7250649929046631,
              0.1978760063648224,
              0.7415549755096436,
              0.1988999992609024,
              0.7250649929046631,
              0.1978760063648224,
              0.7415549755096436,
              0.1978760063648224,
              0.7415549755096436,
              0.1988999992609024,
              0.7260940074920654,
              0.22569400072097778,
              0.7260299921035767,
              0.22358399629592896,
              0.7415549755096436,
              0.22569400072097778,
              0.7260299921035767,
              0.22358399629592896,
              0.7415549755096436,
              0.22358399629592896,
              0.7415549755096436,
              0.22569400072097778,
              0.7260299921035767,
              0.22358399629592896,
              0.7259629964828491,
              0.22147700190544128,
              0.7415549755096436,
              0.22358399629592896,
              0.7259629964828491,
              0.22147700190544128,
              0.7415549755096436,
              0.22147700190544128,
              0.7415549755096436,
              0.22358399629592896,
              0.7256870269775391,
              0.21328499913215637,
              0.7256180047988892,
              0.21134500205516815,
              0.7415549755096436,
              0.21328499913215637,
              0.7256180047988892,
              0.21134500205516815,
              0.7415549755096436,
              0.21134500205516815,
              0.7415549755096436,
              0.21328499913215637,
              0.7256180047988892,
              0.21134500205516815,
              0.7255489826202393,
              0.20946699380874634,
              0.7415549755096436,
              0.21134500205516815,
              0.7255489826202393,
              0.20946699380874634,
              0.7415549755096436,
              0.20946699380874634,
              0.7415549755096436,
              0.21134500205516815,
              0.7254809737205505,
              0.2076610028743744,
              0.7254149913787842,
              0.20593799650669098,
              0.7415549755096436,
              0.2076610028743744,
              0.7254149913787842,
              0.20593799650669098,
              0.7415549755096436,
              0.20593799650669098,
              0.7415549755096436,
              0.2076610028743744,
              0.7252269983291626,
              0.20136100053787231,
              0.7251700162887573,
              0.2000650018453598,
              0.7415549755096436,
              0.20136100053787231,
              0.7251700162887573,
              0.2000650018453598,
              0.7415549755096436,
              0.2000650018453598,
              0.7415549755096436,
              0.20136100053787231,
              0.7251700162887573,
              0.2000650018453598,
              0.7251160144805908,
              0.1988999992609024,
              0.7415549755096436,
              0.2000650018453598,
              0.7251160144805908,
              0.1988999992609024,
              0.7415549755096436,
              0.1988999992609024,
              0.7415549755096436,
              0.2000650018453598,
              0.7260940074920654,
              0.22569400072097778,
              0.7415549755096436,
              0.22569400072097778,
              0.726144015789032,
              0.22777099907398224,
              0.7415549755096436,
              0.22569400072097778,
              0.7415549755096436,
              0.22777099907398224,
              0.726144015789032,
              0.22777099907398224,
              0.7248349785804749,
              0.18784800171852112,
              0.7415549755096436,
              0.18784800171852112,
              0.7247499823570251,
              0.1877090036869049,
              0.7415549755096436,
              0.18784800171852112,
              0.7415549755096436,
              0.1877090036869049,
              0.7247499823570251,
              0.1877090036869049,
              0.7248349785804749,
              0.18784800171852112,
              0.724918007850647,
              0.1882539987564087,
              0.7415549755096436,
              0.18784800171852112,
              0.724918007850647,
              0.1882539987564087,
              0.7415549755096436,
              0.1882539987564087,
              0.7415549755096436,
              0.18784800171852112,
              0.724918007850647,
              0.1882539987564087,
              0.7249979972839355,
              0.18891499936580658,
              0.7415549755096436,
              0.1882539987564087,
              0.7249979972839355,
              0.18891499936580658,
              0.7415549755096436,
              0.18891499936580658,
              0.7415549755096436,
              0.1882539987564087,
              0.7249979972839355,
              0.18891499936580658,
              0.7250739932060242,
              0.18981599807739258,
              0.7415549755096436,
              0.18891499936580658,
              0.7250739932060242,
              0.18981599807739258,
              0.7415549755096436,
              0.18981599807739258,
              0.7415549755096436,
              0.18891499936580658,
              0.7250739932060242,
              0.18981599807739258,
              0.725147008895874,
              0.19094400107860565,
              0.7415549755096436,
              0.18981599807739258,
              0.725147008895874,
              0.19094400107860565,
              0.7415549755096436,
              0.19094400107860565,
              0.7415549755096436,
              0.18981599807739258,
              0.725147008895874,
              0.19094400107860565,
              0.7252159714698792,
              0.19228500127792358,
              0.7415549755096436,
              0.19094400107860565,
              0.7252159714698792,
              0.19228500127792358,
              0.7415549755096436,
              0.19228500127792358,
              0.7415549755096436,
              0.19094400107860565,
              0.7252810001373291,
              0.19382600486278534,
              0.7253419756889343,
              0.19555099308490753,
              0.7415549755096436,
              0.19382600486278534,
              0.7253419756889343,
              0.19555099308490753,
              0.7415549755096436,
              0.19555099308490753,
              0.7415549755096436,
              0.19382600486278534,
              0.7255749702453613,
              0.20403599739074707,
              0.725633978843689,
              0.20648400485515594,
              0.7415549755096436,
              0.20403599739074707,
              0.725633978843689,
              0.20648400485515594,
              0.7415549755096436,
              0.20648400485515594,
              0.7415549755096436,
              0.20403599739074707,
              0.725695013999939,
              0.2090349942445755,
              0.7257580161094666,
              0.2116750031709671,
              0.7415549755096436,
              0.2090349942445755,
              0.7257580161094666,
              0.2116750031709671,
              0.7415549755096436,
              0.2116750031709671,
              0.7415549755096436,
              0.2090349942445755,
              0.7257580161094666,
              0.2116750031709671,
              0.7258239984512329,
              0.21439099311828613,
              0.7415549755096436,
              0.2116750031709671,
              0.7258239984512329,
              0.21439099311828613,
              0.7415549755096436,
              0.21439099311828613,
              0.7415549755096436,
              0.2116750031709671,
              0.7258239984512329,
              0.21439099311828613,
              0.7258899807929993,
              0.21716900169849396,
              0.7415549755096436,
              0.21439099311828613,
              0.7258899807929993,
              0.21716900169849396,
              0.7415549755096436,
              0.21716900169849396,
              0.7415549755096436,
              0.21439099311828613,
              0.7258899807929993,
              0.21716900169849396,
              0.725957989692688,
              0.21999500691890717,
              0.7415549755096436,
              0.21716900169849396,
              0.725957989692688,
              0.21999500691890717,
              0.7415549755096436,
              0.21999500691890717,
              0.7415549755096436,
              0.21716900169849396,
              0.7252159714698792,
              0.19228500127792358,
              0.7252810001373291,
              0.19382600486278534,
              0.7415549755096436,
              0.19228500127792358,
              0.7252810001373291,
              0.19382600486278534,
              0.7415549755096436,
              0.19382600486278534,
              0.7415549755096436,
              0.19228500127792358,
              0.7253419756889343,
              0.19555099308490753,
              0.7254049777984619,
              0.1974489986896515,
              0.7415549755096436,
              0.19555099308490753,
              0.7254049777984619,
              0.1974489986896515,
              0.7415549755096436,
              0.1974489986896515,
              0.7415549755096436,
              0.19555099308490753,
              0.7254049777984619,
              0.1974489986896515,
              0.7254670262336731,
              0.1995050013065338,
              0.7415549755096436,
              0.1974489986896515,
              0.7254670262336731,
              0.1995050013065338,
              0.7415549755096436,
              0.1995050013065338,
              0.7415549755096436,
              0.1974489986896515,
              0.7254670262336731,
              0.1995050013065338,
              0.7255190014839172,
              0.2017049938440323,
              0.7415549755096436,
              0.1995050013065338,
              0.7255190014839172,
              0.2017049938440323,
              0.7415549755096436,
              0.2017049938440323,
              0.7415549755096436,
              0.1995050013065338,
              0.7255190014839172,
              0.2017049938440323,
              0.7255749702453613,
              0.20403599739074707,
              0.7415549755096436,
              0.2017049938440323,
              0.7255749702453613,
              0.20403599739074707,
              0.7415549755096436,
              0.20403599739074707,
              0.7415549755096436,
              0.2017049938440323,
              0.725633978843689,
              0.20648400485515594,
              0.725695013999939,
              0.2090349942445755,
              0.7415549755096436,
              0.20648400485515594,
              0.725695013999939,
              0.2090349942445755,
              0.7415549755096436,
              0.2090349942445755,
              0.7415549755096436,
              0.20648400485515594,
              0.725957989692688,
              0.21999500691890717,
              0.7260259985923767,
              0.22285600006580353,
              0.7415549755096436,
              0.21999500691890717,
              0.7260259985923767,
              0.22285600006580353,
              0.7415549755096436,
              0.22285600006580353,
              0.7415549755096436,
              0.21999500691890717,
              0.7260259985923767,
              0.22285600006580353,
              0.7260950207710266,
              0.22573800384998322,
              0.7415549755096436,
              0.22285600006580353,
              0.7260950207710266,
              0.22573800384998322,
              0.7415549755096436,
              0.22573800384998322,
              0.7415549755096436,
              0.22285600006580353,
              0.7260950207710266,
              0.22573800384998322,
              0.7261639833450317,
              0.22862599790096283,
              0.7415549755096436,
              0.22573800384998322,
              0.7261639833450317,
              0.22862599790096283,
              0.7415549755096436,
              0.22862599790096283,
              0.7415549755096436,
              0.22573800384998322,
              0.7315750122070312,
              0.4545019865036011,
              0.7316139936447144,
              0.45609501004219055,
              0.7415549755096436,
              0.4545019865036011,
              0.7316139936447144,
              0.45609501004219055,
              0.7415549755096436,
              0.45609501004219055,
              0.7415549755096436,
              0.4545019865036011,
              0.7206799983978271,
              0.1906760036945343,
              0.7246339917182922,
              0.1950870007276535,
              0.7206680178642273,
              0.19049400091171265,
              0.7246339917182922,
              0.1950870007276535,
              0.72461998462677,
              0.19490200281143188,
              0.7206680178642273,
              0.19049400091171265,
              0.7206799983978271,
              0.18776999413967133,
              0.7245839834213257,
              0.18776999413967133,
              0.7207159996032715,
              0.1877090036869049,
              0.7245839834213257,
              0.18776999413967133,
              0.7246209979057312,
              0.1877090036869049,
              0.7207159996032715,
              0.1877090036869049,
              0.7207159996032715,
              0.19073599576950073,
              0.7246710062026978,
              0.19514800608158112,
              0.7206799983978271,
              0.1906760036945343,
              0.7246710062026978,
              0.19514800608158112,
              0.7246339917182922,
              0.1950870007276535,
              0.7206799983978271,
              0.1906760036945343,
              0.7206680178642273,
              0.18795199692249298,
              0.724573016166687,
              0.18795299530029297,
              0.7206799983978271,
              0.18776999413967133,
              0.724573016166687,
              0.18795299530029297,
              0.7245839834213257,
              0.18776999413967133,
              0.7206799983978271,
              0.18776999413967133,
              0.7246590256690979,
              0.188167005777359,
              0.7245749831199646,
              0.188167005777359,
              0.7246829867362976,
              0.19475799798965454,
              0.7245749831199646,
              0.188167005777359,
              0.7246189713478088,
              0.19468599557876587,
              0.7246829867362976,
              0.19475799798965454,
              0.7206680178642273,
              0.19049400091171265,
              0.72461998462677,
              0.19490200281143188,
              0.7206680178642273,
              0.1902800053358078,
              0.72461998462677,
              0.19490200281143188,
              0.7246189713478088,
              0.19468599557876587,
              0.7206680178642273,
              0.1902800053358078,
              0.7246209979057312,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7207159996032715,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7207580208778381,
              0.1877090036869049,
              0.7207159996032715,
              0.1877090036869049,
              0.7247340083122253,
              0.1952189952135086,
              0.7246710062026978,
              0.19514800608158112,
              0.7247769832611084,
              0.1952189952135086,
              0.7246710062026978,
              0.19514800608158112,
              0.7247139811515808,
              0.19514800608158112,
              0.7247769832611084,
              0.1952189952135086,
              0.7246590256690979,
              0.18795299530029297,
              0.724573016166687,
              0.18795299530029297,
              0.7246590256690979,
              0.188167005777359,
              0.724573016166687,
              0.18795299530029297,
              0.7245749831199646,
              0.188167005777359,
              0.7246590256690979,
              0.188167005777359,
              0.7247769832611084,
              0.1952189952135086,
              0.7247139811515808,
              0.19514800608158112,
              0.7415549755096436,
              0.1952189952135086,
              0.7247139811515808,
              0.19514800608158112,
              0.7415549755096436,
              0.19514800608158112,
              0.7415549755096436,
              0.1952189952135086,
              0.7207580208778381,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7246639728546143,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7261899709701538,
              0.23147399723529816,
              0.7262330055236816,
              0.23147399723529816,
              0.7261210083961487,
              0.22862599790096283,
              0.7262330055236816,
              0.23147399723529816,
              0.7261639833450317,
              0.22862599790096283,
              0.7261210083961487,
              0.22862599790096283,
              0.7260550260543823,
              0.22788499295711517,
              0.7260569930076599,
              0.2279839962720871,
              0.7260040044784546,
              0.22578300535678864,
              0.7260569930076599,
              0.2279839962720871,
              0.7260059714317322,
              0.22586199641227722,
              0.7260040044784546,
              0.22578300535678864,
              0.7261639833450317,
              0.22862599790096283,
              0.7262330055236816,
              0.23147399723529816,
              0.7415549755096436,
              0.22862599790096283,
              0.7262330055236816,
              0.23147399723529816,
              0.7415549755096436,
              0.23147399723529816,
              0.7415549755096436,
              0.22862599790096283,
              0.7315170168876648,
              0.45541200041770935,
              0.731544017791748,
              0.4565120041370392,
              0.7315059900283813,
              0.4554649889469147,
              0.731544017791748,
              0.4565120041370392,
              0.7315329909324646,
              0.45657598972320557,
              0.7315059900283813,
              0.4554649889469147,
              0.7316640019416809,
              0.46152499318122864,
              0.7316849827766418,
              0.46241798996925354,
              0.7316539883613586,
              0.4616360068321228,
              0.7316849827766418,
              0.46241798996925354,
              0.7316759824752808,
              0.4625380039215088,
              0.7316539883613586,
              0.4616360068321228,
              0.7316849827766418,
              0.46241798996925354,
              0.7317060232162476,
              0.46327200531959534,
              0.7316759824752808,
              0.4625380039215088,
              0.7317060232162476,
              0.46327200531959534,
              0.7316970229148865,
              0.4633989930152893,
              0.7316759824752808,
              0.4625380039215088,
              0.7317060232162476,
              0.46327200531959534,
              0.7317249774932861,
              0.4640839993953705,
              0.7316970229148865,
              0.4633989930152893,
              0.7317249774932861,
              0.4640839993953705,
              0.731715977191925,
              0.46421900391578674,
              0.7316970229148865,
              0.4633989930152893,
              0.7318300008773804,
              0.46848100423812866,
              0.7318410277366638,
              0.4689069986343384,
              0.7318220138549805,
              0.46865400671958923,
              0.7318410277366638,
              0.4689069986343384,
              0.7318329811096191,
              0.4690830111503601,
              0.7318220138549805,
              0.46865400671958923,
              0.7318410277366638,
              0.4689069986343384,
              0.7318500280380249,
              0.4692780077457428,
              0.7318329811096191,
              0.4690830111503601,
              0.7318500280380249,
              0.4692780077457428,
              0.7318419814109802,
              0.4694559872150421,
              0.7318329811096191,
              0.4690830111503601,
              0.7315710186958313,
              0.45765000581741333,
              0.7315340042114258,
              0.45608100295066833,
              0.7315580248832703,
              0.4575969874858856,
              0.7315340042114258,
              0.45608100295066833,
              0.7315199971199036,
              0.4560379981994629,
              0.7315580248832703,
              0.4575969874858856,
              0.7317109704017639,
              0.4634990096092224,
              0.7316780090332031,
              0.46210500597953796,
              0.7316970229148865,
              0.4634059965610504,
              0.7316780090332031,
              0.46210500597953796,
              0.7316640019416809,
              0.46202200651168823,
              0.7316970229148865,
              0.4634059965610504,
              0.7320200204849243,
              0.47639000415802,
              0.7320060133934021,
              0.47579801082611084,
              0.7320039868354797,
              0.4762139916419983,
              0.7320060133934021,
              0.47579801082611084,
              0.7319899797439575,
              0.47562599182128906,
              0.7320039868354797,
              0.4762139916419983,
              0.7320320010185242,
              0.4769040048122406,
              0.7320200204849243,
              0.47639000415802,
              0.7320160269737244,
              0.4767259955406189,
              0.7320200204849243,
              0.47639000415802,
              0.7320039868354797,
              0.4762139916419983,
              0.7320160269737244,
              0.4767259955406189,
              0.7320510149002075,
              0.4776949882507324,
              0.7320430278778076,
              0.4773400127887726,
              0.7320349812507629,
              0.47751399874687195,
              0.7320430278778076,
              0.4773400127887726,
              0.7320259809494019,
              0.4771600067615509,
              0.7320349812507629,
              0.47751399874687195,
              0.7314900159835815,
              0.4542819857597351,
              0.7315170168876648,
              0.45541200041770935,
              0.7314789891242981,
              0.45432499051094055,
              0.7315170168876648,
              0.45541200041770935,
              0.7315059900283813,
              0.4554649889469147,
              0.7314789891242981,
              0.45432499051094055,
              0.731544017791748,
              0.4565120041370392,
              0.7315689921379089,
              0.4575830101966858,
              0.7315329909324646,
              0.45657598972320557,
              0.7315689921379089,
              0.4575830101966858,
              0.7315589785575867,
              0.45765599608421326,
              0.7315329909324646,
              0.45657598972320557,
              0.7317609786987305,
              0.46557798981666565,
              0.731777012348175,
              0.4662570059299469,
              0.7317519783973694,
              0.4657270014286041,
              0.731777012348175,
              0.4662570059299469,
              0.7317690253257751,
              0.4664109945297241,
              0.7317519783973694,
              0.4657270014286041,
              0.7317919731140137,
              0.4668880105018616,
              0.7318059802055359,
              0.46747100353240967,
              0.7317839860916138,
              0.4670479893684387,
              0.7318059802055359,
              0.46747100353240967,
              0.731797993183136,
              0.46763500571250916,
              0.7317839860916138,
              0.4670479893684387,
              0.73191899061203,
              0.4721609950065613,
              0.7319110035896301,
              0.472339004278183,
              0.7318689823150635,
              0.47007301449775696,
              0.7319110035896301,
              0.472339004278183,
              0.7318609952926636,
              0.4702570140361786,
              0.7318689823150635,
              0.47007301449775696,
              0.7317429780960083,
              0.4648439884185791,
              0.7317109704017639,
              0.4634990096092224,
              0.7317289710044861,
              0.4647420048713684,
              0.7317109704017639,
              0.4634990096092224,
              0.7316970229148865,
              0.4634059965610504,
              0.7317289710044861,
              0.4647420048713684,
              0.7318040132522583,
              0.46737900376319885,
              0.7317739725112915,
              0.46613800525665283,
              0.7317889928817749,
              0.4672600030899048,
              0.7317739725112915,
              0.46613800525665283,
              0.7317600250244141,
              0.46602699160575867,
              0.7317889928817749,
              0.4672600030899048,
              0.7318329811096191,
              0.4685640037059784,
              0.7318040132522583,
              0.46737900376319885,
              0.7318170070648193,
              0.46843698620796204,
              0.7318040132522583,
              0.46737900376319885,
              0.7317889928817749,
              0.4672600030899048,
              0.7318170070648193,
              0.46843698620796204,
              0.7319319844245911,
              0.4727090001106262,
              0.7319089770317078,
              0.47176700830459595,
              0.7319160103797913,
              0.472555011510849,
              0.7319089770317078,
              0.47176700830459595,
              0.731893002986908,
              0.47161799669265747,
              0.7319160103797913,
              0.472555011510849,
              0.7319719791412354,
              0.4743939936161041,
              0.7319530248641968,
              0.47358599305152893,
              0.7319560050964355,
              0.47422999143600464,
              0.7319530248641968,
              0.47358599305152893,
              0.7319369912147522,
              0.4734260141849518,
              0.7319560050964355,
              0.47422999143600464,
              0.7239530086517334,
              0.18776999413967133,
              0.7243490219116211,
              0.18776999413967133,
              0.7239490151405334,
              0.18795199692249298,
              0.7243490219116211,
              0.18776999413967133,
              0.7243459820747375,
              0.18795199692249298,
              0.7239490151405334,
              0.18795199692249298,
              0.7235720157623291,
              0.18776999413967133,
              0.7239530086517334,
              0.18776999413967133,
              0.723567008972168,
              0.18795199692249298,
              0.7239530086517334,
              0.18776999413967133,
              0.7239490151405334,
              0.18795199692249298,
              0.723567008972168,
              0.18795199692249298,
              0.7228599786758423,
              0.18776999413967133,
              0.7232069969177246,
              0.18776999413967133,
              0.7228530049324036,
              0.18795199692249298,
              0.7232069969177246,
              0.18776999413967133,
              0.7232009768486023,
              0.18795199692249298,
              0.7228530049324036,
              0.18795199692249298,
              0.7205759882926941,
              0.18776999413967133,
              0.7205690145492554,
              0.18776999413967133,
              0.7205629944801331,
              0.18795199692249298,
              0.7205690145492554,
              0.18776999413967133,
              0.7205569744110107,
              0.18795199692249298,
              0.7205629944801331,
              0.18795199692249298,
              0.7247589826583862,
              0.18776999413967133,
              0.725180983543396,
              0.18776999413967133,
              0.7247570157051086,
              0.18795199692249298,
              0.725180983543396,
              0.18776999413967133,
              0.7251799702644348,
              0.18795199692249298,
              0.7247570157051086,
              0.18795199692249298,
              0.7216759920120239,
              0.18776999413967133,
              0.7219380140304565,
              0.18776999413967133,
              0.7216659784317017,
              0.18795199692249298,
              0.7219380140304565,
              0.18776999413967133,
              0.7219300270080566,
              0.18795199692249298,
              0.7216659784317017,
              0.18795199692249298,
              0.7210400104522705,
              0.18776999413967133,
              0.7212250232696533,
              0.18776999413967133,
              0.7210289835929871,
              0.18795199692249298,
              0.7212250232696533,
              0.18776999413967133,
              0.7212139964103699,
              0.18795199692249298,
              0.7210289835929871,
              0.18795199692249298,
              0.7208830118179321,
              0.18776999413967133,
              0.7210400104522705,
              0.18776999413967133,
              0.7208719849586487,
              0.18795199692249298,
              0.7210400104522705,
              0.18776999413967133,
              0.7210289835929871,
              0.18795199692249298,
              0.7208719849586487,
              0.18795199692249298,
              0.7205690145492554,
              0.18776999413967133,
              0.7205979824066162,
              0.18776999413967133,
              0.7205569744110107,
              0.18795199692249298,
              0.7205979824066162,
              0.18776999413967133,
              0.7205860018730164,
              0.18795199692249298,
              0.7205569744110107,
              0.18795199692249298,
              0.7206799983978271,
              0.18776999413967133,
              0.7206190228462219,
              0.18776999413967133,
              0.7206680178642273,
              0.18795199692249298,
              0.7206190228462219,
              0.18776999413967133,
              0.7206069827079773,
              0.18795199692249298,
              0.7206680178642273,
              0.18795199692249298,
              0.7256150245666504,
              0.18776999413967133,
              0.72604900598526,
              0.18776999413967133,
              0.7256140112876892,
              0.18795199692249298,
              0.72604900598526,
              0.18776999413967133,
              0.72604900598526,
              0.18795199692249298,
              0.7256140112876892,
              0.18795199692249298,
              0.7256150245666504,
              0.18969100713729858,
              0.725180983543396,
              0.18969400227069855,
              0.7256140112876892,
              0.18950699269771576,
              0.725180983543396,
              0.18969400227069855,
              0.7251799702644348,
              0.18951000273227692,
              0.7256140112876892,
              0.18950699269771576,
              0.725180983543396,
              0.18969400227069855,
              0.7247589826583862,
              0.18970300257205963,
              0.7251799702644348,
              0.18951000273227692,
              0.7247589826583862,
              0.18970300257205963,
              0.7247570157051086,
              0.189519003033638,
              0.7251799702644348,
              0.18951000273227692,
              0.7247589826583862,
              0.18970300257205963,
              0.7243490219116211,
              0.18971699476242065,
              0.7247570157051086,
              0.189519003033638,
              0.7243490219116211,
              0.18971699476242065,
              0.7243459820747375,
              0.18953299522399902,
              0.7247570157051086,
              0.189519003033638,
              0.7235720157623291,
              0.1897599995136261,
              0.7232069969177246,
              0.18978899717330933,
              0.723567008972168,
              0.18957599997520447,
              0.7232069969177246,
              0.18978899717330933,
              0.7232009768486023,
              0.1896049976348877,
              0.723567008972168,
              0.18957599997520447,
              0.7232069969177246,
              0.18978899717330933,
              0.7228599786758423,
              0.1898220032453537,
              0.7232009768486023,
              0.1896049976348877,
              0.7228599786758423,
              0.1898220032453537,
              0.7228530049324036,
              0.18963800370693207,
              0.7232009768486023,
              0.1896049976348877,
              0.7228599786758423,
              0.1898220032453537,
              0.7225319743156433,
              0.18985900282859802,
              0.7228530049324036,
              0.18963800370693207,
              0.7225319743156433,
              0.18985900282859802,
              0.7225239872932434,
              0.1896750032901764,
              0.7228530049324036,
              0.18963800370693207,
              0.7222239971160889,
              0.1898999959230423,
              0.7219380140304565,
              0.18994499742984772,
              0.722216010093689,
              0.18971599638462067,
              0.7219380140304565,
              0.18994499742984772,
              0.7219300270080566,
              0.1897609978914261,
              0.722216010093689,
              0.18971599638462067,
              0.7216759920120239,
              0.1899929940700531,
              0.7214369773864746,
              0.19004400074481964,
              0.7216659784317017,
              0.18980999290943146,
              0.7214369773864746,
              0.19004400074481964,
              0.7214270234107971,
              0.189860999584198,
              0.7216659784317017,
              0.18980999290943146,
              0.7208830118179321,
              0.19021500647068024,
              0.7207559943199158,
              0.19027699530124664,
              0.7208719849586487,
              0.1900320053100586,
              0.7207559943199158,
              0.19027699530124664,
              0.7207450270652771,
              0.190093994140625,
              0.7208719849586487,
              0.1900320053100586,
              0.7206609845161438,
              0.19033999741077423,
              0.7205979824066162,
              0.1904049962759018,
              0.720649003982544,
              0.1901569962501526,
              0.7205979824066162,
              0.1904049962759018,
              0.7205860018730164,
              0.19022299349308014,
              0.720649003982544,
              0.1901569962501526,
              0.7205690145492554,
              0.19047200679779053,
              0.7205759882926941,
              0.19054000079631805,
              0.7205569744110107,
              0.19029000401496887,
              0.7205759882926941,
              0.19054000079631805,
              0.7205629944801331,
              0.1903569996356964,
              0.7205569744110107,
              0.19029000401496887,
              0.7205759882926941,
              0.19054000079631805,
              0.7206190228462219,
              0.19060799479484558,
              0.7205629944801331,
              0.1903569996356964,
              0.7206190228462219,
              0.19060799479484558,
              0.7206069827079773,
              0.19042600691318512,
              0.7205629944801331,
              0.1903569996356964,
              0.7315940260887146,
              0.45862001180648804,
              0.7315689921379089,
              0.4575830101966858,
              0.7316300272941589,
              0.4585919976234436,
              0.7315689921379089,
              0.4575830101966858,
              0.7316060066223145,
              0.4575580060482025,
              0.7316300272941589,
              0.4585919976234436,
              0.7316179871559143,
              0.4596239924430847,
              0.7315940260887146,
              0.45862001180648804,
              0.7316539883613586,
              0.4595929980278015,
              0.7315940260887146,
              0.45862001180648804,
              0.7316300272941589,
              0.4585919976234436,
              0.7316539883613586,
              0.4595929980278015,
              0.7316640019416809,
              0.46152499318122864,
              0.7316420078277588,
              0.46059298515319824,
              0.7317000031471252,
              0.4614880084991455,
              0.7316420078277588,
              0.46059298515319824,
              0.7316769957542419,
              0.46055901050567627,
              0.7317000031471252,
              0.4614880084991455,
              0.7317609786987305,
              0.46557798981666565,
              0.7317439913749695,
              0.46485400199890137,
              0.7317960262298584,
              0.4655289947986603,
              0.7317439913749695,
              0.46485400199890137,
              0.7317789793014526,
              0.4648059904575348,
              0.7317960262298584,
              0.4655289947986603,
              0.7317919731140137,
              0.4668880105018616,
              0.731777012348175,
              0.4662570059299469,
              0.731827974319458,
              0.46683499217033386,
              0.731777012348175,
              0.4662570059299469,
              0.7318120002746582,
              0.46620601415634155,
              0.731827974319458,
              0.46683499217033386,
              0.731607973575592,
              0.45917800068855286,
              0.73164302110672,
              0.4606640040874481,
              0.7316449880599976,
              0.459199994802475,
              0.73164302110672,
              0.4606640040874481,
              0.7316809892654419,
              0.4606890082359314,
              0.7316449880599976,
              0.459199994802475,
              0.7317429780960083,
              0.4648439884185791,
              0.7317739725112915,
              0.46613800525665283,
              0.731781005859375,
              0.46487900614738464,
              0.7317739725112915,
              0.46613800525665283,
              0.7318120002746582,
              0.46617498993873596,
              0.731781005859375,
              0.46487900614738464,
              0.7318329811096191,
              0.4685640037059784,
              0.7318599820137024,
              0.4696919918060303,
              0.7318699955940247,
              0.46860700845718384,
              0.7318599820137024,
              0.4696919918060303,
              0.7318969964981079,
              0.4697369933128357,
              0.7318699955940247,
              0.46860700845718384,
              0.7321029901504517,
              0.4798569977283478,
              0.7321410179138184,
              0.4799160063266754,
              0.7320590019226074,
              0.4780080020427704,
              0.7321410179138184,
              0.4799160063266754,
              0.7320970296859741,
              0.478069007396698,
              0.7320590019226074,
              0.4780080020427704,
              0.7316420078277588,
              0.46059298515319824,
              0.7316179871559143,
              0.4596239924430847,
              0.7316769957542419,
              0.46055901050567627,
              0.7316179871559143,
              0.4596239924430847,
              0.7316539883613586,
              0.4595929980278015,
              0.7316769957542419,
              0.46055901050567627,
              0.7317439913749695,
              0.46485400199890137,
              0.7317249774932861,
              0.4640839993953705,
              0.7317789793014526,
              0.4648059904575348,
              0.7317249774932861,
              0.4640839993953705,
              0.7317609786987305,
              0.46404001116752625,
              0.7317789793014526,
              0.4648059904575348,
              0.7318189740180969,
              0.46800199151039124,
              0.7318059802055359,
              0.46747100353240967,
              0.7318540215492249,
              0.46794599294662476,
              0.7318059802055359,
              0.46747100353240967,
              0.7318410277366638,
              0.467415988445282,
              0.7318540215492249,
              0.46794599294662476,
              0.7318300008773804,
              0.46848100423812866,
              0.7318189740180969,
              0.46800199151039124,
              0.7318660020828247,
              0.468423992395401,
              0.7318189740180969,
              0.46800199151039124,
              0.7318540215492249,
              0.46794599294662476,
              0.7318660020828247,
              0.468423992395401,
              0.7318570017814636,
              0.4695909917354584,
              0.7318500280380249,
              0.4692780077457428,
              0.7318919897079468,
              0.46953099966049194,
              0.7318500280380249,
              0.4692780077457428,
              0.7318850159645081,
              0.469217985868454,
              0.7318919897079468,
              0.46953099966049194,
              0.7318630218505859,
              0.4698469936847687,
              0.7318570017814636,
              0.4695909917354584,
              0.7318980097770691,
              0.46978598833084106,
              0.7318570017814636,
              0.4695909917354584,
              0.7318919897079468,
              0.46953099966049194,
              0.7318980097770691,
              0.46978598833084106,
              0.7315710186958313,
              0.45765000581741333,
              0.731607973575592,
              0.45917800068855286,
              0.731607973575592,
              0.4576680064201355,
              0.731607973575592,
              0.45917800068855286,
              0.7316449880599976,
              0.459199994802475,
              0.731607973575592,
              0.4576680064201355,
              0.73164302110672,
              0.4606640040874481,
              0.7316780090332031,
              0.46210500597953796,
              0.7316809892654419,
              0.4606890082359314,
              0.7316780090332031,
              0.46210500597953796,
              0.7317150235176086,
              0.46213299036026,
              0.7316809892654419,
              0.4606890082359314,
              0.7318599820137024,
              0.4696919918060303,
              0.7318850159645081,
              0.4707599878311157,
              0.7318969964981079,
              0.4697369933128357,
              0.7318850159645081,
              0.4707599878311157,
              0.73192298412323,
              0.4708069860935211,
              0.7318969964981079,
              0.4697369933128357,
              0.7318850159645081,
              0.4707599878311157,
              0.7319089770317078,
              0.47176700830459595,
              0.73192298412323,
              0.4708069860935211,
              0.7319089770317078,
              0.47176700830459595,
              0.7319470047950745,
              0.4718160033226013,
              0.73192298412323,
              0.4708069860935211,
              0.7319319844245911,
              0.4727090001106262,
              0.7319530248641968,
              0.47358599305152893,
              0.7319700121879578,
              0.47276100516319275,
              0.7319530248641968,
              0.47358599305152893,
              0.7319909930229187,
              0.47363901138305664,
              0.7319700121879578,
              0.47276100516319275,
              0.7319719791412354,
              0.4743939936161041,
              0.7319899797439575,
              0.4751319885253906,
              0.732010006904602,
              0.4744490087032318,
              0.7319899797439575,
              0.4751319885253906,
              0.7320280075073242,
              0.4751890003681183,
              0.732010006904602,
              0.4744490087032318,
              0.7319899797439575,
              0.4751319885253906,
              0.7320060133934021,
              0.47579801082611084,
              0.7320280075073242,
              0.4751890003681183,
              0.7320060133934021,
              0.47579801082611084,
              0.732043981552124,
              0.4758560061454773,
              0.7320280075073242,
              0.4751890003681183,
              0.7320320010185242,
              0.4769040048122406,
              0.7320430278778076,
              0.4773400127887726,
              0.7320700287818909,
              0.47696399688720703,
              0.7320430278778076,
              0.4773400127887726,
              0.7320809960365295,
              0.477400004863739,
              0.7320700287818909,
              0.47696399688720703,
              0.7247589826583862,
              0.18776999413967133,
              0.7243490219116211,
              0.18776999413967133,
              0.7247650027275085,
              0.1877090036869049,
              0.7243490219116211,
              0.18776999413967133,
              0.7243580222129822,
              0.1877090036869049,
              0.7247650027275085,
              0.1877090036869049,
              0.7225319743156433,
              0.18776999413967133,
              0.7222239971160889,
              0.18776999413967133,
              0.7225540280342102,
              0.1877090036869049,
              0.7222239971160889,
              0.18776999413967133,
              0.7222489714622498,
              0.1877090036869049,
              0.7225540280342102,
              0.1877090036869049,
              0.7222239971160889,
              0.18776999413967133,
              0.7219380140304565,
              0.18776999413967133,
              0.7222489714622498,
              0.1877090036869049,
              0.7219380140304565,
              0.18776999413967133,
              0.7219650149345398,
              0.1877090036869049,
              0.7222489714622498,
              0.1877090036869049,
              0.7216759920120239,
              0.18776999413967133,
              0.7214369773864746,
              0.18776999413967133,
              0.7217040061950684,
              0.1877090036869049,
              0.7214369773864746,
              0.18776999413967133,
              0.7214679718017578,
              0.1877090036869049,
              0.7217040061950684,
              0.1877090036869049,
              0.7206609845161438,
              0.18776999413967133,
              0.7205979824066162,
              0.18776999413967133,
              0.7206969857215881,
              0.1877090036869049,
              0.7205979824066162,
              0.18776999413967133,
              0.7206339836120605,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7205759882926941,
              0.18776999413967133,
              0.7206190228462219,
              0.18776999413967133,
              0.7206119894981384,
              0.1877090036869049,
              0.7206190228462219,
              0.18776999413967133,
              0.7206550240516663,
              0.1877090036869049,
              0.7206119894981384,
              0.1877090036869049,
              0.7256150245666504,
              0.18776999413967133,
              0.725180983543396,
              0.18776999413967133,
              0.7256159782409668,
              0.1877090036869049,
              0.725180983543396,
              0.18776999413967133,
              0.7251840233802795,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7235720157623291,
              0.18776999413967133,
              0.7232069969177246,
              0.18776999413967133,
              0.7235869765281677,
              0.1877090036869049,
              0.7232069969177246,
              0.18776999413967133,
              0.7232249975204468,
              0.1877090036869049,
              0.7235869765281677,
              0.1877090036869049,
              0.7228599786758423,
              0.18776999413967133,
              0.7225319743156433,
              0.18776999413967133,
              0.7228800058364868,
              0.1877090036869049,
              0.7225319743156433,
              0.18776999413967133,
              0.7225540280342102,
              0.1877090036869049,
              0.7228800058364868,
              0.1877090036869049,
              0.7214369773864746,
              0.18776999413967133,
              0.7212250232696533,
              0.18776999413967133,
              0.7214679718017578,
              0.1877090036869049,
              0.7212250232696533,
              0.18776999413967133,
              0.7212569713592529,
              0.1877090036869049,
              0.7214679718017578,
              0.1877090036869049,
              0.7208830118179321,
              0.18776999413967133,
              0.7207559943199158,
              0.18776999413967133,
              0.7209169864654541,
              0.1877090036869049,
              0.7207559943199158,
              0.18776999413967133,
              0.7207909822463989,
              0.1877090036869049,
              0.7209169864654541,
              0.1877090036869049,
              0.7207559943199158,
              0.18776999413967133,
              0.7206609845161438,
              0.18776999413967133,
              0.7207909822463989,
              0.1877090036869049,
              0.7206609845161438,
              0.18776999413967133,
              0.7206969857215881,
              0.1877090036869049,
              0.7207909822463989,
              0.1877090036869049,
              0.7415549755096436,
              0.18776999413967133,
              0.72604900598526,
              0.18776999413967133,
              0.7415549755096436,
              0.1877090036869049,
              0.72604900598526,
              0.18776999413967133,
              0.72604900598526,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7239530086517334,
              0.1897359937429428,
              0.7243490219116211,
              0.18971699476242065,
              0.7239649891853333,
              0.18980200588703156,
              0.7243490219116211,
              0.18971699476242065,
              0.7243580222129822,
              0.1897830069065094,
              0.7239649891853333,
              0.18980200588703156,
              0.7235720157623291,
              0.1897599995136261,
              0.7239530086517334,
              0.1897359937429428,
              0.7235869765281677,
              0.18982599675655365,
              0.7239530086517334,
              0.1897359937429428,
              0.7239649891853333,
              0.18980200588703156,
              0.7235869765281677,
              0.18982599675655365,
              0.7222239971160889,
              0.1898999959230423,
              0.7225319743156433,
              0.18985900282859802,
              0.7222489714622498,
              0.18996499478816986,
              0.7225319743156433,
              0.18985900282859802,
              0.7225540280342102,
              0.18992400169372559,
              0.7222489714622498,
              0.18996499478816986,
              0.7216759920120239,
              0.1899929940700531,
              0.7219380140304565,
              0.18994499742984772,
              0.7217040061950684,
              0.19005699455738068,
              0.7219380140304565,
              0.18994499742984772,
              0.7219650149345398,
              0.19000999629497528,
              0.7217040061950684,
              0.19005699455738068,
              0.7212250232696533,
              0.19009900093078613,
              0.7214369773864746,
              0.19004400074481964,
              0.7212569713592529,
              0.19016200304031372,
              0.7214369773864746,
              0.19004400074481964,
              0.7214679718017578,
              0.19010800123214722,
              0.7212569713592529,
              0.19016200304031372,
              0.7210400104522705,
              0.1901559978723526,
              0.7212250232696533,
              0.19009900093078613,
              0.7210729718208313,
              0.1902189999818802,
              0.7212250232696533,
              0.19009900093078613,
              0.7212569713592529,
              0.19016200304031372,
              0.7210729718208313,
              0.1902189999818802,
              0.7208830118179321,
              0.19021500647068024,
              0.7210400104522705,
              0.1901559978723526,
              0.7209169864654541,
              0.19027799367904663,
              0.7210400104522705,
              0.1901559978723526,
              0.7210729718208313,
              0.1902189999818802,
              0.7209169864654541,
              0.19027799367904663,
              0.7206609845161438,
              0.19033999741077423,
              0.7207559943199158,
              0.19027699530124664,
              0.7206969857215881,
              0.19040200114250183,
              0.7207559943199158,
              0.19027699530124664,
              0.7207909822463989,
              0.19033899903297424,
              0.7206969857215881,
              0.19040200114250183,
              0.7205690145492554,
              0.19047200679779053,
              0.7205979824066162,
              0.1904049962759018,
              0.7206060290336609,
              0.19053299725055695,
              0.7205979824066162,
              0.1904049962759018,
              0.7206339836120605,
              0.1904670000076294,
              0.7206060290336609,
              0.19053299725055695,
              0.7206799983978271,
              0.1906760036945343,
              0.7206190228462219,
              0.19060799479484558,
              0.7207159996032715,
              0.19073599576950073,
              0.7206190228462219,
              0.19060799479484558,
              0.7206550240516663,
              0.1906680017709732,
              0.7207159996032715,
              0.19073599576950073,
              0.7256150245666504,
              0.18969100713729858,
              0.72604900598526,
              0.18969100713729858,
              0.7256159782409668,
              0.18975800275802612,
              0.72604900598526,
              0.18969100713729858,
              0.72604900598526,
              0.18975800275802612,
              0.7256159782409668,
              0.18975800275802612,
              0.72604900598526,
              0.18969100713729858,
              0.7415549755096436,
              0.18969100713729858,
              0.72604900598526,
              0.18975800275802612,
              0.7415549755096436,
              0.18969100713729858,
              0.7415549755096436,
              0.18975800275802612,
              0.72604900598526,
              0.18975800275802612,
              0.7315539717674255,
              0.4553939998149872,
              0.7315800189971924,
              0.45649099349975586,
              0.7315170168876648,
              0.45541200041770935,
              0.7315800189971924,
              0.45649099349975586,
              0.731544017791748,
              0.4565120041370392,
              0.7315170168876648,
              0.45541200041770935,
              0.7317000031471252,
              0.4614880084991455,
              0.7317209839820862,
              0.46237900853157043,
              0.7316640019416809,
              0.46152499318122864,
              0.7317209839820862,
              0.46237900853157043,
              0.7316849827766418,
              0.46241798996925354,
              0.7316640019416809,
              0.46152499318122864,
              0.7317209839820862,
              0.46237900853157043,
              0.7317410111427307,
              0.4632300138473511,
              0.7316849827766418,
              0.46241798996925354,
              0.7317410111427307,
              0.4632300138473511,
              0.7317060232162476,
              0.46327200531959534,
              0.7316849827766418,
              0.46241798996925354,
              0.7317410111427307,
              0.4632300138473511,
              0.7317609786987305,
              0.46404001116752625,
              0.7317060232162476,
              0.46327200531959534,
              0.7317609786987305,
              0.46404001116752625,
              0.7317249774932861,
              0.4640839993953705,
              0.7317060232162476,
              0.46327200531959534,
              0.7318660020828247,
              0.468423992395401,
              0.731876015663147,
              0.46884799003601074,
              0.7318300008773804,
              0.46848100423812866,
              0.731876015663147,
              0.46884799003601074,
              0.7318410277366638,
              0.4689069986343384,
              0.7318300008773804,
              0.46848100423812866,
              0.731876015663147,
              0.46884799003601074,
              0.7318850159645081,
              0.469217985868454,
              0.7318410277366638,
              0.4689069986343384,
              0.7318850159645081,
              0.469217985868454,
              0.7318500280380249,
              0.4692780077457428,
              0.7318410277366638,
              0.4689069986343384,
              0.731607973575592,
              0.4576680064201355,
              0.7315700054168701,
              0.45609501004219055,
              0.7315710186958313,
              0.45765000581741333,
              0.7315700054168701,
              0.45609501004219055,
              0.7315340042114258,
              0.45608100295066833,
              0.7315710186958313,
              0.45765000581741333,
              0.7317489981651306,
              0.4635300040245056,
              0.7317150235176086,
              0.46213299036026,
              0.7317109704017639,
              0.4634990096092224,
              0.7317150235176086,
              0.46213299036026,
              0.7316780090332031,
              0.46210500597953796,
              0.7317109704017639,
              0.4634990096092224,
              0.7320579886436462,
              0.4764479994773865,
              0.732043981552124,
              0.4758560061454773,
              0.7320200204849243,
              0.47639000415802,
              0.732043981552124,
              0.4758560061454773,
              0.7320060133934021,
              0.47579801082611084,
              0.7320200204849243,
              0.47639000415802,
              0.7320700287818909,
              0.47696399688720703,
              0.7320579886436462,
              0.4764479994773865,
              0.7320320010185242,
              0.4769040048122406,
              0.7320579886436462,
              0.4764479994773865,
              0.7320200204849243,
              0.47639000415802,
              0.7320320010185242,
              0.4769040048122406,
              0.7320889830589294,
              0.47775599360466003,
              0.7320809960365295,
              0.477400004863739,
              0.7320510149002075,
              0.4776949882507324,
              0.7320809960365295,
              0.477400004863739,
              0.7320430278778076,
              0.4773400127887726,
              0.7320510149002075,
              0.4776949882507324,
              0.7315269708633423,
              0.4542680084705353,
              0.7315539717674255,
              0.4553939998149872,
              0.7314900159835815,
              0.4542819857597351,
              0.7315539717674255,
              0.4553939998149872,
              0.7315170168876648,
              0.45541200041770935,
              0.7314900159835815,
              0.4542819857597351,
              0.7315800189971924,
              0.45649099349975586,
              0.7316060066223145,
              0.4575580060482025,
              0.731544017791748,
              0.4565120041370392,
              0.7316060066223145,
              0.4575580060482025,
              0.7315689921379089,
              0.4575830101966858,
              0.731544017791748,
              0.4565120041370392,
              0.7317960262298584,
              0.4655289947986603,
              0.7318120002746582,
              0.46620601415634155,
              0.7317609786987305,
              0.46557798981666565,
              0.7318120002746582,
              0.46620601415634155,
              0.731777012348175,
              0.4662570059299469,
              0.7317609786987305,
              0.46557798981666565,
              0.731827974319458,
              0.46683499217033386,
              0.7318410277366638,
              0.467415988445282,
              0.7317919731140137,
              0.4668880105018616,
              0.7318410277366638,
              0.467415988445282,
              0.7318059802055359,
              0.46747100353240967,
              0.7317919731140137,
              0.4668880105018616,
              0.7319539785385132,
              0.47210100293159485,
              0.73191899061203,
              0.4721609950065613,
              0.7319039702415466,
              0.47001200914382935,
              0.73191899061203,
              0.4721609950065613,
              0.7318689823150635,
              0.47007301449775696,
              0.7319039702415466,
              0.47001200914382935,
              0.731781005859375,
              0.46487900614738464,
              0.7317489981651306,
              0.4635300040245056,
              0.7317429780960083,
              0.4648439884185791,
              0.7317489981651306,
              0.4635300040245056,
              0.7317109704017639,
              0.4634990096092224,
              0.7317429780960083,
              0.4648439884185791,
              0.7318419814109802,
              0.46741899847984314,
              0.7318120002746582,
              0.46617498993873596,
              0.7318040132522583,
              0.46737900376319885,
              0.7318120002746582,
              0.46617498993873596,
              0.7317739725112915,
              0.46613800525665283,
              0.7318040132522583,
              0.46737900376319885,
              0.7318699955940247,
              0.46860700845718384,
              0.7318419814109802,
              0.46741899847984314,
              0.7318329811096191,
              0.4685640037059784,
              0.7318419814109802,
              0.46741899847984314,
              0.7318040132522583,
              0.46737900376319885,
              0.7318329811096191,
              0.4685640037059784,
              0.7319700121879578,
              0.47276100516319275,
              0.7319470047950745,
              0.4718160033226013,
              0.7319319844245911,
              0.4727090001106262,
              0.7319470047950745,
              0.4718160033226013,
              0.7319089770317078,
              0.47176700830459595,
              0.7319319844245911,
              0.4727090001106262,
              0.732010006904602,
              0.4744490087032318,
              0.7319909930229187,
              0.47363901138305664,
              0.7319719791412354,
              0.4743939936161041,
              0.7319909930229187,
              0.47363901138305664,
              0.7319530248641968,
              0.47358599305152893,
              0.7319719791412354,
              0.4743939936161041,
              0.7239649891853333,
              0.1877090036869049,
              0.7243580222129822,
              0.1877090036869049,
              0.7239530086517334,
              0.18776999413967133,
              0.7243580222129822,
              0.1877090036869049,
              0.7243490219116211,
              0.18776999413967133,
              0.7239530086517334,
              0.18776999413967133,
              0.7235869765281677,
              0.1877090036869049,
              0.7239649891853333,
              0.1877090036869049,
              0.7235720157623291,
              0.18776999413967133,
              0.7239649891853333,
              0.1877090036869049,
              0.7239530086517334,
              0.18776999413967133,
              0.7235720157623291,
              0.18776999413967133,
              0.7228800058364868,
              0.1877090036869049,
              0.7232249975204468,
              0.1877090036869049,
              0.7228599786758423,
              0.18776999413967133,
              0.7232249975204468,
              0.1877090036869049,
              0.7232069969177246,
              0.18776999413967133,
              0.7228599786758423,
              0.18776999413967133,
              0.7206119894981384,
              0.1877090036869049,
              0.7206060290336609,
              0.1877090036869049,
              0.7205759882926941,
              0.18776999413967133,
              0.7206060290336609,
              0.1877090036869049,
              0.7205690145492554,
              0.18776999413967133,
              0.7205759882926941,
              0.18776999413967133,
              0.7247650027275085,
              0.1877090036869049,
              0.7251840233802795,
              0.1877090036869049,
              0.7247589826583862,
              0.18776999413967133,
              0.7251840233802795,
              0.1877090036869049,
              0.725180983543396,
              0.18776999413967133,
              0.7247589826583862,
              0.18776999413967133,
              0.7217040061950684,
              0.1877090036869049,
              0.7219650149345398,
              0.1877090036869049,
              0.7216759920120239,
              0.18776999413967133,
              0.7219650149345398,
              0.1877090036869049,
              0.7219380140304565,
              0.18776999413967133,
              0.7216759920120239,
              0.18776999413967133,
              0.7210729718208313,
              0.1877090036869049,
              0.7212569713592529,
              0.1877090036869049,
              0.7210400104522705,
              0.18776999413967133,
              0.7212569713592529,
              0.1877090036869049,
              0.7212250232696533,
              0.18776999413967133,
              0.7210400104522705,
              0.18776999413967133,
              0.7209169864654541,
              0.1877090036869049,
              0.7210729718208313,
              0.1877090036869049,
              0.7208830118179321,
              0.18776999413967133,
              0.7210729718208313,
              0.1877090036869049,
              0.7210400104522705,
              0.18776999413967133,
              0.7208830118179321,
              0.18776999413967133,
              0.7206060290336609,
              0.1877090036869049,
              0.7206339836120605,
              0.1877090036869049,
              0.7205690145492554,
              0.18776999413967133,
              0.7206339836120605,
              0.1877090036869049,
              0.7205979824066162,
              0.18776999413967133,
              0.7205690145492554,
              0.18776999413967133,
              0.7207159996032715,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206799983978271,
              0.18776999413967133,
              0.7206550240516663,
              0.1877090036869049,
              0.7206190228462219,
              0.18776999413967133,
              0.7206799983978271,
              0.18776999413967133,
              0.7256159782409668,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7256150245666504,
              0.18776999413967133,
              0.72604900598526,
              0.1877090036869049,
              0.72604900598526,
              0.18776999413967133,
              0.7256150245666504,
              0.18776999413967133,
              0.7256159782409668,
              0.18975800275802612,
              0.7251840233802795,
              0.1897609978914261,
              0.7256150245666504,
              0.18969100713729858,
              0.7251840233802795,
              0.1897609978914261,
              0.725180983543396,
              0.18969400227069855,
              0.7256150245666504,
              0.18969100713729858,
              0.7251840233802795,
              0.1897609978914261,
              0.7247650027275085,
              0.18976899981498718,
              0.725180983543396,
              0.18969400227069855,
              0.7247650027275085,
              0.18976899981498718,
              0.7247589826583862,
              0.18970300257205963,
              0.725180983543396,
              0.18969400227069855,
              0.7247650027275085,
              0.18976899981498718,
              0.7243580222129822,
              0.1897830069065094,
              0.7247589826583862,
              0.18970300257205963,
              0.7243580222129822,
              0.1897830069065094,
              0.7243490219116211,
              0.18971699476242065,
              0.7247589826583862,
              0.18970300257205963,
              0.7235869765281677,
              0.18982599675655365,
              0.7232249975204468,
              0.1898539960384369,
              0.7235720157623291,
              0.1897599995136261,
              0.7232249975204468,
              0.1898539960384369,
              0.7232069969177246,
              0.18978899717330933,
              0.7235720157623291,
              0.1897599995136261,
              0.7232249975204468,
              0.1898539960384369,
              0.7228800058364868,
              0.18988700211048126,
              0.7232069969177246,
              0.18978899717330933,
              0.7228800058364868,
              0.18988700211048126,
              0.7228599786758423,
              0.1898220032453537,
              0.7232069969177246,
              0.18978899717330933,
              0.7228800058364868,
              0.18988700211048126,
              0.7225540280342102,
              0.18992400169372559,
              0.7228599786758423,
              0.1898220032453537,
              0.7225540280342102,
              0.18992400169372559,
              0.7225319743156433,
              0.18985900282859802,
              0.7228599786758423,
              0.1898220032453537,
              0.7222489714622498,
              0.18996499478816986,
              0.7219650149345398,
              0.19000999629497528,
              0.7222239971160889,
              0.1898999959230423,
              0.7219650149345398,
              0.19000999629497528,
              0.7219380140304565,
              0.18994499742984772,
              0.7222239971160889,
              0.1898999959230423,
              0.7217040061950684,
              0.19005699455738068,
              0.7214679718017578,
              0.19010800123214722,
              0.7216759920120239,
              0.1899929940700531,
              0.7214679718017578,
              0.19010800123214722,
              0.7214369773864746,
              0.19004400074481964,
              0.7216759920120239,
              0.1899929940700531,
              0.7209169864654541,
              0.19027799367904663,
              0.7207909822463989,
              0.19033899903297424,
              0.7208830118179321,
              0.19021500647068024,
              0.7207909822463989,
              0.19033899903297424,
              0.7207559943199158,
              0.19027699530124664,
              0.7208830118179321,
              0.19021500647068024,
              0.7206969857215881,
              0.19040200114250183,
              0.7206339836120605,
              0.1904670000076294,
              0.7206609845161438,
              0.19033999741077423,
              0.7206339836120605,
              0.1904670000076294,
              0.7205979824066162,
              0.1904049962759018,
              0.7206609845161438,
              0.19033999741077423,
              0.7206060290336609,
              0.19053299725055695,
              0.7206119894981384,
              0.19059999287128448,
              0.7205690145492554,
              0.19047200679779053,
              0.7206119894981384,
              0.19059999287128448,
              0.7205759882926941,
              0.19054000079631805,
              0.7205690145492554,
              0.19047200679779053,
              0.7206119894981384,
              0.19059999287128448,
              0.7206550240516663,
              0.1906680017709732,
              0.7205759882926941,
              0.19054000079631805,
              0.7206550240516663,
              0.1906680017709732,
              0.7206190228462219,
              0.19060799479484558,
              0.7205759882926941,
              0.19054000079631805,
              0.7315840125083923,
              0.45870399475097656,
              0.7315589785575867,
              0.45765599608421326,
              0.7315940260887146,
              0.45862001180648804,
              0.7315589785575867,
              0.45765599608421326,
              0.7315689921379089,
              0.4575830101966858,
              0.7315940260887146,
              0.45862001180648804,
              0.731607973575592,
              0.4597170054912567,
              0.7315840125083923,
              0.45870399475097656,
              0.7316179871559143,
              0.4596239924430847,
              0.7315840125083923,
              0.45870399475097656,
              0.7315940260887146,
              0.45862001180648804,
              0.7316179871559143,
              0.4596239924430847,
              0.7316539883613586,
              0.4616360068321228,
              0.7316319942474365,
              0.4606949985027313,
              0.7316640019416809,
              0.46152499318122864,
              0.7316319942474365,
              0.4606949985027313,
              0.7316420078277588,
              0.46059298515319824,
              0.7316640019416809,
              0.46152499318122864,
              0.7317519783973694,
              0.4657270014286041,
              0.7317349910736084,
              0.46499499678611755,
              0.7317609786987305,
              0.46557798981666565,
              0.7317349910736084,
              0.46499499678611755,
              0.7317439913749695,
              0.46485400199890137,
              0.7317609786987305,
              0.46557798981666565,
              0.7317839860916138,
              0.4670479893684387,
              0.7317690253257751,
              0.4664109945297241,
              0.7317919731140137,
              0.4668880105018616,
              0.7317690253257751,
              0.4664109945297241,
              0.731777012348175,
              0.4662570059299469,
              0.7317919731140137,
              0.4668880105018616,
              0.7315940260887146,
              0.45911499857902527,
              0.7316290140151978,
              0.46059098839759827,
              0.731607973575592,
              0.45917800068855286,
              0.7316290140151978,
              0.46059098839759827,
              0.73164302110672,
              0.4606640040874481,
              0.731607973575592,
              0.45917800068855286,
              0.7317289710044861,
              0.4647420048713684,
              0.7317600250244141,
              0.46602699160575867,
              0.7317429780960083,
              0.4648439884185791,
              0.7317600250244141,
              0.46602699160575867,
              0.7317739725112915,
              0.46613800525665283,
              0.7317429780960083,
              0.4648439884185791,
              0.7318170070648193,
              0.46843698620796204,
              0.7318440079689026,
              0.469556987285614,
              0.7318329811096191,
              0.4685640037059784,
              0.7318440079689026,
              0.469556987285614,
              0.7318599820137024,
              0.4696919918060303,
              0.7318329811096191,
              0.4685640037059784,
              0.7320870161056519,
              0.4796789884567261,
              0.7321029901504517,
              0.4798569977283478,
              0.7320420145988464,
              0.47782400250434875,
              0.7321029901504517,
              0.4798569977283478,
              0.7320590019226074,
              0.4780080020427704,
              0.7320420145988464,
              0.47782400250434875,
              0.7316319942474365,
              0.4606949985027313,
              0.731607973575592,
              0.4597170054912567,
              0.7316420078277588,
              0.46059298515319824,
              0.731607973575592,
              0.4597170054912567,
              0.7316179871559143,
              0.4596239924430847,
              0.7316420078277588,
              0.46059298515319824,
              0.7317349910736084,
              0.46499499678611755,
              0.731715977191925,
              0.46421900391578674,
              0.7317439913749695,
              0.46485400199890137,
              0.731715977191925,
              0.46421900391578674,
              0.7317249774932861,
              0.4640839993953705,
              0.7317439913749695,
              0.46485400199890137,
              0.731810986995697,
              0.46817100048065186,
              0.731797993183136,
              0.46763500571250916,
              0.7318189740180969,
              0.46800199151039124,
              0.731797993183136,
              0.46763500571250916,
              0.7318059802055359,
              0.46747100353240967,
              0.7318189740180969,
              0.46800199151039124,
              0.7318220138549805,
              0.46865400671958923,
              0.731810986995697,
              0.46817100048065186,
              0.7318300008773804,
              0.46848100423812866,
              0.731810986995697,
              0.46817100048065186,
              0.7318189740180969,
              0.46800199151039124,
              0.7318300008773804,
              0.46848100423812866,
              0.7318490147590637,
              0.46977099776268005,
              0.7318419814109802,
              0.4694559872150421,
              0.7318570017814636,
              0.4695909917354584,
              0.7318419814109802,
              0.4694559872150421,
              0.7318500280380249,
              0.4692780077457428,
              0.7318570017814636,
              0.4695909917354584,
              0.7318549752235413,
              0.47002899646759033,
              0.7318490147590637,
              0.46977099776268005,
              0.7318630218505859,
              0.4698469936847687,
              0.7318490147590637,
              0.46977099776268005,
              0.7318570017814636,
              0.4695909917354584,
              0.7318630218505859,
              0.4698469936847687,
              0.7315580248832703,
              0.4575969874858856,
              0.7315940260887146,
              0.45911499857902527,
              0.7315710186958313,
              0.45765000581741333,
              0.7315940260887146,
              0.45911499857902527,
              0.731607973575592,
              0.45917800068855286,
              0.7315710186958313,
              0.45765000581741333,
              0.7316290140151978,
              0.46059098839759827,
              0.7316640019416809,
              0.46202200651168823,
              0.73164302110672,
              0.4606640040874481,
              0.7316640019416809,
              0.46202200651168823,
              0.7316780090332031,
              0.46210500597953796,
              0.73164302110672,
              0.4606640040874481,
              0.7318440079689026,
              0.469556987285614,
              0.7318689823150635,
              0.47061899304389954,
              0.7318599820137024,
              0.4696919918060303,
              0.7318689823150635,
              0.47061899304389954,
              0.7318850159645081,
              0.4707599878311157,
              0.7318599820137024,
              0.4696919918060303,
              0.7318689823150635,
              0.47061899304389954,
              0.731893002986908,
              0.47161799669265747,
              0.7318850159645081,
              0.4707599878311157,
              0.731893002986908,
              0.47161799669265747,
              0.7319089770317078,
              0.47176700830459595,
              0.7318850159645081,
              0.4707599878311157,
              0.7319160103797913,
              0.472555011510849,
              0.7319369912147522,
              0.4734260141849518,
              0.7319319844245911,
              0.4727090001106262,
              0.7319369912147522,
              0.4734260141849518,
              0.7319530248641968,
              0.47358599305152893,
              0.7319319844245911,
              0.4727090001106262,
              0.7319560050964355,
              0.47422999143600464,
              0.7319740056991577,
              0.4749639928340912,
              0.7319719791412354,
              0.4743939936161041,
              0.7319740056991577,
              0.4749639928340912,
              0.7319899797439575,
              0.4751319885253906,
              0.7319719791412354,
              0.4743939936161041,
              0.7319740056991577,
              0.4749639928340912,
              0.7319899797439575,
              0.47562599182128906,
              0.7319899797439575,
              0.4751319885253906,
              0.7319899797439575,
              0.47562599182128906,
              0.7320060133934021,
              0.47579801082611084,
              0.7319899797439575,
              0.4751319885253906,
              0.7320160269737244,
              0.4767259955406189,
              0.7320259809494019,
              0.4771600067615509,
              0.7320320010185242,
              0.4769040048122406,
              0.7320259809494019,
              0.4771600067615509,
              0.7320430278778076,
              0.4773400127887726,
              0.7320320010185242,
              0.4769040048122406,
              0.7247570157051086,
              0.18795199692249298,
              0.7243459820747375,
              0.18795199692249298,
              0.7247589826583862,
              0.18776999413967133,
              0.7243459820747375,
              0.18795199692249298,
              0.7243490219116211,
              0.18776999413967133,
              0.7247589826583862,
              0.18776999413967133,
              0.7225239872932434,
              0.18795199692249298,
              0.722216010093689,
              0.18795199692249298,
              0.7225319743156433,
              0.18776999413967133,
              0.722216010093689,
              0.18795199692249298,
              0.7222239971160889,
              0.18776999413967133,
              0.7225319743156433,
              0.18776999413967133,
              0.722216010093689,
              0.18795199692249298,
              0.7219300270080566,
              0.18795199692249298,
              0.7222239971160889,
              0.18776999413967133,
              0.7219300270080566,
              0.18795199692249298,
              0.7219380140304565,
              0.18776999413967133,
              0.7222239971160889,
              0.18776999413967133,
              0.7216659784317017,
              0.18795199692249298,
              0.7214270234107971,
              0.18795199692249298,
              0.7216759920120239,
              0.18776999413967133,
              0.7214270234107971,
              0.18795199692249298,
              0.7214369773864746,
              0.18776999413967133,
              0.7216759920120239,
              0.18776999413967133,
              0.720649003982544,
              0.18795199692249298,
              0.7205860018730164,
              0.18795199692249298,
              0.7206609845161438,
              0.18776999413967133,
              0.7205860018730164,
              0.18795199692249298,
              0.7205979824066162,
              0.18776999413967133,
              0.7206609845161438,
              0.18776999413967133,
              0.7205629944801331,
              0.18795199692249298,
              0.7206069827079773,
              0.18795199692249298,
              0.7205759882926941,
              0.18776999413967133,
              0.7206069827079773,
              0.18795199692249298,
              0.7206190228462219,
              0.18776999413967133,
              0.7205759882926941,
              0.18776999413967133,
              0.7256140112876892,
              0.18795199692249298,
              0.7251799702644348,
              0.18795199692249298,
              0.7256150245666504,
              0.18776999413967133,
              0.7251799702644348,
              0.18795199692249298,
              0.725180983543396,
              0.18776999413967133,
              0.7256150245666504,
              0.18776999413967133,
              0.723567008972168,
              0.18795199692249298,
              0.7232009768486023,
              0.18795199692249298,
              0.7235720157623291,
              0.18776999413967133,
              0.7232009768486023,
              0.18795199692249298,
              0.7232069969177246,
              0.18776999413967133,
              0.7235720157623291,
              0.18776999413967133,
              0.7228530049324036,
              0.18795199692249298,
              0.7225239872932434,
              0.18795199692249298,
              0.7228599786758423,
              0.18776999413967133,
              0.7225239872932434,
              0.18795199692249298,
              0.7225319743156433,
              0.18776999413967133,
              0.7228599786758423,
              0.18776999413967133,
              0.7214270234107971,
              0.18795199692249298,
              0.7212139964103699,
              0.18795199692249298,
              0.7214369773864746,
              0.18776999413967133,
              0.7212139964103699,
              0.18795199692249298,
              0.7212250232696533,
              0.18776999413967133,
              0.7214369773864746,
              0.18776999413967133,
              0.7208719849586487,
              0.18795199692249298,
              0.7207450270652771,
              0.18795199692249298,
              0.7208830118179321,
              0.18776999413967133,
              0.7207450270652771,
              0.18795199692249298,
              0.7207559943199158,
              0.18776999413967133,
              0.7208830118179321,
              0.18776999413967133,
              0.7207450270652771,
              0.18795199692249298,
              0.720649003982544,
              0.18795199692249298,
              0.7207559943199158,
              0.18776999413967133,
              0.720649003982544,
              0.18795199692249298,
              0.7206609845161438,
              0.18776999413967133,
              0.7207559943199158,
              0.18776999413967133,
              0.7415549755096436,
              0.18795199692249298,
              0.72604900598526,
              0.18795199692249298,
              0.7415549755096436,
              0.18776999413967133,
              0.72604900598526,
              0.18795199692249298,
              0.72604900598526,
              0.18776999413967133,
              0.7415549755096436,
              0.18776999413967133,
              0.7239490151405334,
              0.18955199420452118,
              0.7243459820747375,
              0.18953299522399902,
              0.7239530086517334,
              0.1897359937429428,
              0.7243459820747375,
              0.18953299522399902,
              0.7243490219116211,
              0.18971699476242065,
              0.7239530086517334,
              0.1897359937429428,
              0.723567008972168,
              0.18957599997520447,
              0.7239490151405334,
              0.18955199420452118,
              0.7235720157623291,
              0.1897599995136261,
              0.7239490151405334,
              0.18955199420452118,
              0.7239530086517334,
              0.1897359937429428,
              0.7235720157623291,
              0.1897599995136261,
              0.722216010093689,
              0.18971599638462067,
              0.7225239872932434,
              0.1896750032901764,
              0.7222239971160889,
              0.1898999959230423,
              0.7225239872932434,
              0.1896750032901764,
              0.7225319743156433,
              0.18985900282859802,
              0.7222239971160889,
              0.1898999959230423,
              0.7216659784317017,
              0.18980999290943146,
              0.7219300270080566,
              0.1897609978914261,
              0.7216759920120239,
              0.1899929940700531,
              0.7219300270080566,
              0.1897609978914261,
              0.7219380140304565,
              0.18994499742984772,
              0.7216759920120239,
              0.1899929940700531,
              0.7212139964103699,
              0.1899159997701645,
              0.7214270234107971,
              0.189860999584198,
              0.7212250232696533,
              0.19009900093078613,
              0.7214270234107971,
              0.189860999584198,
              0.7214369773864746,
              0.19004400074481964,
              0.7212250232696533,
              0.19009900093078613,
              0.7210289835929871,
              0.18997299671173096,
              0.7212139964103699,
              0.1899159997701645,
              0.7210400104522705,
              0.1901559978723526,
              0.7212139964103699,
              0.1899159997701645,
              0.7212250232696533,
              0.19009900093078613,
              0.7210400104522705,
              0.1901559978723526,
              0.7208719849586487,
              0.1900320053100586,
              0.7210289835929871,
              0.18997299671173096,
              0.7208830118179321,
              0.19021500647068024,
              0.7210289835929871,
              0.18997299671173096,
              0.7210400104522705,
              0.1901559978723526,
              0.7208830118179321,
              0.19021500647068024,
              0.720649003982544,
              0.1901569962501526,
              0.7207450270652771,
              0.190093994140625,
              0.7206609845161438,
              0.19033999741077423,
              0.7207450270652771,
              0.190093994140625,
              0.7207559943199158,
              0.19027699530124664,
              0.7206609845161438,
              0.19033999741077423,
              0.7205569744110107,
              0.19029000401496887,
              0.7205860018730164,
              0.19022299349308014,
              0.7205690145492554,
              0.19047200679779053,
              0.7205860018730164,
              0.19022299349308014,
              0.7205979824066162,
              0.1904049962759018,
              0.7205690145492554,
              0.19047200679779053,
              0.7206680178642273,
              0.19049400091171265,
              0.7206069827079773,
              0.19042600691318512,
              0.7206799983978271,
              0.1906760036945343,
              0.7206069827079773,
              0.19042600691318512,
              0.7206190228462219,
              0.19060799479484558,
              0.7206799983978271,
              0.1906760036945343,
              0.7256140112876892,
              0.18950699269771576,
              0.72604900598526,
              0.18950699269771576,
              0.7256150245666504,
              0.18969100713729858,
              0.72604900598526,
              0.18950699269771576,
              0.72604900598526,
              0.18969100713729858,
              0.7256150245666504,
              0.18969100713729858,
              0.72604900598526,
              0.18950699269771576,
              0.7415549755096436,
              0.18950699269771576,
              0.72604900598526,
              0.18969100713729858,
              0.7415549755096436,
              0.18950699269771576,
              0.7415549755096436,
              0.18969100713729858,
              0.72604900598526,
              0.18969100713729858,
              0.7320209741592407,
              0.476949006319046,
              0.7318540215492249,
              0.4699830114841461,
              0.7320299744606018,
              0.4773010015487671,
              0.7318540215492249,
              0.4699830114841461,
              0.7318609952926636,
              0.4702419936656952,
              0.7320299744606018,
              0.4773010015487671,
              0.7320110201835632,
              0.476516991853714,
              0.7318469882011414,
              0.469664990901947,
              0.7320209741592407,
              0.476949006319046,
              0.7318469882011414,
              0.469664990901947,
              0.7318540215492249,
              0.4699830114841461,
              0.7320209741592407,
              0.476949006319046,
              0.7319989800453186,
              0.47600799798965454,
              0.7318379878997803,
              0.46928900480270386,
              0.7320110201835632,
              0.476516991853714,
              0.7318379878997803,
              0.46928900480270386,
              0.7318469882011414,
              0.469664990901947,
              0.7320110201835632,
              0.476516991853714,
              0.7319849729537964,
              0.47542300820350647,
              0.7318270206451416,
              0.46885600686073303,
              0.7319989800453186,
              0.47600799798965454,
              0.7318270206451416,
              0.46885600686073303,
              0.7318379878997803,
              0.46928900480270386,
              0.7319989800453186,
              0.47600799798965454,
              0.7319689989089966,
              0.47476598620414734,
              0.7318159937858582,
              0.4683690071105957,
              0.7319849729537964,
              0.47542300820350647,
              0.7318159937858582,
              0.4683690071105957,
              0.7318270206451416,
              0.46885600686073303,
              0.7319849729537964,
              0.47542300820350647,
              0.7319509983062744,
              0.4740369915962219,
              0.7318029999732971,
              0.46782800555229187,
              0.7319689989089966,
              0.47476598620414734,
              0.7318029999732971,
              0.46782800555229187,
              0.7318159937858582,
              0.4683690071105957,
              0.7319689989089966,
              0.47476598620414734,
              0.7319319844245911,
              0.473239004611969,
              0.7317889928817749,
              0.4672349989414215,
              0.7319509983062744,
              0.4740369915962219,
              0.7317889928817749,
              0.4672349989414215,
              0.7318029999732971,
              0.46782800555229187,
              0.7319509983062744,
              0.4740369915962219,
              0.7319120168685913,
              0.47237399220466614,
              0.7317730188369751,
              0.466592013835907,
              0.7319319844245911,
              0.473239004611969,
              0.7317730188369751,
              0.466592013835907,
              0.7317889928817749,
              0.4672349989414215,
              0.7319319844245911,
              0.473239004611969,
              0.731889009475708,
              0.4714449942111969,
              0.7317559719085693,
              0.4659009873867035,
              0.7319120168685913,
              0.47237399220466614,
              0.7317559719085693,
              0.4659009873867035,
              0.7317730188369751,
              0.466592013835907,
              0.7319120168685913,
              0.47237399220466614,
              0.7318660020828247,
              0.4704520106315613,
              0.7317389845848083,
              0.4651620090007782,
              0.731889009475708,
              0.4714449942111969,
              0.7317389845848083,
              0.4651620090007782,
              0.7317559719085693,
              0.4659009873867035,
              0.731889009475708,
              0.4714449942111969,
              0.7318400144577026,
              0.46939900517463684,
              0.731719970703125,
              0.4643769860267639,
              0.7318660020828247,
              0.4704520106315613,
              0.731719970703125,
              0.4643769860267639,
              0.7317389845848083,
              0.4651620090007782,
              0.7318660020828247,
              0.4704520106315613,
              0.7318140268325806,
              0.46828800439834595,
              0.7317000031471252,
              0.4635489881038666,
              0.7318400144577026,
              0.46939900517463684,
              0.7317000031471252,
              0.4635489881038666,
              0.731719970703125,
              0.4643769860267639,
              0.7318400144577026,
              0.46939900517463684,
              0.7318140268325806,
              0.46828800439834595,
              0.7317860126495361,
              0.4671199917793274,
              0.7317000031471252,
              0.4635489881038666,
              0.7317860126495361,
              0.4671199917793274,
              0.7316790223121643,
              0.4626779854297638,
              0.7317000031471252,
              0.4635489881038666,
              0.7317860126495361,
              0.4671199917793274,
              0.7317559719085693,
              0.46589699387550354,
              0.7316790223121643,
              0.4626779854297638,
              0.7317559719085693,
              0.46589699387550354,
              0.7316570281982422,
              0.46176600456237793,
              0.7316790223121643,
              0.4626779854297638,
              0.7317559719085693,
              0.46589699387550354,
              0.7317259907722473,
              0.46462199091911316,
              0.7316570281982422,
              0.46176600456237793,
              0.7317259907722473,
              0.46462199091911316,
              0.7316349744796753,
              0.4608150124549866,
              0.7316570281982422,
              0.46176600456237793,
              0.7317259907722473,
              0.46462199091911316,
              0.7316939830780029,
              0.4632970094680786,
              0.7316349744796753,
              0.4608150124549866,
              0.7316939830780029,
              0.4632970094680786,
              0.7316110134124756,
              0.4598270058631897,
              0.7316349744796753,
              0.4608150124549866,
              0.7316939830780029,
              0.4632970094680786,
              0.7316610217094421,
              0.4619239866733551,
              0.7316110134124756,
              0.4598270058631897,
              0.7316610217094421,
              0.4619239866733551,
              0.7315859794616699,
              0.4588020145893097,
              0.7316110134124756,
              0.4598270058631897,
              0.7316610217094421,
              0.4619239866733551,
              0.7316269874572754,
              0.4605039954185486,
              0.7315859794616699,
              0.4588020145893097,
              0.7316269874572754,
              0.4605039954185486,
              0.731561005115509,
              0.45774298906326294,
              0.7315859794616699,
              0.4588020145893097,
              0.7316269874572754,
              0.4605039954185486,
              0.7315919995307922,
              0.45903998613357544,
              0.731561005115509,
              0.45774298906326294,
              0.7315919995307922,
              0.45903998613357544,
              0.731535017490387,
              0.4566510021686554,
              0.731561005115509,
              0.45774298906326294,
              0.7315919995307922,
              0.45903998613357544,
              0.7315559983253479,
              0.45753398537635803,
              0.731535017490387,
              0.4566510021686554,
              0.7315559983253479,
              0.45753398537635803,
              0.7315080165863037,
              0.45552799105644226,
              0.731535017490387,
              0.4566510021686554,
              0.7315559983253479,
              0.45753398537635803,
              0.7315189838409424,
              0.45598798990249634,
              0.7315080165863037,
              0.45552799105644226,
              0.7315189838409424,
              0.45598798990249634,
              0.7314800024032593,
              0.4543749988079071,
              0.7315080165863037,
              0.45552799105644226,
              0.7320299744606018,
              0.4773010015487671,
              0.7318609952926636,
              0.4702419936656952,
              0.7320370078086853,
              0.4776090085506439,
              0.7318609952926636,
              0.4702419936656952,
              0.7318660020828247,
              0.4704720079898834,
              0.7320370078086853,
              0.4776090085506439,
              0.7251799702644348,
              0.18816600739955902,
              0.7256140112876892,
              0.18816600739955902,
              0.7251799702644348,
              0.18929600715637207,
              0.7256140112876892,
              0.18816600739955902,
              0.7256140112876892,
              0.1892929971218109,
              0.7251799702644348,
              0.18929600715637207,
              0.7247570157051086,
              0.18816600739955902,
              0.7251799702644348,
              0.18816600739955902,
              0.7247570157051086,
              0.18930499255657196,
              0.7251799702644348,
              0.18816600739955902,
              0.7251799702644348,
              0.18929600715637207,
              0.7247570157051086,
              0.18930499255657196,
              0.7243459820747375,
              0.18816600739955902,
              0.7247570157051086,
              0.18816600739955902,
              0.7243459820747375,
              0.18931899964809418,
              0.7247570157051086,
              0.18816600739955902,
              0.7247570157051086,
              0.18930499255657196,
              0.7243459820747375,
              0.18931899964809418,
              0.7239490151405334,
              0.18816600739955902,
              0.7243459820747375,
              0.18816600739955902,
              0.7239490151405334,
              0.18933799862861633,
              0.7243459820747375,
              0.18816600739955902,
              0.7243459820747375,
              0.18931899964809418,
              0.7239490151405334,
              0.18933799862861633,
              0.723567008972168,
              0.18816600739955902,
              0.7239490151405334,
              0.18816600739955902,
              0.723567008972168,
              0.18936200439929962,
              0.7239490151405334,
              0.18816600739955902,
              0.7239490151405334,
              0.18933799862861633,
              0.723567008972168,
              0.18936200439929962,
              0.7232009768486023,
              0.18816600739955902,
              0.723567008972168,
              0.18816600739955902,
              0.7232009768486023,
              0.18939100205898285,
              0.723567008972168,
              0.18816600739955902,
              0.723567008972168,
              0.18936200439929962,
              0.7232009768486023,
              0.18939100205898285,
              0.7228530049324036,
              0.18816600739955902,
              0.7232009768486023,
              0.18816600739955902,
              0.7228530049324036,
              0.18942399322986603,
              0.7232009768486023,
              0.18816600739955902,
              0.7232009768486023,
              0.18939100205898285,
              0.7228530049324036,
              0.18942399322986603,
              0.7225239872932434,
              0.18816600739955902,
              0.7228530049324036,
              0.18816600739955902,
              0.7225239872932434,
              0.18946099281311035,
              0.7228530049324036,
              0.18816600739955902,
              0.7228530049324036,
              0.18942399322986603,
              0.7225239872932434,
              0.18946099281311035,
              0.722216010093689,
              0.18816600739955902,
              0.7225239872932434,
              0.18816600739955902,
              0.722216010093689,
              0.18950200080871582,
              0.7225239872932434,
              0.18816600739955902,
              0.7225239872932434,
              0.18946099281311035,
              0.722216010093689,
              0.18950200080871582,
              0.7219300270080566,
              0.18816600739955902,
              0.722216010093689,
              0.18816600739955902,
              0.7219300270080566,
              0.18954700231552124,
              0.722216010093689,
              0.18816600739955902,
              0.722216010093689,
              0.18950200080871582,
              0.7219300270080566,
              0.18954700231552124,
              0.7216659784317017,
              0.18816600739955902,
              0.7219300270080566,
              0.18816600739955902,
              0.7216659784317017,
              0.1895959973335266,
              0.7219300270080566,
              0.18816600739955902,
              0.7219300270080566,
              0.18954700231552124,
              0.7216659784317017,
              0.1895959973335266,
              0.7214270234107971,
              0.18816600739955902,
              0.7216659784317017,
              0.18816600739955902,
              0.7214270234107971,
              0.18964700400829315,
              0.7216659784317017,
              0.18816600739955902,
              0.7216659784317017,
              0.1895959973335266,
              0.7214270234107971,
              0.18964700400829315,
              0.7212139964103699,
              0.18816600739955902,
              0.7214270234107971,
              0.18816600739955902,
              0.7212139964103699,
              0.18970200419425964,
              0.7214270234107971,
              0.18816600739955902,
              0.7214270234107971,
              0.18964700400829315,
              0.7212139964103699,
              0.18970200419425964,
              0.7210289835929871,
              0.18816600739955902,
              0.7212139964103699,
              0.18816600739955902,
              0.7210289835929871,
              0.1897590011358261,
              0.7212139964103699,
              0.18816600739955902,
              0.7212139964103699,
              0.18970200419425964,
              0.7210289835929871,
              0.1897590011358261,
              0.7208719849586487,
              0.18816600739955902,
              0.7210289835929871,
              0.18816600739955902,
              0.7208719849586487,
              0.18981799483299255,
              0.7210289835929871,
              0.18816600739955902,
              0.7210289835929871,
              0.1897590011358261,
              0.7208719849586487,
              0.18981799483299255,
              0.7207450270652771,
              0.18816600739955902,
              0.7208719849586487,
              0.18816600739955902,
              0.7207450270652771,
              0.18987999856472015,
              0.7208719849586487,
              0.18816600739955902,
              0.7208719849586487,
              0.18981799483299255,
              0.7207450270652771,
              0.18987999856472015,
              0.720649003982544,
              0.18816600739955902,
              0.7207450270652771,
              0.18816600739955902,
              0.720649003982544,
              0.18994399905204773,
              0.7207450270652771,
              0.18816600739955902,
              0.7207450270652771,
              0.18987999856472015,
              0.720649003982544,
              0.18994399905204773,
              0.7205860018730164,
              0.18816600739955902,
              0.720649003982544,
              0.18816600739955902,
              0.7205860018730164,
              0.1900089979171753,
              0.720649003982544,
              0.18816600739955902,
              0.720649003982544,
              0.18994399905204773,
              0.7205860018730164,
              0.1900089979171753,
              0.7205569744110107,
              0.18816600739955902,
              0.7205860018730164,
              0.18816600739955902,
              0.7205569744110107,
              0.19007599353790283,
              0.7205860018730164,
              0.18816600739955902,
              0.7205860018730164,
              0.1900089979171753,
              0.7205569744110107,
              0.19007599353790283,
              0.7205629944801331,
              0.18816600739955902,
              0.7205569744110107,
              0.18816600739955902,
              0.7205629944801331,
              0.19014300405979156,
              0.7205569744110107,
              0.18816600739955902,
              0.7205569744110107,
              0.19007599353790283,
              0.7205629944801331,
              0.19014300405979156,
              0.7206069827079773,
              0.18816600739955902,
              0.7205629944801331,
              0.18816600739955902,
              0.7206069827079773,
              0.19021199643611908,
              0.7205629944801331,
              0.18816600739955902,
              0.7205629944801331,
              0.19014300405979156,
              0.7206069827079773,
              0.19021199643611908,
              0.7206680178642273,
              0.18816600739955902,
              0.7206069827079773,
              0.18816600739955902,
              0.7206680178642273,
              0.1902800053358078,
              0.7206069827079773,
              0.18816600739955902,
              0.7206069827079773,
              0.19021199643611908,
              0.7206680178642273,
              0.1902800053358078,
              0.7256140112876892,
              0.18816600739955902,
              0.72604900598526,
              0.18816600739955902,
              0.7256140112876892,
              0.1892929971218109,
              0.72604900598526,
              0.18816600739955902,
              0.72604900598526,
              0.1892929971218109,
              0.7256140112876892,
              0.1892929971218109,
              0.72604900598526,
              0.18816600739955902,
              0.7415549755096436,
              0.18816600739955902,
              0.72604900598526,
              0.1892929971218109,
              0.7415549755096436,
              0.18816600739955902,
              0.7415549755096436,
              0.1892929971218109,
              0.72604900598526,
              0.1892929971218109,
              0.731535017490387,
              0.4566510021686554,
              0.7315080165863037,
              0.45552799105644226,
              0.7315329909324646,
              0.45657598972320557,
              0.7315080165863037,
              0.45552799105644226,
              0.7315059900283813,
              0.4554649889469147,
              0.7315329909324646,
              0.45657598972320557,
              0.7316790223121643,
              0.4626779854297638,
              0.7316570281982422,
              0.46176600456237793,
              0.7316759824752808,
              0.4625380039215088,
              0.7316570281982422,
              0.46176600456237793,
              0.7316539883613586,
              0.4616360068321228,
              0.7316759824752808,
              0.4625380039215088,
              0.7317000031471252,
              0.4635489881038666,
              0.7316790223121643,
              0.4626779854297638,
              0.7316970229148865,
              0.4633989930152893,
              0.7316790223121643,
              0.4626779854297638,
              0.7316759824752808,
              0.4625380039215088,
              0.7316970229148865,
              0.4633989930152893,
              0.731719970703125,
              0.4643769860267639,
              0.7317000031471252,
              0.4635489881038666,
              0.731715977191925,
              0.46421900391578674,
              0.7317000031471252,
              0.4635489881038666,
              0.7316970229148865,
              0.4633989930152893,
              0.731715977191925,
              0.46421900391578674,
              0.7318379878997803,
              0.46928900480270386,
              0.7318270206451416,
              0.46885600686073303,
              0.7318329811096191,
              0.4690830111503601,
              0.7318270206451416,
              0.46885600686073303,
              0.7318220138549805,
              0.46865400671958923,
              0.7318329811096191,
              0.4690830111503601,
              0.7318469882011414,
              0.469664990901947,
              0.7318379878997803,
              0.46928900480270386,
              0.7318419814109802,
              0.4694559872150421,
              0.7318379878997803,
              0.46928900480270386,
              0.7318329811096191,
              0.4690830111503601,
              0.7318419814109802,
              0.4694559872150421,
              0.7315189838409424,
              0.45598798990249634,
              0.7315559983253479,
              0.45753398537635803,
              0.7315199971199036,
              0.4560379981994629,
              0.7315559983253479,
              0.45753398537635803,
              0.7315580248832703,
              0.4575969874858856,
              0.7315199971199036,
              0.4560379981994629,
              0.7316610217094421,
              0.4619239866733551,
              0.7316939830780029,
              0.4632970094680786,
              0.7316640019416809,
              0.46202200651168823,
              0.7316939830780029,
              0.4632970094680786,
              0.7316970229148865,
              0.4634059965610504,
              0.7316640019416809,
              0.46202200651168823,
              0.7319849729537964,
              0.47542300820350647,
              0.7319989800453186,
              0.47600799798965454,
              0.7319899797439575,
              0.47562599182128906,
              0.7319989800453186,
              0.47600799798965454,
              0.7320039868354797,
              0.4762139916419983,
              0.7319899797439575,
              0.47562599182128906,
              0.7319989800453186,
              0.47600799798965454,
              0.7320110201835632,
              0.476516991853714,
              0.7320039868354797,
              0.4762139916419983,
              0.7320110201835632,
              0.476516991853714,
              0.7320160269737244,
              0.4767259955406189,
              0.7320039868354797,
              0.4762139916419983,
              0.7320209741592407,
              0.476949006319046,
              0.7320299744606018,
              0.4773010015487671,
              0.7320259809494019,
              0.4771600067615509,
              0.7320299744606018,
              0.4773010015487671,
              0.7320349812507629,
              0.47751399874687195,
              0.7320259809494019,
              0.4771600067615509,
              0.7315080165863037,
              0.45552799105644226,
              0.7314800024032593,
              0.4543749988079071,
              0.7315059900283813,
              0.4554649889469147,
              0.7314800024032593,
              0.4543749988079071,
              0.7314789891242981,
              0.45432499051094055,
              0.7315059900283813,
              0.4554649889469147,
              0.731561005115509,
              0.45774298906326294,
              0.731535017490387,
              0.4566510021686554,
              0.7315589785575867,
              0.45765599608421326,
              0.731535017490387,
              0.4566510021686554,
              0.7315329909324646,
              0.45657598972320557,
              0.7315589785575867,
              0.45765599608421326,
              0.7317730188369751,
              0.466592013835907,
              0.7317559719085693,
              0.4659009873867035,
              0.7317690253257751,
              0.4664109945297241,
              0.7317559719085693,
              0.4659009873867035,
              0.7317519783973694,
              0.4657270014286041,
              0.7317690253257751,
              0.4664109945297241,
              0.7318029999732971,
              0.46782800555229187,
              0.7317889928817749,
              0.4672349989414215,
              0.731797993183136,
              0.46763500571250916,
              0.7317889928817749,
              0.4672349989414215,
              0.7317839860916138,
              0.4670479893684387,
              0.731797993183136,
              0.46763500571250916,
              0.7319110035896301,
              0.472339004278183,
              0.7319160103797913,
              0.4725480079650879,
              0.7318609952926636,
              0.4702570140361786,
              0.7319160103797913,
              0.4725480079650879,
              0.7318660020828247,
              0.4704720079898834,
              0.7318609952926636,
              0.4702570140361786,
              0.7316939830780029,
              0.4632970094680786,
              0.7317259907722473,
              0.46462199091911316,
              0.7316970229148865,
              0.4634059965610504,
              0.7317259907722473,
              0.46462199091911316,
              0.7317289710044861,
              0.4647420048713684,
              0.7316970229148865,
              0.4634059965610504,
              0.7317559719085693,
              0.46589699387550354,
              0.7317860126495361,
              0.4671199917793274,
              0.7317600250244141,
              0.46602699160575867,
              0.7317860126495361,
              0.4671199917793274,
              0.7317889928817749,
              0.4672600030899048,
              0.7317600250244141,
              0.46602699160575867,
              0.7317860126495361,
              0.4671199917793274,
              0.7318140268325806,
              0.46828800439834595,
              0.7317889928817749,
              0.4672600030899048,
              0.7318140268325806,
              0.46828800439834595,
              0.7318170070648193,
              0.46843698620796204,
              0.7317889928817749,
              0.4672600030899048,
              0.731889009475708,
              0.4714449942111969,
              0.7319120168685913,
              0.47237399220466614,
              0.731893002986908,
              0.47161799669265747,
              0.7319120168685913,
              0.47237399220466614,
              0.7319160103797913,
              0.472555011510849,
              0.731893002986908,
              0.47161799669265747,
              0.7319319844245911,
              0.473239004611969,
              0.7319509983062744,
              0.4740369915962219,
              0.7319369912147522,
              0.4734260141849518,
              0.7319509983062744,
              0.4740369915962219,
              0.7319560050964355,
              0.47422999143600464,
              0.7319369912147522,
              0.4734260141849518,
              0.7243459820747375,
              0.18816600739955902,
              0.7239490151405334,
              0.18816600739955902,
              0.7243459820747375,
              0.18795199692249298,
              0.7239490151405334,
              0.18816600739955902,
              0.7239490151405334,
              0.18795199692249298,
              0.7243459820747375,
              0.18795199692249298,
              0.7239490151405334,
              0.18816600739955902,
              0.723567008972168,
              0.18816600739955902,
              0.7239490151405334,
              0.18795199692249298,
              0.723567008972168,
              0.18816600739955902,
              0.723567008972168,
              0.18795199692249298,
              0.7239490151405334,
              0.18795199692249298,
              0.7232009768486023,
              0.18816600739955902,
              0.7228530049324036,
              0.18816600739955902,
              0.7232009768486023,
              0.18795199692249298,
              0.7228530049324036,
              0.18816600739955902,
              0.7228530049324036,
              0.18795199692249298,
              0.7232009768486023,
              0.18795199692249298,
              0.7205569744110107,
              0.18816600739955902,
              0.7205629944801331,
              0.18816600739955902,
              0.7205569744110107,
              0.18795199692249298,
              0.7205629944801331,
              0.18816600739955902,
              0.7205629944801331,
              0.18795199692249298,
              0.7205569744110107,
              0.18795199692249298,
              0.7251799702644348,
              0.18816600739955902,
              0.7247570157051086,
              0.18816600739955902,
              0.7251799702644348,
              0.18795199692249298,
              0.7247570157051086,
              0.18816600739955902,
              0.7247570157051086,
              0.18795199692249298,
              0.7251799702644348,
              0.18795199692249298,
              0.7219300270080566,
              0.18816600739955902,
              0.7216659784317017,
              0.18816600739955902,
              0.7219300270080566,
              0.18795199692249298,
              0.7216659784317017,
              0.18816600739955902,
              0.7216659784317017,
              0.18795199692249298,
              0.7219300270080566,
              0.18795199692249298,
              0.7212139964103699,
              0.18816600739955902,
              0.7210289835929871,
              0.18816600739955902,
              0.7212139964103699,
              0.18795199692249298,
              0.7210289835929871,
              0.18816600739955902,
              0.7210289835929871,
              0.18795199692249298,
              0.7212139964103699,
              0.18795199692249298,
              0.7210289835929871,
              0.18816600739955902,
              0.7208719849586487,
              0.18816600739955902,
              0.7210289835929871,
              0.18795199692249298,
              0.7208719849586487,
              0.18816600739955902,
              0.7208719849586487,
              0.18795199692249298,
              0.7210289835929871,
              0.18795199692249298,
              0.7205860018730164,
              0.18816600739955902,
              0.7205569744110107,
              0.18816600739955902,
              0.7205860018730164,
              0.18795199692249298,
              0.7205569744110107,
              0.18816600739955902,
              0.7205569744110107,
              0.18795199692249298,
              0.7205860018730164,
              0.18795199692249298,
              0.7206069827079773,
              0.18816600739955902,
              0.7206680178642273,
              0.18816600739955902,
              0.7206069827079773,
              0.18795199692249298,
              0.7206680178642273,
              0.18816600739955902,
              0.7206680178642273,
              0.18795199692249298,
              0.7206069827079773,
              0.18795199692249298,
              0.72604900598526,
              0.18816600739955902,
              0.7256140112876892,
              0.18816600739955902,
              0.72604900598526,
              0.18795199692249298,
              0.7256140112876892,
              0.18816600739955902,
              0.7256140112876892,
              0.18795199692249298,
              0.72604900598526,
              0.18795199692249298,
              0.7251799702644348,
              0.18929600715637207,
              0.7256140112876892,
              0.1892929971218109,
              0.7251799702644348,
              0.18951000273227692,
              0.7256140112876892,
              0.1892929971218109,
              0.7256140112876892,
              0.18950699269771576,
              0.7251799702644348,
              0.18951000273227692,
              0.7247570157051086,
              0.18930499255657196,
              0.7251799702644348,
              0.18929600715637207,
              0.7247570157051086,
              0.189519003033638,
              0.7251799702644348,
              0.18929600715637207,
              0.7251799702644348,
              0.18951000273227692,
              0.7247570157051086,
              0.189519003033638,
              0.7243459820747375,
              0.18931899964809418,
              0.7247570157051086,
              0.18930499255657196,
              0.7243459820747375,
              0.18953299522399902,
              0.7247570157051086,
              0.18930499255657196,
              0.7247570157051086,
              0.189519003033638,
              0.7243459820747375,
              0.18953299522399902,
              0.7232009768486023,
              0.18939100205898285,
              0.723567008972168,
              0.18936200439929962,
              0.7232009768486023,
              0.1896049976348877,
              0.723567008972168,
              0.18936200439929962,
              0.723567008972168,
              0.18957599997520447,
              0.7232009768486023,
              0.1896049976348877,
              0.7228530049324036,
              0.18942399322986603,
              0.7232009768486023,
              0.18939100205898285,
              0.7228530049324036,
              0.18963800370693207,
              0.7232009768486023,
              0.18939100205898285,
              0.7232009768486023,
              0.1896049976348877,
              0.7228530049324036,
              0.18963800370693207,
              0.7225239872932434,
              0.18946099281311035,
              0.7228530049324036,
              0.18942399322986603,
              0.7225239872932434,
              0.1896750032901764,
              0.7228530049324036,
              0.18942399322986603,
              0.7228530049324036,
              0.18963800370693207,
              0.7225239872932434,
              0.1896750032901764,
              0.7219300270080566,
              0.18954700231552124,
              0.722216010093689,
              0.18950200080871582,
              0.7219300270080566,
              0.1897609978914261,
              0.722216010093689,
              0.18950200080871582,
              0.722216010093689,
              0.18971599638462067,
              0.7219300270080566,
              0.1897609978914261,
              0.7214270234107971,
              0.18964700400829315,
              0.7216659784317017,
              0.1895959973335266,
              0.7214270234107971,
              0.189860999584198,
              0.7216659784317017,
              0.1895959973335266,
              0.7216659784317017,
              0.18980999290943146,
              0.7214270234107971,
              0.189860999584198,
              0.7207450270652771,
              0.18987999856472015,
              0.7208719849586487,
              0.18981799483299255,
              0.7207450270652771,
              0.190093994140625,
              0.7208719849586487,
              0.18981799483299255,
              0.7208719849586487,
              0.1900320053100586,
              0.7207450270652771,
              0.190093994140625,
              0.7205860018730164,
              0.1900089979171753,
              0.720649003982544,
              0.18994399905204773,
              0.7205860018730164,
              0.19022299349308014,
              0.720649003982544,
              0.18994399905204773,
              0.720649003982544,
              0.1901569962501526,
              0.7205860018730164,
              0.19022299349308014,
              0.7205629944801331,
              0.19014300405979156,
              0.7205569744110107,
              0.19007599353790283,
              0.7205629944801331,
              0.1903569996356964,
              0.7205569744110107,
              0.19007599353790283,
              0.7205569744110107,
              0.19029000401496887,
              0.7205629944801331,
              0.1903569996356964,
              0.7206069827079773,
              0.19021199643611908,
              0.7205629944801331,
              0.19014300405979156,
              0.7206069827079773,
              0.19042600691318512,
              0.7205629944801331,
              0.19014300405979156,
              0.7205629944801331,
              0.1903569996356964,
              0.7206069827079773,
              0.19042600691318512,
              0.7316489815711975,
              0.4575580060482025,
              0.731673002243042,
              0.4585919976234436,
              0.7316060066223145,
              0.4575580060482025,
              0.731673002243042,
              0.4585919976234436,
              0.7316300272941589,
              0.4585919976234436,
              0.7316060066223145,
              0.4575580060482025,
              0.731673002243042,
              0.4585919976234436,
              0.7316970229148865,
              0.4595929980278015,
              0.7316300272941589,
              0.4585919976234436,
              0.7316970229148865,
              0.4595929980278015,
              0.7316539883613586,
              0.4595929980278015,
              0.7316300272941589,
              0.4585919976234436,
              0.731719970703125,
              0.46055901050567627,
              0.7317429780960083,
              0.4614880084991455,
              0.7316769957542419,
              0.46055901050567627,
              0.7317429780960083,
              0.4614880084991455,
              0.7317000031471252,
              0.4614880084991455,
              0.7316769957542419,
              0.46055901050567627,
              0.7318220138549805,
              0.4648059904575348,
              0.7318390011787415,
              0.4655289947986603,
              0.7317789793014526,
              0.4648059904575348,
              0.7318390011787415,
              0.4655289947986603,
              0.7317960262298584,
              0.4655289947986603,
              0.7317789793014526,
              0.4648059904575348,
              0.7318549752235413,
              0.46620601415634155,
              0.7318699955940247,
              0.46683499217033386,
              0.7318120002746582,
              0.46620601415634155,
              0.7318699955940247,
              0.46683499217033386,
              0.731827974319458,
              0.46683499217033386,
              0.7318120002746582,
              0.46620601415634155,
              0.7317240238189697,
              0.4606890082359314,
              0.7316880226135254,
              0.459199994802475,
              0.7316809892654419,
              0.4606890082359314,
              0.7316880226135254,
              0.459199994802475,
              0.7316449880599976,
              0.459199994802475,
              0.7316809892654419,
              0.4606890082359314,
              0.7318549752235413,
              0.46617498993873596,
              0.7318239808082581,
              0.46487900614738464,
              0.7318120002746582,
              0.46617498993873596,
              0.7318239808082581,
              0.46487900614738464,
              0.731781005859375,
              0.46487900614738464,
              0.7318120002746582,
              0.46617498993873596,
              0.731939971446991,
              0.4697369933128357,
              0.7319129705429077,
              0.46860700845718384,
              0.7318969964981079,
              0.4697369933128357,
              0.7319129705429077,
              0.46860700845718384,
              0.7318699955940247,
              0.46860700845718384,
              0.7318969964981079,
              0.4697369933128357,
              0.7321410179138184,
              0.4799160063266754,
              0.7321839928627014,
              0.4799160063266754,
              0.7320970296859741,
              0.478069007396698,
              0.7321839928627014,
              0.4799160063266754,
              0.732138991355896,
              0.478069007396698,
              0.7320970296859741,
              0.478069007396698,
              0.7316970229148865,
              0.4595929980278015,
              0.731719970703125,
              0.46055901050567627,
              0.7316539883613586,
              0.4595929980278015,
              0.731719970703125,
              0.46055901050567627,
              0.7316769957542419,
              0.46055901050567627,
              0.7316539883613586,
              0.4595929980278015,
              0.7318040132522583,
              0.46404001116752625,
              0.7318220138549805,
              0.4648059904575348,
              0.7317609786987305,
              0.46404001116752625,
              0.7318220138549805,
              0.4648059904575348,
              0.7317789793014526,
              0.4648059904575348,
              0.7317609786987305,
              0.46404001116752625,
              0.7318840026855469,
              0.467415988445282,
              0.7318969964981079,
              0.46794599294662476,
              0.7318410277366638,
              0.467415988445282,
              0.7318969964981079,
              0.46794599294662476,
              0.7318540215492249,
              0.46794599294662476,
              0.7318410277366638,
              0.467415988445282,
              0.7318969964981079,
              0.46794599294662476,
              0.7319080233573914,
              0.468423992395401,
              0.7318540215492249,
              0.46794599294662476,
              0.7319080233573914,
              0.468423992395401,
              0.7318660020828247,
              0.468423992395401,
              0.7318540215492249,
              0.46794599294662476,
              0.7319269776344299,
              0.469217985868454,
              0.7319350242614746,
              0.46953099966049194,
              0.7318850159645081,
              0.469217985868454,
              0.7319350242614746,
              0.46953099966049194,
              0.7318919897079468,
              0.46953099966049194,
              0.7318850159645081,
              0.469217985868454,
              0.7319350242614746,
              0.46953099966049194,
              0.7319409847259521,
              0.46978598833084106,
              0.7318919897079468,
              0.46953099966049194,
              0.7319409847259521,
              0.46978598833084106,
              0.7318980097770691,
              0.46978598833084106,
              0.7318919897079468,
              0.46953099966049194,
              0.7316880226135254,
              0.459199994802475,
              0.7316510081291199,
              0.4576680064201355,
              0.7316449880599976,
              0.459199994802475,
              0.7316510081291199,
              0.4576680064201355,
              0.731607973575592,
              0.4576680064201355,
              0.7316449880599976,
              0.459199994802475,
              0.7317579984664917,
              0.46213299036026,
              0.7317240238189697,
              0.4606890082359314,
              0.7317150235176086,
              0.46213299036026,
              0.7317240238189697,
              0.4606890082359314,
              0.7316809892654419,
              0.4606890082359314,
              0.7317150235176086,
              0.46213299036026,
              0.7319660186767578,
              0.4708069860935211,
              0.731939971446991,
              0.4697369933128357,
              0.73192298412323,
              0.4708069860935211,
              0.731939971446991,
              0.4697369933128357,
              0.7318969964981079,
              0.4697369933128357,
              0.73192298412323,
              0.4708069860935211,
              0.7319899797439575,
              0.4718160033226013,
              0.7319660186767578,
              0.4708069860935211,
              0.7319470047950745,
              0.4718160033226013,
              0.7319660186767578,
              0.4708069860935211,
              0.73192298412323,
              0.4708069860935211,
              0.7319470047950745,
              0.4718160033226013,
              0.7320330142974854,
              0.47363901138305664,
              0.7320119738578796,
              0.47276100516319275,
              0.7319909930229187,
              0.47363901138305664,
              0.7320119738578796,
              0.47276100516319275,
              0.7319700121879578,
              0.47276100516319275,
              0.7319909930229187,
              0.47363901138305664,
              0.7320700287818909,
              0.4751890003681183,
              0.7320529818534851,
              0.4744490087032318,
              0.7320280075073242,
              0.4751890003681183,
              0.7320529818534851,
              0.4744490087032318,
              0.732010006904602,
              0.4744490087032318,
              0.7320280075073242,
              0.4751890003681183,
              0.7320860028266907,
              0.4758560061454773,
              0.7320700287818909,
              0.4751890003681183,
              0.732043981552124,
              0.4758560061454773,
              0.7320700287818909,
              0.4751890003681183,
              0.7320280075073242,
              0.4751890003681183,
              0.732043981552124,
              0.4758560061454773,
              0.7321230173110962,
              0.477400004863739,
              0.7321130037307739,
              0.47696399688720703,
              0.7320809960365295,
              0.477400004863739,
              0.7321130037307739,
              0.47696399688720703,
              0.7320700287818909,
              0.47696399688720703,
              0.7320809960365295,
              0.477400004863739,
              0.7243689894676208,
              0.1877090036869049,
              0.7247729897499084,
              0.1877090036869049,
              0.7243580222129822,
              0.1877090036869049,
              0.7247729897499084,
              0.1877090036869049,
              0.7247650027275085,
              0.1877090036869049,
              0.7243580222129822,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7225800156593323,
              0.1877090036869049,
              0.7222489714622498,
              0.1877090036869049,
              0.7225800156593323,
              0.1877090036869049,
              0.7225540280342102,
              0.1877090036869049,
              0.7222489714622498,
              0.1877090036869049,
              0.721996009349823,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7219650149345398,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7222489714622498,
              0.1877090036869049,
              0.7219650149345398,
              0.1877090036869049,
              0.7215030193328857,
              0.1877090036869049,
              0.7217379808425903,
              0.1877090036869049,
              0.7214679718017578,
              0.1877090036869049,
              0.7217379808425903,
              0.1877090036869049,
              0.7217040061950684,
              0.1877090036869049,
              0.7214679718017578,
              0.1877090036869049,
              0.7206770181655884,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206339836120605,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7206339836120605,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206119894981384,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7251880168914795,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7251840233802795,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7251840233802795,
              0.1877090036869049,
              0.7232450246810913,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7232249975204468,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7235869765281677,
              0.1877090036869049,
              0.7232249975204468,
              0.1877090036869049,
              0.7225800156593323,
              0.1877090036869049,
              0.7229030132293701,
              0.1877090036869049,
              0.7225540280342102,
              0.1877090036869049,
              0.7229030132293701,
              0.1877090036869049,
              0.7228800058364868,
              0.1877090036869049,
              0.7225540280342102,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7215030193328857,
              0.1877090036869049,
              0.7212569713592529,
              0.1877090036869049,
              0.7215030193328857,
              0.1877090036869049,
              0.7214679718017578,
              0.1877090036869049,
              0.7212569713592529,
              0.1877090036869049,
              0.7208330035209656,
              0.1877090036869049,
              0.7209569811820984,
              0.1877090036869049,
              0.7207909822463989,
              0.1877090036869049,
              0.7209569811820984,
              0.1877090036869049,
              0.7209169864654541,
              0.1877090036869049,
              0.7207909822463989,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7208330035209656,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7208330035209656,
              0.1877090036869049,
              0.7207909822463989,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7243689894676208,
              0.18978999555110931,
              0.7239789962768555,
              0.18980899453163147,
              0.7243580222129822,
              0.1897830069065094,
              0.7239789962768555,
              0.18980899453163147,
              0.7239649891853333,
              0.18980200588703156,
              0.7243580222129822,
              0.1897830069065094,
              0.7239789962768555,
              0.18980899453163147,
              0.7236040234565735,
              0.18983200192451477,
              0.7239649891853333,
              0.18980200588703156,
              0.7236040234565735,
              0.18983200192451477,
              0.7235869765281677,
              0.18982599675655365,
              0.7239649891853333,
              0.18980200588703156,
              0.7225800156593323,
              0.1899300068616867,
              0.7222779989242554,
              0.189970001578331,
              0.7225540280342102,
              0.18992400169372559,
              0.7222779989242554,
              0.189970001578331,
              0.7222489714622498,
              0.18996499478816986,
              0.7225540280342102,
              0.18992400169372559,
              0.721996009349823,
              0.19001400470733643,
              0.7217379808425903,
              0.1900620013475418,
              0.7219650149345398,
              0.19000999629497528,
              0.7217379808425903,
              0.1900620013475418,
              0.7217040061950684,
              0.19005699455738068,
              0.7219650149345398,
              0.19000999629497528,
              0.7215030193328857,
              0.19011199474334717,
              0.7212939858436584,
              0.19016599655151367,
              0.7214679718017578,
              0.19010800123214722,
              0.7212939858436584,
              0.19016599655151367,
              0.7212569713592529,
              0.19016200304031372,
              0.7214679718017578,
              0.19010800123214722,
              0.7212939858436584,
              0.19016599655151367,
              0.7211120128631592,
              0.19022199511528015,
              0.7212569713592529,
              0.19016200304031372,
              0.7211120128631592,
              0.19022199511528015,
              0.7210729718208313,
              0.1902189999818802,
              0.7212569713592529,
              0.19016200304031372,
              0.7211120128631592,
              0.19022199511528015,
              0.7209569811820984,
              0.1902800053358078,
              0.7210729718208313,
              0.1902189999818802,
              0.7209569811820984,
              0.1902800053358078,
              0.7209169864654541,
              0.19027799367904663,
              0.7210729718208313,
              0.1902189999818802,
              0.7208330035209656,
              0.19034099578857422,
              0.7207390069961548,
              0.19040299952030182,
              0.7207909822463989,
              0.19033899903297424,
              0.7207390069961548,
              0.19040299952030182,
              0.7206969857215881,
              0.19040200114250183,
              0.7207909822463989,
              0.19033899903297424,
              0.7206770181655884,
              0.1904670000076294,
              0.7206479907035828,
              0.19053299725055695,
              0.7206339836120605,
              0.1904670000076294,
              0.7206479907035828,
              0.19053299725055695,
              0.7206060290336609,
              0.19053299725055695,
              0.7206339836120605,
              0.1904670000076294,
              0.7206969857215881,
              0.1906680017709732,
              0.7207580208778381,
              0.19073499739170074,
              0.7206550240516663,
              0.1906680017709732,
              0.7207580208778381,
              0.19073499739170074,
              0.7207159996032715,
              0.19073599576950073,
              0.7206550240516663,
              0.1906680017709732,
              0.72604900598526,
              0.18976500630378723,
              0.7256159782409668,
              0.18976500630378723,
              0.72604900598526,
              0.18975800275802612,
              0.7256159782409668,
              0.18976500630378723,
              0.7256159782409668,
              0.18975800275802612,
              0.72604900598526,
              0.18975800275802612,
              0.7415549755096436,
              0.18976500630378723,
              0.72604900598526,
              0.18976500630378723,
              0.7415549755096436,
              0.18975800275802612,
              0.72604900598526,
              0.18976500630378723,
              0.72604900598526,
              0.18975800275802612,
              0.7415549755096436,
              0.18975800275802612,
              0.7315970063209534,
              0.4553939998149872,
              0.7316229939460754,
              0.45649099349975586,
              0.7315539717674255,
              0.4553939998149872,
              0.7316229939460754,
              0.45649099349975586,
              0.7315800189971924,
              0.45649099349975586,
              0.7315539717674255,
              0.4553939998149872,
              0.7317429780960083,
              0.4614880084991455,
              0.731764018535614,
              0.46237900853157043,
              0.7317000031471252,
              0.4614880084991455,
              0.731764018535614,
              0.46237900853157043,
              0.7317209839820862,
              0.46237900853157043,
              0.7317000031471252,
              0.4614880084991455,
              0.731764018535614,
              0.46237900853157043,
              0.7317839860916138,
              0.4632300138473511,
              0.7317209839820862,
              0.46237900853157043,
              0.7317839860916138,
              0.4632300138473511,
              0.7317410111427307,
              0.4632300138473511,
              0.7317209839820862,
              0.46237900853157043,
              0.7317839860916138,
              0.4632300138473511,
              0.7318040132522583,
              0.46404001116752625,
              0.7317410111427307,
              0.4632300138473511,
              0.7318040132522583,
              0.46404001116752625,
              0.7317609786987305,
              0.46404001116752625,
              0.7317410111427307,
              0.4632300138473511,
              0.7319080233573914,
              0.468423992395401,
              0.73191899061203,
              0.46884799003601074,
              0.7318660020828247,
              0.468423992395401,
              0.73191899061203,
              0.46884799003601074,
              0.731876015663147,
              0.46884799003601074,
              0.7318660020828247,
              0.468423992395401,
              0.73191899061203,
              0.46884799003601074,
              0.7319269776344299,
              0.469217985868454,
              0.731876015663147,
              0.46884799003601074,
              0.7319269776344299,
              0.469217985868454,
              0.7318850159645081,
              0.469217985868454,
              0.731876015663147,
              0.46884799003601074,
              0.7316510081291199,
              0.4576680064201355,
              0.7316139936447144,
              0.45609501004219055,
              0.731607973575592,
              0.4576680064201355,
              0.7316139936447144,
              0.45609501004219055,
              0.7315700054168701,
              0.45609501004219055,
              0.731607973575592,
              0.4576680064201355,
              0.7317910194396973,
              0.4635300040245056,
              0.7317579984664917,
              0.46213299036026,
              0.7317489981651306,
              0.4635300040245056,
              0.7317579984664917,
              0.46213299036026,
              0.7317150235176086,
              0.46213299036026,
              0.7317489981651306,
              0.4635300040245056,
              0.7321010231971741,
              0.4764479994773865,
              0.7320860028266907,
              0.4758560061454773,
              0.7320579886436462,
              0.4764479994773865,
              0.7320860028266907,
              0.4758560061454773,
              0.732043981552124,
              0.4758560061454773,
              0.7320579886436462,
              0.4764479994773865,
              0.7321130037307739,
              0.47696399688720703,
              0.7321010231971741,
              0.4764479994773865,
              0.7320700287818909,
              0.47696399688720703,
              0.7321010231971741,
              0.4764479994773865,
              0.7320579886436462,
              0.4764479994773865,
              0.7320700287818909,
              0.47696399688720703,
              0.7321320176124573,
              0.47775599360466003,
              0.7321230173110962,
              0.477400004863739,
              0.7320889830589294,
              0.47775599360466003,
              0.7321230173110962,
              0.477400004863739,
              0.7320809960365295,
              0.477400004863739,
              0.7320889830589294,
              0.47775599360466003,
              0.7315700054168701,
              0.4542680084705353,
              0.7315970063209534,
              0.4553939998149872,
              0.7315269708633423,
              0.4542680084705353,
              0.7315970063209534,
              0.4553939998149872,
              0.7315539717674255,
              0.4553939998149872,
              0.7315269708633423,
              0.4542680084705353,
              0.7316229939460754,
              0.45649099349975586,
              0.7316489815711975,
              0.4575580060482025,
              0.7315800189971924,
              0.45649099349975586,
              0.7316489815711975,
              0.4575580060482025,
              0.7316060066223145,
              0.4575580060482025,
              0.7315800189971924,
              0.45649099349975586,
              0.7318390011787415,
              0.4655289947986603,
              0.7318549752235413,
              0.46620601415634155,
              0.7317960262298584,
              0.4655289947986603,
              0.7318549752235413,
              0.46620601415634155,
              0.7318120002746582,
              0.46620601415634155,
              0.7317960262298584,
              0.4655289947986603,
              0.7318699955940247,
              0.46683499217033386,
              0.7318840026855469,
              0.467415988445282,
              0.731827974319458,
              0.46683499217033386,
              0.7318840026855469,
              0.467415988445282,
              0.7318410277366638,
              0.467415988445282,
              0.731827974319458,
              0.46683499217033386,
              0.7319409847259521,
              0.46978598833084106,
              0.7319459915161133,
              0.47001200914382935,
              0.7318980097770691,
              0.46978598833084106,
              0.7319459915161133,
              0.47001200914382935,
              0.7319039702415466,
              0.47001200914382935,
              0.7318980097770691,
              0.46978598833084106,
              0.7318239808082581,
              0.46487900614738464,
              0.7317910194396973,
              0.4635300040245056,
              0.731781005859375,
              0.46487900614738464,
              0.7317910194396973,
              0.4635300040245056,
              0.7317489981651306,
              0.4635300040245056,
              0.731781005859375,
              0.46487900614738464,
              0.7318850159645081,
              0.46741899847984314,
              0.7318549752235413,
              0.46617498993873596,
              0.7318419814109802,
              0.46741899847984314,
              0.7318549752235413,
              0.46617498993873596,
              0.7318120002746582,
              0.46617498993873596,
              0.7318419814109802,
              0.46741899847984314,
              0.7319129705429077,
              0.46860700845718384,
              0.7318850159645081,
              0.46741899847984314,
              0.7318699955940247,
              0.46860700845718384,
              0.7318850159645081,
              0.46741899847984314,
              0.7318419814109802,
              0.46741899847984314,
              0.7318699955940247,
              0.46860700845718384,
              0.7320119738578796,
              0.47276100516319275,
              0.7319899797439575,
              0.4718160033226013,
              0.7319700121879578,
              0.47276100516319275,
              0.7319899797439575,
              0.4718160033226013,
              0.7319470047950745,
              0.4718160033226013,
              0.7319700121879578,
              0.47276100516319275,
              0.7320529818534851,
              0.4744490087032318,
              0.7320330142974854,
              0.47363901138305664,
              0.732010006904602,
              0.4744490087032318,
              0.7320330142974854,
              0.47363901138305664,
              0.7319909930229187,
              0.47363901138305664,
              0.732010006904602,
              0.4744490087032318,
              0.7239789962768555,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7239649891853333,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7243580222129822,
              0.1877090036869049,
              0.7239649891853333,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7239789962768555,
              0.1877090036869049,
              0.7235869765281677,
              0.1877090036869049,
              0.7239789962768555,
              0.1877090036869049,
              0.7239649891853333,
              0.1877090036869049,
              0.7235869765281677,
              0.1877090036869049,
              0.7229030132293701,
              0.1877090036869049,
              0.7232450246810913,
              0.1877090036869049,
              0.7228800058364868,
              0.1877090036869049,
              0.7232450246810913,
              0.1877090036869049,
              0.7232249975204468,
              0.1877090036869049,
              0.7228800058364868,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206479907035828,
              0.1877090036869049,
              0.7206119894981384,
              0.1877090036869049,
              0.7206479907035828,
              0.1877090036869049,
              0.7206060290336609,
              0.1877090036869049,
              0.7206119894981384,
              0.1877090036869049,
              0.7247729897499084,
              0.1877090036869049,
              0.7251880168914795,
              0.1877090036869049,
              0.7247650027275085,
              0.1877090036869049,
              0.7251880168914795,
              0.1877090036869049,
              0.7251840233802795,
              0.1877090036869049,
              0.7247650027275085,
              0.1877090036869049,
              0.7217379808425903,
              0.1877090036869049,
              0.721996009349823,
              0.1877090036869049,
              0.7217040061950684,
              0.1877090036869049,
              0.721996009349823,
              0.1877090036869049,
              0.7219650149345398,
              0.1877090036869049,
              0.7217040061950684,
              0.1877090036869049,
              0.7211120128631592,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7210729718208313,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7212569713592529,
              0.1877090036869049,
              0.7210729718208313,
              0.1877090036869049,
              0.7209569811820984,
              0.1877090036869049,
              0.7211120128631592,
              0.1877090036869049,
              0.7209169864654541,
              0.1877090036869049,
              0.7211120128631592,
              0.1877090036869049,
              0.7210729718208313,
              0.1877090036869049,
              0.7209169864654541,
              0.1877090036869049,
              0.7206479907035828,
              0.1877090036869049,
              0.7206770181655884,
              0.1877090036869049,
              0.7206060290336609,
              0.1877090036869049,
              0.7206770181655884,
              0.1877090036869049,
              0.7206339836120605,
              0.1877090036869049,
              0.7206060290336609,
              0.1877090036869049,
              0.7207580208778381,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7207159996032715,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7207159996032715,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7256159782409668,
              0.18976500630378723,
              0.7251880168914795,
              0.1897680014371872,
              0.7256159782409668,
              0.18975800275802612,
              0.7251880168914795,
              0.1897680014371872,
              0.7251840233802795,
              0.1897609978914261,
              0.7256159782409668,
              0.18975800275802612,
              0.7251880168914795,
              0.1897680014371872,
              0.7247729897499084,
              0.1897760033607483,
              0.7251840233802795,
              0.1897609978914261,
              0.7247729897499084,
              0.1897760033607483,
              0.7247650027275085,
              0.18976899981498718,
              0.7251840233802795,
              0.1897609978914261,
              0.7247729897499084,
              0.1897760033607483,
              0.7243689894676208,
              0.18978999555110931,
              0.7247650027275085,
              0.18976899981498718,
              0.7243689894676208,
              0.18978999555110931,
              0.7243580222129822,
              0.1897830069065094,
              0.7247650027275085,
              0.18976899981498718,
              0.7236040234565735,
              0.18983200192451477,
              0.7232450246810913,
              0.189860001206398,
              0.7235869765281677,
              0.18982599675655365,
              0.7232450246810913,
              0.189860001206398,
              0.7232249975204468,
              0.1898539960384369,
              0.7235869765281677,
              0.18982599675655365,
              0.7232450246810913,
              0.189860001206398,
              0.7229030132293701,
              0.18989300727844238,
              0.7232249975204468,
              0.1898539960384369,
              0.7229030132293701,
              0.18989300727844238,
              0.7228800058364868,
              0.18988700211048126,
              0.7232249975204468,
              0.1898539960384369,
              0.7229030132293701,
              0.18989300727844238,
              0.7225800156593323,
              0.1899300068616867,
              0.7228800058364868,
              0.18988700211048126,
              0.7225800156593323,
              0.1899300068616867,
              0.7225540280342102,
              0.18992400169372559,
              0.7228800058364868,
              0.18988700211048126,
              0.7222779989242554,
              0.189970001578331,
              0.721996009349823,
              0.19001400470733643,
              0.7222489714622498,
              0.18996499478816986,
              0.721996009349823,
              0.19001400470733643,
              0.7219650149345398,
              0.19000999629497528,
              0.7222489714622498,
              0.18996499478816986,
              0.7217379808425903,
              0.1900620013475418,
              0.7215030193328857,
              0.19011199474334717,
              0.7217040061950684,
              0.19005699455738068,
              0.7215030193328857,
              0.19011199474334717,
              0.7214679718017578,
              0.19010800123214722,
              0.7217040061950684,
              0.19005699455738068,
              0.7209569811820984,
              0.1902800053358078,
              0.7208330035209656,
              0.19034099578857422,
              0.7209169864654541,
              0.19027799367904663,
              0.7208330035209656,
              0.19034099578857422,
              0.7207909822463989,
              0.19033899903297424,
              0.7209169864654541,
              0.19027799367904663,
              0.7207390069961548,
              0.19040299952030182,
              0.7206770181655884,
              0.1904670000076294,
              0.7206969857215881,
              0.19040200114250183,
              0.7206770181655884,
              0.1904670000076294,
              0.7206339836120605,
              0.1904670000076294,
              0.7206969857215881,
              0.19040200114250183,
              0.7206479907035828,
              0.19053299725055695,
              0.7206550240516663,
              0.19059999287128448,
              0.7206060290336609,
              0.19053299725055695,
              0.7206550240516663,
              0.19059999287128448,
              0.7206119894981384,
              0.19059999287128448,
              0.7206060290336609,
              0.19053299725055695,
              0.7206550240516663,
              0.19059999287128448,
              0.7206969857215881,
              0.1906680017709732,
              0.7206119894981384,
              0.19059999287128448,
              0.7206969857215881,
              0.1906680017709732,
              0.7206550240516663,
              0.1906680017709732,
              0.7206119894981384,
              0.19059999287128448,
              0.7315859794616699,
              0.4588020145893097,
              0.731561005115509,
              0.45774298906326294,
              0.7315840125083923,
              0.45870399475097656,
              0.731561005115509,
              0.45774298906326294,
              0.7315589785575867,
              0.45765599608421326,
              0.7315840125083923,
              0.45870399475097656,
              0.7316110134124756,
              0.4598270058631897,
              0.7315859794616699,
              0.4588020145893097,
              0.731607973575592,
              0.4597170054912567,
              0.7315859794616699,
              0.4588020145893097,
              0.7315840125083923,
              0.45870399475097656,
              0.731607973575592,
              0.4597170054912567,
              0.7316570281982422,
              0.46176600456237793,
              0.7316349744796753,
              0.4608150124549866,
              0.7316539883613586,
              0.4616360068321228,
              0.7316349744796753,
              0.4608150124549866,
              0.7316319942474365,
              0.4606949985027313,
              0.7316539883613586,
              0.4616360068321228,
              0.7317559719085693,
              0.4659009873867035,
              0.7317389845848083,
              0.4651620090007782,
              0.7317519783973694,
              0.4657270014286041,
              0.7317389845848083,
              0.4651620090007782,
              0.7317349910736084,
              0.46499499678611755,
              0.7317519783973694,
              0.4657270014286041,
              0.7317889928817749,
              0.4672349989414215,
              0.7317730188369751,
              0.466592013835907,
              0.7317839860916138,
              0.4670479893684387,
              0.7317730188369751,
              0.466592013835907,
              0.7317690253257751,
              0.4664109945297241,
              0.7317839860916138,
              0.4670479893684387,
              0.7315919995307922,
              0.45903998613357544,
              0.7316269874572754,
              0.4605039954185486,
              0.7315940260887146,
              0.45911499857902527,
              0.7316269874572754,
              0.4605039954185486,
              0.7316290140151978,
              0.46059098839759827,
              0.7315940260887146,
              0.45911499857902527,
              0.7317259907722473,
              0.46462199091911316,
              0.7317559719085693,
              0.46589699387550354,
              0.7317289710044861,
              0.4647420048713684,
              0.7317559719085693,
              0.46589699387550354,
              0.7317600250244141,
              0.46602699160575867,
              0.7317289710044861,
              0.4647420048713684,
              0.7318140268325806,
              0.46828800439834595,
              0.7318400144577026,
              0.46939900517463684,
              0.7318170070648193,
              0.46843698620796204,
              0.7318400144577026,
              0.46939900517463684,
              0.7318440079689026,
              0.469556987285614,
              0.7318170070648193,
              0.46843698620796204,
              0.7320349812507629,
              0.47751399874687195,
              0.7320299744606018,
              0.4773010015487671,
              0.7320420145988464,
              0.47782400250434875,
              0.7320299744606018,
              0.4773010015487671,
              0.7320370078086853,
              0.4776090085506439,
              0.7320420145988464,
              0.47782400250434875,
              0.7316349744796753,
              0.4608150124549866,
              0.7316110134124756,
              0.4598270058631897,
              0.7316319942474365,
              0.4606949985027313,
              0.7316110134124756,
              0.4598270058631897,
              0.731607973575592,
              0.4597170054912567,
              0.7316319942474365,
              0.4606949985027313,
              0.7317389845848083,
              0.4651620090007782,
              0.731719970703125,
              0.4643769860267639,
              0.7317349910736084,
              0.46499499678611755,
              0.731719970703125,
              0.4643769860267639,
              0.731715977191925,
              0.46421900391578674,
              0.7317349910736084,
              0.46499499678611755,
              0.7318159937858582,
              0.4683690071105957,
              0.7318029999732971,
              0.46782800555229187,
              0.731810986995697,
              0.46817100048065186,
              0.7318029999732971,
              0.46782800555229187,
              0.731797993183136,
              0.46763500571250916,
              0.731810986995697,
              0.46817100048065186,
              0.7318270206451416,
              0.46885600686073303,
              0.7318159937858582,
              0.4683690071105957,
              0.7318220138549805,
              0.46865400671958923,
              0.7318159937858582,
              0.4683690071105957,
              0.731810986995697,
              0.46817100048065186,
              0.7318220138549805,
              0.46865400671958923,
              0.7318540215492249,
              0.4699830114841461,
              0.7318469882011414,
              0.469664990901947,
              0.7318490147590637,
              0.46977099776268005,
              0.7318469882011414,
              0.469664990901947,
              0.7318419814109802,
              0.4694559872150421,
              0.7318490147590637,
              0.46977099776268005,
              0.7318609952926636,
              0.4702419936656952,
              0.7318540215492249,
              0.4699830114841461,
              0.7318549752235413,
              0.47002899646759033,
              0.7318540215492249,
              0.4699830114841461,
              0.7318490147590637,
              0.46977099776268005,
              0.7318549752235413,
              0.47002899646759033,
              0.7315559983253479,
              0.45753398537635803,
              0.7315919995307922,
              0.45903998613357544,
              0.7315580248832703,
              0.4575969874858856,
              0.7315919995307922,
              0.45903998613357544,
              0.7315940260887146,
              0.45911499857902527,
              0.7315580248832703,
              0.4575969874858856,
              0.7316269874572754,
              0.4605039954185486,
              0.7316610217094421,
              0.4619239866733551,
              0.7316290140151978,
              0.46059098839759827,
              0.7316610217094421,
              0.4619239866733551,
              0.7316640019416809,
              0.46202200651168823,
              0.7316290140151978,
              0.46059098839759827,
              0.7318400144577026,
              0.46939900517463684,
              0.7318660020828247,
              0.4704520106315613,
              0.7318440079689026,
              0.469556987285614,
              0.7318660020828247,
              0.4704520106315613,
              0.7318689823150635,
              0.47061899304389954,
              0.7318440079689026,
              0.469556987285614,
              0.7318660020828247,
              0.4704520106315613,
              0.731889009475708,
              0.4714449942111969,
              0.7318689823150635,
              0.47061899304389954,
              0.731889009475708,
              0.4714449942111969,
              0.731893002986908,
              0.47161799669265747,
              0.7318689823150635,
              0.47061899304389954,
              0.7319120168685913,
              0.47237399220466614,
              0.7319319844245911,
              0.473239004611969,
              0.7319160103797913,
              0.472555011510849,
              0.7319319844245911,
              0.473239004611969,
              0.7319369912147522,
              0.4734260141849518,
              0.7319160103797913,
              0.472555011510849,
              0.7319509983062744,
              0.4740369915962219,
              0.7319689989089966,
              0.47476598620414734,
              0.7319560050964355,
              0.47422999143600464,
              0.7319689989089966,
              0.47476598620414734,
              0.7319740056991577,
              0.4749639928340912,
              0.7319560050964355,
              0.47422999143600464,
              0.7319689989089966,
              0.47476598620414734,
              0.7319849729537964,
              0.47542300820350647,
              0.7319740056991577,
              0.4749639928340912,
              0.7319849729537964,
              0.47542300820350647,
              0.7319899797439575,
              0.47562599182128906,
              0.7319740056991577,
              0.4749639928340912,
              0.7320110201835632,
              0.476516991853714,
              0.7320209741592407,
              0.476949006319046,
              0.7320160269737244,
              0.4767259955406189,
              0.7320209741592407,
              0.476949006319046,
              0.7320259809494019,
              0.4771600067615509,
              0.7320160269737244,
              0.4767259955406189,
              0.7247570157051086,
              0.18816600739955902,
              0.7243459820747375,
              0.18816600739955902,
              0.7247570157051086,
              0.18795199692249298,
              0.7243459820747375,
              0.18816600739955902,
              0.7243459820747375,
              0.18795199692249298,
              0.7247570157051086,
              0.18795199692249298,
              0.7225239872932434,
              0.18816600739955902,
              0.722216010093689,
              0.18816600739955902,
              0.7225239872932434,
              0.18795199692249298,
              0.722216010093689,
              0.18816600739955902,
              0.722216010093689,
              0.18795199692249298,
              0.7225239872932434,
              0.18795199692249298,
              0.722216010093689,
              0.18816600739955902,
              0.7219300270080566,
              0.18816600739955902,
              0.722216010093689,
              0.18795199692249298,
              0.7219300270080566,
              0.18816600739955902,
              0.7219300270080566,
              0.18795199692249298,
              0.722216010093689,
              0.18795199692249298,
              0.7216659784317017,
              0.18816600739955902,
              0.7214270234107971,
              0.18816600739955902,
              0.7216659784317017,
              0.18795199692249298,
              0.7214270234107971,
              0.18816600739955902,
              0.7214270234107971,
              0.18795199692249298,
              0.7216659784317017,
              0.18795199692249298,
              0.720649003982544,
              0.18816600739955902,
              0.7205860018730164,
              0.18816600739955902,
              0.720649003982544,
              0.18795199692249298,
              0.7205860018730164,
              0.18816600739955902,
              0.7205860018730164,
              0.18795199692249298,
              0.720649003982544,
              0.18795199692249298,
              0.7205629944801331,
              0.18816600739955902,
              0.7206069827079773,
              0.18816600739955902,
              0.7205629944801331,
              0.18795199692249298,
              0.7206069827079773,
              0.18816600739955902,
              0.7206069827079773,
              0.18795199692249298,
              0.7205629944801331,
              0.18795199692249298,
              0.7256140112876892,
              0.18816600739955902,
              0.7251799702644348,
              0.18816600739955902,
              0.7256140112876892,
              0.18795199692249298,
              0.7251799702644348,
              0.18816600739955902,
              0.7251799702644348,
              0.18795199692249298,
              0.7256140112876892,
              0.18795199692249298,
              0.723567008972168,
              0.18816600739955902,
              0.7232009768486023,
              0.18816600739955902,
              0.723567008972168,
              0.18795199692249298,
              0.7232009768486023,
              0.18816600739955902,
              0.7232009768486023,
              0.18795199692249298,
              0.723567008972168,
              0.18795199692249298,
              0.7228530049324036,
              0.18816600739955902,
              0.7225239872932434,
              0.18816600739955902,
              0.7228530049324036,
              0.18795199692249298,
              0.7225239872932434,
              0.18816600739955902,
              0.7225239872932434,
              0.18795199692249298,
              0.7228530049324036,
              0.18795199692249298,
              0.7214270234107971,
              0.18816600739955902,
              0.7212139964103699,
              0.18816600739955902,
              0.7214270234107971,
              0.18795199692249298,
              0.7212139964103699,
              0.18816600739955902,
              0.7212139964103699,
              0.18795199692249298,
              0.7214270234107971,
              0.18795199692249298,
              0.7208719849586487,
              0.18816600739955902,
              0.7207450270652771,
              0.18816600739955902,
              0.7208719849586487,
              0.18795199692249298,
              0.7207450270652771,
              0.18816600739955902,
              0.7207450270652771,
              0.18795199692249298,
              0.7208719849586487,
              0.18795199692249298,
              0.7207450270652771,
              0.18816600739955902,
              0.720649003982544,
              0.18816600739955902,
              0.7207450270652771,
              0.18795199692249298,
              0.720649003982544,
              0.18816600739955902,
              0.720649003982544,
              0.18795199692249298,
              0.7207450270652771,
              0.18795199692249298,
              0.7415549755096436,
              0.18816600739955902,
              0.72604900598526,
              0.18816600739955902,
              0.7415549755096436,
              0.18795199692249298,
              0.72604900598526,
              0.18816600739955902,
              0.72604900598526,
              0.18795199692249298,
              0.7415549755096436,
              0.18795199692249298,
              0.7239490151405334,
              0.18933799862861633,
              0.7243459820747375,
              0.18931899964809418,
              0.7239490151405334,
              0.18955199420452118,
              0.7243459820747375,
              0.18931899964809418,
              0.7243459820747375,
              0.18953299522399902,
              0.7239490151405334,
              0.18955199420452118,
              0.723567008972168,
              0.18936200439929962,
              0.7239490151405334,
              0.18933799862861633,
              0.723567008972168,
              0.18957599997520447,
              0.7239490151405334,
              0.18933799862861633,
              0.7239490151405334,
              0.18955199420452118,
              0.723567008972168,
              0.18957599997520447,
              0.722216010093689,
              0.18950200080871582,
              0.7225239872932434,
              0.18946099281311035,
              0.722216010093689,
              0.18971599638462067,
              0.7225239872932434,
              0.18946099281311035,
              0.7225239872932434,
              0.1896750032901764,
              0.722216010093689,
              0.18971599638462067,
              0.7216659784317017,
              0.1895959973335266,
              0.7219300270080566,
              0.18954700231552124,
              0.7216659784317017,
              0.18980999290943146,
              0.7219300270080566,
              0.18954700231552124,
              0.7219300270080566,
              0.1897609978914261,
              0.7216659784317017,
              0.18980999290943146,
              0.7212139964103699,
              0.18970200419425964,
              0.7214270234107971,
              0.18964700400829315,
              0.7212139964103699,
              0.1899159997701645,
              0.7214270234107971,
              0.18964700400829315,
              0.7214270234107971,
              0.189860999584198,
              0.7212139964103699,
              0.1899159997701645,
              0.7210289835929871,
              0.1897590011358261,
              0.7212139964103699,
              0.18970200419425964,
              0.7210289835929871,
              0.18997299671173096,
              0.7212139964103699,
              0.18970200419425964,
              0.7212139964103699,
              0.1899159997701645,
              0.7210289835929871,
              0.18997299671173096,
              0.7208719849586487,
              0.18981799483299255,
              0.7210289835929871,
              0.1897590011358261,
              0.7208719849586487,
              0.1900320053100586,
              0.7210289835929871,
              0.1897590011358261,
              0.7210289835929871,
              0.18997299671173096,
              0.7208719849586487,
              0.1900320053100586,
              0.720649003982544,
              0.18994399905204773,
              0.7207450270652771,
              0.18987999856472015,
              0.720649003982544,
              0.1901569962501526,
              0.7207450270652771,
              0.18987999856472015,
              0.7207450270652771,
              0.190093994140625,
              0.720649003982544,
              0.1901569962501526,
              0.7205569744110107,
              0.19007599353790283,
              0.7205860018730164,
              0.1900089979171753,
              0.7205569744110107,
              0.19029000401496887,
              0.7205860018730164,
              0.1900089979171753,
              0.7205860018730164,
              0.19022299349308014,
              0.7205569744110107,
              0.19029000401496887,
              0.7206680178642273,
              0.1902800053358078,
              0.7206069827079773,
              0.19021199643611908,
              0.7206680178642273,
              0.19049400091171265,
              0.7206069827079773,
              0.19021199643611908,
              0.7206069827079773,
              0.19042600691318512,
              0.7206680178642273,
              0.19049400091171265,
              0.7256140112876892,
              0.1892929971218109,
              0.72604900598526,
              0.1892929971218109,
              0.7256140112876892,
              0.18950699269771576,
              0.72604900598526,
              0.1892929971218109,
              0.72604900598526,
              0.18950699269771576,
              0.7256140112876892,
              0.18950699269771576,
              0.72604900598526,
              0.1892929971218109,
              0.7415549755096436,
              0.1892929971218109,
              0.72604900598526,
              0.18950699269771576,
              0.7415549755096436,
              0.1892929971218109,
              0.7415549755096436,
              0.18950699269771576,
              0.72604900598526,
              0.18950699269771576,
              0.7251880168914795,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7256159782409668,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7207580208778381,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206479907035828,
              0.1877090036869049,
              0.7206550240516663,
              0.1877090036869049,
              0.7206479907035828,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206770181655884,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7206969857215881,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7208330035209656,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7209569811820984,
              0.1877090036869049,
              0.7208330035209656,
              0.1877090036869049,
              0.7209569811820984,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7211120128631592,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7215030193328857,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7217379808425903,
              0.1877090036869049,
              0.7215030193328857,
              0.1877090036869049,
              0.7217379808425903,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.721996009349823,
              0.1877090036869049,
              0.7207390069961548,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7212939858436584,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7225800156593323,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7229030132293701,
              0.1877090036869049,
              0.7225800156593323,
              0.1877090036869049,
              0.7229030132293701,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7232450246810913,
              0.1877090036869049,
              0.72604900598526,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7222779989242554,
              0.1877090036869049,
              0.7236040234565735,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7239789962768555,
              0.1877090036869049,
              0.7247729897499084,
              0.1877090036869049,
              0.7243689894676208,
              0.1877090036869049,
              0.7251880168914795,
              0.1877090036869049,
              0.7236040234565735,
              0.18983200192451477,
              0.7243689894676208,
              0.18978999555110931,
              0.7232450246810913,
              0.189860001206398,
              0.7243689894676208,
              0.18978999555110931,
              0.7229030132293701,
              0.18989300727844238,
              0.7232450246810913,
              0.189860001206398,
              0.7229030132293701,
              0.18989300727844238,
              0.7217379808425903,
              0.1900620013475418,
              0.7225800156593323,
              0.1899300068616867,
              0.7217379808425903,
              0.1900620013475418,
              0.7222779989242554,
              0.189970001578331,
              0.7225800156593323,
              0.1899300068616867,
              0.7222779989242554,
              0.189970001578331,
              0.7217379808425903,
              0.1900620013475418,
              0.721996009349823,
              0.19001400470733643,
              0.7217379808425903,
              0.1900620013475418,
              0.7209569811820984,
              0.1902800053358078,
              0.7215030193328857,
              0.19011199474334717,
              0.7209569811820984,
              0.1902800053358078,
              0.7212939858436584,
              0.19016599655151367,
              0.7215030193328857,
              0.19011199474334717,
              0.7212939858436584,
              0.19016599655151367,
              0.7209569811820984,
              0.1902800053358078,
              0.7211120128631592,
              0.19022199511528015,
              0.7229030132293701,
              0.18989300727844238,
              0.7251880168914795,
              0.1897680014371872,
              0.7217379808425903,
              0.1900620013475418,
              0.7251880168914795,
              0.1897680014371872,
              0.7209569811820984,
              0.1902800053358078,
              0.7217379808425903,
              0.1900620013475418,
              0.7209569811820984,
              0.1902800053358078,
              0.7206479907035828,
              0.19053299725055695,
              0.7208330035209656,
              0.19034099578857422,
              0.7206479907035828,
              0.19053299725055695,
              0.7207390069961548,
              0.19040299952030182,
              0.7208330035209656,
              0.19034099578857422,
              0.7207390069961548,
              0.19040299952030182,
              0.7206479907035828,
              0.19053299725055695,
              0.7206770181655884,
              0.1904670000076294,
              0.7206479907035828,
              0.19053299725055695,
              0.7415549755096436,
              0.19073499739170074,
              0.7206550240516663,
              0.19059999287128448,
              0.7415549755096436,
              0.19073499739170074,
              0.7206969857215881,
              0.1906680017709732,
              0.7206550240516663,
              0.19059999287128448,
              0.7206969857215881,
              0.1906680017709732,
              0.7415549755096436,
              0.19073499739170074,
              0.7207580208778381,
              0.19073499739170074,
              0.7209569811820984,
              0.1902800053358078,
              0.7251880168914795,
              0.1897680014371872,
              0.7206479907035828,
              0.19053299725055695,
              0.7251880168914795,
              0.1897680014371872,
              0.7415549755096436,
              0.19073499739170074,
              0.7206479907035828,
              0.19053299725055695,
              0.7415549755096436,
              0.19073499739170074,
              0.7251880168914795,
              0.1897680014371872,
              0.7415549755096436,
              0.18976500630378723,
              0.7251880168914795,
              0.1897680014371872,
              0.72604900598526,
              0.18976500630378723,
              0.7415549755096436,
              0.18976500630378723,
              0.72604900598526,
              0.18976500630378723,
              0.7251880168914795,
              0.1897680014371872,
              0.7256159782409668,
              0.18976500630378723,
              0.7251880168914795,
              0.1897680014371872,
              0.7229030132293701,
              0.18989300727844238,
              0.7247729897499084,
              0.1897760033607483,
              0.7229030132293701,
              0.18989300727844238,
              0.7243689894676208,
              0.18978999555110931,
              0.7247729897499084,
              0.1897760033607483,
              0.7239789962768555,
              0.18980899453163147,
              0.7243689894676208,
              0.18978999555110931,
              0.7236040234565735,
              0.18983200192451477,
              0.7319409847259521,
              0.46978598833084106,
              0.7415549755096436,
              0.46978598833084106,
              0.7319459915161133,
              0.47001200914382935,
              0.7415549755096436,
              0.46978598833084106,
              0.7415549755096436,
              0.47001200914382935,
              0.7319459915161133,
              0.47001200914382935,
              0.731673002243042,
              0.4585919976234436,
              0.7316489815711975,
              0.4575580060482025,
              0.7415549755096436,
              0.4585919976234436,
              0.7316489815711975,
              0.4575580060482025,
              0.7415549755096436,
              0.4575580060482025,
              0.7415549755096436,
              0.4585919976234436,
              0.7316970229148865,
              0.4595929980278015,
              0.731673002243042,
              0.4585919976234436,
              0.7415549755096436,
              0.4595929980278015,
              0.731673002243042,
              0.4585919976234436,
              0.7415549755096436,
              0.4585919976234436,
              0.7415549755096436,
              0.4595929980278015,
              0.7317429780960083,
              0.4614880084991455,
              0.731719970703125,
              0.46055901050567627,
              0.7415549755096436,
              0.4614880084991455,
              0.731719970703125,
              0.46055901050567627,
              0.7415549755096436,
              0.46055901050567627,
              0.7415549755096436,
              0.4614880084991455,
              0.7318390011787415,
              0.4655289947986603,
              0.7318220138549805,
              0.4648059904575348,
              0.7415549755096436,
              0.4655289947986603,
              0.7318220138549805,
              0.4648059904575348,
              0.7415549755096436,
              0.4648059904575348,
              0.7415549755096436,
              0.4655289947986603,
              0.7318699955940247,
              0.46683499217033386,
              0.7318549752235413,
              0.46620601415634155,
              0.7415549755096436,
              0.46683499217033386,
              0.7318549752235413,
              0.46620601415634155,
              0.7415549755096436,
              0.46620601415634155,
              0.7415549755096436,
              0.46683499217033386,
              0.731719970703125,
              0.46055901050567627,
              0.7316970229148865,
              0.4595929980278015,
              0.7415549755096436,
              0.46055901050567627,
              0.7316970229148865,
              0.4595929980278015,
              0.7415549755096436,
              0.4595929980278015,
              0.7415549755096436,
              0.46055901050567627,
              0.7318220138549805,
              0.4648059904575348,
              0.7318040132522583,
              0.46404001116752625,
              0.7415549755096436,
              0.4648059904575348,
              0.7318040132522583,
              0.46404001116752625,
              0.7415549755096436,
              0.46404001116752625,
              0.7415549755096436,
              0.4648059904575348,
              0.7318969964981079,
              0.46794599294662476,
              0.7318840026855469,
              0.467415988445282,
              0.7415549755096436,
              0.46794599294662476,
              0.7318840026855469,
              0.467415988445282,
              0.7415549755096436,
              0.467415988445282,
              0.7415549755096436,
              0.46794599294662476,
              0.7319080233573914,
              0.468423992395401,
              0.7318969964981079,
              0.46794599294662476,
              0.7415549755096436,
              0.468423992395401,
              0.7318969964981079,
              0.46794599294662476,
              0.7415549755096436,
              0.46794599294662476,
              0.7415549755096436,
              0.468423992395401,
              0.7319350242614746,
              0.46953099966049194,
              0.7319269776344299,
              0.469217985868454,
              0.7415549755096436,
              0.46953099966049194,
              0.7319269776344299,
              0.469217985868454,
              0.7415549755096436,
              0.469217985868454,
              0.7415549755096436,
              0.46953099966049194,
              0.7319409847259521,
              0.46978598833084106,
              0.7319350242614746,
              0.46953099966049194,
              0.7415549755096436,
              0.46978598833084106,
              0.7319350242614746,
              0.46953099966049194,
              0.7415549755096436,
              0.46953099966049194,
              0.7415549755096436,
              0.46978598833084106,
              0.7316229939460754,
              0.45649099349975586,
              0.7315970063209534,
              0.4553939998149872,
              0.7415549755096436,
              0.45649099349975586,
              0.7315970063209534,
              0.4553939998149872,
              0.7415549755096436,
              0.4553939998149872,
              0.7415549755096436,
              0.45649099349975586,
              0.731764018535614,
              0.46237900853157043,
              0.7317429780960083,
              0.4614880084991455,
              0.7415549755096436,
              0.46237900853157043,
              0.7317429780960083,
              0.4614880084991455,
              0.7415549755096436,
              0.4614880084991455,
              0.7415549755096436,
              0.46237900853157043,
              0.7317839860916138,
              0.4632300138473511,
              0.731764018535614,
              0.46237900853157043,
              0.7415549755096436,
              0.4632300138473511,
              0.731764018535614,
              0.46237900853157043,
              0.7415549755096436,
              0.46237900853157043,
              0.7415549755096436,
              0.4632300138473511,
              0.7318040132522583,
              0.46404001116752625,
              0.7317839860916138,
              0.4632300138473511,
              0.7415549755096436,
              0.46404001116752625,
              0.7317839860916138,
              0.4632300138473511,
              0.7415549755096436,
              0.4632300138473511,
              0.7415549755096436,
              0.46404001116752625,
              0.73191899061203,
              0.46884799003601074,
              0.7319080233573914,
              0.468423992395401,
              0.7415549755096436,
              0.46884799003601074,
              0.7319080233573914,
              0.468423992395401,
              0.7415549755096436,
              0.468423992395401,
              0.7415549755096436,
              0.46884799003601074,
              0.7319269776344299,
              0.469217985868454,
              0.73191899061203,
              0.46884799003601074,
              0.7415549755096436,
              0.469217985868454,
              0.73191899061203,
              0.46884799003601074,
              0.7415549755096436,
              0.46884799003601074,
              0.7415549755096436,
              0.469217985868454,
              0.7315970063209534,
              0.4553939998149872,
              0.7315700054168701,
              0.4542680084705353,
              0.7415549755096436,
              0.4553939998149872,
              0.7315700054168701,
              0.4542680084705353,
              0.7415549755096436,
              0.4542680084705353,
              0.7415549755096436,
              0.4553939998149872,
              0.7316489815711975,
              0.4575580060482025,
              0.7316229939460754,
              0.45649099349975586,
              0.7415549755096436,
              0.4575580060482025,
              0.7316229939460754,
              0.45649099349975586,
              0.7415549755096436,
              0.45649099349975586,
              0.7415549755096436,
              0.4575580060482025,
              0.7318549752235413,
              0.46620601415634155,
              0.7318390011787415,
              0.4655289947986603,
              0.7415549755096436,
              0.46620601415634155,
              0.7318390011787415,
              0.4655289947986603,
              0.7415549755096436,
              0.4655289947986603,
              0.7415549755096436,
              0.46620601415634155,
              0.7318840026855469,
              0.467415988445282,
              0.7318699955940247,
              0.46683499217033386,
              0.7415549755096436,
              0.467415988445282,
              0.7318699955940247,
              0.46683499217033386,
              0.7415549755096436,
              0.46683499217033386,
              0.7415549755096436,
              0.467415988445282,
              0.7316880226135254,
              0.459199994802475,
              0.7317240238189697,
              0.4606890082359314,
              0.7415549755096436,
              0.459199994802475,
              0.7317240238189697,
              0.4606890082359314,
              0.7415549755096436,
              0.4606890082359314,
              0.7415549755096436,
              0.459199994802475,
              0.7318239808082581,
              0.46487900614738464,
              0.7318549752235413,
              0.46617498993873596,
              0.7415549755096436,
              0.46487799286842346,
              0.7318549752235413,
              0.46617498993873596,
              0.7415549755096436,
              0.46617498993873596,
              0.7415549755096436,
              0.46487799286842346,
              0.7319129705429077,
              0.46860700845718384,
              0.731939971446991,
              0.4697369933128357,
              0.7415549755096436,
              0.46860700845718384,
              0.731939971446991,
              0.4697369933128357,
              0.7415549755096436,
              0.4697369933128357,
              0.7415549755096436,
              0.46860700845718384,
              0.7321839928627014,
              0.4799160063266754,
              0.7415549755096436,
              0.4799160063266754,
              0.732138991355896,
              0.478069007396698,
              0.7415549755096436,
              0.4799160063266754,
              0.7415549755096436,
              0.478069007396698,
              0.732138991355896,
              0.478069007396698,
              0.7316510081291199,
              0.4576680064201355,
              0.7316880226135254,
              0.459199994802475,
              0.7415549755096436,
              0.4576680064201355,
              0.7316880226135254,
              0.459199994802475,
              0.7415549755096436,
              0.459199994802475,
              0.7415549755096436,
              0.4576680064201355,
              0.7317240238189697,
              0.4606890082359314,
              0.7317579984664917,
              0.46213299036026,
              0.7415549755096436,
              0.4606890082359314,
              0.7317579984664917,
              0.46213299036026,
              0.7415549755096436,
              0.46213299036026,
              0.7415549755096436,
              0.4606890082359314,
              0.731939971446991,
              0.4697369933128357,
              0.7319660186767578,
              0.4708069860935211,
              0.7415549755096436,
              0.4697369933128357,
              0.7319660186767578,
              0.4708069860935211,
              0.7415549755096436,
              0.4708069860935211,
              0.7415549755096436,
              0.4697369933128357,
              0.7319660186767578,
              0.4708069860935211,
              0.7319899797439575,
              0.4718160033226013,
              0.7415549755096436,
              0.4708069860935211,
              0.7319899797439575,
              0.4718160033226013,
              0.7415549755096436,
              0.4718160033226013,
              0.7415549755096436,
              0.4708069860935211,
              0.7320119738578796,
              0.47276100516319275,
              0.7320330142974854,
              0.47363901138305664,
              0.7415549755096436,
              0.47275999188423157,
              0.7320330142974854,
              0.47363901138305664,
              0.7415549755096436,
              0.47363901138305664,
              0.7415549755096436,
              0.47275999188423157,
              0.7320529818534851,
              0.4744490087032318,
              0.7320700287818909,
              0.4751890003681183,
              0.7415549755096436,
              0.4744490087032318,
              0.7320700287818909,
              0.4751890003681183,
              0.7415549755096436,
              0.4751890003681183,
              0.7415549755096436,
              0.4744490087032318,
              0.7320700287818909,
              0.4751890003681183,
              0.7320860028266907,
              0.4758560061454773,
              0.7415549755096436,
              0.4751890003681183,
              0.7320860028266907,
              0.4758560061454773,
              0.7415549755096436,
              0.4758560061454773,
              0.7415549755096436,
              0.4751890003681183,
              0.7321130037307739,
              0.47696399688720703,
              0.7321230173110962,
              0.477400004863739,
              0.7415549755096436,
              0.47696399688720703,
              0.7321230173110962,
              0.477400004863739,
              0.7415549755096436,
              0.477400004863739,
              0.7415549755096436,
              0.47696399688720703,
              0.7316139936447144,
              0.45609501004219055,
              0.7316510081291199,
              0.4576680064201355,
              0.7415549755096436,
              0.45609501004219055,
              0.7316510081291199,
              0.4576680064201355,
              0.7415549755096436,
              0.4576680064201355,
              0.7415549755096436,
              0.45609501004219055,
              0.7317579984664917,
              0.46213299036026,
              0.7317910194396973,
              0.4635300040245056,
              0.7415549755096436,
              0.46213299036026,
              0.7317910194396973,
              0.4635300040245056,
              0.7415549755096436,
              0.4635300040245056,
              0.7415549755096436,
              0.46213299036026,
              0.7320860028266907,
              0.4758560061454773,
              0.7321010231971741,
              0.4764479994773865,
              0.7415549755096436,
              0.4758560061454773,
              0.7321010231971741,
              0.4764479994773865,
              0.7415549755096436,
              0.4764479994773865,
              0.7415549755096436,
              0.4758560061454773,
              0.7321010231971741,
              0.4764479994773865,
              0.7321130037307739,
              0.47696399688720703,
              0.7415549755096436,
              0.4764479994773865,
              0.7321130037307739,
              0.47696399688720703,
              0.7415549755096436,
              0.47696399688720703,
              0.7415549755096436,
              0.4764479994773865,
              0.7321230173110962,
              0.477400004863739,
              0.7321320176124573,
              0.47775599360466003,
              0.7415549755096436,
              0.477400004863739,
              0.7321320176124573,
              0.47775599360466003,
              0.7415549755096436,
              0.47775599360466003,
              0.7415549755096436,
              0.477400004863739,
              0.7317910194396973,
              0.4635300040245056,
              0.7318239808082581,
              0.46487900614738464,
              0.7415549755096436,
              0.4635300040245056,
              0.7318239808082581,
              0.46487900614738464,
              0.7415549755096436,
              0.46487799286842346,
              0.7415549755096436,
              0.4635300040245056,
              0.7318549752235413,
              0.46617498993873596,
              0.7318850159645081,
              0.46741899847984314,
              0.7415549755096436,
              0.46617498993873596,
              0.7318850159645081,
              0.46741899847984314,
              0.7415549755096436,
              0.46741899847984314,
              0.7415549755096436,
              0.46617498993873596,
              0.7318850159645081,
              0.46741899847984314,
              0.7319129705429077,
              0.46860700845718384,
              0.7415549755096436,
              0.46741899847984314,
              0.7319129705429077,
              0.46860700845718384,
              0.7415549755096436,
              0.46860700845718384,
              0.7415549755096436,
              0.46741899847984314,
              0.7319899797439575,
              0.4718160033226013,
              0.7320119738578796,
              0.47276100516319275,
              0.7415549755096436,
              0.4718160033226013,
              0.7320119738578796,
              0.47276100516319275,
              0.7415549755096436,
              0.47275999188423157,
              0.7415549755096436,
              0.4718160033226013,
              0.7320330142974854,
              0.47363901138305664,
              0.7320529818534851,
              0.4744490087032318,
              0.7415549755096436,
              0.47363901138305664,
              0.7320529818534851,
              0.4744490087032318,
              0.7415549755096436,
              0.4744490087032318,
              0.7415549755096436,
              0.47363901138305664,
              0.7298769950866699,
              0.3853900134563446,
              0.7315319776535034,
              0.4545019865036011,
              0.7299200296401978,
              0.3853900134563446,
              0.7315319776535034,
              0.4545019865036011,
              0.7315750122070312,
              0.4545019865036011,
              0.7299200296401978,
              0.3853900134563446,
              0.7299200296401978,
              0.3853900134563446,
              0.7315750122070312,
              0.4545019865036011,
              0.7415549755096436,
              0.3853900134563446,
              0.7315750122070312,
              0.4545019865036011,
              0.7415549755096436,
              0.4545019865036011,
              0.7415549755096436,
              0.3853900134563446,
              0.7315340042114258,
              0.45608100295066833,
              0.7314950227737427,
              0.45449098944664,
              0.7315199971199036,
              0.4560379981994629,
              0.7314950227737427,
              0.45449098944664,
              0.7314820289611816,
              0.454459011554718,
              0.7315199971199036,
              0.4560379981994629,
              0.7314900159835815,
              0.4542819857597351,
              0.7314630150794983,
              0.4531320035457611,
              0.7315269708633423,
              0.4542680084705353,
              0.7314630150794983,
              0.4531320035457611,
              0.7314990162849426,
              0.45312100648880005,
              0.7315269708633423,
              0.4542680084705353,
              0.7315319776535034,
              0.4545019865036011,
              0.7314950227737427,
              0.45449098944664,
              0.7315700054168701,
              0.45609501004219055,
              0.7314950227737427,
              0.45449098944664,
              0.7315340042114258,
              0.45608100295066833,
              0.7315700054168701,
              0.45609501004219055,
              0.7314789891242981,
              0.45432499051094055,
              0.7314509749412537,
              0.4531640112400055,
              0.7314900159835815,
              0.4542819857597351,
              0.7314509749412537,
              0.4531640112400055,
              0.7314630150794983,
              0.4531320035457611,
              0.7314900159835815,
              0.4542819857597351,
              0.7315189838409424,
              0.45598798990249634,
              0.7314810156822205,
              0.4544210135936737,
              0.7314800024032593,
              0.4543749988079071,
              0.7314810156822205,
              0.4544210135936737,
              0.7314519882202148,
              0.4532020092010498,
              0.7314800024032593,
              0.4543749988079071,
              0.7315199971199036,
              0.4560379981994629,
              0.7314820289611816,
              0.454459011554718,
              0.7315189838409424,
              0.45598798990249634,
              0.7314820289611816,
              0.454459011554718,
              0.7314810156822205,
              0.4544210135936737,
              0.7315189838409424,
              0.45598798990249634,
              0.7315269708633423,
              0.4542680084705353,
              0.7314990162849426,
              0.45312100648880005,
              0.7315700054168701,
              0.4542680084705353,
              0.7314990162849426,
              0.45312100648880005,
              0.7315419912338257,
              0.45312100648880005,
              0.7315700054168701,
              0.4542680084705353,
              0.7315700054168701,
              0.4542680084705353,
              0.7315419912338257,
              0.45312100648880005,
              0.7415549755096436,
              0.4542680084705353,
              0.7315419912338257,
              0.45312100648880005,
              0.7415549755096436,
              0.45312100648880005,
              0.7415549755096436,
              0.4542680084705353,
              0.7318689823150635,
              0.47007301449775696,
              0.7318609952926636,
              0.4702570140361786,
              0.7318630218505859,
              0.4698469936847687,
              0.7318609952926636,
              0.4702570140361786,
              0.7318549752235413,
              0.47002899646759033,
              0.7318630218505859,
              0.4698469936847687,
              0.7320590019226074,
              0.4780080020427704,
              0.7320970296859741,
              0.478069007396698,
              0.7320510149002075,
              0.4776949882507324,
              0.7320970296859741,
              0.478069007396698,
              0.7320889830589294,
              0.47775599360466003,
              0.7320510149002075,
              0.4776949882507324,
              0.7319039702415466,
              0.47001200914382935,
              0.7318689823150635,
              0.47007301449775696,
              0.7318980097770691,
              0.46978598833084106,
              0.7318689823150635,
              0.47007301449775696,
              0.7318630218505859,
              0.4698469936847687,
              0.7318980097770691,
              0.46978598833084106,
              0.7320420145988464,
              0.47782400250434875,
              0.7320590019226074,
              0.4780080020427704,
              0.7320349812507629,
              0.47751399874687195,
              0.7320590019226074,
              0.4780080020427704,
              0.7320510149002075,
              0.4776949882507324,
              0.7320349812507629,
              0.47751399874687195,
              0.7320370078086853,
              0.4776090085506439,
              0.7318660020828247,
              0.4704720079898834,
              0.7320820093154907,
              0.47947001457214355,
              0.7318660020828247,
              0.4704720079898834,
              0.7319160103797913,
              0.4725480079650879,
              0.7320820093154907,
              0.47947001457214355,
              0.7318609952926636,
              0.4702570140361786,
              0.7318660020828247,
              0.4704720079898834,
              0.7318549752235413,
              0.47002899646759033,
              0.7318660020828247,
              0.4704720079898834,
              0.7318609952926636,
              0.4702419936656952,
              0.7318549752235413,
              0.47002899646759033,
              0.7320970296859741,
              0.478069007396698,
              0.732138991355896,
              0.478069007396698,
              0.7320889830589294,
              0.47775599360466003,
              0.732138991355896,
              0.478069007396698,
              0.7321320176124573,
              0.47775599360466003,
              0.7320889830589294,
              0.47775599360466003,
              0.7319539785385132,
              0.47210100293159485,
              0.7319039702415466,
              0.47001200914382935,
              0.7319959998130798,
              0.47210100293159485,
              0.7319039702415466,
              0.47001200914382935,
              0.7319459915161133,
              0.47001200914382935,
              0.7319959998130798,
              0.47210100293159485,
              0.7320870161056519,
              0.4796789884567261,
              0.7320420145988464,
              0.47782400250434875,
              0.7320820093154907,
              0.47947001457214355,
              0.7320420145988464,
              0.47782400250434875,
              0.7320370078086853,
              0.4776090085506439,
              0.7320820093154907,
              0.47947001457214355,
              0.7319959998130798,
              0.47210100293159485,
              0.7319459915161133,
              0.47001200914382935,
              0.7415549755096436,
              0.47210100293159485,
              0.7319459915161133,
              0.47001200914382935,
              0.7415549755096436,
              0.47001200914382935,
              0.7415549755096436,
              0.47210100293159485,
              0.7321320176124573,
              0.47775599360466003,
              0.732138991355896,
              0.478069007396698,
              0.7415549755096436,
              0.47775599360466003,
              0.732138991355896,
              0.478069007396698,
              0.7415549755096436,
              0.478069007396698,
              0.7415549755096436,
              0.47775599360466003,
              0.7314820289611816,
              0.454459011554718,
              0.7314950227737427,
              0.45449098944664,
              0.7298259735107422,
              0.38531601428985596,
              0.7314950227737427,
              0.45449098944664,
              0.7298399806022644,
              0.38537099957466125,
              0.7298259735107422,
              0.38531601428985596,
              0.7314990162849426,
              0.45312100648880005,
              0.7314630150794983,
              0.4531320035457611,
              0.7298200130462646,
              0.3830209970474243,
              0.7314630150794983,
              0.4531320035457611,
              0.7297840118408203,
              0.3830389976501465,
              0.7298200130462646,
              0.3830209970474243,
              0.7315319776535034,
              0.4545019865036011,
              0.7298769950866699,
              0.3853900134563446,
              0.7314950227737427,
              0.45449098944664,
              0.7298769950866699,
              0.3853900134563446,
              0.7298399806022644,
              0.38537099957466125,
              0.7314950227737427,
              0.45449098944664,
              0.7297729849815369,
              0.38309401273727417,
              0.7314509749412537,
              0.4531640112400055,
              0.729773998260498,
              0.38315799832344055,
              0.7314509749412537,
              0.4531640112400055,
              0.7314519882202148,
              0.4532020092010498,
              0.729773998260498,
              0.38315799832344055,
              0.7415549755096436,
              0.3830209970474243,
              0.7415549755096436,
              0.45312100648880005,
              0.7298629879951477,
              0.3830209970474243,
              0.7415549755096436,
              0.45312100648880005,
              0.7315419912338257,
              0.45312100648880005,
              0.7298629879951477,
              0.3830209970474243,
              0.7314509749412537,
              0.4531640112400055,
              0.7297729849815369,
              0.38309401273727417,
              0.7314630150794983,
              0.4531320035457611,
              0.7297729849815369,
              0.38309401273727417,
              0.7297840118408203,
              0.3830389976501465,
              0.7314630150794983,
              0.4531320035457611,
              0.7314810156822205,
              0.4544210135936737,
              0.7298250198364258,
              0.3852519989013672,
              0.7314519882202148,
              0.4532020092010498,
              0.7298250198364258,
              0.3852519989013672,
              0.729773998260498,
              0.38315799832344055,
              0.7314519882202148,
              0.4532020092010498,
              0.7298259735107422,
              0.38531601428985596,
              0.7298250198364258,
              0.3852519989013672,
              0.7314820289611816,
              0.454459011554718,
              0.7298250198364258,
              0.3852519989013672,
              0.7314810156822205,
              0.4544210135936737,
              0.7314820289611816,
              0.454459011554718,
              0.7314990162849426,
              0.45312100648880005,
              0.7298200130462646,
              0.3830209970474243,
              0.7315419912338257,
              0.45312100648880005,
              0.7298200130462646,
              0.3830209970474243,
              0.7298629879951477,
              0.3830209970474243,
              0.7315419912338257,
              0.45312100648880005,
              0.7579089999198914,
              0.1938759982585907,
              0.7579240202903748,
              0.19402800500392914,
              0.7579740285873413,
              0.1923390030860901,
              0.7579240202903748,
              0.19402800500392914,
              0.7579889893531799,
              0.19249999523162842,
              0.7579740285873413,
              0.1923390030860901,
              0.7577850222587585,
              0.19749200344085693,
              0.7577999830245972,
              0.19762100279331207,
              0.7578489780426025,
              0.19559800624847412,
              0.7577999830245972,
              0.19762100279331207,
              0.7578639984130859,
              0.1957390010356903,
              0.7578489780426025,
              0.19559800624847412,
              0.7577239871025085,
              0.1995439976453781,
              0.7577390074729919,
              0.19965900480747223,
              0.7577850222587585,
              0.19749200344085693,
              0.7577390074729919,
              0.19965900480747223,
              0.7577999830245972,
              0.19762100279331207,
              0.7577850222587585,
              0.19749200344085693,
              0.7576709985733032,
              0.20173899829387665,
              0.7576860189437866,
              0.20184099674224854,
              0.7577239871025085,
              0.1995439976453781,
              0.7576860189437866,
              0.20184099674224854,
              0.7577390074729919,
              0.19965900480747223,
              0.7577239871025085,
              0.1995439976453781,
              0.7576149702072144,
              0.2040649950504303,
              0.7576289772987366,
              0.20415200293064117,
              0.7576709985733032,
              0.20173899829387665,
              0.7576289772987366,
              0.20415200293064117,
              0.7576860189437866,
              0.20184099674224854,
              0.7576709985733032,
              0.20173899829387665,
              0.7574949860572815,
              0.20905299484729767,
              0.7575089931488037,
              0.20910799503326416,
              0.7575560212135315,
              0.20650799572467804,
              0.7575089931488037,
              0.20910799503326416,
              0.7575709819793701,
              0.20657899975776672,
              0.7575560212135315,
              0.20650799572467804,
              0.7571640014648438,
              0.22284600138664246,
              0.7571769952774048,
              0.22281399369239807,
              0.7572320103645325,
              0.21999099850654602,
              0.7571769952774048,
              0.22281399369239807,
              0.7572460174560547,
              0.219977006316185,
              0.7572320103645325,
              0.21999099850654602,
              0.7570949792861938,
              0.22572100162506104,
              0.7571079730987549,
              0.22567200660705566,
              0.7571640014648438,
              0.22284600138664246,
              0.7571079730987549,
              0.22567200660705566,
              0.7571769952774048,
              0.22281399369239807,
              0.7571640014648438,
              0.22284600138664246,
              0.7570260167121887,
              0.2286040037870407,
              0.7570390105247498,
              0.22853699326515198,
              0.7570949792861938,
              0.22572100162506104,
              0.7570390105247498,
              0.22853699326515198,
              0.7571079730987549,
              0.22567200660705566,
              0.7570949792861938,
              0.22572100162506104,
              0.7584130167961121,
              0.1951570063829422,
              0.7584260106086731,
              0.19497300684452057,
              0.7583500146865845,
              0.1953279972076416,
              0.7584260106086731,
              0.19497300684452057,
              0.7583630084991455,
              0.19514399766921997,
              0.7583500146865845,
              0.1953279972076416,
              0.7582899928092957,
              0.19568899273872375,
              0.7583019733428955,
              0.1955060064792633,
              0.7582319974899292,
              0.19623200595378876,
              0.7583019733428955,
              0.1955060064792633,
              0.7582430243492126,
              0.19605199992656708,
              0.7582319974899292,
              0.19623200595378876,
              0.7581239938735962,
              0.19781999289989471,
              0.7581340074539185,
              0.1976509988307953,
              0.7580729722976685,
              0.1988459974527359,
              0.7581340074539185,
              0.1976509988307953,
              0.7580829858779907,
              0.19868500530719757,
              0.7580729722976685,
              0.1988459974527359,
              0.7571600079536438,
              0.22360000014305115,
              0.7571700215339661,
              0.2236499935388565,
              0.7570949792861938,
              0.2257159948348999,
              0.7571700215339661,
              0.2236499935388565,
              0.7571049928665161,
              0.22578300535678864,
              0.7570949792861938,
              0.2257159948348999,
              0.7572259902954102,
              0.22148799896240234,
              0.7572370171546936,
              0.22152000665664673,
              0.7571600079536438,
              0.22360000014305115,
              0.7572370171546936,
              0.22152000665664673,
              0.7571700215339661,
              0.2236499935388565,
              0.7571600079536438,
              0.22360000014305115,
              0.7575719952583313,
              0.211326003074646,
              0.7575820088386536,
              0.2112710028886795,
              0.757502019405365,
              0.21327200531959534,
              0.7575820088386536,
              0.2112710028886795,
              0.7575129866600037,
              0.21323400735855103,
              0.757502019405365,
              0.21327200531959534,
              0.75764000415802,
              0.20944300293922424,
              0.7576509714126587,
              0.20937199890613556,
              0.7575719952583313,
              0.211326003074646,
              0.7576509714126587,
              0.20937199890613556,
              0.7575820088386536,
              0.2112710028886795,
              0.7575719952583313,
              0.211326003074646,
              0.7577750086784363,
              0.2059040069580078,
              0.7577840089797974,
              0.2058030068874359,
              0.7577080130577087,
              0.20763200521469116,
              0.7577840089797974,
              0.2058030068874359,
              0.757718026638031,
              0.20754599571228027,
              0.7577080130577087,
              0.20763200521469116,
              0.758018970489502,
              0.20001399517059326,
              0.7580289840698242,
              0.199863001704216,
              0.7579619884490967,
              0.20131400227546692,
              0.7580289840698242,
              0.199863001704216,
              0.757972002029419,
              0.20117299258708954,
              0.7579619884490967,
              0.20131400227546692,
              0.7580729722976685,
              0.1988459974527359,
              0.7580829858779907,
              0.19868500530719757,
              0.758018970489502,
              0.20001399517059326,
              0.7580829858779907,
              0.19868500530719757,
              0.7580289840698242,
              0.199863001704216,
              0.758018970489502,
              0.20001399517059326,
              0.7570260167121887,
              0.2286040037870407,
              0.7569580078125,
              0.23144599795341492,
              0.7570390105247498,
              0.22853699326515198,
              0.7569580078125,
              0.23144599795341492,
              0.7569720149040222,
              0.2313610017299652,
              0.7570390105247498,
              0.22853699326515198,
              0.7584260106086731,
              0.19497300684452057,
              0.7584130167961121,
              0.1951570063829422,
              0.7584890127182007,
              0.19490200281143188,
              0.7584130167961121,
              0.1951570063829422,
              0.7584760189056396,
              0.1950870007276535,
              0.7584890127182007,
              0.19490200281143188,
              0.7584400177001953,
              0.18776999413967133,
              0.7584030032157898,
              0.1877090036869049,
              0.7583540081977844,
              0.18790900707244873,
              0.7584030032157898,
              0.1877090036869049,
              0.7583180069923401,
              0.18784800171852112,
              0.7583540081977844,
              0.18790900707244873,
              0.7583540081977844,
              0.18790900707244873,
              0.7583180069923401,
              0.18784800171852112,
              0.7582719922065735,
              0.1883150041103363,
              0.7583180069923401,
              0.18784800171852112,
              0.758234977722168,
              0.1882539987564087,
              0.7582719922065735,
              0.1883150041103363,
              0.7582719922065735,
              0.1883150041103363,
              0.758234977722168,
              0.1882539987564087,
              0.7581930160522461,
              0.1889750063419342,
              0.758234977722168,
              0.1882539987564087,
              0.7581549882888794,
              0.18891499936580658,
              0.7581930160522461,
              0.1889750063419342,
              0.7581930160522461,
              0.1889750063419342,
              0.7581549882888794,
              0.18891499936580658,
              0.7581160068511963,
              0.18987399339675903,
              0.7581549882888794,
              0.18891499936580658,
              0.7580789923667908,
              0.18981599807739258,
              0.7581160068511963,
              0.18987399339675903,
              0.7581160068511963,
              0.18987399339675903,
              0.7580789923667908,
              0.18981599807739258,
              0.7580429911613464,
              0.19099999964237213,
              0.7580789923667908,
              0.18981599807739258,
              0.7580059766769409,
              0.19094400107860565,
              0.7580429911613464,
              0.19099999964237213,
              0.7580429911613464,
              0.19099999964237213,
              0.7580059766769409,
              0.19094400107860565,
              0.7579740285873413,
              0.1923390030860901,
              0.7580059766769409,
              0.19094400107860565,
              0.7579370141029358,
              0.19228500127792358,
              0.7579740285873413,
              0.1923390030860901,
              0.7579089999198914,
              0.1938759982585907,
              0.7578719854354858,
              0.19382600486278534,
              0.7578489780426025,
              0.19559800624847412,
              0.7578719854354858,
              0.19382600486278534,
              0.7578110098838806,
              0.19555099308490753,
              0.7578489780426025,
              0.19559800624847412,
              0.7576149702072144,
              0.2040649950504303,
              0.7575780153274536,
              0.20403599739074707,
              0.7575560212135315,
              0.20650799572467804,
              0.7575780153274536,
              0.20403599739074707,
              0.757519006729126,
              0.20648400485515594,
              0.7575560212135315,
              0.20650799572467804,
              0.7574949860572815,
              0.20905299484729767,
              0.757457971572876,
              0.2090349942445755,
              0.7574319839477539,
              0.21168799698352814,
              0.757457971572876,
              0.2090349942445755,
              0.757394015789032,
              0.2116750031709671,
              0.7574319839477539,
              0.21168799698352814,
              0.7574319839477539,
              0.21168799698352814,
              0.757394015789032,
              0.2116750031709671,
              0.7573670148849487,
              0.21439799666404724,
              0.757394015789032,
              0.2116750031709671,
              0.757328987121582,
              0.21439099311828613,
              0.7573670148849487,
              0.21439799666404724,
              0.7573670148849487,
              0.21439799666404724,
              0.757328987121582,
              0.21439099311828613,
              0.7573000192642212,
              0.21717000007629395,
              0.757328987121582,
              0.21439099311828613,
              0.7572630047798157,
              0.21716900169849396,
              0.7573000192642212,
              0.21717000007629395,
              0.7573000192642212,
              0.21717000007629395,
              0.7572630047798157,
              0.21716900169849396,
              0.7572320103645325,
              0.21999099850654602,
              0.7572630047798157,
              0.21716900169849396,
              0.757194995880127,
              0.21999500691890717,
              0.7572320103645325,
              0.21999099850654602,
              0.7582899928092957,
              0.19568899273872375,
              0.7582539916038513,
              0.19575099647045135,
              0.7583500146865845,
              0.1953279972076416,
              0.7582539916038513,
              0.19575099647045135,
              0.7583140134811401,
              0.1953900009393692,
              0.7583500146865845,
              0.1953279972076416,
              0.7581760287284851,
              0.19694499671459198,
              0.7581400275230408,
              0.19700400531291962,
              0.7582319974899292,
              0.19623200595378876,
              0.7581400275230408,
              0.19700400531291962,
              0.7581959962844849,
              0.1962919980287552,
              0.7582319974899292,
              0.19623200595378876,
              0.7581239938735962,
              0.19781999289989471,
              0.7580869793891907,
              0.1978760063648224,
              0.7581760287284851,
              0.19694499671459198,
              0.7580869793891907,
              0.1978760063648224,
              0.7581400275230408,
              0.19700400531291962,
              0.7581760287284851,
              0.19694499671459198,
              0.7572259902954102,
              0.22148799896240234,
              0.7571899890899658,
              0.22147700190544128,
              0.7572939991950989,
              0.21938900649547577,
              0.7571899890899658,
              0.22147700190544128,
              0.7572579979896545,
              0.21938399970531464,
              0.7572939991950989,
              0.21938900649547577,
              0.7572939991950989,
              0.21938900649547577,
              0.7572579979896545,
              0.21938399970531464,
              0.7573630213737488,
              0.2173140048980713,
              0.7572579979896545,
              0.21938399970531464,
              0.7573270201683044,
              0.21731500327587128,
              0.7573630213737488,
              0.2173140048980713,
              0.7573630213737488,
              0.2173140048980713,
              0.7573270201683044,
              0.21731500327587128,
              0.7574329972267151,
              0.21527099609375,
              0.7573270201683044,
              0.21731500327587128,
              0.7573959827423096,
              0.2152779996395111,
              0.7574329972267151,
              0.21527099609375,
              0.7574329972267151,
              0.21527099609375,
              0.7573959827423096,
              0.2152779996395111,
              0.757502019405365,
              0.21327200531959534,
              0.7573959827423096,
              0.2152779996395111,
              0.7574660181999207,
              0.21328499913215637,
              0.757502019405365,
              0.21327200531959534,
              0.75764000415802,
              0.20944300293922424,
              0.7576040029525757,
              0.20946699380874634,
              0.7577080130577087,
              0.20763200521469116,
              0.7576040029525757,
              0.20946699380874634,
              0.7576720118522644,
              0.2076610028743744,
              0.7577080130577087,
              0.20763200521469116,
              0.7577750086784363,
              0.2059040069580078,
              0.7577390074729919,
              0.20593799650669098,
              0.757839024066925,
              0.20426900684833527,
              0.7577390074729919,
              0.20593799650669098,
              0.7578030228614807,
              0.20430700480937958,
              0.757839024066925,
              0.20426900684833527,
              0.757839024066925,
              0.20426900684833527,
              0.7578030228614807,
              0.20430700480937958,
              0.7579020261764526,
              0.20273500680923462,
              0.7578030228614807,
              0.20430700480937958,
              0.7578660249710083,
              0.20277799665927887,
              0.7579020261764526,
              0.20273500680923462,
              0.7579020261764526,
              0.20273500680923462,
              0.7578660249710083,
              0.20277799665927887,
              0.7579619884490967,
              0.20131400227546692,
              0.7578660249710083,
              0.20277799665927887,
              0.7579259872436523,
              0.20136100053787231,
              0.7579619884490967,
              0.20131400227546692,
              0.7570589780807495,
              0.22569400072097778,
              0.7570949792861938,
              0.2257159948348999,
              0.7570090293884277,
              0.22777099907398224,
              0.7570949792861938,
              0.2257159948348999,
              0.7570449709892273,
              0.22779999673366547,
              0.7570090293884277,
              0.22777099907398224,
              0.7584400177001953,
              0.18776999413967133,
              0.758525013923645,
              0.18776999413967133,
              0.7584030032157898,
              0.1877090036869049,
              0.758525013923645,
              0.18776999413967133,
              0.7584890127182007,
              0.1877090036869049,
              0.7584030032157898,
              0.1877090036869049,
              0.7578719854354858,
              0.19382600486278534,
              0.7579089999198914,
              0.1938759982585907,
              0.7579370141029358,
              0.19228500127792358,
              0.7579089999198914,
              0.1938759982585907,
              0.7579740285873413,
              0.1923390030860901,
              0.7579370141029358,
              0.19228500127792358,
              0.7577469944953918,
              0.1974489986896515,
              0.7577850222587585,
              0.19749200344085693,
              0.7578110098838806,
              0.19555099308490753,
              0.7577850222587585,
              0.19749200344085693,
              0.7578489780426025,
              0.19559800624847412,
              0.7578110098838806,
              0.19555099308490753,
              0.7576860189437866,
              0.1995050013065338,
              0.7577239871025085,
              0.1995439976453781,
              0.7577469944953918,
              0.1974489986896515,
              0.7577239871025085,
              0.1995439976453781,
              0.7577850222587585,
              0.19749200344085693,
              0.7577469944953918,
              0.1974489986896515,
              0.7576329708099365,
              0.2017049938440323,
              0.7576709985733032,
              0.20173899829387665,
              0.7576860189437866,
              0.1995050013065338,
              0.7576709985733032,
              0.20173899829387665,
              0.7577239871025085,
              0.1995439976453781,
              0.7576860189437866,
              0.1995050013065338,
              0.7575780153274536,
              0.20403599739074707,
              0.7576149702072144,
              0.2040649950504303,
              0.7576329708099365,
              0.2017049938440323,
              0.7576149702072144,
              0.2040649950504303,
              0.7576709985733032,
              0.20173899829387665,
              0.7576329708099365,
              0.2017049938440323,
              0.757457971572876,
              0.2090349942445755,
              0.7574949860572815,
              0.20905299484729767,
              0.757519006729126,
              0.20648400485515594,
              0.7574949860572815,
              0.20905299484729767,
              0.7575560212135315,
              0.20650799572467804,
              0.757519006729126,
              0.20648400485515594,
              0.7571269869804382,
              0.22285600006580353,
              0.7571640014648438,
              0.22284600138664246,
              0.757194995880127,
              0.21999500691890717,
              0.7571640014648438,
              0.22284600138664246,
              0.7572320103645325,
              0.21999099850654602,
              0.757194995880127,
              0.21999500691890717,
              0.7570580244064331,
              0.22573800384998322,
              0.7570949792861938,
              0.22572100162506104,
              0.7571269869804382,
              0.22285600006580353,
              0.7570949792861938,
              0.22572100162506104,
              0.7571640014648438,
              0.22284600138664246,
              0.7571269869804382,
              0.22285600006580353,
              0.756987988948822,
              0.22862599790096283,
              0.7570260167121887,
              0.2286040037870407,
              0.7570580244064331,
              0.22573800384998322,
              0.7570260167121887,
              0.2286040037870407,
              0.7570949792861938,
              0.22572100162506104,
              0.7570580244064331,
              0.22573800384998322,
              0.7583760023117065,
              0.1952189952135086,
              0.7584130167961121,
              0.1951570063829422,
              0.7583140134811401,
              0.1953900009393692,
              0.7584130167961121,
              0.1951570063829422,
              0.7583500146865845,
              0.1953279972076416,
              0.7583140134811401,
              0.1953900009393692,
              0.7582539916038513,
              0.19575099647045135,
              0.7582899928092957,
              0.19568899273872375,
              0.7581959962844849,
              0.1962919980287552,
              0.7582899928092957,
              0.19568899273872375,
              0.7582319974899292,
              0.19623200595378876,
              0.7581959962844849,
              0.1962919980287552,
              0.7580869793891907,
              0.1978760063648224,
              0.7581239938735962,
              0.19781999289989471,
              0.7580369710922241,
              0.1988999992609024,
              0.7581239938735962,
              0.19781999289989471,
              0.7580729722976685,
              0.1988459974527359,
              0.7580369710922241,
              0.1988999992609024,
              0.7571229934692383,
              0.22358399629592896,
              0.7571600079536438,
              0.22360000014305115,
              0.7570589780807495,
              0.22569400072097778,
              0.7571600079536438,
              0.22360000014305115,
              0.7570949792861938,
              0.2257159948348999,
              0.7570589780807495,
              0.22569400072097778,
              0.7571899890899658,
              0.22147700190544128,
              0.7572259902954102,
              0.22148799896240234,
              0.7571229934692383,
              0.22358399629592896,
              0.7572259902954102,
              0.22148799896240234,
              0.7571600079536438,
              0.22360000014305115,
              0.7571229934692383,
              0.22358399629592896,
              0.7575349807739258,
              0.21134500205516815,
              0.7575719952583313,
              0.211326003074646,
              0.7574660181999207,
              0.21328499913215637,
              0.7575719952583313,
              0.211326003074646,
              0.757502019405365,
              0.21327200531959534,
              0.7574660181999207,
              0.21328499913215637,
              0.7576040029525757,
              0.20946699380874634,
              0.75764000415802,
              0.20944300293922424,
              0.7575349807739258,
              0.21134500205516815,
              0.75764000415802,
              0.20944300293922424,
              0.7575719952583313,
              0.211326003074646,
              0.7575349807739258,
              0.21134500205516815,
              0.7577390074729919,
              0.20593799650669098,
              0.7577750086784363,
              0.2059040069580078,
              0.7576720118522644,
              0.2076610028743744,
              0.7577750086784363,
              0.2059040069580078,
              0.7577080130577087,
              0.20763200521469116,
              0.7576720118522644,
              0.2076610028743744,
              0.7579830288887024,
              0.2000650018453598,
              0.758018970489502,
              0.20001399517059326,
              0.7579259872436523,
              0.20136100053787231,
              0.758018970489502,
              0.20001399517059326,
              0.7579619884490967,
              0.20131400227546692,
              0.7579259872436523,
              0.20136100053787231,
              0.7580369710922241,
              0.1988999992609024,
              0.7580729722976685,
              0.1988459974527359,
              0.7579830288887024,
              0.2000650018453598,
              0.7580729722976685,
              0.1988459974527359,
              0.758018970489502,
              0.20001399517059326,
              0.7579830288887024,
              0.2000650018453598,
              0.756987988948822,
              0.22862599790096283,
              0.7569199800491333,
              0.23147399723529816,
              0.7570260167121887,
              0.2286040037870407,
              0.7569199800491333,
              0.23147399723529816,
              0.7569580078125,
              0.23144599795341492,
              0.7570260167121887,
              0.2286040037870407,
              0.7584130167961121,
              0.1951570063829422,
              0.7583760023117065,
              0.1952189952135086,
              0.7584760189056396,
              0.1950870007276535,
              0.7583760023117065,
              0.1952189952135086,
              0.7584390044212341,
              0.19514800608158112,
              0.7584760189056396,
              0.1950870007276535,
              0.758450984954834,
              0.18795299530029297,
              0.7584400177001953,
              0.18776999413967133,
              0.7583670020103455,
              0.18809199333190918,
              0.7584400177001953,
              0.18776999413967133,
              0.7583540081977844,
              0.18790900707244873,
              0.7583670020103455,
              0.18809199333190918,
              0.7583670020103455,
              0.18809199333190918,
              0.7583540081977844,
              0.18790900707244873,
              0.7582849860191345,
              0.18849800527095795,
              0.7583540081977844,
              0.18790900707244873,
              0.7582719922065735,
              0.1883150041103363,
              0.7582849860191345,
              0.18849800527095795,
              0.7582849860191345,
              0.18849800527095795,
              0.7582719922065735,
              0.1883150041103363,
              0.7582060098648071,
              0.1891549974679947,
              0.7582719922065735,
              0.1883150041103363,
              0.7581930160522461,
              0.1889750063419342,
              0.7582060098648071,
              0.1891549974679947,
              0.7582060098648071,
              0.1891549974679947,
              0.7581930160522461,
              0.1889750063419342,
              0.7581300139427185,
              0.19005000591278076,
              0.7581930160522461,
              0.1889750063419342,
              0.7581160068511963,
              0.18987399339675903,
              0.7581300139427185,
              0.19005000591278076,
              0.7581300139427185,
              0.19005000591278076,
              0.7581160068511963,
              0.18987399339675903,
              0.7580580115318298,
              0.19116899371147156,
              0.7581160068511963,
              0.18987399339675903,
              0.7580429911613464,
              0.19099999964237213,
              0.7580580115318298,
              0.19116899371147156,
              0.7580580115318298,
              0.19116899371147156,
              0.7580429911613464,
              0.19099999964237213,
              0.7579889893531799,
              0.19249999523162842,
              0.7580429911613464,
              0.19099999964237213,
              0.7579740285873413,
              0.1923390030860901,
              0.7579889893531799,
              0.19249999523162842,
              0.7579240202903748,
              0.19402800500392914,
              0.7579089999198914,
              0.1938759982585907,
              0.7578639984130859,
              0.1957390010356903,
              0.7579089999198914,
              0.1938759982585907,
              0.7578489780426025,
              0.19559800624847412,
              0.7578639984130859,
              0.1957390010356903,
              0.7576289772987366,
              0.20415200293064117,
              0.7576149702072144,
              0.2040649950504303,
              0.7575709819793701,
              0.20657899975776672,
              0.7576149702072144,
              0.2040649950504303,
              0.7575560212135315,
              0.20650799572467804,
              0.7575709819793701,
              0.20657899975776672,
              0.7575089931488037,
              0.20910799503326416,
              0.7574949860572815,
              0.20905299484729767,
              0.7574459910392761,
              0.21172599494457245,
              0.7574949860572815,
              0.20905299484729767,
              0.7574319839477539,
              0.21168799698352814,
              0.7574459910392761,
              0.21172599494457245,
              0.7574459910392761,
              0.21172599494457245,
              0.7574319839477539,
              0.21168799698352814,
              0.7573800086975098,
              0.21441900730133057,
              0.7574319839477539,
              0.21168799698352814,
              0.7573670148849487,
              0.21439799666404724,
              0.7573800086975098,
              0.21441900730133057,
              0.7573800086975098,
              0.21441900730133057,
              0.7573670148849487,
              0.21439799666404724,
              0.7573140263557434,
              0.2171739935874939,
              0.7573670148849487,
              0.21439799666404724,
              0.7573000192642212,
              0.21717000007629395,
              0.7573140263557434,
              0.2171739935874939,
              0.7573140263557434,
              0.2171739935874939,
              0.7573000192642212,
              0.21717000007629395,
              0.7572460174560547,
              0.219977006316185,
              0.7573000192642212,
              0.21717000007629395,
              0.7572320103645325,
              0.21999099850654602,
              0.7572460174560547,
              0.219977006316185,
              0.7583019733428955,
              0.1955060064792633,
              0.7582899928092957,
              0.19568899273872375,
              0.7583630084991455,
              0.19514399766921997,
              0.7582899928092957,
              0.19568899273872375,
              0.7583500146865845,
              0.1953279972076416,
              0.7583630084991455,
              0.19514399766921997,
              0.7581869959831238,
              0.19676999747753143,
              0.7581760287284851,
              0.19694499671459198,
              0.7582430243492126,
              0.19605199992656708,
              0.7581760287284851,
              0.19694499671459198,
              0.7582319974899292,
              0.19623200595378876,
              0.7582430243492126,
              0.19605199992656708,
              0.7581340074539185,
              0.1976509988307953,
              0.7581239938735962,
              0.19781999289989471,
              0.7581869959831238,
              0.19676999747753143,
              0.7581239938735962,
              0.19781999289989471,
              0.7581760287284851,
              0.19694499671459198,
              0.7581869959831238,
              0.19676999747753143,
              0.7572370171546936,
              0.22152000665664673,
              0.7572259902954102,
              0.22148799896240234,
              0.7573050260543823,
              0.2194029986858368,
              0.7572259902954102,
              0.22148799896240234,
              0.7572939991950989,
              0.21938900649547577,
              0.7573050260543823,
              0.2194029986858368,
              0.7573050260543823,
              0.2194029986858368,
              0.7572939991950989,
              0.21938900649547577,
              0.7573739886283875,
              0.21730999648571014,
              0.7572939991950989,
              0.21938900649547577,
              0.7573630213737488,
              0.2173140048980713,
              0.7573739886283875,
              0.21730999648571014,
              0.7573739886283875,
              0.21730999648571014,
              0.7573630213737488,
              0.2173140048980713,
              0.7574430108070374,
              0.21525099873542786,
              0.7573630213737488,
              0.2173140048980713,
              0.7574329972267151,
              0.21527099609375,
              0.7574430108070374,
              0.21525099873542786,
              0.7574430108070374,
              0.21525099873542786,
              0.7574329972267151,
              0.21527099609375,
              0.7575129866600037,
              0.21323400735855103,
              0.7574329972267151,
              0.21527099609375,
              0.757502019405365,
              0.21327200531959534,
              0.7575129866600037,
              0.21323400735855103,
              0.7576509714126587,
              0.20937199890613556,
              0.75764000415802,
              0.20944300293922424,
              0.757718026638031,
              0.20754599571228027,
              0.75764000415802,
              0.20944300293922424,
              0.7577080130577087,
              0.20763200521469116,
              0.757718026638031,
              0.20754599571228027,
              0.7577840089797974,
              0.2058030068874359,
              0.7577750086784363,
              0.2059040069580078,
              0.7578489780426025,
              0.20415300130844116,
              0.7577750086784363,
              0.2059040069580078,
              0.757839024066925,
              0.20426900684833527,
              0.7578489780426025,
              0.20415300130844116,
              0.7578489780426025,
              0.20415300130844116,
              0.757839024066925,
              0.20426900684833527,
              0.7579110264778137,
              0.2026059925556183,
              0.757839024066925,
              0.20426900684833527,
              0.7579020261764526,
              0.20273500680923462,
              0.7579110264778137,
              0.2026059925556183,
              0.7579110264778137,
              0.2026059925556183,
              0.7579020261764526,
              0.20273500680923462,
              0.757972002029419,
              0.20117299258708954,
              0.7579020261764526,
              0.20273500680923462,
              0.7579619884490967,
              0.20131400227546692,
              0.757972002029419,
              0.20117299258708954,
              0.7571049928665161,
              0.22578300535678864,
              0.7570549845695496,
              0.22788499295711517,
              0.7570949792861938,
              0.2257159948348999,
              0.7570549845695496,
              0.22788499295711517,
              0.7570449709892273,
              0.22779999673366547,
              0.7570949792861938,
              0.2257159948348999,
              0.7584400177001953,
              0.18776999413967133,
              0.758450984954834,
              0.18795299530029297,
              0.758525013923645,
              0.18776999413967133,
              0.758450984954834,
              0.18795299530029297,
              0.7585359811782837,
              0.18795299530029297,
              0.758525013923645,
              0.18776999413967133,
              0.7584270238876343,
              0.19475799798965454,
              0.7584499716758728,
              0.188167005777359,
              0.7583630084991455,
              0.19492800533771515,
              0.7584499716758728,
              0.188167005777359,
              0.7583659887313843,
              0.1883080005645752,
              0.7583630084991455,
              0.19492800533771515,
              0.7583630084991455,
              0.19492800533771515,
              0.7583659887313843,
              0.1883080005645752,
              0.7583010196685791,
              0.19529099762439728,
              0.7583659887313843,
              0.1883080005645752,
              0.7582849860191345,
              0.1887120008468628,
              0.7583010196685791,
              0.19529099762439728,
              0.7583010196685791,
              0.19529099762439728,
              0.7582849860191345,
              0.1887120008468628,
              0.7582420110702515,
              0.1958400011062622,
              0.7582849860191345,
              0.1887120008468628,
              0.7582070231437683,
              0.18936499953269958,
              0.7582420110702515,
              0.1958400011062622,
              0.7582420110702515,
              0.1958400011062622,
              0.7582070231437683,
              0.18936499953269958,
              0.7581859827041626,
              0.19656400382518768,
              0.7582070231437683,
              0.18936499953269958,
              0.7581319808959961,
              0.19025500118732452,
              0.7581859827041626,
              0.19656400382518768,
              0.7581859827041626,
              0.19656400382518768,
              0.7581319808959961,
              0.19025500118732452,
              0.7581310272216797,
              0.19745300710201263,
              0.7581319808959961,
              0.19025500118732452,
              0.7580599784851074,
              0.1913670003414154,
              0.7581310272216797,
              0.19745300710201263,
              0.7581310272216797,
              0.19745300710201263,
              0.7580599784851074,
              0.1913670003414154,
              0.758080005645752,
              0.1984959989786148,
              0.7580599784851074,
              0.1913670003414154,
              0.7579920291900635,
              0.1926880031824112,
              0.758080005645752,
              0.1984959989786148,
              0.758080005645752,
              0.1984959989786148,
              0.7579920291900635,
              0.1926880031824112,
              0.7580260038375854,
              0.1996850073337555,
              0.7579920291900635,
              0.1926880031824112,
              0.7579270005226135,
              0.19420500099658966,
              0.7580260038375854,
              0.1996850073337555,
              0.7580260038375854,
              0.1996850073337555,
              0.7579270005226135,
              0.19420500099658966,
              0.757968008518219,
              0.20100800693035126,
              0.7579270005226135,
              0.19420500099658966,
              0.7578669786453247,
              0.19590400159358978,
              0.757968008518219,
              0.20100800693035126,
              0.757968008518219,
              0.20100800693035126,
              0.7578669786453247,
              0.19590400159358978,
              0.7579079866409302,
              0.20245499908924103,
              0.7578669786453247,
              0.19590400159358978,
              0.7578030228614807,
              0.19777199625968933,
              0.7579079866409302,
              0.20245499908924103,
              0.7579079866409302,
              0.20245499908924103,
              0.7578030228614807,
              0.19777199625968933,
              0.7578459978103638,
              0.20401699841022491,
              0.7578030228614807,
              0.19777199625968933,
              0.7577419877052307,
              0.19979600608348846,
              0.7578459978103638,
              0.20401699841022491,
              0.7578459978103638,
              0.20401699841022491,
              0.7577419877052307,
              0.19979600608348846,
              0.757781982421875,
              0.20568299293518066,
              0.7577419877052307,
              0.19979600608348846,
              0.7576889991760254,
              0.2019609957933426,
              0.757781982421875,
              0.20568299293518066,
              0.757781982421875,
              0.20568299293518066,
              0.7576889991760254,
              0.2019609957933426,
              0.7577160000801086,
              0.2074439972639084,
              0.7576889991760254,
              0.2019609957933426,
              0.7576320171356201,
              0.20425400137901306,
              0.7577160000801086,
              0.2074439972639084,
              0.7576479911804199,
              0.20928800106048584,
              0.7577160000801086,
              0.2074439972639084,
              0.7575730085372925,
              0.20666299760341644,
              0.7577160000801086,
              0.2074439972639084,
              0.7576320171356201,
              0.20425400137901306,
              0.7575730085372925,
              0.20666299760341644,
              0.7575799822807312,
              0.21120700240135193,
              0.7576479911804199,
              0.20928800106048584,
              0.7575110197067261,
              0.20917299389839172,
              0.7576479911804199,
              0.20928800106048584,
              0.7575730085372925,
              0.20666299760341644,
              0.7575110197067261,
              0.20917299389839172,
              0.7575110197067261,
              0.2131900042295456,
              0.7575799822807312,
              0.21120700240135193,
              0.7574480175971985,
              0.21177099645137787,
              0.7575799822807312,
              0.21120700240135193,
              0.7575110197067261,
              0.20917299389839172,
              0.7574480175971985,
              0.21177099645137787,
              0.757440984249115,
              0.21522599458694458,
              0.7575110197067261,
              0.2131900042295456,
              0.7573819756507874,
              0.21444399654865265,
              0.7575110197067261,
              0.2131900042295456,
              0.7574480175971985,
              0.21177099645137787,
              0.7573819756507874,
              0.21444399654865265,
              0.7573720216751099,
              0.2173060029745102,
              0.757440984249115,
              0.21522599458694458,
              0.757315993309021,
              0.21717800199985504,
              0.757440984249115,
              0.21522599458694458,
              0.7573819756507874,
              0.21444399654865265,
              0.757315993309021,
              0.21717800199985504,
              0.75730299949646,
              0.21942000091075897,
              0.7573720216751099,
              0.2173060029745102,
              0.7572479844093323,
              0.21996000409126282,
              0.7573720216751099,
              0.2173060029745102,
              0.757315993309021,
              0.21717800199985504,
              0.7572479844093323,
              0.21996000409126282,
              0.7572349905967712,
              0.22155700623989105,
              0.75730299949646,
              0.21942000091075897,
              0.7571790218353271,
              0.22277599573135376,
              0.75730299949646,
              0.21942000091075897,
              0.7572479844093323,
              0.21996000409126282,
              0.7571790218353271,
              0.22277599573135376,
              0.7571690082550049,
              0.22370800375938416,
              0.7572349905967712,
              0.22155700623989105,
              0.7571099996566772,
              0.22561399638652802,
              0.7572349905967712,
              0.22155700623989105,
              0.7571790218353271,
              0.22277599573135376,
              0.7571099996566772,
              0.22561399638652802,
              0.7571030259132385,
              0.22586199641227722,
              0.7571690082550049,
              0.22370800375938416,
              0.7570409774780273,
              0.2284580022096634,
              0.7571690082550049,
              0.22370800375938416,
              0.7571099996566772,
              0.22561399638652802,
              0.7570409774780273,
              0.2284580022096634,
              0.7570409774780273,
              0.2284580022096634,
              0.7569739818572998,
              0.2312619984149933,
              0.7571030259132385,
              0.22586199641227722,
              0.7569739818572998,
              0.2312619984149933,
              0.757053017616272,
              0.2279839962720871,
              0.7571030259132385,
              0.22586199641227722,
              0.7624419927597046,
              0.18816600739955902,
              0.7585350275039673,
              0.188167005777359,
              0.7624419927597046,
              0.1902800053358078,
              0.7585350275039673,
              0.188167005777359,
              0.7584909796714783,
              0.19468599557876587,
              0.7624419927597046,
              0.1902800053358078,
              0.7579920291900635,
              0.1926880031824112,
              0.7579889893531799,
              0.19249999523162842,
              0.7579270005226135,
              0.19420500099658966,
              0.7579889893531799,
              0.19249999523162842,
              0.7579240202903748,
              0.19402800500392914,
              0.7579270005226135,
              0.19420500099658966,
              0.7578669786453247,
              0.19590400159358978,
              0.7578639984130859,
              0.1957390010356903,
              0.7578030228614807,
              0.19777199625968933,
              0.7578639984130859,
              0.1957390010356903,
              0.7577999830245972,
              0.19762100279331207,
              0.7578030228614807,
              0.19777199625968933,
              0.7578030228614807,
              0.19777199625968933,
              0.7577999830245972,
              0.19762100279331207,
              0.7577419877052307,
              0.19979600608348846,
              0.7577999830245972,
              0.19762100279331207,
              0.7577390074729919,
              0.19965900480747223,
              0.7577419877052307,
              0.19979600608348846,
              0.7577419877052307,
              0.19979600608348846,
              0.7577390074729919,
              0.19965900480747223,
              0.7576889991760254,
              0.2019609957933426,
              0.7577390074729919,
              0.19965900480747223,
              0.7576860189437866,
              0.20184099674224854,
              0.7576889991760254,
              0.2019609957933426,
              0.7576889991760254,
              0.2019609957933426,
              0.7576860189437866,
              0.20184099674224854,
              0.7576320171356201,
              0.20425400137901306,
              0.7576860189437866,
              0.20184099674224854,
              0.7576289772987366,
              0.20415200293064117,
              0.7576320171356201,
              0.20425400137901306,
              0.7575730085372925,
              0.20666299760341644,
              0.7575709819793701,
              0.20657899975776672,
              0.7575110197067261,
              0.20917299389839172,
              0.7575709819793701,
              0.20657899975776672,
              0.7575089931488037,
              0.20910799503326416,
              0.7575110197067261,
              0.20917299389839172,
              0.7572479844093323,
              0.21996000409126282,
              0.7572460174560547,
              0.219977006316185,
              0.7571790218353271,
              0.22277599573135376,
              0.7572460174560547,
              0.219977006316185,
              0.7571769952774048,
              0.22281399369239807,
              0.7571790218353271,
              0.22277599573135376,
              0.7571790218353271,
              0.22277599573135376,
              0.7571769952774048,
              0.22281399369239807,
              0.7571099996566772,
              0.22561399638652802,
              0.7571769952774048,
              0.22281399369239807,
              0.7571079730987549,
              0.22567200660705566,
              0.7571099996566772,
              0.22561399638652802,
              0.7571099996566772,
              0.22561399638652802,
              0.7571079730987549,
              0.22567200660705566,
              0.7570409774780273,
              0.2284580022096634,
              0.7571079730987549,
              0.22567200660705566,
              0.7570390105247498,
              0.22853699326515198,
              0.7570409774780273,
              0.2284580022096634,
              0.7583630084991455,
              0.19492800533771515,
              0.7583630084991455,
              0.19514399766921997,
              0.7584270238876343,
              0.19475799798965454,
              0.7583630084991455,
              0.19514399766921997,
              0.7584260106086731,
              0.19497300684452057,
              0.7584270238876343,
              0.19475799798965454,
              0.7582420110702515,
              0.1958400011062622,
              0.7582430243492126,
              0.19605199992656708,
              0.7583010196685791,
              0.19529099762439728,
              0.7582430243492126,
              0.19605199992656708,
              0.7583019733428955,
              0.1955060064792633,
              0.7583010196685791,
              0.19529099762439728,
              0.758080005645752,
              0.1984959989786148,
              0.7580829858779907,
              0.19868500530719757,
              0.7581310272216797,
              0.19745300710201263,
              0.7580829858779907,
              0.19868500530719757,
              0.7581340074539185,
              0.1976509988307953,
              0.7581310272216797,
              0.19745300710201263,
              0.7571030259132385,
              0.22586199641227722,
              0.7571049928665161,
              0.22578300535678864,
              0.7571690082550049,
              0.22370800375938416,
              0.7571049928665161,
              0.22578300535678864,
              0.7571700215339661,
              0.2236499935388565,
              0.7571690082550049,
              0.22370800375938416,
              0.7571690082550049,
              0.22370800375938416,
              0.7571700215339661,
              0.2236499935388565,
              0.7572349905967712,
              0.22155700623989105,
              0.7571700215339661,
              0.2236499935388565,
              0.7572370171546936,
              0.22152000665664673,
              0.7572349905967712,
              0.22155700623989105,
              0.7575110197067261,
              0.2131900042295456,
              0.7575129866600037,
              0.21323400735855103,
              0.7575799822807312,
              0.21120700240135193,
              0.7575129866600037,
              0.21323400735855103,
              0.7575820088386536,
              0.2112710028886795,
              0.7575799822807312,
              0.21120700240135193,
              0.7575799822807312,
              0.21120700240135193,
              0.7575820088386536,
              0.2112710028886795,
              0.7576479911804199,
              0.20928800106048584,
              0.7575820088386536,
              0.2112710028886795,
              0.7576509714126587,
              0.20937199890613556,
              0.7576479911804199,
              0.20928800106048584,
              0.7577160000801086,
              0.2074439972639084,
              0.757718026638031,
              0.20754599571228027,
              0.757781982421875,
              0.20568299293518066,
              0.757718026638031,
              0.20754599571228027,
              0.7577840089797974,
              0.2058030068874359,
              0.757781982421875,
              0.20568299293518066,
              0.757968008518219,
              0.20100800693035126,
              0.757972002029419,
              0.20117299258708954,
              0.7580260038375854,
              0.1996850073337555,
              0.757972002029419,
              0.20117299258708954,
              0.7580289840698242,
              0.199863001704216,
              0.7580260038375854,
              0.1996850073337555,
              0.7580260038375854,
              0.1996850073337555,
              0.7580289840698242,
              0.199863001704216,
              0.758080005645752,
              0.1984959989786148,
              0.7580289840698242,
              0.199863001704216,
              0.7580829858779907,
              0.19868500530719757,
              0.758080005645752,
              0.1984959989786148,
              0.7570390105247498,
              0.22853699326515198,
              0.7569720149040222,
              0.2313610017299652,
              0.7570409774780273,
              0.2284580022096634,
              0.7569720149040222,
              0.2313610017299652,
              0.7569739818572998,
              0.2312619984149933,
              0.7570409774780273,
              0.2284580022096634,
              0.7584270238876343,
              0.19475799798965454,
              0.7584260106086731,
              0.19497300684452057,
              0.7584909796714783,
              0.19468599557876587,
              0.7584260106086731,
              0.19497300684452057,
              0.7584890127182007,
              0.19490200281143188,
              0.7584909796714783,
              0.19468599557876587,
              0.7582740187644958,
              0.18784800171852112,
              0.7583180069923401,
              0.18784800171852112,
              0.7583600282669067,
              0.1877090036869049,
              0.7583180069923401,
              0.18784800171852112,
              0.7584030032157898,
              0.1877090036869049,
              0.7583600282669067,
              0.1877090036869049,
              0.7581920027732849,
              0.1882539987564087,
              0.758234977722168,
              0.1882539987564087,
              0.7582740187644958,
              0.18784800171852112,
              0.758234977722168,
              0.1882539987564087,
              0.7583180069923401,
              0.18784800171852112,
              0.7582740187644958,
              0.18784800171852112,
              0.7581120133399963,
              0.18891499936580658,
              0.7581549882888794,
              0.18891499936580658,
              0.7581920027732849,
              0.1882539987564087,
              0.7581549882888794,
              0.18891499936580658,
              0.758234977722168,
              0.1882539987564087,
              0.7581920027732849,
              0.1882539987564087,
              0.7580360174179077,
              0.18981599807739258,
              0.7580789923667908,
              0.18981599807739258,
              0.7581120133399963,
              0.18891499936580658,
              0.7580789923667908,
              0.18981599807739258,
              0.7581549882888794,
              0.18891499936580658,
              0.7581120133399963,
              0.18891499936580658,
              0.7579630017280579,
              0.19094400107860565,
              0.7580059766769409,
              0.19094400107860565,
              0.7580360174179077,
              0.18981599807739258,
              0.7580059766769409,
              0.19094400107860565,
              0.7580789923667908,
              0.18981599807739258,
              0.7580360174179077,
              0.18981599807739258,
              0.757893979549408,
              0.19228500127792358,
              0.7579370141029358,
              0.19228500127792358,
              0.7579630017280579,
              0.19094400107860565,
              0.7579370141029358,
              0.19228500127792358,
              0.7580059766769409,
              0.19094400107860565,
              0.7579630017280579,
              0.19094400107860565,
              0.7577679753303528,
              0.19555099308490753,
              0.7578110098838806,
              0.19555099308490753,
              0.7578290104866028,
              0.19382600486278534,
              0.7578110098838806,
              0.19555099308490753,
              0.7578719854354858,
              0.19382600486278534,
              0.7578290104866028,
              0.19382600486278534,
              0.7574759721755981,
              0.20648400485515594,
              0.757519006729126,
              0.20648400485515594,
              0.7575349807739258,
              0.20403599739074707,
              0.757519006729126,
              0.20648400485515594,
              0.7575780153274536,
              0.20403599739074707,
              0.7575349807739258,
              0.20403599739074707,
              0.7573509812355042,
              0.2116750031709671,
              0.757394015789032,
              0.2116750031709671,
              0.7574149966239929,
              0.2090349942445755,
              0.757394015789032,
              0.2116750031709671,
              0.757457971572876,
              0.2090349942445755,
              0.7574149966239929,
              0.2090349942445755,
              0.757286012172699,
              0.21439099311828613,
              0.757328987121582,
              0.21439099311828613,
              0.7573509812355042,
              0.2116750031709671,
              0.757328987121582,
              0.21439099311828613,
              0.757394015789032,
              0.2116750031709671,
              0.7573509812355042,
              0.2116750031709671,
              0.7572199702262878,
              0.21716900169849396,
              0.7572630047798157,
              0.21716900169849396,
              0.757286012172699,
              0.21439099311828613,
              0.7572630047798157,
              0.21716900169849396,
              0.757328987121582,
              0.21439099311828613,
              0.757286012172699,
              0.21439099311828613,
              0.7571520209312439,
              0.21999500691890717,
              0.757194995880127,
              0.21999500691890717,
              0.7572199702262878,
              0.21716900169849396,
              0.757194995880127,
              0.21999500691890717,
              0.7572630047798157,
              0.21716900169849396,
              0.7572199702262878,
              0.21716900169849396,
              0.7582709789276123,
              0.1953900009393692,
              0.7583140134811401,
              0.1953900009393692,
              0.7582100033760071,
              0.19575099647045135,
              0.7583140134811401,
              0.1953900009393692,
              0.7582539916038513,
              0.19575099647045135,
              0.7582100033760071,
              0.19575099647045135,
              0.7581520080566406,
              0.1962919980287552,
              0.7581959962844849,
              0.1962919980287552,
              0.7580969929695129,
              0.19700400531291962,
              0.7581959962844849,
              0.1962919980287552,
              0.7581400275230408,
              0.19700400531291962,
              0.7580969929695129,
              0.19700400531291962,
              0.7580969929695129,
              0.19700400531291962,
              0.7581400275230408,
              0.19700400531291962,
              0.7580440044403076,
              0.1978760063648224,
              0.7581400275230408,
              0.19700400531291962,
              0.7580869793891907,
              0.1978760063648224,
              0.7580440044403076,
              0.1978760063648224,
              0.7572140097618103,
              0.21938399970531464,
              0.7572579979896545,
              0.21938399970531464,
              0.7571460008621216,
              0.22147700190544128,
              0.7572579979896545,
              0.21938399970531464,
              0.7571899890899658,
              0.22147700190544128,
              0.7571460008621216,
              0.22147700190544128,
              0.7572829723358154,
              0.21731500327587128,
              0.7573270201683044,
              0.21731500327587128,
              0.7572140097618103,
              0.21938399970531464,
              0.7573270201683044,
              0.21731500327587128,
              0.7572579979896545,
              0.21938399970531464,
              0.7572140097618103,
              0.21938399970531464,
              0.7573530077934265,
              0.2152779996395111,
              0.7573959827423096,
              0.2152779996395111,
              0.7572829723358154,
              0.21731500327587128,
              0.7573959827423096,
              0.2152779996395111,
              0.7573270201683044,
              0.21731500327587128,
              0.7572829723358154,
              0.21731500327587128,
              0.7574219703674316,
              0.21328499913215637,
              0.7574660181999207,
              0.21328499913215637,
              0.7573530077934265,
              0.2152779996395111,
              0.7574660181999207,
              0.21328499913215637,
              0.7573959827423096,
              0.2152779996395111,
              0.7573530077934265,
              0.2152779996395111,
              0.7576289772987366,
              0.2076610028743744,
              0.7576720118522644,
              0.2076610028743744,
              0.7575610280036926,
              0.20946699380874634,
              0.7576720118522644,
              0.2076610028743744,
              0.7576040029525757,
              0.20946699380874634,
              0.7575610280036926,
              0.20946699380874634,
              0.7577599883079529,
              0.20430700480937958,
              0.7578030228614807,
              0.20430700480937958,
              0.7576950192451477,
              0.20593799650669098,
              0.7578030228614807,
              0.20430700480937958,
              0.7577390074729919,
              0.20593799650669098,
              0.7576950192451477,
              0.20593799650669098,
              0.7578229904174805,
              0.20277799665927887,
              0.7578660249710083,
              0.20277799665927887,
              0.7577599883079529,
              0.20430700480937958,
              0.7578660249710083,
              0.20277799665927887,
              0.7578030228614807,
              0.20430700480937958,
              0.7577599883079529,
              0.20430700480937958,
              0.7578830122947693,
              0.20136100053787231,
              0.7579259872436523,
              0.20136100053787231,
              0.7578229904174805,
              0.20277799665927887,
              0.7579259872436523,
              0.20136100053787231,
              0.7578660249710083,
              0.20277799665927887,
              0.7578229904174805,
              0.20277799665927887,
              0.7570149898529053,
              0.22569400072097778,
              0.7570589780807495,
              0.22569400072097778,
              0.7569659948348999,
              0.22777099907398224,
              0.7570589780807495,
              0.22569400072097778,
              0.7570090293884277,
              0.22777099907398224,
              0.7569659948348999,
              0.22777099907398224,
              0.7584030032157898,
              0.1877090036869049,
              0.7584890127182007,
              0.1877090036869049,
              0.7583600282669067,
              0.1877090036869049,
              0.7584890127182007,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7583600282669067,
              0.1877090036869049,
              0.7578290104866028,
              0.19382600486278534,
              0.7578719854354858,
              0.19382600486278534,
              0.757893979549408,
              0.19228500127792358,
              0.7578719854354858,
              0.19382600486278534,
              0.7579370141029358,
              0.19228500127792358,
              0.757893979549408,
              0.19228500127792358,
              0.7577040195465088,
              0.1974489986896515,
              0.7577469944953918,
              0.1974489986896515,
              0.7577679753303528,
              0.19555099308490753,
              0.7577469944953918,
              0.1974489986896515,
              0.7578110098838806,
              0.19555099308490753,
              0.7577679753303528,
              0.19555099308490753,
              0.7576429843902588,
              0.1995050013065338,
              0.7576860189437866,
              0.1995050013065338,
              0.7577040195465088,
              0.1974489986896515,
              0.7576860189437866,
              0.1995050013065338,
              0.7577469944953918,
              0.1974489986896515,
              0.7577040195465088,
              0.1974489986896515,
              0.7575899958610535,
              0.2017049938440323,
              0.7576329708099365,
              0.2017049938440323,
              0.7576429843902588,
              0.1995050013065338,
              0.7576329708099365,
              0.2017049938440323,
              0.7576860189437866,
              0.1995050013065338,
              0.7576429843902588,
              0.1995050013065338,
              0.7575349807739258,
              0.20403599739074707,
              0.7575780153274536,
              0.20403599739074707,
              0.7575899958610535,
              0.2017049938440323,
              0.7575780153274536,
              0.20403599739074707,
              0.7576329708099365,
              0.2017049938440323,
              0.7575899958610535,
              0.2017049938440323,
              0.7574149966239929,
              0.2090349942445755,
              0.757457971572876,
              0.2090349942445755,
              0.7574759721755981,
              0.20648400485515594,
              0.757457971572876,
              0.2090349942445755,
              0.757519006729126,
              0.20648400485515594,
              0.7574759721755981,
              0.20648400485515594,
              0.7570840120315552,
              0.22285600006580353,
              0.7571269869804382,
              0.22285600006580353,
              0.7571520209312439,
              0.21999500691890717,
              0.7571269869804382,
              0.22285600006580353,
              0.757194995880127,
              0.21999500691890717,
              0.7571520209312439,
              0.21999500691890717,
              0.7570149898529053,
              0.22573800384998322,
              0.7570580244064331,
              0.22573800384998322,
              0.7570840120315552,
              0.22285600006580353,
              0.7570580244064331,
              0.22573800384998322,
              0.7571269869804382,
              0.22285600006580353,
              0.7570840120315552,
              0.22285600006580353,
              0.756945013999939,
              0.22862599790096283,
              0.756987988948822,
              0.22862599790096283,
              0.7570149898529053,
              0.22573800384998322,
              0.756987988948822,
              0.22862599790096283,
              0.7570580244064331,
              0.22573800384998322,
              0.7570149898529053,
              0.22573800384998322,
              0.7583330273628235,
              0.1952189952135086,
              0.7583760023117065,
              0.1952189952135086,
              0.7582709789276123,
              0.1953900009393692,
              0.7583760023117065,
              0.1952189952135086,
              0.7583140134811401,
              0.1953900009393692,
              0.7582709789276123,
              0.1953900009393692,
              0.7582100033760071,
              0.19575099647045135,
              0.7582539916038513,
              0.19575099647045135,
              0.7581520080566406,
              0.1962919980287552,
              0.7582539916038513,
              0.19575099647045135,
              0.7581959962844849,
              0.1962919980287552,
              0.7581520080566406,
              0.1962919980287552,
              0.7580440044403076,
              0.1978760063648224,
              0.7580869793891907,
              0.1978760063648224,
              0.7579939961433411,
              0.1988999992609024,
              0.7580869793891907,
              0.1978760063648224,
              0.7580369710922241,
              0.1988999992609024,
              0.7579939961433411,
              0.1988999992609024,
              0.7570800185203552,
              0.22358399629592896,
              0.7571229934692383,
              0.22358399629592896,
              0.7570149898529053,
              0.22569400072097778,
              0.7571229934692383,
              0.22358399629592896,
              0.7570589780807495,
              0.22569400072097778,
              0.7570149898529053,
              0.22569400072097778,
              0.7571460008621216,
              0.22147700190544128,
              0.7571899890899658,
              0.22147700190544128,
              0.7570800185203552,
              0.22358399629592896,
              0.7571899890899658,
              0.22147700190544128,
              0.7571229934692383,
              0.22358399629592896,
              0.7570800185203552,
              0.22358399629592896,
              0.7574920058250427,
              0.21134500205516815,
              0.7575349807739258,
              0.21134500205516815,
              0.7574219703674316,
              0.21328499913215637,
              0.7575349807739258,
              0.21134500205516815,
              0.7574660181999207,
              0.21328499913215637,
              0.7574219703674316,
              0.21328499913215637,
              0.7575610280036926,
              0.20946699380874634,
              0.7576040029525757,
              0.20946699380874634,
              0.7574920058250427,
              0.21134500205516815,
              0.7576040029525757,
              0.20946699380874634,
              0.7575349807739258,
              0.21134500205516815,
              0.7574920058250427,
              0.21134500205516815,
              0.7576950192451477,
              0.20593799650669098,
              0.7577390074729919,
              0.20593799650669098,
              0.7576289772987366,
              0.2076610028743744,
              0.7577390074729919,
              0.20593799650669098,
              0.7576720118522644,
              0.2076610028743744,
              0.7576289772987366,
              0.2076610028743744,
              0.7579399943351746,
              0.2000650018453598,
              0.7579830288887024,
              0.2000650018453598,
              0.7578830122947693,
              0.20136100053787231,
              0.7579830288887024,
              0.2000650018453598,
              0.7579259872436523,
              0.20136100053787231,
              0.7578830122947693,
              0.20136100053787231,
              0.7579939961433411,
              0.1988999992609024,
              0.7580369710922241,
              0.1988999992609024,
              0.7579399943351746,
              0.2000650018453598,
              0.7580369710922241,
              0.1988999992609024,
              0.7579830288887024,
              0.2000650018453598,
              0.7579399943351746,
              0.2000650018453598,
              0.7515389919281006,
              0.45609501004219055,
              0.7515770196914673,
              0.4545019865036011,
              0.7514960169792175,
              0.45609501004219055,
              0.7515770196914673,
              0.4545019865036011,
              0.7515339851379395,
              0.4545019865036011,
              0.7514960169792175,
              0.45609501004219055,
              0.7623940110206604,
              0.19073599576950073,
              0.7584390044212341,
              0.19514800608158112,
              0.7623519897460938,
              0.19073499739170074,
              0.7584390044212341,
              0.19514800608158112,
              0.7583960294723511,
              0.19514800608158112,
              0.7623519897460938,
              0.19073499739170074,
              0.7584499716758728,
              0.188167005777359,
              0.758450984954834,
              0.18795299530029297,
              0.7583659887313843,
              0.1883080005645752,
              0.758450984954834,
              0.18795299530029297,
              0.7583670020103455,
              0.18809199333190918,
              0.7583659887313843,
              0.1883080005645752,
              0.7583659887313843,
              0.1883080005645752,
              0.7583670020103455,
              0.18809199333190918,
              0.7582849860191345,
              0.1887120008468628,
              0.7583670020103455,
              0.18809199333190918,
              0.7582849860191345,
              0.18849800527095795,
              0.7582849860191345,
              0.1887120008468628,
              0.7582849860191345,
              0.1887120008468628,
              0.7582849860191345,
              0.18849800527095795,
              0.7582070231437683,
              0.18936499953269958,
              0.7582849860191345,
              0.18849800527095795,
              0.7582060098648071,
              0.1891549974679947,
              0.7582070231437683,
              0.18936499953269958,
              0.7582070231437683,
              0.18936499953269958,
              0.7582060098648071,
              0.1891549974679947,
              0.7581319808959961,
              0.19025500118732452,
              0.7582060098648071,
              0.1891549974679947,
              0.7581300139427185,
              0.19005000591278076,
              0.7581319808959961,
              0.19025500118732452,
              0.7581319808959961,
              0.19025500118732452,
              0.7581300139427185,
              0.19005000591278076,
              0.7580599784851074,
              0.1913670003414154,
              0.7581300139427185,
              0.19005000591278076,
              0.7580580115318298,
              0.19116899371147156,
              0.7580599784851074,
              0.1913670003414154,
              0.7580599784851074,
              0.1913670003414154,
              0.7580580115318298,
              0.19116899371147156,
              0.7579920291900635,
              0.1926880031824112,
              0.7580580115318298,
              0.19116899371147156,
              0.7579889893531799,
              0.19249999523162842,
              0.7579920291900635,
              0.1926880031824112,
              0.7579270005226135,
              0.19420500099658966,
              0.7579240202903748,
              0.19402800500392914,
              0.7578669786453247,
              0.19590400159358978,
              0.7579240202903748,
              0.19402800500392914,
              0.7578639984130859,
              0.1957390010356903,
              0.7578669786453247,
              0.19590400159358978,
              0.7576320171356201,
              0.20425400137901306,
              0.7576289772987366,
              0.20415200293064117,
              0.7575730085372925,
              0.20666299760341644,
              0.7576289772987366,
              0.20415200293064117,
              0.7575709819793701,
              0.20657899975776672,
              0.7575730085372925,
              0.20666299760341644,
              0.7575110197067261,
              0.20917299389839172,
              0.7575089931488037,
              0.20910799503326416,
              0.7574480175971985,
              0.21177099645137787,
              0.7575089931488037,
              0.20910799503326416,
              0.7574459910392761,
              0.21172599494457245,
              0.7574480175971985,
              0.21177099645137787,
              0.7574480175971985,
              0.21177099645137787,
              0.7574459910392761,
              0.21172599494457245,
              0.7573819756507874,
              0.21444399654865265,
              0.7574459910392761,
              0.21172599494457245,
              0.7573800086975098,
              0.21441900730133057,
              0.7573819756507874,
              0.21444399654865265,
              0.7573819756507874,
              0.21444399654865265,
              0.7573800086975098,
              0.21441900730133057,
              0.757315993309021,
              0.21717800199985504,
              0.7573800086975098,
              0.21441900730133057,
              0.7573140263557434,
              0.2171739935874939,
              0.757315993309021,
              0.21717800199985504,
              0.757315993309021,
              0.21717800199985504,
              0.7573140263557434,
              0.2171739935874939,
              0.7572479844093323,
              0.21996000409126282,
              0.7573140263557434,
              0.2171739935874939,
              0.7572460174560547,
              0.219977006316185,
              0.7572479844093323,
              0.21996000409126282,
              0.7583010196685791,
              0.19529099762439728,
              0.7583019733428955,
              0.1955060064792633,
              0.7583630084991455,
              0.19492800533771515,
              0.7583019733428955,
              0.1955060064792633,
              0.7583630084991455,
              0.19514399766921997,
              0.7583630084991455,
              0.19492800533771515,
              0.7581859827041626,
              0.19656400382518768,
              0.7581869959831238,
              0.19676999747753143,
              0.7582420110702515,
              0.1958400011062622,
              0.7581869959831238,
              0.19676999747753143,
              0.7582430243492126,
              0.19605199992656708,
              0.7582420110702515,
              0.1958400011062622,
              0.7581310272216797,
              0.19745300710201263,
              0.7581340074539185,
              0.1976509988307953,
              0.7581859827041626,
              0.19656400382518768,
              0.7581340074539185,
              0.1976509988307953,
              0.7581869959831238,
              0.19676999747753143,
              0.7581859827041626,
              0.19656400382518768,
              0.7572349905967712,
              0.22155700623989105,
              0.7572370171546936,
              0.22152000665664673,
              0.75730299949646,
              0.21942000091075897,
              0.7572370171546936,
              0.22152000665664673,
              0.7573050260543823,
              0.2194029986858368,
              0.75730299949646,
              0.21942000091075897,
              0.75730299949646,
              0.21942000091075897,
              0.7573050260543823,
              0.2194029986858368,
              0.7573720216751099,
              0.2173060029745102,
              0.7573050260543823,
              0.2194029986858368,
              0.7573739886283875,
              0.21730999648571014,
              0.7573720216751099,
              0.2173060029745102,
              0.7573720216751099,
              0.2173060029745102,
              0.7573739886283875,
              0.21730999648571014,
              0.757440984249115,
              0.21522599458694458,
              0.7573739886283875,
              0.21730999648571014,
              0.7574430108070374,
              0.21525099873542786,
              0.757440984249115,
              0.21522599458694458,
              0.757440984249115,
              0.21522599458694458,
              0.7574430108070374,
              0.21525099873542786,
              0.7575110197067261,
              0.2131900042295456,
              0.7574430108070374,
              0.21525099873542786,
              0.7575129866600037,
              0.21323400735855103,
              0.7575110197067261,
              0.2131900042295456,
              0.7576479911804199,
              0.20928800106048584,
              0.7576509714126587,
              0.20937199890613556,
              0.7577160000801086,
              0.2074439972639084,
              0.7576509714126587,
              0.20937199890613556,
              0.757718026638031,
              0.20754599571228027,
              0.7577160000801086,
              0.2074439972639084,
              0.757781982421875,
              0.20568299293518066,
              0.7577840089797974,
              0.2058030068874359,
              0.7578459978103638,
              0.20401699841022491,
              0.7577840089797974,
              0.2058030068874359,
              0.7578489780426025,
              0.20415300130844116,
              0.7578459978103638,
              0.20401699841022491,
              0.7578459978103638,
              0.20401699841022491,
              0.7578489780426025,
              0.20415300130844116,
              0.7579079866409302,
              0.20245499908924103,
              0.7578489780426025,
              0.20415300130844116,
              0.7579110264778137,
              0.2026059925556183,
              0.7579079866409302,
              0.20245499908924103,
              0.7579079866409302,
              0.20245499908924103,
              0.7579110264778137,
              0.2026059925556183,
              0.757968008518219,
              0.20100800693035126,
              0.7579110264778137,
              0.2026059925556183,
              0.757972002029419,
              0.20117299258708954,
              0.757968008518219,
              0.20100800693035126,
              0.7516289949417114,
              0.4543749988079071,
              0.7516310214996338,
              0.45432499051094055,
              0.7516570091247559,
              0.4532020092010498,
              0.7516310214996338,
              0.45432499051094055,
              0.751658022403717,
              0.4531640112400055,
              0.7516570091247559,
              0.4532020092010498,
              0.7624419927597046,
              0.18816600739955902,
              0.7624419927597046,
              0.18795199692249298,
              0.7585350275039673,
              0.188167005777359,
              0.7624419927597046,
              0.18795199692249298,
              0.7585359811782837,
              0.18795299530029297,
              0.7585350275039673,
              0.188167005777359,
              0.7415549755096436,
              0.19073499739170074,
              0.7623519897460938,
              0.19073499739170074,
              0.7415549755096436,
              0.19514800608158112,
              0.7623519897460938,
              0.19073499739170074,
              0.7583960294723511,
              0.19514800608158112,
              0.7415549755096436,
              0.19514800608158112,
              0.7583600282669067,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7582100033760071,
              0.19575099647045135,
              0.7415549755096436,
              0.19574999809265137,
              0.7582709789276123,
              0.1953900009393692,
              0.7415549755096436,
              0.19574999809265137,
              0.7415549755096436,
              0.1953890025615692,
              0.7582709789276123,
              0.1953900009393692,
              0.7580969929695129,
              0.19700400531291962,
              0.7415549755096436,
              0.19700400531291962,
              0.7581520080566406,
              0.1962919980287552,
              0.7415549755096436,
              0.19700400531291962,
              0.7415549755096436,
              0.1962919980287552,
              0.7581520080566406,
              0.1962919980287552,
              0.7580440044403076,
              0.1978760063648224,
              0.7415549755096436,
              0.1978760063648224,
              0.7580969929695129,
              0.19700400531291962,
              0.7415549755096436,
              0.1978760063648224,
              0.7415549755096436,
              0.19700400531291962,
              0.7580969929695129,
              0.19700400531291962,
              0.7571460008621216,
              0.22147700190544128,
              0.7415549755096436,
              0.22147700190544128,
              0.7572140097618103,
              0.21938399970531464,
              0.7415549755096436,
              0.22147700190544128,
              0.7415549755096436,
              0.21938399970531464,
              0.7572140097618103,
              0.21938399970531464,
              0.7572140097618103,
              0.21938399970531464,
              0.7415549755096436,
              0.21938399970531464,
              0.7572829723358154,
              0.21731500327587128,
              0.7415549755096436,
              0.21938399970531464,
              0.7415549755096436,
              0.21731500327587128,
              0.7572829723358154,
              0.21731500327587128,
              0.7572829723358154,
              0.21731500327587128,
              0.7415549755096436,
              0.21731500327587128,
              0.7573530077934265,
              0.2152779996395111,
              0.7415549755096436,
              0.21731500327587128,
              0.7415549755096436,
              0.2152779996395111,
              0.7573530077934265,
              0.2152779996395111,
              0.7573530077934265,
              0.2152779996395111,
              0.7415549755096436,
              0.2152779996395111,
              0.7574219703674316,
              0.21328499913215637,
              0.7415549755096436,
              0.2152779996395111,
              0.7415549755096436,
              0.21328499913215637,
              0.7574219703674316,
              0.21328499913215637,
              0.7575610280036926,
              0.20946699380874634,
              0.7415549755096436,
              0.20946699380874634,
              0.7576289772987366,
              0.2076610028743744,
              0.7415549755096436,
              0.20946699380874634,
              0.7415549755096436,
              0.2076610028743744,
              0.7576289772987366,
              0.2076610028743744,
              0.7576950192451477,
              0.20593799650669098,
              0.7415549755096436,
              0.20593799650669098,
              0.7577599883079529,
              0.20430700480937958,
              0.7415549755096436,
              0.20593799650669098,
              0.7415549755096436,
              0.20430700480937958,
              0.7577599883079529,
              0.20430700480937958,
              0.7577599883079529,
              0.20430700480937958,
              0.7415549755096436,
              0.20430700480937958,
              0.7578229904174805,
              0.20277799665927887,
              0.7415549755096436,
              0.20430700480937958,
              0.7415549755096436,
              0.20277799665927887,
              0.7578229904174805,
              0.20277799665927887,
              0.7578229904174805,
              0.20277799665927887,
              0.7415549755096436,
              0.20277799665927887,
              0.7578830122947693,
              0.20136100053787231,
              0.7415549755096436,
              0.20277799665927887,
              0.7415549755096436,
              0.20136100053787231,
              0.7578830122947693,
              0.20136100053787231,
              0.7582709789276123,
              0.1953900009393692,
              0.7415549755096436,
              0.1953890025615692,
              0.7583330273628235,
              0.1952189952135086,
              0.7415549755096436,
              0.1953890025615692,
              0.7415549755096436,
              0.1952189952135086,
              0.7583330273628235,
              0.1952189952135086,
              0.7581520080566406,
              0.1962919980287552,
              0.7415549755096436,
              0.1962919980287552,
              0.7582100033760071,
              0.19575099647045135,
              0.7415549755096436,
              0.1962919980287552,
              0.7415549755096436,
              0.19574999809265137,
              0.7582100033760071,
              0.19575099647045135,
              0.7579939961433411,
              0.1988999992609024,
              0.7415549755096436,
              0.1988999992609024,
              0.7580440044403076,
              0.1978760063648224,
              0.7415549755096436,
              0.1988999992609024,
              0.7415549755096436,
              0.1978760063648224,
              0.7580440044403076,
              0.1978760063648224,
              0.7570149898529053,
              0.22569400072097778,
              0.7415549755096436,
              0.22569400072097778,
              0.7570800185203552,
              0.22358399629592896,
              0.7415549755096436,
              0.22569400072097778,
              0.7415549755096436,
              0.22358399629592896,
              0.7570800185203552,
              0.22358399629592896,
              0.7570800185203552,
              0.22358399629592896,
              0.7415549755096436,
              0.22358399629592896,
              0.7571460008621216,
              0.22147700190544128,
              0.7415549755096436,
              0.22358399629592896,
              0.7415549755096436,
              0.22147700190544128,
              0.7571460008621216,
              0.22147700190544128,
              0.7574219703674316,
              0.21328499913215637,
              0.7415549755096436,
              0.21328499913215637,
              0.7574920058250427,
              0.21134500205516815,
              0.7415549755096436,
              0.21328499913215637,
              0.7415549755096436,
              0.21134500205516815,
              0.7574920058250427,
              0.21134500205516815,
              0.7574920058250427,
              0.21134500205516815,
              0.7415549755096436,
              0.21134500205516815,
              0.7575610280036926,
              0.20946699380874634,
              0.7415549755096436,
              0.21134500205516815,
              0.7415549755096436,
              0.20946699380874634,
              0.7575610280036926,
              0.20946699380874634,
              0.7576289772987366,
              0.2076610028743744,
              0.7415549755096436,
              0.2076610028743744,
              0.7576950192451477,
              0.20593799650669098,
              0.7415549755096436,
              0.2076610028743744,
              0.7415549755096436,
              0.20593799650669098,
              0.7576950192451477,
              0.20593799650669098,
              0.7578830122947693,
              0.20136100053787231,
              0.7415549755096436,
              0.20136100053787231,
              0.7579399943351746,
              0.2000650018453598,
              0.7415549755096436,
              0.20136100053787231,
              0.7415549755096436,
              0.2000650018453598,
              0.7579399943351746,
              0.2000650018453598,
              0.7579399943351746,
              0.2000650018453598,
              0.7415549755096436,
              0.2000650018453598,
              0.7579939961433411,
              0.1988999992609024,
              0.7415549755096436,
              0.2000650018453598,
              0.7415549755096436,
              0.1988999992609024,
              0.7579939961433411,
              0.1988999992609024,
              0.7570149898529053,
              0.22569400072097778,
              0.7569659948348999,
              0.22777099907398224,
              0.7415549755096436,
              0.22569400072097778,
              0.7569659948348999,
              0.22777099907398224,
              0.7415549755096436,
              0.22777099907398224,
              0.7415549755096436,
              0.22569400072097778,
              0.7582740187644958,
              0.18784800171852112,
              0.7583600282669067,
              0.1877090036869049,
              0.7415549755096436,
              0.18784800171852112,
              0.7583600282669067,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.18784800171852112,
              0.7582740187644958,
              0.18784800171852112,
              0.7415549755096436,
              0.18784800171852112,
              0.7581920027732849,
              0.1882539987564087,
              0.7415549755096436,
              0.18784800171852112,
              0.7415549755096436,
              0.1882539987564087,
              0.7581920027732849,
              0.1882539987564087,
              0.7581920027732849,
              0.1882539987564087,
              0.7415549755096436,
              0.1882539987564087,
              0.7581120133399963,
              0.18891499936580658,
              0.7415549755096436,
              0.1882539987564087,
              0.7415549755096436,
              0.18891499936580658,
              0.7581120133399963,
              0.18891499936580658,
              0.7581120133399963,
              0.18891499936580658,
              0.7415549755096436,
              0.18891499936580658,
              0.7580360174179077,
              0.18981599807739258,
              0.7415549755096436,
              0.18891499936580658,
              0.7415549755096436,
              0.18981599807739258,
              0.7580360174179077,
              0.18981599807739258,
              0.7580360174179077,
              0.18981599807739258,
              0.7415549755096436,
              0.18981599807739258,
              0.7579630017280579,
              0.19094400107860565,
              0.7415549755096436,
              0.18981599807739258,
              0.7415549755096436,
              0.19094400107860565,
              0.7579630017280579,
              0.19094400107860565,
              0.7579630017280579,
              0.19094400107860565,
              0.7415549755096436,
              0.19094400107860565,
              0.757893979549408,
              0.19228500127792358,
              0.7415549755096436,
              0.19094400107860565,
              0.7415549755096436,
              0.19228500127792358,
              0.757893979549408,
              0.19228500127792358,
              0.7578290104866028,
              0.19382600486278534,
              0.7415549755096436,
              0.19382600486278534,
              0.7577679753303528,
              0.19555099308490753,
              0.7415549755096436,
              0.19382600486278534,
              0.7415549755096436,
              0.19555099308490753,
              0.7577679753303528,
              0.19555099308490753,
              0.7575349807739258,
              0.20403599739074707,
              0.7415549755096436,
              0.20403599739074707,
              0.7574759721755981,
              0.20648400485515594,
              0.7415549755096436,
              0.20403599739074707,
              0.7415549755096436,
              0.20648400485515594,
              0.7574759721755981,
              0.20648400485515594,
              0.7574149966239929,
              0.2090349942445755,
              0.7415549755096436,
              0.2090349942445755,
              0.7573509812355042,
              0.2116750031709671,
              0.7415549755096436,
              0.2090349942445755,
              0.7415549755096436,
              0.2116750031709671,
              0.7573509812355042,
              0.2116750031709671,
              0.7573509812355042,
              0.2116750031709671,
              0.7415549755096436,
              0.2116750031709671,
              0.757286012172699,
              0.21439099311828613,
              0.7415549755096436,
              0.2116750031709671,
              0.7415549755096436,
              0.21439099311828613,
              0.757286012172699,
              0.21439099311828613,
              0.757286012172699,
              0.21439099311828613,
              0.7415549755096436,
              0.21439099311828613,
              0.7572199702262878,
              0.21716900169849396,
              0.7415549755096436,
              0.21439099311828613,
              0.7415549755096436,
              0.21716900169849396,
              0.7572199702262878,
              0.21716900169849396,
              0.7572199702262878,
              0.21716900169849396,
              0.7415549755096436,
              0.21716900169849396,
              0.7571520209312439,
              0.21999500691890717,
              0.7415549755096436,
              0.21716900169849396,
              0.7415549755096436,
              0.21999500691890717,
              0.7571520209312439,
              0.21999500691890717,
              0.757893979549408,
              0.19228500127792358,
              0.7415549755096436,
              0.19228500127792358,
              0.7578290104866028,
              0.19382600486278534,
              0.7415549755096436,
              0.19228500127792358,
              0.7415549755096436,
              0.19382600486278534,
              0.7578290104866028,
              0.19382600486278534,
              0.7577679753303528,
              0.19555099308490753,
              0.7415549755096436,
              0.19555099308490753,
              0.7577040195465088,
              0.1974489986896515,
              0.7415549755096436,
              0.19555099308490753,
              0.7415549755096436,
              0.1974489986896515,
              0.7577040195465088,
              0.1974489986896515,
              0.7577040195465088,
              0.1974489986896515,
              0.7415549755096436,
              0.1974489986896515,
              0.7576429843902588,
              0.1995050013065338,
              0.7415549755096436,
              0.1974489986896515,
              0.7415549755096436,
              0.1995050013065338,
              0.7576429843902588,
              0.1995050013065338,
              0.7576429843902588,
              0.1995050013065338,
              0.7415549755096436,
              0.1995050013065338,
              0.7575899958610535,
              0.2017049938440323,
              0.7415549755096436,
              0.1995050013065338,
              0.7415549755096436,
              0.2017049938440323,
              0.7575899958610535,
              0.2017049938440323,
              0.7575899958610535,
              0.2017049938440323,
              0.7415549755096436,
              0.2017049938440323,
              0.7575349807739258,
              0.20403599739074707,
              0.7415549755096436,
              0.2017049938440323,
              0.7415549755096436,
              0.20403599739074707,
              0.7575349807739258,
              0.20403599739074707,
              0.7574759721755981,
              0.20648400485515594,
              0.7415549755096436,
              0.20648400485515594,
              0.7574149966239929,
              0.2090349942445755,
              0.7415549755096436,
              0.20648400485515594,
              0.7415549755096436,
              0.2090349942445755,
              0.7574149966239929,
              0.2090349942445755,
              0.7571520209312439,
              0.21999500691890717,
              0.7415549755096436,
              0.21999500691890717,
              0.7570840120315552,
              0.22285600006580353,
              0.7415549755096436,
              0.21999500691890717,
              0.7415549755096436,
              0.22285600006580353,
              0.7570840120315552,
              0.22285600006580353,
              0.7570840120315552,
              0.22285600006580353,
              0.7415549755096436,
              0.22285600006580353,
              0.7570149898529053,
              0.22573800384998322,
              0.7415549755096436,
              0.22285600006580353,
              0.7415549755096436,
              0.22573800384998322,
              0.7570149898529053,
              0.22573800384998322,
              0.7570149898529053,
              0.22573800384998322,
              0.7415549755096436,
              0.22573800384998322,
              0.756945013999939,
              0.22862599790096283,
              0.7415549755096436,
              0.22573800384998322,
              0.7415549755096436,
              0.22862599790096283,
              0.756945013999939,
              0.22862599790096283,
              0.7515339851379395,
              0.4545019865036011,
              0.7415549755096436,
              0.4545019865036011,
              0.7514960169792175,
              0.45609501004219055,
              0.7415549755096436,
              0.4545019865036011,
              0.7415549755096436,
              0.45609501004219055,
              0.7514960169792175,
              0.45609501004219055,
              0.7624300122261047,
              0.1906760036945343,
              0.7624419927597046,
              0.19049400091171265,
              0.7584760189056396,
              0.1950870007276535,
              0.7624419927597046,
              0.19049400091171265,
              0.7584890127182007,
              0.19490200281143188,
              0.7584760189056396,
              0.1950870007276535,
              0.7624300122261047,
              0.18776999413967133,
              0.7623940110206604,
              0.1877090036869049,
              0.758525013923645,
              0.18776999413967133,
              0.7623940110206604,
              0.1877090036869049,
              0.7584890127182007,
              0.1877090036869049,
              0.758525013923645,
              0.18776999413967133,
              0.7623940110206604,
              0.19073599576950073,
              0.7624300122261047,
              0.1906760036945343,
              0.7584390044212341,
              0.19514800608158112,
              0.7624300122261047,
              0.1906760036945343,
              0.7584760189056396,
              0.1950870007276535,
              0.7584390044212341,
              0.19514800608158112,
              0.7624419927597046,
              0.18795199692249298,
              0.7624300122261047,
              0.18776999413967133,
              0.7585359811782837,
              0.18795299530029297,
              0.7624300122261047,
              0.18776999413967133,
              0.758525013923645,
              0.18776999413967133,
              0.7585359811782837,
              0.18795299530029297,
              0.7584499716758728,
              0.188167005777359,
              0.7584270238876343,
              0.19475799798965454,
              0.7585350275039673,
              0.188167005777359,
              0.7584270238876343,
              0.19475799798965454,
              0.7584909796714783,
              0.19468599557876587,
              0.7585350275039673,
              0.188167005777359,
              0.7624419927597046,
              0.19049400091171265,
              0.7624419927597046,
              0.1902800053358078,
              0.7584890127182007,
              0.19490200281143188,
              0.7624419927597046,
              0.1902800053358078,
              0.7584909796714783,
              0.19468599557876587,
              0.7584890127182007,
              0.19490200281143188,
              0.7584890127182007,
              0.1877090036869049,
              0.7623940110206604,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7623940110206604,
              0.1877090036869049,
              0.7623519897460938,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7583760023117065,
              0.1952189952135086,
              0.7583330273628235,
              0.1952189952135086,
              0.7584390044212341,
              0.19514800608158112,
              0.7583330273628235,
              0.1952189952135086,
              0.7583960294723511,
              0.19514800608158112,
              0.7584390044212341,
              0.19514800608158112,
              0.758450984954834,
              0.18795299530029297,
              0.7584499716758728,
              0.188167005777359,
              0.7585359811782837,
              0.18795299530029297,
              0.7584499716758728,
              0.188167005777359,
              0.7585350275039673,
              0.188167005777359,
              0.7585359811782837,
              0.18795299530029297,
              0.7583330273628235,
              0.1952189952135086,
              0.7415549755096436,
              0.1952189952135086,
              0.7583960294723511,
              0.19514800608158112,
              0.7415549755096436,
              0.1952189952135086,
              0.7415549755096436,
              0.19514800608158112,
              0.7583960294723511,
              0.19514800608158112,
              0.7623519897460938,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7584459781646729,
              0.1877090036869049,
              0.7569199800491333,
              0.23147399723529816,
              0.756987988948822,
              0.22862599790096283,
              0.7568770051002502,
              0.23147399723529816,
              0.756987988948822,
              0.22862599790096283,
              0.756945013999939,
              0.22862599790096283,
              0.7568770051002502,
              0.23147399723529816,
              0.7570549845695496,
              0.22788499295711517,
              0.7571049928665161,
              0.22578300535678864,
              0.757053017616272,
              0.2279839962720871,
              0.7571049928665161,
              0.22578300535678864,
              0.7571030259132385,
              0.22586199641227722,
              0.757053017616272,
              0.2279839962720871,
              0.756945013999939,
              0.22862599790096283,
              0.7415549755096436,
              0.22862599790096283,
              0.7568770051002502,
              0.23147399723529816,
              0.7415549755096436,
              0.22862599790096283,
              0.7415549755096436,
              0.23147399723529816,
              0.7568770051002502,
              0.23147399723529816,
              0.7515919804573059,
              0.45541200041770935,
              0.7516030073165894,
              0.4554649889469147,
              0.7515659928321838,
              0.4565120041370392,
              0.7516030073165894,
              0.4554649889469147,
              0.7515770196914673,
              0.45657598972320557,
              0.7515659928321838,
              0.4565120041370392,
              0.751446008682251,
              0.46152499318122864,
              0.7514550089836121,
              0.4616360068321228,
              0.75142502784729,
              0.46241798996925354,
              0.7514550089836121,
              0.4616360068321228,
              0.7514340281486511,
              0.4625380039215088,
              0.75142502784729,
              0.46241798996925354,
              0.75142502784729,
              0.46241798996925354,
              0.7514340281486511,
              0.4625380039215088,
              0.7514039874076843,
              0.46327200531959534,
              0.7514340281486511,
              0.4625380039215088,
              0.7514129877090454,
              0.4633989930152893,
              0.7514039874076843,
              0.46327200531959534,
              0.7514039874076843,
              0.46327200531959534,
              0.7514129877090454,
              0.4633989930152893,
              0.751384973526001,
              0.4640839993953705,
              0.7514129877090454,
              0.4633989930152893,
              0.7513939738273621,
              0.46421900391578674,
              0.751384973526001,
              0.4640839993953705,
              0.7512789964675903,
              0.46848100423812866,
              0.7512869834899902,
              0.46865400671958923,
              0.7512689828872681,
              0.4689069986343384,
              0.7512869834899902,
              0.46865400671958923,
              0.7512770295143127,
              0.4690830111503601,
              0.7512689828872681,
              0.4689069986343384,
              0.7512689828872681,
              0.4689069986343384,
              0.7512770295143127,
              0.4690830111503601,
              0.751259982585907,
              0.4692780077457428,
              0.7512770295143127,
              0.4690830111503601,
              0.7512680292129517,
              0.4694559872150421,
              0.751259982585907,
              0.4692780077457428,
              0.7515389919281006,
              0.45765000581741333,
              0.7515519857406616,
              0.4575969874858856,
              0.7515760064125061,
              0.45608100295066833,
              0.7515519857406616,
              0.4575969874858856,
              0.7515900135040283,
              0.4560379981994629,
              0.7515760064125061,
              0.45608100295066833,
              0.7513989806175232,
              0.4634990096092224,
              0.7514129877090454,
              0.4634059965610504,
              0.7514320015907288,
              0.46210500597953796,
              0.7514129877090454,
              0.4634059965610504,
              0.751446008682251,
              0.46202200651168823,
              0.7514320015907288,
              0.46210500597953796,
              0.7510899901390076,
              0.47639000415802,
              0.7511060237884521,
              0.4762139916419983,
              0.7511039972305298,
              0.47579801082611084,
              0.7511060237884521,
              0.4762139916419983,
              0.7511199712753296,
              0.47562599182128906,
              0.7511039972305298,
              0.47579801082611084,
              0.7510780096054077,
              0.4769040048122406,
              0.7510939836502075,
              0.4767259955406189,
              0.7510899901390076,
              0.47639000415802,
              0.7510939836502075,
              0.4767259955406189,
              0.7511060237884521,
              0.4762139916419983,
              0.7510899901390076,
              0.47639000415802,
              0.7510589957237244,
              0.4776949882507324,
              0.751075029373169,
              0.47751399874687195,
              0.7510669827461243,
              0.4773400127887726,
              0.751075029373169,
              0.47751399874687195,
              0.75108402967453,
              0.4771600067615509,
              0.7510669827461243,
              0.4773400127887726,
              0.7516189813613892,
              0.4542819857597351,
              0.7516310214996338,
              0.45432499051094055,
              0.7515919804573059,
              0.45541200041770935,
              0.7516310214996338,
              0.45432499051094055,
              0.7516030073165894,
              0.4554649889469147,
              0.7515919804573059,
              0.45541200041770935,
              0.7515659928321838,
              0.4565120041370392,
              0.7515770196914673,
              0.45657598972320557,
              0.7515400052070618,
              0.4575830101966858,
              0.7515770196914673,
              0.45657598972320557,
              0.7515509724617004,
              0.45765599608421326,
              0.7515400052070618,
              0.4575830101966858,
              0.7513489723205566,
              0.46557798981666565,
              0.7513570189476013,
              0.4657270014286041,
              0.7513329982757568,
              0.4662570059299469,
              0.7513570189476013,
              0.4657270014286041,
              0.7513409852981567,
              0.4664109945297241,
              0.7513329982757568,
              0.4662570059299469,
              0.751317024230957,
              0.4668880105018616,
              0.7513260245323181,
              0.4670479893684387,
              0.7513039708137512,
              0.46747100353240967,
              0.7513260245323181,
              0.4670479893684387,
              0.7513120174407959,
              0.46763500571250916,
              0.7513039708137512,
              0.46747100353240967,
              0.7511910200119019,
              0.4721609950065613,
              0.7512410283088684,
              0.47007301449775696,
              0.7511990070343018,
              0.472339004278183,
              0.7512410283088684,
              0.47007301449775696,
              0.7512490153312683,
              0.4702570140361786,
              0.7511990070343018,
              0.472339004278183,
              0.7513660192489624,
              0.4648439884185791,
              0.751380980014801,
              0.4647420048713684,
              0.7513989806175232,
              0.4634990096092224,
              0.751380980014801,
              0.4647420048713684,
              0.7514129877090454,
              0.4634059965610504,
              0.7513989806175232,
              0.4634990096092224,
              0.7513059973716736,
              0.46737900376319885,
              0.751321017742157,
              0.4672600030899048,
              0.7513350248336792,
              0.46613800525665283,
              0.751321017742157,
              0.4672600030899048,
              0.7513499855995178,
              0.46602699160575867,
              0.7513350248336792,
              0.46613800525665283,
              0.7512770295143127,
              0.4685640037059784,
              0.7512919902801514,
              0.46843698620796204,
              0.7513059973716736,
              0.46737900376319885,
              0.7512919902801514,
              0.46843698620796204,
              0.751321017742157,
              0.4672600030899048,
              0.7513059973716736,
              0.46737900376319885,
              0.7511780261993408,
              0.4727090001106262,
              0.7511940002441406,
              0.472555011510849,
              0.7512009739875793,
              0.47176700830459595,
              0.7511940002441406,
              0.472555011510849,
              0.7512159943580627,
              0.47161799669265747,
              0.7512009739875793,
              0.47176700830459595,
              0.7511379718780518,
              0.4743939936161041,
              0.7511540055274963,
              0.47422999143600464,
              0.7511569857597351,
              0.47358599305152893,
              0.7511540055274963,
              0.47422999143600464,
              0.7511730194091797,
              0.4734260141849518,
              0.7511569857597351,
              0.47358599305152893,
              0.7591570019721985,
              0.18776999413967133,
              0.7591609954833984,
              0.18795199692249298,
              0.7587599754333496,
              0.18776999413967133,
              0.7591609954833984,
              0.18795199692249298,
              0.7587640285491943,
              0.18795199692249298,
              0.7587599754333496,
              0.18776999413967133,
              0.7595379948616028,
              0.18776999413967133,
              0.7595430016517639,
              0.18795199692249298,
              0.7591570019721985,
              0.18776999413967133,
              0.7595430016517639,
              0.18795199692249298,
              0.7591609954833984,
              0.18795199692249298,
              0.7591570019721985,
              0.18776999413967133,
              0.7602499723434448,
              0.18776999413967133,
              0.7602570056915283,
              0.18795199692249298,
              0.7599030137062073,
              0.18776999413967133,
              0.7602570056915283,
              0.18795199692249298,
              0.7599089741706848,
              0.18795199692249298,
              0.7599030137062073,
              0.18776999413967133,
              0.7625340223312378,
              0.18776999413967133,
              0.7625460028648376,
              0.18795199692249298,
              0.7625409960746765,
              0.18776999413967133,
              0.7625460028648376,
              0.18795199692249298,
              0.7625529766082764,
              0.18795199692249298,
              0.7625409960746765,
              0.18776999413967133,
              0.7583510279655457,
              0.18776999413967133,
              0.7583529949188232,
              0.18795199692249298,
              0.7579280138015747,
              0.18776999413967133,
              0.7583529949188232,
              0.18795199692249298,
              0.7579290270805359,
              0.18795199692249298,
              0.7579280138015747,
              0.18776999413967133,
              0.761434018611908,
              0.18776999413967133,
              0.7614439725875854,
              0.18795199692249298,
              0.7611709833145142,
              0.18776999413967133,
              0.7614439725875854,
              0.18795199692249298,
              0.7611799836158752,
              0.18795199692249298,
              0.7611709833145142,
              0.18776999413967133,
              0.7620700001716614,
              0.18776999413967133,
              0.7620810270309448,
              0.18795199692249298,
              0.7618849873542786,
              0.18776999413967133,
              0.7620810270309448,
              0.18795199692249298,
              0.7618950009346008,
              0.18795199692249298,
              0.7618849873542786,
              0.18776999413967133,
              0.7622269988059998,
              0.18776999413967133,
              0.7622380256652832,
              0.18795199692249298,
              0.7620700001716614,
              0.18776999413967133,
              0.7622380256652832,
              0.18795199692249298,
              0.7620810270309448,
              0.18795199692249298,
              0.7620700001716614,
              0.18776999413967133,
              0.7625409960746765,
              0.18776999413967133,
              0.7625529766082764,
              0.18795199692249298,
              0.7625120282173157,
              0.18776999413967133,
              0.7625529766082764,
              0.18795199692249298,
              0.7625240087509155,
              0.18795199692249298,
              0.7625120282173157,
              0.18776999413967133,
              0.7624300122261047,
              0.18776999413967133,
              0.7624419927597046,
              0.18795199692249298,
              0.76249098777771,
              0.18776999413967133,
              0.7624419927597046,
              0.18795199692249298,
              0.7625030279159546,
              0.18795199692249298,
              0.76249098777771,
              0.18776999413967133,
              0.7574949860572815,
              0.18776999413967133,
              0.7574949860572815,
              0.18795199692249298,
              0.7570610046386719,
              0.18776999413967133,
              0.7574949860572815,
              0.18795199692249298,
              0.7570610046386719,
              0.18795199692249298,
              0.7570610046386719,
              0.18776999413967133,
              0.7574949860572815,
              0.18969100713729858,
              0.7574949860572815,
              0.18950699269771576,
              0.7579280138015747,
              0.18969400227069855,
              0.7574949860572815,
              0.18950699269771576,
              0.7579290270805359,
              0.18951000273227692,
              0.7579280138015747,
              0.18969400227069855,
              0.7579280138015747,
              0.18969400227069855,
              0.7579290270805359,
              0.18951000273227692,
              0.7583510279655457,
              0.18970300257205963,
              0.7579290270805359,
              0.18951000273227692,
              0.7583529949188232,
              0.189519003033638,
              0.7583510279655457,
              0.18970300257205963,
              0.7583510279655457,
              0.18970300257205963,
              0.7583529949188232,
              0.189519003033638,
              0.7587599754333496,
              0.18971699476242065,
              0.7583529949188232,
              0.189519003033638,
              0.7587640285491943,
              0.18953299522399902,
              0.7587599754333496,
              0.18971699476242065,
              0.7595379948616028,
              0.1897599995136261,
              0.7595430016517639,
              0.18957599997520447,
              0.7599030137062073,
              0.18978899717330933,
              0.7595430016517639,
              0.18957599997520447,
              0.7599089741706848,
              0.1896049976348877,
              0.7599030137062073,
              0.18978899717330933,
              0.7599030137062073,
              0.18978899717330933,
              0.7599089741706848,
              0.1896049976348877,
              0.7602499723434448,
              0.1898220032453537,
              0.7599089741706848,
              0.1896049976348877,
              0.7602570056915283,
              0.18963800370693207,
              0.7602499723434448,
              0.1898220032453537,
              0.7602499723434448,
              0.1898220032453537,
              0.7602570056915283,
              0.18963800370693207,
              0.7605779767036438,
              0.18985900282859802,
              0.7602570056915283,
              0.18963800370693207,
              0.7605850100517273,
              0.1896750032901764,
              0.7605779767036438,
              0.18985900282859802,
              0.760886013507843,
              0.1898999959230423,
              0.7608940005302429,
              0.18971599638462067,
              0.7611709833145142,
              0.18994499742984772,
              0.7608940005302429,
              0.18971599638462067,
              0.7611799836158752,
              0.1897609978914261,
              0.7611709833145142,
              0.18994499742984772,
              0.761434018611908,
              0.1899929940700531,
              0.7614439725875854,
              0.18980999290943146,
              0.7616720199584961,
              0.19004400074481964,
              0.7614439725875854,
              0.18980999290943146,
              0.7616829872131348,
              0.189860999584198,
              0.7616720199584961,
              0.19004400074481964,
              0.7622269988059998,
              0.19021500647068024,
              0.7622380256652832,
              0.1900320053100586,
              0.7623530030250549,
              0.19027699530124664,
              0.7622380256652832,
              0.1900320053100586,
              0.7623649835586548,
              0.190093994140625,
              0.7623530030250549,
              0.19027699530124664,
              0.7624490261077881,
              0.19033999741077423,
              0.7624610066413879,
              0.1901569962501526,
              0.7625120282173157,
              0.1904049962759018,
              0.7624610066413879,
              0.1901569962501526,
              0.7625240087509155,
              0.19022299349308014,
              0.7625120282173157,
              0.1904049962759018,
              0.7625409960746765,
              0.19047200679779053,
              0.7625529766082764,
              0.19029000401496887,
              0.7625340223312378,
              0.19054000079631805,
              0.7625529766082764,
              0.19029000401496887,
              0.7625460028648376,
              0.1903569996356964,
              0.7625340223312378,
              0.19054000079631805,
              0.7625340223312378,
              0.19054000079631805,
              0.7625460028648376,
              0.1903569996356964,
              0.76249098777771,
              0.19060799479484558,
              0.7625460028648376,
              0.1903569996356964,
              0.7625030279159546,
              0.19042600691318512,
              0.76249098777771,
              0.19060799479484558,
              0.7515159845352173,
              0.45862001180648804,
              0.751479983329773,
              0.4585919976234436,
              0.7515400052070618,
              0.4575830101966858,
              0.751479983329773,
              0.4585919976234436,
              0.7515040040016174,
              0.4575580060482025,
              0.7515400052070618,
              0.4575830101966858,
              0.7514910101890564,
              0.4596239924430847,
              0.7514560222625732,
              0.4595929980278015,
              0.7515159845352173,
              0.45862001180648804,
              0.7514560222625732,
              0.4595929980278015,
              0.751479983329773,
              0.4585919976234436,
              0.7515159845352173,
              0.45862001180648804,
              0.751446008682251,
              0.46152499318122864,
              0.7514100074768066,
              0.4614880084991455,
              0.7514680027961731,
              0.46059298515319824,
              0.7514100074768066,
              0.4614880084991455,
              0.7514320015907288,
              0.46055901050567627,
              0.7514680027961731,
              0.46059298515319824,
              0.7513489723205566,
              0.46557798981666565,
              0.7513129711151123,
              0.4655289947986603,
              0.7513660192489624,
              0.46485400199890137,
              0.7513129711151123,
              0.4655289947986603,
              0.7513309717178345,
              0.4648059904575348,
              0.7513660192489624,
              0.46485400199890137,
              0.751317024230957,
              0.4668880105018616,
              0.7512819766998291,
              0.46683499217033386,
              0.7513329982757568,
              0.4662570059299469,
              0.7512819766998291,
              0.46683499217033386,
              0.7512969970703125,
              0.46620601415634155,
              0.7513329982757568,
              0.4662570059299469,
              0.7515019774436951,
              0.45917800068855286,
              0.7514650225639343,
              0.459199994802475,
              0.7514669895172119,
              0.4606640040874481,
              0.7514650225639343,
              0.459199994802475,
              0.75142902135849,
              0.4606890082359314,
              0.7514669895172119,
              0.4606640040874481,
              0.7513660192489624,
              0.4648439884185791,
              0.7513290047645569,
              0.46487900614738464,
              0.7513350248336792,
              0.46613800525665283,
              0.7513290047645569,
              0.46487900614738464,
              0.7512980103492737,
              0.46617498993873596,
              0.7513350248336792,
              0.46613800525665283,
              0.7512770295143127,
              0.4685640037059784,
              0.7512400150299072,
              0.46860700845718384,
              0.7512500286102295,
              0.4696919918060303,
              0.7512400150299072,
              0.46860700845718384,
              0.751213014125824,
              0.4697369933128357,
              0.7512500286102295,
              0.4696919918060303,
              0.7510070204734802,
              0.4798569977283478,
              0.7510510087013245,
              0.4780080020427704,
              0.7509689927101135,
              0.4799160063266754,
              0.7510510087013245,
              0.4780080020427704,
              0.7510129809379578,
              0.478069007396698,
              0.7509689927101135,
              0.4799160063266754,
              0.7514680027961731,
              0.46059298515319824,
              0.7514320015907288,
              0.46055901050567627,
              0.7514910101890564,
              0.4596239924430847,
              0.7514320015907288,
              0.46055901050567627,
              0.7514560222625732,
              0.4595929980278015,
              0.7514910101890564,
              0.4596239924430847,
              0.7513660192489624,
              0.46485400199890137,
              0.7513309717178345,
              0.4648059904575348,
              0.751384973526001,
              0.4640839993953705,
              0.7513309717178345,
              0.4648059904575348,
              0.7513489723205566,
              0.46404001116752625,
              0.751384973526001,
              0.4640839993953705,
              0.7512909770011902,
              0.46800199151039124,
              0.751255989074707,
              0.46794599294662476,
              0.7513039708137512,
              0.46747100353240967,
              0.751255989074707,
              0.46794599294662476,
              0.7512680292129517,
              0.467415988445282,
              0.7513039708137512,
              0.46747100353240967,
              0.7512789964675903,
              0.46848100423812866,
              0.7512440085411072,
              0.468423992395401,
              0.7512909770011902,
              0.46800199151039124,
              0.7512440085411072,
              0.468423992395401,
              0.751255989074707,
              0.46794599294662476,
              0.7512909770011902,
              0.46800199151039124,
              0.7512530088424683,
              0.4695909917354584,
              0.7512180209159851,
              0.46953099966049194,
              0.751259982585907,
              0.4692780077457428,
              0.7512180209159851,
              0.46953099966049194,
              0.7512249946594238,
              0.469217985868454,
              0.751259982585907,
              0.4692780077457428,
              0.751246988773346,
              0.4698469936847687,
              0.7512120008468628,
              0.46978598833084106,
              0.7512530088424683,
              0.4695909917354584,
              0.7512120008468628,
              0.46978598833084106,
              0.7512180209159851,
              0.46953099966049194,
              0.7512530088424683,
              0.4695909917354584,
              0.7515389919281006,
              0.45765000581741333,
              0.7515019774436951,
              0.4576680064201355,
              0.7515019774436951,
              0.45917800068855286,
              0.7515019774436951,
              0.4576680064201355,
              0.7514650225639343,
              0.459199994802475,
              0.7515019774436951,
              0.45917800068855286,
              0.7514669895172119,
              0.4606640040874481,
              0.75142902135849,
              0.4606890082359314,
              0.7514320015907288,
              0.46210500597953796,
              0.75142902135849,
              0.4606890082359314,
              0.7513949871063232,
              0.46213299036026,
              0.7514320015907288,
              0.46210500597953796,
              0.7512500286102295,
              0.4696919918060303,
              0.751213014125824,
              0.4697369933128357,
              0.7512249946594238,
              0.4707599878311157,
              0.751213014125824,
              0.4697369933128357,
              0.7511870265007019,
              0.4708069860935211,
              0.7512249946594238,
              0.4707599878311157,
              0.7512249946594238,
              0.4707599878311157,
              0.7511870265007019,
              0.4708069860935211,
              0.7512009739875793,
              0.47176700830459595,
              0.7511870265007019,
              0.4708069860935211,
              0.7511630058288574,
              0.4718160033226013,
              0.7512009739875793,
              0.47176700830459595,
              0.7511780261993408,
              0.4727090001106262,
              0.7511399984359741,
              0.47276100516319275,
              0.7511569857597351,
              0.47358599305152893,
              0.7511399984359741,
              0.47276100516319275,
              0.7511190176010132,
              0.47363901138305664,
              0.7511569857597351,
              0.47358599305152893,
              0.7511379718780518,
              0.4743939936161041,
              0.7511000037193298,
              0.4744490087032318,
              0.7511199712753296,
              0.4751319885253906,
              0.7511000037193298,
              0.4744490087032318,
              0.7510820031166077,
              0.4751890003681183,
              0.7511199712753296,
              0.4751319885253906,
              0.7511199712753296,
              0.4751319885253906,
              0.7510820031166077,
              0.4751890003681183,
              0.7511039972305298,
              0.47579801082611084,
              0.7510820031166077,
              0.4751890003681183,
              0.7510660290718079,
              0.4758560061454773,
              0.7511039972305298,
              0.47579801082611084,
              0.7510780096054077,
              0.4769040048122406,
              0.751039981842041,
              0.47696399688720703,
              0.7510669827461243,
              0.4773400127887726,
              0.751039981842041,
              0.47696399688720703,
              0.7510290145874023,
              0.477400004863739,
              0.7510669827461243,
              0.4773400127887726,
              0.7583510279655457,
              0.18776999413967133,
              0.7583439946174622,
              0.1877090036869049,
              0.7587599754333496,
              0.18776999413967133,
              0.7583439946174622,
              0.1877090036869049,
              0.7587509751319885,
              0.1877090036869049,
              0.7587599754333496,
              0.18776999413967133,
              0.7605779767036438,
              0.18776999413967133,
              0.7605559825897217,
              0.1877090036869049,
              0.760886013507843,
              0.18776999413967133,
              0.7605559825897217,
              0.1877090036869049,
              0.7608609795570374,
              0.1877090036869049,
              0.760886013507843,
              0.18776999413967133,
              0.760886013507843,
              0.18776999413967133,
              0.7608609795570374,
              0.1877090036869049,
              0.7611709833145142,
              0.18776999413967133,
              0.7608609795570374,
              0.1877090036869049,
              0.7611449956893921,
              0.1877090036869049,
              0.7611709833145142,
              0.18776999413967133,
              0.761434018611908,
              0.18776999413967133,
              0.7614060044288635,
              0.1877090036869049,
              0.7616720199584961,
              0.18776999413967133,
              0.7614060044288635,
              0.1877090036869049,
              0.7616419792175293,
              0.1877090036869049,
              0.7616720199584961,
              0.18776999413967133,
              0.7624490261077881,
              0.18776999413967133,
              0.7624130249023438,
              0.1877090036869049,
              0.7625120282173157,
              0.18776999413967133,
              0.7624130249023438,
              0.1877090036869049,
              0.7624750137329102,
              0.1877090036869049,
              0.7625120282173157,
              0.18776999413967133,
              0.7625340223312378,
              0.18776999413967133,
              0.7624980211257935,
              0.1877090036869049,
              0.76249098777771,
              0.18776999413967133,
              0.7624980211257935,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.76249098777771,
              0.18776999413967133,
              0.7574949860572815,
              0.18776999413967133,
              0.7574939727783203,
              0.1877090036869049,
              0.7579280138015747,
              0.18776999413967133,
              0.7574939727783203,
              0.1877090036869049,
              0.7579249739646912,
              0.1877090036869049,
              0.7579280138015747,
              0.18776999413967133,
              0.7595379948616028,
              0.18776999413967133,
              0.7595229744911194,
              0.1877090036869049,
              0.7599030137062073,
              0.18776999413967133,
              0.7595229744911194,
              0.1877090036869049,
              0.7598850131034851,
              0.1877090036869049,
              0.7599030137062073,
              0.18776999413967133,
              0.7602499723434448,
              0.18776999413967133,
              0.7602300047874451,
              0.1877090036869049,
              0.7605779767036438,
              0.18776999413967133,
              0.7602300047874451,
              0.1877090036869049,
              0.7605559825897217,
              0.1877090036869049,
              0.7605779767036438,
              0.18776999413967133,
              0.7616720199584961,
              0.18776999413967133,
              0.7616419792175293,
              0.1877090036869049,
              0.7618849873542786,
              0.18776999413967133,
              0.7616419792175293,
              0.1877090036869049,
              0.7618529796600342,
              0.1877090036869049,
              0.7618849873542786,
              0.18776999413967133,
              0.7622269988059998,
              0.18776999413967133,
              0.7621920108795166,
              0.1877090036869049,
              0.7623530030250549,
              0.18776999413967133,
              0.7621920108795166,
              0.1877090036869049,
              0.7623180150985718,
              0.1877090036869049,
              0.7623530030250549,
              0.18776999413967133,
              0.7623530030250549,
              0.18776999413967133,
              0.7623180150985718,
              0.1877090036869049,
              0.7624490261077881,
              0.18776999413967133,
              0.7623180150985718,
              0.1877090036869049,
              0.7624130249023438,
              0.1877090036869049,
              0.7624490261077881,
              0.18776999413967133,
              0.7415549755096436,
              0.18776999413967133,
              0.7415549755096436,
              0.1877090036869049,
              0.7570610046386719,
              0.18776999413967133,
              0.7415549755096436,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7570610046386719,
              0.18776999413967133,
              0.7591570019721985,
              0.1897359937429428,
              0.7591450214385986,
              0.18980200588703156,
              0.7587599754333496,
              0.18971699476242065,
              0.7591450214385986,
              0.18980200588703156,
              0.7587509751319885,
              0.1897830069065094,
              0.7587599754333496,
              0.18971699476242065,
              0.7595379948616028,
              0.1897599995136261,
              0.7595229744911194,
              0.18982599675655365,
              0.7591570019721985,
              0.1897359937429428,
              0.7595229744911194,
              0.18982599675655365,
              0.7591450214385986,
              0.18980200588703156,
              0.7591570019721985,
              0.1897359937429428,
              0.760886013507843,
              0.1898999959230423,
              0.7608609795570374,
              0.18996499478816986,
              0.7605779767036438,
              0.18985900282859802,
              0.7608609795570374,
              0.18996499478816986,
              0.7605559825897217,
              0.18992400169372559,
              0.7605779767036438,
              0.18985900282859802,
              0.761434018611908,
              0.1899929940700531,
              0.7614060044288635,
              0.19005699455738068,
              0.7611709833145142,
              0.18994499742984772,
              0.7614060044288635,
              0.19005699455738068,
              0.7611449956893921,
              0.19000999629497528,
              0.7611709833145142,
              0.18994499742984772,
              0.7618849873542786,
              0.19009900093078613,
              0.7618529796600342,
              0.19016200304031372,
              0.7616720199584961,
              0.19004400074481964,
              0.7618529796600342,
              0.19016200304031372,
              0.7616419792175293,
              0.1901089996099472,
              0.7616720199584961,
              0.19004400074481964,
              0.7620700001716614,
              0.1901559978723526,
              0.7620369791984558,
              0.1902189999818802,
              0.7618849873542786,
              0.19009900093078613,
              0.7620369791984558,
              0.1902189999818802,
              0.7618529796600342,
              0.19016200304031372,
              0.7618849873542786,
              0.19009900093078613,
              0.7622269988059998,
              0.19021500647068024,
              0.7621920108795166,
              0.19027799367904663,
              0.7620700001716614,
              0.1901559978723526,
              0.7621920108795166,
              0.19027799367904663,
              0.7620369791984558,
              0.1902189999818802,
              0.7620700001716614,
              0.1901559978723526,
              0.7624490261077881,
              0.19033999741077423,
              0.7624130249023438,
              0.19040200114250183,
              0.7623530030250549,
              0.19027699530124664,
              0.7624130249023438,
              0.19040200114250183,
              0.7623180150985718,
              0.19033899903297424,
              0.7623530030250549,
              0.19027699530124664,
              0.7625409960746765,
              0.19047200679779053,
              0.762503981590271,
              0.19053299725055695,
              0.7625120282173157,
              0.1904049962759018,
              0.762503981590271,
              0.19053299725055695,
              0.7624750137329102,
              0.1904670000076294,
              0.7625120282173157,
              0.1904049962759018,
              0.7624300122261047,
              0.1906760036945343,
              0.7623940110206604,
              0.19073599576950073,
              0.76249098777771,
              0.19060799479484558,
              0.7623940110206604,
              0.19073599576950073,
              0.7624549865722656,
              0.1906680017709732,
              0.76249098777771,
              0.19060799479484558,
              0.7574949860572815,
              0.18969100713729858,
              0.7574939727783203,
              0.18975800275802612,
              0.7570610046386719,
              0.18969100713729858,
              0.7574939727783203,
              0.18975800275802612,
              0.7570610046386719,
              0.18975800275802612,
              0.7570610046386719,
              0.18969100713729858,
              0.7570610046386719,
              0.18969100713729858,
              0.7570610046386719,
              0.18975800275802612,
              0.7415549755096436,
              0.18969100713729858,
              0.7570610046386719,
              0.18975800275802612,
              0.7415549755096436,
              0.18975800275802612,
              0.7415549755096436,
              0.18969100713729858,
              0.7515559792518616,
              0.4553939998149872,
              0.7515919804573059,
              0.45541200041770935,
              0.7515299916267395,
              0.45649099349975586,
              0.7515919804573059,
              0.45541200041770935,
              0.7515659928321838,
              0.4565120041370392,
              0.7515299916267395,
              0.45649099349975586,
              0.7514100074768066,
              0.4614880084991455,
              0.751446008682251,
              0.46152499318122864,
              0.7513890266418457,
              0.46237900853157043,
              0.751446008682251,
              0.46152499318122864,
              0.75142502784729,
              0.46241798996925354,
              0.7513890266418457,
              0.46237900853157043,
              0.7513890266418457,
              0.46237900853157043,
              0.75142502784729,
              0.46241798996925354,
              0.7513689994812012,
              0.4632300138473511,
              0.75142502784729,
              0.46241798996925354,
              0.7514039874076843,
              0.46327200531959534,
              0.7513689994812012,
              0.4632300138473511,
              0.7513689994812012,
              0.4632300138473511,
              0.7514039874076843,
              0.46327200531959534,
              0.7513489723205566,
              0.46404001116752625,
              0.7514039874076843,
              0.46327200531959534,
              0.751384973526001,
              0.4640839993953705,
              0.7513489723205566,
              0.46404001116752625,
              0.7512440085411072,
              0.468423992395401,
              0.7512789964675903,
              0.46848100423812866,
              0.7512339949607849,
              0.46884799003601074,
              0.7512789964675903,
              0.46848100423812866,
              0.7512689828872681,
              0.4689069986343384,
              0.7512339949607849,
              0.46884799003601074,
              0.7512339949607849,
              0.46884799003601074,
              0.7512689828872681,
              0.4689069986343384,
              0.7512249946594238,
              0.469217985868454,
              0.7512689828872681,
              0.4689069986343384,
              0.751259982585907,
              0.4692780077457428,
              0.7512249946594238,
              0.469217985868454,
              0.7515019774436951,
              0.4576680064201355,
              0.7515389919281006,
              0.45765000581741333,
              0.7515389919281006,
              0.45609501004219055,
              0.7515389919281006,
              0.45765000581741333,
              0.7515760064125061,
              0.45608100295066833,
              0.7515389919281006,
              0.45609501004219055,
              0.7513610124588013,
              0.4635300040245056,
              0.7513989806175232,
              0.4634990096092224,
              0.7513949871063232,
              0.46213299036026,
              0.7513989806175232,
              0.4634990096092224,
              0.7514320015907288,
              0.46210500597953796,
              0.7513949871063232,
              0.46213299036026,
              0.7510520219802856,
              0.4764479994773865,
              0.7510899901390076,
              0.47639000415802,
              0.7510660290718079,
              0.4758560061454773,
              0.7510899901390076,
              0.47639000415802,
              0.7511039972305298,
              0.47579801082611084,
              0.7510660290718079,
              0.4758560061454773,
              0.751039981842041,
              0.47696399688720703,
              0.7510780096054077,
              0.4769040048122406,
              0.7510520219802856,
              0.4764479994773865,
              0.7510780096054077,
              0.4769040048122406,
              0.7510899901390076,
              0.47639000415802,
              0.7510520219802856,
              0.4764479994773865,
              0.7510210275650024,
              0.47775599360466003,
              0.7510589957237244,
              0.4776949882507324,
              0.7510290145874023,
              0.477400004863739,
              0.7510589957237244,
              0.4776949882507324,
              0.7510669827461243,
              0.4773400127887726,
              0.7510290145874023,
              0.477400004863739,
              0.7515829801559448,
              0.4542680084705353,
              0.7516189813613892,
              0.4542819857597351,
              0.7515559792518616,
              0.4553939998149872,
              0.7516189813613892,
              0.4542819857597351,
              0.7515919804573059,
              0.45541200041770935,
              0.7515559792518616,
              0.4553939998149872,
              0.7515299916267395,
              0.45649099349975586,
              0.7515659928321838,
              0.4565120041370392,
              0.7515040040016174,
              0.4575580060482025,
              0.7515659928321838,
              0.4565120041370392,
              0.7515400052070618,
              0.4575830101966858,
              0.7515040040016174,
              0.4575580060482025,
              0.7513129711151123,
              0.4655289947986603,
              0.7513489723205566,
              0.46557798981666565,
              0.7512969970703125,
              0.46620601415634155,
              0.7513489723205566,
              0.46557798981666565,
              0.7513329982757568,
              0.4662570059299469,
              0.7512969970703125,
              0.46620601415634155,
              0.7512819766998291,
              0.46683499217033386,
              0.751317024230957,
              0.4668880105018616,
              0.7512680292129517,
              0.467415988445282,
              0.751317024230957,
              0.4668880105018616,
              0.7513039708137512,
              0.46747100353240967,
              0.7512680292129517,
              0.467415988445282,
              0.7511559724807739,
              0.47210100293159485,
              0.7512059807777405,
              0.47001200914382935,
              0.7511910200119019,
              0.4721609950065613,
              0.7512059807777405,
              0.47001200914382935,
              0.7512410283088684,
              0.47007301449775696,
              0.7511910200119019,
              0.4721609950065613,
              0.7513290047645569,
              0.46487900614738464,
              0.7513660192489624,
              0.4648439884185791,
              0.7513610124588013,
              0.4635300040245056,
              0.7513660192489624,
              0.4648439884185791,
              0.7513989806175232,
              0.4634990096092224,
              0.7513610124588013,
              0.4635300040245056,
              0.7512680292129517,
              0.46741899847984314,
              0.7513059973716736,
              0.46737900376319885,
              0.7512980103492737,
              0.46617498993873596,
              0.7513059973716736,
              0.46737900376319885,
              0.7513350248336792,
              0.46613800525665283,
              0.7512980103492737,
              0.46617498993873596,
              0.7512400150299072,
              0.46860700845718384,
              0.7512770295143127,
              0.4685640037059784,
              0.7512680292129517,
              0.46741899847984314,
              0.7512770295143127,
              0.4685640037059784,
              0.7513059973716736,
              0.46737900376319885,
              0.7512680292129517,
              0.46741899847984314,
              0.7511399984359741,
              0.47276100516319275,
              0.7511780261993408,
              0.4727090001106262,
              0.7511630058288574,
              0.4718160033226013,
              0.7511780261993408,
              0.4727090001106262,
              0.7512009739875793,
              0.47176700830459595,
              0.7511630058288574,
              0.4718160033226013,
              0.7511000037193298,
              0.4744490087032318,
              0.7511379718780518,
              0.4743939936161041,
              0.7511190176010132,
              0.47363901138305664,
              0.7511379718780518,
              0.4743939936161041,
              0.7511569857597351,
              0.47358599305152893,
              0.7511190176010132,
              0.47363901138305664,
              0.7591450214385986,
              0.1877090036869049,
              0.7591570019721985,
              0.18776999413967133,
              0.7587509751319885,
              0.1877090036869049,
              0.7591570019721985,
              0.18776999413967133,
              0.7587599754333496,
              0.18776999413967133,
              0.7587509751319885,
              0.1877090036869049,
              0.7595229744911194,
              0.1877090036869049,
              0.7595379948616028,
              0.18776999413967133,
              0.7591450214385986,
              0.1877090036869049,
              0.7595379948616028,
              0.18776999413967133,
              0.7591570019721985,
              0.18776999413967133,
              0.7591450214385986,
              0.1877090036869049,
              0.7602300047874451,
              0.1877090036869049,
              0.7602499723434448,
              0.18776999413967133,
              0.7598850131034851,
              0.1877090036869049,
              0.7602499723434448,
              0.18776999413967133,
              0.7599030137062073,
              0.18776999413967133,
              0.7598850131034851,
              0.1877090036869049,
              0.7624980211257935,
              0.1877090036869049,
              0.7625340223312378,
              0.18776999413967133,
              0.762503981590271,
              0.1877090036869049,
              0.7625340223312378,
              0.18776999413967133,
              0.7625409960746765,
              0.18776999413967133,
              0.762503981590271,
              0.1877090036869049,
              0.7583439946174622,
              0.1877090036869049,
              0.7583510279655457,
              0.18776999413967133,
              0.7579249739646912,
              0.1877090036869049,
              0.7583510279655457,
              0.18776999413967133,
              0.7579280138015747,
              0.18776999413967133,
              0.7579249739646912,
              0.1877090036869049,
              0.7614060044288635,
              0.1877090036869049,
              0.761434018611908,
              0.18776999413967133,
              0.7611449956893921,
              0.1877090036869049,
              0.761434018611908,
              0.18776999413967133,
              0.7611709833145142,
              0.18776999413967133,
              0.7611449956893921,
              0.1877090036869049,
              0.7620369791984558,
              0.1877090036869049,
              0.7620700001716614,
              0.18776999413967133,
              0.7618529796600342,
              0.1877090036869049,
              0.7620700001716614,
              0.18776999413967133,
              0.7618849873542786,
              0.18776999413967133,
              0.7618529796600342,
              0.1877090036869049,
              0.7621920108795166,
              0.1877090036869049,
              0.7622269988059998,
              0.18776999413967133,
              0.7620369791984558,
              0.1877090036869049,
              0.7622269988059998,
              0.18776999413967133,
              0.7620700001716614,
              0.18776999413967133,
              0.7620369791984558,
              0.1877090036869049,
              0.762503981590271,
              0.1877090036869049,
              0.7625409960746765,
              0.18776999413967133,
              0.7624750137329102,
              0.1877090036869049,
              0.7625409960746765,
              0.18776999413967133,
              0.7625120282173157,
              0.18776999413967133,
              0.7624750137329102,
              0.1877090036869049,
              0.7623940110206604,
              0.1877090036869049,
              0.7624300122261047,
              0.18776999413967133,
              0.7624549865722656,
              0.1877090036869049,
              0.7624300122261047,
              0.18776999413967133,
              0.76249098777771,
              0.18776999413967133,
              0.7624549865722656,
              0.1877090036869049,
              0.7574939727783203,
              0.1877090036869049,
              0.7574949860572815,
              0.18776999413967133,
              0.7570610046386719,
              0.1877090036869049,
              0.7574949860572815,
              0.18776999413967133,
              0.7570610046386719,
              0.18776999413967133,
              0.7570610046386719,
              0.1877090036869049,
              0.7574939727783203,
              0.18975800275802612,
              0.7574949860572815,
              0.18969100713729858,
              0.7579249739646912,
              0.1897609978914261,
              0.7574949860572815,
              0.18969100713729858,
              0.7579280138015747,
              0.18969400227069855,
              0.7579249739646912,
              0.1897609978914261,
              0.7579249739646912,
              0.1897609978914261,
              0.7579280138015747,
              0.18969400227069855,
              0.7583439946174622,
              0.18976899981498718,
              0.7579280138015747,
              0.18969400227069855,
              0.7583510279655457,
              0.18970300257205963,
              0.7583439946174622,
              0.18976899981498718,
              0.7583439946174622,
              0.18976899981498718,
              0.7583510279655457,
              0.18970300257205963,
              0.7587509751319885,
              0.1897830069065094,
              0.7583510279655457,
              0.18970300257205963,
              0.7587599754333496,
              0.18971699476242065,
              0.7587509751319885,
              0.1897830069065094,
              0.7595229744911194,
              0.18982599675655365,
              0.7595379948616028,
              0.1897599995136261,
              0.7598850131034851,
              0.1898539960384369,
              0.7595379948616028,
              0.1897599995136261,
              0.7599030137062073,
              0.18978899717330933,
              0.7598850131034851,
              0.1898539960384369,
              0.7598850131034851,
              0.1898539960384369,
              0.7599030137062073,
              0.18978899717330933,
              0.7602300047874451,
              0.18988700211048126,
              0.7599030137062073,
              0.18978899717330933,
              0.7602499723434448,
              0.1898220032453537,
              0.7602300047874451,
              0.18988700211048126,
              0.7602300047874451,
              0.18988700211048126,
              0.7602499723434448,
              0.1898220032453537,
              0.7605559825897217,
              0.18992400169372559,
              0.7602499723434448,
              0.1898220032453537,
              0.7605779767036438,
              0.18985900282859802,
              0.7605559825897217,
              0.18992400169372559,
              0.7608609795570374,
              0.18996499478816986,
              0.760886013507843,
              0.1898999959230423,
              0.7611449956893921,
              0.19000999629497528,
              0.760886013507843,
              0.1898999959230423,
              0.7611709833145142,
              0.18994499742984772,
              0.7611449956893921,
              0.19000999629497528,
              0.7614060044288635,
              0.19005699455738068,
              0.761434018611908,
              0.1899929940700531,
              0.7616419792175293,
              0.1901089996099472,
              0.761434018611908,
              0.1899929940700531,
              0.7616720199584961,
              0.19004400074481964,
              0.7616419792175293,
              0.1901089996099472,
              0.7621920108795166,
              0.19027799367904663,
              0.7622269988059998,
              0.19021500647068024,
              0.7623180150985718,
              0.19033899903297424,
              0.7622269988059998,
              0.19021500647068024,
              0.7623530030250549,
              0.19027699530124664,
              0.7623180150985718,
              0.19033899903297424,
              0.7624130249023438,
              0.19040200114250183,
              0.7624490261077881,
              0.19033999741077423,
              0.7624750137329102,
              0.1904670000076294,
              0.7624490261077881,
              0.19033999741077423,
              0.7625120282173157,
              0.1904049962759018,
              0.7624750137329102,
              0.1904670000076294,
              0.762503981590271,
              0.19053299725055695,
              0.7625409960746765,
              0.19047200679779053,
              0.7624980211257935,
              0.19059999287128448,
              0.7625409960746765,
              0.19047200679779053,
              0.7625340223312378,
              0.19054000079631805,
              0.7624980211257935,
              0.19059999287128448,
              0.7624980211257935,
              0.19059999287128448,
              0.7625340223312378,
              0.19054000079631805,
              0.7624549865722656,
              0.1906680017709732,
              0.7625340223312378,
              0.19054000079631805,
              0.76249098777771,
              0.19060799479484558,
              0.7624549865722656,
              0.1906680017709732,
              0.7515259981155396,
              0.45870399475097656,
              0.7515159845352173,
              0.45862001180648804,
              0.7515509724617004,
              0.45765599608421326,
              0.7515159845352173,
              0.45862001180648804,
              0.7515400052070618,
              0.4575830101966858,
              0.7515509724617004,
              0.45765599608421326,
              0.7515010237693787,
              0.4597170054912567,
              0.7514910101890564,
              0.4596239924430847,
              0.7515259981155396,
              0.45870399475097656,
              0.7514910101890564,
              0.4596239924430847,
              0.7515159845352173,
              0.45862001180648804,
              0.7515259981155396,
              0.45870399475097656,
              0.7514550089836121,
              0.4616360068321228,
              0.751446008682251,
              0.46152499318122864,
              0.7514780163764954,
              0.4606949985027313,
              0.751446008682251,
              0.46152499318122864,
              0.7514680027961731,
              0.46059298515319824,
              0.7514780163764954,
              0.4606949985027313,
              0.7513570189476013,
              0.4657270014286041,
              0.7513489723205566,
              0.46557798981666565,
              0.7513750195503235,
              0.46499499678611755,
              0.7513489723205566,
              0.46557798981666565,
              0.7513660192489624,
              0.46485400199890137,
              0.7513750195503235,
              0.46499499678611755,
              0.7513260245323181,
              0.4670479893684387,
              0.751317024230957,
              0.4668880105018616,
              0.7513409852981567,
              0.4664109945297241,
              0.751317024230957,
              0.4668880105018616,
              0.7513329982757568,
              0.4662570059299469,
              0.7513409852981567,
              0.4664109945297241,
              0.7515159845352173,
              0.45911499857902527,
              0.7515019774436951,
              0.45917800068855286,
              0.751479983329773,
              0.46059098839759827,
              0.7515019774436951,
              0.45917800068855286,
              0.7514669895172119,
              0.4606640040874481,
              0.751479983329773,
              0.46059098839759827,
              0.751380980014801,
              0.4647420048713684,
              0.7513660192489624,
              0.4648439884185791,
              0.7513499855995178,
              0.46602699160575867,
              0.7513660192489624,
              0.4648439884185791,
              0.7513350248336792,
              0.46613800525665283,
              0.7513499855995178,
              0.46602699160575867,
              0.7512919902801514,
              0.46843698620796204,
              0.7512770295143127,
              0.4685640037059784,
              0.7512660026550293,
              0.469556987285614,
              0.7512770295143127,
              0.4685640037059784,
              0.7512500286102295,
              0.4696919918060303,
              0.7512660026550293,
              0.469556987285614,
              0.75102299451828,
              0.4796789884567261,
              0.7510679960250854,
              0.47782400250434875,
              0.7510070204734802,
              0.4798569977283478,
              0.7510679960250854,
              0.47782400250434875,
              0.7510510087013245,
              0.4780080020427704,
              0.7510070204734802,
              0.4798569977283478,
              0.7514780163764954,
              0.4606949985027313,
              0.7514680027961731,
              0.46059298515319824,
              0.7515010237693787,
              0.4597170054912567,
              0.7514680027961731,
              0.46059298515319824,
              0.7514910101890564,
              0.4596239924430847,
              0.7515010237693787,
              0.4597170054912567,
              0.7513750195503235,
              0.46499499678611755,
              0.7513660192489624,
              0.46485400199890137,
              0.7513939738273621,
              0.46421900391578674,
              0.7513660192489624,
              0.46485400199890137,
              0.751384973526001,
              0.4640839993953705,
              0.7513939738273621,
              0.46421900391578674,
              0.7512990236282349,
              0.46817100048065186,
              0.7512909770011902,
              0.46800199151039124,
              0.7513120174407959,
              0.46763500571250916,
              0.7512909770011902,
              0.46800199151039124,
              0.7513039708137512,
              0.46747100353240967,
              0.7513120174407959,
              0.46763500571250916,
              0.7512869834899902,
              0.46865400671958923,
              0.7512789964675903,
              0.46848100423812866,
              0.7512990236282349,
              0.46817100048065186,
              0.7512789964675903,
              0.46848100423812866,
              0.7512909770011902,
              0.46800199151039124,
              0.7512990236282349,
              0.46817100048065186,
              0.7512609958648682,
              0.46977099776268005,
              0.7512530088424683,
              0.4695909917354584,
              0.7512680292129517,
              0.4694559872150421,
              0.7512530088424683,
              0.4695909917354584,
              0.751259982585907,
              0.4692780077457428,
              0.7512680292129517,
              0.4694559872150421,
              0.7512540221214294,
              0.47002899646759033,
              0.751246988773346,
              0.4698469936847687,
              0.7512609958648682,
              0.46977099776268005,
              0.751246988773346,
              0.4698469936847687,
              0.7512530088424683,
              0.4695909917354584,
              0.7512609958648682,
              0.46977099776268005,
              0.7515519857406616,
              0.4575969874858856,
              0.7515389919281006,
              0.45765000581741333,
              0.7515159845352173,
              0.45911499857902527,
              0.7515389919281006,
              0.45765000581741333,
              0.7515019774436951,
              0.45917800068855286,
              0.7515159845352173,
              0.45911499857902527,
              0.751479983329773,
              0.46059098839759827,
              0.7514669895172119,
              0.4606640040874481,
              0.751446008682251,
              0.46202200651168823,
              0.7514669895172119,
              0.4606640040874481,
              0.7514320015907288,
              0.46210500597953796,
              0.751446008682251,
              0.46202200651168823,
              0.7512660026550293,
              0.469556987285614,
              0.7512500286102295,
              0.4696919918060303,
              0.7512400150299072,
              0.47061899304389954,
              0.7512500286102295,
              0.4696919918060303,
              0.7512249946594238,
              0.4707599878311157,
              0.7512400150299072,
              0.47061899304389954,
              0.7512400150299072,
              0.47061899304389954,
              0.7512249946594238,
              0.4707599878311157,
              0.7512159943580627,
              0.47161799669265747,
              0.7512249946594238,
              0.4707599878311157,
              0.7512009739875793,
              0.47176700830459595,
              0.7512159943580627,
              0.47161799669265747,
              0.7511940002441406,
              0.472555011510849,
              0.7511780261993408,
              0.4727090001106262,
              0.7511730194091797,
              0.4734260141849518,
              0.7511780261993408,
              0.4727090001106262,
              0.7511569857597351,
              0.47358599305152893,
              0.7511730194091797,
              0.4734260141849518,
              0.7511540055274963,
              0.47422999143600464,
              0.7511379718780518,
              0.4743939936161041,
              0.7511360049247742,
              0.4749639928340912,
              0.7511379718780518,
              0.4743939936161041,
              0.7511199712753296,
              0.4751319885253906,
              0.7511360049247742,
              0.4749639928340912,
              0.7511360049247742,
              0.4749639928340912,
              0.7511199712753296,
              0.4751319885253906,
              0.7511199712753296,
              0.47562599182128906,
              0.7511199712753296,
              0.4751319885253906,
              0.7511039972305298,
              0.47579801082611084,
              0.7511199712753296,
              0.47562599182128906,
              0.7510939836502075,
              0.4767259955406189,
              0.7510780096054077,
              0.4769040048122406,
              0.75108402967453,
              0.4771600067615509,
              0.7510780096054077,
              0.4769040048122406,
              0.7510669827461243,
              0.4773400127887726,
              0.75108402967453,
              0.4771600067615509,
              0.7583529949188232,
              0.18795199692249298,
              0.7583510279655457,
              0.18776999413967133,
              0.7587640285491943,
              0.18795199692249298,
              0.7583510279655457,
              0.18776999413967133,
              0.7587599754333496,
              0.18776999413967133,
              0.7587640285491943,
              0.18795199692249298,
              0.7605850100517273,
              0.18795199692249298,
              0.7605779767036438,
              0.18776999413967133,
              0.7608940005302429,
              0.18795199692249298,
              0.7605779767036438,
              0.18776999413967133,
              0.760886013507843,
              0.18776999413967133,
              0.7608940005302429,
              0.18795199692249298,
              0.7608940005302429,
              0.18795199692249298,
              0.760886013507843,
              0.18776999413967133,
              0.7611799836158752,
              0.18795199692249298,
              0.760886013507843,
              0.18776999413967133,
              0.7611709833145142,
              0.18776999413967133,
              0.7611799836158752,
              0.18795199692249298,
              0.7614439725875854,
              0.18795199692249298,
              0.761434018611908,
              0.18776999413967133,
              0.7616829872131348,
              0.18795199692249298,
              0.761434018611908,
              0.18776999413967133,
              0.7616720199584961,
              0.18776999413967133,
              0.7616829872131348,
              0.18795199692249298,
              0.7624610066413879,
              0.18795199692249298,
              0.7624490261077881,
              0.18776999413967133,
              0.7625240087509155,
              0.18795199692249298,
              0.7624490261077881,
              0.18776999413967133,
              0.7625120282173157,
              0.18776999413967133,
              0.7625240087509155,
              0.18795199692249298,
              0.7625460028648376,
              0.18795199692249298,
              0.7625340223312378,
              0.18776999413967133,
              0.7625030279159546,
              0.18795199692249298,
              0.7625340223312378,
              0.18776999413967133,
              0.76249098777771,
              0.18776999413967133,
              0.7625030279159546,
              0.18795199692249298,
              0.7574949860572815,
              0.18795199692249298,
              0.7574949860572815,
              0.18776999413967133,
              0.7579290270805359,
              0.18795199692249298,
              0.7574949860572815,
              0.18776999413967133,
              0.7579280138015747,
              0.18776999413967133,
              0.7579290270805359,
              0.18795199692249298,
              0.7595430016517639,
              0.18795199692249298,
              0.7595379948616028,
              0.18776999413967133,
              0.7599089741706848,
              0.18795199692249298,
              0.7595379948616028,
              0.18776999413967133,
              0.7599030137062073,
              0.18776999413967133,
              0.7599089741706848,
              0.18795199692249298,
              0.7602570056915283,
              0.18795199692249298,
              0.7602499723434448,
              0.18776999413967133,
              0.7605850100517273,
              0.18795199692249298,
              0.7602499723434448,
              0.18776999413967133,
              0.7605779767036438,
              0.18776999413967133,
              0.7605850100517273,
              0.18795199692249298,
              0.7616829872131348,
              0.18795199692249298,
              0.7616720199584961,
              0.18776999413967133,
              0.7618950009346008,
              0.18795199692249298,
              0.7616720199584961,
              0.18776999413967133,
              0.7618849873542786,
              0.18776999413967133,
              0.7618950009346008,
              0.18795199692249298,
              0.7622380256652832,
              0.18795199692249298,
              0.7622269988059998,
              0.18776999413967133,
              0.7623649835586548,
              0.18795199692249298,
              0.7622269988059998,
              0.18776999413967133,
              0.7623530030250549,
              0.18776999413967133,
              0.7623649835586548,
              0.18795199692249298,
              0.7623649835586548,
              0.18795199692249298,
              0.7623530030250549,
              0.18776999413967133,
              0.7624610066413879,
              0.18795199692249298,
              0.7623530030250549,
              0.18776999413967133,
              0.7624490261077881,
              0.18776999413967133,
              0.7624610066413879,
              0.18795199692249298,
              0.7415549755096436,
              0.18795199692249298,
              0.7415549755096436,
              0.18776999413967133,
              0.7570610046386719,
              0.18795199692249298,
              0.7415549755096436,
              0.18776999413967133,
              0.7570610046386719,
              0.18776999413967133,
              0.7570610046386719,
              0.18795199692249298,
              0.7591609954833984,
              0.18955199420452118,
              0.7591570019721985,
              0.1897359937429428,
              0.7587640285491943,
              0.18953299522399902,
              0.7591570019721985,
              0.1897359937429428,
              0.7587599754333496,
              0.18971699476242065,
              0.7587640285491943,
              0.18953299522399902,
              0.7595430016517639,
              0.18957599997520447,
              0.7595379948616028,
              0.1897599995136261,
              0.7591609954833984,
              0.18955199420452118,
              0.7595379948616028,
              0.1897599995136261,
              0.7591570019721985,
              0.1897359937429428,
              0.7591609954833984,
              0.18955199420452118,
              0.7608940005302429,
              0.18971599638462067,
              0.760886013507843,
              0.1898999959230423,
              0.7605850100517273,
              0.1896750032901764,
              0.760886013507843,
              0.1898999959230423,
              0.7605779767036438,
              0.18985900282859802,
              0.7605850100517273,
              0.1896750032901764,
              0.7614439725875854,
              0.18980999290943146,
              0.761434018611908,
              0.1899929940700531,
              0.7611799836158752,
              0.1897609978914261,
              0.761434018611908,
              0.1899929940700531,
              0.7611709833145142,
              0.18994499742984772,
              0.7611799836158752,
              0.1897609978914261,
              0.7618950009346008,
              0.1899159997701645,
              0.7618849873542786,
              0.19009900093078613,
              0.7616829872131348,
              0.189860999584198,
              0.7618849873542786,
              0.19009900093078613,
              0.7616720199584961,
              0.19004400074481964,
              0.7616829872131348,
              0.189860999584198,
              0.7620810270309448,
              0.18997299671173096,
              0.7620700001716614,
              0.1901559978723526,
              0.7618950009346008,
              0.1899159997701645,
              0.7620700001716614,
              0.1901559978723526,
              0.7618849873542786,
              0.19009900093078613,
              0.7618950009346008,
              0.1899159997701645,
              0.7622380256652832,
              0.1900320053100586,
              0.7622269988059998,
              0.19021500647068024,
              0.7620810270309448,
              0.18997299671173096,
              0.7622269988059998,
              0.19021500647068024,
              0.7620700001716614,
              0.1901559978723526,
              0.7620810270309448,
              0.18997299671173096,
              0.7624610066413879,
              0.1901569962501526,
              0.7624490261077881,
              0.19033999741077423,
              0.7623649835586548,
              0.190093994140625,
              0.7624490261077881,
              0.19033999741077423,
              0.7623530030250549,
              0.19027699530124664,
              0.7623649835586548,
              0.190093994140625,
              0.7625529766082764,
              0.19029000401496887,
              0.7625409960746765,
              0.19047200679779053,
              0.7625240087509155,
              0.19022299349308014,
              0.7625409960746765,
              0.19047200679779053,
              0.7625120282173157,
              0.1904049962759018,
              0.7625240087509155,
              0.19022299349308014,
              0.7624419927597046,
              0.19049400091171265,
              0.7624300122261047,
              0.1906760036945343,
              0.7625030279159546,
              0.19042600691318512,
              0.7624300122261047,
              0.1906760036945343,
              0.76249098777771,
              0.19060799479484558,
              0.7625030279159546,
              0.19042600691318512,
              0.7574949860572815,
              0.18950699269771576,
              0.7574949860572815,
              0.18969100713729858,
              0.7570610046386719,
              0.18950699269771576,
              0.7574949860572815,
              0.18969100713729858,
              0.7570610046386719,
              0.18969100713729858,
              0.7570610046386719,
              0.18950699269771576,
              0.7570610046386719,
              0.18950699269771576,
              0.7570610046386719,
              0.18969100713729858,
              0.7415549755096436,
              0.18950699269771576,
              0.7570610046386719,
              0.18969100713729858,
              0.7415549755096436,
              0.18969100713729858,
              0.7415549755096436,
              0.18950699269771576,
              0.7510889768600464,
              0.476949006319046,
              0.7510799765586853,
              0.4773010015487671,
              0.751255989074707,
              0.4699830114841461,
              0.7510799765586853,
              0.4773010015487671,
              0.7512490153312683,
              0.4702419936656952,
              0.751255989074707,
              0.4699830114841461,
              0.7510989904403687,
              0.476516991853714,
              0.7510889768600464,
              0.476949006319046,
              0.7512630224227905,
              0.469664990901947,
              0.7510889768600464,
              0.476949006319046,
              0.751255989074707,
              0.4699830114841461,
              0.7512630224227905,
              0.469664990901947,
              0.7511109709739685,
              0.47600799798965454,
              0.7510989904403687,
              0.476516991853714,
              0.7512720227241516,
              0.46928900480270386,
              0.7510989904403687,
              0.476516991853714,
              0.7512630224227905,
              0.469664990901947,
              0.7512720227241516,
              0.46928900480270386,
              0.7511249780654907,
              0.47542300820350647,
              0.7511109709739685,
              0.47600799798965454,
              0.7512819766998291,
              0.46885600686073303,
              0.7511109709739685,
              0.47600799798965454,
              0.7512720227241516,
              0.46928900480270386,
              0.7512819766998291,
              0.46885600686073303,
              0.7511410117149353,
              0.47476598620414734,
              0.7511249780654907,
              0.47542300820350647,
              0.7512940168380737,
              0.4683690071105957,
              0.7511249780654907,
              0.47542300820350647,
              0.7512819766998291,
              0.46885600686073303,
              0.7512940168380737,
              0.4683690071105957,
              0.7511579990386963,
              0.4740369915962219,
              0.7511410117149353,
              0.47476598620414734,
              0.7513070106506348,
              0.46782800555229187,
              0.7511410117149353,
              0.47476598620414734,
              0.7512940168380737,
              0.4683690071105957,
              0.7513070106506348,
              0.46782800555229187,
              0.7511780261993408,
              0.473239004611969,
              0.7511579990386963,
              0.4740369915962219,
              0.751321017742157,
              0.4672349989414215,
              0.7511579990386963,
              0.4740369915962219,
              0.7513070106506348,
              0.46782800555229187,
              0.751321017742157,
              0.4672349989414215,
              0.7511979937553406,
              0.47237399220466614,
              0.7511780261993408,
              0.473239004611969,
              0.7513369917869568,
              0.466592013835907,
              0.7511780261993408,
              0.473239004611969,
              0.751321017742157,
              0.4672349989414215,
              0.7513369917869568,
              0.466592013835907,
              0.7512199878692627,
              0.4714449942111969,
              0.7511979937553406,
              0.47237399220466614,
              0.7513530254364014,
              0.4659009873867035,
              0.7511979937553406,
              0.47237399220466614,
              0.7513369917869568,
              0.466592013835907,
              0.7513530254364014,
              0.4659009873867035,
              0.7512440085411072,
              0.4704520106315613,
              0.7512199878692627,
              0.4714449942111969,
              0.7513710260391235,
              0.4651620090007782,
              0.7512199878692627,
              0.4714449942111969,
              0.7513530254364014,
              0.4659009873867035,
              0.7513710260391235,
              0.4651620090007782,
              0.7512689828872681,
              0.46939900517463684,
              0.7512440085411072,
              0.4704520106315613,
              0.7513899803161621,
              0.4643769860267639,
              0.7512440085411072,
              0.4704520106315613,
              0.7513710260391235,
              0.4651620090007782,
              0.7513899803161621,
              0.4643769860267639,
              0.7512959837913513,
              0.46828800439834595,
              0.7512689828872681,
              0.46939900517463684,
              0.7514100074768066,
              0.4635489881038666,
              0.7512689828872681,
              0.46939900517463684,
              0.7513899803161621,
              0.4643769860267639,
              0.7514100074768066,
              0.4635489881038666,
              0.7512959837913513,
              0.46828800439834595,
              0.7514100074768066,
              0.4635489881038666,
              0.7513239979743958,
              0.4671199917793274,
              0.7514100074768066,
              0.4635489881038666,
              0.7514309883117676,
              0.4626779854297638,
              0.7513239979743958,
              0.4671199917793274,
              0.7513239979743958,
              0.4671199917793274,
              0.7514309883117676,
              0.4626779854297638,
              0.7513530254364014,
              0.46589699387550354,
              0.7514309883117676,
              0.4626779854297638,
              0.7514520287513733,
              0.46176600456237793,
              0.7513530254364014,
              0.46589699387550354,
              0.7513530254364014,
              0.46589699387550354,
              0.7514520287513733,
              0.46176600456237793,
              0.7513840198516846,
              0.46462199091911316,
              0.7514520287513733,
              0.46176600456237793,
              0.7514749765396118,
              0.4608150124549866,
              0.7513840198516846,
              0.46462199091911316,
              0.7513840198516846,
              0.46462199091911316,
              0.7514749765396118,
              0.4608150124549866,
              0.751416027545929,
              0.4632970094680786,
              0.7514749765396118,
              0.4608150124549866,
              0.7514989972114563,
              0.4598270058631897,
              0.751416027545929,
              0.4632970094680786,
              0.751416027545929,
              0.4632970094680786,
              0.7514989972114563,
              0.4598270058631897,
              0.7514489889144897,
              0.4619239866733551,
              0.7514989972114563,
              0.4598270058631897,
              0.7515230178833008,
              0.4588020145893097,
              0.7514489889144897,
              0.4619239866733551,
              0.7514489889144897,
              0.4619239866733551,
              0.7515230178833008,
              0.4588020145893097,
              0.7514830231666565,
              0.4605039954185486,
              0.7515230178833008,
              0.4588020145893097,
              0.7515490055084229,
              0.45774298906326294,
              0.7514830231666565,
              0.4605039954185486,
              0.7514830231666565,
              0.4605039954185486,
              0.7515490055084229,
              0.45774298906326294,
              0.7515180110931396,
              0.45903998613357544,
              0.7515490055084229,
              0.45774298906326294,
              0.7515749931335449,
              0.4566510021686554,
              0.7515180110931396,
              0.45903998613357544,
              0.7515180110931396,
              0.45903998613357544,
              0.7515749931335449,
              0.4566510021686554,
              0.751554012298584,
              0.45753398537635803,
              0.7515749931335449,
              0.4566510021686554,
              0.7516019940376282,
              0.45552799105644226,
              0.751554012298584,
              0.45753398537635803,
              0.751554012298584,
              0.45753398537635803,
              0.7516019940376282,
              0.45552799105644226,
              0.7515910267829895,
              0.45598798990249634,
              0.7516019940376282,
              0.45552799105644226,
              0.7516289949417114,
              0.4543749988079071,
              0.7515910267829895,
              0.45598798990249634,
              0.7510799765586853,
              0.4773010015487671,
              0.7510730028152466,
              0.4776090085506439,
              0.7512490153312683,
              0.4702419936656952,
              0.7510730028152466,
              0.4776090085506439,
              0.7512440085411072,
              0.4704720079898834,
              0.7512490153312683,
              0.4702419936656952,
              0.7579290270805359,
              0.18816600739955902,
              0.7579290270805359,
              0.18929600715637207,
              0.7574949860572815,
              0.18816600739955902,
              0.7579290270805359,
              0.18929600715637207,
              0.7574949860572815,
              0.1892929971218109,
              0.7574949860572815,
              0.18816600739955902,
              0.7583529949188232,
              0.18816600739955902,
              0.7583529949188232,
              0.18930499255657196,
              0.7579290270805359,
              0.18816600739955902,
              0.7583529949188232,
              0.18930499255657196,
              0.7579290270805359,
              0.18929600715637207,
              0.7579290270805359,
              0.18816600739955902,
              0.7587640285491943,
              0.18816600739955902,
              0.7587640285491943,
              0.18931899964809418,
              0.7583529949188232,
              0.18816600739955902,
              0.7587640285491943,
              0.18931899964809418,
              0.7583529949188232,
              0.18930499255657196,
              0.7583529949188232,
              0.18816600739955902,
              0.7591609954833984,
              0.18816600739955902,
              0.7591609954833984,
              0.18933799862861633,
              0.7587640285491943,
              0.18816600739955902,
              0.7591609954833984,
              0.18933799862861633,
              0.7587640285491943,
              0.18931899964809418,
              0.7587640285491943,
              0.18816600739955902,
              0.7595430016517639,
              0.18816600739955902,
              0.7595430016517639,
              0.18936200439929962,
              0.7591609954833984,
              0.18816600739955902,
              0.7595430016517639,
              0.18936200439929962,
              0.7591609954833984,
              0.18933799862861633,
              0.7591609954833984,
              0.18816600739955902,
              0.7599089741706848,
              0.18816600739955902,
              0.7599089741706848,
              0.18939100205898285,
              0.7595430016517639,
              0.18816600739955902,
              0.7599089741706848,
              0.18939100205898285,
              0.7595430016517639,
              0.18936200439929962,
              0.7595430016517639,
              0.18816600739955902,
              0.7602570056915283,
              0.18816600739955902,
              0.7602570056915283,
              0.18942399322986603,
              0.7599089741706848,
              0.18816600739955902,
              0.7602570056915283,
              0.18942399322986603,
              0.7599089741706848,
              0.18939100205898285,
              0.7599089741706848,
              0.18816600739955902,
              0.7605850100517273,
              0.18816600739955902,
              0.7605850100517273,
              0.18946099281311035,
              0.7602570056915283,
              0.18816600739955902,
              0.7605850100517273,
              0.18946099281311035,
              0.7602570056915283,
              0.18942399322986603,
              0.7602570056915283,
              0.18816600739955902,
              0.7608940005302429,
              0.18816600739955902,
              0.7608940005302429,
              0.18950200080871582,
              0.7605850100517273,
              0.18816600739955902,
              0.7608940005302429,
              0.18950200080871582,
              0.7605850100517273,
              0.18946099281311035,
              0.7605850100517273,
              0.18816600739955902,
              0.7611799836158752,
              0.18816600739955902,
              0.7611799836158752,
              0.18954700231552124,
              0.7608940005302429,
              0.18816600739955902,
              0.7611799836158752,
              0.18954700231552124,
              0.7608940005302429,
              0.18950200080871582,
              0.7608940005302429,
              0.18816600739955902,
              0.7614439725875854,
              0.18816600739955902,
              0.7614439725875854,
              0.1895959973335266,
              0.7611799836158752,
              0.18816600739955902,
              0.7614439725875854,
              0.1895959973335266,
              0.7611799836158752,
              0.18954700231552124,
              0.7611799836158752,
              0.18816600739955902,
              0.7616829872131348,
              0.18816600739955902,
              0.7616829872131348,
              0.18964700400829315,
              0.7614439725875854,
              0.18816600739955902,
              0.7616829872131348,
              0.18964700400829315,
              0.7614439725875854,
              0.1895959973335266,
              0.7614439725875854,
              0.18816600739955902,
              0.7618950009346008,
              0.18816600739955902,
              0.7618950009346008,
              0.18970200419425964,
              0.7616829872131348,
              0.18816600739955902,
              0.7618950009346008,
              0.18970200419425964,
              0.7616829872131348,
              0.18964700400829315,
              0.7616829872131348,
              0.18816600739955902,
              0.7620810270309448,
              0.18816600739955902,
              0.7620810270309448,
              0.1897590011358261,
              0.7618950009346008,
              0.18816600739955902,
              0.7620810270309448,
              0.1897590011358261,
              0.7618950009346008,
              0.18970200419425964,
              0.7618950009346008,
              0.18816600739955902,
              0.7622380256652832,
              0.18816600739955902,
              0.7622380256652832,
              0.18981799483299255,
              0.7620810270309448,
              0.18816600739955902,
              0.7622380256652832,
              0.18981799483299255,
              0.7620810270309448,
              0.1897590011358261,
              0.7620810270309448,
              0.18816600739955902,
              0.7623649835586548,
              0.18816600739955902,
              0.7623649835586548,
              0.18987999856472015,
              0.7622380256652832,
              0.18816600739955902,
              0.7623649835586548,
              0.18987999856472015,
              0.7622380256652832,
              0.18981799483299255,
              0.7622380256652832,
              0.18816600739955902,
              0.7624610066413879,
              0.18816600739955902,
              0.7624610066413879,
              0.18994399905204773,
              0.7623649835586548,
              0.18816600739955902,
              0.7624610066413879,
              0.18994399905204773,
              0.7623649835586548,
              0.18987999856472015,
              0.7623649835586548,
              0.18816600739955902,
              0.7625240087509155,
              0.18816600739955902,
              0.7625240087509155,
              0.1900089979171753,
              0.7624610066413879,
              0.18816600739955902,
              0.7625240087509155,
              0.1900089979171753,
              0.7624610066413879,
              0.18994399905204773,
              0.7624610066413879,
              0.18816600739955902,
              0.7625529766082764,
              0.18816600739955902,
              0.7625529766082764,
              0.19007599353790283,
              0.7625240087509155,
              0.18816600739955902,
              0.7625529766082764,
              0.19007599353790283,
              0.7625240087509155,
              0.1900089979171753,
              0.7625240087509155,
              0.18816600739955902,
              0.7625460028648376,
              0.18816600739955902,
              0.7625460028648376,
              0.19014300405979156,
              0.7625529766082764,
              0.18816600739955902,
              0.7625460028648376,
              0.19014300405979156,
              0.7625529766082764,
              0.19007599353790283,
              0.7625529766082764,
              0.18816600739955902,
              0.7625030279159546,
              0.18816600739955902,
              0.7625030279159546,
              0.19021199643611908,
              0.7625460028648376,
              0.18816600739955902,
              0.7625030279159546,
              0.19021199643611908,
              0.7625460028648376,
              0.19014300405979156,
              0.7625460028648376,
              0.18816600739955902,
              0.7624419927597046,
              0.18816600739955902,
              0.7624419927597046,
              0.1902800053358078,
              0.7625030279159546,
              0.18816600739955902,
              0.7624419927597046,
              0.1902800053358078,
              0.7625030279159546,
              0.19021199643611908,
              0.7625030279159546,
              0.18816600739955902,
              0.7574949860572815,
              0.18816600739955902,
              0.7574949860572815,
              0.1892929971218109,
              0.7570610046386719,
              0.18816600739955902,
              0.7574949860572815,
              0.1892929971218109,
              0.7570610046386719,
              0.1892929971218109,
              0.7570610046386719,
              0.18816600739955902,
              0.7570610046386719,
              0.18816600739955902,
              0.7570610046386719,
              0.1892929971218109,
              0.7415549755096436,
              0.18816600739955902,
              0.7570610046386719,
              0.1892929971218109,
              0.7415549755096436,
              0.1892929971218109,
              0.7415549755096436,
              0.18816600739955902,
              0.7515749931335449,
              0.4566510021686554,
              0.7515770196914673,
              0.45657598972320557,
              0.7516019940376282,
              0.45552799105644226,
              0.7515770196914673,
              0.45657598972320557,
              0.7516030073165894,
              0.4554649889469147,
              0.7516019940376282,
              0.45552799105644226,
              0.7514309883117676,
              0.4626779854297638,
              0.7514340281486511,
              0.4625380039215088,
              0.7514520287513733,
              0.46176600456237793,
              0.7514340281486511,
              0.4625380039215088,
              0.7514550089836121,
              0.4616360068321228,
              0.7514520287513733,
              0.46176600456237793,
              0.7514100074768066,
              0.4635489881038666,
              0.7514129877090454,
              0.4633989930152893,
              0.7514309883117676,
              0.4626779854297638,
              0.7514129877090454,
              0.4633989930152893,
              0.7514340281486511,
              0.4625380039215088,
              0.7514309883117676,
              0.4626779854297638,
              0.7513899803161621,
              0.4643769860267639,
              0.7513939738273621,
              0.46421900391578674,
              0.7514100074768066,
              0.4635489881038666,
              0.7513939738273621,
              0.46421900391578674,
              0.7514129877090454,
              0.4633989930152893,
              0.7514100074768066,
              0.4635489881038666,
              0.7512720227241516,
              0.46928900480270386,
              0.7512770295143127,
              0.4690830111503601,
              0.7512819766998291,
              0.46885600686073303,
              0.7512770295143127,
              0.4690830111503601,
              0.7512869834899902,
              0.46865400671958923,
              0.7512819766998291,
              0.46885600686073303,
              0.7512630224227905,
              0.469664990901947,
              0.7512680292129517,
              0.4694559872150421,
              0.7512720227241516,
              0.46928900480270386,
              0.7512680292129517,
              0.4694559872150421,
              0.7512770295143127,
              0.4690830111503601,
              0.7512720227241516,
              0.46928900480270386,
              0.7515910267829895,
              0.45598798990249634,
              0.7515900135040283,
              0.4560379981994629,
              0.751554012298584,
              0.45753398537635803,
              0.7515900135040283,
              0.4560379981994629,
              0.7515519857406616,
              0.4575969874858856,
              0.751554012298584,
              0.45753398537635803,
              0.7514489889144897,
              0.4619239866733551,
              0.751446008682251,
              0.46202200651168823,
              0.751416027545929,
              0.4632970094680786,
              0.751446008682251,
              0.46202200651168823,
              0.7514129877090454,
              0.4634059965610504,
              0.751416027545929,
              0.4632970094680786,
              0.7511249780654907,
              0.47542300820350647,
              0.7511199712753296,
              0.47562599182128906,
              0.7511109709739685,
              0.47600799798965454,
              0.7511199712753296,
              0.47562599182128906,
              0.7511060237884521,
              0.4762139916419983,
              0.7511109709739685,
              0.47600799798965454,
              0.7511109709739685,
              0.47600799798965454,
              0.7511060237884521,
              0.4762139916419983,
              0.7510989904403687,
              0.476516991853714,
              0.7511060237884521,
              0.4762139916419983,
              0.7510939836502075,
              0.4767259955406189,
              0.7510989904403687,
              0.476516991853714,
              0.7510889768600464,
              0.476949006319046,
              0.75108402967453,
              0.4771600067615509,
              0.7510799765586853,
              0.4773010015487671,
              0.75108402967453,
              0.4771600067615509,
              0.751075029373169,
              0.47751399874687195,
              0.7510799765586853,
              0.4773010015487671,
              0.7516019940376282,
              0.45552799105644226,
              0.7516030073165894,
              0.4554649889469147,
              0.7516289949417114,
              0.4543749988079071,
              0.7516030073165894,
              0.4554649889469147,
              0.7516310214996338,
              0.45432499051094055,
              0.7516289949417114,
              0.4543749988079071,
              0.7515490055084229,
              0.45774298906326294,
              0.7515509724617004,
              0.45765599608421326,
              0.7515749931335449,
              0.4566510021686554,
              0.7515509724617004,
              0.45765599608421326,
              0.7515770196914673,
              0.45657598972320557,
              0.7515749931335449,
              0.4566510021686554,
              0.7513369917869568,
              0.466592013835907,
              0.7513409852981567,
              0.4664109945297241,
              0.7513530254364014,
              0.4659009873867035,
              0.7513409852981567,
              0.4664109945297241,
              0.7513570189476013,
              0.4657270014286041,
              0.7513530254364014,
              0.4659009873867035,
              0.7513070106506348,
              0.46782800555229187,
              0.7513120174407959,
              0.46763500571250916,
              0.751321017742157,
              0.4672349989414215,
              0.7513120174407959,
              0.46763500571250916,
              0.7513260245323181,
              0.4670479893684387,
              0.751321017742157,
              0.4672349989414215,
              0.7511990070343018,
              0.472339004278183,
              0.7512490153312683,
              0.4702570140361786,
              0.7511940002441406,
              0.4725480079650879,
              0.7512490153312683,
              0.4702570140361786,
              0.7512440085411072,
              0.4704720079898834,
              0.7511940002441406,
              0.4725480079650879,
              0.751416027545929,
              0.4632970094680786,
              0.7514129877090454,
              0.4634059965610504,
              0.7513840198516846,
              0.46462199091911316,
              0.7514129877090454,
              0.4634059965610504,
              0.751380980014801,
              0.4647420048713684,
              0.7513840198516846,
              0.46462199091911316,
              0.7513530254364014,
              0.46589699387550354,
              0.7513499855995178,
              0.46602699160575867,
              0.7513239979743958,
              0.4671199917793274,
              0.7513499855995178,
              0.46602699160575867,
              0.751321017742157,
              0.4672600030899048,
              0.7513239979743958,
              0.4671199917793274,
              0.7513239979743958,
              0.4671199917793274,
              0.751321017742157,
              0.4672600030899048,
              0.7512959837913513,
              0.46828800439834595,
              0.751321017742157,
              0.4672600030899048,
              0.7512919902801514,
              0.46843698620796204,
              0.7512959837913513,
              0.46828800439834595,
              0.7512199878692627,
              0.4714449942111969,
              0.7512159943580627,
              0.47161799669265747,
              0.7511979937553406,
              0.47237399220466614,
              0.7512159943580627,
              0.47161799669265747,
              0.7511940002441406,
              0.472555011510849,
              0.7511979937553406,
              0.47237399220466614,
              0.7511780261993408,
              0.473239004611969,
              0.7511730194091797,
              0.4734260141849518,
              0.7511579990386963,
              0.4740369915962219,
              0.7511730194091797,
              0.4734260141849518,
              0.7511540055274963,
              0.47422999143600464,
              0.7511579990386963,
              0.4740369915962219,
              0.7587640285491943,
              0.18816600739955902,
              0.7587640285491943,
              0.18795199692249298,
              0.7591609954833984,
              0.18816600739955902,
              0.7587640285491943,
              0.18795199692249298,
              0.7591609954833984,
              0.18795199692249298,
              0.7591609954833984,
              0.18816600739955902,
              0.7591609954833984,
              0.18816600739955902,
              0.7591609954833984,
              0.18795199692249298,
              0.7595430016517639,
              0.18816600739955902,
              0.7591609954833984,
              0.18795199692249298,
              0.7595430016517639,
              0.18795199692249298,
              0.7595430016517639,
              0.18816600739955902,
              0.7599089741706848,
              0.18816600739955902,
              0.7599089741706848,
              0.18795199692249298,
              0.7602570056915283,
              0.18816600739955902,
              0.7599089741706848,
              0.18795199692249298,
              0.7602570056915283,
              0.18795199692249298,
              0.7602570056915283,
              0.18816600739955902,
              0.7625529766082764,
              0.18816600739955902,
              0.7625529766082764,
              0.18795199692249298,
              0.7625460028648376,
              0.18816600739955902,
              0.7625529766082764,
              0.18795199692249298,
              0.7625460028648376,
              0.18795199692249298,
              0.7625460028648376,
              0.18816600739955902,
              0.7579290270805359,
              0.18816600739955902,
              0.7579290270805359,
              0.18795199692249298,
              0.7583529949188232,
              0.18816600739955902,
              0.7579290270805359,
              0.18795199692249298,
              0.7583529949188232,
              0.18795199692249298,
              0.7583529949188232,
              0.18816600739955902,
              0.7611799836158752,
              0.18816600739955902,
              0.7611799836158752,
              0.18795199692249298,
              0.7614439725875854,
              0.18816600739955902,
              0.7611799836158752,
              0.18795199692249298,
              0.7614439725875854,
              0.18795199692249298,
              0.7614439725875854,
              0.18816600739955902,
              0.7618950009346008,
              0.18816600739955902,
              0.7618950009346008,
              0.18795199692249298,
              0.7620810270309448,
              0.18816600739955902,
              0.7618950009346008,
              0.18795199692249298,
              0.7620810270309448,
              0.18795199692249298,
              0.7620810270309448,
              0.18816600739955902,
              0.7620810270309448,
              0.18816600739955902,
              0.7620810270309448,
              0.18795199692249298,
              0.7622380256652832,
              0.18816600739955902,
              0.7620810270309448,
              0.18795199692249298,
              0.7622380256652832,
              0.18795199692249298,
              0.7622380256652832,
              0.18816600739955902,
              0.7625240087509155,
              0.18816600739955902,
              0.7625240087509155,
              0.18795199692249298,
              0.7625529766082764,
              0.18816600739955902,
              0.7625240087509155,
              0.18795199692249298,
              0.7625529766082764,
              0.18795199692249298,
              0.7625529766082764,
              0.18816600739955902,
              0.7625030279159546,
              0.18816600739955902,
              0.7625030279159546,
              0.18795199692249298,
              0.7624419927597046,
              0.18816600739955902,
              0.7625030279159546,
              0.18795199692249298,
              0.7624419927597046,
              0.18795199692249298,
              0.7624419927597046,
              0.18816600739955902,
              0.7570610046386719,
              0.18816600739955902,
              0.7570610046386719,
              0.18795199692249298,
              0.7574949860572815,
              0.18816600739955902,
              0.7570610046386719,
              0.18795199692249298,
              0.7574949860572815,
              0.18795199692249298,
              0.7574949860572815,
              0.18816600739955902,
              0.7579290270805359,
              0.18929600715637207,
              0.7579290270805359,
              0.18951000273227692,
              0.7574949860572815,
              0.1892929971218109,
              0.7579290270805359,
              0.18951000273227692,
              0.7574949860572815,
              0.18950699269771576,
              0.7574949860572815,
              0.1892929971218109,
              0.7583529949188232,
              0.18930499255657196,
              0.7583529949188232,
              0.189519003033638,
              0.7579290270805359,
              0.18929600715637207,
              0.7583529949188232,
              0.189519003033638,
              0.7579290270805359,
              0.18951000273227692,
              0.7579290270805359,
              0.18929600715637207,
              0.7587640285491943,
              0.18931899964809418,
              0.7587640285491943,
              0.18953299522399902,
              0.7583529949188232,
              0.18930499255657196,
              0.7587640285491943,
              0.18953299522399902,
              0.7583529949188232,
              0.189519003033638,
              0.7583529949188232,
              0.18930499255657196,
              0.7599089741706848,
              0.18939100205898285,
              0.7599089741706848,
              0.1896049976348877,
              0.7595430016517639,
              0.18936200439929962,
              0.7599089741706848,
              0.1896049976348877,
              0.7595430016517639,
              0.18957599997520447,
              0.7595430016517639,
              0.18936200439929962,
              0.7602570056915283,
              0.18942399322986603,
              0.7602570056915283,
              0.18963800370693207,
              0.7599089741706848,
              0.18939100205898285,
              0.7602570056915283,
              0.18963800370693207,
              0.7599089741706848,
              0.1896049976348877,
              0.7599089741706848,
              0.18939100205898285,
              0.7605850100517273,
              0.18946099281311035,
              0.7605850100517273,
              0.1896750032901764,
              0.7602570056915283,
              0.18942399322986603,
              0.7605850100517273,
              0.1896750032901764,
              0.7602570056915283,
              0.18963800370693207,
              0.7602570056915283,
              0.18942399322986603,
              0.7611799836158752,
              0.18954700231552124,
              0.7611799836158752,
              0.1897609978914261,
              0.7608940005302429,
              0.18950200080871582,
              0.7611799836158752,
              0.1897609978914261,
              0.7608940005302429,
              0.18971599638462067,
              0.7608940005302429,
              0.18950200080871582,
              0.7616829872131348,
              0.18964700400829315,
              0.7616829872131348,
              0.189860999584198,
              0.7614439725875854,
              0.1895959973335266,
              0.7616829872131348,
              0.189860999584198,
              0.7614439725875854,
              0.18980999290943146,
              0.7614439725875854,
              0.1895959973335266,
              0.7623649835586548,
              0.18987999856472015,
              0.7623649835586548,
              0.190093994140625,
              0.7622380256652832,
              0.18981799483299255,
              0.7623649835586548,
              0.190093994140625,
              0.7622380256652832,
              0.1900320053100586,
              0.7622380256652832,
              0.18981799483299255,
              0.7625240087509155,
              0.1900089979171753,
              0.7625240087509155,
              0.19022299349308014,
              0.7624610066413879,
              0.18994399905204773,
              0.7625240087509155,
              0.19022299349308014,
              0.7624610066413879,
              0.1901569962501526,
              0.7624610066413879,
              0.18994399905204773,
              0.7625460028648376,
              0.19014300405979156,
              0.7625460028648376,
              0.1903569996356964,
              0.7625529766082764,
              0.19007599353790283,
              0.7625460028648376,
              0.1903569996356964,
              0.7625529766082764,
              0.19029000401496887,
              0.7625529766082764,
              0.19007599353790283,
              0.7625030279159546,
              0.19021199643611908,
              0.7625030279159546,
              0.19042600691318512,
              0.7625460028648376,
              0.19014300405979156,
              0.7625030279159546,
              0.19042600691318512,
              0.7625460028648376,
              0.1903569996356964,
              0.7625460028648376,
              0.19014300405979156,
              0.7514610290527344,
              0.4575580060482025,
              0.7515040040016174,
              0.4575580060482025,
              0.7514359951019287,
              0.4585919976234436,
              0.7515040040016174,
              0.4575580060482025,
              0.751479983329773,
              0.4585919976234436,
              0.7514359951019287,
              0.4585919976234436,
              0.7514359951019287,
              0.4585919976234436,
              0.751479983329773,
              0.4585919976234436,
              0.7514129877090454,
              0.4595929980278015,
              0.751479983329773,
              0.4585919976234436,
              0.7514560222625732,
              0.4595929980278015,
              0.7514129877090454,
              0.4595929980278015,
              0.7513899803161621,
              0.46055901050567627,
              0.7514320015907288,
              0.46055901050567627,
              0.7513669729232788,
              0.4614880084991455,
              0.7514320015907288,
              0.46055901050567627,
              0.7514100074768066,
              0.4614880084991455,
              0.7513669729232788,
              0.4614880084991455,
              0.7512879967689514,
              0.4648059904575348,
              0.7513309717178345,
              0.4648059904575348,
              0.7512710094451904,
              0.4655289947986603,
              0.7513309717178345,
              0.4648059904575348,
              0.7513129711151123,
              0.4655289947986603,
              0.7512710094451904,
              0.4655289947986603,
              0.7512540221214294,
              0.46620601415634155,
              0.7512969970703125,
              0.46620601415634155,
              0.751239001750946,
              0.46683499217033386,
              0.7512969970703125,
              0.46620601415634155,
              0.7512819766998291,
              0.46683499217033386,
              0.751239001750946,
              0.46683499217033386,
              0.7513859868049622,
              0.4606890082359314,
              0.75142902135849,
              0.4606890082359314,
              0.7514219880104065,
              0.459199994802475,
              0.75142902135849,
              0.4606890082359314,
              0.7514650225639343,
              0.459199994802475,
              0.7514219880104065,
              0.459199994802475,
              0.7512549757957458,
              0.46617498993873596,
              0.7512980103492737,
              0.46617498993873596,
              0.751285970211029,
              0.46487900614738464,
              0.7512980103492737,
              0.46617498993873596,
              0.7513290047645569,
              0.46487900614738464,
              0.751285970211029,
              0.46487900614738464,
              0.7511699795722961,
              0.4697369933128357,
              0.751213014125824,
              0.4697369933128357,
              0.7511969804763794,
              0.46860700845718384,
              0.751213014125824,
              0.4697369933128357,
              0.7512400150299072,
              0.46860700845718384,
              0.7511969804763794,
              0.46860700845718384,
              0.7509689927101135,
              0.4799160063266754,
              0.7510129809379578,
              0.478069007396698,
              0.7509260177612305,
              0.4799160063266754,
              0.7510129809379578,
              0.478069007396698,
              0.7509700059890747,
              0.478069007396698,
              0.7509260177612305,
              0.4799160063266754,
              0.7514129877090454,
              0.4595929980278015,
              0.7514560222625732,
              0.4595929980278015,
              0.7513899803161621,
              0.46055901050567627,
              0.7514560222625732,
              0.4595929980278015,
              0.7514320015907288,
              0.46055901050567627,
              0.7513899803161621,
              0.46055901050567627,
              0.7513059973716736,
              0.46404001116752625,
              0.7513489723205566,
              0.46404001116752625,
              0.7512879967689514,
              0.4648059904575348,
              0.7513489723205566,
              0.46404001116752625,
              0.7513309717178345,
              0.4648059904575348,
              0.7512879967689514,
              0.4648059904575348,
              0.7512249946594238,
              0.467415988445282,
              0.7512680292129517,
              0.467415988445282,
              0.751213014125824,
              0.46794599294662476,
              0.7512680292129517,
              0.467415988445282,
              0.751255989074707,
              0.46794599294662476,
              0.751213014125824,
              0.46794599294662476,
              0.751213014125824,
              0.46794599294662476,
              0.751255989074707,
              0.46794599294662476,
              0.7512009739875793,
              0.468423992395401,
              0.751255989074707,
              0.46794599294662476,
              0.7512440085411072,
              0.468423992395401,
              0.7512009739875793,
              0.468423992395401,
              0.7511820197105408,
              0.469217985868454,
              0.7512249946594238,
              0.469217985868454,
              0.7511749863624573,
              0.46953099966049194,
              0.7512249946594238,
              0.469217985868454,
              0.7512180209159851,
              0.46953099966049194,
              0.7511749863624573,
              0.46953099966049194,
              0.7511749863624573,
              0.46953099966049194,
              0.7512180209159851,
              0.46953099966049194,
              0.7511690258979797,
              0.46978598833084106,
              0.7512180209159851,
              0.46953099966049194,
              0.7512120008468628,
              0.46978598833084106,
              0.7511690258979797,
              0.46978598833084106,
              0.7514219880104065,
              0.459199994802475,
              0.7514650225639343,
              0.459199994802475,
              0.751459002494812,
              0.4576680064201355,
              0.7514650225639343,
              0.459199994802475,
              0.7515019774436951,
              0.4576680064201355,
              0.751459002494812,
              0.4576680064201355,
              0.7513520121574402,
              0.46213299036026,
              0.7513949871063232,
              0.46213299036026,
              0.7513859868049622,
              0.4606890082359314,
              0.7513949871063232,
              0.46213299036026,
              0.75142902135849,
              0.4606890082359314,
              0.7513859868049622,
              0.4606890082359314,
              0.7511439919471741,
              0.4708069860935211,
              0.7511870265007019,
              0.4708069860935211,
              0.7511699795722961,
              0.4697369933128357,
              0.7511870265007019,
              0.4708069860935211,
              0.751213014125824,
              0.4697369933128357,
              0.7511699795722961,
              0.4697369933128357,
              0.7511199712753296,
              0.4718160033226013,
              0.7511630058288574,
              0.4718160033226013,
              0.7511439919471741,
              0.4708069860935211,
              0.7511630058288574,
              0.4718160033226013,
              0.7511870265007019,
              0.4708069860935211,
              0.7511439919471741,
              0.4708069860935211,
              0.7510759830474854,
              0.47363901138305664,
              0.7511190176010132,
              0.47363901138305664,
              0.7510970234870911,
              0.47276100516319275,
              0.7511190176010132,
              0.47363901138305664,
              0.7511399984359741,
              0.47276100516319275,
              0.7510970234870911,
              0.47276100516319275,
              0.7510390281677246,
              0.4751890003681183,
              0.7510820031166077,
              0.4751890003681183,
              0.7510570287704468,
              0.4744490087032318,
              0.7510820031166077,
              0.4751890003681183,
              0.7511000037193298,
              0.4744490087032318,
              0.7510570287704468,
              0.4744490087032318,
              0.75102299451828,
              0.4758560061454773,
              0.7510660290718079,
              0.4758560061454773,
              0.7510390281677246,
              0.4751890003681183,
              0.7510660290718079,
              0.4758560061454773,
              0.7510820031166077,
              0.4751890003681183,
              0.7510390281677246,
              0.4751890003681183,
              0.7509859800338745,
              0.477400004863739,
              0.7510290145874023,
              0.477400004863739,
              0.750997006893158,
              0.47696399688720703,
              0.7510290145874023,
              0.477400004863739,
              0.751039981842041,
              0.47696399688720703,
              0.750997006893158,
              0.47696399688720703,
              0.758741021156311,
              0.1877090036869049,
              0.7587509751319885,
              0.1877090036869049,
              0.7583370208740234,
              0.1877090036869049,
              0.7587509751319885,
              0.1877090036869049,
              0.7583439946174622,
              0.1877090036869049,
              0.7583370208740234,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7608609795570374,
              0.1877090036869049,
              0.7605289816856384,
              0.1877090036869049,
              0.7608609795570374,
              0.1877090036869049,
              0.7605559825897217,
              0.1877090036869049,
              0.7605289816856384,
              0.1877090036869049,
              0.7611140012741089,
              0.1877090036869049,
              0.7611449956893921,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7611449956893921,
              0.1877090036869049,
              0.7608609795570374,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7616069912910461,
              0.1877090036869049,
              0.7616419792175293,
              0.1877090036869049,
              0.7613720297813416,
              0.1877090036869049,
              0.7616419792175293,
              0.1877090036869049,
              0.7614060044288635,
              0.1877090036869049,
              0.7613720297813416,
              0.1877090036869049,
              0.7624329924583435,
              0.1877090036869049,
              0.7624750137329102,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7624750137329102,
              0.1877090036869049,
              0.7624130249023438,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624980211257935,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7579219937324524,
              0.1877090036869049,
              0.7579249739646912,
              0.1877090036869049,
              0.7574930191040039,
              0.1877090036869049,
              0.7579249739646912,
              0.1877090036869049,
              0.7574939727783203,
              0.1877090036869049,
              0.7574930191040039,
              0.1877090036869049,
              0.7598649859428406,
              0.1877090036869049,
              0.7598850131034851,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7598850131034851,
              0.1877090036869049,
              0.7595229744911194,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7605289816856384,
              0.1877090036869049,
              0.7605559825897217,
              0.1877090036869049,
              0.7602059841156006,
              0.1877090036869049,
              0.7605559825897217,
              0.1877090036869049,
              0.7602300047874451,
              0.1877090036869049,
              0.7602059841156006,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7618529796600342,
              0.1877090036869049,
              0.7616069912910461,
              0.1877090036869049,
              0.7618529796600342,
              0.1877090036869049,
              0.7616419792175293,
              0.1877090036869049,
              0.7616069912910461,
              0.1877090036869049,
              0.7622770071029663,
              0.1877090036869049,
              0.7623180150985718,
              0.1877090036869049,
              0.7621520161628723,
              0.1877090036869049,
              0.7623180150985718,
              0.1877090036869049,
              0.7621920108795166,
              0.1877090036869049,
              0.7621520161628723,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7624130249023438,
              0.1877090036869049,
              0.7622770071029663,
              0.1877090036869049,
              0.7624130249023438,
              0.1877090036869049,
              0.7623180150985718,
              0.1877090036869049,
              0.7622770071029663,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.758741021156311,
              0.18978999555110931,
              0.7587509751319885,
              0.1897830069065094,
              0.7591300010681152,
              0.18980899453163147,
              0.7587509751319885,
              0.1897830069065094,
              0.7591450214385986,
              0.18980200588703156,
              0.7591300010681152,
              0.18980899453163147,
              0.7591300010681152,
              0.18980899453163147,
              0.7591450214385986,
              0.18980200588703156,
              0.7595059871673584,
              0.18983200192451477,
              0.7591450214385986,
              0.18980200588703156,
              0.7595229744911194,
              0.18982599675655365,
              0.7595059871673584,
              0.18983200192451477,
              0.7605289816856384,
              0.1899300068616867,
              0.7605559825897217,
              0.18992400169372559,
              0.7608320116996765,
              0.189970001578331,
              0.7605559825897217,
              0.18992400169372559,
              0.7608609795570374,
              0.18996499478816986,
              0.7608320116996765,
              0.189970001578331,
              0.7611140012741089,
              0.19001400470733643,
              0.7611449956893921,
              0.19000999629497528,
              0.7613720297813416,
              0.1900620013475418,
              0.7611449956893921,
              0.19000999629497528,
              0.7614060044288635,
              0.19005699455738068,
              0.7613720297813416,
              0.1900620013475418,
              0.7616069912910461,
              0.19011199474334717,
              0.7616419792175293,
              0.1901089996099472,
              0.7618160247802734,
              0.19016599655151367,
              0.7616419792175293,
              0.1901089996099472,
              0.7618529796600342,
              0.19016200304031372,
              0.7618160247802734,
              0.19016599655151367,
              0.7618160247802734,
              0.19016599655151367,
              0.7618529796600342,
              0.19016200304031372,
              0.7619979977607727,
              0.19022199511528015,
              0.7618529796600342,
              0.19016200304031372,
              0.7620369791984558,
              0.1902189999818802,
              0.7619979977607727,
              0.19022199511528015,
              0.7619979977607727,
              0.19022199511528015,
              0.7620369791984558,
              0.1902189999818802,
              0.7621520161628723,
              0.1902800053358078,
              0.7620369791984558,
              0.1902189999818802,
              0.7621920108795166,
              0.19027799367904663,
              0.7621520161628723,
              0.1902800053358078,
              0.7622770071029663,
              0.19034099578857422,
              0.7623180150985718,
              0.19033899903297424,
              0.7623710036277771,
              0.19040299952030182,
              0.7623180150985718,
              0.19033899903297424,
              0.7624130249023438,
              0.19040200114250183,
              0.7623710036277771,
              0.19040299952030182,
              0.7624329924583435,
              0.19046799838542938,
              0.7624750137329102,
              0.1904670000076294,
              0.7624610066413879,
              0.19053299725055695,
              0.7624750137329102,
              0.1904670000076294,
              0.762503981590271,
              0.19053299725055695,
              0.7624610066413879,
              0.19053299725055695,
              0.7624120116233826,
              0.1906680017709732,
              0.7624549865722656,
              0.1906680017709732,
              0.7623519897460938,
              0.19073499739170074,
              0.7624549865722656,
              0.1906680017709732,
              0.7623940110206604,
              0.19073599576950073,
              0.7623519897460938,
              0.19073499739170074,
              0.7570610046386719,
              0.18976500630378723,
              0.7570610046386719,
              0.18975800275802612,
              0.7574930191040039,
              0.18976500630378723,
              0.7570610046386719,
              0.18975800275802612,
              0.7574939727783203,
              0.18975800275802612,
              0.7574930191040039,
              0.18976500630378723,
              0.7415549755096436,
              0.18976500630378723,
              0.7415549755096436,
              0.18975800275802612,
              0.7570610046386719,
              0.18976500630378723,
              0.7415549755096436,
              0.18975800275802612,
              0.7570610046386719,
              0.18975800275802612,
              0.7570610046386719,
              0.18976500630378723,
              0.7515130043029785,
              0.4553939998149872,
              0.7515559792518616,
              0.4553939998149872,
              0.7514870166778564,
              0.45649099349975586,
              0.7515559792518616,
              0.4553939998149872,
              0.7515299916267395,
              0.45649099349975586,
              0.7514870166778564,
              0.45649099349975586,
              0.7513669729232788,
              0.4614880084991455,
              0.7514100074768066,
              0.4614880084991455,
              0.7513459920883179,
              0.46237900853157043,
              0.7514100074768066,
              0.4614880084991455,
              0.7513890266418457,
              0.46237900853157043,
              0.7513459920883179,
              0.46237900853157043,
              0.7513459920883179,
              0.46237900853157043,
              0.7513890266418457,
              0.46237900853157043,
              0.7513260245323181,
              0.4632300138473511,
              0.7513890266418457,
              0.46237900853157043,
              0.7513689994812012,
              0.4632300138473511,
              0.7513260245323181,
              0.4632300138473511,
              0.7513260245323181,
              0.4632300138473511,
              0.7513689994812012,
              0.4632300138473511,
              0.7513059973716736,
              0.46404001116752625,
              0.7513689994812012,
              0.4632300138473511,
              0.7513489723205566,
              0.46404001116752625,
              0.7513059973716736,
              0.46404001116752625,
              0.7512009739875793,
              0.468423992395401,
              0.7512440085411072,
              0.468423992395401,
              0.7511910200119019,
              0.46884799003601074,
              0.7512440085411072,
              0.468423992395401,
              0.7512339949607849,
              0.46884799003601074,
              0.7511910200119019,
              0.46884799003601074,
              0.7511910200119019,
              0.46884799003601074,
              0.7512339949607849,
              0.46884799003601074,
              0.7511820197105408,
              0.469217985868454,
              0.7512339949607849,
              0.46884799003601074,
              0.7512249946594238,
              0.469217985868454,
              0.7511820197105408,
              0.469217985868454,
              0.751459002494812,
              0.4576680064201355,
              0.7515019774436951,
              0.4576680064201355,
              0.7514960169792175,
              0.45609501004219055,
              0.7515019774436951,
              0.4576680064201355,
              0.7515389919281006,
              0.45609501004219055,
              0.7514960169792175,
              0.45609501004219055,
              0.7513179779052734,
              0.4635300040245056,
              0.7513610124588013,
              0.4635300040245056,
              0.7513520121574402,
              0.46213299036026,
              0.7513610124588013,
              0.4635300040245056,
              0.7513949871063232,
              0.46213299036026,
              0.7513520121574402,
              0.46213299036026,
              0.7510089874267578,
              0.4764479994773865,
              0.7510520219802856,
              0.4764479994773865,
              0.75102299451828,
              0.4758560061454773,
              0.7510520219802856,
              0.4764479994773865,
              0.7510660290718079,
              0.4758560061454773,
              0.75102299451828,
              0.4758560061454773,
              0.750997006893158,
              0.47696399688720703,
              0.751039981842041,
              0.47696399688720703,
              0.7510089874267578,
              0.4764479994773865,
              0.751039981842041,
              0.47696399688720703,
              0.7510520219802856,
              0.4764479994773865,
              0.7510089874267578,
              0.4764479994773865,
              0.7509779930114746,
              0.47775599360466003,
              0.7510210275650024,
              0.47775599360466003,
              0.7509859800338745,
              0.477400004863739,
              0.7510210275650024,
              0.47775599360466003,
              0.7510290145874023,
              0.477400004863739,
              0.7509859800338745,
              0.477400004863739,
              0.7515400052070618,
              0.4542680084705353,
              0.7515829801559448,
              0.4542680084705353,
              0.7515130043029785,
              0.4553939998149872,
              0.7515829801559448,
              0.4542680084705353,
              0.7515559792518616,
              0.4553939998149872,
              0.7515130043029785,
              0.4553939998149872,
              0.7514870166778564,
              0.45649099349975586,
              0.7515299916267395,
              0.45649099349975586,
              0.7514610290527344,
              0.4575580060482025,
              0.7515299916267395,
              0.45649099349975586,
              0.7515040040016174,
              0.4575580060482025,
              0.7514610290527344,
              0.4575580060482025,
              0.7512710094451904,
              0.4655289947986603,
              0.7513129711151123,
              0.4655289947986603,
              0.7512540221214294,
              0.46620601415634155,
              0.7513129711151123,
              0.4655289947986603,
              0.7512969970703125,
              0.46620601415634155,
              0.7512540221214294,
              0.46620601415634155,
              0.751239001750946,
              0.46683499217033386,
              0.7512819766998291,
              0.46683499217033386,
              0.7512249946594238,
              0.467415988445282,
              0.7512819766998291,
              0.46683499217033386,
              0.7512680292129517,
              0.467415988445282,
              0.7512249946594238,
              0.467415988445282,
              0.7511690258979797,
              0.46978598833084106,
              0.7512120008468628,
              0.46978598833084106,
              0.7511630058288574,
              0.47001200914382935,
              0.7512120008468628,
              0.46978598833084106,
              0.7512059807777405,
              0.47001200914382935,
              0.7511630058288574,
              0.47001200914382935,
              0.751285970211029,
              0.46487900614738464,
              0.7513290047645569,
              0.46487900614738464,
              0.7513179779052734,
              0.4635300040245056,
              0.7513290047645569,
              0.46487900614738464,
              0.7513610124588013,
              0.4635300040245056,
              0.7513179779052734,
              0.4635300040245056,
              0.7512249946594238,
              0.46741899847984314,
              0.7512680292129517,
              0.46741899847984314,
              0.7512549757957458,
              0.46617498993873596,
              0.7512680292129517,
              0.46741899847984314,
              0.7512980103492737,
              0.46617498993873596,
              0.7512549757957458,
              0.46617498993873596,
              0.7511969804763794,
              0.46860700845718384,
              0.7512400150299072,
              0.46860700845718384,
              0.7512249946594238,
              0.46741899847984314,
              0.7512400150299072,
              0.46860700845718384,
              0.7512680292129517,
              0.46741899847984314,
              0.7512249946594238,
              0.46741899847984314,
              0.7510970234870911,
              0.47276100516319275,
              0.7511399984359741,
              0.47276100516319275,
              0.7511199712753296,
              0.4718160033226013,
              0.7511399984359741,
              0.47276100516319275,
              0.7511630058288574,
              0.4718160033226013,
              0.7511199712753296,
              0.4718160033226013,
              0.7510570287704468,
              0.4744490087032318,
              0.7511000037193298,
              0.4744490087032318,
              0.7510759830474854,
              0.47363901138305664,
              0.7511000037193298,
              0.4744490087032318,
              0.7511190176010132,
              0.47363901138305664,
              0.7510759830474854,
              0.47363901138305664,
              0.7591300010681152,
              0.1877090036869049,
              0.7591450214385986,
              0.1877090036869049,
              0.758741021156311,
              0.1877090036869049,
              0.7591450214385986,
              0.1877090036869049,
              0.7587509751319885,
              0.1877090036869049,
              0.758741021156311,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7595229744911194,
              0.1877090036869049,
              0.7591300010681152,
              0.1877090036869049,
              0.7595229744911194,
              0.1877090036869049,
              0.7591450214385986,
              0.1877090036869049,
              0.7591300010681152,
              0.1877090036869049,
              0.7602059841156006,
              0.1877090036869049,
              0.7602300047874451,
              0.1877090036869049,
              0.7598649859428406,
              0.1877090036869049,
              0.7602300047874451,
              0.1877090036869049,
              0.7598850131034851,
              0.1877090036869049,
              0.7598649859428406,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624980211257935,
              0.1877090036869049,
              0.7624610066413879,
              0.1877090036869049,
              0.7624980211257935,
              0.1877090036869049,
              0.762503981590271,
              0.1877090036869049,
              0.7624610066413879,
              0.1877090036869049,
              0.7583370208740234,
              0.1877090036869049,
              0.7583439946174622,
              0.1877090036869049,
              0.7579219937324524,
              0.1877090036869049,
              0.7583439946174622,
              0.1877090036869049,
              0.7579249739646912,
              0.1877090036869049,
              0.7579219937324524,
              0.1877090036869049,
              0.7613720297813416,
              0.1877090036869049,
              0.7614060044288635,
              0.1877090036869049,
              0.7611140012741089,
              0.1877090036869049,
              0.7614060044288635,
              0.1877090036869049,
              0.7611449956893921,
              0.1877090036869049,
              0.7611140012741089,
              0.1877090036869049,
              0.7619979977607727,
              0.1877090036869049,
              0.7620369791984558,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7620369791984558,
              0.1877090036869049,
              0.7618529796600342,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7621520161628723,
              0.1877090036869049,
              0.7621920108795166,
              0.1877090036869049,
              0.7619979977607727,
              0.1877090036869049,
              0.7621920108795166,
              0.1877090036869049,
              0.7620369791984558,
              0.1877090036869049,
              0.7619979977607727,
              0.1877090036869049,
              0.7624610066413879,
              0.1877090036869049,
              0.762503981590271,
              0.1877090036869049,
              0.7624329924583435,
              0.1877090036869049,
              0.762503981590271,
              0.1877090036869049,
              0.7624750137329102,
              0.1877090036869049,
              0.7624329924583435,
              0.1877090036869049,
              0.7623519897460938,
              0.1877090036869049,
              0.7623940110206604,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7623940110206604,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7574930191040039,
              0.1877090036869049,
              0.7574939727783203,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7574939727783203,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7574930191040039,
              0.18976500630378723,
              0.7574939727783203,
              0.18975800275802612,
              0.7579219937324524,
              0.1897680014371872,
              0.7574939727783203,
              0.18975800275802612,
              0.7579249739646912,
              0.1897609978914261,
              0.7579219937324524,
              0.1897680014371872,
              0.7579219937324524,
              0.1897680014371872,
              0.7579249739646912,
              0.1897609978914261,
              0.7583370208740234,
              0.1897760033607483,
              0.7579249739646912,
              0.1897609978914261,
              0.7583439946174622,
              0.18976899981498718,
              0.7583370208740234,
              0.1897760033607483,
              0.7583370208740234,
              0.1897760033607483,
              0.7583439946174622,
              0.18976899981498718,
              0.758741021156311,
              0.18978999555110931,
              0.7583439946174622,
              0.18976899981498718,
              0.7587509751319885,
              0.1897830069065094,
              0.758741021156311,
              0.18978999555110931,
              0.7595059871673584,
              0.18983200192451477,
              0.7595229744911194,
              0.18982599675655365,
              0.7598649859428406,
              0.189860001206398,
              0.7595229744911194,
              0.18982599675655365,
              0.7598850131034851,
              0.1898539960384369,
              0.7598649859428406,
              0.189860001206398,
              0.7598649859428406,
              0.189860001206398,
              0.7598850131034851,
              0.1898539960384369,
              0.7602059841156006,
              0.18989300727844238,
              0.7598850131034851,
              0.1898539960384369,
              0.7602300047874451,
              0.18988700211048126,
              0.7602059841156006,
              0.18989300727844238,
              0.7602059841156006,
              0.18989300727844238,
              0.7602300047874451,
              0.18988700211048126,
              0.7605289816856384,
              0.1899300068616867,
              0.7602300047874451,
              0.18988700211048126,
              0.7605559825897217,
              0.18992400169372559,
              0.7605289816856384,
              0.1899300068616867,
              0.7608320116996765,
              0.189970001578331,
              0.7608609795570374,
              0.18996499478816986,
              0.7611140012741089,
              0.19001400470733643,
              0.7608609795570374,
              0.18996499478816986,
              0.7611449956893921,
              0.19000999629497528,
              0.7611140012741089,
              0.19001400470733643,
              0.7613720297813416,
              0.1900620013475418,
              0.7614060044288635,
              0.19005699455738068,
              0.7616069912910461,
              0.19011199474334717,
              0.7614060044288635,
              0.19005699455738068,
              0.7616419792175293,
              0.1901089996099472,
              0.7616069912910461,
              0.19011199474334717,
              0.7621520161628723,
              0.1902800053358078,
              0.7621920108795166,
              0.19027799367904663,
              0.7622770071029663,
              0.19034099578857422,
              0.7621920108795166,
              0.19027799367904663,
              0.7623180150985718,
              0.19033899903297424,
              0.7622770071029663,
              0.19034099578857422,
              0.7623710036277771,
              0.19040299952030182,
              0.7624130249023438,
              0.19040200114250183,
              0.7624329924583435,
              0.19046799838542938,
              0.7624130249023438,
              0.19040200114250183,
              0.7624750137329102,
              0.1904670000076294,
              0.7624329924583435,
              0.19046799838542938,
              0.7624610066413879,
              0.19053299725055695,
              0.762503981590271,
              0.19053299725055695,
              0.7624549865722656,
              0.19059999287128448,
              0.762503981590271,
              0.19053299725055695,
              0.7624980211257935,
              0.19059999287128448,
              0.7624549865722656,
              0.19059999287128448,
              0.7624549865722656,
              0.19059999287128448,
              0.7624980211257935,
              0.19059999287128448,
              0.7624120116233826,
              0.1906680017709732,
              0.7624980211257935,
              0.19059999287128448,
              0.7624549865722656,
              0.1906680017709732,
              0.7624120116233826,
              0.1906680017709732,
              0.7515230178833008,
              0.4588020145893097,
              0.7515259981155396,
              0.45870399475097656,
              0.7515490055084229,
              0.45774298906326294,
              0.7515259981155396,
              0.45870399475097656,
              0.7515509724617004,
              0.45765599608421326,
              0.7515490055084229,
              0.45774298906326294,
              0.7514989972114563,
              0.4598270058631897,
              0.7515010237693787,
              0.4597170054912567,
              0.7515230178833008,
              0.4588020145893097,
              0.7515010237693787,
              0.4597170054912567,
              0.7515259981155396,
              0.45870399475097656,
              0.7515230178833008,
              0.4588020145893097,
              0.7514520287513733,
              0.46176600456237793,
              0.7514550089836121,
              0.4616360068321228,
              0.7514749765396118,
              0.4608150124549866,
              0.7514550089836121,
              0.4616360068321228,
              0.7514780163764954,
              0.4606949985027313,
              0.7514749765396118,
              0.4608150124549866,
              0.7513530254364014,
              0.4659009873867035,
              0.7513570189476013,
              0.4657270014286041,
              0.7513710260391235,
              0.4651620090007782,
              0.7513570189476013,
              0.4657270014286041,
              0.7513750195503235,
              0.46499499678611755,
              0.7513710260391235,
              0.4651620090007782,
              0.751321017742157,
              0.4672349989414215,
              0.7513260245323181,
              0.4670479893684387,
              0.7513369917869568,
              0.466592013835907,
              0.7513260245323181,
              0.4670479893684387,
              0.7513409852981567,
              0.4664109945297241,
              0.7513369917869568,
              0.466592013835907,
              0.7515180110931396,
              0.45903998613357544,
              0.7515159845352173,
              0.45911499857902527,
              0.7514830231666565,
              0.4605039954185486,
              0.7515159845352173,
              0.45911499857902527,
              0.751479983329773,
              0.46059098839759827,
              0.7514830231666565,
              0.4605039954185486,
              0.7513840198516846,
              0.46462199091911316,
              0.751380980014801,
              0.4647420048713684,
              0.7513530254364014,
              0.46589699387550354,
              0.751380980014801,
              0.4647420048713684,
              0.7513499855995178,
              0.46602699160575867,
              0.7513530254364014,
              0.46589699387550354,
              0.7512959837913513,
              0.46828800439834595,
              0.7512919902801514,
              0.46843698620796204,
              0.7512689828872681,
              0.46939900517463684,
              0.7512919902801514,
              0.46843698620796204,
              0.7512660026550293,
              0.469556987285614,
              0.7512689828872681,
              0.46939900517463684,
              0.751075029373169,
              0.47751399874687195,
              0.7510679960250854,
              0.47782400250434875,
              0.7510799765586853,
              0.4773010015487671,
              0.7510679960250854,
              0.47782400250434875,
              0.7510730028152466,
              0.4776090085506439,
              0.7510799765586853,
              0.4773010015487671,
              0.7514749765396118,
              0.4608150124549866,
              0.7514780163764954,
              0.4606949985027313,
              0.7514989972114563,
              0.4598270058631897,
              0.7514780163764954,
              0.4606949985027313,
              0.7515010237693787,
              0.4597170054912567,
              0.7514989972114563,
              0.4598270058631897,
              0.7513710260391235,
              0.4651620090007782,
              0.7513750195503235,
              0.46499499678611755,
              0.7513899803161621,
              0.4643769860267639,
              0.7513750195503235,
              0.46499499678611755,
              0.7513939738273621,
              0.46421900391578674,
              0.7513899803161621,
              0.4643769860267639,
              0.7512940168380737,
              0.4683690071105957,
              0.7512990236282349,
              0.46817100048065186,
              0.7513070106506348,
              0.46782800555229187,
              0.7512990236282349,
              0.46817100048065186,
              0.7513120174407959,
              0.46763500571250916,
              0.7513070106506348,
              0.46782800555229187,
              0.7512819766998291,
              0.46885600686073303,
              0.7512869834899902,
              0.46865400671958923,
              0.7512940168380737,
              0.4683690071105957,
              0.7512869834899902,
              0.46865400671958923,
              0.7512990236282349,
              0.46817100048065186,
              0.7512940168380737,
              0.4683690071105957,
              0.751255989074707,
              0.4699830114841461,
              0.7512609958648682,
              0.46977099776268005,
              0.7512630224227905,
              0.469664990901947,
              0.7512609958648682,
              0.46977099776268005,
              0.7512680292129517,
              0.4694559872150421,
              0.7512630224227905,
              0.469664990901947,
              0.7512490153312683,
              0.4702419936656952,
              0.7512540221214294,
              0.47002899646759033,
              0.751255989074707,
              0.4699830114841461,
              0.7512540221214294,
              0.47002899646759033,
              0.7512609958648682,
              0.46977099776268005,
              0.751255989074707,
              0.4699830114841461,
              0.751554012298584,
              0.45753398537635803,
              0.7515519857406616,
              0.4575969874858856,
              0.7515180110931396,
              0.45903998613357544,
              0.7515519857406616,
              0.4575969874858856,
              0.7515159845352173,
              0.45911499857902527,
              0.7515180110931396,
              0.45903998613357544,
              0.7514830231666565,
              0.4605039954185486,
              0.751479983329773,
              0.46059098839759827,
              0.7514489889144897,
              0.4619239866733551,
              0.751479983329773,
              0.46059098839759827,
              0.751446008682251,
              0.46202200651168823,
              0.7514489889144897,
              0.4619239866733551,
              0.7512689828872681,
              0.46939900517463684,
              0.7512660026550293,
              0.469556987285614,
              0.7512440085411072,
              0.4704520106315613,
              0.7512660026550293,
              0.469556987285614,
              0.7512400150299072,
              0.47061899304389954,
              0.7512440085411072,
              0.4704520106315613,
              0.7512440085411072,
              0.4704520106315613,
              0.7512400150299072,
              0.47061899304389954,
              0.7512199878692627,
              0.4714449942111969,
              0.7512400150299072,
              0.47061899304389954,
              0.7512159943580627,
              0.47161799669265747,
              0.7512199878692627,
              0.4714449942111969,
              0.7511979937553406,
              0.47237399220466614,
              0.7511940002441406,
              0.472555011510849,
              0.7511780261993408,
              0.473239004611969,
              0.7511940002441406,
              0.472555011510849,
              0.7511730194091797,
              0.4734260141849518,
              0.7511780261993408,
              0.473239004611969,
              0.7511579990386963,
              0.4740369915962219,
              0.7511540055274963,
              0.47422999143600464,
              0.7511410117149353,
              0.47476598620414734,
              0.7511540055274963,
              0.47422999143600464,
              0.7511360049247742,
              0.4749639928340912,
              0.7511410117149353,
              0.47476598620414734,
              0.7511410117149353,
              0.47476598620414734,
              0.7511360049247742,
              0.4749639928340912,
              0.7511249780654907,
              0.47542300820350647,
              0.7511360049247742,
              0.4749639928340912,
              0.7511199712753296,
              0.47562599182128906,
              0.7511249780654907,
              0.47542300820350647,
              0.7510989904403687,
              0.476516991853714,
              0.7510939836502075,
              0.4767259955406189,
              0.7510889768600464,
              0.476949006319046,
              0.7510939836502075,
              0.4767259955406189,
              0.75108402967453,
              0.4771600067615509,
              0.7510889768600464,
              0.476949006319046,
              0.7583529949188232,
              0.18816600739955902,
              0.7583529949188232,
              0.18795199692249298,
              0.7587640285491943,
              0.18816600739955902,
              0.7583529949188232,
              0.18795199692249298,
              0.7587640285491943,
              0.18795199692249298,
              0.7587640285491943,
              0.18816600739955902,
              0.7605850100517273,
              0.18816600739955902,
              0.7605850100517273,
              0.18795199692249298,
              0.7608940005302429,
              0.18816600739955902,
              0.7605850100517273,
              0.18795199692249298,
              0.7608940005302429,
              0.18795199692249298,
              0.7608940005302429,
              0.18816600739955902,
              0.7608940005302429,
              0.18816600739955902,
              0.7608940005302429,
              0.18795199692249298,
              0.7611799836158752,
              0.18816600739955902,
              0.7608940005302429,
              0.18795199692249298,
              0.7611799836158752,
              0.18795199692249298,
              0.7611799836158752,
              0.18816600739955902,
              0.7614439725875854,
              0.18816600739955902,
              0.7614439725875854,
              0.18795199692249298,
              0.7616829872131348,
              0.18816600739955902,
              0.7614439725875854,
              0.18795199692249298,
              0.7616829872131348,
              0.18795199692249298,
              0.7616829872131348,
              0.18816600739955902,
              0.7624610066413879,
              0.18816600739955902,
              0.7624610066413879,
              0.18795199692249298,
              0.7625240087509155,
              0.18816600739955902,
              0.7624610066413879,
              0.18795199692249298,
              0.7625240087509155,
              0.18795199692249298,
              0.7625240087509155,
              0.18816600739955902,
              0.7625460028648376,
              0.18816600739955902,
              0.7625460028648376,
              0.18795199692249298,
              0.7625030279159546,
              0.18816600739955902,
              0.7625460028648376,
              0.18795199692249298,
              0.7625030279159546,
              0.18795199692249298,
              0.7625030279159546,
              0.18816600739955902,
              0.7574949860572815,
              0.18816600739955902,
              0.7574949860572815,
              0.18795199692249298,
              0.7579290270805359,
              0.18816600739955902,
              0.7574949860572815,
              0.18795199692249298,
              0.7579290270805359,
              0.18795199692249298,
              0.7579290270805359,
              0.18816600739955902,
              0.7595430016517639,
              0.18816600739955902,
              0.7595430016517639,
              0.18795199692249298,
              0.7599089741706848,
              0.18816600739955902,
              0.7595430016517639,
              0.18795199692249298,
              0.7599089741706848,
              0.18795199692249298,
              0.7599089741706848,
              0.18816600739955902,
              0.7602570056915283,
              0.18816600739955902,
              0.7602570056915283,
              0.18795199692249298,
              0.7605850100517273,
              0.18816600739955902,
              0.7602570056915283,
              0.18795199692249298,
              0.7605850100517273,
              0.18795199692249298,
              0.7605850100517273,
              0.18816600739955902,
              0.7616829872131348,
              0.18816600739955902,
              0.7616829872131348,
              0.18795199692249298,
              0.7618950009346008,
              0.18816600739955902,
              0.7616829872131348,
              0.18795199692249298,
              0.7618950009346008,
              0.18795199692249298,
              0.7618950009346008,
              0.18816600739955902,
              0.7622380256652832,
              0.18816600739955902,
              0.7622380256652832,
              0.18795199692249298,
              0.7623649835586548,
              0.18816600739955902,
              0.7622380256652832,
              0.18795199692249298,
              0.7623649835586548,
              0.18795199692249298,
              0.7623649835586548,
              0.18816600739955902,
              0.7623649835586548,
              0.18816600739955902,
              0.7623649835586548,
              0.18795199692249298,
              0.7624610066413879,
              0.18816600739955902,
              0.7623649835586548,
              0.18795199692249298,
              0.7624610066413879,
              0.18795199692249298,
              0.7624610066413879,
              0.18816600739955902,
              0.7415549755096436,
              0.18816600739955902,
              0.7415549755096436,
              0.18795199692249298,
              0.7570610046386719,
              0.18816600739955902,
              0.7415549755096436,
              0.18795199692249298,
              0.7570610046386719,
              0.18795199692249298,
              0.7570610046386719,
              0.18816600739955902,
              0.7591609954833984,
              0.18933799862861633,
              0.7591609954833984,
              0.18955199420452118,
              0.7587640285491943,
              0.18931899964809418,
              0.7591609954833984,
              0.18955199420452118,
              0.7587640285491943,
              0.18953299522399902,
              0.7587640285491943,
              0.18931899964809418,
              0.7595430016517639,
              0.18936200439929962,
              0.7595430016517639,
              0.18957599997520447,
              0.7591609954833984,
              0.18933799862861633,
              0.7595430016517639,
              0.18957599997520447,
              0.7591609954833984,
              0.18955199420452118,
              0.7591609954833984,
              0.18933799862861633,
              0.7608940005302429,
              0.18950200080871582,
              0.7608940005302429,
              0.18971599638462067,
              0.7605850100517273,
              0.18946099281311035,
              0.7608940005302429,
              0.18971599638462067,
              0.7605850100517273,
              0.1896750032901764,
              0.7605850100517273,
              0.18946099281311035,
              0.7614439725875854,
              0.1895959973335266,
              0.7614439725875854,
              0.18980999290943146,
              0.7611799836158752,
              0.18954700231552124,
              0.7614439725875854,
              0.18980999290943146,
              0.7611799836158752,
              0.1897609978914261,
              0.7611799836158752,
              0.18954700231552124,
              0.7618950009346008,
              0.18970200419425964,
              0.7618950009346008,
              0.1899159997701645,
              0.7616829872131348,
              0.18964700400829315,
              0.7618950009346008,
              0.1899159997701645,
              0.7616829872131348,
              0.189860999584198,
              0.7616829872131348,
              0.18964700400829315,
              0.7620810270309448,
              0.1897590011358261,
              0.7620810270309448,
              0.18997299671173096,
              0.7618950009346008,
              0.18970200419425964,
              0.7620810270309448,
              0.18997299671173096,
              0.7618950009346008,
              0.1899159997701645,
              0.7618950009346008,
              0.18970200419425964,
              0.7622380256652832,
              0.18981799483299255,
              0.7622380256652832,
              0.1900320053100586,
              0.7620810270309448,
              0.1897590011358261,
              0.7622380256652832,
              0.1900320053100586,
              0.7620810270309448,
              0.18997299671173096,
              0.7620810270309448,
              0.1897590011358261,
              0.7624610066413879,
              0.18994399905204773,
              0.7624610066413879,
              0.1901569962501526,
              0.7623649835586548,
              0.18987999856472015,
              0.7624610066413879,
              0.1901569962501526,
              0.7623649835586548,
              0.190093994140625,
              0.7623649835586548,
              0.18987999856472015,
              0.7625529766082764,
              0.19007599353790283,
              0.7625529766082764,
              0.19029000401496887,
              0.7625240087509155,
              0.1900089979171753,
              0.7625529766082764,
              0.19029000401496887,
              0.7625240087509155,
              0.19022299349308014,
              0.7625240087509155,
              0.1900089979171753,
              0.7624419927597046,
              0.1902800053358078,
              0.7624419927597046,
              0.19049400091171265,
              0.7625030279159546,
              0.19021199643611908,
              0.7624419927597046,
              0.19049400091171265,
              0.7625030279159546,
              0.19042600691318512,
              0.7625030279159546,
              0.19021199643611908,
              0.7574949860572815,
              0.1892929971218109,
              0.7574949860572815,
              0.18950699269771576,
              0.7570610046386719,
              0.1892929971218109,
              0.7574949860572815,
              0.18950699269771576,
              0.7570610046386719,
              0.18950699269771576,
              0.7570610046386719,
              0.1892929971218109,
              0.7570610046386719,
              0.1892929971218109,
              0.7570610046386719,
              0.18950699269771576,
              0.7415549755096436,
              0.1892929971218109,
              0.7570610046386719,
              0.18950699269771576,
              0.7415549755096436,
              0.18950699269771576,
              0.7415549755096436,
              0.1892929971218109,
              0.758741021156311,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7591300010681152,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7591300010681152,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7598649859428406,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7602059841156006,
              0.1877090036869049,
              0.7598649859428406,
              0.1877090036869049,
              0.7602059841156006,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7605289816856384,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7611140012741089,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7613720297813416,
              0.1877090036869049,
              0.7611140012741089,
              0.1877090036869049,
              0.7613720297813416,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7616069912910461,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7619979977607727,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7621520161628723,
              0.1877090036869049,
              0.7619979977607727,
              0.1877090036869049,
              0.7621520161628723,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7622770071029663,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7618160247802734,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7624329924583435,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7624610066413879,
              0.1877090036869049,
              0.7624329924583435,
              0.1877090036869049,
              0.7624610066413879,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7624549865722656,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7623519897460938,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7623519897460938,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7415549755096436,
              0.1877090036869049,
              0.7623710036277771,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7624120116233826,
              0.1877090036869049,
              0.7595059871673584,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.7608320116996765,
              0.1877090036869049,
              0.7570610046386719,
              0.1877090036869049,
              0.758741021156311,
              0.1877090036869049,
              0.7574930191040039,
              0.1877090036869049,
              0.758741021156311,
              0.1877090036869049,
              0.7579219937324524,
              0.1877090036869049,
              0.7574930191040039,
              0.1877090036869049,
              0.7583370208740234,
              0.1877090036869049,
              0.7579219937324524,
              0.1877090036869049,
              0.758741021156311,
              0.1877090036869049,
              0.758741021156311,
              0.18978999555110931,
              0.7602059841156006,
              0.18989300727844238,
              0.7583370208740234,
              0.1897760033607483,
              0.7602059841156006,
              0.18989300727844238,
              0.7579219937324524,
              0.1897680014371872,
              0.7583370208740234,
              0.1897760033607483,
              0.7579219937324524,
              0.1897680014371872,
              0.7415549755096436,
              0.19073499739170074,
              0.7574930191040039,
              0.18976500630378723,
              0.7415549755096436,
              0.19073499739170074,
              0.7570610046386719,
              0.18976500630378723,
              0.7574930191040039,
              0.18976500630378723,
              0.7570610046386719,
              0.18976500630378723,
              0.7415549755096436,
              0.19073499739170074,
              0.7415549755096436,
              0.18976500630378723,
              0.7415549755096436,
              0.19073499739170074,
              0.7624610066413879,
              0.19053299725055695,
              0.7623519897460938,
              0.19073499739170074,
              0.7624610066413879,
              0.19053299725055695,
              0.7624120116233826,
              0.1906680017709732,
              0.7623519897460938,
              0.19073499739170074,
              0.7624120116233826,
              0.1906680017709732,
              0.7624610066413879,
              0.19053299725055695,
              0.7624549865722656,
              0.19059999287128448,
              0.7624610066413879,
              0.19053299725055695,
              0.7621520161628723,
              0.1902800053358078,
              0.7624329924583435,
              0.19046799838542938,
              0.7621520161628723,
              0.1902800053358078,
              0.7623710036277771,
              0.19040299952030182,
              0.7624329924583435,
              0.19046799838542938,
              0.7623710036277771,
              0.19040299952030182,
              0.7621520161628723,
              0.1902800053358078,
              0.7622770071029663,
              0.19034099578857422,
              0.7415549755096436,
              0.19073499739170074,
              0.7579219937324524,
              0.1897680014371872,
              0.7624610066413879,
              0.19053299725055695,
              0.7579219937324524,
              0.1897680014371872,
              0.7621520161628723,
              0.1902800053358078,
              0.7624610066413879,
              0.19053299725055695,
              0.7621520161628723,
              0.1902800053358078,
              0.7613720297813416,
              0.1900620013475418,
              0.7619979977607727,
              0.19022199511528015,
              0.7613720297813416,
              0.1900620013475418,
              0.7618160247802734,
              0.19016599655151367,
              0.7619979977607727,
              0.19022199511528015,
              0.7618160247802734,
              0.19016599655151367,
              0.7613720297813416,
              0.1900620013475418,
              0.7616069912910461,
              0.19011199474334717,
              0.7613720297813416,
              0.1900620013475418,
              0.7602059841156006,
              0.18989300727844238,
              0.7611140012741089,
              0.19001400470733643,
              0.7602059841156006,
              0.18989300727844238,
              0.7608320116996765,
              0.189970001578331,
              0.7611140012741089,
              0.19001400470733643,
              0.7608320116996765,
              0.189970001578331,
              0.7602059841156006,
              0.18989300727844238,
              0.7605289816856384,
              0.1899300068616867,
              0.7621520161628723,
              0.1902800053358078,
              0.7579219937324524,
              0.1897680014371872,
              0.7613720297813416,
              0.1900620013475418,
              0.7579219937324524,
              0.1897680014371872,
              0.7602059841156006,
              0.18989300727844238,
              0.7613720297813416,
              0.1900620013475418,
              0.7602059841156006,
              0.18989300727844238,
              0.758741021156311,
              0.18978999555110931,
              0.7598649859428406,
              0.189860001206398,
              0.758741021156311,
              0.18978999555110931,
              0.7595059871673584,
              0.18983200192451477,
              0.7598649859428406,
              0.189860001206398,
              0.7591300010681152,
              0.18980899453163147,
              0.7595059871673584,
              0.18983200192451477,
              0.758741021156311,
              0.18978999555110931,
              0.7511690258979797,
              0.46978598833084106,
              0.7511630058288574,
              0.47001200914382935,
              0.7415549755096436,
              0.46978598833084106,
              0.7511630058288574,
              0.47001200914382935,
              0.7415549755096436,
              0.47001200914382935,
              0.7415549755096436,
              0.46978598833084106,
              0.7514359951019287,
              0.4585919976234436,
              0.7415549755096436,
              0.4585919976234436,
              0.7514610290527344,
              0.4575580060482025,
              0.7415549755096436,
              0.4585919976234436,
              0.7415549755096436,
              0.4575580060482025,
              0.7514610290527344,
              0.4575580060482025,
              0.7514129877090454,
              0.4595929980278015,
              0.7415549755096436,
              0.4595929980278015,
              0.7514359951019287,
              0.4585919976234436,
              0.7415549755096436,
              0.4595929980278015,
              0.7415549755096436,
              0.4585919976234436,
              0.7514359951019287,
              0.4585919976234436,
              0.7513669729232788,
              0.4614880084991455,
              0.7415549755096436,
              0.4614880084991455,
              0.7513899803161621,
              0.46055901050567627,
              0.7415549755096436,
              0.4614880084991455,
              0.7415549755096436,
              0.46055901050567627,
              0.7513899803161621,
              0.46055901050567627,
              0.7512710094451904,
              0.4655289947986603,
              0.7415549755096436,
              0.4655289947986603,
              0.7512879967689514,
              0.4648059904575348,
              0.7415549755096436,
              0.4655289947986603,
              0.7415549755096436,
              0.4648059904575348,
              0.7512879967689514,
              0.4648059904575348,
              0.751239001750946,
              0.46683499217033386,
              0.7415549755096436,
              0.46683499217033386,
              0.7512540221214294,
              0.46620601415634155,
              0.7415549755096436,
              0.46683499217033386,
              0.7415549755096436,
              0.46620601415634155,
              0.7512540221214294,
              0.46620601415634155,
              0.7513899803161621,
              0.46055901050567627,
              0.7415549755096436,
              0.46055901050567627,
              0.7514129877090454,
              0.4595929980278015,
              0.7415549755096436,
              0.46055901050567627,
              0.7415549755096436,
              0.4595929980278015,
              0.7514129877090454,
              0.4595929980278015,
              0.7512879967689514,
              0.4648059904575348,
              0.7415549755096436,
              0.4648059904575348,
              0.7513059973716736,
              0.46404001116752625,
              0.7415549755096436,
              0.4648059904575348,
              0.7415549755096436,
              0.46404001116752625,
              0.7513059973716736,
              0.46404001116752625,
              0.751213014125824,
              0.46794599294662476,
              0.7415549755096436,
              0.46794599294662476,
              0.7512249946594238,
              0.467415988445282,
              0.7415549755096436,
              0.46794599294662476,
              0.7415549755096436,
              0.467415988445282,
              0.7512249946594238,
              0.467415988445282,
              0.7512009739875793,
              0.468423992395401,
              0.7415549755096436,
              0.468423992395401,
              0.751213014125824,
              0.46794599294662476,
              0.7415549755096436,
              0.468423992395401,
              0.7415549755096436,
              0.46794599294662476,
              0.751213014125824,
              0.46794599294662476,
              0.7511749863624573,
              0.46953099966049194,
              0.7415549755096436,
              0.46953099966049194,
              0.7511820197105408,
              0.469217985868454,
              0.7415549755096436,
              0.46953099966049194,
              0.7415549755096436,
              0.469217985868454,
              0.7511820197105408,
              0.469217985868454,
              0.7511690258979797,
              0.46978598833084106,
              0.7415549755096436,
              0.46978598833084106,
              0.7511749863624573,
              0.46953099966049194,
              0.7415549755096436,
              0.46978598833084106,
              0.7415549755096436,
              0.46953099966049194,
              0.7511749863624573,
              0.46953099966049194,
              0.7514870166778564,
              0.45649099349975586,
              0.7415549755096436,
              0.45649099349975586,
              0.7515130043029785,
              0.4553939998149872,
              0.7415549755096436,
              0.45649099349975586,
              0.7415549755096436,
              0.4553939998149872,
              0.7515130043029785,
              0.4553939998149872,
              0.7513459920883179,
              0.46237900853157043,
              0.7415549755096436,
              0.46237900853157043,
              0.7513669729232788,
              0.4614880084991455,
              0.7415549755096436,
              0.46237900853157043,
              0.7415549755096436,
              0.4614880084991455,
              0.7513669729232788,
              0.4614880084991455,
              0.7513260245323181,
              0.4632300138473511,
              0.7415549755096436,
              0.4632300138473511,
              0.7513459920883179,
              0.46237900853157043,
              0.7415549755096436,
              0.4632300138473511,
              0.7415549755096436,
              0.46237900853157043,
              0.7513459920883179,
              0.46237900853157043,
              0.7513059973716736,
              0.46404001116752625,
              0.7415549755096436,
              0.46404001116752625,
              0.7513260245323181,
              0.4632300138473511,
              0.7415549755096436,
              0.46404001116752625,
              0.7415549755096436,
              0.4632300138473511,
              0.7513260245323181,
              0.4632300138473511,
              0.7511910200119019,
              0.46884799003601074,
              0.7415549755096436,
              0.46884799003601074,
              0.7512009739875793,
              0.468423992395401,
              0.7415549755096436,
              0.46884799003601074,
              0.7415549755096436,
              0.468423992395401,
              0.7512009739875793,
              0.468423992395401,
              0.7511820197105408,
              0.469217985868454,
              0.7415549755096436,
              0.469217985868454,
              0.7511910200119019,
              0.46884799003601074,
              0.7415549755096436,
              0.469217985868454,
              0.7415549755096436,
              0.46884799003601074,
              0.7511910200119019,
              0.46884799003601074,
              0.7515130043029785,
              0.4553939998149872,
              0.7415549755096436,
              0.4553939998149872,
              0.7515400052070618,
              0.4542680084705353,
              0.7415549755096436,
              0.4553939998149872,
              0.7415549755096436,
              0.4542680084705353,
              0.7515400052070618,
              0.4542680084705353,
              0.7514610290527344,
              0.4575580060482025,
              0.7415549755096436,
              0.4575580060482025,
              0.7514870166778564,
              0.45649099349975586,
              0.7415549755096436,
              0.4575580060482025,
              0.7415549755096436,
              0.45649099349975586,
              0.7514870166778564,
              0.45649099349975586,
              0.7512540221214294,
              0.46620601415634155,
              0.7415549755096436,
              0.46620601415634155,
              0.7512710094451904,
              0.4655289947986603,
              0.7415549755096436,
              0.46620601415634155,
              0.7415549755096436,
              0.4655289947986603,
              0.7512710094451904,
              0.4655289947986603,
              0.7512249946594238,
              0.467415988445282,
              0.7415549755096436,
              0.467415988445282,
              0.751239001750946,
              0.46683499217033386,
              0.7415549755096436,
              0.467415988445282,
              0.7415549755096436,
              0.46683499217033386,
              0.751239001750946,
              0.46683499217033386,
              0.7514219880104065,
              0.459199994802475,
              0.7415549755096436,
              0.459199994802475,
              0.7513859868049622,
              0.4606890082359314,
              0.7415549755096436,
              0.459199994802475,
              0.7415549755096436,
              0.4606890082359314,
              0.7513859868049622,
              0.4606890082359314,
              0.751285970211029,
              0.46487900614738464,
              0.7415549755096436,
              0.46487799286842346,
              0.7512549757957458,
              0.46617498993873596,
              0.7415549755096436,
              0.46487799286842346,
              0.7415549755096436,
              0.46617498993873596,
              0.7512549757957458,
              0.46617498993873596,
              0.7511969804763794,
              0.46860700845718384,
              0.7415549755096436,
              0.46860700845718384,
              0.7511699795722961,
              0.4697369933128357,
              0.7415549755096436,
              0.46860700845718384,
              0.7415549755096436,
              0.4697369933128357,
              0.7511699795722961,
              0.4697369933128357,
              0.7509260177612305,
              0.4799160063266754,
              0.7509700059890747,
              0.478069007396698,
              0.7415549755096436,
              0.4799160063266754,
              0.7509700059890747,
              0.478069007396698,
              0.7415549755096436,
              0.478069007396698,
              0.7415549755096436,
              0.4799160063266754,
              0.751459002494812,
              0.4576680064201355,
              0.7415549755096436,
              0.4576680064201355,
              0.7514219880104065,
              0.459199994802475,
              0.7415549755096436,
              0.4576680064201355,
              0.7415549755096436,
              0.459199994802475,
              0.7514219880104065,
              0.459199994802475,
              0.7513859868049622,
              0.4606890082359314,
              0.7415549755096436,
              0.4606890082359314,
              0.7513520121574402,
              0.46213299036026,
              0.7415549755096436,
              0.4606890082359314,
              0.7415549755096436,
              0.46213299036026,
              0.7513520121574402,
              0.46213299036026,
              0.7511699795722961,
              0.4697369933128357,
              0.7415549755096436,
              0.4697369933128357,
              0.7511439919471741,
              0.4708069860935211,
              0.7415549755096436,
              0.4697369933128357,
              0.7415549755096436,
              0.4708069860935211,
              0.7511439919471741,
              0.4708069860935211,
              0.7511439919471741,
              0.4708069860935211,
              0.7415549755096436,
              0.4708069860935211,
              0.7511199712753296,
              0.4718160033226013,
              0.7415549755096436,
              0.4708069860935211,
              0.7415549755096436,
              0.4718160033226013,
              0.7511199712753296,
              0.4718160033226013,
              0.7510970234870911,
              0.47276100516319275,
              0.7415549755096436,
              0.47275999188423157,
              0.7510759830474854,
              0.47363901138305664,
              0.7415549755096436,
              0.47275999188423157,
              0.7415549755096436,
              0.47363901138305664,
              0.7510759830474854,
              0.47363901138305664,
              0.7510570287704468,
              0.4744490087032318,
              0.7415549755096436,
              0.4744490087032318,
              0.7510390281677246,
              0.4751890003681183,
              0.7415549755096436,
              0.4744490087032318,
              0.7415549755096436,
              0.4751890003681183,
              0.7510390281677246,
              0.4751890003681183,
              0.7510390281677246,
              0.4751890003681183,
              0.7415549755096436,
              0.4751890003681183,
              0.75102299451828,
              0.4758560061454773,
              0.7415549755096436,
              0.4751890003681183,
              0.7415549755096436,
              0.4758560061454773,
              0.75102299451828,
              0.4758560061454773,
              0.750997006893158,
              0.47696399688720703,
              0.7415549755096436,
              0.47696399688720703,
              0.7509859800338745,
              0.477400004863739,
              0.7415549755096436,
              0.47696399688720703,
              0.7415549755096436,
              0.477400004863739,
              0.7509859800338745,
              0.477400004863739,
              0.7514960169792175,
              0.45609501004219055,
              0.7415549755096436,
              0.45609501004219055,
              0.751459002494812,
              0.4576680064201355,
              0.7415549755096436,
              0.45609501004219055,
              0.7415549755096436,
              0.4576680064201355,
              0.751459002494812,
              0.4576680064201355,
              0.7513520121574402,
              0.46213299036026,
              0.7415549755096436,
              0.46213299036026,
              0.7513179779052734,
              0.4635300040245056,
              0.7415549755096436,
              0.46213299036026,
              0.7415549755096436,
              0.4635300040245056,
              0.7513179779052734,
              0.4635300040245056,
              0.75102299451828,
              0.4758560061454773,
              0.7415549755096436,
              0.4758560061454773,
              0.7510089874267578,
              0.4764479994773865,
              0.7415549755096436,
              0.4758560061454773,
              0.7415549755096436,
              0.4764479994773865,
              0.7510089874267578,
              0.4764479994773865,
              0.7510089874267578,
              0.4764479994773865,
              0.7415549755096436,
              0.4764479994773865,
              0.750997006893158,
              0.47696399688720703,
              0.7415549755096436,
              0.4764479994773865,
              0.7415549755096436,
              0.47696399688720703,
              0.750997006893158,
              0.47696399688720703,
              0.7509859800338745,
              0.477400004863739,
              0.7415549755096436,
              0.477400004863739,
              0.7509779930114746,
              0.47775599360466003,
              0.7415549755096436,
              0.477400004863739,
              0.7415549755096436,
              0.47775599360466003,
              0.7509779930114746,
              0.47775599360466003,
              0.7513179779052734,
              0.4635300040245056,
              0.7415549755096436,
              0.4635300040245056,
              0.751285970211029,
              0.46487900614738464,
              0.7415549755096436,
              0.4635300040245056,
              0.7415549755096436,
              0.46487799286842346,
              0.751285970211029,
              0.46487900614738464,
              0.7512549757957458,
              0.46617498993873596,
              0.7415549755096436,
              0.46617498993873596,
              0.7512249946594238,
              0.46741899847984314,
              0.7415549755096436,
              0.46617498993873596,
              0.7415549755096436,
              0.46741899847984314,
              0.7512249946594238,
              0.46741899847984314,
              0.7512249946594238,
              0.46741899847984314,
              0.7415549755096436,
              0.46741899847984314,
              0.7511969804763794,
              0.46860700845718384,
              0.7415549755096436,
              0.46741899847984314,
              0.7415549755096436,
              0.46860700845718384,
              0.7511969804763794,
              0.46860700845718384,
              0.7511199712753296,
              0.4718160033226013,
              0.7415549755096436,
              0.4718160033226013,
              0.7510970234870911,
              0.47276100516319275,
              0.7415549755096436,
              0.4718160033226013,
              0.7415549755096436,
              0.47275999188423157,
              0.7510970234870911,
              0.47276100516319275,
              0.7510759830474854,
              0.47363901138305664,
              0.7415549755096436,
              0.47363901138305664,
              0.7510570287704468,
              0.4744490087032318,
              0.7415549755096436,
              0.47363901138305664,
              0.7415549755096436,
              0.4744490087032318,
              0.7510570287704468,
              0.4744490087032318,
              0.753233015537262,
              0.3853900134563446,
              0.7531899809837341,
              0.3853900134563446,
              0.7515770196914673,
              0.4545019865036011,
              0.7531899809837341,
              0.3853900134563446,
              0.7515339851379395,
              0.4545019865036011,
              0.7515770196914673,
              0.4545019865036011,
              0.7531899809837341,
              0.3853900134563446,
              0.7415549755096436,
              0.3853900134563446,
              0.7515339851379395,
              0.4545019865036011,
              0.7415549755096436,
              0.3853900134563446,
              0.7415549755096436,
              0.4545019865036011,
              0.7515339851379395,
              0.4545019865036011,
              0.7515760064125061,
              0.45608100295066833,
              0.7515900135040283,
              0.4560379981994629,
              0.751613974571228,
              0.45449098944664,
              0.7515900135040283,
              0.4560379981994629,
              0.7516270279884338,
              0.454459011554718,
              0.751613974571228,
              0.45449098944664,
              0.7516189813613892,
              0.4542819857597351,
              0.7515829801559448,
              0.4542680084705353,
              0.7516469955444336,
              0.4531320035457611,
              0.7515829801559448,
              0.4542680084705353,
              0.7516109943389893,
              0.45312100648880005,
              0.7516469955444336,
              0.4531320035457611,
              0.7515770196914673,
              0.4545019865036011,
              0.7515389919281006,
              0.45609501004219055,
              0.751613974571228,
              0.45449098944664,
              0.7515389919281006,
              0.45609501004219055,
              0.7515760064125061,
              0.45608100295066833,
              0.751613974571228,
              0.45449098944664,
              0.7516310214996338,
              0.45432499051094055,
              0.7516189813613892,
              0.4542819857597351,
              0.751658022403717,
              0.4531640112400055,
              0.7516189813613892,
              0.4542819857597351,
              0.7516469955444336,
              0.4531320035457611,
              0.751658022403717,
              0.4531640112400055,
              0.7515910267829895,
              0.45598798990249634,
              0.7516289949417114,
              0.4543749988079071,
              0.7516279816627502,
              0.4544210135936737,
              0.7516289949417114,
              0.4543749988079071,
              0.7516570091247559,
              0.4532020092010498,
              0.7516279816627502,
              0.4544210135936737,
              0.7515900135040283,
              0.4560379981994629,
              0.7515910267829895,
              0.45598798990249634,
              0.7516270279884338,
              0.454459011554718,
              0.7515910267829895,
              0.45598798990249634,
              0.7516279816627502,
              0.4544210135936737,
              0.7516270279884338,
              0.454459011554718,
              0.7515829801559448,
              0.4542680084705353,
              0.7515400052070618,
              0.4542680084705353,
              0.7516109943389893,
              0.45312100648880005,
              0.7515400052070618,
              0.4542680084705353,
              0.751567006111145,
              0.45312100648880005,
              0.7516109943389893,
              0.45312100648880005,
              0.7515400052070618,
              0.4542680084705353,
              0.7415549755096436,
              0.4542680084705353,
              0.751567006111145,
              0.45312100648880005,
              0.7415549755096436,
              0.4542680084705353,
              0.7415549755096436,
              0.45312100648880005,
              0.751567006111145,
              0.45312100648880005,
              0.7512410283088684,
              0.47007301449775696,
              0.751246988773346,
              0.4698469936847687,
              0.7512490153312683,
              0.4702570140361786,
              0.751246988773346,
              0.4698469936847687,
              0.7512540221214294,
              0.47002899646759033,
              0.7512490153312683,
              0.4702570140361786,
              0.7510510087013245,
              0.4780080020427704,
              0.7510589957237244,
              0.4776949882507324,
              0.7510129809379578,
              0.478069007396698,
              0.7510589957237244,
              0.4776949882507324,
              0.7510210275650024,
              0.47775599360466003,
              0.7510129809379578,
              0.478069007396698,
              0.7512059807777405,
              0.47001200914382935,
              0.7512120008468628,
              0.46978598833084106,
              0.7512410283088684,
              0.47007301449775696,
              0.7512120008468628,
              0.46978598833084106,
              0.751246988773346,
              0.4698469936847687,
              0.7512410283088684,
              0.47007301449775696,
              0.7510679960250854,
              0.47782400250434875,
              0.751075029373169,
              0.47751399874687195,
              0.7510510087013245,
              0.4780080020427704,
              0.751075029373169,
              0.47751399874687195,
              0.7510589957237244,
              0.4776949882507324,
              0.7510510087013245,
              0.4780080020427704,
              0.7510730028152466,
              0.4776090085506439,
              0.7510280013084412,
              0.47947001457214355,
              0.7512440085411072,
              0.4704720079898834,
              0.7510280013084412,
              0.47947001457214355,
              0.7511940002441406,
              0.4725480079650879,
              0.7512440085411072,
              0.4704720079898834,
              0.7512490153312683,
              0.4702570140361786,
              0.7512540221214294,
              0.47002899646759033,
              0.7512440085411072,
              0.4704720079898834,
              0.7512540221214294,
              0.47002899646759033,
              0.7512490153312683,
              0.4702419936656952,
              0.7512440085411072,
              0.4704720079898834,
              0.7510129809379578,
              0.478069007396698,
              0.7510210275650024,
              0.47775599360466003,
              0.7509700059890747,
              0.478069007396698,
              0.7510210275650024,
              0.47775599360466003,
              0.7509779930114746,
              0.47775599360466003,
              0.7509700059890747,
              0.478069007396698,
              0.7511559724807739,
              0.47210100293159485,
              0.7511129975318909,
              0.47210100293159485,
              0.7512059807777405,
              0.47001200914382935,
              0.7511129975318909,
              0.47210100293159485,
              0.7511630058288574,
              0.47001200914382935,
              0.7512059807777405,
              0.47001200914382935,
              0.75102299451828,
              0.4796789884567261,
              0.7510280013084412,
              0.47947001457214355,
              0.7510679960250854,
              0.47782400250434875,
              0.7510280013084412,
              0.47947001457214355,
              0.7510730028152466,
              0.4776090085506439,
              0.7510679960250854,
              0.47782400250434875,
              0.7511129975318909,
              0.47210100293159485,
              0.7415549755096436,
              0.47210100293159485,
              0.7511630058288574,
              0.47001200914382935,
              0.7415549755096436,
              0.47210100293159485,
              0.7415549755096436,
              0.47001200914382935,
              0.7511630058288574,
              0.47001200914382935,
              0.7509779930114746,
              0.47775599360466003,
              0.7415549755096436,
              0.47775599360466003,
              0.7509700059890747,
              0.478069007396698,
              0.7415549755096436,
              0.47775599360466003,
              0.7415549755096436,
              0.478069007396698,
              0.7509700059890747,
              0.478069007396698,
              0.7516270279884338,
              0.454459011554718,
              0.7532839775085449,
              0.38531699776649475,
              0.751613974571228,
              0.45449098944664,
              0.7532839775085449,
              0.38531699776649475,
              0.7532699704170227,
              0.38537099957466125,
              0.751613974571228,
              0.45449098944664,
              0.7516109943389893,
              0.45312100648880005,
              0.7532899975776672,
              0.3830209970474243,
              0.7516469955444336,
              0.4531320035457611,
              0.7532899975776672,
              0.3830209970474243,
              0.7533259987831116,
              0.3830389976501465,
              0.7516469955444336,
              0.4531320035457611,
              0.7515770196914673,
              0.4545019865036011,
              0.751613974571228,
              0.45449098944664,
              0.753233015537262,
              0.3853900134563446,
              0.751613974571228,
              0.45449098944664,
              0.7532699704170227,
              0.38537099957466125,
              0.753233015537262,
              0.3853900134563446,
              0.753337025642395,
              0.38309401273727417,
              0.7533349990844727,
              0.38315799832344055,
              0.751658022403717,
              0.4531640112400055,
              0.7533349990844727,
              0.38315799832344055,
              0.7516570091247559,
              0.4532020092010498,
              0.751658022403717,
              0.4531640112400055,
              0.7415549755096436,
              0.3830209970474243,
              0.7532470226287842,
              0.3830209970474243,
              0.7415549755096436,
              0.45312100648880005,
              0.7532470226287842,
              0.3830209970474243,
              0.751567006111145,
              0.45312100648880005,
              0.7415549755096436,
              0.45312100648880005,
              0.751658022403717,
              0.4531640112400055,
              0.7516469955444336,
              0.4531320035457611,
              0.753337025642395,
              0.38309401273727417,
              0.7516469955444336,
              0.4531320035457611,
              0.7533259987831116,
              0.3830389976501465,
              0.753337025642395,
              0.38309401273727417,
              0.7516279816627502,
              0.4544210135936737,
              0.7516570091247559,
              0.4532020092010498,
              0.7532849907875061,
              0.3852519989013672,
              0.7516570091247559,
              0.4532020092010498,
              0.7533349990844727,
              0.38315799832344055,
              0.7532849907875061,
              0.3852519989013672,
              0.7532839775085449,
              0.38531699776649475,
              0.7516270279884338,
              0.454459011554718,
              0.7532849907875061,
              0.3852519989013672,
              0.7516270279884338,
              0.454459011554718,
              0.7516279816627502,
              0.4544210135936737,
              0.7532849907875061,
              0.3852519989013672,
              0.7516109943389893,
              0.45312100648880005,
              0.751567006111145,
              0.45312100648880005,
              0.7532899975776672,
              0.3830209970474243,
              0.751567006111145,
              0.45312100648880005,
              0.7532470226287842,
              0.3830209970474243,
              0.7532899975776672,
              0.3830209970474243,
              0.7270039916038513,
              0.26547300815582275,
              0.726967990398407,
              0.2654919922351837,
              0.7265530228614807,
              0.2466219961643219,
              0.726967990398407,
              0.2654919922351837,
              0.7265160083770752,
              0.2466460019350052,
              0.7265530228614807,
              0.2466219961643219,
              0.7265530228614807,
              0.2466219961643219,
              0.7265160083770752,
              0.2466460019350052,
              0.7261009812355042,
              0.22777099907398224,
              0.7265160083770752,
              0.2466460019350052,
              0.7260649800300598,
              0.22779999673366547,
              0.7261009812355042,
              0.22777099907398224,
              0.726967990398407,
              0.2654919922351837,
              0.7269570231437683,
              0.2655459940433502,
              0.7265160083770752,
              0.2466460019350052,
              0.7269570231437683,
              0.2655459940433502,
              0.7265059947967529,
              0.2467159926891327,
              0.7265160083770752,
              0.2466460019350052,
              0.7265160083770752,
              0.2466460019350052,
              0.7265059947967529,
              0.2467159926891327,
              0.7260649800300598,
              0.22779999673366547,
              0.7265059947967529,
              0.2467159926891327,
              0.7260550260543823,
              0.22788499295711517,
              0.7260649800300598,
              0.22779999673366547,
              0.7261360287666321,
              0.2312619984149933,
              0.7260569930076599,
              0.2279839962720871,
              0.7265719771385193,
              0.2494809925556183,
              0.7260569930076599,
              0.2279839962720871,
              0.7265080213546753,
              0.24679699540138245,
              0.7265719771385193,
              0.2494809925556183,
              0.7265719771385193,
              0.2494809925556183,
              0.7265080213546753,
              0.24679699540138245,
              0.7270079851150513,
              0.2676999866962433,
              0.7265080213546753,
              0.24679699540138245,
              0.7269579768180847,
              0.265610009431839,
              0.7270079851150513,
              0.2676999866962433,
              0.7270079851150513,
              0.2676999866962433,
              0.7270100116729736,
              0.2677629888057709,
              0.7265719771385193,
              0.2494809925556183,
              0.7270100116729736,
              0.2677629888057709,
              0.7265740036964417,
              0.24956199526786804,
              0.7265719771385193,
              0.2494809925556183,
              0.7265719771385193,
              0.2494809925556183,
              0.7265740036964417,
              0.24956199526786804,
              0.7261360287666321,
              0.2312619984149933,
              0.7265740036964417,
              0.24956199526786804,
              0.7261379957199097,
              0.2313610017299652,
              0.7261360287666321,
              0.2312619984149933,
              0.7415549755096436,
              0.26547300815582275,
              0.7270470261573792,
              0.26547300815582275,
              0.7415549755096436,
              0.2466219961643219,
              0.7270470261573792,
              0.26547300815582275,
              0.7265959978103638,
              0.2466219961643219,
              0.7415549755096436,
              0.2466219961643219,
              0.7415549755096436,
              0.2466219961643219,
              0.7265959978103638,
              0.2466219961643219,
              0.7415549755096436,
              0.22777099907398224,
              0.7265959978103638,
              0.2466219961643219,
              0.726144015789032,
              0.22777099907398224,
              0.7415549755096436,
              0.22777099907398224,
              0.7260569930076599,
              0.2279839962720871,
              0.7260550260543823,
              0.22788499295711517,
              0.7265070080757141,
              0.24679699540138245,
              0.7260550260543823,
              0.22788499295711517,
              0.7265059947967529,
              0.2467159926891327,
              0.7265070080757141,
              0.24679699540138245,
              0.7265070080757141,
              0.24679699540138245,
              0.7265059947967529,
              0.2467159926891327,
              0.7269579768180847,
              0.265610009431839,
              0.7265059947967529,
              0.2467159926891327,
              0.7269570231437683,
              0.2655459940433502,
              0.7269579768180847,
              0.265610009431839,
              0.7270100116729736,
              0.2677629888057709,
              0.7270240187644958,
              0.26781800389289856,
              0.7265740036964417,
              0.24956199526786804,
              0.7270240187644958,
              0.26781800389289856,
              0.7265880107879639,
              0.24963200092315674,
              0.7265740036964417,
              0.24956199526786804,
              0.7265740036964417,
              0.24956199526786804,
              0.7265880107879639,
              0.24963200092315674,
              0.7261379957199097,
              0.2313610017299652,
              0.7265880107879639,
              0.24963200092315674,
              0.7261520028114319,
              0.23144599795341492,
              0.7261379957199097,
              0.2313610017299652,
              0.7270240187644958,
              0.26781800389289856,
              0.7270609736442566,
              0.2678360044956207,
              0.7265880107879639,
              0.24963200092315674,
              0.7270609736442566,
              0.2678360044956207,
              0.7266250252723694,
              0.24965499341487885,
              0.7265880107879639,
              0.24963200092315674,
              0.7265880107879639,
              0.24963200092315674,
              0.7266250252723694,
              0.24965499341487885,
              0.7261520028114319,
              0.23144599795341492,
              0.7266250252723694,
              0.24965499341487885,
              0.7261899709701538,
              0.23147399723529816,
              0.7261520028114319,
              0.23144599795341492,
              0.7270470261573792,
              0.26547300815582275,
              0.7270039916038513,
              0.26547300815582275,
              0.7265959978103638,
              0.2466219961643219,
              0.7270039916038513,
              0.26547300815582275,
              0.7265520095825195,
              0.2466219961643219,
              0.7265959978103638,
              0.2466219961643219,
              0.7265959978103638,
              0.2466219961643219,
              0.7265520095825195,
              0.2466219961643219,
              0.726144015789032,
              0.22777099907398224,
              0.7265520095825195,
              0.2466219961643219,
              0.7261009812355042,
              0.22777099907398224,
              0.726144015789032,
              0.22777099907398224,
              0.7271040081977844,
              0.2678360044956207,
              0.7415549755096436,
              0.2678360044956207,
              0.7266680002212524,
              0.24965499341487885,
              0.7415549755096436,
              0.2678360044956207,
              0.7415549755096436,
              0.24965499341487885,
              0.7266680002212524,
              0.24965499341487885,
              0.7266680002212524,
              0.24965499341487885,
              0.7415549755096436,
              0.24965499341487885,
              0.7262330055236816,
              0.23147399723529816,
              0.7415549755096436,
              0.24965499341487885,
              0.7415549755096436,
              0.23147399723529816,
              0.7262330055236816,
              0.23147399723529816,
              0.7262330055236816,
              0.23147399723529816,
              0.7261899709701538,
              0.23147399723529816,
              0.7266690135002136,
              0.24965499341487885,
              0.7261899709701538,
              0.23147399723529816,
              0.7266250252723694,
              0.24965499341487885,
              0.7266690135002136,
              0.24965499341487885,
              0.7266690135002136,
              0.24965499341487885,
              0.7266250252723694,
              0.24965499341487885,
              0.7271040081977844,
              0.2678360044956207,
              0.7266250252723694,
              0.24965499341487885,
              0.7270609736442566,
              0.2678360044956207,
              0.7271040081977844,
              0.2678360044956207,
              0.7561420202255249,
              0.2654919922351837,
              0.7561060190200806,
              0.26547300815582275,
              0.7565940022468567,
              0.2466460019350052,
              0.7561060190200806,
              0.26547300815582275,
              0.7565580010414124,
              0.2466219961643219,
              0.7565940022468567,
              0.2466460019350052,
              0.7565940022468567,
              0.2466460019350052,
              0.7565580010414124,
              0.2466219961643219,
              0.7570449709892273,
              0.22779999673366547,
              0.7565580010414124,
              0.2466219961643219,
              0.7570090293884277,
              0.22777099907398224,
              0.7570449709892273,
              0.22779999673366547,
              0.7561529874801636,
              0.2655459940433502,
              0.7561420202255249,
              0.2654919922351837,
              0.756604015827179,
              0.2467159926891327,
              0.7561420202255249,
              0.2654919922351837,
              0.7565929889678955,
              0.2466460019350052,
              0.756604015827179,
              0.2467159926891327,
              0.756604015827179,
              0.2467159926891327,
              0.7565929889678955,
              0.2466460019350052,
              0.7570549845695496,
              0.22788499295711517,
              0.7565929889678955,
              0.2466460019350052,
              0.7570449709892273,
              0.22779999673366547,
              0.7570549845695496,
              0.22788499295711517,
              0.757053017616272,
              0.2279839962720871,
              0.7569739818572998,
              0.2312619984149933,
              0.7566019892692566,
              0.24679699540138245,
              0.7569739818572998,
              0.2312619984149933,
              0.7565370202064514,
              0.2494809925556183,
              0.7566019892692566,
              0.24679699540138245,
              0.7566019892692566,
              0.24679699540138245,
              0.7565370202064514,
              0.2494809925556183,
              0.756151020526886,
              0.265610009431839,
              0.7565370202064514,
              0.2494809925556183,
              0.7561010122299194,
              0.2676999866962433,
              0.756151020526886,
              0.265610009431839,
              0.7560999989509583,
              0.26776400208473206,
              0.7561010122299194,
              0.2676999866962433,
              0.7565360069274902,
              0.24956199526786804,
              0.7561010122299194,
              0.2676999866962433,
              0.7565370202064514,
              0.2494809925556183,
              0.7565360069274902,
              0.24956199526786804,
              0.7565360069274902,
              0.24956199526786804,
              0.7565370202064514,
              0.2494809925556183,
              0.7569720149040222,
              0.2313610017299652,
              0.7565370202064514,
              0.2494809925556183,
              0.7569739818572998,
              0.2312619984149933,
              0.7569720149040222,
              0.2313610017299652,
              0.7560629844665527,
              0.26547300815582275,
              0.7415549755096436,
              0.26547300815582275,
              0.7565140128135681,
              0.2466219961643219,
              0.7415549755096436,
              0.26547300815582275,
              0.7415549755096436,
              0.2466219961643219,
              0.7565140128135681,
              0.2466219961643219,
              0.7565140128135681,
              0.2466219961643219,
              0.7415549755096436,
              0.2466219961643219,
              0.7569659948348999,
              0.22777099907398224,
              0.7415549755096436,
              0.2466219961643219,
              0.7415549755096436,
              0.22777099907398224,
              0.7569659948348999,
              0.22777099907398224,
              0.7570549845695496,
              0.22788499295711517,
              0.757053017616272,
              0.2279839962720871,
              0.756604015827179,
              0.2467149943113327,
              0.757053017616272,
              0.2279839962720871,
              0.7566019892692566,
              0.24679699540138245,
              0.756604015827179,
              0.2467149943113327,
              0.756604015827179,
              0.2467149943113327,
              0.7566019892692566,
              0.24679699540138245,
              0.7561529874801636,
              0.2655459940433502,
              0.7566019892692566,
              0.24679699540138245,
              0.756151020526886,
              0.265610009431839,
              0.7561529874801636,
              0.2655459940433502,
              0.756085991859436,
              0.26781800389289856,
              0.7560999989509583,
              0.26776400208473206,
              0.756521999835968,
              0.24963200092315674,
              0.7560999989509583,
              0.26776400208473206,
              0.7565360069274902,
              0.24956299364566803,
              0.756521999835968,
              0.24963200092315674,
              0.756521999835968,
              0.24963200092315674,
              0.7565360069274902,
              0.24956299364566803,
              0.7569580078125,
              0.23144599795341492,
              0.7565360069274902,
              0.24956299364566803,
              0.7569720149040222,
              0.2313610017299652,
              0.7569580078125,
              0.23144599795341492,
              0.7560489773750305,
              0.2678360044956207,
              0.756085991859436,
              0.26781800389289856,
              0.7564849853515625,
              0.24965499341487885,
              0.756085991859436,
              0.26781800389289856,
              0.756521999835968,
              0.24963200092315674,
              0.7564849853515625,
              0.24965499341487885,
              0.7564849853515625,
              0.24965499341487885,
              0.756521999835968,
              0.24963200092315674,
              0.7569199800491333,
              0.23147399723529816,
              0.756521999835968,
              0.24963200092315674,
              0.7569580078125,
              0.23144599795341492,
              0.7569199800491333,
              0.23147399723529816,
              0.7561060190200806,
              0.26547300815582275,
              0.7560629844665527,
              0.26547300815582275,
              0.7565580010414124,
              0.2466219961643219,
              0.7560629844665527,
              0.26547300815582275,
              0.7565140128135681,
              0.2466219961643219,
              0.7565580010414124,
              0.2466219961643219,
              0.7565580010414124,
              0.2466219961643219,
              0.7565140128135681,
              0.2466219961643219,
              0.7570090293884277,
              0.22777099907398224,
              0.7565140128135681,
              0.2466219961643219,
              0.7569659948348999,
              0.22777099907398224,
              0.7570090293884277,
              0.22777099907398224,
              0.7415549755096436,
              0.2678360044956207,
              0.7560060024261475,
              0.2678360044956207,
              0.7415549755096436,
              0.24965499341487885,
              0.7560060024261475,
              0.2678360044956207,
              0.7564409971237183,
              0.24965499341487885,
              0.7415549755096436,
              0.24965499341487885,
              0.7415549755096436,
              0.24965499341487885,
              0.7564409971237183,
              0.24965499341487885,
              0.7415549755096436,
              0.23147399723529816,
              0.7564409971237183,
              0.24965499341487885,
              0.7568770051002502,
              0.23147399723529816,
              0.7415549755096436,
              0.23147399723529816,
              0.7569199800491333,
              0.23147399723529816,
              0.7568770051002502,
              0.23147399723529816,
              0.7564849853515625,
              0.24965499341487885,
              0.7568770051002502,
              0.23147399723529816,
              0.7564420104026794,
              0.24965499341487885,
              0.7564849853515625,
              0.24965499341487885,
              0.7564849853515625,
              0.24965499341487885,
              0.7564420104026794,
              0.24965499341487885,
              0.7560489773750305,
              0.2678360044956207,
              0.7564420104026794,
              0.24965499341487885,
              0.7560060024261475,
              0.2678360044956207,
              0.7560489773750305,
              0.2678360044956207,
              0.7474009990692139,
              0.3830209970474243,
              0.7465609908103943,
              0.45312100648880005,
              0.7444779872894287,
              0.3830209970474243,
              0.7465609908103943,
              0.45312100648880005,
              0.7440580129623413,
              0.45312100648880005,
              0.7444779872894287,
              0.3830209970474243,
              0.7444779872894287,
              0.3830209970474243,
              0.7440580129623413,
              0.45312100648880005,
              0.7415549755096436,
              0.3830209970474243,
              0.7440580129623413,
              0.45312100648880005,
              0.7415549755096436,
              0.45312100648880005,
              0.7415549755096436,
              0.3830209970474243,
              0.7415549755096436,
              0.41994601488113403,
              0.7307469844818115,
              0.41994601488113403,
              0.7415549755096436,
              0.43722400069236755,
              0.7307469844818115,
              0.41994601488113403,
              0.7311609983444214,
              0.43722400069236755,
              0.7415549755096436,
              0.43722400069236755,
              0.7415549755096436,
              0.43722400069236755,
              0.7311609983444214,
              0.43722400069236755,
              0.7415549755096436,
              0.4545019865036011,
              0.7311609983444214,
              0.43722400069236755,
              0.7315750122070312,
              0.4545019865036011,
              0.7415549755096436,
              0.4545019865036011,
              0.7306680083274841,
              0.41993099451065063,
              0.7307050228118896,
              0.41994601488113403,
              0.7302539944648743,
              0.40265101194381714,
              0.7307050228118896,
              0.41994601488113403,
              0.7302910089492798,
              0.4026679992675781,
              0.7302539944648743,
              0.40265101194381714,
              0.7302539944648743,
              0.40265101194381714,
              0.7302910089492798,
              0.4026679992675781,
              0.7298399806022644,
              0.38537099957466125,
              0.7302910089492798,
              0.4026679992675781,
              0.7298769950866699,
              0.3853900134563446,
              0.7298399806022644,
              0.38537099957466125,
              0.7306230068206787,
              0.4180859923362732,
              0.7306119799613953,
              0.41812899708747864,
              0.7302039861679077,
              0.40056198835372925,
              0.7306119799613953,
              0.41812899708747864,
              0.7301920056343079,
              0.400611013174057,
              0.7302039861679077,
              0.40056198835372925,
              0.7302039861679077,
              0.40056198835372925,
              0.7301920056343079,
              0.400611013174057,
              0.7297840118408203,
              0.3830389976501465,
              0.7301920056343079,
              0.400611013174057,
              0.7297729849815369,
              0.38309401273727417,
              0.7297840118408203,
              0.3830389976501465,
              0.7314820289611816,
              0.45443999767303467,
              0.7298250198364258,
              0.3852840065956116,
              0.7314810156822205,
              0.4544290006160736,
              0.7298250198364258,
              0.3852840065956116,
              0.7298250198364258,
              0.3852680027484894,
              0.7314810156822205,
              0.4544290006160736,
              0.7314810156822205,
              0.4544290006160736,
              0.7298250198364258,
              0.3852680027484894,
              0.7314810156822205,
              0.4544210135936737,
              0.7298250198364258,
              0.3852680027484894,
              0.7298250198364258,
              0.3852519989013672,
              0.7314810156822205,
              0.4544210135936737,
              0.7298020124435425,
              0.3830310106277466,
              0.7314810156822205,
              0.4531269967556,
              0.7297930121421814,
              0.38303300738334656,
              0.7314810156822205,
              0.4531269967556,
              0.7314720153808594,
              0.45312801003456116,
              0.7297930121421814,
              0.38303300738334656,
              0.7297930121421814,
              0.38303300738334656,
              0.7314720153808594,
              0.45312801003456116,
              0.7297840118408203,
              0.3830389976501465,
              0.7314720153808594,
              0.45312801003456116,
              0.7314630150794983,
              0.4531320035457611,
              0.7297840118408203,
              0.3830389976501465,
              0.7306129932403564,
              0.418179988861084,
              0.7306119799613953,
              0.41812899708747864,
              0.7310330271720886,
              0.4356909990310669,
              0.7306119799613953,
              0.41812899708747864,
              0.7310310006141663,
              0.4356459975242615,
              0.7310330271720886,
              0.4356909990310669,
              0.7310330271720886,
              0.4356909990310669,
              0.7310310006141663,
              0.4356459975242615,
              0.7314519882202148,
              0.4532020092010498,
              0.7310310006141663,
              0.4356459975242615,
              0.7314509749412537,
              0.4531640112400055,
              0.7314519882202148,
              0.4532020092010498,
              0.7298330068588257,
              0.3853429853916168,
              0.7314879894256592,
              0.4544749855995178,
              0.7298359870910645,
              0.38535699248313904,
              0.7314879894256592,
              0.4544749855995178,
              0.7314919829368591,
              0.4544839859008789,
              0.7298359870910645,
              0.38535699248313904,
              0.7298359870910645,
              0.38535699248313904,
              0.7314919829368591,
              0.4544839859008789,
              0.7298399806022644,
              0.38537099957466125,
              0.7314919829368591,
              0.4544839859008789,
              0.7314950227737427,
              0.45449098944664,
              0.7298399806022644,
              0.38537099957466125,
              0.7306129932403564,
              0.418179988861084,
              0.7306529879570007,
              0.41983699798583984,
              0.730193018913269,
              0.40066900849342346,
              0.7306529879570007,
              0.41983699798583984,
              0.7302389740943909,
              0.4025439918041229,
              0.730193018913269,
              0.40066900849342346,
              0.730193018913269,
              0.40066900849342346,
              0.7302389740943909,
              0.4025439918041229,
              0.729773998260498,
              0.38315799832344055,
              0.7302389740943909,
              0.4025439918041229,
              0.7298250198364258,
              0.3852519989013672,
              0.729773998260498,
              0.38315799832344055,
              0.7307019829750061,
              0.4180710017681122,
              0.730659008026123,
              0.4180710017681122,
              0.7302830219268799,
              0.40054601430892944,
              0.730659008026123,
              0.4180710017681122,
              0.730239987373352,
              0.40054601430892944,
              0.7302830219268799,
              0.40054601430892944,
              0.7302830219268799,
              0.40054601430892944,
              0.730239987373352,
              0.40054601430892944,
              0.7298629879951477,
              0.3830209970474243,
              0.730239987373352,
              0.40054601430892944,
              0.7298200130462646,
              0.3830209970474243,
              0.7298629879951477,
              0.3830209970474243,
              0.7415549755096436,
              0.4180710017681122,
              0.7307029962539673,
              0.4180710017681122,
              0.7415549755096436,
              0.40054601430892944,
              0.7307029962539673,
              0.4180710017681122,
              0.7302830219268799,
              0.40054601430892944,
              0.7415549755096436,
              0.40054601430892944,
              0.7415549755096436,
              0.40054601430892944,
              0.7302830219268799,
              0.40054601430892944,
              0.7415549755096436,
              0.3830209970474243,
              0.7302830219268799,
              0.40054601430892944,
              0.7298629879951477,
              0.3830209970474243,
              0.7415549755096436,
              0.3830209970474243,
              0.7532510161399841,
              0.3853810131549835,
              0.7515950202941895,
              0.45449700951576233,
              0.7532610297203064,
              0.3853760063648224,
              0.7515950202941895,
              0.45449700951576233,
              0.7516049742698669,
              0.45449399948120117,
              0.7532610297203064,
              0.3853760063648224,
              0.7532610297203064,
              0.3853760063648224,
              0.7516049742698669,
              0.45449399948120117,
              0.7532699704170227,
              0.38537099957466125,
              0.7516049742698669,
              0.45449399948120117,
              0.751613974571228,
              0.45449098944664,
              0.7532699704170227,
              0.38537099957466125,
              0.7515559792518616,
              0.4545010030269623,
              0.7532119750976562,
              0.3853900134563446,
              0.7515450119972229,
              0.45450299978256226,
              0.7532119750976562,
              0.3853900134563446,
              0.7532010078430176,
              0.3853900134563446,
              0.7515450119972229,
              0.45450299978256226,
              0.7515450119972229,
              0.45450299978256226,
              0.7532010078430176,
              0.3853900134563446,
              0.7515339851379395,
              0.4545019865036011,
              0.7532010078430176,
              0.3853900134563446,
              0.7531899809837341,
              0.3853900134563446,
              0.7515339851379395,
              0.4545019865036011,
              0.751658022403717,
              0.45318299531936646,
              0.7533360123634338,
              0.3831239938735962,
              0.7516570091247559,
              0.4531930088996887,
              0.7533360123634338,
              0.3831239938735962,
              0.7533360123634338,
              0.38314101099967957,
              0.7516570091247559,
              0.4531930088996887,
              0.7516570091247559,
              0.4531930088996887,
              0.7533360123634338,
              0.38314101099967957,
              0.7516570091247559,
              0.4532020092010498,
              0.7533360123634338,
              0.38314101099967957,
              0.7533349990844727,
              0.38315799832344055,
              0.7516570091247559,
              0.4532020092010498,
              0.752485990524292,
              0.4180859923362732,
              0.7524499893188477,
              0.4180710017681122,
              0.7529060244560242,
              0.40056198835372925,
              0.7524499893188477,
              0.4180710017681122,
              0.7528700232505798,
              0.40054601430892944,
              0.7529060244560242,
              0.40056198835372925,
              0.7529060244560242,
              0.40056198835372925,
              0.7528700232505798,
              0.40054601430892944,
              0.7533259987831116,
              0.3830389976501465,
              0.7528700232505798,
              0.40054601430892944,
              0.7532899975776672,
              0.3830209970474243,
              0.7533259987831116,
              0.3830389976501465,
              0.7533320188522339,
              0.3830670118331909,
              0.7516530156135559,
              0.4531469941139221,
              0.7533289790153503,
              0.3830539882183075,
              0.7516530156135559,
              0.4531469941139221,
              0.7516499757766724,
              0.45313799381256104,
              0.7533289790153503,
              0.3830539882183075,
              0.7533289790153503,
              0.3830539882183075,
              0.7516499757766724,
              0.45313799381256104,
              0.7533259987831116,
              0.3830389976501465,
              0.7516499757766724,
              0.45313799381256104,
              0.7516469955444336,
              0.4531320035457611,
              0.7533259987831116,
              0.3830389976501465,
              0.7524420022964478,
              0.41993099451065063,
              0.7524549961090088,
              0.4198879897594452,
              0.7528560161590576,
              0.40265101194381714,
              0.7524549961090088,
              0.4198879897594452,
              0.7528700232505798,
              0.40260300040245056,
              0.7528560161590576,
              0.40265101194381714,
              0.7528560161590576,
              0.40265101194381714,
              0.7528700232505798,
              0.40260300040245056,
              0.7532699704170227,
              0.38537099957466125,
              0.7528700232505798,
              0.40260300040245056,
              0.7532839775085449,
              0.38531699776649475,
              0.7532699704170227,
              0.38537099957466125,
              0.7532680034637451,
              0.3830200135707855,
              0.7515890002250671,
              0.45312100648880005,
              0.7532579898834229,
              0.3830209970474243,
              0.7515890002250671,
              0.45312100648880005,
              0.7515779733657837,
              0.45312198996543884,
              0.7532579898834229,
              0.3830209970474243,
              0.7532579898834229,
              0.3830209970474243,
              0.7515779733657837,
              0.45312198996543884,
              0.7532470226287842,
              0.3830209970474243,
              0.7515779733657837,
              0.45312198996543884,
              0.751567006111145,
              0.45312100648880005,
              0.7532470226287842,
              0.3830209970474243,
              0.7533100247383118,
              0.38420501351356506,
              0.7516419887542725,
              0.45381098985671997,
              0.7533230185508728,
              0.3836809992790222,
              0.7516419887542725,
              0.45381098985671997,
              0.7516499757766724,
              0.4535070061683655,
              0.7533230185508728,
              0.3836809992790222,
              0.7533230185508728,
              0.3836809992790222,
              0.7516499757766724,
              0.4535070061683655,
              0.7533349990844727,
              0.38315799832344055,
              0.7516499757766724,
              0.4535070061683655,
              0.7516570091247559,
              0.4532020092010498,
              0.7533349990844727,
              0.38315799832344055,
              0.7465440034866333,
              0.4545019865036011,
              0.7473729848861694,
              0.3853900134563446,
              0.7440500259399414,
              0.4545019865036011,
              0.7473729848861694,
              0.3853900134563446,
              0.7444639801979065,
              0.3853900134563446,
              0.7440500259399414,
              0.4545019865036011,
              0.7440500259399414,
              0.4545019865036011,
              0.7444639801979065,
              0.3853900134563446,
              0.7415549755096436,
              0.4545019865036011,
              0.7444639801979065,
              0.3853900134563446,
              0.7415549755096436,
              0.3853900134563446,
              0.7415549755096436,
              0.4545019865036011,
              0.75245600938797,
              0.41983699798583984,
              0.75245600938797,
              0.4198879897594452,
              0.7520419955253601,
              0.4371289908885956,
              0.75245600938797,
              0.4198879897594452,
              0.7520409822463989,
              0.4371730089187622,
              0.7520419955253601,
              0.4371289908885956,
              0.7520419955253601,
              0.4371289908885956,
              0.7520409822463989,
              0.4371730089187622,
              0.7516279816627502,
              0.4544210135936737,
              0.7520409822463989,
              0.4371730089187622,
              0.7516270279884338,
              0.454459011554718,
              0.7516279816627502,
              0.4544210135936737,
              0.7307479977607727,
              0.41994601488113403,
              0.7307040095329285,
              0.41994601488113403,
              0.7311609983444214,
              0.43722400069236755,
              0.7307040095329285,
              0.41994601488113403,
              0.7311180233955383,
              0.43722400069236755,
              0.7311609983444214,
              0.43722400069236755,
              0.7311609983444214,
              0.43722400069236755,
              0.7311180233955383,
              0.43722400069236755,
              0.7315750122070312,
              0.4545019865036011,
              0.7311180233955383,
              0.43722400069236755,
              0.7315319776535034,
              0.4545019865036011,
              0.7315750122070312,
              0.4545019865036011,
              0.749064028263092,
              0.45312100648880005,
              0.750324010848999,
              0.3830209970474243,
              0.751567006111145,
              0.45312100648880005,
              0.750324010848999,
              0.3830209970474243,
              0.7532470226287842,
              0.3830209970474243,
              0.751567006111145,
              0.45312100648880005,
              0.750324010848999,
              0.3830209970474243,
              0.749064028263092,
              0.45312100648880005,
              0.7474009990692139,
              0.3830209970474243,
              0.749064028263092,
              0.45312100648880005,
              0.7465609908103943,
              0.45312100648880005,
              0.7474009990692139,
              0.3830209970474243,
              0.7415549755096436,
              0.3853900134563446,
              0.7299200296401978,
              0.3853900134563446,
              0.7415549755096436,
              0.4026679992675781,
              0.7299200296401978,
              0.3853900134563446,
              0.7303339838981628,
              0.4026679992675781,
              0.7415549755096436,
              0.4026679992675781,
              0.7415549755096436,
              0.4026679992675781,
              0.7303339838981628,
              0.4026679992675781,
              0.7415549755096436,
              0.41994601488113403,
              0.7303339838981628,
              0.4026679992675781,
              0.7307469844818115,
              0.41994601488113403,
              0.7415549755096436,
              0.41994601488113403,
              0.7314950227737427,
              0.45449098944664,
              0.7315319776535034,
              0.4545019865036011,
              0.7310810089111328,
              0.4372110068798065,
              0.7315319776535034,
              0.4545019865036011,
              0.7311180233955383,
              0.43722400069236755,
              0.7310810089111328,
              0.4372110068798065,
              0.7310810089111328,
              0.4372110068798065,
              0.7311180233955383,
              0.43722400069236755,
              0.7306680083274841,
              0.41993099451065063,
              0.7311180233955383,
              0.43722400069236755,
              0.7307050228118896,
              0.41994601488113403,
              0.7306680083274841,
              0.41993099451065063,
              0.7314630150794983,
              0.4531320035457611,
              0.7314509749412537,
              0.4531640112400055,
              0.7310429811477661,
              0.43560901284217834,
              0.7314509749412537,
              0.4531640112400055,
              0.7310310006141663,
              0.4356459975242615,
              0.7310429811477661,
              0.43560901284217834,
              0.7310429811477661,
              0.43560901284217834,
              0.7310310006141663,
              0.4356459975242615,
              0.7306230068206787,
              0.4180859923362732,
              0.7310310006141663,
              0.4356459975242615,
              0.7306119799613953,
              0.41812899708747864,
              0.7306230068206787,
              0.4180859923362732,
              0.7314820289611816,
              0.454459011554718,
              0.7298259735107422,
              0.38531601428985596,
              0.7314820289611816,
              0.45444899797439575,
              0.7298259735107422,
              0.38531601428985596,
              0.7298259735107422,
              0.3852989971637726,
              0.7314820289611816,
              0.45444899797439575,
              0.7314820289611816,
              0.45444899797439575,
              0.7298259735107422,
              0.3852989971637726,
              0.7314820289611816,
              0.45443999767303467,
              0.7298259735107422,
              0.3852989971637726,
              0.7298250198364258,
              0.3852840065956116,
              0.7314820289611816,
              0.45443999767303467,
              0.7298200130462646,
              0.3830209970474243,
              0.7314990162849426,
              0.45312100648880005,
              0.7298110127449036,
              0.38302499055862427,
              0.7314990162849426,
              0.45312100648880005,
              0.7314900159835815,
              0.453125,
              0.7298110127449036,
              0.38302499055862427,
              0.7298110127449036,
              0.38302499055862427,
              0.7314900159835815,
              0.453125,
              0.7298020124435425,
              0.3830310106277466,
              0.7314900159835815,
              0.453125,
              0.7314810156822205,
              0.4531269967556,
              0.7298020124435425,
              0.3830310106277466,
              0.729773998260498,
              0.38315799832344055,
              0.7297729849815369,
              0.38309401273727417,
              0.7301939725875854,
              0.40066900849342346,
              0.7297729849815369,
              0.38309401273727417,
              0.7301920056343079,
              0.400611013174057,
              0.7301939725875854,
              0.40066900849342346,
              0.7301939725875854,
              0.40066900849342346,
              0.7301920056343079,
              0.400611013174057,
              0.7306129932403564,
              0.418179988861084,
              0.7301920056343079,
              0.400611013174057,
              0.7306119799613953,
              0.41812899708747864,
              0.7306129932403564,
              0.418179988861084,
              0.7298259735107422,
              0.38531601428985596,
              0.7314820289611816,
              0.454459011554718,
              0.7298290133476257,
              0.3853299915790558,
              0.7314820289611816,
              0.454459011554718,
              0.7314850091934204,
              0.4544669985771179,
              0.7298290133476257,
              0.3853299915790558,
              0.7298290133476257,
              0.3853299915790558,
              0.7314850091934204,
              0.4544669985771179,
              0.7298330068588257,
              0.3853429853916168,
              0.7314850091934204,
              0.4544669985771179,
              0.7314879894256592,
              0.4544749855995178,
              0.7298330068588257,
              0.3853429853916168,
              0.7314519882202148,
              0.4532020092010498,
              0.7314810156822205,
              0.4544210135936737,
              0.7310320138931274,
              0.4356909990310669,
              0.7314810156822205,
              0.4544210135936737,
              0.7310670018196106,
              0.4371289908885956,
              0.7310320138931274,
              0.4356909990310669,
              0.7310320138931274,
              0.4356909990310669,
              0.7310670018196106,
              0.4371289908885956,
              0.7306129932403564,
              0.418179988861084,
              0.7310670018196106,
              0.4371289908885956,
              0.7306529879570007,
              0.41983699798583984,
              0.7306129932403564,
              0.418179988861084,
              0.7315419912338257,
              0.45312100648880005,
              0.7314990162849426,
              0.45312100648880005,
              0.7311220169067383,
              0.4355959892272949,
              0.7314990162849426,
              0.45312100648880005,
              0.7310789823532104,
              0.4355959892272949,
              0.7311220169067383,
              0.4355959892272949,
              0.7311220169067383,
              0.4355959892272949,
              0.7310789823532104,
              0.4355959892272949,
              0.7307019829750061,
              0.4180710017681122,
              0.7310789823532104,
              0.4355959892272949,
              0.730659008026123,
              0.4180710017681122,
              0.7307019829750061,
              0.4180710017681122,
              0.7415549755096436,
              0.45312100648880005,
              0.7315419912338257,
              0.45312100648880005,
              0.7415549755096436,
              0.4355959892272949,
              0.7315419912338257,
              0.45312100648880005,
              0.7311220169067383,
              0.4355959892272949,
              0.7415549755096436,
              0.4355959892272949,
              0.7415549755096436,
              0.4355959892272949,
              0.7311220169067383,
              0.4355959892272949,
              0.7415549755096436,
              0.4180710017681122,
              0.7311220169067383,
              0.4355959892272949,
              0.7307029962539673,
              0.4180710017681122,
              0.7415549755096436,
              0.4180710017681122,
              0.753233015537262,
              0.3853900134563446,
              0.7515770196914673,
              0.4545019865036011,
              0.753242015838623,
              0.38538500666618347,
              0.7515770196914673,
              0.4545019865036011,
              0.7515860199928284,
              0.4545010030269623,
              0.753242015838623,
              0.38538500666618347,
              0.753242015838623,
              0.38538500666618347,
              0.7515860199928284,
              0.4545010030269623,
              0.7532510161399841,
              0.3853810131549835,
              0.7515860199928284,
              0.4545010030269623,
              0.7515950202941895,
              0.45449700951576233,
              0.7532510161399841,
              0.3853810131549835,
              0.7515770196914673,
              0.4545019865036011,
              0.753233015537262,
              0.3853900134563446,
              0.7515659928321838,
              0.4545019865036011,
              0.753233015537262,
              0.3853900134563446,
              0.7532219886779785,
              0.3853900134563446,
              0.7515659928321838,
              0.4545019865036011,
              0.7515659928321838,
              0.4545019865036011,
              0.7532219886779785,
              0.3853900134563446,
              0.7515559792518616,
              0.4545010030269623,
              0.7532219886779785,
              0.3853900134563446,
              0.7532119750976562,
              0.3853900134563446,
              0.7515559792518616,
              0.4545010030269623,
              0.751658022403717,
              0.4531640112400055,
              0.753337025642395,
              0.38309401273727417,
              0.751658022403717,
              0.4531719982624054,
              0.753337025642395,
              0.38309401273727417,
              0.753337025642395,
              0.3831090033054352,
              0.751658022403717,
              0.4531719982624054,
              0.751658022403717,
              0.4531719982624054,
              0.753337025642395,
              0.3831090033054352,
              0.751658022403717,
              0.45318299531936646,
              0.753337025642395,
              0.3831090033054352,
              0.7533360123634338,
              0.3831239938735962,
              0.751658022403717,
              0.45318299531936646,
              0.7516469955444336,
              0.4531320035457611,
              0.7516109943389893,
              0.45312100648880005,
              0.7520670294761658,
              0.43560901284217834,
              0.7516109943389893,
              0.45312100648880005,
              0.7520310282707214,
              0.4355959892272949,
              0.7520670294761658,
              0.43560901284217834,
              0.7520670294761658,
              0.43560901284217834,
              0.7520310282707214,
              0.4355959892272949,
              0.752485990524292,
              0.4180859923362732,
              0.7520310282707214,
              0.4355959892272949,
              0.7524499893188477,
              0.4180710017681122,
              0.752485990524292,
              0.4180859923362732,
              0.753337025642395,
              0.38309401273727417,
              0.751658022403717,
              0.4531640112400055,
              0.7533339858055115,
              0.38308000564575195,
              0.751658022403717,
              0.4531640112400055,
              0.7516549825668335,
              0.4531559944152832,
              0.7533339858055115,
              0.38308000564575195,
              0.7533339858055115,
              0.38308000564575195,
              0.7516549825668335,
              0.4531559944152832,
              0.7533320188522339,
              0.3830670118331909,
              0.7516549825668335,
              0.4531559944152832,
              0.7516530156135559,
              0.4531469941139221,
              0.7533320188522339,
              0.3830670118331909,
              0.751613974571228,
              0.45449098944664,
              0.7516270279884338,
              0.454459011554718,
              0.7520279884338379,
              0.4372110068798065,
              0.7516270279884338,
              0.454459011554718,
              0.7520409822463989,
              0.437173992395401,
              0.7520279884338379,
              0.4372110068798065,
              0.7520279884338379,
              0.4372110068798065,
              0.7520409822463989,
              0.437173992395401,
              0.7524420022964478,
              0.41993099451065063,
              0.7520409822463989,
              0.437173992395401,
              0.7524549961090088,
              0.4198879897594452,
              0.7524420022964478,
              0.41993099451065063,
              0.7532899975776672,
              0.3830209970474243,
              0.7516109943389893,
              0.45312100648880005,
              0.7532789707183838,
              0.3830200135707855,
              0.7516109943389893,
              0.45312100648880005,
              0.7516000270843506,
              0.4531190097332001,
              0.7532789707183838,
              0.3830200135707855,
              0.7532789707183838,
              0.3830200135707855,
              0.7516000270843506,
              0.4531190097332001,
              0.7532680034637451,
              0.3830200135707855,
              0.7516000270843506,
              0.4531190097332001,
              0.7515890002250671,
              0.45312100648880005,
              0.7532680034637451,
              0.3830200135707855,
              0.7532849907875061,
              0.3852519989013672,
              0.7516279816627502,
              0.4544210135936737,
              0.7532979846000671,
              0.38472801446914673,
              0.7516279816627502,
              0.4544210135936737,
              0.7516350150108337,
              0.45411598682403564,
              0.7532979846000671,
              0.38472801446914673,
              0.7532979846000671,
              0.38472801446914673,
              0.7516350150108337,
              0.45411598682403564,
              0.7533100247383118,
              0.38420501351356506,
              0.7516350150108337,
              0.45411598682403564,
              0.7516419887542725,
              0.45381098985671997,
              0.7533100247383118,
              0.38420501351356506,
              0.7515339851379395,
              0.4545019865036011,
              0.7531899809837341,
              0.3853900134563446,
              0.7490389943122864,
              0.4545019865036011,
              0.7531899809837341,
              0.3853900134563446,
              0.7502809762954712,
              0.3853900134563446,
              0.7490389943122864,
              0.4545019865036011,
              0.7490389943122864,
              0.4545019865036011,
              0.7502809762954712,
              0.3853900134563446,
              0.7465440034866333,
              0.4545019865036011,
              0.7502809762954712,
              0.3853900134563446,
              0.7473729848861694,
              0.3853900134563446,
              0.7465440034866333,
              0.4545019865036011,
              0.7532849907875061,
              0.3852519989013672,
              0.7532839775085449,
              0.38531699776649475,
              0.7528709769248962,
              0.4025439918041229,
              0.7532839775085449,
              0.38531699776649475,
              0.7528700232505798,
              0.4026019871234894,
              0.7528709769248962,
              0.4025439918041229,
              0.7528709769248962,
              0.4025439918041229,
              0.7528700232505798,
              0.4026019871234894,
              0.75245600938797,
              0.41983699798583984,
              0.7528700232505798,
              0.4026019871234894,
              0.75245600938797,
              0.4198879897594452,
              0.75245600938797,
              0.41983699798583984,
              0.7299200296401978,
              0.3853900134563446,
              0.7298769950866699,
              0.3853900134563446,
              0.7303339838981628,
              0.4026679992675781,
              0.7298769950866699,
              0.3853900134563446,
              0.7302910089492798,
              0.4026679992675781,
              0.7303339838981628,
              0.4026679992675781,
              0.7303339838981628,
              0.4026679992675781,
              0.7302910089492798,
              0.4026679992675781,
              0.7307479977607727,
              0.41994601488113403,
              0.7302910089492798,
              0.4026679992675781,
              0.7307040095329285,
              0.41994601488113403,
              0.7307479977607727,
              0.41994601488113403
            ],
            "normalized": false
          }
        },
        "groups": [
          {
            "start": 0,
            "materialIndex": 0,
            "count": 846
          },
          {
            "start": 846,
            "materialIndex": 1,
            "count": 36
          },
          {
            "start": 882,
            "materialIndex": 0,
            "count": 744
          },
          {
            "start": 1626,
            "materialIndex": 1,
            "count": 132
          },
          {
            "start": 1758,
            "materialIndex": 0,
            "count": 120
          },
          {
            "start": 1878,
            "materialIndex": 1,
            "count": 24
          },
          {
            "start": 1902,
            "materialIndex": 0,
            "count": 144
          },
          {
            "start": 2046,
            "materialIndex": 1,
            "count": 24
          },
          {
            "start": 2070,
            "materialIndex": 0,
            "count": 546
          },
          {
            "start": 2616,
            "materialIndex": 2,
            "count": 10140
          },
          {
            "start": 12756,
            "materialIndex": 0,
            "count": 846
          },
          {
            "start": 13602,
            "materialIndex": 1,
            "count": 36
          },
          {
            "start": 13638,
            "materialIndex": 0,
            "count": 744
          },
          {
            "start": 14382,
            "materialIndex": 1,
            "count": 132
          },
          {
            "start": 14514,
            "materialIndex": 0,
            "count": 120
          },
          {
            "start": 14634,
            "materialIndex": 1,
            "count": 24
          },
          {
            "start": 14658,
            "materialIndex": 0,
            "count": 144
          },
          {
            "start": 14802,
            "materialIndex": 1,
            "count": 24
          },
          {
            "start": 14826,
            "materialIndex": 0,
            "count": 546
          },
          {
            "start": 15372,
            "materialIndex": 2,
            "count": 10140
          }
        ]
      }
    }
  ],
  "materials": [
    {
      "uuid": "05E9D2E6-CC61-4F67-9F2A-6DFC0EF31EAE",
      "type": "MeshNormalMaterial",
      "depthFunc": 3,
      "depthTest": true,
      "depthWrite": true,
      "wireframe": true
    }
  ],
  "object": {
    "uuid": "C9523642-F024-480F-AB72-B428F6AA12A3",
    "type": "Mesh",
    "layers": 1,
    "matrix": [
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    ],
    "geometry": "C41D692E-D3B0-4506-A01F-7CFEF0880A85",
    "material": "05E9D2E6-CC61-4F67-9F2A-6DFC0EF31EAE"
  }
}
